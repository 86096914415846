<template>
  <div class="modal__content reconnect-modal login-block">
    <modal-header :close-button="false"
                  :title="$t('title.reconnect')" />
    <component-alert v-if="error"
                     type="error"
                     :message="errorMessage || $t('message.warning.unknown')" />
    <p class="login-subtitle">
      {{ $t('modal.reconnect.text') }}
    </p>
    <div class="reconnect-modal__content">
      <validation-form ref="form"
                       v-slot="{ errors, isSubmitting }"
                       class="login-form"
                       autocomplete="off"
                       :validation-schema="schema"
                       @submit="onSubmit"
                       @invalid-submit="onInvalidSubmit">
        <div class="login-form__input">
          <input id="email"
                 v-model="email"
                 type="text"
                 class="form-control"
                 name="email"
                 :disabled="true">
        </div>
        <div class="login-form__input"
             :class="{ error: errors.password }">
          <validation-field id="password"
                            v-model="password"
                            :type="passwordType"
                            class="form-control"
                            :placeholder="$t('form.password.placeholder')"
                            name="password" />
          <div class="login-form__input-addon">
            <button v-if="password.length"
                    class="btn-link"
                    type="button"
                    @click="togglePasswordVisibility">
              <ic-view class="ic"
                       :class="[passwordType === 'text' ? 'ic--secondary' : 'ic--gray']" />
            </button>
          </div>
        </div>
        <component-button type="submit"
                          class="btn--primary btn--block login-form__submit"
                          :disabled="isSubmitting || hasOpenRequests"
                          :show-loader="isSubmitting || hasOpenRequests"
                          :label="$t('button.continue')" />
        <button type="button"
                class="btn--white btn-link btn--block login-form__abort"
                :disabled="isSubmitting"
                @click="onAbort">
          {{ $t('button.cancel') }}
        </button>
      </validation-form>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import IcView from '@/components/svg/icons/ic-view.vue'

export default {
  name: 'PageReconnect',

  components: {
    ModalHeader,
    IcView,
    ComponentAlert,
    ComponentButton
  },

  props: {
    location: {
      type: Object,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { modal } = storeToRefs(appStore)
    const { hasOpenRequests } = storeToRefs(apiStore)
    const { user } = storeToRefs(authStore)

    return {
      appStore,
      authStore,

      modal,
      hasOpenRequests,
      user
    }
  },

  data () {
    return {
      email: this.user.email,
      password: '',
      error: false,
      errorMessage: null,
      formSubmitted: false,
      passwordType: 'password'
    }
  },

  computed: {
    schema () {
      return yup.object({
        password: yup.string().required()
      })
    }
  },

  created () {
    this.authStore.setUserToken(null)
  },

  mounted () {
    this.$bus.on('on-background-action', this.onAbort)
  },

  beforeUnmount () {
    this.$bus.off('on-background-action', this.onAbort)
    this.letUserLeaveModal(true)
  },

  methods: {
    togglePasswordVisibility () {
      this.passwordType = (this.passwordType === 'password' ? 'text' : 'password')
    },

    onAbort () {
      if (!this.$refs.form.isSubmitting) {
        this.$emit('close')
        this.$router.push({ name: 'login' })
      }
      this.appStore.removeRetryRequests()
    },

    letUserLeaveModal (permission) {
      this.modal.onBackgroundLeaveAction = permission
      this.modal.blockLeaveBackground = !permission
    },

    async onSubmit () {
      this.letUserLeaveModal(false)
      if (this.hasOpenRequests) {
        return
      }

      this.formSubmitted = true

      const { success, error } = await this.authStore.reAuth(this.email, this.password)

      if (success) {
        return this.onAuthSuccess()
      }

      this.errorMessage = error.response?.data?.message || null
      this.letUserLeaveModal(true)
      this.error = true
    },

    onInvalidSubmit () {
      this.errorMessage = this.$i18n.t('message.warning.reconnect')
      this.letUserLeaveModal(true)
      this.error = true
    },

    onAuthSuccess () {
      this.$emit('close')
      if (this.location) {
        this.$router.push(this.location)
      } else {
        this.appStore.executeAllRetryRequests()
      }
    }
  }
}
</script>
