<template>
  <div id="limit"
       data-cy="cards.settings.section.periodicity-virtual"
       class="form__fieldset">
    <group-title :title="$t('form.title.card_periodicity')" />
    <div class="form__fieldset__group">
      <div class="row">
        <div class="col col--sm-6">
          <div class="form__fieldset__block">
            <div class="item-left">
              <ic-card swipe
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('form.amount.label') }}</span>
            </div>
            <div class="item-right">
              <div class="input-group">
                <number-input v-model="amount"
                              name="amount"
                              class="form-control form-control--sm right"
                              :disabled="disabled"
                              :placeholder="$t('form.amount.placeholder')"
                              @change="updatePeriodicity" />
                <span v-if="amount !== ''"
                      class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col col--sm-6">
          <div class="form__fieldset__block">
            <div class="item-left">
              <ic-card periodicity
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('general.periodicity') }}</span>
            </div>
            <div class="item-right">
              <component-dropdown :disabled="disabled"
                                  :dropdown-height="21"
                                  dropdown-width="100%"
                                  :model="activePeriod"
                                  :values="cardPeriods"
                                  label="periodicity"
                                  @select="onChangePeriod">
                <template #trigger>
                  <div class="select">
                    <div class="form-control form-control--sm">
                      {{ $t(`generate_ecard.choice.recurring.type.${activePeriod}`) }}
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="display-monthly-limit">
        <div class="row">
          <div class="col col--sm">
            <div class="form__fieldset__block">
              <div class="item-left">
                <div :class="{ 'text-muted': !settings.display_limit }"
                     class="switch-btn-label">
                  {{ $t('cards_settings.limit.display_limit') }}
                </div>
              </div>
              <div class="item-right">
                <toggle-switch :model-value="settings.display_limit"
                               :disabled="disabled"
                               :toggle-class="'switch-btn--sm'"
                               @update:model-value="value => updateCardSettings('display_limit', value)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import NumberInput from '@/components/NumberInput.vue'
import IcCard from '@/components/svg/icons/ic-card.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcCard,
    ComponentDropdown,
    NumberInput,
    ToggleSwitch,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  data () {
    return {
      periods: ['daily', 'weekly', 'monthly', 'annually'],
      activePeriod: null,
      amount: null,
      payment: []
    }
  },

  computed: {
    cardPeriods () {
      return [
        {
          id: 'daily',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.daily')
        },
        {
          id: 'weekly',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.weekly')
        },
        {
          id: 'monthly',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.monthly')
        },
        {
          id: 'annually',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.annually')
        }
      ]
    }
  },

  created () {
    for (const period in this.settings.payment) {
      if (this.settings.payment[period].enabled) {
        this.activePeriod = period
        this.amount = this.settings.payment[period].limit
      }
    }
    this.payment = this.settings.payment
  },

  methods: {
    getCurrencySymbol,

    onChangePeriod (value) {
      this.activePeriod = value.id
      this.updatePeriodicity()
    },

    updatePeriodicity () {
      const amount = parseFloat(this.amount)
      if (isNaN(amount) || amount <= 0) { return }

      for (const period in this.settings.payment) {
        this.payment[period].enabled = period === this.activePeriod
        this.payment[period].limit = period === this.activePeriod ? amount : null
      }

      this.updateCardSettings('payment', this.payment)
    },

    updateCardSettings (key, value) {
      this.$emit('update-card-settings', {
        [key]: value
      })
    }
  }
}
</script>
