<template>
  <div class="content-wrapper">
    <form class="form form--w780 form--centered">
      <group-title :title="$t('pages.preferences.display_sections.title')"
                   :subtitle="$t('pages.preferences.display_sections.subtitle')" />
      <div class="bordered-box bordered-box--padding">
        <table class="table">
          <tr v-for="(item, index) in sections"
              :key="index">
            <td class="col-fit-content px-1">
              <ic-section v-bind="{ [item]: true }"
                          :class="{ active: displaySections[item] }"
                          class="ic ic-section" />
            </td>
            <td :class="[displaySections[item] ? 'strong' : 'text-muted']">
              {{ $t(`menu.${item}`) }}
            </td>
            <td class="col--sm-3 right">
              <span :class="{ 'switch-btn-label--off': !displaySections[item] }"
                    class="switch-btn-label">
                {{ displaySections[item] ? $t('button.visible') : $t('button.hidden') }}
              </span>
              <toggle-switch v-model="account.display_sections[item]"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="onUpdateDisplaySettings(item)" />
            </td>
          </tr>
        </table>
      </div>
    </form>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'

import GroupTitle from '@/components/GroupTitle.vue'
import IcSection from '@/components/svg/icons/ic-section.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcSection,
    GroupTitle,
    ToggleSwitch
  },

  setup () {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()

    const { account, displayMenuPreference, displaySections } = storeToRefs(accountStore)

    return { account, authStore, displayMenuPreference, displaySections }
  },

  computed: {
    sections () {
      return Object.keys(this.displaySections)
    }
  },

  mounted () {
    this.init()
    this.$bus.on('account-switched', this.init)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.init)
  },

  methods: {
    init () {
      if (!this.displayMenuPreference) {
        this.$router.push({ name: 'account-settings-notifications' })
      }
    },

    async onUpdateDisplaySettings (item) {
      this.loading = true
      const success = await this.authStore.updateDisplaySettings(item, this.displaySections[item])
      if (!success) this.account.display_sections[item] = !this.account.display_sections[item]
      this.loading = false
    }
  }
}
</script>
