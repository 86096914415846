<template>
  <div v-show="hasSettingField('proof_required') && hasSettingField('proof_required_delay')"
       class="form__fieldset card-advanced-proofs">
    <group-title :title="$t('card.security.mandatory_proof.title')"
                 :subtitle="$t('card.security.mandatory_proof.content_settings')" />
    <div class="form__fieldset__group">
      <div class="proof-delay">
        <div class="row">
          <div class="col col--sm">
            <div class="bordered-box">
              <div class="form__fieldset__block bordered-box__item mb-0">
                <div class="item-left">
                  <toggle-switch :model-value="settings.proof_required"
                                 :disabled="apiLoading"
                                 :toggle-class="'switch-btn--sm'"
                                 @update:model-value="value => updateCardSettings('proof_required', value)" />
                  <span class="switch-btn-label">
                    {{ $t('cards_settings.proof_delay.title') }}
                    <span v-tooltip="{ content: $t('poptip.mandatory_proof'), theme: 'poptip' }"
                          class="ic-helper ml-1">
                      <ic-info outline
                               class="ic ic--gray" />
                    </span>
                  </span>
                </div>
                <div v-show="settings.proof_required"
                     class="item-right">
                  <span class="mr-2">{{ $t('cards_settings.proof_delay.choice.label') }}</span>
                  <component-dropdown :value="settings.proof_required_delay"
                                      :dropdown-width="'100%'"
                                      :model="settings.proof_required_delay"
                                      async-url="/cards/proof-required/delays"
                                      label="delay"
                                      has-default
                                      bind-value
                                      :can-reset-value="false"
                                      @select="changeDelay">
                    <template #trigger>
                      <div class="dropdown-toggle select"
                           style="width: 18rem;">
                        <div class="form-control form-control--sm">
                          <span>{{ settings.proof_required_delay_label }}</span>
                        </div>
                      </div>
                    </template>
                  </component-dropdown>
                </div>
              </div>
              <div v-if="settings.proof_required"
                   class="bordered-box__item bordered-box__item--sm">
                <i18n-t tag="span"
                        keypath="cards_settings.proof_delay.description.text"
                        scope="global">
                  <template #delay>
                    <strong v-if="settings.proof_required_delay === 'instant'"
                            class="lowercase">{{ settings.proof_required_delay_label }}</strong>
                    <strong v-else>{{ $t('cards_settings.proof_delay.description.within', { delay: settings.proof_required_delay_label }) }}</strong>
                  </template>
                </i18n-t>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useApiStore } from '@/stores/api'

import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcInfo,
    ComponentDropdown,
    GroupTitle,
    ToggleSwitch
  },

  props: {
    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const apiStore = useApiStore()

    const { loading: apiLoading } = storeToRefs(apiStore)

    return {
      apiLoading
    }
  },

  methods: {
    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    },

    updateCardSettings (key, value) {
      this.$emit('update-card-settings', {
        [key]: value
      })
    },

    changeDelay (delay) {
      this.$emit('update-card-settings', {
        proof_required_delay: delay.value,
        proof_required_delay_label: delay.label
      })
    }
  }
}
</script>
