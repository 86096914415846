<template>
  <svg viewBox="0 0 200 160">
    <path d="m120.2 39.749856h-40.4c-3.1734509-.0220658-5.7670201 2.5267868-5.8 5.700144v90.1c.0329799 3.173357 2.6265491 5.72221 5.8 5.700144h40.4c3.173451.022066 5.76702-2.526787 5.8-5.700144v-90.1c-.03298-3.1733572-2.626549-5.7222098-5.8-5.700144zm0 1.4999482c2.345059-.0220557 4.267075 1.8552625 4.3 4.2001958v90.1c-.032925 2.344933-1.954941 4.222251-4.3 4.200196h-40.4c-2.3450589.022055-4.2670751-1.855263-4.3-4.200196v-90.1c.0329249-2.3449333 1.9549411-4.2222515 4.3-4.2001958l10.3687351.0000478c.6150007.3411406 1.0312649.9970324 1.0312649 1.750148 0 .9664983.7835017 1.75 1.75 1.75h14.1c.966498 0 1.75-.7835017 1.75-1.75l.005486-.1492623c.050899-.6899066.451778-1.2824878 1.025779-1.6008857z"
          :fill="colorDark" />
    <path d="m151.56 34.4-15 11 .89 1.21 15-11zm-50.06-25.4h-1.5v18h1.5zm62.38 52.26-18 3 .25 1.48 18-3zm-128 1.48 18 3 .25-1.48-18-3zm11.68-27.14 15 11 .89-1.21-15-11zm22.77-19.27 8 16 1.34-.67-8-16zm50 15.33 1.34.67 8-16-1.34-.67z"
          :fill="colorGrey" />
    <path v-if="success"
          d="m100 73.75c-9.5269119 0-17.25 7.7230881-17.25 17.25 0 9.526912 7.7230881 17.25 17.25 17.25 9.526912 0 17.25-7.723088 17.25-17.25-.011021-9.5223431-7.727657-17.2389788-17.25-17.25zm0 29c-3.9148229-.005105-7.552187-2.022085-9.63-5.34l1.26-.82c1.7965476 2.8957372 4.9622328 4.657159 8.37 4.657159 3.407767 0 6.573452-1.7614218 8.37-4.657159l1.26.82c-2.077813 3.317915-5.715177 5.334895-9.63 5.34zm0 7c-10.3553391 0-18.75-8.394661-18.75-18.75 0-10.3553391 8.3946609-18.75 18.75-18.75 10.355339 0 18.75 8.3946609 18.75 18.75-.011023 10.35077-8.39923 18.738977-18.75 18.75z"
          :fill="colorPrimary" />
    <path v-else-if="problem"
          d="m100 73.75c-9.5269119 0-17.25 7.7230881-17.25 17.25 0 9.526912 7.7230881 17.25 17.25 17.25 9.526912 0 17.25-7.723088 17.25-17.25-.011021-9.5223431-7.727657-17.2389788-17.25-17.25zm0 22.84c3.914823.0051047 7.552187 2.0220854 9.63 5.34l-1.26.82c-1.796548-2.8957372-4.962233-4.6571588-8.37-4.6571588-3.4077672 0-6.5734524 1.7614216-8.37 4.6571588l-1.26-.82c2.077813-3.3179146 5.7151771-5.3348953 9.63-5.34zm0 13.16c-10.3553391 0-18.75-8.394661-18.75-18.75 0-10.3553391 8.3946609-18.75 18.75-18.75 10.355339 0 18.75 8.3946609 18.75 18.75-.011023 10.35077-8.39923 18.738977-18.75 18.75z"
          :fill="colorPrimary" />
    <path v-else-if="code"
          d="m111.746 80c2.35 0 4.254 1.875 4.254 4.189l0 13.958-.006.192c-.118 2.228-1.994 3.988-4.278 3.971l-4.761-.027-5.855 4.717.063-4.665-12.909.001c-2.35 0-4.254-1.875-4.254-4.189l0-13.958c0-2.314 1.904-4.189 4.254-4.189l23.492 0zm0 1.323-23.492 0c-1.608 0-2.911 1.283-2.911 2.866l0 13.958c0 1.583 1.303 2.866 2.911 2.866l14.27 0-.043 3.165 3.998-3.22 5.246.03c1.608.011 2.92-1.263 2.932-2.846l0-13.953c0-1.583-1.303-2.866-2.911-2.866zm-19.126 6.952-.056 1.852 1.598-.982.621 1.038-1.674.888 1.674.871-.621 1.037-1.598-.963.056 1.852-1.204 0 .057-1.871-1.617.982-.602-1.037 1.655-.871-1.655-.888.602-1.038 1.617 1-.057-1.87 1.204 0zm7.944 0-.056 1.852 1.598-.982.621 1.038-1.674.888 1.674.871-.621 1.037-1.598-.963.056 1.852-1.203 0 .056-1.871-1.617.982-.602-1.037 1.655-.871-1.655-.888.602-1.038 1.617 1-.056-1.87 1.203 0zm7.944 0-.056 1.852 1.598-.982.621 1.038-1.674.888 1.674.871-.621 1.037-1.598-.963.056 1.852-1.203 0 .056-1.871-1.617.982-.602-1.037 1.655-.871-1.655-.888.602-1.038 1.617 1-.056-1.87 1.203 0z"
          :fill="colorPrimary" />
    <g v-else-if="sca">
      <path d="m110 97.75v1.5h-20v-1.5zm3-5v1.5h-26v-1.5zm-4-5v1.5h-18v-1.5zm4-5v1.5h-26v-1.5zm50.88-21.49.25 1.48-18 3-.25-1.48zm-127.75 0 18 3-.25 1.48-18-3zm115.43-26.86.89 1.21-15 11-.89-1.21zm-103.11-.01 15 11-.89 1.21-15-11zm23.22-18.73 8 16-1.34.67-8-16zm56.66 0 1.34.67-8 16-1.34-.67zm-26.83-6.66v18h-1.5v-18z"
            :fill="colorGrey" />
      <rect :fill="colorLightGrey"
            height="6"
            rx="1"
            width="18"
            x="82"
            y="127" />
      <rect :fill="colorPrimary"
            height="6"
            rx="1"
            width="18"
            x="101"
            y="127" />
    </g>
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    code: {
      type: Boolean,
      default: false
    },

    success: {
      type: Boolean,
      default: false
    },

    problem: {
      type: Boolean,
      default: false
    },

    sca: {
      type: Boolean,
      default: false
    },

    colorLightGrey: {
      type: String,
      default: '#d5d7dd'
    },

    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
