<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="users"
                          :dropdown-width="'100%'"
                          :dropdown-height="24"
                          :search="false"
                          :can-add-item="false"
                          :values="values"
                          :label-filter="reliabilityFilter"
                          :model="selectedReliability.id"
                          @select="onSelectReliability">
        <template #trigger>
          <div class="select"
               :class="modelValue ? 'is-clearable' : 'select--off'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue">
                {{ reliabilityFilter(selectedReliability) }}
              </template>
              <template v-else>
                {{ $t('form.reliability.placeholder') }}
              </template>
            </div>
            <button v-if="modelValue"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$emit('clear-field', 'reliability')">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },

  emits: [
    'clear-field',
    'update:modelValue'
  ],

  data () {
    return {
      selectedReliability: {}
    }
  },

  computed: {
    values () {
      return [
        {
          label: this.$i18n.t('form.reliability.filter.high'),
          value: 'high',
          id: 1
        },
        {
          label: this.$i18n.t('form.reliability.filter.medium'),
          value: 'medium',
          id: 2
        },
        {
          label: this.$i18n.t('form.reliability.filter.low'),
          value: 'low',
          id: 3
        },
        {
          label: this.$i18n.t('form.reliability.filter.none'),
          value: 'none',
          id: 4
        }
      ]
    }
  },

  watch: {
    modelValue () {
      if (!this.modelValue) {
        this.selectedReliability = {}
      }
    }
  },

  methods: {
    reliabilityFilter (reliability) {
      return reliability.label
    },

    onSelectReliability (reliability) {
      this.selectedReliability = reliability
      this.$emit('update:modelValue', reliability.value)
      this.$parent.onSubmit()
    }
  }
}
</script>
