<template>
  <div class="sidepanel__content"
       data-cy="periodic-transfers.upcoming-transfers.panel">
    <div class="sidepanel-header">
      <button v-if="!formView"
              type="button"
              class="sidepanel-double__close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close">
        <ic-close class="ic ic--18" />
      </button>
      <h2 class="panel-title">
        {{ formView ? $t('upcoming_transfers.sidepanel.title') : $t('upcoming_transfers.sidepanel.to_come.title') }}
      </h2>
    </div>
    <div class="sidepanel-body">
      <loader-spinner v-if="loading"
                      class="c c--vh" />
      <template v-else>
        <component-timeline v-if="upcomingTransfers.length"
                            type="block"
                            :title="endDate ? $tc('upcoming_transfers.timeline.count', upcomingTransfersCount.active) : null"
                            :subtitle="upcomingTransfersCount.deleted ? $tc('upcoming_transfers.timeline.deleted_count', upcomingTransfersCount.deleted) : null">
          <template #body>
            <component-timeline-item v-for="(upcomingTransfer, index) in upcomingTransfers"
                                     :id="index"
                                     :key="index"
                                     type="block"
                                     :title="upcomingTransfer.label"
                                     :subtitle="upcomingTransfer.desc"
                                     :top-right-label="upcomingTransfer.time"
                                     :cancelled="upcomingTransfer.status === 'cancelled'">
              <template #actions>
                <template v-if="upcomingTransfer.status === 'cancelled'">
                  <core-badge :fill="ECoreBadgeFill.Shaded"
                              :theme="ECoreBadgeTheme.GrayLow"
                              :value="$t('upcoming_transfers.status.deleted')" />
                  <button v-tooltip="{ content: $t('tooltip.restore_deadline'), theme: 'tooltip' }"
                          class="btn-link"
                          :data-cy="`periodic-transfers.upcoming-transfers.deadline-${upcomingTransfer.id}.restore`"
                          :disabled="actionLoading"
                          @click="restoreUpcomingTransfer(upcomingTransfer.id)">
                    <ic-reset class="ic ic--20 ic--gray" />
                  </button>
                </template>
                <button v-else
                        v-tooltip="{ content: $t('tooltip.delete_deadline'), theme: 'tooltip' }"
                        class="btn-link"
                        :data-cy="`periodic-transfers.upcoming-transfers.deadline-${upcomingTransfer.id}.cancel`"
                        :disabled="actionLoading"
                        @click="cancelUpcomingTransfer(upcomingTransfer.id)">
                  <ic-remove class="ic ic--20 ic--gray" />
                </button>
              </template>
            </component-timeline-item>
          </template>
        </component-timeline>
        <next-page-button v-if="hasNextPage"
                          :next-page-loading="nextPageLoading"
                          @btn-click="getNextPage" />
        <component-placeholder v-if="upcomingTransfers.length === 0"
                               data-cy="periodic-transfers.upcoming-transfers.placeholder"
                               :label="$t('upcoming_transfers.placeholder.empty')"
                               class="c c--vh" />
      </template>
    </div>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useTransferStore } from '@/stores/transfer'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import NextPageButton from '@/components/NextPageButton.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcClose from '@/components/svg/icons/ic-close.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import IcReset from '@/components/svg/icons/ic-reset.vue'
import ComponentTimeline from '@/components/timeline/Timeline.vue'
import ComponentTimelineItem from '@/components/timeline/TimelineItem.vue'

export default {
  components: {
    CoreBadge,
    NextPageButton,
    LoaderSpinner,
    ComponentPlaceholder,
    ComponentTimeline,
    IcClose,
    IcRemove,
    IcReset,
    ComponentTimelineItem

  },

  props: {
    amount: {
      type: Number,
      required: true
    },

    beneficiaryLabel: {
      type: String,
      required: true
    },

    currency: {
      type: Object,
      required: true
    },

    endDate: {
      type: String,
      default: ''
    },

    initialDeletedDeadlines: {
      type: Array,
      required: true
    },

    period: {
      type: String,
      required: true
    },

    startDate: {
      type: String,
      required: true
    },

    formView: {
      type: Boolean,
      default: true
    },

    transferUuid: {
      type: String,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const transferStore = useTransferStore()

    const { permanentTransfers, transfers, upcomingTransfersCount, upcomingTransfersPagination } = storeToRefs(transferStore)

    return {
      appStore,
      permanentTransfers,
      transfers,
      transferStore,
      upcomingTransfersCount,
      upcomingTransfersPagination
    }
  },

  data: () => ({
    deletedDeadlines: [],
    loading: true,
    actionLoading: false,
    nextPageLoading: false,
    store,
    upcomingTransfers: []
  }),

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    hasNextPage () {
      return this.upcomingTransfersPagination.current < this.upcomingTransfersPagination.count
    }
  },

  async created () {
    this.deletedDeadlines = cloneDeep(this.initialDeletedDeadlines)
    await this.getData()
    this.loading = false
  },

  methods: {
    async cancelUpcomingTransfer (id) {
      this.actionLoading = true
      const transferToCancel = this.upcomingTransfers.find(elem => elem.id === id)

      if (!this.formView) {
        const success = await this.transferStore.cancelDate(this.transferUuid, transferToCancel.execution_date)
        if (!success) return
      }

      transferToCancel.status = 'cancelled'
      this.deletedDeadlines.push(transferToCancel.execution_date)
      this.transferStore.updateUpcomingTransfersCount(
        this.upcomingTransfersCount.active - 1,
        this.upcomingTransfersCount.deleted + 1
      )

      this.$bus.emit('update-deleted-deadlines', this.deletedDeadlines)
      this.actionLoading = false
    },

    async getData (page = 1) {
      const data = await this.transferStore.getUpcomingTransfers({
        deleted_deadlines: this.deletedDeadlines,
        from: this.startDate,
        to: this.endDate,
        period: this.period,
        page
      })

      if (data) {
        const transfers = data.map((object, index) => {
          return {
            id: index + this.upcomingTransfers.length,
            execution_date: object.execution_date,
            label: `${dayjs(object.execution_date).format('D MMMM YYYY')}`,
            desc: this.$t('upcoming_transfers.timeline.description', {
              amount: formatAmount(this.amount, this.currency),
              name: this.beneficiaryLabel
            }),
            status: object.status,
            status_label: object.status_label
          }
        })
        this.upcomingTransfers = this.upcomingTransfers.concat(transfers)
      }
    },

    async getNextPage () {
      this.nextPageLoading = true
      const page = parseInt(this.upcomingTransfersPagination.current) + 1
      await this.getData(page)
      this.nextPageLoading = false
    },

    async restoreUpcomingTransfer (id) {
      this.actionLoading = true
      const transferToRestore = this.upcomingTransfers.find(elem => elem.id === id)

      if (!this.formView) {
        const success = await this.transferStore.restoreDate(this.transferUuid, transferToRestore.execution_date)
        if (!success) return
      }

      transferToRestore.status = 'pending'
      this.deletedDeadlines = this.deletedDeadlines.filter(deadline => deadline !== transferToRestore.execution_date)
      this.transferStore.updateUpcomingTransfersCount(
        this.upcomingTransfersCount.active + 1,
        this.upcomingTransfersCount.deleted - 1
      )

      this.$bus.emit('update-deleted-deadlines', this.deletedDeadlines)
      this.actionLoading = false
    },

    close () {
      this.formView ? this.appStore.closeSidePanel() : this.$emit('close')
    }
  }

}
</script>
