<template>
  <footer class="footer"
          :class="{ 'section-login__footer': onLoginPage }">
    <div class="footer-left">
      <p class="footer-copyright">
        <span>{{ $t("footer.copyright", { year }) }}</span>
      </p>
    </div>
    <div class="footer-right">
      <ul class="footer-links">
        <li v-if="hasContactFooter">
          <a href="#"
             @click.prevent.stop="showContactForm">{{ $t("footer.contact") }}</a>
        </li>
        <li v-if="hasCookiesFooter">
          <a href="#"
             @click.prevent.stop="onOpenIframe(links.COMMERCIAL.COOKIES)">{{ $t("footer.cookies") }}
          </a>
        </li>
        <li v-if="hasCardBlockFooter">
          <a href="#"
             @click.prevent.stop="onOpenIframe(links.COMMERCIAL.BLOCK_CARD)">{{ $t("footer.block_card") }}
          </a>
        </li>
        <li v-if="hasLegalNoticesFooter">
          <a href="#"
             @click.prevent.stop="onOpenIframe(links.COMMERCIAL.LEGAL_NOTICES)">{{ $t("footer.legal_notice") }}</a>
        </li>
        <li v-if="hasTermsFooter">
          <a href="#"
             @click.prevent.stop="onOpenIframe(links.COMMERCIAL.TERMS)">{{ $t("footer.terms") }}</a>
        </li>
        <li v-if="hasPricingFooter">
          <a href="#"
             @click.prevent.stop="onOpenIframe(links.COMMERCIAL.PRICING)">{{ $t("footer.pricing") }}</a>
        </li>
        <li v-if="hasMultipleLanguages"
            class="footer-lang">
          <language-popover v-if="!user.loggedIn" />
          <a v-else
             class="footer-lang-button"
             @click="displayLanguageModal">
            {{ getLanguageName($i18n.locale) }}
            <ic-chevron down
                        class="ic ic--14 ic--gray ic--down" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import config from '@/config/config'
import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import { getLanguageName } from '@/helpers/utils/intl'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import Contact from '@/components/modals/Contact.vue'
import ComponentIframe from '@/components/modals/Iframe.vue'
import LanguageSelection from '@/components/modals/LanguageSelection.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'

import LanguagePopover from './LanguagePopover.vue'

export default {
  name: 'ComponentFooter',

  components: {
    IcChevron,
    LanguagePopover
  },

  props: {
    onLoginPage: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { user } = storeToRefs(authStore)

    return {
      appStore,
      user
    }
  },

  data () {
    return {
      year: dayjs().format('YYYY'),
      links
    }
  },

  computed: {
    hasContactFooter () {
      return hasFeature(EFeature.FooterContact)
    },

    hasCookiesFooter () {
      return hasFeature(EFeature.FooterCookies) && config.isCookiePolicyIframeType()
    },

    hasCardBlockFooter () {
      return hasFeature(EFeature.CardBlockFooter)
    },

    hasLegalNoticesFooter () {
      return hasFeature(EFeature.FooterLegal)
    },

    hasTermsFooter () {
      return hasFeature(EFeature.FooterTerms)
    },

    hasPricingFooter () {
      return hasFeature(EFeature.FooterPricing)
    },

    hasMultipleLanguages () {
      return config.TRANSLATIONS.AVAILABLE_LANGUAGES.length > 1
    }
  },

  methods: {
    getLanguageName,

    showContactForm () {
      this.appStore.showModal(Contact, { isContact: !this.user.loggedIn }, { wrapperClass: 'modal--md' })
    },

    displayLanguageModal () {
      this.appStore.showModal(LanguageSelection, {}, { wrapperClass: 'modal--xs' })
    },

    onOpenIframe (source) {
      this.appStore.showModal(
        ComponentIframe,
        { source },
        { wrapperClass: 'modal--iframe modal--fullscreen modal--white' }
      )
    }
  }
}

</script>
