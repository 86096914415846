<template>
  <svg viewBox="0 0 24 24">
    <path d="M11 13v5H6.5a.5.5 0 0 1-.5-.5V13zm7 0v4.5a.5.5 0 0 1-.5.5H13v-5zm-7-7v5H6V6.5a.5.5 0 0 1 .5-.5zm6.5 0a.5.5 0 0 1 .5.5V11h-5V6z" />
  </svg>
</template>

<script>
export default {
}
</script>
