<template>
  <svg viewBox="0 0 72 72">
    <rect x=".171"
          width="72"
          height="72"
          rx="18"
          fill="#F5F6F9" />
    <g v-if="user">
      <circle cx="36.26"
              cy="36.089"
              r="18.089"
              fill="#B4B9C3" />
      <circle cx="36.26"
              cy="36.089"
              r="16"
              fill="#B4B9C3" />
      <mask id="ic-square/mask/a"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="20"
            y="20"
            width="33"
            height="33">
        <circle cx="36.26"
                cy="36.089"
                r="16"
                fill="#fff" />
      </mask>
      <g mask="url(#ic-square/mask/a)">
        <path d="M30.45 32.8a5.756 5.756 0 1 1 11.512.001 5.756 5.756 0 0 1-11.513-.001Zm5.4 21.498c7.946 0 14.388-2.76 14.388-6.166 0-3.406-6.442-6.167-14.389-6.167-7.946 0-14.389 2.761-14.389 6.167 0 3.406 6.443 6.166 14.39 6.166Z"
              fill="#F5F6F9" />
      </g>
      <path d="M51.06 18.644a5.112 5.112 0 1 0 .001 10.224 5.112 5.112 0 0 0 0-10.224Z"
            fill="#707989"
            stroke="#F5F6F9"
            stroke-width="2" />
    </g>
    <g v-else-if="transfer">
      <path d="M27.024 34.97a.853.853 0 0 1-.853-.852v-4.295c0-.47.382-.852.853-.852h10.294c.471 0 .853.381.853.852v4.295a.853.853 0 0 1-.853.853H27.024Z"
            fill="#B4B9C3" />
      <path d="m18.171 32.193 10-8.708v17l-10-8.292Z"
            fill="#B4B9C3"
            stroke="#B4B9C3"
            stroke-width="1.5"
            stroke-linejoin="round" />
      <path d="M45.318 44a.853.853 0 0 0 .853-.853v-4.294a.853.853 0 0 0-.853-.853H34.024a.853.853 0 0 0-.853.853v4.294c0 .471.382.853.853.853h11.294Z"
            fill="#707989" />
      <path d="m53.407 41.193-10-8.708v17l10-8.292Z"
            fill="#707989"
            stroke="#707989"
            stroke-width="1.5"
            stroke-linejoin="round" />
    </g>
    <g v-else-if="statusCertified">
      <path d="M26.171 20a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2H39.63a9.994 9.994 0 0 1-.458-3c0-5.523 4.477-10 10-10 .338 0 .672.017 1 .05V28h-6a2 2 0 0 1-2-2v-6h-16Z"
            fill="#D5D7DD" />
      <circle cx="49.171"
              cy="49"
              r="8"
              fill="#707989" />
      <path d="m48.002 50.425-1.77-1.304c-.56-.458-1.298-.032-.9.655l1.864 2.488c.247.312.799.617 1.365 0l4.459-5.784c.552-.61-.167-1.145-.646-.687l-4.372 4.632Z"
            fill="#F5F6F9" />
      <path d="m42.171 20 8 8h-6a2 2 0 0 1-2-2v-6Zm-13 15a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2h-11a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Z"
            fill="#979EAC" />
    </g>
    <g v-else-if="documents">
      <path d="M23.171 22a2 2 0 0 0-2 2v26a4 4 0 0 0 4 4h20a2 2 0 0 0 2-2h-22a2 2 0 0 1-2-2V22Z"
            fill="#B4B9C3" />
      <path d="M25.171 20a2 2 0 0 1 2-2h16v6a2 2 0 0 0 2 2h6v22a2 2 0 0 1-2 2h-22a2 2 0 0 1-2-2V20Z"
            fill="#D5D7DD" />
      <path d="M30.171 33a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2h-11a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Z"
            fill="#707989" />
      <path d="m43.171 18 8 8h-6a2 2 0 0 1-2-2v-6Z"
            fill="#979EAC" />
    </g>
    <g v-else-if="immatriculationKbis">
      <defs>
        <rect id="ic-square/rect/a"
              width="4"
              height="4"
              rx=".5" />
      </defs>

      <path d="M20.171 20.571A2.571 2.571 0 0 1 22.743 18H35.6a2.571 2.571 0 0 1 2.571 2.571V50h-18V20.571Z"
            fill="#D5D7DD" />
      <g fill="#B4B9C3">
        <path d="M40.171 25h9.3a2.7 2.7 0 0 1 2.7 2.7V50h-12V25Z" />
        <path d="M16.171 51a1 1 0 0 1 1-1h38a1 1 0 0 1 1 1v1h-40v-1Z" />
      </g>

      <g fill="#979EAC">
        <use href="#ic-square/rect/a"
             x="24.171"
             y="24" />
        <use href="#ic-square/rect/a"
             x="24.171"
             y="30" />
        <use href="#ic-square/rect/a"
             x="24.171"
             y="36" />
        <use href="#ic-square/rect/a"
             x="24.171"
             y="42" />
        <use href="#ic-square/rect/a"
             x="30.171"
             y="24" />
        <use href="#ic-square/rect/a"
             x="30.171"
             y="30" />
        <use href="#ic-square/rect/a"
             x="30.171"
             y="36" />
        <use href="#ic-square/rect/a"
             x="30.171"
             y="42" />
      </g>

      <g fill="#F5F6F9">
        <use href="#ic-square/rect/a"
             x="44.171"
             y="30" />
        <use href="#ic-square/rect/a"
             x="44.171"
             y="36" />
        <use href="#ic-square/rect/a"
             x="44.171"
             y="42" />
      </g>
    </g>
    <g v-else-if="depotCapitalCertificate">
      <path d="M47.5 20h-23a1.5 1.5 0 0 0-1.5 1.5v29a1.5 1.5 0 0 0 1.5 1.5h23a1.5 1.5 0 0 0 1.5-1.5v-29a1.5 1.5 0 0 0-1.5-1.5z"
            fill="#d4d7dd" />
      <path d="m45.68 56-4-5.4c1.1-1.2 1.7-2.8 1.7-4.6 0-3.9-3.1-7-7-7s-7 3.1-7 7c0 1.8.7 3.4 1.7 4.6l-4 5.4c-.2.2 0 .6.3.5l3.6-1c.2 0 .4.1.4.2l1 3.7c.1.3.5.3.6 0l2.7-6.4h1.2l2.7 6.4c.1.3.5.3.6 0l1-3.7c0-.2.2-.3.4-.2l3.6 1c.5.1.7-.2.5-.5zm-14.2-9.9c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z"
            fill="#979eac" />
      <path d="M36.48 43.1c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
            fill="#707989" />
      <path d="M43 34a1 1 0 1 1 0 2H29a1 1 0 1 1 0-2zm0-4a1 1 0 1 1 0 2H29a1 1 0 1 1 0-2zm0-4a1 1 0 1 1 0 2H29a1 1 0 1 1 0-2z"
            fill="#fff" />
    </g>
    <g v-else-if="depotCapital">
      <rect x="20.171"
            y="30"
            width="32"
            height="21"
            rx="2"
            fill="#D5D7DD" />
      <path d="M26.171 23a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14l-9.966 4-10.034-4V23Z"
            fill="#979EAC" />
      <path d="M35.629 30.555v1.183c0 .377.104.676.325.897.207.234.48.351.806.351.325 0 .598-.117.806-.351.207-.221.311-.52.311-.897v-.702h1.287v.702c0 .351-.065.676-.181.975a2.358 2.358 0 0 1-.494.767 2.229 2.229 0 0 1-.767.52 2.586 2.586 0 0 1-.962.182 2.47 2.47 0 0 1-.95-.182 2.062 2.062 0 0 1-.754-.52 2.196 2.196 0 0 1-.506-.767 2.655 2.655 0 0 1-.182-.975v-1.183h-.806v-.91h.806v-.78h-.806v-.91h.806v-.949c0-.351.064-.663.181-.962.117-.299.287-.546.508-.767a2.02 2.02 0 0 1 .753-.507 2.47 2.47 0 0 1 .95-.182c.35 0 .663.065.962.182.298.117.545.286.767.507.208.221.377.468.494.767.117.299.181.611.181.962v.533h-1.287v-.533c0-.364-.104-.663-.312-.884a1.069 1.069 0 0 0-.805-.338c-.325 0-.599.117-.806.338-.221.221-.325.52-.325.884v.949h1.95v.91h-1.95v.78h1.95v.91h-1.95Z"
            fill="#F5F6F9" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    user: Boolean,
    transfer: Boolean,
    statusCertified: Boolean,
    documents: Boolean,
    immatriculationKbis: Boolean,
    depotCapitalCertificate: Boolean,
    depotCapital: Boolean
  }
}
</script>
