<template>
  <div class="group-title">
    <h4 class="title">
      {{ title }}
    </h4>
    <p v-if="subtitle"
       class="subtitle">
      {{ subtitle }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
