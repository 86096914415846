<template>
  <div class="loader-spinner"
       :style="getStyle">
    <svg viewBox="0 0 38 38"
         class="spinner"
         :class="getColorClass">
      <g fill="none"
         fill-rule="evenodd">
        <g transform="translate(1 1)"
           stroke-width="2">
          <circle stroke="white"
                  stroke-opacity="0.1"
                  cx="18"
                  cy="18"
                  r="18"
                  class="spinner-bg" />
          <path d="M36 18c0-9.94-8.06-18-18-18"
                stroke="white"
                stroke-opacity="1"
                class="spinner-fg" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: '32'
    }
  },

  computed: {
    getColorClass () {
      return this.color ? `spinner--${this.color}` : ''
    },

    getLoaderSize () {
      return `${this.size}px`
    },

    getStyle () {
      return `width: ${this.getLoaderSize}; height: ${this.getLoaderSize};`
    }
  }
}
</script>

<style lang="stylus" scoped>
.loader-spinner
  width 32px
  height @width
  display flex
  margin 1rem auto

  &--no-marge
    display flex
    margin auto

  > svg
    width 100%
    height @width

  + .loader-message
    margin-top 1.5rem

  .spinner
    animation 1s linear infinite rotate

@keyframes rotate {
  from {
    transform rotate(0)
  }
  to {
    transform rotate(360deg)
  }
}
</style>
