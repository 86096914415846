<template>
  <svg viewBox="0 0 24 24">
    <path d="M19 3a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-6.686L6 22.42V18H5a3 3 0 0 1-2.995-2.824L2 15V6a3 3 0 0 1 3-3zm0 2H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v2.578L11.685 16H19a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zm-5.5 6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm4-3a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
