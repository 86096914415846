<template>
  <section class="section section-sponsorship">
    <div class="section__content">
      <div class="referral-section">
        <div class="referral-block">
          <div class="referral-section-title">
            <h2>
              {{ $t("sponsorship.title") }}<br>
              <span class="text-secondary">{{ $t("sponsorship.subtitle") }}</span>
            </h2>
          </div>
          <p class="text-muted pre-line">
            {{ $t('sponsorship.content') }}
          </p>
          <validation-form ref="form"
                           v-slot="{ errors, isSubmitting }"
                           class="form"
                           :validation-schema="schema"
                           @submit="onSubmit"
                           @invalid-submit="onInvalidSubmit">
            <div class="form__row row">
              <div class="form-group col col--sm--8"
                   :class="{ error: errors.email && formSubmitted }">
                <div class="input-group">
                  <validation-field id="email"
                                    v-model="email"
                                    class="form-control"
                                    type="text"
                                    :placeholder="$t('form.email.placeholder_sponsorship')"
                                    name="email" />
                  <button class="btn btn--default"
                          :disabled="isSubmitting">
                    {{ $t("button.invite") }}
                  </button>
                </div>
              </div>
            </div>
            <p class="text-muted sponsorship-condition">
              {{ $t("sponsorship.text") }}
            </p>
          </validation-form>
        </div>
        <div v-if="sponsors.length"
             class="referral-panel">
          <h3 class="uppercase-title text-muted">
            {{ $t("title.sponsorship_table") }}
          </h3>
          <div class="bordered-box bordered-box--padding">
            <table class="table">
              <tbody v-for="sponsor in sponsors"
                     :key="sponsor.id">
                <tr>
                  <td width="100"
                      class="text-muted nowrap">
                    {{ formatDate(sponsor.created_at) }}
                  </td>
                  <td width="288">
                    {{ sponsor.email }}
                  </td>
                  <template v-if="!disabledSponsor.includes(sponsor.state)">
                    <td width="200">
                      {{ sponsor.state_label }}
                    </td>
                    <td width="130"
                        class="center">
                      <button class="btn-link btn-link--upper"
                              @click.prevent.stop="sendSponsorRequest(sponsor.email)">
                        <span class="text-secondary">{{ $t("button.revive") }}</span>
                      </button>
                    </td>
                  </template>
                  <template v-else-if="terminatedSponsor.includes(sponsor.state)">
                    <td class="text-success strong"
                        width="200">
                      {{ sponsor.state_label }}
                    </td>
                    <td width="130"
                        class="center">
                      <ic-valid class="ic ic--20" />
                    </td>
                  </template>
                  <td v-else
                      colspan="2"
                      class="text-muted nowrap">
                    {{ sponsor.state_label }} le {{ formatDate(sponsor.updated_at) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ECoreBannerTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { EFeature, hasFeature } from '@/config/features'
import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'

import IcValid from '@/components/svg/icons/ic-valid.vue'

export default {
  components: {
    IcValid
  },

  setup () {
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const userStore = useUserStore()

    const { error: apiError } = storeToRefs(apiStore)
    const { sponsors } = storeToRefs(userStore)

    return {
      appStore,
      userStore,

      apiError,
      sponsors
    }
  },

  data () {
    return {
      email: '',
      disabledSponsor: [2, 4, 8, 16, 32, 64],
      terminatedSponsor: [64],
      formSubmitted: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        email: yup.string().validEmail().required()
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    if (!hasFeature(EFeature.Sponsorship)) {
      this.$router.push({ name: 'account' })
    }

    this.initData()
  },

  methods: {
    formatDate,

    async initData () {
      await this.userStore.getSponsors()
    },

    async onSubmit () {
      await this.sendSponsorRequest(this.email)
    },

    onInvalidSubmit () {
      this.formSubmitted = true
      this.appStore.showBanner(ECoreBannerTheme.Danger, this.$i18n.t('message.warning.form_not_valid'))
      this.$bus.emit('scrollToTop')
    },

    async sendSponsorRequest (email) {
      const success = await this.userStore.sendSponsorRequest(email)

      if (success) {
        this.formSubmitted = false
        this.email = ''
        showToastSuccess(this.$i18n.t('message.success.sponsorship.send'))
        await this.initData()
      }
    }
  }
}

</script>
