const getOnResizeEvent = (element: HTMLElement) => {
  return () => {
    for (const li of element.querySelectorAll('li')) {
      li.style.visibility = li.getBoundingClientRect().bottom - 10 > element.getBoundingClientRect().bottom ? 'hidden' : ''
    }
  }
}

const onListResize = {
  mounted (el: HTMLElement) {
    el.onresize = getOnResizeEvent(el)
    window.addEventListener('resize', el.onresize)
  },

  beforeUnmount (el: HTMLElement) {
    if (el.onresize) {
      window.removeEventListener('resize', el.onresize)
    }
  }
}

export { onListResize }
