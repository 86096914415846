<template>
  <loader-spinner v-if="loading"
                  size="24" />
  <div v-else-if="data"
       class="card-summary-tracking">
    <component-placeholder :content="data.summary.content"
                           :label="data.summary.label"
                           :icon-width="160"
                           :icon-height="128">
      <image-card info />
    </component-placeholder>
    <div class="card-balance-buttons">
      <VTooltip theme="poptip"
                :disabled="data.summary.is_tracking_available">
        <template #popper>
          <poptip :title="$t('popover.no_tracking.title')"
                  :message="noTrackingContent" />
        </template>

        <div class="v-popper__button-wrapping">
          <button data-cy="cards.details.delivery.button"
                  class="btn btn--outline mb-1"
                  :class="{ 'pointer-none': !data.summary.is_tracking_available }"
                  :disabled="!data.summary.is_tracking_available"
                  @click="showDetail">
            <i class="btn-icon btn-icon--left">
              <ic-info outline
                       class="ic ic--gray" />
            </i>
            {{ $t('button.track_delivery') }}
          </button>
        </div>
      </VTooltip>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import TrackShipmentDetail from '@/pages/cards/sidepanel/TrackShipmentDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import Poptip from '@/components/Poptip.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ImageCard from '@/components/svg/img/card.vue'

export default {
  components: {
    IcInfo,
    LoaderSpinner,
    ComponentPlaceholder,
    Poptip,
    ImageCard
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()

    return {
      accountStore,
      appStore,
      cardStore
    }
  },

  data () {
    return {
      loading: true,
      data: {}
    }
  },

  computed: {
    noTrackingContent () {
      if (this.accountStore.cardPinMailer) {
        return this.$i18n.t('popover.no_tracking.message')
      }
      return this.$i18n.t('popover.no_tracking.no_pin_mailer.message')
    }
  },

  async created () {
    this.loading = true

    this.data = this.card.display_tracking
      ? await this.cardStore.getCardDeliveryStatus(this.card.uuid)
      : { summary: { label: this.$i18n.t('track_delivery.created_at', { date: formatDateText(this.card.created_at, 'll') }) } }

    this.loading = false
  },

  methods: {
    showDetail () {
      this.appStore.showSidePanel(
        TrackShipmentDetail,
        { model: this.data },
        { wrapperClass: 'sidepanel-card-tracking sidepanel--500', backgroundOverlay: true }
      )
    }
  }
}
</script>
