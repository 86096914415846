<template>
  <svg width="24"
       height="24"
       viewBox="0 0 20 20"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.16537 9.64298L3.77076 9.64298L3.77076 8.29768C3.77076 5.40509 6.11566 3.06018 9.00825 3.06018L10.9583 3.06018L10.9583 1.60185L9.00825 1.60185C5.31025 1.60185 2.31242 4.59968 2.31242 8.29768L2.31242 9.64298L0.0260469 9.64298L3.11477 13.9687L6.16537 9.64298ZM8.88334 16.3C8.88334 17.3494 9.734 18.2 10.7833 18.2L17.1333 18.2C18.1827 18.2 19.0333 17.3494 19.0333 16.3L19.0333 8.70001C19.0333 7.65067 18.1827 6.80001 17.1333 6.80001L10.7833 6.80001C9.734 6.80001 8.88334 7.65067 8.88334 8.70001L8.88334 16.3ZM10.7833 16.8C10.5072 16.8 10.2833 16.5762 10.2833 16.3L10.2833 8.70001C10.2833 8.42387 10.5072 8.20001 10.7833 8.20001L17.1333 8.20001C17.4095 8.20001 17.6333 8.42387 17.6333 8.70001L17.6333 16.3C17.6333 16.5762 17.4095 16.8 17.1333 16.8L10.7833 16.8Z"
          fill="white" />
  </svg>
</template>

<script>
export default {}
</script>
