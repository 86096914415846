<template>
  <div class="sidepanel__content"
       data-cy="expense-requests.detail.sidepanel">
    <transition name="fade-delay">
      <file-viewer v-if="displayFileViewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <loader-spinner v-if="!modelForm"
                    class="c c--vh" />
    <template v-else>
      <div id="sidepanel-wrapper"
           class="sidepanel-wrapper overflow-scrollbar">
        <div class="sidepanel-header">
          <div class="transaction-summary transaction-summary--no-border">
            <h2 class="transaction-amount">
              {{ formatAmount(modelForm.amount, modelForm.currency) }}
            </h2>
            <p v-if="modelForm.fx_amount && modelForm.fx_currency && modelForm.fx_currency.iso !== modelForm.currency.iso"
               class="fx-currency text-muted">
              {{ formatAmount(modelForm.fx_amount, modelForm.fx_currency, { currencyDisplay: 'code' }) }}
            </p>
            <p class="transaction-name transaction-name--editable">
              <span>{{ modelForm.label }}</span>
              <popover v-if="canEditDetail"
                       ref="popover"
                       data-cy="expense-requests.detail.rename"
                       trigger="click"
                       popover-class="popover--center popover-rename"
                       position-arrow-x="center">
                <template #trigger>
                  <button class="btn-link btn-edit">
                    <ic-pencil class="ic ic--20 ic--gray" />
                  </button>
                </template>
                <template #default="slotProps">
                  <popover-rename :name="modelForm.label"
                                  data-key="label"
                                  :popover-active="slotProps.popoverActive"
                                  @renamed="value => modelForm.label = value"
                                  @close-popover="slotProps.closePopover" />
                </template>
              </popover>
            </p>
            <core-badge class="transaction-badge"
                        v-bind="statusBadge"
                        :size="ECoreBadgeSize.Large" />

            <div v-if="isPending || hasDocument"
                 class="document-actions-wrapper">
              <button type="button"
                      class="btn btn-proofs"
                      :class="proofButton.btnClass"
                      @click="showFile">
                <span class="btn-icon btn-icon--left">
                  <component :is="proofButton.icon"
                             class="ic ic--24 ic--gray" />
                </span>
                <span class="btn__label">{{ proofButton.label }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="sidepanel-body">
          <div class="sidepanel-details">
            <transaction-user v-if="modelForm.user"
                              :pictures="[modelForm.user.picture]"
                              :user-names="[`${modelForm.user.first_name} ${modelForm.user.last_name}`]"
                              :text="$t('general.initiated_by')" />

            <approvers-list v-if="isPending"
                            data-cy="expense-requests.detail.approvers-list.pending"
                            class="mb-4"
                            pending
                            :type="modelForm.approval_type"
                            :approvers="modelForm.approvers" />

            <h3 class="head-title">
              {{ $t('sidepanel.transfer.title') }}
            </h3>

            <div class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('table.type') }}</span>
              <span class="entry-value right">{{ modelForm.type_label }}</span>
            </div>
            <div class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('general.created_at') }}</span>
              <span class="entry-value right">{{ formatDateText(modelForm.created_at, 'lll') }}</span>
            </div>
            <div class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('general.execution_date') }}</span>
              <span class="entry-value right">
                <span v-if="!canEditDetail"
                      class="entry-value right">{{ formatDateText(modelForm.settlement_date, 'll') }}</span>
                <component-datepicker v-else
                                      v-model="modelForm.settlement_date"
                                      name="settlement_date"
                                      :wrapper-class="'datepicker--right select select--inline select--rtl'"
                                      :input-class="'form-control form-control--noborder right'"
                                      :disabled-days="{ to: new Date() }"
                                      :label="null"
                                      :language="$i18n.locale"
                                      format="dd MMMM yyyy"
                                      :monday-first="true"
                                      :readonly="false"
                                      :placeholder="$t('form.date.placeholder')" />
              </span>
            </div>
            <div v-if="modelForm.beneficiary.label"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.beneficiary") }}</span>
              <span class="entry-value right">{{ modelForm.beneficiary.label }}</span>
            </div>
            <div v-if="modelForm.beneficiary.iban"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.iban") }}</span>
              <span class="entry-value right">{{ formatIban(modelForm.beneficiary.iban) }}</span>
            </div>
            <div v-if="modelForm.detail.categories"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ categoryLabel }}</span>
              <div class="entry-value right">
                <div v-if="!canEditDetail">
                  {{ modelForm.detail.categories.length ? categoryList : $t('general.none') }}
                </div>
                <div v-else
                     class="select select--inline select--rtl">
                  <component-dropdown label="categories"
                                      data-cy="categories"
                                      multiselect
                                      search
                                      :dropdown-width="34"
                                      :can-add-item="hasPermission($permissions.categoriesWrite)"
                                      async-url="/categories"
                                      :async-url-params="{ filter: 'transfer' }"
                                      has-icon
                                      :model="modelForm.detail.categories"
                                      @select="onCategoriesSelected"
                                      @new-item="onNewCategoryAdded">
                    <template #trigger="{ showDropdown }">
                      <div v-tooltip="{ content: showDropdown ? false : tooltipCategoriesLabel, theme: 'tooltip', html: true }"
                           class="form-control"
                           :class="{ 'text-muted': !modelForm.detail.categories.length }">
                        {{ categoryList }}
                      </div>
                    </template>
                  </component-dropdown>
                </div>
              </div>
            </div>
            <category-detail-fields data-cy="expense-requests.detail.category-detail-fields"
                                    :operation="{ detail: modelForm.detail }"
                                    :disabled="!canEditDetail"
                                    @change="onCategoryDetailChange" />
            <sidepanel-affectations v-if="canReadAffectations"
                                    :can-edit-affectations="canEditAffectations"
                                    :can-write-affectations="canWriteAffectations"
                                    :affectations="modelForm.detail.affectations"
                                    @affectations-selected="onAffectationsSelected"
                                    @new-affectation-added="onNewAffectationAdded" />
            <hr class="divider divider--fw">
            <h3 class="head-title">
              {{ $t('form.personal_note.label') }}
            </h3>
            <div class="sidepanel-details__entry">
              <input-note v-model="modelForm.comment"
                          data-cy="expense-requests.detail.comment"
                          :placeholder="$t('form.personal_note.placeholder')"
                          :disabled="!canEditDetail" />
            </div>

            <template v-if="modelForm.status !== 'pending' && modelForm.approvers.length">
              <hr class="divider divider--fw">
              <approvers-list class="mb-4"
                              data-cy="expense-requests.detail.approvers-list.done"
                              :type="modelForm.approval_type"
                              :approvers="modelForm.approvers" />
            </template>
          </div>
        </div>
      </div>

      <component-sidepanel-footer v-if="modelForm !== null && (didRequestDetailChange || canApproveRequest || canCancelRequest)">
        <transition name="fade"
                    mode="out-in">
          <component-button v-if="didRequestDetailChange"
                            data-cy="expense-requests.detail.save"
                            :label="$t('button.save')"
                            wrapper-class="btn btn--block btn--sm btn--primary"
                            @click="saveRequest" />

          <div v-else-if="canApproveRequest"
               class="btn-grouped">
            <button type="button"
                    data-cy="expense-requests.detail.reject"
                    class="btn btn--sm btn--gray"
                    :disabled="loading"
                    @click.prevent="reject">
              {{ $t('button.reject') }}
            </button>
            <button type="button"
                    data-cy="expense-requests.detail.approve"
                    class="btn btn--sm btn--primary"
                    :disabled="loading"
                    @click.prevent="approve">
              {{ $t('button.approve') }}
            </button>
          </div>

          <component-button v-else-if="canCancelRequest"
                            data-cy="expense-requests.detail.cancel"
                            :label="$t('button.cancel')"
                            wrapper-class="btn btn--block btn--sm btn--gray"
                            @click="cancel" />
        </transition>
      </component-sidepanel-footer>
    </template>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import { cloneDeep, isEqual } from 'lodash'
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useExpenseRequestStore } from '@/stores/expense-request'
import { useOperationStore } from '@/stores/operation'

import ApproversList from '@/pages/expense_requests/components/ApproversList.vue'
import RejectRequest from '@/pages/expense_requests/modal/RejectRequest.vue'

import ComponentButton from '@/components/Button.vue'
import CategoryDetailFields from '@/components/CategoryDetailFields.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FileViewer from '@/components/FileViewer.vue'
import InputNote from '@/components/InputNote.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Popover from '@/components/Popover.vue'
import PopoverRename from '@/components/popovers/Rename.vue'
import SidepanelAffectations from '@/components/SidepanelAffectations.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import TransactionUser from '@/components/TransactionUser.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    CoreBadge,
    IcPencil,
    Popover,
    PopoverRename,
    InputNote,
    ApproversList,
    ComponentButton,
    TransactionUser,
    ComponentDropdown,
    ComponentDatepicker,
    ComponentSidepanelFooter,
    CategoryDetailFields,
    FileViewer,
    UserAvatar,
    LoaderSpinner,
    SidepanelAffectations
  },

  props: {
    uuid: {
      type: String,
      required: true
    },

    showHeader: {
      type: Boolean,
      default: true
    }
  },

  emits: ['dropped-files-upload'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const expenseRequestStore = useExpenseRequestStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { sidePanel } = storeToRefs(appStore)
    const { user } = storeToRefs(authStore)

    return {
      appStore,
      expenseRequestStore,
      operationStore,

      account,
      sidePanel,
      user
    }
  },

  data () {
    return {
      displayFileViewer: false,
      loading: false,
      modelForm: null,
      expenseRequest: null
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    hasDocument () {
      return this.modelForm.documents.length
    },

    isApprover () {
      return this.modelForm.approvers.find(approver => { return approver.user.uuid === this.user.uuid })
    },

    isPending () {
      return this.modelForm.status === 'pending'
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.expenseRequestsAffectationsRead)
    },

    canEditAffectations () {
      return this.canReadAffectations && this.hasPermission(this.$permissions.expenseRequestsAffectationsWrite)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    hasCategory () {
      return this.modelForm?.detail?.categories?.length
    },

    categoryList () {
      return this.hasCategory
        ? this.$i18n.tc('form.transfer_details.categories', this.modelForm.detail.categories.length - 1, { name: this.modelForm.detail.categories[0].label })
        : this.$i18n.t('form.common.select')
    },

    categoryLabel () {
      return this.$i18n.tc('form.category.label', this.modelForm.detail.categories?.length || 0)
    },

    tooltipCategoriesLabel () {
      return this.modelForm.detail.categories.length >= 2 ? this.modelForm.detail.categories.map(c => `${c.label}<br>`).join('') : false
    },

    proofButton () {
      return this.hasDocument
        ? {
            icon: IcDocument,
            btnClass: 'btn--outline btn-document',
            label: this.$i18n.tc('button.show_proofs', this.modelForm.documents.length)
          }
        : {
            icon: IcAttachment,
            btnClass: 'btn--outline btn-attachment',
            label: this.$i18n.t('button.attach_proof')
          }
    },

    canApproveRequest () {
      return this.isPending && this.hasPermission(this.$permissions.expenseRequestsItemsApprove, this.modelForm)
    },

    canCancelRequest () {
      return this.isPending && this.hasPermission(this.$permissions.expenseRequestsItemsDelete, this.modelForm)
    },

    canEditDetail () {
      return this.isPending
    },

    didRequestDetailChange () {
      return !isEqual({
        label: this.modelForm.label,
        settlement_date: formatDateText(this.modelForm.settlement_date, 'YYYY-MM-DD'),
        detail: this.modelForm.detail,
        comment: this.modelForm.comment || ''
      }, {
        label: this.expenseRequest.label,
        settlement_date: formatDateText(this.expenseRequest.settlement_date, 'YYYY-MM-DD'),
        detail: this.expenseRequest.detail,
        comment: this.expenseRequest.comment || ''
      })
    },

    statusBadge () {
      if (this.isPending) {
        if (this.canApproveRequest && this.isApprover) {
          return {
            value: this.$i18n.t('general.to_approve'),
            theme: this.ECoreBadgeTheme.Info
          }
        } else {
          return {
            value: this.$i18n.t('general.pending'),
            theme: this.ECoreBadgeTheme.Info,
            fill: this.ECoreBadgeFill.Shaded
          }
        }
      } else {
        return {
          value: this.modelForm.status_label,
          ...getBadgeProps(this.modelForm.status)
        }
      }
    }
  },

  async created () {
    this.expenseRequest = await this.expenseRequestStore.getExpenseRequest(this.uuid)
    this.modelForm = cloneDeep(this.expenseRequest)
    this.$bus.emit('drag-and-drop-ready')

    if (this.sidePanel.initShowFile) this.showFile()
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.closeFileViewer)
    this.$bus.on('on-files-dropped', this.onFilesDropped)
    this.$bus.on('on-upload-success', this.onDocumentChange)
    this.$bus.on('delete-success', this.onDocumentChange)
    this.$bus.on('delete-category', this.onDeleteCategory)
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.closeFileViewer)
    this.$bus.off('on-files-dropped', this.onFilesDropped)
    this.$bus.off('on-upload-success', this.onDocumentChange)
    this.$bus.off('delete-success', this.onDocumentChange)
    this.$bus.off('delete-category', this.onDeleteCategory)
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
  },

  methods: {
    getBadgeProps,
    downloadFileFromUrl,

    formatIban,

    formatAmount,

    formatDateText,

    closeFileViewer () {
      this.displayFileViewer = false
    },

    showFile () {
      if (this.hasDocument) {
        this.appStore.showFileViewer({
          content: this.modelForm.documents,
          isMultiDoc: true,
          canEdit: this.canEditDetail,
          operationId: this.modelForm.uuid,
          documentsUrl: `/requests/${this.modelForm.uuid}/documents`,
          dataModel: this.modelForm
        })
        this.displayFileViewer = true
      } else {
        this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file', 2), this.modelForm, { url: `/requests/${this.modelForm.uuid}/documents`, hasMultipleFiles: true })
      }
    },

    async reject () {
      this.appStore.showModal(
        RejectRequest,
        {
          uuid: this.modelForm.uuid,
          user: this.modelForm.user
        },
        {
          wrapperClass: 'modal--xs'
        })
    },

    async approve () {
      this.loading = true
      const success = await this.expenseRequestStore.approveExpenseRequest(this.modelForm.uuid)
      this.loading = false
      if (success) {
        this.appStore.closeSidePanel()
        this.$bus.emit('expense-request-approve-success')
        showToastSuccess(`${this.$i18n.tc('message.success.approve_request', 0)}`)
      }
    },

    async cancel () {
      this.loading = true
      const success = await this.expenseRequestStore.cancelExpenseRequest(this.modelForm.uuid)
      this.loading = false
      if (success) {
        this.appStore.closeSidePanel()
        this.$bus.emit('expense-request-reject')
        showToastSuccess(`${this.$i18n.t('message.success.cancel_request')}`)
      }
    },

    async onNewCategoryAdded (itemLabel) {
      const newCategory = await this.operationStore.addCategory(itemLabel)
      if (newCategory) {
        this.modelForm.detail.categories.push(newCategory)
        this.$bus.emit('on-new-item-added', newCategory)
      }
    },

    onCategoriesSelected (value) {
      this.modelForm.detail.categories = [...value].sort((a, b) => a.id - b.id)
    },

    onDeleteCategory (itemId) {
      const index = this.modelForm.detail.categories.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.modelForm.detail.categories.splice(index, 1)
      }
    },

    onCategoryDetailChange (field, value) {
      if (field === 'fuel') {
        this.modelForm.detail.fuel = value
      } else if (field === 'service') {
        this.modelForm.detail.service = value.name
      } else if (field === 'half_board_included') {
        this.modelForm.detail.half_board_included = value
      } else {
        this.modelForm.detail[field] = value
      }
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.modelForm.detail.affectations.push(newAffectation)
        this.$bus.emit('on-new-item-added', newAffectation)
      }
    },

    onAffectationsSelected (value) {
      this.modelForm.detail.affectations = [...value].sort((a, b) => a.id - b.id)
    },

    onDeleteAffectation (itemId) {
      const index = this.modelForm.detail.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.modelForm.detail.affectations.splice(index, 1)
      }
    },

    onDocumentChange () {
      this.$bus.emit('expense-request-update-success')
    },

    async onFilesDropped (files) {
      const documents = await this.expenseRequestStore.sendDocuments(this.modelForm.uuid, files)
      this.$emit('dropped-files-upload')

      if (documents) {
        this.modelForm.documents = [...documents]
        this.showFile(this.modelForm.documents.length - files.length)
        this.$bus.emit('on-upload-success', { documents })
      }
    },

    async saveRequest () {
      const payload = {
        detail: {
          label: this.modelForm.label,
          affectations: this.modelForm.detail.affectations,
          categories: this.modelForm.detail.categories,
          guest: this.modelForm.detail.guest,
          nights: this.modelForm.detail.nights,
          service: this.modelForm.detail.service,
          charge_back: this.modelForm.detail.charge_back,
          fuel: this.modelForm.detail.fuel,
          liters: this.modelForm.detail.liters,
          mileage: this.modelForm.detail.mileage,
          half_board_included: this.modelForm.detail.half_board_included
        },

        settlement_date: formatDateText(this.modelForm.settlement_date, 'YYYY-MM-DD'),
        comment: this.modelForm.comment
      }

      const data = await this.expenseRequestStore.updateExpenseRequest(this.modelForm.uuid, payload)

      if (data) {
        this.expenseRequest = cloneDeep(data)
        this.modelForm = cloneDeep(data)
        this.$bus.emit('expense-request-update-success')
      }
    }
  }
}
</script>
