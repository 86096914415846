<template>
  <div class="limit-item"
       :class="{ 'limit-item--off': !enabled }">
    <div class="limit-item__left">
      <toggle-switch :model-value="enabled"
                     :disabled="disabled"
                     data-cy="toggle"
                     toggle-class="switch-btn--sm"
                     @update:model-value="value => updateEnabled(value)" />
      <span class="switch-btn-label capitalize">{{ label }}</span>
    </div>
    <div class="limit-item__right">
      <div v-if="enabled"
           class="input-group">
        <span v-if="invalidAmountWarning"
              v-tooltip="{ content: invalidAmountWarning, theme: 'tooltip' }"
              class="input-group__addon">
          <ic-warning outline
                      class="ic ic--warning ic--16" />
        </span>
        <number-input :model-value="limit"
                      name="transfer_limit"
                      class="form-control form-control--sm right"
                      :class="{ 'line-through': invalidAmountWarning }"
                      :disabled="disabled"
                      :placeholder="placeholder ? $t('form.amount.placeholder') : null"
                      @focus="updateFocus(true)"
                      @blur="value => updateLimit(value)" />
        <span v-if="limit && displayCurrency"
              class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
      </div>
      <span v-else
            class="text-muted">{{ noLimitText || $t("card.limit.no_limit") }}</span>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import NumberInput from '@/components/NumberInput.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    NumberInput,
    IcWarning,
    ToggleSwitch
  },

  props: {
    // TODO type an object props value: {enabled: Boolean, limit: Number} and remove enabled and limit props
    disabled: {
      type: Boolean,
      default: false
    },

    displayCurrency: {
      type: Boolean,
      default: true
    },

    enabled: {
      type: Boolean,
      required: true
    },

    invalidAmountWarning: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      required: true
    },

    limit: {
      type: Number,
      required: true
    },

    noLimitText: {
      type: String,
      default: ''
    },

    placeholder: {
      type: Boolean,
      default: true
    }
  },

  emits: [
    'update-enabled',
    'update-focus',
    'update-limit'
  ],

  setup () {
    const accountStore = useAccountStore()
    const { account } = storeToRefs(accountStore)
    return { account }
  },

  methods: {
    getCurrencySymbol,

    updateEnabled (value) {
      this.$emit('update-enabled', value)
    },

    updateFocus (value) {
      this.$emit('update-focus', value)
    },

    updateLimit (value) {
      if (value !== this.limit) {
        this.$emit('update-limit', value)
      }

      this.updateFocus(false)
    }
  }
}

</script>

<style lang="stylus" scoped>
.limit-item
  height 8rem
  display flex
  padding 0 2.4rem
  align-items center

  &--off
    .switch-btn-label
      opacity .5

  &__left
  &__right
      display inline-flex
      align-items center

  &__left
      flex 1

  &__right
      margin-left 2rem

  .input-group
    max-width 12rem
    border 1px solid $borderColor
    border-radius $border-radius

    :deep(.input-group__addon)
      border none
    :deep(.form-control)
      border none
</style>
