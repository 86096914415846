<template>
  <svg viewBox="0 0 200 160">
    <path d="m126 106.25c9.25077 0 16.75 7.49923 16.75 16.75s-7.49923 16.75-16.75 16.75-16.75-7.49923-16.75-16.75 7.49923-16.75 16.75-16.75zm0 1.5c-8.422342 0-15.25 6.827658-15.25 15.25s6.827658 15.25 15.25 15.25 15.25-6.827658 15.25-15.25-6.827658-15.25-15.25-15.25zm0 6.5c.379696 0 .693491.282154.743153.648229l.006847.101771v12.189l4.71967-4.71933c.266266-.266267.68293-.290473.976542-.072618l.084118.072618c.266267.266266.290473.68293.072618.976542l-.072618.084118-6 6c-.266266.266267-.68293.290473-.976542.072618l-.084118-.072618-6-6c-.292893-.292893-.292893-.767767 0-1.06066.266266-.266267.68293-.290473.976542-.072618l.084118.072618 4.71967 4.71933v-12.189c0-.414214.335786-.75.75-.75zm-.57-72.59v1.5h-60.94v-1.5z"
          :fill="colorPrimary" />
    <path d="m128.44 25.25c2.900484.0428058 5.232683 2.3691891 5.309004 5.2434317l.001468.2165683-.000224 75.624594c-.48876-.227685-.989202-.434501-1.50018-.619303l-.000068-74.965291c.029543-1.0368824-.356264-2.0426191-1.071693-2.793725-.664326-.6974555-1.561767-1.1217098-2.516938-1.1955023l-.221369-.0107727-52.88-.04c-1.0370924.0209119-2.0228787.455169-2.7383071 1.206275-.7154284.7511059-1.1012362 1.7568426-1.0716929 2.793725l-.0009324 1.54h49.6909324c2.900484.0428058 5.232683 2.3691891 5.309004 5.2434317l.001468.2165683.000168 66.930053c-.24897-.010003-.499215-.015053-.75064-.015053-.251339 0-.501499.005046-.750385.015043l-.000131-67.1286689c-.031246-.9514785-.410781-1.8617706-1.071177-2.5550991-.715428-.751106-1.701215-1.1853631-2.738307-1.206275l-52.88-.04c-1.0370924.0209119-2.0228787.455169-2.7383071 1.206275-.7154284.7511059-1.1012362 1.7568426-1.0716929 2.793725l.0004718 83.01h1.0295282c3.4827065.054828 6.2645219 2.917147 6.22 6.4l.0004718 1.22 36.4126572.000527c.14513.478554.309258.948846.491505 1.41l-37.614634-.000527c-.2037708 0-.3987189-.083159-.54-.23l-7.29-7.53c-.1346084-.139657-.2104718-.326033-.2104718-.52v-83.76c-.0384665-2.9730645 2.337476-5.4161241 5.3104718-5.46h1.6890676l.0004606-1.54c-.0375283-2.9005508 2.2230127-5.2966351 5.0940274-5.452535l.2164444-.007465zm-62.66 96.97h-.0995282l4.822 4.977-.0024718-.077c.0446279-2.654494-2.0656945-4.845295-4.72-4.9z"
          :fill="colorDark" />
    <path d="m118.75 69c.414214 0 .75.3357864.75.75v34c0 .414214-.335786.75-.75.75h-47.5c-.4142136 0-.75-.335786-.75-.75v-34c0-.4142136.3357864-.75.75-.75zm-36.351 26.87h-10.399v7.13l10.399-.001zm11.851 0h-10.351v7.129h10.351zm23.75 0h-10.4v7.129l10.4.001zm-11.9 0h-10.35v7.129h10.35zm11.9-8.471h-10.4v6.971h10.4zm-35.601 0h-10.399v6.971h10.399zm11.851 0h-10.351v6.971h10.351zm11.85 0h-10.35v6.971h10.35zm11.9-8.469h-10.4v6.969h10.4zm-35.601 0h-10.399v6.969h10.399zm11.851 0h-10.351v6.969h10.351zm11.85 0h-10.35v6.969h10.35zm11.9-8.42h-10.4v6.92h10.4zm-35.601 0h-10.399v6.92h10.399zm11.851 0h-10.351v6.92h10.351zm11.85 0h-10.35v6.92h10.35zm-1.6-9.51v1.5h-34v-1.5zm-9.5-6v1.5h-24.5v-1.5z"
          :fill="colorGrey" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
