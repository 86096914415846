<template>
  <div>
    <div v-if="title"
         class="popover-header">
      <h4 class="popover-header-title">
        {{ title }}
      </h4>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div class="popover-body"
         v-html="message" />
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'ComponentPoptip',

  props: {
    title: {
      type: String,
      default: '',
      required: false
    },

    message: {
      type: String,
      required: true
    }
  }
}
</script>
