<template>
  <modal-dialog-prompt :title="$t('modal.non_compliant_expense.title')"
                       :subtitle="$t('modal.non_compliant_expense.subtitle')"
                       @close="$emit('close')">
    <template #body>
      <textarea id="nonCompliantComment"
                v-model="claimNonCompliantComment"
                class="form-control multiple-rows-note"
                rows="3"
                :placeholder="$t('modal.non_compliant_expense.textarea.placeholder')" />
    </template>

    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              :disabled="loading"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <component-button type="submit"
                        class="btn--primary btn--block login-form__submit"
                        :disabled="loading"
                        :label="$t('button.reject')"
                        @click="onSubmit" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { showToastSuccess } from '@/helpers/utils/notification'
import { useOperationStore } from '@/stores/operation'

import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ModalDialogPrompt,
    ComponentButton
  },

  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const operationStore = useOperationStore()

    return { operationStore }
  },

  data () {
    return {
      loading: false,
      claimNonCompliantComment: null
    }
  },

  methods: {
    async onSubmit () {
      this.loading = true
      const data = await this.operationStore.updateOperationCompliance({ uuid: this.uuid, compliance: false, comment: this.claimNonCompliantComment })
      this.loading = false

      if (!data) { return }

      showToastSuccess(this.$i18n.t('message.success.claim_non_compliant_operation'))
      this.$bus.emit('on-update-compliance-success', data)
      this.$bus.emit('on-reject-expense')
      this.$emit('close')
    }
  }
}
</script>
