<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t("title.my_companies") }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <template v-else>
        <ul class="company-list">
          <li v-if="isAddCompanyVisible">
            <a :href="getSignupRoute"
               class="item add-new">
              <div class="add-new-item">
                <p class="add-new-item__label">{{ $t("button.add_company") }}</p>
                <div class="add-new-item__icon">
                  <ic-plus class="ic--gray ic--24" />
                </div>
              </div>
              <span class="decorations">
                <hr class="decorative-hr decorative-hr_1">
                <hr class="decorative-hr decorative-hr_2">
              </span>
            </a>
          </li>
          <company-item v-for="company in companiesList"
                        :key="company.id"
                        :company-data="company" />
        </ul>
        <component-pagination :pagination="companiesListPagination" />
      </template>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'

import CompanyItem from './components/CompanyItem.vue'

export default {
  components: {
    IcPlus,
    IcArrow,
    CompanyItem,
    ComponentPagination,
    LoaderSpinner
  },

  props: {
    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()

    const { companiesList, companiesListPagination } = storeToRefs(accountStore)
    const { user } = storeToRefs(authStore)

    return { companiesList, companiesListPagination, accountStore, user }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    getSignupRoute () {
      return `${links.SIGNUP}/?token=${this.user.token}`
    },

    isAddCompanyVisible () {
      return hasFeature(EFeature.AccountCompanyCreation)
    }
  },

  watch: {
    page () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  methods: {
    async initData () {
      this.loading = true
      await this.accountStore.getCompaniesList(this.page)
      this.loading = false
    }
  }
}
</script>
