<template>
  <modal-dialog-prompt :title="t('prompt.loyalty.campaign.title')"
                       data-cy="confirm-modal.container"
                       @close="emit('close')">
    <template #body>
      <p class="pre-line">
        {{ t('prompt.loyalty.campaign.content', { phone: formattedPhoneNumber }) }}
      </p>
    </template>
  </modal-dialog-prompt>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { parsePhoneNumber } from 'awesome-phonenumber'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

const { t } = useI18n()

const props = defineProps<{
  phone: string
}>()

const formattedPhoneNumber = computed(() => {
  const parsedPhoneNumber = parsePhoneNumber(props.phone)
  return parsedPhoneNumber.valid ? parsedPhoneNumber.number?.international : props.phone
})

const emit = defineEmits<{
  close: []
}>()
</script>
