<template>
  <div class="modal__content">
    <modal-header @close="$emit('close')" />
    <div class="modal__body">
      <div class="multiple-transfer-modal-content center">
        <lottie v-if="apiLoading"
                name="document-sablier"
                class="icon"
                style="height: 110px; margin-left: auto; margin-right: auto; margin-bottom: 2rem;"
                :start-frames="[0, 10]"
                :loop-frames="[10, 61]" />
        <ImageFile v-else-if="isValid"
                   success
                   width="200px"
                   class="icon" />
        <ImageDocsWarning v-else
                          width="200px"
                          class="icon" />
        <h4 class="pre-line">
          {{ getLabel }}
        </h4>
        <p class="pre-line">
          {{ getContent }}
        </p>
        <i18n-t v-if="showSepaInfoLink"
                tag="p"
                keypath="modal.multiple_transfers.error.content_sepa.informations"
                scope="global">
          <template #link>
            <a href="https://fr.wikipedia.org/wiki/ISO_20022"
               target="_blank">{{ $t('button.click_here') }}</a>
          </template>
        </i18n-t>
        <template v-if="!apiLoading && !isValid && hasErrorsDetail">
          <button v-if="!showDetail"
                  class="text-primary"
                  @click="onShowDetailClicked">
            {{ $t('button.show_details') }}
          </button>
          <component-alert v-else
                           type="error"
                           :show-api-messages="false"
                           :message="errorsDetail" />
        </template>
        <div v-if="!apiLoading"
             class="buttons">
          <p v-if="isValid && unknownBeneficiaryLength">
            <strong>{{ $t('modal.continue') }}</strong>
          </p>
          <button type="button"
                  class="btn btn--gray"
                  @click="$emit('close')">
            {{ getCancelButtonLabel }}
          </button>
          <button v-if="isValid"
                  type="button"
                  class="btn btn--primary"
                  @click="onValidate">
            {{ $t('button.continue') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { getFlatErrorsArray } from '@/helpers/utils'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useCardStore } from '@/stores/card'
import { useTransferStore } from '@/stores/transfer'
import { useUserStore } from '@/stores/user'

import ComponentAlert from '@/components/Alert.vue'
import Lottie from '@/components/Lottie.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import ImageDocsWarning from '@/components/svg/img/docs-warning.vue'
import ImageFile from '@/components/svg/img/file.vue'

export default {
  components: {
    ComponentAlert,
    ImageDocsWarning,
    ImageFile,
    Lottie,
    ModalHeader
  },

  props: {
    type: {
      type: String,
      required: true
    },

    files: {
      type: Array,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const beneficiaryStore = useBeneficiaryStore()
    const cardStore = useCardStore()
    const transferStore = useTransferStore()
    const userStore = useUserStore()

    const { account } = storeToRefs(accountStore)
    const { beneficiariesImport } = storeToRefs(beneficiaryStore)
    const { cardOrders } = storeToRefs(cardStore)
    const {
      error: apiError,
      loading: apiLoading
    } = storeToRefs(apiStore)
    const { multipleTransfers } = storeToRefs(transferStore)
    const { usersImport } = storeToRefs(userStore)

    return {
      account,
      apiError,
      apiLoading,
      beneficiariesImport,
      cardOrders,
      multipleTransfers,
      usersImport
    }
  },

  data () {
    return {
      showDetail: false
    }
  },

  computed: {
    structuredInfo () {
      if (this.type === 'cards') {
        return {
          data: this.cardOrders,
          successLabel: this.$i18n.t('modal.card.order_multiple.success.label'),
          errorLabel: this.$i18n.t('modal.card.order_multiple.error.label'),
          successContent: this.$i18n.tc('modal.card.order_multiple.success.content', this.cardOrders.length, { cardsNumber: this.cardOrders.length }),
          errorContent: this.$i18n.t('modal.card.order_multiple.error.content')
        }
      }

      if (this.type === 'users') {
        return {
          data: this.usersImport,
          successLabel: this.$i18n.t('modal.card.order_multiple.success.label'),
          errorLabel: this.$i18n.t('modal.card.order_multiple.error.label'),
          successContent: this.$i18n.tc('modal.users_import.success.content', this.usersImport.length),
          errorContent: this.$i18n.t('modal.card.order_multiple.error.content')
        }
      }

      if (this.type === 'beneficiaries') {
        return {
          data: this.beneficiariesImport,
          successLabel: this.$i18n.t('modal.card.order_multiple.success.label'),
          errorLabel: this.$i18n.t('modal.card.order_multiple.error.label'),
          successContent: this.$i18n.tc('modal.beneficiaries_import.success.content', this.beneficiariesImport.length),
          errorContent: this.$i18n.t('modal.card.order_multiple.error.content')
        }
      }

      if (this.type === 'payslips') {
        return {
          data: this.multipleTransfers,
          successLabel: this.$i18n.tc('modal.multiple_transfers.success.label', this.files.length),
          errorLabel: this.$i18n.tc('modal.multiple_transfers.error.label', this.files.length),
          successContent: `${this.$i18n.tc('modal.multiple_transfers.success.files', this.files.length)} ${this.$i18n.tc('modal.multiple_transfers.success.content', this.validTransfersLength + this.invalidTransfersLength, { totalAmount: this.totalAmount })}`,
          errorContent: this.$i18n.tc('modal.multiple_transfers.error.content_pdf', this.files.length)
        }
      }

      return {
        data: this.multipleTransfers,
        successLabel: this.$i18n.tc('modal.multiple_transfers.success.label', this.files.length),
        errorLabel: this.$i18n.tc('modal.multiple_transfers.error.label', this.files.length),
        successContent: `${this.$i18n.tc('modal.multiple_transfers.success.files', this.files.length)} ${this.$i18n.tc('modal.multiple_transfers.success.content', this.validTransfersLength + this.invalidTransfersLength, { totalAmount: this.totalAmount })}`,
        errorContent: this.$i18n.t('modal.multiple_transfers.error.content_sepa.general')
      }
    },

    errorsDetail () {
      let message = ''
      if (this.fileErrors.message && typeof this.fileErrors.message === 'string') {
        message = this.fileErrors.message
      } else if (Array.isArray(this.fileErrors)) {
        this.fileErrors.forEach(row => {
          getFlatErrorsArray(row.errors).forEach(error => {
            message += Object.hasOwnProperty.call(row, 'index')
              ? this.$i18n.t('modal.card.order_multiple.error.detail', { position: row.index, message: error.message })
              : error.message
            message += '\n'
          })
        })
      }
      return message
    },

    fileErrors () {
      return this.apiError?.fields || []
    },

    hasErrorsDetail () {
      return this.fileErrors && Object.keys(this.fileErrors).length
    },

    validTransfersLength () {
      return this.multipleTransfers?.valid?.length || 0
    },

    invalidTransfersLength () {
      return this.multipleTransfers?.invalid?.length || 0
    },

    unknownBeneficiaryLength () {
      return this.multipleTransfers?.invalid ? this.multipleTransfers.invalid.filter(value => value.beneficiary.id === null && value.beneficiary.label && value.transfer).length : 0
    },

    isValid () {
      if (this.apiError?.code) {
        return false
      }

      return ['payslips', 'sepa'].includes(this.type)
        ? this.validTransfersLength || this.invalidTransfersLength
        : !!this.structuredInfo.data.length
    },

    getLabel () {
      if (this.apiLoading) {
        return this.$i18n.t('modal.multiple_transfers.loading.label')
      }

      if (!this.isValid) {
        return this.structuredInfo.errorLabel
      }

      if (this.unknownBeneficiaryLength) {
        return this.$i18n.tc('modal.multiple_transfers.unknown_beneficiary.label', this.unknownBeneficiaryLength, { unknownBeneficiary: this.unknownBeneficiaryLength })
      }

      return this.structuredInfo.successLabel
    },

    getContent () {
      if (this.apiLoading) {
        return this.$i18n.tc('modal.multiple_transfers.loading.content', this.files.length)
      }

      if (!this.isValid) {
        return this.structuredInfo.errorContent
      }

      if (this.unknownBeneficiaryLength) {
        return this.$i18n.tc('modal.multiple_transfers.unknown_beneficiary.content', this.unknownBeneficiaryLength, { unknownBeneficiary: this.unknownBeneficiaryLength })
      }

      return this.structuredInfo.successContent
    },

    getCancelButtonLabel () {
      return this.isValid ? this.$t('button.cancel') : this.$t('button.ok_understood')
    },

    totalAmount () {
      const totalAmount = this.multipleTransfers?.valid
        ? this.multipleTransfers.valid.reduce((total, valid) => total + valid.transfer.amount, 0)
        : 0

      return formatAmount(totalAmount, this.account.currency)
    },

    showSepaInfoLink () {
      return !this.isValid && this.type === 'sepa'
    }
  },

  methods: {
    onShowDetailClicked () {
      this.showDetail = true
    },

    onValidate () {
      if (['payslips', 'sepa'].includes(this.type)) {
        this.$router.push({ name: 'transfers-edit-multiple', params: { type: this.type } })
      } else {
        this.$bus.emit('on-validate-file-import', this.files)
        this.$emit('close')
      }
    }
  }

}
</script>
