<template>
  <svg viewBox="0 0 24 24">
    <path d="M16.17 3c1.577 0 2.874 1.358 2.874 3.02 0 .5-.117.993-.339 1.439H20a1 1 0 0 1 1 1v3.271a1 1 0 0 1-1 1h-.119V19a1 1 0 0 1-1 1H5.12a1 1 0 0 1-1-1v-6.27H4a1 1 0 0 1-1-1V8.46a1 1 0 0 1 1-1h1.295a3.224 3.224 0 0 1-.34-1.438C4.956 4.358 6.254 3 7.83 3 9.965 3 11.262 4.62 12 6.021 12.738 4.621 14.037 3 16.17 3zm2.116 9.73h-5.488v5.649h5.488zm-7.084 0H5.714v5.647h5.488zm0-3.65H4.597v2.028h6.605zm8.2.001h-6.605v2.027h6.605zm-3.231-4.46c-.979 0-1.836.608-2.555 1.804l-.518 1.035h3.073c.679 0 1.277-.668 1.277-1.44a1.35 1.35 0 0 0-1.277-1.399zm-8.224 0a1.35 1.35 0 0 0-1.277 1.4c0 .77.598 1.44 1.277 1.44h3.073l-.52-1.037C9.783 5.23 8.925 4.621 7.947 4.621z" />
  </svg>
</template>

<script>
export default {
}
</script>
