<template>
  <div v-if="availableProviders.length"
       class="card-options row">
    <card-option-block data-cy="cards.details.wallet"
                       block
                       :content="{
                         title: $t(`card.general.wallet.${textProvider}.title`),
                         description: $t(`card.general.wallet.${textProvider}.content`)
                       }">
      <template #button>
        <WalletWidget v-if="availableProviders"
                      :card-uuid="props.cardUuid"
                      :providers="availableProviders" />
      </template>
    </card-option-block>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'
import { ECardWalletProvider } from '@/types/card.d'
import { EDeviceFormat, EDeviceOs } from '@/types/device.d'

import WalletWidget from '@/pages/cards/details/components/wallet/WalletWidget.vue'

import CardOptionBlock from './CardOptionBlock.vue'

const props = defineProps({
  cardUuid: {
    type: String,
    required: true
  }
})

const appStore = useAppStore()
const { device } = storeToRefs(appStore)

const availableProviders = ref<ECardWalletProvider[]>([])

const textProvider = computed(() => availableProviders.value.length === 2 ? 'default' : availableProviders.value[0])

onMounted(() => {
  if (device.value.format === EDeviceFormat.Desktop) {
    availableProviders.value = [ECardWalletProvider.GOOGLE, ECardWalletProvider.APPLE]
    return
  }

  if (device.value.os === EDeviceOs.Ios) {
    availableProviders.value = [ECardWalletProvider.APPLE]
  } else {
    availableProviders.value = [ECardWalletProvider.GOOGLE]
  }
})
</script>
