<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="modal__content">
    <modal-header @close="$emit('close')">
      <template #header-extra>
        <div class="message-header">
          <div class="message-header__avatar">
            <logo-bank-icon />
          </div>
          <div class="message-header__details">
            <h3>{{ message.from }}</h3>
            <p class="text-muted">
              {{ formatDateText(message.created_at, 'LLL') }}
            </p>
          </div>
        </div>
      </template>
    </modal-header>
    <div class="modal__body">
      <div class="message-content">
        <h1 class="message-subject">
          {{ message.subject }}
        </h1>
        <div class="message-body">
          <p class="pre-line">
            {{ content }}
          </p>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="modal__footer__left" />
      <div class="modal__footer__right">
        <button type="button"
                class="btn btn--primary"
                @click.prevent="appStore.showDeleteModal({ label: $tc('prompt.message.delete.title'), content: $tc('prompt.message.delete.content'), url: `/messages/${message.id}` })">
          {{ $t('button.delete_message') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/config/store'
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useMessageStore } from '@/stores/message'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import LogoBankIcon from '@/components/svg/img/logo-bank-icon.vue'

export default {
  components: {
    LogoBankIcon,
    LoaderSpinner,
    ModalHeader
  },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const messageStore = useMessageStore()

    return { appStore, messageStore }
  },

  data () {
    return {
      store,
      message: null,
      loading: true
    }
  },

  computed: {
    content () {
      return this.message.content.replace(/<br\s*\/?>/g, '\n')
    }
  },

  async created () {
    this.loading = true
    this.message = await this.messageStore.getMessage(this.id)
    this.loading = false
  },

  methods: {
    formatDateText
  }
}
</script>
