<template>
  <section class="section section--empty"
           data-cy="account.create.check.page">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'create-sub-account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('create_checks_account.title') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <component-placeholder icon
                             :button="$t('button.open_new_account')"
                             :content="$t('create_checks_account.placeholder.content')"
                             :content-info="$t('create_checks_account.placeholder.content_info')"
                             :disabled="loading"
                             :label="$t('create_checks_account.placeholder.title')"
                             @btn-click="openCheckAccount">
        <check-simple />
      </component-placeholder>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { EAccountType } from '@/types/account.d'

import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import CheckSimple from '@/components/svg/img/check-simple.vue'

import CreateSubAccountValidation from './modal/CreateSubAccountValidation.vue'

export default {
  components: {
    CheckSimple,
    ComponentPlaceholder,
    IcArrow
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return {
      account,
      accountStore,
      appStore
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    async openCheckAccount () {
      this.loading = true

      const account = await this.accountStore.createNewSubAccount({
        billable: null,
        company_id: this.account.company.id,
        label: this.$i18n.t('general.checks_account'),
        type: EAccountType.Sequestre
      })

      if (account) {
        this.appStore.showModal(
          CreateSubAccountValidation,
          { newAccount: account },
          { blockLeaveBackground: true }
        )
      }

      this.loading = false
    }
  }
}
</script>
