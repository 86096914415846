<template>
  <div>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('form.title.card_address')" />
      <div class="form__fieldset__group">
        <div v-if="isAddressEditable"
             class="form__row row row--sm-bottom">
          <div class="form-group col col--sm-4">
            <toggle-switch :model-value="address.add_company_name"
                           name="add_company_name"
                           toggle-class="switch-btn--sm"
                           @update:model-value="value => updateAddress('add_company_name', value)" />
            <span class="switch-btn-label">{{ $t('form.address.use_company_name') }}</span>
          </div>
        </div>
        <div class="form__row row row--sm-bottom">
          <div v-if="address.add_company_name"
               class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.company.label") }}</label>
            <div class="input-group">
              <input :value="invitation.company"
                     type="text"
                     class="form-control form-control--noborder disabled"
                     disabled>
              <div class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
          <div class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.first_name.label") }}</label>
            <div class="input-group">
              <input :value="card.first_name"
                     type="text"
                     class="form-control form-control--noborder disabled"
                     disabled>
              <div class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
          <div class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.last_name.label") }}</label>
            <div class="input-group">
              <input :value="card.last_name"
                     type="text"
                     class="form-control form-control--noborder disabled"
                     disabled>
              <div class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
        </div>
        <div class="form__row row row--sm-bottom">
          <div class="form-group col col--sm"
               :class="{ error: errors.street }">
            <label class="label input__label">{{ $t("form.address.label") }}</label>
            <validation-field :value="address.street"
                              :disabled="!isAddressEditable"
                              type="text"
                              name="street"
                              class="form-control form-control--noborder"
                              @input="updateAddress('street', $event.target.value)" />
          </div>
        </div>
        <div v-if="isAddressEditable || address.details"
             class="form__row row row--sm-bottom">
          <div class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.address.details") }}</label>
            <input :value="address.details"
                   :disabled="!isAddressEditable"
                   type="text"
                   name="details"
                   class="form-control form-control--noborder"
                   @input="updateAddress('details', $event.target.value)">
          </div>
        </div>
        <div class="form__row row row--sm-bottom">
          <div class="form-group col col--sm-4"
               :class="{ error: errors.zip }">
            <label class="label input__label">{{ $t('form.postcode.label') }}</label>
            <validation-field id="postcode"
                              :value="address.zip"
                              :disabled="!isAddressEditable"
                              type="text"
                              class="form-control form-control--noborder"
                              :placeholder="$t('form.postcode.placeholder')"
                              name="postcode"
                              @input="updateAddress('zip', $event.target.value)" />
          </div>
          <div class="form-group col col--sm-4"
               :class="{ error: errors.city }">
            <label class="label input__label"
                   for="city">{{ $t('form.city.label') }}</label>
            <validation-field id="city"
                              :value="address.city"
                              :disabled="!isAddressEditable"
                              type="text"
                              class="form-control form-control--noborder"
                              :placeholder="$t('form.city.placeholder')"
                              name="city"
                              @input="updateAddress('city', $event.target.value)" />
          </div>
          <div class="form-group col col--sm-4"
               :class="{ error: errors.country }">
            <label class="label input__label">{{ $t('form.country.label') }}</label>
            <country-select :model-value="address.country"
                            :disabled="!isAddressEditable"
                            name="country"
                            data-vv-as="country"
                            @update:model-value="value => updateAddress('country', value)" />
          </div>
        </div>
      </div>
    </fieldset>
    <div v-if="isAddressEditable && !isImportedUser && address.add_company_name"
         class="form-check">
      <label class="form-check__label checkbox"
             data-cy="cards.create.form.accept">
        <validation-field :model-value="accept"
                          type="checkbox"
                          :value="true"
                          name="accept"
                          @change="updateField" />
        <span class="checkbox__icon" />
        {{ $t('prompt.cards.order.certify_address') }}
      </label>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/CountrySelect.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcCadenasSm,
    ToggleSwitch,
    CountrySelect,
    FieldsetTitle
  },

  props: {
    accept: {
      type: Boolean,
      default: false
    },

    address: {
      type: Object,
      required: true
    },

    card: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      required: true
    },

    invitation: {
      type: Object,
      required: true
    },

    isImportedUser: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:address',
    'update:accept'
  ],

  computed: {
    // Adresse de livraison fixe, TODO: permission édition de l'adresse de livraison
    isAddressEditable () {
      return false
    }
  },

  methods: {
    updateAddress (key, value) {
      this.$emit('update:address', { ...this.address, [key]: value })
    },

    updateField () {
      this.$emit('update:accept', !this.accept)
    }
  }
}
</script>
