<template>
  <svg viewBox="0 0 24 24">
    <path d="M13.412 14.172v4.345L20 11.76 13.412 5v4.345C5.362 9.345 4 18.517 4 19c1.882-4.345 6.588-5.31 9.412-4.828z" />
  </svg>
</template>

<script>
export default {
}
</script>
