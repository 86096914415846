<template>
  <transition name="account-picker">
    <div v-if="active"
         class="account-picker"
         data-cy="account-picker">
      <div class="account-picker__bg"
           @click.prevent.stop="hideAccountPicker" />
      <div class="account-picker__dialog">
        <div class="account-picker__content">
          <div class="account-picker-header">
            <a href="#"
               @click.prevent.stop="hideAccountPicker">
              <ic-arrow class="ic ic--24 ic-arrow-prev ic--white" />
            </a>
            {{ $t('general.my_accounts') }}
          </div>
          <div class="account-picker-body">
            <div v-if="numberOfAccounts > 5"
                 class="account-picker-body__search">
              <div class="input-group input-group--noborder">
                <span class="input-group__addon">
                  <ic-search class="ic ic--20 ic--white" />
                </span>
                <input v-model="filter"
                       class="form-control"
                       type="text"
                       :placeholder="$t('form.accounts.placeholder')"
                       @input="() => getUserAccounts()">
              </div>
            </div>
            <template v-if="!loading || accounts.length">
              <div v-for="(items, idxGrp) in groupedAccounts"
                   :key="idxGrp"
                   class="account-picker-body__block">
                <div class="title">
                  {{ idxGrp }}
                </div>
                <div v-for="(item, idxAcc) in items"
                     :key="`${idxAcc}_account`"
                     class="item"
                     :class="{ 'item--selected': account.uuid === item.uuid }"
                     :data-cy="`account-picker.account-${item.uuid}`"
                     :title="hasPermission($permissions.accountOwner, item) ? 'main-account' : 'guest-account'"
                     @click="onAccountClick(item)">
                  <div class="detail">
                    <strong>
                      <span>{{ item.label || 'Compte principal' }}</span>
                      <span v-if="isAccountClosing(item)">
                        - {{ $t('menu.detail.closing_account') }}
                      </span>
                      <span v-else-if="isAccountClosed(item)">
                        - {{ $t('menu.detail.closed_account') }}
                      </span>
                      <span v-else-if="item.status === 'sequestre'">
                        - {{ $t('menu.detail.sequestre') }}
                      </span>
                      <span v-else-if="isClassicAccount(item) && !hasPermission($permissions.accountOwner, item)">
                        - {{ $t('menu.detail.shared_access') }}
                      </span>
                    </strong>
                    <div v-if="hasRib(item)">
                      {{ item.rib.number }}
                    </div>
                  </div>
                  <div class="amount">
                    <template v-if="hasBalance(item) && hasPermission($permissions.accountBalanceRead, item)">
                      <span :class="[item.balance > 0 ? 'text-green' : 'text-red']">
                        {{ formatAmount(item.balance, item.currency) }}
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <loader-spinner v-if="loading"
                            color="white"
                            size="20" />
            <div v-else-if="accountsPagination.current < accountsPagination.count"
                 class="center">
              <button class="account-picker__see-more"
                      @click="getUserAccounts(true)">
                {{ $t('button.see_more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { isMainAccount, isSubAccount } from '@/types/account.d'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'

export default {
  components: {
    IcSearch,
    IcArrow,
    LoaderSpinner
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { account, accounts, accountsPagination } = storeToRefs(accountStore)
    const { numberOfAccounts } = storeToRefs(authStore)

    return {
      account,
      accounts,
      accountsPagination,
      accountStore,
      appStore,
      numberOfAccounts
    }
  },

  data () {
    return {
      store,
      active: false,
      loading: false,
      filter: '',
      enterTimer: null
    }
  },

  computed: {
    groupedAccounts () {
      return this.accounts.reduce((r, a) => {
        const name = a.company.name
        r[name] = r[name] || []
        r[name].push(a)
        return r
      }, Object.create(null))
    }
  },

  watch: {
    active (value) {
      if (!value) {
        this.filter = ''
      }
    }
  },

  mounted () {
    this.$bus.on('show-account-picker', this.showAccountPicker)
  },

  beforeUnmount () {
    this.$bus.off('show-account-picker', this.showAccountPicker)
  },

  methods: {
    formatAmount,

    getUserAccounts (nextPage = false) {
      if (this.enterTimer) { clearTimeout(this.enterTimer) }

      this.enterTimer = setTimeout(async () => {
        this.loading = true
        const filter = { search: this.filter }
        if (nextPage) {
          filter.page = this.accountsPagination.current + 1
        }
        await this.accountStore.getAccounts(filter)
        this.loading = false
      }, 500)
    },

    hideAccountPicker () {
      this.active = false
    },

    showAccountPicker () {
      this.getUserAccounts()
      this.active = true
    },

    hasBalance (accountItem) {
      return accountItem.balance !== null && Object.prototype.hasOwnProperty.call(accountItem, 'balance')
    },

    hasRib (item) {
      return item.rib && item.rib.number
    },

    onAccountClick (account) {
      this.changeAccount(account)
    },

    changeAccount (account) {
      this.accountStore.switchAccount(account.uuid)
      this.active = false
    },

    isClassicAccount (account) {
      return isSubAccount(account) || isMainAccount(account)
    },

    isAccountClosing (account) {
      return account.status_group === 'closing'
    },

    isAccountClosed (account) {
      return account.status_group === 'closed'
    }
  }
}
</script>
