<template>
  <modal-dialog-prompt :title="$t('prompt.close_account.title')"
                       @close="$emit('close')">
    <template #body>
      <p>{{ $t('prompt.close_account.content.closing_account') }} &nbsp;<strong>{{ accountToCloseLabel }}</strong> </p>
      <div>
        <p v-if="!canBeClosed"
           class="strong">
          {{ contentLabelImportant }}
        </p>
        <p>
          {{ contentLabel }}
        </p>
      </div>
    </template>

    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ cancelButtonLabel }}
      </button>
      <button :disabled="loading"
              type="button"
              class="btn btn--primary"
              @click="onSubmit">
        {{ buttonLabel }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: { ModalDialogPrompt },
  props: {
    account: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    return { accountStore, appStore }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    canBeClosed () {
      return this.account.balance === 0
    },

    isBalancePositive () {
      return this.account.balance > 0
    },

    isBalanceNegative () {
      return this.account.balance < 0
    },

    contentLabelImportant () {
      return this.isBalanceNegative
        ? this.$i18n.t('prompt.close_account.content.negative_important', { balance: this.account.balance })
        : this.$i18n.t('prompt.close_account.content.positive_important')
    },

    contentLabel () {
      if (this.isBalanceNegative) {
        return this.$i18n.t('prompt.close_account.content.negative')
      }
      if (this.isBalancePositive) {
        return this.$i18n.t('prompt.close_account.content.positive')
      }
      return this.$i18n.t('prompt.close_account.content.warning')
    },

    accountToCloseLabel () {
      return `${this.account.company.name} - ${this.account.type_label}`
    },

    buttonLabel () {
      if (this.isBalancePositive) {
        return this.$i18n.t('general.transfer')
      }
      if (this.isBalanceNegative) {
        return this.$i18n.t('button.ok_understood')
      }
      if (this.canBeClosed) {
        return this.$i18n.t('button.closing')
      }
      return 'unknown'
    },

    cancelButtonLabel () {
      return this.$i18n.t('button.cancel')
    }
  },

  methods: {
    async onSubmit () {
      if (this.canBeClosed) {
        const account = await this.accountStore.closeAccount(this.account.uuid)
        if (account) {
          this.$emit('close')
          this.appStore.closeSidePanel()
          showToastSuccess(this.$i18n.t('message.success.user.close_account'))
          this.$bus.emit('on-account-closed', account)
        }
      } else {
        if (this.isBalanceNegative) {
          this.$emit('close')
        } else if (this.isBalancePositive) {
          this.appStore.closeSidePanel()
          await this.$router.push({ name: 'transfers-create' })
        }
      }
    }
  }

}
</script>
