<template>
  <modal-dialog-placeholder data-cy="validation-modal"
                            @close="$emit('close')">
    <component-placeholder :button="$t('button.ok_understood')"
                           button-theme="default"
                           :label="$t(`placeholder.to_validate_${type}.title`)"
                           :content="$t(`placeholder.to_validate_${type}.subtitle`)"
                           @btn-click="$emit('close')">
      <image-guarantee />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script>
import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageGuarantee from '@/components/svg/img/guarantee.vue'

export default {
  components: {
    ComponentPlaceholder,
    ModalDialogPlaceholder,
    ImageGuarantee
  },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  emits: ['close']
}
</script>
