<template>
  <svg viewBox="0 0 24 24">
    <path d="M6.5 16.5v2h11v-2h2v3a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1v-3zm6.5-13a.5.5 0 0 1 .5.5v5.5H17l-5 6-5-6h3.5V4a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
