<template>
  <div class="section-actions">
    <button-filters />
    <component-popover v-if="hasPermission(physicalCard ? $permissions.cardsPhysicalWrite : $permissions.cardsVirtualWrite)"
                       popover-class="popover-actions-menu popover--pull-right"
                       trigger="hover">
      <template #trigger>
        <button class="btn btn--icon btn--white">
          <i class="btn-icon">
            <ic-settings class="ic ic--gray" />
          </i>
        </button>
      </template>
      <div class="popover__item"
           @click="$emit('lock-all-cards', physicalCard ? 'physical' : 'virtual')">
        <div class="popover__item__icon">
          <ic-cadenas-sm class="ic ic--16 ic--gray" />
        </div>
        <div>{{ $t(`cards_settings.popover.lock_all_${physicalCard ? 'physical' : 'virtual'}`) }}</div>
      </div>
      <div class="popover__item"
           @click="$emit('unlock-all-cards', physicalCard ? 'physical' : 'virtual')">
        <div class="popover__item__icon">
          <ic-cadenas-sm unlock
                         class="ic ic--16 ic--gray" />
        </div>
        <div>{{ $t(`cards_settings.popover.unlock_all_${physicalCard ? 'physical' : 'virtual'}`) }}</div>
      </div>
      <div v-if="physicalCard"
           class="popover__item"
           @click="$emit('export-cards')">
        <div class="popover__item__icon">
          <ic-export class="ic ic--gray" />
        </div>
        <div>{{ $t('cards_settings.popover.export_rights') }}</div>
      </div>
    </component-popover>
    <router-link v-if="displayOrderCta"
                 class="btn btn--primary"
                 data-cy="cards.create.button"
                 :to="getOrderCtaRedirect()">
      {{ orderButtonLabel }}
    </router-link>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'

export default {
  components: {
    IcCadenasSm,
    IcSettings,
    IcExport,
    ButtonFilters,
    ComponentPopover
  },

  props: {
    physicalCard: {
      type: Boolean,
      required: true
    }
  },

  emits: ['lock-all-cards', 'unlock-all-cards', 'export-cards'],

  setup () {
    const accountStore = useAccountStore()

    const { account, virtualCardTypes } = storeToRefs(accountStore)

    return { account, virtualCardTypes }
  },

  computed: {
    hasVirtualCardTypes () {
      return this.virtualCardTypes.length > 0
    },

    orderButtonLabel () {
      return !this.physicalCard && this.hasPermission(this.$permissions.cardsVirtualRequest)
        ? this.$i18n.t('generate_ecard.title_request')
        : this.$i18n.t(`button.${this.physicalCard ? 'order_card' : 'generate_ecard'}`)
    },

    displayOrderCta () {
      if (!this.physicalCard && !this.hasVirtualCardTypes) return false
      return !this.physicalCard && this.hasPermission(this.$permissions.cardsVirtualRequest)
        ? true
        : this.hasPermission(this.physicalCard ? this.$permissions.cardsPhysicalCreate : this.$permissions.cardsVirtualCreate)
    }
  },

  methods: {
    getOrderCtaRedirect () {
      if (!this.physicalCard && this.virtualCardTypes.length === 1) {
        return { name: 'generate-virtual-card', params: { cardType: this.virtualCardTypes[0] } }
      }
      return this.physicalCard ? { name: 'order-cards-choice' } : { name: 'generate-cards-choice' }
    }
  }
}
</script>
