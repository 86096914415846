<template>
  <section class="section section-debits">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.debits") }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
        </div>
      </div>
    </div>
    <component-filters :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange,
                         EFilter.TransactionType
                       ]"
                       unique
                       :number-of-results="numberOfResults"
                       :total-amount="debitsTotalAmount"
                       @filters="filters" />
    <tabs :data="segments" />
    <router-view :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useDebitStore } from '@/stores/debit'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'PageDebits',

  components: {
    ButtonFilters,
    ComponentFilters,
    Tabs
  },

  setup () {
    const debitStore = useDebitStore()
    const { debitsTotalAmount } = storeToRefs(debitStore)

    return { debitStore, debitsTotalAmount }
  },

  data () {
    return {
      numberOfResults: null,
      filter: {
        search: null,
        from: null,
        to: null,
        transaction_type: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.overview'),
          route: { name: 'debits' },
          active: true
        },
        {
          label: this.$i18n.t('button.mandates'),
          route: { name: 'mandates' },
          active: false
        }
      ]
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.pending'),
          route: { name: 'debits' }
        },
        {
          label: this.$i18n.tc('button.done'),
          route: { name: 'debits-done' }
        },
        {
          label: this.$i18n.tc('button.rejected'),
          route: { name: 'debits-rejected' }
        }
      ]
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        search: null,
        from: null,
        to: null,
        transaction_type: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to,
        transaction_type: filters.transaction_type
      }
    }
  }
}

</script>
