<template>
  <div class="sidepanel__content">
    <transition name="fade-delay">
      <file-viewer v-if="displayFileViewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <div class="sidepanel-header">
      <div class="transaction-summary">
        <h2 class="transaction-amount">
          {{ model.label }}
        </h2>
        <button class="btn btn--default btn-attachment btn-attachment--float"
                @click="showFile">
          <ic-document class="ic ic--white" />
        </button>
      </div>
    </div>
    <div class="sidepanel-body">
      <div class="sidepanel-details">
        <h3 class="head-title">
          {{ $t('sidepanel.mandate.title') }}
        </h3>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.add_date') }}</span>
          <span class="entry-value right">{{ formatDateText(model.created_at, 'll') }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.frequency') }}</span>
          <span class="entry-value right">{{ model.unique ? $t('general.unique') : $t('general.periodic') }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.creditor_sepa_id') }}</span>
          <span class="entry-value right">{{ model.ics }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.reference') }}</span>
          <span class="entry-value right">{{ model.rum }}</span>
        </div>
        <div v-if="model.comment"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("table.comment") }}</span>
          <span class="entry-value right">{{ model.comment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'

import FileViewer from '@/components/FileViewer.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'

export default {
  components: {
    IcDocument,
    FileViewer
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      displayFileViewer: false
    }
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.closeFileViewer)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.closeFileViewer)
  },

  methods: {
    formatDateText,

    closeFileViewer () {
      this.displayFileViewer = false
    },

    showFile () {
      this.appStore.showFileViewer({
        content: [this.model.document]
      })
      this.displayFileViewer = true
    }
  }
}
</script>
