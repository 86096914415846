<template>
  <svg viewBox="0 0 16 16">
    <path d="M14 14v2H2v-2h12ZM8 0l5.5 7-3.999.001L9.5 11.5a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5l.001-4.499L2.5 7 8 0Z" />
  </svg>
</template>

<script>
export default {
}
</script>
