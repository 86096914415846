<template>
  <div class="section__content mt-2">
    <div v-if="loading"
         class="section__loader">
      <core-loader />
    </div>
    <div v-else-if="rewards.length"
         class="rewards">
      <reward-block v-for="reward in rewards"
                    :key="reward.uuid"
                    :reward="reward" />
      <component-pagination class="pagination"
                            :pagination="loyaltyPagination" />
    </div>
    <component-placeholder v-else
                           :label="t('rewards.placeholder.label')" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreLoader } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import bus from '@/helpers/eventBus'
import { useLoyaltyStore } from '@/stores/loyalty'
import { IRewardLight } from '@/types/loyalty.d'

import RewardBlock from '@/pages/loyalty/components/RewardBlock.vue'

import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'

const { t } = useI18n()
const loyaltyStore = useLoyaltyStore()
const { loyaltyPagination } = storeToRefs(loyaltyStore)

const loading = ref(true)
const rewards = ref<IRewardLight[]>([])

const props = defineProps<{
  page: number
}>()

onMounted(() => {
  initData()

  bus.on('language-changed', initData)
})

onUnmounted(() => {
  bus.off('language-changed', initData)
})

watch(() => props.page, initData)

async function initData () {
  loading.value = true
  rewards.value = await loyaltyStore.getRewards(props.page)
  loading.value = false
}
</script>

<style lang="stylus" scoped>
.rewards
  display grid
  grid-template-columns repeat(3, 1fr)
  gap 2rem
  max-width 1600px

.pagination
  grid-column span 3
</style>
