<template>
  <div :data-cy="`users.invite.${type}-configuration.sidepanel`"
       class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t(`title.${type}s_validation`) }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <form class="form">
          <fieldset class="form__fieldset">
            <fieldset-title :title="$t('form.title.validation_rule')"
                            :show-number="showApprovalFlow" />
            <div class="form-group">
              <component-dropdown label="validationTypes"
                                  dropdown-width="100%"
                                  :dropdown-height="40"
                                  :disabled="!hasWritePermission"
                                  :values="validationTypes"
                                  :model="validation_type"
                                  @select="el => validation_type = el.value">
                <template #trigger>
                  <div class="select">
                    <div class="form-control">
                      {{ validationTypes.find(el => el.value === validation_type).label }}
                    </div>
                  </div>
                </template>
              </component-dropdown>
              <component-info v-if="isTransfer"
                              class="my-1"
                              size="sm">
                {{ validationTypes.find(el => el.value === validation_type).description }}
              </component-info>
            </div>
            <div v-if="isValidationConditional">
              <p class="bordered-box-text mt-5">
                {{ $t('form.transfer_limit.label') }}
              </p>
              <fieldset-block v-if="type !== 'mileage'">
                <limit-item :enabled="alert.enabled"
                            :disabled="!hasWritePermission"
                            :label="$t(`form.${type}_limit.single.label`)"
                            :limit="alert.limit"
                            @update-enabled="value => alert.enabled = value"
                            @update-limit="value => alert.limit = parseInt(value)" />
              </fieldset-block>
              <fieldset-block style="margin-top: 2.5rem">
                <limits-per-period :value="periods"
                                   :disabled="!hasWritePermission"
                                   :periods="type === 'mileage' ? ['monthly'] : ['daily', 'weekly', 'monthly']"
                                   item-class="bordered-box__item"
                                   data-cy="cards.settings.limit_transaction"
                                   @update-limit-settings="limit => updateLimitSettings(limit.period, limit.value)" />
              </fieldset-block>
            </div>
          </fieldset>
          <fieldset v-if="showApprovalFlow"
                    class="form__fieldset">
            <fieldset-title :title="$t('form.title.user_approval_workflow')"
                            show-number>
              <button v-if="expense_request_config.trigger && hasWritePermission"
                      v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                      :data-cy="`users.invite.${type}-configuration.expense-request.reset-button`"
                      class="btn-icon btn-icon--right"
                      type="button"
                      @click="toggleExpenseRequestConfig(false)">
                <ic-trash class="ic ic--gray ic--20" />
              </button>
            </fieldset-title>
            <template v-if="expense_request_config.trigger">
              <div class="form-group mb-3">
                <label class="label">{{ $t('form.approval_workflow_type.label') }}</label>
                <component-dropdown data-cy="expense-requests-type"
                                    label="type"
                                    :disabled="!hasWritePermission"
                                    dropdown-width="100%"
                                    :model="expense_request_config.type"
                                    :values="expenseRequestConfigTypes"
                                    @select="type => expense_request_config.type = type.value">
                  <template #trigger>
                    <div class="select">
                      <div class="form-control form-control">
                        <span v-if="expense_request_config.type">
                          {{ expenseRequestConfigTypes.find(type => type.value === expense_request_config.type).label }}
                        </span>
                        <span v-else
                              class="text-muted">
                          {{ $t('form.common.select') }}
                        </span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
              <div class="form-group mb-3">
                <p v-if="hasWritePermission"
                   class="label">
                  {{ expenseRequestConfigTypes.find(type => type.value === expense_request_config.type).description }}
                </p>
                <component-dropdown v-if="hasWritePermission"
                                    data-cy="expense-requests-approvers"
                                    class="mb-2"
                                    label="approvers"
                                    async-url="/users"
                                    :async-url-params="{ status: 'active' }"
                                    :async-results-filter="item => item.uuid !== invitationData.uuid"
                                    dropdown-width="100%"
                                    :dropdown-height="21"
                                    multiselect
                                    has-item-picture
                                    search
                                    :label-filter="item => item.name + (item.role_label ? `- ${item.role_label}` : '')"
                                    :model="expense_request_config.approvers"
                                    @select="onApproverSelect">
                  <template #trigger>
                    <div class="form-control d-flex align-items-center is-link">
                      <ic-plus class="ic ic--gray ic--22 mr-1" />
                      <span class="text-muted">{{ $t('button.add_approver') }}</span>
                    </div>
                  </template>
                </component-dropdown>
                <editable-workflow v-model="expense_request_config.approvers"
                                   :read-only="!hasWritePermission"
                                   data-cy="expense-requests-approvers"
                                   :type="expense_request_config.type" />
              </div>
            </template>

            <template v-else>
              <field-placeholder border="dashed"
                                 :data-cy="`users.invite.${type}-configuration.expense-request.placeholder`"
                                 :title="$t(`team.create.approval_workflow.placeholder.title`)"
                                 :subtitle="hasWritePermission ? $t(`team.create.approval_workflow.placeholder.subtitle`) : $t(`team.create.approval_workflow.placeholder.read_only`)"
                                 :read-only="!hasWritePermission"
                                 @click="hasWritePermission && toggleExpenseRequestConfig(true)" />
              <component-info class="my-1"
                              size="sm">
                {{ $t('team.create.approval_workflow.placeholder.info') }}
              </component-info>
            </template>
          </fieldset>
        </form>
      </div>
    </div>
    <component-sidepanel-footer v-if="hasWritePermission"
                                :key="validation_type">
      <div class="form-buttons">
        <button class="btn btn--gray"
                type="button"
                @click="appStore.closeSidePanel">
          {{ $t('button.cancel') }}
        </button>
        <component-button :data-cy="`users.invite.${type}-configuration.submit-button`"
                          :label="$t('button.validate')"
                          class="btn btn--primary"
                          @click="onSubmit" />
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useInvitationStore } from '@/stores/invitation'
import { useUserStore } from '@/stores/user'

import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import EditableWorkflow from '@/components/EditableWorkflow.vue'
import FieldPlaceholder from '@/components/FieldPlaceholder.vue'
import FieldsetBlock from '@/components/FieldsetBlock.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ComponentInfo from '@/components/Info.vue'
import LimitItem from '@/components/limits/LimitItem.vue'
import LimitsPerPeriod from '@/components/limits/LimitsPerPeriod.vue'
import Confirm from '@/components/modals/Confirm.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'
import IcTrash from '@/components/svg/icons/ic-trash.vue'

export default {
  components: {
    FieldsetTitle,
    FieldPlaceholder,
    LimitItem,
    FieldsetBlock,
    LimitsPerPeriod,
    IcTrash,
    ComponentInfo,
    IcPlus,
    EditableWorkflow,
    ComponentDropdown,
    ComponentButton,
    ComponentSidepanelFooter
  },

  props: {
    type: {
      type: String,
      required: true
    },

    invitationData: {
      type: Object,
      default: () => null
    },

    limits: {
      type: Object,
      required: true
    },

    isEditingUser: {
      type: Boolean,
      required: true
    },

    expenseRequestConfig: {
      type: Object,
      default: null
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const invitationStore = useInvitationStore()
    const userStore = useUserStore()

    const { account } = storeToRefs(accountStore)

    return { appStore, invitationStore, userStore, account }
  },

  data () {
    return {
      periods: {
        daily: {
          enabled: false,
          limit: 0
        },

        weekly: {
          enabled: false,
          limit: 0
        },

        monthly: {
          enabled: false,
          limit: 0
        }
      },

      alert: {
        enabled: false,
        limit: 0
      },

      validation_type: '',
      formSubmitted: false,
      activeFields: [],
      expense_request_config: null
    }
  },

  computed: {
    isTransfer () {
      return this.type === 'transfer'
    },

    isValidationConditional () {
      return this.validation_type === 'conditional'
    },

    isValidationDisabled () {
      return this.validation_type === 'novalidation'
    },

    hasWritePermission () {
      return this.isEditingUser
        ? this.hasPermission(this.$permissions.usersWrite)
        : this.hasPermission(this.$permissions.invitationsWrite)
    },

    validationTypes () {
      return [
        {
          label: this.$i18n.t('form.validation_type.systematic_validation'),
          description: this.$i18n.t('form.validation_type.description.systematic'),
          value: 'systematic'
        },
        {
          label: this.$i18n.t('form.validation_type.conditional_validation'),
          description: this.$i18n.t('form.validation_type.description.conditional'),
          value: 'conditional'
        },
        {
          label: this.$i18n.t('form.validation_type.no_validation'),
          description: this.$i18n.t('form.validation_type.description.no_validation'),
          value: 'novalidation'
        }
      ]
    },

    expenseRequestConfigTypes () {
      return [
        {
          label: this.$i18n.t('form.approval_workflow_type.unique.label'),
          description: this.$i18n.t('form.approval_workflow_type.unique.description'),
          value: 'simple'
        },
        {
          label: this.$i18n.t('form.approval_workflow_type.multiple.label'),
          description: this.$i18n.t('form.approval_workflow_type.multiple.description'),
          value: 'multiple'
        },
        {
          label: this.$i18n.t('form.approval_workflow_type.chain.label'),
          description: this.$i18n.t('form.approval_workflow_type.chain.description'),
          value: 'ordered'
        }
      ]
    },

    limitsPayload () {
      if (this.isValidationConditional) {
        return {
          alert_limit: this.alert.enabled ? this.alert.limit : null,
          daily_limit: this.periods.daily.enabled ? this.periods.daily.limit : null,
          weekly_limit: this.periods.weekly.enabled ? this.periods.weekly.limit : null,
          monthly_limit: this.periods.monthly.enabled ? this.periods.monthly.limit : null
        }
      } else if (this.validation_type === 'systematic') {
        return {
          alert_limit: 0,
          daily_limit: 0,
          weekly_limit: 0,
          monthly_limit: 0
        }
      } else {
        return {
          alert_limit: null,
          daily_limit: null,
          weekly_limit: null,
          monthly_limit: null
        }
      }
    },

    expenseRequestConfigPayload () {
      if (this.expense_request_config) {
        if (!this.expense_request_config.approvers?.length) {
          return {
            trigger: null,
            type: 'simple',
            approvers: []
          }
        } else if (this.expense_request_config.approvers?.length === 1) {
          return {
            trigger: 'always',
            type: 'simple',
            approvers: this.expense_request_config.approvers
          }
        }
        return this.expense_request_config
      }
      return null
    },

    showApprovalFlow () {
      return this.isTransfer && !this.isValidationDisabled && hasFeature(EFeature.TransferApprovalFlow)
    }
  },

  created () {
    this.activeFields = this.type === 'mileage' ? ['monthly'] : ['alert', 'daily', 'weekly', 'monthly']

    this.alert.enabled = !isNaN(parseFloat(this.limits.alert_limit))
    this.periods.daily.enabled = !isNaN(parseFloat(this.limits.daily_limit))
    this.periods.weekly.enabled = !isNaN(parseFloat(this.limits.weekly_limit))
    this.periods.monthly.enabled = !isNaN(parseFloat(this.limits.monthly_limit))

    this.alert.limit = this.alert.enabled ? this.limits.alert_limit : 0
    this.periods.daily.limit = this.periods.daily.enabled ? this.limits.daily_limit : 0
    this.periods.weekly.limit = this.periods.weekly.enabled ? this.limits.weekly_limit : 0
    this.periods.monthly.limit = this.periods.monthly.enabled ? this.limits.monthly_limit : 0

    const limits = this.activeFields.map(name => this.limits[`${name}_limit`])

    if (limits.every(i => i === null)) {
      this.validation_type = 'novalidation'
    } else if (limits.every(i => parseFloat(i) === 0)) {
      this.validation_type = 'systematic'
    } else {
      this.validation_type = 'conditional'
    }

    this.expense_request_config = cloneDeep(this.expenseRequestConfig)
  },

  mounted () {
    this.$bus.on('confirm-update-invitation', this.updateInvitation)
  },

  beforeUnmount () {
    this.$bus.off('confirm-update-invitation', this.updateInvitation)
  },

  methods: {
    getCurrencySymbol,

    onApproverSelect (values) {
      this.expense_request_config.approvers = cloneDeep(values)
    },

    async onSubmit () {
      this.formSubmitted = true

      if (this.expense_request_config?.trigger) {
        if (this.expense_request_config.approvers.length === 0) {
          return this.appStore.showModal(
            Confirm,
            {
              title: this.$i18n.t('prompt.invitation.expense_request_config_warning.title'),
              content: this.$i18n.t('prompt.invitation.expense_request_config_warning.content.no_user_selected'),
              confirm: this.$i18n.t('button.reset'),
              event: 'update-invitation'
            },
            { wrapperClass: 'modal--xs' }
          )
        } else if (!this.expense_request_config.approvers.some(user => hasFeature(EFeature.TransferCreation, user))) {
          return this.appStore.showModal(
            Confirm,
            {
              title: this.$i18n.t('prompt.invitation.expense_request_config_warning.title'),
              content: this.$i18n.t('prompt.invitation.expense_request_config_warning.content.no_transfer_right_user_selected'),
              confirm: this.$i18n.t('button.validate'),
              event: 'update-invitation'
            },
            { wrapperClass: 'modal--xs' }
          )
        }
      }

      this.updateInvitation()
    },

    updateInvitation () {
      if (this.type === 'transfer') {
        this.invitationStore.setInvitationTransferLimit(this.limitsPayload)
        this.invitationStore.setInvitationExpenseRequestConfig(this.expenseRequestConfigPayload)
      } else if (this.type === 'refund' || this.type === 'payment') {
        this.invitationStore.setInvitationRefundLimit(this.limitsPayload)
      } else if (this.type === 'mileage') {
        this.invitationStore.setInvitationMileageLimit(this.limitsPayload)
      }

      this.updateExistingLimits(this.limitsPayload)

      this.appStore.closeSidePanel()
    },

    updateLimitSettings (period, value) {
      this.periods[period] = value
    },

    updateExistingLimits (limits) {
      if (!this.isEditingUser) { return }
      if (!['transfer', 'refund', 'payment', 'mileage'].includes(this.type)) { return }

      let invitation = {}
      if (this.type === 'transfer') {
        invitation = {
          alert_transfer_limit: limits.alert_limit,
          daily_transfer_limit: limits.daily_limit,
          weekly_transfer_limit: limits.weekly_limit,
          monthly_transfer_limit: limits.monthly_limit
        }
        if (this.showApprovalFlow) {
          invitation.expense_request_config = this.expenseRequestConfigPayload
        }
      } else if (['refund', 'payment'].includes(this.type)) {
        invitation = {
          alert_refund_limit: limits.alert_limit,
          daily_refund_limit: limits.daily_limit,
          weekly_refund_limit: limits.weekly_limit,
          monthly_refund_limit: limits.monthly_limit
        }
      } else if (this.type === 'mileage') {
        invitation = {
          monthly_mileage_limit: limits.monthly_limit
        }
      }

      this.invitationData.uuid
        ? this.userStore.updateUser(this.invitationData.uuid, invitation)
        : this.invitationStore.updateInvitation(this.invitationData.id, invitation)
    },

    toggleExpenseRequestConfig (state) {
      this.expense_request_config = {
        trigger: state ? 'always' : null,
        type: 'simple',
        approvers: []
      }
    }
  }
}
</script>
