<template>
  <svg viewBox="0 0 24 24">
    <path v-if="filled"
          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11.16.04 2.601-2.626a.834.834 0 0 0 0-1.171.815.815 0 0 0-1.16 0L12 10.869 9.399 8.243a.815.815 0 0 0-1.16 0 .834.834 0 0 0 0 1.171l2.6 2.627-2.52 2.545a.834.834 0 0 0 0 1.171c.32.324.84.324 1.16 0L12 13.212l2.52 2.545c.321.324.84.324 1.16 0a.834.834 0 0 0 0-1.171l-2.52-2.546Z" />
    <path v-else
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924zm2.95 5.424.082.07a.666.666 0 0 1-.003.939l-2.094 2.094 2.03 2.029c.234.234.26.596.073.856l-.07.082a.666.666 0 0 1-.939-.003L12 13l-2.03 2.03a.663.663 0 0 1-.855.073l-.083-.07a.666.666 0 0 1 .003-.938l2.03-2.03L8.97 9.97a.663.663 0 0 1-.073-.856l.07-.083a.666.666 0 0 1 .938.003L12 11.13l2.095-2.094a.663.663 0 0 1 .856-.073z" />

  </svg>
</template>

<script>
export default {
  props: {
    filled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
