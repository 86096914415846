<template>
  <div class="modal__content center">
    <modal-header :title="getModalTitle"
                  align-title="center"
                  @close="$emit('close')" />
    <div class="modal__body">
      <div class="user-proof-content">
        <div class="user-proof-btn is-link"
             @click="showFile(invitation.identity_card_documents)">
          <core-illustration :name="ECoreIllustrationList.IdentityProof"
                             :size="160" />
          <p class="btn-link btn-link--upper">
            {{ $tc('button.show_proofs', 1) }}
          </p>
        </div>
        <p>{{ getModalContent }}</p>
      </div>
    </div>
    <div class="modal__footer modal__footer--centered">
      <button v-if="invitationIsToValidateByBank"
              type="button"
              class="btn btn--default placeholder-btn"
              @click="$emit('close')">
        {{ $t('button.ok_understood') }}
      </button>
      <div v-else>
        <button type="button"
                class="btn btn--gray"
                :disabled="isRefuseLoading"
                @click="onRefuse">
          {{ $t('button.refuse') }}
        </button>
        <button type="button"
                class="btn btn--primary"
                :disabled="loading"
                @click="onValidate">
          {{ $t('button.validate') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CoreIllustration, ECoreIllustrationList } from '@common/core-ui'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useInvitationStore } from '@/stores/invitation'

import ModalHeader from '@/components/modal-template/ModalHeader.vue'

export default {
  components: {
    ModalHeader,
    CoreIllustration
  },

  props: {
    invitation: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const invitationStore = useInvitationStore()

    return { appStore, invitationStore }
  },

  data () {
    return {
      loading: false,
      isRefuseLoading: false
    }
  },

  computed: {
    ECoreIllustrationList () {
      return ECoreIllustrationList
    },

    userName () {
      return `${this.invitation.first_name} ${this.invitation.last_name}`.trim()
    },

    invitationIsToValidateByBank () {
      return this.invitation.status === 'to_validate_by_bank'
    },

    getModalTitle () {
      return this.invitationIsToValidateByBank
        ? this.$i18n.t('title.request_pending_validation')
        : this.$i18n.t('title.validate_user')
    },

    getModalContent () {
      return this.invitationIsToValidateByBank
        ? this.$i18n.t('prompt.invitation.request_pending_validation.content')
        : this.$i18n.t('prompt.invitation.validate.content', { userName: this.userName })
    }
  },

  methods: {
    async onValidate () {
      this.loading = true
      const success = await this.invitationStore.validateInvitation(this.invitation.id)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.invitation.validate'))
        this.$emit('close')
        this.$bus.emit('validate-user-invitation')
      }
      this.loading = false
    },

    async onRefuse () {
      this.isRefuseLoading = true
      const success = await this.invitationStore.refuseInvitation(this.invitation.id)
      this.isRefuseLoading = false
      if (success) {
        this.$emit('close')
        this.$bus.emit('refuse-user-invitation')
      }
    },

    showFile (files) {
      this.appStore.showFileViewer({
        isMultiDoc: true,
        documentsAllUrl: this.invitation.identity_card.url,
        content: files,
        displayFullScreen: true
      })
    }
  }
}
</script>
