import dayjs from 'dayjs'

import { formatAmount } from '@/helpers/utils/number'
import { capitalizeFirstLetter } from '@/helpers/utils/text'
import { Model } from '@/models/Model'
import {
  ETreasuryResumeEvolutionType,
  ITreasuryResume, TreasuryResumeEvolution,
  TreasuryResumePoint
} from '@/types/treasury.d'

export class ModelTreasuryResume extends Model {
  readonly low_point?: TreasuryResumePoint
  readonly high_point?: TreasuryResumePoint
  readonly evolution?: TreasuryResumeEvolution
  private constructor (properties: Partial<ITreasuryResume>) {
    super()

    if (properties.low_point) {
      this.low_point = { ...properties.low_point, date: dayjs.tz(properties.low_point.date, 'Europe/Paris') }
    }

    if (properties.high_point) {
      this.high_point = { ...properties.high_point, date: dayjs.tz(properties.high_point.date, 'Europe/Paris') }
    }

    if (properties.evolution) {
      this.evolution = { ...properties.evolution, first_date: dayjs.tz(properties.evolution.first_date, 'Europe/Paris'), end_date: dayjs.tz(properties.evolution.end_date, 'Europe/Paris') }
    }

    return this.onConstructed()
  }

  static create (properties: Partial<ITreasuryResume>): ModelTreasuryResume {
    return new ModelTreasuryResume(properties || {})
  }

  get lowPointBalanceFormatted (): string {
    return formatAmount(this.low_point?.balance, null, { maximumFractionDigits: 0 })
  }

  get highPointBalanceFormatted (): string {
    return formatAmount(this.high_point?.balance, null, { maximumFractionDigits: 0 })
  }

  get lowPointDateFormatted (): string {
    return capitalizeFirstLetter(this.low_point?.date.format('MMM YYYY'))
  }

  get highPointDateFormatted (): string {
    return capitalizeFirstLetter(this.high_point?.date.format('MMM YYYY'))
  }

  get evolutionPercentFormatted (): string {
    return `${this.evolution?.percentage}%`
  }

  get evolutionPercentType (): ETreasuryResumeEvolutionType {
    if (this.evolution && this.evolution.percentage > 0) return ETreasuryResumeEvolutionType.Positive
    else if (this.evolution && this.evolution.percentage < 0) return ETreasuryResumeEvolutionType.Negative

    return ETreasuryResumeEvolutionType.Zero
  }

  get evolutionFirstDateFormatted (): string {
    return this.evolution?.first_date.format(this.evolution?.first_date.year() !== this.evolution?.end_date.year() ? 'MMMM YYYY' : 'MMMM')
  }

  get evolutionEndDateFormatted (): string {
    return this.evolution?.end_date.format('MMMM YYYY')
  }
}
