import lang from '@/locales/fr/fr.yml'

export default {
  required: function (field) {
    if (lang.form.errors.required[field] !== 'undefined') {
      return lang.form.errors.required[field]
    } else {
      return 'Ce champ est requis'
    }
  },
  email: function (field) {
    if (lang.form.errors.email[field] !== 'undefined') {
      return lang.form.errors.email[field]
    } else {
      return 'Ce champ n\'est pas valide'
    }
  },
  decimal: function (field) {
    if (lang.form.errors.decimal[field] !== 'undefined') {
      return lang.form.errors.decimal[field]
    } else {
      return 'Ce champ n\'est pas valide'
    }
  },
  digits: function (field) {
    if (lang.form.errors.digits[field] !== 'undefined') {
      return lang.form.errors.digits[field]
    } else {
      return 'Ce champ n\'est pas valide'
    }
  },
  regex: function (field) {
    if (lang.form.errors.regex[field] !== 'undefined') {
      return lang.form.errors.regex[field]
    } else {
      return 'Ce champ n\'est pas valide'
    }
  },
  confirmed: function (field) {
    if (lang.form.errors.confirmed[field] !== 'undefined') {
      return lang.form.errors.confirmed[field]
    } else {
      return 'Ce champ ne correspond pas'
    }
  },
  date_format: function (field) {
    if (lang.form.errors.date_format[field] !== 'undefined') {
      return lang.form.errors.date_format[field]
    } else {
      return 'La date doit être au format JJ/MM/AAAA'
    }
  }
}
