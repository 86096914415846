<template>
  <svg class="amount-gauge"
       viewBox="0 0 300 150">
    <path id="arc-background"
          stroke-linecap="round"
          fill="none"
          :stroke="emptyGaugeColor"
          stroke-width="5" />
    <path v-show="radiusFill"
          id="arc-fill"
          stroke-linecap="round"
          fill="none"
          :stroke="color"
          stroke-width="5" />
  </svg>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true
    },

    white: {
      type: Boolean,
      default: false
    },

    width: {
      type: Number,
      default: 300
    }
  },

  computed: {
    color () {
      if (this.white) { return '#ffffff' }

      if (this.percentage < 50) { return '#1BE3B9' }
      return this.percentage < 75 ? '#FFC963' : '#FF4157'
    },

    emptyGaugeColor () {
      return this.white ? 'rgba(255,255,255, 0.3)' : '#F0F2F6'
    },

    radiusFill () {
      const result = Math.round(this.percentage * 180 / 100)
      return Math.min(result, 180)
    }
  },

  watch: {
    percentage () {
      this.init()
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      document.getElementById('arc-background').setAttribute('d', this.describeArc(150, 150, 140, 0, 180))
      if (this.radiusFill) {
        document.getElementById('arc-fill').setAttribute('d', this.describeArc(150, 150, 140, 0, this.radiusFill))
      }
    },

    polarToCartesian (centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = ((angleInDegrees) * Math.PI / 180.0)

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      }
    },

    describeArc (x, y, radius, startAngle, endAngle) {
      const start = this.polarToCartesian(x, y, radius, endAngle + 180)
      const end = this.polarToCartesian(x, y, radius, startAngle + 180)

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

      return [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(' ')
    }
  }
}
</script>

<style lang="stylus" scoped>
.amount-gauge
  width 30rem
  overflow visible
</style>
