f<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t("title.my_profile") }}
        </h1>
      </div>
    </div>
    <tabs :data="segments" />
    <div class="section__content">
      <router-view />
    </div>
  </section>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'

import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    IcArrow,
    Tabs
  },

  computed: {
    segments () {
      return [
        {
          label: this.$i18n.t('title.my_informations'),
          route: { name: 'account-info' },
          visible: true
        },
        {
          label: this.$i18n.t('button.bank_details'),
          route: { name: 'account-info-bank-details' },
          visible: true
        },
        {
          label: this.$i18n.t('button.my_vehicle'),
          route: { name: 'account-info-vehicle' },
          visible: hasFeature(EFeature.Mileage)
        }
      ].filter(route => route.visible)
    }
  }
}
</script>
