<template>
  <section class="section"
           data-cy="account.create.term.page">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <button class="btn-link btn-back"
                  @click="goBack">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </button>
          {{ $t('create_sub_account.title') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <router-view v-else
                   v-slot="{ Component }"
                   :term-account-rates="termAccountRates">
        <component :is="Component"
                   ref="form" />
      </router-view>
    </div>
  </section>
</template>

<script>
import { useAccountStore } from '@/stores/account'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow,
    LoaderSpinner
  },

  setup () {
    const accountStore = useAccountStore()

    return {
      accountStore
    }
  },

  data () {
    return {
      loading: true,
      termAccountRates: []
    }
  },

  async mounted () {
    this.termAccountRates = await this.accountStore.getTermAccountRates()
    this.loading = false
  },

  methods: {
    goBack () {
      if (this.$refs.form.getPreviousStep) {
        this.$refs.form.getPreviousStep()
      } else {
        this.$router.push({ name: 'create-sub-account' })
      }
    }
  }
}
</script>
