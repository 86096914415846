<template>
  <svg viewBox="0 0 24 24">
    <path v-if="height"
          d="M11.593 5.308a.25.25 0 0 1 .414 0L14.57 9.11a.25.25 0 0 1-.208.39H9.24a.25.25 0 0 1-.208-.39l2.562-3.802Zm.389 13.422a.219.219 0 0 1-.363 0l-2.62-3.889a.219.219 0 0 1 .181-.341h5.24c.175 0 .28.196.181.341l-2.62 3.89Z" />
    <path v-else-if="width"
          d="M4.108 12.207a.25.25 0 0 1 0-.414L7.91 9.23a.25.25 0 0 1 .39.208v5.122c0 .2-.224.32-.39.208l-3.802-2.562Zm15.422-.388a.219.219 0 0 1 0 .362l-3.889 2.62a.219.219 0 0 1-.341-.181V9.38c0-.175.196-.28.341-.181l3.89 2.62Z" />
    <path d="M4.8 4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h14c.621 0 1.125-.504 1.125-1.125V6c0-.621-.504-1.125-1.125-1.125h-14ZM1.925 6A2.875 2.875 0 0 1 4.8 3.125h14A2.875 2.875 0 0 1 21.675 6v12a2.875 2.875 0 0 1-2.875 2.875h-14A2.875 2.875 0 0 1 1.925 18V6Z" />
  </svg>
</template>

<script>
export default {
  props: {
    height: Boolean,
    width: Boolean
  }
}
</script>
