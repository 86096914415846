<template>
  <div class="sidepanel__content">
    <div class="sidepanel-header">
      <div class="transaction-summary">
        <h2 class="transaction-amount">
          {{ formatAmount(model.amount, model.currency) }}
        </h2>
        <p class="transaction-name">
          {{ model.label }}
        </p>
      </div>
    </div>
    <div class="sidepanel-body">
      <div class="sidepanel-details">
        <h3 class="head-title">
          {{ $t('sidepanel.transfer.title') }}
        </h3>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.reception_date') }}</span>
          <span class="entry-value right">{{ formatDateText(model.settlement_date, 'll') }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.debtor_name') }}</span>
          <span class="entry-value right">{{ model.debtor_name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatAmount,

    formatDateText
  }
}
</script>
