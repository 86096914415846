<template>
  <section class="section section-account">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ t("title.check_remittance") }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <div class="section__content-block--centered section__content--w780">
        <component-placeholder :label="t('check_remittance.title')"
                               :button="t('check_remittance.button')"
                               :content="t('check_remittance.content')"
                               :disabled="loading"
                               @btn-click="downloadCheckRemittance">
          <core-illustration :name="ECoreIllustrationList.BankCheckDeposit"
                             :size="200" />
        </component-placeholder>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreIllustration, ECoreIllustrationList } from '@common/core-ui'

import { downloadFileFromBlob } from '@/helpers/utils'
import { useAccountStore } from '@/stores/account'

import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

const { t } = useI18n()

const accountStore = useAccountStore()

const loading = ref(false)

async function downloadCheckRemittance () {
  loading.value = true
  const file = await accountStore.getCheckRemittanceSlipFile()
  if (file) {
    const blob = new Blob([file], { type: 'application/pdf' })
    downloadFileFromBlob(blob, t('check_remittance.file_name'))
  }
  loading.value = false
}
</script>
