<template>
  <div class="fs-page__content page-wire-check">
    <h2 class="text-dark pre-line">
      {{ $t('wirecheck.title') }}
    </h2>
    <p>{{ $t('wirecheck.description') }}</p>
    <form autocomplete="off"
          @submit.prevent="onSubmit">
      <div ref="verificationKeys"
           class="form-group">
        <input v-for="(n, i) in keyLength"
               :key="i"
               type="text"
               class="form-control"
               maxlength="1"
               :disabled="n !== 1 && verificationKey === ''"
               @keyup="handleVerificationKey"
               @keydown="handlePasteKey"
               @paste.prevent="handlePasteVerificationKey"
               @click="handleClickVerificationKey">
      </div>
      <button class="btn btn--block btn--primary btn--strong"
              :disabled="verificationKey.length !== keyLength">
        {{ $t('button.check') }}
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'WirecheckForm',

  data () {
    return {
      isCtrl: false,
      keyLength: 6,
      verificationKey: ''
    }
  },

  mounted () {
    this.$refs.verificationKeys.children[0].focus()
  },

  methods: {
    handleClickVerificationKey (e) {
      if (e.target.disabled) {
        this.$refs.verificationKeys.children[0].focus()
      }
    },

    handlePasteKey (e) {
      if (e.which === 91 || e.which === 17) {
        this.isCtrl = true
      }
    },

    handlePasteVerificationKey (e) {
      const verificationKey = e.clipboardData.getData('Text')
      if (verificationKey.length > 0) {
        const verificationKeys = verificationKey.split('')
        let row = 0
        verificationKeys.forEach(key => {
          if (this.$refs.verificationKeys.children[row]) {
            this.$refs.verificationKeys.children[row].value = key
            this.$refs.verificationKeys.children[row].disabled = false
            this.$refs.verificationKeys.children[row].focus()
            row += 1
          } else {
            this.$refs.verificationKeys.children[row - 1].blur()
          }
        })

        this.verificationKey = this.makeVerificationKey()
      }
    },

    handleVerificationKey (e) {
      const key = e.target.value

      this.verificationKey = this.makeVerificationKey()
      if (key && e.which !== 32 && !this.isCtrl) {
        if (e.target.nextSibling?.focus) {
          e.target.nextSibling.disabled = false
          e.target.nextSibling.focus()
        }
      } else if (e.which === 8 && e.target.previousSibling?.focus) {
        e.target.previousSibling.focus()
      } else if (e.which === 32) {
        e.target.value = ''
      }

      if (e.which === 91 || e.which === 17) {
        this.isCtrl = false
      }
    },

    makeVerificationKey () {
      let verificationKey = ''
      Array.prototype.forEach.call(this.$refs.verificationKeys.children, node => (verificationKey += node.value.toUpperCase()))

      return verificationKey
    },

    async onSubmit () {
      if (this.verificationKey.length === this.keyLength) {
        await this.$router.push({ name: 'wirecheck-detail', params: { id: this.verificationKey } })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.page-wire-check
  max-width 45rem

  +min-screen($md-screen)
    padding 5rem 2rem

  +ie11()
    +min-screen($sm-screen)
      padding-left 4rem
      padding-right @padding-left
      position absolute
      width 100%
      top 50%
      left 50%
      transform translate(-50%, -50%)

  h2
    font-size 2.4rem
    margin-bottom 3rem
    line-height 1.2
    +min-screen($sm-screen)
      font-size 4rem

  p
    margin-bottom 3rem
    font-size 1.6rem
    color $colorShinyGray

  form
    .form-group
      display flex
      flex-direction row
      padding-left 0
      padding-right @padding-left
      margin-bottom 3rem

      input
        font-size 2rem
        text-align center
        text-transform uppercase
        margin-left -1px
        color $colorMidGray
        background $colorWhite
        padding 0.5rem
        border 1px solid #dbdfe3
        width 100%
        min-width 0
        flex 1
        transition border-color 0.3s ease, background 0.3s ease, color 0.3s ease
        height 5.2rem
        &:not(:first-child)
          border-top-left-radius 0
          border-bottom-left-radius 0
        &:not(:last-child)
          border-top-right-radius 0
          border-bottom-right-radius 0

        +min-screen($md-screen)
          padding 1.3rem 1.5rem
          font-size 3rem
          height 7.6rem
</style>
