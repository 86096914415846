<template>
  <svg width="200"
       height="160"
       viewBox="0 0 200 160"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4739_769)">
      <path d="M138.438 63.1827H61.5625V62.1827H138.438V63.1827Z"
            fill="#D5D7DD" />
      <path d="M61.5625 69.7077H138.438V68.7077H61.5625V69.7077Z"
            fill="#D5D7DD" />
      <path d="M138.438 76.2328H61.5625V75.2328H138.438V76.2328Z"
            fill="#D5D7DD" />
      <path d="M61.5625 82.7578H138.438V81.7578H61.5625V82.7578Z"
            fill="#D5D7DD" />
      <path d="M61.5625 89.2827H138.438V88.2827H61.5625V89.2827Z"
            fill="#D5D7DD" />
      <path d="M61.5 55.6577V95.9077H138.438V94.9077H133.5V70.125H130.5V94.9077H126.5V72.125H123.5V94.9077H119.5V60.875H116.5V94.9077H112.5V72.125H109.5V94.9077H105.5V76.875H102.5V94.9077H98.5V74.125H95.5V94.9077H84.5V76.875H81.5V94.9077H77.5V74.125H74.5V94.9077H70.5V60.2827H67.5V94.9077H62.5V55.6577H61.5Z"
            fill="#BCC0C5" />
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46 42H154C156.623 42 158.75 44.1266 158.75 46.75V114.75C158.75 117.373 156.623 119.5 154 119.5H112.846L113.785 127.243C114.277 131.299 117.657 134.37 121.715 134.496L121.974 134.5H123V136H78V134.5H79.025C83.1105 134.5 86.5662 131.513 87.1798 127.5L87.2151 127.243L88.1536 119.5H46C43.3766 119.5 41.25 117.373 41.25 114.75V46.75C41.25 44.1266 43.3766 42 46 42ZM89.6646 119.5L88.7042 127.423C88.3545 130.308 86.7681 132.771 84.515 134.308L84.219 134.5H116.78L116.738 134.475C114.423 132.999 112.762 130.568 112.332 127.694L112.296 127.423L111.335 119.5H89.6646ZM154 43.5H46C44.2051 43.5 42.75 44.9551 42.75 46.75V105.75H157.25V46.75C157.25 44.9551 155.795 43.5 154 43.5ZM42.75 114.75V107.25H157.25V114.75C157.25 116.545 155.795 118 154 118H46C44.2051 118 42.75 116.545 42.75 114.75Z"
            fill="#424853" />
      <rect x="88.5"
            y="72.125"
            width="3"
            height="23.125"
            :fill="colorPrimary" />
    </g>
    <defs>
      <clipPath id="clip0_4739_769">
        <rect width="118"
              height="94"
              fill="white"
              transform="translate(41 42)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  name: 'ImageComputerChart',

  props: {
    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    }
  }
}
</script>
