<template>
  <div id="security"
       data-cy="cards.settings.section.security"
       class="form__fieldset">
    <group-title :title="$t('form.title.security_settings')" />
    <div class="form__fieldset__group">
      <div class="row">
        <div v-if="!isVirtualCard || (isVirtualCard && hasSettingField('mobile_payment'))"
             class="col col--sm-6">
          <div v-if="!isVirtualCard"
               class="form__fieldset__block">
            <div class="item-left">
              <ic-card contactless
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('card.security.contactless.title') }}</span>
            </div>
            <div class="item-right">
              <toggle-switch :model-value="settings.nfc"
                             data-cy="cards.settings.security.nfc-toggle"
                             :disabled="disabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="value => updateCardSettings('nfc', value)" />
            </div>
          </div>
          <div v-if="!isVirtualCard"
               class="form__fieldset__block">
            <div class="item-left">
              <ic-card swipe
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('card.security.magnetic_stripe.title') }}</span>
            </div>
            <div class="item-right">
              <toggle-switch :model-value="settings.magnetic_strip"
                             data-cy="cards.settings.security.magnetic-strip-toggle"
                             :disabled="disabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="value => updateCardSettings('magnetic_strip', value)" />
            </div>
          </div>
          <div v-if="hasSettingField('mobile_payment')"
               class="form__fieldset__block">
            <div class="item-left">
              <ic-mobile-payment class="ic ic--28 ic--gray" />
              <span class="form__fieldset__block__label">
                {{ $t('card.security.mobile_payment.title') }}
                <span v-tooltip="{ content: $t('poptip.mobile_payment'), theme: 'poptip' }"
                      class="ic-helper">
                  <ic-info outline
                           class="ic ic--gray" />
                </span>
              </span>
            </div>
            <div class="item-right">
              <toggle-switch :model-value="settings.mobile_payment"
                             data-cy="cards.settings.security.mobile-payment-toggle"
                             :disabled="disabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="value => updateCardSettings('mobile_payment', value)" />
            </div>
          </div>
        </div>
        <div class="col col--sm-6">
          <div v-if="!isVirtualCard"
               class="form__fieldset__block">
            <div class="item-left">
              <ic-card online-pay
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('card.security.onlinepay.title') }}</span>
            </div>
            <div class="item-right">
              <toggle-switch :model-value="settings.distance_selling"
                             data-cy="cards.settings.security.distance-selling-toggle"
                             :disabled="disabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="value => updateCardSettings('distance_selling', value)" />
            </div>
          </div>
          <div v-if="!isVirtualCard && hasAuthorizationCategories"
               class="form__fieldset__block">
            <div class="item-left">
              <ic-card withdrawal
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('card.security.withdrawal.title') }}</span>
            </div>
            <div class="item-right">
              <toggle-switch :model-value="authorizationCategoriesRetrait.enabled"
                             data-cy="cards.settings.security.withdrawal-toggle"
                             :disabled="disabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="updateRetrait" />
            </div>
          </div>
          <div class="form__fieldset__block">
            <div class="item-left">
              <ic-card foreign-currency
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('card.security.foreign_currency.title') }}</span>
            </div>
            <div class="item-right">
              <toggle-switch :model-value="settings.fx"
                             data-cy="cards.settings.security.fx-toggle"
                             :disabled="disabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="value => updateCardSettings('fx', value)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupTitle from '@/components/GroupTitle.vue'
import IcCard from '@/components/svg/icons/ic-card.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcMobilePayment from '@/components/svg/icons/ic-mobile-payment.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  name: 'ComponentCardSecurity',

  components: {
    IcInfo,
    IcMobilePayment,
    IcCard,
    ToggleSwitch,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    },

    isVirtualCard: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update-card-settings'],

  computed: {
    authorizationCategoriesRetrait () {
      return this.settings.authorization_categories?.find(item => item.name === 'retrait')
    },

    hasAuthorizationCategories () {
      return !!this.settings.authorization_categories?.length
    }
  },

  methods: {
    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    },

    updateCardSettings (key, value) {
      this.$emit('update-card-settings', {
        [key]: value
      })
    },

    updateRetrait (value) {
      this.$emit('update-card-settings', {
        authorization_categories: [
          {
            ...this.authorizationCategoriesRetrait,
            enabled: value
          }
        ]
      })
    }
  }
}
</script>
