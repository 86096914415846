<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="hasResult">
      <component-table data-cy="debits.list"
                       class="table--hover">
        <thead>
          <tr>
            <th class="col-date">
              {{ $t("table.date") }}
            </th>
            <th>{{ $t("table.creditor") }}</th>
            <th>{{ $t("table.mandat_reference") }}</th>
            <th>{{ $t("table.frequency") }}</th>
            <th />
            <th />
            <th class="col-amount">
              {{ $t("table.amount") }}
            </th>
            <th v-if="listHasDocument"
                class="center">
              {{ $t("table.documents") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isToCome">
            <template v-for="(items, index) in debits"
                      :key="index">
              <tr v-if="items.length"
                  class="header-table-section">
                <td colspan="7">
                  <span class="arrow">&rsaquo;</span>{{ $tc(`table.debits.${index}`, items.length) }}
                </td>
              </tr>
              <tr v-for="debit in items"
                  :key="debit.id"
                  class="is-link"
                  :class="{ 'is-cancelled': debit.status === 'cancelled' }"
                  @click.prevent.stop="showDebitDetail(debit)">
                <td class="text-muted nowrap">
                  {{ formatDate(debit.collection_date) }}
                </td>
                <td><span class="col-label text-overflow">{{ debit.creditor_name }}</span></td>
                <td>{{ debit.mandate_unique_reference }}</td>
                <td>{{ debit.unique ? $t('general.unique') : $t('general.periodic') }}</td>
                <td class="right">
                  <core-badge v-if="debit.transaction_type === 'B2B'"
                              :theme="ECoreBadgeTheme.Currency"
                              :size="ECoreBadgeSize.Small"
                              :fill="ECoreBadgeFill.Outline"
                              value="SEPA B2B" />
                </td>
                <td class="right">
                  <core-badge v-if="debit.transaction_type === 'B2B' && !debit.is_handled"
                              :theme="ECoreBadgeTheme.Warning"
                              :fill="ECoreBadgeFill.Shaded"
                              :value="$t('poptip.mandate.needed.title')">
                    <template #default="{ value }">
                      <TooltipPoptip :value="value"
                                     :title="$t('poptip.mandate.needed.title')"
                                     :message="$t('poptip.mandate.needed.content')" />
                    </template>
                  </core-badge>
                </td>
                <td class="right">
                  <span class="amount">{{ formatAmount(debit.amount, debit.currency) }}</span>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr v-for="debit in debits"
                :key="debit.id"
                class="is-link"
                :class="{ 'is-cancelled': debit.status === 'cancelled' }"
                @click.prevent.stop="showDebitDetail(debit)">
              <td class="text-muted nowrap">
                {{ formatDate(debit.collection_date) }}
              </td>
              <td><span class="col-label text-overflow">{{ debit.creditor_name }}</span></td>
              <td>{{ debit.mandate_unique_reference }}</td>
              <td>{{ debit.unique ? $t('general.unique') : $t('general.periodic') }}</td>
              <td class="right">
                <core-badge v-if="debit.transaction_type === 'B2B'"
                            :theme="ECoreBadgeTheme.Currency"
                            :size="ECoreBadgeSize.Small"
                            :fill="ECoreBadgeFill.Outline"
                            value="SEPA B2B" />
              </td>
              <td class="right">
                <core-badge v-if="debit.transaction_type === 'B2B' && !debit.is_handled"
                            :theme="ECoreBadgeTheme.Warning"
                            :fill="ECoreBadgeFill.Shaded"
                            :value="$t('poptip.mandate.needed.title')">
                  <template #default="{ value }">
                    <TooltipPoptip :value="value"
                                   :title="$t('poptip.mandate.needed.title')"
                                   :message="$t('poptip.mandate.needed.content')" />
                  </template>
                </core-badge>
              </td>
              <td class="right">
                <span class="amount">{{ formatAmount(debit.amount, debit.currency) }}</span>
              </td>
              <td v-if="listHasDocument"
                  class="center">
                <button v-if="debit.documents.length && debit.operation_id"
                        v-tooltip="{ content: fileViewerTooltip(debit.documents), theme: 'tooltip' }"
                        class="btn-link btn-attachment"
                        @click.prevent.stop="showFile(debit)">
                  <ic-document class="ic ic--gray" />
                </button>
                <button v-else-if="!debit.documents.length && debit.operation_id"
                        v-tooltip="{ content: $t('tooltip.attach_file'), theme: 'tooltip' }"
                        class="btn-link btn-attachment"
                        @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_invoice'), debit, { url: `/operations/${debit.operation_id}/documents` })">
                  <ic-attachment class="ic ic--gray" />
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="debitsPagination" />
    </template>
    <component-placeholder v-else
                           :label="placeholderLabel" />
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useDebitStore } from '@/stores/debit'

import DebitDetail from '@/pages/debits/sidepanel/DebitDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import ComponentTable from '@/components/Table.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'

export default {
  components: {
    TooltipPoptip,
    CoreBadge,
    IcAttachment,
    IcDocument,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const debitStore = useDebitStore()

    const { user } = storeToRefs(authStore)
    const { debits: allDebits, debitsPagination } = storeToRefs(debitStore)

    return { user, appStore, allDebits, debitsPagination, debitStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    placeholderLabel () {
      switch (this.state) {
        case 'done': return this.$i18n.t('placeholder.no_withdrawal.history')
        case 'rejected': return this.$i18n.t('placeholder.no_withdrawal.rejected')
        case 'to_come': return this.$i18n.t('placeholder.no_withdrawal.to_come')
        default: return ''
      }
    },

    debits () {
      if (!this.isToCome) {
        return this.allDebits
      }

      return this.allDebits.reduce((r, debit) => {
        debit.sequence_type === 'FRST'
          ? r.first.push(debit)
          : r.all.push(debit)

        return r
      }, { first: [], all: [] })
    },

    hasResult () {
      return this.isToCome
        ? (this.debits.first.length || this.debits.all.length)
        : this.debits.length
    },

    listHasDocument () {
      return this.isToCome ? false : this.debits.some(debit => debit.operation_id !== null)
    },

    isToCome () {
      return this.state === 'to_come'
    }
  },

  watch: {
    state () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'debitsPagination.totalItems' () {
      this.$emit('pagination-total-items', this.debitsPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('debit-handled', this.onDebitHandled)
  },

  beforeUnmount () {
    this.$bus.on('debit-handled', this.onDebitHandled)
  },

  methods: {
    formatAmount,

    formatDate,

    async initData () {
      this.loading = true
      await this.debitStore.getDebits(this.state, this.page, this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    fileViewerTooltip (documents) {
      return this.$i18n.tc('tooltip.show_file', documents.length)
    },

    showDebitDetail (debit) {
      this.appStore.showSidePanel(DebitDetail, { model: debit }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    },

    showFile (item) {
      this.appStore.showFileViewer({
        content: item.documents,
        isMultiDoc: true,
        canEdit: true,
        operationId: item.operation_id,
        documentsUrl: `/operations/${item.operation_id}/documents`,
        displayFullScreen: true
      })
    }
  },

  onDebitHandled (debitId) {
    const debit = this.allDebits.find(debit => debit.id === debitId)
    if (debit) { debit.is_handled = true }
  }
}

</script>
