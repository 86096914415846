import { defineStore } from 'pinia'

interface Error {
  code: number,
  fields: Array<{field: string, message: string}> | object
}

interface State {
  abortControllers: Array<AbortController>,
  error: Error | null,
  loading: boolean,
  openedRequests: number
}

export const useApiStore = defineStore('api', {
  state: (): State => {
    return {
      abortControllers: [],
      error: null,
      loading: false,
      openedRequests: 0
    }
  },

  getters: {
    hasOpenRequests: state => state.openedRequests !== 0
  },

  actions: {
    abortAllRequests () {
      while (this.abortControllers.length) {
        this.abortControllers?.shift()?.abort()
      }
    },

    addAbortController () {
      const controller = new AbortController()
      this.abortControllers.push(controller)
      return controller
    },

    addError (error: Error) {
      this.error = error
    },

    decrementOpenedRequests () {
      this.openedRequests--
    },

    incrementOpenedRequests () {
      this.openedRequests++
    },

    removeAbortController (signal: AbortSignal) {
      const abortControllerIndex = this.abortControllers.findIndex(controller => controller.signal === signal)
      if (abortControllerIndex !== -1) {
        this.abortControllers.splice(abortControllerIndex, 1)
      }
    },

    resetError () {
      this.error = null
    },

    setLoading (value: boolean) {
      this.loading = value
    }
  }
})
