<template>
  <div class="cards-list__item bordered-box bordered-box--padding is-link"
       @click="handleRowClick">
    <div class="col--sm-3 col--sm-flex align-items-center">
      <user-avatar class="mr-2"
                   :picture="card.user?.picture"
                   :size="32"
                   :uuid="card.user?.uuid"
                   :placeholder-label="cardName" />
      <div>
        <div class="strong">
          {{ cardName }}
        </div>
        <div v-if="card.label"
             class="text-muted">
          {{ card.label }}
        </div>
      </div>
    </div>
    <div :class="[isCardPhysical ? 'col--sm-4' : 'col--sm-2']">
      <badge-visa :type="card.type" />
      <span class="hidden-digits">•••• </span>{{ card.last_digits }}
    </div>
    <div v-if="!isCardPhysical"
         class="col--sm-2 center">
      <core-badge :theme="cardBadgeTheme(card.type)"
                  :value="badgeLabel" />
    </div>
    <div v-if="!isCardPhysical"
         class="col--sm-1" />
    <div :class="[isCardPhysical ? 'col--sm' : 'col--sm-2']">
      <template v-if="hasCardActivationRight && isCardToActivate">
        <button class="btn btn--xs btn--outline-primary"
                @click.prevent.stop="showActivateCardModal">
          {{ $t('button.activate_card') }}
        </button>
      </template>
      <div v-else-if="!isCardToValidate"
           class="card-limit-settings__item">
        <div v-if="displayLimit"
             class="card-limit-value">
          <span class="card-limit-value__amount">
            {{ formatAmount(paymentConsumed, account.currency, { trailingZeroDisplay: 'stripIfInteger' }) }}
            <span class="card-limit-value__amount card-limit-value__amount__grey">
              / {{ formatAmount(paymentLimit, account.currency, { trailingZeroDisplay: 'stripIfInteger' }) }}
            </span>
          </span>
          <line-gauge :percentage="percentageConsumed"
                      size="small"
                      class="card-limit-value__gauge" />
        </div>
        <div v-else-if="isActive"
             class="card-limit-value">
          <span class="card-limit-value__amount">
            {{ formatAmount(paymentConsumed, account.currency) }}
          </span>
        </div>
      </div>
    </div>
    <div class="col--sm right">
      <core-badge v-bind="getBadgeProps(badgeStatus)"
                  :value="card.status_label">
        <template v-if="card.reject_reason"
                  #default="{ value }">
          <TooltipPoptip :value="value"
                         :message="card.reject_reason"
                         :title="$t('popover.reject_reason.title')" />
        </template>
      </core-badge>
    </div>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeSize } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { cardBadgeTheme, getBadgeProps } from '@/helpers/utils/badge'
import { getCardBadgeLabel, isPhysicalCard, isRecurringVirtualCard } from '@/helpers/utils/card'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import ActivateCard from '@/pages/cards/modal/ActivateCard.vue'
import ValidateCardRequest from '@/pages/cards/sidepanel/ValidateCardRequest.vue'

import BadgeVisa from '@/components/BadgeVisa.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'
import UserAvatar from '@/components/UserAvatar.vue'

import LineGauge from './LineGauge.vue'

export default {
  components: {
    TooltipPoptip,
    CoreBadge,
    BadgeVisa,
    LineGauge,
    UserAvatar
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    cardName () {
      return `${this.card.first_name} ${this.card.last_name}`.trim()
    },

    isCardPhysical () {
      return isPhysicalCard(this.card)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.card)
    },

    isCardExpired () {
      return this.card.expired
    },

    writePermission () {
      return this.isCardPhysical ? this.$permissions.cardsPhysicalWrite : this.$permissions.cardsVirtualWrite
    },

    hasCardActivationRight () {
      return hasFeature(EFeature.CardActivation, this.card)
    },

    isCardPending () {
      return this.isCardToActivate || this.isCardToValidate
    },

    isCardToValidate () {
      return this.card.status === 'to_validate' || this.card.status === 'to_validate_by_bank'
    },

    isActive () {
      return this.card.status === 'active' || this.isLocked
    },

    isLocked () {
      return ['locked', 'locked_proof', 'locked_service', 'locked_partner'].includes(this.card.status)
    },

    isBlocked () {
      return ['blocked', 'expired', 'cancelled', 'used', 'deleted'].includes(this.card.status)
    },

    isCardToActivate () {
      return ['pending', 'ordered', 'shipped'].includes(this.card.status)
    },

    wrapperClass () {
      return {
        'card-inactive': this.isCardPending || this.isBlocked,
        'is-link': true,
        'menu-hover card-locked': this.isLocked
      }
    },

    badgeLabel () {
      return getCardBadgeLabel(this.card)
    },

    badgeStatus () {
      return this.card.expire_soon && this.card.status === 'active' ? 'expire_soon' : this.card.status
    },

    displayLimit () {
      if (!this.isActive) {
        return false
      }

      return this.card.settings?.payment?.monthly.enabled &&
          (this.hasPermission(this.writePermission) || this.card.settings.display_limit)
    },

    paymentConsumed () {
      return this.card.settings?.payment?.monthly ? this.card.settings.payment.monthly.consumed : this.card.total_consumed
    },

    percentageConsumed () {
      return this.paymentLimit && this.paymentConsumed
        ? this.paymentConsumed / this.paymentLimit * 100
        : 0
    },

    paymentLimit () {
      return this.card.settings?.payment?.monthly.limit
    }
  },

  methods: {
    getBadgeProps,
    cardBadgeTheme,
    formatAmount,

    showActivateCardModal () {
      this.appStore.showModal(
        ActivateCard,
        { card: this.card },
        { wrapperClass: 'modal--xs' }
      )
    },

    handleRowClick () {
      if (this.isCardExpired) {
        this.$router.push({ name: 'cards-detail-operations', params: { uuid: this.card.uuid } })
        return
      }

      this.isCardToValidate
        ? this.appStore.showSidePanel(ValidateCardRequest, { uuid: this.card.uuid }, { wrapperClass: 'sidepanel--md sidepanel-card-request', backgroundOverlay: true })
        : this.$router.push({ name: 'cards-detail', params: { uuid: this.card.uuid } })
    }
  }
}
</script>
