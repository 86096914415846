<template>
  <div class="modal__content center">
    <modal-header @close="onCancel" />
    <div class="modal__body">
      <image-file :success="!nothingFound"
                  :warning="nothingFound"
                  width="200px"
                  height="160px"
                  class="icon" />
      <h4 class="modal-content-title">
        {{ getTitle }}
      </h4>
      <p class="pre-line">
        {{ getContent }}
      </p>
      <div class="buttons">
        <p v-if="question">
          <strong>{{ $t(question) }}</strong>
        </p>
        <template v-if="event">
          <button type="button"
                  class="btn btn--gray"
                  @click="onCancel">
            {{ $t(cancel) }}
          </button>
          <button type="button"
                  class="btn btn--primary"
                  @click="onSubmit">
            {{ $t(validate) }}
          </button>
        </template>
        <button v-else
                type="button"
                class="btn btn--primary"
                @click="$emit('close')">
          {{ $t('button.validate') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import ImageFile from '@/components/svg/img/file.vue'

export default {
  components: {
    ImageFile,
    ModalHeader
  },

  props: {
    imported: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      default: 'prompt.analyze_invoice.title.items_found'
    },

    content: {
      type: String,
      default: 'prompt.analyze_invoice.content.items_found'
    },

    question: {
      type: String,
      default: null
    },

    validate: {
      type: String,
      default: 'button.add'
    },

    cancel: {
      type: String,
      default: 'button.cancel'
    },

    event: {
      type: String,
      default: null
    }
  },

  emits: ['close'],

  computed: {
    nothingFound () {
      return !Object.values(this.imported).some(i => i)
    },

    getTitle () {
      return this.nothingFound
        ? this.$i18n.t('prompt.analyze_invoice.title.nothing_found')
        : this.$i18n.t(this.title)
    },

    getContent () {
      return this.nothingFound
        ? this.$i18n.t('prompt.analyze_invoice.content.nothing_found')
        : this.$i18n.t(this.content)
    }
  },

  methods: {
    onSubmit (submitted = true) {
      this.$emit('close')
      if (this.event) {
        this.$bus.emit(this.event, submitted)
      }
    },

    onCancel () {
      this.onSubmit(false)
    }
  }
}
</script>
