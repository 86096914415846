<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17zm.878 11.393V17h-1.915v-2.107zM11.963 7c.578 0 1.06.089 1.446.267.386.178.697.399.932.663.236.265.405.55.507.855.101.306.152.59.152.855 0 .438-.05.798-.152 1.08a2.634 2.634 0 0 1-.878 1.238c-.183.142-.356.284-.519.425a2.593 2.593 0 0 0-.433.486c-.126.182-.205.41-.238.684v.52h-1.646v-.616c.025-.392.092-.72.201-.985.11-.265.238-.49.385-.677.146-.187.3-.349.463-.486.163-.137.313-.273.451-.41.138-.137.25-.288.336-.452s.123-.369.115-.615c0-.42-.091-.73-.274-.93-.183-.201-.437-.301-.762-.301a1.076 1.076 0 0 0-.958.526c-.101.16-.176.347-.225.561a3.108 3.108 0 0 0-.073.69H9a4.3 4.3 0 0 1 .226-1.353 3.29 3.29 0 0 1 .597-1.067c.256-.301.565-.536.927-.705A2.837 2.837 0 0 1 11.963 7z" />
  </svg>
</template>

<script>
export default {
}
</script>
