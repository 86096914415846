<template>
  <svg viewBox="0 0 200 160">
    <path d="M141 49.25H53C50.3766 49.25 48.25 51.3766 48.25 54V102C48.25 104.623 50.3766 106.75 53 106.75H56.25V110C56.25 112.623 58.3766 114.75 61 114.75H129.13C128.877 114.262 128.644 113.762 128.432 113.25H61C59.2051 113.25 57.75 111.795 57.75 110V106.75H127.015C127.005 106.501 127 106.251 127 106C127 105.749 127.005 105.499 127.015 105.25H53C51.2051 105.25 49.75 103.795 49.75 102V54C49.75 52.2051 51.2051 50.75 53 50.75H141C142.795 50.75 144.25 52.2051 144.25 54V87.0795C144.745 87.0343 145.245 87.0081 145.75 87.0016V58.75H149C150.795 58.75 152.25 60.2051 152.25 62V88.0519C152.76 88.2296 153.261 88.4284 153.75 88.6473V62C153.75 59.3766 151.623 57.25 149 57.25H145.75V54C145.75 51.3766 143.623 49.25 141 49.25Z"
          :fill="colorDark" />
    <path d="M58.0151 61.2411V62.7411H84.5151V61.2411H58.0151ZM58.001 90.2675V91.7675H72.001V90.2675H58.001ZM75.001 90.2675V91.7675H89.001V90.2675H75.001ZM92.001 91.7675V90.2675H106.001V91.7675H92.001ZM109.001 90.2675V91.7675H123.001V90.2675H109.001ZM58 97.5V96H123.001V97.5H58Z"
          :fill="colorGrey" />
    <path d="M134.484 61.5677C134.421 61.4394 133.532 59.6376 131.969 59.6376C129.615 59.7351 127.568 61.2259 126.823 63.3845H132.002V64.9632H126.376C126.31 65.3776 126.277 65.7964 126.278 66.2157C126.279 66.635 126.311 67.0536 126.376 67.4682H132.002V69.0469H126.802C127.533 71.2363 129.62 72.7394 132.002 72.7938C133.084 72.6627 134.034 72.0356 134.553 71.1098C134.764 70.7233 135.259 70.5748 135.66 70.7783C136.06 70.9817 136.214 71.46 136.003 71.8466C135.207 73.2914 133.703 74.244 132.013 74.3725C128.726 74.3067 125.89 72.1256 125.09 69.0469H122.201V67.4682H124.741C124.685 67.0528 124.656 66.6345 124.654 66.2157C124.655 65.7968 124.684 65.3785 124.741 64.9632H122.146V63.3845H125.046C125.875 60.3277 128.687 58.1609 131.958 58.0588C134.64 58.0588 135.981 60.8269 135.981 60.9427C136.122 61.3321 135.929 61.7608 135.538 61.9263C135.146 62.0918 134.689 61.9384 134.488 61.5742C134.487 61.5731 134.486 61.5709 134.484 61.5677ZM129.25 106C129.25 115.251 136.749 122.75 146 122.75C155.251 122.75 162.75 115.251 162.75 106C162.75 96.7492 155.251 89.25 146 89.25C136.749 89.25 129.25 96.7492 129.25 106ZM161.25 106C161.25 114.422 154.422 121.25 146 121.25C137.578 121.25 130.75 114.422 130.75 106C130.75 97.5777 137.578 90.75 146 90.75C154.422 90.75 161.25 97.5777 161.25 106ZM149 105C147.895 105 147 104.105 147 103V99H145V103C145 104.105 144.105 105 143 105H139V107H143C144.105 107 145 107.895 145 109V113H147V109C147 107.895 147.895 107 149 107H153V105H149Z"
          :fill="colorPrimary" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
