<template>
  <div id="card-digits"
       class="card-number"
       :class="{ 'is-hidden': hidden }">
    <span v-for="(digit, index) in digitsToArray"
          :key="index">{{ digit }}</span>
  </div>
</template>

<script>
import { formatCardDigits } from '@/helpers/utils/text'

export default {
  props: {
    showPan: {
      type: Boolean,
      default: false
    },

    digits: {
      type: String,
      default: '•'.repeat(16)
    },

    hidden: Boolean
  },

  computed: {
    digitsToArray () {
      return formatCardDigits(this.digits, this.showPan)
    }
  }
}
</script>
