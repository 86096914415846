<template>
  <section v-if="loading"
           class="section section--empty">
    <loader-spinner size="32" />
  </section>
  <section v-else
           class="section mone-card-detail">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: isVirtualCard ? 'cards-virtual' : 'cards-physical' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ card.label || $t('card.pending.title', { name: cardName }) }}
          <popover v-if="hasWriteCardRight"
                   ref="popover"
                   trigger="click"
                   popover-class="popover--center popover-rename"
                   position-arrow-x="center">
            <template #trigger="slotProps">
              <button v-tooltip="{ content: slotProps.popoverActive ? false : $t('tooltip.rename'), theme: 'tooltip' }"
                      class="btn-link btn-edit">
                <ic-pencil class="ic ic--20 ic--gray" />
              </button>
            </template>
            <template #default="slotProps">
              <popover-rename :name="card.label || $t('card.pending.title', { name: cardName })"
                              :url="`/cards/${card.uuid}/label`"
                              :data-key="'label'"
                              :can-be-blank="true"
                              :popover-active="slotProps.popoverActive"
                              @renamed="onCardRenamed"
                              @close-popover="closePopover" />
            </template>
          </popover>
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <div v-if="isComponentOperation">
            <button-filters show-text />
            <button v-if="hasExportFeature"
                    class="btn btn--default"
                    @click="showExportPanel">
              <i class="btn-icon btn-icon--left">
                <ic-export class="ic ic--20 ic--white" />
              </i>
              {{ $t("button.export") }}
            </button>
          </div>
          <button v-if="hasCardActivationRight && isCardToActivate"
                  class="btn btn--primary"
                  @click="showActivateCardModal">
            {{ $t('button.activate_card') }}
          </button>
          <popover v-if="hasWriteCardRight && isVirtualCard && (isComponentGeneral || isCardExpired)"
                   popover-class="popover-actions-menu popover--pull-right"
                   trigger="hover">
            <template #trigger>
              <button class="btn btn--icon btn--white">
                <i class="btn-icon">
                  <ic-settings class="ic ic--gray" />
                </i>
              </button>
            </template>
            <div class="popover__item"
                 @click="requestDuplicateVirtualCard">
              <div class="popover__item__icon">
                <ic-clipboard class="ic ic--16 ic--gray" />
              </div>
              <div>{{ $t('form.cards.duplicate_card') }}</div>
            </div>
          </popover>
          <statement-filter v-if="isComponentStatement"
                            is-card
                            :card-uuid="card.uuid"
                            :is-virtual-card="isVirtualCard"
                            :selected-statements="selectedStatements"
                            @selected-params="onSelectedParams"
                            @update-loading="updateLoadingFilter" />
        </div>
      </div>
    </div>
    <component-filters v-if="isComponentOperation"
                       :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange,
                         EFilter.Attachment,
                         EFilter.ExpenseCategory
                       ]"
                       :total-credit="operationsTotalCredit"
                       :total-debit="operationsTotalDebit"
                       :number-of-results="numberOfResults"
                       @filters="filters" />
    <tabs data-cy="cards.details.tabs"
          :data="segments" />
    <router-view ref="list"
                 :card="card"
                 :card-uuid="card.uuid"
                 :has-write-card-right="hasWriteCardRight"
                 :params="statementsParams"
                 :filter="filter"
                 :loading-filter="loadingFilter"
                 @pagination-total-items="onPaginationTotalItems"
                 @card-update="onCardUpdate"
                 @edit-card-limit="onEditCardLimitRequested"
                 @selected-statements="onSelectedStatements"
                 @cancel-deletion-success="onCancelDeletionSuccess"
                 @cancel-renewal-success="onCancelRenewalSuccess" />
  </section>
</template>

<script>
import { upperFirst } from 'lodash/string'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { isVirtualCard } from '@/helpers/utils/card'
import { scrollElementToTop } from '@/helpers/utils/dom'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { useOperationStore } from '@/stores/operation'
import { EFilter } from '@/types/filter.d'

import DuplicateVirtualCard from '@/pages/cards/sidepanel/DuplicateVirtualCard.vue'
import OperationsExport from '@/pages/operations/sidepanel/OperationsExport.vue'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import StatementFilter from '@/components/filters/StatementFilter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Popover from '@/components/Popover.vue'
import PopoverRename from '@/components/popovers/Rename.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcClipboard from '@/components/svg/icons/ic-clipboard.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'
import Tabs from '@/components/Tabs.vue'

import ActivateCard from './modal/ActivateCard.vue'

export default {
  components: {
    IcPencil,
    IcSettings,
    IcExport,
    IcClipboard,
    IcArrow,
    ButtonFilters,
    ComponentFilters,
    StatementFilter,
    LoaderSpinner,
    Popover,
    PopoverRename,
    Tabs
  },

  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()
    const operationStore = useOperationStore()
    const { operationsTotalCredit, operationsTotalDebit } = storeToRefs(operationStore)

    const { isDeviceMobile } = storeToRefs(appStore)

    return {
      appStore,
      cardStore,
      isDeviceMobile,
      operationStore,
      operationsTotalCredit,
      operationsTotalDebit
    }
  },

  data () {
    return {
      loading: true,
      card: null,
      numberOfResults: null,
      filter: {
        search: null,
        from: null,
        to: null,
        attachment: null,
        proof_filter: null,
        expense_category: null
      },

      initialFilter: {},
      selectedStatements: [],
      statementsParams: null,
      loadingFilter: true
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.general'),
          route: { name: 'cards-detail' },
          visible: this.hasReadCardRight && !this.isCardExpired
        },
        {
          label: this.$i18n.t('button.operations'),
          route: { name: 'cards-detail-operations' },
          visible: hasFeature(EFeature.OperationCard) && (this.isCardActive || this.isCardExpired)
        },
        {
          label: this.$i18n.t('button.settings'),
          route: { name: 'cards-detail-settings' },
          visible: this.shouldSettingsTabBeVisible
        },
        {
          label: this.$i18n.t('title.statements'),
          route: { name: 'cards-detail-statements' },
          visible: !this.isCardToActivate && this.hasPermission(this.$permissions.statementsCardsRead)
        }
      ].filter(route => route.visible)
    },

    isComponentGeneral () {
      return this.$route.name === 'cards-detail'
    },

    isComponentOperation () {
      return this.$route.name === 'cards-detail-operations'
    },

    isComponentStatement () {
      return this.$route.name === 'cards-detail-statements'
    },

    hasReadCardRight () {
      return this.isVirtualCard
        ? this.hasPermission(this.$permissions.cardsVirtualRead) || this.hasPermission(this.$permissions.cardsVirtualRequest)
        : this.hasPermission(this.$permissions.cardsPhysicalRead)
    },

    hasWriteCardRight () {
      return this.isVirtualCard
        ? this.hasPermission(this.$permissions.cardsVirtualWrite)
        : this.hasPermission(this.$permissions.cardsPhysicalWrite)
    },

    hasCardActivationRight () {
      return hasFeature(EFeature.CardActivation, this.card)
    },

    hasExportFeature () {
      return hasFeature(EFeature.OperationExport)
    },

    isCardToActivate () {
      return !this.isCardExpired && !this.isCardActive && !this.isVirtualCard
    },

    isCardActive () {
      return this.card.active
    },

    isCardExpired () {
      return this.card.expired
    },

    cardName () {
      return `${upperFirst(this.card.first_name)} ${upperFirst(this.card.last_name)}`.trim()
    },

    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    shouldSettingsTabBeVisible () {
      return (hasFeature(EFeature.SmartCard, this.card) || hasFeature(EFeature.CardSettings, this.card)) && !this.isCardExpired
    }
  },

  watch: {
    'card.settings.proof_required': function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.card.status = 'active'
      }
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('card-activate-success', this.onActivateCardSuccess)
    this.$bus.on('required-proof-added', this.refreshCard)
    this.$bus.on('on-upload-success', this.refreshCard) // used to refresh the card when missing requied document attached to operation. TODO do that call only if operation to justify id
    this.$bus.on('account-switched', this.goBackToList)
    this.$bus.on('operation-update-success', this.refreshCard)
    this.$bus.on('language-changed', this.refreshCard)
  },

  beforeUnmount () {
    this.$bus.off('card-activate-success', this.onActivateCardSuccess)
    this.$bus.off('required-proof-added', this.refreshCard)
    this.$bus.off('on-upload-success', this.refreshCard)
    this.$bus.off('account-switched', this.goBackToList)
    this.$bus.off('operation-update-success', this.refreshCard)
    this.$bus.off('language-changed', this.refreshCard)
  },

  methods: {
    async initData () {
      this.loading = true

      this.numberOfResults = null
      this.filter = {
        search: null,
        from: null,
        to: null,
        attachment: null,
        proof_filter: null,
        expense_category: null
      }

      this.initialFilter = { ...this.filter }
      this.statementsParams = null

      await this.refreshCard(this.uuid)
      if (!this.card) { return this.goBackToList() }

      if (this.isCardToActivate && !this.hasReadCardRight) {
        this.goBackToList()
      }

      if (this.isCardExpired && !this.isComponentOperation) {
        await this.$router.push({ name: 'cards-detail-operations' })
      }

      this.loading = false
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    onSelectedParams (params) {
      this.statementsParams = params
      this.$nextTick(() => this.$refs.list.initData())
    },

    filters (filters) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to,
        attachment: filters.attachment,
        proof_filter: filters.proof_filter,
        expense_category: filters.expense_category
      }
    },

    showActivateCardModal () {
      this.appStore.showModal(
        ActivateCard,
        { card: this.card },
        { wrapperClass: 'modal--xs' }
      )
    },

    onActivateCardSuccess (data) {
      showToastSuccess(this.$t('message.success.card.activate'))
      Object.assign(this.card, data)
    },

    showExportPanel () {
      this.appStore.showSidePanel(
        OperationsExport,
        {
          type: 'operations',
          filter: this.filter,
          isFiltered: true,
          persistingFilters: { card: true, card_uuid: this.uuid }
        },
        { wrapperClass: 'sidepanel-export sidepanel--md', backgroundOverlay: true }
      )
    },

    updateLoadingFilter (value) {
      this.loadingFilter = value
    },

    onSelectedStatements (statements) {
      this.selectedStatements = statements
    },

    requestDuplicateVirtualCard () {
      this.appStore.showSidePanel(DuplicateVirtualCard, { model: this.card }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    },

    onEditCardLimitRequested () {
      this.$nextTick(() => {
        scrollElementToTop(
          document.getElementById('content'),
          document.getElementById('limit')
        )
      })
    },

    onCardRenamed (value) {
      this.card.label = value.label
    },

    closePopover () {
      this.$refs.popover.closePopover()
    },

    onCardUpdate (card) {
      this.card = card
    },

    async refreshCard () {
      const card = await this.cardStore.getCard(this.uuid)
      if (card) { this.card = card }
    },

    goBackToList () {
      this.$router.push({ name: this.isVirtualCard ? 'cards-virtual' : 'cards-physical' })
    },

    onCancelDeletionSuccess () {
      this.card.automatic_deletion = false
    },

    onCancelRenewalSuccess () {
      this.card.settings.automatic_renewal = false
    }
  }
}
</script>
