<template>
  <svg viewBox="0 0 24 24">
    <defs>
      <path id="ic-alert/path/a"
            d="M20.867 7.503a1 1 0 0 0-1.366-.366l-15.589 9a1 1 0 0 0 1 1.732l15.589-9a1 1 0 0 0 .366-1.366Z" />
      <mask id="ic-alert/mask/b">
        <path fill="#fff"
              d="M0 0h24v24H0z" />
        <use href="#a"
             stroke="#000"
             stroke-width="4" />
      </mask>
    </defs>
    <use v-if="off"
         href="#ic-alert/path/a" />
    <path :mask="off ? 'url(#ic-alert/mask/b)' : ''"
          fill-rule="evenodd"
          d="M12 2c.828 0 1.5.689 1.5 1.538 0 .048-.002.094-.006.14C16.56 4.264 19 6.65 19 10.205v4.615c0 .741.496 1.604 1 2.3.182.251.377.478.572.675l.174.168.073.065c.346.287.163.895-.27.895h-5.643C14.572 20.693 13.398 22 12 22c-1.398 0-2.572-1.308-2.905-3.077H3.45c-.433 0-.616-.608-.27-.895l.073-.065A5.835 5.835 0 0 0 4 17.12c.504-.695 1-1.558 1-2.3v-4.615c0-3.56 2.438-5.943 5.506-6.527a1.61 1.61 0 0 1-.006-.14C10.5 2.688 11.172 2 12 2Zm5 8.205v4.615c0 .706.178 1.377.499 2.052H6.5c.32-.675.499-1.346.499-2.052v-4.615c0-2.73 2.102-4.615 5-4.615 2.895 0 5 1.89 5 4.615Z" />
  </svg>
</template>

<script>
export default {
  props: {
    off: Boolean
  }
}
</script>
