<template>
  <div class="modal__content">
    <modal-header :title="dispatch.id ? $t('title.edit_dispatch') : $t('title.new_dispatch')"
                  @close="$emit('close')" />
    <div class="modal__body">
      <validation-form v-slot="{ errors }"
                       class="form form-envoi-releves-auto"
                       :validation-schema="schema"
                       @keydown.enter.prevent
                       @submit="onSubmit">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('form.alerts.common')" />
        <div class="form__row row">
          <div class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.common.select_user") }}</label>
            <component-dropdown async-url="/users"
                                :async-url-params="{ status: 'active', exclude: 'me', scenario: 'light' }"
                                :dropdown-height="24"
                                dropdown-width="100%"
                                has-you-user
                                :label-filter="userFilter"
                                has-item-picture
                                :model="user || null"
                                search
                                label="user"
                                @select="selectUser">
              <template #trigger>
                <div class="dropdown-toggle select">
                  <div class="form-control">
                    <span v-if="!user"
                          class="text-muted">{{ $t('form.common.select') }}</span>
                    <span v-else>
                      {{ userLabel }}
                    </span>
                  </div>
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
        <div class="form__row row">
          <div :class="{ error: errors.first_name }"
               class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.first_name.label") }}</label>
            <validation-field v-model="dispatch.first_name"
                              :placeholder="$t('form.first_name.placeholder')"
                              class="form-control"
                              name="first_name"
                              type="text" />
          </div>
          <div :class="{ error: errors.last_name }"
               class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.last_name.label") }}</label>
            <validation-field v-model="dispatch.last_name"
                              class="form-control"
                              :placeholder="$t('form.last_name.placeholder')"
                              name="last_name"
                              type="text" />
          </div>
        </div>
        <div class="form__row row">
          <div :class="{ error: errors.email }"
               class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.email.label") }}</label>
            <validation-field v-model="dispatch.mail"
                              class="form-control"
                              :placeholder="$t('form.email.placeholder')"
                              name="email"
                              type="text" />
          </div>
          <div :class="{ error: errors.role }"
               class="form-group col col--sm">
            <label class="label input__label">{{ $t("form.role.label") }}</label>
            <div v-if="!isRoleOther && !newRole">
              <validation-field name="roles">
                <component-dropdown label="role"
                                    dropdown-width="100%"
                                    :values="roleOptions"
                                    :model-value="dispatch.role"
                                    @select="selectRole">
                  <template #trigger>
                    <div class="select">
                      <div class="form-control">
                        <template v-if="!dispatch.role">
                          <span class="text-placeholder">
                            {{ $t('form.common.select') }}
                          </span>
                        </template>
                        <template v-else>
                          {{ roleLabel(dispatch.role) }}
                        </template>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </validation-field>
            </div>
            <div v-else
                 class="input-group">
              <validation-field v-model="newRole"
                                :placeholder="$t('form.roles.placeholder.other')"
                                class="form-control"
                                name="role"
                                type="text" />
              <span class="input-group__addon is-link"
                    @click="resetRole">
                <ic-remove class="ic ic--addon ic--16 ic--gray" />
              </span>
            </div>
          </div>
        </div>
        <div class="modal__footer">
          <div class="modal__footer__left">
            <div class="form-check">
              <label class="form-check__label checkbox">
                <input v-model="dispatch.include_attach"
                       type="checkbox">
                <span class="checkbox__icon" />
                {{ $t("form.attachment.include") }}
              </label>
            </div>
          </div>
          <div class="modal__footer__right">
            <button type="button"
                    class="btn btn--gray"
                    @click="$emit('close')">
              {{ $t("button.cancel") }}
            </button>
            <component-button type="submit"
                              class="btn btn--primary"
                              :label="$t('button.validate')" />
          </div>
        </div>
      </validation-form>
    </div>
  </div>
</template>

<script>
import * as yup from 'yup'

import store from '@/config/store'
import { useStatementStore } from '@/stores/statement'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentAlert,
    ComponentButton,
    ComponentDropdown,
    ModalHeader
  },

  props: {
    model: {
      type: Object,
      default: null
    },

    roles: {
      type: Array,
      default: () => []
    }
  },

  emits: ['close'],

  setup () {
    const statementStore = useStatementStore()

    return { statementStore }
  },

  data () {
    return {
      store,
      user: null,
      newRole: '',
      dispatch: {
        id: null,
        last_name: '',
        first_name: '',
        mail: '',
        role: null,
        include_attach: true
      },

      attachmentInput: false,

      schema: {
        email: yup.string().required().email(),
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        roles: yup.string(),
        role: yup.string().when('roles', {
          is: 'other',
          then: () => yup.string().required()
        })
      }
    }
  },

  computed: {
    userLabel () {
      if (!this.user) { return }

      let label = `${this.user.first_name} ${this.user.last_name}`.trim()
      if (this.user.owner) {
        label += ` (${this.$i18n.t('general.you')})`
      } else if (this.user.role) {
        const roleLabel = this.user.role_label || this.roleOptions.find(option => option.value === this.user.role)?.label
        label += roleLabel ? ` - ${roleLabel}` : ''
      }

      return label
    },

    roleOptions () {
      return this.roles.map(role => ({
        value: role.role,
        label: role.role_label
      })).concat({ value: 'other', label: this.$t('form.roles.other') })
    },

    isRoleOther () {
      return this.dispatch.role === 'other'
    }
  },

  created () {
    if (!this.model) { return }

    this.dispatch = { ...this.model }
    this.user = {
      first_name: this.model.first_name,
      last_name: this.model.last_name,
      role: this.model.role
    }

    this.setRole()
  },

  methods: {
    userFilter (user) {
      let label = `${user.first_name} ${user.last_name}`.trim()

      if (user.role && user.role_label) {
        label += ` - ${user.role_label}`
      }

      return label
    },

    selectUser (user) {
      this.user = { ...user }

      const roleValue = user.owner
        ? 'associate'
        : (this.roleOptions.find(option => option.label === user.role_label) || {}).value

      this.dispatch = {
        id: this.dispatch.id,
        include_attach: this.dispatch.include_attach,
        first_name: user.first_name,
        last_name: user.last_name,
        mail: user.email,
        role: roleValue
      }
      this.setRole()
    },

    resetRole () {
      this.dispatch.role = null
      this.newRole = ''
    },

    setRole () {
      const roleObject = this.roleOptions.find(role => role.value === this.dispatch.role || role.label === this.dispatch.role)
      if (roleObject) {
        this.dispatch.role = roleObject.value
      } else {
        this.newRole = this.dispatch.role
      }
    },

    async onSubmit () {
      if (this.dispatch.role === 'other') {
        this.dispatch.role = this.newRole
      }
      this.dispatch.id
        ? await this.statementStore.editStatementDispatch(this.dispatch)
        : await this.statementStore.postStatementDispatch(this.dispatch)
    },

    roleLabel (value) {
      const role = this.roleOptions.find(role => role.value === value)
      return role ? role.label : ''
    },

    selectRole (role) {
      this.dispatch.role = role.value
    }
  }
}
</script>
