<template>
  <router-link v-if="!loading"
               class="dashboard__block"
               :class="[`dashboard__block--${color}`, { 'dashboard__block--small': small }]"
               :to="{ name: 'account' }">
    <user-avatar :key="`${user.picture?.url}-${user.picture?.updated_at}`"
                 class="user-picture"
                 :size="pictureSize"
                 :picture="user.picture"
                 :placeholder-label="`${user.first_name} ${user.last_name}`" />
    <span class="sub-label">{{ user.first_name }} {{ user.last_name }}</span>
  </router-link>
  <dashboard-loading v-else
                     :color="color" />
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores/auth'

import DashboardLoading from '@/pages/dashboard/components/DashboardLoading.vue'

import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    DashboardLoading,
    UserAvatar
  },

  props: {
    color: {
      type: String,
      required: false,
      default: 'white'
    },

    small: {
      type: Boolean,
      required: false,
      default: false
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup () {
    const authStore = useAuthStore()

    const { user } = storeToRefs(authStore)

    return { user }
  },

  data: () => ({
    pictureSize: 72
  }),

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize () {
      const { height, width } = this.$el.getBoundingClientRect()
      this.pictureSize = height > 240 && width > 320 ? 92 : 72
    }
  }
}
</script>

<style lang="stylus" scoped>
.user-picture
  margin auto

.dashboard__block--small .user-picture
  transform translateY(-2rem)
</style>
