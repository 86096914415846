<template>
  <svg viewBox="0 0 200 160">
    <path d="m127 120.187c.011 1.953-1.561 3.547-3.515 3.563h-46.0011c-.1563-5.026-1.9577-6.828-6.9839-6.984v-75.9525c-.011-1.9536 1.5611-3.5475 3.5146-3.5635h49.4704c1.954.016 3.526 1.6099 3.515 3.5635v43.1865h1.5v-43.1865c.01-2.7816-2.233-5.0467-5.015-5.0635h-49.4704c-2.7816.0168-5.0247 2.2819-5.0146 5.0635v76.6865c0 .199.079.39.2197.53l7 7c.1406.141.3314.22.5303.22h46.735c2.782-.017 5.025-2.282 5.015-5.063v-10.187h-1.5v10.187zm-55.3617-1.859c2.9266.272 4.0117 1.357 4.2839 4.284l-4.2839-4.284z"
          :fill="colorDark" />
    <path d="m99 57h-23v1.5h23v-1.5zm65.75 39.98c.414 0 .75.3358.75.75 0 .4142-.336.75-.75.75h-9c-.414 0-.75-.3358-.75-.75 0-.4142.336-.75.75-.75h9zm-4.305-18.125c.207.359.084.8179-.275 1.025l-7.79 4.5c-.359.2071-.818.084-1.025-.275-.207-.359-.084-.8179.275-1.025l7.79-4.5c.359-.2071.818-.084 1.025.275zm-18.045-4.975 4.5-7.79c.207-.359.084-.8179-.275-1.025-.359-.2071-.818-.084-1.025.275l-4.5 7.79c-.207.359-.084.8179.275 1.025.359.2071.818.084 1.025-.275zm17.77 41.74c.343.208.462.648.27 1v-.01c-.093.177-.254.31-.445.368-.192.058-.399.037-.575-.058l-7.79-4.5c-.359-.207-.482-.666-.275-1.025.207-.359.666-.482 1.025-.275l7.79 4.5zm-13.55 14.78c.343-.208.462-.648.27-1l-4.5-7.79c-.134-.232-.382-.375-.65-.375-.268-.001-.516.142-.65.375-.134.232-.134.518 0 .75l4.5 7.79c.216.353.676.465 1.03.25zm-70.62-67.4h32v1.5h-32v-1.5zm19.8994 11.9414h-19.8603v1.5h19.8603v-1.5zm-19.8994 6.0586h25v1.5h-25v-1.5zm26-12h-26v1.5h26v-1.5z"
          :fill="colorGrey" />
    <path d="m127 43.75h-56.5v1.5h56.5v-1.5zm-11 41.5h23c2.071 0 3.75 1.6789 3.75 3.75v16c0 2.071-1.679 3.75-3.75 3.75h-23c-2.071 0-3.75-1.679-3.75-3.75v-16c0-2.0711 1.679-3.75 3.75-3.75zm23 1.5h-23c-1.243 0-2.25 1.0074-2.25 2.25v16c0 1.243 1.007 2.25 2.25 2.25h23c1.243 0 2.25-1.007 2.25-2.25v-16c0-1.2426-1.007-2.25-2.25-2.25zm.034 1.162c.325-.2571.797-.2023 1.054.1225.234.2952.21.7119-.041.9784l-.081.0751-12 9.5c-.243.192-.574.2133-.837.064l-.095-.064-12-9.5c-.324-.2571-.379-.7288-.122-1.0535.234-.2953.645-.3674.962-.185l.092.0625 11.533 9.13 11.535-9.13z"
          :fill="colorPrimary" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
