<template>
  <div class="progression-gauge">
    <p class="progression-gauge__title center">
      {{ t('account_overview.remaining', { n: dayjs(props.endDate).fromNow() }) }}
    </p>
    <div class="progression-gauge__container">
      <span class="progression-gauge__container__date">{{ formatDateText(props.startDate, 'D MMM YYYY') }}</span>
      <line-gauge class="progression-gauge__container__gauge"
                  :percentage="percentage"
                  theme="primary"
                  size="large" />
      <span class="progression-gauge__container__date">{{ formatDateText(props.endDate, 'D MMM YYYY') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import { formatDateText, getDateDifference } from '@/helpers/utils/date'

import LineGauge from '@/pages/cards/components/LineGauge.vue'

const { t } = useI18n()

const props = defineProps<{
  startDate: string,
  endDate: string
}>()

const today = dayjs()

const totalDays = computed(() => getDateDifference(props.startDate, props.endDate, 'day'))
const elapsedDays = computed(() => getDateDifference(props.startDate, today, 'day'))

const percentage = computed(() => { return elapsedDays.value * 100 / totalDays.value })
</script>

<style lang="stylus" scoped>
.progression-gauge
  &__container
    display flex
    justify-content space-between
    align-items center
    &__date
      width 10rem
      color $colorShinyGray
      &:last-child
        text-align right
    &__gauge
      margin 0 2rem
</style>
