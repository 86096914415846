<template>
  <section data-cy="operations.list"
           class="section section-operations">
    <div class="section__heading">
      <div class="section__heading__left align-items-center">
        <h1 class="section-title">
          {{ $t("title.operations") }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters show-text />
          <button v-if="hasExportFeature"
                  class="btn btn--default"
                  @click="showExportPanel">
            <i class="btn-icon btn-icon--left">
              <ic-export class="ic ic--20 ic--white" />
            </i>
            {{ $t("button.export") }}
          </button>
        </div>
      </div>
    </div>
    <component-filters :list-filter="operationFilters"
                       :number-of-results="numberOfResults"
                       :total-credit="operationsTotalCredit"
                       :total-debit="operationsTotalDebit"
                       is-section-operations
                       @filters="filters" />
    <operations-tabs />
    <component-alert v-if="isAlertVisible"
                     type="info"
                     :message="$tc('alerts.operation_to_justify', operationsToJustify)">
      <component-button class="btn btn--xs btn--info"
                        :label="$t('button.show_operations')"
                        :show-loader="false"
                        @click="$router.push({ name: 'operations-claimed' })" />
    </component-alert>
    <router-view :key="$route.name"
                 :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import store from '@/config/store'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'
import { EFilter } from '@/types/filter.d'

import OperationsTabs from '@/pages/operations/components/OperationsTabs.vue'
import OperationsExport from '@/pages/operations/sidepanel/OperationsExport.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'

export default {
  components: {
    OperationsTabs,
    ComponentButton,
    IcExport,
    ButtonFilters,
    ComponentFilters,
    ComponentAlert
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const operationStore = useOperationStore()

    const { account, operationFilters } = storeToRefs(accountStore)
    const { operations, operationsTotalCredit, operationsTotalDebit } = storeToRefs(operationStore)

    return { account, appStore, operations, operationFilters, operationStore, operationsTotalCredit, operationsTotalDebit }
  },

  data () {
    return {
      store,
      numberOfResults: null,
      filter: {
        attachment: null,
        user_uuid: null,
        category: null,
        charge_back: null,
        expense_category: null,
        from: null,
        is_compliant: null,
        max: null,
        min: null,
        proof_filter: null,
        search: null,
        to: null,
        type: null,
        affectations: null
      },

      operationsToJustify: 0
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    isFiltered () {
      return !!(
        typeof (this.filter.attachment) === 'number' ||
        this.filter.user_uuid ||
        this.filter.category ||
        this.filter.charge_back !== null ||
        this.filter.expense_category ||
        this.filter.from ||
        this.filter.is_compliant === false ||
        this.filter.max ||
        this.filter.min ||
        this.filter.proof_filter ||
        this.filter.search ||
        this.filter.to ||
        this.filter.type ||
        this.filter.affectations
      )
    },

    isCard () {
      return this.$route.name === 'operations-cards'
    },

    isAlertVisible () {
      return this.operationsToJustify > 0
    },

    hasExportFeature () {
      return hasFeature(EFeature.OperationExport)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('download-export-success', this.appStore.closeSidePanel)
    this.$bus.on('operation-proof-update', this.getOperationsToJustifyCount)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('download-export-success', this.appStore.closeSidePanel)
    this.$bus.off('operation-proof-update', this.getOperationsToJustifyCount)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        attachment: null,
        user_uuid: null,
        category: null,
        charge_back: null,
        expense_category: null,
        from: null,
        is_compliant: null,
        max: null,
        min: null,
        proof_filter: null,
        search: null,
        to: null,
        type: null,
        affectations: null
      }
      this.getOperationsToJustifyCount()
      this.operationStore.getOperationTypes()
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        attachment: filters.attachment,
        user_uuid: filters.user_uuid,
        category: filters.category,
        charge_back: filters.charge_back,
        expense_category: filters.expense_category,
        from: filters.from,
        is_compliant: filters.is_compliant,
        max: filters.max,
        min: filters.min,
        proof_filter: filters.proof_filter,
        search: filters.search,
        to: filters.to,
        type: filters.type,
        affectations: filters.affectation
      }
    },

    showExportPanel () {
      this.appStore.showSidePanel(
        OperationsExport,
        {
          type: 'operations',
          filter: this.filter,
          isFiltered: this.isFiltered,
          persistingFilters: this.isCard ? { card: true } : {}
        },
        { wrapperClass: 'sidepanel-export sidepanel--md', backgroundOverlay: true }
      )
    },

    async getOperationsToJustifyCount () {
      const { proof_claimed_count } = await this.operationStore.getOperationsCount()
      this.operationsToJustify = parseInt(proof_claimed_count)
    }
  }
}
</script>
