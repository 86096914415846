import { Ref, ref } from 'vue'
import dayjs from 'dayjs'

import { Model } from '@/models/Model'
import { IMessage } from '@/types/message.d'

export class ModelMessage extends Model {
  readonly id?: number
  readonly created_at?: number
  public subject: string
  public from: string
  public content: string
  public is_read: Ref<boolean>
  public selected: Ref<boolean> = ref(false)

  public apiFields = ['subject', 'content']

  private constructor (properties: IMessage) {
    super()

    this.id = properties?.id
    this.created_at = properties?.created_at
    this.subject = properties?.subject || ''
    this.from = properties?.from || ''
    this.content = properties?.content || ''
    this.is_read = ref(properties?.is_read || false)

    return this.onConstructed()
  }

  static create (properties?: IMessage): ModelMessage {
    return new ModelMessage(properties || {} as IMessage)
  }

  createdAtFormatted (format = 'lll') {
    if (!this.created_at) return null
    return dayjs.unix(this.created_at).format(format)
  }
}
