<template>
  <section class="section section--empty">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account-sca' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.sca') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <div v-else-if="hasResult"
           class="block-options">
        <template v-for="(item, index) in scaAvailable"
                  :key="index">
          <router-link v-if="$config.features.account.sca.methods.includes(item.type)"
                       :to="{ name: 'account-sca-configure-token', params: { type: item.type } }"
                       class="block-options__item">
            <span v-if="item.preferred"
                  class="top-left-badge">{{ $t('general.recommended') }}</span>
            <image-mobile-app v-if="item.type === 'app'"
                              sca
                              class="icon" />
            <image-mobile-app v-else-if="item.type === 'sms'"
                              code
                              class="icon" />
            <image-computer v-else-if="item.type === 'email'"
                            code
                            class="icon" />
            <div class="option-text">
              <h4>{{ getTitle(item.type) }}</h4>
              <p>{{ getContent(item.type) }}</p>
            </div>
          </router-link>
        </template>
      </div>
      <div v-else
           class="placeholder-action-description">
        <component-placeholder :label="$t('placeholder.no_sca_method.title')"
                               :content="$t('placeholder.no_sca_method.subtitle')">
          <computer-phone-security />
        </component-placeholder>
        <div class="center">
          <button class="btn btn--default placeholder-btn"
                  @click="$router.push({ name: 'account-sca' })">
            {{ $t('button.ok_understood') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores/auth'
import { useScaStore } from '@/stores/sca'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import ImageComputer from '@/components/svg/img/computer.vue'
import ComputerPhoneSecurity from '@/components/svg/img/computer-phone-security.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'

export default {
  components: {
    IcArrow,
    ComputerPhoneSecurity,
    ComponentPlaceholder,
    LoaderSpinner,
    ImageComputer,
    ImageMobileApp
  },

  setup () {
    const authStore = useAuthStore()
    const scaStore = useScaStore()

    const { scaAvailable } = storeToRefs(scaStore)

    return { scaAvailable, authStore, scaStore }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    hasResult () {
      return this.scaAvailable.some(sca => this.$config.features.account.sca.methods.includes(sca.type))
    }
  },

  async created () {
    this.loading = true
    if (this.authStore.isScaSectionEnforced) {
      await this.authStore.refreshUser()
    }
    await this.scaStore.getScaAvailable()
    this.loading = false
  },

  methods: {
    getTitle (type) {
      return type ? this.$i18n.t(`sca.method.${type}.title`) : ''
    },

    getContent (type) {
      return type ? this.$i18n.t(`sca.method.${type}.subtitle`) : ''
    }
  }
}
</script>
