<template>
  <div id="mcc"
       data-cy="cards.settings.section.categories"
       class="form__fieldset">
    <group-title :title=" $t('cards_settings.categories')" />
    <div class="form__fieldset__group">
      <div class="bordered-box">
        <div class="mcc-list">
          <template v-for="col in [0, 1]"
                    :key="col">
            <div class="mcc-list__col">
              <template v-for="(item, index) in settings.authorization_categories"
                        :key="index">
                <div v-if="index % 2 === col"
                     :data-cy="`cards.settings.categories.${item.name}`"
                     class="mcc-list__item"
                     :class="{ 'mcc-list__item--off': !item.enabled }">
                  <div class="mcc-list__item__label">
                    <toggle-switch :model-value="item.enabled"
                                   :disabled="disabled"
                                   :toggle-class="'switch-btn--sm'"
                                   @update:model-value="value => updateCardSettings({ ...item, enabled: value })" />
                    <ic-category v-bind="{ [item.name]: true }"
                                 class="ic ic-category ic--gray" />
                    <span class="switch-btn-label">{{ item.label }}</span>
                  </div>
                  <div v-if="showConfigure && item.enabled"
                       class="mcc-list__item__configure">
                    <button class="btn-link strong"
                            data-cy="cards.settings.categories.configure"
                            type="button"
                            :disabled="disabled"
                            @click="showConfigurationPanel(item)">
                      {{ $t('button.configure') }}
                    </button>
                    <span v-tooltip="{ content: getTooltipText(item.limit), theme: 'tooltip' }"
                          class="ic-helper">
                      <ic-info outline
                               class="ic" />
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatAmount, isNumber } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'

import GroupTitle from '@/components/GroupTitle.vue'
import IcCategory from '@/components/svg/icons/ic-category.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

import CategoryLimitsConfig from './sidepanel/CategoryLimitsConfig.vue'

export default {
  name: 'ComponentCardCategories',

  components: {
    IcInfo,
    IcCategory,
    ToggleSwitch,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    showConfigure: {
      type: Boolean,
      default: true
    },

    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)

    return {
      account,
      appStore,

      operationStore
    }
  },

  methods: {
    showConfigurationPanel (item) {
      this.appStore.showSidePanel(
        CategoryLimitsConfig,
        {
          category: {
            ...item
          },
          onUpdated: category => this.updateCardSettings(category)
        },
        {
          wrapperClass: 'sidepanel--lg',
          backgroundOverlay: true
        }
      )
    },

    getTooltipText (limits) {
      let text = ''

      const limitTypes = ['unit', 'daily', 'weekly', 'monthly', 'annually']

      limitTypes.forEach(limitType => {
        const limitValue = limits[`payment_${limitType}_limit`]
        if (isNumber(limitValue)) {
          text += `${this.$t(`limit.${limitType}`)} : ${formatAmount(limitValue, this.account.currency, { trailingZeroDisplay: 'stripIfInteger' })} \n`
        }
      })

      return text || this.$t('card.limit.no_limit')
    },

    updateCardSettings (item) {
      this.$emit('update-card-settings', { authorization_categories: [item] })
    }
  }
}
</script>

<style lang="stylus" scoped>
.mcc-list
  padding 2rem 3rem
  display flex
  flex 1

  &__col
    flex 1

    &:first-child
      border-right 1px solid $colorLightGray
      padding-right 3rem

    &:last-child
      padding-left 3rem

  &__item
    display flex
    align-items center
    justify-content space-between
    height 5rem

    .ic-category
      margin-left 2rem
      margin-right 1rem

    &--off
      .ic-category
      .switch-btn-label
        opacity 0.5

    &__label
      display flex
      align-items center

    &__configure
      display flex
      align-items center
      .ic
        transition fill .2s
        fill $colorMidGray
        &:hover
          fill $primaryColor

.btn-link
  margin-right .6rem
</style>
