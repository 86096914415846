<template>
  <svg viewBox="0 0 24 24">
    <path v-if="outline"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924zm.025 10.962c.79 0 1.376.592 1.376 1.389 0 .791-.587 1.382-1.376 1.382a1.35 1.35 0 0 1-1.382-1.382c0-.795.59-1.389 1.382-1.389zm1.137-8-.125 7.12H11l-.125-7.12z" />
    <path v-else
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm.025 12.5c-.792 0-1.382.594-1.382 1.389 0 .79.592 1.382 1.382 1.382.79 0 1.376-.59 1.376-1.382 0-.797-.585-1.389-1.376-1.389zm1.137-8h-2.287l.125 7h2.037z" />
  </svg>
</template>

<script>
export default {
  props: {
    outline: Boolean
  }
}
</script>
