import { defineStore } from 'pinia'

import axiosClient from '@/api'

export const useDocumentStore = defineStore('document', {
  state: () => {
    return {
      dropzoneQueue: false
    }
  },

  actions: {
    async analyzeDocument (document, abortSignal) {
      const payload = new FormData()

      payload.append('files', document)

      try {
        const { data } = await axiosClient.post('/invoices/analyze', payload, { signal: abortSignal })
        return {
          amount: data.amount,
          label: data.label,
          expense_date: data.expense_date
        }
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getDocumentCheck (uuid, signature) {
      try {
        const { data } = await axiosClient.get(`/documents/${uuid}/check/${signature}`, { doNotShowBanner: true })
        return { success: true, data }
      } catch (e) {
        console.error(e)
        return { success: false, data: e.response.status }
      }
    },

    setDropzoneQueue (value) {
      this.dropzoneQueue = value
    }
  }
})
