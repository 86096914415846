<template>
  <svg viewBox="0 0 16 16">
    <path d="M13.5 2.5A1.5 1.5 0 0 1 15 4v8a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12V4a1.5 1.5 0 0 1 1.5-1.5zm0 2.393L8.48 9.076a.75.75 0 0 1-.863.069l-.097-.069L2.5 4.894V12h11zM12.227 4H3.772l4.227 3.523z" />
  </svg>
</template>

<script>
export default {
}
</script>
