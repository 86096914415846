<template>
  <div class="form__row row">
    <div class="form-group col col--sm-6">
      <label class="label input__label">{{ $t('form.language.label') }}</label>
      <language-dropdown v-model="language"
                         variant="noborder" />
    </div>
    <div class="form-group col col--sm-6">
      <label class="label input__label">&nbsp;</label>
      <div class="form-control form-control--transparent p-0">
        <component-info size="sm"
                        style="height: 45px">
          {{ $t('form.language.help') }}
        </component-info>
      </div>
    </div>
  </div>
</template>

<script>
import ComponentInfo from '@/components/Info.vue'
import LanguageDropdown from '@/components/LanguageDropdown.vue'

export default {
  components: {
    ComponentInfo,
    LanguageDropdown
  },

  props: {
    model: {
      type: [String, null],
      default: null
    }
  },

  emits: ['update-model'],

  data () {
    return {
      language: null
    }
  },

  watch: {
    language: function () {
      this.$emit('update-model', { language: this.language })
    }
  },

  created () {
    this.language = this.model
  }
}
</script>
