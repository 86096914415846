'use strict'

import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { ModelMessage } from '@/models/Message/ModelMessage'
import { useAccountStore } from '@/stores/account'
import { IHeaderResponse } from '@/types/headers.d'
import { IMessage, IMessageState } from '@/types/message.d'

export const useMessageStore = defineStore('message', {
  state: (): IMessageState => ({
    messages: [],
    messagesPagination: {
      current: 1,
      count: 1,
      perPage: 50,
      totalItems: 0
    }
  }),

  actions: {
    async getMessages (params = {}): Promise<void> {
      try {
        const { data, headers } = await axiosClient.get<IMessage[]>('/messages', { params })
        this.messagesPagination = this.parsePagination(headers)
        this.updateUnreadMessageFromHeaders(headers)

        this.messages = data.map(api => ModelMessage.create(api))
      } catch (error) {
        console.error(error)
      }
    },

    async getMessage (messageId: number): Promise<ModelMessage|null> {
      try {
        const { data, headers } = await axiosClient.get<IMessage>(`/messages/${messageId}`)
        this.updateUnreadMessageFromHeaders(headers)
        const current = this.messages.findIndex(message => message.id === data.id)
        const model = ModelMessage.create(data)

        if (current !== -1) { this.messages[current] = model }

        return model
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async sendMessage (message: ModelMessage): Promise<boolean> {
      try {
        await axiosClient.post('/messages', message.toApi())

        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    updateUnreadMessageFromHeaders (headers: IHeaderResponse): void {
      const accountStore = useAccountStore()
      if (headers['x-messages-unread-count'] && accountStore.account && parseInt(headers['x-messages-unread-count']) !== accountStore.account.unread_messages_count) {
        accountStore.account.unread_messages_count = parseInt(headers['x-messages-unread-count'])
      }
    }
  }
})
