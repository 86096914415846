<template>
  <div class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ user ? $t('title.vehicle', { name: `${user.first_name} ${user.last_name}` }) : $t('title.add_vehicle') }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <infos-vehicle :user="user"
                       :display-footer="false" />
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="form-buttons">
        <button class="btn btn--gray"
                @click="appStore.closeSidePanel">
          {{ $t('button.cancel') }}
        </button>
        <component-button :label="$t('button.validate')"
                          class="btn btn--primary"
                          @click="onSubmit" />
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

import InfosVehicle from '@/pages/account/infos/components/Vehicle.vue'

import ComponentButton from '@/components/Button.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    ComponentButton,
    ComponentSidepanelFooter,
    InfosVehicle
  },

  props: {
    user: {
      type: Object,
      default: () => null
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  methods: {
    onSubmit () {
      this.$bus.emit('submit-add-vehicle-form')
    }
  }
}
</script>
