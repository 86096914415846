<template>
  <div class="modal__content center">
    <div class="modal__body">
      <div class="placeholder-action-description">
        <component-placeholder :label="$t('placeholder.statement_export.title')"
                               :content="$t('placeholder.statement_export.subtitle')">
          <file-export />
        </component-placeholder>
        <div class="center">
          <component-button type="button"
                            class="btn btn--primary"
                            :label="$t('button.ok_understood')"
                            @click="$emit('close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComponentButton from '@/components/Button.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import FileExport from '@/components/svg/img/file-export.vue'

export default {
  components: {
    FileExport,
    ComponentPlaceholder,
    ComponentButton
  },

  emits: ['close']
}
</script>
