<template>
  <svg viewBox="0 0 24 24">
    <g fill-rule="evenodd">
      <path v-if="accounting"
            d="M7.5 4A1.5 1.5 0 0 0 6 5.5v13A1.5 1.5 0 0 0 7.5 20h9a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 16.5 4h-9Zm.8 2a.3.3 0 0 0-.3.3v3.4a.3.3 0 0 0 .3.3h7.4a.3.3 0 0 0 .3-.3V6.3a.3.3 0 0 0-.3-.3H8.3ZM8 13.3a.3.3 0 0 1 .3-.3h1.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H8.3a.3.3 0 0 1-.3-.3v-1.4Zm.3 2.7a.3.3 0 0 0-.3.3v1.4a.3.3 0 0 0 .3.3h1.4a.3.3 0 0 0 .3-.3v-1.4a.3.3 0 0 0-.3-.3H8.3Zm2.7-2.7a.3.3 0 0 1 .3-.3h1.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3h-1.4a.3.3 0 0 1-.3-.3v-1.4Zm.3 2.7a.3.3 0 0 0-.3.3v1.4a.3.3 0 0 0 .3.3h1.4a.3.3 0 0 0 .3-.3v-1.4a.3.3 0 0 0-.3-.3h-1.4Zm2.7-2.7a.3.3 0 0 1 .3-.3h1.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3h-1.4a.3.3 0 0 1-.3-.3v-1.4Zm.3 2.7a.3.3 0 0 0-.3.3v1.4a.3.3 0 0 0 .3.3h1.4a.3.3 0 0 0 .3-.3v-1.4a.3.3 0 0 0-.3-.3h-1.4Z" />
      <path v-else-if="cards"
            d="M3 6.826C3 5.818 3.818 5 4.826 5h14.348C20.182 5 21 5.818 21 6.826V8H3V6.826ZM3 10h18v6.174A1.826 1.826 0 0 1 19.174 18H4.826A1.826 1.826 0 0 1 3 16.174V10Zm12.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3Z" />
      <path v-else-if="cardholder"
            d="M17.182 12c2.496 0 4.611 2.27 4.816 5.168a.849.849 0 0 1-.172.584.618.618 0 0 1-.486.248H9.66a.62.62 0 0 1-.486-.248.85.85 0 0 1-.172-.584C9.207 14.27 11.322 12 13.818 12h3.364ZM9.82 12c-1.455 1.342-2.5 3.497-2.77 6H3.708A1.707 1.707 0 0 1 2 16.304V12h7.82Zm5.68-8c1.654 0 3 1.307 3 2.914v1.172c0 1.607-1.346 2.914-3 2.914s-3-1.307-3-2.914V6.914C12.5 5.307 13.846 4 15.5 4ZM11 8v.837c0 .591.134 1.154.375 1.664L2 10.5v-.804C2 8.76 2.769 8 3.707 8H11Z" />
      <path v-else-if="credits"
            d="M18.5 6h-10l2.557 2H16.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2.885l1.136.889C15.5 11.5 15.5 12 15.5 12.5V17h3c1 0 1.5-.5 1.5-1.5v-8c0-1-.5-1.5-1.5-1.5ZM6.077 6C5.508 6 5 6.472 5 7.053v8.281c0 .465.29.89 1.03 1.404L13 22c.568 0 1-.418 1-1v-8c0-.889-.922-1.482-.922-1.482L6.077 6.001Z" />
      <path v-else-if="dashboard"
            d="M6 5h5v6H5V6a1 1 0 0 1 1-1Zm-1 8v5a1 1 0 0 0 1 1h5v-6H5Zm8 0v6h5a1 1 0 0 0 1-1v-5h-6Zm6-2V6a1 1 0 0 0-1-1h-5v6h6Z" />
      <path v-else-if="debits"
            d="M14 15.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V10H7.69a1 1 0 0 1-.756-1.655l4.31-4.973a1 1 0 0 1 1.512 0l4.31 4.973A1 1 0 0 1 16.31 10H14v5.5ZM6.5 18a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-11Z" />
      <path v-else-if="loyalty"
            d="M11.987 2a1 1 0 1 0 0 2C16.413 4 20 7.583 20 12s-3.586 8-8.012 8C7.767 20 4.31 16.742 4 12.61a1 1 0 0 0-1.995.15c.39 5.17 4.711 9.24 9.983 9.24C17.515 22 22 17.524 22 12S17.515 2 11.987 2ZM8.693 4.705a1 1 0 0 0-.823-1.823A10.033 10.033 0 0 0 2.545 8.67a1 1 0 1 0 1.885.666 8.033 8.033 0 0 1 4.263-4.63Zm3.686 2.98a.414.414 0 0 0-.782 0l-.795 2.283a.414.414 0 0 1-.383.278l-2.417.05a.414.414 0 0 0-.242.744l1.926 1.458a.414.414 0 0 1 .146.45l-.7 2.311a.414.414 0 0 0 .633.46l1.986-1.38a.414.414 0 0 1 .473 0l1.986 1.38a.414.414 0 0 0 .633-.46l-.7-2.31a.414.414 0 0 1 .147-.451l1.925-1.458a.414.414 0 0 0-.241-.745l-2.418-.05a.414.414 0 0 1-.383-.277l-.794-2.282Z" />
      <path v-else-if="mileages"
            d="M15.108 5c1.03 0 1.954.649 2.3 1.6l1.261 3.495A2.088 2.088 0 0 1 20 12.041v3.679a.696.696 0 0 1-.696.695h-.193v2.161c0 .234-.244.424-.546.424H16.99c-.301 0-.546-.19-.546-.424v-2.16H7.556v2.16c0 .234-.245.424-.546.424H5.435c-.302 0-.546-.19-.546-.424v-2.16h-.193A.696.696 0 0 1 4 15.72v-3.68a2.09 2.09 0 0 1 1.33-1.945L6.58 6.63A2.465 2.465 0 0 1 8.892 5h6.216Zm-8.441 7.323h-.89a.442.442 0 0 0-.437.353l-.007.078v.861c0 .212.158.388.365.424l.08.007h.889a.44.44 0 0 0 .437-.353l.007-.078v-.861a.438.438 0 0 0-.444-.43Zm11.555 0h-.889a.44.44 0 0 0-.437.353l-.007.078v.861c0 .212.157.388.364.424l.08.007h.89a.442.442 0 0 0 .437-.353l.007-.078v-.861a.438.438 0 0 0-.445-.43Zm-3.11.216H8.888v.875h6.222v-.875Zm-.004-6.247H8.892c-.462 0-.884.301-1.054.766L6.794 9.953h10.41L16.15 7.028a1.127 1.127 0 0 0-.805-.71l-.123-.02-.114-.006Z" />
      <path v-else-if="refunds"
            d="M6.599 19.114A9 9 0 0 0 12.125 21c4.982 0 9.021-4.029 9.021-9s-4.04-9-9.021-9C7.514 3 3.66 6.467 3.158 11H1.853a.353.353 0 0 0-.282.566l2.293 3.057a.353.353 0 0 0 .565 0l2.293-3.057A.353.353 0 0 0 6.44 11H5.174c.489-3.414 3.437-6 6.95-6 3.88 0 7.022 3.135 7.022 7s-3.143 7-7.021 7a7 7 0 0 1-4.302-1.467 1 1 0 1 0-1.224 1.581ZM15.646 9.09l-.556 1.194c-.408-.62-1.202-1.136-2.337-1.136-1.248 0-1.986.574-2.27 1.377h4.494l-.545 1.148H10.28a4.516 4.516 0 0 0-.023.39c0 .115.012.241.023.356h3.802l-.534 1.148h-2.95c.227.631 1.021 1.285 2.213 1.285 1.078 0 1.872-.482 2.269-.941v1.377c-.34.287-1.248.712-2.44.712-1.986 0-3.12-1.113-3.574-2.433h-1.42l.568-1.148h.579c-.012-.16-.012-.264-.012-.367 0-.115 0-.218.012-.38H7.646l.568-1.147h.85C9.463 9.09 10.825 8 12.81 8c1.135 0 2.157.402 2.837 1.09Z" />
      <path v-if="requests"
            d="M12 21.75c4.971 0 9-4.01 9-8.954 0 0 0-1-1-1s-1 1-1 1c0 3.85-3.134 6.97-7 6.97-3.865 0-7-3.12-7-6.97 0-3.572 2.713-6.555 6.25-6.93v1.955c0 .286.322.454.556.29l4.03-2.821a.354.354 0 0 0 0-.58l-4.03-2.82a.353.353 0 0 0-.556.289v1.71a1.013 1.013 0 0 0-.192.002C6.496 4.363 3 8.2 3 12.796c0 4.945 4.029 8.954 9 8.954Zm4.731-11.068a1 1 0 1 0-1.462-1.364l-3.935 4.216-1.603-1.716a1 1 0 0 0-1.462 1.364l2.333 2.5a1 1 0 0 0 1.463 0l4.666-5Z" />
      <path v-else-if="statements"
            d="M7.4 4A1.4 1.4 0 0 0 6 5.4v13.2A1.4 1.4 0 0 0 7.4 20h9.2a1.4 1.4 0 0 0 1.4-1.4v-7.4a.2.2 0 0 0-.2-.2H13a2 2 0 0 1-2-2V4.2a.2.2 0 0 0-.2-.2H7.4Zm9.917 4.317a.4.4 0 0 1-.283.683H13.8a.8.8 0 0 1-.8-.8V4.966a.4.4 0 0 1 .683-.283l3.634 3.634Z" />
      <path v-else-if="synthese"
            d="M15.866 5c.328 0 .628.183.778.475L20 12v6.125a.875.875 0 0 1-.875.875H4.875A.875.875 0 0 1 4 18.125V12l3.356-6.525A.875.875 0 0 1 8.134 5h7.732Zm2.534 7.438-2.8-6.126H8.4l-2.8 6.125h3.289c.592 1.75 1.63 2.626 3.111 2.626 1.482 0 2.518-.876 3.111-2.626H18.4Z" />
      <path v-else-if="transfers"
            d="M1.652 9.313a.5.5 0 0 1 0-.73l4.761-4.445a.5.5 0 0 1 .841.365v2.919h4.491a.5.5 0 0 1 .5.5v2.052a.5.5 0 0 1-.5.5h-4.49v2.919a.5.5 0 0 1-.842.365L1.652 9.313Zm10.099 4.213a.5.5 0 0 0-.5.5v2.052a.5.5 0 0 0 .5.5h4.994v2.919a.5.5 0 0 0 .841.365l4.762-4.444a.5.5 0 0 0 0-.731l-4.761-4.445a.5.5 0 0 0-.842.365v2.92h-4.994Z" />
      <path v-else-if="users"
            d="M10.134 18.5h11.232a.639.639 0 0 0 .466-.202.62.62 0 0 0 .166-.474c-.197-2.355-2.23-4.199-4.63-4.199h-3.236c-2.4 0-4.433 1.844-4.63 4.199a.619.619 0 0 0 .166.474c.12.129.29.202.466.202Zm-4.12-4.063c-2.08 0-3.842 1.537-4.012 3.5a.503.503 0 0 0 .143.395.564.564 0 0 0 .405.168h5.918A.6.6 0 0 1 8 18.265a.798.798 0 0 1-.165-.554c.09-1.263.569-2.4 1.293-3.26a3.544 3.544 0 0 0-.31-.014H6.015ZM15.75 5.5c-1.608 0-2.917 1.214-2.917 2.706v1.088c0 1.492 1.309 2.706 2.917 2.706 1.608 0 2.917-1.214 2.917-2.706V8.206c0-1.492-1.309-2.706-2.917-2.706Zm-7.917.813c-1.378 0-2.5 1.062-2.5 2.367v.952c0 1.306 1.122 2.368 2.5 2.368 1.379 0 2.5-1.062 2.5-2.368V8.68c0-1.305-1.121-2.367-2.5-2.367Z" />
      <path v-else-if="virtualcards"
            d="M4.826 5A1.826 1.826 0 0 0 3 6.826v9.348C3 17.182 3.818 18 4.826 18h5.29c.104-.426.277-.837.518-1.217a3.993 3.993 0 0 1 1.633-1.455l.146-.066.057-.185c.646-1.968 2.276-3.505 4.354-3.949l.233-.045a5.761 5.761 0 0 1 3.943.74V6.825A1.826 1.826 0 0 0 19.174 5H4.826ZM6 8.5a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V9a.5.5 0 0 0-.5-.5H6Zm15.674 7.15c-.623-1.818-2.428-2.913-4.279-2.595-1.85.317-3.211 1.955-3.226 3.882h-.192l-.165.007a1.967 1.967 0 0 0-1.49.913 2.078 2.078 0 0 0-.152 1.932l.069.144A1.973 1.973 0 0 0 13.976 21h7.394l.166-.004c1.315-.076 2.381-1.155 2.459-2.523.08-1.426-.94-2.667-2.322-2.823Z" />
      <path v-else-if="treasury"
            d="M6.5 4C5.11929 4 4 5.11929 4 6.5V17.5C4 18.8807 5.11929 20 6.5 20H17.5C18.8807 20 20 18.8807 20 17.5V6.5C20 5.11929 18.8807 4 17.5 4H6.5ZM12 8C11.4477 8 11 8.44772 11 9V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V9C13 8.44772 12.5523 8 12 8ZM7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V12ZM16 12.9375C15.4477 12.9375 15 13.3852 15 13.9375V16C15 16.5523 15.4477 17 16 17C16.5523 17 17 16.5523 17 16V13.9375C17 13.3852 16.5523 12.9375 16 12.9375Z" />
      <path v-else-if="expensecontrol"
            clip-rule="evenodd"
            d="m7.47082 11.25c-.76621 0-1.42067-.0071-1.96317-.0152.38115-3.50599 3.35123-6.2348 6.95865-6.2348 3.866 0 7 3.13401 7 7 0 3.866-3.134 7-7 7-1.6446 0-3.15419-.5659-4.34874-1.5143-.43253-.3434-1.06156-.2712-1.40498.1614-.34341.4325-.27117 1.0615.16137 1.4049 1.53551 1.2192 3.48025 1.948 5.59235 1.948 4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.71799 0-8.58807 3.63035-8.96919 8.25h-1.66831c-.06465 0-.12807.0177-.18334.0512-.05527.0336-.10027.0816-.13009.139s-.04331.1218-.039.1863.02625.1266.06343.1795l2.82001 4.03c.03264.0466.07604.0847.12652.111s.10656.04.16348.04c.05691 0 .11299-.0137.16347-.04.05049-.0263.09389-.0644.12653-.111l2.82101-4.03c.164-.234-.004-.556-.29-.556zm9.07928-.8201c.3434-.43256.271-1.06157-.1616-1.4049-.4326-.34332-1.0616-.27095-1.405.16165l-3.1167 3.92725-1.6285-1.4084c-.41772-.3613-1.04923-.3156-1.41051.1021-.36128.4178-.31553 1.0493.10219 1.4106l2.41742 2.0908c.2062.1783.4762.2647.7475.2393.2714-.0255.5206-.1605.69-.374z"
            fill-rule="evenodd" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    accounting: Boolean,
    cards: Boolean,
    cardholder: Boolean,
    credits: Boolean,
    dashboard: Boolean,
    debits: Boolean,
    loyalty: Boolean,
    mileages: Boolean,
    refunds: Boolean,
    requests: Boolean,
    statements: Boolean,
    synthese: Boolean,
    transfers: Boolean,
    users: Boolean,
    virtualcards: Boolean,
    treasury: Boolean,
    expensecontrol: Boolean
  }
}
</script>
