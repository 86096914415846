import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { useAccountStore } from '@/stores/account'

export const useMileageStore = defineStore('milage', {
  state: () => {
    return {
      mileages: [],
      mileagesPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      mileagesTotalAmount: 0,
      mileageUsers: [],
      mileageUsersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  actions: {
    async getMileages (state, page, params = {}) {
      if (state === 'done') {
        params.except_status = 'pending'
      } else {
        params.status = 'pending'
      }

      try {
        params.page = page
        params.mode = 'collection'

        const { data, headers } = await axiosClient.get('/mileages', { params })
        const collection = this.parseCollection(data, headers)

        this.mileages = collection.items
        this.mileagesTotalAmount = collection.totalAmount
        this.mileagesPagination = collection.pagination
      } catch (error) {
        console.error(error)
        this.mileages = []
        this.mileagesTotalAmount = 0
      }
    },

    async getMileageUsers (page, params = {}) {
      params.has_access = 'mileages'
      params.status = 'active'
      params.exclude = 'me'

      try {
        params.page = page
        const { data, headers } = await axiosClient.get('/users', { params })
        this.mileageUsersPagination = this.parsePagination(headers)

        this.mileageUsers = data
      } catch (error) {
        console.error(error)
      }
    },

    async getMileageDetail (id) {
      try {
        const { data } = await axiosClient.get(`/mileages/${id}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async saveMileage (mileage) {
      const payload = new FormData()

      if (mileage.documents?.length) {
        mileage.documents.forEach(document => payload.append('files[]', document))
      }
      delete mileage.documents
      delete mileage.document

      payload.append('jsonBody', JSON.stringify(mileage))

      try {
        const { data } = await axiosClient.post('/mileages', payload)
        const accountStore = useAccountStore()
        if (!accountStore.account.beneficiary) {
          accountStore.account.beneficiary = data.beneficiary
        }
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async editMileage (mileage) {
      delete mileage.documents
      delete mileage.document

      try {
        await axiosClient.put(`/mileages/${mileage.id}`, mileage)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getVehicleDistanceAmount (params) {
      try {
        const { data } = await axiosClient.get('/mileages/estimation', { params })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getMileagesExportCount (params = {}) {
      try {
        const { headers } = await axiosClient.head('/mileages/export', { params })
        return this.parsePagination(headers).totalItems
      } catch (error) {
        console.error(error)
        return 0
      }
    }
  }
})
