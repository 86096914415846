import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { useAccountStore } from '@/stores/account'

export const useRefundStore = defineStore('refund', {
  state: () => {
    return {
      refunds: [],
      refundsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  actions: {
    async getRefunds (state, page) {
      let refunds = []

      const params = { page }

      if (state === 'done') {
        params.except_status = 'to_validate,pending'
      } else {
        params.status = 'to_validate,pending'
      }

      try {
        const { data, headers } = await axiosClient.get('/refunds', { params })
        this.refundsPagination = this.parsePagination(headers)

        refunds = data
      } catch (error) {
        console.error(error)
      }

      this.refunds = refunds
    },

    async saveRefund (refund) {
      const payload = new FormData()

      if (refund.documents.length) {
        refund.documents.forEach(document => payload.append('documents[]', document))
      }
      delete refund.documents
      delete refund.document

      payload.append('jsonBody', JSON.stringify(refund))

      try {
        const { data } = await axiosClient.post('/refunds', payload)
        const accountStore = useAccountStore()
        if (!accountStore.account.beneficiary) {
          accountStore.account.beneficiary = data.beneficiary
        }
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async editRefund (refund) {
      delete refund.documents
      delete refund.document

      try {
        await axiosClient.put(`/refunds/${refund.id}`, refund)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
})
