<template>
  <div class="wallet-btn">
    <GoogleWidget v-if="Object.values(props.providers).includes(ECardWalletProvider.GOOGLE)"
                  :card-uuid="props.cardUuid"
                  @message="code => showMessage(code, ECardWalletProvider.GOOGLE)" />
  </div>
  <div class="wallet-btn">
    <AppleWidget v-if="Object.values(props.providers).includes(ECardWalletProvider.APPLE)"
                 :card-uuid="props.cardUuid"
                 @message="code => showMessage(code, ECardWalletProvider.APPLE)"
                 @handle-blur="computed.isBlurred = true" />
  </div>
  <Transition name="blur">
    <div v-if="computed.isBlurred"
         class="blur" />
  </Transition>
</template>

<script setup lang="ts">
import { type PropType } from 'vue'
import { reactive } from 'vue'
import { ECoreBannerTheme } from '@common/core-ui'

import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import { useAppStore } from '@/stores/app'
import { ECardWalletErrorCode, ECardWalletProvider } from '@/types/card.d'

import AppleWidget from '@/pages/cards/details/components/wallet/AppleWidget.vue'
import GoogleWidget from '@/pages/cards/details/components/wallet/GoogleWidget.vue'

const appStore = useAppStore()

const props = defineProps({
  providers: {
    type: Array as PropType<ECardWalletProvider[]>,
    required: true
  },
  cardUuid: {
    type: String,
    required: true
  }
})

const computed = reactive({
  isBlurred: false
})

const showMessage = (code: number|string, provider : ECardWalletProvider) => {
  computed.isBlurred = false

  console.warn(`Receiving a new message, status code: ${code}`)
  const messageProvider = { provider: i18n.global.t(`card.general.wallet.provider.${provider}`), code }
  const message = i18n.global.te(`card.general.wallet.banner.${code}`)
    ? i18n.global.t(`card.general.wallet.banner.${code}`, messageProvider)
    : i18n.global.t('card.general.wallet.banner.other', messageProvider)

  code === ECardWalletErrorCode.SUCCESS
    ? showToastSuccess(message)
    : appStore.showBanner(ECoreBannerTheme.Danger, message, true, true, 6000)
}

</script>

<style scoped lang="stylus">
.wallet-btn
  svg
    height 42px
    cursor pointer

.blur
  position: fixed
  top 0
  left 0
  height 100%
  width 100%
  z-index 9999
  background-color rgba(0, 0, 0, 0.6)
  backdrop-filter blur(8px)
  -webkit-backdrop-filter blur(8px)

  &-enter-active,
  &-leave-active
    transition opacity 0.4s ease

  &-enter-from,
  &-leave-to
    opacity 0

</style>
