<template>
  <div class="timeline"
       :class="`timeline--${type}`">
    <div v-if="type === 'block' && (title || subtitle)"
         class="timeline__header">
      <span class="timeline__header__title">
        {{ title }}
      </span>
      <span class="timeline__header__subtitle">
        {{ subtitle }}
      </span>
    </div>
    <div class="timeline__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentTimeline',

  components: {
  },

  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['block', 'lite', 'workflow'].includes(value)
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.timeline
  &--block
    .timeline__header
      display flex
      justify-content space-between
      margin-bottom 1.6rem
      min-height 2.2rem

      &__title
        font-size 1.2rem
        text-transform uppercase
        color $colorMidGray
        font-weight 500

      &__subtitle
        color $colorShinyGray

    .timeline__body
      position relative
      padding-left 3rem
      margin-bottom 2rem

      &:before
        content ""
        position absolute
        width 3px
        background alpha($colorShinyGray, 0.1)
        border-radius 1.5px
        left .5rem
        top -1rem
        bottom -1rem

  &--workflow
    padding-top 3rem

</style>
