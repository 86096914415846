<template>
  <section class="section section-beneficiaries">
    <div class="section__heading">
      <div class="row row--no-gutters">
        <div class="section__heading__left">
          <h1 v-if="!loading"
              class="section-title">
            <router-link class="btn-link btn-back"
                         :to="{ name: 'beneficiary-lists' }">
              <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
            </router-link>
            {{ currentBeneficiaryList.label }}
          </h1>
        </div>
        <div class="section__heading__right">
          <div class="section-actions">
            <button-filters />
            <component-popover trigger="hover"
                               popover-class="popover-actions-menu popover--pull-right">
              <template #trigger>
                <button class="btn btn--icon btn--white">
                  <i class="btn-icon">
                    <ic-settings class="ic ic--gray" />
                  </i>
                </button>
              </template>
              <div class="popover__item"
                   @click="$router.push({ name: 'beneficiaries-import', query: { listId: id } })">
                <div class="popover__item__icon">
                  <ic-import class="ic ic--16 ic--gray" />
                </div>
                <div style="white-space: nowrap">
                  {{ $t('button.import_beneficiaries') }}
                </div>
              </div>
              <div class="popover__item"
                   @click="exportBeneficiariesList">
                <div class="popover__item__icon">
                  <ic-export class="ic ic--gray" />
                </div>
                <div>{{ $t('button.export_beneficiaries_list') }}</div>
              </div>
            </component-popover>
            <button v-if="hasPermission($permissions.beneficiaryListsWrite) && currentBeneficiaryList.updatable && currentBeneficiaryList.list_type?.internal === false"
                    class="btn btn--secondary"
                    @click.prevent.stop="onEditList(currentBeneficiaryList)">
              {{ $t("button.edit_list") }}
            </button>
            <router-link v-if="hasPermission($permissions.beneficiariesWrite)"
                         class="btn btn--primary"
                         data-cy="beneficiaries.list.add-button"
                         :to="{ name: 'beneficiaries-create', query: { listId: id } }">
              {{ $t("button.add_beneficiary") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="beneficiariesPagination.totalItems"
                       @filters="filters" />
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <table v-else-if="beneficiaries.length"
             class="table table--hover">
        <thead>
          <tr>
            <th>{{ $t("table.beneficiary_name") }}</th>
            <th>{{ $t("table.code") }}</th>
            <th>{{ $t("table.account_number") }}</th>
            <th>{{ $t("table.bank_name") }}</th>
            <th v-if="showSearchResult">
              {{ $t("table.list") }}
            </th>
            <th />
            <th />
            <th v-if="hasPermission($permissions.beneficiariesWrite)" />
          </tr>
        </thead>
        <tbody v-if="showSearchResult">
          <template v-for="(list, key) in filteredBeneficiaryList"
                    :key="key">
            <tr v-if="list.length"
                class="header-table-section">
              <td colspan="8">
                <span class="arrow">&rsaquo;</span>{{
                  key === 'currentList' ? $tc('label.beneficiary.found_in', list.length, { name: currentBeneficiaryList.label }) : otherListsLabel
                }}
              </td>
            </tr>
            <BeneficiaryTableRow v-for="(beneficiary, i) in list"
                                 :key="`${key}-${i}`"
                                 :beneficiary="beneficiary"
                                 :current-beneficiary-list="currentBeneficiaryList"
                                 is-search-result />
          </template>
        </tbody>
        <tbody v-else>
          <BeneficiaryTableRow v-for="(beneficiary, i) in beneficiaries"
                               :key="i"
                               :beneficiary="beneficiary"
                               :current-beneficiary-list="currentBeneficiaryList" />
        </tbody>
      </table>
      <component-placeholder v-else
                             :label="showSearchResult ? $t('placeholder.no_beneficiary.found') : $t('placeholder.no_beneficiary.title')" />
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import IcImport from '@/components/svg/icons/ic-import.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'

import BeneficiaryTableRow from './components/BeneficiaryTableRow.vue'
import CreateBeneficiaryList from './modal/CreateBeneficiaryList.vue'

export default {
  components: {
    IcSettings,
    IcImport,
    IcExport,
    IcArrow,
    ButtonFilters,
    BeneficiaryTableRow,
    ComponentPlaceholder,
    ComponentFilters,
    ComponentPopover,
    LoaderSpinner
  },

  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    const { account } = storeToRefs(accountStore)
    const { beneficiaries, beneficiaryLists, currentBeneficiaryList, beneficiariesPagination } = storeToRefs(beneficiaryStore)

    return { account, appStore, beneficiaries, beneficiaryLists, beneficiaryStore, currentBeneficiaryList, beneficiariesPagination }
  },

  data () {
    return {
      loading: false,
      filter: {
        search: null
      },

      deleteModel: {
        label: 'prompt.beneficiary.delete.title',
        content: 'prompt.beneficiary.delete.content',
        cancel: 'button.no',
        submit: 'button.yes'
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    showSearchResult () {
      return !!this.filter.search
    },

    filteredBeneficiaryList () {
      return this.beneficiaries.reduce((acc, cur) => {
        this.currentBeneficiaryList.id === (cur.beneficiary_list?.id || 0)
          ? acc.currentList.push(cur)
          : acc.otherLists.push(cur)

        return acc
      }, { currentList: [], otherLists: [] })
    },

    otherListsLabel () {
      const otherLists = this.filteredBeneficiaryList.otherLists
        .reduce((acc, cur) => {
          const id = cur.beneficiary_list?.id || 0
          if (!acc.includes(id)) { acc.push(id) }

          return acc
        }, [])

      return otherLists.length > 1 ? this.$i18n.t('label.beneficiary.found_in_other_lists') : this.$i18n.tc('label.beneficiary.found_in_another_list', this.filteredBeneficiaryList.otherLists.length)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('account-switched', this.goBackToList)
    this.$bus.on('beneficiaries-list-edit-success', this.onEditSuccess)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('account-switched', this.goBackToList)
    this.$bus.off('beneficiaries-list-edit-success', this.onEditSuccess)
  },

  methods: {
    async initData () {
      this.loading = true
      this.showSearchResult
        ? await this.beneficiaryStore.getBeneficiaries({
          ...this.filter,
          expand: 'beneficiary_list',
          priority_list: this.id
        })
        : await this.beneficiaryStore.getBeneficiariesFromList(this.id)
      this.loading = false
    },

    onDeleteSuccess (message) {
      this.onSuccess(message || this.$i18n.t('message.success.beneficiary.delete'))
    },

    onEditSuccess () {
      this.onSuccess(this.$i18n.t('message.success.beneficiaries_list.update'))
    },

    onSuccess (message) {
      this.appStore.closeModal()
      showToastSuccess(message)
      this.initData()
    },

    async filters (filters) {
      this.filter = {
        search: filters.search
      }

      await this.initData()
    },

    exportBeneficiariesList () {
      downloadFileFromUrl('/beneficiaries/export', { beneficiary_list_id: this.id })
    },

    goBackToList () {
      this.$router.push({ name: 'beneficiary-lists' })
    },

    onEditList (list) {
      this.appStore.showModal(CreateBeneficiaryList, { list }, { wrapperClass: 'modal--md' })
    }
  }
}
</script>
