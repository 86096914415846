<template>
  <modal-dialog-prompt :title="t('account_overview.actions.withdraw')"
                       data-cy="early-withdraw.modal"
                       @close="emit('close')">
    <template #body>
      <p class="bold">
        {{ t('account_overview.withdraw.first_paragraph', { n: formatDateText(account?.detail.due_date) }) }}
      </p>
      <p> {{ t('account_overview.withdraw.second_paragraph') }} </p>
      <i18n-t tag="p"
              keypath="account_overview.withdraw.third_paragraph"
              scope="global">
        <template #link>
          <button class="underline"
                  @click="downloadContract">
            {{ $t('account_overview.withdraw.your_contrat') }}
          </button>
        </template>
      </i18n-t>
    </template>

    <template #footerButtons>
      <core-button :text="t('button.cancel')"
                   :theme="ECoreButtonTheme.GrayLight"
                   type="button"
                   @click="emit('close')" />
      <core-button :loading="loading"
                   data-cy="early-withdraw.modal.confirm"
                   :text="t('button.closing')"
                   :theme="ECoreButtonTheme.Primary"
                   type="button"
                   @click="onSubmit" />
    </template>
  </modal-dialog-prompt>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, ECoreButtonTheme } from '@common/core-ui'

import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDateText } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { isTermAccount } from '@/types/account.d'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

const emit = defineEmits([
  'close'
])

const accountStore = useAccountStore()
const loading = ref(false)
const { t } = useI18n()

const account = computed(() => {
  return accountStore.account && isTermAccount(accountStore.account)
    ? accountStore.account
    : null
})

async function onSubmit () {
  loading.value = true
  const success = await accountStore.requestEarlyWithdraw()
  if (success) {
    emit('close')
    showToastSuccess(t('message.success.account.withdraw'))
  }
  loading.value = false
}

function downloadContract () {
  downloadFileFromUrl(account.value?.detail.contract_url)
}
</script>
