import { config, event, query, set } from 'vue-gtag'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { defineStore } from 'pinia'

import i18n from '@/locales'
import { useAuthStore } from '@/stores/auth'
import { useFirebaseStore } from '@/stores/firebase'
import { ECookieStatus, logNetworkEventType, logUserPropertiesType, TCookiePreference } from '@/types/analytics.d'
import { IUser } from '@/types/auth.d'

interface IAnalytics {
  cookies: TCookiePreference,
  gtag: {
    enabled: boolean
  }
}

export const useAnalyticStore = defineStore('analytics', {
  state: (): IAnalytics => {
    return {
      cookies: {
        analytics: ECookieStatus.UNDEFINED,
        functional: ECookieStatus.CONSENTED
      },
      gtag: {
        enabled: !!import.meta.env.VITE_GTAG_ID
      }
    }
  },

  actions: {
    restorePreferences () {
      this.gtag.enabled = !!import.meta.env.VITE_GTAG_ID

      const cookie = JSON.parse(Cookies.get('cookie-consent') || 'null')

      if (cookie !== null && Object.prototype.hasOwnProperty.call(cookie, 'analytics')) {
        this.cookies.analytics = cookie.analytics
      }
    },

    logNetworkEvent (config: AxiosRequestConfig, response: AxiosResponse | null, errorCode: string | null): void {
      if (!this.gtag.enabled) {
        return
      }

      const log: logNetworkEventType = {
        request_method: config.method,
        request_path: config.url
      }

      if (errorCode) {
        log.request_status_key = errorCode === 'ERR_CANCELED' ? 'cancelled' : 'failed'
      }

      if (response) {
        if (response.headers['x-debug-tag']) {
          log.request_debug_tag = response.headers['x-debug-tag']
        }
        log.request_id = response.headers['x-request-id']
        log.request_status_code = response.status
      }

      event('network_request', log)
    },

    logConsent (status: boolean): void {
      if (!this.gtag.enabled) {
        return
      }

      const consentStatus = status ? 'granted' : 'denied'

      query('consent', 'update', {
        ad_storage: consentStatus,
        analytics_storage: consentStatus,
        personalization_storage: consentStatus
      })
    },

    logAnonymizeIp (value: boolean): void {
      if (!this.gtag.enabled) {
        return
      }

      config({
        anonymize_ip: value
      })
    },

    async initAnalytics (): void {
      const firebaseStore = useFirebaseStore()
      firebaseStore.initialize()

      if (!this.gtag.enabled) {
        return
      }

      if (this.cookies.analytics === ECookieStatus.UNDEFINED) {
        this.logAnonymizeIp(true)
      } else if (this.cookies.analytics === ECookieStatus.NOT_CONSENTED) {
        this.logConsent(false)
        this.logAnonymizeIp(true)
      } else {
        this.logConsent(true)
        this.logAnonymizeIp(false)
        firebaseStore.initializeAnalytics()
      }

      this.setUserDimensions()
    },

    setUserDimensions (): void {
      if (!this.gtag.enabled) {
        return
      }

      let userProperties: logUserPropertiesType = {
        locale: i18n.global.locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      const authStore = useAuthStore()
      const user: IUser = authStore.user

      if (user?.uuid) {
        userProperties = {
          ...userProperties,
          sca_enabled: user.sca?.configured,
          multiple_accounts: user.numberOfAccounts > 1,
          sub_user: !user.defaultAccount?.permissions?.includes('account.owner'),
          user_id: this.cookies.analytics === ECookieStatus.CONSENTED ? user.uuid : null
        }

        useFirebaseStore().setAnalyticsUserId(user?.uuid)
      }

      set({ user_properties: userProperties })
    },

    setCookiePreferences (preferences: TCookiePreference): void {
      this.cookies = preferences
      Cookies.set('cookie-consent', JSON.stringify(preferences), { expires: 365, domain: import.meta.env.VITE_COOKIE_CONSENT_DOMAIN || '', path: '/' })
    }
  }
})
