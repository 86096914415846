<template>
  <div id="alerts"
       data-cy="cards.settings.section.alerts"
       class="form__fieldset">
    <group-title :title="$t('cards_settings.alerts.title')" />
    <div class="form__fieldset__group">
      <div class="row">
        <div v-if="isAlertLimitAvailable"
             class="col col--sm-6">
          <div class="bordered-box form__fieldset__block">
            <toggle-switch :model-value="settings.alert_limit_enabled"
                           :disabled="disabled"
                           :toggle-class="'switch-btn--sm'"
                           @update:model-value="value => updateCardSettings('alert_limit_enabled', value)" />
            <span class="switch-btn-label"
                  :class="{ 'text-muted': !settings.alert_limit_enabled }">{{ $t('cards_settings.alerts.alert_limit') }}</span>
          </div>
        </div>
        <div v-if="isAlertSpentAvailable"
             class="col col--sm-6">
          <fieldset-block>
            <limit-item :enabled="settings.alert_spent.enabled"
                        :label="$t('cards_settings.alerts.alert_spent')"
                        :disabled="disabled"
                        :limit="settings.alert_spent.limit"
                        @update-enabled="value => updateCardSettings('alert_spent', { enabled: value, limit: settings.alert_spent.limit })"
                        @update-limit="value => updateCardSettings('alert_spent', { enabled: settings.alert_spent.enabled, limit: parseInt(value) })" />
          </fieldset-block>
        </div>
        <div v-if="isAlertCardUsedAvailable"
             class="col col--sm-6">
          <div class="bordered-box form__fieldset__block">
            <toggle-switch :model-value="settings.alert_card_used_enabled"
                           :disabled="disabled"
                           :toggle-class="'switch-btn--sm'"
                           @update:model-value="value => updateCardSettings('alert_card_used_enabled', value)" />
            <span class="switch-btn-label"
                  :class="{ 'text-muted': !settings.alert_card_used_enabled }">
              {{ alertCardUsedLabel }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { isCappedVirtualCard, isOneTimeVirtualCard } from '@/helpers/utils/card'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import FieldsetBlock from '@/components/FieldsetBlock.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LimitItem from '@/components/limits/LimitItem.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  name: 'ComponentCardAlerts',

  components: {
    LimitItem,
    FieldsetBlock,
    ToggleSwitch,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    isAlertLimitAvailable () {
      return !isOneTimeVirtualCard({ type: this.type })
    },

    isAlertSpentAvailable () {
      return !isOneTimeVirtualCard({ type: this.type })
    },

    isAlertCardUsedAvailable () {
      return isCappedVirtualCard({ type: this.type }) || isOneTimeVirtualCard({ type: this.type })
    },

    alertCardUsedLabel () {
      return isOneTimeVirtualCard({ type: this.type })
        ? this.$t('cards_settings.alerts.alert_card_used')
        : this.$t('cards_settings.alerts.alert_card_used_80')
    }
  },

  methods: {
    getCurrencySymbol,

    updateCardSettings (key, value) {
      this.$emit('update-card-settings', {
        [key]: value
      })
    }
  }
}
</script>
