<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="expenseComplianceTypes"
                          dropdown-width="100%"
                          :dropdown-height="40"
                          :search="false"
                          :can-add-item="false"
                          :values="expenseComplianceTypes"
                          :model="selectedComplianceType"
                          @select="onExpenseComplianceSelected">
        <template #trigger>
          <div class="select"
               :class="$parent.selectClass(modelValue)">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="expenseComplianceFilterIsSet">
                {{ selectedComplianceType }}
              </template>
              <template v-else>
                {{ $t('form.expense_compliance.filter.placeholder') }}
              </template>
            </div>
            <button v-if="expenseComplianceFilterIsSet"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="reset">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      type: [String, Boolean],
      required: true
    }
  },

  emits: ['update:modelValue', 'clear-field'],

  data () {
    return {
      selectedComplianceType: null
    }
  },

  computed: {
    expenseComplianceTypes () {
      return [
        {
          label: this.$i18n.t('general.non_compliant_expense'),
          value: false
        }
      ]
    },

    expenseComplianceFilterIsSet () {
      return this.modelValue !== ''
    }
  },

  methods: {
    onExpenseComplianceSelected (complianceType) {
      this.selectedComplianceType = complianceType.label
      this.$emit('update:modelValue', !!complianceType.modelValue)
    },

    reset () {
      this.selectedChargeBack = { label: null, value: null }
      this.$emit('clear-field')
    }
  }
}
</script>
