<template>
  <svg viewBox="0 0 200 160">
    <path d="M67.9693 63.6376C69.5719 63.6376 70.4659 65.5321 70.4877 65.5742C70.6885 65.9384 71.146 66.0919 71.5375 65.9263C71.929 65.7608 72.1224 65.3322 71.9813 64.9427C71.9813 64.8269 70.6403 62.0588 67.9584 62.0588C64.6872 62.161 61.875 64.3277 61.0464 67.3845H58.1464V68.9632H60.7411C60.6841 69.3785 60.655 69.7968 60.6539 70.2157C60.6562 70.6345 60.6853 71.0528 60.7411 71.4682H58.2009V73.0469H61.09C61.8904 76.1257 64.7256 78.3067 68.0129 78.3726C69.7027 78.244 71.2074 77.2914 72.0031 75.8466C72.2139 75.46 72.0601 74.9817 71.6597 74.7783C71.2593 74.5748 70.7639 74.7233 70.5531 75.1098C70.034 76.0356 69.084 76.6627 68.002 76.7938C65.6196 76.7394 63.5335 75.2363 62.8016 73.0469H68.002V71.4682H62.3764C62.3115 71.0536 62.2787 70.635 62.2783 70.2157C62.2775 69.7964 62.3103 69.3776 62.3764 68.9632H68.002V67.3845H62.8234C63.5676 65.2259 65.615 63.7351 67.9693 63.6376Z"
          :fill="colorPrimary" />
    <path d="M53 49.25H141C143.623 49.25 145.75 51.3766 145.75 54V57.25H149C151.623 57.25 153.75 59.3766 153.75 62V110C153.75 112.623 151.623 114.75 149 114.75H61C58.3766 114.75 56.25 112.623 56.25 110V106.75H53C50.3766 106.75 48.25 104.623 48.25 102V54C48.25 51.3766 50.3766 49.25 53 49.25ZM57.75 106.75V110C57.75 111.795 59.2051 113.25 61 113.25H149C150.795 113.25 152.25 111.795 152.25 110V62C152.25 60.2051 150.795 58.75 149 58.75H145.75V102C145.75 104.623 143.623 106.75 141 106.75H57.75ZM53 50.75C51.2051 50.75 49.75 52.2051 49.75 54V102C49.75 103.795 51.2051 105.25 53 105.25H141C142.795 105.25 144.25 103.795 144.25 102V54C144.25 52.2051 142.795 50.75 141 50.75H53Z"
          :fill="colorDark" />
    <path d="M58 90.25V91.75H128V90.25H58ZM58 96.25V97.75H122V96.25H58Z"
          :fill="colorGrey" />
    <path d="M107.75 71.25H134.25V63.75H107.75V71.25ZM135.75 62.25V72.75H106.25V62.25H135.75Z"
          :fill="colorGrey" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
