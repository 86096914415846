<template>
  <div class="form-control p-0">
    <div :ref="`input-note-${uuid}`"
         key
         class="input-note"
         :class="{ empty: isEmpty, disabled }"
         :contentEditable="!disabled"
         :placeholder="placeholder"
         @keydown.home.prevent
         @paste="onPaste"
         @input="onInput" />
  </div>
</template>

<script>

import { uniqueId } from 'lodash/util'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:model-value'],
  data () {
    return {
      uuid: uniqueId()
    }
  },

  computed: {
    isEmpty () {
      return (!this.modelValue || this.modelValue.length === 0)
    }
  },

  watch: {
    modelValue (value) {
      this.bindValue(value)
    }
  },

  mounted () {
    this.bindValue(this.modelValue, true)
  },

  methods: {
    bindValue (value, force) {
      if (value !== this.modelValue || force) this.$refs[`input-note-${this.uuid}`].innerText = value
    },

    onInput (event) {
      this.$emit('update:model-value', event.target.innerText)
    },

    onPaste (event) {
      event.preventDefault()
      const text = event.clipboardData.getData('text/plain')
      this.insertTextAtSelection(event.target, text)
      this.$emit('update:model-value', event.target.innerText)
    },

    insertTextAtSelection (div, txt) {
      const sel = window.getSelection()
      const text = div.textContent
      const before = Math.min(sel.focusOffset, sel.anchorOffset)
      const after = Math.max(sel.focusOffset, sel.anchorOffset)
      const afterStr = text.substring(after)
      div.textContent = text.substring(0, before) + txt + afterStr
      sel.removeAllRanges()
      const range = document.createRange()
      range.setStart(div.childNodes[0], before + txt.length)
      range.setEnd(div.childNodes[0], before + txt.length)
      sel.addRange(range)
    }
  }
}
</script>

<style lang="stylus">
.input-note
  position relative
  padding 1.1rem 1.4rem
  white-space pre-wrap
  transition all 0.2s $easeInOutSine
  max-height 8.6rem
  overflow-y scroll

  &.disabled
    cursor not-allowed

  &.empty:before
    content attr(placeholder)
    pointer-events none
    color $placeholder-text-color
</style>
