const store = {
  data: {
    deltaTime: 0
  },
  api: {
    base: import.meta.env.VITE_API_URL || '/api',
    hideProgressBar: false
  },
  logrocket: import.meta.env.VITE_LOGROCKET_ACTIVE === 'true',
  translations: {}
}

export default store
