import { markRaw } from 'vue'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state'

import router from '@/router'
import { Collection, CollectionResponse } from '@/types/collection.d'
import { Pagination } from '@/types/pagination.d'

const pinia = createPinia()
pinia.use(createPersistedState({ debug: false }))
pinia.use(PiniaSharedState({ enable: false }))
pinia.use(({ store }) => {
  store.router = markRaw(router)
  store.parsePagination = (headers: RawAxiosResponseHeaders | AxiosResponseHeaders): Pagination => ({
    current: parseInt(headers['x-pagination-current-page'] || '1'),
    count: parseInt(headers['x-pagination-page-count'] || '1'),
    perPage: parseInt(headers['x-pagination-per-page'] || '50'),
    totalItems: parseInt(headers['x-pagination-total-count'] || '0')
  })
  store.parseCollection = (response: CollectionResponse, headers: RawAxiosResponseHeaders | AxiosResponseHeaders): Collection => ({
    items: response.data,
    totalAmount: response._meta.totalAmount || 0,
    totalDebit: response._meta.totalDebit || 0,
    totalCredit: response._meta.totalCredit || 0,
    pagination: store.parsePagination(headers)
  })
})

export default pinia
