<template>
  <svg viewBox="0 0 24 24">
    <path d="M19 3.125A2.875 2.875 0 0 1 21.875 6v9A2.875 2.875 0 0 1 19 17.875h-5.694l-6.181 4.946v-4.946H5a2.875 2.875 0 0 1-2.87-2.706L2.125 15V6A2.875 2.875 0 0 1 5 3.125zm0 1.75H5c-.621 0-1.125.504-1.125 1.125v9c0 .621.504 1.125 1.125 1.125h3.875v3.053l3.818-3.053H19c.621 0 1.125-.504 1.125-1.125V6c0-.621-.504-1.125-1.125-1.125zM8 9a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm4 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm4 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
  </svg>
</template>

<script>
export default {
}
</script>
