<template>
  <div class="flex-1">
    <div class="fileviewer-topbar">
      <button type="button"
              class="fileviewer-close"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="close">
        <ic-close class="ic ic--18 ic--gray" />
      </button>
    </div>
    <div class="fileviewer-content"
         style="height: 75%;">
      <div class="placeholder">
        <div class="placeholder__inner">
          <file-upload width="160"
                       height="160" />
          <h4>{{ $t('placeholder.no_proof.title') }}</h4>
          <p>{{ $t('placeholder.no_proof.subtitle') }}</p>
          <button class="btn btn--default"
                  @click="addDocument">
            {{ $t('button.attach_file') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

import IcClose from '@/components/svg/icons/ic-close.vue'
import FileUpload from '@/components/svg/img/file-upload.vue'

export default {
  name: 'ComponentPlaceholder',

  components: {
    IcClose,
    FileUpload
  },

  props: {
    model: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    addDocument () {
      this.appStore.showDropzoneModal(
        this.$i18n.tc('title.attach_file', 2),
        this.model,
        {
          url: `/operations/${this.model.id}/documents`
        })
    }
  }
}
</script>
