import { capitalizeFirstLetter } from '@/helpers/utils/text'

export default function (context) {
  let tooltipEl = context.chart.canvas.parentNode.querySelector('div')
  const isBar = context.chart.config.type === 'bar'

  const topDataPoints = context.tooltip?.dataPoints?.filter(item => item.element).reduce((prev, current) => {
    return (prev.element.height > current.element.height) ? prev : current
  }) || null

  if (!topDataPoints) return

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chart-tooltip'
    tooltipEl.innerHTML = `<div class="chart-tooltip ${isBar ? 'chart-tooltip--full' : ''}"></div>`
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'
    tooltipEl.style.opacity = '1'
    context.chart.canvas.parentNode.appendChild(tooltipEl)
  }

  const tooltipModel = context.tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  tooltipEl.classList.remove('below', 'no-transform')

  if (tooltipModel.body) {
    const data = tooltipModel.dataPoints[0]
    const itemIndex = data.dataIndex
    const item = data.dataset.data[itemIndex]
    const datasets = context.chart.data.datasets

    let innerHtml = ''

    if (isBar) {
      innerHtml = `<div class="chart-tooltip--title mb-1">${capitalizeFirstLetter(item.xTooltip)}</div>`
      datasets.forEach((set, index) => {
        const dataset = datasets[index]
        const backgroundColor = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[itemIndex] : dataset.backgroundColor
        innerHtml += `<div class="chart-badge chart-badge--tooltip">
                        <div class="chart-badge--group"><div class="chart-badge__square" style="background-color: ${backgroundColor}"></div><span class="chart-badge__title">${dataset.label}</span></div>
                        <span class="chart-tooltip--subtitle">${dataset.data[data.dataIndex]?.yTooltip}</span>
                      </div>`
      })
    } else {
      innerHtml = `<div class="chart-tooltip--title">${item.yTooltip}</div><div class="chart-tooltip--subtitle">${capitalizeFirstLetter(item.xTooltip)}</div>`
    }

    tooltipEl.querySelector('div').innerHTML = innerHtml
  }

  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = `${tooltipModel.caretX}px`
  tooltipEl.style.top = `${topDataPoints.element.y - (isBar ? 180 : 100)}px`
  tooltipEl.style.font = tooltipModel.options.bodyFont.string
  tooltipEl.style.padding = `${tooltipModel.options.padding}px ${tooltipModel.options.padding}px`

  tooltipEl.style.opacity = '1'
}
