<template>
  <svg viewBox="0 0 24 24">
    <path d="M17.993 3A2 2 0 0 1 20 4.993v14.014A2 2 0 0 1 17.993 21H6.007A2 2 0 0 1 4 19.007V4.993A2 2 0 0 1 6.007 3zM18 4.993h-.016l-.02.001h-.107l-.051.001h-.286l-.09.001h-.576l-.139.001-.999.001H13.94L13.682 5H6.007l-.001.064v.262l-.001.076-.001.503v.869l-.001.173v1.437l-.001.237v2.5L6 11.437v7.57h.016l.02-.001h.107l.051-.001h.286l.09-.001h.576l.139-.001.999-.001h1.776l.258-.001L16.72 19h1.273l.001-.064v-.262l.001-.076.001-.503v-.869l.001-.173v-1.437l.001-.237v-2.5l.001-.317L18 5.464v-.47zM8 11.505c0-.279.23-.505.5-.505h7c.276 0 .5.214.5.505v.99c0 .279-.23.505-.5.505h-7a.495.495 0 0 1-.5-.505zm0-3C8 8.226 8.23 8 8.5 8h7c.276 0 .5.214.5.505v.99c0 .279-.23.505-.5.505h-7a.495.495 0 0 1-.5-.505zm0 6c0-.279.215-.505.498-.505h4.004c.275 0 .498.214.498.505v.99a.496.496 0 0 1-.498.505H8.498A.494.494 0 0 1 8 15.495z" />
  </svg>
</template>

<script>
export default {
}
</script>
