<template>
  <button class="field-placeholder"
          :disabled="readOnly"
          :class="[getStyle]"
          type="button">
    <div class="field-placeholder__title">
      <ic-add-circle class="ic ic--20 ic--primary" />
      <span>{{ title }}</span>
    </div>
    <div class="text-muted center">
      {{ subtitle }}
    </div>
  </button>
</template>

<script>
import IcAddCircle from '@/components/svg/icons/ic-add-circle.vue'

export default {
  components: {
    IcAddCircle
  },

  props: {
    border: {
      type: String,
      default: 'none',
      validator: value => ['none', 'dashed'].includes(value)
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: true
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getStyle () {
      if (this.border === 'dashed') {
        return 'field-placeholder--dashed'
      }
      return ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.field-placeholder
  transition $btn-transition
  background-color white
  border-radius 3px
  padding 4rem 2rem
  display flex
  align-items center
  flex-direction column
  justify-content center
  cursor pointer
  width 100%
  letter-spacing $letterSpacing

  &--dashed
    border-dashed()

    &:hover
      border-dashed($gray-300)

  &__title
    font-weight 500
    font-size 1.5rem
    margin-bottom 1.8rem
    display flex
    align-items center
    .ic
      margin-right 1rem

  &[disabled]
    cursor not-allowed
</style>
