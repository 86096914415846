<template>
  <div class="modal__header">
    <slot name="header-extra" />
    <h2 v-if="title"
        :class="`modal-title ${alignTitle} modal-title--${size}`">
      {{ title }}
    </h2>
    <button v-if="closeButton"
            type="button"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')">
      <ic-close class="ic ic--18 ic--gray" />
    </button>
  </div>
</template>

<script>
import IcClose from '@/components/svg/icons/ic-close.vue'

export default {
  components: { IcClose },
  props: {
    alignTitle: {
      type: String,
      default: 'left'
    },

    title: {
      type: String,
      default: ''
    },

    closeButton: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: 'medium'
    }
  },

  emits: ['close']
}
</script>

<style lang="stylus" scoped>
.modal__header
  margin-bottom 3.2rem
  flex-direction column

  .modal-title
    font-size 3.2rem
    line-height 1.2
    margin-bottom 0
    color $colorDarkGray

    &--small
      font-size 1.8rem
      font-weight 500

  .modal-close
    position absolute
    top 3rem
    right 3rem
    width 2rem
    height @width
    font-size 2rem
    transition opacity 0.3s ease
    line-height 0
    opacity 0.7

    &:hover
      opacity 1
</style>
