import dayjs, { Dayjs } from 'dayjs'

import { ITreasuryFlowOperation } from '@/models/Treasury/ModelTreasuryFlowOperation'

export enum ETreasuryPeriodFilter {
  LastTwelveMonths = 'last_twelve_months',
  Year = 'year',
  LastThreeMonths = 'last_three_months',
  Quarter = 'quarter',
  LastThirtyDays = 'last_thirty_days',
  Month = 'month',
  Custom = 'custom'
}

export enum ETreasuryFlowOperationType {
  In = 'in',
  Out = 'out',
  Result = 'result'
}

export enum ETreasuryFlowGranularity {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export enum ETreasuryResumeEvolutionType {
  Positive = 1,
  Negative = -1,
  Zero = 0
}

export type TreasuryResumePoint = {
  balance: number
  date: Dayjs
}
export type TreasuryResumeEvolution = {
  percentage: number
  first_date: dayjs
  end_date: dayjs
}

export interface ITreasuryEvolution {
  balance: number
  balance_date: Dayjs
}

export interface ITreasuryFlow {
  total_in_amount: number
  total_out_amount: number
  operations: Array<ITreasuryFlowOperation>
  granularity: ETreasuryFlowGranularity
}

export interface ITreasuryFlowOperation {
  accounting_at: number
  amount: number
  type: ETreasuryFlowOperationType
}

export interface ITreasuryResume {
  low_point?: TreasuryResumePoint
  high_point?: TreasuryResumePoint
  evolution?: TreasuryResumeEvolution
}
