<template>
  <div v-if="loadingBeneficiaryList"
       class="modal__content">
    <div class="section__loader">
      <loader-spinner />
    </div>
  </div>
  <div v-else
       class="modal__content"
       data-cy="beneficiaries.lists.create-modal.container">
    <modal-header :title="modalTitle"
                  @close="$emit('close')" />
    <validation-form v-slot="{ errors, isSubmitting }"
                     class="form"
                     :validation-schema="schema"
                     @keydown.enter.prevent
                     @invalid-submit="$bus.emit('scrollToTop')"
                     @submit="onSubmit">
      <div class="modal__body">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('form.alerts.short')" />
        <div class="form__row row">
          <div class="form-group col col--sm-12"
               :class="{ error: errors.label }">
            <label class="label input__label"
                   for="label">{{ $t('form.beneficiaries_list.name.label') }}</label>
            <validation-field id="label"
                              v-model="beneficiaryList.label"
                              type="text"
                              class="form-control"
                              name="label"
                              data-cy="beneficiaries.lists.create-modal.label"
                              :placeholder="$t('form.beneficiaries_list.name.placeholder')" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col"
               :class="[{ error: errors.selected_list_type }, isNewType ? 'col--sm-6' : 'col--sm-12']">
            <label class="label input__label"
                   for="type">{{ $t('form.beneficiaries_list.type.label') }}</label>
            <validation-field :model-value="selectedListType.id"
                              name="selected_list_type">
              <component-dropdown dropdown-width="100%"
                                  :values="types"
                                  :model="selectedListType.name"
                                  @select="selectListType">
                <template #trigger>
                  <div class="select form-control">
                    <span v-if="selectedListType">
                      {{ selectedListType.label }}
                    </span>
                    <span v-else
                          class="text-muted">
                      {{ $t('form.common.select') }}
                    </span>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
          <div v-if="isNewType"
               class="form-group col col--sm-6"
               :class="{ error: errors.list_type_name }">
            <label class="label input__label"
                   for="new_type">{{ $t('form.beneficiaries_list.new_type.label') }}</label>
            <validation-field :model-value="beneficiaryList.list_type.name"
                              name="list_type_name">
              <input id="new_type"
                     v-model="beneficiaryList.list_type.name"
                     type="text"
                     class="form-control"
                     :placeholder="$t('form.beneficiaries_list.new_type.placeholder')">
            </validation-field>
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm"
               :class="{ error: errors.comment }">
            <label class="label input__label"
                   for="comment">{{ $t('form.comment.label') }}</label>
            <validation-field id="comment"
                              v-model="beneficiaryList.comment"
                              type="text"
                              class="form-control"
                              name="comment"
                              :placeholder="$t('form.beneficiaries_list.comment.placeholder')" />
          </div>
        </div>
      </div>
      <div class="modal__footer">
        <div class="modal__footer__left" />
        <div class="modal__footer__right">
          <button type="button"
                  class="btn btn--gray"
                  @click.prevent="$emit('close')">
            {{ $t('button.cancel') }}
          </button>
          <button class="btn btn--primary"
                  type="submit"
                  :disabled="isSubmitting"
                  data-cy="beneficiaries.lists.create-modal.submit">
            {{ $t('button.validate') }}
          </button>
        </div>
      </div>
    </validation-form>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as yup from 'yup'

import { useBeneficiaryStore } from '@/stores/beneficiary'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'

export default {
  components: {
    ComponentDropdown,
    ComponentAlert,
    LoaderSpinner,
    ModalHeader
  },

  props: {
    list: {
      type: Object,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const beneficiaryStore = useBeneficiaryStore()
    return { beneficiaryStore }
  },

  data () {
    return {
      loadingBeneficiaryList: false,
      beneficiaryList: {
        id: null,
        label: '',
        comment: '',
        list_type: {
          id: null,
          label: '',
          name: ''
        }
      },

      types: [],
      selectedListType: null
    }
  },

  computed: {
    schema () {
      return yup.object({
        label: yup.string().required(),
        comment: yup.string().notRequired(),
        selected_list_type: yup.number().nullable(),
        list_type_name: this.isNewType ? yup.string().required() : yup.string()
      })
    },

    isUpdateList () {
      return this.list !== null
    },

    modalTitle () {
      return this.isUpdateList ? this.$i18n.t('title.edit_list') : this.$i18n.t('title.new_list')
    },

    isNewType () {
      return !!this.selectedListType?.is_new
    }
  },

  async created () {
    this.loadingBeneficiaryList = true
    this.types = await this.beneficiaryStore.getBeneficiaryListTypes()

    this.types.push({
      id: null,
      name: '',
      label: this.$i18n.t('form.beneficiaries_list.type.new'),
      is_new: true
    })

    if (this.isUpdateList) {
      this.beneficiaryList = {
        id: this.list.id,
        label: this.list.label,
        comment: this.list.comment,
        list_type: this.list.list_type
      }
      this.selectListType(this.list.list_type)
    } else {
      this.selectListType(this.types[0])
    }

    this.loadingBeneficiaryList = false
  },

  methods: {
    selectListType (type) {
      this.selectedListType = type
      this.beneficiaryList.list_type = {
        id: type.id,
        label: type.label,
        name: type.name
      }
    },

    async onSubmit () {
      const beneficiaryList = cloneDeep(this.beneficiaryList)

      beneficiaryList.list_type = this.beneficiaryList.list_type.id
        ? { id: this.beneficiaryList.list_type.id }
        : { name: this.beneficiaryList.list_type.name }

      this.isUpdateList
        ? await this.beneficiaryStore.updateBeneficiaryList(beneficiaryList)
        : await this.beneficiaryStore.saveBeneficiaryList(beneficiaryList)

      this.$emit('close')
    }
  }
}
</script>
