<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <div v-else
         class="company-account-informations content-wrapper">
      <div class="form form--centered form--w1000">
        <account-information-details :title="$t('label.company.informations.legal_informations_label')"
                                     :informations="legalInformations" />
        <div v-if="companyRepresentatives.length"
             class="company-account-informations--details">
          <h3>{{ $t('label.company.informations.representatives_label') }}</h3>
          <div class="bordered-box bordered-box--padding">
            <table class="table">
              <tr v-for="(info, index) in companyRepresentatives"
                  :key="index">
                <td class="col--sm-12">
                  {{ representativeName(info) }} &nbsp;<span v-if="info.title"
                                                             class="text-muted">{{ `- ${info.title}` }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAccountStore } from '@/stores/account'

import LoaderSpinner from '@/components/LoaderSpinner.vue'

import AccountInformationDetails from './AccountInformationDetails.vue'

export default {
  components: {
    AccountInformationDetails,
    LoaderSpinner
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()

    return { accountStore }
  },

  data () {
    return {
      companyRepresentatives: null,
      loading: true
    }
  },

  computed: {
    legalInformations () {
      return [
        {
          label: this.$i18n.t('label.company.informations.company_name'),
          detail: this.company.name
        },
        {
          label: this.$i18n.t('form.address.label'),
          detail: `${this.company.address.street}, ${this.company.address.zip} ${this.company.address.city}`
        },
        {
          label: this.$i18n.t('form.company.legal.label'),
          detail: this.company.legal_form?.label || null
        },
        {
          label: this.$i18n.t('general.siret_label'),
          detail: this.company.siret
        },
        {
          label: this.$i18n.t('general.vat_number'),
          detail: this.company.vat_number
        }
      ]
    }
  },

  async created () {
    this.loading = true
    this.companyRepresentatives = await this.accountStore.getCompanyRepresentatives(this.company.id)
    this.loading = false
  },

  methods: {
    representativeName (representative) {
      return representative.is_company
        ? representative.last_name
        : `${representative.first_name} ${representative.last_name}`.trim()
    }
  }
}
</script>
