let hoverElement = false

const drawRoundedRect = (ctx, x, y, width, height, radius) => {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.arcTo(x + width, y, x + width, y + radius, radius)
  ctx.lineTo(x + width, y + height)
  ctx.lineTo(x, y + height)
  ctx.lineTo(x, y + radius)
  ctx.arcTo(x, y, x + radius, y, radius)
  ctx.closePath()
  ctx.fill()
}

const hoverSegment = {
  id: 'hoverSegment',

  beforeDatasetsDraw (chart) {
    const element = hoverElement.element
    const height = element?.height || hoverElement?.element?.$animations?.height?._to

    if (!hoverElement || !height) return

    const { ctx } = chart
    const yAxis = element.y - 15

    ctx.save()
    ctx.fillStyle = 'rgba(212, 215, 221, 0.3)'

    const getX = () => {
      switch (hoverElement.datasetIndex) {
        case 0:
          return 16
        case 1:
          return 34
        default:
          return 44
      }
    }

    drawRoundedRect(ctx, element.x - getX(), yAxis, 68, height + 15, 8)
  },

  beforeEvent (chart, args) {
    const elements = chart.getElementsAtEventForMode(args.event, 'index', { intersect: false })
    hoverElement = elements?.length
      ? elements.filter(item => item.element).reduce((prev, current) => {
        return (prev.element.y < current.element.y) ? prev : current
      })
      : false
  },

  defaults: {
    enable: false
  }

}

export default hoverSegment
