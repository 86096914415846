<template>
  <div class="modal__content center">
    <modal-header :close-button="canIgnoreSca"
                  @close="rejectSca" />
    <div class="modal__body">
      <component :is="activeStep"
                 :type="challenge.token.type"
                 :challenge="challenge"
                 @action="goToScreen"
                 @validation-request-started="onValidationRequestStarted"
                 @validation-success="success => validationSuccess(success)"
                 @validation-rejected="rejectPromise"
                 @reject-sca="rejectSca"
                 @close="$emit('close')" />
      <p v-if="displayUseAnotherMethod"
         class="later"
         @click="goToScreen('challenges-list')">
        {{ $t('button.use_another_method') }}
      </p>
      <div v-else-if="loading"
           style="margin-top: 1.4rem;">
        <loader-spinner size="22" />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useScaStore } from '@/stores/sca'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import IcClose from '@/components/svg/icons/ic-close.vue'

import ChallengesList from './sca/ChallengesList.vue'
import SendInvitation from './sca/SendInvitation.vue'
import StartValidation from './sca/StartValidation.vue'
import ValidationFailed from './sca/ValidationFailed.vue'
import ValidationRejected from './sca/ValidationRejected.vue'

export default {
  components: {
    IcClose,
    LoaderSpinner,
    StartValidation,
    SendInvitation,
    ValidationFailed,
    ValidationRejected,
    ChallengesList,
    ModalHeader
  },

  props: {
    challenge: {
      type: Object,
      required: true
    },

    payload: {
      type: [Object, Array],
      default: () => ({})
    },

    resolve: {
      type: Function,
      required: true
    },

    reject: {
      type: Function,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const scaStore = useScaStore()

    const { user } = storeToRefs(authStore)
    const { modal } = storeToRefs(appStore)

    return {
      authStore,
      modal,
      scaStore,
      user
    }
  },

  data () {
    return {
      activeStep: '',
      loading: false
    }
  },

  computed: {
    isThreeDsChallenge () {
      return this.challenge.event === 'auth.three-ds'
    },

    displayUseAnotherMethod () {
      return this.activeStep !== 'challenges-list' && !this.loading && !this.isThreeDsChallenge
    },

    canIgnoreSca () {
      return ['validation-rejected', 'validation-failed', 'validation-failed', 'challenges-list'].includes(this.activeStep)
    }
  },

  created () {
    this.activeStep = this.challenge.status === 'triggered'
      ? 'send-invitation'
      : 'start-validation'

    if (this.challenge.event === 'auth' && this.payload.token) {
      this.authStore.setUserToken(this.payload.token)
    }
  },

  beforeUnmount () {
    (this.user.token === this.payload.token)
      ? this.authStore.setUserToken(null)
      : this.reject()
  },

  methods: {
    close () {
      this.challenge.event === 'auth'
        ? this.$emit('close')
        : this.$bus.emit('close-sca-modal')
    },

    goToScreen (screen, challenge = null) {
      this.loading = false
      if (challenge) {
        this.modal.props.challenge = challenge
      }
      this.activeStep = screen
    },

    onValidationRequestStarted () {
      this.loading = true
    },

    validationSuccess (success) {
      this.loading = false
      this.resolve(success)
      this.close()
    },

    async rejectSca () {
      await this.scaStore.rejectChallenge(this.challenge.id)
      this.rejectPromise()
    },

    rejectPromise () {
      this.reject()
      this.close()
    }
  }

}
</script>
