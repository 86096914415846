<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="permanentTransfers.length">
      <component-table data-cy="periodic-transfers.list"
                       class="table--hover">
        <thead>
          <tr>
            <th class="col--sm-1" />
            <th class="col-nowrap">
              {{ $t("table.periodic_start_date") }}
            </th>
            <th class="col--sm-1">
              {{ $t("table.periodic_end_date") }}
            </th>
            <th class="col--sm-3">
              {{ $t("general.reason") }}
            </th>
            <th class="col--sm-2">
              {{ $t("table.beneficiary") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.periodicity") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.amount") }}
            </th>
            <th v-if="showColActions"
                class="col-action col-action--3-items" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in permanentTransfers"
              :key="item.id"
              class="is-link">
            <td :data-cy="`periodic-transfers.row-${item.id}.details`"
                @click="showTransferDetail(item)">
              <core-badge v-if="item.status_label"
                          v-bind="getBadgeProps(item.status)"
                          :value="item.status_label">
                <template #default="{ value }">
                  <TooltipPoptip :value="value"
                                 placement="bottom-start"
                                 :title="$t(`popover.transfer.${item.status}.title`)"
                                 :message="$t(`popover.transfer.${item.status}.message`)" />
                </template>
              </core-badge>
            </td>
            <td class="text-muted nowrap"
                @click="showTransferDetail(item)">
              {{ formatDate(item.start_date) }}
            </td>
            <td class="text-muted nowrap"
                @click="showTransferDetail(item)">
              {{ formatDate(item.end_date) }}
            </td>
            <td class="text-overflow"
                @click="showTransferDetail(item)">
              {{ item.label }}
            </td>
            <td @click="showTransferDetail(item)">
              <template v-if="item.beneficiary">
                {{ item.beneficiary.label }}
              </template>
            </td>
            <td class="right"
                @click="showTransferDetail(item)">
              {{ $t(`form.transfer_details.periodic.${item.period}`) }}
            </td>
            <td class="right"
                @click="showTransferDetail(item)">
              <span v-if="displayFxAmount(item)"
                    class="text-muted">
                {{ formatAmount(item.fx_amount, item.fx_currency) }} /
              </span>
              <span class="amount">{{ formatAmount(item.amount, item.currency) }}</span>
            </td>
            <td v-if="showColActions"
                class="col-action col-action--3-items">
              <span class="hover-item">
                <router-link v-if="hasFeature(EFeature.TransferPeriodicEdition)"
                             v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                             class="btn-link"
                             :to="{ name: 'transfers-periodic-edit', params: { id: item.uuid } }">
                  <ic-pencil class="ic ic--24 ic--gray" />
                </router-link>
                <button v-if="hasFeature(EFeature.TransferPeriodicCancellation)"
                        v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                        class="btn-link"
                        :data-cy="`periodic-transfers.row-${item.id}.delete`"
                        @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.permanent_transfer.delete.title'), content: $t('prompt.permanent_transfer.delete.content'), url: `/periodic-transfers/${item.uuid}` })">
                  <ic-remove class="ic ic--24 ic--gray" />
                </button>
                <template v-if="hasFeature(EFeature.TransferPeriodicSuspension)">
                  <button v-if="!isSuspended(item)"
                          v-tooltip="{ content: $t('tooltip.suspend'), theme: 'tooltip' }"
                          class="btn-link"
                          :data-cy="`periodic-transfers.row-${item.id}.suspend`"
                          @click.prevent.stop="onToggleTransfer(item)">
                    <ic-pause class="ic ic--24 ic--gray" />
                  </button>
                  <button v-if="isSuspended(item)"
                          v-tooltip="{ content: $t('tooltip.resume'), theme: 'tooltip' }"
                          class="btn-link hover-item-visible"
                          :data-cy="`periodic-transfers.row-${item.id}.resume`"
                          @click.prevent.stop="onToggleTransfer(item)">
                    <ic-play class="ic ic--20 ic--gray" />
                  </button>
                </template>
              </span>
            </td>
          </tr>
        </tbody>
      </component-table>
      <component-pagination :pagination="periodicTransfersPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t('placeholder.no_permanent_transfer.title')"
                           :content="''" />
  </div>
</template>

<script>
import { CoreBadge } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import store from '@/config/store'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useTransferStore } from '@/stores/transfer'

import ValidateToggleTransfer from '@/pages/transfers/modal/ValidateToggleTransfer.vue'
import PermanentTransferDetail from '@/pages/transfers/sidepanel/PermanentTransferDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcPause from '@/components/svg/icons/ic-pause.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcPlay from '@/components/svg/icons/ic-play.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import ComponentTable from '@/components/Table.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'

export default {
  components: {
    TooltipPoptip,
    CoreBadge,
    IcPencil,
    IcRemove,
    IcPlay,
    IcPause,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    filter: {
      type: Object,
      required: true
    },

    page: {
      type: Number,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const transferStore = useTransferStore()

    const { user } = storeToRefs(authStore)
    const { permanentTransfers, periodicTransfersPagination } = storeToRefs(transferStore)

    return { appStore, user, permanentTransfers, periodicTransfersPagination, transferStore }
  },

  data () {
    return {
      store,
      loading: true
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    isAccountOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    showColActions () {
      return hasFeature(EFeature.TransferPeriodicAction)
    }
  },

  watch: {
    filter () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    'periodicTransfersPagination.totalItems' () {
      this.$emit('pagination-total-items', this.periodicTransfersPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  async mounted () {
    this.$bus.on('validate-toggle', this.onValidateToggle)
    this.$bus.on('transfers-cancel', this.initData)
    this.$bus.on('delete-success', this.initData)
    this.$bus.on('language-changed', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('validate-toggle', this.onValidateToggle)
    this.$bus.off('transfers-cancel', this.initData)
    this.$bus.off('delete-success', this.initData)
    this.$bus.off('language-changed', this.initData)
  },

  methods: {
    getBadgeProps,
    hasFeature,

    formatAmount,

    formatDate,

    async initData () {
      this.loading = true
      await this.transferStore.getPermanentTransfers(this.page, this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    isSuspended (transfer) {
      return transfer.status === 'suspended'
    },

    fileViewerTooltip (documents) {
      return this.$i18n.tc('tooltip.show_file', documents.length)
    },

    showTransferDetail (transfer) {
      this.appStore.showSidePanel(PermanentTransferDetail, { model: transfer }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, canDragAndDrop: false })
    },

    displayFxAmount (transfer) {
      return transfer.fx_currency !== 'EUR' && !transfer.beneficiary.is_sepa && transfer.fx_currency !== null
    },

    onToggleTransfer (transfer) {
      this.appStore.showModal(
        ValidateToggleTransfer,
        { transfer },
        { wrapperClass: 'modal--xs' }
      )
    },

    async onValidateToggle (transfer) {
      const success = await this.transferStore.togglePermanentTransfers(transfer)
      if (!success) { return }

      const message = transfer.enabled
        ? this.$i18n.t('message.success.permanent_transfer.on')
        : this.$i18n.t('message.success.permanent_transfer.off')

      showToastSuccess(message)

      await this.initData()
    }
  }
}
</script>
