<template>
  <svg viewBox="0 0 24 24">
    <path d="M19.5 4.5h-15a2.004 2.004 0 0 0-2 1.998v11.003a2 2 0 0 0 2 1.999h15a2.004 2.004 0 0 0 2-1.998V6.498a2 2 0 0 0-2-1.999zm-.573 2L12 12.205 5.072 6.498l13.855.001zM4.5 17.5V8.619l6.864 5.653a1 1 0 0 0 1.272 0L19.5 8.619v8.883z" />
  </svg>
</template>

<script>
export default {
}
</script>
