<template>
  <div class="dropdown-custom"
       :class="{ 'is-open': showDropdown }">
    <input v-model="input"
           class="form-control"
           type="text"
           :placeholder="$t('form.country.placeholder_type')"
           :disabled="disabled"
           @input="resetSelectedIndex"
           @keydown.down="onKeyPressed('down')"
           @keydown.up="onKeyPressed('up')"
           @keyup.enter="addHighlightedCountry">
    <div ref="countryDropdown"
         class="dropdown-menu"
         style="width: 100%">
      <div class="dropdown-menu__items">
        <template v-if="hasCountries">
          <div v-for="(country, index) in countries"
               :key="index"
               class="dropdown-menu__item"
               :class="{ selected: isCountrySelected(index) }"
               @click="onSelectCountry(index)">
            <img ref=""
                 :src="getFlagSrc(index)"
                 :class="{ hide: hideFlag[index] }"
                 style="width: 2rem; margin-right: 1.5rem"
                 @load="e => onImageLoaded(e, index)"
                 @error="e => onImageError(e, index)">{{ country }}
          </div>
        </template>
        <div v-else
             class="dropdown-menu__item center"
             style="pointer-events: none; cursor: default;">
          <span>{{ $t("general.no_result") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

import store from '@/config/store'
import { getCountryName, getFlagSrc } from '@/helpers/utils/intl'
import { textContainsFilter } from '@/helpers/utils/text'

export default {
  props: {
    selectedCountries: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update-card-settings', 'select-country'],

  setup () {
    const countryDropdown = ref()
    const input = ref('')

    const showDropdown = computed(() => { return input.value.length > 2 })

    function reset () {
      input.value = ''
    }

    onClickOutside(countryDropdown, () => {
      if (showDropdown.value) {
        reset()
      }
    })

    return {
      countryDropdown,
      input,
      showDropdown,

      reset
    }
  },

  data () {
    return {
      store,
      selectedIndex: null,
      hideFlag: {},
      allCountries: [
        'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AN', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA',
        'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BM', 'BN', 'BO', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ',
        'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CS', 'CU', 'CV', 'CX', 'CY',
        'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO',
        'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW',
        'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JM',
        'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK',
        'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ',
        'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP',
        'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY',
        'QA', 'RE', 'RO', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN',
        'SO', 'SR', 'ST', 'SV', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR',
        'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF',
        'WS', 'YE', 'YT', 'ZA', 'ZM', 'ZW'
      ]
    }
  },

  computed: {
    countries () {
      const countries = {}
      this.allCountries.forEach(country => {
        if (!this.selectedCountries.includes(country) && this.showDropdown) {
          const name = getCountryName(country)
          if (name !== country && textContainsFilter(name, this.input)) {
            countries[country] = name
          }
        }
      })
      return countries
    },

    arrayCountries () {
      return Object.keys(this.countries)
    },

    hasCountries () {
      return this.arrayCountries.length
    }
  },

  methods: {
    getFlagSrc,
    onImageLoaded (e, key) {
      this.hideFlag[key] = false
    },

    onImageError (e, key) {
      this.hideFlag[key] = true
    },

    onSelectCountry (country) {
      this.reset()
      if (!this.isCountrySelected(country)) {
        this.$emit('select-country', country)
        this.resetSelectedIndex()
      }
    },

    isCountrySelected (country) {
      const index = this.arrayCountries.indexOf(country)
      return this.selectedCountries.includes(country) || index === this.selectedIndex
    },

    onKeyPressed (type) {
      if (!this.arrayCountries.length) {
        this.selectedIndex = null
        return
      }
      if (type === 'down') {
        if (this.selectedIndex < this.arrayCountries.length - 1 || this.selectedIndex === null) {
          this.selectedIndex = this.selectedIndex === null ? 0 : this.selectedIndex + 1
        }
      } else {
        if (this.selectedIndex > 0) {
          this.selectedIndex -= 1
        }
      }
    },

    resetSelectedIndex () {
      this.selectedIndex = null
    },

    addHighlightedCountry () {
      if (this.arrayCountries.length === 1) {
        this.onSelectCountry(this.arrayCountries[0])
      } else if (this.selectedIndex !== null) {
        this.onSelectCountry(this.arrayCountries[this.selectedIndex])
      }
    }
  }
}
</script>
