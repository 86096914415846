<template>
  <div ref="sidepanelContent"
       class="sidepanel__content">
    <div class="sidepanel-header"
         style="height: 5rem;">
      <h2 class="panel-title">
        {{ $t('title.new_beneficiary') }}
      </h2>
      <a v-if="beneficiary.rib"
         class="btn-link btn-link--attachment"
         href="#"
         @click.prevent="showFile">
        <i class="btn-icon btn-icon--left">
          <ic-document class="ic ic-document ic--gray" />
        </i>
        <span class="btn__label">{{ beneficiary.rib.name }}</span>
        <button class="btn-link btn-remove-file"
                @click.prevent.stop="removeFile">
          <ic-remove class="ic ic--18 ic--gray" />
        </button>
      </a>
      <button v-else
              class="btn btn--default"
              @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_rib'), {}, { maxFiles: 1 })">
        <i class="btn-icon btn-icon--left">
          <ic-attachment class="ic ic--white" />
        </i>
        {{ $t('button.analyze_rib') }}
      </button>
    </div>
    <div class="sidepanel-body">
      <new-beneficiary-form ref="beneficiaryForm"
                            is-side-panel
                            :existing-beneficiary="beneficiary"
                            :enable-no-sepa="isNoSepaEnabled" />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { EFeature, hasFeature } from '@/config/features'
import { scrollToTop } from '@/helpers/utils/dom'
import { useAppStore } from '@/stores/app'

import NewBeneficiaryForm from '@/components/forms/NewBeneficiaryForm.vue'
import DocumentAnalyze from '@/components/modals/DocumentAnalyze.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcAttachment,
    IcDocument,
    IcRemove,
    NewBeneficiaryForm
  },

  props: {
    beneficiaryInitial: {
      type: Object,
      required: true
    },

    enableNoSepa: {
      type: Boolean,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      beneficiary: null
    }
  },

  computed: {
    isNoSepaEnabled () {
      return hasFeature(EFeature.BeneficiaryInternational)
        ? this.enableNoSepa
        : false
    }
  },

  created () {
    this.beneficiary = cloneDeep(this.beneficiaryInitial)
  },

  mounted () {
    this.$bus.on('on-file-attached', this.onFileAttached)
    this.$bus.on('delete-local-file', this.removeFile)
    this.$bus.on('scrollToTop', this.scrollToTopHandler)
  },

  beforeUnmount () {
    this.$bus.off('on-file-attached', this.onFileAttached)
    this.$bus.off('delete-local-file', this.removeFile)
    this.$bus.on('scrollToTop', this.scrollToTopHandler)
  },

  methods: {
    onFileAttached ({ files }) {
      if (!files[0]) { return }

      this.beneficiary.rib = files[0]
      this.appStore.showModal(
        DocumentAnalyze,
        {
          title: this.$i18n.t('prompt.beneficiary.rib.title'),
          content: this.$i18n.t('prompt.beneficiary.rib.content'),
          fileToAnalyze: files[0],
          type: 'beneficiary'
        },
        { wrapperClass: 'modal-document-analyze' }
      )
    },

    removeFile () {
      this.beneficiary.rib = null
      this.$refs.beneficiaryForm.onRemoveRibFile()
    },

    showFile () {
      this.appStore.showFileViewer({
        content: [this.beneficiary.rib],
        canEdit: true,
        isLocal: true,
        displayFullScreen: true
      })
    },

    scrollToTopHandler () {
      scrollToTop(this.$refs.sidepanelContent)
    }
  }
}
</script>
