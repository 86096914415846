<template>
  <modal-dialog-placeholder class="request-sca"
                            @close="$emit('close')">
    <component-placeholder :label="$t('placeholder.sca.title') "
                           :content="$t('placeholder.sca.subtitle')"
                           :button="$t('button.configure_now')"
                           :cancel-button="$t('button.later')"
                           @btn-click="$router.push({ name: 'account-sca' })"
                           @cancel-btn-click="$emit('close')">
      <computer-phone-security width="160px"
                               height="128px" />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script>
import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ComputerPhoneSecurity from '@/components/svg/img/computer-phone-security.vue'

export default {
  components: {
    ComponentPlaceholder,
    ModalDialogPlaceholder,
    ComputerPhoneSecurity
  },

  emits: ['close']
}
</script>

<style lang="stylus" scoped>
.request-sca
  min-height 55rem
  width 60rem
</style>
