<template>
  <section class="section section-payslip-wire advance-payment"
           :class="{ 'section--centered': !displayData }">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'transfers-create-multiple' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('advance_payment.title') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <component-loader-spinner />
      </div>
      <validation-form v-else-if="displayData"
                       v-slot="{ errors, isSubmitting }"
                       :validation-schema="schema"
                       class="form form--centered form--w1000 form-newbeneficiary"
                       @invalid-submit="$bus.emit('scrollToTop')"
                       @submit="onSubmit"
                       @keydown.enter.prevent>
        <component-alert v-if="Object.keys(errors).length && formSubmitted"
                         :type="'error'"
                         :message="$t('form.alerts.common')" />
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.debit_account.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row">
              <div class="form-group col col--sm-8">
                <label class="label input__label">{{ $t('form.debit_account.label') }}</label>
                <component-dropdown label="accounts"
                                    dropdown-width="100%"
                                    disabled
                                    :model="account">
                  <template #trigger>
                    <div class="select select-account">
                      <div class="form-control form-control--noborder">
                        {{ accountFilter(account) }}
                      </div>
                      <span class="select-account__balance text-green">{{ formatAmount(account.balance, account.currency) }}</span>
                    </div>
                  </template>
                </component-dropdown>
              </div>
              <component-datepicker v-model="executionDate"
                                    class="form-group col col--sm-4"
                                    name="execution_date"
                                    :class="{ error: errors.execution_date }"
                                    :disabled-days="disabledDays"
                                    :label="$t('table.execution_date')"
                                    :language="$i18n.locale"
                                    format="dd/MM/yyyy"
                                    input-class="form-control form-control--noborder"
                                    monday-first
                                    :readonly="false"
                                    :placeholder="$t('form.date.placeholder')"
                                    @update:model-value="updateTransfers" />
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.credit_accounts.title')"
                          show-number />
          <div class="bordered-box">
            <table class="table table--hover"
                   data-cy="advance-payments.table">
              <thead class="bordered-header">
                <tr>
                  <th />
                  <th>{{ $t('table.beneficiary') }}</th>
                  <th class="col-action center">
                    {{ $t('table.notification') }}
                  </th>
                  <th class="col col--sm-3 right">
                    {{ $t('table.net_salary') }}
                    <span v-tooltip="{ content: $t('tooltip.for_your_information'), theme: 'tooltip' }">
                      <ic-info outline
                               class="ic ic--gray ic--12" />
                    </span>
                  </th>
                  <th class="col col--sm-3 right payslip-amount">
                    {{ $t('table.advance_payment') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, id) in advancePayments"
                    :key="id"
                    class="is-link">
                  <td class="col-checkbox">
                    <label class="checkbox">
                      <input v-model="value.transfer.checked"
                             type="checkbox"
                             name="checkbox">
                      <span class="checkbox__icon" />
                    </label>
                  </td>
                  <td @click="onClick(id)">
                    {{ value.beneficiary.label }} <br>
                    <span class="text-muted">{{ formattedIban(value) }}</span>
                  </td>
                  <td class="center">
                    <button v-tooltip="{ content: getTooltipMessageNotifications(value), theme: 'tooltip' }"
                            class="btn-link btn-link--document"
                            type="button">
                      <template v-if="value.beneficiary.email">
                        <ic-mail v-if="!value.transfer.send_notification"
                                 class="ic ic--24 ic--off"
                                 @click.prevent.stop="onClickNotification(value)" />
                        <ic-mail-success v-else
                                         class="ic ic--dark ic--32"
                                         @click.prevent.stop="onClickNotification(value)" />
                      </template>
                      <ic-mail-error v-else-if="!value.beneficiary.email"
                                     class="ic ic--24 ic--gray"
                                     @click="onClick(id)" />
                    </button>
                  </td>
                  <td class="right payslip-amount"
                      @click="onClick(id)">
                    <strong>{{ formatAmount(value.transfer.last_month_salary, value.transfer.currency) }}</strong>
                  </td>
                  <td class="right payslip-amount"
                      @click="onClick(id)">
                    <strong>{{ formatAmount(value.transfer.amount, value.transfer.currency) }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="add-row"
                 @click="addAdvancePayment">
              <div class="add-row__icon">
                <ic-plus-circle class="ic ic--24 ic--gray" />
              </div>
              <span>
                {{ $t('button.add_beneficiaries') }}
              </span>
            </div>
            <div class="summary-transfer">
              <div class="row">
                <div class="col col--sm">
                  <strong>
                    {{ totalLabel }}
                  </strong>
                </div>
                <div v-if="totalAmount"
                     class="col col--sm right">
                  <strong>{{ formatAmount(totalAmount, account.currency) }}</strong>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <component-alert v-if="isAmountTooHigh"
                         :type="'warning'"
                         :message="$tc('form.multiple_transfers.warning.not_enough_funds', numberOfSelectedTransfers)"
                         :show-api-messages="false" />
        <div class="form__fieldset__block form__fieldset__block--advancepayment bordered-box">
          <span>
            <toggle-switch v-model="toExport.send_export"
                           :toggle-class="'switch-btn--sm'" />
            <span class="switch-btn-label">{{ $t('advance_payment.form.share') }}</span>
          </span>
          <div class="form-group"
               :class="{ error: errors.export_recipient }">
            <validation-field v-if="toExport.send_export"
                              v-model="toExport.export_recipient"
                              type="text"
                              name="export_recipient"
                              class="form-control form-control--xs"
                              :placeholder="$t('form.set_email.placeholder')" />
          </div>
        </div>
        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'transfers-create-multiple' }">
            {{ $t('button.cancel') }}
          </router-link>
          <button class="btn btn--primary"
                  data-cy="advance-payments.validate-button"
                  type="submit"
                  :disabled="isSubmitting || numberOfSelectedTransfers === 0">
            {{ $t('button.validate') }}
          </button>
        </div>
      </validation-form>
      <div v-else-if="!isInSalaryDateRange || !advancePayments.length"
           class="placeholder-action-description">
        <component-placeholder :label="$t('advance_payment.title')"
                               :content="noAdvancePaymentsText">
          <file-payslips />
        </component-placeholder>
        <button v-if="isInSalaryDateRange && beneficiaries.length && !addNewItems"
                class="btn btn--primary"
                @click="addNewItems = true">
          {{ $t('advance_payment.form.add') }}
        </button>
        <button v-else-if="(!beneficiaries.length && displaySalaryTab)"
                class="btn btn--default btn--sm btn--advance-payment"
                @click="accessHistory">
          {{ $t('placeholder.access_history') }}
        </button>
        <button v-else
                class="btn btn--outline btn--sm btn--advance-payment"
                @click="openModal">
          <i class="btn-icon btn-icon--left">
            <ic-info outline
                     class="ic ic-info-outline ic--gray ic--16" />
          </i>
          {{ $t('tooltip.see_more') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useTransferStore } from '@/stores/transfer'
import { useUserStore } from '@/stores/user'

import AddBeneficiaries from '@/pages/transfers/sidepanel/AddBeneficiaries.vue'
import EditMultipleTransfer from '@/pages/transfers/sidepanel/EditMultipleTransfer.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ComponentLoaderSpinner from '@/components/LoaderSpinner.vue'
import AdvancePaymentExplanation from '@/components/modals/AdvancePaymentExplanation.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcMailError from '@/components/svg/icons/ic-mail-error.vue'
import IcMailSuccess from '@/components/svg/icons/ic-mail-success.vue'
import IcPlusCircle from '@/components/svg/icons/ic-plus-circle.vue'
import FilePayslips from '@/components/svg/img/file-payslips.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcMailError,
    IcMailSuccess,
    IcMail,
    IcInfo,
    IcPlusCircle,
    IcArrow,
    FilePayslips,
    ComponentAlert,
    ComponentDropdown,
    ComponentLoaderSpinner,
    ComponentPlaceholder,
    ComponentDatepicker,
    ToggleSwitch,
    FieldsetTitle
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()
    const transferStore = useTransferStore()
    const userStore = useUserStore()

    const { account, displaySalaryTab } = storeToRefs(accountStore)
    const { beneficiaries } = storeToRefs(beneficiaryStore)
    const { advancePayments } = storeToRefs(transferStore)

    return {
      account,
      advancePayments,
      appStore,
      beneficiaries,
      beneficiaryStore,
      displaySalaryTab,
      transferStore,
      userStore
    }
  },

  data () {
    return {
      addNewItems: false,
      executionDate: null,
      disabledDays: {
        to: new Date()
      },

      formSubmitted: false,
      loading: true,
      toExport: {
        send_export: false,
        export_recipient: ''
      }
    }
  },

  computed: {
    schema () {
      return yup.object({
        execution_date: yup.date().required(),
        export_recipient: this.toExport.send_export ? yup.string().required() : null
      })
    },

    displayData () {
      return this.isInSalaryDateRange && (this.advancePayments.length || this.addNewItems)
    },

    isInSalaryDateRange () {
      return dayjs().format('DD') >= 14 && dayjs().format('DD') < 22
    },

    isAmountTooHigh () {
      return this.totalAmount > this.account.balance
    },

    NextSessionMonth () {
      return dayjs().format('DD') > 21
        ? formatDateText(dayjs().startOf('month').add(1, 'months'), 'MMMM')
        : formatDateText(dayjs(), 'MMMM')
    },

    numberOfSelectedTransfers () {
      return this.selectedTransfers.length
    },

    noAdvancePaymentsText () {
      let text = this.$i18n.t('advance_payment.placeholder.empty')
      if (!this.isInSalaryDateRange) {
        text = this.$i18n.t('advance_payment.placeholder.invalid_date', { month: this.NextSessionMonth })
      }
      if (this.beneficiaries.length) {
        text = this.$i18n.t('advance_payment.placeholder.empty_add')
      }
      if (this.displaySalaryTab && !this.beneficiaries.length) {
        text += ` ${this.$i18n.t('advance_payment.placeholder.history')}`
      }
      return text
    },

    selectedTransfers () {
      return this.advancePayments.filter(x => x.transfer.checked)
    },

    totalAmount () {
      return this.advancePayments.reduce((total, pay) => total + (pay.transfer.checked ? pay.transfer.amount : 0), 0)
    },

    totalLabel () {
      return this.$i18n.tc('advance_payment.form.total_transfers', this.numberOfSelectedTransfers)
    }
  },

  created () {
    this.initData()
    this.executionDate = dayjs().format('YYYY-MM-DD')
    const date = new Date()
    this.disabledDays.from = new Date(date.getFullYear(), date.getMonth(), 21)
  },

  mounted () {
    this.$bus.on('on-edit-transfers-success', this.editTransfers)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('on-edit-transfers-success', this.editTransfers)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    formatAmount,

    accessHistory () {
      this.$router.push({ name: 'transfers-salary' })
    },

    accountFilter (account) {
      return account.label ? `${account.company.name} - ${account.label}` : account.company.name
    },

    addAdvancePayment () {
      this.appStore.showSidePanel(AddBeneficiaries, {}, { wrapperClass: 'sidepanel--xl', backgroundOverlay: true, closeButton: false })
    },

    editTransfers (newValues) {
      newValues.forEach(newValue => {
        this.transferStore.setAdvancePayments(this.advancePayments.map(value => value.id === newValue.id ? newValue : value))
      })
    },

    formattedIban (value) {
      if (value.beneficiary.account_number_formatted) {
        return value.beneficiary.account_number_formatted
      } else if (value.beneficiary.iban) {
        return formatIban(value.beneficiary.iban)
      }
    },

    getTooltipMessageNotifications (item) {
      if (item.beneficiary.email) {
        return item.transfer.send_notification ? this.$i18n.t('tooltip.notification.on') : this.$i18n.t('tooltip.notification.off')
      }

      return this.$i18n.t('tooltip.add_email')
    },

    async initData () {
      this.loading = true
      this.addNewItems = false
      if (this.isInSalaryDateRange) {
        if (this.account.detail && this.account.detail.payroll_manager_email) {
          this.toExport.export_recipient = this.account.detail.payroll_manager_email
        }
        if (this.account.detail && this.account.detail.advance_payment_export) {
          this.toExport.send_export = this.account.detail.advance_payment_export
        }

        await this.transferStore.getAdvancePayments()
        await this.beneficiaryStore.getBeneficiaries({ advance_payment_option: false })
      }
      this.loading = false
    },

    onClick (index, setEmail = false) {
      this.advancePayments.forEach((elem, i) => { elem.id = i })
      this.appStore.showSidePanel(
        EditMultipleTransfer,
        { initialIndex: index, initialValues: this.advancePayments, setEmail },
        { wrapperClass: 'sidepanel--xl', backgroundOverlay: true }
      )
    },

    onClickNotification (item) {
      item.transfer.send_notification = !item.transfer.send_notification
    },

    async onSubmit () {
      this.formSubmitted = true
      this.loading = true
      this.selectedTransfers.forEach(e => { e.transfer.execution_date = this.executionDate })

      const success = await this.transferStore.sendMultipleTransfers({
        transfers: this.selectedTransfers,
        export: this.toExport,
        isAdvancePayment: true
      })

      this.loading = false
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.advance_payments.save'))
        await this.$router.push({ name: 'transfers' })
      }
    },

    openModal () {
      this.appStore.showModal(AdvancePaymentExplanation)
    },

    updateTransfers (date) {
      this.advancePayments.forEach(e => { e.transfer.execution_date = date })
    }
  }

}
</script>
