<template>
  <div class="modal__content center">
    <modal-header close-button
                  @close="$emit('close')">
      <template #header-extra>
        <slot name="header-extra" />
      </template>
    </modal-header>
    <slot />
  </div>
</template>

<script>
import ModalHeader from './ModalHeader.vue'

export default {
  components: {
    ModalHeader
  },

  emits: ['close']
}
</script>

<style lang="stylus" scoped>
.modal__header
  margin-bottom 0

.modal__content
  min-height 42rem
  width 50rem
  display flex
  flex-direction column
  justify-content center
  padding 4rem 6rem

p
  line-height 2.6rem
  max-width 38rem
  margin-left 0 !important
  margin-right @margin-left
</style>
