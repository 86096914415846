<template>
  <div class="loading">
    <logo-bank-inline white
                      class="logo" />
    <loader-spinner color="white"
                    size="32" />
  </div>
</template>

<script>
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'

import LoaderSpinner from './LoaderSpinner.vue'

export default {
  components: {
    LogoBankInline,
    LoaderSpinner
  }
}
</script>

<style lang="stylus" scoped>
.loading
  fullscreen(fixed, $zindex-loader)
  background alpha($sidebarBG,0.8)
  display flex
  justify-content center
  align-items center
  flex-direction column

  .logo
    width 180px
    margin-left -20px
    animation-name animeLogo
    animation-duration 0.6s
    animation-delay 1.3s
    animation-fill-mode both
    animation-timing-function $easeOutCubic
    margin-top 2.8rem
    display none

@keyframes animeLogo {
  from {
    opacity 0
    transform translateY(20px)
  }
  to {
    opacity 1
    transform translateY(0)
  }
}

@keyframes zoomIn {
  0% {
    transform scale(0)
  }
  50% {
    transform scale(1.5)
  }
  to {
    transform scale(1)
  }
}
</style>
