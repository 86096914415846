<template>
  <svg viewBox="0 0 24 24">
    <path d="M16 2a2 2 0 0 1 2 2v4.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V4H4v16h6.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm1.5 10a.5.5 0 0 1 .5.5V16h3.5a.5.5 0 0 1 .492.41l.008.09v1a.5.5 0 0 1-.5.5H18v3.5a.5.5 0 0 1-.41.492L17.5 22h-1a.5.5 0 0 1-.5-.5V18h-3.5a.5.5 0 0 1-.492-.41L12 17.5v-1a.5.5 0 0 1 .5-.5H16v-3.5a.5.5 0 0 1 .41-.492L16.5 12zm-7-2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm3-3a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
