<template>
  <section class="section section--centered"
           data-cy="account.create.page">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account-company-subaccounts', params: { id: account.company.id } }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('create_account.title') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <h4 class="body--extra-large center">
        {{ $t('create_account.choice') }}
      </h4>
      <div class="block-options block-options--two-columns mt-4">
        <block-options-item v-for="(item, index) in accountTypes"
                            :key="index"
                            :badge-value="item.badgeValue"
                            :to="item.route"
                            hide-chevron
                            :title="item.title"
                            :content="item.content"
                            :data-cy="item.dataCy">
          <component :is="item.icon" />
        </block-options-item>
      </div>
    </div>
  </section>
</template>

<script>
import { CoreBadge, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'

import BlockOptionsItem from '@/components/BlockOptionsItem.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import CapitalDeposit from '@/components/svg/img/capital-deposit.vue'
import CheckSimple from '@/components/svg/img/check-simple.vue'
import MultipleBankAccount from '@/components/svg/img/multiple-bank-account.vue'
import SavingAccount from '@/components/svg/img/saving-account.vue'

export default {
  components: {
    CoreBadge,
    IcArrow,
    BlockOptionsItem,
    CapitalDeposit
  },

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    accountTypes () {
      return [
        {
          icon: MultipleBankAccount,
          route: { name: 'create-additional-account' },
          title: this.$i18n.t('general.additional_account'),
          dataCy: 'account.create.additional-account',
          content: this.$i18n.t('create_account.additional_account.content'),
          permission: this.$permissions.accountSubAccountCreate
        },
        {
          badgeValue: this.$i18n.t('general.new'),
          icon: SavingAccount,
          route: { name: 'create-term-account' },
          title: this.$i18n.t('general.term_account'),
          dataCy: 'account.create.term',
          content: this.$i18n.t('create_account.term_account.content'),
          permission: this.$permissions.accountTermAccountCreate
        },
        {
          icon: CheckSimple,
          route: { name: 'create-checks-account' },
          title: this.$i18n.t('general.checks_account'),
          dataCy: 'account.create.check',
          content: this.$i18n.t('create_account.checks_account.content'),
          permission: this.$permissions.accountSequestreAccountCreate
        },
        {
          icon: CapitalDeposit,
          route: { name: 'create-capital-gain-account' },
          title: this.$i18n.t('general.capital_gain_account'),
          dataCy: 'account.create.capital-gain',
          content: this.$i18n.t('create_account.capital_gain_account.content'),
          permission: this.$permissions.accountCapitalGainCreate
        }
      ].filter(item => this.hasPermission(item.permission, this.account.company))
    }
  }
}
</script>
