<template>
  <div class="chart-nodata">
    <div class="chart-nodata__body"
         :class="{ 'chart-nodata__body--no-border': !border }">
      <ic-warning class="ic ic--16 ic--gray mr-1" />
      <span>{{ $t('treasury.chart.nodata') }}</span>
    </div>
  </div>
</template>

<script>
import IcWarning from '@/components/svg/icons/ic-warning.vue'

export default {
  name: 'ComponentsChartNodata',
  components: { IcWarning },

  props: {
    border: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus">
.chart-nodata
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  display flex
  align-items center
  justify-content center

  &__body
    display flex
    align-items center
    justify-content center
    z-index 10
    padding 14px 20px
    border-radius 3px
    border 1px solid $borderColor
    background-color $colorWhite
    box-shadow 0 10px 12px 0 rgba($colorDarkGray, 0.06)
    font-size 14px
    font-weight 500
    text-align center
    color $colorShinyGray

    &--no-border
      box-shadow none
      border none
</style>
