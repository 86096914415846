<template>
  <fieldset id="authorisations"
            :data-cy="`cards.settings.section.${type}`"
            class="form__fieldset">
    <group-title :title="$t(`cards_settings.${type}.title`)" />
    <div class="bordered-box form__fieldset__block-list overflow-scrollbar">
      <div class="input-group">
        <input v-model="input"
               class="form-control"
               type="text"
               :disabled="disabled"
               :placeholder="$t(`cards_settings.${type}.form.placeholder`)"
               @keydown.enter="addItem">
        <button class="input-group__addon--button btn btn--sm btn--default"
                :disabled="hasDisabled"
                @click="addItem">
          <ic-plus class="ic ic-plus ic--white" />
        </button>
      </div>
      <div v-if="value.length"
           class="form__fieldset__block-list__list">
        <div v-for="(item, index) in value"
             :key="index"
             class="form__fieldset__block-list__item">
          <ic-category class="ic ic-category ic--gray item-icon" />
          <div class="item-label upper">
            {{ item }}
          </div>
          <button class="btn-link btn-remove"
                  :disabled="disabled"
                  @click="deleteItem(item)">
            <ic-remove class="ic ic--14 ic--gray" />
          </button>
        </div>
      </div>
      <div v-else
           class="block-placeholder">
        <component :is="getImg"
                   class="block-placeholder__img" />
        <div class="block-placeholder__label">
          {{ $t(`cards_settings.${type}.placeholder`) }}
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import GroupTitle from '@/components/GroupTitle.vue'
import IcCategory from '@/components/svg/icons/ic-category.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import AuthorizedMerchant from '@/components/svg/img/authorized-merchant.vue'
import BlockedKeyword from '@/components/svg/img/blocked-keyword.vue'

export default {
  components: {
    IcRemove,
    IcPlus,
    IcCategory,
    BlockedKeyword,
    GroupTitle,
    AuthorizedMerchant
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: true,
      validator: prop => ['blocked_keywords', 'authorization_merchants'].includes(prop)

    }
  },

  emits: ['update-card-settings'],

  data () {
    return {
      input: ''
    }
  },

  computed: {
    hasDisabled () {
      return this.disabled || this.input.length < 3 || this.value.findIndex(item => this.input.toLowerCase() === item.toLowerCase()) !== -1
    },

    getImg () {
      return this.type === 'blocked_keywords' ? BlockedKeyword : AuthorizedMerchant
    }
  },

  methods: {
    addItem () {
      if (!this.hasDisabled) {
        this.updateCardSettings(
          this.type,
          this.value.concat(this.input)
        )
        this.input = ''
      }
    },

    deleteItem (item) {
      this.updateCardSettings(
        this.type,
        this.value.filter(keyword => keyword.toLowerCase() !== item.toLowerCase())
      )
    },

    updateCardSettings (key, value) {
      this.$emit('update-card-settings', {
        [key]: value
      })
    }
  }
}
</script>
