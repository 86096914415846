<template>
  <div>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('form.title.custom_pin_code')" />
      <div class="form__fieldset__group">
        <div class="form__row row row--sm-bottom">
          <div class="form-group col col--sm-6">
            <tile-radio id="random"
                        v-model="pinCodeOption"
                        value="random"
                        name="pinCodeOption"
                        :label="$t('form.tile_radio.label.random_pin_code')"
                        :content="randomPinCodeContent"
                        @update:model-value="updatePinCodeOption" />
          </div>
          <div class="form-group col col--sm-6">
            <tile-radio id="custom"
                        v-model="pinCodeOption"
                        value="custom"
                        name="pinCodeOption"
                        :label="$t('form.tile_radio.label.custom_pin_code')"
                        :content="customPinCodeContent"
                        @update:model-value="updatePinCodeOption" />
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset v-if="isCustomPinSelected"
              class="form__fieldset form__fieldset__pin-code">
      <div class="form__fieldset__group">
        <div class="form__fieldset__custom-pin">
          <fieldset-title :title="$t('form.title.set_pin_code')" />
          <verification-code v-model="cardPinCode"
                             :has-separator="false"
                             :number-of-digits="4" />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { useAccountStore } from '@/stores/account'

import FieldsetTitle from '@/components/FieldsetTitle.vue'
import TileRadio from '@/components/TileRadio.vue'
import VerificationCode from '@/components/VerificationCode.vue'

export default {
  components: {
    TileRadio,
    VerificationCode,
    FieldsetTitle
  },

  emits: ['change-pin-code', 'select-pin-choice'],

  setup () {
    const accountStore = useAccountStore()
    return { accountStore }
  },

  data () {
    return {
      cardPinCode: '',
      pinCodeOption: 'random'
    }
  },

  computed: {
    isCustomPinSelected () {
      return this.pinCodeOption === 'custom'
    },

    randomPinCodeContent () {
      if (this.accountStore.cardPinMailer) {
        return this.$i18n.t('form.tile_radio.content.random_pin_code')
      }
      return this.$i18n.t('form.tile_radio.content.no_pin_mailer.random_pin_code')
    },

    customPinCodeContent () {
      if (this.accountStore.cardPinMailer) {
        return this.$i18n.t('form.tile_radio.content.custom_pin_code')
      }
      return this.$i18n.t('form.tile_radio.content.no_pin_mailer.custom_pin_code')
    }
  },

  watch: {
    cardPinCode: function () {
      this.$emit('change-pin-code', this.cardPinCode)
    }
  },

  methods: {
    updatePinCodeOption: function () {
      this.$emit('select-pin-choice', this.pinCodeOption)
    }
  }
}
</script>
