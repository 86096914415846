<template>
  <div ref="popover"
       class="popover-wrapper"
       @click="onClickItem"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave">
    <div class="popover-rel"
         @click="handleClick">
      <slot ref="slot"
            name="trigger"
            :popover-active="popoverActive" />
    </div>
    <div ref="popover-element"
         class="popover"
         :class="[{ 'is-open': popoverActive }, popoverClass]"
         :style="[popoverStyle, popoverPositionStyle]">
      <div v-if="arrow"
           class="popover-arrow"
           :class="arrowClass" />
      <div class="popover__content">
        <slot :popover-active="popoverActive"
              :close-popover="closePopover" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
  name: 'ComponentPopover',

  props: {
    value: {
      type: Boolean,
      default: false
    },

    trigger: {
      type: String,
      default: 'hover'
    },

    arrow: {
      type: Boolean,
      default: true
    },

    positionArrowX: {
      type: String,
      default: 'right'
    },

    positionArrowY: {
      type: String,
      default: 'top'
    },

    popoverStyle: {
      type: String,
      default: ''
    },

    popoverClass: {
      type: String,
      default: ''
    },

    closeOnClick: {
      type: Boolean,
      default: false
    }
  },

  emits: ['toggle-popover'],

  setup () {
    const popover = ref()
    const popoverActive = ref(false)

    function closePopover () {
      popoverActive.value = false
    }

    onClickOutside(popover, () => { closePopover() })

    return {
      popover,
      popoverActive,

      closePopover,
      onClickOutside
    }
  },

  data: () => ({
    popoverPositionStyle: ''
  }),

  computed: {
    arrowClass () {
      return `popover-arrow--${this.positionArrowY}-${this.positionArrowX}`
    }
  },

  watch: {
    popoverActive () {
      this.$emit('toggle-popover', this.popoverActive)

      if (this.popoverActive) {
        this.popoverPositionStyle = ''
        window.requestAnimationFrame(this.setPosition)
      }
    }
  },

  methods: {
    setPosition () {
      const element = this.$refs['popover-element'].getBoundingClientRect()
      const leftDiff = -element.x + 10
      const rightDiff = window.innerWidth - element.x - element.width - 10
      if (leftDiff > 0) {
        this.popoverPositionStyle = `margin-left: ${leftDiff}px`
      } else if (rightDiff < 0) {
        this.popoverPositionStyle = `margin-left: ${rightDiff}px`
      } else {
        this.popoverPositionStyle = ''
      }
    },

    handleMouseEnter () {
      if (this.trigger === 'hover') {
        if (this.enterTimer) clearTimeout(this.enterTimer)
        this.enterTimer = setTimeout(() => {
          this.popoverActive = true
        }, 200)
      }
    },

    handleMouseLeave () {
      if (this.trigger === 'hover') {
        if (this.enterTimer) {
          clearTimeout(this.enterTimer)
          this.enterTimer = setTimeout(() => {
            this.closePopover()
          }, 400)
        }
      }
    },

    handleClick () {
      if (this.trigger === 'click') {
        this.popoverActive = !this.popoverActive
      }
    },

    onClickItem (e) {
      if (!this.closeOnClick) { return }

      let element = e.target
      while (!element.classList.contains('popover__item') && !element.classList.contains('popover-wrapper')) {
        element = element.parentNode
      }
      if (element.classList.contains('popover__item')) {
        this.closePopover()
      }
    }
  }
}
</script>
