<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <table v-else-if="beneficiaryLists.length"
           data-cy="beneficiaries.lists.table"
           class="table table--hover">
      <thead>
        <tr>
          <th>{{ $t("table.label") }}</th>
          <th>{{ $t("table.type") }}</th>
          <th>{{ $t("table.comment") }}</th>
          <th class="center">
            {{ $t("table.beneficiaries_number") }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="list in beneficiaryLists"
            :key="list.id"
            class="is-link"
            :data-cy="`beneficiaries.lists.table.list-${list.id}.row`"
            @click="$router.push({ name: 'beneficiary-list', params: { id: list.id } })">
          <td>{{ list.label }}</td>
          <td>{{ getListType(list) }}</td>
          <td>{{ list.comment || "-" }}</td>
          <td class="center">
            {{ list.nb_beneficiaries }}
          </td>
          <td class="col-action">
            <template v-if="hasPermission($permissions.beneficiaryListsWrite)">
              <span class="hover-item">
                <button v-if="list.updatable && list.list_type?.internal === false"
                        v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                        class="btn-link"
                        :data-cy="`beneficiaries.lists.table.list-${list.id}.edit`"
                        @click.prevent.stop="onEditList(list)">
                  <ic-pencil class="ic ic--24 ic--gray" />
                </button>
                <button v-if="list.updatable || list.list_type?.internal"
                        v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                        class="btn-link"
                        :data-cy="`beneficiaries.lists.table.list-${list.id}.delete`"
                        @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.beneficiaries_list.delete.title'), content: $t('prompt.beneficiaries_list.delete.content'), url: `/beneficiary-lists/${list.id}` })">
                  <ic-remove class="ic ic--24 ic--gray" />
                </button>
              </span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <component-placeholder v-else
                           :label="$t('placeholder.no_beneficiary.found')" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import CreateBeneficiaryList from '@/pages/beneficiaries/modal/CreateBeneficiaryList.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcPencil,
    IcRemove,
    ComponentPlaceholder,
    LoaderSpinner
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    const { beneficiaryLists } = storeToRefs(beneficiaryStore)

    return { appStore, beneficiaryLists, beneficiaryStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('beneficiaries-list-create-success', this.onCreateSuccess)
    this.$bus.on('beneficiaries-list-edit-success', this.onEditSuccess)
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('language-changed', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('beneficiaries-list-create-success', this.onCreateSuccess)
    this.$bus.off('beneficiaries-list-edit-success', this.onEditSuccess)
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('language-changed', this.initData)
  },

  methods: {
    async initData () {
      this.loading = true
      await this.beneficiaryStore.getBeneficiaryLists()
      this.loading = false
    },

    onCreateSuccess () {
      this.onSuccess(this.$i18n.t('message.success.beneficiaries_list.create'))
    },

    onEditSuccess () {
      this.onSuccess(this.$i18n.t('message.success.beneficiaries_list.update'))
    },

    onDeleteSuccess (message) {
      this.onSuccess(message || this.$i18n.t('message.success.beneficiaries_list.delete'))
    },

    onSuccess (message) {
      this.$emit('close')
      showToastSuccess(message)
      this.initData()
    },

    getListType (list) {
      return list.list_type?.label || list.type
    },

    onEditList (list) {
      this.appStore.showModal(CreateBeneficiaryList, { list }, { wrapperClass: 'modal--md' })
    }
  }
}
</script>
