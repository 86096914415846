<template>
  <svg viewBox="0 0 200 160">
    <path d="m128.43 41.66h-60.94v1.5h60.94v-1.5zm.82 98.34c9.251 0 16.75-7.499 16.75-16.75 0-9.251-7.499-16.75-16.75-16.75-9.251 0-16.75 7.499-16.75 16.75 0 9.251 7.499 16.75 16.75 16.75zm0-1.5c-8.422 0-15.25-6.828-15.25-15.25 0-8.422 6.828-15.25 15.25-15.25 8.422 0 15.25 6.828 15.25 15.25 0 8.422-6.828 15.25-15.25 15.25zm-.743-8.148c.05.366.363.648.743.648.414 0 .75-.336.75-.75v-12.189l4.72 4.719.084.073c.293.218.71.194.976-.073.293-.293.293-.767 0-1.06l-6-6-.084-.073c-.293-.218-.71-.194-.976.073l-6 6-.073.084c-.218.293-.194.71.073.976l.084.073c.293.218.71.194.976-.073l4.72-4.719v12.189l.007.102z"
          :fill="colorPrimary" />
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="m73.5 55h24.5v1.5h-24.5v-1.5zm0 6h34v1.5h-34v-1.5zm47.5 17.93h-10.4v6.97h10.4v-6.97zm0 8.47h-10.4v6.97h10.4v-6.97zm0 8.47h-10.4v7.13h10.4v-7.13zm-11.9 7.13v-7.13h-10.35v7.13h10.35zm-11.85 0v-7.13h-10.35v7.13h10.35zm-11.85 0v-7.13h-10.4v7.13h10.4zm-10.4-8.63h10.4v-6.97h-10.4v6.97zm0-8.47h10.4v-6.97h-10.4v6.97zm46-8.47v-6.92h-10.4v6.92h10.4zm-11.9-6.92h-10.35v6.92h10.35v-6.92zm-11.85 0h-10.35v6.92h10.35v-6.92zm-11.85 0h-10.4v6.92h10.4v-6.92zm23.7 23.86h-10.35v-6.97h10.35v6.97zm-11.85 0v-6.97h-10.35v6.97h10.35zm11.85-8.47h-10.35v-6.97h10.35v6.97zm-11.85 0v-6.97h-10.35v6.97h10.35zm24.5 18.6h-47.5c-.4142 0-.75-.336-.75-.75v-34c0-.4142.3358-.75.75-.75h47.5c.414 0 .75.3358.75.75v34c0 .414-.336.75-.75.75z"
          :fill="colorGrey" />
    <path d="m111.904 129.75h-37.614c-.2038 0-.3987-.083-.54-.23l-7.29-7.53c-.1346-.14-.2099-.326-.21-.52v-83.76c-.0389-2.9731 2.337-5.4161 5.31-5.46h52.88c2.973.0439 5.349 2.4869 5.31 5.46v66.93c-.249-.01-.499-.015-.75-.015-.251 0-.501.005-.75.015v-66.89c.03-1.0369-.356-2.0426-1.072-2.7937-.715-.7511-1.701-1.1854-2.738-1.2063l-52.88-.04c-1.0371.0209-2.0229.4552-2.7383 1.2063-.7154.7511-1.1012 1.7568-1.0717 2.7937v83.01h1.03c3.4827.055 6.2645 2.917 6.22 6.4v1.22h36.413c.145.479.309.949.491 1.41zm-43.124-7.53h-.0998l4.822 4.977-.0022-.077c.0446-2.654-2.0657-4.845-4.72-4.9z"
          :fill="colorDark" />

  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
