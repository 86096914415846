import { EFilter } from '@/types/filter.d'

export function formatApiFilters (filters: string[]) {
  const filterList = []

  filterList.push(EFilter.Search)

  if (filters.includes('amount')) {
    filterList.push(EFilter.Amount)
  }

  if (filters.includes('affectations')) {
    filterList.push(EFilter.Affectation)
  }

  if (filters.includes('attachment')) {
    filterList.push(EFilter.Attachment)
  }

  if (filters.includes('beneficiary_id')) {
    filterList.push(EFilter.Beneficiary)
  }

  if (filters.includes('category')) {
    filterList.push(EFilter.Category)
  }

  if (filters.includes('charge_back')) {
    filterList.push(EFilter.ChargeBack)
  }

  if (filters.includes('expense_category')) {
    filterList.push(EFilter.ExpenseCategory)
  }

  if (filters.includes('expiration_date')) {
    filterList.push(EFilter.Expiration)
  }

  if (filters.includes('from') && filters.includes('to')) {
    filterList.push(EFilter.DateRange)
  }

  if (filters.includes('is_compliant')) {
    filterList.push(EFilter.Compliance)
  }

  if (filters.includes('min') && filters.includes('max')) {
    filterList.push(EFilter.MinMax)
  }

  if (filters.includes('reliability')) {
    filterList.push(EFilter.Reliability)
  }

  if (filters.includes('status')) {
    filterList.push(EFilter.Status)
  }

  if (filters.includes('transaction_type')) {
    filterList.push(EFilter.TransactionType)
  }

  if (filters.includes('is_sepa')) {
    filterList.push(EFilter.IsSepa)
  }

  if (filters.includes('type')) {
    filterList.push(EFilter.Type)
  }

  if (filters.includes('user_uuid')) {
    filterList.push(EFilter.User)
  }

  return filterList
}
