import { defineStore } from 'pinia'

import axiosClient from '@/api'

export const useAccountingStore = defineStore('accounting', {
  actions: {
    async getAccounts (type) {
      try {
        const { data } = await axiosClient.get('/companies/accounting/accounts', { params: { type } })
        return data
      } catch (e) {
        console.error(e)
        return []
      }
    },

    async updateAccount (id, account) {
      try {
        await axiosClient.put(`/companies/accounting/accounts/${id}`, account)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getPreferences () {
      try {
        const { data } = await axiosClient.get('/companies/accounting/preferences')
        return data
      } catch (e) {
        return {}
      }
    },

    async updatePreference (key, preference) {
      try {
        const { data } = await axiosClient.put('/companies/accounting/preferences', { [key]: preference })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getCategories () {
      try {
        const { data } = await axiosClient.get('/companies/accounting/categories')
        return data
      } catch (e) {
        console.error(e)
        return []
      }
    },

    async createCategory (chartAccount) {
      try {
        await axiosClient.post('/companies/accounting/categories', chartAccount)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateCategory (id, chartAccount) {
      try {
        await axiosClient.put(`/companies/accounting/categories/${id}`, chartAccount)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getProfiles () {
      try {
        const { data } = await axiosClient.get('/companies/accounting/profiles')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async createProfile (company_profile_id) {
      try {
        const { data } = await axiosClient.post('/companies/accounting/profile', { company_profile_id })
        return data.company_profile_id
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async delete () {
      try {
        await axiosClient.delete('/companies/accounting')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
})
