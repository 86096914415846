<template>
  <svg v-if="type === ETreasuryResumeEvolutionType.Positive"
       width="17"
       height="16"
       viewBox="0 0 17 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect x="0.166504"
          width="16"
          height="16"
          rx="8"
          fill="#0CC288" />
    <path d="M9.34178 5.77677L5.01886 5.77677L5.01886 4.25113L11.8843 4.25113L11.8843 11.1165H10.3586L10.3586 6.91753L5.52728 11.7489L4.44849 10.6701L9.34178 5.77677Z"
          fill="white" />
  </svg>

  <svg v-else-if="type === ETreasuryResumeEvolutionType.Negative"
       width="17"
       height="16"
       viewBox="0 0 17 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect x="0.666748"
          width="16"
          height="16"
          rx="8"
          fill="#FF584A" />
    <path d="M10.8901 9.1754V4.85248L12.4157 4.85248L12.4157 11.7179L5.55031 11.7179L5.5503 10.1922L9.7493 10.1922L4.91797 5.3609L5.99676 4.2821L10.8901 9.1754Z"
          fill="white" />
  </svg>

  <svg v-else
       width="17"
       height="16"
       viewBox="0 0 17 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect x="0.666748"
          width="16"
          height="16"
          rx="8"
          fill="#979EAC" />
    <path d="M4.66675 8H12.6667"
          stroke="white"
          stroke-width="2" />
  </svg>
</template>

<script>
import { ETreasuryResumeEvolutionType } from '@/types/treasury.d'

export default {
  name: 'IcTreasuryResume',
  props: {
    type: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      ETreasuryResumeEvolutionType
    }
  }
}
</script>
