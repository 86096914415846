<template>
  <ul v-if="pageCount > 1"
      class="pagination">
    <li v-show="currentPage > 1">
      <router-link :to="getRouteFor(currentPage - 1)" />
    </li>
    <template v-if="pageCount > 10">
      <template v-if="currentPage > 4">
        <li>
          <router-link :to="getRouteFor(1)"
                       class="page-number">
            1
          </router-link>
        </li>
        <li>
          ...
        </li>
      </template>
      <li v-for="(page, index) in dotsPagination"
          :key="index">
        <router-link :to="getRouteFor(page)"
                     :class="{ active: page === currentPage }"
                     class="page-number">
          {{ page }}
        </router-link>
      </li>
      <template v-if="currentPage < (pageCount - 4)">
        <li>
          ...
        </li>
        <li>
          <router-link :to="getRouteFor(pageCount)"
                       class="page-number">
            {{ pageCount }}
          </router-link>
        </li>
      </template>
    </template>
    <template v-else>
      <li v-for="(page, index) in pageCount"
          :key="index">
        <router-link :to="getRouteFor(page)"
                     :class="{ active: page === currentPage }"
                     class="page-number">
          {{ page }}
        </router-link>
      </li>
    </template>
    <li v-show="currentPage < pageCount">
      <router-link :to="getRouteFor(currentPage + 1)" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ComponentPagination',

  props: {
    pagination: {
      type: Object,
      required: true
    }
  },

  computed: {
    currentPage () {
      return this.pagination.current
    },

    pageCount () {
      return this.pagination.count
    },

    dotsPagination () {
      const pagination = []
      for (let i = 1; i <= this.pageCount; i++) {
        pagination.push(i)
      }

      return pagination.slice(Math.max(0, this.currentPage - 4), Math.min(this.pageCount, this.currentPage + 3))
    }
  },

  methods: {
    getRouteFor (page) {
      const route = { query: { ...this.$route.query, page } }
      if (page <= 1) {
        delete route.query.page
      }

      return route
    }
  }
}

</script>
