import { RootI18nDictionary } from '@vee-validate/i18n'

import messagesEN from './en'
import messagesFR from './fr'

const dictionary: RootI18nDictionary = {
  en: {
    messages: messagesEN
  },
  fr: {
    messages: messagesFR
  },
  de: {
    messages: messagesFR
  }
}

export default dictionary
