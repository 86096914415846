<template>
  <validation-field v-slot="{ field }"
                    class="select"
                    :name="name"
                    :model-value="modelValue">
    <component-dropdown label="countries"
                        :search="true"
                        :disabled="disabled"
                        dropdown-width="100%"
                        async-url="/countries"
                        :model="field"
                        :label-filter="countryFilter"
                        @select="onCountrySelect">
      <template #trigger>
        <div class="select form-control form-control--noborder">
          <span :class="{ 'text-muted': !field.value }">
            {{ field.value ? getCountryName(field.value) : $t('form.country.placeholder') }}
          </span>
        </div>
      </template>
    </component-dropdown>
  </validation-field>
</template>

<script>
import { getCountryName } from '@/helpers/utils/intl'

import ComponentDropdown from './Dropdown.vue'

export default {
  components: {
    ComponentDropdown
  },

  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === undefined,
      required: true
    },

    name: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  methods: {
    getCountryName,

    countryFilter (country) {
      return `<img src="${country.flag_url}" class="ic-flag"> ${getCountryName(country.iso)}`
    },

    onCountrySelect (country) {
      this.$emit('update:modelValue', country.iso)
    }
  }
}
</script>
