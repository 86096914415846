<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="type"
                          dropdown-width="100%"
                          :dropdown-height="24"
                          :can-add-item="false"
                          :values="values"
                          :label-filter="getTypeLabel"
                          :model="selectedCardType.value"
                          @select="onSelectCardType">
        <template #trigger>
          <div class="select"
               :class="modelValue ? 'is-clearable' : 'select--off'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue">
                {{ getTypeLabel(selectedCardType) }}
              </template>
              <template v-else>
                {{ $t('table.type') }}
              </template>
            </div>
            <button v-if="modelValue"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$emit('clear-field')">
              <ic-remove class="ic ic-remove ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { getCardTypeLabel } from '@/helpers/utils/card'
import { useAccountStore } from '@/stores/account'

import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    ComponentDropdown,
    IcRemove
  },

  props: {
    modelValue: {
      type: null,
      required: true
    },

    cardGroup: {
      type: String,
      required: true
    }
  },

  emits: [
    'clear-field',
    'update:modelValue'
  ],

  setup () {
    const accountStore = useAccountStore()

    const { account, physicalCardTypes, virtualCardTypes } = storeToRefs(accountStore)

    return { account, physicalCardTypes, virtualCardTypes }
  },

  data: () => ({
    selectedCardType: {}
  }),

  computed: {
    values () {
      return (this.cardGroup === 'virtual' ? this.virtualCardTypes : this.physicalCardTypes)
        .map(cardType => {
          return {
            label: getCardTypeLabel({ type: cardType }),
            value: cardType
          }
        })
    }
  },

  watch: {
    modelValue () {
      if (!this.modelValue) {
        this.selectedCardType = {}
      }
    }
  },

  methods: {
    getTypeLabel (cardType) {
      return cardType.label
    },

    onSelectCardType (cardType) {
      this.selectedCardType = cardType
      this.$emit('update:modelValue', cardType.value)
    }
  }
}
</script>
