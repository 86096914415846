<template>
  <div class="section__content">
    <div class="section__content-block--centered">
      <component-placeholder :label="$t('placeholder.accounting.title')"
                             :content="$t('placeholder.accounting.subtitle')">
        <accounting-sheet />
      </component-placeholder>
      <div style="width: 32.5rem">
        <div class="form-group">
          <div class="select">
            <component-dropdown dropdown-width="100%"
                                label="profile"
                                :model="value"
                                :values="companyProfiles"
                                @select="onProfileSelected">
              <template #trigger>
                <div class="form-control">
                  <span v-if="value">
                    {{ value.label }}
                  </span>
                  <span v-else
                        class="text-muted">
                    {{ $t("form.common.select") }}
                  </span>
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
        <component-button wrapper-class="btn btn--block btn--primary"
                          :label="$t('button.validate')"
                          :disabled="!value"
                          @click="onSubmitProfile" />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useAccountingStore } from '@/stores/accounting'

import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import AccountingSheet from '@/components/svg/img/accounting-sheet.vue'

export default {
  components: {
    ComponentDropdown,
    AccountingSheet,
    ComponentButton,
    ComponentPlaceholder
  },

  setup () {
    const accountStore = useAccountStore()
    const accountingStore = useAccountingStore()

    const { account } = storeToRefs(accountStore)

    return { account, accountingStore }
  },

  data () {
    return {
      value: null,
      companyProfiles: []
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      const data = await this.accountingStore.getProfiles()
      if (data) {
        this.companyProfiles = data
      }
    },

    async onSubmitProfile () {
      const companyProfileId = await this.accountingStore.createProfile(this.value)
      if (companyProfileId) {
        this.account.company.company_profile_id = companyProfileId
      }
    },

    onProfileSelected (profile) {
      this.value = profile
    }
  }
}
</script>

<style lang="stylus" scoped>
.form-group
  margin-top 2rem
  margin-bottom 2rem
</style>
