<template>
  <core-card-icon :disabled="disabled"
                  :theme="theme"
                  class="logo-visa" />
</template>

<script>
import { CoreCardIcon } from '@common/core-ui'

import { getBadgeVisaTheme } from '@/helpers/utils/card'

export default {
  components: { CoreCardIcon },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: ''
    }
  },

  computed: {
    theme () {
      return getBadgeVisaTheme({ type: this.type })
    }
  }
}
</script>

<style lang="stylus" scoped>
.logo-visa
  margin-right 1.2rem
</style>
