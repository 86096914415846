export enum ECardWalletProvider {
  APPLE = 'apple',
  GOOGLE = 'google',
}

export enum EPhysicalCard {
  CARD = 'card',
  CARD_CONSUMER = 'card_consumer',
  PREMIER_CARD_CONSUMER = 'premier_card_consumer',
  INFINITE_CARD_CONSUMER = 'infinite_card_consumer'
}

export enum EClassicVirtualCard {
  VIRTUAL_CARD = 'virtual_card',
  VIRTUAL_CARD_CONSUMER = 'virtual_card_consumer'
}

export enum EOneTimeVirtualCard {
  ONE_TIME_VIRTUAL_CARD = 'one_time_virtual_card',
  ONE_TIME_VIRTUAL_CARD_CONSUMER = 'one_time_virtual_card_consumer'
}

export enum ECappedVirtualCard {
  CAPPED_VIRTUAL_CARD = 'capped_virtual_card',
  CAPPED_VIRTUAL_CARD_CONSUMER = 'capped_virtual_card_consumer'
}

export enum ERecurringVirtualCard {
  RECURRING_VIRTUAL_CARD = 'recurring_virtual_card',
  RECURRING_VIRTUAL_CARD_CONSUMER = 'recurring_virtual_card_consumer'
}

export type TVirtualCard = EClassicVirtualCard | EOneTimeVirtualCard | ECappedVirtualCard | ERecurringVirtualCard

export type TCardType = EPhysicalCard | TVirtualCard

export type TCardWalletGooglePayloadError = {
  errorCode: string
}

export type TCardWalletGooglePayload = {
  serverSessionId: string
  publicDeviceId: string
  publicWalletId: string
  cardSetting: string
  tokenSetting: string
  errors?: TCardWalletGooglePayloadError[]
}

export type TCardWalletAppleJwt = {
  state: string,
  jws: {
    header: {
      kid: string
    },
    payload: string,
    protected: string,
    signature: string
  }
}

export type TCardWalletAppleResolver = {
  status: integer
  statusMessage: string
}

export type TCardWalletApplePopupMessage = {
  origin: string
  data: {
    message: string
    result: TCardWalletAppleResolver
  }
  source: {
    postMessage(data: {
      message: string,
      timestamp?: number,
      jwt?: TCardWalletAppleJwt | null
    }, domain: string): void
  }
}

export enum ECardWalletErrorCode {
  UNKNOWN_ERROR = 0,
  SUCCESS = 200,
  ABORT_BY_USER = 444,
  POPUP_BLOCKED = 999
}

export enum ECardWalletEmitType {
  MESSAGE = 'message',
  HANDLE_BLUR = 'handleBlur'
}

export enum ECardProcessor {
  MARQETA = 'marqeta',
  WORLDLINE = 'worldline'
}
