export const getDocumentIcon = (mimeType: string) => {
  if (mimeType.startsWith('image')) {
    return 'image'
  }

  if (mimeType.startsWith('video')) {
    return 'video'
  }

  if (mimeType.startsWith('audio')) {
    return 'video'
  }

  if (mimeType.startsWith('text')) {
    return 'default'
  }

  switch (mimeType) {
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'ppt'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
    case 'application/vnd.oasis.opendocument.text':
      return 'doc'
      break
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'xls'
      break
    case 'application/pdf':
      return 'pdf'
      break
    case 'application/gzip':
    case 'application/zip':
    case 'application/x-tar':
    case 'application/x-7z-compressed':
    case 'application/x-rar-compressed':
      return 'archive'
      break
    default:
      return 'default'
  }
}
