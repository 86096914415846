<template>
  <GoogleWallet :disabled="computed.formSubmitted"
                :data-cy="`card.wallet-${ECardWalletProvider.GOOGLE}`"
                :locale="i18n.global.locale"
                @click="enroll" />
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { uniqueId } from 'lodash'

import { getLanguageNameRfc4647 } from '@/helpers/utils/intl'
import i18n from '@/locales'
import { useCardStore } from '@/stores/card'
import {
  ECardWalletEmitType, ECardWalletErrorCode, ECardWalletProvider,
  TCardWalletGooglePayload
} from '@/types/card.d'

import GoogleWallet from '@/components/svg/img/google-wallet.vue'

import './sdk-google'

const cardStore = useCardStore()

const emit = defineEmits<{(e: 'message', code: string|number): void }>()

const props = defineProps({
  cardUuid: {
    type: String,
    required: true
  }
})

const computed = reactive({
  formSubmitted: false
})

const enroll = () => {
  computed.formSubmitted = true
  const clientSessionId = uniqueId()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  window.googlepay.openAppWindow({
    integratorId: import.meta.env.VITE_WEB_WALLET_GOOGLE_INTEGRATOR_ID,
    clientSessionId,
    isTestEnvironment: false,
    tokenSetting: 1,
    cardSetting: 1,
    hl: getLanguageNameRfc4647(i18n.global.locale),
    onSessionCreated: (payload: TCardWalletGooglePayload) => {
      // SCA is not applicable because calls are made outside the console window
      cardStore.initWallet(props.cardUuid, {
        provider: ECardWalletProvider.GOOGLE,
        client_session_id: clientSessionId,
        public_device_id: payload.publicDeviceId,
        public_wallet_id: payload.publicWalletId,
        server_session_id: payload.serverSessionId,
        card_setting: payload.cardSetting,
        token_setting: payload.tokenSetting
      })
    },
    onSuccess: () => {
      emit(ECardWalletEmitType.MESSAGE, ECardWalletErrorCode.SUCCESS)
    },
    onFailure: (payload: TCardWalletGooglePayload) => {
      emit(ECardWalletEmitType.MESSAGE, payload?.errors?.[0]?.errorCode ?? ECardWalletErrorCode.UNKNOWN_ERROR)
      console.error(payload.errors)
    },
    onCancel: () => {
      emit(ECardWalletEmitType.MESSAGE, ECardWalletErrorCode.ABORT_BY_USER)
    }
  })
}
</script>
