<template>
  <svg viewBox="0 0 20 20">
    <path d="M1.667 10a8.333 8.333 0 1 0 16.666 0 8.333 8.333 0 0 0-16.666 0Zm15.384 0A7.051 7.051 0 1 1 2.95 10a7.051 7.051 0 0 1 14.102 0Z" />
    <path v-if="out"
          d="M13.333 10.625H6.667v-1.25h6.666v1.25Z" />
    <path v-else
          d="M9.375 6.042c0-.115.093-.209.208-.209h.834c.115 0 .208.094.208.209v3.333h3.333c.115 0 .209.093.209.208v.834a.208.208 0 0 1-.209.208h-3.333v3.333a.208.208 0 0 1-.208.209h-.834a.208.208 0 0 1-.208-.209v-3.333H6.042a.208.208 0 0 1-.209-.208v-.834c0-.115.094-.208.209-.208h3.333V6.042Z" />
  </svg>
</template>

<script>
export default {
  props: {
    out: Boolean
  }
}
</script>
