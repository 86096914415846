import { IRate } from '@/models/Account/ModelTermAccountRate'
import { TCompany } from '@/types/company.d'
import { Nullable } from '@/types/default.d'
import { TRole } from '@/types/role.d'

import { ECardProcessor, EPhysicalCard, TVirtualCard } from './card.d'

export type AccountId = string

export enum EAccountType {
  CapitalGain = 'capital_gain',
  Main = 'main',
  Sequestre = 'sequestre',
  Sub = 'sub',
  Term = 'term_account',
}

export enum EAccountBillable {
    Billable = 'billable',
    BillableOnMain = 'bill_on_main'
}

export type TSubAccountPayload = {
    billable: EAccountBillable | null
    company_id: number
    label: string
    type: EAccountTypes
}

type AccountCounts = {
  transfers: {
    salaries: number
    waiting_validation: number
  },
  expense_requests: {
    waiting_validation: number
  },
  virtual_cards: {
    waiting_validation: number
  },
  physical_cards: {
    waiting_validation: number
  }
}

type InfoAccount = {
  uuid: string
  access: TRole
  balance: number
  billable: EAccountBillable
  card_partner: ECardProcessor
  card_pin_mailer: boolean
  card_types: {
    physical: EPhysicalCard[]
    virtual: TVirtualCard[]
  }
  closed_at: Nullable<string>
  company: TCompany
  counts: AccountCounts
  currency: {
    flag_url: string
    label: string
    iso: string
    fraction_digits: number
  },
  filters: {
    operations: string[]
  },
  display_sections: { [key: string]: boolean }
  has_account_validation: boolean
  invitation_status: Nullable<string>
  is_deferred: boolean
  label: string
  permissions?: Array<string>
  rib?: {
    number: string,
  }
  settings: {
    default_card_country_holidays: string
    expense_control: boolean
    map_access_affectations_to_operations: boolean
  }
  status: string
  status_group: string
  type: EAccountType
  unread_messages_count?: number
} & object

type CapitalGainAccount = InfoAccount
type MainAccount = InfoAccount
type SubAccount = InfoAccount
type SequestreAccount = InfoAccount
type VirtualAccount = InfoAccount

interface TermAccount extends InfoAccount {
  detail: {
    anniversary_date: Nullable<string>
    contract_url: string
    due_date: string
    estimated_interest_earned: number
    gross_annual_rate: number
    initial_deposit: number
    interest_earned:number
    open_at: string
    term_account_rate: IRate
  }
  withdrawal_request_at: Nullable<string>
}

type Account = CapitalGainAccount | MainAccount | SubAccount | SequestreAccount | TermAccount

export function isCapitalGainAccount (account: Account): account is CapitalGainAccount {
  return (account as CapitalGainAccount).type === EAccountType.CapitalGain
}

export function isMainAccount (account: Account): account is MainAccount {
  return (account as MainAccount).type === EAccountType.Main
}

export function isSubAccount (account: Account): account is SubAccount {
  return (account as SubAccount).type === EAccountType.Sub
}

export function isTermAccount (account: Account): account is TermAccount {
  return (account as TermAccount).type === EAccountType.Term
}
