<template>
  <svg viewBox="0 0 28 28">
    <path v-if="contactless"
          d="M15.244 3.254c4.262 4.24 4.226 9.567 4.226 10.246 0 0 .42 5.422-4.223 10.248-.14.145-.247.322-.247.533 0 .397.336.719.75.719.256 0 .627-.3.627-.3 5.045-5.195 4.614-11.2 4.614-11.2 0-6.883-4.577-11.191-4.577-11.191-.135-.187-.409-.309-.664-.309-.414 0-.75.322-.75.719 0 .211.095.401.247.533l-.003.002ZM12.29 6.262c3.363 3.26 3.14 7.198 3.14 7.198 0 3.442-1.63 5.788-3.152 7.287a.685.685 0 0 0-.279.545c0 .39.347.708.774.708a.807.807 0 0 0 .567-.226S17 18.691 17 13.52c0-5.29-3.66-8.294-3.66-8.294A.807.807 0 0 0 12.775 5c-.427 0-.774.317-.774.708 0 .224.114.424.291.554h-.002Zm-.909 7.748c0 1.903-.86 3.602-2.233 4.814l.01.008A.687.687 0 0 0 9 19.27c0 .403.357.73.798.73a.836.836 0 0 0 .556-.207l.009.007C11.989 18.318 13 16.268 13 13.99c0-2.267-1.011-4.312-2.636-5.792l-.01.008A.835.835 0 0 0 9.798 8C9.358 8 9 8.327 9 8.73c0 .164.06.315.16.437l-.008.006c1.382 1.228 2.23 2.948 2.23 4.837h-.001Zm-3.986-.176C7.396 15.809 6 15.85 6 16.572c0 .402.354.728.791.728.21 0 .577-.167.577-.167C8.36 16.32 9 15.146 9 13.833c0-1.297-.627-2.494-1.628-3.322 0 0-.684-.538-1.187.105-.379.484.042.941.277 1.17.554.54.934 1.254.934 2.048h-.001Z" />
    <path v-else-if="foreignCurrency"
          d="M14 25c6.075 0 11-4.925 11-11S20.075 3 14 3 3 7.925 3 14s4.925 11 11 11Zm0 1C7.373 26 2 20.627 2 14S7.373 2 14 2s12 5.373 12 12-5.373 12-12 12Zm-.705-11.902L10.34 8.097h1.154l2.449 4.947 2.449-4.947h1.153l-2.955 6.001h2.275v.855H14.49v1.104h2.374v.847H14.49V20h-1.07v-3.096h-2.4v-.847h2.399v-1.104H11.02v-.855h2.275Z" />
    <path v-else-if="onlinePay"
          d="M14 25C7.925 25 3 20.075 3 14S7.925 3 14 3s11 4.925 11 11-4.925 11-11 11Zm0-1.1c5.468 0 9.9-4.432 9.9-9.9S19.468 4.1 14 4.1 4.1 8.532 4.1 14s4.432 9.9 9.9 9.9Zm4.063-15.045c.332 1.536.52 3.286.52 5.145 0 1.825-.181 3.547-.502 5.062 1.452.342 2.758.883 3.916 1.625l-.594.926c-1.05-.673-2.244-1.168-3.581-1.482-.807 2.937-2.176 4.869-3.73 4.869-1.561 0-2.936-1.95-3.741-4.909-1.408.312-2.659.82-3.754 1.522l-.594-.926c1.204-.771 2.568-1.326 4.091-1.665C9.778 17.516 9.6 15.809 9.6 14c0-1.842.185-3.58.512-5.105-1.53-.339-2.9-.895-4.109-1.669l.594-.926c1.101.706 2.36 1.215 3.777 1.527C11.182 4.914 12.545 3 14.092 3c1.54 0 2.898 1.897 3.707 4.788 1.347-.315 2.548-.81 3.604-1.488l.594.926c-1.162.745-2.474 1.288-3.934 1.63v-.001Zm-6.865.24A23.613 23.613 0 0 0 10.7 14c0 1.757.181 3.399.48 4.82.89-.133 1.83-.2 2.82-.2 1.055 0 2.055.076 2.998.228.328-1.595.49-3.22.485-4.848 0-1.804-.19-3.485-.503-4.932-.938.15-1.932.225-2.98.225-.983 0-1.917-.066-2.802-.198Zm.264-1.073c.8.114 1.646.171 2.538.171.958 0 1.862-.066 2.714-.197-.67-2.377-1.678-3.896-2.622-3.896-.948 0-1.961 1.53-2.63 3.922Zm-.023 11.872c.669 2.44 1.694 4.006 2.653 4.006.955 0 1.976-1.555 2.646-3.98-.86-.133-1.772-.2-2.738-.2-.9 0-1.754.058-2.561.174Z" />
    <path v-else-if="periodicity"
          d="M24 20.5a2.5 2.5 0 0 1-2.336 2.495L21.5 23h-15a2.5 2.5 0 0 1-2.495-2.336L4 20.5v-13a2.5 2.5 0 0 1 2.336-2.495L6.5 5 8 4.999V4h1v.999h10V4h1v.999L21.5 5a2.5 2.5 0 0 1 2.495 2.336L24 7.5zM23 12H5v8.5a1.5 1.5 0 0 0 1.356 1.493L6.5 22h15a1.5 1.5 0 0 0 1.493-1.356L23 20.5zm-1.5-6L20 5.999V8h-1V5.999H9V8H8V5.999L6.5 6l-.144.007a1.5 1.5 0 0 0-1.35 1.349L5 7.5V11h18V7.5a1.5 1.5 0 0 0-1.356-1.493z" />
    <path v-else-if="swipe"
          d="M4 8h21v-.5A1.5 1.5 0 0 0 23.5 6h-18A1.5 1.5 0 0 0 4 7.5V8Zm0 3v8.5A1.5 1.5 0 0 0 5.5 21h18a1.5 1.5 0 0 0 1.5-1.5V11H4Zm1.5-6h18A2.5 2.5 0 0 1 26 7.5v12a2.5 2.5 0 0 1-2.5 2.5h-18A2.5 2.5 0 0 1 3 19.5v-12A2.5 2.5 0 0 1 5.5 5ZM6 18h7v1H6v-1Z" />
    <path v-else-if="withdrawal"
          d="M25 20v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2V5a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-2Zm-1 0H5a1 1 0 0 1-1-1V8H2v14h22v-2ZM5 5v14h22V5H5Zm11.776 2.682c1.133 0 2.298.482 2.927 1.229l-.486.828c-.576-.707-1.496-1.134-2.555-1.134-1.297 0-2.4.81-2.794 1.98h5.114l-.436.924h-4.882c-.02.277-.015.2-.015.278l.003.107c.002.039.005.076.011.138h4.644l-.436.923H13.87c.434 1.245 1.52 2.004 2.906 2.004.974 0 1.871-.414 2.441-1.1l.14 1.117c-.666.534-1.674.906-2.638.906-1.886 0-3.426-1.145-3.878-2.927h-1.372l.437-.923h.77a4.856 4.856 0 0 1-.005-.256v-.068c0-.073.001-.135.005-.2h-1.207l.437-.922h.935c.452-1.784 1.995-2.904 3.935-2.904Z" />
  </svg>
</template>

<script>
export default {
  props: {
    contactless: Boolean,
    foreignCurrency: Boolean,
    onlinePay: Boolean,
    periodicity: Boolean,
    swipe: Boolean,
    withdrawal: Boolean
  }
}
</script>
