<template>
  <div id="dayblock"
       data-cy="cards.settings.section.authorized-days"
       class="form__fieldset">
    <group-title :title="$t('cards_settings.authorized_days.title')" />
    <div class="form__fieldset__group">
      <div class="authorized-days-block">
        <div class="d-flex">
          <div v-for="(day, index) in settings.authorization_range"
               :key="index"
               class="authorized-days-block__item">
            <div class="switch-btn-label"
                 :class="{ 'text-dark': day.enabled }">
              {{ $t(`general.days.full.${index}`) }}
            </div>
            <toggle-switch :model-value="day.enabled"
                           :toggle-class="'switch-btn--sm'"
                           :disabled="disabled"
                           @update:model-value="value => updateDayRange(index, 'enabled', value)" />
            <div class="input-group-col">
              <component-dropdown class="form-control form-control--sm"
                                  :class="{ 'form-control--error': invalidRange(day) }"
                                  :disabled="disabled"
                                  :dropdown-width="'100%'"
                                  :dropdown-height="21"
                                  :dropdown-margin-top="0"
                                  :search="false"
                                  :can-add-item="false"
                                  :values="getHoursBefore(day.end)"
                                  :model="null"
                                  @select="e => isTimeCorrect(e, index, 'start')">
                <template #trigger>
                  <div class="center"
                       :class="{ 'text-muted': !day.start || !day.enabled }"
                       style="flex: 1">
                    {{ settings.authorization_range[index].start || $t('cards_settings.authorized_days.start_time') }}
                  </div>
                </template>
              </component-dropdown>
              <component-dropdown class="form-control form-control--sm"
                                  :class="{ 'form-control--error': invalidRange(day) }"
                                  :disabled="disabled"
                                  :dropdown-width="'100%'"
                                  :dropdown-height="21"
                                  :dropdown-margin-top="0"
                                  :search="false"
                                  :can-add-item="false"
                                  :values="getHoursAfter(day.start)"
                                  :model="null"
                                  @select="e => isTimeCorrect(e, index, 'end')">
                <template #trigger>
                  <div class="center"
                       :class="{ 'text-muted': !day.end || !day.enabled }"
                       style="flex: 1">
                    {{ settings.authorization_range[index].end || $t('cards_settings.authorized_days.end_time') }}
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
        </div>
        <div v-if="hasEnabledDays"
             class="center mt-3">
          <component-popover trigger="click"
                             popover-class="popover--center popover--no-padding mt-1"
                             :arrow="false">
            <template #trigger>
              <button class="btn-link"
                      type="button"
                      :disabled="disabled">
                <span class="text-muted pb-2">{{ $t('cards_settings.authorized_days.same_range') }}</span>
                <i class="btn-icon btn-icon--right">
                  <ic-chevron down
                              class="ic ic--14 ic--gray" />
                </i>
              </button>
            </template>
            <time-picker :end="everydayEnd"
                         :start="everydayStart"
                         @change-end="changeEnd"
                         @change-start="changeStart" />
          </component-popover>
        </div>
      </div>
      <div class="form__fieldset__block authorized-holidays">
        <div class="item-left">
          <toggle-switch :model-value="settings.non_working_day"
                         data-cy="cards.settings.non-working-day.toggle"
                         :disabled="disabled"
                         :toggle-class="'switch-btn--sm'"
                         @update:model-value="value => updateCardSettings('non_working_day', value)" />
          <div class="switch-btn-label"
               :class="{ 'text-muted': !settings.non_working_day }">
            {{ $t('cards_settings.authorized_days.holidays') }}
          </div>
        </div>
        <div class="item-right">
          <button class="btn-link btn-link--bold"
                  type="button"
                  data-cy="cards.settings.holiday-country.configure"
                  :disabled="disabled"
                  @click="editHolidayCountry">
            <span>{{ $t('button.configure') }}</span>
            <span v-tooltip="{ content: `${$t('tooltip.country_used')} ${getCountryName(settings.country_holidays)}`, theme: 'tooltip', html: true }"
                  class="ic-helper">
              <ic-info outline
                       class="ic ic--16 ic--gray ml-1" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { getHoursOfDay } from '@/helpers/utils/date'
import { getCountryName } from '@/helpers/utils/intl'
import { useAppStore } from '@/stores/app'

import HolidayCountry from '@/pages/cards/details/components/settings/modal/HolidayCountry.vue'

import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import ComponentPopover from '@/components/Popover.vue'
import TimePicker from '@/components/popovers/TimePicker.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcChevron,
    IcInfo,
    TimePicker,
    ComponentDropdown,
    ComponentPopover,
    ToggleSwitch,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      enabledHolidays: false,
      hours: getHoursOfDay()
    }
  },

  computed: {
    everydayStart: {
      get: function () {
        const val = Object.values(this.settings.authorization_range).filter(el => el.enabled)[0].start
        const sum = Object.values(this.settings.authorization_range).filter(el => el.enabled).filter(el => el.start !== val)
        if (sum.length !== 0) { return '' }

        return val || this.$i18n.t('general.none')
      },

      set: function (newVal) { return newVal }
    },

    everydayEnd: {
      get: function () {
        const val = Object.values(this.settings.authorization_range).filter(el => el.enabled)[0].end
        const sum = Object.values(this.settings.authorization_range).filter(el => el.enabled).filter(el => el.end !== val)
        if (sum.length !== 0) { return '' }

        return val || this.$i18n.t('general.none')
      },

      set: function (newVal) { return newVal }
    },

    hasEnabledDays () {
      return !!Object.values(this.settings.authorization_range).filter(el => el.enabled).length
    }
  },

  methods: {
    getCountryName,

    invalidRange (range) {
      return range.start !== null && range.end !== null && this.isABeforeB(range.end, range.start)
    },

    updateCardSettings (key, value) {
      this.$emit('update-card-settings', {
        [key]: value
      })
    },

    isTimeCorrect (value, day, type) {
      const isCorrect = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/i.test(value)
      this.updateDayRange(day, type, (isCorrect) ? value : null)
    },

    isABeforeB (a, b) {
      const hoursA = parseInt(a.split(':')[0])
      const minA = parseInt(a.split(':')[1])
      const hoursB = parseInt(b.split(':')[0])
      const minB = parseInt(b.split(':')[1])

      return hoursA < hoursB || (hoursA === hoursB && minA < minB)
    },

    getHoursAfter (a) {
      const result = [this.$i18n.t('general.none')]
      if (!a) { return result.concat(this.hours) }

      this.hours.forEach(b => { if (this.isABeforeB(a, b)) { result.push(b) } })

      return result
    },

    getHoursBefore (a) {
      const result = [this.$i18n.t('general.none')]
      if (!a) { return result.concat(this.hours) }

      this.hours.forEach(b => { if (!this.isABeforeB(a, b) && a !== b) { result.push(b) } })

      return result
    },

    changeStart (start) {
      const authorization_range = cloneDeep(this.settings.authorization_range)
      this.everydayStart = start
      if (start === this.$i18n.t('general.none')) { start = null }

      Object.values(authorization_range).filter(el => el.enabled).forEach(el => { el.start = start })

      this.updateCardSettings('authorization_range', authorization_range)
    },

    changeEnd (end) {
      const authorization_range = cloneDeep(this.settings.authorization_range)
      this.everydayEnd = end
      if (end === this.$i18n.t('general.none')) { end = null }

      Object.values(authorization_range).filter(el => el.enabled).forEach(el => { el.end = end })

      this.updateCardSettings('authorization_range', authorization_range)
    },

    updateDayRange (day, key, value) {
      const authorization_range = cloneDeep(this.settings.authorization_range)
      authorization_range[day][key] = value

      this.updateCardSettings('authorization_range', authorization_range)
    },

    editHolidayCountry () {
      this.appStore.showModal(
        HolidayCountry,
        {
          callbackFunction: this.updateCardSettings,
          holidayCountry: this.settings.country_holidays
        },
        { wrapperClass: 'modal--xs' }
      )
    }
  }
}
</script>
