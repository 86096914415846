<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="chargeBack"
                          :dropdown-width="'100%'"
                          :search="false"
                          :can-add-item="false"
                          :values="values"
                          :model="selectedChargeBack.value"
                          @select="onSelectChargeBack">
        <template #trigger>
          <div class="select"
               :class="modelValue === null ? 'select--off' : 'is-clearable'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue === null">
                {{ $t('form.charge_back.placeholder') }}
              </template>
              <template v-else>
                {{ selectedChargeBack.label }}
              </template>
            </div>
            <button v-if="modelValue !== null"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="reset">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },

  emits: ['update:modelValue', 'clear-field'],

  data () {
    return {
      selectedChargeBack: {
        value: null
      }
    }
  },

  computed: {
    values () {
      return [
        {
          label: this.$i18n.t('form.charge_back.label'),
          value: 'true'
        },
        {
          label: this.$i18n.t('form.charge_back.none'),
          value: 'false'
        },
        {
          label: this.$i18n.t('general.undefined'),
          value: 'null'
        }
      ]
    }
  },

  methods: {
    onSelectChargeBack (chargeBack) {
      this.selectedChargeBack = chargeBack
      this.$emit('update:modelValue', chargeBack.value)
    },

    reset () {
      this.selectedChargeBack = { label: null, value: null }
      this.$emit('update:modelValue', null)
    }
  }
}
</script>
