<template>
  <svg viewBox="0 0 24 24">
    <path d="M6.279 18H3V8.972C3 7.889 3.913 7 5.046 7h13.908C20.091 7 21 7.889 21 8.972V18h-3.245v2.364H6.28zm12.706-9.09H5.05c-.224 0-.41.167-.41.374v6.989h1.64v-2.455h11.476v2.455h1.64V9.284c0-.207-.184-.375-.41-.375zm-2.049.817c.418 0 .763.31.813.715l.007.103c0 .418-.31.762-.717.812l-.103.007a.817.817 0 0 1-.103-1.63zM17.176 4a.82.82 0 0 1 .824.813V6H6V4.813A.82.82 0 0 1 6.825 4zm-1.06 14.727v-3.272H7.92v3.272z" />
  </svg>
</template>

<script>
export default {
}
</script>
