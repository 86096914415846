<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="transferType"
                          dropdown-width="100%"
                          :values="values"
                          :model="selectedTransferType.value"
                          @select="onSelectedTransferType">
        <template #trigger>
          <div class="select"
               :class="modelValue === null ? 'select--off' : 'is-clearable'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue === null">
                {{ $t('form.transfer_type.placeholder') }}
              </template>
              <template v-else>
                {{ selectedTransferType.label }}
              </template>
            </div>
            <button v-if="modelValue !== null"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="reset">
              <core-icon :name="ECoreIconList.Delete"
                         :size="16"
                         :theme="ECoreIconTheme.GrayLow" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreIcon, ECoreIconList, ECoreIconTheme } from '@common/core-ui'

import ComponentDropdown from '@/components/Dropdown.vue'

const { t } = useI18n()

const props = defineProps<{
  modelValue: boolean | null
}>()

const emits = defineEmits(['update:modelValue'])

const selectedTransferType = computed(() => ({
  label: props.modelValue ? t('form.transfer_type.sepa') : t('form.transfer_type.swift'),
  value: props.modelValue
}))

const values = computed(() => [
  { label: t('form.transfer_type.sepa'), value: true },
  { label: t('form.transfer_type.swift'), value: false }
])

function onSelectedTransferType (transferType: { label: string, value: boolean }) {
  emits('update:modelValue', transferType.value)
}

function reset () {
  emits('update:modelValue', null)
}
</script>
