<template>
  <svg viewBox="0 0 24 24">
    <path d="M6.974 10.113a.88.88 0 0 1 1.262 0 .882.882 0 0 1 .082 1.172l-.082.092-1.944 1.948c-1.222 1.198-1.222 3.161-.001 4.384a3.065 3.065 0 0 0 4.232.136l.144-.135 1.945-1.949a.88.88 0 0 1 1.262 0 .882.882 0 0 1 .082 1.172l-.082.093-1.944 1.947A4.915 4.915 0 0 1 8.48 20.4c-1.285 0-2.506-.504-3.451-1.451-1.848-1.828-1.904-4.792-.168-6.71l.168-.178zm5.983-.345a.88.88 0 0 1 1.262 0 .882.882 0 0 1 .081 1.171l-.08.093-3.177 3.157a.872.872 0 0 1-.619.267.968.968 0 0 1-.643-.242.882.882 0 0 1-.081-1.172l.081-.093zm-.861-4.736c1.881-1.91 4.969-1.91 6.875 0 1.848 1.828 1.904 4.792.168 6.71l-.168.178-1.945 1.924a.872.872 0 0 1-.619.266c-.22 0-.469-.089-.619-.217a.882.882 0 0 1-.081-1.172l.081-.092 1.945-1.948c1.221-1.198 1.221-3.161 0-4.384a3.057 3.057 0 0 0-2.188-.918c-.746 0-1.47.278-2.037.776l-.15.142-1.946 1.948a.88.88 0 0 1-1.262 0 .882.882 0 0 1-.08-1.172l.08-.093z" />
  </svg>
</template>

<script>
export default {
}
</script>
