<template>
  <modal-dialog-placeholder @close="$emit('close')">
    <component-placeholder :label="$t('modal.capital_gain.title')"
                           :content="$t('modal.capital_gain.text', { button_label: $t('button.validate') })"
                           :button="$t('button.validate')"
                           :cancel-button="$t('button.cancel')"
                           @btn-click="onConfirmCapitalGain"
                           @cancel-btn-click="$emit('close')">
      <capital-deposit />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script>
import { useAccountStore } from '@/stores/account'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import CapitalDeposit from '@/components/svg/img/capital-deposit.vue'

export default {
  components: {
    ComponentPlaceholder,
    ModalDialogPlaceholder,
    CapitalDeposit
  },

  emits: ['close'],

  setup () {
    const accountStore = useAccountStore()

    return { accountStore }
  },

  methods: {
    async onConfirmCapitalGain () {
      const success = await this.accountStore.capitalGainRequestSignature()
      if (success) {
        this.$bus.emit('capital-gain-signature-requested')
      }
      this.$emit('close')
    }
  }

}
</script>
