import { Ref, ref } from 'vue'
import dayjs from 'dayjs'

import { formatAmount } from '@/helpers/utils/number'
import { globalTranslation } from '@/locales'
import { Model } from '@/models/Model'
import { ModelOperation } from '@/models/Operation/ModelOperation'
import { useCreditStore } from '@/stores/credit'
import { ECreditState, ICredit } from '@/types/credit.d'
import { ICurrency } from '@/types/currency.d'
import { Nullable } from '@/types/default.d'
import { IDocument } from '@/types/document.d'

export class ModelCredit extends Model {
  public id?: number
  readonly state: ECreditState
  readonly currency?: Nullable<ICurrency>
  readonly operation: Nullable<ModelOperation>
  public date: Nullable<Date>
  public label: string
  public reference: string
  public amount: Nullable<number>
  public send_notification: Ref
  public client_email: Nullable<string>
  public document: Nullable<IDocument> = null

  public apiFields = ['date', 'label', 'reference', 'amount', 'client_email', 'document', 'send_notification']
  public apiCast = { document: 'file' }

  private constructor (properties: ICredit) {
    super()

    this.id = properties?.id
    this.state = properties.state
    this.currency = properties?.currency || null
    this.operation = properties?.operation || null
    this.date = properties?.date ? new Date(properties.date) : null
    this.label = properties?.label || ''
    this.reference = properties?.reference || ''
    this.amount = properties?.amount || null
    this.client_email = properties.client_email || ''
    this.document = properties.document
    this.send_notification = ref(properties?.send_notification || false)

    return this.onConstructed()
  }

  static create (properties: ICredit = {} as ICredit) {
    return new ModelCredit(
      properties
    )
  }

  get formattedDate (): string {
    if (!this.date) return ''
    return dayjs(this.date).format('L')
  }

  get formattedAmount () {
    return formatAmount(this.amount, this.currency)
  }

  get notificationTooltip () {
    return this.send_notification.value
      ? globalTranslation('tooltip.notification.on')
      : globalTranslation('tooltip.notification.off')
  }

  toggleNotification = async () => {
    const value = !this.send_notification.value
    this.send_notification.value = value

    if (!this.id) return
    const success = await useCreditStore().creditSendNotification(this)
    if (!success) this.send_notification.value = !value
  }

  getCredit = async () => {
    if (!this.id) return
    return await useCreditStore().getCredit(this.id)
  }
}
