<template>
  <svg width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667ZM8.00004 13.6411C11.1155 13.6411 13.6411 11.1155 13.6411 8.00004C13.6411 4.88459 11.1155 2.35902 8.00004 2.35902C4.88459 2.35902 2.35902 4.88459 2.35902 8.00004C2.35902 11.1155 4.88459 13.6411 8.00004 13.6411ZM8.49548 3.93219C8.46237 3.68814 8.25317 3.50004 8.00004 3.50004C7.7239 3.50004 7.50004 3.7239 7.50004 4.00004V8.00004L7.5046 8.06789C7.53771 8.31194 7.74691 8.50004 8.00004 8.50004H11L11.0679 8.49548C11.3119 8.46237 11.5 8.25317 11.5 8.00004L11.4955 7.93219C11.4624 7.68814 11.2532 7.50004 11 7.50004H8.50004V4.00004L8.49548 3.93219Z"
          fill="#979EAC" />
  </svg>
</template>

<script>
export default {
}
</script>
