<template>
  <section class="section section-operations">
    <div class="section__heading">
      <div class="section__heading__left align-items-center">
        <h1 class="section-title">
          {{ $t("title.operations") }}
        </h1>
      </div>
    </div>
    <operations-tabs />
    <router-view />
  </section>
</template>

<script>
import OperationsTabs from '@/pages/operations/components/OperationsTabs.vue'

export default {
  components: {
    OperationsTabs
  }
}
</script>
