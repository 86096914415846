<template>
  <svg viewBox="0 0 24 24">
    <path v-if="outline"
          d="M12 20.25a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5Zm0 1.5c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75Zm-.88-4.25V9.82h1.92v7.68h-1.92Zm-.272-10.288c0-.608.512-1.152 1.216-1.152s1.248.496 1.248 1.152c0 .656-.544 1.152-1.248 1.152s-1.216-.544-1.216-1.152Z" />
    <path v-else
          d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75Zm-.88-4.25h1.92V9.82h-1.92v7.68Zm-.272-10.288c0 .608.512 1.152 1.216 1.152s1.248-.496 1.248-1.152c0-.656-.544-1.152-1.248-1.152s-1.216.544-1.216 1.152Z" />
  </svg>
</template>

<script>
export default {
  props: {
    outline: Boolean
  }
}
</script>
