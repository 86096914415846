<template>
  <div class="company-account-informations--details">
    <h3>{{ title }}</h3>
    <div class="bordered-box bordered-box--padding">
      <table class="table">
        <tr v-for="(info, index) in informations"
            :key="index">
          <td v-if="info.detail"
              class="col--sm-4 text-muted">
            {{ info.label }}
          </td>
          <td v-if="info.detail"
              class="col--sm-8">
            {{ info.detail }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    informations: {
      type: Array,
      required: true
    }
  }
}
</script>
