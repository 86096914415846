<template>
  <div class="row">
    <div class="col col--sm-12">
      <component-dropdown :model="modelValue"
                          :label-filter="labelFilter"
                          :values="translatedLanguages"
                          :dropdown-menu-class="getDropdownMenuClass"
                          label="language"
                          dropdown-width="100%"
                          @select="onLanguageSelect">
        <template #trigger>
          <div class="select form-control"
               :class="[`form-control--${variant}`]">
            <span v-if="modelValue"
                  class="flag">
              <img class="flag__img"
                   :src="getFlagSrc(modelValue)">
              {{ getLanguageName(modelValue) }}
            </span>
            <span v-else
                  class="text-placeholder">{{ $t('form.language.placeholder') }}</span>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import { getFlagSrc, getLanguageName } from '@/helpers/utils/intl'
import { translatedLanguages } from '@/locales'

import ComponentDropdown from '@/components/Dropdown.vue'

export default {
  components: {
    ComponentDropdown
  },

  props: {
    modelValue: {
      type: [String, null],
      default: null
    },

    variant: {
      type: String,
      validator: prop => ['border', 'noborder'].includes(prop),
      default: 'border'
    }
  },

  emits: ['update:modelValue'],

  data () {
    return {
      translatedLanguages,
      values: []
    }
  },

  computed: {
    isVariantNoBorder () {
      return this.variant === 'noborder'
    },

    getDropdownMenuClass () {
      return this.isVariantNoBorder ? 'dropdown-menu--noborder' : ''
    }
  },

  methods: {
    getFlagSrc,
    getLanguageName,

    labelFilter (value) {
      return `<span class="flag"><img class="flag__img" src="${getFlagSrc(value)}">${getLanguageName(value)}</span>`
    },

    onLanguageSelect (value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
