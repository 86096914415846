<template>
  <div data-cy="operations.menu-dropdown.popover"
       class="account-dropdown"
       :class="{ 'is-open': isOpen }"
       @mouseleave="onLeave"
       @mouseover="onHover">
    <router-link class="account-dropdown__header"
                 :to="{ name: isDeviceMobile ? 'account-info' : 'account' }">
      <div class="account-dropdown-user">
        <div v-tooltip="{
               content: profilePictureTitle,
               theme: 'tooltip',
               triggers: [!preventHover && 'hover']
             }"
             @click.prevent.stop="editProfilePicture">
          <user-avatar :key="`${user.picture?.url}-${user.picture?.updated_at}`"
                       :size="42"
                       :picture="user.picture"
                       :placeholder-label="userFullName" />
        </div>
        <div class="user-info">
          <span class="user-info__name">
            <span>{{ userFullName }}</span>
            <ic-chevron down
                        class="ic ic--16 ic--gray" />
          </span>
          <span class="user-info__email">{{ user.email }}</span>
        </div>
      </div>
    </router-link>
    <div class="account-dropdown__body">
      <ul class="account-dropdown-menu">
        <li>
          <router-link :to="{ name: 'account' }">
            {{ $t('title.account') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'account-info' }">
            {{ $t('account.menu.user_profile.title') }}
          </router-link>
        </li>
        <li v-if="isRibVisible">
          <router-link :to="{ name: 'account-rib' }">
            {{ $t('account.menu.rib.title') }}
          </router-link>
        </li>
        <li v-if="isCompanyVisible">
          <router-link :to="{ name: 'account-company' }">
            {{ $t('account.menu.company.title') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'account-settings-notifications' }">
            {{ $t('account.menu.settings.title') }}
          </router-link>
        </li>
      </ul>
      <button v-if="isPermissionGranted"
              data-cy="operations.menu-dropdown.button"
              @click="$router.push({ name: 'create-sub-account' })">
        <ic-plus-circle class="ic ic--20 ic--dark" />
        <span>{{ $t('button.create_sub_account') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import EditProfilePicture from '@/components/modals/EditProfilePicture.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcPlusCircle from '@/components/svg/icons/ic-plus-circle.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcPlusCircle,
    IcChevron,
    UserAvatar
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)
    const { isDeviceMobile } = storeToRefs(appStore)
    const { user, userFullName } = storeToRefs(authStore)

    return {
      account,
      accountStore,
      appStore,
      isDeviceMobile,
      user,
      userFullName
    }
  },

  data () {
    return {
      preventHover: false,
      isOpen: false
    }
  },

  computed: {
    isRibVisible () {
      return this.account.rib && hasFeature(EFeature.AccountRIB)
    },

    isCompanyVisible () {
      return hasFeature(EFeature.AccountCompany)
    },

    isPermissionGranted () {
      return this.isCompanyVisible && this.accountStore.hasAnyCompanyPermission(this.account.company)
    },

    profilePictureTitle () {
      return this.user.picture ? this.$t('title.edit_profile_picture') : this.$t('title.add_profile_picture')
    }
  },

  watch: {
    '$route' () {
      this.preventHover = true
      window.setTimeout(() => {
        this.preventHover = this.isDeviceMobile
      }, 100)
      this.isOpen = false
    }
  },

  mounted () {
    this.preventHover = this.isDeviceMobile
  },

  methods: {
    editProfilePicture () {
      this.appStore.showModal(
        EditProfilePicture,
        {
          pictureToUpdate: 'user',
          title: this.profilePictureTitle
        },
        { wrapperClass: 'modal--sm modal-profile-picture' })
    },

    onHover () {
      if (this.preventHover) return
      this.isOpen = true
    },

    onLeave () {
      this.isOpen = false
    }
  }
}
</script>
