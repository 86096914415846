<template>
  <section class="section section-statements">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.statements") }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <statement-filter :key="$route.name"
                            :is-card="isCard"
                            :is-annual-fees="isAnnualFees"
                            :is-virtual-card="isVirtualCard"
                            :selected-statements="selectedStatements"
                            @update-loading="updateLoadingFilter"
                            @selected-params="onSelectedParams" />
        </div>
      </div>
    </div>
    <tabs v-if="!isOnlyStatements"
          :data="segments" />
    <router-view ref="list"
                 :key="$route.name"
                 :loading-filter="loadingFilter"
                 :params="statementsParams"
                 @selected-statements="onSelectedStatements" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { useAccountStore } from '@/stores/account'
import { isTermAccount } from '@/types/account.d'

import StatementFilter from '@/components/filters/StatementFilter.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'PageStatements',

  components: {
    StatementFilter,
    Tabs
  },

  setup () {
    const accountStore = useAccountStore()
    const { account } = storeToRefs(accountStore)
    return { account }
  },

  data () {
    return {
      loadingFilter: true,
      statementsParams: null,
      selectedStatements: []
    }
  },

  computed: {
    displayCardTabs () {
      return hasFeature(EFeature.SectionCard) && this.hasPermission(this.$permissions.statementsCardsRead)
    },

    displayAnnualFeesTab () {
      return this.hasPermission(this.$permissions.statementsAnnualFeesRead)
    },

    isOnlyStatements () {
      return !this.displayCardTabs && !this.displayAnnualFeesTab
    },

    displayStatementDispatch () {
      return this.hasPermission(this.$permissions.statementsDispatchRead)
    },

    sections () {
      const sections = [{
        label: this.$i18n.t('button.my_statements'),
        route: { name: 'statements' },
        active: true
      }]
      if (this.displayStatementDispatch) {
        sections.push({
          label: this.$i18n.t('button.statement_dispatch'),
          route: { name: 'statements-dispatch' },
          active: false
        })
      }
      return sections
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.checking_account'),
          route: { name: 'statements' },
          visible: true
        },
        {
          label: this.$i18n.t('button.physical_cards'),
          route: { name: 'statements-cards-physical' },
          visible: this.displayCardTabs
        },
        {
          label: this.$i18n.t('button.virtual_cards'),
          route: { name: 'statements-cards-virtual' },
          visible: this.displayCardTabs
        },
        {
          label: this.$i18n.tc('button.annual_fees'),
          route: { name: 'statements-annual-fees' },
          visible: this.displayAnnualFeesTab
        }
      ].filter(route => route.visible)
    },

    isCard () {
      return ['statements-cards-physical', 'statements-cards-virtual'].includes(this.$route.name)
    },

    isVirtualCard () {
      return this.$route.name === 'statements-cards-virtual'
    },

    isAnnualFees () {
      return this.$route.name === 'statements-annual-fees'
    }
  },

  watch: {
    '$route.name' () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    isTermAccount,

    initData () {
      this.selectedStatements = []
      this.statementsParams = null
    },

    onSelectedParams (params) {
      this.statementsParams = params
    },

    onSelectedStatements (statements) {
      this.selectedStatements = statements
    },

    updateLoadingFilter (value) {
      this.loadingFilter = value
    }
  }
}
</script>
