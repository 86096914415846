<template>
  <validation-form v-slot="{ errors }"
                   ref="form"
                   :validation-schema="schema"
                   class="sidepanel__content"
                   @invalid-submit="formSubmitted = true"
                   @submit="onSubmit">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t("form.transfer_details.title") }} {{ index + 1 }}/{{ values.length }}
        </h2>
      </div>
      <div class="sidepanel-body form">
        <component-alert v-if="Object.keys(errors).length && formSubmitted"
                         :type="'error'"
                         :message="$t('message.warning.form_not_valid')"
                         :wrapper-class="''" />
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.credit_account.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div class="form-group col col--sm-12">
                <label class="label input__label">{{ $t('form.credit_account.label') }}</label>
                <div class="dropdown dropdown--select">
                  <div class="dropdown-toggle select">
                    <input v-model="currentBeneficiaryLabel"
                           type="text"
                           class="form-control form-control--noborder"
                           autocomplete="off"
                           :placeholder="$t('form.credit_account.placeholder')"
                           :disabled="true">
                  </div>
                </div>
              </div>
            </div>
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.email && formSubmitted || (setEmail && !currentBeneficiary.email) }">
                <label class="label input__label"
                       for="email">{{ $t('form.email.label') }}
                  <template v-if="!currentTransfer.send_notification">({{ $t('general.optional') }})</template>
                </label>
                <validation-field id="email"
                                  v-model="currentBeneficiary.email"
                                  type="text"
                                  class="form-control form-control--noborder"
                                  name="email"
                                  :placeholder="$t('form.email.placeholder')" />
              </div>
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.phone_number }">
                <label class="label input__label">
                  {{ $t('form.phone.label') }} ({{ $t('general.optional') }})
                </label>
                <phone-input id="phone_number"
                             v-model="currentBeneficiary.phone_number"
                             wrapper-class="input-group--noborder"
                             name="phone_number" />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.transfer_details.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.amount }">
                <label class="label input__label"
                       for="amount">{{ $t('form.amount.label') }}</label>
                <div class="input-group">
                  <number-input id="amount"
                                v-model="currentTransfer.amount"
                                name="amount"
                                class="form-control form-control--noborder input__field"
                                :decimal="true"
                                :minimum-fraction-digits="2" />
                  <span class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
                </div>
              </div>
              <component-datepicker v-model="currentTransfer.execution_date"
                                    name="execution_date"
                                    :disabled-days="disabled"
                                    :label="$t('form.transfer_details.date_label')"
                                    icon-calendar
                                    :language="$i18n.locale"
                                    :format="'dd/MM/yyyy'"
                                    :wrapper-class="'form-group col col--sm-6'"
                                    :input-class="'form-control form-control--noborder'"
                                    :monday-first="true"
                                    :disable-input="currentTransfer.is_advance_payment"
                                    :placeholder="$t('form.date.placeholder')" />
            </div>
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.label }">
                <label class="label input__label"
                       for="label">{{ $t('form.transfer_details.beneficiary_label') }}</label>
                <div class="dropdown">
                  <validation-field id="label"
                                    v-model="currentTransfer.label"
                                    type="text"
                                    class="form-control form-control--noborder input__field"
                                    :placeholder="$t('form.transfer_details.beneficiary_placeholder')"
                                    name="label" />
                </div>
              </div>
              <div class="form-group col col--sm-6">
                <label class="label input__label"
                       for="comment">{{ $t('form.personal_note.label') }}</label>
                <input id="comment"
                       v-model="currentTransfer.comment"
                       type="text"
                       class="form-control form-control--noborder input__field"
                       :placeholder="$t('form.personal_note.placeholder')"
                       name="comment">
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="row row--sm-bottom">
        <div class="col col--sm left">
          <div v-if="values.length > 1"
               class="btn-group">
            <button class="btn btn--outline"
                    type="button"
                    @click="getPreviousTransfer">
              <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
            </button>
            <button class="btn btn--outline"
                    type="button"
                    @click="getNextTransfer">
              <ic-arrow next
                        class="ic ic-arrow-next ic--24 ic--gray" />
            </button>
          </div>
        </div>
        <div class="col col--sm right">
          <div class="form-buttons">
            <button class="btn btn--gray"
                    type="button"
                    @click.prevent.stop="appStore.closeSidePanel()">
              {{ $t('button.cancel') }}
            </button>
            <button class="btn btn--primary"
                    :disabled="Object.keys(errors).length && formSubmitted">
              {{ $t('button.validate') }}
            </button>
          </div>
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import modulo from 'just-modulo'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatIban } from '@/helpers/utils/iban'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import NumberInput from '@/components/NumberInput.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow,
    ComponentDatepicker,
    ComponentAlert,
    ComponentSidepanelFooter,
    NumberInput,
    PhoneInput,
    FieldsetTitle
  },

  props: {
    initialIndex: {
      type: Number,
      required: true
    },

    initialValues: {
      type: Array,
      required: true
    },

    setEmail: {
      type: Boolean,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    const { account } = storeToRefs(accountStore)

    return { appStore, beneficiaryStore, account }
  },

  data () {
    return {
      index: 0,
      values: cloneDeep(this.initialValues.filter(value => value.beneficiary.id !== null)),
      disabled: {
        to: new Date()
      },

      formSubmitted: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        email: this.currentTransfer.send_notification ? yup.string().validEmail().required() : yup.string().validEmail().nullable(),
        phone_number: yup.string().validPhoneNumber().nullable(),
        amount: yup.number().min(0.01).required(),
        execution_date: yup.date().required(),
        label: yup.string().required()
      })
    },

    filteredInitialValues () {
      return this.initialValues.filter(value => value.beneficiary.id !== null)
    },

    currentTransfer () {
      return this.values[this.index].transfer
    },

    currentBeneficiary () {
      return this.values[this.index].beneficiary
    },

    currentBeneficiaryLabel () {
      const iban = this.currentBeneficiary.account_number_formatted || formatIban(this.currentBeneficiary.iban)
      return `${this.currentBeneficiary.label} - ${iban}`
    },

    totalAmount () {
      let totalAmount = 0
      for (let i = 0; i < this.values.length; i++) {
        if (!isNaN(parseFloat(this.values[i].transfer.amount))) {
          totalAmount += parseFloat(this.values[i].transfer.amount)
        }
      }
      return totalAmount
    }
  },

  mounted () {
    this.index = this.values.indexOf(this.values.find(value => value.id === this.initialIndex))
    this.$refs.form.validate()
  },

  methods: {
    getCurrencySymbol,

    async getPreviousTransfer () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.index = modulo(this.index - 1, this.values.length)
      }
      this.formSubmitted = !valid
    },

    async getNextTransfer () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.index = modulo(this.index + 1, this.values.length)
      }
      this.formSubmitted = !valid
    },

    onSubmit () {
      this.formSubmitted = true

      for (let i = 0; i < this.values.length; i++) {
        if (
          this.filteredInitialValues[i].beneficiary.email !== this.values[i].beneficiary.email ||
              this.filteredInitialValues[i].beneficiary.phone_number !== this.values[i].beneficiary.phone_number
        ) {
          this.beneficiaryStore.updateBeneficiary(this.values[i].beneficiary)
        }
      }
      this.values.forEach(value => {
        value.transfer.amount = parseFloat(value.transfer.amount)
      })
      this.$bus.emit('on-edit-transfers-success', this.values)
      this.appStore.closeSidePanel()
    }
  }
}
</script>
