<template>
  <div class="toast"
       data-cy="toast">
    <component :is="icon"
               class="toast-icon ic ic--18" />
    <div class="toast__content"
         data-cy="toast.content">
      <p v-if="title"
         class="toast__title">
        {{ title }}
      </p>
      <p v-if="message"
         class="toast__message">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TYPE } from 'vue-toastification'

// COMPONENTS
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'

// PROPS
const props = defineProps({
  type: {
    type: String,
    required: true
  },

  title: {
    type: String,
    required: true
  },

  message: {
    type: String,
    default: ''
  }
})

// COMPUTED
const icon = computed(() => {
  switch (props.type) {
    case TYPE.SUCCESS:
      return IcCheckCircle
    case TYPE.INFO:
      return IcInfo
    case TYPE.ERROR:
      return IcError
    case TYPE.WARNING:
      return IcError
    default:
      return ''
  }
})
</script>

<style lang="stylus">
.toast
  display flex
  position relative
  padding-right 3rem

  .ic
    flex-shrink 0
    margin-top 2px
    margin-right 1.4rem

  &__content
    font-size 1.4rem
    user-select none

  &__title
  &__message
    margin-bottom 0

  &__title
    font-weight 500

  &-close
    position absolute
    right 0
    top 0

.toast-centered
  .ic
    display block
    margin-top auto
    margin-bottom auto

  .Vue-Toastification__close-button
    top 50%
    transform translateY(-50%)

.Vue-Toastification
  &__toast--default
    & ^[0]__progress-bar
      background-color rgba($colorInfo, 0.7)
    .toast-icon
      fill $colorInfo

  &__toast--info
    & ^[0]__progress-bar
      background-color rgba($colorInfo, 0.7)
    .toast-icon
      fill $colorInfo

  &__toast--success
    & ^[0]__progress-bar
      background-color rgba($colorSuccess, 0.7)
    .toast-icon
      fill $colorSuccess

  &__toast--error
    & ^[0]__progress-bar
      background-color rgba($colorError, 0.7)
    .toast-icon
      fill $colorError

  &__toast--warning
    & ^[0]__progress-bar
      background-color rgba($colorWarning, 0.7)
    .toast-icon
      fill $colorWarning

.toast-animation
  &-enter-active
    animation-duration 750ms
    animation-fill-mode both
    &.top-left, &.bottom-left
      animation-name slideInLeft

    &.top-right, &.bottom-right
      animation-name slideInRight

    &.top-center
      animation-name slideInTop

    &.bottom-center
      animation-name slideInBottom

  &-leave-active
    animation-duration 750ms
    animation-fill-mode both
    animation-name fadeOut

  &-move
    transition-timing-function ease-in-out
    transition-property all
    transition-duration 400ms

/* Animations */
@keyframes slideInLeft
  0%
    transform translateX(-1000px)
    transform-origin 100% 50%
    opacity 0
  100%
    transform translateX(0)
    transform-origin 50% 50%
    opacity 1

@keyframes slideInTop
  0%
    transform translateY(-1000px)
    transform-origin 50% 0%
    opacity 0
  100%
    transform translateY(0)
    transform-origin 50% 50%
    opacity 1

@keyframes slideInRight
  0%
    transform translateX(1000px)
    transform-origin 0% 50%
    opacity 0
  100%
    transform translateX(0)
    transform-origin 50% 50%
    opacity 1

@keyframes slideInBottom
  0%
    transform translateY(1000px)
    transform-origin 50% 100%
    opacity 0
  100%
    transform translateY(0)
    transform-origin 50% 50%
    opacity 1

@keyframes fadeOut
  0%
    opacity 1
  100%
    opacity 0
</style>
