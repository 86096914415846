<template>
  <svg viewBox="0 0 24 24">
    <path d="m5.777 18.268 2.072-.453-1.609-1.608zm1.784-4.227-.588.715 2.261 2.25c.408-.255.66-.43.735-.505l6.276-6.288-2.426-2.416zm11.013-6.157c.126-.12.188-.276.188-.484 0-.22-.064-.385-.192-.514l-1.413-1.412a.702.702 0 0 0-.514-.23.636.636 0 0 0-.493.221l-.008.009-1.214 1.214 2.427 2.437zm-.3-3.527.006.006 1.41 1.411c.421.468.635 1.014.635 1.626 0 .612-.215 1.155-.645 1.624l-8.575 8.562c-.3.3-1.122.83-2.575 1.65l-4.86 1.099.1-.435.994-4.353c.03-.314.264-.762.701-1.386.392-.546.723-.95.992-1.21l8.575-8.588c.452-.452.994-.682 1.611-.682.607 0 1.155.228 1.63.676z" />
  </svg>
</template>

<script>
export default {
}
</script>
