<template>
  <div data-cy="account.create.term.amount-form">
    <div v-show="!loading">
      <div class="form__fieldset__group">
        <div class="form-group">
          <label class="label input__label">
            {{ $t('form.account.label') }}
          </label>
          <dropdown-account :account="account"
                            :has-default="!useInitialAccount"
                            :params="{
                              company_id: account.company.id,
                              type: [EAccountType.Main, EAccountType.Sub]
                            }"
                            show-account-balance
                            :switch-account-on-select="false"
                            @select="updateAccount" />
        </div>
      </div>
      <component-alert v-if="isAmountTooLow"
                       data-cy="account.create.term.amount-form.alert"
                       class="mt-3"
                       type="warning"
                       :message="$t('create_term_account.steps.amount.warning')" />
      <div v-show="!isAmountTooLow"
           class="form__fieldset__group mt-3">
        <div class="form-group"
             :class="{ error: errors.amount }">
          <label class="label input__label">
            {{ $t('form.amount.label') }}
          </label>
          <div class="input-group">
            <number-input :model-value="amount"
                          class="form-control form-control--noborder"
                          name="amount"
                          @update:model-value="updateAmount" />
            <span class="input-group__addon">
              {{ getCurrencySymbol(account.currency) }}
            </span>
          </div>
          <component-info class="mt-2"
                          :danger="!!errors.amount"
                          size="sm">
            {{ $t('create_term_account.steps.amount.warning_info') }}
          </component-info>
        </div>
      </div>
    </div>
    <div v-if="loading"
         class="d-flex align-items-center justify-content-center">
      <core-loader />
    </div>
  </div>
</template>

<script>
import { CoreLoader } from '@common/core-ui'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { EAccountType } from '@/types/account.d'

import ComponentAlert from '@/components/Alert.vue'
import DropdownAccount from '@/components/DropdownAccount.vue'
import ComponentInfo from '@/components/Info.vue'
import NumberInput from '@/components/NumberInput.vue'

export default {
  components: {
    ComponentAlert,
    ComponentInfo,
    CoreLoader,
    DropdownAccount,
    NumberInput
  },

  props: {
    account: {
      type: Object,
      required: true
    },

    amount: {
      type: Number,
      required: true
    },

    errors: {
      type: Object,
      default: null
    },

    isAmountTooLow: {
      type: Boolean,
      required: true
    },

    useInitialAccount: {
      type: Boolean,
      required: true
    }
  },

  emits: [
    'update:account',
    'update:amount'
  ],

  setup () {
    const accountStore = useAccountStore()

    return {
      accountStore
    }
  },

  data () {
    return {
      loading: !this.useInitialAccount
    }
  },

  computed: {
    EAccountType () {
      return EAccountType
    }
  },

  methods: {
    getCurrencySymbol,
    updateAccount (account) {
      this.$emit('update:account', account)
      this.loading = false
    },

    updateAmount (value) {
      this.$emit('update:amount', value)
    }
  }
}
</script>
