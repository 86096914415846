<template>
  <div class="placeholder-action-description">
    <component-placeholder :label="placeholderContent.title"
                           :content="placeholderContent.subtitle">
      <file-sheet-import />
    </component-placeholder>
    <div class="center">
      <component-button wrapper-class="btn btn--default placeholder-btn"
                        :label="$t('button.import_excel')"
                        @click="$emit('on-attach-file')" />
    </div>
  </div>
</template>

<script>
import ComponentButton from '@/components/Button.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import FileSheetImport from '@/components/svg/img/file-sheet-import.vue'

export default {
  name: 'ComponentStepsPlaceholder',

  components: {
    FileSheetImport,
    ComponentButton,
    ComponentPlaceholder
  },

  inheritAttrs: false,

  props: {
    placeholderContent: {
      type: Object,
      required: true
    }
  },

  emits: ['on-attach-file']
}
</script>
