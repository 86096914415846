<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t("title.my_rib") }}
        </h1>
      </div>
      <div v-if="validAccountsList.length"
           class="section__heading__right">
        <div class="section-actions">
          <form class="form-inline">
            <div class="form-group">
              <component-dropdown label="accounts"
                                  :dropdown-width="24"
                                  :search="true"
                                  async-url="/accounts"
                                  :async-url-params="{ access: 'owner', status: 'valid', rib: true }"
                                  :label-filter="getAccountLabel"
                                  :model="account"
                                  @select="onSwitchAccount">
                <template #trigger>
                  <div class="select select-account">
                    <div class="form-control">
                      {{ getAccountLabel(account) }}
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </div>
            <button class="btn btn--default"
                    @click.prevent.stop="onSendEmail">
              {{ $t("button.email_send") }}
            </button>
            <a href="#"
               class="btn btn--primary"
               @click.prevent.stop="downloadFileFromUrl(account.rib.pdf_url)">{{ $t("button.download") }}</a>
          </form>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <div v-else-if="account"
           class="rib-iban-document">
        <div class="rib-iban-document__content">
          <h1>{{ $t("label.account_details.title") }}</h1>
          <logo-bank-icon class="logo" />
          <div class="block-address">
            <p>{{ account.company.name }}</p>
            <p>{{ account.company.address.street }}</p>
            <p v-if="account.company.address.details">
              {{ account.company.address.details }}
            </p>
            <p>{{ account.company.address.zip }} {{ account.company.address.city }}</p>
          </div>
          <div class="block-iban">
            <small>IBAN</small>
            <div v-for="(value, index) in getFormattedIban"
                 :key="index"
                 class="block-iban__item">
              <strong>{{ value }}</strong>
            </div>
          </div>
          <div class="block-bic">
            <small>{{ $t("label.account_details.bic") }}</small>
            <strong>{{ account.rib.bic }}</strong>
          </div>
          <div class="block-rib">
            <div class="block-rib__item">
              <small>{{ $t("label.account_details.bank") }}</small>
              <strong>{{ account.rib.bank }}</strong>
            </div>
            <div class="block-rib__item">
              <small>{{ $t("label.account_details.branch") }}</small>
              <strong>{{ account.rib.branch }}</strong>
            </div>
            <div class="block-rib__item">
              <small>{{ $t("label.account_details.number") }}</small>
              <strong>{{ account.rib.number }}</strong>
            </div>
            <div class="block-rib__item">
              <small>{{ $t("label.account_details.checksum") }}</small>
              <strong>{{ account.rib.checksum }}</strong>
            </div>
            <div class="block-rib__item">
              <small>{{ $t("label.account_details.establishment") }}</small>
              <strong>{{ account.rib.establishment }}</strong>
            </div>
          </div>
        </div>
      </div>
      <component-placeholder v-else
                             :label="$t('placeholder.no_rib.title')"
                             :content="''" />
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import ComponentDropdown from '@/components/Dropdown.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import SendEmail from '@/components/modals/SendEmail.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import LogoBankIcon from '@/components/svg/img/logo-bank-icon.vue'

export default {
  components: {
    LogoBankIcon,
    IcArrow,
    ComponentDropdown,
    ComponentPlaceholder,
    LoaderSpinner
  },

  setup () {
    const appStore = useAppStore()
    const accountStore = useAccountStore()

    const { account, accounts } = storeToRefs(accountStore)

    return { appStore, account, accounts, accountStore }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    getFormattedIban () {
      return this.account.rib.iban ? this.account.rib.iban.match(/.{1,4}/g) ?? [] : []
    },

    validAccountsList () {
      return this.accounts.filter(account => account.status === 'valid')
    }
  },

  async created () {
    this.loading = true
    const success = await this.accountStore.getAccounts({ status_group: 'valid' })
    if (success) {
      this.selectValidAccount()
    }
    this.loading = false
  },

  mounted () {
    this.$bus.on('account-switched', this.selectValidAccount)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.selectValidAccount)
  },

  methods: {
    downloadFileFromUrl,

    onSwitchAccount (account) {
      this.accountStore.switchAccount(account.uuid)
    },

    selectValidAccount () {
      if (this.account.status !== 'valid') {
        this.$router.push({ name: 'account' })
      }
    },

    getAccountLabel (account) {
      return account.label ? `${account.company.name} - ${account.label}` : account.company.name
    },

    onSendEmail () {
      this.appStore.showModal(SendEmail, { callback: this.accountStore.sendRibByEmail }, { wrapperClass: 'modal--xs' })
    }
  }
}
</script>
