<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="cards.length">
      <div v-if="isCardListDisplay"
           class="cards-list-container">
        <div class="cards-list__header cards-list__header--bordered">
          <div class="col--sm-3">
            {{ $t('label.card.holder') }}
          </div>
          <div :class="[physicalCard ? 'col--sm-4' : 'col--sm-2']">
            {{ $t('table.card_number') }}
          </div>
          <template v-if="physicalCard">
            <div class="col--sm">
              <template v-if="!isExpired">
                {{ $t('table.month_consumption') }}
              </template>
            </div>
          </template>
          <template v-else>
            <div class="col--sm-2 center">
              {{ $t('table.type') }}
            </div>
            <div class="col--sm-1" />
            <div v-if="!isToValidate"
                 class="col--sm-3">
              {{ $t(`table.${isExpired ? 'total_consumed' : 'month_consumption'}`) }}
            </div>
          </template>
        </div>
        <div class="table">
          <template v-if="isToValidate">
            <card-rows :label="$t('label.card.to_validate')"
                       :cards="toValidateCards" />
            <card-rows :label="$t('label.card.all_cards')"
                       :cards="pendingCards" />
          </template>
          <template v-else>
            <card-rows :label="$t('label.card.my_cards')"
                       :cards="myCards" />
            <card-rows :label="$t('label.card.employee_cards')"
                       :cards="usersCards" />
          </template>
        </div>
      </div>
      <div v-else
           data-cy="cards.list"
           class="cards-list">
        <card-item v-for="card in cards"
                   :key="card.uuid"
                   :card="card"
                   :data-cy="`cards.list.item-${card.id}`"
                   @validate-card="onValidateCard" />
      </div>
      <component-pagination :pagination="cardsPagination" />
    </template>
    <component-placeholder v-else
                           :label="getPlaceholderLabel"
                           :content="getPlaceholderContent">
      <image-card :virtual-card="!physicalCard" />
    </component-placeholder>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useCardStore } from '@/stores/card'

import ValidateCardRequest from '@/pages/cards/sidepanel/ValidateCardRequest.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageCard from '@/components/svg/img/card.vue'

import CardItem from './CardItem.vue'
import CardRows from './CardRows.vue'

export default {
  components: {
    CardItem,
    CardRows,
    ComponentPlaceholder,
    ComponentPagination,
    LoaderSpinner,
    ImageCard
  },

  props: {
    physicalCard: {
      type: Boolean,
      required: true
    },

    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const cardStore = useCardStore()
    const accountStore = useAccountStore()

    const { user, isCardListDisplay } = storeToRefs(authStore)
    const { cards, cardsPagination } = storeToRefs(cardStore)
    const { account } = storeToRefs(accountStore)

    return { appStore, user, isCardListDisplay, cards, cardsPagination, cardStore, account }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    myCards () {
      return this.cards.filter(i => i.user?.uuid === this.user.uuid)
    },

    usersCards () {
      return this.cards.filter(i => i.user?.uuid !== this.user.uuid)
    },

    toValidateCards () {
      return this.cards.filter(i => this.hasPermission(this.$permissions.cardsVirtualValidate, i))
    },

    pendingCards () {
      return this.cards.filter(i => i.status === 'pending' || i.status === 'to_validate_by_bank')
    },

    getPlaceholderLabel () {
      return this.physicalCard
        ? this.$i18n.t(`placeholder.no_card${this.isExpired ? '_history' : ''}.title`)
        : this.$i18n.t(`placeholder.no_ecard${this.isExpired ? '_history' : ''}.title`)
    },

    getPlaceholderContent () {
      if (this.hasFilter) {
        return this.$i18n.t('placeholder.no_filters.subtitle')
      }

      if (this.physicalCard) {
        return this.hasPermission(this.readPermission) ? this.$i18n.t(`placeholder.no_card${this.isExpired ? '_history' : ''}.subtitle`) : ''
      }

      if (this.hasPermission(this.$permissions.cardsVirtualRequest)) {
        return this.$i18n.t('placeholder.no_ecard_request.subtitle')
      }

      return this.hasPermission(this.readPermission) ? this.$i18n.t(`placeholder.no_ecard${this.isExpired ? '_history' : ''}.subtitle`) : ''
    },

    isToValidate () {
      return this.state === 'to_validate'
    },

    isExpired () {
      return this.state === 'history'
    },

    hasFilter () {
      return !!this.filter.search || !!this.filter.expiration_date
    },

    readPermission () {
      return this.physicalCard ? this.$permissions.cardsPhysicalRead : this.$permissions.cardsVirtualRead
    }
  },

  watch: {
    physicalCard () {
      this.resetData()
    },

    state () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'cardsPagination.totalItems' () {
      this.$emit('pagination-total-items', this.cardsPagination.totalItems)
    }
  },

  created () {
    this.initData()

    if (this.physicalCard) {
      this.cardStore.getNumberOfPendingPhysicalCards()
    } else {
      this.cardStore.getNumberOfPendingVirtualCards()
    }
  },

  mounted () {
    if (this.$route.query.cardRequestUuid) {
      this.onValidateCard(this.$route.query.cardRequestUuid)
    }
    this.$bus.on('card-activate-success', this.onActivateCardSuccess)
    this.$bus.on('cards-lock-success', this.onCardsLockSuccess)
    this.$bus.on('cards-unlock-success', this.onCardsUnlockSuccess)
    this.$bus.on('refresh-cards-list', this.refreshCardsList)
  },

  beforeUnmount () {
    this.$bus.off('card-activate-success', this.onActivateCardSuccess)
    this.$bus.off('cards-lock-success', this.onCardsLockSuccess)
    this.$bus.off('cards-unlock-success', this.onCardsUnlockSuccess)
    this.$bus.off('refresh-cards-list', this.refreshCardsList)
  },

  methods: {
    async initData () {
      this.loading = true
      await this.cardStore.getCards(this.state, this.page, this.physicalCard, this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    async refreshCardsList () {
      if (this.physicalCard) {
        await this.cardStore.getNumberOfPendingPhysicalCards()
        if (!this.account.counts.physical_cards.waiting_validation) {
          return this.$router.push({ name: 'cards-physical' })
        }
      } else {
        await this.cardStore.getNumberOfPendingVirtualCards()
        if (!this.account.counts.virtual_cards.waiting_validation) {
          return this.$router.push({ name: 'cards-virtual' })
        }
      }
      return this.initData()
    },

    onCardsLockSuccess (message) {
      showToastSuccess(message)
      this.initData()
    },

    onCardsUnlockSuccess (message) {
      showToastSuccess(message)
      this.initData()
    },

    onActivateCardSuccess (data) {
      showToastSuccess(this.$t('message.success.card.activate'))
      this.cardStore.setCards(this.cards.map(card => (card.uuid === data.uuid) ? data : card))
    },

    onValidateCard (uuid) {
      this.appStore.showSidePanel(ValidateCardRequest, { uuid }, { wrapperClass: 'sidepanel--md sidepanel-card-request', backgroundOverlay: true })
    }
  }
}
</script>
