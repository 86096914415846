<template>
  <div class="minimized-block"
       :class="{ 'minimized-block--expanded': expanded }">
    <slot :prop-array="propArray" />
    <button @click="expandBlock">
      <span>{{ displayMoreText }}</span>
      <ic-chevron down />
    </button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import IcChevron from '@/components/svg/icons/ic-chevron.vue'

export default {
  components: {
    IcChevron
  },

  props: {
    arrayData: {
      type: Array,
      required: true
    },

    displayMoreText: {
      type: String,
      default: function () {
        const { t } = useI18n()
        return t('button.see_more')
      }
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    propArray () {
      return this.expanded ? this.arrayData : this.arrayData.slice(0, 6)
    }
  },

  methods: {
    expandBlock () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="stylus" scoped>
.minimized-block
  position relative

  &:before
    content ''
    position absolute
    left 0
    right 0
    bottom 0
    height 14rem
    background linear-gradient(0deg, #ffffff 0%, transparent 100%);
    z-index 1

  > *:first-child
    max-height 100%

  > button
    display flex
    align-items center
    position absolute
    bottom 3rem
    left 50%
    transform translateX(-50%)
    background-color rgba($colorDarkGray, 0.9)
    color $colorWhite
    height 3.5rem
    border-radius (@height/2)
    padding-left 2rem
    padding-right @padding-left - 0.4rem
    font-size 1.2rem
    font-weight 500
    z-index 2
    transition background-color 0.2s ease-in-out

    &:hover
      background-color rgba(#292D35, 1)

    > svg
      height 16px
      width 16px
      fill #ffffff
      margin-left 4px

  &--expanded
    &:before, > button
      display none
</style>
