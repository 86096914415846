<template>
  <modal-dialog-placeholder @close="$emit('close')">
    <template #header-extra>
      <div class="left">
        <core-badge :theme="ECoreBadgeTheme.Primary"
                    :fill="ECoreBadgeFill.Shaded"
                    :value="$t('general.new')" />
      </div>
    </template>
    <component-placeholder :button="$t('advance_payment.modal.see_video')"
                           :content="$t('advance_payment.modal.content')"
                           :label="$t('advance_payment.title')"
                           @btn-click="openLink"
                           @close="$emit('close')">
      <file-payslips />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'

import links from '@/config/links'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import FilePayslips from '@/components/svg/img/file-payslips.vue'

export default {
  components: {
    CoreBadge,
    ComponentPlaceholder,
    FilePayslips,
    ModalDialogPlaceholder
  },

  emits: ['close'],

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    }
  },

  methods: {
    openLink () {
      return window.open(links.SUPPORT.ADVANCE_PAYMENT)
    }
  }
}
</script>
