<template>
  <section v-if="account"
           class="section"
           data-cy="account-overview.section">
    <section-header :title="t('general.term_account')">
      <template #actions>
        <core-button v-if="hasFeature(EFeature.AccountOverviewContract)"
                     :text="$t('account_overview.actions.download_contract')"
                     :prepend-icon="ECoreIconList.Download"
                     :theme="ECoreButtonTheme.Transparent"
                     @click="downloadContract" />
        <core-button v-if="hasPermission(permissions.accountWithdrawal)"
                     data-cy="account-overview.withdraw"
                     :text="$t('account_overview.actions.withdraw')"
                     :appearance="ECoreButtonAppearance.Outlined"
                     :theme="ECoreButtonTheme.Transparent"
                     @click="withdraw" />
      </template>
    </section-header>
    <component-alert v-if="alertMessage"
                     type="info"
                     :message="alertMessage" />
    <core-block-summary :items="accountSummary"
                        class="mb-3" />
    <progression-gauge v-if="isAccountValid"
                       :start-date="account.detail.open_at"
                       :end-date="account.detail.due_date" />
    <account-details :details="accountDetailsList"
                     class="mt-2" />
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreBlockSummary, CoreButton, ECoreButtonAppearance, ECoreButtonTheme, ECoreIconList } from '@common/core-ui'

import { EFeature, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount, formatPercentage } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { isTermAccount } from '@/types/account.d'

import EarlyWithdraw from '@/pages/account-overview/modal/EarlyWithdraw.vue'

import ComponentAlert from '@/components/Alert.vue'
import SectionHeader from '@/components/SectionHeader.vue'

import AccountDetails from './components/AccountDetails.vue'
import ProgressionGauge from './components/ProgressionGauge.vue'

// DATA

const { t } = useI18n()
const accountStore = useAccountStore()
const appStore = useAppStore()

// COMPUTED

const account = computed(() => {
  return accountStore.account && isTermAccount(accountStore.account)
    ? accountStore.account
    : null
})

const accountDetailsList = computed<Array<{ label: string, value: string }>>(() => {
  return [
    {
      label: t('account_overview.details.open_at'),
      value: formatDateText(account.value?.detail.open_at),
      visible: true
    },
    {
      label: t('account_overview.details.account_number'),
      value: account.value?.rib?.number,
      visible: true
    },
    {
      label: t('account_overview.details.due_date'),
      value: formatDateText(account.value?.detail.due_date),
      visible: true
    },
    {
      label: t('create_term_account.steps.tax_type.label'),
      value: t(`create_term_account.steps.tax_type.${account.value?.company.tax_type}.subtitle`),
      visible: hasFeature(EFeature.AccountOverviewTaxType)
    },
    {
      label: t('label.anniversary_date'),
      value: formatDateText(account.value?.detail.anniversary_date),
      visible: hasFeature(EFeature.AccountOverviewAnniversaryDate)
    },
    {
      label: t('account_overview.details.closed_at'),
      value: formatDateText(account.value?.closed_at),
      visible: account.value?.status === 'closed'
    },
    {
      info: account.value?.withdrawal_request_at ? t('account_overview.details.paid_up.info') : '',
      label: t('account_overview.details.paid_up.label'),
      value: formatAmount((account.value?.detail.initial_deposit || 0) + (account.value?.detail.interest_earned || 0)),
      visible: account.value?.status === 'closed'
    }
  ].filter(detail => detail.visible)
})

const accountSummary = computed(() => {
  return [
    {
      subtitle: t('create_term_account.steps.amount.title'),
      title: formatAmount(account.value?.detail.initial_deposit),
      visible: true
    },
    {
      subtitle: t('create_term_account.steps.summary.duration'),
      title: t('general.months', { n: account.value?.detail.term_account_rate.duration }),
      visible: true
    },
    {
      subtitle: t('create_term_account.steps.summary.rate'),
      title: formatPercentage(account.value?.detail.term_account_rate.client_rate),
      visible: true
    },
    {
      subtitle: t('account_overview.details.estimated_interest_earned'),
      title: formatAmount(account.value?.detail.estimated_interest_earned),
      visible: !account.value?.withdrawal_request_at && account.value?.status !== 'closed' && hasFeature(EFeature.AccountOverviewEstimatedInterestEarned)
    }
  ].filter(summary => summary.visible)
})

const isAccountValid = computed(() => {
  return account.value?.status === 'valid'
})

const alertMessage = computed(() => {
  if (account.value?.status === 'closing' || account.value?.status === 'closed') {
    return t(`account_overview.alert.${account.value?.status}.${account.value?.withdrawal_request_at ? 'withdrawal' : 'no_withdrawal'}`, { n: account.value?.closed_at ? formatDateText(account.value?.closed_at) : formatDateText(account.value?.withdrawal_request_at) })
  }

  return ''
})

// METHODS

function downloadContract () {
  downloadFileFromUrl(account.value?.detail.contract_url)
}

function withdraw () {
  appStore.showModal(EarlyWithdraw, {}, { wrapperClass: 'modal--xs' })
}
</script>
