<template>
  <router-link :to="to"
               class="block-options__item">
    <div class="icon">
      <slot />
    </div>
    <div class="option-text">
      <h4>{{ title }}</h4>
      <p>{{ content }}</p>
    </div>
    <ic-chevron v-if="!hideChevron"
                circle
                right
                shrink
                class="ic ic-chevron-circle ic--primary ic--28" />
    <core-badge v-if="badgeValue"
                class="block-options__item__badge"
                :value="badgeValue"
                :theme="ECoreBadgeTheme.Primary"
                fill="shaded" />
  </router-link>
</template>

<script>
import { CoreBadge, ECoreBadgeTheme } from '@common/core-ui'

import IcChevron from '@/components/svg/icons/ic-chevron.vue'

export default {
  components: {
    CoreBadge,
    IcChevron
  },

  props: {
    badgeValue: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    },

    to: {
      type: Object,
      required: true
    },

    hideChevron: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    }
  }
}
</script>
