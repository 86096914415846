<template>
  <router-link v-if="!loading"
               class="dashboard__block"
               :class="[`dashboard__block--${color}`, { 'dashboard__block--small': small }]"
               :to="{ name: 'inbox' }">
    <h2 class="number-label">
      {{ dashboard.message_count }}
    </h2>
    <span class="sub-label">
      {{ $tc("label.new_message", dashboard.message_count) }}
    </span>
  </router-link>
  <dashboard-loading v-else
                     :color="color" />
</template>

<script>
import DashboardLoading from '@/pages/dashboard/components/DashboardLoading.vue'

export default {
  components: {
    DashboardLoading
  },

  props: {
    color: {
      type: String,
      required: false,
      default: 'white'
    },

    dashboard: {
      type: Object,
      required: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.number-label
  color $primaryColor

.dashboard__block--small .number-label
  transform translateY(-1.5rem)
</style>
