<template>
  <svg viewBox="0 0 16 16">
    <path v-if="optional "
          d="M13 10a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5zM12 0c1.16 0 2.1.94 2.1 2.1v7.053C13.75 9.053 13.38 9 13 9l-.101.002L12.9 2.1a.9.9 0 0 0-.787-.893L12 1.2H4a.9.9 0 0 0-.9.9v10.5a.9.9 0 0 0 .9.9h5.031c.053.425.172.828.347 1.2H4a2.1 2.1 0 0 1-2.1-2.1V2.1C1.9.94 2.84 0 4 0zm-2 6.5v1.2H5V6.5zM9 4v1.2H5V4z" />
    <path v-else-if="danger "
          d="M8.967 1.266c.226.133.42.312.572.524l.086.131 6.109 10.23a1.882 1.882 0 0 1-1.456 2.841l-.17.008H1.891a1.888 1.888 0 0 1-1.694-2.722l.07-.128L6.374 1.921a1.895 1.895 0 0 1 2.592-.655zM7.445 2.498l-.048.07-6.109 10.23a.618.618 0 0 0-.088.317c0 .323.257.603.603.644l.088.006h12.217c.13 0 .258-.035.368-.1a.629.629 0 0 0 .274-.792l-.039-.076L8.603 2.568a.672.672 0 0 0-.235-.232.719.719 0 0 0-.923.162zm.575 7.975c.522 0 .9.396.9.938 0 .538-.379.932-.9.932-.522 0-.906-.395-.906-.932 0-.541.383-.938.906-.938zm.706-5.355L8.633 9.74H7.397l-.094-4.622z" />
    <path v-else-if="invalid "
          d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 1.23a6.77 6.77 0 1 0 0 13.54A6.77 6.77 0 0 0 8 1.23zM8.02 10c.633 0 1.1.474 1.1 1.11 0 .634-.469 1.107-1.1 1.107a1.08 1.08 0 0 1-1.106-1.106c0-.636.472-1.111 1.106-1.111zm.91-6.4-.1 5.3H7.2l-.1-5.3z" />
    <path v-else-if="document "
          d="M12 .5c1.16 0 2.1.94 2.1 2.1v10.5a2.1 2.1 0 0 1-2.1 2.1H4a2.1 2.1 0 0 1-2.1-2.1V2.6C1.9 1.44 2.84.5 4 .5zm0 1.2H4a.9.9 0 0 0-.9.9v10.5a.9.9 0 0 0 .9.9h8a.9.9 0 0 0 .9-.9V2.6a.9.9 0 0 0-.9-.9zM8 9.5v1.2H5V9.5zM10 7v1.2H5V7zM9 4.5v1.2H5V4.5z" />
    <path v-else
          d="M13.033 1.675c1.527 1.49 1.605 3.849.212 5.362l-.144.148-3.747 3.659c-.894.873-2.362.855-3.278-.04-.877-.855-.931-2.203-.151-3.082l.111-.117L9.62 4.106a.656.656 0 0 1 .911.01c.23.225.257.57.078.814l-.067.077L6.96 8.505a.988.988 0 0 0 .017 1.42c.378.37.966.402 1.366.095l.09-.077 3.747-3.658c1.042-1.018 1.021-2.688-.047-3.731-1.025-1.001-2.643-1.06-3.693-.162l-.128.117-4.917 4.8c-1.543 1.506-1.463 4.041.197 5.661 1.608 1.57 4.096 1.693 5.653.33l.147-.137 4.503-4.396a.656.656 0 0 1 .912.01c.229.225.256.57.078.813l-.067.078-4.503 4.395c-2.049 2-5.462 1.897-7.623-.213C.586 11.795.43 8.582 2.315 6.568l.157-.16 4.916-4.8c1.54-1.503 4.067-1.473 5.645.067z" />
  </svg>
</template>

<script>
export default {
  props: {
    optional: Boolean,
    danger: Boolean,
    invalid: Boolean,
    document: Boolean
  }
}
</script>
