<template>
  <component-popover trigger="hover"
                     popover-class="popover--pull-right popover--top"
                     :arrow="false">
    <template #trigger>
      <a class="footer-lang-button">{{ getLanguageName($i18n.locale) }} <ic-chevron down
                                                                                    class="ic ic--14 ic--down" /></a>
    </template>
    <div v-for="(language, index) in translatedLanguages"
         :key="index"
         class="popover__item"
         :class="{ selected: language === $i18n.locale }"
         @click="changeLanguage(language)">
      <span class="flag">
        <img class="flag__img"
             :src="getFlagSrc(language)">
        {{ getLanguageName(language) }}
      </span>
    </div>
  </component-popover>
</template>

<script>
import { getFlagSrc, getLanguageName } from '@/helpers/utils/intl'
import i18n, { translatedLanguages } from '@/locales'

import ComponentPopover from '@/components/Popover.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'

export default {
  name: 'LanguagePopover',

  components: {
    IcChevron,
    ComponentPopover
  },

  data () {
    return {
      translatedLanguages
    }
  },

  methods: {
    getFlagSrc,
    getLanguageName,

    changeLanguage (value) {
      if (this.$i18n.locale !== value) {
        i18n.global.locale = value
        this.$bus.emit('language-changed')
      }
    }
  }
}
</script>
