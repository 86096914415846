<template>
  <div ref="dropdownCustom"
       class="dropdown-custom"
       :class="{ 'is-open': showDropdown }"
       :data-cy="`dropdown-${dataCy}`">
    <div :class="{ 'dropdown-disabled': disabled }"
         style="display: flex"
         @click="toggleDropdown">
      <slot id="slot"
            ref="slot"
            name="trigger"
            :show-dropdown="showDropdown" />
    </div>
    <div :id="id"
         ref="dropdown"
         class="dropdown-menu"
         :class="[`dropdown-menu--${dropdownPosition}`, dropdownMenuClass]"
         :style="getDropdownStyle">
      <template v-if="inDOM">
        <span v-if="canAddItem && !filter"
              class="btn btn--default dropdown-float-button"
              :class="{ 'dropdown-float-button--close': addItem }"
              @click.prevent.stop="toggleItem">
          <ic-plus class="ic ic-plus ic--white ic--20" />
        </span>
        <div v-if="search"
             class="dropdown-search">
          <ic-search class="ic ic--20 ic--gray" />
          <input v-model="filter"
                 class="input-search"
                 type="text"
                 :placeholder="$t('form.search.placeholder')">
        </div>
        <div id="list-items"
             class="dropdown-menu__items"
             :style="`max-height:${dropdownHeight}rem;`">
          <div v-if="filteredValues.length || addItem || (hasYouUser && !filter)">
            <div v-if="canAddItem && addItem"
                 class="dropdown-menu__item">
              <input ref="new-item"
                     v-model="newItem"
                     class="form-control"
                     type="text"
                     :placeholder="searchPlaceholder"
                     @keyup.enter="onAddItem">
            </div>
            <template v-if="hasMultipleLists">
              <template v-for="(list, i) in filteredValues"
                        :key="`li-${i}`">
                <li class="dropdown-menu__item dropdown-menu__group">
                  <strong class="text-dark">{{ list.label }}</strong>
                </li>
                <dropdown-items-list :label-filter="labelFilter"
                                     :data-cy="dataCy"
                                     :filtered-values="[...list.items]"
                                     :has-item-picture="hasItemPicture" />
              </template>
            </template>
            <template v-else>
              <template v-if="hasYouUser">
                <li class="dropdown-menu__item dropdown-menu__group">
                  <strong>{{ $t('general.you') }}</strong>
                </li>
                <dropdown-items-list :is-you-user="true"
                                     :label-filter="labelFilter"
                                     :filtered-values="[userItem]"
                                     :has-item-picture="hasItemPicture"
                                     :data-cy="dataCy" />
                <li v-if="filteredValues.length"
                    class="dropdown-menu__item dropdown-menu__group">
                  <strong>{{ $t('general.your_users') }}</strong>
                </li>
              </template>
              <dropdown-items-list :can-add-item="canAddItem"
                                   :has-icon="hasIcon"
                                   :label-filter="labelFilter"
                                   :filtered-values="filteredValues"
                                   :has-item-picture="hasItemPicture"
                                   :data-cy="dataCy">
                <template #prefix="item">
                  <slot name="prefix"
                        v-bind="item" />
                </template>
              </dropdown-items-list>
            </template>
          </div>
          <div v-else
               class="dropdown-menu__item center"
               style="pointer-events: none; cursor: default;">
            <div v-if="!loading"
                 class="dropdown-menu__item__label">
              {{ $t("general.no_result") }}
            </div>
            <template v-else>
              <loader-spinner size="24" />
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import axiosClient from '@/api'
import store from '@/config/store'
import { formatPercentage } from '@/helpers/utils/number'
import { textContainsFilter } from '@/helpers/utils/text'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'

import DropdownItemsList from './DropdownItemsList.vue'

export default {
  name: 'ComponentDropdown',

  components: {
    IcPlus,
    IcSearch,
    DropdownItemsList,
    LoaderSpinner
  },

  props: {
    id: {
      type: String,
      default: 'dropdown'
    },

    values: {
      type: [Array, Object],
      required: false,
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    model: {
      type: [Array, Boolean, String, Number, Object, null],
      required: false,
      default: 0
    },

    multiselect: {
      type: Boolean,
      required: false,
      default: false
    },

    name: {
      type: String,
      default: ''
    },

    labelType: {
      type: String,
      required: false,
      default: ''
    },

    hasDefault: {
      type: Boolean,
      default: false
    },

    bindValue: {
      type: Boolean,
      default: false
    },

    hasOther: {
      type: Boolean,
      default: false
    },

    search: {
      type: Boolean,
      default: false
    },

    canAddItem: {
      type: Boolean,
      default: false
    },

    dropdownWidth: {
      type: [Number, String],
      default: 24
    },

    dropdownHeight: {
      type: Number,
      default: 20
    },

    dropdownMarginTop: {
      type: Number,
      default: 0.5
    },

    dropdownPosition: {
      type: String,
      default: 'left'
    },

    hasIcon: {
      type: Boolean,
      default: false
    },

    asyncUrl: {
      type: String,
      default: ''
    },

    asyncUrlParams: {
      type: Object,
      default: () => ({})
    },

    canResetValue: {
      type: Boolean,
      default: true
    },

    searchPlaceholder: {
      type: String,
      default: function () {
        const { t } = useI18n()
        return t('example.prompt.default')
      }
    },

    labelFilter: {
      type: Function,
      required: false,
      default: item => {
        return item.label || item
      }
    },

    dropdownMenuClass: {
      type: String,
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    emiter: {
      type: String,
      required: false,
      default: ''
    },

    hasYouUser: {
      type: Boolean,
      required: false,
      default: false
    },

    listFilter: {
      type: Function,
      required: false,
      default: item => {
        return item
      }
    },

    hasItemPicture: {
      type: Boolean,
      required: false,
      default: false
    },

    asyncResultsFilter: {
      type: Function,
      default: () => true
    },

    dataCy: {
      type: String,
      default: 'default'
    },

    getDataOnMounted: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'delete-item',
    'new-item',
    'request-loaded',
    'reset-value',
    'select',
    'toggle-dropdown'
  ],

  setup () {
    const { t } = useI18n()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { modal } = storeToRefs(appStore)
    const { user, userFullName } = storeToRefs(authStore)

    const addItem = ref(false)
    const dropdownCustom = ref()
    const showDropdown = ref(false)
    const filter = ref('')
    const newItem = ref('')
    const preventReset = ref(false)
    const selectDefault = ref(false)
    const inDOM = ref(false)

    function reset () {
      filter.value = ''
      showDropdown.value = false
      addItem.value = false
      newItem.value = ''
      selectDefault.value = false
      setTimeout(() => {
        inDOM.value = false
      }, 200)
    }

    onClickOutside(dropdownCustom, () => {
      if (showDropdown.value && !preventReset.value) {
        reset()
      }

      if (preventReset.value) {
        preventReset.value = false
      }
    })

    return {
      apiStore,
      appStore,

      addItem,
      dropdownCustom,
      filter,
      inDOM,
      modal,
      newItem,
      preventReset,
      selectDefault,
      showDropdown,
      t,
      user,
      userFullName,

      reset,
      onClickOutside
    }
  },

  data () {
    return {
      store,
      selectedValues: [],
      loading: false,
      currentPage: 1,
      pageCount: 1,
      isLastPage: true,
      centerStyle: '',
      waitingEmitter: false,
      dropdownClicked: false,
      valuesForm: {}
    }
  },

  computed: {
    filteredValues () {
      const values = this.listFilter(this.valuesForm)
      if (this.asyncUrl) {
        return values
      }

      if (this.isValuesObject) {
        return Object.values(values)
      }

      if (this.filter) {
        let searchValues = values
        if (Array.isArray(values[0]?.items)) {
          searchValues = values.map(value => value.items).flat()
        }
        return searchValues.filter(value => textContainsFilter(this.labelFilter(value) || value.label, this.filter))
      }

      return values
    },

    hasMultipleLists () {
      return this.filteredValues.length && Object.prototype.hasOwnProperty.call(this.filteredValues[0], 'items') && Array.isArray(this.filteredValues[0].items)
    },

    isValuesObject () {
      return !Array.isArray(this.valuesForm)
    },

    getDropdownStyle () {
      const width = isNaN(this.dropdownWidth) ? this.dropdownWidth : `${this.dropdownWidth}rem`
      return `width: ${width}; margin-top: ${this.dropdownMarginTop}rem; ${this.centerStyle}`
    },

    userItem () {
      return {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        name: this.userFullName,
        gender: this.user.gender,
        email: this.user.email,
        phone: this.user.phone,
        birth_date: this.user.birth_date,
        picture: this.user.picture,
        owner: true
      }
    }
  },

  watch: {
    filter: function (value) {
      if (value) {
        this.addItem = false
        this.newItem = ''
      }
      if (this.asyncUrl) {
        this.getAsyncData()
      }
    },

    model: function () {
      this.selectedValues = this.model
    },

    showDropdown: function () {
      this.$emit('toggle-dropdown', this.showDropdown)
    },

    values: {
      handler (values) {
        this.valuesForm = cloneDeep(values)
      },

      deep: true
    },

    inDOM: function () {
      setTimeout(() => {
        if ((this.$refs.dropdown?.getBoundingClientRect().bottom > (window.innerHeight || document.documentElement.clientHeight)) && (this.$refs.dropdown?.offsetHeight < this.$refs.dropdown.getBoundingClientRect().top)) {
          this.$refs.dropdown.classList.add('dropdown-menu--top')
        } else {
          this.$refs.dropdown?.classList.remove('dropdown-menu--top')
        }

        const list = document.getElementById('list-items')

        if (list && this.model) {
          const elements = document.getElementsByClassName('dropdown-menu__item selected')
          if (elements.length) {
            list.scrollTop = elements[0].offsetTop - list.offsetTop
          }
        }
      }, 10)
    },

    'modal.active' (value) {
      if (!value) {
        this.waitingEmitter = false
      }
    }
  },

  created () {
    this.valuesForm = cloneDeep(this.values)
  },

  async mounted () {
    if (this.dropdownPosition === 'center') {
      this.centerStyle = `left: 50%; margin-left: -${this.dropdownWidth / 2}rem`
    }
    if (this.multiselect && this.model) {
      this.selectedValues = this.model
    }

    this.$bus.on('on-new-item-added', this.onNewItemAdded)
    this.$bus.on('on-item-deleted', this.onItemDeleted)
    this.$bus.on('account-switched', this.onSwitchedAccount)

    if (this.hasDefault || this.bindValue || this.getDataOnMounted) await this.getDropdownData()
  },

  beforeUnmount () {
    this.$bus.off('on-new-item-added', this.onNewItemAdded)
    this.$bus.off('on-item-deleted', this.onItemDeleted)
    this.$bus.off('account-switched', this.onSwitchedAccount)
  },

  methods: {
    async toggleDropdown () {
      if (this.disabled) {
        return
      }
      if (!this.dropdownClicked && !this.hasDefault && !this.bindValue) {
        this.getDropdownData()
      }
      this.dropdownClicked = true
      this.showDropdown = !this.showDropdown
      if (!this.showDropdown) {
        this.filter = ''
        setTimeout(() => {
          this.inDOM = false
        }, 200)
      } else {
        this.inDOM = true
      }
    },

    toggleItem () {
      this.addItem = !this.addItem
      if (this.addItem) {
        setTimeout(() => this.$refs['new-item'].focus(), 1)
      }
    },

    onSwitchedAccount () {
      if (this.hasDefault) {
        this.getDropdownData(true)
      } else if (this.asyncUrl) {
        this.getAsyncData(true)
      }
    },

    onSelect (value) {
      if (!this.showDropdown && !this.selectDefault) {
        return
      }
      if (this.multiselect) {
        const valueIsSelected = this.selectedValues.find(selectedValue => (selectedValue.id && selectedValue.id === value.id) || (selectedValue.uuid && selectedValue.uuid === value.uuid))
        if (valueIsSelected) {
          this.selectedValues.splice(this.selectedValues.indexOf(valueIsSelected), 1)
        } else {
          this.selectedValues.push(value)
        }
        this.$emit('select', this.selectedValues)
      } else {
        if (this.isSelected(value) && this.canResetValue) {
          this.$emit('reset-value')
        } else {
          this.$emit('select', value)
        }
        this.reset()
      }
    },

    onDelete (value) {
      this.waitingEmitter = true
      this.$emit('delete-item', value)
      this.onItemDeleted(value)
    },

    onAddItem () {
      this.waitingEmitter = true
      this.$refs['new-item'].blur()
      this.$emit('new-item', this.newItem)
      this.reset()
    },

    getLabelType (value) {
      if (!this.labelType) return this.labelFilter(value)
      if (this.labelType === 'percentage') {
        return formatPercentage(this.labelFilter(value))
      }
    },

    hasSameField (value, item) {
      const fields = ['id', 'uuid', 'value']
      return fields.some(field => (value[field] || !isNaN(value[field])) && value[field] === item[field])
    },

    isSelected (item) {
      if (this.multiselect) {
        return this.selectedValues.some(value => this.hasSameField(value, item))
      } else {
        if (typeof item === 'object') {
          if (Array.isArray(this.model)) {
            return this.model.some(value => this.hasSameField(value, item)) || this.model === item.value
          }
          return this.model === item.id || this.model === item.uuid || this.model === item.value || this.model === item.name
        }
        return this.model === item
      }
    },

    async getAsyncData (reset = true) {
      if (!this.asyncUrl || this.disabled) {
        return
      }
      const params = {
        ...this.asyncUrlParams,
        search: this.filter,
        page: this.currentPage
      }
      if (reset) {
        this.valuesForm.splice(0, this.valuesForm.length)
      }
      if (this.showDropdown) {
        store.api.hideProgressBar = true
      }
      this.loading = true

      try {
        this.abortController?.abort()
        this.abortController = this.apiStore.addAbortController()
        const { data, headers } = await axiosClient.get(this.asyncUrl, { params, signal: this.abortController.signal })
        this.abortController = null
        const pagination = this.appStore.parsePagination(headers)
        this.currentPage = pagination.current
        this.pageCount = pagination.count
        this.$emit('request-loaded', { data, pagination })
        store.api.hideProgressBar = false
        this.isLastPage = this.currentPage === this.pageCount

        if (Array.isArray(data)) {
          data.filter(this.asyncResultsFilter).forEach(item => { this.valuesForm.push(item) })
        }
        this.loading = false
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getDropdownData (reset = false) {
      if (this.asyncUrl) {
        const success = await this.getAsyncData(reset)
        if (success) {
          if (this.getDataOnMounted) {
            this.dropdownClicked = true
            this.selectDefault = true
          }
          if (this.bindValue && this.model) {
            this.selectDefault = true
            const itemToFind = this.valuesForm.find(i => this.isSelected(i))
            this.onSelect(itemToFind)
          } else if (this.hasDefault) {
            this.selectDefault = true
            this.onSelect(this.valuesForm[0])
          }
          if (this.emiter) {
            this.$bus.emit(this.emiter, this.valuesForm)
          }
        }
      } else {
        if (this.bindValue && this.model) {
          this.selectDefault = true
          const itemToFind = this.valuesForm.find(i => this.isSelected(i))
          this.onSelect(itemToFind)
        }
      }
    },

    getNextPage () {
      this.preventReset = true
      this.currentPage += 1
      this.getAsyncData(false)
    },

    onNewItemAdded (item) {
      if (this.waitingEmitter) {
        this.valuesForm.push(item)
        this.waitingEmitter = false
      }
    },

    onItemDeleted (id) {
      if (this.waitingEmitter) {
        const index = this.valuesForm.findIndex(value => value.id === id)
        if (index !== -1) {
          this.valuesForm.splice(index, 1)
        }
        this.waitingEmitter = false
      }
    }
  }
}
</script>
