<template>
  <fieldset class="form__fieldset"
            data-cy="cards.settings.section.countries-list-select">
    <group-title :title="title" />
    <div class="bordered-box form__fieldset__block-list overflow-scrollbar">
      <autoselect-country :selected-countries="countries"
                          :disabled="disabled"
                          @select-country="onSelectCountry" />
      <div v-if="countries.length"
           class="form__fieldset__block-list__list">
        <div v-for="(country, index) in countries"
             :key="`list-${country}-${index}`"
             class="form__fieldset__block-list__item">
          <img :src="getFlagSrc(country)"
               class="item-icon">
          <div class="item-label">
            {{ getCountryName(country) }}
          </div>
          <button class="btn-link btn-remove"
                  :disabled="disabled"
                  @click="deleteCountry(country)">
            <ic-remove class="ic ic--14 ic--gray" />
          </button>
        </div>
      </div>
      <div v-else
           class="block-placeholder">
        <component :is="placeholderIcon"
                   class="block-placeholder__img" />
        <div class="block-placeholder__label">
          {{ placeholderText }}
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { getCountryName, getFlagSrc } from '@/helpers/utils/intl'

import GroupTitle from '@/components/GroupTitle.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

import AutoselectCountry from './AutoselectCountry.vue'

export default {
  components: {
    IcRemove,
    AutoselectCountry,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    placeholderIcon: {
      type: [String, Object],
      required: true
    },

    placeholderText: {
      type: String,
      required: true
    },

    countries: {
      type: Array,
      required: true
    }
  },

  emits: ['update-countries'],

  methods: {
    getFlagSrc,
    getCountryName,

    onSelectCountry (country) {
      this.updateCardSettings(this.countries.concat(country))
    },

    deleteCountry (country) {
      this.updateCardSettings(this.countries.filter(el => el !== country))
    },

    updateCardSettings (countries) {
      this.$emit('update-countries', countries)
    }
  }
}
</script>
