<template>
  <div class="card-item"
       :class="wrapperClass"
       @click="handleClick">
    <template v-if="isLogoVisible">
      <logo-bank-inline v-bind="logoBankColor"
                        class="logo-bank-card" />
    </template>
    <image-sim v-if="showSim"
               class="card-sim" />
    <div class="card-item__content">
      <div class="card-title">
        <div class="badge-block">
          <core-badge v-if="isVirtualCard"
                      :theme="cardBadgeTheme(card.type)"
                      :value="badgeLabel" />
        </div>
      </div>
      <div class="card-amount">
        <template v-if="canDisplayAvailableAmount">
          {{ formatAmount(card.available_amount, account.currency) }}
        </template>
      </div>
      <div class="card-name">
        <template v-if="showCardLabel">
          {{ card.label || '' }}
        </template>
      </div>

      <div v-if="showPan && isMarqeta"
           id="mq-card-pan"
           class="card-number" />
      <card-digits v-else
                   :digits="showPan ? secretInformations.pan : card.digits"
                   :show-pan="showPan"
                   :hidden="isCardPending || !showDigits" />

      <div v-if="showCVV"
           class="card-bottom-details">
        <span v-if="!showPan"
              class="card-details holder">
          <strong>{{ cardName }}</strong>
          <small>{{ card.sub_reference }}</small>
        </span>
        <span class="card-details date"
              :class="{ 'is-hidden': !isActive || !isValidExpirationDate }">
          <small>{{ $t('label.card.expiration_end') }}</small>
          <strong v-if="!showPan">{{ card.expiration_date }}</strong>
          <strong v-else-if="isMarqeta"
                  id="mq-card-exp" />
          <strong v-else-if="isWorldline">{{ secretInformations.expiration_date }}</strong>
        </span>
        <span v-if="showPan"
              class="card-details holder">
          <small>CVV</small>
          <strong v-if="isMarqeta"
                  id="mq-card-cvv" />
          <strong v-else-if="isWorldline">
            {{ secretInformations.cvv }}
          </strong>
        </span>
      </div>
    </div>
    <div v-if="!showPan && isCardToActivate"
         class="card-item__menu card-item__menu--2-buttons">
      <button v-if="hasPermission(writePermission)"
              class="btn btn--default btn--xs"
              @click.prevent.stop="$router.push({ name: 'cards-detail-settings', params: { uuid: card.uuid } })">
        {{ $t('button.set') }}
      </button>
      <button v-if="hasCardActivationRight"
              class="btn btn--primary btn--xs"
              data-cy="cards.details.activate.button"
              @click.prevent.stop="showActivateCardModal">
        {{ $t('button.activate') }}
      </button>
    </div>
    <div v-else-if="!showPan && isLocked"
         class="card-item__menu card-item__menu--2-buttons">
      <div class="card-locked-overlay">
        <span class="card-locked-badge">
          <ic-cadenas-sm class="ic ic--16 ic--white" />
          <span>{{ lockedCardLabel }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { CoreBadge } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { cardBadgeTheme } from '@/helpers/utils/badge'
import {
  getCardBadgeLabel,
  isCappedVirtualCard,
  isOneTimeVirtualCard,
  isPhysicalCard,
  isVirtualCard
} from '@/helpers/utils/card'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { ECardProcessor } from '@/types/card.d'

import ActivateCard from '@/pages/cards/modal/ActivateCard.vue'

import CardDigits from '@/components/CardDigits.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'
import ImageSim from '@/components/svg/img/sim.vue'

export default {
  components: {
    ImageSim,
    CoreBadge,
    CardDigits,
    IcCadenasSm,
    LogoBankInline
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    clickable: {
      type: Boolean,
      required: false,
      default: true
    },

    nativeClick: {
      type: Boolean,
      required: false,
      default: true
    },

    showCardLabel: {
      type: Boolean,
      required: false,
      default: true
    },

    showPan: {
      type: Boolean,
      default: false
    },

    smallCard: {
      type: Boolean,
      default: false
    },

    showSim: {
      type: Boolean,
      default: false
    },

    showDigits: {
      type: Boolean,
      default: true
    },

    showCVV: {
      type: Boolean,
      default: true
    },

    secretInformations: {
      type: Object,
      default: null
    }
  },

  emits: ['validate-card'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore }
  },

  computed: {
    isPhysicalCard () {
      return isPhysicalCard(this.card)
    },

    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    isLogoVisible () {
      return (this.isMarqeta || this.isWorldline) && this.isPhysicalCard
    },

    cardName () {
      return `${this.card.first_name} ${this.card.last_name}`.trim()
    },

    readPermission () {
      return this.isPhysicalCard ? this.$permissions.cardsPhysicalRead : this.$permissions.cardsVirtualRead
    },

    writePermission () {
      return this.isPhysicalCard ? this.$permissions.cardsPhysicalWrite : this.$permissions.cardsVirtualWrite
    },

    hasCardActivationRight () {
      return hasFeature(EFeature.CardActivation, this.card)
    },

    isCardPending () {
      return this.isCardToActivate
    },

    isCardExpired () {
      return this.card.expired
    },

    isCardToValidate () {
      return this.card.status === 'to_validate'
    },

    isCardToValidateByBank () {
      return this.card.status === 'to_validate_by_bank'
    },

    isActive () {
      return this.card.status === 'active' || this.isLocked
    },

    isValidExpirationDate () {
      return (/^((0[1-9])|(1[0-2]))\/(\d{2})$/).test(this.card.expiration_date)
    },

    isLocked () {
      return ['locked', 'locked_proof', 'locked_service', 'locked_partner'].includes(this.card.status)
    },

    lockedCardLabel () {
      return this.card.status === 'locked_partner' ? this.$i18n.t('tooltip.card_locked_by_bank') : this.$i18n.t('tooltip.locked_card')
    },

    logoBankColor () {
      if (this.isCardToValidateByBank) {
        return { white: true }
      }
      return this.isActive ? { white: true } : { gray: true }
    },

    isBlocked () {
      return ['blocked', 'expired', 'cancelled', 'used', 'deleted'].includes(this.card.status)
    },

    isCardToActivate () {
      return ['pending', 'ordered', 'shipped'].includes(this.card.status)
    },

    isMarqeta () {
      return this.card.subtype === ECardProcessor.MARQETA
    },

    isWorldline () {
      return this.card.subtype === ECardProcessor.WORLDLINE
    },

    wrapperClass () {
      return [{
        'card-item--default-physical': this.isPhysicalCard,
        'card-item--default-virtual': this.isVirtualCard,
        'card-old': !(this.isMarqeta || this.isWorldline),
        'card-inactive': (this.isCardPending || this.isBlocked) && !this.showPan,
        'card-simple': this.showPan,
        'is-link': this.clickable && (this.isCardToValidate || this.isCardToValidateByBank || this.isActive || (this.isCardToActivate && this.hasPermission(this.readPermission))),
        'menu-hover card-locked': this.isLocked && !this.showPan,
        'card-item--sm': this.smallCard
      },
      `card-item--${this.card.type}`]
    },

    badgeLabel () {
      return getCardBadgeLabel(this.card)
    },

    canDisplayAvailableAmount () {
      return (isOneTimeVirtualCard(this.card) || isCappedVirtualCard(this.card)) &&
        (this.hasPermission(this.writePermission) || this.card.settings.display_limit)
    }
  },

  methods: {
    cardBadgeTheme,
    formatAmount,

    showActivateCardModal () {
      this.appStore.showModal(
        ActivateCard,
        { card: this.card },
        { wrapperClass: 'modal--xs' }
      )
    },

    handleClick () {
      if (!this.nativeClick) {
        return
      }

      if (this.isCardToActivate && !this.hasPermission(this.readPermission)) {
        return
      }

      if (this.isCardExpired) {
        this.$router.push({ name: 'cards-detail-operations', params: { uuid: this.card.uuid } })
        return
      }

      this.isCardToValidate || this.isCardToValidateByBank
        ? this.$emit('validate-card', this.card.uuid)
        : this.$router.push({ name: 'cards-detail', params: { uuid: this.card.uuid } })
    }
  }
}
</script>
