<template>
  <div v-if="hasCategories"
       class="form__row row">
    <template v-if="hasRestaurantCategory">
      <div class="form-group col col--sm-2"
           :class="{ error: errors.guests }">
        <label class="label input__label"
               for="guests">
          {{ $t('form.refunds.number_of_guests') }}
        </label>
        <validation-field id="guests"
                          v-model="transferForm.guest"
                          type="number"
                          name="guests"
                          class="form-control form-control--noborder input__field" />
      </div>
      <div class="form-group col col--sm-4">
        <label class="label input__label">
          {{ $t('form.refunds.service.label') }}
        </label>
        <div class="btn-group btn-group--noborder btn-group-method"
             data-toggle="buttons">
          <label class="btn"
                 :class="{ active: transferForm.service === 'noon' }">
            <input v-model="transferForm.service"
                   type="radio"
                   name="options"
                   autocomplete="off"
                   value="noon">
            {{ $t('form.refunds.service.noon') }}
          </label>
          <label class="btn"
                 :class="{ active: transferForm.service === 'evening' }">
            <input v-model="transferForm.service"
                   type="radio"
                   name="options"
                   autocomplete="off"
                   value="evening">
            {{ $t('form.refunds.service.evening') }}
          </label>
        </div>
      </div>
      <div class="col--sm-6" />
    </template>
    <template v-if="hasHotelCategory">
      <div class="form-group col col--sm-2"
           :class="{ error: errors.nights }">
        <label class="label input__label"
               for="nights">
          {{ $t('form.refunds.number_of_nights') }}
        </label>
        <validation-field id="nights"
                          v-model="transferForm.nights"
                          type="number"
                          name="nights"
                          class="form-control form-control--noborder input__field" />
      </div>
      <div class="form-group col col--sm-4">
        <label class="label input__label">
          {{ $t('form.dinner_included.label') }}
        </label>
        <div class="btn-group btn-group--noborder btn-group-method"
             data-toggle="buttons">
          <label class="btn"
                 :class="{ active: transferForm.half_board_included }">
            <input v-model="transferForm.half_board_included"
                   type="radio"
                   name="options"
                   autocomplete="off"
                   :value="true">
            {{ $t('general.yes') }}
          </label>
          <label class="btn"
                 :class="{ active: !transferForm.half_board_included }">
            <input v-model="transferForm.half_board_included"
                   type="radio"
                   name="options"
                   autocomplete="off"
                   :value="false">
            {{ $t('general.no') }}
          </label>
        </div>
      </div>
      <div class="col--sm-6" />
    </template>
    <template v-if="hasFuelStationCategory">
      <div class="form-group col col--sm-6">
        <label class="label input__label">{{ $t('form.fuel.label') }} ({{ $t('general.optional') }})</label>
        <component-dropdown label="fuel"
                            dropdown-menu-class="dropdown-menu--noborder"
                            :dropdown-width="'100%'"
                            async-url="/list/fuel"
                            :model="transferForm.fuel"
                            @select="fuel => onDetailChanged('fuel', fuel)">
          <template #trigger>
            <div class="dropdown-toggle select">
              <div class="form-control form-control--noborder"
                   :class="{ 'text-muted': !transferForm.fuel }">
                {{ fuelLabel }}
              </div>
            </div>
          </template>
        </component-dropdown>
      </div>

      <div class="form-group col col--sm-3">
        <label class="label input__label"
               for="liters">
          {{ $t('form.liters.label') }} ({{ $t('general.optional') }})
        </label>
        <input id="liters"
               v-model="transferForm.liters"
               type="number"
               name="liters"
               class="form-control form-control--noborder input__field">
      </div>
      <div class="form-group col col--sm-3">
        <label class="label input__label"
               for="mileage">
          {{ $t('form.mileage.label') }} ({{ $t('general.optional') }})
        </label>
        <input id="mileage"
               v-model="transferForm.mileage"
               type="number"
               name="mileage"
               class="form-control form-control--noborder input__field">
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import ComponentDropdown from '@/components/Dropdown.vue'

export default {
  components: {
    ComponentDropdown
  },

  props: {
    transfer: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['update:transfer'],

  data () {
    return {
      transferForm: {}
    }
  },

  computed: {
    hasCategories () {
      return this.transferForm.categories &&
          (this.hasRestaurantCategory || this.hasHotelCategory || this.hasFuelStationCategory)
    },

    hasRestaurantCategory () {
      return this.transferForm.categories.some(category => category.name === 'restaurant')
    },

    hasHotelCategory () {
      return this.transferForm.categories.some(category => category.name === 'hotel-logement')
    },

    hasFuelStationCategory () {
      return this.transferForm.categories.some(category => category.name === 'station-essence')
    },

    fuelLabel () {
      return this.transferForm.fuel?.label || this.$i18n.t('form.fuel.placeholder')
    }
  },

  watch: {
    transferForm: {
      handler (transferForm) {
        this.$emit('update:transfer', transferForm)
      },

      deep: true
    }
  },

  created () {
    this.transferForm = cloneDeep(this.transfer)
  },

  methods: {
    onDetailChanged (field, value) {
      this.transferForm[field] = value
    }
  }
}
</script>
