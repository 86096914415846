<template>
  <fieldset v-if="hasSettingField('auto_block_spent')"
            data-cy="cards.settings.section.auto-block-spent"
            class="form__fieldset form__fieldset--block">
    <group-title :title="$t('cards_settings.auto_block_spent.title')" />
    <div class="bordered-box form__fieldset__block-value">
      <number-input :model-value="settings.auto_block_spent.limit"
                    :disabled="disabled || !settings.auto_block_spent.enabled"
                    name="block-limit"
                    class="form-control"
                    :addon="getCurrencySymbol(account.currency)"
                    :placeholder="$t('form.amount.placeholder')"
                    @change="value => updateCardSettings(true, parseInt(value))" />
      <core-badge class="mb-4"
                  :value="$t('cards_settings.auto_block_spent.max')"
                  :fill="ECoreBadgeFill.Shaded"
                  :size="ECoreBadgeSize.Large"
                  :theme="settings.auto_block_spent.enabled ? ECoreBadgeTheme.Primary : ECoreBadgeTheme.GrayLow" />

      <toggle-switch :model-value="settings.auto_block_spent.enabled"
                     :disabled="disabled"
                     :toggle-class="'switch-btn--sm'"
                     @update:model-value="value => updateCardSettings(value, settings.auto_block_spent.limit)" />
    </div>
  </fieldset>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import GroupTitle from '@/components/GroupTitle.vue'
import NumberInput from '@/components/NumberInput.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    CoreBadge,
    ToggleSwitch,
    NumberInput,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    }
  },

  methods: {
    getCurrencySymbol,

    updateCardSettings (enabledValue, limitValue) {
      this.$emit('update-card-settings', {
        auto_block_spent: {
          enabled: enabledValue,
          limit: limitValue
        }
      })
    },

    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    }
  }
}
</script>
