import dayjs, { Dayjs } from 'dayjs'

import { Model } from '@/models/Model'
import { IDocument } from '@/types/document.d'

export class ModelDocument extends Model {
  public id?: number
  readonly created_at: Dayjs
  readonly updated_at: Dayjs
  public name: string|null
  public filename: string|null
  public url: string|null
  public mime_type: string
  public size: number
  public file: File | null

  public apiFields = ['file']

  private constructor (properties: IDocument) {
    super()

    this.id = properties?.id
    this.created_at = dayjs(properties?.created_at)
    this.updated_at = dayjs(properties?.updated_at)
    this.name = properties?.name || ''
    this.filename = properties?.filename || ''
    this.url = properties?.url || ''
    this.mime_type = properties?.mime_type || ''
    this.size = properties?.size || 0
    this.file = properties?.file || null

    return this.onConstructed()
  }

  static create (properties: IDocument = {} as IDocument) {
    return new ModelDocument(properties)
  }

  static createFromFile (file: File) {
    const properties = {} as IDocument
    properties.name = file?.name
    properties.mime_type = file?.type
    properties.size = file?.size
    properties.filename = file?.name
    properties.file = file
    return new ModelDocument(
      properties
    )
  }
}
