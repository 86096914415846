<template>
  <div class="dashboard__block"
       :class="`dashboard__block--${color}`">
    <loader-spinner :color="loaderSpinnerColor" />
  </div>
</template>

<script>
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  components: {
    LoaderSpinner
  },

  props: {
    color: {
      type: String,
      required: false,
      default: 'white'
    }
  },

  computed: {
    loaderSpinnerColor () {
      return ['dark', 'mid'].includes(this.color) ? 'white' : ''
    }
  }
}
</script>
