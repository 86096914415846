<template>
  <svg viewBox="0 0 200 160">
    <path d="M126 44.75H69.54v1.5H126v-1.5Zm8.5 56.86a15.7 15.7 0 0 0-7-2.3H126a15.68 15.68 0 0 0-15 19.49c.62 2.5 1.84 4.83 3.57 6.75.22.24.46.51.7.75a15.73 15.73 0 1 0 19.25-24.64l-.02-.05Zm-18.46 23.3-.15-.16a14.27 14.27 0 0 1-1.5-17.67 14.2 14.2 0 0 1 11.63-6.32h1.5a14.25 14.25 0 0 1 8.85 24.18c-1.32-3.6-4.15-6.1-7.44-6.1h-5.47c-3.3 0-6.12 2.48-7.42 6.07Zm10.16-8.2c3.26 0 5.9-2.62 5.9-5.86a5.88 5.88 0 0 0-5.9-5.85 5.88 5.88 0 0 0-5.45 8.1 5.9 5.9 0 0 0 5.45 3.61Z"
          :fill="colorPrimary" />
    <path d="M129.53 30.75H80.06a5 5 0 0 0-5 5.06v.94h-2a5 5 0 0 0-5 5.06v76.69c0 .2.08.39.22.53l7 7c.14.14.33.22.53.22h37.45c-.21-.24-.42-.5-.61-.75-.2-.26-.38-.49-.56-.75H76.53c-.16-5-2-6.83-7-7V41.81a3.54 3.54 0 0 1 3.51-3.56h49.47a3.54 3.54 0 0 1 3.49 3.56v55.95h1.5v-56a5.01 5.01 0 0 0-5-5.06h-46v-.94a3.54 3.54 0 0 1 3.51-3.56h49.47a3.54 3.54 0 0 1 3.52 3.61v63.31c.51.22 1.01.46 1.5.73v-64a5 5 0 0 0-4.97-5.1ZM75 123.61l-4.28-4.28c2.89.27 3.97 1.36 4.28 4.28Z"
          :fill="colorDark" />
    <path d="M97.29 57.75h-23v1.5h23v-1.5Zm-23 6v1.5h32v-1.5h-32Zm0 11.94v1.5h19.9v-1.5h-19.9Zm0 7.56h25v-1.5h-25v1.5Zm0-12h26v-1.5h-26v1.5Z"
          :fill="colorGrey" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
