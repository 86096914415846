<template>
  <section class="section section-account">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.account") }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <section class="section__content-block--centered account-home">
        <div class="block-options block-options--two-columns">
          <block-options-item :to="{ name: 'account-info' }"
                              :title="$t('account.menu.user_profile.title')"
                              :content="$t('account.menu.user_profile.subtitle')">
            <core-illustration :name="ECoreIllustrationList.PersonalInfo" />
          </block-options-item>
          <block-options-item v-if="isRibVisible"
                              :to="{ name: 'account-rib' }"
                              :title="$t('account.menu.rib.title')"
                              :content="$t('account.menu.rib.subtitle')">
            <core-illustration :name="ECoreIllustrationList.BankDetails" />
          </block-options-item>
          <block-options-item v-if="isCheckRemittanceVisible"
                              :to="{ name: 'account-check-remittance' }"
                              :title="$t('account.menu.check_remittance.title')"
                              :content="$t('account.menu.check_remittance.subtitle')">
            <core-illustration :name="ECoreIllustrationList.BankCheckDeposit" />
          </block-options-item>
          <block-options-item v-if="isCompanyVisible"
                              :to="{ name: 'account-company' }"
                              :title="$t('account.menu.company.title')"
                              :content="$t('account.menu.company.subtitle')">
            <core-illustration :name="ECoreIllustrationList.Buildings" />
          </block-options-item>
          <block-options-item :to="{ name: 'account-settings-notifications' }"
                              :title="$t('account.menu.settings.title')"
                              :content="$t('account.menu.settings.subtitle')">
            <core-illustration :name="ECoreIllustrationList.Settings" />
          </block-options-item>
          <block-options-item :to="{ name: 'account-sca' }"
                              :title="$t('account.menu.sca.title')"
                              :content="$t('account.menu.sca.subtitle')">
            <core-illustration :name="ECoreIllustrationList.ComputerPhoneSecurity" />
          </block-options-item>
          <block-options-item v-if="isServicesVisible"
                              :to="{ name: 'account-services' }"
                              :title="$t('account.menu.services.title')"
                              :content="$t('account.menu.services.subtitle')">
            <core-illustration :name="ECoreIllustrationList.AppServices" />
          </block-options-item>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { CoreIllustration, ECoreIllustrationList } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { useAccountStore } from '@/stores/account'

import BlockOptionsItem from '@/components/BlockOptionsItem.vue'

export default {
  name: 'PageAccount',

  components: {
    CoreIllustration,
    BlockOptionsItem
  },

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    ECoreIllustrationList () {
      return ECoreIllustrationList
    },

    isRibVisible () {
      return this.account.rib && hasFeature(EFeature.AccountRIB)
    },

    isCompanyVisible () {
      return hasFeature(EFeature.AccountCompany)
    },

    isServicesVisible () {
      return hasFeature(EFeature.AccountService)
    },

    isCheckRemittanceVisible () {
      return hasFeature(EFeature.AccountCheckRemittanceSlip)
    }
  }
}
</script>
