import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { groupByDate } from '@/helpers/utils/date'

export const useInvitationStore = defineStore('invitation', {
  state: () => {
    return {
      invitations: [],
      invitationsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      invitationLogs: [],
      invitationLogsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      invitationLogsGroupByDay: [],
      numberOfPendingInvitations: 0,
      enable_transfer_limit: false,
      invitationTransferLimit: {
        alert_transfer_limit: 0,
        daily_transfer_limit: 0,
        weekly_transfer_limit: 0,
        monthly_transfer_limit: 0
      },
      invitationRefundLimit: {
        alert_refund_limit: 0,
        daily_refund_limit: 0,
        weekly_refund_limit: 0,
        monthly_refund_limit: 0
      },
      invitationMileageLimit: {
        monthly_mileage_limit: 0
      },
      invitationExpenseRequestConfig: {
        trigger: null,
        type: 'simple',
        approvers: []
      },
      invitationCurrentPage: 1,
      invitationPageCount: 1
    }
  },

  actions: {
    async getInvitations (page, params = {}) {
      let invitations = []
      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/invitations', { params })
        this.invitationsPagination = this.parsePagination(headers)

        invitations = data
      } catch (error) {
        console.error(error)
      }

      this.invitations = invitations
    },

    async getNumberOfPendingInvitations () {
      try {
        const { headers } = await axiosClient.head('/invitations', { params: { status: 'pending,expired' } })
        this.numberOfPendingInvitations = this.parsePagination(headers).totalItems
      } catch (e) {
        throw new Error(e)
      }
    },

    async checkInvitationStatus (token) {
      try {
        await axiosClient.post('/invitations/check', { token }, { doNotShowBanner: true })
        return { success: true, error: null }
      } catch (e) {
        return { success: false, error: e.response.data.message }
      }
    },

    async getUserLogs (page, userUuid) {
      const params = { page }
      if (page === 1) {
        this.invitationLogs = this.invitationLogsGroupByDay = []
      }

      try {
        const { data, headers } = await axiosClient.get(`/users/${userUuid}/logs`, { params })
        this.invitationLogsPagination = this.parsePagination(headers)

        this.invitationLogs.push(...data)

        this.invitationLogsGroupByDay = groupByDate(this.invitationLogs, 'created_at', { groupByFormat: 'LL' })
      } catch (error) {
        console.error(error)
      }
    },

    setInvitationTransferLimit (limit) {
      this.invitationTransferLimit = {
        alert_transfer_limit: limit.alert_limit,
        daily_transfer_limit: limit.daily_limit,
        weekly_transfer_limit: limit.weekly_limit,
        monthly_transfer_limit: limit.monthly_limit
      }
    },

    setInvitationRefundLimit (limit) {
      this.invitationRefundLimit = {
        alert_refund_limit: limit.alert_limit,
        daily_refund_limit: limit.daily_limit,
        weekly_refund_limit: limit.weekly_limit,
        monthly_refund_limit: limit.monthly_limit
      }
    },

    setInvitationMileageLimit (limit) {
      this.invitationMileageLimit = {
        monthly_mileage_limit: limit.monthly_limit
      }
    },

    setInvitationExpenseRequestConfig (config) {
      this.invitationExpenseRequestConfig = config
    },

    async refuseInvitation (invitationId) {
      try {
        await axiosClient.put(`/invitations/${invitationId}/refuse`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async refreshInvitation (invitationId) {
      try {
        await axiosClient.put(`/invitations/${invitationId}/refresh`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async validateInvitation (invitationId) {
      try {
        await axiosClient.put(`/invitations/${invitationId}/validate`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async saveInvitation (invitation) {
      try {
        await axiosClient.post('/invitations', invitation)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async updateInvitation (id, invitation) {
      try {
        const { data } = await axiosClient.put(`/invitations/${id}`, invitation)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
