<template>
  <header class="header">
    <div class="topbar">
      <div class="row row--sm-center row--no-gutters">
        <div class="col col--sm col--no-gutters">
          <ul class="topbar__nav">
            <li class="topbar-item topbar-item--menu">
              <button class="btn-link btn-menu"
                      @click="onToggleSidebar">
                <i class="topbar-icon">
                  <ic-menu class="ic ic--gray" />
                </i>
              </button>
            </li>
            <li class="topbar-item topbar-item--user">
              <account-dropdown-menu />
            </li>
          </ul>
        </div>
        <div class="col col--sm col--no-gutters right">
          <ul class="topbar__nav topbar__nav--right">
            <li v-if="account && account.status_group === 'pending'"
                class="topbar-item topbar-item--rib">
              <a href="#"
                 @click.prevent.stop="downloadFileFromUrl(account.rib.pdf_url)">
                <i class="topbar-icon">
                  <ic-sheet class="ic ic--22 ic--gray" />
                </i>
                {{ $t("button.temporary_rib") }}
              </a>
            </li>
            <li v-else-if="isRibVisible"
                class="topbar-item topbar-item--rib">
              <component-popover trigger="hover"
                                 popover-class="popover-actions-menu"
                                 :arrow="false">
                <template #trigger>
                  <a href="#"
                     class="dropdown-toggle dropdown-toggle--hide">
                    <i class="topbar-icon">
                      <ic-sheet class="ic ic--24 ic--gray" />
                    </i>
                    {{ $t("button.rib") }}
                  </a>
                </template>
                <div class="popover__item"
                     @click.prevent.stop="downloadFileFromUrl(account.rib.pdf_url)">
                  <div class="popover__item__icon">
                    <ic-download-sm class="ic ic--16 ic--gray" />
                  </div>
                  <div style="white-space: nowrap">
                    {{ $t("button.download") }}
                  </div>
                </div>
                <div id="iban-copy"
                     class="popover__item"
                     :data-clipboard-text="formatIban(account.rib.iban)">
                  <div class="popover__item__icon">
                    <ic-clipboard class="ic ic--16 ic--gray" />
                  </div>
                  <div style="white-space: nowrap">
                    {{ $t("button.copy_iban") }}
                  </div>
                </div>
                <div class="popover__item"
                     @click.prevent.stop="onSendEmail">
                  <div class="popover__item__icon">
                    <ic-mail-sm class="ic ic--16 ic--gray" />
                  </div>
                  <div style="white-space: nowrap">
                    {{ $t("button.email_send") }}
                  </div>
                </div>
              </component-popover>
            </li>
            <li v-if="isInboxVisible"
                class="topbar-item topbar-item--messages">
              <router-link :to="{ name: 'inbox' }">
                <i class="topbar-icon">
                  <dot v-if="account.unread_messages_count && account.unread_messages_count !== 0" />
                  <ic-mail class="ic ic--24 ic--gray" />
                </i>
                <template v-if="account.unread_messages_count !== 0">
                  {{ $tc('general.message', account.unread_messages_count) }}
                </template>
                <template v-else>
                  {{ $t("button.inbox") }}
                </template>
              </router-link>
            </li>
            <li v-if="isSponsorshipVisible"
                class="topbar-item topbar-item--sponsorship">
              <router-link :to="{ name: 'sponsorship' }">
                <i class="topbar-icon">
                  <ic-gift class="ic ic--24 ic--gray" />
                </i>
                {{ $t("button.sponsorship") }}
              </router-link>
            </li>
            <li class="topbar-item topbar-item--assistance">
              <a :href="links.SUPPORT.BASE"
                 target="_blank">
                <i class="topbar-icon">
                  <ic-help class="ic ic--24 ic--gray" />
                </i>
                {{ $t("button.help") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Clipboard from 'clipboard'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import store from '@/config/store'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import Dot from '@/components/Dot.vue'
import SendEmail from '@/components/modals/SendEmail.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcClipboard from '@/components/svg/icons/ic-clipboard.vue'
import IcDownloadSm from '@/components/svg/icons/ic-download-sm.vue'
import IcGift from '@/components/svg/icons/ic-gift.vue'
import IcHelp from '@/components/svg/icons/ic-help.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcMailSm from '@/components/svg/icons/ic-mail-sm.vue'
import IcMenu from '@/components/svg/icons/ic-menu.vue'
import IcSheet from '@/components/svg/icons/ic-sheet.vue'

import AccountDropdownMenu from './AccountDropdownMenu.vue'

export default {
  name: 'ComponentHeader',

  components: {
    ComponentPopover,
    IcDownloadSm,
    IcMail,
    IcMailSm,
    IcHelp,
    IcSheet,
    IcMenu,
    IcGift,
    IcClipboard,
    Dot,
    AccountDropdownMenu
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)
    const { user } = storeToRefs(authStore)

    return { account, accountStore, appStore, user, authStore }
  },

  data () {
    return {
      store,
      clipboard: null,
      links
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    isRibVisible () {
      return hasFeature(EFeature.AccountRIB)
    },

    isInboxVisible () {
      return this.isAccountValid && hasFeature(EFeature.Inbox)
    },

    isSponsorshipVisible () {
      return this.isAccountValid && hasFeature(EFeature.Sponsorship)
    },

    isAccountValid () {
      return this.account.status_group === 'valid'
    }
  },

  mounted () {
    this.clipboard = new Clipboard('#iban-copy')
    this.clipboard.on('success', this.onCopySuccess)
  },

  beforeUnmount () {
    this.clipboard.off('success', this.onCopySuccess)
  },

  methods: {
    hasFeature,

    downloadFileFromUrl,

    formatIban,

    onCopySuccess () {
      showToastSuccess(this.$i18n.t('message.success.copy_iban'))
    },

    onToggleSidebar () {
      document.getElementsByClassName('sidebar')[0].classList.toggle('is-open')
    },

    onSendEmail () {
      this.appStore.showModal(SendEmail, { callback: this.accountStore.sendRibByEmail }, { wrapperClass: 'modal--xs' })
    }
  }
}
</script>
