<template>
  <svg data-v-f83bf3c8=""
       width="10"
       height="30"
       viewBox="0 0 15 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5"
            cy="7.5"
            r="5.5"
            fill="#D4D7DD"
            stroke="white"
            stroke-width="0" />
  </svg>
</template>

<script>
export default {
}
</script>
