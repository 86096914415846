<template>
  <div class="steps">
    <template v-for="(step, index) in steps"
              :key="`step${index}`">
      <div class="steps__item"
           :class="{ 'steps__item--active': activeStep === index }">
        {{ index + 1 }}. {{ step }}
      </div>
      <ic-step-arrow v-if="!isLastElement(index)"
                     class="ic ic--gray" />
    </template>
  </div>
</template>

<script>
import IcStepArrow from '@/components/svg/icons/ic-step-arrow.vue'

export default {
  name: 'ComponentSteps',

  components: {
    IcStepArrow
  },

  props: {
    steps: {
      type: Array,
      required: true
    },

    activeStep: {
      type: Number,
      required: true
    }
  },

  methods: {
    isLastElement (index) {
      return index + 1 === this.steps.length
    }
  }
}
</script>

<style lang="stylus" scoped>
.steps
  display flex
  justify-content space-evenly
  width 100rem
  margin 0 auto
  height 8rem
  align-items center
  padding 0 4rem

  &__item
    color $colorShinyGray
    font-size 1.5rem

    &--active
      color $colorDarkGray
      font-weight 500

  .ic
    width 2rem
    height @width
    opacity .5
</style>
