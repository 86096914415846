<template>
  <svg viewBox="0 0 24 24">
    <path d="M7 17V7h6v1h2V5.773A.783.783 0 0 0 14.209 5H5.79a.782.782 0 0 0-.79.773v12.454c.005.432.36.778.791.773h8.418a.787.787 0 0 0 .791-.783V16h-2v1zm10-6h-4.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H17v2.276a.3.3 0 0 0 .512.212l3.134-3.134a.5.5 0 0 0 0-.708l-3.134-3.134a.3.3 0 0 0-.512.212z" />
  </svg>
</template>

<script>
export default {
}
</script>
