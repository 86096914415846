<template>
  <transition>
    <div v-if="modal.active"
         class="modal"
         :class="modal.wrapperClass">
      <div class="modal__bg"
           @click="onClickBackground" />
      <div class="modal__dialog"
           role="document">
        <component :is="modal.component"
                   v-bind="modal.props"
                   @close="onClose" />
      </div>
    </div>
  </transition>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'

export default {
  name: 'ComponentModal',

  setup () {
    const appStore = useAppStore()

    const { modal } = storeToRefs(appStore)

    return { modal, appStore }
  },

  mounted () {
    window.addEventListener('keyup', this.onEscape)
  },

  beforeUnmount () {
    window.removeEventListener('keyup', this.onEscape)
  },

  methods: {
    onEscape (e) {
      if (e.key === 'Escape' && this.modal.active) {
        this.onClickBackground()
      }
    },

    onClickBackground () {
      if (this.modal.onBackgroundLeaveAction) {
        this.$bus.emit('on-background-action')
      }
      if (!this.modal.blockLeaveBackground) {
        this.onClose()
      }
    },

    onClose () {
      this.appStore.closeModal()
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-enter-from, .v-leave-active
  opacity 0

.v-enter .modal__dialog
.v-leave-active .modal__dialog
  transform scale(0.9)
</style>
