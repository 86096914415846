export enum ECookieStatus {
  NOT_CONSENTED = false,
  CONSENTED = true,
  UNDEFINED = null
}

export type logNetworkEventType = {
  request_method?: string
  request_path?: string
  request_status_key?: string
  request_debug_tag?: string
  request_id?: string
  request_status_code?: number
}

export type logUserPropertiesType = {
  user_id?: string | null
  sub_user?: boolean
  sca_enabled?: boolean
  multiple_accounts?: boolean
  locale: string
  timezone: string
}

export type TCookiePreference = {
  analytics: ECookieStatus
  functional: ECookieStatus
}
