export enum EFilter {
  Amount = 'amount',
  Affectation = 'affectation',
  Attachment = 'attachment',
  Beneficiary = 'beneficiary',
  CardType = 'card_type',
  Category = 'category',
  ChargeBack = 'charge_back',
  DateRange = 'date_range',
  Compliance = 'compliance',
  ExpenseCategory = 'expense_category',
  Expiration = 'expiration',
  MinMax = 'min_max',
  Reliability = 'reliability',
  Search = 'search',
  Status = 'status',
  Team = 'team',
  TransactionType = 'transaction_type',
  IsSepa = 'is_sepa',
  Type = 'type',
  User = 'user'
}
