<template>
  <div class="modal__content center">
    <modal-header align-title="center"
                  :title="$t('modal.user_inactivity.title')"
                  :close-button="false" />
    <div class="modal__body">
      <div class="placeholder-action-description">
        <p class="mb-3 pre-line">
          {{ $t('modal.user_inactivity.subtitle') }}
        </p>
        <timeout-circle :time="time"
                        @ended="logout" />
      </div>
    </div>
    <div class="modal__footer">
      <button class="btn btn--gray mr-1"
              @click="logout">
        {{ $t('button.logout') }}
      </button>
      <component-button wrapper-class="btn btn--primary"
                        :label="$t('button.extend_session')"
                        :disabled="loading"
                        @click="validate" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import store from '@/config/store'
import { useAuthStore } from '@/stores/auth'

import ComponentButton from '@/components/Button.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import TimeoutCircle from '@/components/TimeoutCircle.vue'

export default {
  name: 'ComponentInactivity',

  components: {
    TimeoutCircle,
    ComponentButton,
    ModalHeader
  },

  props: {
    tokenExpireAt: {
      type: Number,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },

  data () {
    return {
      loading: false,
      time: 30
    }
  },

  created () {
    this.time = this.tokenExpireAt - dayjs().unix() - store.data.deltaTime
  },

  methods: {
    async logout () {
      this.$router.push({ name: 'logout-inactivity' })
      await this.authStore.logout()
    },

    async validate () {
      this.loading = true
      const success = await this.authStore.refreshToken()
      this.loading = false

      success ? this.$emit('close') : this.logout()
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal__content
  padding 6rem

.modal__footer
  .btn
    flex 1
</style>
