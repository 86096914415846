<template>
  <modal-dialog-placeholder @close="close">
    <component-placeholder :button="t('button.ok_understood')"
                           button-theme="default"
                           :label="t(`title.request_pending_validation`)"
                           :content="t(`placeholder.request_pending_validation.subtitle`)"
                           @btn-click="close">
      <image-guarantee />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageGuarantee from '@/components/svg/img/guarantee.vue'

const { t } = useI18n()

const emits = defineEmits(['close'])

function close () {
  emits('close')
}
</script>
