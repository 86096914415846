<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924zm0 1.712a.75.75 0 0 1 .743.648L12.75 6v5.25h3.75a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.648.743l-.102.007H12a.75.75 0 0 1-.743-.648L11.25 12V6a.75.75 0 0 1 .75-.75z" />
  </svg>
</template>

<script>
export default {
}
</script>
