<template>
  <div id="limit"
       :data-cy="isPhysicalCard ? 'cards.settings.section.limit' : 'cards.settings.section.limit-virtual'"
       class="form__fieldset">
    <group-title :title="$t(isPhysicalCard ? 'form.title.card_limits_withdrawals' : 'form.title.card_limits')" />
    <div class="form__fieldset__group">
      <div v-if="displayGlobalLimit"
           class="row"
           style="margin-bottom: 2rem">
        <div class="col col--sm">
          <div class="form__fieldset__block">
            <div class="item-left">
              <ic-card swipe
                       class="ic ic-card-order ic--gray" />
              <span class="form__fieldset__block__label">{{ $t('card.security.global_limit.title') }}</span>
            </div>
            <div class="item-right">
              <div class="input-group">
                <number-input :model-value="settings.initial_amount"
                              name="card-global-limit"
                              class="form-control form-control--sm right"
                              :class="{ error: settings.initial_amount === '' }"
                              :disabled="disabled"
                              :placeholder="$t('form.amount.placeholder')"
                              @blur="value => updateCardSettings('initial_amount', value)" />
                <span v-if="settings.initial_amount !== ''"
                      class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col--sm">
          <h3 v-if="isPhysicalCard"
              class="uppercase-title">
            {{ $t("card.limit.payment.title") }}
          </h3>
          <fieldset-block v-if="hasSettingField('payment_transaction')">
            <limit-item :invalid-amount-warning="invalidAmountWarning('payment')"
                        :limit="settings.payment_transaction.limit"
                        :label="$t('limit.per_transaction')"
                        :disabled="disabled"
                        :enabled="settings.payment_transaction.enabled"
                        @update-focus="newValue => focused.payment_transaction = newValue"
                        @update-enabled="value => updateLimitTransactionSettings('payment_transaction', { enabled: value, limit: settings.payment_transaction.limit })"
                        @update-limit="value => updateLimitTransactionSettings('payment_transaction', { enabled: settings.payment_transaction.enabled, limit: parseInt(value) })" />
          </fieldset-block>
          <fieldset-block v-if="hasSettingField('payment')">
            <limits-per-period :value="settings.payment"
                               data-cy="cards.settings.limit.payment"
                               :disabled="disabled"
                               @update-limit-settings="limit => updateLimitPeriodSettings('payment', limit.period, limit.value)" />
          </fieldset-block>
        </div>
        <div v-if="isPhysicalCard"
             class="col col--sm">
          <h3 class="uppercase-title">
            {{ $t("card.limit.withdrawal.title") }}
          </h3>
          <fieldset-block>
            <limit-item :invalid-amount-warning="invalidAmountWarning('withdrawal')"
                        :limit="settings.withdrawal_transaction.limit"
                        :label="$t('limit.per_withdrawal')"
                        :disabled="disabled"
                        :enabled="settings.withdrawal_transaction.enabled"
                        @update-focus="newValue => focused.withdrawal_transaction = newValue"
                        @update-enabled="value => updateLimitTransactionSettings('withdrawal_transaction', { enabled: value, limit: settings.withdrawal_transaction.limit })"
                        @update-limit="value => updateLimitTransactionSettings('withdrawal_transaction', { enabled: settings.withdrawal_transaction.enabled, limit: parseInt(value) })" />
          </fieldset-block>
          <fieldset-block>
            <limits-per-period :value="settings.withdrawal"
                               :disabled="disabled"
                               data-cy="cards.settings.limit.withdrawal"
                               @update-limit-settings="limit => updateLimitPeriodSettings('withdrawal', limit.period, limit.value)" />
          </fieldset-block>
        </div>
      </div>
      <transition name="show-toggle">
        <div v-if="showDisplayLimit"
             class="display-monthly-limit">
          <div class="row">
            <div class="col col--sm">
              <div class="form__fieldset__block">
                <div class="item-left">
                  <div class="switch-btn-label"
                       :class="{ 'text-muted': !settings.display_limit }">
                    {{ $t('cards_settings.limit.display_limit') }}
                  </div>
                </div>
                <div class="item-right">
                  <toggle-switch :model-value="settings.display_limit"
                                 :disabled="disabled"
                                 toggle-class="switch-btn--sm"
                                 @update:model-value="value => updateCardSettings('display_limit', value)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { merge } from 'lodash'
import { storeToRefs } from 'pinia'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import FieldsetBlock from '@/components/FieldsetBlock.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LimitItem from '@/components/limits/LimitItem.vue'
import LimitsPerPeriod from '@/components/limits/LimitsPerPeriod.vue'
import NumberInput from '@/components/NumberInput.vue'
import IcCard from '@/components/svg/icons/ic-card.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  name: 'ComponentCardLimit',

  components: {
    LimitItem,
    FieldsetBlock,
    NumberInput,
    IcCard,
    LimitsPerPeriod,
    ToggleSwitch,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    displayGlobalLimit: {
      type: Boolean,
      default: false
    },

    isPhysicalCard: {
      type: Boolean,
      required: true
    },

    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  setup () {
    const accountStore = useAccountStore()
    const { account } = storeToRefs(accountStore)
    return { account }
  },

  data () {
    return {
      periods: ['daily', 'weekly', 'monthly', 'annually'],
      focused: {
        payment_transaction: false,
        withdrawal_transaction: false
      }
    }
  },

  computed: {
    showDisplayLimit () {
      if (!this.hasSettingField('display_limit')) {
        return false
      }

      if (this.displayGlobalLimit) {
        return true
      }

      if (this.settings.payment_transaction?.enabled || this.settings.withdrawal_transaction?.enabled) {
        return true
      }

      let hasEnabledValue = false
      this.periods.forEach(period => {
        if (this.settings.payment[period].enabled || this.settings.withdrawal?.[period].enabled) hasEnabledValue = true
      })

      return hasEnabledValue
    }
  },

  methods: {
    getCurrencySymbol,

    getDisplayLimit (key, val, value) {
      if (this.displayGlobalLimit) {
        return this.settings.display_limit
      }

      const settings = merge(this.settings, { [key]: val })
      let count = 0
      if (settings.payment_transaction.enabled || settings.withdrawal_transaction?.enabled) count++

      this.periods.forEach(period => {
        if (settings.payment[period]?.enabled || settings.withdrawal?.[period]?.enabled) count++
      })

      if (count === 1 && value) {
        return true
      }

      if (count === 0) {
        return false
      }

      return this.settings.display_limit
    },

    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    },

    invalidAmountWarning (type) {
      const period = this.periods.find(period => (!this.focused[`${type}_transaction`] && this.settings[type][period].enabled && this.settings[type][period].limit < this.settings[`${type}_transaction`].limit))

      return period
        ? this.$i18n.t('tooltip.invalid_amount_period', { period: this.$i18n.t(`card.limit.option.${period}`) })
        : null
    },

    updateCardSettings (key, value, displayLimit = null) {
      const settings = {
        [key]: value
      }

      if (displayLimit !== null) {
        settings.display_limit = displayLimit
      }

      this.$emit('update-card-settings', settings)
    },

    updateLimitPeriodSettings (key, period, value) {
      const settings = merge(this.settings[key], { [period]: value })
      const displayLimit = this.getDisplayLimit(key, settings, value.enabled)
      this.updateCardSettings(key, settings, displayLimit)
    },

    updateLimitTransactionSettings (key, value) {
      const displayLimit = this.getDisplayLimit(key, value, value.enabled)
      this.updateCardSettings(key, value, displayLimit)
    }
  }
}
</script>

<style lang="stylus" scoped>
.form__fieldset__group
    .bordered-box:not(:last-child)
      margin-bottom 1.6rem

.show-toggle-enter-to
.show-toggle-leave-from
  height 10rem
</style>
