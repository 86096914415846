<template>
  <svg viewBox="0 0 200 160">
    <path fill="#53d8a8"
          d="m100 34c11.4407 0 22.2293 4.1943 30.5892 11.6438l.4377.3951-1.3494 1.4762c-8.0542-7.3624-18.5404-11.5151-29.6775-11.5151-24.3005 0-44 19.6995-44 44s19.6995 44 44 44 44-19.6995 44-44c0-7.3293-1.7934-14.3965-5.17-20.7126l-.3212-.59 1.7492-.9697c3.7474 6.7595 5.742 14.3725 5.742 22.2723 0 25.4051-20.5949 46-46 46s-46-20.5949-46-46 20.5949-46 46-46zm45.6954 7.2814c.3664.3545.4034.9212.1048 1.3185l-.0816.0955-45 46.5c-.3432.3547-.888.4022-1.2849.1289l-.0959-.075-21.5-19c-.4138-.3657-.4529-.9977-.0871-1.4115.3376-.382.902-.4446 1.3123-.1644l.0992.0773 20.7838 18.3663 44.3354-45.8124c.3841-.3969 1.0171-.4073 1.414-.0232z" />
  </svg>
</template>

<script>
export default {
  name: 'ImageSuccess'
}
</script>
