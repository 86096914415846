<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="operations.length">
      <mobile-table v-if="isDeviceMobile"
                    :operations="operations" />
      <component-table v-else
                       class="table--hover table-operations table--fixed"
                       data-cy="operations.table">
        <thead>
          <tr>
            <template v-if="isCheckingAccount">
              <th class="col--sm-1">
                {{ $t("table.date") }}
              </th>
              <th class="col--sm-4">
                {{ $t("table.label") }}
              </th>
              <th class="col--sm-2">
                {{ $t("table.type") }}
              </th>
              <th class="col--sm-2 right">
                {{ $t("table.credit") }}
              </th>
              <th class="col--sm-2 right">
                {{ $t("table.debit") }}
              </th>
              <th v-if="hasPermission($permissions.operationsDocumentsRead)"
                  class="col--sm-1 center">
                {{ $t("table.documents") }}
              </th>
            </template>
            <template v-else-if="isCard">
              <th class="col--sm-1">
                {{ $t("table.date") }}
              </th>
              <th class="col--sm-3">
                {{ $t("table.label") }}
              </th>
              <th class="col--sm-2">
                {{ $t("table.card") }}
              </th>
              <th class="col--sm-2">
                {{ $t("label.card.holder") }}
              </th>
              <th class="col--sm-2 right">
                {{ $t("table.amount") }}
              </th>
              <th v-if="hasPermission($permissions.operationsDocumentsRead)"
                  class="col--sm-1 center">
                {{ $t("table.documents") }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(items, date) in groupedOperations"
                    :key="date">
            <tr class="header-table-section">
              <td colspan="6">
                <span class="arrow">&rsaquo;</span>{{ date }}
              </td>
            </tr>
            <tr v-for="item in items"
                :key="item.uuid"
                class="is-link"
                :class="{ 'text-muted': isOperationPending(item) || isOperationRejected(item) }"
                :data-cy="`operations.item-${item.uuid}`"
                @click="showOperationDetail(item)">
              <template v-if="isCheckingAccount">
                <td class="text-muted nowrap">
                  {{ formatDate(item[sortingDate]) }}
                </td>
                <td class="text-overflow">
                  <div class="d-flex">
                    <core-badge v-if="isOperationPending(item)"
                                class="mr-1"
                                :theme="ECoreBadgeTheme.GrayLow"
                                :fill="ECoreBadgeFill.Shaded"
                                :value="$t('general.to_come')" />
                    <span class="text-overflow col-label">{{ item.short_label ? item.short_label : item.label }}</span>
                  </div>
                </td>
                <td><span class="text-overflow col-type">{{ item.category_label }}</span></td>
                <td class="right">
                  <span v-if="item.type === 'credit'"
                        class="amount text-green">
                    {{ formatAmount(item.amount, item.currency) }}
                  </span>
                </td>
                <td class="right">
                  <span v-if="item.type === 'debit'"
                        class="amount text-red">
                    {{ formatAmount(item.amount, item.currency) }}
                  </span>
                </td>
              </template>
              <template v-else-if="isCard">
                <td class="text-muted nowrap">
                  {{ formatDate(item.created_at) }}
                </td>
                <td class="text-overflow">
                  <div class="d-flex">
                    <core-badge v-if="isOperationPreAuth(item)"
                                :theme="ECoreBadgeTheme.GrayLow"
                                :fill="ECoreBadgeFill.Shaded"
                                class="mr-1"
                                :value="$t('general.pre_authorization')" />
                    <core-badge v-else-if="isOperationPending(item)"
                                v-tooltip="{ content: $t('poptip.operation_to_come.content'), theme: 'poptip' }"
                                :theme="ECoreBadgeTheme.GrayLow"
                                :fill="ECoreBadgeFill.Shaded"
                                :value="$t('general.to_come')"
                                class="mr-1" />
                    <core-badge v-else-if="isOperationRejected(item)"
                                :theme="ECoreBadgeTheme.Danger"
                                :fill="ECoreBadgeFill.Shaded"
                                :value="$t('general.rejected')"
                                class="mr-1">
                      <template #default="{ value }">
                        <TooltipPoptip :value="value"
                                       :title="$t('popover.operation.rejected.title')"
                                       :message="`${$t('popover.operation.rejected.message')}<br>${item.reject_reason}`" />
                      </template>
                    </core-badge>
                    <span class="text-overflow col-label">{{ item.short_label ? item.short_label : item.label }}</span>
                  </div>
                </td>
                <td>**** {{ item.credit_card.last_digits }}</td>
                <td class="col-cardholder">
                  <span class="text-overflow block">{{ item.credit_card.user ? item.credit_card.user.name : '-' }}</span>
                </td>
                <td class="right">
                  <span class="amount"
                        :class="{ 'text-green': item.type === 'credit', 'text-through': isOperationRejected(item) }">
                    {{ formatAmount(item.amount, item.currency, { isNegative: item.type === 'debit' }) }}
                  </span>
                </td>
              </template>
              <td v-if="hasPermission($permissions.operationsDocumentsRead)"
                  class="center">
                <template v-if="(hasPermission($permissions.operationsDocumentsWrite) || (hasPermission($permissions.operationsDocumentsRead) && hasDocument(item))) && (isCard || isCheckingAccount) && !isOperationRejected(item)">
                  <button v-tooltip="{ content: fileTooltip(item), theme: 'tooltip' }"
                          class="btn-link btn-attachment"
                          @click.prevent.stop="documentCheckMethod(item)">
                    <component :is="proofIcon(item)"
                               class="ic ic--24 ic--gray"
                               :class="proofIcon(item)" />
                  </button>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="operationsPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t(emptyPlaceholderLabel)"
                           :content="''" />
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatDate, groupByDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcDocumentInvalid from '@/components/svg/icons/ic-document-invalid.vue'
import IcDocumentOptional from '@/components/svg/icons/ic-document-optional.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'
import ComponentTable from '@/components/Table.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'

import MobileTable from './MobileTable.vue'

export default {
  components: {
    MobileTable,
    TooltipPoptip,
    CoreBadge,
    ComponentPagination,
    ComponentTable,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    type: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    },

    cardUuid: {
      type: [String, null],
      default: null
    },

    emptyPlaceholderLabel: {
      type: String,
      default: 'placeholder.no_operation.title'
    },

    isClaimed: {
      type: Boolean,
      default: false
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const operationStore = useOperationStore()

    const { isDeviceMobile } = storeToRefs(appStore)
    const { operations, operationsPagination } = storeToRefs(operationStore)

    return {
      appStore,
      isDeviceMobile,
      operations,
      operationsPagination,
      operationStore
    }
  },

  data () {
    return {
      store,
      loading: true
    }
  },

  computed: {
    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    groupedOperations () {
      return groupByDate(this.operations, this.sortingDate, { dateFieldFormat: 'YYYY-MM-DD' })
    },

    sortingDate () {
      return this.isCard ? 'value_date' : 'accounting_date'
    },

    isCard () {
      return this.type === 'cards'
    },

    isCheckingAccount () {
      return this.type === 'checking-account'
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'operationsPagination.totalItems' () {
      this.$emit('pagination-total-items', this.operationsPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('operation-update-success', this.reloadData)
    this.$bus.on('on-upload-success', this.reloadData)
    this.$bus.on('delete-success', this.reloadData)
  },

  beforeUnmount () {
    this.$bus.off('operation-update-success', this.reloadData)
    this.$bus.off('on-upload-success', this.reloadData)
    this.$bus.off('delete-success', this.reloadData)
  },

  methods: {
    formatAmount,

    formatDate,

    async initData (showLoader = true) {
      this.loading = showLoader

      const filter = { ...this.filter }
      if (this.isCard) { filter.card = true }
      if (this.cardUuid) { filter.card_uuid = this.cardUuid }
      this.isClaimed ? await this.operationStore.getClaimedOperations(this.page, filter) : await this.operationStore.getOperations(this.page, filter)

      this.loading = false
    },

    reloadData () {
      this.initData(false)
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    showOperationDetail (operation, showFileViewer = false) {
      this.appStore.showSidePanel(
        OperationDetail,
        { model: operation },
        {
          wrapperClass: 'sidepanel--md',
          backgroundOverlay: true,
          canDragAndDrop: !this.isOperationRejected(operation) && !this.isProofBill(operation),
          initShowFile: showFileViewer
        }
      )
    },

    fileTooltip (operation) {
      if (this.isProofLost(operation)) {
        return this.$i18n.t('tooltip.lost_proof')
      }
      if (this.isProofInvalid(operation)) {
        return this.$i18n.t('tooltip.invalid_proof')
      }
      if (this.isProofOptional(operation)) {
        return this.$i18n.t('button.optional_proof')
      }
      if (this.isProofUploaded(operation)) {
        return this.$i18n.tc('tooltip.show_file', operation.documents.length)
      }
      if (this.isProofBill(operation)) {
        return this.$i18n.tc('tooltip.show_file')
      }
      return this.$i18n.t('tooltip.attach_files')
    },

    documentCheckMethod (operation) {
      if (this.isProofLost(operation)) {
        return this.showOperationDetail(operation)
      }
      if (this.isProofUploaded(operation) || this.isProofBill(operation)) {
        return this.showOperationDetail(operation, true)
      }
      return this.appStore.showDropzoneModal(
        this.$i18n.tc('title.attach_file', 2),
        operation,
        { url: `/operations/${operation.uuid}/documents`, canAnalyzeFile: true })
    },

    isOperationPending (operation) {
      return operation.status === 'pending'
    },

    isOperationPreAuth (operation) {
      return operation.is_pre_auth
    },

    isOperationRejected (operation) {
      return operation.status === 'rejected'
    },

    isProofUploaded (operation) {
      return operation.documents.length
    },

    isProofBill (operation) {
      return operation.bill
    },

    isProofInvalid (operation) {
      return operation.documents_status === 'invalid'
    },

    isProofLost (operation) {
      return operation.documents_status === 'lost'
    },

    isProofOptional (operation) {
      return operation.documents_status === 'optional'
    },

    proofIcon (operation) {
      if (this.isProofLost(operation)) {
        return IcWarning
      }
      if (this.isProofInvalid(operation)) {
        return IcDocumentInvalid
      }
      if (this.isProofOptional(operation)) {
        return IcDocumentOptional
      }
      if (this.isProofUploaded(operation) || this.isProofBill(operation)) {
        return IcDocument
      }
      return IcAttachment
    },

    hasDocument (operation) {
      return this.isProofUploaded(operation) || this.isProofBill(operation)
    }
  }
}
</script>
