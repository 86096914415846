<template>
  <div class="document-check document-check--success">
    <div class="document-check__header">
      <ic-check-circle outline
                       class="ic ic--white ic--26" />
      <h1>{{ $t('document_check.title.transfer_proof') }}</h1>
    </div>
    <div class="document-check__content">
      <div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.sender') }}
          </div>
          <div class="response-row__data">
            {{ data.owner }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.label') }}
          </div>
          <div class="response-row__data">
            {{ data.label }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.status') }}
          </div>
          <div class="response-row__data">
            {{ data.status }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.execution_date') }}
          </div>
          <div class="response-row__data">
            {{ formatDateText(data.execution_date) }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.amount') }}
          </div>
          <div class="response-row__data">
            {{ data.amount }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.beneficiary_account') }}
          </div>
          <div class="response-row__data">
            {{ data.beneficiary }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'

import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'

export default {
  components: {
    IcCheckCircle
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatDateText
  }
}
</script>
