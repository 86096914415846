'use strict'

import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { isCardTypeDefined } from '@/helpers/utils/card'
import {
  getWorldlineCardPin,
  getWorldlineCardSecretInformations,
  getWorldlineOrderCardPayload,
  getWorldlineUpdateCardPinPayload
} from '@/helpers/worldline'
import { useAccountStore } from '@/stores/account'
import { ECardProcessor } from '@/types/card.d'

export const useCardStore = defineStore('card', {
  state: () => {
    return {
      cards: [],
      cardsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      cardOrders: []
    }
  },

  actions: {
    async getCards (state, page, physical, params) {
      let cards = []

      params.grp = physical ? 'physical' : 'virtual'
      params.expired = state === 'history'
      params.to_validate = state === 'to_validate'
      params.page = page
      params.except_status = !physical && state === 'done' ? ['order_in_progress'] : null

      try {
        const { data, headers } = await axiosClient.get('/cards', { params })
        this.cardsPagination = this.parsePagination(headers)

        cards = data.filter(isCardTypeDefined)
      } catch (error) {
        console.error(error)
      }

      this.cards = cards
    },

    async getCardOrders (file) {
      this.$reset()

      const payload = new FormData()

      payload.append('files', file)

      try {
        const { data } = await axiosClient.post('/cards/order/import?version=2', payload)
        data.forEach((item, index) => {
          item.selected = true
          item.id = index
          item.card = { ...item.card, invitation: item.invitation }
          delete item.invitation
          item.card.settings.proof_required_delay_label = item.card.settings.proof_required_delay_label || ''
        })
        this.cardOrders = data
      } catch (error) {
        console.error(error)
      }
    },

    editCardOrders (newValues) {
      newValues.forEach(newValue => {
        this.cardOrders = this.cardOrders.map(order => order.id === newValue.id ? newValue : order)
      })
    },

    async orderMultipleCards (orders) {
      try {
        const { data } = await axiosClient.post('/cards/order/batch', orders)
        return data.message
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async requestCardLimitIncrease (uuid, reason) {
      try {
        await axiosClient.post(`/cards/${uuid}/request/increase`, { reason })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getCardPinAccessToken (uuid) {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/pin-access-token`)
        return data.control_token
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardOrderFees (type) {
      try {
        const { data } = await axiosClient.get('/cards/fees', { params: { type } })
        return data.order_fees
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardDeliveryStatus (uuid) {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/delivery-tracking`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardPin (uuid, payload, processor) {
      let cardPayload = cloneDeep(payload)
      try {
        if (processor === ECardProcessor.WORLDLINE) {
          cardPayload = await getWorldlineUpdateCardPinPayload(uuid, cardPayload)
        }
        await axiosClient.post(`/cards/${uuid}/pin`, cardPayload)
        return { success: true, error: null }
      } catch (error) {
        console.error(error)
        return { success: false, error: error.response.data[0]?.message || '' }
      }
    },

    async validateCardRequest (uuid) {
      try {
        await axiosClient.post(`/cards/${uuid}/approve`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async rejectCardRequest (uuid, reason) {
      try {
        await axiosClient.post(`/cards/${uuid}/reject`, { reason })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getNumberOfPendingVirtualCards () {
      const params = {
        expired: false,
        to_validate: true,
        grp: 'virtual'
      }

      try {
        const { headers } = await axiosClient.head('/cards', { params })
        useAccountStore().account.counts.virtual_cards.waiting_validation = this.parsePagination(headers).totalItems
      } catch (error) {
        console.error(error)
      }
    },

    async getNumberOfPendingPhysicalCards () {
      const params = {
        expired: false,
        to_validate: true,
        grp: 'physical'
      }

      try {
        const { headers } = await axiosClient.head('/cards', { params })
        useAccountStore().account.counts.physical_cards.waiting_validation = this.parsePagination(headers).totalItems
      } catch (error) {
        console.error(error)
      }
    },

    async duplicateCard (uuid, label) {
      try {
        await axiosClient.post(`/cards/${uuid}/duplicate`, { label })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getCardPinCode (cardUuid, cvv = null) {
      try {
        await axiosClient.post(`/cards/${cardUuid}/pin`, { cvv })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async toggleLockCard (card) {
      try {
        const verb = ['locked', 'locked_partner', 'locked_proof', 'locked_service'].includes(card.status)
          ? 'unlock'
          : 'lock'
        const { data } = await axiosClient.patch(`/cards/${card.uuid}/${verb}?version=2`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async resetConsumedAmount (cardUuid) {
      try {
        const { data } = await axiosClient.patch(`/cards/${cardUuid}/reset-consumed-amount`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async orderCard (card, processor) {
      let cardPayload = cloneDeep(card)
      try {
        if (processor === ECardProcessor.WORLDLINE && card.pin) {
          cardPayload = await getWorldlineOrderCardPayload(cardPayload)
        }
        const { data } = await axiosClient.post('/cards', cardPayload)
        this.cards = [data, ...this.cards]
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    setCards (cards) {
      this.cards = cards
    },

    async validateOrderCardStep (card, step) {
      const data = {
        first_name: card.first_name,
        last_name: card.last_name,
        phone: card.phone,
        invitation: card.invitation,
        user_uuid: card.user_uuid
      }

      if (step === 1) {
        data.address = card.address
      } else if (step === 2) {
        data.settings = card.settings
      }

      try {
        await axiosClient.post('/cards/validate', data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async cancelAutomaticDeletion (uuid) {
      try {
        await axiosClient.patch(`/cards/${uuid}/cancel-automatic-deletion`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async cancelAutomaticRenewal (uuid) {
      try {
        await axiosClient.patch(`/cards/${uuid}/cancel-automatic-renewal`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async activateCard (cardUuid, reference) {
      const payload = { last_digits: reference }

      try {
        const { data } = await axiosClient.post(`/cards/${cardUuid}/activate?version=2`, payload)
        bus.emit('card-activate-success', data)
      } catch (error) {
        console.error(error)
      }
    },

    async blockCard (cardUuid, reason = null) {
      try {
        const { data } = await axiosClient.patch(`/cards/${cardUuid}/block`, { reason })
        bus.emit('card-block-success', data)
      } catch (error) {
        console.error(error)
      }
    },

    async unlockAllCards (grp) {
      const params = {
        grp
      }

      try {
        const { data } = await axiosClient.post('/cards/unlock', {}, { params })
        bus.emit('cards-unlock-success', data.message)
      } catch (error) {
        console.error(error)
      }
    },

    async getCard (cardUuid) {
      try {
        const { data } = await axiosClient.get(`/cards/${cardUuid}?version=2`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async lockAllCards (owner, grp) {
      const params = {
        grp,
        owner
      }

      try {
        const { data } = await axiosClient.post('/cards/lock', {}, { params })
        bus.emit('cards-lock-success', data.message)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async updateSettings (cardUuid, settings) {
      try {
        await axiosClient.put(`/cards/${cardUuid}/settings?version=2`, settings)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async updateSmartCardSettings (cardUuid, settings) {
      try {
        const { data } = await axiosClient.put(`/cards/${cardUuid}/smart-card`, {
          nfc_tag: settings
        })
        return data.nfc_tag
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async deleteSmartCardFile (cardUuid) {
      try {
        await axiosClient.delete(`/cards/${cardUuid}/file`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async updateSmartCardFile (cardUuid, file) {
      const payload = new FormData()
      payload.append('files', file)
      try {
        const { data } = await axiosClient.post(`/cards/${cardUuid}/file`, payload)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getSmartCard (cardUuid) {
      try {
        const { data } = await axiosClient.get(`/cards/${cardUuid}/smart-card`, { doNotShowBanner: true })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async postSmartCardPicture (cardUuid, picture) {
      const payload = new FormData()
      payload.append('files', picture, 'avatar.png')

      try {
        const { data } = await axiosClient.post(`/cards/${cardUuid}/picture`, payload)
        return { success: true, data }
      } catch (error) {
        console.error(error)
        return { success: false }
      }
    },

    async deleteSmartCardPicture (cardUuid) {
      try {
        await axiosClient.delete(`/cards/${cardUuid}/picture`, { doNotShowBanner: true })
        return { success: true, data: null }
      } catch (error) {
        console.error(error)
        return { success: false }
      }
    },

    async getCardLastExpenses (cardUuid) {
      const params = {
        card: true,
        card_uuid: cardUuid,
        limit: 3
      }

      try {
        const { data } = await axiosClient.get('/operations', { params })
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getClientAccessToken (cardUuid, type) {
      try {
        const { data } = await axiosClient.get(`/cards/${cardUuid}/client-access-token`, { params: { data: type } })
        return data.token
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getExpenses (uuid) {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/expenses`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateControlRuleSettings (uuid, controlRule) {
      try {
        const { data } = await axiosClient.post(`/cards/${uuid}/control`, controlRule)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getAvailableExpirationDates (cardType) {
      try {
        const { data } = await axiosClient.get(`/cards/available-expiration-dates?card_type=${cardType}`)
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async updateAddress (uuid, address) {
      try {
        await axiosClient.put(`/cards/${uuid}/address`, address)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getHolidaysCountries () {
      try {
        const { data } = await axiosClient.get('/cards/settings/countries-holidays')
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async initWallet (cardUuid, form) {
      try {
        const { data } = await axiosClient.post(`/cards/${cardUuid}/web-wallet`, form)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    getWorldlineCardPin,
    getWorldlineCardSecretInformations
  }
})
