<template>
  <div class="placeholder-action-description">
    <component-placeholder :label="$t('placeholder.sca_validation_failed.title')"
                           :content="getPlaceholderContent">
      <image-mobile-app v-if="['app', 'sms'].includes(type)"
                        problem />
      <image-computer v-else-if="type === 'email'"
                      problem />
    </component-placeholder>
    <div class="center">
      <component-button wrapper-class="btn btn--default placeholder-btn"
                        :label="$t('button.try_again')"
                        :disabled="loading"
                        @click="onClick" />
    </div>
  </div>
</template>

<script>
import store from '@/config/store'
import { useScaStore } from '@/stores/sca'

import ComponentButton from '@/components/Button.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageComputer from '@/components/svg/img/computer.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'

export default {
  components: {
    ComponentButton,
    ComponentPlaceholder,
    ImageComputer,
    ImageMobileApp
  },

  props: {
    type: {
      type: String,
      required: true
    },

    challenge: {
      type: Object,
      default: null
    }
  },

  emits: ['action'],

  setup () {
    const scaStore = useScaStore()

    return { scaStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  computed: {
    getPlaceholderContent () {
      return this.challenge
        ? this.$i18n.t('placeholder.sca_validation_failed.subtitle')
        : this.$i18n.t('placeholder.sca_validation_failed.subtitle_configuration')
    }
  },

  methods: {
    async onClick () {
      this.loading = true
      let data = null
      if (this.challenge) {
        data = await this.scaStore.triggerScaChallenge(this.challenge.id)
      } else {
        await this.scaStore.initScaConfiguration(this.type)
      }
      this.loading = false
      this.$emit('action', 'send-invitation', data)
    }
  }
}
</script>
