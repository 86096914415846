<template>
  <div ref="sidepanelContent"
       class="sidepanel__content sidepanel-add-beneficiaries">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('advance_payment.form.add_beneficiaries.title') }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <div v-if="!filteredBeneficiaries.length && !search"
             class="advance-payment--placeholder-wrapper">
          <component-placeholder :label="$t('advance_payment.no_beneficiary')"
                                 :content="$t('advance_payment.placeholder.no_beneficiary')">
            <users-management grey />
          </component-placeholder>
        </div>
        <template v-else>
          <p>{{ $t('advance_payment.form.add_beneficiaries.subtitle') }}</p>
          <div class="bordered-box mt-4">
            <component-filters :list-filter="[EFilter.Search]"
                               default-visible
                               :number-of-results="beneficiariesPagination.totalItems" />
            <ul class="scrollable-list">
              <li v-for="(beneficiary, index) in filteredBeneficiaries"
                  :key="index"
                  @click="toggleSelectBeneficiary(beneficiary)">
                <span class="col-checkbox">
                  <label class="checkbox">
                    <input type="checkbox"
                           name="checkbox"
                           :checked="isBeneficiarySelected(beneficiary)"
                           @click="toggleSelectBeneficiary(beneficiary)">
                    <span class="checkbox__icon" />
                  </label>
                </span>
                <span>
                  <span :class="{ bold: isBeneficiarySelected(beneficiary) }">{{ beneficiary.label }} </span> <br>
                  <span class="text-muted">{{ formattedIban(beneficiary) }}</span>
                </span>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="row row--sm-bottom">
        <div class="col col--sm right">
          <div class="form-buttons">
            <button class="btn btn--gray"
                    @click.prevent.stop="appStore.closeSidePanel">
              {{ $t('button.cancel') }}
            </button>
            <component-button :label="$t('button.validate')"
                              :disabled="!selectedBeneficiaries.length"
                              wrapper-class="btn btn--primary"
                              @click="onSubmit" />
          </div>
        </div>
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatIban } from '@/helpers/utils/iban'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useTransferStore } from '@/stores/transfer'
import { EFilter } from '@/types/filter.d'

import ComponentButton from '@/components/Button.vue'
import ComponentFilters from '@/components/Filter.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import UsersManagement from '@/components/svg/img/users-management.vue'

export default {
  components: {
    UsersManagement,
    ComponentButton,
    ComponentPlaceholder,
    ComponentFilters,
    ComponentSidepanelFooter
  },

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()
    const transferStore = useTransferStore()

    const { beneficiaries, beneficiariesPagination } = storeToRefs(beneficiaryStore)
    const { advancePayments } = storeToRefs(transferStore)

    return { appStore, beneficiaries, beneficiaryStore, advancePayments, transferStore, beneficiariesPagination }
  },

  data () {
    return {
      store,
      selectedBeneficiaries: [],
      search: false,
      filter: {
        search: null
      },

      showSearchResult: false
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    filteredBeneficiaries () {
      return this.beneficiaries.filter(elem => !this.advancePayments.find(e => e.beneficiary.id === elem.id))
    }
  },

  mounted () {
    this.$bus.on('filters', this.filters)
  },

  beforeUnmount () {
    this.$bus.off('filters', this.filters)
  },

  methods: {
    async initData () {
      this.filters({ search: null })
    },

    filters (filters) {
      this.beneficiaryStore.getBeneficiaries({
        advance_payment_option: false,
        search: filters.search
      })
      this.search = true
      this.filter = {
        search: filters.search
      }

      this.showSearchResult = !!filters.search
    },

    formattedIban (beneficiary) {
      if (beneficiary.account_number_formatted) {
        return beneficiary.account_number_formatted
      } else if (beneficiary.iban) {
        return formatIban(beneficiary.iban)
      }
    },

    isBeneficiarySelected (beneficiary) {
      return this.selectedBeneficiaries.find(elem => elem === beneficiary.id)
    },

    async onSubmit () {
      await this.transferStore.getAdvancePayments({ beneficiaries: this.selectedBeneficiaries })
      this.appStore.closeSidePanel()
    },

    toggleSelectBeneficiary (beneficiary) {
      if (this.isBeneficiarySelected(beneficiary)) {
        const index = this.selectedBeneficiaries.findIndex(elem => elem === beneficiary.id)
        this.selectedBeneficiaries.splice(index, 1)
      } else {
        this.selectedBeneficiaries.push(beneficiary.id)
      }
    }
  }
}

</script>

<style scoped lang="stylus">
.sidepanel-add-beneficiaries
  .section__filters
    margin 0
    background-color $colorWhite
    border none
    box-shadow none
    border-bottom solid 1px $borderColor
    border-radius 0

    &--no-results
      border-bottom none
</style>
