<template>
  <div>
    <div :id="`dropzone-${uuid}`"
         data-cy="dropzone"
         class="dropzone--parent"
         :class="{ 'dropzone--sm': isDropzoneSm }"
         @click="$emit('click')">
      <div v-if="showFilesTop"
           class="dropzone__attachments mb-2">
        <file-item-row v-for="(file, index) in dropzone?.files"
                       v-bind="itemOptions"
                       :key="index"
                       :error="getErrorFile(file)"
                       :file="file"
                       class="ml-0 mr-0"
                       @delete="onDelete">
          <slot />
        </file-item-row>
      </div>
      <component-alert v-if="hasError && showFilesTop"
                       :type="hasError.type"
                       :message="hasError.alert"
                       class="mt-1" />
      <div v-show="showDropzone"
           class="dropzone__inner dz-default dz-message">
        <div v-if="showFilesInDropzone"
             class="dropzone__attachments">
          <file-item-row v-for="(file, index) in dropzone?.files"
                         v-bind="itemOptions"
                         :key="index"
                         :error="getErrorFile(file)"
                         :file="file"
                         @delete="onDelete">
            <slot />
          </file-item-row>
        </div>
        <div v-show="showDropzoneHelper"
             class="dropzone__helper">
          <file-upload v-if="!isDropzoneSm"
                       class="picto-file-upload"
                       width="160"
                       height="160" />
          <ic-upload-dropzone-sm v-else
                                 class="ic--gray ic--32 picto-file-upload-sm" />
          <h3 v-if="showDropzoneHelperTitle"
              class="dropzone__helper-title">
            {{ textOptions.title }}
          </h3>
          <p class="dropzone__helper-content">
            {{ helperContent }}
          </p>
        </div>
      </div>
      <component-alert v-if="hasError && showDropzone && showFilesInDropzone"
                       :type="hasError.type"
                       :message="hasError.alert"
                       class="mt-1" />
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone'
import { cloneDeep, first } from 'lodash'
import { uniqueId } from 'lodash/util'
import mime from 'mime-types'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatDateText } from '@/helpers/utils/date'
import { formatFileSize } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useDocumentStore } from '@/stores/document'
import { useFirebaseStore } from '@/stores/firebase'

import ComponentAlert from '@/components/Alert.vue'
import FileItemRow from '@/components/FileItemRow.vue'
import Reconnect from '@/components/modals/Reconnect.vue'
import IcUploadDropzoneSm from '@/components/svg/icons/ic-upload-dropzone-sm.vue'
import FileUpload from '@/components/svg/img/file-upload.vue'

export default {
  name: 'ComponentDropzone',

  components: {
    FileItemRow,
    ComponentAlert,
    IcUploadDropzoneSm,
    FileUpload
  },

  props: {
    acceptedFiles: {
      type: String,
      required: false,
      default: ''
    },

    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },

    mode: {
      type: String,
      required: true,
      validator: value => ['inline', 'modal', 'large'].includes(value)
    },

    url: {
      type: String,
      default: ''
    },

    maxFiles: {
      type: [Number, null],
      default: null
    },

    maxFilesize: {
      type: [Number, null],
      default: 25
    },

    textOptions: {
      type: Object,
      default: () => ({})
    },

    itemOptions: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['click', 'on-file-added', 'input', 'has-error'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const firebaseStore = useFirebaseStore()
    const documentStore = useDocumentStore()

    const { account } = storeToRefs(accountStore)
    const { dropzoneQueue } = storeToRefs(documentStore)
    const { user } = storeToRefs(authStore)

    return {
      appStore,
      documentStore,
      firebaseStore,

      account,
      dropzoneQueue,
      user
    }
  },

  data () {
    return {
      store,
      dropzone: null,
      willSubmit: false,
      filesError: [],
      uuid: uniqueId(),
      config: {
        timeout: 0,
        paramName: 'files',
        autoProcessQueue: false,
        uploadMultiple: (this.maxFiles === null || this.maxFiles > 1),
        maxFiles: this.maxFiles,
        maxFilesize: this.maxFilesize,
        url: this.url.startsWith('http') ? this.url : `${store.api.base}${this.url}`,
        headers: { Authorization: `Bearer ${this.user.token}`, 'Accept-Language': this.$i18n.locale, 'X-Mone-Account-ID': this.account?.uuid },
        acceptedFiles: this.acceptedFiles || null,
        method: 'post',
        parallelUploads: 99,
        disablePreviews: true,
        dictDefaultMessage: this.$t('dropzone.errors.dict_default_message'),
        dictFallbackMessage: this.$t('dropzone.errors.dict_fallback_message'),
        dictFallbackText: this.$t('dropzone.errors.dict_fallback_text'),
        dictResponseError: this.$t('dropzone.errors.dict_response_error'),
        dictCancelUpload: this.$t('dropzone.errors.dict_cancel_upload'),
        dictCancelUploadConfirmation: this.$t('dropzone.errors.dict_cancel_upload_confirmation'),
        dictRemoveFile: this.$t('dropzone.errors.dict_remove_file'),
        dictMaxFilesExceeded: 'dictMaxFilesExceeded',
        dictFileTooBig: 'dictFileTooBig',
        dictInvalidFileType: 'dictInvalidFileType',
        processing: function () {
          useApiStore().setLoading(true)
        },

        success: function (file, response, e) {
          const newToken = e.target.getResponseHeader('x-authorization-token')
          if (newToken) { useAuthStore().replaceToken(newToken) }

          useApiStore().setLoading(false)
        },

        error: (file, response) => {
          if (response.status === 401) {
            useAppStore().showModal(Reconnect, {}, { wrapperClass: 'modal--sm', onBackgroundLeaveAction: true })
          }

          if (file?.upload?.uuid) this.filesError.push(this.parseError((typeof response === 'string') ? response : response.message, file))

          this.documentStore.setDropzoneQueue(false)
          this.willSubmit = false

          useApiStore().setLoading(false)
        }
      }
    }
  },

  computed: {
    showFiles () {
      return this.dropzone?.files?.length
    },

    showFilesInDropzone () {
      return this.showFiles && this.mode === 'modal'
    },

    showFilesTop () {
      return this.showFiles && (this.isInline || (this.isLarge && this.maxFiles === 1 && this.showFiles === 1))
    },

    showDropzone () {
      return (this.isInline && (this.maxFiles > this.showFiles || !this.showFiles)) || this.isModal || this.isLarge
    },

    showDropzoneHelper () {
      return this.isInline || this.isLarge || !this.showFiles
    },

    showDropzoneHelperTitle () {
      return this.textOptions.title && !this.isInline && !this.showFiles
    },

    isDropzoneSm () {
      return this.isInline || (this.isLarge && this.showFiles)
    },

    fileSize () {
      return formatFileSize(this.modelValue[0].size)
    },

    isInline () {
      return this.mode === 'inline'
    },

    isModal () {
      return this.mode === 'modal'
    },

    isLarge () {
      return this.mode === 'large'
    },

    multipleFiles () {
      return this.maxFiles === null || this.maxFiles > 1
    },

    helperContent () {
      return this.textOptions.content ? this.textOptions.content : (this.$tc('dropzone.content', this.multipleFiles ? 2 : 1))
    },

    hasError () {
      return first(this.filesError)
    },

    extensionsAllowed () {
      const extensions = []

      this.acceptedFiles.split(',').forEach(mimeType => {
        extensions.push(mime.extension(mimeType))
      })

      return extensions.filter(mime => mime).join(', ')
    }
  },

  watch: {
    dropzoneQueue: function () {
      if (this.dropzoneQueue) {
        if (this.dropzone.getQueuedFiles().length) {
          this.dropzone.processQueue()
        } else {
          this.dropzoneQueue = false
        }
      }
    },

    modelValue () {
      if (this.modelValue && !this.modelValue.length) { // reset display
        this.populateDropzone()
      }
    },

    filesError: {
      handler () {
        this.$emit('has-error', this.filesError?.length)
      },

      deep: true,
      immediate: true
    }
  },

  async mounted () {
    const config = this.config

    const appCheckToken = await this.firebaseStore.getAppCheckToken()
    if (appCheckToken) {
      config.headers['X-Firebase-AppCheck'] = appCheckToken
    }

    if (import.meta.env.VITE_CLIENT_BRAND) { config.headers['X-Client-Brand'] = import.meta.env.VITE_CLIENT_BRAND }

    if (import.meta.env.VITE_API_PROXY_URL && config.url.startsWith(import.meta.env.VITE_API_PROXY_URL)) {
      // if we are in proxy mode, ensure api url are proxy
      config.url = config.url.replace(import.meta.env.VITE_API_PROXY_URL, '/api')
    }

    this.dropzone = new Dropzone(`#dropzone-${this.uuid}`, this.config)
    this.populateDropzone()

    this.dropzone.on('removedfile', this.checkCanSubmit)
    this.dropzone.on('addedfile', this.checkCanSubmit)
    this.dropzone.on('success', this.onUploadSuccess)
  },

  beforeUnmount () {
    this.dropzone.hiddenFileInput.remove()
  },

  methods: {
    formatDateText,

    checkCanSubmit () {
      if (!this.multipleFiles && this.dropzone.files.length > 1) {
        this.onDelete(this.dropzone.files[0])
      }

      this.dropzone.files = cloneDeep(this.dropzone.files)

      this.$emit('on-file-added', this.dropzone.files)
      this.willSubmit = true
      this.setMaxFileError()
    },

    onUploadSuccess (file, response) {
      this.$bus.emit('on-upload-success', response)
    },

    populateDropzone () {
      this.dropzone.removeAllFiles(true)
      if (this.modelValue && this.modelValue.length) {
        this.modelValue.forEach(file => {
          const mockFile = {
            name: file.name || file.filename,
            size: file.size,
            url: file.url,
            type: file.mime_type,
            id: file.id
          }
          this.dropzone.files.push(mockFile)
          this.dropzone.emit('addedfile', mockFile)
        })
        this.setMaxFileError()
      }
    },

    setMaxFileError () {
      if (this.showFiles > this.maxFiles && this.maxFiles !== null) {
        this.filesError.push(this.parseError('dictMaxFilesExceeded', this.dropzone.files[0]))
      } else {
        this.filesError = this.filesError.filter(obj => obj.error !== 'dictMaxFilesExceeded')
      }
    },

    onDelete (file) {
      this.filesError = this.filesError.filter(error => error?.uuid !== file?.upload?.uuid || error?.id !== file?.id)

      this.setMaxFileError()

      this.dropzone.removeFile(file)
    },

    parseError (error, file) {
      let object = {}

      switch (error) {
        case 'dictMaxFilesExceeded':
          object = {
            alert: this.$tc('dropzone.errors.max_files_exceeded', this.maxFiles),
            error,
            type: 'warning'
          }
          break
        case 'dictFileTooBig':
          object = {
            type: 'error',
            tooltip: this.$t('dropzone.errors.tooltip.file_too_big'),
            tooltipContent: this.$tc('dropzone.errors.dict_file_too_big', 0, { fileSize: formatFileSize(file.size), maxFileSize: this.maxFilesize }),
            alert: this.$tc('dropzone.errors.dict_error_default', !this.multipleFiles)
          }
          break
        case 'dictInvalidFileType':
          object = {
            type: 'error',
            tooltip: this.$t('dropzone.errors.tooltip.invalid_file_type'),
            tooltipContent: this.$t('dropzone.errors.dict_invalid_file_type', { n: this.extensionsAllowed }),
            alert: this.$tc('dropzone.errors.dict_error_default', !this.multipleFiles)
          }
          break
        default:
          object = {
            type: 'error',
            alert: this.$tc('dropzone.errors.dict_error_default', !this.multipleFiles)
          }
      }

      if (file?.upload?.uuid) return { ...object, ...{ uuid: file?.upload?.uuid } }
      return { ...object, ...{ id: file?.id } }
    },

    getErrorFile (file) {
      return this.filesError.find(error => error.uuid === file?.upload?.uuid)
    }
  }
}
</script>
