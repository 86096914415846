<template>
  <div class="input-group">
    <validation-field v-bind="$attrs"
                      :class="inputClass"
                      :model-value="modelValue"
                      :name="name"
                      @input="onInput" />
    <div v-if="isDisabled"
         class="input-group__addon">
      <ic-cadenas-sm class="ic ic--16 ic--off" />
    </div>
  </div>
</template>

<script>
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'

export default {
  name: 'ComponentLockInput',
  components: {
    IcCadenasSm
  },

  inheritAttrs: false,

  props: {
    inputClass: {
      type: String,
      required: true
    },

    modelValue: {
      type: [String, null],
      default: null
    },

    name: {
      type: String,
      default: 'lock-input'
    }
  },

  emits: ['update:modelValue'],

  computed: {
    isDisabled () {
      return this.$attrs.disabled || this.$attrs.disabled === ''
    }
  },

  methods: {
    onInput (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>
