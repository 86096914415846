<template>
  <div v-if="isItemObject"
       class="dropdown-menu__wrapper">
    <template v-for="(item, index) in filteredValues"
              :key="index">
      <hr v-if="['deleted', 'blocked', 'cancelled'].includes(item.status)"
          class="dropdown-menu__divider">
      <div :ref="itemRef(item)"
           class="dropdown-menu__item"
           :class="{
             selected: isSelected(item),
             disabled: (item.isDisabled || item.deleted_at) && !isSelected(item),
             'gray-user': ['deleted', 'blocked', 'cancelled'].includes(item.status)
           }"
           :data-cy="`dropdown-${dataCy}.item-${index}`"
           @click="onSelect(item)">
        <ic-category v-if="hasIcon"
                     v-bind="{ [item.name]: true }"
                     class="ic ic-category ic--gray" />
        <user-avatar v-if="hasItemPicture"
                     :picture="item.picture"
                     :placeholder-label="item.name || item.label" />
        <slot name="prefix"
              v-bind="item" />
        <!-- eslint-disable vue/no-v-html -->
        <div v-tooltip.bottom-start="{ content: item.deleted_at && !isSelected(item) ? deletedTooltipMessage : null, theme: 'tooltip' }"
             class="dropdown-menu__item__label"
             v-html="labelFilter(item) || item.label" />
        <!-- eslint-enable -->
        <span v-if="['deleted', 'blocked', 'cancelled'].includes(item.status)"
              class="dropdown-menu__item__right text-italic">{{ item.status_label }}</span>
        <button v-if="!item.default && !item.deleted_at && canAddItem"
                v-tooltip="{ content: apiLoading ? false : $t('tooltip.delete'), theme: 'tooltip' }"
                type="button"
                class="dropdown-menu__item__right"
                data-tooltip-index="9999"
                @click.prevent.stop="onDelete(item)">
          <ic-remove class="ic ic--16 ic--gray" />
        </button>
      </div>
    </template>
    <div v-if="$parent.asyncUrl && !$parent.isLastPage && !isYouUser">
      <button v-if="!$parent.loading"
              class="btn--block btn--sm text-muted"
              @click="getNextPage">
        <div class="dropdown-menu__item__label block">
          {{ $t('button.see_more') }}
        </div>
      </button>
      <loader-spinner v-else
                      size="20" />
    </div>
  </div>
  <div v-else>
    <div v-for="(item, index) in filteredValues"
         :ref="itemRef(item)"
         :key="index"
         :data-cy="`dropdown-${dataCy}.item-${index}`"
         class="dropdown-menu__item"
         :class="{ selected: isSelected(item) }"
         @click.prevent.stop="onSelect(item)">
      <div class="dropdown-menu__item__label"
           v-html="getLabelType(item)" />
    </div>
    <div v-if="$parent.hasOther"
         class="dropdown-menu__item"
         :class="{ selected: isSelected(-1) }"
         @click.prevent.stop="onSelect(-1)">
      <div class="dropdown-menu__item__label">
        {{ $t('general.other') }}
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcCategory from '@/components/svg/icons/ic-category.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

import UserAvatar from './UserAvatar.vue'

export default {
  components: {
    IcRemove,
    UserAvatar,
    LoaderSpinner,
    IcCategory
  },

  props: {
    canAddItem: {
      type: Boolean,
      default: false
    },

    hasIcon: {
      type: Boolean,
      default: false
    },

    labelFilter: {
      type: Function,
      required: false,
      default: item => {
        return item.label || item
      }
    },

    filteredValues: {
      type: Array,
      default: () => []
    },

    hasItemPicture: {
      type: Boolean,
      required: false,
      default: false
    },

    isYouUser: {
      type: Boolean,
      required: false,
      default: false
    },

    dataCy: {
      type: String,
      default: 'default'
    }
  },

  setup () {
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const operationStore = useOperationStore()

    const { loading: apiLoading } = storeToRefs(apiStore)

    return {
      appStore,
      operationStore,

      apiLoading
    }
  },

  computed: {
    isItemObject () {
      if (this.$parent.isValuesObject) {
        return false
      }
      return typeof this.filteredValues[0] === 'object'
    },

    isAffectationDropdown () {
      return this.$parent.label === 'affectations'
    },

    deletedTooltipMessage () {
      if (this.isAffectationDropdown) {
        return this.$i18n.t('tooltip.affectation_deleted')
      }
      return null
    }
  },

  methods: {
    reset () {
      this.$parent.reset()
    },

    onSelect (value) {
      if ((value.isDisabled || value.deleted_at) && !this.isSelected(value)) {
        return
      }
      this.$parent.onSelect(value)
    },

    onDelete (item) {
      if (['categories', 'affectations'].includes(this.$parent.label) && !item.temp) {
        this.$parent.waitingEmitter = true
        this.showDelete(item)
      } else {
        this.$parent.onDelete(item.id)
      }
    },

    async showDelete (item) {
      let filter, url, label, content, event
      if (this.$parent.label === 'categories') {
        filter = { expense_category: item.id }
        url = `/categories/${item.id}`
        label = this.$i18n.t('prompt.category.delete.title')
        const numberOfOperations = await this.operationStore.getNumberOfOperations(filter)
        content = this.$i18n.tc('prompt.category.delete.content', numberOfOperations, { label: item.label })
        event = 'category'
      } else {
        filter = { affectation_id: item.id }
        url = `/affectations/${item.id}`
        label = this.$i18n.t('prompt.affectation.delete.title')
        const numberOfOperations = await this.operationStore.getNumberOfOperations(filter)
        content = this.$i18n.tc('prompt.affectation.delete.content', numberOfOperations, { label: item.label })
        event = 'affectation'
      }
      this.appStore.showDeleteModal({
        label,
        content,
        cancel: 'button.cancel',
        submit: 'button.delete',
        url,
        event,
        id: item.id
      })
    },

    getLabelType (value) {
      return this.$parent.getLabelType(value)
    },

    isSelected (item) {
      return this.$parent.isSelected(item)
    },

    getNextPage () {
      this.$parent.getNextPage()
    },

    itemRef (item) {
      const field = ['uuid', 'id', 'value'].find(i => item[i])
      return field ? `item${item[field]}` : `item${item}`
    }
  }
}
</script>

<style scoped lang="stylus">
.dropdown-menu__wrapper
  .dropdown-menu__divider:not(:first-of-type)
    display none
</style>
