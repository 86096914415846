<template>
  <validation-form ref="form"
                   v-slot="{ errors, isSubmitting }"
                   class="form form--centered form--w1000"
                   :validation-schema="schema"
                   @keydown.enter.prevent
                   @submit="openAdditionalAccount">
    <component-alert type="info"
                     :message="$i18n.t('alerts.account_creation_price') "
                     :show-api-messages="false" />
    <fieldset class="form__fieldset">
      <group-title :title="$t('create_additional_account.form.title')"
                   :subtitle="$t('create_additional_account.form.subtitle')" />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.account_label }">
            <label class="label input__label"
                   for="accountLabel">{{ $t('form.create_account.name.label') }}</label>
            <validation-field id="accountLabel"
                              v-model="accountLabel"
                              type="text"
                              :placeholder="$t('form.create_account.name.placeholder')"
                              class="form-control form-control--noborder"
                              name="account_label" />
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.billing_type }">
            <label class="label input__label">{{ $t('menu.billing') }}</label>
            <validation-field name="billing_type"
                              :model-value="billingType.value">
              <component-dropdown label="billingTypes"
                                  dropdown-menu-class="dropdown-menu--noborder"
                                  dropdown-width="100%"
                                  :model="billingType.value || null"
                                  :values="billingTypes"
                                  @select="onSelectBillingType">
                <template #trigger>
                  <div class="select">
                    <div class="form-control form-control--noborder"
                         :class="{ 'text-muted': !billingType.value }">
                      <template v-if="billingType.value">
                        {{ billingType.label }}
                      </template>
                      <template v-else>
                        {{ $t('form.create_account.billing.placeholder') }}
                      </template>
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="form-buttons">
      <button class="btn btn--gray"
              type="button"
              @click="cancel">
        {{ $t('button.cancel') }}
      </button>
      <component-button type="submit"
                        class="btn btn--primary"
                        :disabled="isSubmitting"
                        :label="$t('button.open_new_account')" />
    </div>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { EAccountBillable, EAccountType } from '@/types/account.d'

import CreateSubAccountValidation from '@/pages/account/create-sub-account/modal/CreateSubAccountValidation.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'

export default {
  components: {
    ComponentAlert,
    ComponentButton,
    ComponentDropdown,
    GroupTitle
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return {
      account,
      accountStore,
      appStore
    }
  },

  data () {
    return {
      accountLabel: null,
      billingType: {},
      schema: yup.object({
        account_label: yup.string().required(),
        billing_type: yup.string().required()
      })
    }
  },

  computed: {
    billingTypes () {
      return [
        {
          label: this.$i18n.t('form.create_account.billing.main_account'),
          value: EAccountBillable.BillableOnMain
        },
        {
          label: this.$i18n.t('form.create_account.billing.sub_account'),
          value: EAccountBillable.Billable
        }
      ]
    }
  },

  methods: {
    cancel () {
      this.$router.push({ name: 'create-sub-account' })
    },

    onSelectBillingType (billingType) {
      this.billingType = { ...billingType }
    },

    async openAdditionalAccount () {
      const account = await this.accountStore.createNewSubAccount({
        billable: this.billingType.value,
        company_id: this.account.company.id,
        label: this.accountLabel,
        type: EAccountType.Sub
      })

      if (account) {
        this.$bus.emit('sub-account-created')
        this.appStore.showModal(
          CreateSubAccountValidation,
          { newAccount: account },
          { blockLeaveBackground: true }
        )
      }
    }
  }
}
</script>
