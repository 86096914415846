import Dexie, { Table } from 'dexie'

export interface ProfilePicture {
  url: string
  updated_at: number
  data: Blob
}

export class MoneDexie extends Dexie {
  pictures!: Table<ProfilePicture>

  constructor () {
    super('mone')

    this.version(1).stores({
      pictures: '&url'
    })
  }
}

export const db = new MoneDexie()
