<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-body">
        <div class="sidepanel-details">
          <card-item :card="card"
                     :clickable="false"
                     :native-click="false" />
          <router-link v-if="hasCardSettingsReadFeature"
                       class="btn btn--outline btn--block btn--sm"
                       style="width: 300px; margin: -1rem auto 3rem auto;"
                       data-cy="validate-card-request.settings-button"
                       :to="cardSettingsRedirect">
            <span class="btn-icon btn-icon--left">
              <ic-settings class="ic ic--16" />
            </span>
            <span class="btn__label">{{ $t('order_card.step.card_settings') }}</span>
          </router-link>
          <transaction-user :pictures="[card.user?.picture]"
                            :user-names="[cardOwnerName]"
                            :text="$t('label.card.holder')" />
          <h3 class="head-title">
            {{ $t('sidepanel.card.order.title') }}
          </h3>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('general.created_at_f') }}
            </div>
            <div class="entry-value right">
              {{ formatDateText(card.created_at, 'LLL') }}
            </div>
          </div>
          <div v-if="card.metadata && card.metadata.reason"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('general.reason') }}
            </div>
            <div class="entry-value right">
              {{ card.metadata.reason }}
            </div>
          </div>
          <div v-if="!isCardClassic && getAmount"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ isRecurringCard ? $t('form.amount.label') : $t('form.virtual_cards.cap.label') }}
            </div>
            <div class="entry-value right">
              {{ formatAmount(getAmount, account.currency) }}
            </div>
          </div>
          <div v-if="isRecurringCard && card.settings.periodicity"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t("general.periodicity") }}
            </div>
            <div class="entry-value right capitalize">
              {{ $t(`generate_ecard.choice.recurring.type.${card.settings.periodicity}`) }}
            </div>
          </div>
          <div v-if="card.expiration_date"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('label.card.expiration_end_long') }}
            </div>
            <div class="entry-value right"
                 style="text-transform: capitalize">
              {{ formattedExpirationDateFull }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div v-if="hasPermission($permissions.cardsVirtualValidate, card)"
           class="btn-grouped">
        <component-button data-cy="validate-card-request.reject-button"
                          :label="$t('button.refuse')"
                          wrapper-class="btn btn--sm btn--gray"
                          @click="onRejectCardRequest" />
        <component-button data-cy="validate-card-request.validate-button"
                          :label="$t('button.validate')"
                          wrapper-class="btn btn--sm btn--primary"
                          @click="onValidateCardRequest" />
      </div>
      <button v-else-if="hasCardBlockFeature"
              class="btn btn--outline-red btn--block"
              type="button"
              @click="deleteCardRequest">
        {{ $t('button.cancel_card_request') }}
      </button>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { isClassicVirtualCard, isRecurringVirtualCard, isVirtualCard } from '@/helpers/utils/card'
import { formatDateText } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import CardItem from '@/pages/cards/components/CardItem.vue'
import BlockCard from '@/pages/cards/modal/BlockCard.vue'
import RejectCardRequest from '@/pages/cards/modal/RejectCardRequest.vue'

import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'
import TransactionUser from '@/components/TransactionUser.vue'

dayjs.extend(customParseFormat)

export default {
  components: {
    TransactionUser,
    IcSettings,
    ComponentButton,
    ComponentSidepanelFooter,
    LoaderSpinner,
    CardItem
  },

  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore, cardStore }
  },

  data () {
    return {
      loading: true,
      submitLoading: true,
      showSettings: false,
      card: {}
    }
  },

  computed: {
    cardOwnerName () {
      return `${this.card.first_name} ${this.card.last_name}`.trim()
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.card)
    },

    isCardClassic () {
      return isClassicVirtualCard(this.card)
    },

    formattedExpirationDateFull () {
      return dayjs(this.card.expiration_date, 'MM/YY').format('MMMM YYYY')
    },

    getAmount () {
      return this.isRecurringCard ? this.card.settings.payment[this.card.settings.periodicity].limit : this.card.settings.initial_amount
    },

    hasCardBlockFeature () {
      return hasFeature(EFeature.CardBlock, this.card)
    },

    hasCardSettingsReadFeature () {
      return hasFeature(EFeature.CardSettings, this.card)
    },

    cardSettingsRedirect () {
      return isVirtualCard(this.card)
        ? { name: 'virtual-card-request-settings', params: { uuid: this.card.uuid } }
        : { name: 'physical-card-request-settings', params: { uuid: this.card.uuid } }
    }
  },

  async created () {
    this.loading = true
    this.card = await this.cardStore.getCard(this.uuid)
    if (this.card) {
      this.loading = false
    }
  },

  mounted () {
    this.$bus.on('card-block-success', this.onBlockCardSuccess)
  },

  beforeUnmount () {
    this.$bus.off('card-block-success', this.onBlockCardSuccess)
  },

  methods: {
    formatAmount,

    formatDateText,

    deleteCardRequest () {
      this.appStore.showModal(BlockCard, { card: this.card }, { wrapperClass: 'modal--xs' })
    },

    onRejectCardRequest () {
      this.appStore.showModal(RejectCardRequest, { uuid: this.card.uuid }, { wrapperClass: 'modal--xs' })
    },

    async onValidateCardRequest () {
      const success = await this.cardStore.validateCardRequest(this.card.uuid)
      if (success) {
        this.$bus.emit('refresh-cards-list')
        showToastSuccess(this.$i18n.t('message.success.ecard.validate'))
        this.appStore.closeSidePanel()
      }
    },

    onBlockCardSuccess () {
      this.$bus.emit('refresh-cards-list')
      this.appStore.closeSidePanel()
    }
  }
}
</script>
