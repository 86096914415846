import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'

export const useOperationStore = defineStore('operation', {
  state: () => {
    return {
      operation: {},
      operations: [],
      operationsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      operationsTotalCredit: 0,
      operationsTotalDebit: 0,
      operationTypes: {},
      operationAffectations: [],
      operationCategories: [],
      operationCategoriesCard: [],
      incomingTransfers: [],
      incomingTransfersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      dashboard: {
        account_updated_at: null,
        pending_transfers_count: 0,
        pending_operations_count: 0,
        message_count: 0,
        last_operations_positive: [],
        last_operations_negative: [],
        last_operations: [],
        expenses_by_users: [],
        expenses_by_categories: {
          details: [],
          total: { amount: 0, count: 0 }
        }
      },
      processingAnalyze: false
    }
  },

  actions: {
    async getOperationCategories () {
      try {
        const { data } = await axiosClient.get('/categories')
        this.operationCategories = data
      } catch (e) {
        console.error(e)
      }

      try {
        const { data } = await axiosClient.get('/categories', { params: { filter: 'card' } })
        this.operationCategoriesCard = data
      } catch (e) {
        console.error(e)
      }
    },

    async getOperationAffectations () {
      try {
        const { data } = await axiosClient.get('/affectations')
        this.operationAffectations = data
      } catch (e) {
        console.error(e)
      }
    },

    async getOperationDetail (uuid) {
      try {
        const { data } = await axiosClient.get(`/operations/${uuid}`, { params: { expand: 'required_fields,documents_histories' } })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getOperationVATRates (uuid) {
      try {
        const { data } = await axiosClient.get(`/operations/${uuid}/vat`)
        return data
      } catch (e) {
        console.error(e)
        return []
      }
    },

    async updateOperationDetails (uuid, details) {
      try {
        const { data } = await axiosClient.put(`/operations/${uuid}`, details, { params: { expand: 'required_fields,documents_histories' } })
        bus.emit('operation-update-success')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getOperationTypes () {
      try {
        const { data } = await axiosClient.get('/list/operation/category')
        this.operationTypes = data
      } catch (e) {
        console.error(e)
      }
    },

    async analyzeOperationDocument (operation, abortSignal = null) {
      this.processingAnalyze = true
      try {
        const { data } = await axiosClient.get(`/operations/${operation.operationUuid}/documents/${operation.documentId}/analyze`, { signal: abortSignal })
        bus.emit('document-analyze-success', data)
      } catch (e) {
        console.error(e)
      } finally {
        this.processingAnalyze = false
      }
    },

    /**
     * @param operationId
     * @param {FileList} files
     */
    async sendDocuments (operationId, files) {
      const payload = new FormData()

      Array.from(files).forEach(file => payload.append('files[]', file))

      try {
        const { data } = await axiosClient.post(`/operations/${operationId}/documents`, payload)
        return data.documents
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async updateOperationCompliance (operation) {
      try {
        const { data } = await axiosClient.put(`/operations/${operation.uuid}/compliance`, { is_compliant: operation.compliance, reason: operation.comment })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getNumberOfOperations (params = {}) {
      try {
        const { headers } = await axiosClient.head('/operations', { params })
        return parseInt(headers['x-total-operations-count'])
      } catch (e) {
        console.error(e)
        return 0
      }
    },

    async getOperations (page, params = {}) {
      params.page = page
      params.mode = 'collection'

      try {
        const { data, headers } = await axiosClient.get('/operations', { params })
        const collection = this.parseCollection(data, headers)

        this.operations = collection.items
        this.operationsTotalCredit = collection.totalCredit
        this.operationsTotalDebit = collection.totalDebit
        this.operationsPagination = collection.pagination
      } catch (error) {
        console.error(error)
        this.operations = []
        this.operationsTotalCredit = 0
        this.operationsTotalDebit = 0
      }
    },

    async getClaimedOperations (page, params = {}) {
      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/operations/claimed', { params })
        this.operationsPagination = this.parsePagination(headers)
        this.operations = data
      } catch (error) {
        console.error(error)
        this.operations = []
      }

      return this.operations
    },

    async updateOperationProof (uuid, action) {
      try {
        const { data } = await axiosClient.put(`/operations/${uuid}/document-management/${action}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getIncomingTransfers (page, params = {}) {
      let transfers = []

      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/incoming-transfers', { params })
        this.incomingTransfersPagination = this.parsePagination(headers)

        transfers = data
      } catch (error) {
        console.error(error)
      }

      this.incomingTransfers = transfers
    },

    // to move
    async addCategory (label) {
      try {
        const { data } = await axiosClient.post('/categories', { label })
        this.operationCategoriesCard.unshift(data)
        this.operationCategories.unshift(data)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async addAffectation (label) {
      try {
        const { data } = await axiosClient.post('/affectations', { label })
        this.operationAffectations.unshift(data)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getOperationsCount (params = {}) {
      try {
        const { data } = await axiosClient.get('/operations/count', { params })
        return data
      } catch (error) {
        console.error(error)
        return { count: 0, proof_claimed_count: 0, export_delayed: false }
      }
    },

    async refreshDashboard (items = []) {
      const params = items.length
        ? { items }
        : null
      try {
        const { data } = await axiosClient.get('/dashboard', { params })
        this.dashboard = data
      } catch (e) {
        console.error(e)
      }
    }
  }
})
