<template>
  <div :class="`line-gauge line-gauge--${size}`">
    <div :class="`line-gauge__fill line-gauge__fill--${color}`"
         :style="`width: ${computedPercentage}%;`" />
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true
    },

    theme: {
      type: String,
      default: 'changing'
    },

    size: {
      type: String,
      default: 'medium'
    }
  },

  data () {
    return {
      full: 100
    }
  },

  computed: {
    color () {
      if (this.theme !== 'changing') {
        return this.theme
      }

      if (this.percentage < 50) {
        return 'gauge-low'
      }

      if (this.percentage < 75) {
        return 'gauge-mid'
      }

      return 'gauge-high'
    },

    computedPercentage () {
      return this.percentage > this.full ? this.full : this.percentage
    }
  }
}
</script>

<style lang="stylus" scoped>
.line-gauge
  margin-top .5rem
  width 100%
  background-color #F0F2F6
  border-radius 1rem

  &--small
    height .4rem

  &--medium
    height .6rem

  &--large
    height .7rem

  &__fill
    height 100%
    border-radius 1rem
    &--primary
      background-color $primaryColor
    &--gauge-low
      background-color $gaugeLow
    &--gauge-mid
      background-color $gaugeMedium
    &--gauge-high
      background-color $gaugeHigh
</style>
