<template>
  <div class="landing-app">
    <div class="appmobile-page">
      <logo-bank-inline class="logo-mone-icon" />
      <h1 class="appmobile-page__title">
        {{ $t('mobile.title') }}
      </h1>
      <a class="btn btn--primary btn--block"
         :href="links.MOBILE"
         target="_blank">{{ $t('mobile.link') }}</a>
      <div class="appmobile-page__img">
        <img src="@/assets/img/app-mobile.png"
             class="img-fw">
      </div>
    </div>
  </div>
</template>

<script>
import links from '@/config/links'

import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'

export default {
  name: 'ComponentMobile',

  components: {
    LogoBankInline
  },

  data () {
    return {
      links
    }
  }
}
</script>
