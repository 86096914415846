<template>
  <modal-dialog-placeholder @close="$emit('close')">
    <component-placeholder :button="$t('message.user_invitation_mandate.modal.link')"
                           :label="$t('message.user_invitation_mandate.modal.title')"
                           :content="$t('message.user_invitation_mandate.modal.content')"
                           @btn-click="openLink">
      <user-document />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script>
import links from '@/config/links'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import UserDocument from '@/components/svg/img/user-document.vue'

export default {
  components: {
    ComponentPlaceholder,
    UserDocument,
    ModalDialogPlaceholder
  },

  emits: ['close'],

  methods: {
    openLink () {
      return window.open(links.STATIC.FILES.PROCURATION())
    }
  }
}
</script>
