import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { ModelTreasuryEvolution } from '@/models/Treasury/ModelTreasuryEvolution'
import { ModelTreasuryFlow } from '@/models/Treasury/ModelTreasuryFlow'
import { ModelTreasuryResume } from '@/models/Treasury/ModelTreasuryResume'
import { ITreasuryEvolution, ITreasuryFlow, ITreasuryResume } from '@/types/treasury.d'

export const useTreasuryStore = defineStore('treasury', {
  actions: {
    async getTreasuryResume (params: object): Promise<ModelTreasuryResume|null> {
      try {
        const { data, status } = await axiosClient.get<ITreasuryResume>('/treasury/resume', { params })

        if (status === 200 && data?.low_point) return ModelTreasuryResume.create(data)
        return null
      } catch (error) {
        console.error(error)
      }
      return null
    },

    async getTreasuryEvolution (params: object): Promise<Array<ModelTreasuryEvolution>|null> {
      try {
        const { data } = await axiosClient.get<Array<ITreasuryEvolution>>('/treasury/evolution', { params })
        return data.map(item => ModelTreasuryEvolution.create(item))
      } catch (error) {
        console.error(error)
      }
      return null
    },

    async getTreasuryFlow (params: object): Promise<ModelTreasuryFlow|null> {
      try {
        const { data } = await axiosClient.get<ITreasuryFlow>('/treasury/flow', { params })
        return ModelTreasuryFlow.create(data)
      } catch (error) {
        console.error(error)
      }
      return null
    }
  }
})
