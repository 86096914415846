<template>
  <svg viewBox="0 0 171 269">
    <path d="M3 0h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm25 0h126a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zM3 37h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h114a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zM3 74h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h140a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zM3 111h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h114a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zM3 148h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h99a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zM3 185h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h125a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zM3 222h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h114a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zM3 259h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm25 0h130a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H28a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3z"
          fill="#fff"
          opacity=".152" />
  </svg>
</template>

<script>
export default {
}
</script>
