<template>
  <div v-if="loading"
       class="section__loader">
    <loader-spinner />
  </div>
  <div v-else-if="control"
       class="content-wrapper">
    <form class="form form--w780 form--centered"
          @submit.prevent>
      <GroupTitle :title="$t('control_rule.form.title')"
                  :subtitle="$t('control_rule.form.subtitle')" />
      <div class="row">
        <div class="col col--sm">
          <div class="bordered-box">
            <div class="bordered-box__item">
              <div class="item-left">
                {{ $t('control_rule.form.delay.label') }}
              </div>
              <div class="item-right">
                <component-dropdown :dropdown-width="'100%'"
                                    :model="control.apply_delay"
                                    :has-default="!control.apply_delay"
                                    async-url="/cards/proof-required/delays"
                                    label="delay"
                                    :disabled="isDisabled"
                                    @select="onSelectDelay">
                  <template #trigger>
                    <div class="dropdown-toggle select"
                         style="width: 18rem;">
                      <div class="form-control form-control--sm">
                        <span>{{ control.apply_delay_label }}</span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bordered-box mt-3">
        <table class="table table--noborder">
          <thead class="bordered-header">
            <th>{{ $t('control_rule.form.fields') }}</th>
            <th class="col--sm-1 center">
              {{ $t('general.mandatory') }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in control.params"
                :key="index">
              <td :class="[item.enabled ? 'strong' : 'text-muted']">
                <span class="">{{ item.label }}</span>
                <template v-if="item.name === 'operation.comment' && item.enabled">
                  <div class="select select--inline">
                    <component-dropdown multiselect
                                        search
                                        :dropdown-margin-top="2.5"
                                        :dropdown-width="34"
                                        dropdown-position="right"
                                        async-url="/categories?filter=card"
                                        has-icon
                                        :model="item.params.categories"
                                        :disabled="isDisabled"
                                        @select="value => onSelectCategories(item, value)">
                      <template #trigger>
                        <div class="form-control">
                          : {{ forCategoriesLabel(item) }}
                        </div>
                      </template>
                    </component-dropdown>
                  </div>
                </template>
              </td>
              <td class="col--sm-1 center">
                <toggle-switch v-model="item.enabled"
                               toggle-class="switch-btn--sm"
                               :disabled="isDisabled" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="isApplyBlockVisible"
           class="row mt-1">
        <div class="col col--sm">
          <div class="bordered-box">
            <div class="bordered-box__item">
              <div class="item-left"
                   style="height: 4rem">
                <toggle-switch v-model="overrideToggle"
                               toggle-class="switch-btn--sm mr-2" />
                {{ $t('control_rule.form.override.label') }}
              </div>
              <div v-if="overrideToggle"
                   class="item-right">
                <component-dropdown :dropdown-width="30"
                                    :model="override"
                                    :values="overrideValues"
                                    @select="onOverrideSelect">
                  <template #trigger>
                    <div class="dropdown-toggle select"
                         style="width: 30rem;">
                      <div class="form-control form-control--sm">
                        {{ overrideLabel }}
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDisabled"
           class="form-buttons">
        <component-button wrapper-class="btn btn--primary"
                          :label="$t('button.save')"
                          @click="onSave" />
      </div>
    </form>
  </div>
  <div v-else
       class="feature-placeholder">
    <div class="feature-placeholder__content">
      <h1 class="feature-placeholder__title">
        {{ $t('placeholder.control_rule.title') }}
      </h1>
      <p class="feature-placeholder__subtitle text-muted">
        {{ $t('placeholder.control_rule.subtitle') }}
      </p>
      <button class="btn btn--primary"
              @click="createAccountControlRule">
        {{ $t('button.create_control_rule') }}
      </button>
    </div>
    <div class="feature-placeholder__img">
      <feature-placeholder-expense-rules />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Confirm from '@/components/modals/Confirm.vue'
import FeaturePlaceholderExpenseRules from '@/components/svg/img/feature-placeholder-expense-rules.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    FeaturePlaceholderExpenseRules,
    ComponentButton,
    ComponentDropdown,
    GroupTitle,
    ToggleSwitch,
    LoaderSpinner
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, accountStore, appStore }
  },

  data () {
    return {
      loading: true,
      control: null,
      override: null,
      cardsCount: { none: 0, any: 0 }
    }
  },

  computed: {
    isDisabled () {
      return !hasFeature(EFeature.AccountCardControlRuleEdition)
    },

    isApplyBlockVisible () {
      return !this.isDisabled && (this.cardsCount.none || this.cardsCount.any)
    },

    overrideToggle: {
      get () {
        return !!this.override
      },

      set (value) {
        this.override = value ? this.overrideValues.find(item => !item.isDisabled).value : null
      }
    },

    overrideValues () {
      return ['none', 'any'].map(value => ({
        label: this.$i18n.t(`control_rule.form.override.${value}`),
        value,
        isDisabled: this.cardsCount[value] === 0
      }))
    },

    overrideLabel () {
      const override = this.overrideValues.find(item => item.value === this.override)
      return override ? override.label : null
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('language-changed', this.initData)
    this.$bus.on('confirm-save-account-control-rule', this.updateAccountControlRule)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('language-changed', this.initData)
    this.$bus.off('confirm-save-account-control-rule', this.updateAccountControlRule)
  },

  methods: {
    async initData () {
      this.loading = true

      this.control = await this.accountStore.getAccountControlRule(this.account.uuid)
      await this.getOverrideCardsCount()
      this.loading = false
    },

    async getOverrideCardsCount () {
      if (!this.control) return

      const data = await this.accountStore.getAccountControlOverrides(this.account.uuid)
      if (data) {
        this.cardsCount = {
          none: data.cards_none_count,
          any: data.cards_any_count
        }
      }
    },

    async createAccountControlRule () {
      this.control = await this.accountStore.createAccountControlRule(this.account.uuid, 'operation')
      await this.getOverrideCardsCount()
    },

    async updateAccountControlRule () {
      const success = await this.accountStore.updateAccountControlRule(this.account.uuid, this.control, this.override || false)
      if (success) {
        showToastSuccess(this.$i18n.t(`control_rule.form.success.${this.override || 'default'}`))
        if (this.override) {
          this.cardsCount = {
            any: this.override === 'none' ? this.cardsCount.any - this.cardsCount.none : 0,
            none: 0
          }
        }
        this.overrideToggle = false
      }
    },

    onSelectCategories (item, value) {
      item.params = {
        categories: value
      }
    },

    onSelectDelay (delay) {
      this.control.apply_delay = delay.value
      this.control.apply_delay_label = delay.label
    },

    forCategoriesLabel (item) {
      return this.$i18n.tc('general.for_categories_number', item.params.categories?.length || 0)
    },

    onOverrideSelect (item) {
      if (!item.isDisabled) {
        this.override = item.value
      }
    },

    onSave () {
      if (!this.override) {
        this.updateAccountControlRule()
        return
      }

      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.t('control_rule.modal.title'),
          content: this.$i18n.tc(`control_rule.modal.content.${this.override}`, this.cardsCount[this.override]),
          event: 'save-account-control-rule'
        },
        { wrapperClass: 'modal--xs' }
      )
    }
  }
}
</script>

<style lang="stylus">
  .feature-placeholder
    display flex
    align-items center
    width 92rem
    margin auto

    &__content
      width 42rem
      margin-right 8rem
    &__title
      font-size 2.4rem
      font-weight 500
      width 70%
      margin-bottom 1.5rem
    &__subtitle
      line-height 2.7rem
      margin-bottom 4rem

    &__img
      flex 1
</style>
