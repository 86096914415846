<template>
  <svg viewBox="0 0 200 160">
    <path d="m64.7484201 43h60.5031599v1.5h-60.5031599zm48.2208549 62.637571c1.602632 0 2.496617 1.894497 2.518422 1.936597.200826.364208.658312.517668 1.049799.352147s.584893-.594178.44381-.983646c0-.115775-1.340978-2.883845-4.022933-2.883845-3.271239.10212-6.083435 2.268886-6.912031 5.325642h-2.900001v1.578747h2.594738c-.056952.415245-.086085.833606-.087218 1.252473.002294.418828.031423.837118.087218 1.252473h-2.540226v1.578748h2.889098c.800404 3.07873 3.635642 5.259809 6.922933 5.325642 1.689754-.128569 3.194536-1.081165 3.990227-2.525996.21074-.386551.056985-.864838-.343421-1.068286-.400407-.203448-.895839-.055013-1.10658.331537-.519161.925788-1.469135 1.552865-2.551128 1.683997-2.38238-.054426-4.468512-1.557493-5.200377-3.746894h5.200377v-1.578748h-5.625565c-.064962-.41455-.09776-.833206-.09812-1.252473-.000862-.419311.031943-.838056.09812-1.252473h5.625565v-1.578747h-5.178572c.744128-2.158537 2.791548-3.649339 5.145865-3.746895z"
          :fill="colorPrimary" />
    <path d="m128.44 26.25c2.900484.0428058 5.232683 2.3691891 5.309004 5.2434317l.001468.2165683v86.58c.037528 2.900551-2.223013 5.296635-5.094028 5.452535l-.216444.007465h-1.689932l.000404 1.54c.037528 2.900551-2.223013 5.296635-5.094028 5.452535l-.216444.007465h-50.15c-.2037708 0-.3987189-.083159-.54-.23l-7.29-7.53c-.1346084-.139657-.2104718-.326033-.2104718-.52v-83.76c-.0384665-2.9730645 2.337476-5.4161241 5.3104718-5.46h1.6890676l.0004606-1.54c-.0375283-2.9005508 2.2230127-5.2966351 5.0940274-5.452535l.2164444-.007465zm-59.88 8.46c-1.0370924.0209119-2.0228787.455169-2.7383071 1.206275-.6603955.6933285-1.0399307 1.6036206-1.0711765 2.5550991l.000416 83.2486259h1.0290676c3.4827065.054828 6.2645219 2.917147 6.22 6.4l.0009324 1.22h49.4390676c1.037092-.020912 2.022879-.455169 2.738307-1.206275.715429-.751106 1.101236-1.756843 1.071693-2.793725l-.000516-86.8286259c-.031246-.9514785-.410781-1.8617706-1.071177-2.5550991-.715428-.751106-1.701215-1.1853631-2.738307-1.206275zm-2.78 88.51h-.0990676l4.822 4.977-.0029324-.077c.0446279-2.654494-2.0656945-4.845295-4.72-4.9zm9.78-95.51c-1.0370924.0209119-2.0228787.455169-2.7383071 1.206275-.6603955.6933285-1.0399307 1.6036206-1.0711765 2.5550991l-.0005164.2386259-.0009324 1.54h49.6909324c2.900484.0428058 5.232683 2.3691891 5.309004 5.2434317l.001468.2165683-.000404 83.63h1.689932c.963014-.019418 1.88179-.395241 2.580943-1.050209l.157364-.156066c.660396-.693329 1.039931-1.603621 1.071177-2.555099v-87.0672519c-.031246-.9514785-.410781-1.8617706-1.071177-2.5550991-.664326-.6974555-1.561767-1.1217098-2.516938-1.1955023l-.221369-.0107727z"
          :fill="colorDark" />
    <path d="m71 55h18v1.5h-18zm0 30h18v1.5h-18zm28.025-30h7.977839v1.5h-7.977839zm0 30h7.977839v1.5h-7.977839zm11-30h7.977839v1.5h-7.977839zm0 30h7.977839v1.5h-7.977839zm-39.025-24h22v1.5h-22zm0 30h22v1.5h-22zm28.025-30h7.977839v1.5h-7.977839zm0 30h7.977839v1.5h-7.977839zm11-30h7.977839v1.5h-7.977839zm0 30h7.977839v1.5h-7.977839zm-38.975-18h19v1.5h-19zm27.9971607 0h7.9778393v1.5h-7.9778393zm11.0000003 0h7.977839v1.5h-7.977839zm-39.047161 6h18v1.5h-18zm28.025 0h7.977839v1.5h-7.977839zm11 0h7.977839v1.5h-7.977839zm-39.025-12h16v1.5h-16zm0 30h16v1.5h-16zm28.025-30h7.977839v1.5h-7.977839zm11 0h7.977839v1.5h-7.977839z"
          :fill="colorGrey" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
