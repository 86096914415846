<template>
  <li>
    <router-link :to="{ name: 'account-company-subaccounts', params: { id: companyData.id } }"
                 class="item account-item">
      <div class="account-item__infos">
        <h4 class="account-item__name">
          {{ companyData.name }}
        </h4>
      </div>
      <div class="account-item__infos">
        <div>{{ $tc('label.company.company_item.account_number', companyData.nb_account) }}</div>
        <div v-if="displayAccountBalance"
             :class="[companyData.balance < 0 ? 'text-red' : 'text-green']"
             class="account-item__balance">
          {{ formatAmount(companyData.balance, account.currency) }}
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

export default {
  props: {
    companyData: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    displayAccountBalance () {
      return Object.prototype.hasOwnProperty.call(this.companyData, 'balance')
    }
  },

  methods: {
    formatAmount
  }
}
</script>
