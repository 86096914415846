<template>
  <modal-dialog-prompt :title="modalTitle"
                       data-cy="cards.details.delete.modal.container"
                       @close="$emit('close')">
    <template #body>
      <component-alert v-if="errors.length && formSubmitted"
                       type="error"
                       :message="$t('message.warning.form_not_valid')" />
      <template v-if="isVirtualCard">
        <p>{{ $t('prompt.ecards.delete.content') }}</p>
      </template>
      <template v-else-if="isCardToValidateByBank">
        <p>{{ $t('prompt.cards.cancel_card_request.content') }}</p>
      </template>
      <template v-else>
        <p>{{ $t('form.cards.block.label') }}</p>
        <form class="form">
          <div class="form-group">
            <div class="select">
              <component-dropdown label="reason"
                                  dropdown-width="100%"
                                  data-cy="reasons"
                                  async-url="/cards/block/reasons"
                                  :model="cardBlockType"
                                  @select="onBlockTypeSelect">
                <template #trigger>
                  <div class="select form-control">
                    <span :class="{ 'text-muted': !cardBlockType }">
                      {{ cardBlockType ? cardBlockType.label : $t("form.common.select") }}
                    </span>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
        </form>
      </template>
    </template>

    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <button type="button"
              class="btn btn--primary"
              :disabled="loading || (!cardBlockType && !isCardToValidateByBank)"
              data-cy="cards.details.delete.modal.submit"
              @click="onSubmit()">
        {{ $t('button.confirm') }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { storeToRefs } from 'pinia'

import { isVirtualCard } from '@/helpers/utils/card'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useApiStore } from '@/stores/api'
import { useCardStore } from '@/stores/card'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ComponentAlert,
    ComponentDropdown,
    ModalDialogPrompt
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const cardStore = useCardStore()
    const apiStore = useApiStore()

    const { error: apiError } = storeToRefs(apiStore)

    return {
      cardStore,
      apiError
    }
  },

  data () {
    return {
      loading: false,
      cardBlockType: null,
      formSubmitted: false,
      errors: []
    }
  },

  computed: {
    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    isCardToValidateByBank () {
      return this.card.status === 'to_validate_by_bank'
    },

    modalTitle () {
      if (this.isCardToValidateByBank) {
        return this.$t('title.cancel_card_request')
      }
      return this.isVirtualCard ? this.$i18n.t('prompt.ecards.delete.title') : this.$i18n.t('title.block_card')
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.errors.push({ field: item.field, message: item.message })
        })
      }
    }
  },

  created () {
    if (this.isVirtualCard) {
      this.onBlockTypeSelect({ value: 'delete_virtual_card' })
    }
  },

  mounted () {
    this.$bus.on('card-block-success', this.onBlockCardSuccess)
  },

  beforeUnmount () {
    this.$bus.off('card-block-success', this.onBlockCardSuccess)
  },

  methods: {
    onBlockTypeSelect (blockType) {
      this.cardBlockType = blockType
    },

    async onSubmit () {
      this.formSubmitted = true

      this.loading = true
      await this.cardStore.blockCard(this.card.uuid, this.cardBlockType?.value)
      this.loading = false
    },

    onBlockCardSuccess (data) {
      const message = data.message || (this.isVirtualCard ? this.$i18n.t('message.success.ecard.delete') : this.$i18n.t('message.success.card.block'))
      showToastSuccess(message)
      this.$emit('close')
    }
  }
}
</script>
