<template>
  <div class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('form.cards.duplicate_card') }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <div class="duplicate-card">
          <card-item :show-card-label="false"
                     :card="model"
                     :clickable="false"
                     :native-click="false" />
        </div>
        <div class="sidepanel-details mt-4">
          <h3 class="head-title mb-0">
            {{ $t('form.virtual_cards.label.label') }}
          </h3>
          <div class="sidepanel-details__entry">
            <input v-model="label"
                   class="form-control form-control--sm"
                   :placeholder="$t('form.label.placeholder')">
          </div>
          <hr class="divider divider--fw">
          <h3 class="head-title">
            {{ $t('form.cards.informations') }}
          </h3>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('document_check.details.owner') }}</span>
            <span class="entry-value right">{{ model.first_name }} {{ model.last_name }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('form.beneficiaries_list.type.label') }}</span>
            <span class="entry-value right">{{ typeLabel }}</span>
          </div>
          <div v-if="isCapVisible"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('form.virtual_cards.cap.label') }}</span>
            <span class="entry-value right">{{ formatAmount(model.settings.initial_amount, account.currency) }}</span>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="btn-grouped">
        <button type="button"
                class="btn btn--gray"
                @click="closeSidePanel">
          {{ $t('button.cancel') }}
        </button>
        <component-button :label="$t('button.duplicate')"
                          :disabled="loading"
                          wrapper-class="btn btn--primary"
                          @click="onDuplicateCard" />
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { getCardBadgeLabel, isClassicVirtualCard } from '@/helpers/utils/card'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import CardItem from '@/pages/cards/components/CardItem.vue'
import BankValidationRequest from '@/pages/cards/modal/BankValidationRequest.vue'

import ComponentButton from '@/components/Button.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    CardItem,
    ComponentButton,
    ComponentSidepanelFooter
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore, cardStore }
  },

  data () {
    return {
      loading: false,
      label: ''
    }
  },

  computed: {
    typeLabel () {
      return getCardBadgeLabel(this.model)
    },

    isCapVisible () {
      return !isClassicVirtualCard(this.model)
    }
  },

  methods: {
    formatAmount,

    closeSidePanel () {
      this.appStore.closeSidePanel()
    },

    async onDuplicateCard () {
      this.loading = true
      const success = await this.cardStore.duplicateCard(this.model.uuid, this.label)

      if (success) {
        if (this.model.status === 'to_validate_by_bank') {
          this.appStore.showModal(BankValidationRequest)
        } else {
          showToastSuccess(this.$t('message.success.card.duplicate'))
        }
        this.appStore.closeSidePanel()
        if (this.model.status === 'to_validate_by_bank') {
          this.appStore.showModal(BankValidationRequest)
        }
      }

      this.loading = false
    }
  }
}
</script>
