<template>
  <div class="login-block-wrapper">
    <loader-spinner size="24"
                    class="c c--vh spinner--loading" />
    <div class="login-block">
      <h2 class="login-title">
        {{ $t("title.login.h1") }}
      </h2>
      <div class="login-subtitle">
        {{ $t('title.login.subtitle') }}
      </div>
      <validation-form v-slot="{ isSubmitting, values }"
                       class="login-form"
                       autocomplete="off"
                       :initial-values="initialValues"
                       :validation-schema="schema"
                       @submit="onSubmit"
                       @invalid-submit="onInvalidSubmit">
        <div class="login-form__input">
          <validation-field id="email"
                            name="email"
                            type="email"
                            class="form-control"
                            autocomplete="off"
                            ontouchstart="this.removeAttribute('readonly');"
                            onfocus="this.removeAttribute('readonly');"
                            :placeholder="$t('form.email.label')"
                            :readonly="!isIE11" />
        </div>
        <div class="login-form__input">
          <validation-field id="password"
                            :type="passwordType"
                            autocomplete="off"
                            name="password"
                            class="form-control"
                            ontouchstart="this.removeAttribute('readonly');"
                            onfocus="this.removeAttribute('readonly');"
                            :placeholder="$t('form.password.label')"
                            :readonly="!isIE11" />
          <div class="login-form__input-addon">
            <button v-if="values.password"
                    class="btn-link"
                    type="button"
                    @click="togglePasswordVisibility">
              <ic-view class="ic"
                       :class="[passwordType === 'text' ? 'ic--secondary' : 'ic--gray']" />
            </button>
          </div>
        </div>
        <router-link class="btn-link btn-password-forgotten"
                     :to="{ name: 'forgot' }">
          {{ $t('title.forgot_password') }}
        </router-link>
        <component-button type="submit"
                          :label="$t('button.login')"
                          :disabled="isSubmitting"
                          :loading="isSubmitting"
                          wrapper-class="btn btn--primary btn--block login-form__submit" />
      </validation-form>
      <div v-if="isSignupEnabled"
           class="signup-link center">
        <span class="text-muted">{{ $t("label.not_client") }}</span> <a :href="links.SIGNUP">{{ $t("button.signup") }}</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ECoreBannerTheme } from '@common/core-ui'
import * as yup from 'yup'

import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

// COMPONENTS
import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcView from '@/components/svg/icons/ic-view.vue'

// DATA
const appStore = useAppStore()
const authStore = useAuthStore()
const { t } = useI18n()

const schema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required()
})

const initialValues = {
  email: import.meta.env.VITE_LOGIN_USER || '',
  password: import.meta.env.VITE_LOGIN_PASS || ''
}

const isIE11 = ref(!!window.MSInputMethodContext && !!document.documentMode)
const passwordType = ref('password')

// COMPUTED
const isSignupEnabled = computed(() => {
  return hasFeature(EFeature.LoginSignupLink)
})

// LIFECYCLE
onMounted(() => {
  authStore.clearInactivityTimeout()
  useAuthStore().resetStores()
})

// METHODS
function onInvalidSubmit () {
  appStore.showBanner(ECoreBannerTheme.Danger, t('message.warning.login'))
}

async function onSubmit (values: {email: string, password: string}) {
  removeOnePasswordElements()
  await authStore.auth(values.email, values.password)
}

function removeOnePasswordElements () {
  document.querySelector('com-1password-button')?.remove()
  document.querySelector('com-1password-menu')?.remove()
}

function togglePasswordVisibility () {
  passwordType.value = (passwordType.value === 'password' ? 'text' : 'password')
}
</script>
