<template>
  <svg viewBox="0 0 200 160">
    <path :fill="colorPrimary"
          d="m156.88 74.85-.76 1.29c1.43.83 3.13 2.18 3.13 3.85 0 1.33-1.05 2.63-3.13 3.85l.76 1.29c2.57-1.51 3.87-3.24 3.87-5.15 0-1.91-1.3-3.63-3.87-5.13zm-97.88 2.4 45 0 0-1.5-45 0 0 1.5zm21 7 17 0 0-1.5-17 0 0 1.5zm-52 0 25 0 0-1.5-25 0 0 1.5zm36 0 7 0 0-1.5-7 0 0 1.5zm-16-8.5-18 0 0 1.5 18 0 0-1.5z" />
    <path :fill="colorDark"
          d="m164.36 73.27c-4.3632-1.6972-9.0085-2.5522-13.69-2.52l-19.67 0c-2.86 0-5.37-2.86-8-5.89-2.89-3.3-5.88-6.71-10-7.56-.521-.1373-1.0761-.0225-1.5.31-.5139.4884-.7787 1.1835-.72 1.89l0 41c-.0486.7512.2412 1.4848.79 2 .2992.2434.6743.3744 1.06.37.1396.014.2804.014.42 0 4.12-.86 7.1-4.31 10-7.64 2.64-3.06 5.14-5.94 8-5.95l19.68 0c4.6781.0298 9.3196-.8252 13.68-2.52 4.92-1.94 7.41-4.21 7.4-6.73-.01-2.52-2.53-4.82-7.45-6.76zm-52.11-13.77c-.0332-.2559.0272-.5151.17-.73.0808-.029.1692-.029.25 0 3.63.75 6.45 4 9.18 7.08 1.4612 1.8136 3.1216 3.4573 4.95 4.9l-14.55 0 0-11.25zm9.62 34.72c-2.72 3.14-5.53 6.39-9.16 7.15-.16 0-.21 0-.23 0-.1842-.243-.27-.5465-.24-.85l0-11.27 14.58 0c-1.8335 1.4632-3.4942 3.1306-4.95 4.97zm42-8.88c-4.1857 1.6234-8.6406 2.4411-13.13 2.41l-38.49 0 0-15.5 38.43 0c4.4935-.0306 8.9522.7906 13.14 2.42 4.22 1.67 6.46 3.52 6.46 5.34 0 1.82-2.28 3.66-6.46 5.33l.05 0zm-56.87-16.59-43.56 0c1.52-6.43 4-11 7.58-14 4.69-4.32 10.75-6.51 18-6.51 5.309-.0644 10.5637 1.0741 15.37 3.33l.69-1.33c-5.0249-2.3741-10.5228-3.5776-16.08-3.52-7.61 0-14 2.28-19 6.88-4 3.35-6.69 8.5-8.27 15.74l-.2.91 45.47 0 0-1.5zm-56.76.9c1.4954-7.735 5.4944-14.763 11.38-20 7.3-6.59 16.47-9.93 27.28-9.93 6.3208-.0562 12.5794 1.2501 18.35 3.83l.63-1.36c-5.9719-2.6846-12.4527-4.049-19-4-11.19 0-20.71 3.47-28.28 10.31-5.9715 5.3216-10.0763 12.42-11.71 20.25l-8.54 0 0 1.5 9.77 0 .12-.6zm37.4 52.42c-11.22 0-21.15-3.77-28-10.64-5.13-4.92-8.64-12.4-9.88-21l-.09-.64-12.67 0 0 1.5 11.4 0c1.37 8.7 5 16.23 10.22 21.25 7.08 7.14 17.39 11.07 29 11.07 11.2 0 18.62-3.65 21.29-5.22l-.76-1.29c-2.55 1.46-9.68 4.97-20.52 4.97l.01 0zm13.36-32.32-40.34 0 .14.87c1.12 7 3.93 13 8 16.91 5.19 4.75 12.21 7.27 20.29 7.27 6.0471-.0691 12.0048-1.4689 17.45-4.1l-.66-1.35c-5.2672 2.5422-11.0316 3.8877-16.88 3.94-7.7 0-14.37-2.38-19.26-6.86-3.61-3.48-6.2-8.85-7.35-15.18l38.61 0 0-1.5z" />
    <path :fill="colorGrey"
          d="m140.37 43.57-1.87 1.87-1.87-1.87-1.06 1.06 1.87 1.87-1.87 1.87 1.06 1.06 1.87-1.87 1.87 1.87 1.06-1.06-1.87-1.87 1.87-1.87-1.06-1.06zm20.06 15.06-1.06-1.06-1.87 1.87-1.87-1.87-1.06 1.06 1.87 1.87-1.87 1.87 1.06 1.06 1.87-1.87 1.87 1.87 1.06-1.06-1.87-1.87 1.87-1.87zm-29.06 48.94-1.87 1.87-1.87-1.87-1.06 1.06 1.87 1.87-1.87 1.87 1.06 1.06 1.87-1.87 1.87 1.87 1.06-1.06-1.87-1.87 1.87-1.87-1.06-1.06z" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
