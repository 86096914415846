<template>
  <div class="preferences-notifications">
    <form class="form form--w780 form--centered"
          @submit.prevent>
      <group-title :title="isBankNotification ? $t('pages.preferences.notifications.bank_account.title') : $t('pages.preferences.notifications.user_account.title')"
                   :subtitle="$t('pages.preferences.notifications.subtitle')" />
      <component-dropdown v-if="isBankNotification"
                          label="bank-accounts"
                          :dropdown-width="'100%'"
                          :dropdown-height="21"
                          class="form-group"
                          async-url="/accounts"
                          :async-url-params="{ access: 'owner', status: 'valid' }"
                          :label-filter="accountFilter"
                          :model="account"
                          @select="onAccountSelect">
        <template #trigger>
          <div class="select"
               :class="{ 'select--off': !account }">
            <div class="form-control">
              <span v-if="account">
                {{ accountFilter(account) }}
              </span>
              <span v-else
                    class="text-muted">
                {{ $t('form.common.select_account') }}
              </span>
            </div>
          </div>
        </template>
      </component-dropdown>
      <div v-for="(category, i) in filteredPreferences"
           :key="i">
        <div class="bordered-box-head">
          <div class="row">
            <div class="col--xs">
              <span class="bordered-box-head__title">{{ category.label }}</span>
            </div>
            <span v-if="categoriesAllowedNotifications[i].push"
                  class="col--xs-1 center">
              <ic-phone class="ic ic--off ic-phone" />
            </span>
            <span v-if="categoriesAllowedNotifications[i].mail"
                  class="col--xs-1 center">
              <ic-mail class="ic ic--off ic-mail" />
            </span>
          </div>
        </div>
        <div class="bordered-box bordered-box--padding">
          <table class="table">
            <tr v-for="(item, index) in category.notifications"
                :key="index">
              <td class="col--xs px-0">
                <span>{{ $t(`api_label.notification.${item.resource}`) }}{{ item.params ? ' :' : '' }}</span>
                <template v-for="(param, key) in item.params"
                          :key="`${index}-${key}`">
                  <popover trigger="click"
                           popover-class="popover--center"
                           position-arrow-x="center"
                           @click.stop>
                    <template #trigger="slotProps">
                      <button v-tooltip="{ content: slotProps.popoverActive ? false : $t('tooltip.edit'), theme: 'tooltip' }"
                              type="button"
                              class="btn-link--edit strong ml-1">
                        <template v-if="param.type === 'currency'">
                          {{ formatAmount(param.value, account.currency) }}
                        </template>
                        <template v-else>
                          {{ param.value }}
                        </template>
                        <ic-pencil class="ic ic--gray ic--18" />
                      </button>
                    </template>
                    <template #default="slotProps">
                      <popover-edit v-model="param.value"
                                    :popover-active="slotProps.popoverActive"
                                    :type="param.type"
                                    :disabled="preferenceUpdating"
                                    @input="(value, oldValue) => onParamUpdate(i, index, key, value, oldValue)"
                                    @close-popover="slotProps.closePopover" />
                    </template>
                  </popover>
                </template>
              </td>
              <td v-if="categoriesAllowedNotifications[i].push"
                  class="col--xs-1 center">
                <span v-tooltip="{ content: item.push_locked ? $t('tooltip.security_notifications') : '', theme: 'tooltip-md' }">
                  <toggle-switch v-if="item.push_allowed"
                                 v-model="item.push_enabled"
                                 toggle-class="switch-btn--sm"
                                 :disabled="preferenceUpdating || item.push_locked"
                                 @update:model-value="updateSetting(item, 'push_enabled')" />
                </span>
              </td>
              <td v-if="categoriesAllowedNotifications[i].mail"
                  class="col--xs-1 center">
                <span v-tooltip="{ content: item.mail_locked ? $t('tooltip.security_notifications') : '', theme: 'tooltip-md' }">
                  <toggle-switch v-if="item.mail_allowed"
                                 v-model="item.mail_enabled"
                                 toggle-class="switch-btn--sm"
                                 :disabled="preferenceUpdating || item.mail_locked"
                                 @update:model-value="updateSetting(item, 'mail_enabled')" />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'

import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import Popover from '@/components/Popover.vue'
import PopoverEdit from '@/components/popovers/Edit.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcPhone from '@/components/svg/icons/ic-phone.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcPencil,
    IcMail,
    IcPhone,
    ToggleSwitch,
    GroupTitle,
    Popover,
    PopoverEdit,
    ComponentDropdown
  },

  props: {
    isBankNotification: {
      type: Boolean,
      default: false
    }
  },

  emits: ['loaded'],

  setup () {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)

    return { account, accountStore, authStore }
  },

  data () {
    return {
      preferences: [],
      preferenceUpdating: false,
      store
    }
  },

  computed: {
    categoriesAllowedNotifications () {
      return this.preferences.map(cat => ({
        mail: cat.notifications.some(el => el.mail_allowed),
        push: cat.notifications.some(el => el.push_allowed)
      }))
    },

    filteredPreferences () {
      return this.preferences.filter(cat => cat.notifications && cat.notifications.length > 0)
    }
  },

  watch: {
    '$i18n.locale': {
      handler () {
        this.initData()
      }
    }
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  activated () {
    this.initData()
  },

  methods: {
    formatAmount,

    async initData () {
      if (!store.translations.api_label || !store.translations.api_label.notification) {
        this.preferences = []
        store.translations.api_label = Object.assign(store.translations.api_label || {}, { notification: {} })
      }

      const data = this.isBankNotification
        ? await this.authStore.getAccountNotifications()
        : await this.authStore.getUserNotifications()

      if (data && Array.isArray(data.categories)) {
        data.categories.forEach(cat => {
          cat.notifications = Array.isArray(cat.notifications) ? cat.notifications : []
          cat.notifications.forEach(notification => {
            store.translations.api_label.notification[notification.resource] = notification.title
          })
        })
        this.preferences = data.categories
      } else {
        this.preferences = []
      }

      this.$emit('loaded', true)
    },

    accountFilter (account) {
      return `${account.company.name} - ${account.type_label}`.trim()
    },

    onAccountSelect (account) {
      this.accountStore.switchAccount(account.uuid)
    },

    onParamUpdate (categoryIdx, notificationIdx, paramKey, value, oldValue) {
      const payload = this.preferences[categoryIdx].notifications[notificationIdx]
      payload.params[paramKey].value = value
      this.updateSetting(payload, paramKey, oldValue)
    },

    async updateSetting (item, key, oldValue = null) {
      if (this.preferenceUpdating) return

      this.preferenceUpdating = true
      const success = this.isBankNotification
        ? await this.authStore.updateAccountNotificationsPreferences(item)
        : await this.authStore.updateUserNotificationsPreferences(item)
      if (!success) {
        oldValue
          ? item.params[key].value = oldValue
          : item[key] = !item[key]
      }
      this.preferenceUpdating = false
    }
  }
}
</script>
