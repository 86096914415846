<template>
  <div class="timeline-item"
       :data-cy="`timeline.item-${id}`"
       :class="{ 'timeline-item--cancelled': cancelled }">
    <div v-if="type !== 'block'"
         class="timeline-item__left">
      <div v-if="type === 'lite'">
        <ic-round class="ic--round" />
      </div>
      <div v-if="type === 'workflow'">
        <div v-if="iconProcessing === 'circle'"
             class="workflow-icon">
          <ic-circle class="ic--circle" />
        </div>
        <template v-if="iconProcessing === 'pending'">
          <ic-pending-circle v-if="status === 'processing'"
                             class="ic"
                             :class="`ic--${iconProcessingSize}`" />
          <ic-check-circle v-if="status === 'done'"
                           class="ic"
                           :class="[{ 'ic--primary': color === 'red', 'ic--info': color === 'blue' }, `ic--${iconProcessingSize}`]" />
          <ic-remove v-if="status === 'rejected'"
                     filled
                     class="ic"
                     :class="[{ 'ic--primary': color === 'red', 'ic--info': color === 'blue' }, `ic--${iconProcessingSize}`]" />
        </template>
      </div>
      <div class="timeline-item__left__bar"
           :class="{ hidden: isLastItem, blue: nextIsDone && color === 'blue', red: nextIsDone && color === 'red' }" />
    </div>
    <div class="timeline-item__right">
      <div class="timeline-item__right__content">
        <h4 class="timeline-item__right__content__title">
          {{ title }}
        </h4>
        <p class="timeline-item__right__content__subtitle">
          {{ subtitle }}
        </p>
      </div>
      <div class="timeline-item__right__trailing">
        <span v-if="topRightLabel"
              class="timeline-item__right__trailing__label">{{ topRightLabel }}</span>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcCircle from '@/components/svg/icons/ic-circle.vue'
import IcPendingCircle from '@/components/svg/icons/ic-pending-circle.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import IcRound from '@/components/svg/icons/ic-round.vue'

export default {
  components: { IcRemove, IcRound, IcCircle, IcCheckCircle, IcPendingCircle },

  props: {
    id: {
      type: Number,
      required: true
    },

    color: {
      type: String,
      default: 'red',
      validator: value => ['red', 'blue', ''].includes(value)
    },

    status: {
      type: String,
      default: 'processing'
    },

    type: {
      type: String,
      required: true,
      validator: value => ['block', 'lite', 'workflow'].includes(value)
    },

    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    },

    topRightLabel: {
      type: String,
      default: null
    },

    isLastItem: {
      type: Boolean,
      default: false
    },

    iconProcessing: {
      type: String,
      default: 'circle',
      validator: value => ['circle', 'pending'].includes(value)
    },

    iconProcessingSize: {
      type: String,
      default: '24'
    },

    cancelled: {
      type: Boolean,
      default: false
    },

    nextIsDone: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.ic--round
  height 2.5rem

.ic--circle
  height 1.5rem
  display inline-block

.workflow-icon
  height auto
  display flex
  padding 5px 0px

.timeline--lite
.timeline--workflow
  .timeline-item
    position relative
    display flex
    gap 2rem

    &__left
      display flex
      flex-direction column

      &__bar
        height 100%
        width 1px
        background $gray-200
        margin auto
        transform translateX(-0.5px)
      .blue
        background $blue-500
        width 2px
      .red
        background $colorVividRed
        width 2px
      .hidden
        width 0px
    &__right
      &__content
        &__title
          line-height 1.5
          color $gray-600

        &__subtitle
          color $gray-500
          font-weight 400
          margin-bottom 2rem
.timeline--workflow
  .timeline-item
    &__right
      &__content
        &__title
          margin 0
          line-height normal
          margin-bottom 0.2rem
          font-size 1.3rem
          font-weight 400!important
          color $gray-600

        &__subtitle
          line-height 1.75
          margin-bottom 2rem
          font-weight 500
          color $gray-600
.timeline--block
  .timeline-item
    position relative
    display flex
    justify-content space-between
    align-items center
    margin 1.8rem 0
    padding 2.4rem
    min-height 9rem
    border 1px solid alpha($borderColor,0.5)
    border-radius $border-radius
    box-shadow 0 2px 3px -2px alpha($defaultColor, 0.05)

    &:after
      content ""
      width 1.1rem
      height @width
      background white
      position absolute
      border-radius 50%
      border 2px solid $colorShinyGray
      top 50%
      margin-top -( @height / 2 )
      left -3rem

    &--cancelled
      &:after
      .timeline-item__right__content
        opacity .5
        text-decoration line-through

    &__left
      display none

    &__right
      width 100%
      display flex
      justify-content space-between
      align-items center

      &__content
        max-width 42rem
        &__title
          font-weight 500
          margin-bottom 0
          line-height 1.5

        &__subtitle
          margin-bottom 0
          color $colorShinyGray

      &__trailing
        display flex
        gap 2rem
        align-items center

        &__label
          position absolute
          top 1rem
          right @top
          color alpha($colorShinyGray, 0.5)
          font-weight 500
          font-size 0.9em

        &-actions
          display flex
          align-items center

          span, button, div
            &:not(:first-child)
              margin-left 4rem
</style>
