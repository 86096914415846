import { Nullable } from '@/types/default.d'
import { EDocumentStatus, IDocument } from '@/types/document.d'

export enum EType {
  Debit = 'debit',
  Credit = 'credit'
}

export enum ECategory {
  DepositCash = 'deposit_cash',
  DepositCheck = 'deposit_check',
  Fees = 'fees',
  Other = 'other',
  Payment_check = 'payment_check',
  CreditCard = 'credit_card',
  RefundAtm = 'redraw_atm',
  Refund = 'refund',
  Prelevement = 'prelevement'
}

export enum EStatus {
  Cancelled = 'cancelled',
  Done = 'done',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  Deleted = 'deleted'
}

export interface IOperation {
  accounting_date: Nullable<string>
  amount: number
  bill: Nullable<object>
  category: ECategory
  category_label: string
  credit_card?: object
  comment: Nullable<string>
  documents: Array<IDocument>,
  documents_status: EDocumentStatus,
  expense_control: {
    status: string,
    status_label: string
  },
  currency: {
    flag_url: string
    label: string
    iso: string
    fraction_digits: number
  },
  id: number
  is_pre_auth: boolean
  label: string
  reject_reason: Nullable<string>
  short_label: string
  status: EStatus
  type: EType
  value_date: Nullable<string>
  uuid: string
}
