<template>
  <div class="reliability-gauge">
    <div class="reliability-gauge__value"
         :class="`value-${value}`" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
</script>
