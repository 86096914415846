<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ isUserEmployee ? $t('title.refunds') : $t('title.payments') }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <router-link class="btn btn--primary"
                       :to="{ name: 'refund-create' }">
            {{ isUserEmployee ? $t('button.new_refund') : $t('button.new_payment') }}
          </router-link>
        </div>
      </div>
    </div>
    <tabs :data="segments" />
    <router-view ref="listTable" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'

import Tabs from '@/components/Tabs.vue'

export default {
  name: 'PageRefunds',

  components: {
    Tabs
  },

  setup () {
    const accountStore = useAccountStore()

    const { isUserEmployee } = storeToRefs(accountStore)

    return { isUserEmployee }
  },

  computed: {
    segments () {
      return [
        {
          label: this.$i18n.t('button.pending'),
          route: { name: 'refunds' }
        },
        {
          label: this.$i18n.tc('button.history'),
          route: { name: 'refunds-done' }
        }
      ]
    }
  }
}
</script>
