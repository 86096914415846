<template>
  <svg viewBox="0 0 24 24">
    <path d="M20.5 16c.276 0 .5.214.5.505v.99a.505.505 0 0 1-.5.505h-17a.495.495 0 0 1-.5-.505v-.99c0-.279.228-.505.5-.505zm0-5c.276 0 .5.214.5.505v.99a.505.505 0 0 1-.5.505h-17a.495.495 0 0 1-.5-.505v-.99c0-.279.228-.505.5-.505zm0-5c.276 0 .5.214.5.505v.99a.505.505 0 0 1-.5.505h-17a.495.495 0 0 1-.5-.505v-.99C3 6.226 3.228 6 3.5 6z" />
  </svg>
</template>

<script>
export default {
}
</script>
