<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="mileages.length">
      <component-table class="table--hover table--fixed">
        <thead>
          <tr>
            <th v-if="isDone"
                class="col-sm-1" />
            <th class="col--sm-1">
              {{ $t("general.date") }}
            </th>
            <th class="col--sm-2">
              {{ $t("general.reason") }}
            </th>
            <th class="col--sm-2">
              {{ isOwner ? $t("table.beneficiary") : $t("table.vehicle") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.distance") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.amount") }}
            </th>
            <th class="cols--sm-1 center">
              {{ $t("table.documents") }}
            </th>
            <th v-if="isPending"
                class="col--sm-1" />
          </tr>
        </thead>
        <tbody>
          <template v-for="(items, index) in groupedMileages"
                    :key="index">
            <tr v-if="items.length"
                class="header-table-section">
              <td colspan="7">
                <span class="arrow">&rsaquo;</span>{{ index }}
              </td>
            </tr>
            <tr v-for="item in items"
                :key="item.id"
                class="is-link"
                @click="showMileageDetail(item)">
              <td v-if="isDone">
                <core-badge v-if="item.status_label"
                            v-bind="getBadgeProps(item.status)"
                            :value="item.status_label" />
              </td>
              <td class="text-muted nowrap">
                {{ formatDate(item.mileage_date) }}
              </td>
              <td class="text-overflow">
                <span>{{ item.label }}</span>
              </td>
              <td class="text-overflow">
                <template v-if="isOwner">
                  {{ item.beneficiary ? item.beneficiary.label : '-' }}
                </template>
                <template v-else>
                  {{ item.vehicle ? item.vehicle.label : '-' }}
                </template>
              </td>
              <td class="right text-overflow">
                {{ formatDistance(item.distance) }}
              </td>
              <td class="right strong">
                {{ formatAmount(item.amount, account.currency) }}
              </td>
              <td class="center">
                <button v-if="item.documents?.length"
                        v-tooltip="{ content: fileViewerTooltip(item.documents), theme: 'tooltip' }"
                        class="btn-link btn-attachment"
                        @click.prevent.stop="showMileageDetail(item, true)">
                  <ic-document class="ic ic--gray" />
                </button>
                <button v-else-if="item.status !== 'cancelled'"
                        v-tooltip="{ content: $t('tooltip.attach_files'), theme: 'tooltip' }"
                        class="btn-link btn-attachment"
                        @click.prevent.stop="appStore.showDropzoneModal($tc('title.attach_file', 2), item, { url: `/mileages/${item.id}/documents`, acceptedFiles: 'image/jpg,image/jpeg,image/png,image/tif,image/tiff,application/pdf' })">
                  <ic-attachment class="ic ic--gray" />
                </button>
              </td>
              <td v-if="isPending"
                  class="col-action col-action--2-items"
                  @click.stop="() => {}">
                <span v-if="item.updatable"
                      class="hover-item">
                  <router-link v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                               :to="{ name: 'mileage-edit', params: { id: item.id } }"
                               class="btn-link">
                    <ic-pencil class="ic ic--24 ic--gray" />
                  </router-link>
                  <button v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                          class="btn-link"
                          @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.mileage.delete.title'), content: $t('prompt.mileage.delete.content'), url: `/mileages/${item.id}` })">
                    <ic-remove class="ic ic--gray" />
                  </button>
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="mileagesPagination" />
    </template>
    <component-placeholder v-else
                           :label="placeholderLabel" />
  </div>
</template>

<script>
import { CoreBadge } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate, groupByDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount, formatDistance } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useMileageStore } from '@/stores/mileage'

import MileageDetail from '@/pages/mileage/sidepanel/MileageDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import ComponentTable from '@/components/Table.vue'

export default {
  components: {
    CoreBadge,
    IcAttachment,
    IcDocument,
    IcPencil,
    IcRemove,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const mileageStore = useMileageStore()

    const { fileViewer } = storeToRefs(appStore)
    const { account } = storeToRefs(accountStore)
    const { user } = storeToRefs(authStore)
    const { mileages, mileagesPagination } = storeToRefs(mileageStore)

    return { account, user, appStore, fileViewer, mileages, mileagesPagination, mileageStore }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    isOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    groupedMileages () {
      return this.isOwner
        ? {
            [this.$i18n.t('table.my_mileages')]: this.mileages.filter(i => i.user_uuid === this.user.uuid),
            [this.$i18n.t('table.employees_mileages')]: this.mileages.filter(i => i.user_uuid !== this.user.uuid)
          }
        : groupByDate(this.mileages, 'mileage_date', { dateFieldFormat: 'YYYY-MM-DD' })
    },

    isPending () {
      return this.state === 'pending'
    },

    isDone () {
      return this.state === 'done'
    },

    placeholderLabel () {
      return this.$i18n.t(`placeholder.no_mileage.title${this.isDone ? '_history' : ''}`)
    }
  },

  watch: {
    state () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'mileagesPagination.totalItems' () {
      this.$emit('pagination-total-items', this.mileagesPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('account-switched', this.resetData)
    this.$bus.on('language-changed', this.resetData)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('account-switched', this.resetData)
    this.$bus.off('language-changed', this.resetData)
  },

  methods: {
    getBadgeProps,
    formatAmount,

    formatDistance,

    formatDate,

    async initData () {
      this.loading = true
      await this.mileageStore.getMileages(this.state, this.page, { ...this.filter })
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    fileViewerTooltip (documents) {
      return this.$i18n.tc('tooltip.show_file', documents.length)
    },

    onDeleteSuccess (message) {
      this.initData()
      if (!this.fileViewer.active) {
        showToastSuccess(message || this.$i18n.t('message.success.refund.delete'))
      }
    },

    showMileageDetail (mileage, showFileViewer = false) {
      this.appStore.showSidePanel(
        MileageDetail,
        { model: mileage },
        { wrapperClass: 'sidepanel--md', backgroundOverlay: true, initShowFile: showFileViewer }
      )
    }
  }
}
</script>
