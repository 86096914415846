<template>
  <div class="user-profile-avatar d-flex align-items-center">
    <div class="user-profile-avatar__picture avatar-pic avatar-pic--rounded">
      <user-avatar :key="`${picture?.url}-${picture?.updated_at}`"
                   :size="circleSize"
                   :picture="picture"
                   :placeholder-label="userFullName" />
      <button v-tooltip="{ content: title, theme: 'tooltip' }"
              class="user-profile-avatar__edit"
              type="button"
              @click.prevent.stop="editProfilePicture">
        <ic-pencil class="ic ic--20 ic--white" />
      </button>
    </div>
    <div class="user-profile-avatar__infos">
      <button class="btn-link text-dark strong subtitle"
              type="button"
              @click="editProfilePicture">
        {{ title }}
      </button>
      <div class="text-muted">
        {{ $t('message.info.account.profile_picture_restrictions.subtitle') }}
      </div>
      <button v-if="picture"
              class="btn-link text-error subtitle mt-1"
              type="button"
              data-cy="profile-picture.delete.button"
              @click="deleteProfilePicture">
        {{ $t('button.delete_picture') }}
      </button>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import EditProfilePicture from '@/components/modals/EditProfilePicture.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcPencil,
    UserAvatar
  },

  props: {
    circleSize: {
      type: Number,
      default: 110
    },

    canRemovePicture: {
      type: Boolean,
      default: false
    },

    picture: {
      type: Object,
      default: () => ({})
    },

    pictureToUpdate: {
      type: String,
      validator: prop => ['default', 'user'].includes(prop),
      default: 'default'
    },

    userFullName: {
      type: String,
      required: true
    },

    updatePicture: {
      type: Function,
      default: () => ({})
    }
  },

  emits: ['vcard-picture-updated'],

  setup () {
    const appStore = useAppStore()
    const { user } = storeToRefs(useAuthStore())

    return { user, appStore }
  },

  computed: {
    isUser () {
      return this.pictureToUpdate === 'user'
    },

    title () {
      const pictureType = this.isUser ? 'profile_' : ''
      return this.picture
        ? this.$i18n.t(`title.edit_${pictureType}picture`)
        : this.$i18n.t(`title.add_${pictureType}picture`)
    }
  },

  mounted () {
    this.$bus.on('delete-success', this.deleteProfilePictureSuccess)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.deleteProfilePictureSuccess)
  },

  methods: {
    editProfilePicture () {
      this.appStore.showModal(
        EditProfilePicture,
        {
          canRemovePicture: this.canRemovePicture,
          pictureToUpdate: this.pictureToUpdate,
          title: this.title,
          updatePicture: this.updatePicture
        },
        { wrapperClass: 'modal--sm modal-profile-picture' }
      )
    },

    deleteProfilePicture () {
      this.appStore.showDeleteModal({
        label: this.$i18n.tc('prompt.picture.delete.title', this.isUser ? 2 : 1),
        content: this.$i18n.tc('prompt.picture.delete.content', this.isUser ? 2 : 1),
        url: this.isUser ? '/users/documents/picture' : '',
        callbackDelete: this.isUser ? null : () => this.updatePicture(null)
      })
    },

    deleteProfilePictureSuccess () {
      if (this.isUser) {
        this.user.picture = null
      }
      showToastSuccess(this.$i18n.tc('message.success.picture.delete', this.isUser ? 2 : 1))
    }
  }
}
</script>

<style lang="stylus" scoped>
.user-profile-avatar
  &__edit
    square(100%)
    border-radius 50%
    background alpha($colorDarkGray, 0.8)
    line-height 1
    text-align center
    position absolute
    left 0
    top 0
    opacity 0
    transition opacity 0.3s ease
    color white

    > .ic-pencil
      transform scale(0)
      transition transform 0.3s ease 0.1s

    &:hover
      opacity 1

      > .ic-pencil
        transform scale(1)

  &__picture
    display block
    position relative

  &__infos
    margin-left 2.8rem
</style>
