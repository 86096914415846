<template>
  <div class="dropdown-members-select">
    <div class="input-group">
      <span class="input-group__addon">
        <ic-search class="ic ic--addon ic--18 ic--gray" />
      </span>
      <input v-model="search"
             class="form-control"
             type="text"
             :placeholder="$t('form.search.placeholder')"
             @input="onSearch">
    </div>
    <div class="bordered-box bordered-box--padding">
      <loader-spinner v-if="loading"
                      size="22" />
      <template v-else>
        <div v-if="teamUsers.length === 0"
             class="text-muted center">
          {{ $t('general.no_result') }}
        </div>
        <div v-else>
          <div v-for="(user, index) in teamUsersWithRole"
               :key="index"
               :class="{ 'member-item--disabled': user.teamRole }"
               class="member-item">
            <label class="form-check__label checkbox">
              <input type="checkbox"
                     :disabled="user.teamRole && !isUserSelected(user)"
                     :checked="isUserSelected(user)"
                     @click="toggleSelectUser(user)">
              <span class="checkbox__icon" />
            </label>
            <user-avatar :picture="user.picture"
                         :placeholder-label="user.name" />
            <div class="flex-1"
                 :class="{ strong: isUserSelected(user) }">
              {{ user.name }}
              <template v-if="user.role">
                - {{ user.role_label }}
              </template>
            </div>
            <div v-if="user.teamRole">
              {{ $t(`form.team_role.${user.teamRole}.label`) }}
            </div>
          </div>
        </div>
        <next-page-button v-if="hasNextPage"
                          class="mt-2"
                          :next-page-loading="nextPageLoading"
                          @btn-click="getNextPage" />
      </template>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'

import { useTeamStore } from '@/stores/team'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import NextPageButton from '@/components/NextPageButton.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    NextPageButton,
    IcSearch,
    LoaderSpinner,
    UserAvatar
  },

  props: {
    selectedMembers: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    hideMembers: {
      type: Array,
      default: () => ([])
    }
  },

  emits: ['update:selected-members', 'members-loaded'],

  setup () {
    const teamStore = useTeamStore()
    const { teamUsersPagination } = storeToRefs(teamStore)

    return {
      teamStore,
      teamUsersPagination
    }
  },

  data () {
    return {
      loading: true,
      teamUsers: [],

      form: {
        selectedMembers: []
      },

      membersType: '',
      nextPageLoading: false,
      search: ''
    }
  },

  computed: {
    teamUsersWithRole () {
      return this.teamUsers.map(user => ({
        ...user,
        ...this.hideMembers.find(hideMember => hideMember.uuid === user.uuid) || {}
      }))
    },

    hasNextPage () {
      return this.teamUsersPagination.current < this.teamUsersPagination.count
    }
  },

  async created () {
    this.form.selectedMembers = cloneDeep(this.selectedMembers)
    await this.initData()
  },

  methods: {
    async initData () {
      this.loading = true
      await this.getData()
      this.loading = false
    },

    onSearch: debounce(function () {
      this.initData()
    }, 500),

    isUserSelected (user) {
      return this.form.selectedMembers.some(u => u.uuid === user.uuid)
    },

    toggleSelectUser (user) {
      if (this.isUserSelected(user)) {
        const index = this.form.selectedMembers.findIndex(u => u.uuid === user.uuid)
        this.form.selectedMembers.splice(index, 1)
      } else {
        this.form.selectedMembers.push(user)
      }
      this.$emit('update:selected-members', this.form.selectedMembers)
    },

    async getData (page = 1) {
      const users = await this.teamStore.getTeamUsers({
        search: this.search,
        page
      })

      this.teamUsers = (page === 1) ? users : [...this.teamUsers, ...users]

      this.$bus.emit('members-loaded', { bySearch: this.search !== '', users: this.teamUsers })
    },

    async getNextPage () {
      this.nextPageLoading = true
      const page = this.teamUsersPagination.current + 1
      await this.getData(page)
      this.nextPageLoading = false
    },

    getTeamsLabel (teams) {
      return teams.length >= 2
        ? this.$i18n.tc('form.operations.attributions.multiple', teams.length - 1, {
          name: teams[0].name,
          number: teams.length - 1
        })
        : teams[0].name
    },

    tooltipTeamsLabel (teams) {
      return teams.length >= 2 ? teams.map(t => `${t.name}<br>`).join('') : false
    }
  }
}
</script>

<style scoped lang="stylus">
.dropdown-members-select
  .input-group__addon
    border-bottom-left-radius 0
    border-bottom 0
  .form-control
    border-bottom-right-radius 0
    border-bottom 0
  .bordered-box
    border-top-left-radius 0
    border-top-right-radius 0
    max-height 40rem
    overflow auto
  .member-item
    display flex
    align-items center
    &:not(:first-child)
      padding-top 1.6rem
    &:not(:last-child)
      padding-bottom 1.6rem
      border-bottom 1px solid #F5F6F9
    &--disabled
      opacity 0.5
  .user-avatar
  .checkbox__icon
    margin-right 2rem
</style>
