<template>
  <svg viewBox="0 0 24 24">
    <path d="M12.25 3C17.082 3 21 7.03 21 12s-3.918 9-8.75 9a8.574 8.574 0 0 1-5.686-2.16l1.053-1.615a6.746 6.746 0 0 0 4.633 1.846c3.797 0 6.875-3.166 6.875-7.071s-3.078-7.071-6.875-7.071c-3.467 0-6.335 2.64-6.807 6.071H8.3l-3.75 6L.8 11h2.753c.484-4.5 4.193-8 8.697-8z" />
  </svg>
</template>

<script>
export default {
}
</script>
