<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="transaction_type"
                          dropdown-width="100%"
                          :values="transactionTypes"
                          :model="selectedTransactionType ? transactionTypes[selectedTransactionType] : null"
                          @select="onTransactionTypeSelected">
        <template #trigger>
          <div class="select"
               :class="selectClass(modelValue)">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue">
                {{ selectedTransactionType }}
              </template>
              <template v-else>
                {{ $t('form.mandate.filter.transaction_type.placeholder') }}
              </template>
            </div>
            <button v-if="modelValue"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$emit('clear-field', 'transaction_type')">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import { findKey } from 'lodash'

import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      type: [String, null],
      default: null
    },

    selectClass: {
      type: Function,
      required: true
    }
  },

  emits: [
    'clear-field',
    'update:modelValue'
  ],

  data () {
    return {
      selectedTransactionType: null
    }
  },

  computed: {
    transactionTypes () {
      return {
        B2B: this.$i18n.t('form.debits.filter.sepa.b2b'),
        CORE: this.$i18n.t('form.debits.filter.sepa.b2c')
      }
    }
  },

  methods: {
    onTransactionTypeSelected (type) {
      this.selectedTransactionType = type
      this.$emit('update:modelValue', findKey(this.transactionTypes, item => item === type))
    }
  }
}
</script>
