<template>
  <svg viewBox="0 0 200 160">
    <path d="M123 49.25H66.54v1.5H123v-1.5Zm8.5 56.86a15.707 15.707 0 0 0-7-2.3H123a15.68 15.68 0 0 0-15 19.49 15.722 15.722 0 0 0 2.93 6c.2.26.42.51.64.75l.142.155c.179.198.369.406.558.595a15.731 15.731 0 0 0 26.639-9.638 15.732 15.732 0 0 0-7.389-15.002l-.02-.05Zm-8.25 27.64a14.157 14.157 0 0 1-8.71-3c-.31-.24-.6-.49-.89-.75-.29-.26-.52-.49-.76-.75a14.27 14.27 0 0 1-2.87-4.5 14.21 14.21 0 0 1 13-19.49h1.5c7.573.697 13.265 7.218 12.931 14.816-.334 7.598-6.576 13.595-14.181 13.624l-.02.05ZM123 122.5h.54c.07.008.14.008.21 0a.69.69 0 0 0 .57-.61l.18-4.63.28-7a.619.619 0 0 0-.28-.52.848.848 0 0 0-.5-.24h-1.4a.732.732 0 0 0-.78.69l.46 11.67a.73.73 0 0 0 .72.64Zm.75 2.33a1.73 1.73 0 0 0-.5-.08H123a1.77 1.77 0 1 0 .75.06v.02Z"
          :fill="colorPrimary" />
    <path d="M126.53 35.25H77.06a5 5 0 0 0-5 5.06v.94h-2a5 5 0 0 0-5 5.06V123c0 .199.08.389.22.53l7 7c.14.141.331.22.53.22h37.45c-.21-.24-.41-.49-.61-.75s-.38-.49-.56-.75H73.53c-.16-5-2-6.83-7-7V46.31a3.54 3.54 0 0 1 3.51-3.56h49.47a3.54 3.54 0 0 1 3.49 3.56v55.95h1.5v-56a5.002 5.002 0 0 0-5-5.06h-46v-.94a3.54 3.54 0 0 1 3.51-3.56h49.47a3.538 3.538 0 0 1 3.52 3.61v63.31c.513.22 1.013.463 1.5.73v-64a5 5 0 0 0-4.97-5.1ZM72 128.11l-4.28-4.28c2.89.27 3.97 1.36 4.28 4.28Z"
          :fill="colorDark" />
    <path d="M94.29 62.25h-23v1.5h23v-1.5Zm-23 6v1.5h32v-1.5h-32Zm0 11.94v1.5h19.9v-1.5h-19.9Zm0 7.56h25v-1.5h-25v1.5Zm0-12h26v-1.5h-26v1.5Z"
          :fill="colorGrey" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
