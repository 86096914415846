<template>
  <validation-field v-slot="{ errors }"
                    :model-value="modelValue"
                    :name="name"
                    @keydown.enter.prevent>
    <input ref="input"
           type="text"
           :data-cy="dataCy"
           :class="[$attrs.class, { error: errors?.length && displayError }]"
           :value="formattedInput"
           :maxlength="maxlength"
           :name="name"
           :disabled="disabled"
           :placeholder="placeholder"
           @input="onInput"
           @change="$emit('change', $event.target.value)"
           @focus="onFocus"
           @blur="onBlur"
           @keyup.enter="e => e.target.blur()">
  </validation-field>
</template>

<script>
export default {
  props: {
    dataCy: {
      type: String,
      default: 'number-input'
    },

    displayError: {
      type: Boolean,
      default: true
    },

    modelValue: {
      type: [String, Number],
      default: 0,
      required: false
    },

    defaultZero: {
      type: Boolean,
      required: false,
      default: true
    },

    name: {
      type: String,
      required: true
    },

    addon: {
      type: String,
      required: false,
      default: ''
    },

    decimal: {
      type: Boolean,
      required: false,
      default: false
    },

    minimumFractionDigits: {
      type: [Number, null],
      required: false,
      default: null
    },

    localeString: {
      type: Boolean,
      required: false,
      default: true
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    maxlength: {
      type: Number,
      required: false,
      default: -1
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['change', 'focus', 'blur', 'update:modelValue'],

  data () {
    return {
      showAddon: true
    }
  },

  computed: {
    formattedInput () {
      let formattedValue = ''
      if (!this.modelValue && this.modelValue !== 0) {
        return ''
      }
      if (this.showAddon) {
        let minimumFractionDigits = this.decimal ? 2 : 0
        if (this.minimumFractionDigits !== null) {
          minimumFractionDigits = this.minimumFractionDigits
        }
        formattedValue = this.localeString && this.modelValue ? this.modelValue.toLocaleString('fr-FR', { minimumFractionDigits }) : this.modelValue
        return this.addon ? `${formattedValue} ${this.addon}` : formattedValue
      }
      if (this.localeString) {
        return `${this.modelValue.toLocaleString('fr-FR', { minimumFractionDigits: 0 }).replace(/(\s)/g, '')}`
      }
      return this.modelValue
    }
  },

  methods: {
    focus () {
      this.$refs.input?.focus?.()
    },

    onFocus () {
      this.showAddon = false
      this.$emit('focus')
    },

    processInputValue (value) {
      value = value.replace(',', '.')
      let result = null
      if (this.defaultZero) {
        result = this.decimal ? parseFloat(value || '0') : parseInt(value || '0')
      } else {
        if (value !== null && value !== '') {
          result = this.decimal ? parseFloat(value) : parseInt(value)
        }
      }
      return result
    },

    onBlur (e) {
      this.showAddon = true
      const result = this.processInputValue(e.target.value)
      this.$emit('blur', result)
    },

    onInput (e) {
      const regex = this.decimal ? /^\d+(\.|,)?\d{0,2}$/ : /^\d+$/
      if (e.target.value && !e.target.value.match(regex)) {
        e.target.value = e.target.value.slice(0, -1)
      }

      const result = this.processInputValue(e.target.value)
      this.$emit('update:modelValue', result)
    }
  }
}
</script>
