<template>
  <div class="sidepanel__content">
    <transition name="fade">
      <file-viewer v-if="displayFileViewer"
                   ref="fileviewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <div class="sidepanel-header">
      <div class="transaction-summary transaction-summary--no-border">
        <h2 class="transaction-amount">
          {{ formatAmount(model.amount, model.currency) }}
        </h2>
        <p class="transaction-name">
          {{ model.label }}
        </p>
        <core-badge v-if="model.status_label"
                    v-bind="getBadgeProps(model.status)"
                    :size="ECoreBadgeSize.Large"
                    :value="model.status_label"
                    class="transaction-badge" />
        <div v-if="model.status !== 'cancelled' && showHeader"
             class="document-actions-wrapper">
          <button type="button"
                  class="btn btn-proofs"
                  :class="proofButton.btnClass"
                  @click="showFile">
            <span class="btn-icon btn-icon--left">
              <component :is="proofButton.icon"
                         class="ic ic--24 ic--gray" />
            </span>
            <span class="btn__label">{{ proofLabel }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="sidepanel-body">
      <div class="sidepanel-details">
        <h3 class="head-title">
          {{ $t('sidepanel.transfer.title') }}
        </h3>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("general.created_at") }}</span>
          <span class="entry-value right">{{ formatDateText(model.created_at, 'lll') }}</span>
        </div>
        <div v-if="model.operation.expense_date"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("form.expense_date.label") }}</span>
          <span class="entry-value right">{{ formatDateText(model.operation.expense_date, 'll') }}</span>
        </div>
        <div v-if="model.execution_date"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("general.execution_date") }}</span>
          <span class="entry-value right">{{ formatDateText(model.execution_date, 'll') }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("general.beneficiary") }}</span>
          <span v-if="model.beneficiary.label"
                class="entry-value right">{{ model.beneficiary.label }}</span>
          <span v-else
                class="entry-value right">{{ formatIban(model.beneficiary.iban) }}</span>
        </div>
        <div v-if="hasCategory"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ categoryLabel }}</span>
          <span v-tooltip.bottom-start="{ content: tooltipCategoriesLabel, theme: 'tooltip', html: true }"
                class="entry-value right">{{ categoryList }}</span>
        </div>
        <template v-if="hasRestaurantCategory">
          <div v-if="model.operation.detail.guest"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.guests.label') }}
            </div>
            <span class="entry-value right">{{ model.operation.detail.guest }}</span>
          </div>
          <div v-if="model.operation.detail.service"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.refunds.service.label') }}
            </div>
            <span class="entry-value right">{{ $t(`form.refunds.service.${model.operation.detail.service}`) }}</span>
          </div>
        </template>
        <template v-if="hasHotelCategory">
          <div v-if="model.operation.detail.nights"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.hotels.label') }}
            </div>
            <span class="entry-value right">{{ model.operation.detail.nights }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.dinner_included.label') }}
            </div>
            <span class="entry-value right">{{ model.operation.detail.half_board_included ? $t('general.yes') : $t('general.no') }}</span>
          </div>
        </template>
        <template v-if="hasFuelStationCategory">
          <div v-if="model.operation.detail.fuel"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.fuel.label') }}
            </div>
            <span class="entry-value right">{{ model.operation.detail.fuel.label }}</span>
          </div>
          <div v-if="model.operation.detail.liters"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.liters.label') }}
            </div>
            <span class="entry-value right">{{ model.operation.detail.liters }}</span>
          </div>
          <div v-if="model.operation.detail.mileage"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.mileage.label') }}
            </div>
            <span class="entry-value right">{{ model.operation.detail.mileage }}</span>
          </div>
        </template>
        <sidepanel-affectations v-if="canReadAffectations"
                                :can-edit-affectations="false"
                                :can-write-affectations="false"
                                :affectations="model.operation.affectations" />
        <div v-if="model.operation.comment"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("form.personal_note.label") }}</span>
          <span class="entry-value right">{{ model.operation.comment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeSize } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'

import FileViewer from '@/components/FileViewer.vue'
import SidepanelAffectations from '@/components/SidepanelAffectations.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'

export default {
  components: {
    CoreBadge,
    FileViewer,
    SidepanelAffectations
  },

  props: {
    model: {
      type: Object,
      required: true
    },

    showHeader: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    const appStore = useAppStore()

    const { sidePanel } = storeToRefs(appStore)

    return { sidePanel, appStore }
  },

  data () {
    return {
      displayFileViewer: false
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    hasDocument () {
      return this.model.operation?.documents?.length
    },

    hasCategory () {
      return this.model.operation?.detail?.categories?.length
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.refundsAffectationsRead)
    },

    categoryLabel () {
      return this.hasCategory ? this.$i18n.tc('form.category.label', this.model.operation.detail.categories.length) : null
    },

    tooltipCategoriesLabel () {
      return this.model.operation.detail.categories.length >= 2 ? this.model.operation.detail.categories.map(c => `${c.label}<br>`).join('') : false
    },

    categoryList () {
      return this.hasCategory
        ? this.$i18n.tc('form.transfer_details.categories', this.model.operation.detail.categories.length - 1, { name: this.model.operation.detail.categories[0].label })
        : null
    },

    hasRestaurantCategory () {
      return this.hasCategory && this.model.operation.detail.categories.some(category => category.name === 'restaurant')
    },

    hasHotelCategory () {
      return this.hasCategory && this.model.operation.detail.categories.some(category => category.name === 'hotel-logement')
    },

    hasFuelStationCategory () {
      return this.hasCategory && this.model.operation.detail.categories.some(category => category.name === 'station-essence')
    },

    isToValidate () {
      return this.model.status === 'to_validate'
    },

    isRejected () {
      return this.model.status === 'rejected'
    },

    proofButton () {
      return this.hasDocument ? { icon: IcDocument, btnClass: 'btn--outline btn-document' } : { icon: IcAttachment, btnClass: 'btn--outline btn-attachment' }
    },

    proofLabel () {
      return this.hasDocument ? this.$i18n.tc('button.show_proofs', this.hasDocument) : this.$i18n.t('button.attach_proof')
    }
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.closeFileViewer)
    if (this.sidePanel.initShowFile) this.showFile()
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.closeFileViewer)
  },

  methods: {
    getBadgeProps,
    formatIban,

    formatAmount,

    formatDateText,

    closeFileViewer () {
      this.displayFileViewer = false
    },

    showFile () {
      if (this.hasDocument) {
        this.appStore.showFileViewer({
          content: this.model.operation.documents,
          isMultiDoc: true,
          canEdit: true,
          operationId: this.model.operation.uuid,
          documentsUrl: `/operations/${this.model.operation.uuid}/documents`,
          dataModel: this.model
        })
        this.displayFileViewer = true
      } else {
        this.appStore.showDropzoneModal(
          this.$i18n.tc('title.attach_file', 2),
          this.model.operation,
          {
            url: `/operations/${this.model.operation.uuid}/documents`,
            canAnalyzeFile: true,
            hasMultipleFiles: true
          })
      }
    }
  }
}
</script>
