<template>
  <div class="chart-badge">
    <div class="chart-badge__square"
         :class="colorClass" />
    <span class="chart-badge__title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'ComponentsChartBadge',

  props: {
    color: {
      type: String,
      required: true,
      validator: prop => ['light-green', 'light-red', 'two-tone', 'red', 'green'].includes(prop)
    },

    title: {
      type: String,
      required: true
    }
  },

  computed: {
    colorClass () {
      return `bg-${this.color}`
    }
  }
}
</script>

<style lang="stylus">
.chart-badge
  display flex
  align-items center
  justify-content center

  &__square
    width 12px
    height 12px
    border-radius 3px
    margin-right 0.6rem

    &.bg-light-green
      background #47E7A8

    &.bg-light-red
      background #FF8981

    &.bg-two-tone
      background linear-gradient(135deg, $colorGreen 50%, $colorRed 50%)

    &.bg-red
      background $colorRed

    &.bg-green
      background $colorGreen

  &__title
    font-size 13px
    font-weight 400
    color $colorMidGray

  &--tooltip
    margin-top .5rem

    .chart-badge__title
      font-weight 400
      font-size 13px

</style>
