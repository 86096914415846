<template>
  <table class="table">
    <slot />
  </table>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { useAppStore } from '@/stores/app'

export default {
  name: 'ComponentTable',

  setup () {
    const appStore = useAppStore()

    const { sidePanel } = storeToRefs(appStore)

    return { sidePanel }
  },

  data () {
    return {
      store,
      rows: []
    }
  },

  created () {
    this.initTable()
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.removeClass)
    this.observer = new MutationObserver(this.initTable)
    this.observer.observe(this.$el, {
      childList: true,
      subtree: true
    })
  },

  beforeUnmount () {
    this.observer.disconnect()
    this.removeAllListeners()
  },

  unmounted () {
    this.$bus.off('hide-sidepanel', this.removeClass)
  },

  methods: {
    addClass (e) {
      if (this.sidePanel.active) {
        e.classList.add('is-selected')
      }
    },

    initTable () {
      this.removeAllListeners()
      this.rows = document.querySelectorAll('.is-link')
      this.rows.forEach(row => {
        row.addEventListener('click', this.onSelect)
      })
    },

    onSelect (e) {
      setTimeout(this.addClass, 30, e.currentTarget)
    },

    removeClass () {
      const row = document.getElementsByClassName('is-selected')
      if (row.length) {
        row[0].classList.remove('is-selected')
      }
    },

    removeAllListeners () {
      this.rows.forEach(row => {
        row.removeEventListener('click', this.onSelect)
      })
    }
  }
}
</script>
