<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'users' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ title }}
        </h1>
      </div>
      <div v-if="id"
           class="section__heading__right">
        <div v-if="!isReadOnly"
             class="section-actions">
          <button class="btn btn--outline-red"
                  @click.prevent="onDeleteUser">
            {{ $t('button.delete_user') }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <validation-form ref="form"
                       v-slot="{ errors, isSubmitting }"
                       class="form form--w1000 form--centered"
                       :validation-schema="schema"
                       @keydown.enter.prevent
                       @invalid-submit="onInvalidSubmit"
                       @submit="onSubmit">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('form.alerts.common')" />
        <component-alert v-if="isReadOnly"
                         type="info"
                         :message="$t('form.alerts.user.read_only')" />
        <component-user-informations :key="`info-${invitation.id}`"
                                     v-model:user="invitation"
                                     :errors="errors"
                                     :mode="mode" />
        <component-user-rights :key="`access-${invitation.id}`"
                               v-model:user="invitation"
                               :mode="isEditingUser ? 'edit' : 'create'" />
        <div v-if="!isReadOnly"
             class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'users' }">
            {{ $t("button.cancel") }}
          </router-link>
          <button data-cy="users.invite.submit-button"
                  type="submit"
                  class="btn btn--primary"
                  :disabled="isSubmitting">
            {{ $t("button.validate") }}
          </button>
        </div>
      </validation-form>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useInvitationStore } from '@/stores/invitation'
import { useUserStore } from '@/stores/user'

import ComponentAlert from '@/components/Alert.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

import ComponentUserInformations from './components/form/UserInformations.vue'
import ComponentUserRights from './components/form/UserRights.vue'

export default {
  components: {
    ComponentAlert,
    ComponentUserInformations,
    ComponentUserRights,
    IcArrow
  },

  props: {
    mode: {
      type: String,
      required: true
    },

    id: {
      type: [Number, String],
      default: null
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const apiStore = useApiStore()
    const invitationStore = useInvitationStore()
    const userStore = useUserStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { users } = storeToRefs(userStore)
    const { user } = storeToRefs(authStore)
    const {
      invitationTransferLimit,
      invitationRefundLimit,
      invitationMileageLimit,
      invitationExpenseRequestConfig,
      invitations
    } = storeToRefs(invitationStore)

    return {
      accountStore,
      appStore,
      invitationStore,
      userStore,

      account,
      apiError,
      invitationTransferLimit,
      invitationRefundLimit,
      invitationMileageLimit,
      invitationExpenseRequestConfig,
      invitations,
      users,
      user
    }
  },

  data () {
    return {
      invitation: {
        id: null,
        role: '',
        email: '',
        last_name: '',
        first_name: '',
        company: this.account.company.name,
        phone: '',
        employee_number: '',
        language: '',
        team: '',
        team_role: '',
        status: '',
        access: {
          operations: false,
          transfers: false,
          debits: false,
          credits: false,
          statements: false,
          cards: false,
          refunds: false,
          mileages: false,
          accounting: false,
          treasury: false
        },

        card_settings: {
          virtual: {
            request: false
          }
        },

        alert_transfer_limit: null,
        daily_transfer_limit: null,
        weekly_transfer_limit: null,
        monthly_transfer_limit: null,
        alert_refund_limit: null,
        daily_refund_limit: null,
        weekly_refund_limit: null,
        monthly_refund_limit: null,
        monthly_mileage_limit: null,
        affectations: [],
        notify: false,

        expense_request_config: {
          trigger: null,
          type: 'simple',
          approvers: []
        },

        beneficiary: {
          iban: '',
          bic: '',
          details: {
            social_security_number: '',
            contract_type: null,
            contract_start_date: null,
            contract_end_date: null,
            advance_payment: false
          }
        }
      },

      isValidIban: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        advance_payment: yup.boolean(),
        first_name: yup.string().when([], {
          is: () => !this.isEditingUser,
          then: () => yup.string().required().matches(/^([^0-9]*)$/)
        }),
        last_name: yup.string().when([], {
          is: () => !this.isEditingUser,
          then: () => yup.string().required().matches(/^([^0-9]*)$/)
        }),
        email: yup.string().when([], {
          is: () => !this.isEditingUser,
          then: () => yup.string().required().email()
        }),
        phone: yup.string().validPhoneNumber().nullable(),
        roles: yup.string().required(),
        role: yup.string().when('roles', {
          is: 'other',
          then: () => yup.string().required()
        }),
        iban: yup.string().when('advance_payment', {
          is: advance_payment => !!advance_payment && !this.isEditingUser && !this.beneficiary?.id,
          then: () => yup.string().required()
        }),
        bic: yup.string().when('advance_payment', {
          is: advance_payment => !!advance_payment && !this.isEditingUser && !this.beneficiary?.id,
          then: () => yup.string().required()
        })
      })
    },

    title () {
      if (this.isReadOnly) {
        return `${this.invitation.first_name} ${this.invitation.last_name}`
      }

      return this.id ? this.$i18n.t('title.edit_user') : this.$i18n.t('title.invite_user')
    },

    isRoleOther () {
      return this.invitation.role === 'other'
    },

    isAccountSequestre () {
      return this.account.status === 'sequestre'
    },

    hasBankDetails () {
      return ['salarie', 'associe', 'employee', 'associate'].includes(this.invitation.role)
    },

    isEditingUser () {
      return this.mode === 'edit-user'
    },

    isReadOnly () {
      return this.isEditingUser ? !this.hasPermission(this.$permissions.usersWrite) : !this.hasPermission(this.$permissions.invitationsWrite)
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    },

    invitationTransferLimit: function () {
      this.invitation.alert_transfer_limit = this.invitationTransferLimit.alert_transfer_limit
      this.invitation.daily_transfer_limit = this.invitationTransferLimit.daily_transfer_limit
      this.invitation.weekly_transfer_limit = this.invitationTransferLimit.weekly_transfer_limit
      this.invitation.monthly_transfer_limit = this.invitationTransferLimit.monthly_transfer_limit
    },

    invitationRefundLimit: function () {
      this.invitation.alert_refund_limit = this.invitationRefundLimit.alert_refund_limit
      this.invitation.daily_refund_limit = this.invitationRefundLimit.daily_refund_limit
      this.invitation.weekly_refund_limit = this.invitationRefundLimit.weekly_refund_limit
      this.invitation.monthly_refund_limit = this.invitationRefundLimit.monthly_refund_limit
    },

    invitationMileageLimit: function () {
      this.invitation.monthly_mileage_limit = this.invitationMileageLimit.monthly_mileage_limit
    },

    invitationExpenseRequestConfig: function () {
      this.invitation.expense_request_config = this.invitationExpenseRequestConfig
    }
  },

  async created () {
    if (!this.id) {
      this.invitation.language = this.user.language
      return
    }

    const user = this.isEditingUser
      ? this.users.find(o => o.uuid === this.id)
      : this.invitations.find(o => o.id === parseInt(this.id))

    if (!user) return this.goBackToList()

    this.invitation = {
      id: user.id,
      uuid: user.uuid,
      role: user.role || '',
      role_label: user.role === 'other' ? (user.role_label) : '',
      email: user.email,
      last_name: user.last_name,
      first_name: user.first_name,
      company: user.company,
      phone: user.phone,
      accepted_at: user.accepted_at,
      employee_number: user.employee_number,
      language: user.language,
      team_id: user.team?.id,
      team_name: user.team?.name,
      team_role: user.team_role,
      status: user.status,
      access: {
        operations: user.access.operations,
        transfers: user.access.transfers,
        debits: user.access.debits,
        credits: user.access.credits,
        statements: user.access.statements,
        cards: user.access.cards,
        refunds: user.access.refunds,
        mileages: user.access.mileages,
        accounting: user.access.accounting,
        treasury: user.access.treasury
      },

      permissions: user.permissions,

      card_settings: cloneDeep(user.card_settings),
      alert_transfer_limit: user.access.transfer_config.alert_transfer_limit,
      daily_transfer_limit: user.access.transfer_config.daily_transfer_limit,
      weekly_transfer_limit: user.access.transfer_config.weekly_transfer_limit,
      monthly_transfer_limit: user.access.transfer_config.monthly_transfer_limit,
      alert_refund_limit: user.access.refund_config.alert_refund_limit,
      daily_refund_limit: user.access.refund_config.daily_refund_limit,
      weekly_refund_limit: user.access.refund_config.weekly_refund_limit,
      monthly_refund_limit: user.access.refund_config.monthly_refund_limit,
      monthly_mileage_limit: user.access.mileage_config.monthly_mileage_limit,
      expense_request_config: user.access.expense_request_config || {
        trigger: null,
        type: 'simple',
        approvers: []
      },

      affectations: user.affectations
    }

    if (user.beneficiary) {
      this.invitation.beneficiary = user.beneficiary
      this.invitation.beneficiary.iban = formatIban(user.beneficiary.iban)
    } else {
      this.invitation.beneficiary = {
        iban: '',
        bic: '',
        details: {
          social_security_number: '',
          contract_type: null,
          contract_start_date: null,
          contract_end_date: null,
          advance_payment: false
        }
      }
    }

    this.invitationStore.setInvitationTransferLimit({
      alert_limit: user.access.transfer_config.alert_transfer_limit,
      daily_limit: user.access.transfer_config.daily_transfer_limit,
      weekly_limit: user.access.transfer_config.weekly_transfer_limit,
      monthly_limit: user.access.transfer_config.monthly_transfer_limit
    })
    this.invitationStore.setInvitationRefundLimit({
      alert_limit: user.access.refund_config.alert_refund_limit,
      daily_limit: user.access.refund_config.daily_refund_limit,
      weekly_limit: user.access.refund_config.weekly_refund_limit,
      monthly_limit: user.access.refund_config.monthly_refund_limit
    })
    this.invitationStore.setInvitationMileageLimit({
      monthly_limit: user.access.mileage_config.monthly_mileage_limit
    })
    this.invitationStore.setInvitationExpenseRequestConfig(this.invitation.expense_request_config)
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('account-switched', this.onSwitchedAccount)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('account-switched', this.onSwitchedAccount)
  },

  methods: {
    onSwitchedAccount () {
      if (this.invitation.id || this.isEditingUser) {
        this.$router.push({ name: 'users' })
      } else {
        this.invitation.company = this.account.company.name
      }
    },

    onInvalidSubmit () {
      this.$bus.emit('scrollToTop')
    },

    async onSubmit () {
      const payload = cloneDeep(this.invitation)

      if (this.isAccountSequestre) {
        payload.access.operations = true
      }

      if (this.hasBankDetails) {
        payload.company = this.account.company.name
      }

      payload.beneficiary.details.contract_start_date = payload.beneficiary.details.contract_start_date ? dayjs(payload.beneficiary.details.contract_start_date).format('YYYY-MM-DD') : null
      payload.beneficiary.details.contract_end_date = payload.beneficiary.details.contract_end_date ? dayjs(payload.beneficiary.details.contract_end_date).format('YYYY-MM-DD') : null

      if (payload.access.cards) {
        payload.card_settings.virtual.request = false
      }

      if (!this.isRoleOther) {
        delete payload.role_label
      }

      if (this.invitation.card_settings.virtual.request) {
        payload.permissions = [this.$permissions.cardsVirtualRequest]
      }

      if (this.id) {
        if (this.isEditingUser) {
          const data = await this.userStore.updateUser(payload.uuid, payload)
          if (data) { this.onUpdateSuccess(data) }
        } else {
          const data = await this.invitationStore.updateInvitation(payload.id, payload)
          if (data) { this.onUpdateSuccess(data) }
        }
      } else {
        const result = await this.invitationStore.saveInvitation(payload)
        if (result) { this.onSaveSuccess() }
      }
    },

    onDeleteSuccess () {
      showToastSuccess(this.$t('message.success.invitation.delete'))
      this.goBackToList()
    },

    onDeleteUser () {
      this.appStore.showDeleteModal({
        label: this.$i18n.t('prompt.invitation.delete.title'),
        content: this.invitation.access.card_holder
          ? this.$i18n.t('prompt.card_holder.delete.content')
          : this.$i18n.t('prompt.invitation.delete.content'),
        url: this.isEditingUser ? `/users/${this.invitation.uuid}` : `/invitations/${this.invitation.id}`
      })
    },

    onSaveSuccess () {
      showToastSuccess(this.$t('message.success.invitation.save'))
      this.goBackToList()
    },

    onUpdateSuccess (data) {
      const message = JSON.stringify(this.invitation.access) === JSON.stringify(data.access)
        ? this.$i18n.t('message.success.invitation.update_user')
        : this.$i18n.t('message.success.invitation.update_rights')
      showToastSuccess(message)
      this.goBackToList()
    },

    goBackToList () {
      this.$router.push({ name: this.isEditingUser ? 'users' : 'users-pending' })
    }
  }
}
</script>
