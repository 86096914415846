import { ECoreBannerTheme } from '@common/core-ui'
import axios from 'axios'
import dayjs from 'dayjs'
import NProgress from 'nprogress'

import store from '@/config/store'
import bus from '@/helpers/eventBus'
import { getFlatErrorsArray } from '@/helpers/utils'
import i18n from '@/locales'
import router from '@/router'
import { useAccountStore } from '@/stores/account'
import { useAnalyticStore } from '@/stores/analytics'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useFirebaseStore } from '@/stores/firebase'

import Reconnect from '@/components/modals/Reconnect.vue'

const axiosClient = axios.create({
  baseURL: store.api.base
})

const NProgressInstance = NProgress.configure({ showSpinner: false, speed: 200, trickleSpeed: 100 })

const removeAbortController = response => {
  const apiStore = useApiStore()

  if (response.config) {
    apiStore.removeAbortController(response.config.signal)
  }
}

let startTime = 0
let endTime = 0

const retryRequest = response => {
  return () => axiosClient.request({
    data: response.config.data ? JSON.parse(response.config.data) : {},
    method: response.config.method,
    url: response.config.url
  })
}

axiosClient.interceptors.request.use(async config => {
  const apiStore = useApiStore()
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const firebaseStore = useFirebaseStore()

  config.responseType ||= 'json'
  apiStore.incrementOpenedRequests()

  if (import.meta.env.VITE_CLIENT_BRAND) { config.headers['X-Client-Brand'] = import.meta.env.VITE_CLIENT_BRAND }
  config.headers['X-Mone-Console-Version'] = import.meta.env.PROJECT_VERSION
  config.headers['Accept-Language'] = i18n.global.locale

  if (!NProgressInstance.isStarted() && !store.api.hideProgressBar) {
    NProgressInstance.start()
  }
  startTime = dayjs().valueOf()

  if (config.method === 'get' && !config.signal && config.responseType === 'json' && config.url !== '/init') {
    config.signal = apiStore.addAbortController().signal
  }

  for (const key in config.params) {
    if (Object.prototype.hasOwnProperty.call(config.params, key) && (config.params[key] === '' || config.params[key] === null)) {
      delete config.params[key]
    }
  }

  if (authStore.user.token !== null && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${authStore.user.token}`
  }

  if (typeof accountStore.account?.uuid !== 'undefined') {
    config.headers['X-Mone-Account-ID'] = accountStore.account.uuid
  }

  const appCheckToken = await firebaseStore.getAppCheckToken()
  if (appCheckToken) { config.headers['X-Firebase-AppCheck'] = appCheckToken }

  config.headers['X-Client-DateTime'] = dayjs().format()

  if (import.meta.env.VITE_API_PROXY_URL && config.url.startsWith(import.meta.env.VITE_API_PROXY_URL)) {
    // if we are in proxy mode, ensure api url are proxy
    config.url = config.url.replace(import.meta.env.VITE_API_PROXY_URL, '')
  }

  apiStore.resetError()
  apiStore.setLoading(true)

  return config
}, error => {
  const apiStore = useApiStore()

  NProgressInstance.done()
  apiStore.setLoading(false)
  return Promise.reject(error)
})

axiosClient.interceptors.response.use(response => {
  const accountStore = useAccountStore()
  const analyticStore = useAnalyticStore()
  const apiStore = useApiStore()
  const authStore = useAuthStore()

  analyticStore.logNetworkEvent(response.config, response)

  apiStore.decrementOpenedRequests()
  removeAbortController(response)

  if (response.headers['x-mone-account-balance'] && !isNaN(parseFloat(response.headers['x-mone-account-balance']))) {
    accountStore.account.balance = parseFloat(response.headers['x-mone-account-balance'])
  }

  if (response.headers['x-authorization-token']) {
    authStore.replaceToken(response.headers['x-authorization-token'])
  }

  if (response.headers['x-mone-invitations-waiting-validation-count']) {
    accountStore.account.invitation_waiting_validation_count = parseInt(response.headers['x-mone-invitations-waiting-validation-count'])
  }

  endTime = dayjs().valueOf()
  const requestTime = endTime - startTime
  if (response.headers.date) {
    store.data.deltaTime = (dayjs.utc(response.headers.date).valueOf() + requestTime - dayjs().valueOf()) / 1000
  }

  if (!apiStore.openedRequests) {
    apiStore.setLoading(false)
    if (!store.api.hideProgressBar && response.status) {
      NProgressInstance.done()
    }
  }
  return response
}, async error => {
  const apiStore = useApiStore()
  const analyticStore = useAnalyticStore()
  const appStore = useAppStore()

  apiStore.decrementOpenedRequests()
  removeAbortController(error)

  if (!apiStore.openedRequests) {
    apiStore.setLoading(false)

    if (!store.api.hideProgressBar && error.response && error.response.status) {
      NProgressInstance.done()
    }
  }

  if (!error.response || error.code === 'ERR_NETWORK' || error.code === 'ERR_CANCELED') {
    analyticStore.logNetworkEvent(error.config, null, error.code)

    if (error.code !== 'ERR_CANCELED') {
      appStore.showBanner(ECoreBannerTheme.Danger, store.translations.message.warning.unknown)
    }
    NProgressInstance.done()
    return Promise.reject(error)
  }

  analyticStore.logNetworkEvent(error.config, error.response)

  if (error.response.status === 399) {
    try {
      const { challenge, payload } = error.response.data
      const response = await appStore.showScaValidationModal(challenge, payload)
      apiStore.resetError()
      return response
    } catch (e) {
      NProgressInstance.done()
      return Promise.reject(error)
    }
  }

  if (error.response.status === 401) {
    appStore.closeSidePanel()

    if (!error.config.url.match('/user/auth') && router.requireAuth()) {
      try {
        return await new Promise(resolve => {
          appStore.addRetryRequest(() => retryRequest(error.response)().then(resolve))
          appStore.showModal(
            Reconnect,
            {},
            { onBackgroundLeaveAction: true, wrapperClass: 'modal--sm' })
        })
      } catch {
        NProgressInstance.done()
        return Promise.reject(new Error('Error on Reconnect'))
      }
    } else {
      const message = (router.currentRoute.name === 'login') ? store.translations.message.warning.login : store.translations.message.warning.logout
      if (!error.config.doNotShowBanner) {
        appStore.showBanner(ECoreBannerTheme.Danger, error.response.data.message || message)
      }
    }
  } else {
    const code = error.response.status
    let fields = []

    if (error.config.responseType === 'blob') {
      fields = JSON.parse(await error.response.data.text())
    } else if (Array.isArray(error.response.data)) {
      fields = getFlatErrorsArray(error.response.data)
    } else {
      fields = error.response.data
    }

    const hasSimilarError = Array.isArray(fields) && fields.find(e => e.field === 'similar')

    if (!hasSimilarError) {
      apiStore.addError({ code, fields })
    }

    let message = store.translations.message.warning.unknown

    switch (code) {
      case 404:
      case 429:
        message = fields.message || store.translations.message.warning.unknown
        break
      case 422:
        if (fields.message) message = fields.message
        else if (fields.length === 1 && (fields[0].message)) message = fields[0].message
        else message = store.translations.message.warning.form_not_valid
        break
      case 403:
        message = fields.message || store.translations.message.warning.restricted
        break
      case 400:
        if (fields.message) message = fields.message
        break
    }

    if (code === 422 && hasSimilarError) {
      bus.emit('similar')
    } else if (
      error.response.status &&
      !error.config.doNotShowBanner
    ) {
      appStore.showBanner(ECoreBannerTheme.Danger, message)
    }
  }

  NProgressInstance.done()
  return Promise.reject(error)
})

export default axiosClient
