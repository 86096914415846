import { upperFirst } from 'lodash/string'

import links from '@/config/links'
import i18n from '@/locales'

export const getCountryName = country => {
  return (new Intl.DisplayNames([i18n.global.locale], { type: 'region' })).of(country)
}

export const getLanguageName = language => {
  return upperFirst((new Intl.DisplayNames([i18n.global.locale], { type: 'language' })).of(language))
}

export const getCurrencyName = currency => {
  return upperFirst((new Intl.DisplayNames([i18n.global.locale], { type: 'currency' })).of(currency))
}

export const getFlagSrc = country => {
  return `${links.STATIC.FLAGS}/${country.toUpperCase()}.png`
}

export const getLanguageNameRfc4647 = language => {
  switch (language) {
    case 'fr':
      return 'fr-FR'
    case 'de':
      return 'de-DE'
    default:
      return 'en-US'
  }
}
