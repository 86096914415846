<template>
  <div class="placeholder-action-description">
    <component-placeholder :icon="!isThreeDsChallenge"
                           :label="getTitle"
                           :content="getPlaceholderContent">
      <image-mobile-app v-if="type === 'app'"
                        sca />
      <image-mobile-app v-else-if="type === 'sms'"
                        code />
      <image-computer v-else-if="type === 'email'"
                      code />
    </component-placeholder>
    <div class="center">
      <loader-spinner v-if="loading" />
      <component-button v-else
                        :disabled="clickLoading"
                        :label="$t('button.continue')"
                        wrapper-class="btn btn--primary placeholder-btn"
                        @click="onClick" />
    </div>
  </div>
</template>

<script>
import { useScaStore } from '@/stores/sca'

import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageComputer from '@/components/svg/img/computer.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'

export default {
  components: {
    LoaderSpinner,
    ComponentButton,
    ComponentPlaceholder,
    ImageComputer,
    ImageMobileApp
  },

  props: {
    type: {
      type: String,
      required: true
    },

    challenge: {
      type: Object,
      default: null
    }
  },

  emits: ['action'],

  setup () {
    const scaStore = useScaStore()

    return { scaStore }
  },

  data () {
    return {
      loading: false,
      clickLoading: false
    }
  },

  computed: {
    isThreeDsChallenge () {
      return this.challenge?.event === 'auth.three-ds'
    },

    getTitle () {
      return this.isThreeDsChallenge
        ? this.$i18n.t('placeholder.sca.three_ds.title')
        : this.$i18n.t(`placeholder.sca_${this.type}.start_validation.title`)
    },

    getPlaceholderContent () {
      if (this.isThreeDsChallenge) {
        return this.$i18n.t(`placeholder.sca_${this.type}.three_ds.subtitle`)
      }

      return this.challenge
        ? this.$i18n.t(`placeholder.sca_${this.type}.start_validation.subtitle_continue`)
        : this.$i18n.t(`placeholder.sca_${this.type}.start_validation.subtitle`)
    }
  },

  async created () {
    if (!this.challenge) { return }

    this.loading = true
    await this.onClick()
    this.loading = false
  },

  methods: {
    async onClick () {
      this.clickLoading = true
      let data = null
      if (this.challenge) {
        data = await this.scaStore.triggerScaChallenge(this.challenge.id)
      } else {
        await this.scaStore.initScaConfiguration(this.type)
      }
      this.clickLoading = false
      this.$emit('action', 'send-invitation', data)
    }
  }
}
</script>
