<template>
  <div v-show="user.loggedIn"
       class="app__container">
    <component-sidebar v-if="account" />
    <account-picker />
    <div id="content"
         ref="content"
         class="content"
         @scroll="onScrollEvent">
      <component-header v-if="hasHeader" />
      <router-view :class="{ 'is-stuck': headerIsStuck }" />
      <component-footer class="section__footer" />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { scrollToTop } from '@/helpers/utils/dom'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useScaStore } from '@/stores/sca'

import AccountPicker from '@/components/AccountPicker.vue'

import ComponentFooter from './components/Footer.vue'
import ComponentHeader from './components/Header.vue'
import ComponentSidebar from './components/Sidebar.vue'

export default {
  name: 'LayoutSection',

  components: {
    AccountPicker,
    ComponentSidebar,
    ComponentHeader,
    ComponentFooter
  },

  props: {
    hasHeader: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const scaStore = useScaStore()

    const { account } = storeToRefs(accountStore)
    const { modal } = storeToRefs(appStore)
    const { user } = storeToRefs(authStore)

    return {
      account,
      appStore,
      authStore,
      modal,
      scaStore,
      user
    }
  },

  data () {
    return {
      headerIsStuck: false,
      renewSessionLastAttempt: null
    }
  },

  async created () {
    await this.getMailChallenges()
  },

  mounted () {
    this.$bus.on('scrollToTop', this.scrollToTopHandler)
    this.$bus.on('close-sca-modal', this.onCloseSca)
    document.addEventListener('mousemove', this.checkNeedActivityRefresh)
    document.addEventListener('keypress', this.checkNeedActivityRefresh)
    window.addEventListener('focus', this.getMailChallenges)
  },

  beforeUnmount () {
    this.$bus.off('scrollToTop', this.scrollToTopHandler)
    this.$bus.off('close-sca-modal', this.onCloseSca)
    document.removeEventListener('mousemove', this.checkNeedActivityRefresh)
    document.removeEventListener('keypress', this.checkNeedActivityRefresh)
    window.removeEventListener('focus', this.getMailChallenges)
  },

  methods: {
    onScrollEvent () {
      this.headerIsStuck = this.$refs.content.scrollTop >= 230
    },

    scrollToTopHandler () {
      scrollToTop(this.$refs.content)
    },

    async checkNeedActivityRefresh () {
      if (this.modal.active && ['reconnect', 'inactivity'].includes(this.modal.component.name)) {
        return
      }

      const time = Math.floor(new Date().getTime() / 1000) - (this.renewSessionLastAttempt || 0)
      if (time >= 5) { // Wait 5s before re-checking
        if (this.user.decodedToken?.rat <= time) {
          await this.authStore.refreshToken()
          this.renewSessionLastAttempt = Math.floor(new Date().getTime() / 1000)
        }
      }
    },

    async getMailChallenges () {
      if (this.authStore.user.loggedIn && this.authStore.user.sca.configured && !this.appStore.modal.active) {
        const challenges = await this.scaStore.getScaChallenges()
        if (challenges.length) {
          await this.appStore.showScaValidationModal(challenges[0], {})
        }
      }
    },

    onCloseSca () {
      this.getMailChallenges()
      this.appStore.closeModal()
    }
  }
}
</script>
