<template>
  <div class="login-flex-container">
    <div class="section-login">
      <div class="login-container">
        <logo-bank-inline class="logo-mone-inline logo-page-midcenter" />
        <router-view />
      </div>
    </div>
    <div class="section-feature"
         :class="{ 'section-feature--login': animationType === 'login' && hasLoginAnimationsEnabled }">
      <div v-if="hasLoginAnimationsEnabled"
           class="feature-block">
        <transition v-if="currentLottieAnimation.title"
                    mode="out-in">
          <div :key="currentLottieAnimation.animationName"
               class="feature-block__text">
            <h2 class="slideUpFadeIn">
              {{ currentLottieAnimation.title }}
            </h2>
            <p class="slideUpFadeIn">
              {{ currentLottieAnimation.subtitle }}
            </p>
          </div>
        </transition>
        <div class="feature-block__img">
          <lottie :key="currentLottieAnimation.animationName"
                  :name="currentLottieAnimation.animationName"
                  :loop="!hasMultipleAnimations"
                  class="lottie"
                  :timeout-animation="1000"
                  @animation-ended="onAnimationEnded" />
        </div>
      </div>
    </div>
    <component-footer on-login-page />
  </div>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'

import Lottie from '@/components/Lottie.vue'
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'

import ComponentFooter from './components/Footer.vue'

export default {
  name: 'LayoutLogin',

  components: {
    LogoBankInline,
    ComponentFooter,
    Lottie
  },

  props: {
    animationType: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      animationIndex: 0
    }
  },

  computed: {
    hasLoginAnimationsEnabled () {
      return hasFeature(EFeature.LoginAnimations)
    },

    lottieAnimations () {
      let animations = []

      if (!this.hasLoginAnimationsEnabled) {
        return animations
      }

      switch (this.animationType) {
        case 'login': animations = [
          {
            animationName: 'login-accounting',
            title: this.$i18n.t('title.login.login_accounting.title'),
            subtitle: this.$i18n.t('title.login.login_accounting.subtitle')
          },
          {
            animationName: 'login-default',
            title: this.$i18n.t('title.login.login_default.title'),
            subtitle: this.$i18n.t('title.login.login_default.subtitle')
          },
          {
            animationName: 'login-receipt',
            title: this.$i18n.t('title.login.login_receipts.title'),
            subtitle: this.$i18n.t('title.login.login_receipts.subtitle')
          },
          {
            animationName: 'login-refund',
            title: this.$i18n.t('title.login.login_refund.title'),
            subtitle: this.$i18n.t('title.login.login_refund.subtitle')
          },
          {
            animationName: 'login-ocr',
            title: this.$i18n.t('title.login.login_ocr.title'),
            subtitle: this.$i18n.t('title.login.login_ocr.subtitle')
          },
          {
            animationName: 'login-virtual-card-request',
            title: this.$i18n.t('title.login.login_virtualcard.title'),
            subtitle: this.$i18n.t('title.login.login_virtualcard.subtitle')
          }
        ]
          break
        case 'forgot': animations = [
          {
            animationName: 'login-lost-password',
            title: null,
            subtitle: null
          }
        ]
          break
        case 'password-update': animations = [
          {
            animationName: 'login-password-update',
            title: null,
            subtitle: null
          }
        ]
          break
        case 'logout-inactivity': animations = [
          {
            animationName: 'logout-inactivity',
            title: null,
            subtitle: null
          }
        ]
          break
      }

      return animations
    },

    currentLottieAnimation () {
      return this.lottieAnimations[this.animationIndex]
    },

    hasMultipleAnimations () {
      return this.lottieAnimations.length > 1
    }
  },

  watch: {
    animationType () {
      this.animationIndex = this.hasMultipleAnimations ? Math.floor(Math.random() * this.lottieAnimations.length) : 0
    }
  },

  created () {
    document.documentElement.classList.add('page-login')

    this.animationIndex = this.hasMultipleAnimations ? Math.floor(Math.random() * this.lottieAnimations.length) : 0
  },

  methods: {
    onAnimationEnded () {
      if (this.hasMultipleAnimations) {
        this.animationIndex = (this.animationIndex + 1) % this.lottieAnimations.length
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-enter-active, .v-leave-active
  transition opacity .5s

.v-enter-from, .v-leave-to
  opacity 0
  transition all .3s ease
</style>
