<template>
  <modal-dialog-prompt :title="title"
                       data-cy="confirm-modal.container"
                       @close="$emit('close')">
    <template #body>
      <!-- eslint-disable vue/no-v-html -->
      <p class="pre-line"
         v-html="content" />
      <!-- eslint-enable -->
    </template>
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <component-button data-cy="confirm-modal.confirm"
                        wrapper-class="btn--primary"
                        :label="confirm || $t('button.confirm')"
                        @click="onConfirm" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  name: 'ComponentConfirm',

  components: {
    ComponentButton,
    ModalDialogPrompt
  },

  props: {
    title: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    },

    event: {
      type: String,
      required: true
    },

    confirm: {
      type: String,
      default: null
    },

    preventClose: {
      type: Boolean,
      default: false
    }
  },

  emits: ['close'],

  methods: {
    onConfirm () {
      this.$bus.emit(`confirm-${this.event}`)
      if (!this.preventClose) {
        this.$emit('close')
      }
    }
  }
}
</script>
