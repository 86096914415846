<template>
  <div>
    <div class="form__row row">
      <div class="form-group col col--sm col--sm-6">
        <label class="label input__label">{{ $t('employee_details.contract.type') }}</label>
        <validation-field :name="getFieldName('contract_type')"
                          :model-value="userForm.details.contract_type">
          <component-dropdown label="contract_type"
                              :disabled="disabled"
                              :values="contractOptions"
                              dropdown-width="100%"
                              :model="userForm.details.contract_type"
                              @select="onSelectType">
            <template #trigger>
              <div class="select form-control form-control--noborder">
                <span :class="{ 'text-placeholder': !userForm.details.contract_type }">
                  {{ contractLabel }}
                </span>
              </div>
            </template>
          </component-dropdown>
        </validation-field>
      </div>
      <component-datepicker v-if="!userForm.details.contract_type || userForm.details.contract_type === 'CDI'"
                            v-model="userForm.details.contract_start_date"
                            class="form-group col col--sm-6"
                            :disable-input="disabled"
                            :name="getFieldName('contract_start_date')"
                            :class="{ error: errors[getFieldName('contract_start_date')] }"
                            :label="$t('employee_details.contract.start_date')"
                            :language="$i18n.locale"
                            :format="'dd/MM/yyyy'"
                            :input-class="'form-control form-control--noborder'"
                            :monday-first="true"
                            :readonly="false"
                            :placeholder="$t('form.date.placeholder')" />
      <span v-else
            class="form-group col col--sm-6">
        <component-datepicker-v2 v-model:from-value="userForm.details.contract_start_date"
                                 v-model:to-value="userForm.details.contract_end_date"
                                 :disable-input="disabled"
                                 :from-name="getFieldName('contract_start_date')"
                                 :to-name="getFieldName('contract_end_date')"
                                 :lang="$i18n.locale"
                                 :field-class="'form-group flex-1'"
                                 :input-class="'form-control form-control--noborder'"
                                 :size-class="'md'"
                                 :from-label="$t('employee_details.contract.start_date')"
                                 :to-label="$t('employee_details.contract.end_date')"
                                 :from-placeholder="$t('form.date.placeholder')"
                                 :to-placeholder="$t('form.date.placeholder')"
                                 :clear-button="!disabled"
                                 :show-periods="false" />
      </span>
    </div>
    <transition v-if="isAdvancePaymentEnabled"
                name="show-toggle">
      <div v-if="userForm.details.contract_type === 'CDI'"
           class="form__row row">
        <div class="form-group col col--sm-12">
          <label class="label input__label">{{ $t('employee_details.advance_payment_option.title') }}</label>
          <div class="form__fieldset__block form__fieldset__block--advancepayment">
            <span>
              <toggle-switch v-model="userForm.details.advance_payment"
                             :name="getFieldName('advance_payment')"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="onToggleAdvancePayment" />
              <span class="switch-btn-label">{{ $t('employee_details.advance_payment_option.label') }}</span>
            </span>
            <component-info is-button
                            @click="openModal">
              {{ $t('tooltip.see_more') }}
            </component-info>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { EFeature, hasFeature } from '@/config/features'
import { useAppStore } from '@/stores/app'

import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDatepickerV2 from '@/components/DatepickerV2.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ComponentInfo from '@/components/Info.vue'
import AdvancePaymentExplanation from '@/components/modals/AdvancePaymentExplanation.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    ComponentDropdown,
    ComponentDatepicker,
    ComponentDatepickerV2,
    ComponentInfo,
    ToggleSwitch
  },

  props: {
    user: {
      type: Object,
      required: true
    },

    name: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['update:user'],

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      userForm: {}
    }
  },

  computed: {
    isAdvancePaymentEnabled () {
      return hasFeature(EFeature.TransferAdvancedPayment)
    },

    contractLabel () {
      const val = this.contractOptions.find(elem => elem.value === this.userForm.details.contract_type)
      return val?.label || this.$i18n.t('employee_details.contract.select')
    },

    contractOptions () {
      return [
        {
          label: this.$i18n.t('employee_details.contract.permanent_contract'),
          value: 'CDI'
        },
        {
          label: this.$i18n.t('employee_details.contract.temporary_contract'),
          value: 'CDD'
        },
        {
          label: this.$i18n.t('employee_details.contract.apprenticeship'),
          value: 'alternance'
        },
        {
          label: this.$i18n.t('employee_details.contract.internship'),
          value: 'stage'
        }
      ]
    }
  },

  watch: {
    userForm: {
      handler (newUserForm, oldUserForm) {
        if (Object.keys(oldUserForm)) {
          this.$emit('update:user', newUserForm)
        }
      },

      deep: true
    }
  },

  created () {
    this.userForm = cloneDeep(this.user)
  },

  methods: {
    hasFeature,
    getFieldName (name) {
      return [this.name, name].filter(n => n).join('.')
    },

    onSelectType (item) {
      if (item.value !== 'CDI') {
        this.userForm.details.advance_payment = false
      }
      this.userForm.details.contract_type = item.value
    },

    onToggleAdvancePayment (value) {
      this.userForm.advance_payment = value
    },

    openModal () {
      this.appStore.showModal(AdvancePaymentExplanation)
    }
  }
}
</script>
