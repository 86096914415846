<template>
  <div class="container">
    <transition name="fade">
      <img v-show="!loading"
           class="image"
           :src="blobUrl"
           @load="pictureLoaded">
    </transition>
    <loader-spinner v-if="loading" />
  </div>
</template>

<script>
import axiosClient from '@/api'
import store from '@/config/store'

import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  components: {
    LoaderSpinner
  },

  props: {
    url: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      blobUrl: null,
      loading: true
    }
  },

  watch: {
    blobUrl () {
      this.loading = true
    }
  },

  created () {
    this.getPicture()
  },

  methods: {
    async getPicture () {
      try {
        store.api.hideProgressBar = true
        const { data } = await axiosClient.get(this.url, { responseType: 'blob' })
        this.blobUrl = URL.createObjectURL(data)
      } catch (e) {
        console.error(e)
        this.loading = false
      } finally {
        store.api.hideProgressBar = false
      }
    },

    pictureLoaded () {
      this.loading = false
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  background-color $gray-100
  display flex
  align-items center
  justify-content center
.image
  width 100%
  height 100%
  object-fit cover
  object-position top center
</style>
