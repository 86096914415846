import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { isNumber } from '@/helpers/utils/number'

export const useBeneficiaryStore = defineStore('beneficiary', {
  state: () => {
    return {
      beneficiaries: [],
      beneficiariesPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      beneficiaryListTypes: [],
      beneficiaryLists: [],
      beneficiariesImport: [],
      currentBeneficiaryList: {}
    }
  },

  actions: {
    async getBeneficiaries (params = {}) {
      let beneficiaries = []

      try {
        const { data, headers } = await axiosClient.get('/beneficiaries', { params })
        this.beneficiariesPagination = this.parsePagination(headers)

        beneficiaries = data || []
      } catch (error) {
        console.error(error)
      }

      this.beneficiaries = beneficiaries
    },

    async saveBeneficiary (beneficiary, force = false) {
      const payload = new FormData()
      const beneficiaryPayload = cloneDeep(beneficiary)

      if (beneficiaryPayload.rib instanceof File) {
        payload.append('rib', beneficiaryPayload.rib)
      }
      delete beneficiaryPayload.rib

      if (force) {
        beneficiaryPayload.force = true
      }

      payload.append('jsonBody', JSON.stringify(beneficiaryPayload))

      try {
        const { data } = await axiosClient.post('/beneficiaries', payload)
        bus.emit('beneficiary-save-success', data)
      } catch (error) {
        console.error(error)
      }
    },

    async updateBeneficiary (beneficiary) {
      const payload = new FormData()

      if (beneficiary.rib instanceof File) {
        payload.append('rib', beneficiary.rib)
      }
      delete beneficiary.rib

      payload.append('jsonBody', JSON.stringify(beneficiary))

      try {
        const { data } = await axiosClient.put(`/beneficiaries/${beneficiary.id}`, payload)
        bus.emit('beneficiary-update-success', data)
      } catch (error) {
        console.error(error)
      }
    },

    async getBeneficiaryLists (params = {}) {
      let beneficiaryLists = []

      try {
        const { data } = await axiosClient.get('/beneficiary-lists', { params })
        beneficiaryLists = data
      } catch (error) {
        console.error(error)
      }
      this.beneficiaryLists = beneficiaryLists
      return this.beneficiaryLists
    },

    async getBeneficiaryListTypes () {
      try {
        const { data } = await axiosClient.get('/beneficiary-lists/types')
        this.beneficiaryListTypes = data
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getBeneficiariesFromList (listId) {
      try {
        const { data } = await axiosClient.get(`/beneficiary-lists/${listId}`, { params: { expand: 'beneficiaries' } })
        const { beneficiaries, ...currentBeneficiaryList } = data

        this.beneficiaries = beneficiaries.map(beneficiary => ({ ...beneficiary, beneficiary_list_id: parseInt(listId) }))
        this.currentBeneficiaryList = currentBeneficiaryList
      } catch (error) {
        console.error(error)
      }
    },

    async saveBeneficiaryList (list) {
      try {
        const { data } = await axiosClient.post('/beneficiary-lists', list)

        if (!this.beneficiaryListTypes.some(type => type.name === data.list_type?.name)) {
          this.beneficiaryListTypes.push(data.list_type)
        }

        bus.emit('beneficiaries-list-create-success')
      } catch (error) {
        console.error(error)
      }
    },

    async updateBeneficiaryList (list) {
      try {
        const { data } = await axiosClient.put(`/beneficiary-lists/${list.id}`, list)

        if (!this.beneficiaryListTypes.some(type => type.name === data.list_type?.name)) {
          this.beneficiaryListTypes.push(data.list_type)
        }

        bus.emit('beneficiaries-list-edit-success', list)
      } catch (error) {
        console.error(error)
      }
    },

    async getRibInfos (rib, abortSignal) {
      const payload = new FormData()

      payload.append('files', rib)

      try {
        const { data } = await axiosClient.post('/beneficiaries/document/analyze', payload, { signal: abortSignal })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async changeListOfBeneficiary (beneficiary, list) {
      try {
        await axiosClient.put(`/beneficiaries/${beneficiary.id}`, { beneficiary_list_id: list.id })
        const benef = this.beneficiaries.find(el => el.id === beneficiary.id)
        benef.beneficiary_list = list
      } catch (error) {
        console.error(error)
      }
    },

    async getBeneficiariesImport (file, listId) {
      this.beneficiariesImport = []

      const payload = new FormData()

      payload.append('files', file)

      try {
        const { data } = await axiosClient.post('/beneficiaries/import', payload)
        this.setBeneficiariesImport(data, listId)
      } catch (error) {
        console.error(error)
      }
    },

    setBeneficiariesImport (data, listId) {
      const defaultAddress = { street: '', zip: '', city: '', country: '' }
      const defaultBankData = { street: '', zip: '', city: '', country: '', country_iso: '', bank: '' }

      this.beneficiariesImport = data.map((value, index) => {
        value.selected = true
        value.id = index
        if (isNumber(listId)) {
          value.beneficiary_list_id = listId
        }
        value.bank_data = {
          ...defaultBankData,
          ...value.bank_data,
          sepa: value.is_sepa
        }
        value.address = {
          ...defaultAddress,
          ...value.address
        }
        return value
      })
    },

    editBeneficiariesImport (data) {
      this.beneficiariesImport = data
    },

    async editBeneficiaryRib (payload) {
      try {
        // param to never detect beneficiary duplicates
        payload.force = true
        const { data } = await axiosClient.put('/beneficiaries/me/rib', payload)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async createMultipleBeneficiaries (payload) {
      try {
        const { data } = await axiosClient.post('/beneficiaries/batch', payload)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
