<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <div v-else
         class="table-bordered-box table-bordered-box--100">
      <template v-for="(list, idxList) in accountingAccountList"
                :key="`list${idxList}`">
        <div v-if="list.items.length"
             class="bordered-box">
          <div class="bordered-box__header">
            <div class="bordered-box__header__title">
              {{ list.label }}
            </div>
            <div v-if="idxList === 'vat'"
                 class="bordered-box__header__right">
              <span class="switch-btn-label">{{ $t('accounting.apply_vat_accounts') }}</span>
              <toggle-switch v-model="preferences.coa_apply_vat"
                             :toggle-class="'switch-btn--sm'"
                             :disabled="loadingPreferenceUpdate"
                             @update:model-value="updateAccountingPreferences('coa_apply_vat')" />
            </div>
          </div>
          <div v-if="idxList !== 'vat' || preferences.coa_apply_vat"
               class="bordered-box__body">
            <table class="table table--settings">
              <thead>
                <tr>
                  <th class="col--sm-5">
                    {{ $t('accounting.table.label') }}
                  </th>
                  <th class="col--sm-2">
                    {{ $t('accounting.table.account_number') }}
                  </th>
                  <th class="col--sm" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idxItem) in list.items"
                    :key="idxItem">
                  <td>{{ item.label }}</td>
                  <td>
                    <input v-model="item.account_number"
                           type="text"
                           class="form-control form-control--xs"
                           :disabled="loadingAccountUpdate"
                           :placeholder=" $t('form.account_number.placeholder')"
                           @change="updateAccountingAccount(item)">
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useAccountingStore } from '@/stores/accounting'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    ToggleSwitch,
    LoaderSpinner
  },

  setup () {
    const accountingStore = useAccountingStore()

    return { accountingStore }
  },

  data () {
    return {
      loading: true,
      loadingPreferenceUpdate: false,
      loadingAccountUpdate: false,
      accountingAccounts: [],
      accountingAccountList: {
        general: {
          label: this.$i18n.t('accounting.general_accounts'),
          items: []
        },

        vat: {
          label: this.$i18n.t('accounting.tax_accounts'),
          items: []
        }
      },

      preferences: { coa_apply_vat: false }
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      this.loading = true
      await this.getAccountingAccounts()
      await this.getAccountingPreferences()
      this.loading = false
    },

    async getAccountingAccounts () {
      this.accountingAccounts = await this.accountingStore.getAccounts('settings')
      for (const key in this.accountingAccountList) {
        this.accountingAccountList[key].items = []
      }

      this.accountingAccounts.forEach(item => {
        this.accountingAccountList[item.vat ? 'vat' : 'general'].items.push(item)
      })
    },

    async getAccountingPreferences () {
      this.preferences = await this.accountingStore.getPreferences()
    },

    async updateAccountingAccount (item) {
      this.loadingAccountUpdate = true
      const success = await this.accountingStore.updateAccount(item.id, item)
      if (success) await this.getAccountingAccounts()
      this.loadingAccountUpdate = false
    },

    async updateAccountingPreferences (key) {
      this.loadingPreferenceUpdate = true
      const data = await this.accountingStore.updatePreference(key, this.preferences[key])
      this.preferences[key] = data ? data[key] : !this.preferences[key]
      this.loadingPreferenceUpdate = false
    }
  }
}
</script>
