<template>
  <div class="section__content mt-2">
    <div v-if="loading"
         class="section__loader">
      <core-loader />
    </div>
    <div v-else-if="campaigns.length"
         class="campaigns">
      <campaign-block v-for="campaign in campaigns"
                      :key="campaign.uuid"
                      :campaign="campaign" />
      <component-pagination class="pagination"
                            :pagination="loyaltyPagination" />
    </div>
    <component-placeholder v-else
                           :label="t('campaigns.placeholder.label')" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreLoader } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import bus from '@/helpers/eventBus'
import { useLoyaltyStore } from '@/stores/loyalty'
import { ICampaignLight } from '@/types/loyalty.d'

import CampaignBlock from '@/pages/loyalty/components/CampaignBlock.vue'

import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'

const { t } = useI18n()
const loyaltyStore = useLoyaltyStore()
const { loyaltyPagination } = storeToRefs(loyaltyStore)

const loading = ref(true)
const campaigns = ref<ICampaignLight[]>([])

const props = defineProps<{
  page: number
}>()

onMounted(() => {
  initData()

  bus.on('language-changed', initData)
})

onUnmounted(() => {
  bus.off('language-changed', initData)
})

watch(() => props.page, initData)

async function initData () {
  loading.value = true
  campaigns.value = await loyaltyStore.getCampaigns(props.page)
  loading.value = false
}
</script>

<style lang="stylus" scoped>
.campaigns
  display grid
  grid-template-columns repeat(3, 1fr)
  gap 2rem

.pagination
  grid-column span 3
</style>
