<template>
  <svg viewBox="0 0 32 32">
    <path d="M19.583 8.5a6.04 6.04 0 0 0-.046 1.673l.046.327H9.072L16 16.204l4.367-3.597a6.03 6.03 0 0 0 1.308 1.516l-5.04 4.148a1 1 0 0 1-1.166.076l-.105-.076L8.5 12.62v8.88l15 .002v-6.343a5.99 5.99 0 0 0 2 .341v6.002a2.004 2.004 0 0 1-1.85 1.993l-.15.005h-15a2 2 0 0 1-1.994-1.849l-.006-.15V10.5a2.004 2.004 0 0 1 1.85-1.993L8.5 8.5zm5.917-4a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.296 2.79-3.04 3.04-1.54-1.54-.92.92 2.46 2.459 3.96-3.96z" />
  </svg>
</template>

<script>
export default {
}
</script>
