<template>
  <div v-if="isVisible"
       id="control-rule"
       data-cy="cards.settings.section.control-rule"
       class="form__fieldset card-advanced-proofs">
    <group-title :title="$t('control_rule.form.title')" />
    <div class="form__fieldset__group">
      <div class="proof-delay">
        <p class="label mb-2">
          {{ $t('control_rule.card.subtitle') }}
        </p>
        <div class="row">
          <div class="col col--sm">
            <div class="bordered-box">
              <div class="bordered-box__item">
                <div class="item-left">
                  <span class="switch-btn-label">{{ $t('control_rule.card.select_rule.label') }}</span>
                </div>
                <div class="item-right">
                  <component-dropdown :can-add-item="false"
                                      :disabled="disabled"
                                      :dropdown-width="'100%'"
                                      :model="controlRule.type"
                                      :multiselect="false"
                                      :search="false"
                                      :values="rules"
                                      label="control_rule"
                                      @select="selectRule">
                    <template #trigger>
                      <div class="dropdown-toggle select"
                           style="width: 28rem;">
                        <div class="form-control form-control--sm">
                          <loader-spinner v-if="loading.rule"
                                          size="15" />
                          <span v-else>{{ ruleLabel }}</span>
                        </div>
                      </div>
                    </template>
                  </component-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="controlRule.type === 'card'">
        <p class="label mt-3 mb-2">
          {{ $t('control_rule.card.params') }}
        </p>
        <div class="form__fieldset__group">
          <div class="proof-delay">
            <div class="row">
              <div class="col col--sm">
                <div class="bordered-box">
                  <div class="bordered-box__item">
                    <div class="item-left">
                      <span class="switch-btn-label">
                        {{ $t('control_rule.form.delay.label') }}
                      </span>
                      <span v-tooltip="{ content: $t('control_rule.form.delay.tooltip'), theme: 'poptip' }"
                            class="ic-helper">
                        <ic-info outline
                                 class="ic ic--gray" />
                      </span>
                    </div>
                    <div class="item-right">
                      <component-dropdown :dropdown-width="'100%'"
                                          :model="controlRule.apply_delay"
                                          :has-default="!controlRule.apply_delay"
                                          :disabled="disabled"
                                          async-url="/cards/proof-required/delays"
                                          label="delay"
                                          @select="changeDelay">
                        <template #trigger>
                          <div class="dropdown-toggle select"
                               style="width: 18rem;">
                            <div class="form-control form-control--sm">
                              <loader-spinner v-if="loading.delay"
                                              size="16" />
                              <span v-else>{{ controlRule.apply_delay_label }}</span>
                            </div>
                          </div>
                        </template>
                      </component-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bordered-box mt-2">
            <minimized-block v-slot="{ propArray }"
                             :array-data="controlRule.params"
                             :display-more-text="$t('control_rule.form.display_all_fields')">
              <table class="table table--noborder">
                <thead class="bordered-header bordered-header--noborder">
                  <th>{{ $t('control_rule.form.fields') }}</th>
                  <th class="col--sm-1 center">
                    {{ $t('general.mandatory') }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in propArray"
                      :key="index">
                    <td :class="[item.enabled ? 'strong' : 'text-muted']">
                      <span class="">{{ item.label }}</span>
                      <template v-if="item.name === 'operation.comment' && item.enabled">
                        <div class="select select--inline">
                          <component-dropdown multiselect
                                              :disabled="disabled"
                                              search
                                              :dropdown-margin-top="2.5"
                                              :dropdown-width="34"
                                              dropdown-position="right"
                                              async-url="/categories?filter=card"
                                              has-icon
                                              :model="item.params.categories"
                                              @select="value => onSelectCategories(item, value)">
                            <template #trigger>
                              <div>
                                <div class="form-control">
                                  : {{ forCategoriesLabel(item) }}
                                </div>
                              </div>
                            </template>
                          </component-dropdown>
                        </div>
                      </template>
                    </td>
                    <td class="col--sm-1 center">
                      <toggle-switch v-model="item.enabled"
                                     :disabled="loading.items || disabled"
                                     :toggle-class="'switch-btn--sm'"
                                     @update:model-value="updateControlRuleSettings()" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </minimized-block>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { EFeature, hasFeature } from '@/config/features'
import { showToastWarning } from '@/helpers/utils/notification'
import { useCardStore } from '@/stores/card'

import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import MinimizedBlock from '@/components/MinimizedBlock.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcInfo,
    LoaderSpinner,
    ComponentDropdown,
    GroupTitle,
    ToggleSwitch,
    MinimizedBlock
  },

  props: {
    settings: {
      type: Object,
      required: true
    },

    cardUuid: {
      type: String,
      required: true
    },

    card: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const cardStore = useCardStore()

    return { cardStore }
  },

  data () {
    return {
      loading: { rule: false, delay: false, items: false },
      controlRule: cloneDeep(this.settings.control_rule)
    }
  },

  computed: {
    isVisible () {
      return hasFeature(EFeature.CardControlRule) && this.hasSettingField('proof_required') && this.hasSettingField('proof_required_delay')
    },

    rules () {
      return [
        {
          label: this.$i18n.t('control_rule.card.select_rule.no_rule'),
          value: 'none'
        },
        {
          label: this.$i18n.t('control_rule.card.select_rule.global_rule'),
          value: 'account'
        },
        {
          label: this.$i18n.t('control_rule.card.select_rule.custom_rule'),
          value: 'card'
        }
      ]
    },

    ruleLabel () {
      const rule = this.rules.find(r => r.value === this.controlRule.type)
      return rule?.label
    }
  },

  methods: {
    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    },

    changeDelay (delay) {
      this.controlRule.apply_delay = delay.value
      this.loading.delay = true
      this.updateControlRuleSettings()
    },

    selectRule (rule) {
      this.loading.rule = true
      this.updateControlRuleSettings(rule.value, false)
    },

    onSelectCategories (item, value) {
      item.params = { categories: value }
      this.updateControlRuleSettings()
    },

    forCategoriesLabel (item) {
      return this.$i18n.tc('general.for_categories_number', item.params.categories?.length || 0)
    },

    async updateControlRuleSettings (ruleType = null, sendParams = true) {
      this.loading.items = true
      const controlRule = {
        type: ruleType || this.controlRule.type,
        apply_delay: this.controlRule.apply_delay
      }

      if (sendParams && controlRule.type === 'card') {
        controlRule.params = [...this.controlRule.params]
      }

      const data = await this.cardStore.updateControlRuleSettings(this.cardUuid, controlRule)
      this.loading = { rule: false, delay: false, items: false }
      if (!data) { return }

      this.controlRule = data
      if (ruleType === 'account' && data.type === 'none') {
        showToastWarning(this.$i18n.t('message.warning.control_preferences_not_configured'))
      }
    }
  }
}
</script>
