<template>
  <div class="multiple-card-order-details form form--w1000 form--centered">
    <component-alert :type="'success'"
                     :message="$tc('form.beneficiaries_import.success', beneficiariesImport.length)"
                     :show-api-messages="false" />
    <fieldset class="form__fieldset">
      <div>
        <fieldset-title :title="$t('form.title.beneficiaries_import_details')" />
        <div class="bordered-box">
          <div class="bordered-box__search">
            <div class="input-group input-group--noborder">
              <span class="input-group__addon">
                <ic-search class="ic ic--gray" />
              </span>
              <input v-model="filter"
                     class="form-control"
                     type="text"
                     :placeholder="$t('form.search.placeholder')">
            </div>
          </div>
          <div class="overflow-scrollbar order-details-list">
            <table class="table table--hover">
              <tbody>
                <tr v-for="(item, index) in filteredBeneficiariesList"
                    :key="index"
                    class="is-link">
                  <td class="col-checkbox">
                    <label class="checkbox">
                      <input v-model="item.selected"
                             type="checkbox"
                             name="checkbox">
                      <span class="checkbox__icon" />
                    </label>
                  </td>
                  <td @click="editBeneficiary(item.id)">
                    <div class="strong text-dark">
                      {{ item.label }}
                    </div>
                    <div>{{ formatIban(item.iban) }}</div>
                  </td>
                  <td @click="editBeneficiary(item.id)">
                    {{ item.email }}
                  </td>
                  <td class="right button-edit"
                      @click="editBeneficiary(item.id)">
                    <button class="btn btn--outline btn-edit">
                      <ic-pencil class="ic ic--18 ic--gray" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bordered-box__summary">
            <div class="row row--sm-center">
              <div class="col col--sm">
                <strong>
                  {{ $t('beneficiaries_import.total_beneficiaries') }} :
                </strong>
              </div>
              <div class="col col--sm right">
                <strong>{{ beneficiariesToSubmit.length }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'beneficiary-lists' }">
            {{ $t('button.cancel') }}
          </router-link>
          <component-button wrapper-class="btn--primary"
                            :label="$t('button.validate')"
                            :disabled="loading || !beneficiariesToSubmit.length"
                            @click="onSubmit" />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { textContainsFilter } from '@/helpers/utils/text'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import EditImportedBeneficiary from '@/pages/beneficiaries/sidepanel/EditImportedBeneficiary.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'

export default {
  components: {
    IcPencil,
    IcSearch,
    ComponentAlert,
    ComponentButton,
    FieldsetTitle
  },

  emits: ['toggle-step'],

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    const { beneficiariesImport } = storeToRefs(beneficiaryStore)

    return { appStore, beneficiariesImport, beneficiaryStore }
  },

  data () {
    return {
      loading: false,
      filter: ''
    }
  },

  computed: {
    beneficiariesToSubmit () {
      return this.beneficiariesImport
        .filter(b => b.selected)
        .map(beneficiary => this.createBeneficiaryPayload(beneficiary))
    },

    filteredBeneficiariesList () {
      return this.beneficiariesImport.filter(user => textContainsFilter(user.label, this.filter))
    }
  },

  methods: {
    formatIban,

    editBeneficiary (id) {
      this.appStore.showSidePanel(
        EditImportedBeneficiary,
        {
          idInitial: id,
          items: cloneDeep(this.beneficiariesImport)
        },
        {
          wrapperClass: 'sidepanel--xl',
          backgroundOverlay: true
        })
    },

    toggleStep (step) {
      this.$emit('toggle-step', step)
    },

    createBeneficiaryPayload (item) {
      const beneficiary = {
        label: item.label,
        beneficiary_list_id: item.beneficiary_list_id || 0,
        short_tag: item.short_tag || '',
        comment: item.comment || '',
        iban: item.iban ? item.iban.replace(/\s+/g, '') : '',
        bic: item.bic ? item.bic.replace(/\s+/g, '') : '',
        email: item.email || '',
        phone_number: item.phone_number || '',
        is_sepa: item.is_sepa,
        address: item.address
      }

      if (item.details) {
        beneficiary.details = {
          social_security_number: item.details && item.details.social_security_number ? item.details.social_security_number.replace(/\s+/g, '') : '',
          advance_payment: item.details.advance_payment,
          contract_type: item.details.contract_type,
          contract_start_date: item.details.contract_start_date,
          contract_end_date: item.details.contract_end_date
        }
      }

      if (!item.is_sepa) {
        beneficiary.bank_data = item.bank_data
      }

      return beneficiary
    },

    async onSubmit () {
      this.loading = true
      const data = await this.beneficiaryStore.createMultipleBeneficiaries(this.beneficiariesToSubmit)
      this.loading = false

      if (data) {
        showToastSuccess(data.message)
        return this.$router.push(
          this.$route.query.listId
            ? { name: 'beneficiary-list', params: { id: this.$route.query.listId } }
            : { name: 'beneficiary-lists' }
        )
      }
    }
  }
}
</script>
