<template>
  <div class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ title }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <div class="form__row">
          <div class="form-group">
            <label class="label label--sm">{{ $t("sidepanel.export.file_format") }}</label>
            <div class="select">
              <component-dropdown name="format"
                                  dropdown-width="100%"
                                  :model="format"
                                  :values="formatOptions"
                                  @select="selectFormat">
                <template #trigger>
                  <div class="form-control">
                    {{ getFormatLabel(format) }}
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
        </div>
        <div class="form__row"
             style="margin-top: 2.5rem;">
          <div class="form-group">
            <label class="label label--sm">{{ exportTypeLabel }}</label>
            <div class="select">
              <component-dropdown name="type"
                                  dropdown-width="100%"
                                  :model="selectedExportType"
                                  :values="exportTypeOptions"
                                  @select="selectExportType">
                <template #trigger>
                  <div class="form-control">
                    {{ getExportTypeLabel(selectedExportType) }}
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
        </div>
        <hr class="divider divider--fw">
        <div v-if="isFilterSelected && !hideFiltersList"
             class="sidepanel-details export-filters-options">
          <h3 class="head-title">
            {{ $t("sidepanel.export.used_filters") }}
          </h3>
          <div class="export-filters-details">
            <template v-if="isExpenseType">
              <div v-if="filter.status"
                   class="sidepanel-details__entry">
                <span class="entry-label text-muted">{{ $t('table.status') }}</span>
                <span class="entry-value right">{{
                  filter.status === 'pending' ? $t('general.pending') : $tc('general.treated', 2)
                }}</span>
              </div>
            </template>
            <template v-if="isMileageType">
              <div v-if="filter.status"
                   class="sidepanel-details__entry">
                <span class="entry-label text-muted">{{ $t('table.status') }}</span>
                <span class="entry-value right">{{ $t('general.processing') }}</span>
              </div>
              <div v-if="filter.except_status"
                   class="sidepanel-details__entry">
                <span class="entry-label text-muted">{{ $t('table.status') }}</span>
                <span class="entry-value right">{{ $t('general.history') }}</span>
              </div>
            </template>
            <div v-if="filter.search"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("sidepanel.export.search") }}</span>
              <span class="entry-value right">{{ filter.search }}</span>
            </div>
            <div v-if="filter.team_uuid && selectedTeam"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("table.team") }}</span>
              <span class="entry-value right">{{ selectedTeam.name }}</span>
            </div>
            <div v-if="filter.beneficiary_id && selectedBeneficiary"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.beneficiary") }}</span>
              <span class="entry-value right">{{ selectedBeneficiary.label }}</span>
            </div>
            <div v-if="filter.user_uuid && selectedUser"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("form.user.label") }}</span>
              <span class="entry-value right">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</span>
            </div>
            <div v-if="filter.type"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("sidepanel.export.type") }}</span>
              <span class="entry-value right">{{ $t(`table.${filter.type}`) }}</span>
            </div>
            <div v-if="filter.category"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("sidepanel.export.category") }}</span>
              <span class="entry-value right">{{ $t(`form.operations.type.${filter.category}`) }}</span>
            </div>
            <div v-if="persistingFilters.card && !persistingFilters.card_uuid"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('title.cards') }}</span>
              <span class="entry-value right">{{ $t('general.yes') }}</span>
            </div>
            <div v-if="filter.card_uuid && selectedCard && !persistingFilters.card_uuid"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("sidepanel.export.card") }}</span>
              <span class="entry-value right">
                •••• {{ selectedCard.last_digits }} - {{ `${selectedCard.first_name} ${selectedCard.last_name}` }}
              </span>
            </div>
            <div v-if="isAttachmentSet"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("sidepanel.export.attachment") }}</span>
              <span class="entry-value right">{{
                $t(`sidepanel.export.${filter.proof_filter ? filter.proof_filter : filter.attachment ? 'with' : 'without'}`)
              }}</span>
            </div>
            <div v-if="filter.from"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.start_date") }}</span>
              <span class="entry-value right">{{ formatDateText(filter.from, 'll') }}</span>
            </div>
            <div v-if="filter.to"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.end_date") }}</span>
              <span class="entry-value right">{{ formatDateText(filter.to, 'll') }}</span>
            </div>
            <div v-if="filter.expense_category"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("cards_settings.menu.mcc") }}</span>
              <span class="entry-value right">{{ selectedCategory }}</span>
            </div>
            <div v-if="filter.is_sepa != null"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("form.transfer_details.method_label") }}</span>
              <span class="entry-value right">{{ filter.is_sepa ? $t("form.transfer_type.sepa") : $t("form.transfer_type.swift") }}</span>
            </div>
            <div v-if="filter.reliability"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("form.reliability.label") }}</span>
              <span v-if="filter.reliability !== 'none'"
                    class="entry-value right">{{ $t(`form.reliability.filter.short.${filter.reliability}`) }}</span>
              <span v-else
                    class="entry-value right">{{ $t(`form.reliability.filter.${filter.reliability}`) }}</span>
            </div>
            <div v-if="filter.is_compliant === false"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('form.expense_compliance.filter.label') }}</span>
              <span class="entry-value right">{{ $t('form.expense_compliance.filter.non_compliant') }}</span>
            </div>
            <div v-if="filter.amount"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("wirecheck.details.amount") }}</span>
              <span class="entry-value right">{{ $t(`form.amount.filter.${filter.amount}`) }}</span>
            </div>
            <div v-if="filter.affectations"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('form.affectation.label') }} </span>
              <span class="entry-value right">{{ selectedAffectation }}</span>
            </div>
            <div v-if="isChargeBackSet"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('form.charge_back.label') }} </span>
              <span class="entry-value right">{{ chargeBackLabel }}</span>
            </div>
            <div v-if="filter.min"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('sidepanel.export.min') }}</span>
              <span class="entry-value right">{{ minAmountLabel }}</span>
            </div>
            <div v-if="filter.max"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t('sidepanel.export.max') }}</span>
              <span class="entry-value right">{{ maxAmountLabel }}</span>
            </div>
          </div>
          <hr class="divider divider--fw">
        </div>
        <div class="export-default-options">
          <div v-if="hasPermission($permissions.operationsDocumentsRead) && !noAttachmentsOnFilteredExport"
               class="form-check">
            <label class="form-check__label checkbox">
              {{ $t("sidepanel.export.include_attachment") }}
              <input v-model="isAttachmentChecked"
                     type="checkbox">
              <span class="checkbox__icon" />
            </label>
          </div>
          <div v-if="(isExpenseType || isOperationType) && hasAccountingAccount"
               class="form-check">
            <label class="form-check__label checkbox">
              {{ $t("sidepanel.export.apply_accounting_account") }}
              <input v-model="isAccountingAccountChecked"
                     type="checkbox"
                     @change="loadOperationsCount">
              <span class="checkbox__icon" />
            </label>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer class="sidepanel-footer--bordered">
      <div v-if="!countLoading"
           class="export-counter">
        <strong>
          {{ operationsToExport }}
        </strong>
        {{ operationsToExportLabel }}
      </div>
      <button class="btn btn--block btn--default"
              :disabled="countLoading || !operationsToExport || exportLoading"
              @click="onSubmit">
        <span v-if="!exportByMail"
              class="btn-icon btn-icon--left">
          <ic-download class="ic ic--20 ic--white" />
        </span>
        {{ exportByMail ? $t("sidepanel.export.mail") : $t("sidepanel.export.download") }}
      </button>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useExpenseStore } from '@/stores/expense'
import { useMileageStore } from '@/stores/mileage'
import { useOperationStore } from '@/stores/operation'
import { useTransferStore } from '@/stores/transfer'

import ComponentDropdown from '@/components/Dropdown.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcDownload from '@/components/svg/icons/ic-download.vue'

export default {
  components: {
    ComponentDropdown,
    ComponentSidepanelFooter,
    IcDownload
  },

  props: {
    type: {
      type: String,
      required: true
    },

    filter: {
      type: Object,
      required: true
    },

    persistingFilters: {
      type: Object,
      default: () => ({})
    },

    isFiltered: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const expenseStore = useExpenseStore()
    const mileageStore = useMileageStore()
    const operationStore = useOperationStore()
    const transferStore = useTransferStore()

    const { account } = storeToRefs(accountStore)
    const { expenses, expensesPagination } = storeToRefs(expenseStore)
    const { mileages, mileagesPagination } = storeToRefs(mileageStore)
    const { operations, operationsPagination } = storeToRefs(operationStore)

    return {
      account,
      expenses,
      expensesPagination,
      expenseStore,
      mileages,
      mileagesPagination,
      mileageStore,
      operations,
      operationsPagination,
      operationStore,
      transferStore
    }
  },

  data () {
    return {
      store,
      format: 'xlsx',
      formats: [
        {
          label: 'xlsx (Excel)',
          value: 'xlsx'
        },
        {
          label: 'xls',
          value: 'xls'
        },
        {
          label: 'csv',
          value: 'csv'
        },
        {
          label: 'ofx',
          value: 'ofx'
        },
        {
          label: 'qif',
          value: 'qif'
        },
        {
          label: 'camt.053',
          value: 'camt053'
        }
      ],

      isAttachmentChecked: true,
      isAccountingAccountChecked: false,
      selectedExportType: 'current_page',
      exportTypeOptions: [],
      selectedBeneficiary: null,
      selectedCard: null,
      selectedUser: null,
      selectedTeam: null,
      selectedCategory: null,
      selectedAffectation: null,
      operationsToExport: null,
      exportDelayed: false,
      exportLoading: false,
      countLoading: false
    }
  },

  computed: {
    title () {
      const titles = {
        mileages: this.$i18n.t('sidepanel.export_mileages.title'),
        expenses: this.$i18n.t('sidepanel.export_expenses.title'),
        payslip: this.$i18n.t('button.export_payslips'),
        advancePayment: this.$i18n.t('button.export_advance_payments')
      }

      return titles[this.type] || this.$i18n.t('sidepanel.export.title')
    },

    exportTypeLabel () {
      return {
        mileages: this.$i18n.t('sidepanel.export.export_type'),
        expenses: this.$i18n.t('sidepanel.export.export_type'),
        payslip: this.$i18n.t('sidepanel.export.export_type_payslips'),
        advancePayment: this.$i18n.t('sidepanel.export.export_type_advance_payments')
      }[this.type]
    },

    formatOptions () {
      let formatDisplayed = ['xlsx', 'csv', 'ofx', 'qif', 'camt053']
      if (this.isMileageType) {
        formatDisplayed = ['xlsx']
      } else if (this.isExpenseType) {
        formatDisplayed = ['xlsx', 'xls', 'csv']
      }
      return this.formats.filter(format => formatDisplayed.includes(format.value))
    },

    isMileageType () {
      return this.type === 'mileages'
    },

    isExpenseType () {
      return this.type === 'expenses'
    },

    isOperationType () {
      return this.type === 'operations'
    },

    isPayslipType () {
      return this.type === 'payslip'
    },

    isAdvancePaymentType () {
      return this.type === 'advancePayment'
    },

    pagination () {
      if (this.isMileageType) {
        return this.mileagesPagination
      }
      if (this.isExpenseType) {
        return this.expensesPagination
      }
      if (this.isOperationType) {
        return this.operationsPagination
      }

      return {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    },

    operationsToExportLabel () {
      return this.pagination.current === 1 && this.pagination.count > 1 && this.selectedExportType === 'current_page' && !this.isFiltered
        ? this.$i18n.t('sidepanel.export.last_operations')
        : this.$i18n.tc('sidepanel.export.operations', this.operationsToExport)
    },

    isFilterSelected () {
      return this.selectedExportType === 'use_filters' || (Object.keys(this.persistingFilters).length)
    },

    noAttachmentsOnFilteredExport () {
      return this.selectedExportType === 'use_filters' && this.isAttachmentSet && this.filter.attachment === 0
    },

    exportByMail () {
      return this.noAttachmentsOnFilteredExport
        ? this.exportDelayed
        : (this.exportDelayed || this.isAttachmentChecked)
    },

    isAttachmentSet () {
      return typeof (this.filter.attachment) === 'number' || this.filter.proof_filter
    },

    isChargeBackSet () {
      return ['string', 'boolean'].includes(typeof this.filter.charge_back)
    },

    hideFiltersList () {
      let fields = []

      if (this.isMileageType || this.isPayslipType || this.isAdvancePaymentType) {
        fields = [
          'beneficiary_id',
          'expense_category',
          'is_sepa',
          'from',
          'search',
          'to'
        ]
      } else if (this.isOperationType || this.isExpenseType) {
        if (this.isAttachmentSet) return false
        if (this.isChargeBackSet) return false
        fields = [
          'user_uuid',
          'team_uuid',
          'category',
          'expense_category',
          'from',
          'max',
          'min',
          'search',
          'type',
          'to',
          'affectations',
          'is_compliant'
        ]
      }

      for (let i = 0; i < fields.length; i++) {
        if (fields[i] === 'is_compliant' && !this.filter[fields[i]]) return false
        else if (fields[i] !== 'is_compliant' && this.filter[fields[i]]) return false
        else if (fields[i] === 'is_sepa' && !this.filter[fields[i]]) return false
      }

      return true
    },

    hasAccountingAccount () {
      return this.account.company?.company_profile_id
    },

    chargeBackLabel () {
      if (this.filter.charge_back === 'null') {
        return this.$i18n.t('general.undefined')
      }

      return this.filter.charge_back
        ? this.$i18n.t('form.charge_back.label')
        : this.$i18n.t('form.charge_back.none')
    },

    minAmountLabel () {
      return this.getMinMaxAmountWithCurrency(this.filter.min)
    },

    maxAmountLabel () {
      return this.getMinMaxAmountWithCurrency(this.filter.max)
    },

    apiFilters () {
      const filters = { ...this.filter, ...this.persistingFilters }

      switch (this.selectedExportType) {
        case 'current_page': {
          filters.page = this.pagination.current
          break
        }
        case 'all_payslips':
        case 'all_advance':
        case 'all_operations': {
          filters.search = ''
          filters.type = ''
          filters.attachment = ''
          delete filters.page
          delete filters.to
          delete filters.from
          break
        }
        case 'current_month': {
          filters.search = ''
          filters.type = ''
          filters.attachment = ''
          delete filters.page
          filters.from = dayjs().startOf('month').format('YYYY-MM-DD')
          filters.to = dayjs().format('YYYY-MM-DD')
          break
        }
        case 'previous_month' : {
          filters.search = ''
          filters.type = ''
          filters.attachment = ''
          delete filters.page
          const startOfPreviousMonth = dayjs().startOf('month').subtract(1, 'month')
          filters.from = startOfPreviousMonth.format('YYYY-MM-DD')
          filters.to = startOfPreviousMonth.endOf('month').format('YYYY-MM-DD')
          break
        }
        case 'use_filters' : {
          delete filters.page
          break
        }
      }

      if (this.isPayslipType) {
        filters.origin_method = ['import_payslip']
        filters.status = 'done'
      } else if (this.isAdvancePaymentType) {
        filters.origin_method = ['advance_payment']
        filters.status = 'done'
      }
      filters.accounting = this.isAccountingAccountChecked || ''

      return filters
    }
  },

  watch: {
    selectedExportType () {
      this.loadOperationsCount()
    }
  },

  created () {
    let all = 'all_operations'
    if (this.isPayslipType) {
      all = 'all_payslips'
    } else if (this.isAdvancePaymentType) {
      all = 'all_advance'
    }

    const options = [all, 'current_month', 'previous_month', 'current_page']

    if (this.isFiltered && !this.hideFiltersList) {
      options.push('use_filters')
    }

    this.exportTypeOptions = options.map(type => ({
      value: type,
      label: this.$i18n.t(`sidepanel.export.options.${type}`)
    }))

    if (this.isPayslipType || this.isAdvancePaymentType) {
      this.selectedExportType = all
    }

    if (this.isFiltered && !this.hideFiltersList) {
      this.selectedExportType = 'use_filters'
    }

    this.loadOperationsCount()
  },

  mounted () {
    this.$bus.on('send-filtered-beneficiary', this.onReceiveFilteredBeneficiary)
    this.$bus.on('send-filtered-card', this.onReceiveFilteredCard)
    this.$bus.on('send-filtered-category', this.onReceiveFilteredCategory)
    this.$bus.on('send-filtered-user', this.onReceiveFilteredUser)
    this.$bus.on('send-filtered-team', this.onReceiveFilteredTeam)
    this.$bus.on('send-filtered-affectations', this.onReceiveFilteredAffectations)

    if (this.filter.card_uuid) {
      this.$bus.emit('get-filtered-card')
    }
    if (this.filter.expense_category) {
      this.$bus.emit('get-filtered-category')
    }
    if (this.filter.user_uuid) {
      this.$bus.emit('get-filtered-user')
    }
    if (this.filter.team_uuid) {
      this.$bus.emit('get-filtered-team')
    }
    if (this.filter.beneficiary_id) {
      this.$bus.emit('get-filtered-beneficiary')
    }
    if (this.filter.affectations) {
      this.$bus.emit('get-filtered-affectations')
    }
  },

  beforeUnmount () {
    this.$bus.off('send-filtered-beneficiary', this.onReceiveFilteredBeneficiary)
    this.$bus.off('send-filtered-card', this.onReceiveFilteredCard)
    this.$bus.off('send-filtered-category', this.onReceiveFilteredCategory)
    this.$bus.off('send-filtered-user', this.onReceiveFilteredUser)
    this.$bus.off('send-filtered-team', this.onReceiveFilteredTeam)
    this.$bus.off('send-filtered-affectations', this.onReceiveFilteredAffectations)
  },

  methods: {
    formatDateText,

    async onSubmit () {
      if (!this.operationsToExport) { return }
      this.exportLoading = true
      const filter = {
        format: this.format,
        include_attach: (!this.noAttachmentsOnFilteredExport && this.isAttachmentChecked) || '',
        accounting: this.isAccountingAccountChecked || ''
      }
      const successIfNoContent = filter.include_attach || filter.accounting || this.exportDelayed

      Object.assign(filter, this.apiFilters)

      let url = '/operations'
      if (this.isMileageType) {
        url = '/mileages'
      } else if (this.isExpenseType) {
        url = '/operations/control'
      } else if (this.isPayslipType || this.isAdvancePaymentType) {
        url = '/transfers'
      } else if (this.isOperationType) {
        url = '/operations'
      }

      await downloadFileFromUrl(`${url}/export`, filter, successIfNoContent)
      this.exportLoading = false
    },

    async loadOperationsCount () {
      this.countLoading = true
      this.operationsToExport = 0

      if (this.isMileageType) {
        this.operationsToExport = await this.mileageStore.getMileagesExportCount(this.apiFilters)
        this.exportDelayed = this.operationsToExport > 500
      } else if (this.isExpenseType) {
        this.operationsToExport = await this.expenseStore.getExpensesExportCount(this.apiFilters)
        this.exportDelayed = this.isAccountingAccountChecked ? (this.operationsToExport * 3) > 500 : this.operationsToExport > 500
      } else if (this.isPayslipType || this.isAdvancePaymentType) {
        this.operationsToExport = await this.transferStore.getCountTransfers(this.apiFilters)
        this.exportDelayed = this.operationsToExport > 500
      } else if (this.isOperationType) {
        const { count, export_delayed } = await this.operationStore.getOperationsCount(this.apiFilters)
        this.operationsToExport = count
        this.exportDelayed = export_delayed
      }

      this.countLoading = false
    },

    onReceiveFilteredBeneficiary (beneficiary) {
      this.selectedBeneficiary = beneficiary
    },

    onReceiveFilteredCard (card) {
      this.selectedCard = card
    },

    onReceiveFilteredCategory (category) {
      this.selectedCategory = category
    },

    onReceiveFilteredUser (user) {
      this.selectedUser = user
    },

    onReceiveFilteredTeam (team) {
      this.selectedTeam = team
    },

    onReceiveFilteredAffectations (team) {
      this.selectedAffectation = team
    },

    getMinMaxAmountWithCurrency (amount) {
      return formatAmount(amount, this.account.currency)
    },

    selectFormat (format) {
      this.format = format.value
    },

    getFormatLabel (formatValue) {
      const formatOption = this.formats.find(option => option.value === formatValue)
      return formatOption ? formatOption.label : ''
    },

    selectExportType (exportType) {
      this.selectedExportType = exportType.value
    },

    getExportTypeLabel (ExportTypeValue) {
      const exportTypeOption = this.exportTypeOptions.find(option => option.value === ExportTypeValue)
      return exportTypeOption ? exportTypeOption.label : ''
    }
  }
}
</script>
