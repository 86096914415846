<template>
  <svg viewBox="0 0 200 160">
    <path d="M77.57 118.75h2.94v-1.5h-2.94v1.5Zm-21.84-56.5A3.57 3.57 0 0 0 59 65.5h12l6-6.5H59a3.57 3.57 0 0 0-3.27 3.25Zm27.71 56.5h2.94v-1.5h-2.93l-.01 1.5Zm-4.94-46.5h3.07v-1.5h-3.06l-.01 1.5Zm12.3 0h3.07v-1.5H90.8v1.5Zm-18.44 0h3.07v-1.5h-3.07v1.5Zm22.85 46.5h2.94v-1.5h-2.94v1.5Zm-5.88 0h2.94v-1.5h-2.94v1.5Zm-4.67-46.5h3.07v-1.5h-3.07v1.5Zm-24.73 46.5h2.94v-1.5h-2.95l.01 1.5Zm-4.19-46.5H57v-1.5h-1.27l.01 1.5Zm0 46c.02.168.05.335.09.5H57v-1.5h-1.27l.01 1Zm4.33-46h3.07v-1.5h-3.08l.01 1.5Zm6.15 0h3.07v-1.5h-3.08l.01 1.5Zm-.41 46.5h2.94v-1.5H65.8l.01 1.5Zm5.88 0h2.94v-1.5h-2.94v1.5Zm52.93-48h-3.07v1.5h3.07v-1.5Zm7.87 37.39H134v-2.94h-1.5l-.01 2.94Zm0-5.89H134V101h-1.5l-.01 1.25Zm0 11.77H134v-2.94h-1.5l-.01 2.94Zm-1.24 3.23h-.76v1.5h.76a2.73 2.73 0 0 0 2.44-1.49l-1.33-.69a1.23 1.23 0 0 1-1.11.68Zm2.64-44.49-1.45.39c.014.106.014.214 0 .32V76H134v-2.52a2.702 2.702 0 0 0-.11-.72Zm-3.12-2h-3.07v1.5h3.07v-1.5ZM97 72.25h3v-1.5h-3v1.5Zm35.53 9.93H134V79.1h-1.5l.03 3.08Zm0 4.32H134v-1.25h-1.5l.03 1.25Zm-23.28-14.25h3.07v-1.5h-3.07v1.5Zm15.36 46.5h2.94v-1.5h-2.94v1.5Zm-21.51-46.5h3.07v-1.5h-3.07v1.5Zm-2 46.5h2.9v-1.5h-2.94l.04 1.5Zm5.88 0h2.94v-1.5H107l-.02 1.5Zm11.76 0h2.94v-1.5h-2.94v1.5Zm-3.33-46.5h3.07v-1.5h-3.07v1.5Zm-2.55 46.5h2.94v-1.5h-2.94v1.5Z"
          :fill="colorGrey" />
    <path d="M125.48 90.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5Zm0 5a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5Z"
          :fill="colorPrimary" />
    <path d="M139.73 81.52v-.31l19-15.73-29-35.11-5.87 4.85-6.94-7.08-4.24 4.16-6.12-5.61-28.2 30.81H59a5.07 5.07 0 0 0-4.75 4.75v56.07a6.19 6.19 0 0 0 6 5.68h73.45a5.77 5.77 0 0 0 5.78-5.75V101c1.91 0 3.25-1.54 3.25-3.75v-12.5a2.919 2.919 0 0 0-3-3.23Zm7.13-12.59-7.12 5.89v-3.69A5.7 5.7 0 0 0 134 65.5H95l29.15-24.09a6.75 6.75 0 0 0 8.39.8l15.16 18.34a6.75 6.75 0 0 0-.8 8.39l-.04-.01Zm9.79-3.65-16.92 14v-2.5l9.21-7.61-.48-.58a5.25 5.25 0 0 1 .7-7.39l.58-.48-17-20.58-.58.48a5.25 5.25 0 0 1-7.39-.7l-.48-.58L92.61 65.5h-3l40-33 27.04 32.78Zm-70.77.23 26.38-25.89c.426.336.889.621 1.38.85a6.739 6.739 0 0 0 3.11.64L87.24 65.5l-1.36.01Zm36.84-29.33-1.87 1.55-.11-.11-.53.52a5.32 5.32 0 0 1-7.42-.07l-.52-.54-28.51 28H81l35.92-35.24 5.8 5.89Zm-45 29.33 24.81-27.09c.822.592 1.77.985 2.77 1.15L78.85 65.5l-1.13.01Zm29-36.69 5 4.54-4.92 4.82a5.17 5.17 0 0 1-3.75-1.35l-.55-.51L75.67 65.5h-2.6l33.65-36.68ZM59 59h18l-6 6.5H59a3.25 3.25 0 0 1 0-6.5Zm79 59.25a4.27 4.27 0 0 1-4.28 4.25H60.26a4.66 4.66 0 0 1-4.53-4.25V65.54A4.75 4.75 0 0 0 59 67h75a4.2 4.2 0 0 1 4.25 4.13V86.5H125a7.25 7.25 0 0 0 0 14.5h13v17.25Zm3.25-21c0 .38-.08 2.25-1.75 2.25H125a5.75 5.75 0 1 1 0-11.5h14.5a3.703 3.703 0 0 0 1.75-.39v9.64Zm-1.5-10.77V83c1.06.07 1.5.58 1.5 1.74 0 1.16-.49 1.67-1.52 1.74h.02Z"
          :fill="colorDark" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  name: 'ImageWallet',

  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
