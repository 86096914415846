<template>
  <div class="chart bordered-box mb-2">
    <div class="chart__header">
      <div class="header__left">
        <h3 class="header__left--title">
          {{ title }}
        </h3>
        <slot name="filter" />
      </div>

      <div class="header__right">
        <slot name="header" />
      </div>
    </div>

    <div class="chart__body">
      <canvas :id="`chart-${uuid}`"
              height="400" />
      <transition name="fade">
        <components-chart-nodata v-if="noData && !firstInit" />
      </transition>
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { Chart, registerables } from 'chart.js'
import dayjs from 'dayjs'
import { uniqueId } from 'lodash/util'

import config from '@/config/config'
import { chartFormatNumber } from '@/helpers/utils/number'
import { capitalizeFirstLetter } from '@/helpers/utils/text'

import ComponentsChartNodata from '@/components/charts/ChartNodata.vue'
import { backLine } from '@/components/charts/utils/plugin-backline'
import generateGradient from '@/components/charts/utils/plugin-gradient'
import pluginTooltip from '@/components/charts/utils/plugin-tooltip'

import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'

Chart.register(...registerables, backLine)

export default {
  name: 'LineChart',
  components: { ComponentsChartNodata },
  props: {
    datasets: {
      type: Array,
      required: true
    },

    dateFormat: {
      type: String,
      default: 'MMM YYYY'
    },

    hidePoint: {
      type: Boolean,
      default: false
    },

    primaryColor: {
      type: String,
      default: config.CHART.COLOR.PRIMARY
    },

    title: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      chart: null,
      defaultOptions: {
        animation: false,
        hover: {
          intersect: false,
          mode: 'nearest'
        },

        interaction: {
          axis: 'x',
          intersect: false,
          mode: 'nearest'
        },

        lineTension: 0,
        maintainAspectRatio: false,
        responsive: true,
        scaleShowVerticalLines: false,

        tooltip: {
          intersect: false,
          mode: 'nearest'
        }
      },

      firstInit: true,
      uuid: uniqueId()
    }
  },

  computed: {
    chartX () {
      return {
        align: 'inner',
        border: {
          color: this.colors.border
        },

        font: {
          family: 'Manager',
          size: 11,
          weight: 'bold'
        },

        grid: {
          display: true,
          drawOnChartArea: false,
          tickColor: this.colors.border
        },

        hideOtherPoints: true,
        showEndX: true,
        showStartX: true,

        ticks: {
          align: 'inner',
          autoSkip: false,
          callback: (value, index, values) => {
            if (index !== 0 && index !== values.length - 1 && Math.floor(values.length / 2) !== index) return ''

            const dataset = this.datasets[0]?.data
            const date = dataset[index]?.x

            if (date === '') return ''

            return capitalizeFirstLetter(dayjs(date).format(this.dateFormat))
          },

          maxRotation: 0,
          maxTicksLimit: 20,
          padding: 10,
          precision: 0,
          source: 'data'
        }
      }
    },

    chartY () {
      return {
        beginAtZero: true,
        border: {
          display: false
        },

        bounds: 'ticks',

        grid: {
          color: this.colors.border,
          drawBorder: false,
          drawTicks: false
        },

        ticks: {
          align: 'inner',
          autoSkip: false,

          callback: value => {
            if (this.noData) {
              return chartFormatNumber(value * 100000)
            }
            return chartFormatNumber(value)
          },

          font: {
            size: 11,
            weight: 400
          },

          maxRotation: 0,
          maxTicksLimit: 6,
          padding: 10,
          source: 'data'
        },

        type: 'linear'
      }
    },

    colors () {
      return {
        background: config.SVG.COLOR.WHITE,
        border: config.SVG.COLOR.BORDER_COLOR,
        primary: this.primaryColor
      }
    },

    datasetsFormatted () {
      return this.datasets.map(data => {
        return {
          ...{
            backgroundColor: generateGradient(this.uuid, this.colors.primary),
            borderColor: this.colors.primary,
            borderWidth: 1.5,
            fill: 'start',
            pointBackgroundColor: this.colors.primary,
            pointBorderColor: this.colors.background,
            pointBorderWidth: this.hidePoint ? 0 : 2,
            pointHoverBorderColor: this.colors.background,
            pointHoverBorderWidth: 2,
            pointHoverRadius: 6,
            pointRadius: this.hidePoint ? 1 : 5
          },
          ...data
        }
      })
    },

    noData () {
      return this.datasets.every(item => item.data.length === 0)
    }

  },

  watch: {
    datasets: {
      handler () {
        this.chart.data = { datasets: this.datasetsFormatted }
        this.chart.update()
        this.firstInit = false
      },

      deep: true
    }
  },

  mounted () {
    this.chart = markRaw(new Chart(`chart-${this.uuid}`, {
      data: { datasets: this.datasetsFormatted },

      options: {
        ...this.defaultOptions,

        plugins: {
          backLine: { enable: true },
          hoverSegment: false,
          legend: { display: false },
          tooltip: { enabled: false, external: pluginTooltip, position: 'nearest' }
        },

        scales: {
          x: this.chartX,

          y: this.chartY
        }
      },

      type: 'line'
    }))
  }
}
</script>
