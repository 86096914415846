<template>
  <section class="section section--empty">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: physicalCard ? 'cards-physical' : 'cards-virtual' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ titleLabel }}
        </h1>
      </div>
    </div>
    <div class="section__content section__content-block--centered section-card-order">
      <div class="block-options"
           :class="{ 'block-options--two-columns': !physicalCard }">
        <block-options-item v-for="(item, index) in cardInfos"
                            :key="index"
                            :to="item.route"
                            :title="item.title"
                            :content="item.content"
                            :hide-chevron="physicalCard"
                            :data-cy="item.dataCy">
          <image-card v-if="item.icon.card"
                      v-bind="item.icon.props" />
        </block-options-item>
      </div>
    </div>
  </section>
</template>

<script>
import { kebabCase } from 'lodash'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { getCardTypeLabel, getImageCardIconName } from '@/helpers/utils/card'
import { useAccountStore } from '@/stores/account'

import BlockOptionsItem from '@/components/BlockOptionsItem.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import ImageCard from '@/components/svg/img/card.vue'

export default {
  components: {
    IcArrow,
    BlockOptionsItem,
    ImageCard
  },

  props: {
    physicalCard: {
      type: Boolean,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()

    const { account, physicalCardTypes, virtualCardTypes } = storeToRefs(accountStore)

    return { account, physicalCardTypes, virtualCardTypes }
  },

  computed: {
    cardInfos () {
      return this.physicalCard
        ? [
            {
              icon: { card: true, props: {} },
              route: this.getRoute('owner'),
              title: this.$i18n.t('prompt.cards.order.choice.me'),
              dataCy: 'cards.create.physical.me',
              content: this.$i18n.t('order_card.choice.choice_me', { account: this.account.company.name }),
              visible: true
            },
            {
              icon: { card: true, props: { user: true } },
              route: this.getRoute('user'),
              title: this.$i18n.t('prompt.cards.order.choice.employee'),
              dataCy: 'cards.create.physical.employee',
              content: this.$i18n.t('order_card.choice.choice_employee', { account: this.account.company.name }),
              visible: true
            },
            {
              icon: { card: true, props: { multiple: true } },
              route: { name: 'order-multiple-cards' },
              title: this.$i18n.t('prompt.cards.order.choice.multiple'),
              dataCy: 'cards.create.physical.multiple',
              content: this.$i18n.t('order_card.choice.order_multiple', { account: this.account.company.name }),
              visible: hasFeature(EFeature.CardCreateMultiple)
            }
          ].filter(route => route.visible)
        : this.virtualCardTypes.map(this.getVirtualCardInfo)
    },

    titleLabel () {
      if (this.physicalCard) return this.$i18n.t('order_card.choice.title')

      return this.hasPermission(this.$permissions.cardsVirtualRequest)
        ? this.$i18n.t('generate_ecard.title_request')
        : this.$i18n.t('generate_ecard.title')
    },

    cardOrderTypes () {
      return this.physicalCardTypes
    }
  },

  methods: {
    getRoute (role) {
      return this.cardOrderTypes.length === 1
        ? { name: 'order-card-form', params: { role }, query: { type: this.cardOrderTypes[0] } }
        : { name: 'order-card-type', params: { role } }
    },

    getVirtualCardInfo (cardType) {
      return {
        route: { name: 'generate-virtual-card', params: { cardType } },
        title: getCardTypeLabel({ type: cardType }),
        dataCy: `cards.create.virtual.${kebabCase(cardType)}`,
        content: this.$i18n.t(`generate_ecard.choice.${cardType}.content`),
        icon: { card: true, props: { [getImageCardIconName({ type: cardType })]: true } }
      }
    }
  }
}
</script>

<style lang="stylus">
.section-card-order
  .block-options--two-columns
    .block-options__item
      .icon
        svg
          margin-bottom .6rem
</style>
