type TRgbColor = {
  r: number,
  g: number,
  b: number
}
export function hexToRgb (hex: string): TRgbColor {
  hex = hex.replace(/^#/, '')

  const color = parseInt(hex, 16)

  const r = (color >> 16) & 0xFF
  const g = (color >> 8) & 0xFF
  const b = color & 0xFF

  return { r, g, b }
}

export function hexToRgba (hex: string, opacity = 1) {
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) { throw new Error('Format de couleur hexadécimale invalide') }

  hex = hex.replace('#', '')
  if (hex.length === 3) { hex = hex.split('').map(char => char + char).join('') }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
