<template>
  <div class="login-block-wrapper">
    <loader-spinner size="24"
                    class="c c--vh spinner--loading" />
    <div class="login-block">
      <h2 class="login-title">
        {{ $t("title.forgot_password") }}
      </h2>
      <div class="login-subtitle">
        {{ $t("pages.forgot.subtitle") }}
      </div>
      <validation-form ref="form"
                       v-slot="{ errors, isSubmitting, validate }"
                       :validation-schema="schema"
                       class="login-form"
                       autocomplete="off"
                       @invalid-submit="formSubmitted = true"
                       @submit="onSubmit">
        <div class="login-form__input">
          <validation-field id="email"
                            v-model="email"
                            type="email"
                            class="form-control"
                            name="email"
                            autocomplete="off"
                            :class="{ error: errors.email && formSubmitted }"
                            onfocus="this.removeAttribute('readonly');"
                            :placeholder="$t('form.email.label')"
                            :readonly="true"
                            @input="validate" />
        </div>
        <component-button type="submit"
                          :label="$t('button.send')"
                          :disabled="isSubmitting"
                          :loading="isSubmitting"
                          wrapper-class="btn btn--primary btn--block login-form__submit" />
      </validation-form>
      <div class="signup-link center">
        <router-link class="text-muted strong"
                     :to="{ name: 'login' }">
          {{ $t("button.cancel") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import * as yup from 'yup'

import { useAuthStore } from '@/stores/auth'

import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  name: 'PageForgot',

  components: {
    ComponentButton,
    LoaderSpinner
  },

  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },

  data () {
    return {
      formSubmitted: false,
      email: ''
    }
  },

  computed: {
    schema () {
      return yup.object({
        email: yup.string().validEmail().required()
      })
    }
  },

  methods: {
    async onSubmit () {
      this.formSubmitted = false
      await this.authStore.passwordRecovery(this.email)
      this.$refs.form.setFieldError('email', 'error')
      this.formSubmitted = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.section-login
  .login-title
    margin-bottom 2.5rem

  .form-control
    border-radius $border-radius
</style>
