<template>
  <div v-if="loadingBeneficiary"
       class="section__loader c c--vh">
    <loader-spinner />
  </div>
  <validation-form v-else
                   ref="form"
                   v-slot="{ errors, isSubmitting }"
                   :validation-schema="schema"
                   keep-values
                   class="form form--centered form--w1000 form-newbeneficiary"
                   :initial-values="beneficiary"
                   @keydown.enter.prevent
                   @invalid-submit="$bus.emit('scrollToTop')"
                   @submit="onSubmit(false)">
    <component-alert v-if="Object.keys(errors).length && formSubmitted && !isSubmitting"
                     type="error"
                     :message="$t('form.alerts.common')" />
    <component-alert v-if="!enableNoSepa && isInternational"
                     type="error"
                     :show-api-messages="false"
                     :message="$t('form.alerts.no_sepa')" />
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('general.general_informations')"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.label, 'is-valid': imported.label }">
            <label class="label input__label"
                   for="label">{{ $t('form.beneficiary.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field id="label"
                                v-model="beneficiary.label"
                                type="text"
                                data-cy="create-beneficiary.form.label"
                                class="form-control input__field"
                                :placeholder="$t('form.beneficiary.placeholder')"
                                name="label" />
            </div>
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.beneficiary_list_id }">
            <label class="label input__label"
                   for="beneficiary_list_id">{{ $t('form.beneficiary_attach_list.label') }}</label>
            <validation-field name="beneficiary_list_id"
                              :model-value="beneficiary.beneficiary_list_id">
              <component-dropdown label="beneficiary_list"
                                  dropdown-width="100%"
                                  :dropdown-height="40"
                                  :values="beneficiaryLists"
                                  :model="beneficiary.beneficiary_list_id"
                                  @select="value => beneficiary.beneficiary_list_id = value.id">
                <template #trigger>
                  <div class="dropdown-toggle select">
                    <div class="form-control form-control--noborder">
                      <div v-if="!beneficiary.beneficiary_list_id && beneficiary.beneficiary_list_id !== 0"
                           class="text-placeholder">
                        {{ $t('form.beneficiary_attach_list.placeholder') }}
                      </div>
                      <div v-else>
                        {{ beneficiaryLists.find(list => list.id === beneficiary.beneficiary_list_id)?.label }}
                      </div>
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-2"
               :class="{ error: errors.short_tag }">
            <label class="label input__label"
                   for="short_tag">{{ $t('form.beneficiary.code.label') }}</label>
            <validation-field id="short_tag"
                              v-model="beneficiary.short_tag"
                              type="text"
                              class="form-control form-control--noborder"
                              name="short_tag" />
          </div>
          <div class="form-group col col--sm"
               :class="[{ error: errors.comment }]">
            <label class="label input__label"
                   for="comment">{{ $t('form.beneficiary.comment.label') }}</label>
            <validation-field id="comment"
                              v-model="beneficiary.comment"
                              type="text"
                              class="form-control form-control--noborder"
                              :placeholder="$t('form.beneficiary.comment.placeholder')"
                              name="comment" />
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset v-if="isListEmployee"
              class="form__fieldset">
      <fieldset-title :title="`${$t('form.title.employee_informations')} (${$t('general.optional')})`"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.social_security_number }">
            <label class="label input__label"
                   for="short_tag">{{ $t('form.beneficiary.social_security_number.label') }}</label>
            <validation-field id="social_security_number"
                              v-model="beneficiary.social_security_number"
                              autocomplete="off"
                              type="text"
                              class="form-control form-control--noborder"
                              maxlength="30"
                              :placeholder="$t('form.beneficiary.social_security_number.placeholder')"
                              name="social_security_number" />
          </div>
          <div class="form-group col col--sm-6">
            <label class="label input__label"
                   for="employee_number">{{ $t('form.registration_number.label') }}</label>
            <div class="input-group">
              <validation-field id="employee_number"
                                v-model="beneficiary.employee_number"
                                type="text"
                                class="form-control form-control--noborder"
                                :placeholder="$t('form.registration_number.placeholder')"
                                name="employee_number" />
            </div>
          </div>
        </div>
        <employee-details ref="details"
                          name="details"
                          :user="beneficiary"
                          :errors="errors"
                          @update:user="value => beneficiary.details = value.details" />
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('general.bank_details')"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div v-if="enableNoSepa"
               class="form-group col"
               style="padding-bottom:1.5rem;">
            <toggle-switch :model-value="isInternational"
                           :disabled="!isNewBeneficiary || (isIban && beneficiary?.iban !== '') || imported.iban"
                           toggle-class="switch-btn--sm"
                           name="isInternational"
                           @update:model-value="value => isInternational = value" />
            <span class="switch-btn-label">{{ $t('form.no_sepa.label') }} : <span v-if="isInternational">{{ $t('general.yes') }}</span><span v-else>{{ $t('general.no') }}</span></span>
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-8"
               :class="{ error: errors.iban || (!enableNoSepa && isInternational), 'is-valid': imported.iban }">
            <label class="label input__label"
                   for="iban">{{ $t('form.iban.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field id="iban"
                                v-model="beneficiary.iban"
                                data-cy="create-beneficiary.form.iban"
                                type="text"
                                class="form-control"
                                :disabled="!isNewBeneficiary"
                                :placeholder="$t('form.iban.placeholder')"
                                name="iban"
                                @keyup="onIbanKeyUp"
                                @blur="onIbanBlur" />
              <div v-if="!isNewBeneficiary"
                   class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
          <div class="form-group col col--sm-4"
               :class="{ error: errors.bic, 'is-valid': imported.bic }">
            <label class="label input__label"
                   for="bic">{{ $t('general.bic_swift') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field id="bic"
                                v-model="beneficiary.bic"
                                type="text"
                                class="form-control input__field"
                                data-cy="create-beneficiary.form.bic"
                                :placeholder="$t('form.beneficiary.bic_code.placeholder')"
                                :disabled="!isNewBeneficiary"
                                name="bic" />
              <div v-if="!isNewBeneficiary"
                   class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset v-show="showBankDataFields"
              class="form__fieldset">
      <fieldset-title :title="$t('form.title.beneficiary_bank_details')"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors['bank_data.bank'] }">
            <label class="label input__label">{{ $t("form.bank_name.label") }}</label>
            <div class="input-group input-group--noborder">
              <validation-field v-model="beneficiary.bank_data.bank"
                                type="text"
                                class="form-control form-control--noborder"
                                :disabled="!isNewBeneficiary"
                                :placeholder="$t('form.bank_name.placeholder')"
                                name="bank_data.bank" />
              <div v-if="!isNewBeneficiary"
                   class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors['bank_data.country_iso'] }">
            <label class="label input__label">{{ $t('form.country.label') }}</label>
            <country-select v-model="beneficiary.bank_data.country_iso"
                            name="bank_data.country_iso"
                            :disabled="!isNewBeneficiary" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors['bank_data.street'] }">
            <label class="label input__label">{{ $t('form.address.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field v-model="beneficiary.bank_data.street"
                                name="bank_data.street"
                                type="text"
                                class="form-control form-control--noborder"
                                :disabled="!isNewBeneficiary"
                                :placeholder="$t('form.address.placeholder')" />
              <div v-if="!isNewBeneficiary"
                   class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
          <div class="form-group col col--sm-2"
               :class="{ error: errors['bank_data.zip'] }">
            <label class="label input__label">{{ $t('form.zip.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field v-model="beneficiary.bank_data.zip"
                                name="bank_data.zip"
                                type="text"
                                class="form-control form-control--noborder"
                                :disabled="!isNewBeneficiary"
                                :placeholder="$t('form.zip.placeholder')" />
              <div v-if="!isNewBeneficiary"
                   class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
          <div class="form-group col col--sm-4"
               :class="{ error: errors['bank_data.city'] }">
            <label class="label input__label">{{ $t('form.city.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field v-model="beneficiary.bank_data.city"
                                name="bank_data.city"
                                type="text"
                                class="form-control form-control--noborder"
                                :disabled="!isNewBeneficiary"
                                :placeholder="$t('form.city.placeholder')" />
              <div v-if="!isNewBeneficiary"
                   class="input-group__addon">
                <ic-cadenas-sm class="ic ic--16 ic--off" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('general.beneficiary_informations') + (isInternational ? '' : ` (${$t('general.optional')})`)"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.email }">
            <label class="label input__label"
                   for="email">{{ $t('form.email.label') }}
              <template v-if="!isInternational">({{ $t('general.optional') }})</template>
            </label>
            <validation-field id="email"
                              v-model="beneficiary.email"
                              name="email"
                              type="text"
                              class="form-control form-control--noborder"
                              :placeholder="$t('form.email.placeholder')" />
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.phone_number }">
            <label class="label input__label">{{ $t('form.phone.label') }}
              <template v-if="isInternational">({{ $t('general.optional') }})</template>
            </label>
            <phone-input id="phone_number"
                         name="phone_number"
                         wrapper-class="input-group--noborder"
                         :model-value="beneficiary.phone_number"
                         @update:model-value="value => beneficiary.phone_number = value" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm"
               :class="{ error: errors['address.street'], 'is-valid': imported.street }">
            <label class="label input__label"
                   for="street">{{ $t('form.address.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field id="street"
                                v-model="beneficiary.address.street"
                                name="address.street"
                                type="text"
                                class="form-control form-control--noborder"
                                :placeholder="$t('form.address.placeholder')" />
            </div>
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-2"
               :class="{ error: errors['address.zip'], 'is-valid': imported.zip }">
            <label class="label input__label"
                   for="zip">{{ $t('form.zip.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field id="zip"
                                v-model="beneficiary.address.zip"
                                name="address.zip"
                                type="text"
                                class="form-control form-control--noborder" />
            </div>
          </div>
          <div class="form-group col col--sm-4"
               :class="{ error: errors['address.city'], 'is-valid': imported.city }">
            <label class="label input__label"
                   for="city">{{ $t('form.city.label') }}</label>
            <div class="input-group input-group--noborder">
              <validation-field id="city"
                                v-model="beneficiary.address.city"
                                name="address.city"
                                type="text"
                                class="form-control form-control--noborder"
                                :placeholder="$t('form.city.placeholder')" />
            </div>
          </div>
          <div class="form-group is-select col col--sm-6"
               :class="{ error: errors['address.country'], 'is-valid': imported.country }">
            <label class="label input__label">{{ $t('form.country.label') }}</label>
            <country-select v-model="beneficiary.address.country"
                            name="address.country" />
          </div>
        </div>
      </div>
    </fieldset>
    <div v-if="!isEditingImportedBeneficiary"
         class="form-buttons">
      <button class="btn btn--gray"
              type="button"
              @click="onCancel">
        {{ $t('button.cancel') }}
      </button>
      <button class="btn btn--primary"
              type="submit"
              :disabled="isSubmitting || (!enableNoSepa && isInternational) || modal.active"
              data-cy="create-beneficiary.form.submit">
        {{ $t('button.validate') }}
      </button>
    </div>
  </validation-form>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber'
import dayjs from 'dayjs'
import IBAN from 'iban'
import { cloneDeep, merge } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { clearInputs } from '@/helpers/utils'
import { formatIban, formatIbanOnKeyUp } from '@/helpers/utils/iban'
import { showToastInfo, showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useTransferStore } from '@/stores/transfer'

import ComponentAlert from '@/components/Alert.vue'
import CountrySelect from '@/components/CountrySelect.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import EmployeeDetails from '@/components/EmployeeDetails.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Confirm from '@/components/modals/Confirm.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

const initBeneficiary = {
  details: {
    advance_payment: false,
    contract_type: null,
    contract_start_date: null,
    contract_end_date: null,
    social_security_number: ''
  },

  address: {
    street: '',
    zip: '',
    city: '',
    country: ''
  },

  bank_data: {
    bank: '',
    street: '',
    zip: '',
    city: '',
    country_iso: ''
  },

  label: '',
  short_tag: '',
  iban: '',
  bic: '',
  phone_number: '',
  email: '',
  comment: '',
  beneficiary_list_id: null,
  is_sepa: 1
}

export default {
  components: {
    FieldsetTitle,
    IcCadenasSm,
    CountrySelect,
    EmployeeDetails,
    ToggleSwitch,
    ComponentAlert,
    PhoneInput,
    LoaderSpinner,
    ComponentDropdown
  },

  props: {
    isSidePanel: {
      type: Boolean,
      default: false
    },

    isEditingImportedBeneficiary: {
      type: Boolean,
      default: false
    },

    existingBeneficiary: {
      type: Object,
      required: true
    },

    existingAddress: {
      type: Object,
      default: null
    },

    enableNoSepa: {
      type: Boolean,
      required: true
    },

    previousRoute: {
      type: Object,
      default: null
    }
  },

  emits: ['form-changed'],

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()
    const transferStore = useTransferStore()

    const { account } = storeToRefs(accountStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { modal } = storeToRefs(appStore)
    const { beneficiaryLists } = storeToRefs(beneficiaryStore)
    const { iban } = storeToRefs(transferStore)

    return {
      appStore,
      beneficiaryStore,
      transferStore,

      account,
      apiError,
      beneficiaryLists,
      iban,
      modal
    }
  },

  data () {
    return {
      loadingBeneficiary: false,
      isIban: false,
      formSubmitted: false,
      dataFilled: false,
      beneficiary: cloneDeep(initBeneficiary),
      initBeneficiary: cloneDeep(initBeneficiary),
      imported: {}
    }
  },

  computed: {
    schema () {
      return yup.object({
        label: yup.string().required(),
        beneficiary_list_id: yup.number().integer().nullable(),
        short_tag: yup.string().nullable(),
        comment: yup.string().nullable(),
        social_security_number: yup.string().matches(/^[a-zA-Z0-9]{0,15}$/).nullable(),
        employee_number: yup.string().nullable(),
        details: yup.object().when('beneficiary_list_id', {
          is: () => this.isListEmployee,
          then: () => yup.object({
            advance_payment: yup.boolean().notRequired(),
            contract_type: yup.string().notRequired(),
            contract_start_date: this.beneficiary.details.contract_type ? yup.date().required() : null,
            contract_end_date: yup.date().notRequired()
          })
        }),
        iban: yup.string().required(),
        bic: yup.string().required(),
        is_sepa: yup.number().oneOf([0, 1]),
        bank_data: this.showBankDataFields
          ? yup.object({
            bank: yup.string().required(),
            country_iso: yup.string().required(),
            street: yup.string().required(),
            zip: yup.string().nullable(),
            city: yup.string().nullable()
          })
          : null,
        email: this.isInternational ? yup.string().validEmail().required() : yup.string().validEmail().nullable(),
        phone_number: yup.string().validPhoneNumber().nullable(),
        address: yup.object({
          street: this.isInternational ? yup.string().required() : yup.string().nullable(),
          zip: yup.string().nullable(),
          city: yup.string().nullable(),
          country: this.isInternational ? yup.string().required() : yup.string().nullable()
        })
      })
    },

    getBeneficiaryPayload () {
      const beneficiary = cloneDeep(this.beneficiary)

      beneficiary.iban = beneficiary.iban.replace(/\s+/g, '')
      beneficiary.bic = beneficiary.bic.replace(/\s+/g, '')

      const phone = parsePhoneNumber(beneficiary.phone_number || '')
      beneficiary.phone_number = phone.number.significant ? beneficiary.phone_number : ''

      if (this.isListEmployee) {
        beneficiary.details.contract_end_date = (beneficiary.details.contract_end_date && beneficiary.details.contract_type !== 'CDI') ? dayjs(beneficiary.details.contract_end_date).format('YYYY-MM-DD') : null
        beneficiary.details.contract_start_date = beneficiary.details.contract_start_date ? dayjs(beneficiary.details.contract_start_date).format('YYYY-MM-DD') : null
        beneficiary.details.social_security_number = beneficiary.social_security_number
      } else {
        delete beneficiary.details
        delete beneficiary.social_security_number
        delete beneficiary.employee_number
      }

      if (!this.showBankDataFields) {
        delete beneficiary.bank_data
      }

      return beneficiary
    },

    isListEmployee () {
      const list = this.beneficiaryLists.find(list => list.id === this.beneficiary.beneficiary_list_id)

      return list?.list_type?.name === 'employees'
    },

    isNewBeneficiary () {
      const id = this.existingBeneficiary.id
      return (id === null || id === undefined) || this.isEditingImportedBeneficiary
    },

    isInternational: {
      get () {
        return this.beneficiary.is_sepa === 0
      },

      set (value) {
        this.beneficiary.is_sepa = value ? 0 : 1
      }
    },

    showBankDataFields () {
      return this.isInternational && this.enableNoSepa
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    },

    beneficiary: {
      handler () {
        if (this.dataFilled) {
          this.$emit('form-changed')
        }
      },

      deep: true
    },

    existingBeneficiary: {
      handler ({ rib }) {
        this.beneficiary.rib = rib
      },

      deep: true
    },

    'beneficiary.address': {
      handler () {
        if (this.dataFilled) {
          this.$emit('form-changed')
        }
      },

      deep: true
    }
  },

  async created () {
    await this.fillData()

    this.dataFilled = true
  },

  mounted () {
    this.$bus.on('similar', this.onSimilarBeneficiary)
    this.$bus.on('confirm-similar-beneficiary-create', this.onSimilarBeneficiaryCreateConfirm)
    this.$bus.on('beneficiary-save-success', this.onValidateBeneficiary)
    this.$bus.on('beneficiary-update-success', this.onUpdateBeneficiarySuccess)
    this.$bus.on('beneficiary-validate-success', this.onValidateBeneficiary)
    this.$bus.on('beneficiary-rib-upload-success', this.onRibUploadSuccess)
  },

  beforeUnmount () {
    this.$bus.off('similar', this.onSimilarBeneficiary)
    this.$bus.off('confirm-similar-beneficiary-create', this.onSimilarBeneficiaryCreateConfirm)
    this.$bus.off('beneficiary-save-success', this.onValidateBeneficiary)
    this.$bus.off('beneficiary-update-success', this.onUpdateBeneficiarySuccess)
    this.$bus.off('beneficiary-validate-success', this.onValidateBeneficiary)
    this.$bus.off('beneficiary-rib-upload-success', this.onRibUploadSuccess)
  },

  methods: {
    async onSubmit (force = false) {
      this.formSubmitted = true

      this.isNewBeneficiary
        ? await this.beneficiaryStore.saveBeneficiary(this.getBeneficiaryPayload, force)
        : await this.beneficiaryStore.updateBeneficiary(this.getBeneficiaryPayload)
    },

    async validateForm () {
      const result = await this.$refs.form.validate()
      return {
        result,
        payload: this.getBeneficiaryPayload
      }
    },

    populateBeneficiary (beneficiaryData) {
      const beneficiary = merge(cloneDeep(this.initBeneficiary), beneficiaryData)
      beneficiary.details.contract_start_date = beneficiary.details.contract_start_date ? dayjs(beneficiary.details.contract_start_date, 'YYYY-MM-DD').toDate() : null
      beneficiary.details.contract_end_date = beneficiary.details.contract_end_date ? dayjs(beneficiary.details.contract_end_date, 'YYYY-MM-DD').toDate() : null
      beneficiary.social_security_number = beneficiary.details.social_security_number

      if (this.$refs.details?.userForm) this.$refs.details.userForm = beneficiary
      this.beneficiary = beneficiary

      this.isInternational = !this.beneficiary.is_sepa
    },

    onIbanKeyUp (e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        return
      }
      const cursorPosition = e.target.selectionStart
      const formattedIban = formatIbanOnKeyUp(e.target.value)
      if (formattedIban) {
        this.beneficiary.iban = formattedIban
        this.$nextTick(() => {
          this.setCursorPosition(e, cursorPosition)
        })
      }
    },

    setCursorPosition (e, cursorPosition) {
      if (e.key === 'Backspace' && cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition -= 1
      } else if (!/[a-z0-9]/i.test(e.key)) {
        cursorPosition -= 1
      } else if (cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition += 1
      }
      e.target.setSelectionRange(cursorPosition, cursorPosition)
    },

    onValidateBeneficiary (data, validation = 'added') {
      if (this.isSidePanel) {
        this.appStore.closeSidePanel()
        this.$bus.emit('create-new-beneficiary', data)
        return
      }

      clearInputs()
      if (validation === 'added') {
        showToastSuccess(this.$i18n.t('message.success.beneficiary.add'))
      } else if (validation === 'pending') {
        showToastInfo(this.$i18n.t('message.info.beneficiary.pending'))
      } else if (validation === 'rejected') {
        // TODO à clean
      }

      this.$router.push(this.previousRoute)
    },

    onUpdateBeneficiarySuccess () {
      if (this.isSidePanel) {
        this.appStore.closeSidePanel()
        return
      }

      clearInputs()
      showToastSuccess(this.$i18n.t('message.success.beneficiary.edit'))
      this.$router.push({ name: 'beneficiary-list', params: { id: (this.beneficiary.beneficiary_list_id || '0') } })
    },

    async onIbanBlur (e) {
      const value = e.target.value

      if (e.target.value.length <= 0) {
        return
      }

      await this.analyzeIban(value)
    },

    async analyzeIban (value) {
      if (IBAN.isValid(value)) {
        this.isIban = true
        this.beneficiary.iban = formatIban(value)
      } else {
        this.isIban = false
      }

      const response = await this.transferStore.validateIban(value)
      if (typeof response?.sepa === 'boolean') {
        this.isInternational = !response.sepa
        this.beneficiary.bic = response.bic
        this.beneficiary.bank_data.bank = response.bank
        this.beneficiary.bank_data.city = response.city
        this.beneficiary.bank_data.country_iso = response.country_iso
        this.beneficiary.bank_data.street = response.street
        this.beneficiary.bank_data.zip = response.zip
      } else {
        this.isInternational = true
      }
    },

    onCancel () {
      this.isSidePanel ? this.appStore.closeSidePanel() : this.$router.push(this.previousRoute)
    },

    findAncestor (el) {
      while ((el = el.parentElement) && (!el.classList.contains('form-group') || el.tagName !== 'DIV'));
      return el
    },

    onRibUploadSuccess (data) {
      if (!data || !this.modal.active) return

      if (data.account_number_formatted) {
        this.beneficiary.iban = data.account_number_formatted
        this.imported.iban = true
      } else {
        this.beneficiary.iban = ''
      }

      if (data.bank_data.bic) {
        this.beneficiary.bic = data.bank_data.bic
        this.imported.bic = true
      } else {
        this.beneficiary.bic = ''
      }

      if (data.label) {
        this.beneficiary.label = data.label
        this.imported.label = true
      } else {
        this.beneficiary.label = ''
      }

      for (const field in data.address) {
        if (Object.prototype.hasOwnProperty.call(this.beneficiary.address, field) && Object.prototype.hasOwnProperty.call(data.address, field) && data.address[field]) {
          this.beneficiary.address[field] = data.address[field]
          this.imported[field] = true
        } else {
          this.beneficiary.address[field] = ''
        }
      }
      this.isInternational = !data.is_sepa

      document.querySelectorAll('div.form-group').forEach(input => {
        const listener = input.getElementsByTagName('input')[0]?.type === 'text' ? 'keyup' : 'change'
        input.addEventListener(listener, e => this.findAncestor(e.target).classList.remove('is-valid'))
      })
    },

    onRemoveRibFile () {
      const importedValues = document.getElementsByClassName('is-valid')
      for (const el of importedValues) {
        const field = el.getElementsByTagName('input')[0]?.name || null
        if (field === 'bic') {
          this.beneficiary.bank_data.bic = ''
        } else if (['street', 'zip', 'city', 'country'].includes(field)) {
          this.address[field] = ''
        } else if (field) {
          this.beneficiary[field] = ''
        }
      }

      this.imported = {}
    },

    onSimilarBeneficiary () {
      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.t('prompt.beneficiary.validate.title'),
          content: this.$i18n.t('prompt.beneficiary.validate.content'),
          confirm: this.$i18n.t('button.add'),
          event: 'similar-beneficiary-create'
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    onSimilarBeneficiaryCreateConfirm () {
      this.onSubmit(true)
    },

    async fillData () {
      if (this.existingBeneficiary) {
        this.loadingBeneficiary = true
      }

      await this.beneficiaryStore.getBeneficiaryLists()

      if (this.isEditingImportedBeneficiary) {
        this.populateBeneficiary(this.existingBeneficiary)
        this.loadingBeneficiary = false
        return
      }

      useTransferStore().$reset()

      if (this.isSidePanel) {
        for (const field in this.existingBeneficiary) {
          if (Object.prototype.hasOwnProperty.call(this.beneficiary, field) && this.existingBeneficiary[field]) {
            this.beneficiary[field] = this.existingBeneficiary[field]
          }
        }

        if (!this.isNewBeneficiary) {
          this.beneficiary.id = this.existingBeneficiary.id
        }

        this.beneficiary.iban = this.existingBeneficiary.account_number_formatted || formatIban(this.existingBeneficiary.iban) || ''
        if (this.existingBeneficiary.address && Object.values(this.existingBeneficiary.address).some(i => i)) {
          for (const key in this.address) {
            if (this.existingBeneficiary.address[key]) {
              this.beneficiary.address[key] = this.existingBeneficiary.address[key]
            }
          }
        }

        if (this.beneficiary.iban) {
          await this.analyzeIban(this.beneficiary.iban)
        }

        this.loadingBeneficiary = false
        return
      }

      if (this.existingBeneficiary?.iban) {
        this.beneficiary.id = this.existingBeneficiary.id
        for (const key in this.beneficiary) {
          if (typeof this.beneficiary[key] !== 'object' && this.existingBeneficiary[key]) {
            this.beneficiary[key] = this.existingBeneficiary[key]
          }
        }
        if (this.existingBeneficiary.details) {
          this.beneficiary.details = cloneDeep(this.existingBeneficiary.details)
          this.beneficiary.details.contract_start_date = this.beneficiary.details.contract_start_date ? dayjs(this.beneficiary.details.contract_start_date, 'YYYY-MM-DD').toDate() : null
          this.beneficiary.details.contract_end_date = this.beneficiary.details.contract_end_date ? dayjs(this.beneficiary.details.contract_end_date, 'YYYY-MM-DD').toDate() : null
          this.beneficiary.social_security_number = this.existingBeneficiary.details.social_security_number
        }
        if (this.existingBeneficiary.bank_data) {
          for (const key in this.beneficiary.bank_data) {
            if (this.existingBeneficiary.bank_data[key]) this.beneficiary.bank_data[key] = this.existingBeneficiary.bank_data[key]
          }
        }
        if (this.existingAddress && Object.values(this.existingAddress).some(i => i)) {
          for (const key in this.beneficiary.address) {
            if (this.existingAddress[key]) this.beneficiary.address[key] = this.existingAddress[key]
          }
        }
        if (this.existingBeneficiary.beneficiary_list_id || this.existingBeneficiary.beneficiary_list_id === 0) {
          this.beneficiary.beneficiary_list_id = this.existingBeneficiary.beneficiary_list_id
        } else if (this.existingBeneficiary.beneficiary_list?.id || this.existingBeneficiary.beneficiary_list?.id === 0) {
          this.beneficiary.beneficiary_list_id = this.existingBeneficiary.beneficiary_list.id
        }
        this.beneficiary.is_sepa = this.existingBeneficiary.is_sepa ? 1 : 0
      }

      this.beneficiary.beneficiary_list_id ||= this.existingBeneficiary.beneficiary_list_id
      this.loadingBeneficiary = false
    }
  }
}
</script>
