<template>
  <section data-cy="create-beneficiary"
           class="section section-beneficiary-create">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="previousRoute">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ title }}
        </h1>
      </div>
      <div v-if="isNewBeneficiary"
           class="section__heading__right">
        <div class="section-actions">
          <a v-if="beneficiary.rib"
             class="btn-link btn-link--attachment"
             href="#"
             @click="showFile">
            <i class="btn-icon btn-icon--left">
              <ic-document class="ic ic-document ic--gray" />
            </i>
            <span class="btn__label">{{ beneficiary.rib.name }}</span>
            <button class="btn-link btn-remove-file"
                    @click.prevent.stop="removeFile">
              <ic-remove class="ic ic--18 ic--gray" />
            </button>
          </a>
          <button v-else
                  class="btn btn--default"
                  @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_rib'), {}, { maxFiles: 1 })">
            <i class="btn-icon btn-icon--left">
              <ic-attachment class="ic ic--white" />
            </i>
            {{ $t('button.analyze_rib') }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <new-beneficiary-form ref="beneficiaryForm"
                            :existing-beneficiary="beneficiary"
                            :existing-address="address"
                            :enable-no-sepa="hasFeature(EFeature.BeneficiaryInternational)"
                            :previous-route="previousRoute"
                            @form-changed="formUpdated = true" />
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { checkCompletedInputs, clearInputs } from '@/helpers/utils'
import { formatIban } from '@/helpers/utils/iban'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import NewBeneficiaryForm from '@/components/forms/NewBeneficiaryForm.vue'
import DocumentAnalyze from '@/components/modals/DocumentAnalyze.vue'
import Leave from '@/components/modals/Leave.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcAttachment,
    IcDocument,
    IcRemove,
    IcArrow,
    NewBeneficiaryForm
  },

  beforeRouteLeave (to, from, next) {
    const nextPath = this.accountStore.getUserNextPathBasedOnPermissions(from)

    if (nextPath.path === from.path && !this.hasAccountSwitched && checkCompletedInputs() && (this.isNewBeneficiary || this.formUpdated)) {
      next(false)
      this.appStore.showModal(Leave, { location: to }, { wrapperClass: 'modal--xs' })
    } else { next() }
  },

  props: {
    listId: {
      type: [Number, String],
      default: null
    },

    id: {
      type: [Number, String],
      default: null
    }
  },

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()
    const accountStore = useAccountStore()

    const { beneficiaries } = storeToRefs(beneficiaryStore)

    return { appStore, beneficiaries, beneficiaryStore, accountStore }
  },

  data () {
    return {
      formUpdated: false,
      hasAccountSwitched: false,
      beneficiary: {
        id: null,
        label: '',
        short_tag: '',
        iban: '',
        bic: '',
        phone_number: '',
        email: '',
        comment: '',
        beneficiary_list_id: null,
        rib: null
      },

      address: {
        street: '',
        zip: '',
        city: '',
        country: ''
      }
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    title () {
      return this.isNewBeneficiary ? this.$t('title.new_beneficiary') : this.$t('title.edit_beneficiary')
    },

    isNewBeneficiary () {
      return this.id === null
    },

    previousRoute () {
      return this.listId === null ? { name: 'beneficiary-lists' } : { name: 'beneficiary-list', params: { id: this.listId } }
    }
  },

  created () {
    if (this.isNewBeneficiary) {
      this.beneficiary.beneficiary_list_id = (this.listId || this.listId === 0) ? parseInt(this.listId) : null
      return
    }

    const beneficiary = this.beneficiaries.find(beneficiary => beneficiary.id === parseInt(this.id))

    if (beneficiary) {
      this.beneficiary = beneficiary
      this.beneficiary.beneficiary_list_id = this.beneficiary.beneficiary_list_id || this.beneficiary.beneficiary_list?.id || 0
      this.beneficiary.iban = formatIban(this.beneficiary.iban)

      if (this.beneficiary.address) {
        this.address = this.beneficiary.address
      }
    } else {
      clearInputs()
      this.$router.push(this.previousRoute)
    }
  },

  mounted () {
    this.$bus.on('on-file-attached', this.onFileAttached)
    this.$bus.on('delete-local-file', this.removeFile)
    this.$bus.on('account-switched', this.accountSwitched)
  },

  beforeUnmount () {
    this.$bus.off('on-file-attached', this.onFileAttached)
    this.$bus.off('delete-local-file', this.removeFile)
    this.$bus.off('account-switched', this.accountSwitched)
  },

  methods: {
    hasFeature,

    onFileAttached ({ files }) {
      if (!files[0]) { return }

      this.beneficiary.rib = files[0]
      this.appStore.showModal(
        DocumentAnalyze,
        {
          title: this.$i18n.t('prompt.beneficiary.rib.title'),
          content: this.$i18n.t('prompt.beneficiary.rib.content'),
          type: 'beneficiary',
          fileToAnalyze: files[0]
        },
        { wrapperClass: 'modal-document-analyze' }
      )
    },

    accountSwitched () {
      this.hasAccountSwitched = true
      this.$router.push({ name: 'beneficiary-lists' })
    },

    removeFile () {
      this.beneficiary.rib = null
      this.$refs.beneficiaryForm.onRemoveRibFile()
    },

    showFile () {
      this.appStore.showFileViewer({
        content: [this.beneficiary.rib],
        canEdit: true,
        isLocal: true,
        displayFullScreen: true
      })
    }
  }
}
</script>
