<template>
  <validation-form v-slot="{ errors, isSubmitting }"
                   :validation-schema="schema"
                   @submit="onSubmit">
    <modal-dialog-prompt :title="$t('title.send_by_email')"
                         @close="$emit('close')">
      <template #body>
        <form class="form"
              @submit.prevent="onSubmit">
          <div class="form__row row">
            <div class="form-group col col--sm"
                 :class="{ error: errors.email }">
              <label class="label input__label"
                     for="email">{{ $t('form.email.label') }}</label>
              <validation-field id="email"
                                v-model="email"
                                name="email"
                                class="form-control"
                                type="text"
                                :placeholder="$t('form.email.placeholder2')" />
            </div>
          </div>
        </form>
      </template>

      <template #footerButtons>
        <button type="button"
                class="btn btn--gray"
                @click.prevent="$emit('close')">
          {{ $t('button.cancel') }}
        </button>
        <button class="btn btn--primary"
                :disabled="isSubmitting">
          {{ $t('button.send') }}
        </button>
      </template>
    </modal-dialog-prompt>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useAuthStore } from '@/stores/auth'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ModalDialogPrompt
  },

  props: {
    callback: {
      type: Function,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const authStore = useAuthStore()

    const { user } = storeToRefs(authStore)

    return { user }
  },

  data () {
    return {
      email: this.user.email,
      schema: yup.object({
        email: yup.string().required().email()
      })
    }
  },

  methods: {
    async onSubmit () {
      const success = await this.callback(this.email)
      if (!success) { return }

      this.$emit('close')
    }
  }
}
</script>
