<template>
  <section class="section"
           data-cy="treasury.page">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.treasury') }}
        </h1>
      </div>
    </div>

    <loader-spinner v-if="loading"
                    class="c c--vh"
                    size="24" />

    <template v-else-if="showPlaceholder">
      <div class="section__content"
           data-cy="treasury.placeholder-no-content">
        <component-placeholder class="c c--vh"
                               :label="$t('placeholder.treasury.title')"
                               :content="$t('placeholder.treasury.content')">
          <image-computer-chart />
        </component-placeholder>
      </div>
    </template>
    <template v-else-if="!loading">
      <div class="section__content">
        <core-block-summary :items="resumeList"
                            :loading="loadingSummary"
                            class="chart mb-2"
                            data-cy="treasury.resume">
          <template #header>
            <div class="chart__header">
              <div class="header__left">
                <h3 class="header__left--title">
                  {{ $t('treasury.resume.title') }}
                </h3>
                <chart-filter v-model="formFilter"
                              custom-period-type="month"
                              :values="getFilter"
                              child-data-cy="treasury.resume.filter" />
              </div>
            </div>
          </template>
          <template #title="{ item }">
            <span :class="{
              'text-success': item.type === ETreasuryResumeEvolutionType.Positive,
              'text-error': item.type === ETreasuryResumeEvolutionType.Negative,
              'text-muted': item.type === ETreasuryResumeEvolutionType.Zero
            }">
              {{ item.title }} <ic-treasury-resume v-if="item.type !== undefined"
                                                   :type="item.type" />
            </span>
          </template>
          <template #subtitle="{ item }">
            {{ item.subtitle }}
            <span v-if="item.tooltip"
                  v-tooltip="{ content: item.tooltip, theme: 'tooltip' }"
                  class="ic-helper small">
              <ic-info outline
                       class="ic" />
            </span>
          </template>
        </core-block-summary>

        <div class="row row--no-gutters">
          <div class="col--sm-12">
            <treasury-evolution data-cy="treasury.evolution" />
          </div>
          <div class="col--sm-6">
            <treasury-flow-type :type="ETreasuryFlowOperationType.In"
                                data-cy="treasury.flow-in"
                                class="mr-1" />
          </div>
          <div class="col--sm-6">
            <treasury-flow-type :type="ETreasuryFlowOperationType.Out"
                                data-cy="treasury.flow-out"
                                class="ml-1" />
          </div>
          <div class="col--sm-12">
            <treasury-flow data-cy="treasury.flow-all" />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { CoreBlockSummary } from '@common/core-ui'
import dayjs from 'dayjs'

import { formatAmount } from '@/helpers/utils/number'
import { useTreasuryStore } from '@/stores/treasury'
import {
  ETreasuryFlowOperationType,
  ETreasuryPeriodFilter,
  ETreasuryResumeEvolutionType
} from '@/types/treasury.d'

import TreasuryEvolution from '@/pages/treasury/components/TreasuryEvolution.vue'
import TreasuryFlow from '@/pages/treasury/components/TreasuryFlow.vue'
import TreasuryFlowType from '@/pages/treasury/components/TreasuryFlowType.vue'

import ChartFilter from '@/components/charts/ChartFilter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcTreasuryResume from '@/components/svg/icons/ic-treasury-resume.vue'
import ImageComputerChart from '@/components/svg/img/computer-chart.vue'

export default {
  name: 'PageTreasury',
  components: {
    ChartFilter,
    ComponentPlaceholder,
    CoreBlockSummary,
    IcInfo,
    IcTreasuryResume,
    ImageComputerChart,
    LoaderSpinner,
    TreasuryEvolution,
    TreasuryFlow,
    TreasuryFlowType
  },

  setup () {
    const treasuryStore = useTreasuryStore()

    return { treasuryStore }
  },

  data () {
    return {
      ETreasuryFlowOperationType,
      ETreasuryResumeEvolutionType,
      evolution: [],
      flow: [],
      formFilter: {
        from: null,
        periodFilter: ETreasuryPeriodFilter.LastTwelveMonths,
        to: null
      },

      loading: true,
      loadingSummary: true,
      resume: null,
      showPlaceholder: false
    }
  },

  computed: {
    getFilter () {
      return [ETreasuryPeriodFilter.LastTwelveMonths, ETreasuryPeriodFilter.Year, ETreasuryPeriodFilter.Custom].map(name => {
        return { label: `treasury.filter.${name}`, name }
      })
    },

    resumeList () {
      const defaultAmount = formatAmount(0, null, { maximumFractionDigits: 0 })

      const list = []

      if (this.resume?.low_point?.date) {
        list.push({
          subtitle: this.$tc('treasury.resume.low_point', 0, { date: this.resume?.lowPointDateFormatted || dayjs(this.formFilter?.from).format('MMM YYYY') }),
          title: this.resume?.lowPointBalanceFormatted || defaultAmount
        })
      }

      if (this.resume?.high_point?.date) {
        list.push({
          subtitle: this.$tc('treasury.resume.high_point', 0, { date: this.resume?.highPointDateFormatted || dayjs(this.formFilter?.to).format('MMM YYYY') }),
          title: this.resume?.highPointBalanceFormatted || defaultAmount
        })
      }

      if (this.resume?.evolution?.percentage) {
        list.push({
          subtitle: this.$t('treasury.resume.evolution.title', { period: this.$t(`treasury.resume.evolution.period.${this.formFilter.periodFilter}`) }),
          title: this.resume.evolutionPercentFormatted,
          tooltip: this.$tc('treasury.resume.evolution.tooltip', 0, {
            date_end: this.resume.evolutionEndDateFormatted,
            date_start: this.resume.evolutionFirstDateFormatted
          }),
          type: this.resume.evolutionPercentType
        })
      }

      return list
    }
  },

  watch: {
    formFilter: {
      handler () {
        this.initData()
      },

      deep: true
    }
  },

  mounted () {
    this.initData(true)
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('language-changed', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('language-changed', this.initData)
  },

  methods: {
    async initData (onMounted = false) {
      if (onMounted) this.loading = true
      this.loadingSummary = true
      const params = {}

      if (this.formFilter.periodFilter === ETreasuryPeriodFilter.Custom) {
        params.from = dayjs(this.formFilter.from).format('YYYY-MM')
        params.to = dayjs(this.formFilter.to).format('YYYY-MM')
      } else {
        params.period_filter = this.formFilter.periodFilter
      }

      this.resume = await this.treasuryStore.getTreasuryResume(params)
      this.showPlaceholder = (this.formFilter.periodFilter === ETreasuryPeriodFilter.LastTwelveMonths && !this.resume)
      this.loading = false
      this.loadingSummary = false
    }
  }
}
</script>
