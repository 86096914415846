<template>
  <validation-form v-slot="{ errors, meta, isSubmitting }"
                   :validation-schema="schema"
                   :initial-values="initialValues"
                   class="sidepanel__content"
                   data-cy="cards.settings.smart-card.sidepanel.container"
                   @submit="onSubmit">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t(`cards_settings.smart_card.${option}.sidepanel.title`) }}
        </h2>
      </div>

      <div class="sidepanel-body">
        <p class="mb-5 text-muted">
          {{ $t(`cards_settings.smart_card.${option}.sidepanel.description`) }}
        </p>

        <div v-if="isOptionLink">
          <div class="form-group"
               :class="{ error: errors.link }">
            <input-link v-model="scopedSmartCardSettings.smart_card_link"
                        name="link"
                        :placeholder="$t('form.enter_link.placeholder')" />
          </div>
        </div>
        <div v-else-if="isOptionFile">
          <div class="form-group"
               :class="{ error: errors.file }">
            <validation-field :model-value="scopedSmartCardSettings.smart_card_file"
                              name="file">
              <dropzone :model-value="dropzoneFile"
                        mode="inline"
                        :max-files="1"
                        :max-filesize="24"
                        accepted-files="
                                    application/msword,
                                    application/pdf,
                                    application/vnd.ms-excel,
                                    application/vnd.ms-powerpoint,
                                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                    image/jpg,
                                    image/jpeg,
                                    image/gif,
                                    image/png,
                                    video/mp4,
                                    video/quicktime,
                                  "
                        @on-file-added="onDocumentAttached" />
              <component-info v-if="!scopedSmartCardSettings.smart_card_file"
                              class="mt-2">
                {{ $t('dropzone.max_size', { size: maxFileSize }) }}
              </component-info>
            </validation-field>
          </div>
        </div>

        <div v-else>
          <div class="mb-6">
            <user-profile-avatar :can-remove-picture="!!scopedSmartCardSettings.smart_card_vcard.picture"
                                 :circle-size="96"
                                 :picture="scopedSmartCardSettings.smart_card_vcard.picture"
                                 :user-full-name="vcardFullName"
                                 :update-picture="updateVCardPicture" />
          </div>

          <div class="mb-6">
            <group-title :title="$t('form.title.general_informations')" />

            <div class="form__row row">
              <div class="form-group col col--sm col--sm-6">
                <label class="label input__label">{{ $t('form.first_name.label') }}</label>
                <input v-model="scopedSmartCardSettings.smart_card_vcard.first_name"
                       :placeholder="$t('form.first_name.placeholder')"
                       class="form-control"
                       name="first_name"
                       type="text">
              </div>
              <div class="form-group col col--sm col--sm-6">
                <label class="label input__label">{{ $t('form.last_name.label') }}</label>
                <input v-model="scopedSmartCardSettings.smart_card_vcard.last_name"
                       :placeholder="$t('form.last_name.placeholder')"
                       class="form-control"
                       name="last_name"
                       type="text">
              </div>
            </div>

            <div class="form__row row">
              <div class="form-group col col--sm col--sm-6">
                <label class="label input__label">{{ $t('table.company') }}</label>
                <input v-model="scopedSmartCardSettings.smart_card_vcard.company_name"
                       :placeholder="$t('form.company.placeholder')"
                       class="form-control"
                       name="company_name"
                       type="text">
              </div>
              <div class="form-group col col--sm col--sm-6">
                <label class="label input__label">{{ $t('table.function') }}</label>
                <input v-model="scopedSmartCardSettings.smart_card_vcard.role"
                       :placeholder="$t('form.role.placeholder')"
                       class="form-control"
                       name="role"
                       type="text">
              </div>
            </div>
          </div>

          <div class="mb-6">
            <group-title :title="$t('footer.contact')" />

            <div class="form__row row">
              <div class="form-group col col--sm col--sm-6">
                <label class="label input__label">{{ $t('form.mobile_phone.label') }}</label>
                <phone-input v-model="scopedSmartCardSettings.smart_card_vcard.phone" />
              </div>

              <div class="form-group col col--sm col--sm-6">
                <label class="label input__label">{{ $t('form.secondary_phone.label') }}</label>
                <phone-input v-model="scopedSmartCardSettings.smart_card_vcard.phone2" />
              </div>
            </div>

            <div class="form__row row">
              <div class="form-group col col--sm col--sm-12"
                   :class="{ error: errors.email }">
                <label class="label input__label">{{ $t('form.email.label') }}</label>
                <validation-field v-model="scopedSmartCardSettings.smart_card_vcard.email"
                                  :placeholder="$t('form.email.placeholder')"
                                  class="form-control"
                                  name="email"
                                  type="text" />
              </div>
            </div>
          </div>

          <group-title :title="$t('form.title.social_media')" />

          <div class="form__row row">
            <div class="form-group col col--sm col--sm-6"
                 :class="{ error: errors.linkedin }">
              <label class="label input__label">{{ $t('form.linkedin.label') }}</label>
              <input-link v-model="scopedSmartCardSettings.smart_card_vcard.social_media.linkedin"
                          name="linkedin"
                          data-cy="cards.settings.smart-card.sidepanel.form.linkedin"
                          :placeholder="$t('form.linkedin.placeholder')">
                <template #icon>
                  <ic-linkedin class="ic ic--16 ic--gray-400" />
                </template>
              </input-link>
            </div>
            <div class="form-group col col--sm col--sm-6"
                 :class="{ error: errors.twitter }">
              <label class="label input__label">{{ $t('form.twitter.label') }}</label>
              <input-link v-model="scopedSmartCardSettings.smart_card_vcard.social_media.twitter"
                          name="twitter"
                          :placeholder="$t('form.twitter.placeholder')">
                <template #icon>
                  <ic-twitter class="ic ic--16 ic--gray-400" />
                </template>
              </input-link>
            </div>
          </div>

          <div class="form__row row">
            <div class="form-group col col--sm col--sm-12"
                 :class="{ error: errors.website }">
              <label class="label input__label">{{ $t('form.website.label') }}</label>
              <input-link v-model="scopedSmartCardSettings.smart_card_vcard.website"
                          name="website"
                          :placeholder="$t('form.website.placeholder')">
                <template #icon>
                  <ic-external class="ic ic--16 ic--gray-400" />
                </template>
              </input-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="buttons-wrapper buttons-wrapper--pull-right">
        <div class="btn-grouped">
          <button type="button"
                  class="btn btn--gray"
                  @click="appStore.closeSidePanel">
            {{ $t('button.cancel') }}
          </button>
          <button type="submit"
                  class="btn btn--primary"
                  data-cy="cards.settings.smart-card.sidepanel.submit"
                  :disabled="!meta.dirty || !meta.valid || isSubmitting">
            {{ $t('button.validate') }}
          </button>
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as yup from 'yup'

import { showToastSuccess } from '@/helpers/utils/notification'
import { formatFileSize } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import Dropzone from '@/components/Dropzone.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import ComponentInfo from '@/components/Info.vue'
import InputLink from '@/components/InputLink.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcExternal from '@/components/svg/icons/ic-external.vue'
import IcLinkedin from '@/components/svg/icons/ic-linkedin.vue'
import IcTwitter from '@/components/svg/icons/ic-twitter.vue'
import UserProfileAvatar from '@/components/UserProfileAvatar.vue'

export default {
  components: {
    IcLinkedin,
    InputLink,
    IcExternal,
    IcTwitter,
    ComponentInfo,
    Dropzone,
    ComponentSidepanelFooter,
    UserProfileAvatar,
    PhoneInput,
    GroupTitle
  },

  props: {
    cardId: {
      type: String,
      required: true
    },

    smartCardSettings: {
      type: Object,
      required: true
    },

    onUpdated: {
      type: Function,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()

    return { appStore, cardStore }
  },

  data () {
    return {
      scopedSmartCardSettings: cloneDeep(this.smartCardSettings),
      initialValues: {
        file: this.smartCardSettings.smart_card_file
      }
    }
  },

  computed: {
    maxFileSize () {
      return formatFileSize(24000000)
    },

    option () {
      return this.scopedSmartCardSettings.smart_card_option
    },

    isOptionLink () {
      return this.option === 'link'
    },

    isOptionFile () {
      return this.option === 'file'
    },

    previewUrlPrefix () {
      return this.scopedSmartCardSettings.smart_card_link.startsWith('http') ? '' : '//'
    },

    vcardFullName () {
      return `${this.scopedSmartCardSettings.smart_card_vcard.first_name || ''} ${this.scopedSmartCardSettings.smart_card_vcard.last_name || ''}`.trim()
    },

    dropzoneFile () {
      return this.scopedSmartCardSettings.smart_card_file ? [this.scopedSmartCardSettings.smart_card_file] : []
    },

    schema () {
      if (this.isOptionLink) {
        return yup.object({
          link: yup.string().required().validUrl()
        })
      }

      if (this.isOptionFile) {
        return yup.object({
          file: yup.mixed().required()
        })
      }

      return yup.object({
        email: yup.string().email(),
        linkedin: yup.string().validUrl().nullable(),
        twitter: yup.string().validUrl().nullable(),
        website: yup.string().validUrl().nullable()
      })
    }
  },

  methods: {
    /**
     * updateVCardPicture
     * @param picture Blob | null
     */
    async updateVCardPicture (picture) {
      const { success, data } = picture !== null
        ? await this.cardStore.postSmartCardPicture(this.cardId, picture)
        : await this.cardStore.deleteSmartCardPicture(this.cardId)
      if (success) {
        this.scopedSmartCardSettings.smart_card_vcard.picture = data
        const newSettings = cloneDeep(this.smartCardSettings)
        newSettings.smart_card_vcard.picture = data
        this.onUpdated(newSettings)
      }
      return success
    },

    onDocumentAttached (files) {
      this.documentAttached = true
      this.scopedSmartCardSettings.smart_card_file = files[0]
    },

    removeFile () {
      this.scopedSmartCardSettings.smart_card_file = null
    },

    async sendToApi () {
      if (this.isOptionFile && this.scopedSmartCardSettings.smart_card_file) {
        const response = await this.cardStore.updateSmartCardFile(this.cardId, this.scopedSmartCardSettings.smart_card_file)
        if (response) {
          showToastSuccess(this.$t('cards_settings.smart_card.success.file_uploaded'))
          return { ...this.scopedSmartCardSettings, smart_card_file: response }
        }
        return response ? { ...this.scopedSmartCardSettings, smart_card_file: response } : null
      }

      if (this.isOptionFile) {
        const success = await this.cardStore.deleteSmartCardFile(this.cardId)
        return success ? { ...this.scopedSmartCardSettings, smart_card_file: null } : null
      }

      const response = await this.cardStore.updateSmartCardSettings(this.cardId, this.scopedSmartCardSettings)

      if (response) {
        showToastSuccess(this.smartCardSettings.smart_card_option === 'vcard' ? this.$t('cards_settings.smart_card.success.vcard_saved') : this.$t('cards_settings.smart_card.success.url_saved'))
      }

      return response
    },

    async onSubmit () {
      const newSettings = await this.sendToApi()
      if (newSettings) {
        this.onUpdated(newSettings)
        this.appStore.closeSidePanel()
      }
    }
  }
}
</script>
