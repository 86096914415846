<template>
  <modal-dialog-placeholder data-cy="cards.details.invalid-pin.modal.container"
                            @close="emit('close')">
    <component-placeholder data-cy="cards.details.invalid-pin.modal.submit"
                           :icon-width="160"
                           :icon-height="128"
                           :button="t('button.ok_understood')"
                           :label="t('message.success.card.order_pin_about')"
                           :content="accountStore.cardPinMailer ? t('message.success.card.order_pin_message') : t('message.success.card.no_pin_mailer.order_pin_message')"
                           @btn-click="emit('close')">
      <card info />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useAccountStore } from '@/stores/account'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import Card from '@/components/svg/img/card.vue'

const { t } = useI18n()
const accountStore = useAccountStore()

const emit = defineEmits<{
  'close': []
}>()
</script>
