<template>
  <section class="section section-operations">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'operations' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.operations_claimed') }}
        </h1>
      </div>
    </div>
    <router-view />
  </section>
</template>

<script>
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow
  }
}
</script>
