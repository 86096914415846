<template>
  <svg viewBox="0 0 200 160">
    <path d="M68.49 41.66h60.94v1.5H68.49v-1.5Z"
          :fill="colorPrimary" />
    <path d="M122.71 129.75H72.56a5.39 5.39 0 0 1-5.31-5.46V37.71a5.39 5.39 0 0 1 5.31-5.46h52.88a5.39 5.39 0 0 1 5.31 5.46v83.76c0 .194-.075.38-.21.52l-7.29 7.53a.748.748 0 0 1-.54.23Zm-50.15-96a3.89 3.89 0 0 0-3.81 4v86.59a3.89 3.89 0 0 0 3.81 4H122v-1.22a6.32 6.32 0 0 1 6.22-6.4h1.03V37.71a3.889 3.889 0 0 0-3.81-4l-52.88.04Zm55.76 88.47h-.1a4.82 4.82 0 0 0-4.72 4.9l-.002.077 4.822-4.977Z"
          :fill="colorDark" />
    <path fill-rule="evenodd"
          d="M74.5 55H99v1.5H74.5V55Zm0 6h34v1.5h-34V61Zm47.5 9.51v6.92h-10.4v-6.92H122Zm-22.25 0h10.35v6.92H99.75v-6.92Zm-1.5 0v6.92H87.9v-6.92h10.35Zm0 8.42H87.9v6.97h10.35v-6.97Zm0 8.47H87.9v6.97h10.35V87.4Zm1.5 6.97V87.4h10.35v6.97H99.75Zm-1.5 1.5H87.9V103h10.35v-7.13Zm1.5 7.13v-7.13h10.35V103H99.75Zm0-17.1v-6.97h10.35v6.97H99.75ZM76 70.51h10.4v6.92H76v-6.92Zm35.6 8.42H122v6.97h-10.4v-6.97Zm0 8.47H122v6.97h-10.4V87.4Zm0 8.47H122V103h-10.4v-7.13Zm-25.2 0V103H76v-7.13h10.4Zm0-1.5H76V87.4h10.4v6.97Zm0-8.47H76v-6.97h10.4v6.97Zm-11.15 18.6h47.5a.75.75 0 0 0 .75-.75v-34a.75.75 0 0 0-.75-.75h-47.5a.75.75 0 0 0-.75.75v34c0 .414.336.75.75.75Z"
          :fill="colorGrey" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
