<template>
  <validation-form v-slot="{ errors, isSubmitting }"
                   ref="form"
                   :validation-schema="schema"
                   class="sidepanel__content"
                   @invalid-submit="formSubmitted = true"
                   @submit="onSubmit">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h1 class="modal-title">
          {{ $t('sidepanel.password_editing.title') }}
        </h1>
      </div>
      <div class="sidepanel-body">
        <div class="form__row row row--sm-bottom">
          <div class="form-group col"
               :class="{ error: errors.current_password && formSubmitted }">
            <label class="label input__label"
                   for="current_password">{{ $t("form.current_password.label") }}</label>
            <validation-field id="current_password"
                              v-model="current_password"
                              v-tooltip.bottom-start="{ content: errors.current_password, theme: 'tooltip' }"
                              type="password"
                              name="current_password"
                              class="form-control"
                              :placeholder="$t('form.current_password.placeholder')" />
          </div>
          <div class="form-group col"
               :class="{ error: (formSubmitted && (errors.password || errors.password_repeat)), 'is-valid': !errors.password_repeat && password_repeat !== '' }">
            <label class="label input__label"
                   for="password">{{ $t("form.new_password.label") }}</label>
            <validation-field v-slot="{ validate, field }"
                              v-model="password"
                              name="password">
              <input id="password"
                     v-bind="field"
                     type="password"
                     class="form-control"
                     :placeholder="$t('form.new_password.placeholder')"
                     @focus="togglePasswordMeter(true)"
                     @blur="togglePasswordMeter(false)"
                     @input="validate">
            </validation-field>
            <div v-if="showPasswordMeter"
                 class="pwd-strength-meter pwd-strength-meter--dropdown">
              <template v-if="password.length === 0">
                {{ $t('form.password.helper') }}
              </template>
              <template v-else-if="!errors.password">
                {{ $t('form.password.security.label') }} <b>{{ passwordStrength[getPasswordScore(password)] }}</b>
                <span class="pwd-strength-meter__gauge"
                      :data-level="getPasswordScore(password)" />
              </template>
              <template v-else>
                {{ $t('form.password.security.label') }} <b>{{ $t('form.password.security.poor') }}</b> - {{ $t('form.password.helper_short') }}
                <span class="pwd-strength-meter__gauge"
                      :data-level="1" />
              </template>
            </div>
          </div>
          <div class="form-group col"
               :class="{ error: formSubmitted && errors.password_repeat, 'is-valid': !errors.password_repeat && password_repeat !== '' }">
            <label class="label input__label"
                   for="password_repeat">{{ $t("form.confirm_password.label") }}</label>
            <validation-field v-slot="{ validate, field }"
                              v-model="password_repeat"
                              name="password_repeat">
              <input id="password_repeat"
                     v-bind="field"
                     v-tooltip.bottom-start="{ content: errors.password_repeat ? $i18n.t('form.confirm_password.error') : false, theme: 'tooltip' }"
                     type="password"
                     class="form-control"
                     :placeholder="$t('form.confirm_password.placeholder')"
                     name="password_repeat"
                     :disabled="errors.password"
                     @input="validate">
            </validation-field>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="buttons-wrapper buttons-wrapper--pull-right">
        <div class="btn-grouped">
          <button type="button"
                  class="btn btn--gray"
                  @click="onCloseSidePanel">
            {{ $t('button.cancel') }}
          </button>
          <component-button type="submit"
                            :disabled="isSubmitting"
                            class="btn btn--primary"
                            :label="$t('button.edit')" />
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import { zxcvbn } from '@zxcvbn-ts/core'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import store from '@/config/store'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import ComponentButton from '@/components/Button.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    ComponentButton,
    ComponentSidepanelFooter
  },

  setup () {
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { error: apiError } = storeToRefs(apiStore)
    return {
      appStore,
      authStore,

      apiError
    }
  },

  data () {
    return {
      store,
      showPasswordMeter: false,
      current_password: '',
      password: '',
      password_repeat: '',
      score: 0,
      passwordStrength: [
        this.$i18n.t('form.password.security.poor'),
        this.$i18n.t('form.password.security.weak'),
        this.$i18n.t('form.password.security.medium'),
        this.$i18n.t('form.password.security.good'),
        this.$i18n.t('form.password.security.high')
      ],

      formSubmitted: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        current_password: yup.string().required(),
        password: yup.string().matches(/\d/).min(8)
          .test(value => this.getPasswordScore(value) >= 1)
          .required(),
        password_repeat: yup.string()
          .oneOf([yup.ref('password'), null], 'Passwords must match').required()
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  methods: {
    async onSubmit () {
      this.formSubmitted = true
      const success = await this.authStore.updatePassword(this.current_password, this.password, this.password_repeat)
      if (success) this.onCloseSidePanel()
    },

    getPasswordScore (password) {
      return zxcvbn(password).score
    },

    onCloseSidePanel () {
      this.appStore.closeSidePanel()
    },

    togglePasswordMeter (value) {
      this.showPasswordMeter = value
    }
  }
}
</script>
