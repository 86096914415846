<template>
  <svg viewBox="0 0 24 24">
    <path d="M9 4v1h6V4h2v1h1.006C19.107 5 20 5.893 20 6.992v11.016c0 1.1-.895 1.992-1.994 1.992H5.994A1.994 1.994 0 0 1 4 18.008V6.992C4 5.892 4.895 5 5.994 5H7V4zm9 7H6v7h12zm0-4H6v2h12z" />
  </svg>
</template>

<script>
export default {
}
</script>
