<template>
  <section class="section section-account">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t("title.services") }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <div class="form-group">
            <component-dropdown label="accounts"
                                :dropdown-width="24"
                                :search="true"
                                async-url="/accounts"
                                :async-url-params="{ access: 'owner', status_group: 'valid' }"
                                :label-filter="getAccountLabel"
                                :model="account.uuid"
                                @select="onSwitchAccount">
              <template #trigger>
                <div class="select select-account">
                  <div class="form-control">
                    {{ getAccountLabel(account) }}
                  </div>
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <template v-else-if="services.length">
        <p>
          {{ $t('services.subtitle') }}
        </p>
        <div class="services-list">
          <div v-for="service in services"
               :key="service.client_id"
               class="card-block is-link"
               @click="serviceLogs(service.client_id)">
            <VTooltip theme="poptip"
                      class="card-block__info">
              <template #popper>
                <poptip :title="$t('popover.services.title')"
                        :message="tooltipMessage(service.access)" />
              </template>
              <ic-info class="ic ic--gray" />
            </VTooltip>
            <div class="card-block__content">
              <div class="avatar"
                   :style="{ 'background-image': getIcon(service) }" />
              <h4 class="title">
                {{ service.name }}
              </h4>
              <div class="subtitle">
                {{ $t('general.added_on') }} {{ formatDate(service.created_at) }}
              </div>
            </div>
            <button class="btn btn--sm btn--outline btn--block btn--cancel"
                    @click.stop="serviceStore.revokeService(service.client_id)">
              {{ $t('button.revoke') }}
            </button>
          </div>
        </div>
      </template>
      <component-placeholder v-else
                             :label="$t('placeholder.no_services.title')"
                             :content="$t('placeholder.no_services.message')">
        <image-app-service />
      </component-placeholder>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatDate } from '@/helpers/utils/date'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useServiceStore } from '@/stores/service'

import ServiceActivity from '@/pages/account/sidepanel/ServiceActivity.vue'

import ComponentDropdown from '@/components/Dropdown.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import Poptip from '@/components/Poptip.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ImageAppService from '@/components/svg/img/app-services.vue'

export default {
  components: {
    IcInfo,
    IcArrow,
    ComponentDropdown,
    ComponentPlaceholder,
    LoaderSpinner,
    Poptip,
    ImageAppService
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const serviceStore = useServiceStore()

    const { account } = storeToRefs(accountStore)
    const { services } = storeToRefs(serviceStore)

    return { account, accountStore, appStore, services, serviceStore }
  },

  data () {
    return {
      loading: true
    }
  },

  created () {
    this.accountStore.getAccounts()
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    formatDate,

    async initData () {
      this.loading = true
      await this.serviceStore.getServicesList()
      this.loading = false
    },

    onSwitchAccount (account) {
      this.accountStore.switchAccount(account.uuid)
    },

    getIcon (service) {
      return service.logo.url ? `url(${service.logo.url})` : '@/assets/img/ic-service-default.png'
    },

    tooltipMessage (access) {
      const rows = []
      for (const key in access) {
        if (access[key]) {
          rows.push(this.$i18n.t(`popover.services.${key}`))
        }
      }
      return rows.join('<br>')
    },

    serviceLogs (serviceId) {
      this.appStore.showSidePanel(ServiceActivity, { serviceId }, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true, showLoader: true })
    },

    getAccountLabel (account) {
      return account.label ? `${account.company.name} - ${account.label}` : account.company.name
    }
  }
}
</script>
