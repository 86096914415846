<template>
  <validation-form ref="form"
                   v-slot="{ errors, isSubmitting, meta }"
                   class="form form-newuser"
                   :validation-schema="schema"
                   @keydown.enter.prevent
                   @submit="onAcceptInvitation">
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('form.title.personal_informations')"
                      show-number />
      <div class="form__row row">
        <div class="form-group col col--sm"
             :class="{ error: errors.first_name }">
          <validation-field v-model="user.first_name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('form.first_name.placeholder')"
                            name="first_name"
                            :disabled="true" />
        </div>
        <div class="form-group col col--sm"
             :class="{ error: errors.last_name }">
          <validation-field v-model="user.last_name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('form.last_name.placeholder')"
                            name="last_name"
                            :disabled="true" />
        </div>
      </div>
      <div class="form__row row">
        <div class="form-group col col--sm"
             :class="{ error: errors.phone }">
          <phone-input id="phone"
                       v-model="user.phone"
                       :placeholder="$t('form.phone.placeholder')"
                       name="phone" />
        </div>
      </div>
    </fieldset>
    <fieldset v-if="isIdentityProofRequired"
              class="form__fieldset">
      <fieldset-title :title="$t('form.title.proof_identity')"
                      show-number />
      <div class="form__row row">
        <div class="col col--sm">
          <validation-field name="files"
                            :model-value="user.files">
            <dropzone v-model="user.files"
                      mode="inline"
                      :max-files="3"
                      :max-filesize="25"
                      accepted-files="image/jpg,image/jpeg,image/png,image/tif,image/tiff,image/webp,image/heic,application/pdf"
                      @on-file-added="onFileAdded" />
          </validation-field>
        </div>
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('form.title.email_password')"
                      show-number />
      <div class="form__row row">
        <div class="form-group col col--sm"
             :class="{ error: errors.email }">
          <validation-field v-model="user.email"
                            type="text"
                            class="form-control"
                            :placeholder="$t('form.email.placeholder')"
                            name="email" />
        </div>
      </div>
      <div class="form__row row">
        <div class="form-group col col--sm"
             :class="{ error: errors.password, 'is-valid': passwordMatch && passwordIsValid }">
          <input-password v-model="user.password"
                          :placeholder="$t('form.password.placeholder')"
                          :password-visibility-toggle="true"
                          name="password" />
        </div>
      </div>
      <div class="form__row row">
        <div class="form-group col col--sm"
             :class="{ error: errors.password_repeat, 'is-valid': passwordMatch }">
          <input-password v-model="user.password_repeat"
                          :placeholder="$t('form.confirm_password.placeholder')"
                          name="password_repeat"
                          :password-visibility-toggle="true"
                          :password-meter="false" />
        </div>
      </div>
    </fieldset>
    <div class="form__checkbox">
      <label class="checkbox checkbox--start">
        <validation-field v-model="terms"
                          :value="true"
                          type="checkbox"
                          name="terms" />
        <span class="checkbox__icon" />
        <i18n-t class="text-muted"
                tag="span"
                keypath="form.checkbox_invitation_terms.label"
                scope="global">
          <template #terms>
            <a href="#"
               @click.prevent.stop="onOpenIframe(links.COMMERCIAL.TERMS)">{{ $t("button.terms") }}</a>
          </template>
          <template #legal_notices>
            <a href="#"
               @click.prevent.stop="onOpenIframe(links.COMMERCIAL.LEGAL_NOTICES)">{{ $t("button.legal_notices") }}</a>
          </template>
          <template #privacy_policy>
            <a href="#"
               @click.prevent.stop="onOpenIframe(links.COMMERCIAL.PRIVACY_POLICY)">{{ $t("button.privacy_policy") }}</a>
          </template>
        </i18n-t>
      </label>
    </div>
    <div class="form__checkbox">
      <label class="checkbox">
        <validation-field v-model="engagement"
                          :value="true"
                          type="checkbox"
                          name="engagement" />
        <span class="checkbox__icon" />
        <span class="text-muted">
          {{ $t('form.checkbox_invitation_engagement.label') }}
        </span>
      </label>
    </div>
    <div class="form-actions">
      <button type="submit"
              class="btn btn--block btn--primary"
              :disabled="isSubmitting || isSubmitButtonDisabled || !meta.valid">
        {{ $t('button.create_user_account') }}
      </button>
    </div>
    <div class="center">
      <router-link type="button"
                   class="btn-link text-muted strong"
                   :to="{ name: 'invitation-existing-account', query: { token } }">
        {{ $t('button.existing_user_account') }}
      </router-link>
    </div>
  </validation-form>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import { checkPasswordValid } from '@/helpers/utils/text'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import Dropzone from '@/components/Dropzone.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import InputPassword from '@/components/InputPassword.vue'
import ComponentIframe from '@/components/modals/Iframe.vue'
import PhoneInput from '@/components/PhoneInput.vue'

export default {
  components: {
    Dropzone,
    InputPassword,
    PhoneInput,
    FieldsetTitle
  },

  props: {
    token: {
      type: String,
      required: true
    },

    decodedToken: {
      type: Object,
      required: true
    }
  },

  emits: ['success'],

  setup () {
    const apiStore = useApiStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const { error: apiError } = storeToRefs(apiStore)

    return { authStore, apiError, appStore }
  },

  data () {
    return {
      user: {
        token: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        password_repeat: '',
        files: []
      },

      showPasswordError: false,
      formSubmitted: false,
      terms: false,
      engagement: false,
      links
    }
  },

  computed: {
    passwordIsValid () {
      return checkPasswordValid(this.user.password)
    },

    passwordMatch () {
      return this.user.password && this.user.password === this.user.password_repeat
    },

    isSubmitButtonDisabled () {
      return !(this.passwordIsValid)
    },

    isIdentityProofRequired () {
      return hasFeature(EFeature.UserIdentityProofRequired)
    },

    schema () {
      return yup.object({
        email: yup.string().required().email(),
        engagement: yup.boolean().required().oneOf([true]),
        files: this.isIdentityProofRequired ? yup.array().required().min(1).max(3) : null,
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        password: yup.string().required(),
        password_repeat: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
        phone: yup.string().validPhoneNumber().nullable(),
        terms: yup.boolean().required().oneOf([true])
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    this.user.token = this.token
    this.user.first_name = this.decodedToken.fna
    this.user.last_name = this.decodedToken.lna
    this.user.phone = this.decodedToken.pho
    this.user.email = this.decodedToken.ema
  },

  methods: {
    async onAcceptInvitation () {
      this.user.email_repeat = this.user.email

      const success = await this.authStore.saveUser(cloneDeep(this.user))
      if (success) {
        this.$emit('success')
      }
    },

    onFileAdded (files) {
      this.user.files = [...files]
    },

    onOpenIframe (source) {
      this.appStore.showModal(
        ComponentIframe,
        { source },
        { wrapperClass: 'modal--iframe modal--fullscreen modal--white' }
      )
    }
  }
}
</script>
