<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 2a4.25 4.25 0 0 1 4.184 3.5H20.5a1 1 0 0 1 1 1V7h-2.322l-1.342 13.424a1.75 1.75 0 0 1-1.593 1.57l-.148.006h-8.19a1.75 1.75 0 0 1-1.741-1.576L4.82 7H2.5v-.5a1 1 0 0 1 1-1h4.316A4.251 4.251 0 0 1 12 2zm5.67 5H6.329l1.327 13.275a.25.25 0 0 0 .187.217l.062.008h8.19a.25.25 0 0 0 .249-.225zm-7.17 3a.75.75 0 0 1 .743.648l.007.102v6a.75.75 0 0 1-1.493.102l-.007-.102v-6a.75.75 0 0 1 .75-.75zm3 0a.75.75 0 0 1 .743.648l.007.102v6a.75.75 0 0 1-1.493.102l-.007-.102v-6a.75.75 0 0 1 .75-.75zM12 3.5a2.75 2.75 0 0 0-2.646 2h5.293A2.751 2.751 0 0 0 12 3.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
