<template>
  <span class="user-access">
    <span v-if="isOwner || isOwnerMain"
          v-tooltip="{ content: $t(`user.role.${isOwnerMain ? 'owner' : 'full_access'}.tooltip`) }"
          class="ic-access">
      <ic-check-circle outline
                       class="ic ic--20 ic--gray" />
      <span class="text-muted strong">{{ $t(`user.role.${isOwnerMain ? 'owner' : 'full_access'}.title`) }}</span>
    </span>

    <span v-else-if="!hasAccess"
          class="ic-access label-empty">
      <ic-block class="ic ic-block ic--20 ic--gray" />
      <span class="text-muted strong">{{ $t('placeholder.no_rights.title') }}</span>
    </span>

    <span v-for="(access, index) in accessList"
          v-else
          :key="index"
          v-tooltip="{ content: access.tooltipMessage, theme: 'tooltip' }"
          class="ic-access">
      <ic-section v-bind="{ [access.icon]: true }"
                  class="ic ic-section"
                  :class="{ active: hasActiveAccess(access) }" />
    </span>
  </span>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'

import IcBlock from '@/components/svg/icons/ic-block.vue'
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcSection from '@/components/svg/icons/ic-section.vue'

export default {
  components: { IcCheckCircle, IcBlock, IcSection },

  props: {
    invitation: {
      type: Object,
      required: true
    },

    invitationActive: {
      type: Boolean,
      default: false
    },

    isImportMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    isEmployee () {
      return ['salarie', 'associe', 'employee', 'associate'].includes(this.invitation.role)
    },

    hasAccess () {
      return this.accessList.filter(item => item.active).length
    },

    isOwner () {
      return this.invitation?.permissions?.includes('account.owner')
    },

    isOwnerMain () {
      return this.invitation?.permissions?.includes('account.owner.main')
    },

    accessList () {
      return [
        {
          active: this.invitation?.access?.operations,
          tooltipMessage: this.$i18n.t('menu.operations'),
          icon: 'synthese',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionOperation)
        },
        {
          active: this.invitation?.access?.transfers,
          tooltipMessage: this.$i18n.t('menu.transfers'),
          icon: 'transfers',
          visible: hasFeature(EFeature.SectionTransfer)
        },
        {
          active: this.invitation?.access?.debits,
          tooltipMessage: this.$i18n.t('menu.debits'),
          icon: 'debits',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionDebit)
        },
        {
          active: this.invitation?.access?.credits,
          tooltipMessage: this.$i18n.t('menu.credits'),
          icon: 'credits',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionCredit)
        },
        {
          active: this.invitation?.access?.statements,
          tooltipMessage: this.$i18n.t('menu.statements'),
          icon: 'statements',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionStatement)
        },
        {
          active: this.invitation?.access?.cards,
          tooltipMessage: this.$i18n.t('menu.cards'),
          icon: 'cards',
          visible: hasFeature(EFeature.SectionCard)
        },
        {
          active: !this.invitation?.access?.cards && this.invitation?.card_settings?.virtual?.request,
          tooltipMessage: this.$i18n.t('generate_ecard.title_request'),
          icon: 'virtualcards',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionCardVirtual)
        },
        {
          active: this.invitation?.access?.refunds,
          tooltipMessage: this.isEmployee ? this.$i18n.t('menu.refunds') : this.$i18n.t('menu.payments'),
          icon: 'refunds',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionRefund)
        },
        {
          active: this.invitation?.access?.mileages,
          tooltipMessage: this.$i18n.t('menu.mileages'),
          icon: 'mileages',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionMileage)
        },
        {
          active: this.invitation?.access?.card_holder,
          tooltipMessage: this.$i18n.t('tooltip.card_holder'),
          icon: 'cardholder',
          visible: hasFeature(EFeature.SectionCard)
        },
        {
          active: this.invitation?.access?.accounting,
          tooltipMessage: this.$i18n.t('menu.accounting'),
          icon: 'accounting',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionAccounting)
        },
        {
          active: this.invitation?.access?.owner,
          tooltipMessage: this.$i18n.t('menu.users'),
          icon: 'users',
          visible: hasFeature(EFeature.SectionUser)
        },
        {
          active: this.invitation?.access?.treasury,
          tooltipMessage: this.$i18n.t('menu.treasury'),
          icon: 'treasury',
          showOnImport: true,
          visible: hasFeature(EFeature.SectionTreasury)
        }
      ].filter(access => access.visible && (this.isImportMode ? access.showOnImport : access.active))
    }
  },

  methods: {
    hasActiveAccess (access) {
      return this.isImportMode ? access.active : this.invitationActive
    }
  }

}
</script>

<style lang="stylus">

.user-access
  .ic-access
    padding 0.5rem

    .ic-block
    .ic-check-circle
      margin-right 0.6rem

  .text-muted
    vertical-align middle

  .ic-section
    width 22px
    height @width
    opacity 0.3

    &.active
      opacity .9
</style>
