<template>
  <section class="section section-account-followup">
    <div class="section__heading">
      <h1 class="section-title">
        {{ $t('followup.title') }}
      </h1>
    </div>
    <p class="section-subtitle text-muted">
      {{ $t('followup.subtitle') }}
    </p>
    <div v-if="!loading && accountValidation.updated_at"
         class="center mt-1">
      <core-badge :theme="ECoreBadgeTheme.Info"
                  :fill="ECoreBadgeFill.Shaded"
                  :size="ECoreBadgeSize.Large"
                  :value="`${$t('followup.update')}  ${formatDate(accountValidation.updated_at)}`" />
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <div v-else-if="isOpeningRejected"
           class="account-reject">
        <ic-reject class="account-reject__icon" />
        <h3 class="account-reject__title">
          {{ $t('followup.reject.title') }}
        </h3>
        <p class="account-reject__subtitle">
          {{ $t('followup.reject.subtitle') }}
        </p>
        <div class="account-reject__info">
          <ic-info class="ic ic--info" />
          <span>{{ $t('followup.reject.info') }}</span>
        </div>
      </div>
      <div v-else
           class="account-followup">
        <template v-if="!hasCapitalDeposit || !accountValidation.attestation_sent_at">
          <div v-if="allDocuments.transfers.length"
               class="account-followup__block"
               :style="getBlockOrder">
            <div class="account-followup__block__header"
                 @click="toggleBody">
              <ic-square depot-capital
                         class="account-followup__block__icon" />
              <div>
                <h3 class="account-followup__block__title">
                  {{ $t(`followup.transfers.${hasCapitalDeposit ? 'capital' : 'funds'}.title`) }}
                </h3>
                <p class="account-followup__block__details text-muted">
                  {{ $tc('followup.transfers.validated_transfers', allDocuments.transfers.length) }} : <strong><span>{{ numberValidatedTransfers }}</span> / {{ allDocuments.transfers.length }}</strong>
                </p>
              </div>
              <component :is="statusTransfers.icon"
                         class="account-followup__block__state"
                         :class="statusTransfers.class" />
              <ic-chevron down
                          class="ic ic--20 ic--gray-400 account-followup__block__header__arrow" />
            </div>
            <div class="account-followup__block__body"
                 :class="{ 'is-open': statusTransfers.class !== 'state-valid' }">
              <p class="account-followup__block__body__text">
                {{ depositSubtitle }}
                <a href="#"
                   class="dropdown-menu__item"
                   @click.prevent.stop="downloadFileFromUrl(account.rib.pdf_url)">
                  {{ $t("button.download_rib") }}
                </a>
              </p>
              <template v-if="incomingTransfers.length">
                <h3 class="head-title">
                  {{ $t('followup.received_transfers_details') }}
                </h3>
                <div class="account-followup__block__body__container">
                  <div v-for="(transfer, index) in incomingTransfers"
                       :key="index"
                       class="account-followup__block__body__item account-followup__block__body__item--compact">
                    <div class="account-followup__block__body__item__content"
                         style="display: flex; justify-content: space-between;">
                      <span>{{ transfer.sender }}</span>
                      <span class="text-muted">{{ $t('general.received_the') }} {{ formatDate(transfer.settlement_date) }}</span>
                      <span class="account-followup__block__body__item__title">{{ formatAmount(transfer.amount, account.currency) }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <h3 class="head-title"
                  style="margin-top: 4rem">
                {{ $t('followup.capital_details') }}
              </h3>
              <div class="account-followup__block__body__container">
                <div v-for="(transfer, index) in allDocuments.transfers"
                     :key="index"
                     class="account-followup__block__body__item">
                  <div class="account-followup__block__body__item__state"
                       :class="itemClass(transfer)">
                    <component :is="itemIcon(transfer)"
                               class="ic--20" />
                    <p>{{ itemLabel(transfer, 'transfer') }}</p>
                  </div>
                  <div class="account-followup__block__body__item__content">
                    <h4 v-if="transfer.representative"
                        class="account-followup__block__body__item__title">
                      {{ transfer.representative.name }}
                    </h4>
                    <h4 v-else
                        class="account-followup__block__body__item__title">
                      {{ $t('general.transfer') }} {{ index + 1 }}
                    </h4>
                    <p class="account-followup__block__body__item__desc text-muted">
                      {{ transferSubtitle(transfer) }}
                    </p>
                  </div>
                  <div v-if="transfer.representative"
                       class="account-followup__block__body__item-right">
                    <span>{{ hasCapitalDeposit ? $t('table.amount') : $t('table.minimum_amount') }} : <strong>{{ formatAmount(transfer.representative.transferAmount, account.currency) }}</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="allDocuments.proofs.length"
               class="account-followup__block">
            <div class="account-followup__block__header"
                 @click="toggleBody">
              <ic-square documents
                         class="account-followup__block__icon" />
              <div>
                <h3 class="account-followup__block__title">
                  {{ $t('followup.proofs.title') }}
                </h3>
                <p class="account-followup__block__details text-muted">
                  {{ $t('followup.proofs.validated_documents') }} : <strong>{{ numberValidatedProofs }} / {{ allDocuments.proofs.length }}</strong>
                </p>
              </div>
              <component :is="statusProof.icon"
                         class="account-followup__block__state"
                         :class="statusProof.class" />
              <ic-chevron down
                          class="ic ic--20 ic--gray-400 account-followup__block__header__arrow" />
            </div>
            <div class="account-followup__block__body"
                 :class="{ 'is-open': statusTransfers.class === 'state-valid' }">
              <p v-if="allDocuments.proofs.some(p => isItemRejected(p))"
                 class="account-followup__block__body__text">
                {{ $t('followup.proofs.subtitle_error') }}
              </p>
              <div class="account-followup__block__body__container">
                <div v-for="(proof, index) in allDocuments.proofs"
                     :key="index"
                     class="account-followup__block__body__item">
                  <div class="account-followup__block__body__item__state"
                       :class="itemClass(proof)">
                    <component :is="itemIcon(proof)"
                               class="ic--20" />
                    <p>{{ itemLabel(proof, 'document') }}</p>
                  </div>
                  <div class="account-followup__block__body__item__content">
                    <h4 class="account-followup__block__body__item__title">
                      <template v-if="proof.rule === 'custom_document_proof'">
                        {{ proof.name }}
                      </template>
                      <template v-else>
                        {{ $t(`followup.proofs.label.${proof.rule}`, { name: proof.name }) }}
                      </template>
                    </h4>
                    <p v-if="proof.description"
                       class="account-followup__block__body__item__desc text-muted">
                      {{ proof.description }}
                    </p>
                    <p class="account-followup__block__body__item__desc text-muted">
                      {{ proofSubtitle(proof) }}
                    </p>
                    <a v-if="getMoreLink(proof)"
                       :href="getMoreLink(proof)"
                       target="_blank">{{ $t('message.info.cookie.more') }}</a>
                  </div>
                  <div class="account-followup__block__body__item-right">
                    <button v-if="isItemSent(proof)"
                            v-tooltip="{ content: $tc('tooltip.show_file', proof.documents.length || 0), theme: 'tooltip' }"
                            class="btn btn--outline btn-attachment"
                            @click.prevent.stop="downloadFile(proof)">
                      <ic-document class="ic ic--gray" />
                    </button>
                    <button v-if="!isItemValidated(proof)"
                            v-tooltip="{ content: $t('tooltip.attach_file'), theme: 'tooltip' }"
                            class="btn btn--outline btn-attachment"
                            @click.prevent.stop="joinDocument(proof)">
                      <ic-attachment class="ic ic--gray" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="allDocuments.representatives.length && !account.company.is_ae_ei"
               class="account-followup__block">
            <div class="account-followup__block__header"
                 @click="toggleBody">
              <ic-square documents
                         class="account-followup__block__icon" />
              <div>
                <h3 class="account-followup__block__title">
                  {{ $t('followup.proofs.shareholders') }}
                </h3>
                <p class="account-followup__block__details text-muted">
                  {{ $t('followup.proofs.validated_documents') }} :
                  <strong>{{ numberValidatedRepresentatives }} / {{ allDocuments.representatives.length }}</strong>
                </p>
              </div>
              <component :is="statusRepresentatives.icon"
                         class="account-followup__block__state"
                         :class="statusRepresentatives.class" />
              <ic-chevron down
                          class="ic ic--20 ic--gray-400 account-followup__block__header__arrow" />
            </div>
            <div class="account-followup__block__body"
                 :class="{ 'is-open': statusRepresentatives.class === 'state-valid' }">
              <p v-if="allDocuments.representatives.some(representative => isItemRejected(representative))"
                 class="account-followup__block__body__text">
                {{ $t('followup.proofs.subtitle_error') }}
              </p>
              <div class="account-followup__block__body__container">
                <div v-for="(representative, index) in allDocuments.representatives"
                     :key="index"
                     class="account-followup__block__body__item">
                  <div class="account-followup__block__body__item__state"
                       :class="itemClass(representative)">
                    <component :is="itemIcon(representative)"
                               class="ic--20" />
                    <p>{{ itemLabel(representative, 'document') }}</p>
                  </div>
                  <div class="account-followup__block__body__item__content">
                    <h4 class="account-followup__block__body__item__title">
                      <template v-if="representative.rule === 'custom_document_proof'">
                        {{ representative.name }}
                      </template>
                      <template v-else>
                        {{ $t(`followup.proofs.label.${representative.rule}`, { name: representative.name }) }}
                      </template>
                    </h4>
                    <p v-if="representative.description"
                       class="account-followup__block__body__item__desc text-muted">
                      {{ representative.description }}
                    </p>
                    <p class="account-followup__block__body__item__desc text-muted">
                      {{ proofSubtitle(representative) }}
                    </p>
                  </div>
                  <div class="account-followup__block__body__item-right">
                    <button v-if="representative.document"
                            v-tooltip="{ content: $t('tooltip.download'), theme: 'tooltip' }"
                            class="btn btn--outline btn-attachment"
                            @click.prevent.stop="downloadFile(representative)">
                      <ic-document class="ic ic--gray" />
                    </button>
                    <button v-if="!isItemValidated(representative)"
                            v-tooltip="{ content: $t('tooltip.attach_file'), theme: 'tooltip' }"
                            class="btn btn--outline btn-attachment"
                            @click.prevent.stop="joinDocument(representative)">
                      <ic-attachment class="ic ic--gray" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="hasCapitalDeposit && accountValidation.attestation_sent_at">
          <div class="account-followup__block">
            <div class="account-followup__block__header"
                 @click="toggleBody">
              <ic-square depot-capital-certificate
                         class="account-followup__block__icon" />
              <div>
                <h3 class="account-followup__block__title">
                  {{ $t('followup.capital_deposit.title') }}
                </h3>
                <p class="account-followup__block__details text-muted">
                  {{ $t('followup.capital_deposit.subtitle') }}
                </p>
              </div>
              <ic-valid class="ic account-followup__block__state state-valid" />
              <ic-chevron down
                          class="ic ic--20 ic--gray-400 account-followup__block__header__arrow" />
            </div>
            <div class="account-followup__block__body is-open">
              <div class="account-followup__block__body__content-centered">
                <div class="account-followup__block__body__details">
                  <h4>{{ $t('followup.capital_deposit.title') }}</h4>
                  <p class="text-muted">
                    {{ $t('followup.capital_deposit.content') }}
                  </p>
                </div>
                <button class="btn btn--default"
                        @click="downloadFileFromUrl(`/companies/${account.company.id}/documents/deposit_certificate`)">
                  <span class="btn-icon btn-icon--left">
                    <ic-download class="ic ic--white" />
                  </span>
                  <span>{{ $t('button.download') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="companyCertificateRule"
               class="account-followup__block">
            <div class="account-followup__block__header"
                 @click="toggleBody">
              <ic-square status-certified
                         class="account-followup__block__icon" />
              <div>
                <h3 class="account-followup__block__title">
                  {{ $t('followup.certificate.title') }}
                </h3>
                <p class="account-followup__block__details text-muted">
                  {{ $t('followup.certificate.subtitle') }}
                </p>
              </div>
              <component :is="itemIcon(companyCertificateRule)"
                         class="account-followup__block__state"
                         :class="itemClass(companyCertificateRule)" />
              <ic-chevron down
                          class="ic ic--20 ic--gray-400 account-followup__block__header__arrow" />
            </div>
            <div class="account-followup__block__body is-open">
              <div class="account-followup__block__body__container">
                <div class="account-followup__block__body__item">
                  <div class="account-followup__block__body__item__state"
                       :class="itemClass(companyCertificateRule)">
                    <component :is="itemIcon(companyCertificateRule)"
                               class="ic--20" />
                    <p>{{ itemLabel(companyCertificateRule, 'certificate') }}</p>
                  </div>
                  <div class="account-followup__block__body__item__content">
                    <h4 class="account-followup__block__body__item__title">
                      {{ $t('followup.certificate.title') }}
                    </h4>
                    <p class="account-followup__block__body__item__desc text-muted">
                      {{ certificateSubtitle(companyCertificateRule) }}
                    </p>
                    <a :href="links.SUPPORT.LEXIQUE_CERTIF_CONFORME"
                       target="_blank">{{ $t('message.info.cookie.more') }}</a>
                  </div>
                  <div class="account-followup__block__body__item-right">
                    <button v-if="companyCertificateRule.document"
                            v-tooltip="{ content: $t('tooltip.download'), theme: 'tooltip' }"
                            class="btn btn--outline btn-attachment"
                            @click.prevent.stop="downloadFile(companyCertificateRule)">
                      <ic-document class="ic ic--gray" />
                    </button>
                    <button v-if="!isItemValidated(companyCertificateRule)"
                            v-tooltip="{ content: $t('tooltip.attach_file'), theme: 'tooltip' }"
                            class="btn btn--outline btn-attachment"
                            @click.prevent.stop="appStore.showDropzoneModal($tc('title.attach_file'), {}, { url: `/companies/${account.company.id}/documents/status`, maxFiles: 1 })">
                      <ic-attachment class="ic ic--gray" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="account-followup__block">
            <div class="account-followup__block__header"
                 @click="toggleBody">
              <ic-square immatriculation-kbis
                         class="account-followup__block__icon" />
              <div>
                <h3 class="account-followup__block__title">
                  {{ $t('followup.kbis_upload.title') }}
                </h3>
                <p v-if="isItemRejected(companyKbis)"
                   class="account-followup__block__details text-muted">
                  {{ companyKbis.reject_motive }}
                </p>
                <p v-else-if="!isItemValidated(companyKbis)"
                   class="account-followup__block__details text-muted">
                  {{ $t(`followup.kbis_upload.subtitle.${accountValidation.kbis_uploaded ? 'waiting_validation' : 'waiting_upload'}`) }}
                </p>
                <p v-else
                   class="account-followup__block__details text-muted">
                  {{ $t(`followup.kbis_upload.subtitle.validated`) }}
                </p>
              </div>
              <component :is="itemIcon(companyKbis)"
                         class="account-followup__block__state"
                         :class="itemClass(companyKbis)" />
              <ic-chevron down
                          class="ic ic--20 ic--gray-400 account-followup__block__header__arrow" />
            </div>
            <div class="account-followup__block__body is-open">
              <div class="account-followup__block__body__content-centered">
                <image-file v-if="isItemValidated(companyKbis)"
                            success
                            height="120px"
                            width="120px" />
                <image-file v-else-if="isItemRejected(companyKbis)"
                            warning
                            height="120px"
                            width="120px" />
                <image-upload v-else
                              width="71px"
                              height="71px"
                              class="mb-3" />
                <div class="account-followup__block__body__details">
                  <h4 v-if="!isItemValidated(companyKbis)">
                    {{ $t('followup.kbis_upload.content') }}
                  </h4>
                  <h4 v-else>
                    {{ $t('followup.kbis_upload.content_validated') }}
                  </h4>
                  <p v-if="isItemRejected(companyKbis)"
                     class="text-muted">
                    {{ companyKbis.reject_motive }}
                  </p>
                  <p v-else-if="!isItemValidated(companyKbis)"
                     class="text-muted">
                    {{ $t(`followup.kbis_upload.subcontent.${accountValidation.kbis_uploaded ? 'waiting_validation' : 'waiting_upload'}`) }}
                  </p>
                </div>
                <button v-if="!accountValidation.kbis_uploaded || isItemRejected(companyKbis)"
                        class="btn btn--default"
                        @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_kbis'), {}, { url: `/companies/${account.company.id}/documents/kbis`, maxFiles: 1 })">
                  <span class="btn-icon btn-icon--left">
                    <ic-attachment class="ic ic--white" />
                  </span>
                  <span>{{ $t('button.attach_kbis') }}</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import LogRocket from 'logrocket'
import { storeToRefs } from 'pinia'

import links from '@/config/links'
import store from '@/config/store'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAnalyticStore } from '@/stores/analytics'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { ECookieStatus } from '@/types/analytics.d'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcDownload from '@/components/svg/icons/ic-download.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcPending from '@/components/svg/icons/ic-pending.vue'
import IcReject from '@/components/svg/icons/ic-reject.vue'
import IcSquare from '@/components/svg/icons/ic-square.vue'
import IcValid from '@/components/svg/icons/ic-valid.vue'
import ImageFile from '@/components/svg/img/file.vue'
import ImageUpload from '@/components/svg/img/upload.vue'

export default {
  name: 'PageFollowOpening',

  components: {
    CoreBadge,
    IcAttachment,
    IcDocument,
    IcDownload,
    IcInfo,
    IcValid,
    ImageUpload,
    IcReject,
    IcChevron,
    IcSquare,
    ImageFile,
    LoaderSpinner
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const analyticStore = useAnalyticStore()

    const { user, userFullName } = storeToRefs(authStore)
    const { accountValidation, account } = storeToRefs(accountStore)
    const { cookies } = storeToRefs(analyticStore)

    return { account, accountValidation, accountStore, appStore, user, userFullName, cookies }
  },

  data () {
    return {
      store,
      loading: true,
      moreLinks: {
        identification: links.SUPPORT.IDENTITY_CARD,
        address_proof: links.SUPPORT.ADDRESS_PROOF,
        certificate_project: links.SUPPORT.STATUS_PROJECT,
        certificate: links.SUPPORT.STATUS,
        proof_of_address: links.SUPPORT.PROOF_OF_ADDRESS,
        activity_proof: links.SUPPORT.ACTIVITY_PROOF,
        kbis: links.SUPPORT.KBIS
      },

      links
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    statusProof () {
      if (this.allDocuments.proofs.some(p => this.isItemRejected(p))) return { icon: IcError, class: 'state-warning' }
      if (this.allDocuments.proofs.some(p => this.isItemPending(p))) return { icon: IcPending, class: 'state-pending' }

      return { icon: IcValid, class: 'state-valid' }
    },

    statusRepresentatives () {
      if (this.allDocuments.representatives.some(p => this.isItemRejected(p))) return { icon: IcError, class: 'state-warning' }
      if (this.allDocuments.representatives.some(p => this.isItemPending(p))) return { icon: IcPending, class: 'state-pending' }

      return { icon: IcValid, class: 'state-valid' }
    },

    statusTransfers () {
      if (this.allDocuments.transfers.some(t => this.isItemRejected(t))) return { icon: IcError, class: 'state-warning' }
      if (this.allDocuments.transfers.some(t => this.isItemPending(t))) return { icon: IcPending, class: 'state-pending' }

      return { icon: IcValid, class: 'state-valid' }
    },

    numberValidatedProofs () {
      return this.allDocuments.proofs.filter(p => this.isItemValidated(p)).length
    },

    numberValidatedRepresentatives () {
      return this.allDocuments.representatives.filter(r => this.isItemValidated(r)).length
    },

    incomingTransfers () {
      return this.accountValidation.incoming_transfers || []
    },

    numberValidatedTransfers () {
      return this.allDocuments.transfers.filter(t => this.isItemValidated(t)).length
    },

    hasCapitalDeposit () {
      return !this.account.company.is_ae_ei && this.account.company.registration_type === 'new_company'
    },

    isOpeningRejected () {
      return this.accountValidation.status === 'rejected'
    },

    depositSubtitle () {
      if (this.account.company.is_ae_ei) {
        return this.$i18n.t('followup.label.deposit.ae_ei')
      }

      if (this.hasCapitalDeposit) {
        return this.allDocuments.transfers.length > 1 ? this.$i18n.t('followup.label.deposit.multiple_representative') : this.$i18n.t('followup.label.deposit.only_representative')
      }

      return this.$i18n.t('followup.label.deposit.common')
    },

    getBlockOrder () {
      return this.statusTransfers.class === 'state-valid' ? 'order: 2;' : 'order: 1;'
    },

    allDocuments () {
      const rules = this.accountValidation.validation_rules || []

      return rules.reduce((documents, item) => {
        if (item.rule.includes('transfer')) {
          documents.transfers.push(item)
        } else if (['user', 'company', 'mandate'].includes(item.type)) {
          documents.proofs.push(item)
        } else if (['representative', 'mandate_company'].includes(item.type)) {
          documents.representatives.push(item)
        }

        return documents
      }, {
        proofs: [],
        representatives: [],
        transfers: []
      })
    },

    companyCertificateRule () {
      return this.accountValidation.validation_rules.find(rule =>
        rule.type === 'company' && rule.rule === 'certificate'
      ) || null
    },

    companyKbis () {
      return this.accountValidation.validation_rules.find(rule =>
        rule.type === 'company' && rule.rule === 'kbis'
      ) || null
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('on-upload-success', this.onUploadSuccess)
    this.$bus.on('account-switched', this.refreshData)
  },

  beforeUnmount () {
    if (store.logrocket) {
      LogRocket.uninstall()
    }

    this.$bus.off('on-upload-success', this.onUploadSuccess)
    this.$bus.off('account-switched', this.refreshData)
  },

  methods: {
    downloadFileFromUrl,

    formatAmount,

    formatDate,

    initData () {
      this.refreshData()

      if (store.logrocket && this.cookies.analytics === ECookieStatus.CONSENTED) {
        LogRocket.init('managerone/client-followup', {
          console: {
            shouldAggregateConsoleErrors: true
          }
        })

        LogRocket.identify(this.user.uuid, {
          name: this.userFullName,
          email: this.user.email
        })
      }
    },

    async refreshData () {
      this.loading = true
      await this.accountStore.getAccountValidation()
      this.loading = false
    },

    toggleBody (e) {
      let element = e.target
      if (!element) { return }

      if (!element.classList.contains('account-followup__block__header')) {
        element = element.closest('.account-followup__block__header')
      }

      element.nextElementSibling.classList.toggle('is-open')
    },

    isItemValidated (item) {
      return item?.validated_at
    },

    isItemRejected (item) {
      return item?.rejected_at
    },

    isItemSent (item) {
      return item && !!item.document
    },

    isItemPending (item) {
      return !this.isItemValidated(item) && !this.isItemRejected(item)
    },

    itemClass (item) {
      if (this.isItemValidated(item)) return 'state-valid'
      if (this.isItemRejected(item)) return 'state-warning'
      return 'state-pending'
    },

    itemIcon (item) {
      if (this.isItemValidated(item)) return IcValid
      if (this.isItemRejected(item)) return IcError
      return IcPending
    },

    itemLabel (item, itemType) {
      if (this.isItemValidated(item)) return this.$i18n.t('followup.label.validated')
      if (this.isItemRejected(item)) return this.$i18n.t('followup.label.not_valid')
      if ((itemType === 'certificate' && item && item.document) || (itemType === 'transfer' && this.incomingTransfers.length >= this.allDocuments.transfers.length)) {
        return this.$i18n.t('followup.label.received')
      }
      if (itemType === 'document' && !this.isItemSent(item)) {
        return this.$i18n.t('followup.label.pending')
      }
      return itemType === 'document' ? this.$i18n.t('followup.label.received') : this.$i18n.t('followup.label.pending')
    },

    proofSubtitle (item) {
      if (this.isItemValidated(item)) return `${this.$i18n.t('followup.proofs.validated_document')} ${formatDate(item.validated_at)}.`
      if (this.isItemRejected(item)) return item.reject_motive
      if (item.document === null) return this.$i18n.t('followup.proofs.wait_document')
      return this.$i18n.t('followup.proofs.validating_document')
    },

    transferSubtitle (item) {
      if (this.isItemValidated(item)) return `${this.$i18n.t('followup.transfers.validated_transfer')} ${formatDate(item.validated_at)}.`
      if (this.isItemRejected(item)) return item.reject_motive
      if (this.incomingTransfers.length >= this.allDocuments.transfers.length) {
        return this.$i18n.t('followup.transfers.waiting_transfer_validation')
      }
      return this.$i18n.t('followup.transfers.waiting_transfer')
    },

    certificateSubtitle (item) {
      if (this.isItemValidated(item)) return `${this.$i18n.t('followup.certificate.validated_certificate')} ${formatDate(item.validated_at)}.`
      if (this.isItemRejected(item)) return item.reject_motive
      if (item && item.document) return this.$i18n.t('followup.certificate.validating_certificate')
      return this.$i18n.t('followup.certificate.waiting_certificate')
    },

    joinDocument (item) {
      this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file'), {}, { url: `/${item.document_send_to}`, maxFiles: this.getMaxFiles(item), acceptedFiles: 'image/jpg,image/jpeg,image/png,image/tif,image/tiff,image/webp,image/heic,application/pdf' })
    },

    getMaxFiles (item) {
      if (item.rule === 'proof_of_address' && item.type === 'company') return 1

      switch (item.rule) {
        case 'proof_of_address':
        case 'identification':
          return 3
        default:
          return 1
      }
    },

    getMoreLink (item) {
      if (item.rule === 'proof_of_address' && item.type === 'user') return this.moreLinks.address_proof
      if (this.moreLinks[item.rule]) return this.moreLinks[item.rule]

      return false
    },

    downloadFile (item) {
      const maxFiles = this.getMaxFiles(item)
      this.appStore.showFileViewer({
        content: (maxFiles === 1 ? [item.document] : item.documents),
        isMultiDoc: (maxFiles !== 1),
        displayFullScreen: true,
        documentsAllUrl: item.document.url
      })
    },

    onUploadSuccess () {
      this.initData()
    }
  }
}
</script>

<style lang="stylus">
.section-account-followup
  .section-title
    margin auto

  .section-subtitle
    max-width 82rem
    text-align center
    margin -2rem auto 0 auto

.account-followup
  display flex
  flex-direction column
  margin 4.9rem auto
  width 85rem

  &__block
    border #EFEFF2 solid 1px
    border-radius $border-radius
    margin-bottom 18px

    &__title
      font-weight 500

    &__details
      margin-bottom 0

    &__state
      margin-left auto
      width 2rem
      height @width

    &__icon
      width 52px
      height @width
      margin-right 30px

    &__header
      padding 30px 44px 26px 34px
      display flex
      cursor pointer
      align-items center

      svg > rect:first-child
        transition fill 0.1s ease

      &:hover
        background-color $table-bg-hover
        svg > rect:first-child
          fill $colorWhite

      &__arrow
        margin-left 38px

    &__body
      padding 34px 38px 37px
      border-top 1px solid #EFEFF2
      flex-direction column
      display none

      .head-title
        text-transform uppercase
        font-weight 500
        font-size 1.24rem
        margin-bottom 2rem
        margin-left 0.6rem

      &.is-open
        display flex

      &__text
        width 100%
        margin-bottom 36px

      &__container
        border 1px solid #EFEFF2
        border-radius $border-radius
        width 100%

      &__item
        padding 29px
        display flex
        align-items center

        &--compact
          padding 1.6rem 2.4rem

        &__content
          flex 1

        &__state
          width 60px
          height @width
          display flex
          flex-direction column
          align-items center
          justify-content space-around
          margin-right 30px

          & p
            margin-bottom 0
            text-transform uppercase
            font-size 0.8em
            font-weight 500

        &-right
          margin-left 5rem

          .btn-attachment
            margin .5rem
            width 4rem
            height @width
            border-radius 50%
            padding 0

          .ic
            width 1.6rem
            height @width

        &__title
          font-weight 500

        & > div > p:nth-last-child(1)
          margin-bottom 0

        &:not(:last-child)
          border-bottom 1px solid #EFEFF2

      > svg
        width 48px
        height 71px
        margin-top 20px

      &__content-centered
        text-align center
        margin 3rem 0

        > .btn
          min-width 17.5rem
          margin-top 2rem

      &__details
        width 34rem
        margin-left auto
        margin-right @margin-left

        & > h4
          font-weight 500
          font-size 1.6rem

.state
  &-warning
    color $colorWarning
    fill $colorWarning

  &-valid
    color $colorSuccess
    fill $colorSuccess

  &-pending
    color $colorShinyGray
    fill $colorShinyGray

.account-reject
  width 376px
  display flex
  flex-direction column
  align-items center
  margin 80px auto 0

  &__icon
    width 84px
    height @width
    margin-bottom 25px

  &__title
    font-weight 500

  &__subtitle
    margin-bottom 30px
    text-align center
    width 340px

  &__info
    background-color #F2F9FF
    padding 18px 22px
    color #3E81CE
    fill #3E81CE

    & svg
      width 16px
      height @width
      margin-right 8px
</style>
