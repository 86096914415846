<template>
  <div class="placeholder-action-description">
    <timeout-circle v-if="isThreeDsChallenge && challenge.ttl"
                    class="mb-2"
                    :time="challenge?.ttl"
                    @ended="onEndWithoutValidation" />
    <component-placeholder :icon="!isThreeDsChallenge"
                           :label="getTitle"
                           :content="getPlaceholderContent">
      <image-mobile-app v-if="type === 'app'"
                        sca />
      <image-mobile-app v-else-if="type === 'sms'"
                        code />
      <image-computer v-else-if="type === 'email'"
                      code />
    </component-placeholder>
    <div v-if="challenge && !isApp"
         class="bordered-box">
      <div class="sca-description">
        <div v-if="isThreeDsChallenge"
             class="sca-details">
          <div class="sca-details__entry">
            <div class="entry-label text-muted">
              {{ $t('general.merchant') }}
            </div>
            <div class="entry-label text-muted">
              {{ $t('table.amount') }}
            </div>
            <div class="entry-label text-muted">
              {{ $t('table.card') }}
            </div>
            <div class="entry-label text-muted">
              {{ $t('table.date') }}
            </div>
          </div>
          <div class="sca-details__entry">
            <div class="entry-value">
              {{ challenge.payload.merchant_name }}
            </div>
            <div class="entry-value">
              {{ formatAmount(challenge.payload.amount, challenge.payload.currency) }}
            </div>
            <div class="entry-value">
              {{ challenge.payload.digits }}
            </div>
            <div class="entry-value">
              {{ formatDateText(challenge.payload.created_time, 'lll') }}
            </div>
          </div>
        </div>
        <div v-else
             class="text-muted pre-line">
          {{ challenge?.description }}
        </div>
      </div>
      <button class="btn-sca-deny"
              type="button"
              @click="rejectSca">
        <ic-forbidden class="ic ic--20 ic--gray" />
        <span class="btn-sca-deny__text">{{ $t('button.reject_sca') }}</span>
      </button>
    </div>
    <div class="center">
      <template v-if="isApp">
        <loader-spinner v-if="isScaChallengeAccepted" />
        <timeout-circle v-else
                        :time="ttl"
                        @ended="onEndWithoutValidation" />
      </template>
      <verification-code v-else
                         :disabled="loading"
                         @complete="onCodeComplete" />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useScaStore } from '@/stores/sca'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcForbidden from '@/components/svg/icons/ic-forbidden.vue'
import ImageComputer from '@/components/svg/img/computer.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'
import TimeoutCircle from '@/components/TimeoutCircle.vue'
import VerificationCode from '@/components/VerificationCode.vue'

export default {
  components: {
    IcForbidden,
    LoaderSpinner,
    ComponentPlaceholder,
    TimeoutCircle,
    VerificationCode,
    ImageComputer,
    ImageMobileApp
  },

  props: {
    type: {
      type: String,
      required: true
    },

    challenge: {
      type: Object,
      default: null
    }
  },

  emits: ['validation-success', 'action', 'validation-request-started', 'reject-sca'],

  setup () {
    const appStore = useAppStore()
    const scaStore = useScaStore()

    const { modal } = storeToRefs(appStore)
    const { scaInitialized } = storeToRefs(scaStore)

    return { modal, scaInitialized, scaStore }
  },

  data () {
    return {
      store,
      loading: false,
      timeout: null,
      endStatusCall: false,
      isScaChallengeAccepted: false
    }
  },

  computed: {
    isApp () {
      return this.type === 'app'
    },

    isThreeDsChallenge () {
      return this.challenge?.event === 'auth.three-ds'
    },

    ttl () {
      return this.challenge?.ttl ?? this.scaInitialized.ttl ?? 120
    },

    hiddenData () {
      return this.challenge
        ? this.challenge.token.description
        : this.scaInitialized.description
    },

    getPlaceholderContent () {
      if (!this.challenge) {
        return this.$i18n.t(`placeholder.sca_${this.type}.send_invitation.subtitle_configuration`, { data: this.hiddenData })
      }

      if (this.type === 'app') {
        return this.$i18n.t('placeholder.sca_app.send_invitation.subtitle')
      }

      if (this.challenge.event === 'auth' || !this.challenge.description) {
        return this.$i18n.t(`placeholder.sca_${this.type}.send_invitation.subtitle_common`, { data: this.hiddenData })
      }

      return this.isThreeDsChallenge
        ? this.$i18n.t(`placeholder.sca_${this.type}.three_ds.subtitle`)
        : this.$i18n.t(`placeholder.sca_${this.type}.send_invitation.subtitle_description`, { data: this.hiddenData })
    },

    getTitle () {
      return this.isThreeDsChallenge
        ? this.$i18n.t('placeholder.sca.three_ds.title')
        : this.$i18n.t(`placeholder.sca_${this.type}.send_invitation.title`)
    }
  },

  beforeUnmount () {
    this.endStatusCall = true
    clearTimeout(this.timeout)
  },

  mounted () {
    if (this.isApp) {
      return this.recursiveScaStatusCall()
    }

    if (this.challenge?.description) {
      this.modal.wrapperClass = 'modal--sm modal-explanation'
    }
  },

  methods: {
    formatAmount,

    formatDateText,

    async recursiveScaStatusCall () {
      if (this.endStatusCall) { return }

      if (this.challenge) {
        const status = await this.scaStore.getChallengeStatus(this.challenge.id)
        if (!status) return

        if (['init', 'triggered'].includes(status)) {
          this.timeout = setTimeout(() => { return this.recursiveScaStatusCall() }, 2000)
        } else if (status === 'accepted') {
          this.isScaChallengeAccepted = true
          const success = await this.scaStore.validateScaRequest(this.challenge.id)
          success
            ? this.$emit('validation-success', success)
            : this.$emit('action', 'validation-failed')
        } else {
          status === 'rejected'
            ? this.$emit('action', 'validation-rejected')
            : this.$emit('action', 'validation-failed')
        }
      } else {
        store.api.hideProgressBar = true
        const status = await this.scaStore.getScaStatus(this.scaInitialized.id)
        store.api.hideProgressBar = false
        if (!status) return

        if (['init', 'triggered'].includes(status)) {
          this.timeout = setTimeout(() => { return this.recursiveScaStatusCall() }, 2000)
        } else {
          status === 'active'
            ? this.$emit('action', 'validation-success')
            : this.$emit('action', 'validation-failed')
        }
      }
    },

    async onCodeComplete (code) {
      this.loading = true
      this.challenge
        ? await this.validateRequest(code)
        : await this.validateConfiguration(code)
      this.loading = false
    },

    async validateRequest (code) {
      this.$emit('validation-request-started')
      const success = await this.scaStore.validateScaRequest(this.challenge.id, { code })
      success
        ? this.$emit('validation-success', success)
        : this.$emit('action', 'validation-failed')
    },

    async validateConfiguration (code) {
      const success = await this.scaStore.validateScaConfiguration(
        this.scaInitialized.id,
        { code, challenge_id: this.scaInitialized.challenge.id }
      )
      success
        ? this.$emit('action', 'validation-success')
        : this.$emit('action', 'validation-failed')
    },

    rejectSca () {
      this.$emit('reject-sca')
    },

    onEndWithoutValidation () {
      this.$emit('action', 'validation-failed')
    }
  }
}
</script>
