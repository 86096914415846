<template>
  <div class="card-options row">
    <card-option-block v-if="canViewPinCode"
                       data-cy="cards.details.show-pin-code"
                       :content="{
                         title: $t('card.pincode.show.title'),
                         description: $t('card.pincode.show.content')
                       }"
                       :button="$t('button.card_show_pin')"
                       :icon="ECoreIconList.Key"
                       :loading="loading"
                       @btn-click="onShowPinCode" />
    <card-option-block v-if="canEditPinCode"
                       :content="{
                         title: $t('card.pincode.edit.title'),
                         description: $t('card.pincode.edit.content')
                       }"
                       :button="$t('button.set_new_pin')"
                       :icon="ECoreIconList.Settings"
                       @btn-click="onEditPinCode" />
    <card-option-block v-if="hasCardBlockFeature"
                       :content="{
                         title: isVirtualCard ? $t('general.deletion') : $t('card.block.title'),
                         description: isVirtualCard ? $t('card.delete.content') : $t('card.block.content')
                       }"
                       data-cy="cards.details.delete.block"
                       :button="isVirtualCard ? $t('button.delete_card') : $t('button.card_block')"
                       :icon="ECoreIconList.Block"
                       @btn-click="onBlockCard" />
  </div>
</template>

<script>
import { ECoreIconList, ECoreIconTheme } from '@common/core-ui'

import { EFeature, hasFeature } from '@/config/features'
import { isVirtualCard } from '@/helpers/utils/card'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ECardProcessor } from '@/types/card.d'

import BlockCard from '@/pages/cards/modal/BlockCard.vue'
import EditPinCode from '@/pages/cards/modal/EditPinCode.vue'
import ShowPinCode from '@/pages/cards/modal/ShowPinCode.vue'

import CardOptionBlock from './CardOptionBlock.vue'

export default {
  components: {
    CardOptionBlock
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()

    return { appStore, cardStore }
  },

  data () {
    return {
      ECoreIconList,
      ECoreIconTheme,
      loading: false
    }
  },

  computed: {
    isMarqeta () {
      return this.card.subtype === ECardProcessor.MARQETA
    },

    isWorldline () {
      return this.card.subtype === ECardProcessor.WORLDLINE
    },

    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    canViewPinCode () {
      return (this.isMarqeta || this.isWorldline) && this.card.active && this.hasCardPinReadFeature
    },

    canEditPinCode () {
      return (this.isMarqeta || this.isWorldline) && this.card.active && this.hasCardPinEditionFeature
    },

    hasCardPinEditionFeature () {
      return hasFeature(EFeature.CardPinEdition, this.card)
    },

    hasCardBlockFeature () {
      return hasFeature(EFeature.CardBlock, this.card)
    },

    hasCardPinReadFeature () {
      return hasFeature(EFeature.CardPin, this.card)
    }
  },

  mounted () {
    this.$bus.on('card-block-success', this.onBlockCardSuccess)
  },

  beforeUnmount () {
    this.$bus.off('card-block-success', this.onBlockCardSuccess)
  },

  methods: {
    onBlockCard () {
      this.appStore.showModal(BlockCard, { card: this.card }, { wrapperClass: 'modal--xs' })
    },

    onEditPinCode () {
      this.appStore.showModal(EditPinCode, { card: this.card }, { wrapperClass: 'modal--xs' })
    },

    onBlockCardSuccess () {
      this.$router.push({ name: this.isVirtualCard ? 'cards-virtual' : 'cards-physical' })
    },

    async onShowPinCode () {
      this.loading = true
      let pin = null
      let token = null
      if (this.card.subtype === ECardProcessor.WORLDLINE) {
        pin = await this.cardStore.getWorldlineCardPin(this.card.uuid)
      } else if (this.card.subtype === ECardProcessor.MARQETA) {
        token = await this.cardStore.getClientAccessToken(this.card.uuid, 'pin')
      }
      if (token || pin) {
        this.appStore.showModal(ShowPinCode,
          { card: this.card, token, pin },
          { wrapperClass: 'modal--xs' }
        )
      }
      this.loading = false
    }
  }
}
</script>
