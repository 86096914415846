<template>
  <div class="multiple-card-helper">
    <h2 class="title">
      {{ helperContent.title }}
    </h2>
    <div class="tutorial-blocks">
      <div v-for="(step, i) in helperContent.steps"
           :key="i"
           class="tutorial-blocks__item">
        <span class="tutorial-step-number">{{ i + 1 }}</span>
        <h3 class="tutorial-step-title">
          {{ $t(`${step}.title`) }}
        </h3>
        <i18n-t class="tutorial-step-text"
                :keypath="`${step}.content`"
                scope="global">
          <template #download_link>
            <a :href="helperContent.downloadLink"
               class="btn-link btn-link--upper">
              {{ $t('button.download_template') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </div>
    <button type="button"
            class="btn btn--primary"
            @click="toggleStep(2)">
      {{ $t('button.continue') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ComponentStepsHelper',

  inheritAttrs: false,

  props: {
    helperContent: {
      type: Object,
      required: true
    }
  },

  emits: ['toggle-step'],

  methods: {
    toggleStep (step) {
      this.$emit('toggle-step', step)
    }
  }
}
</script>
