<template>
  <validation-field :model-value="modelValue"
                    :name="name">
    <div :data-message="dropdownOpen ? null : $attrs['data-message']"
         class="input-group phone-input"
         :class="[wrapperClass ? wrapperClass : '']">
      <div class="select form-control"
           style="max-width: 6rem">
        <component-dropdown :can-add-item="false"
                            :disabled="disabled"
                            :dropdown-height="21"
                            :dropdown-width="'100%'"
                            :label-filter="phoneCountryFilter"
                            :model="phone_country"
                            :search="true"
                            :values="dialCodes"
                            label="attachment"
                            @select="onSelectPhoneCountry"
                            @toggle-dropdown="toggleDropdownOpen">
          <template #trigger>
            <span>
              <img id="phone-flag"
                   :src="flagSrc"
                   style="width: 20px; margin-right: 6px;"
                   @error="onImageError"
                   @load="onImageLoaded">
            </span>
          </template>
        </component-dropdown>
      </div>
      <input id="phone"
             type="text"
             :disabled="disabled"
             :placeholder="placeholder"
             class="form-control input__field"
             :value="fullNumber"
             style="padding-left: 0.8rem"
             @input="onInput">
      <slot name="addon" />
    </div>
  </validation-field>
</template>

<script>
import {
  getCountryCodeForRegionCode,
  getSupportedRegionCodes,
  parsePhoneNumber
} from 'awesome-phonenumber'

import { getCountryName, getFlagSrc } from '@/helpers/utils/intl'

import ComponentDropdown from '@/components/Dropdown.vue'

export default {
  components: {
    ComponentDropdown
  },

  props: {
    modelValue: {
      required: true,
      validator: value => {
        return typeof value === 'object' || typeof value === 'string' || value === null
      }
    },

    name: {
      required: false,
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  data () {
    return {
      phone_country: {
        dial_code: '+33',
        code: 'FR'
      },

      phone_national: '',
      dropdownOpen: false
    }
  },

  computed: {
    fullNumber () {
      const pn = parsePhoneNumber(this.phone_country.dial_code + this.phone_national, { regionCode: this.phone_country.code })
      return pn.valid
        ? pn.number.international
        : this.phone_country.dial_code + this.phone_national
    },

    flagSrc () {
      return getFlagSrc(this.phone_country.code)
    },

    dialCodes () {
      return getSupportedRegionCodes().map(code => ({
        code,
        dial_code: `+${getCountryCodeForRegionCode(code)}`,
        country: getCountryName(code)
      })
      ).filter(code => code.country)
        .sort((a, b) => a.country.localeCompare(b.country))
    }
  },

  watch: {
    modelValue () {
      this.formatPhoneNumber()
    },

    fullNumber () {
      this.$emit('update:modelValue', this.phone_national ? this.fullNumber : '')
    }
  },

  created () {
    this.formatPhoneNumber()
  },

  methods: {
    formatPhoneNumber () {
      if (this.modelValue === null) {
        return
      }

      const phone = parsePhoneNumber(this.modelValue)
      if (phone.valid) {
        if (phone.regionCode !== this.phone_country.code) {
          this.phone_country.code = phone.regionCode
        }
        if (`+${phone.countryCode}` !== this.phone_country.dial_code) {
          this.phone_country.dial_code = `+${phone.countryCode}`
        }
        this.phone_national = phone.number.significant
      } else if (this.modelValue === '') {
        this.phone_national = ''
      }
    },

    onInput (e) {
      const input = e.target.value.replace(/[^0-9+]/g, '')
      e.target.value = input
      if (e.data && isNaN(parseInt(e.data))) {
        e.target.value = this.fullNumber
        return
      }
      const phone = parsePhoneNumber(input)
      if (e.inputType === 'insertFromPaste' && phone.valid) {
        this.phone_country.code = phone.regionCode
        this.phone_country.dial_code = `+${phone.countryCode}`
        this.phone_national = phone.number.significant
      } else if (!this.phone_national && !e.data) {
        e.target.value = this.phone_country.dial_code
        return
      }
      this.phone_national = input.replace(/\s/g, '').replace(this.phone_country.dial_code.replace(/\s/g, ''), '').replace(/\+/g, '')
    },

    onImageLoaded () {
      if (document.getElementById('phone-flag')) {
        document.getElementById('phone-flag').classList.remove('hide')
      }
    },

    onImageError () {
      if (document.getElementById('phone-flag')) {
        document.getElementById('phone-flag').classList.add('hide')
      }
    },

    phoneCountryFilter (item) {
      return `${item.dial_code} - ${item.country}`
    },

    onSelectPhoneCountry (item) {
      this.phone_country = { ...item }
    },

    toggleDropdownOpen (value) {
      this.dropdownOpen = value
    }
  }
}
</script>

<style lang="stylus" scoped>
.phone-input
  position relative

  .select.form-control
    border-right none !important
    position initial

  .dropdown-custom
    position initial

  .select.form-control
    &:after
      left 4.8rem
</style>
