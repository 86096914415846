<template>
  <section v-if="loading"
           class="section section--empty">
    <loader-spinner class="c c--vh" />
  </section>
  <section v-else
           class="section">
    <div class="section__heading">
      <h1 class="section-title">
        <router-link class="btn-link btn-back"
                     :to="{ name: 'account-company' }">
          <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
        </router-link>
        {{ company.name }}
      </h1>
    </div>
    <tabs :data="segments" />
    <router-view :company="company" />
  </section>
</template>

<script>
import { useAccountStore } from '@/stores/account'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    IcArrow,
    LoaderSpinner,
    Tabs
  },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()

    return { accountStore }
  },

  data () {
    return {
      loading: true,
      company: null
    }
  },

  computed: {
    segments () {
      return [
        {
          label: this.$i18n.t('button.bank_accounts'),
          route: { name: 'account-company-subaccounts' }
        },
        {
          label: this.$i18n.t('button.informations'),
          route: { name: 'account-company-info' }
        }
      ]
    }
  },

  async created () {
    this.loading = true
    this.company = await this.accountStore.getCompany(this.id)
    this.loading = false
  }
}
</script>
