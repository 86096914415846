<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 1.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924ZM12.5 7a.25.25 0 0 1 .25.25v4h4a.25.25 0 0 1 .25.25v1a.25.25 0 0 1-.25.25h-4v4a.25.25 0 0 1-.25.25h-1a.25.25 0 0 1-.25-.25v-4h-4A.25.25 0 0 1 7 12.5v-1a.25.25 0 0 1 .25-.25h4v-4A.25.25 0 0 1 11.5 7h1Z" />
  </svg>
</template>

<script>
export default {
}
</script>
