<template>
  <div>
    <div :class="{ 'input-group': passwordVisibilityToggle }">
      <validation-field v-bind="$attrs"
                        v-model="password"
                        as="input"
                        :type="passwordType"
                        autocomplete="off"
                        class="form-control"
                        :name="name"
                        @focus="onFocusPassword"
                        @blur="onBlurPassword">
        <div v-if="passwordVisibilityToggle"
             class="input-group__addon input-group__addon--pwd-visibility">
          <button v-if="password.length"
                  tabindex="-1"
                  class="btn-link"
                  type="button"
                  @click="togglePasswordVisibility">
            <ic-view class="ic"
                     :class="[passwordType === 'text' ? 'ic--secondary' : 'ic--gray']" />
          </button>
        </div>
      </validation-field>
    </div>
    <div v-if="passwordMeter && showPasswordMeter"
         class="pwd-strength-meter pwd-strength-meter--dropdown">
      <template v-if="password.length === 0">
        {{ $t('form.password.helper') }}
      </template>
      <template v-else-if="passwordIsValid">
        {{ $t('form.password.security.label') }} <b>{{ passwordStrengh[passwordMeterScore] }}</b><span class="pwd-strength-meter__gauge"
                                                                                                       :data-level="passwordMeterScore" />
      </template>
      <template v-else>
        {{ $t('form.password.security.label') }} <b>{{ $t('form.password.security.poor') }}</b> - {{ $t('form.password.helper_short') }}<span class="pwd-strength-meter__gauge"
                                                                                                                                              :data-level="1" />
      </template>
    </div>
  </div>
</template>

<script>
import { zxcvbn } from '@zxcvbn-ts/core'

import { checkPasswordValid } from '@/helpers/utils/text'

import IcView from '@/components/svg/icons/ic-view.vue'

export default {
  components: {
    IcView
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    passwordMeter: {
      type: Boolean,
      default: true
    },

    passwordVisibilityToggle: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:model-value'],

  data () {
    return {
      isReadOnly: true,
      showPasswordMeter: false,
      password: '',
      score: 0,
      passwordType: 'password'
    }
  },

  computed: {
    passwordStrengh () {
      return [
        this.$i18n.t('form.password.security.poor'),
        this.$i18n.t('form.password.security.weak'),
        this.$i18n.t('form.password.security.medium'),
        this.$i18n.t('form.password.security.good'),
        this.$i18n.t('form.password.security.high')
      ]
    },

    passwordContainString () {
      return /\d/.test(this.password)
    },

    passwordIsValid () {
      return checkPasswordValid(this.password)
    },

    passwordMeterScore () {
      const result = zxcvbn(this.password)

      return this.passwordIsValid ? result.score : 0
    }
  },

  watch: {
    password () {
      this.$emit('update:model-value', this.password)
    }
  },

  created () {
    this.password = this.modelValue
  },

  methods: {
    onFocusPassword () {
      this.isReadOnly = false
      if (this.passwordMeter) {
        this.showPasswordMeter = true
      }
    },

    onBlurPassword () {
      if (this.passwordMeter) {
        this.showPasswordMeter = false

        this.showPasswordError = !this.passwordIsValid
      }
    },

    togglePasswordVisibility () {
      this.passwordType = (this.passwordType === 'password' ? 'text' : 'password')
    }
  }
}
</script>
