<template>
  <div class="col col--sm-3">
    <component-datepicker id="datepicker-to"
                          ref="expiration_date"
                          clear-button
                          :model-value="modelValue"
                          name="date"
                          month-picker
                          icon-calendar
                          :label="$t('form.expiration_month.label')"
                          :language="$i18n.locale"
                          format="MM/yyyy"
                          wrapper-class="form-group filter-item filter-date"
                          input-class="form-control form-control--noborder"
                          size-class="sm"
                          monday-first
                          :readonly="false"
                          :placeholder="$t('form.expiration_month.label')"
                          @update:model-value="onSelectDate" />
  </div>
</template>

<script>
import dayjs from 'dayjs'

import ComponentDatepicker from '@/components/Datepicker.vue'

export default {
  components: { ComponentDatepicker },
  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },

  emits: [
    'update:modelValue'
  ],

  methods: {
    onSelectDate (date) {
      const formattedDate = date ? dayjs(date).format('YYYY-MM') : null
      this.$emit('update:modelValue', formattedDate)
    }
  }
}
</script>
