<template>
  <svg viewBox="0 0 24 24">
    <path d="m13.6 12 6.046-6.046a.5.5 0 0 0 0-.708l-.892-.892a.5.5 0 0 0-.708 0L12 10.4 5.954 4.354a.5.5 0 0 0-.708 0l-.892.892a.5.5 0 0 0 0 .708L10.4 12l-6.046 6.046a.5.5 0 0 0 0 .708l.892.892a.5.5 0 0 0 .708 0L12 13.6l6.046 6.046a.5.5 0 0 0 .708 0l.892-.892a.5.5 0 0 0 0-.708z" />
  </svg>
</template>

<script>
export default {
}
</script>
