<template>
  <div class="modal__content center"
       data-cy="cards.details.virtual-card-secret-information.modal">
    <modal-header :title="title"
                  @close="$emit('close')" />
    <div class="modal__body">
      <loader-spinner v-if="loading"
                      class="c c--vh" />
      <div :class="{ 'is-hidden': loading }">
        <card-item :show-card-label="true"
                   :card="card"
                   :native-click="false"
                   show-pan
                   :secret-informations="secretInformations" />
        <component-button id="copy-pan-container"
                          :data-clipboard-text="secretInformations?.pan || null"
                          icon
                          :label="$t('button.copy_numbers')"
                          style="position: relative; z-index: 9999;"
                          wrapper-class="btn btn--outline btn-copy-card-numbers">
          <ic-clipboard class="ic ic--16 ic--gray" />
        </component-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ECoreBannerTheme } from '@common/core-ui'
import Clipboard from 'clipboard'

import links from '@/config/links'
import { showSecretInformations } from '@/helpers/marqeta'
import { getCardTypeLabel } from '@/helpers/utils/card'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'

import CardItem from '@/pages/cards/components/CardItem.vue'

import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import IcClipboard from '@/components/svg/icons/ic-clipboard.vue'

export default {
  components: {
    CardItem,
    IcClipboard,
    ComponentButton,
    LoaderSpinner,
    ModalHeader
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    token: {
      type: String,
      required: false,
      default: null
    },

    secretInformations: {
      type: Object,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    title () {
      return this.$t('title.your_card', { card_type: getCardTypeLabel(this.card).toLowerCase() })
    }
  },

  async created () {
    if (this.secretInformations) {
      this.clipboard = new Clipboard('#copy-pan-container')
      this.loading = false
      return
    }

    await this.$loadScript(links.MARQETA.WIDGET)
    await showSecretInformations(
      this.token,
      'mq-card-pan',
      'copy-pan-container',
      'mq-card-exp',
      'mq-card-cvv',
      () => {
        showToastSuccess(this.$t('message.success.copy_card', { card_type: this.cardType }))
      },
      () => { this.loading = false },
      () => {
        this.appStore.showBanner(ECoreBannerTheme.Danger, this.$i18n.t('message.warning.unknown'))
        this.$emit('close')
      }
    )
  },

  unmounted () {
    if (this.secretInformations) {
      this.clipboard.off('success', this.onCopySuccess)
      return
    }

    this.$unloadScript(links.MARQETA.WIDGET)
  }
}
</script>
