<template>
  <section class="section section-users">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.users') }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <router-link v-if="hasPermission($permissions.teamsWrite)"
                       class="btn btn--primary"
                       :to="{ name: 'team-create' }">
            {{ $t('button.create_team') }}
          </router-link>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="teamsPagination.totalItems"
                       @filters="filters" />
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <template v-else-if="teams.length">
        <component-table class="table--hover">
          <thead>
            <tr>
              <th class="col--sm-3">
                {{ $t('table.team') }}
              </th>
              <th class="col--sm-2 center">
                {{ $t('table.members') }}
              </th>
              <th class="col--sm-3">
                {{ $t('table.managers') }}
              </th>
              <th class="col--sm">
                {{ $t('table.approvers') }}
              </th>
              <th class="col-action" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="team in teams"
                :key="team.id"
                class="is-link">
              <td class="strong"
                  @click="onRowClick(team)">
                <div class="d-flex align-items-center">
                  <user-avatar class="mr-2"
                               type="team"
                               :size="40"
                               :placeholder-label="team.name" />
                  <div>
                    {{ team.name }}
                  </div>
                </div>
              </td>
              <td class="center"
                  @click="onRowClick(team)">
                {{ team.members_count }}
              </td>
              <td @click="onRowClick(team)">
                {{ team.managers.length ? team.managers.map(i => i.name).join(', ') : '-' }}
              </td>
              <td @click="onRowClick(team)">
                {{ team.approvers.length ? team.approvers.map(i => i.name).join(', ') : '-' }}
              </td>
              <td class="col-action">
                <span class="hover-item">
                  <router-link v-if="hasPermission($permissions.teamsWrite)"
                               v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                               class="btn-link"
                               :to="{ name: 'team-edit', params: { id: team.id } }">
                    <ic-pencil class="ic ic--24 ic--gray" />
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </component-table>
        <component-pagination :pagination="teamsPagination" />
      </template>
      <component-placeholder v-else
                             :label="$t('placeholder.no_team.title')"
                             :content="$t('placeholder.no_team.subtitle')">
        <users-management />
      </component-placeholder>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useTeamStore } from '@/stores/team'
import { EFilter } from '@/types/filter.d'

import TeamDetail from '@/pages/teams/sidepanel/TeamDetail.vue'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import UsersManagement from '@/components/svg/img/users-management.vue'
import ComponentTable from '@/components/Table.vue'
import Tabs from '@/components/Tabs.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcPencil,
    UsersManagement,
    ButtonFilters,
    UserAvatar,
    Tabs,
    ComponentFilters,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const teamStore = useTeamStore()

    const { teams, teamsPagination } = storeToRefs(teamStore)

    return { appStore, teams, teamsPagination, teamStore }
  },

  data () {
    return {
      loading: true,
      filter: {
        search: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      const sections = [
        {
          label: this.$i18n.t('button.teams'),
          route: { name: 'teams' },
          active: true
        }
      ]
      if (this.hasPermission(this.$permissions.usersRead)) {
        sections.unshift({
          label: this.$i18n.t('button.users'),
          route: { name: 'users' },
          active: false
        })
      }
      return sections
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('account-switched', this.filters)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('account-switched', this.filters)
  },

  methods: {
    async initData () {
      this.loading = true
      await this.teamStore.getAllTeams(this.page, this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    filters (filters = {}) {
      this.filter = {
        search: filters.search
      }
    },

    onRowClick (team) {
      this.appStore.showSidePanel(TeamDetail, { id: team.id }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    },

    onDeleteSuccess () {
      this.appStore.closeSidePanel()
      showToastSuccess(this.$t('message.success.team.delete'))
      this.initData()
    }
  }
}
</script>
