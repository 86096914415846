<template>
  <div class="points"
       :class="{ 'points--large': size === EPointsTitleSize.Large }">
    {{ points }}
    <span class="points__indice">
      {{ t('general.points.short') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { EPointsTitleSize } from '@/pages/loyalty/components/PointsTitle.d'

const { t } = useI18n()

defineProps<{
  points: number
  size: EPointsTitleSize

}>()
</script>

<style scoped lang="stylus">
.points
  font-weight 700
  font-size 2.2rem
  color $primaryColor
  display flex

  &--large
    font-size 2.8rem

  &__indice
    margin-top .3rem
    margin-left .6rem
    font-size 1.2rem
</style>
