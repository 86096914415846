<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="teams"
                          :dropdown-width="'100%'"
                          :dropdown-height="24"
                          search
                          async-url="/teams"
                          :async-url-params="{ scenario: 'light' }"
                          :label-filter="teamFilter"
                          :model="modelValue"
                          @select="onSelectTeam">
        <template #trigger>
          <div class="select"
               :class="modelValue ? 'is-clearable' : 'select--off'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue">
                {{ teamFilter(selectedTeam) }}
              </template>
              <template v-else>
                {{ $t('form.team.placeholder') }}
              </template>
            </div>
            <button v-if="modelValue"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$emit('clear-field', 'team_uuid')">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },

  emits: [
    'clear-field',
    'update:modelValue'
  ],

  data () {
    return {
      selectedTeam: {}
    }
  },

  mounted () {
    this.$bus.on('get-filtered-team', this.sendFilteredTeam)
  },

  beforeUnmount () {
    this.$bus.off('get-filtered-team', this.sendFilteredTeam)
  },

  methods: {
    teamFilter (team) {
      return team.name
    },

    onSelectTeam (team) {
      this.selectedTeam = team
      this.$emit('update:modelValue', team.id)
      this.$parent.onSubmit()
    },

    sendFilteredTeam () {
      this.$bus.emit('send-filtered-team', this.selectedTeam)
    }
  }
}
</script>
