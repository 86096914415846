<template>
  <svg viewBox="0 0 84 84">
    <g stroke="#F83B2B"
       fill="none">
      <circle stroke-width="2"
              opacity=".606"
              cx="42"
              cy="42"
              r="41" />
      <path d="M54.728 29.272 29.272 54.728m0-25.456 25.456 25.456"
            stroke-linecap="round"
            stroke-width="1.8" />
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
