<template>
  <svg viewBox="0 0 24 24">
    <path d="M8.25 15.75a3.251 3.251 0 0 1 3.163 2.5h9.837a.75.75 0 0 1 .102 1.493l-.102.007h-9.837a3.251 3.251 0 0 1-6.326 0H2.75a.75.75 0 0 1-.102-1.493l.102-.007h2.337a3.251 3.251 0 0 1 3.163-2.5zm0 1.5a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5zm7.5-8.5a3.251 3.251 0 0 1 3.163 2.5h2.337a.75.75 0 0 1 .102 1.493l-.102.007h-2.337a3.251 3.251 0 0 1-6.326 0H2.75a.75.75 0 0 1-.102-1.493l.102-.007h9.837a3.251 3.251 0 0 1 3.163-2.5zm0 1.5a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5zM8.5 1.75a3.251 3.251 0 0 1 3.163 2.5h9.587a.75.75 0 0 1 .102 1.493l-.102.007h-9.587a3.251 3.251 0 0 1-6.326 0H2.75a.75.75 0 0 1-.102-1.493l.102-.007h2.587A3.251 3.251 0 0 1 8.5 1.75zm0 1.5a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
