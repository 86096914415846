<template>
  <button type="button"
          class="btn"
          :class="[wrapperClass, { 'btn--loading': isLoading }]"
          :disabled="disabled || apiLoading"
          @click="$emit('click')">
    <loader-spinner v-if="isLoading"
                    color="white"
                    :size="isXs ? '16' : '24'" />
    <i v-if="icon && !isLoading"
       class="btn-icon btn-icon--left">
      <slot />
    </i>
    {{ label }}
  </button>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useApiStore } from '@/stores/api'

import LoaderSpinner from './LoaderSpinner.vue'

export default {
  name: 'ComponentButton',

  components: {
    LoaderSpinner
  },

  props: {
    label: {
      type: String,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    wrapperClass: {
      type: String,
      default: ''
    },

    icon: Boolean,

    showLoader: {
      type: Boolean,
      default: true
    }
  },

  emits: ['click'],

  setup () {
    const apiStore = useApiStore()
    const { loading: apiLoading } = storeToRefs(apiStore)
    return { apiLoading }
  },

  computed: {
    isLoading () {
      return (this.loading || this.apiLoading) && this.showLoader
    },

    isXs () {
      return this.wrapperClass.includes('btn--xs')
    }
  }
}
</script>
