<template>
  <div>
    <div class="document-check document-check--success">
      <div class="document-check__header">
        <ic-check-circle outline
                         class="ic ic--white ic--26" />
        <h1>{{ $t('document_check.title.capital_deposit_certificate') }}</h1>
      </div>
      <div class="document-check__content">
        <div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('document_check.details.company_name') }}
            </div>
            <div class="response-row__data">
              {{ data.owner }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('document_check.details.address') }}
            </div>
            <div class="response-row__data">
              {{ data.address }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('document_check.details.certificate_date') }}
            </div>
            <div class="response-row__data">
              {{ data.date }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('document_check.details.deposit_amount') }}
            </div>
            <div class="response-row__data">
              {{ data.amount }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('document_check.details.account_number') }}
            </div>
            <div class="response-row__data">
              {{ data.account_number }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="document-check">
      <h2 class="document-check__list-title">
        {{ $t('document_check.shareholders_list') }}
      </h2>
      <div class="document-check__content">
        <table class="table capital-table--desktop">
          <thead>
            <tr>
              <th>{{ $t('table.shareholder') }}</th>
              <th class="right">
                {{ $t('table.shares_number') }}
              </th>
              <th class="right">
                {{ $t('table.paid_up_capital') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(shareholder, i) in data.shareholders"
                :key="`shareholder_${i}`">
              <td>{{ shareholder.name }}</td>
              <td class="right">
                {{ shareholder.shares }}
              </td>
              <td class="right nowrap">
                {{ shareholder.capital }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="capital-table--mobile">
          <div v-for="(shareholder, i) in data.shareholders"
               :key="`shareholder_${i}`"
               class="capital-table__shareholder">
            <table>
              <h2 class="capital-table__shareholder-title">
                {{ shareholder.name }}
              </h2>
              <tbody>
                <tr>
                  <th>
                    {{ $t('table.shares_number') }}
                  </th>
                  <td class="left">
                    {{ shareholder.shares }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('table.paid_up_capital') }}
                  </th>
                  <td class="left nowrap">
                    {{ shareholder.capital }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('table.premium_included') }}
                  </th>
                  <td class="left nowrap">
                    {{ shareholder.prime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'

export default {
  components: {
    IcCheckCircle
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
