<template>
  <svg viewBox="0 0 24 24">
    <path v-if="outline"
          d="M13.04 3.64a2 2 0 0 1 .669.67l7.998 13.15a2 2 0 0 1-1.709 3.04H4.001a2 2 0 0 1-1.708-3.04L10.29 4.31a2 2 0 0 1 2.748-.67zm-1.015 11.363c-.657 0-1.138.481-1.138 1.139 0 .652.482 1.132 1.138 1.132.655 0 1.131-.48 1.131-1.132 0-.658-.475-1.139-1.131-1.139zm.887-6.503h-1.787l.117 5.613h1.553z" />
    <path v-else
          d="M9.968 4.619c.668-1.087 2.12-1.443 3.241-.797.338.195.621.47.822.797l7.636 12.42c.668 1.087.3 2.492-.822 3.139-.366.21-.784.322-1.21.322H4.365C3.058 20.5 2 19.475 2 18.21c0-.412.115-.816.333-1.17Zm2.462.258a1.054 1.054 0 0 0-1.32.4l-7.636 12.42a.98.98 0 0 0-.146.514c0 .554.464 1.003 1.036 1.003h15.271c.187 0 .37-.049.53-.141a.986.986 0 0 0 .36-1.375L12.89 5.277a1.02 1.02 0 0 0-.36-.35Zm-.406 10.437c.621 0 1.072.454 1.072 1.076 0 .616-.452 1.07-1.072 1.07-.621 0-1.079-.454-1.079-1.07 0-.621.456-1.076 1.079-1.076Zm.84-7.092-.11 6.252h-1.472l-.111-6.252h1.694Z" />
  </svg>
</template>

<script>
export default {
  props: {
    outline: Boolean
  }
}
</script>
