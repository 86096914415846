import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'

import axiosClient from '@/api'

export const useTeamStore = defineStore('team', {
  state: () => {
    return {
      teams: [],
      teamsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },

      teamUsersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  actions: {
    async getAllTeams (page, params = {}) {
      let teams = []

      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/teams', { params })
        this.teamsPagination = this.parsePagination(headers)

        teams = data
      } catch (error) {
        console.error(error)
      }

      this.teams = teams
    },

    async getTeamDetail (teamId) {
      try {
        const { data } = await axiosClient.get(`/teams/${teamId}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getTeamUsers (params = {}) {
      try {
        const { data, headers } = await axiosClient.get('/users', {
          params: {
            scenario: 'light',
            search: params.search,
            page: params.page
          }
        })

        this.teamUsersPagination = this.parsePagination(headers)
        return data
      } catch (error) {
        console.error(error)
      }

      return []
    },

    async createTeam (data) {
      const team = cloneDeep(data)
      team.approvers = team.approvers.map(m => m.uuid)
      team.managers = team.managers.map(m => m.uuid)
      team.members = team.members.map(m => m.uuid)

      try {
        await axiosClient.post('/teams', team)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async editTeam (data) {
      const team = {
        name: data.name,
        approvers: data.approvers.map(m => m.uuid),
        managers: data.managers.map(m => m.uuid),
        members: data.members.map(m => m.uuid)
      }

      try {
        await axiosClient.put(`/teams/${data.id}`, team)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
})
