<template>
  <div ref="periodPicker"
       class="day-period-picker"
       :class="{ 'is-open': open }"
       :data-cy="`day-period-picker.${dataCy}`">
    <div class="day-period-picker__body">
      <span class="prev"
            :class="{ disabled: isPrevMonthDisabled }"
            @click="prevMonth" />
      <span class="next"
            :class="{ disabled: isNextMonthButtonDisabled }"
            @click="nextMonth" />
      <div class="period-picker-calendar">
        <div v-for="i in [0, 1]"
             :key="i"
             class="period-picker-calendar__item">
          <header>
            <span class="month">{{ getMonth(i) }}</span>
          </header>
          <span v-for="d in daysOfWeek"
                :key="d"
                class="cell day-header">{{ d }}</span>
          <span v-for="d in getBlankDays(i)"
                :key="d"
                class="cell day blank" />
          <span v-for="(day, dayIdx) in getDays(i)"
                :key="day.timestamp"
                class="cell day"
                track-by="timestamp"
                :class="{ selected: day.isSelected, between: day.isBetween, disabled: day.isDisabled }"
                :data-cy="`day-period-picker.${dataCy}.${i}.${dayIdx}`"
                @mouseover="onMouseOver(day)"
                @mouseout="onMouseOut"
                @click.stop="selectDate(day)">{{ day.dayObj.date() }}</span>
        </div>
      </div>
    </div>
    <div class="day-period-picker__footer">
      <span class="period-text">
        {{ periodText }}
      </span>

      <div>
        <button class="btn btn--gray"
                type="button"
                @click="cancel">
          {{ $t('button.cancel') }}
        </button>
        <button class="btn btn--default"
                type="button"
                :disabled="isSubmitButtonDisabled"
                :data-cy="`day-period-picker.${dataCy}.submit`"
                @click="periodSelect">
          {{ $t('button.apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

export default {
  name: 'ComponentDatepicker',

  props: {
    dataCy: {
      type: String,
      default: 'default'
    },

    disabledDays: {
      type: [Object, null],
      default: null
    },

    id: {
      type: String,
      default: null
    },

    maxMonthPeriod: {
      type: Number,
      default: null
    },

    period: {
      type: Object,
      default: null
    }
  },

  emits: [
    'period-select',
    'cancel'
  ],

  setup () {
    const periodPicker = ref()
    const open = ref(false)

    onClickOutside(periodPicker, () => {
      open.value = false
    })

    return { open, periodPicker }
  },

  data () {
    return {
      currDate: dayjs(),
      focus: 'from',
      from: null,
      hoverDayObj: null,
      selectedDate: null,
      to: null
    }
  },

  computed: {
    daysOfWeek () {
      return dayjs.weekdaysShort(true)
    },

    isNextMonthButtonDisabled () {
      return this.disabledDays?.after && this.currDate.add(2, 'month').startOf('month').isAfter(this.disabledDays.after, 'day')
    },

    isNextMonthDisabled () {
      return this.disabledDays?.after && this.currDate.add(1, 'month').startOf('month').isAfter(this.disabledDays.after, 'day')
    },

    isPrevMonthDisabled () {
      return this.disabledDays?.before && this.currDate.subtract(1, 'month').endOf('month').isBefore(this.disabledDays.before, 'day')
    },

    isSubmitButtonDisabled () {
      return !this.from || !this.to
    },

    monthsList () {
      const monthsList = []
      let d = dayjs().subtract(6 * this.monthsListIndex, 'month')
      while (monthsList.length < 6) {
        monthsList.push({
          dayObj: d,
          isDisabled: this.isDisabledDate(d.startOf('month')) && this.isDisabledDate(d.endOf('month')),
          isSelected: this.isSelectedMonth(d)
        })
        d = d.subtract(1, 'month')
      }
      return monthsList
    },

    periodText () {
      if (!this.from || !this.to) return ''

      const isSameMonth = dayjs(this.from).isSame(this.to, 'month')
      const isSameYear = dayjs(this.from).isSame(this.to, 'year')
      const fromFormat = isSameMonth ? 'DD' : (isSameYear ? 'DD MMMM' : 'DD MMMM YYYY')
      return this.$i18n.tc('treasury.tooltip.date.from_to', {
        date_end: dayjs(this.to).format('DD MMMM YYYY'),
        date_start: dayjs(this.from).format(fromFormat)
      })
    }
  },

  watch: {
    open (value) {
      if (value) {
        this.to = this.period.to
        this.from = this.period.from
        this.focus = 'from'
        if (this.from) {
          this.currDate = dayjs(this.from)
          if (this.isNextMonthDisabled) {
            this.prevMonth()
          }
        }

        this.periodPicker.style = ''
        window.requestAnimationFrame(() => {
          const element = this.periodPicker.getBoundingClientRect()
          const diff = window.innerWidth - element.x - element.width - 10
          this.periodPicker.style = diff < 0 ? `margin-left: ${diff}px` : ''
        })
      }
    }
  },

  mounted () {
    if (this.isNextMonthDisabled) {
      this.prevMonth()
    }
  },

  methods: {
    cancel () {
      this.togglePicker(false)
      this.$emit('cancel')
    },

    getBlankDays (i) {
      const d = this.currDate.add(i, 'month').startOf('month')

      return d.day() > 0 ? d.day() - 1 : 6
    },

    getDays (i) {
      let dayObj = this.currDate.add(i, 'month').startOf('month')
      const days = []
      const daysInMonth = dayObj.daysInMonth()
      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          dayObj,
          isBetween: this.isBetweenDate(dayObj),
          isDisabled: this.isDisabledDate(dayObj),
          isSelected: this.isSelectedDate(dayObj)
        })
        dayObj = dayObj.add(1, 'day')
      }

      return days
    },

    getMonth (i) {
      return this.currDate.add(i, 'month').format('MMMM YYYY')
    },

    isBetweenDate (dayObj) {
      if (this.hoverDayObj) {
        if (this.focus === 'from' && this.hoverDayObj.isSameOrAfter(this.to, 'day')) return false
        return this.focus === 'from' || this.hoverDayObj.isBefore(this.from, 'day')
          ? dayObj.isBetween(this.hoverDayObj, this.to)
          : dayObj.isBetween(this.from, this.hoverDayObj)
      }

      return !this.isSelectedDate(dayObj) && dayObj.isBetween(this.from, this.to)
    },

    isDisabledDate (dayObj) {
      if (this.maxMonthPeriod !== null && this.focus === 'to' && this.from && dayObj.diff(dayjs(this.from), 'month', true) >= this.maxMonthPeriod) {
        return true
      }

      if (!this.disabledDays) { return false }

      if (this.disabledDays?.before && dayObj.isBefore(this.disabledDays.before, 'day')) {
        return true
      }
      if (this.disabledDays?.after && dayObj.isAfter(this.disabledDays.after, 'day')) {
        return true
      }

      return false
    },

    isSelectedDate (dayObj) {
      let isSelected = false
      if (this.hoverDayObj) {
        if (this.focus === 'from' && this.hoverDayObj.isAfter(this.to, 'day')) {
          return false
        } else if (this.focus === 'from' || this.hoverDayObj.isBefore(this.from, 'day')) {
          isSelected = dayObj.isSame(this.to, 'day')
        } else {
          isSelected = dayObj.isSame(this.from, 'day')
        }
      } else {
        isSelected = dayObj.isSame(this.from, 'day') || dayObj.isSame(this.to, 'day')
      }
      return isSelected
    },

    nextMonth () {
      if (!this.isNextMonthButtonDisabled) {
        this.currDate = this.currDate.add(1, 'month')
      }
    },

    onMouseOut () {
      this.hoverDayObj = null
    },

    onMouseOver (day) {
      if (!day.isDisabled) {
        this.hoverDayObj = day.dayObj
      }
    },

    periodSelect () {
      if (this.isSubmitButtonDisabled) return
      this.togglePicker(false)
      this.$emit('period-select', { from: this.from, to: this.to })
    },

    prevMonth () {
      if (!this.isPrevMonthDisabled) {
        this.currDate = this.currDate.subtract(1, 'month')
      }
    },

    selectDate (day) {
      if (day.isDisabled) return

      if (this.focus === 'from') {
        this.from = day.dayObj.toDate()
        this.focus = 'to'
        if (day.dayObj.isAfter(this.to) || this.isDisabledDate(dayjs(this.to))) this.to = null
        return
      }
      if (this.focus === 'to') {
        if (day.dayObj.isBefore(this.from, 'day')) {
          this.from = day.dayObj.toDate()
        } else {
          this.to = day.dayObj.toDate()
        }
      }
    },

    togglePicker (value = null) {
      if (value === null) {
        this.open = !this.open
      } else {
        this.open = value
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.day-period-picker
  z-index 100
  background white
  font-size 1.5rem
  margin-top 0.5rem
  position absolute
  transition opacity 0.3s $easeOutCirc
  opacity 0
  visibility hidden
  transform-origin top center
  user-select none
  border-radius $border-radius
  border 1px solid $borderColor
  box-shadow 0 7px 10px rgba($colorDarkGray, 0.05)

  &.is-open
    opacity 1
    visibility visible
    transform scale3d(1, 1, 1)
    user-select auto

  &__body
    width auto
    padding 1rem 1.5rem 1.5rem

    .next, .prev
      position absolute
      width 3.7rem
      height 3.7rem
      text-indent -10000px
      display inline-block
      text-align center
      border-radius 2px
      top 1rem

      &:after
        content ''
        position absolute
        left 50%
        top 50%
        transform translateX(-50%) translateY(-50%)
        border 5px solid transparent

      &:not(.disabled)
        cursor pointer
        &:hover
          background $colorLightGray

    .prev
      left 1.5rem
      &:after
        border-right 7px solid $colorMidGray
        margin-left -5px
      &.disabled:after
        border-right-color $colorLightGray

    .next
      right 1.5rem
      &:after
        border-left 7px solid $colorMidGray
        margin-left 5px
      &.disabled:after
        border-left-color $colorLightGray

    .cell
      float left
      font-size 0.95em
      display inline-block
      padding 0 5px
      width calc(100% / 7)
      height 38px
      border-radius 2px
      line-height 38px
      text-align center
      vertical-align middle
      border 1px solid transparent

      &:not(.blank):not(.disabled).day
        cursor pointer
        &:hover
          background $colorLightGray

      &.selected
        background $colorShinyGray
        color white
        font-weight 500

        &:hover
          background $colorShinyGray

      &.day:not(.blank):not(.disabled)
        &.between
          background-color $colorLightGray
          border-radius 0

        &:not(.disabled):hover, &.selected
          border-color $colorShinyGray
          background $colorShinyGray
          color white
          font-weight 500

      &.disabled
        color lighten($colorShinyGray, 50%)
        cursor default

    .period-picker-calendar
      display flex

      &__item
        width 26rem

        &:first-child
          margin-right 4rem

        header
          display flex
          line-height 38px

          .month
            flex 1
            text-align center
            font-weight 500
            text-transform capitalize

  &__footer
    display flex
    justify-content space-between
    align-items center
    padding 1.5rem
    border-top 1px solid $colorLightGray

    .period-text
      color $colorShinyGray
      font-size 1.4rem

    .btn
      margin-left 1rem
</style>
