<template>
  <bar-chart :title="$t('treasury.flow.title')"
             :datasets="data"
             :date-format="flow ? flow.dateGranularity : 'LLL'">
    <template #filter>
      <chart-filter v-model="formFilter"
                    custom-period-type="month"
                    :max-custom-period="12"
                    :values="getFilter"
                    child-data-cy="treasury.flow-all.filter" />
      <loader-spinner v-if="loading"
                      size="16" />
    </template>
    <template #header>
      <div class="d-flex">
        <components-chart-badge :title="$t('treasury.flow.in.title')"
                                color="light-green"
                                class="mr-1" />
        <components-chart-badge :title="$t('treasury.flow.out.title')"
                                color="light-red"
                                class="mr-1" />
        <components-chart-badge :title="$t('treasury.flow.result.title')"
                                color="two-tone" />
      </div>
    </template>
    <template v-if="flow !== null"
              #resume>
      <core-block-summary :items="flowList"
                          :bordered="false"
                          :elevated="false"
                          :size="ECoreBlockSummarySize.Medium">
        <template #title="{ item }">
          {{ item.title }}
        </template>
        <template #subtitle="{ item }">
          <components-chart-badge :title="item.subtitle"
                                  :color="item.color" />
        </template>
      </core-block-summary>
    </template>
  </bar-chart>
</template>

<script>
import { CoreBlockSummary, ECoreBlockSummarySize } from '@common/core-ui'
import dayjs from 'dayjs'

import config from '@/config/config'
import { useTreasuryStore } from '@/stores/treasury'
import { ETreasuryFlowGranularity, ETreasuryFlowOperationType, ETreasuryPeriodFilter } from '@/types/treasury.d'

import BarChart from '@/components/charts/BarChart.vue'
import ComponentsChartBadge from '@/components/charts/ChartBadge.vue'
import ChartFilter from '@/components/charts/ChartFilter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  name: 'TreasuryFlow',
  components: { CoreBlockSummary, LoaderSpinner, ChartFilter, ComponentsChartBadge, BarChart },

  setup () {
    const treasuryStore = useTreasuryStore()

    return { treasuryStore }
  },

  data () {
    return {
      loading: false,
      ETreasuryFlowOperationType,
      ETreasuryFlowGranularity,
      formFilter: {
        from: null,
        to: null,
        periodFilter: ETreasuryPeriodFilter.LastTwelveMonths
      },

      flow: null
    }
  },

  computed: {
    ECoreBlockSummarySize () {
      return ECoreBlockSummarySize
    },

    getFilter () {
      return [ETreasuryPeriodFilter.LastTwelveMonths, ETreasuryPeriodFilter.Year, ETreasuryPeriodFilter.Custom].map(name => {
        return { label: `treasury.filter.${name}`, name }
      })
    },

    dateFormat () {
      if (this.flow.granularity === ETreasuryFlowGranularity.Day) return 'DD'
      else if (this.flow.granularity === ETreasuryFlowGranularity.Week) return 'ddd'
      else if (this.flow.granularity === ETreasuryFlowGranularity.Month) return 'MMM'
      else return 'YYYY'
    },

    data () {
      const itemsIn = this.flow?.operations?.filter(item => item.type === ETreasuryFlowOperationType.In).map(item => ({ x: item.accountingAtFormatted, y: item.amount, xTooltip: item.accountingAtFormat('MMMM YYYY'), yTooltip: item.amountFormatted })) || []
      const itemsOut = this.flow?.operations?.filter(item => item.type === ETreasuryFlowOperationType.Out).map(item => ({ x: item.accountingAtFormatted, y: item.amount, xTooltip: item.accountingAtFormat('MMMM YYYY'), yTooltip: item.amountFormatted })) || []
      const colorsResults = this.flow?.operations?.filter(item => item.type === ETreasuryFlowOperationType.Result).map(item => {
        return item.amount > 0 ? config.CHART.COLOR.GREEN : config.CHART.COLOR.RED
      }) || []

      return [
        {
          label: this.$t('treasury.flow.in.title'),
          data: itemsIn,
          backgroundColor: Array(itemsIn.length).fill(config.CHART.COLOR.GREEN_300),
          hoverBackgroundColor: Array(itemsIn.length).fill(config.CHART.COLOR.GREEN_400)
        },
        {
          label: this.$t('treasury.flow.out.title'),
          data: itemsOut,
          backgroundColor: Array(itemsOut.length).fill(config.CHART.COLOR.RED_200),
          hoverBackgroundColor: Array(itemsOut.length).fill(config.CHART.COLOR.RED_300)
        },
        {
          label: this.$t('treasury.flow.result.title'),
          data: this.flow ? this.flow.operations.filter(item => item.type === ETreasuryFlowOperationType.Result).map(item => ({ x: item.accountingAtFormatted, y: item.positiveAmount, xTooltip: item.accountingAtFormat('MMMM YYYY'), yTooltip: item.amountFormatted })) : [],
          backgroundColor: colorsResults,
          hoverBackgroundColor: colorsResults
        }
      ]
    },

    flowList () {
      if (!this.flow) {
        return []
      }

      return [
        {
          subtitle: this.$tc('treasury.flow.resume.total_in'),
          title: this.flow.totalInAmountFormatted,
          color: 'light-green'
        },
        {
          subtitle: this.$tc('treasury.flow.resume.total_out'),
          title: this.flow.totalOutAmountFormatted,
          color: 'light-red'
        },
        {
          subtitle: this.$tc('treasury.flow.resume.total_result'),
          title: this.flow.totalResultAmountFormatted,
          color: this.getTotalResultColor
        }
      ]
    },

    getTotalResultColor () {
      return this.flow.total_result_amount > 0 ? 'green' : 'red'
    }
  },

  watch: {
    formFilter: {
      handler () {
        this.initData()
      },

      deep: true
    }
  },

  mounted () {
    this.initData()
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      this.loading = true

      const params = {
        granularity: ETreasuryFlowGranularity.Month
      }
      if (this.formFilter.periodFilter === ETreasuryPeriodFilter.Custom) {
        params.from = dayjs(this.formFilter.from).format('YYYY-MM')
        params.to = dayjs(this.formFilter.to).format('YYYY-MM')
      } else {
        params.period_filter = this.formFilter.periodFilter
      }

      this.flow = await this.treasuryStore.getTreasuryFlow(params)
      this.loading = false
    }
  }
}
</script>
