<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <validation-form v-else
                   ref="form"
                   v-slot="{ errors, isSubmitting }"
                   class="sidepanel__content"
                   :validation-schema="schema"
                   @keydown.enter.prevent
                   @submit="onSubmit"
                   @invalid-submit="onInvalidSubmit">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ title }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <div class="form">
          <component-alert v-if="Object.keys(errors).length"
                           type="error"
                           :message="$t('form.alerts.credit')" />
          <div class="form__row row">
            <div class="form-group col col--sm-12"
                 :class="{ error: errors.label, 'is-valid': imported.label }">
              <label class="label input__label">{{ $t("general.reason") }}</label>
              <validation-field id="label"
                                v-model="credit.label"
                                type="text"
                                class="form-control"
                                :placeholder="$t('form.income.name.placeholder')"
                                name="label"
                                @input="removeValidClass('label')" />
            </div>
            <div class="form-group col col--sm-6"
                 :class="{ error: errors.amount, 'is-valid': imported.amount }">
              <label class="label input__label"
                     for="amount">{{ $t("form.amount.label") }}</label>
              <div class="input-group">
                <number-input id="amount"
                              v-model="credit.amount"
                              name="amount"
                              class="form-control"
                              :placeholder="$t('form.amount_ttc.placeholder')"
                              decimal
                              :minimum-fraction-digits="2"
                              @update:model-value="removeValidClass('amount')" />
                <span class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
              </div>
            </div>
            <div class="form-group col col--sm-6"
                 :class="{ error: errors.reference, 'is-valid': imported.reference }">
              <label class="label input__label">{{ $t("form.reference.label") }}</label>
              <validation-field id="reference"
                                v-model="credit.reference"
                                type="text"
                                class="form-control"
                                :placeholder="$t('form.invoice_reference.placeholder')"
                                name="reference"
                                @input="removeValidClass('reference')" />
            </div>
          </div>
          <div class="form__row row">
            <component-datepicker v-model="credit.date"
                                  name="date"
                                  :class="{ error: errors.date, 'is-valid': imported.date }"
                                  :label="$t('form.billing_date.label')"
                                  :language="$i18n.locale"
                                  format="dd/MM/yyyy"
                                  wrapper-class="form-group col col--sm-6"
                                  input-class="form-control"
                                  monday-first
                                  :readonly="false"
                                  :placeholder="$t('form.date.placeholder')"
                                  @selected="removeValidClass('date')" />
            <div class="form-group col col--sm-6"
                 :class="{ error: errors.client_email, 'is-valid': imported.client_email }">
              <label class="label input__label">
                {{ $t("form.client_email.label") }} ({{ $t("general.optional") }})</label>
              <input id="client_email"
                     v-model="credit.client_email"
                     type="text"
                     class="form-control"
                     :placeholder="$t('form.client_email.placeholder')"
                     name="client_email"
                     @input="removeValidClass('client_email')">
            </div>
          </div>
          <div class="form__row row">
            <div class="form-group col col--sm-12">
              <label class="label input__label">
                {{ $t('placeholder.attach_invoice') }}
              </label>
              <dropzone v-model="creditDocument"
                        :max-files="1"
                        accepted-files="image/jpg,image/jpeg,image/png,image/tif,image/tiff,application/pdf"
                        mode="inline"
                        name="credit_document"
                        @on-file-added="files => onDocumentAttached({ files })">
                <button v-if="showAnalyzeButton"
                        v-tooltip="{ content: $t('button.analyze_document'), theme: 'tooltip' }"
                        type="button"
                        class="btn-link"
                        @click.prevent.stop="analyzeFile">
                  <ic-analyze class="ic ic--20 ic--gray" />
                </button>
              </dropzone>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="form-buttons">
        <button class="btn btn--gray"
                type="button"
                @click="appStore.closeSidePanel">
          {{ $t('button.cancel') }}
        </button>
        <component-button :label="$t('button.validate')"
                          :disabled="isSubmitting"
                          type="submit"
                          class="btn btn--primary" />
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { ModelCredit } from '@/models/Credit/ModelCredit'
import { ModelDocument } from '@/models/Document/ModelDocument'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useCreditStore } from '@/stores/credit'

import FinishAnalyzeInvoice from '@/pages/transfers/modal/FinishAnalyzeInvoice.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import Dropzone from '@/components/Dropzone.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import DocumentAnalyze from '@/components/modals/DocumentAnalyze.vue'
import NumberInput from '@/components/NumberInput.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcAnalyze from '@/components/svg/icons/ic-analyze.vue'

export default {
  components: {
    LoaderSpinner,
    IcAnalyze,
    ComponentDatepicker,
    ComponentAlert,
    ComponentButton,
    ComponentSidepanelFooter,
    Dropzone,
    NumberInput
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const creditStore = useCreditStore()

    const { error: apiError } = storeToRefs(apiStore)
    const { account } = storeToRefs(accountStore)

    return {
      appStore,
      creditStore,

      account,
      apiError
    }
  },

  data () {
    return {
      loading: false,
      credit: null,

      imported: {},
      schema: yup.object({
        amount: yup.string().required(),
        date: yup.string().required(),
        label: yup.string().required(),
        reference: yup.string().required()
      }),

      showAnalyzeButton: false
    }
  },

  computed: {
    title () {
      return this.model.id
        ? this.$i18n.t('title.edit_credit')
        : this.$i18n.t('title.new_credit')
    },

    creditDocument: {
      get () {
        return this.credit.document ? [this.credit.document] : []
      },

      set (value) {
        this.credit.document = value[0] || null
      }
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        if (Array.isArray(this.apiError?.fields)) {
          this.apiError?.fields?.forEach(item => {
            this.$refs.form.setFieldError(item.field, item.message)
          })
        }
      }
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('credits-save-success', this.onSuccess)
    this.$bus.on('credits-update-success', this.onSuccess)
    this.$bus.on('on-file-attached', this.onDocumentAttached)
    this.$bus.on('credit-document-analyzed', this.onDocumentAnalyzed)
  },

  beforeUnmount () {
    this.$bus.off('credits-save-success', this.onSuccess)
    this.$bus.off('credits-update-success', this.onSuccess)
    this.$bus.off('on-file-attached', this.onDocumentAttached)
    this.$bus.off('credit-document-analyzed', this.onDocumentAnalyzed)
  },

  methods: {
    getCurrencySymbol,

    async initData () {
      if (!this.model.id) {
        this.credit = ModelCredit.create()
        return
      }

      this.loading = true
      const credit = await this.model.getCredit()
      if (!credit) {
        this.appStore.closeSidePanel()
      }

      this.credit = credit
      this.loading = false
    },

    onInvalidSubmit () {
      this.$bus.emit('scrollToTop')
    },

    async onSubmit () {
      this.model.id
        ? await this.creditStore.updateCredit(this.credit)
        : await this.creditStore.saveCredit(this.credit)
    },

    onSuccess () {
      this.appStore.closeSidePanel()
    },

    onFileAdded (files) {
      this.credit.document = ModelDocument.createFromFile(files[0])
    },

    removeFile () {
      this.credit.document = null
      Object.entries(this.imported).forEach(([key, value]) => {
        if (value) {
          this.credit[key] = ''
        }
      })
      this.imported = {}
    },

    removeValidClass (key) {
      if (this.imported[key]) {
        this.imported[key] = false
      }
    },

    async analyzeFile () {
      if (this.credit.document) {
        this.appStore.showModal(
          DocumentAnalyze,
          {
            title: this.$i18n.t('prompt.transfer.document_analyze.title'),
            content: this.$i18n.t('prompt.transfer.document_analyze.content'),
            type: 'credit',
            fileToAnalyze: this.credit
          },
          { wrapperClass: 'modal-document-analyze' }
        )
      }
    },

    onDocumentAnalyzed (result) {
      this.credit.setFields(result)
      this.imported = {}

      Object.keys(result).forEach(key => {
        if (result[key] && Object.prototype.hasOwnProperty.call(this.credit, key)) {
          this.imported[key] = true
        }
      })
      this.appStore.showModal(FinishAnalyzeInvoice, { imported: this.imported }, { wrapperClass: 'modal-fixed-size' })
      this.showAnalyzeButton = false
    },

    onDocumentAttached ({ files, isAnalyzing = false }) {
      this.onFileAdded(files)
      if (!isAnalyzing) {
        this.appStore.closeModal()
        this.showAnalyzeButton = true
      }
    }
  }
}
</script>
