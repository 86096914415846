<template>
  <modal-dialog-prompt data-cy="cards.settings.holiday-country.container"
                       :title="$t('cards_settings.holiday_country.title')"
                       :subtitle="$t('cards_settings.holiday_country.description')"
                       @close="appStore.closeModal">
    <template #body>
      <div class="pb-2">
        <div class="row">
          <div class="col col--sm-12">
            <component-dropdown data-cy="holiday-country"
                                :model="selectedCountry"
                                :label-filter="labelFilter"
                                async-url="/cards/settings/countries-holidays"
                                dropdown-width="100%"
                                @select="value => selectedCountry = value">
              <template #trigger>
                <div class="select form-control">
                  <span v-if="selectedCountry"
                        :data-cy="`dropdown-holiday-country.${selectedCountry}`"
                        class="flag">
                    <img class="flag__img"
                         :src="getFlagSrc(selectedCountry)">{{ getCountryName(selectedCountry) }}
                    <span v-if="isDefaultCountry(selectedCountry)"
                          class="default mr-2">{{ $t('general.by_default') }}</span>
                  </span>
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
      </div>
      <transition name="show-toggle">
        <div v-if="!isDefaultCountry(selectedCountry)"
             class="form-check">
          <label data-cy="cards.settings.holiday-country.save-as-default"
                 class="form-check__label checkbox">
            <input v-model="useCountryAsDefault"
                   type="checkbox">
            <span class="checkbox__icon" />
            <span>{{ $t('cards_settings.holiday_country.save_as_default_country') }}</span>
          </label>
        </div>
      </transition>
    </template>
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="appStore.closeModal">
        {{ $t('button.cancel') }}
      </button>
      <component-button show-loader
                        :label="$t('button.validate')"
                        class="btn btn--primary"
                        data-cy="cards.settings.holiday_country.submit"
                        @click="onSubmit" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { storeToRefs } from 'pinia'

import { getCountryName, getFlagSrc } from '@/helpers/utils/intl'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ComponentDropdown,
    ModalDialogPrompt,
    ComponentButton
  },

  props: {
    holidayCountry: {
      type: String,
      required: true
    },

    callbackFunction: {
      type: Function,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, accountStore, appStore }
  },

  data () {
    return {
      selectedCountry: this.holidayCountry,
      useCountryAsDefault: false
    }
  },

  created () {
    this.$bus.on('card-updated', this.onCardUpdated)
  },

  beforeUnmount () {
    this.$bus.off('card-updated', this.onCardUpdated)
  },

  methods: {
    getCountryName,
    getFlagSrc,

    isDefaultCountry (country) {
      return this.account.settings.default_card_country_holidays === country
    },

    labelFilter (value) {
      let label = `<img class="flag__img" src="${getFlagSrc(value)}">${getCountryName(value)}`
      if (this.isDefaultCountry(value)) {
        label += `<span class="default">${this.$i18n.t('general.by_default')}</span>`
      }
      return `<span class="flag">${label}</span>`
    },

    onCardUpdated () {
      this.appStore.closeModal()
    },

    async onSubmit () {
      if (this.useCountryAsDefault && !this.isDefaultCountry(this.selectedCountry)) {
        await this.accountStore.updateAccountSettings({ default_card_country_holidays: this.selectedCountry })
      }

      this.callbackFunction('country_holidays', this.selectedCountry)
    }
  }
}
</script>

<style lang="stylus" scoped>
.dropdown-custom:deep(.flag)
  display inline-flex
  width 100%
  .default
    margin-left auto
    font-style italic
    color $gray-400

.show-toggle-enter-to
.show-toggle-leave-from
  height 2.467rem
</style>
