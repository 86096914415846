import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { useAccountStore } from '@/stores/account'

type Params = {
  page: number
  search: string
  state: string
  status: string
}

type Category = {
  uuid: string
}

type Affectation = {
  uuid: string
}

type ExpenseRequestPayload = {
  uuid: string
  detail: {
    categories: Category[]
    affectations: Affectation[]
  }
  comment: string
}

export const useExpenseRequestStore = defineStore('expense-requests', {
  state: () => {
    return {
      expenseRequests: [],
      expenseRequestsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      counts: {
        toApprove: 0,
        inProgress: 0
      }
    }
  },

  actions: {
    async getExpenseRequests (state: string, page: number, params: Params) {
      let expenseRequests = []

      if (state === 'pending') {
        params.status = state
      } else {
        params.state = state
      }

      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/requests', { params })
        this.expenseRequestsPagination = this.parsePagination(headers)
        expenseRequests = data
      } catch (error) {
        console.error(error)
      }

      this.expenseRequests = expenseRequests
    },

    async getExpenseRequestsCount () {
      try {
        const { headers: toApproveHeaders } = await axiosClient.head('/requests', { params: { state: 'to_approve' } })
        this.counts.toApprove = this.parsePagination(toApproveHeaders).totalItems
        useAccountStore().updateExpenseRequestsWaitingValidationCount(this.counts.toApprove)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getExpenseRequest (uuid: string) {
      let expenseRequest = {}
      try {
        const { data } = await axiosClient.get(`/requests/${uuid}`)
        expenseRequest = data
      } catch (error) {
        console.error(error)
      }

      return expenseRequest
    },

    async updateExpenseRequest (uuid: string, payload: ExpenseRequestPayload) {
      try {
        const { data } = await axiosClient.put(`requests/${uuid}`, payload)
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async sendDocuments (uuid: string, files: Blob[]) {
      const payload = new FormData()

      Array.from(files).forEach(file => payload.append('files[]', file))

      try {
        const { data } = await axiosClient.post(`/requests/${uuid}/documents`, payload)
        return data.documents
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async rejectExpenseRequest (uuid: string, reason: string) {
      try {
        await axiosClient.put(`/requests/${uuid}/reject`, { reason })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async approveExpenseRequest (uuid: string) {
      try {
        await axiosClient.put(`/requests/${uuid}/accept`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async cancelExpenseRequest (uuid: string) {
      try {
        await axiosClient.delete(`/requests/${uuid}`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async approveAllExpenseRequest () {
      try {
        await axiosClient.put('/requests/accept-all')
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
})
