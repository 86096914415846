<template>
  <svg viewBox="0 0 28 28">
    <path d="M14 3a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h10Zm0 1H4a1 1 0 0 0-.993.883L3 5v18a1 1 0 0 0 .883.993L4 24h10a1 1 0 0 0 .993-.883L15 23V5a1 1 0 0 0-.883-.993L14 4Z" />
    <path d="M11.5 4v.5a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V4h5Zm12.558 3.693c2.752 2.685 2.568 5.928 2.568 5.928 0 2.835-1.332 4.766-2.578 6.001a.565.565 0 0 0-.228.448c0 .323.283.584.633.584a.658.658 0 0 0 .464-.186s2.994-2.539 2.994-6.799c0-4.356-2.994-6.83-2.994-6.83a.658.658 0 0 0-.464-.185c-.35 0-.633.261-.633.583 0 .185.093.35.238.456Zm-.744 6.38c0 1.568-.704 2.967-1.827 3.965l.009.007a.568.568 0 0 0-.13.36c0 .333.291.602.652.602a.681.681 0 0 0 .455-.17l.007.005c1.33-1.22 2.158-2.909 2.158-4.785 0-1.867-.827-3.551-2.157-4.77l-.008.007a.68.68 0 0 0-.455-.17c-.36 0-.653.27-.653.601 0 .135.049.26.13.36l-.006.006c1.13 1.01 1.825 2.427 1.825 3.983Zm-3.262-.145c0 1.627-1.141 1.661-1.141 2.256 0 .33.29.6.647.6.171 0 .472-.139.472-.139.81-.668 1.335-1.636 1.335-2.716 0-1.069-.512-2.055-1.332-2.737 0 0-.56-.443-.97.086-.31.399.034.776.225.964.453.445.764 1.033.764 1.686Z" />
  </svg>
</template>

<script>
export default {
}
</script>
