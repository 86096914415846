<template>
  <core-loader v-if="loading"
               class="c c--vh" />
  <div v-else-if="reward"
       class="sidepanel__content reward-detail"
       :class="{ 'reward-detail--disabled': isLocked }">
    <div class="reward-detail__background">
      <image-api :url="reward.picture.url"
                 class="reward-detail__background__picture" />
      <span v-if="isLocked"
            class="reward-detail__background__overlay">
        <core-icon :name="ECoreIconList.Cadenas"
                   :size="20"
                   :theme="ECoreIconTheme.White" />
        {{ t('general.locked') }}
      </span>
    </div>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <user-avatar :picture="reward.partner.logo"
                   elevated
                   class="reward-detail__logo"
                   :size="80"
                   :uuid="reward.uuid"
                   :rounded="false" />
      <div class="sidepanel-body reward-detail__body">
        <core-alert v-if="isAlertVisible"
                    :icon-name="ECoreIconList.WarningFilled"
                    :theme="ECoreAlertTheme.Primary"
                    :text="alertText" />
        <div class="reward-detail__body__partner">
          {{ reward.partner.label }}
        </div>
        <div class="reward-detail__body__label">
          {{ reward.label }}
        </div>
        <points-title class="reward-detail__body__points"
                      :points="reward.points"
                      :size="EPointsTitleSize.Large" />
        <div class="reward-detail__body__description">
          {{ reward.description }}
        </div>
        <div class="reward-detail__body__link">
          <a target="_blank"
             :href="reward.gcu_url">
            {{ t('loyalty.gcu.see') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreAlert, CoreIcon, CoreLoader, ECoreAlertTheme, ECoreIconList, ECoreIconTheme } from '@common/core-ui'

import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { useLoyaltyStore } from '@/stores/loyalty'
import { IReward } from '@/types/loyalty.d'

import { EPointsTitleSize } from '@/pages/loyalty/components/PointsTitle.d'
import PointsTitle from '@/pages/loyalty/components/PointsTitle.vue'

import ImageApi from '@/components/ImageApi.vue'
import UserAvatar from '@/components/UserAvatar.vue'

const { t } = useI18n()
const loyaltyStore = useLoyaltyStore()

const loading = ref(true)
const reward = ref<IReward | null>(null)

const props = defineProps<{
  uuid: string
}>()

onMounted(() => {
  initData()
})

const canClaimReward = computed(() => {
  return hasPermission(permissions.loyaltyRewardsClaim, reward.value)
})

const isLocked = computed(() => {
  return !reward.value?.has_required_points
})

const isAlertVisible = computed(() => {
  return !canClaimReward.value || isLocked.value
})

const alertText = computed(() => {
  if (isLocked.value) {
    return t('loyalty.reward.alert.points')
  }
  return t('loyalty.reward.alert.default')
})

async function initData () {
  loading.value = true
  reward.value = await loyaltyStore.getReward(props.uuid)
  loading.value = false
}
</script>

<style lang="stylus" scoped>
.reward-detail
  &__background
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content center
    align-items center

    &__picture
      flex 1
      height 27rem
      overflow hidden

    &__overlay
      position absolute
      display flex
      gap 1rem
      align-items center
      z-index 10
      color $colorWhite
      font-weight 700
      text-transform uppercase

  &__logo
    position absolute
    margin-top -6rem
    margin-left 4rem

  &__body
    &__partner
      margin-top 2.8rem
      font-size 1.3rem
      text-transform uppercase
      color $colorShinyGray

    &__label
      margin-top 1.4rem
      font-size 2.2rem
      font-weight 700
      color $colorDarkGray

    &__points
      margin-top 1.2rem

    &__description
      margin-top 1.8rem

    &__link
      margin-top 1.8rem

  &--disabled
    :deep(.points)
      color $disabledColor
    .reward-detail
      &__background__picture
        filter brightness(60%)
</style>
