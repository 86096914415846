<template>
  <svg viewBox="0 0 24 24">
    <path d="M12.5 6a.5.5 0 0 1 .5.5V11h4.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H13v4.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V13H6.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H11V6.5a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
