<template>
  <div class="document-check document-check--success">
    <div class="document-check__header">
      <ic-check-circle outline
                       class="ic ic--white ic--26" />
      <h1>{{ $t('document_check.title.closing_account_certificate') }}</h1>
    </div>
    <div class="document-check__content">
      <div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.company_name') }}
          </div>
          <div class="response-row__data">
            {{ data.owner }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.address') }}
          </div>
          <div class="response-row__data">
            {{ data.address }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.siren') }}
          </div>
          <div class="response-row__data">
            {{ data.siren }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.account_number') }}
          </div>
          <div class="response-row__data">
            {{ data.account_number }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.certificate_date') }}
          </div>
          <div class="response-row__data">
            {{ data.date }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.closing_date') }}
          </div>
          <div class="response-row__data">
            {{ data.closing_date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'

export default {
  components: {
    IcCheckCircle
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
