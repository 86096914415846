<template>
  <div>
    <div class="d-flex">
      <div class="time-column">
        <div class="time-column__title">
          {{ $t('cards_settings.authorized_days.start_label') }}
        </div>
        <div class="time-values">
          <div v-for="(item, index) in hoursStart"
               :key="index"
               :class="{ active: item.selected === true }"
               class="time-values__item"
               @click="selectHour(item, 'start')">
            {{ item.time }}
          </div>
        </div>
      </div>
      <div class="time-column">
        <div>
          <div class="time-column__title">
            {{ $t('cards_settings.authorized_days.end_label') }}
          </div>
          <div class="time-values">
            <div v-for="(item, index) in hoursEnd"
                 :key="index"
                 :class="{ active: item.selected === true }"
                 class="time-values__item"
                 @click="selectHour(item, 'end')">
              {{ item.time }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHoursOfDay } from '@/helpers/utils/date'

export default {
  props: {
    start: {
      type: String,
      required: true
    },

    end: {
      type: String,
      required: true
    }
  },

  emits: ['change-start', 'change-end'],

  data () {
    return {
      hoursStart: [],
      hoursEnd: []
    }
  },

  watch: {
    end (val) {
      this.hoursEnd.forEach(el => { el.selected = false })
      if (this.hoursEnd.filter(el => el.time === val).length > 0) {
        this.hoursEnd.filter(el => el.time === val)[0].selected = true
      }
    },

    start (val) {
      this.hoursStart.forEach(el => { el.selected = false })
      if (this.hoursStart.filter(el => el.time === val).length > 0) {
        this.hoursStart.filter(el => el.time === val)[0].selected = true
      }
    }
  },

  created () {
    this.hoursStart.push({
      time: this.$i18n.t('general.none'),
      selected: false
    })
    this.hoursEnd.push({
      time: this.$i18n.t('general.none'),
      selected: false
    })
    getHoursOfDay().forEach(el => {
      this.hoursStart.push({ time: el, selected: false })
      this.hoursEnd.push({ time: el, selected: false })
    })
    if (this.hoursStart.filter(el => el.time === this.start).length > 0) {
      this.hoursStart.filter(el => el.time === this.start)[0].selected = true
    }
    if (this.hoursEnd.filter(el => el.time === this.end).length > 0) {
      this.hoursEnd.filter(el => el.time === this.end)[0].selected = true
    }
  },

  methods: {
    selectHour (item, hours) {
      if (hours === 'start') {
        this.hoursStart.forEach(el => { el.selected = false })
        this.$emit('change-start', item.time)
      } else {
        this.hoursEnd.forEach(el => { el.selected = false })
        this.$emit('change-end', item.time)
      }
      item.selected = true
    }
  }
}
</script>

<style lang="stylus">
.time-column
  width 18.5rem
  padding-top 1.6rem
  &:not(:last-child)
    border-right 1px solid #E4E6E7 //borderColor

  &__title
    text-align center
    font-weight 500
    margin-bottom 1.6rem
    color #424853//$colorMidGray

.time-values
  max-height 22.8rem
  overflow auto
  padding-left 1.6rem
  padding-right @padding-left
  &__item
    padding .8rem 0
    border-radius 3px
    cursor pointer
    &.active
      background-color #F5F6F9 //gray-100
      color #292D35//$colorDarkGray
      font-weight 500
    &:hover
      color #292D35//$colorDarkGray
    &:last-child
      margin-bottom 1.6rem
</style>
