<template>
  <svg viewBox="0 0 90 90">
    <path d="M35.167 43.001V44H20v-.999h15.167ZM41 38.334v.999H20v-.999h21Zm-9.333-4.667v.999H20v-.999h11.667Zm3.5-4.667v.999H20V29h15.167Z"
          :fill="colorGrey" />
    <path d="M14.169 18.005h62.662a2.98 2.98 0 0 1 2.3.899c.604.618.917 1.47.863 2.305l-.001 39.946a3.07 3.07 0 0 1-.862 2.338 2.963 2.963 0 0 1-2.272.9H53.144l.782 5.281c.514 2.349 2.295 4.317 4.166 4.317V75H32.49v-1.009c1.871 0 3.652-1.968 4.16-4.282l.788-5.317h-23.27a2.98 2.98 0 0 1-2.3-.899 3.047 3.047 0 0 1-.862-2.306l.001-39.945a3.07 3.07 0 0 1 .862-2.338 2.98 2.98 0 0 1 2.3-.9Zm62.69 1.008-62.745-.001a1.985 1.985 0 0 0-1.532.599 2.078 2.078 0 0 0-.576 1.598v32.597h66.988l.001-32.63a2.055 2.055 0 0 0-.577-1.565 2.003 2.003 0 0 0-1.56-.599Zm2.135 35.802H12.005v6.405a2.055 2.055 0 0 0 .577 1.566c.403.413.96.63 1.56.598h62.744a1.985 1.985 0 0 0 1.532-.598 2.08 2.08 0 0 0 .576-1.599v-6.372ZM52.943 69.858l-.81-5.462H38.448l-.814 5.496c-.356 1.625-1.237 3.106-2.393 4.05l-.064.049h20.232l-.066-.052c-1.1-.9-1.95-2.288-2.341-3.836l-.058-.245Z"
          :fill="colorDark" />
    <path d="M60 27c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10Zm6.517 4.543a8.5 8.5 0 0 1-11.974 11.974l11.974-11.974Zm-1.06-1.06L53.483 42.457a8.5 8.5 0 0 1 11.974-11.974Z"
          :fill="colorPrimary" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
