<template>
  <div class="timeout-circle"
       :style="`width: ${(radius + strokeWidth) * 2}px; height: ${(radius + strokeWidth) * 2}px`">
    <div class="timeout-remaining">
      {{ fancyTimeFormat(remainingTime) }}
    </div>
    <svg class="amount-gauge"
         :style="`width: ${(radius + strokeWidth) * 2}px; height: ${(radius + strokeWidth) * 2}px`">
      <path id="circle-background"
            stroke-linecap="round"
            fill="none"
            stroke="#F0F2F6"
            :stroke-width="strokeWidth" />
      <path v-show="radiusFill"
            id="circle-fill"
            transform="rotate(0 90 0)"
            stroke-linecap="round"
            fill="none"
            :stroke="colorPrimaryVivid"
            :stroke-width="strokeWidth" />
    </svg>
  </div>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    strokeWidth: {
      type: Number,
      default: 3
    },

    radius: {
      type: Number,
      default: 40
    },

    time: {
      type: Number,
      default: 30
    },

    colorPrimaryVivid: {
      type: String,
      default: config.SVG.COLOR.PRIMARY_VIVID
    }
  },

  emits: ['ended'],

  data () {
    return {
      percentage: 100,
      radiusFill: 359.9,
      remainingTime: 0,
      startedAt: null,
      loop: null,
      loopIteration: null
    }
  },

  mounted () {
    this.remainingTime = this.time
    document.getElementById('circle-background').setAttribute('d', this.describeArc(this.radius + this.strokeWidth, this.radius + this.strokeWidth, this.radius, 0, 359.9))
    document.getElementById('circle-fill').setAttribute('d', this.describeArc(this.radius + this.strokeWidth, this.radius + this.strokeWidth, this.radius, 0, 359.9))
    this.fillCircle()
    this.init()
  },

  beforeUnmount () {
    cancelAnimationFrame(this.loop)
    cancelAnimationFrame(this.loopIteration)
  },

  methods: {
    init () {
      this.startedAt = Date.now()
      this.loop = requestAnimationFrame(this.update)
    },

    updateCircle (elapsedTime) {
      this.remainingTime -= elapsedTime
      this.percentage = this.remainingTime / this.time * 100
      this.radiusFill = Math.round(this.percentage * 360 / 100)
      if (this.remainingTime > 0) {
        this.fillCircle()
      }
    },

    update () {
      const elapsedTime = Date.now() - this.startedAt

      if (this.remainingTime < 1) {
        this.$emit('ended')
        return
      }

      if (elapsedTime < 1000) {
        this.loopIteration = requestAnimationFrame(this.update)
        return
      }

      this.updateCircle(elapsedTime / 1000)
      this.init()
    },

    fillCircle () {
      const circleFill = document.getElementById('circle-fill')
      if (this.radiusFill && circleFill) {
        const circumference = 2 * Math.PI * this.radius
        const offset = circumference * (1 - this.percentage / 100)

        circleFill.setAttribute('stroke-dasharray', `${circumference} ${circumference}`)
        circleFill.setAttribute('stroke-dashoffset', `${offset}`)
      }
    },

    polarToCartesian (centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = ((angleInDegrees) * Math.PI / 180.0)

      return {
        x: (centerX + (radius * Math.cos(angleInRadians))),
        y: centerY + (radius * Math.sin(angleInRadians))
      }
    },

    describeArc (x, y, radius, startAngle, endAngle) {
      const start = this.polarToCartesian(x, y, radius, endAngle + 180)
      const end = this.polarToCartesian(x, y, radius, startAngle + 180)

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

      const d = [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(' ')

      return d
    },

    fancyTimeFormat (time) {
      const hrs = ~~(time / 3600)
      const mins = ~~((time % 3600) / 60)
      const secs = ~~time % 60

      if (hrs <= 0 && mins <= 0 && secs <= 0) { return '0:00' }

      let ret = ''

      if (hrs > 0) {
        ret += `${hrs}:${mins < 10 ? '0' : ''}`
      }

      ret += `${mins}:${secs < 10 ? '0' : ''}`
      ret += `${secs}`
      return ret
    }
  }
}
</script>

<style lang="stylus" scoped>
#circle-fill
  transition: stroke-dashoffset 1s linear
</style>
