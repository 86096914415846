<template>
  <div class="approvers-list"
       :class="{ 'approvers-list--pending': pending }">
    <div class="approvers-list__head">
      <ic-clock v-if="pending"
                class="ic ic--16 ic--dark" />
      <h3>{{ $t(`sidepanel.expense_request.approval.title.${pending ? 'pending' : 'done'}`) }}</h3>
    </div>
    <p v-if="pending"
       class="approvers-list__description">
      {{ approvers.length ? $t(`sidepanel.expense_request.approval.description.${type}`) : $t('sidepanel.expense_request.approval.description.hidden') }}
    </p>
    <component-timeline v-if="approvers.length"
                        :key="approvers.length"
                        type="lite">
      <template #body>
        <component-timeline-item v-for="(approver, index) in approvers"
                                 :id="index"
                                 :key="index"
                                 type="workflow"
                                 :title="approver.user.name"
                                 :subtitle="approverStatusLabel(approver)"
                                 :is-last-item="index === approvers.length - 1"
                                 :status="approverIcon(approver).status"
                                 :color="approverIcon(approver).color"
                                 icon-processing="pending"
                                 icon-processing-size="16" />
      </template>
    </component-timeline>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'

import IcClock from '@/components/svg/icons/ic-clock.vue'
import ComponentTimeline from '@/components/timeline/Timeline.vue'
import ComponentTimelineItem from '@/components/timeline/TimelineItem.vue'

export default {
  components: {
    ComponentTimeline,
    ComponentTimelineItem,
    IcClock
  },

  props: {
    pending: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'simple'
    },

    approvers: {
      type: Array,
      required: true
    }
  },

  methods: {
    approverIcon (approver) {
      if (approver.approval_status === true) {
        return {
          status: 'done',
          color: 'blue'
        }
      } else if (approver.approval_status === false) {
        return {
          status: 'rejected',
          color: 'red'
        }
      } else {
        return {
          status: 'processing',
          color: ''
        }
      }
    },

    approverStatusLabel (approver) {
      if (approver.approval_status === true) {
        return this.$tc('sidepanel.expense_request.approval.status.approved', 0, { date: formatDateText(approver.approval_date, 'LLL') })
      } else if (approver.approval_status === false) {
        return this.$tc('sidepanel.expense_request.approval.status.rejected', 0, { date: formatDateText(approver.approval_date, 'LLL') })
      }
      return this.pending ? this.$t('sidepanel.expense_request.approval.status.pending') : ''
    }
  }
}
</script>

<style scoped lang="stylus">
.approvers-list
  &--pending
    border 1px solid rgba($colorShinyGray, 0.2)
    padding 1.4rem  2rem

  &__head
    .ic
      margin-right 1rem

    h3
      display inline-block
      text-transform uppercase
      font-weight 500
      font-size 1.2rem
      margin-bottom 0
      vertical-align middle

    + .timeline
      margin-top 2rem

  &__description
    color $colorShinyGray
    margin-top 2rem

    + .timeline
      margin-top 2rem

  :deep(.timeline-item__right__content__title)
    margin-bottom 0
</style>
