<template>
  <div>
    <core-badge v-for="badge in badges"
                :key="badge.key"
                :value="`${$t(badge.label)} : ${formatAmount(badge.value)}`"
                :fill="ECoreBadgeFill.Shaded"
                :theme="badge.theme"
                class="ml-1" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'

import { formatAmount } from '@/helpers/utils/number'

const props = withDefaults(defineProps<{
  totalAmount?: number,
  totalCredit?: number,
  totalDebit?: number
}>(), {
  totalAmount: 0,
  totalCredit: 0,
  totalDebit: 0
})

const badges = computed(() => [
  {
    key: 'totalAmount',
    label: 'general.total.amount',
    value: props.totalAmount,
    theme: ECoreBadgeTheme.Neutral
  },
  {
    key: 'totalDebit',
    label: 'general.total.debit',
    value: props.totalDebit,
    theme: ECoreBadgeTheme.Negative
  },
  {
    key: 'totalCredit',
    label: 'general.total.credit',
    value: props.totalCredit,
    theme: ECoreBadgeTheme.Positive
  }
].filter(badge => badge.value !== 0))

</script>
