<template>
  <div class="d-flex flex-1 flex-col">
    <component-tabs :data="segments">
      <li class="nav__item__grow" />
      <li class="nav__item__icon">
        <button :disabled="!isCardListDisplay || loadingPreferences"
                @click="onToggleMode('grid')">
          <ic-gridview class="ic ic--24"
                       :class="isCardListDisplay ? 'ic--off' : 'ic--gray'" />
        </button>
      </li>
      <li class="nav__item__icon">
        <button :disabled="isCardListDisplay || loadingPreferences"
                @click="onToggleMode('list')">
          <ic-listview class="ic ic--24"
                       :class="isCardListDisplay ? 'ic--gray' : 'ic--off'" />
        </button>
      </li>
    </component-tabs>
    <router-view :filter="filter"
                 :physical-card="physicalCard"
                 @pagination-total-items="onPaginationTotalItems" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'

import IcGridview from '@/components/svg/icons/ic-gridview.vue'
import IcListview from '@/components/svg/icons/ic-listview.vue'
import ComponentTabs from '@/components/Tabs.vue'

export default {
  name: 'CardSegments',

  components: {
    ComponentTabs,
    IcGridview,
    IcListview
  },

  props: {
    physicalCard: {
      type: Boolean,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)
    const { user, isCardListDisplay } = storeToRefs(authStore)

    return { account, user, isCardListDisplay, authStore }
  },

  data () {
    return {
      loadingPreferences: false
    }
  },

  computed: {
    isOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.valides'),
          route: { name: this.physicalCard ? 'cards-physical' : 'cards-virtual' },
          visible: true
        },
        {
          label: this.$i18n.t('button.pending'),
          route: { name: this.physicalCard ? 'cards-physical-to-validate' : 'cards-virtual-to-validate' },
          badgeValue: this.physicalCard ? this.account.counts.physical_cards.waiting_validation : this.account.counts.virtual_cards.waiting_validation,
          visible: this.physicalCard ? this.account.counts.physical_cards.waiting_validation : this.account.counts.virtual_cards.waiting_validation
        },
        {
          label: this.$i18n.t('button.history'),
          route: { name: this.physicalCard ? 'cards-physical-history' : 'cards-virtual-history' },
          visible: true
        }
      ].filter(route => route.visible)
    }
  },

  methods: {
    onPaginationTotalItems (totalItems) {
      this.$emit('pagination-total-items', totalItems)
    },

    async onToggleMode (mode) {
      this.loadingPreferences = true
      await this.authStore.updateUserPreferences({ cards: { list_display_type: mode } })
      this.loadingPreferences = false
    }
  }
}
</script>
