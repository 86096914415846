<template>
  <div>
    <div class="form-control form-control--sm">
      <div style="display: flex">
        <div ref="input"
             contenteditable
             style="display: inline-block;"
             @paste="onPaste"
             @keypress.enter="onSubmit"
             @input="onChange" />
        <div class="text-muted"
             style="display: inline-block; margin-right: 15px; width: 100%"
             @click="selectInput">
          <template v-if="!!extension">
            .{{ extension }}
          </template>
        </div>
      </div>
    </div>
    <div class="popover-footer">
      <button type="button"
              class="btn btn--block btn--gray btn--sm"
              @click="$emit('close-popover')">
        <span>{{ $t('button.cancel') }}</span>
      </button>
      <component-button :label="$t('button.save')"
                        :disabled="!value && !canBeBlank"
                        wrapper-class="btn btn--block btn--primary btn--sm"
                        @click="onSubmit" />
    </div>
  </div>
</template>

<script>
import axiosClient from '@/api'

import ComponentButton from '@/components/Button.vue'

export default {
  name: 'ComponentPopoverRename',

  components: {
    ComponentButton
  },

  props: {
    name: {
      type: String,
      required: true
    },

    url: {
      type: String,
      default: null
    },

    dataKey: {
      type: String,
      required: true
    },

    canBeBlank: {
      type: Boolean,
      required: false,
      default: false
    },

    popoverActive: {
      type: Boolean,
      required: true
    }
  },

  emits: ['close-popover', 'renamed'],

  data () {
    return {
      value: '',
      extension: ''
    }
  },

  watch: {
    popoverActive (value) {
      if (value) {
        this.$refs.input.innerText = this.dataKey === 'filename'
          ? this.name.split('.').slice(0, -1).join('.')
          : this.name
        this.selectInput()
      }
    },

    name () {
      const splitted = this.name.split('.')
      if (splitted[1]) {
        this.extension = splitted[splitted.length - 1]
        splitted.splice(splitted.length - 1, 1)
      }
      this.value = splitted.join('.')
      this.$refs.input.innerText = this.value
    }
  },

  mounted () {
    this.value = this.name
    this.$refs.input.innerText = this.value
  },

  methods: {
    onChange (e) {
      if (e.target.innerText.match(/\n/g) && e.target.innerText.match(/\n/g).length) {
        e.preventDefault()
        if (e.target.innerText) {
          e.target.innerText = e.target.innerText.replace(/\n/g, '')
        }
      }
      this.value = e.target.innerText
    },

    onPaste (e) {
      e.preventDefault()
      const text = (e.originalEvent || e).clipboardData.getData('text/plain')
      document.execCommand('insertHTML', false, text)
    },

    selectInput () {
      if (!this.value) {
        this.$refs.input.innerHTML = '&nbsp;'
      }
      this.$refs.input.focus()
      const range = document.createRange()
      range.selectNodeContents(this.$refs.input)
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    },

    async onSubmit () {
      if (this.value || this.canBeBlank) {
        this.$refs.input.blur()
        if (this.url) {
          try {
            const { data } = await axiosClient.put(this.url, { [this.dataKey]: this.value })
            this.$emit('renamed', data)
            this.$emit('close-popover')
          } catch (e) {
            console.error(e)
          }
        } else {
          this.$emit('renamed', this.value)
          this.$emit('close-popover')
        }
      }
    }
  }
}
</script>
