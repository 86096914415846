<template>
  <svg viewBox="0 0 32 32">
    <path d="M8.93 23.071c-3.906-3.905-3.906-10.237 0-14.142 3.904-3.905 10.236-3.905 14.141 0 3.906 3.905 3.906 10.237 0 14.142-3.905 3.906-10.237 3.906-14.142 0Zm-1.415 1.414c4.686 4.687 12.284 4.687 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.686 4.686-4.686 12.284 0 16.97ZM24.485 8.93l-1.414-1.414L7.515 23.07l1.414 1.414L24.486 8.93Z" />
  </svg>
</template>

<script>
export default {
}
</script>
