<template>
  <div class="form__fieldset__group"
       data-cy="account.create.term.tax-type-form">
    <div class="form-group">
      <core-radio-tile-group :model-value="taxType"
                             :options="taxTypeOptions"
                             @update:model-value="updateTaxType" />
    </div>
  </div>
</template>

<script>
import { CoreRadioTileGroup } from '@common/core-ui'

import { ECompanyTaxType } from '@/types/company.d'

export default {
  components: {
    CoreRadioTileGroup
  },

  props: {
    taxType: {
      type: String,
      required: true
    }
  },

  emits: ['update:tax-type'],

  computed: {
    taxTypeOptions () {
      return Object.values(ECompanyTaxType).map(taxType => {
        return {
          subtitle: this.$i18n.t(`create_term_account.steps.tax_type.${taxType}.subtitle`),
          title: this.$i18n.t(`create_term_account.steps.tax_type.${taxType}.title`),
          value: taxType
        }
      })
    }
  },

  methods: {
    updateTaxType (value) {
      this.$emit('update:tax-type', value)
    }
  }
}
</script>
