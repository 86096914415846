<template>
  <div v-on-list-resize
       class="dashboard__block dashboard__block--table">
    <div class="dashboard__block__heading">
      <h3 class="title">
        {{ $t('title.expense_history') }}
      </h3>
      <router-link v-if="isStatementLinkVisible"
                   class="view-more"
                   :to="{ name: 'statements' }">
        {{ $t("button.see_more") }}
      </router-link>
    </div>
    <loader-spinner v-if="loading" />
    <ul v-else-if="statements?.length > 0"
        class="list-transaction">
      <li v-for="item in statements"
          :key="item.id">
        <span class="name capitalize">{{ formatDateText(item.closed_at, 'MMMM YYYY') }}</span>
        <span class="amount right">
          {{ formatAmount(item.eom_balance, account.currency) }}
        </span>
        <span class="attachment">
          <button class="btn-link btn-attachment"
                  @click.prevent.stop="downloadFileFromUrl(item.document.url)">
            <ic-download-sm class="ic ic--16 ic--gray" />
          </button>
        </span>
      </li>
    </ul>
    <p v-else
       class="empty-text">
      {{ $t("placeholder.no_statement.title") }}
    </p>
  </div>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcDownloadSm from '@/components/svg/icons/ic-download-sm.vue'

export default {
  components: {
    IcDownloadSm,
    LoaderSpinner
  },

  props: {
    statements: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const appStore = useAppStore()
    const { account } = useAccountStore()

    return { appStore, account }
  },

  computed: {
    isStatementLinkVisible () {
      return hasFeature(EFeature.SectionStatement)
    }
  },

  methods: {
    downloadFileFromUrl,
    formatDateText,
    formatAmount
  }
}
</script>
