<template>
  <accept-card-terms v-if="!termsAccepted"
                     @accept="termsAccepted = true"
                     @close="$emit('close')" />
  <div v-else-if="!cardActivated"
       class="modal__content modal__content--activation center">
    <modal-header align-title="center"
                  :title="$t('title.card_activation')"
                  @close="$emit('close')" />
    <validation-form ref="form"
                     v-slot="{ errors, isSubmitting, meta }"
                     class="form"
                     :validation-schema="digitsSchema"
                     @keydown.enter.prevent
                     @submit="onSubmit">
      <div class="modal__body">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('message.warning.form_not_valid')" />
        <p style="max-width: 40rem; margin: 0 auto 3rem;"
           class="pre-line">
          {{ $t('form.cards.activate.mone_card.label') }}
        </p>
        <div class="form-group"
             :class="{ error: Object.keys(errors).length }">
          <verification-code v-model="digits"
                             :has-separator="false"
                             :number-of-digits="4" />
        </div>
      </div>
      <div class="modal__footer modal__footer--centered">
        <button class="btn btn--gray"
                type="button"
                @click="$emit('close')">
          {{ $t('button.cancel') }}
        </button>
        <component-button :label="$t('button.activate')"
                          :disabled="isSubmitting || !meta.valid"
                          wrapper-class="btn--primary"
                          type="submit"
                          data-cy="cards.details.activate.submit" />
      </div>
    </validation-form>
  </div>
  <modal-dialog-prompt v-else
                       :title="$t('prompt.cards.activated.title')"
                       @close="$emit('close')">
    <template #body>
      <p class="pre-line">
        {{ $t('prompt.cards.activated.content') }}
      </p>
    </template>
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.no') }}
      </button>
      <component-button wrapper-class="btn--primary"
                        :label="$t('button.yes')"
                        @click="onShowPinCode" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ECardProcessor } from '@/types/card.d'

import ShowPinCode from '@/pages/cards/modal/ShowPinCode.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import VerificationCode from '@/components/VerificationCode.vue'

import AcceptCardTerms from './AcceptCardTerms.vue'

export default {
  components: {
    ModalDialogPrompt,
    AcceptCardTerms,
    VerificationCode,
    ComponentAlert,
    ComponentButton,
    ModalHeader
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()
    const apiStore = useApiStore()
    const { error: apiError } = storeToRefs(apiStore)

    return {
      appStore,
      cardStore,
      apiError
    }
  },

  data () {
    return {
      scopedCard: cloneDeep(this.card),
      digits: '',
      termsAccepted: false,
      cardActivated: false
    }
  },

  computed: {
    digitsSchema () {
      return yup.object({
        digits0: yup.string().required(),
        digits1: yup.string().required(),
        digits2: yup.string().required(),
        digits3: yup.string().required()
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  mounted () {
    this.$bus.on('card-activate-success', this.onCardActivationSuccess)
  },

  beforeUnmount () {
    this.$bus.off('card-activate-success', this.onCardActivationSuccess)
  },

  methods: {
    async onSubmit () {
      await this.cardStore.activateCard(this.scopedCard.uuid, this.digits)
    },

    async onCardActivationSuccess () {
      this.scopedCard = await this.cardStore.getCard(this.scopedCard.uuid)

      if (this.scopedCard && this.hasPermission(this.$permissions.cardsPinRead, this.scopedCard)) {
        this.cardActivated = true
      } else {
        this.$emit('close')
      }
    },

    async onShowPinCode () {
      let pin = null
      let token = null
      if (this.scopedCard.subtype === ECardProcessor.WORLDLINE) {
        pin = await this.cardStore.getWorldlineCardPin(this.scopedCard.uuid)
      } else if (this.scopedCard.subtype === ECardProcessor.MARQETA) {
        token = await this.cardStore.getClientAccessToken(this.scopedCard.uuid, 'pin')
      }
      if (token || pin) {
        this.appStore.showModal(ShowPinCode,
          { card: this.scopedCard, token, pin },
          { wrapperClass: 'modal--xs' }
        )
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal__content--activation
  padding 6rem

.input-verification-code
  margin-bottom 6rem

.modal__footer
  .btn
    width 13rem
</style>
