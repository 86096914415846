import IBAN from 'iban'

export const formatIban = iban => {
  return iban && IBAN.isValid(iban)
    ? iban.replace(/[^\dA-Z]/g, '').match(/(.{1,4})/g).join(' ')
    : iban
}

export const formatIbanOnKeyUp = value => {
  if (value.length >= 2 && Object.keys(IBAN.countries).includes(value.slice(0, 2).toUpperCase())) {
    value = value.toUpperCase().replace(/[^\dA-Z]/g, '').match(/(.{1,4})/g).join(' ')
  }

  return value
}

export const formatIbanHidden = value => {
  if (!value || !IBAN.isValid(value)) { return value }

  const hiddenIban = value.slice(0, 4) + '*'.repeat(value.length - 8) + value.slice(-4)

  return hiddenIban.replace(/[^\dA-Z\\*]/g, '').match(/(.{1,4})/g).join(' ')
}

export const autoCompleteIban = e => {
  const keys = [
    'Backspace',
    'ArrowRight',
    'ArrowLeft',
    'MetaLeft',
    'MetaRight',
    'ControlLeft',
    'ControlRight'
  ]

  return keys.includes(e.code) ? e.target.value : formatIban(e.target.value)
}
