<template>
  <div class="content-wrapper">
    <form class="form form-personal-info form--w680 form--centered"
          @submit.prevent.stop>
      <h2 class="content-title">
        {{ $t('title.my_informations') }}
      </h2>

      <user-profile-avatar :picture="user.picture"
                           picture-to-update="user"
                           :user-full-name="userFullName" />

      <div class="field-wrapper">
        <group-title :title="$t('form.title.personal_informations')" />
        <div class="form-group pb-1">
          <label id="genderMr"
                 class="form-check__label radio label disabled inline-flex">
            <input v-model="user.gender"
                   type="radio"
                   :value="1"
                   name="gender"
                   disabled><span class="radio__icon" /><div class="lh--1">{{ $t("form.gender.mr") }}</div>
          </label>
          <label id="genderMme"
                 class="form-check__label radio label disabled inline-flex">
            <input v-model="user.gender"
                   type="radio"
                   :value="2"
                   name="gender"
                   disabled><span class="radio__icon" /><div class="lh--1">{{ $t("form.gender.mrs") }}</div>
          </label>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-6">
            <label class="label input__label">{{ $t("form.last_name.label") }}</label>
            <lock-input v-model="user.last_name"
                        name="last_name"
                        type="text"
                        input-class="form-control"
                        disabled />
          </div>
          <div class="form-group col col--sm-6">
            <label class="label input__label">{{ $t("form.first_name.label") }}</label>
            <lock-input v-model="user.first_name"
                        name="first_name"
                        type="text"
                        input-class="form-control"
                        disabled />
          </div>
        </div>
        <div class="form__row row">
          <div v-if="user.birth_date"
               class="form-group col col--sm-6">
            <label class="label input__label">{{ $t("form.birth.date.label") }}</label>
            <lock-input type="text"
                        name="birth_date"
                        input-class="form-control"
                        :model-value="formatDateText(user.birth_date)"
                        disabled />
          </div>
          <div class="form-group col col--sm-6">
            <label class="label input__label">{{ $t("form.phone.label") }}</label>
            <div class="form-inline input-pencil-addon">
              <phone-input v-model="user.phone"
                           name="phone_number"
                           disabled />
              <button class="btn btn--icon btn--default"
                      type="button"
                      @click="onEditPhoneNumber">
                <i class="btn-icon">
                  <ic-pencil class="ic ic--20 ic--white" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="field-wrapper">
        <group-title :title="$t('form.title.login_and_password')" />
        <div class="form__row row">
          <div class="form-group col col--sm-6">
            <label class="label input__label">{{ $t("form.email.label") }}</label>
            <component-popover trigger="hover"
                               position-arrow-x="center"
                               position-arrow-y="top"
                               popover-style="margin-top: 1.2rem">
              <template #trigger>
                <lock-input v-model="user.email"
                            name="email"
                            type="text"
                            input-class="form-control"
                            disabled />
              </template>
              <h4 class="strong">
                {{ $t('title.important') }}
              </h4>
              <div class="popover-body">
                {{ $t("message.info.account.secured_email") }}
                <a href="#"
                   class="strong text-muted"
                   @click.prevent="onContact">
                  {{ $t('button.click_contact') }}
                </a>.
              </div>
            </component-popover>
          </div>
          <div class="form-group col col--sm-6">
            <label class="label input__label">{{ $t("form.current_password.label") }}</label>
            <div class="form-inline input-pencil-addon">
              <lock-input type="password"
                          name="password"
                          input-class="form-control"
                          model-value="•••••••••••••••"
                          disabled />
              <button class="btn btn--icon btn--default"
                      type="button"
                      @click="onEditPassword">
                <i class="btn-icon">
                  <ic-pencil class="ic ic--20 ic--white" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasMultipleLanguages"
           class="field-wrapper">
        <group-title :title="$t('account_info.default_language.title')"
                     :subtitle="$t('account_info.default_language.subtitle')" />
        <language-dropdown :model-value="$i18n.locale"
                           @update:model-value="onLanguageSelect($event)" />
      </div>
    </form>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import config from '@/config/config'
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import SidepanelEditPassword from '@/pages/account/infos/sidepanel/SidepanelEditPassword.vue'
import SidepanelEditPhoneNumber from '@/pages/account/infos/sidepanel/SidepanelEditPhoneNumber.vue'

import GroupTitle from '@/components/GroupTitle.vue'
import LanguageDropdown from '@/components/LanguageDropdown.vue'
import LockInput from '@/components/LockInput.vue'
import Contact from '@/components/modals/Contact.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import UserProfileAvatar from '@/components/UserProfileAvatar.vue'

export default {
  components: {
    UserProfileAvatar,
    IcPencil,
    PhoneInput,
    LanguageDropdown,
    LockInput,
    GroupTitle,
    ComponentPopover
  },

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { user, userFullName } = storeToRefs(authStore)

    return { appStore, user, userFullName, authStore }
  },

  computed: {
    hasMultipleLanguages () {
      return config.TRANSLATIONS.AVAILABLE_LANGUAGES.length > 1
    }
  },

  methods: {
    formatDateText,

    onEditPassword () {
      this.appStore.showSidePanel(SidepanelEditPassword, {}, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    },

    onEditPhoneNumber () {
      this.appStore.showSidePanel(SidepanelEditPhoneNumber, {}, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    },

    onContact () {
      this.appStore.showModal(Contact, {}, { wrapperClass: 'modal--md' })
    },

    async onLanguageSelect (lang) {
      if (this.$i18n.locale === lang) return

      await this.authStore.updateUserLanguagePreferences(lang)
    }
  }
}
</script>
