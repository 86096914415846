import { globalTranslation } from '@/locales'

const webSite = import.meta.env.VITE_WWW_URL || 'https://www.staging.manager.one'
const staticSite = 'https://static.manager.one'
const supportSite = 'https://support.manager.one/portal/fr/kb'

const links = {
  SIGNUP: import.meta.env.VITE_SIGNUP_URL || 'https://signup.staging.manager.one',
  COMMERCIAL: {
    BASE: webSite,
    BLOCK_CARD: `${webSite}/fr/includes/opposition-carte`,
    TERMS: `${webSite}/fr/includes/cgv`,
    LEGAL_NOTICES: `${webSite}/fr/includes/mentions-legales`,
    PRIVACY_POLICY: `${webSite}/fr/includes/donnees-personnelles`,
    PRICING: `${webSite}/fr/includes/tarifs`
  },
  CLIENT: import.meta.env.VITE_CLIENT_URL || 'https://client.staging.manager.one',
  STATIC: {
    FLAGS: `${staticSite}/flags/country`,
    CARDS: {
      CGU_VIRTUAL: `${staticSite}/mone/files/fr/manager-one_cgu-service-cartes-virtuelles.pdf`
    },
    CGU_TERM_ACCOUNT: `${staticSite}/mone/files/fr/manager-one_cgu-compte-a-terme.pdf`,
    VISA: {
      CGU: `${staticSite}/mone/files/fr/manager-one_cgu-carte-visa-corporate.pdf`,
      ASSISTANCE: `${staticSite}/mone/files/fr/manager-one_assistance-carte-visa-corporate_2023.pdf`,
      INSURANCE: `${staticSite}/mone/files/fr/manager-one_assurance-carte-visa-corporate_2023.pdf`
    },
    FILES: {
      MANDAT_ICS: `${staticSite}/mone/files/mandat_b2b_ics.pdf`,
      MANDAT_RUM: `${staticSite}/mone/files/mandat_b2b_rum.pdf`,
      PROCURATION: () => globalTranslation('links.files.procuration', { base_url: staticSite }),
      TEMPLATE_ORDER_CARDS: `${staticSite}/mone/files/manager-one_commande-cartes-collaborateurs_excel.zip`,
      TEMPLATE_CREA_MULTI_BENEF: `${staticSite}/mone/files/manager-one_creation-plusieurs-beneficiaires_excel.zip`,
      TEMPLATE_CREA_MULTI_USERS: `${staticSite}/mone/files/manager-one_creation-plusieurs-utilisateurs_excel.zip`
    }
  },
  MOBILE: 'https://app.manager.one/download',
  SUPPORT: {
    BASE: supportSite,
    LEXIQUE_CERTIF_CONFORME: `${supportSite}/articles/certifie-conforme`,
    IDENTITY_CARD: `${supportSite}/articles/les-documents-d-identite-recevable`,
    ADDRESS_PROOF: `${supportSite}/articles/documents-constituant-un-justificatif-de-domicile`,
    STATUS_PROJECT: `${supportSite}/articles/les-statuts-de-la-societe`,
    STATUS: `${supportSite}/articles/les-statuts-de-la-societe`,
    PROOF_OF_ADDRESS: `${supportSite}/articles/documents-justifiant-l-adresse-du-siege-social`,
    ACTIVITY_PROOF: `${supportSite}/articles/les-statuts-de-la-societe`,
    KBIS: `${supportSite}/articles/extrait-kbis`,
    ADVANCE_PAYMENT: `${supportSite}/articles/acompte-sur-salaire`,
    SMART_CARD: `${supportSite}/articles/qu-est-ce-que-la-fonctionnalit%c3%a9-carte-connect%c3%a9e-et-comment-l-utiliser`,
    IMPORT_SEPA_LIMIT: `${supportSite}/articles/format-des-fichiers-pour-les-virements`
  },
  MARQETA: {
    WIDGET: import.meta.env.VITE_MARQETA_WIDGET_URL || 'https://widgets-sandbox.marqeta.com/marqetajs/2.0.0/marqeta.min.js'
  }
}

export default links
