<template>
  <div :class="{ 'has-hover': isRemote }"
       class="dropzone__attachments__item"
       @click.prevent.stop="showFile">
    <div class="dropzone__attachments__item-row">
      <div class="d-flex align-items-center dropzone__attachments__item-title">
        <ic-file v-bind="{ [getDocumentIcon(file.type)]: true }"
                 class="ic ic--30 mr-2" />
        <div :class="{ small: hasOptions }"
             class="file-name">
          {{ file.name }}
          <span v-if="displaySize"
                class="text-muted ml-1">
            {{ fileSize }}
          </span>
        </div>
        <div v-if="displayAddedOn && file.created_at"
             class="text-muted">
          {{ $t('general.added_on') }} {{ formatDateText(file.created_at) }}
        </div>
      </div>
      <div class="d-flex align-items-center dropzone__attachments__item-action">
        <div v-if="hasError"
             v-tooltip="{ content: error.tooltipContent }"
             class="error-message">
          <ic-error class="ic ic--error ic--20" />
          <span>{{ error.tooltip }}</span>
        </div>
        <slot v-if="!hasError" />
        <button type="button"
                class="btn-link"
                @click.prevent.stop="$emit('delete', file)">
          <ic-remove class="ic ic--20 ic--gray" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'
import { getDocumentIcon } from '@/helpers/utils/document'
import { formatFileSize } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'

import IcError from '@/components/svg/icons/ic-error.vue'
import IcFile from '@/components/svg/icons/ic-file.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcError,
    IcRemove,
    IcFile
  },

  props: {
    file: {
      type: [Object, File],
      required: true
    },

    displayAddedOn: {
      type: Boolean,
      default: false
    },

    displaySize: {
      type: Boolean,
      default: false
    },

    error: {
      type: [Object, null],
      default: null
    }
  },

  emits: ['delete'],

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  computed: {
    fileSize () {
      return formatFileSize(this.file.size)
    },

    hasError () {
      return this.error && this.error.tooltip
    },

    hasOptions () {
      return this.displayAddedOn || this.displaySize
    },

    isRemote () {
      return (this.file?.url)
    }
  },

  methods: {
    getDocumentIcon,
    formatDateText,

    showFile () {
      if (this.isRemote) {
        this.appStore.showFileViewer({
          content: [this.file],
          displayFullScreen: true,
          isLocal: (this.file instanceof File)
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.dropzone__attachments__item
  &-row
    transition box-shadow 0.3s ease
    height auto
    width 100%
    display flex
    justify-content space-between
    overflow hidden
    gap 10px
    cursor initial
    padding 1.5rem 0

    .file-name.small
      max-width 55%

    .text-muted
      margin-left 10px

  &.has-hover:hover
    box-shadow 0 0.9rem .5rem -0.5rem rgba($colorDarkGray, 0.05)
    cursor pointer
    .dropzone__attachments__item-row
      cursor pointer

  &-action
      gap: 15px

  &-title
    overflow hidden
    svg
      min-width 30px
      margin-right 15px
</style>
