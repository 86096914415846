import { defineStore } from 'pinia'

import axiosClient from '@/api'

export const useServiceStore = defineStore('service', {
  state: () => {
    return {
      services: [],
      serviceLogs: []
    }
  },

  actions: {
    async getServicesList () {
      try {
        const { data } = await axiosClient.get('/oauth/services')
        this.services = data
      } catch (error) {
        console.error(error)
      }
    },

    async getServiceLogs (serviceId) {
      try {
        const { data } = await axiosClient.get(`/oauth/services/${serviceId}/logs`)
        this.serviceLogs = data
      } catch (error) {
        console.error(error)
      }
    },

    async revokeService (id) {
      try {
        await axiosClient.put(`/oauth/services/${id}/revoke`)
        this.services = this.services.filter(service => service.client_id !== id)
      } catch (error) {
        console.error(error)
      }
    }
  }
})
