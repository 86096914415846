<template>
  <transition name="sidepanel">
    <div v-if="sidePanel.active"
         data-cy="sidepanel"
         class="sidepanel"
         :class="sidePanel.wrapperClass"
         :style="sidePanel.wrapperStyle">
      <transition name="sidepanel-drag-and-drop">
        <div v-if="isDraggingVisible"
             id="drag-and-drop-overlay"
             class="sidepanel-drag-and-drop">
          <div class="sidepanel-drag-and-drop__label"
               :class="{ 'sidepanel-drag-and-drop__label--loading': isDragAndDropLoading }">
            <template v-if="!isDragAndDropLoading">
              <ic-attachment class="ic ic-attachment" />
              <span>{{ $t('popover.drag.placeholder') }}</span>
            </template>
            <template v-else>
              <loader-spinner size="22" />
              <span>{{ $t('popover.drag.wait') }}</span>
            </template>
          </div>
        </div>
      </transition>
      <div v-if="sidePanel.backgroundOverlay"
           class="sidepanel__bg"
           @click.prevent.stop="appStore.closeSidePanel()" />
      <div class="sidepanel__dialog">
        <button v-if="sidePanel.closeButton"
                type="button"
                class="panel-close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent.stop="appStore.closeSidePanel()">
          <ic-close class="ic ic--18 ic--gray" />
        </button>
        <loader-spinner v-if="apiLoading && sidePanel.showLoader"
                        class="c c--vh" />
        <component :is="sidePanel.component"
                   v-bind="sidePanel.props"
                   @dropped-files-upload="stopLoading" />
      </div>
    </div>
  </transition>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcClose from '@/components/svg/icons/ic-close.vue'

export default {
  components: {
    IcAttachment,
    IcClose,
    'loader-spinner': LoaderSpinner
  },

  setup () {
    const apiStore = useApiStore()
    const appStore = useAppStore()

    const { loading: apiLoading } = storeToRefs(apiStore)
    const { modal, sidePanel } = storeToRefs(appStore)

    return {
      appStore,

      apiLoading,
      modal,
      sidePanel
    }
  },

  data () {
    return {
      isDragging: false,
      isDragAndDropLoading: false,
      isDragAndDropReady: false
    }
  },

  computed: {
    canDragAndDrop () {
      return !!this.sidePanel.canDragAndDrop
    },

    isDraggingVisible () {
      return this.canDragAndDrop && (this.isDragging || this.isDragAndDropLoading)
    }
  },

  watch: {
    'sidePanel.active' (value) {
      value
        ? this.addDragAndDropBehavior()
        : this.removeDragAndDropBehavior()
    },

    'modal.active' (value) {
      this.sidePanel.active && value
        ? this.removeDragAndDropBehavior()
        : this.addDragAndDropBehavior()
    },

    isDragging () {
      this.isDragging
        ? document.body.classList.add('drag')
        : document.body.classList.remove('drag')
    }
  },

  mounted () {
    this.$bus.on('drag-and-drop-ready', this.onDragAndDropReady)
  },

  unmounted () {
    this.$bus.off('drag-and-drop-ready', this.onDragAndDropReady)
  },

  methods: {
    onDragAndDropReady () {
      this.isDragAndDropReady = true
    },

    onDragEnter (e) {
      if (!this.isDragAndDropReady) { return }
      this.counter = this.counter + 1
      this.isDragging = true
      this.preventDefault(e)
    },

    onDragLeave (e) {
      const w = window.innerWidth
      const h = window.innerHeight
      if (e.clientX > w || e.clientX <= 0 || e.clientY > h || e.clientY <= 0) {
        this.isDragging = false
      }
      this.preventDefault(e)
    },

    onDrop (e) {
      if (!this.isDragAndDropReady) { return }
      const dt = e.dataTransfer
      const files = dt.files
      this.isDragAndDropLoading = true
      this.isDragging = false
      this.preventDefault(e)
      this.$bus.emit('on-files-dropped', files)
    },

    preventDefault (e) {
      e.preventDefault()
      e.stopPropagation()
    },

    addDragAndDropBehavior () {
      if (this.canDragAndDrop) {
        document.body.addEventListener('dragenter', this.onDragEnter, false)
        document.body.addEventListener('dragover', this.preventDefault, false)
        document.body.addEventListener('dragleave', this.onDragLeave, false)
        document.body.addEventListener('drop', this.onDrop, false)
      }
    },

    removeDragAndDropBehavior () {
      document.body.removeEventListener('dragenter', this.onDragEnter, false)
      document.body.removeEventListener('dragover', this.preventDefault, false)
      document.body.removeEventListener('dragleave', this.onDragLeave, false)
      document.body.removeEventListener('drop', this.onDrop, false)
      this.isDragAndDropReady = false
    },

    stopLoading () {
      this.isDragAndDropLoading = false
    }
  }
}
</script>
