<template>
  <div class="logo-print hide">
    <logo-bank-icon class="logo-mone-icon" />
  </div>
</template>

<script>
import LogoBankIcon from '@/components/svg/img/logo-bank-icon.vue'

export default {
  components: {
    LogoBankIcon
  }
}
</script>
