<template>
  <div class="placeholder-action-description">
    <component-placeholder :label="$t('placeholder.sca_validation_rejected.title')"
                           :content="$t('placeholder.sca_validation_rejected.subtitle')">
      <image-mobile-app problem />
    </component-placeholder>
    <div class="center">
      <component-button wrapper-class="btn btn--default placeholder-btn"
                        :label="$t('button.close')"
                        @click="$emit('validation-rejected')" />
    </div>
  </div>
</template>

<script>
import ComponentButton from '@/components/Button.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'

export default {
  components: {
    ComponentButton,
    ComponentPlaceholder,
    ImageMobileApp
  },

  emits: ['validation-rejected']
}
</script>
