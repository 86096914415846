<template>
  <div class="segmented-control"
       :class="step">
    <button v-for="(button, index) in buttons"
            :key="index"
            :data-cy="`cards.settings.segmented-control.${button.value}`"
            :class="{ selected: modelValue === button.value }"
            class="segmented-control__button"
            @click="toggleState(button.value, index)">
      {{ button.label }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs } from 'vue'

// PROPS
const props = defineProps<{
  buttons: object[]
  modelValue: string
}>()

const { modelValue, buttons } = toRefs(props)

// EMITS
const emit = defineEmits([
  'update:modelValue'
])

// DATA
const step = ref('step0')

// METHODS
function toggleState (val: string, key: number) {
  step.value = `step${key}`
  emit('update:modelValue', val)
}
</script>
