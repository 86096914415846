<template>
  <validation-form v-slot="{ errors }"
                   :validation-schema="schema"
                   class="sidepanel__content"
                   @submit="onSubmit">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('sidepanel.accounting_category.title') }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <div class="sidepanel-details">
          <h3 class="head-title">
            {{ $t('general.general_informations') }}
          </h3>
          <div class="sidepanel-details__entry">
            <div class="form-group">
              <div class="label input__label">
                {{ $tc('form.category.label') }}
              </div>
              <component-dropdown v-if="!editMode"
                                  label="categories"
                                  :dropdown-width="'100%'"
                                  :multiselect="false"
                                  :search="true"
                                  :can-add-item="hasPermission($permissions.categoriesWrite)"
                                  async-url="/categories"
                                  :async-url-params="{ filter: 'card' }"
                                  :has-icon="true"
                                  :model="chartAccount.category ? chartAccount.category.id : null"
                                  @select="onCategorySelected"
                                  @new-item="onNewItemAdded"
                                  @delete-item="onDeleteCategory">
                <template #trigger>
                  <div class="dropdown-toggle select">
                    <div class="form-control">
                      <span v-if="!chartAccount.category"
                            class="text-muted">{{ $t('form.category.placeholder') }}</span>
                      <span v-else>{{ chartAccount.category.label }}</span>
                    </div>
                  </div>
                </template>
              </component-dropdown>
              <div v-else
                   class="input-group">
                <input class="form-control"
                       :value="chartAccount.label"
                       :disabled="true">
                <div class="input-group__addon">
                  <ic-cadenas-sm class="ic ic--16 ic--off" />
                </div>
              </div>
            </div>
          </div>
          <div class="sidepanel-details__entry">
            <div class="form-group">
              <div class="label input__label">
                {{ $t('form.description.label') }}
              </div>
              <input v-model="chartAccount.description"
                     type="text"
                     class="form-control"
                     :placeholder="$t('form.description.placeholder')">
            </div>
          </div>
          <div class="sidepanel-details__entry">
            <div class="form-group"
                 :class="{ error: errors.account_number }">
              <div class="label input__label">
                {{ $t('form.expense_account.label') }}
              </div>
              <number-input v-model="chartAccount.account_number"
                            class="form-control"
                            :placeholder="$t('form.expense_account.placeholder')"
                            :default-zero="false"
                            :locale-string="false"
                            name="account_number"
                            data-vv-name="account_number"
                            :maxlength="9" />
            </div>
          </div>
          <div class="sidepanel-details__entry">
            <div class="form-group">
              <div class="label input__label">
                {{ $t('form.vendor_account.label') }}
              </div>
              <input v-model="chartAccount.vendor_account_number"
                     type="text"
                     class="form-control"
                     :placeholder="$t('form.vendor_account.placeholder')">
            </div>
          </div>
          <hr class="divider divider--fw">
          <h3 class="head-title">
            {{ $t('sidepanel.accounting_category.vat_informations') }}
          </h3>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.apply_vat.label') }}
            </div>
            <div class="entry-value right">
              <toggle-switch v-model="chartAccount.apply_vat"
                             :disabled="apiLoading"
                             :toggle-class="'switch-btn--sm'" />
            </div>
          </div>
          <template v-if="chartAccount.apply_vat">
            <div class="sidepanel-details__entry">
              <div class="entry-label text-muted">
                {{ $t('form.vat_account.label') }}
              </div>
              <div class="entry-value right">
                <div class="select select--inline select--rtl">
                  <component-dropdown label="vat"
                                      :search="true"
                                      :dropdown-width="34"
                                      async-url="/companies/accounting/accounts"
                                      :async-url-params="{ type: 'vat' }"
                                      :label-filter="vatAccountLabel"
                                      :model="chartAccount.vat_account_number"
                                      @select="onVatAccountSelected">
                    <template #trigger>
                      <div class="form-control"
                           :class="{ 'text-muted': !chartAccount.vat_account_number }">
                        {{ chartAccount.vat_account_number || $t('form.vat_account.placeholder') }}
                      </div>
                    </template>
                  </component-dropdown>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="btn-grouped">
        <button type="button"
                class="btn btn--gray"
                :disabled="apiLoading"
                @click.prevent="appStore.closeSidePanel">
          {{ $t("button.cancel") }}
        </button>
        <component-button :label="editMode ? $t('button.edit') : $t('button.validate')"
                          :disabled="!chartAccount.category && !editMode"
                          type="submit"
                          wrapper-class="btn btn--primary" />
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useAccountStore } from '@/stores/account'
import { useAccountingStore } from '@/stores/accounting'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'

import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import NumberInput from '@/components/NumberInput.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcCadenasSm,
    NumberInput,
    ComponentButton,
    ComponentDropdown,
    ComponentSidepanelFooter,
    ToggleSwitch
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const accountingStore = useAccountingStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)
    const { loading: apiLoading } = storeToRefs(apiStore)

    return {
      accountingStore,
      appStore,

      account,
      apiLoading
    }
  },

  data () {
    return {
      VATAmounts: [],
      category_id: null,
      categories: [],
      affectations: [],
      isTypingAmount: false,
      personalNoteTag: null,
      operation: {},
      category: {},
      chartAccount: {
        category: null,
        label: '',
        description: '',
        account_number: '',
        vendor_account_number: '',
        vat_account_number: '',
        apply_vat: true
      },

      schema: yup.object({
        account_number: yup.string().required()
      })
    }
  },

  computed: {
    company () {
      return this.account.company
    },

    isAccountOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    editMode () {
      return !!Object.keys(this.model).length
    }
  },

  created () {
    Object.assign(this.chartAccount, this.model)
    if (this.model.coa_accounting_account && this.model.coa_accounting_account.account_number) {
      this.chartAccount.account_number = this.model.coa_accounting_account.account_number
    }
  },

  mounted () {
    this.$bus.on('delete-category', this.onDeleteCategory)
  },

  beforeUnmount () {
    this.$bus.off('delete-category', this.onDeleteCategory)
  },

  methods: {
    vatAccountLabel (item) {
      return `${item.account_number} - ${item.label}`
    },

    onVatAccountSelected (item) {
      this.chartAccount.vat_account_number = item.account_number
    },

    onCategorySelected (category) {
      if (category.temp) {
        this.chartAccount.label = category.label
      }
      this.chartAccount.category = category
    },

    onNewItemAdded (itemLabel) {
      const item = {
        label: itemLabel,
        temp: true,
        id: Date.now(), // fake id
        name: 'custom'
      }
      this.chartAccount.category = item
      this.chartAccount.label = this.chartAccount.category.label
      this.$bus.emit('on-new-item-added', item)
    },

    onDeleteCategory (itemId) {
      if (this.chartAccount.category && this.chartAccount.category.id === itemId) {
        this.chartAccount.category = null
        this.chartAccount.label = null
      }
    },

    async onSubmit () {
      const chartAccount = { ...this.chartAccount }
      chartAccount.vat_account_number = chartAccount.vat_account_number || null
      chartAccount.vendor_account_number = chartAccount.vendor_account_number || null

      if (!this.editMode && chartAccount.category.temp) {
        delete chartAccount.category
      }

      let successEvent = null
      if (this.editMode) {
        const success = await this.accountingStore.updateCategory(chartAccount.id, chartAccount)
        if (success) successEvent = 'accounting-category-edited'
      } else {
        const success = await this.accountingStore.createCategory(chartAccount)
        if (success) successEvent = 'accounting-category-added'
      }

      if (successEvent) {
        this.$bus.emit(successEvent)
        this.appStore.closeSidePanel()
      }
    }
  }
}
</script>
