<template>
  <modal-dialog-prompt data-cy="delete-modal.container"
                       :title="getLabel"
                       :subtitle="getContent"
                       @close="$emit('close')">
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              data-cy="delete-modal.cancel"
              @click="$emit('close')">
        {{ $t(cancel) }}
      </button>
      <component-button :label="$t(submit)"
                        :disabled="loading"
                        wrapper-class="btn--primary"
                        data-cy="delete-modal.confirm"
                        @click="onDelete" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { ECoreBannerTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import axiosClient from '@/api'
import { useAppStore } from '@/stores/app'

import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  name: 'ComponentDelete',

  components: {
    ComponentButton,
    ModalDialogPrompt
  },

  props: {
    url: {
      type: [String, Object],
      required: true
    },

    label: {
      type: String,
      default: 'prompt.common.delete.title'
    },

    content: {
      type: String,
      default: 'prompt.common.delete.content'
    },

    cancel: {
      type: String,
      default: 'button.no'
    },

    callbackDelete: {
      type: Function,
      default: null
    },

    submit: {
      type: String,
      default: 'button.yes'
    },

    event: {
      type: String,
      default: null
    },

    id: {
      type: Number,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()

    const { fileViewer } = storeToRefs(appStore)

    return { appStore, fileViewer }
  },

  data () {
    return {
      loading: false,
      documentSent: false
    }
  },

  computed: {
    getLabel () {
      return this.label.match(/^([a-z0-9]+(\.|_){1})+[a-z0-9]+$/) ? this.$i18n.t(this.label) : this.label
    },

    getContent () {
      return this.content.match(/^([a-z0-9]+(\.|_){1})+[a-z0-9]+$/) ? this.$i18n.t(this.content) : this.content
    }
  },

  methods: {
    async onDelete () {
      if (this.fileViewer.isLocal) {
        this.$bus.emit('delete-local-document', this.url)
        this.appStore.closeFileViewer()
        return this.$emit('close')
      }

      this.loading = true
      try {
        if (this.callbackDelete) {
          const result = await this.callbackDelete()
          this.onDeleteSuccess(result)
        } else if (this.url) {
          const { data } = await axiosClient.delete(this.url)
          this.onDeleteSuccess(data)
        }
      } catch (e) {
        console.error(e)
        this.onDeleteFailure(e.response.data)
      } finally {
        this.loading = false
      }
    },

    onDeleteSuccess (data) {
      if (this.event) {
        this.$bus.emit(`delete-${this.event}`, this.id)
        this.$bus.emit('on-item-deleted', this.id)
      } else {
        this.$bus.emit('delete-local-document', this.url)
        this.$bus.emit('delete-success', data.message)
      }
      this.$emit('close')
    },

    onDeleteFailure (data) {
      let error = data.message
      if (data[0]?.message) {
        error = data[0].message
      }
      this.$bus.emit('delete-failure', error)
      this.appStore.showBanner(ECoreBannerTheme.Danger, error || 'Erreur à la suppression')
    }
  }
}
</script>
