<template>
  <div data-cy="account.create.term.form-summary">
    <div class="form__fieldset__group">
      <div class="form-group">
        <h4 class="body--extra-large center">
          {{ $t('create_term_account.steps.summary.content.title') }}
        </h4>
        <div class="block-summaries">
          <core-block-summary v-for="(blockSummary, index) in blockSummaries"
                              :key="index"
                              :items="[blockSummary]"
                              :bordered="false"
                              :elevated="false"
                              :size="ECoreBlockSummarySize.Medium" />
        </div>
        <span class="pre-line">
          {{ $t('create_term_account.steps.summary.explanation', { account: getAccountLabel(), amount: formatAmount(amount), duration, percentage: formatPercentage(rate) }) }}
          <button class="link underline"
                  type="button"
                  @click="showPenalties">
            {{ $t('create_term_account.button.show_penalties') }}
          </button>
        </span>
      </div>
    </div>
    <i18n-t tag="p"
            class="mt-3 text-muted"
            keypath="create_term_account.steps.summary.subscribe.info"
            scope="global">
      <template #cgu>
        <a class="is-link text-dark"
           target="_blank"
           :href="links.STATIC.CGU_TERM_ACCOUNT">
          {{ $t('create_term_account.cgu') }}
        </a>
      </template>
    </i18n-t>
  </div>
</template>

<script>
import { CoreBlockSummary, ECoreBlockSummarySize } from '@common/core-ui'

import links from '@/config/links'
import { formatAmount, formatPercentage } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'

import TermAccountPenalties from '@/pages/account/create-sub-account/modal/TermAccountPenalties.vue'

export default {
  components: {
    CoreBlockSummary
  },

  props: {
    account: {
      type: Object,
      required: true
    },

    amount: {
      type: Number,
      required: true
    },

    duration: {
      type: Number,
      required: true
    },

    termAccountRate: {
      type: Object,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()

    return {
      appStore
    }
  },

  data () {
    return {
      rate: this.termAccountRate.getRateByDuration(this.duration)
    }
  },

  computed: {
    ECoreBlockSummarySize () {
      return ECoreBlockSummarySize
    },

    blockSummaries () {
      return [
        {
          subtitle: this.$t('create_term_account.steps.amount.title'),
          title: formatAmount(this.amount)
        },
        {
          subtitle: this.$t('create_term_account.steps.summary.duration'),
          title: this.$t('general.months', { n: this.duration })
        },
        {
          subtitle: this.$t('create_term_account.steps.summary.rate'),
          title: formatPercentage(this.rate)
        }
      ]
    },

    links () {
      return links
    }
  },

  methods: {
    formatAmount,
    formatPercentage,
    getAccountLabel () {
      return this.account.label ? `${this.account.company.name} - ${this.account.label}` : this.account.company.name
    },

    showPenalties () {
      return this.appStore.showModal(TermAccountPenalties)
    }
  }
}
</script>

<style lang="stylus" scoped>
.form__fieldset__group
  padding 5rem

.block-summaries
  display flex
  gap 1rem
  width 100%
  margin 4rem 0

  .core-block-summary
    width 100%
    background-color $colorWhite
</style>
