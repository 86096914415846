<template>
  <div id="lottie" />
</template>

<script>
import lottie from 'lottie-web'

import config from '@/config/config'

import DocumentSablier from './lottie/document-sablier.json?url'
import DocumentScanLoader from './lottie/document-scan-loader.json?url'
import LoginAccountingDe from './lottie/login-accounting--de.json?url'
import LoginAccountingEn from './lottie/login-accounting--en.json?url'
import LoginAccountingFr from './lottie/login-accounting--fr.json?url'
import LoginDefaultDe from './lottie/login-default--de.json?url'
import LoginDefaultEn from './lottie/login-default--en.json?url'
import LoginDefaultFr from './lottie/login-default--fr.json?url'
import LoginLostPasswordDe from './lottie/login-lostpwd--de.json?url'
import LoginLostPasswordEn from './lottie/login-lostpwd--en.json?url'
import LoginLostPasswordFr from './lottie/login-lostpwd--fr.json?url'
import LoginOCRDe from './lottie/login-ocr--de.json?url'
import LoginOCREn from './lottie/login-ocr--en.json?url'
import LoginOCRFr from './lottie/login-ocr--fr.json?url'
import LoginPasswordUpdateDe from './lottie/login-pwdupdate--de.json?url'
import LoginPasswordUpdateEn from './lottie/login-pwdupdate--en.json?url'
import LoginPasswordUpdateFr from './lottie/login-pwdupdate--fr.json?url'
import LoginReceiptDe from './lottie/login-receipt--de.json?url'
import LoginReceiptEn from './lottie/login-receipt--en.json?url'
import LoginReceiptFr from './lottie/login-receipt--fr.json?url'
import LoginRefundDe from './lottie/login-refund--de.json?url'
import LoginRefundEn from './lottie/login-refund--en.json?url'
import LoginRefundFr from './lottie/login-refund--fr.json?url'
import LoginVirtualCardRequestDe from './lottie/login-virtualcard-request--de.json?url'
import LoginVirtualCardRequestEn from './lottie/login-virtualcard-request--en.json?url'
import LoginVirtualCardRequestFr from './lottie/login-virtualcard-request--fr.json?url'
import LogoutInactivity from './lottie/logout-inactivity.json?url'

export default {
  name: 'ComponentLottie',

  props: {
    name: {
      type: String,
      required: true
    },

    startFrames: {
      type: Array,
      required: false,
      default: () => []
    },

    loop: {
      type: Boolean,
      required: false,
      default: true
    },

    loopFrames: {
      type: Array,
      required: false,
      default: () => []
    },

    endFrames: {
      type: Array,
      required: false,
      default: () => []
    },

    timeoutAnimation: {
      type: Number,
      required: false,
      default: 50
    },

    filterSize: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  emits: ['animation-ended'],

  data () {
    return {
      animation: {}
    }
  },

  computed: {
    getAnimation () {
      const lottieAnimations = {
        'document-scan-loader': DocumentScanLoader,
        'login-default': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginDefaultEn : (this.$i18n.locale === 'de' ? LoginDefaultDe : LoginDefaultFr),
        'login-receipt': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginReceiptEn : (this.$i18n.locale === 'de' ? LoginReceiptDe : LoginReceiptFr),
        'login-refund': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginRefundEn : (this.$i18n.locale === 'de' ? LoginRefundDe : LoginRefundFr),
        'login-password-update': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginPasswordUpdateEn : (this.$i18n.locale === 'de' ? LoginPasswordUpdateDe : LoginPasswordUpdateFr),
        'login-lost-password': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginLostPasswordEn : (this.$i18n.locale === 'de' ? LoginLostPasswordDe : LoginLostPasswordFr),
        'login-ocr': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginOCREn : (this.$i18n.locale === 'de' ? LoginOCRDe : LoginOCRFr),
        'login-virtual-card-request': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginVirtualCardRequestEn : (this.$i18n.locale === 'de' ? LoginVirtualCardRequestDe : LoginVirtualCardRequestFr),
        'login-accounting': this.$i18n.locale === 'en' || this.$i18n.locale === 'nl' ? LoginAccountingEn : (this.$i18n.locale === 'de' ? LoginAccountingDe : LoginAccountingFr),
        'logout-inactivity': LogoutInactivity
      }

      return lottieAnimations[this.name] || DocumentSablier
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.animation.destroy()
      this.startAnimation()
    }
  },

  mounted () {
    setTimeout(() => this.startAnimation(), this.timeoutAnimation)

    this.$bus.on('display-end-animation', this.onDisplayEndAnimation)
  },

  beforeUnmount () {
    lottie.destroy(this.animation)
    this.$bus.off('display-end-animation', this.onDisplayEndAnimation)
  },

  methods: {
    async startAnimation () {
      const response = await fetch(this.getAnimation)
      const animationData = await response.json()
        .then(json => {
          const match = config.LOTTIE.MAIN_COLOR?.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)
          if (match) {
            const replace = `[${[1, 2, 3].map(n => (parseInt(match[n], 16) / 255).toFixed(3)).join(',')},1]`
            json = JSON.parse(JSON.stringify(json).replace(/\[0.988,0.212,0.431,1]/ig, replace))
          }
          return json
        })
      this.animation = lottie.loadAnimation({
        container: document.getElementById('lottie'),
        renderer: 'svg',
        loop: this.loop,
        autoplay: true,
        animationData,
        filterSize: this.filterSize,
        rendererSettings: {
          progressiveLoad: true,
          hideOnTransparent: true
        }
      })
      this.animation.setSubframe(false)
      if (this.startFrames.length) {
        this.animation.playSegments(this.startFrames, true)
        this.animation.loop = false
        this.animation.onComplete = this.onCompleteStartAnimation
      } else {
        this.onCompleteStartAnimation()
      }
    },

    onCompleteStartAnimation () {
      if (this.loopFrames.length) {
        this.playLoopFrames()
        this.animation.onComplete = this.playLoopFrames
      } else {
        this.animation.play()
        this.animation.onComplete = this.loop ? null : this.onComplete
      }
    },

    onDisplayEndAnimation () {
      this.animation.loop = false
      this.animation.onComplete = this.onCompleteLoading
    },

    onCompleteLoading () {
      if (this.endFrames && this.endFrames.length) {
        this.animation.playSegments(this.endFrames, true)
      }
      this.animation.onComplete = this.onComplete
    },

    onComplete () {
      this.$emit('animation-ended')
    },

    playLoopFrames () {
      this.animation.playSegments(this.loopFrames)
    }
  }
}
</script>
