<template>
  <modal-dialog-prompt :title="$t('modal.locked_card_proof_required.label')"
                       :subtitle="$t('modal.locked_card_proof_required.content')"
                       @click="$emit('close')">
    <template #footerButtons>
      <button class="btn btn--gray"
              @click.prevent.stop="$emit('close')">
        {{ $t('button.close') }}
      </button>
      <button class="btn btn--primary"
              @click.prevent.stop="addProofsRequired">
        {{ $t('button.add_proofs') }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: { ModalDialogPrompt },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  methods: {
    addProofsRequired () {
      this.$router.push({ name: 'operations-to-justify', params: { uuid: this.uuid } })
    }
  }
}
</script>
