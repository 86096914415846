<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="expenses.length">
      <component-table class="table--hover table-operations">
        <thead>
          <tr>
            <th v-if="isTreated"
                class="col-status" />
            <th v-else>
              <label class="form-check__label checkbox">
                <input v-model="areAllSelected"
                       type="checkbox">
                <span class="checkbox__icon mr-0" />
              </label>
            </th>
            <th class="col--sm-1">
              {{ $t("table.date") }}
            </th>
            <th class="col--sm-4">
              {{ $t("table.label") }}
            </th>
            <th class="col--sm-2">
              <template v-if="filter.order_by !== 'users'">
                {{ $t('form.user.label') }}
              </template>
            </th>
            <th class="col--sm-2 right">
              {{ $t('form.amount.label') }}
            </th>
            <th class="col--sm-2 center">
              {{ $t('form.reliability.label') }}
            </th>
            <th class="col-action" />
          </tr>
        </thead>
        <tbody>
          <template v-for="expenseGroup in groupedExpenses"
                    :key="`header_${expenseGroup.key.uuid || expenseGroup.key.id || expenseGroup.key}`">
            <tr class="header-table-section strong">
              <td colspan="7">
                <span class="arrow">&rsaquo;</span>
                <template v-if="filter.order_by === 'users'">
                  <user-avatar class="ml-1 mr-1"
                               :size="24"
                               :picture="expenseGroup.key.picture"
                               :placeholder-label="expenseGroup.key.name" /> {{ expenseGroup.key.name }}
                </template>
                <template v-else>
                  {{ upperFirst(expenseGroup.key) }}
                </template>
              </td>
            </tr>
            <tr v-for="(expense, expenseIndex) in expenseGroup.data"
                :key="`item_${expenseGroup.key.uuid || expenseGroup.key.id || expenseGroup.key}${expenseIndex}`"
                class="is-link"
                @click="showExpenseDetail(expense, isPending)">
              <td v-if="isPending"
                  @click.stop>
                <label class="form-check__label checkbox">
                  <input v-model="expense.selected"
                         type="checkbox"
                         name="checkbox">
                  <span class="checkbox__icon mr-0" />
                </label>
              </td>
              <td v-if="!isExpensePending(expense)">
                <core-badge v-if="expense.expense_control.status_label"
                            v-bind="getBadgeProps(expense.expense_control.status)"
                            :value="expense.expense_control.status_label" />
              </td>
              <td class="text-muted nowrap">
                {{ formatDate(expense.created_at) }}
              </td>
              <td>
                <div class="text-overflow col-label">
                  {{ expense.label }}
                </div>
                <div class="text-muted">
                  {{ expense.mcc_category ? expense.mcc_category.label : $t('general.no_category') }}
                </div>
              </td>
              <td>
                <template v-if="filter.order_by !== 'users' && expense.user">
                  <div>{{ expense.user.name }}</div>
                  <div v-if="expense.user.teams && expense.user.teams?.length"
                       class="text-muted">
                    {{ expense.user.access.team.name }}
                  </div>
                </template>
              </td>
              <td class="right">
                <span class="amount">
                  {{ formatAmount(expense.amount, expense.currency, { isNegative: true }) }}
                </span>
              </td>
              <td class="center">
                <div v-if="showProofIcon(expense)"
                     v-tooltip="{ content: fileTooltip(expense), theme: 'tooltip' }"
                     class="inline"
                     style="opacity: 0.7">
                  <component :is="proofIcon(expense)"
                             class="ic ic--24 ic--gray"
                             :class="proofIcon(expense)" />
                </div>
                <div v-else-if="expense.reliability_status === 'done' && expense.reliability"
                     v-tooltip="{ content: $t(`form.reliability.filter.${expense.reliability}`), theme: 'tooltip' }"
                     class="inline-flex p-1">
                  <reliability-gauge :value="expense.reliability" />
                </div>
                <div v-else>
                  <img style="width: 3rem; height: auto;"
                       src="@/assets/img/gauge_loader.gif">
                </div>
              </td>
              <td @click.prevent.stop>
                <popover popover-class="popover-actions-menu popover--pull-right">
                  <template #trigger>
                    <button class="btn-link">
                      <ic-check-circle v-if="isExpensePending(expense)"
                                       outline
                                       class="ic ic--24 ic--gray" />
                      <ic-more v-else
                               class="ic ic--24 ic--gray" />
                    </button>
                  </template>
                  <div v-if="expense.expense_control.status !== 'accepted'"
                       class="popover__item"
                       @click="onAcceptExpense(expense)">
                    <div class="popover__item__icon">
                      <ic-check-circle class="ic ic--20 ic--gray" />
                    </div>
                    <div>{{ $t('button.validate_expense') }}</div>
                  </div>
                  <div v-if="expense.documents_actions.includes('invalid')"
                       class="popover__item"
                       @click="setProofInvalid(expense)">
                    <div class="popover__item__icon">
                      <ic-document-invalid class="ic ic--20 ic--gray" />
                    </div>
                    <div>{{ $t('tooltip.invalid_proof') }}</div>
                  </div>
                  <div v-if="expense.expense_control.status !== 'rejected'"
                       class="popover__item"
                       @click="nonCompliantExpense(expense)">
                    <div class="popover__item__icon">
                      <ic-block outline
                                class="ic ic--20 ic--gray" />
                    </div>
                    <div>{{ $t('general.non_compliant_expense') }}</div>
                  </div>
                  <div class="popover__item"
                       @click="requestMoreInfo(expense)">
                    <div class="popover__item__icon">
                      <ic-feedback class="ic ic--20 ic--gray" />
                    </div>
                    <div>{{ $t('button.request_informations') }}</div>
                  </div>
                  <template v-if="!isExpensePending(expense)">
                    <hr class="divider">
                    <div class="popover__item text-red"
                         @click="resetExpenseControl(expense)">
                      <div class="popover__item__icon">
                        <ic-remove class="ic ic--20 ic--error" />
                      </div>
                      <div>{{ $t('button.cancel_control') }}</div>
                    </div>
                  </template>
                </popover>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="expensesPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t('placeholder.no_expense.title')"
                           :content="isPending && !isFiltered ? $t('placeholder.no_expense.subtitle') : null" />
  </div>
</template>

<script>
import { CoreBadge } from '@common/core-ui'
import isEqual from 'lodash/isEqual'
import { upperFirst } from 'lodash/string'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate, formatDateText } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useExpenseStore } from '@/stores/expense'
import { useOperationStore } from '@/stores/operation'

import ClaimNonCompliantOperation from '@/pages/operations/modal/ClaimNonCompliantOperation.vue'
import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import Popover from '@/components/Popover.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcBlock from '@/components/svg/icons/ic-block.vue'
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcDocumentInvalid from '@/components/svg/icons/ic-document-invalid.vue'
import IcDocumentOptional from '@/components/svg/icons/ic-document-optional.vue'
import IcFeedback from '@/components/svg/icons/ic-feedback.vue'
import IcMore from '@/components/svg/icons/ic-more.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'
import ComponentTable from '@/components/Table.vue'
import UserAvatar from '@/components/UserAvatar.vue'

import ReliabilityGauge from './ReliabilityGauge.vue'

export default {
  components: {
    CoreBadge,
    IcDocumentInvalid,
    IcBlock,
    IcCheckCircle,
    IcRemove,
    IcFeedback,
    IcMore,
    UserAvatar,
    Popover,
    ReliabilityGauge,
    ComponentTable,
    ComponentPagination,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['selected-expenses', 'pagination-total-items', 'pending-expenses-count'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const expenseStore = useExpenseStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { expenses, expensesPagination } = storeToRefs(expenseStore)

    return { account, appStore, expenses, expensesPagination, expenseStore, operationStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  computed: {
    isPending () {
      return this.state === 'pending'
    },

    isTreated () {
      return this.state === 'treated'
    },

    isFiltered () {
      return Object.keys(this.filter).some(key => this.filter[key] && !['status', 'order_by', 'page'].includes(key))
    },

    selectedExpenses () {
      return this.expenses.filter(e => e.selected)
    },

    areAllSelected: {
      set (value) {
        this.selectAll(value)
      },

      get () {
        return this.selectedExpenses.length === this.expenses.length
      }
    },

    groupedExpenses () {
      const expenses = []
      this.expenses.forEach(expense => {
        let key = null
        switch (this.filter.order_by) {
          case 'date':
            key = formatDateText(expense.created_at, 'MMMM YYYY')
            break
          case 'users':
            key = expense.user || { name: '' }
            break
          case 'reliability':
            key = this.$i18n.t(`form.reliability.filter.${expense.reliability || 'none'}`)
            break
          case 'amount':
            if (expense.amount < 100) {
              key = this.$i18n.t('form.amount.filter.under_100')
            } else if (expense.amount > 500) {
              key = this.$i18n.t('form.amount.filter.over_500')
            } else {
              key = this.$i18n.t('form.amount.filter.between_100_and_500')
            }
            break
          default:
            key = 'unknown'
        }
        const idx = expenses.findIndex(e => isEqual(e.key, key))
        idx !== -1
          ? expenses[idx].data.push(expense)
          : expenses.push({ key, data: [expense] })
      })
      return expenses
    }
  },

  watch: {
    state () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    selectedExpenses () {
      this.$emit('selected-expenses', this.selectedExpenses)
    },

    'expensesPagination.totalItems' () {
      this.$emit('pagination-total-items', this.expensesPagination.totalItems)
    }
  },

  async created () {
    await this.initData()
  },

  mounted () {
    this.$bus.on('on-update-compliance-success', this.reloadData)
    this.$bus.on('on-accept-expense', this.initData)
    this.$bus.on('on-reset-expense', this.initData)
    this.$bus.on('operation-update-success', this.reloadData)
    this.$bus.on('confirm-accept-all-expenses', this.onAcceptAllExpenses)
    this.$bus.on('on-upload-success', this.reloadData)
    this.$bus.on('delete-success', this.reloadData)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('on-update-compliance-success', this.reloadData)
    this.$bus.off('on-accept-expense', this.initData)
    this.$bus.off('on-reset-expense', this.initData)
    this.$bus.off('operation-update-success', this.reloadData)
    this.$bus.off('confirm-accept-all-expenses', this.onAcceptAllExpenses)
    this.$bus.off('on-upload-success', this.reloadData)
    this.$bus.off('delete-success', this.reloadData)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    getBadgeProps,
    upperFirst,

    formatAmount,

    formatDate,

    async initData (hidePopover = true, hideModal = false, showLoader = true) {
      if (hidePopover) { this.hidePopover() }
      if (hideModal) { this.appStore.closeModal() }
      const parallele = this.loading
      this.loading = showLoader
      await this.getPendingExpensesCount()
      await this.expenseStore.getExpenses(this.state, this.page, this.filter)
      this.selectAll(false)
      if (!parallele) {
        this.loading = false
      }
    },

    reloadData () {
      this.initData(true, false, false)
    },

    async resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : await this.initData()
    },

    async getPendingExpensesCount () {
      const numberOfPendingExpenses = await this.expenseStore.getNumberOfPendingExpenses(this.filter)
      this.$emit('pending-expenses-count', numberOfPendingExpenses)
    },

    showProofIcon (operation) {
      return ['lost', 'invalid', 'optional'].includes(operation.documents_status) ||
        operation.reliability_status === 'none' ||
        !operation.reliability_status
    },

    proofIcon (operation) {
      switch (operation.documents_status) {
        case 'lost':
          return IcWarning
        case 'invalid':
          return IcDocumentInvalid
        case 'optional':
          return IcDocumentOptional
        case 'available':
          return IcDocument
        default:
          return IcAttachment
      }
    },

    fileTooltip (operation) {
      switch (operation.documents_status) {
        case 'lost':
          return this.$i18n.t('tooltip.lost_proof')
        case 'invalid':
          return this.$i18n.t('tooltip.invalid_proof')
        case 'optional':
          return this.$i18n.t('button.optional_proof')
        case 'available':
          return this.$i18n.tc('button.show_proofs', operation.documents.length)
        default:
          return this.$i18n.t('tooltip.attach_files')
      }
    },

    selectAll (value) {
      this.expenses.forEach(expense => { expense.selected = value })
    },

    async resetExpenseControl (expense) {
      const success = await this.expenseStore.resetExpense(expense.uuid)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.reset'))
        await this.initData()
      }
    },

    nonCompliantExpense (expense) {
      this.appStore.showModal(ClaimNonCompliantOperation, { uuid: expense.uuid }, { wrapperClass: 'modal--xs' })
    },

    async onAcceptExpense (expense) {
      const success = await this.expenseStore.acceptExpense(expense)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.accepted'))
        await this.initData()
      }
    },

    async onAcceptAllExpenses () {
      await this.expenseStore.acceptAllExpenses(this.selectedExpenses.map(e => e.uuid))
      await this.initData(false, true)
    },

    async setProofInvalid (expense) {
      const data = await this.operationStore.updateOperationProof(expense.uuid, 'invalid')
      if (data) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.set_invalid'))
        await this.initData()
      }
    },

    async requestMoreInfo (expense) {
      const success = await this.expenseStore.requestMoreInformations(expense.uuid)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.expense_control.request_informations'))
        this.hidePopover()
      }
    },

    isExpensePending (expense) {
      return expense.expense_control?.status === 'pending'
    },

    showExpenseDetail (expense, hasActions) {
      const data = expense
      data.expenseToValidate = hasActions
      this.appStore.showSidePanel(
        OperationDetail,
        { model: data, type: 'expense' },
        { wrapperClass: 'sidepanel--md', backgroundOverlay: true, initShowFile: hasActions }
      )
    },

    hidePopover () {
      const popover = document.querySelector('.popover.is-open')
      popover?.classList.remove('is-open')
    }
  }

}
</script>
