<template>
  <svg viewBox="0 0 200 160">
    <path d="M75.06 36.25a5 5 0 0 0-5 5.06V118c0 .199.08.389.22.53l7 7c.14.141.331.22.53.22h37.45c-.21-.24-.41-.49-.61-.75s-.38-.49-.56-.75H78.53c-.16-5-2-6.83-7-7V41.31a3.54 3.54 0 0 1 3.51-3.56h49.47a3.54 3.54 0 0 1 3.49 3.56v55.95h1.5v-56a5.002 5.002 0 0 0-5-5.06l-49.44.05ZM77 123.11l-4.28-4.28c2.89.27 3.97 1.36 4.28 4.28Z"
          :fill="colorDark" />
    <path d="M99.29 57.25h-23v1.5h23v-1.5Zm-23 6v1.5h32v-1.5h-32Zm0 11.94v1.5h19.9v-1.5h-19.9Zm0 7.56h25v-1.5h-25v1.5Zm0-12h26v-1.5h-26v1.5Z"
          :fill="colorGrey" />
    <g :fill="colorPrimary">
      <path d="M71.54 44.25H128v1.5H71.54v-1.5Z" />
      <path v-if="success"
            d="m138.172 102.63a14.936 14.936 0 00-9.172-3.13c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-2.652-.688-5.142-1.895-7.304l-1.093 1.135a13.453 13.453 0 011.488 6.169c0 7.456-6.044 13.5-13.5 13.5s-13.5-6.044-13.5-13.5 6.044-13.5 13.5-13.5c2.99 0 5.82.976 8.125 2.717l1.047-1.087zm5.197-2.15a.75.75 0 011.151.955l-.071.085-14.601 15.155a.75.75 0 01-.964.099l-.085-.068-6.794-6.271a.75.75 0 01.931-1.172l.087.07 6.254 5.772 14.092-14.625z" />
      <path v-else-if="warning"
            d="m137.5 101.11c-2.118-1.311-4.517-2.099-7-2.3l-1.5 0c-10.117.135-17.459 9.675-15 19.49.536 2.187 1.535 4.233 2.93 6 .2.26.42.51.64.75.22.24.46.51.7.75 8.663 8.461 23.238 4.371 26.233-7.363.191-.747.327-1.508.406-2.275.619-6.004-2.252-11.833-7.389-15.002l-.02-.05zm-8.25 27.64c-3.157-.001-6.222-1.057-8.71-3-.31-.24-.6-.49-.89-.75-.29-.26-.52-.49-.76-.75-1.231-1.306-2.205-2.833-2.87-4.5-3.707-9.259 3.028-19.355 13-19.49l1.5 0c7.573.697 13.265 7.218 12.931 14.816-.334 7.598-6.576 13.595-14.181 13.624l-.02.05zm-.25-11.25.54 0c.07.008.14.008.21 0 .305-.052.539-.302.57-.61l.18-4.63.28-7c.001-.21-.105-.405-.28-.52-.135-.134-.311-.218-.5-.24l-1.4 0c-.407-.027-.758.283-.78.69l.46 11.67c.045.364.353.638.72.64zm.75 2.33c-.162-.051-.33-.078-.5-.08l-.25 0c-1.35.183-1.997 1.758-1.163 2.836.833 1.079 2.521.85 3.038-.41.396-.966-.126-2.063-1.125-2.366l0 .02z" />
    </g>
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  name: 'ImageFile',

  props: {
    success: {
      type: Boolean,
      default: false
    },

    warning: {
      type: Boolean,
      default: false
    },

    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
