<template>
  <div v-show="visible"
       id="section-filters"
       class="section__filters"
       :class="{ 'section__filters--no-results': numberOfResults === 0 }"
       data-cy="filters">
    <form class="form-inline"
          @submit.prevent="onSubmit">
      <div v-if="listFilter.includes(EFilter.Search)"
           class="filter-item filter-search"
           :class="{ 'filter-search--single-input': hasOnlySearchFilter && !hasFilters }"
           data-cy="filters.filter-search">
        <div class="input-group input-group--noborder">
          <span class="input-group__addon input-group__addon--sm">
            <ic-search class="ic ic--addon ic--18 ic--gray" />
          </span>
          <input ref="searchInput"
                 v-model="filters.search"
                 class="form-control form-control--sm"
                 type="text"
                 :placeholder="$t('form.search.placeholder')"
                 @keydown.prevent.stop.enter="onSubmit">
          <button v-if="!isEmpty(filters.search)"
                  class="input-group__addon input-group__addon--sm btn-addon"
                  @click="clearField('search')">
            <ic-remove class="ic ic--16 ic--gray" />
          </button>
        </div>
      </div>
      <div v-if="!hasOnlySearchFilter"
           class="filters"
           :class="{ 'show-advanced-filter': showAdvancedFilter }">
        <div class="row row--sm-center"
             data-cy="filters.grid">
          <transaction-type-filter v-if="listFilter.includes(EFilter.TransactionType)"
                                   v-model="filters.transaction_type"
                                   data-cy="filters.filter-transaction-type"
                                   :select-class="(value) => selectClass(value)"
                                   @clear-field="clearField"
                                   @update:model-value="onSubmit" />
          <date-filter v-if="listFilter.includes(EFilter.DateRange)"
                       v-model:from-value="filters.from"
                       v-model:to-value="filters.to"
                       data-cy="filters.filter-date-range"
                       :disabled-periods="disabledPeriods"
                       @update="onSubmit" />
          <expiration-filter v-if="listFilter.includes(EFilter.Expiration)"
                             v-model="filters.expiration_date"
                             data-cy="filters.filter-expiration"
                             @update:model-value="onSubmit" />
          <type-filter v-if="listFilter.includes(EFilter.Type)"
                       v-model="filters.type"
                       data-cy="filters.filter-type"
                       @clear-field="clearField"
                       @update:model-value="onSubmit" />
          <div v-if="listFilter.includes(EFilter.Category)"
               class="col col--sm-3"
               data-cy="filters.filter-category">
            <div class="filter-item filter-select">
              <component-dropdown label="operationTypes"
                                  :dropdown-width="'100%'"
                                  :dropdown-height="40"
                                  :search="false"
                                  :can-add-item="false"
                                  :values="operationTypes"
                                  :model="filters.category ? operationTypes[filters.category] : null"
                                  @select="onCategorySelected">
                <template #trigger>
                  <div class="select"
                       :class="selectClass(filters.category)">
                    <div class="form-control form-control--sm form-control--noborder">
                      <template v-if="filters.category">
                        {{ selectedCategory }}
                      </template>
                      <template v-else>
                        {{ $t('form.operations.type.placeholder') }}
                      </template>
                    </div>
                    <button v-if="!isEmpty(filters.category)"
                            class="form-control-clear-btn"
                            type="button"
                            @click.prevent.stop="clearField('category')">
                      <ic-remove class="ic ic--16 ic--gray" />
                    </button>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
          <div v-if="listFilter.includes(EFilter.Attachment) && hasPermission($permissions.operationsDocumentsRead)"
               class="col col--sm-3"
               data-cy="filters.filter-attachment">
            <div class="filter-item filter-select">
              <component-dropdown label="attachment"
                                  :dropdown-width="'100%'"
                                  :dropdown-height="21"
                                  :search="false"
                                  :can-add-item="false"
                                  :values="attachmentTypes"
                                  :model="selectedAttachment"
                                  @select="onAttachmentSelected">
                <template #trigger>
                  <div class="select"
                       :class="selectClass(attachmentFilterIsSet)">
                    <div class="form-control form-control--sm form-control--noborder">
                      <template v-if="attachmentFilterIsSet">
                        {{ filterProofsLabel }}
                      </template>
                      <template v-else>
                        {{ $t('form.attachment.filter.placeholder') }}
                      </template>
                    </div>
                    <button v-if="attachmentFilterIsSet"
                            class="form-control-clear-btn"
                            type="button"
                            @click.prevent.stop="clearField('attachment', 'proof_filter')">
                      <ic-remove class="ic ic--16 ic--gray" />
                    </button>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
          <div v-if="listFilter.includes(EFilter.Beneficiary) && hasPermission($permissions.beneficiariesRead)"
               data-cy="filters.filter-beneficiary"
               class="col col--sm-3">
            <div class="filter-item filter-select">
              <component-dropdown async-url="/beneficiary-lists"
                                  :async-url-params="{ expand: 'beneficiaries' }"
                                  :can-add-item="false"
                                  :dropdown-height="30"
                                  :dropdown-width="'100%'"
                                  :label-filter="beneficiaryFilter"
                                  :list-filter="beneficiaryListFilter"
                                  :model="filters.beneficiary_id"
                                  :search="true"
                                  label="beneficiary"
                                  @select="onBeneficiarySelected">
                <template #trigger>
                  <div :class="selectClass(filters.beneficiary_id)"
                       class="select">
                    <div class="form-control form-control--sm form-control--noborder">
                      <template v-if="filters.beneficiary_id">
                        {{ beneficiaryFilter(selectedBeneficiary) }}
                      </template>
                      <template v-else>
                        {{ $t('form.beneficiary_short.placeholder') }}
                      </template>
                    </div>
                    <button v-if="filters.beneficiary_id"
                            class="form-control-clear-btn"
                            type="button"
                            @click.prevent.stop="clearField('beneficiary_id')">
                      <ic-remove class="ic ic--16 ic--gray" />
                    </button>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
          <transfer-type-filter v-if="listFilter.includes(EFilter.IsSepa)"
                                v-model="filters.is_sepa"
                                data-cy="filters.filter-transfer-type"
                                @update:model-value="onSubmit" />
          <div v-if="listFilter.includes(EFilter.ExpenseCategory)"
               data-cy="filters.filter-expense-category"
               class="col col--sm-3">
            <div class="filter-item filter-select">
              <component-dropdown :dropdown-height="24"
                                  :dropdown-width="'100%'"
                                  :model="filters.expense_category"
                                  :search="true"
                                  :can-add-item="false"
                                  :multiselect="false"
                                  :has-icon="true"
                                  async-url="/categories"
                                  label="expense_category"
                                  @select="onCatSelected">
                <template #trigger>
                  <div class="select"
                       :class="selectClass(filters.expense_category)">
                    <div class="form-control form-control--sm form-control--noborder">
                      <template v-if="filters.expense_category">
                        {{ catLabel }}
                      </template>
                      <template v-else>
                        {{ $t('form.category.filter.placeholder') }}
                      </template>
                    </div>
                    <button v-if="!isEmpty(filters.expense_category)"
                            type="button"
                            class="form-control-clear-btn"
                            @click.prevent.stop="clearField('expense_category')">
                      <ic-remove class="ic ic--16 ic--gray" />
                    </button>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
          <card-status-filter v-if="listFilter.includes(EFilter.Status)"
                              v-model="filters.status"
                              data-cy="filters.filter-card-status"
                              :group="cardGroup"
                              @clear-field="clearField('status')"
                              @update:model-value="onSubmit" />
          <card-type-filter v-if="listFilter.includes(EFilter.CardType) || cardGroup === 'virtual'"
                            v-model="filters.type"
                            :card-group="cardGroup"
                            data-cy="filters.filter-card-type"
                            @clear-field="clearField('type')"
                            @update:model-value="onSubmit" />
          <user-filter v-if="listFilter.includes(EFilter.User)"
                       v-model="filters.user_uuid"
                       data-cy="filters.filter-user"
                       @clear-field="clearField" />
          <team-filter v-if="listFilter.includes(EFilter.Team)"
                       v-model="filters.team_uuid"
                       data-cy="filters.filter-team"
                       @clear-field="clearField" />
          <reliability-filter v-if="listFilter.includes(EFilter.Reliability)"
                              v-model="filters.reliability"
                              data-cy="filters.filter-reliability"
                              @clear-field="clearField" />
          <amount-filter v-if="listFilter.includes(EFilter.Amount)"
                         v-model="filters.amount"
                         data-cy="filters.filter-amount"
                         @clear-field="clearField" />
          <min-max-filter v-if="listFilter.includes(EFilter.MinMax)"
                          v-model:min="filters.min"
                          v-model:max="filters.max"
                          data-cy="filters.filter-min-max" />
          <affectation-filter v-if="listFilter.includes(EFilter.Affectation) && hasPermission($permissions.affectationsRead)"
                              v-model="filters.affectation"
                              data-cy="filters.filter-affectation"
                              @clear-field="clearField"
                              @update:model-value="onSubmit" />
          <charge-back-filter v-if="listFilter.includes(EFilter.ChargeBack)"
                              v-model="filters.charge_back"
                              data-cy="filters.filter-charge-back"
                              @clear-field="clearField"
                              @update:model-value="onSubmit" />
          <expense-compliance-filter v-if="listFilter.includes(EFilter.Compliance)"
                                     v-model="filters.is_compliant"
                                     data-cy="filters.filter-compliance"
                                     @clear-field="clearField('is_compliant')"
                                     @update:model-value="onSubmit" />
        </div>
      </div>
      <transition name="show-results">
        <div class="results-line"
             :class="{ 'only-search-bar': hasOnlySearchFilter, 'results-line-hidden': !hasFilters }">
          <div class="filters-applied">
            <loader-spinner v-if="apiLoading || showLoader"
                            size="15" />
            <div v-else
                 class="filters-totals">
              <span class="filters-results"> {{ $tc('form.filter.results.label', numberOfResults) }} </span>
              <total-filter :total-amount="totalAmount"
                            :total-debit="totalDebit"
                            :total-credit="totalCredit" />
            </div>
            <div v-if="!hasOnlySearchFilter"
                 class="reset-filters">
              <button type="button"
                      class="btn-link btn--white btn-filter-reset"
                      @click="onReset">
                <span class="btn-icon btn-icon--left">
                  <ic-remove class="ic ic--16 ic--gray" />
                </span>
                <span class="btn__label">{{ $t('button.delete_filters') }}</span>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </form>
    <button v-if="listFilterLength > 8"
            class="btn--filter"
            data-cy="filters.button-show-more"
            type="button"
            @click="showAdvancedFilter = !showAdvancedFilter">
      <ic-chevron down
                  :class="{ selected: showAdvancedFilter }"
                  class="ic ic--20 ic--gray ic--filter" />
    </button>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { useOperationStore } from '@/stores/operation'
import { EFilter } from '@/types/filter.d'

import AffectationFilter from '@/components/filters/AffectationFilter.vue'
import AmountFilter from '@/components/filters/AmountFilter.vue'
import ChargeBackFilter from '@/components/filters/ChargeBackFilter.vue'
import DateFilter from '@/components/filters/DateFilter.vue'
import ExpenseComplianceFilter from '@/components/filters/ExpenseComplianceFilter.vue'
import ExpirationFilter from '@/components/filters/ExpirationFilter.vue'
import MinMaxFilter from '@/components/filters/MinMaxFilter.vue'
import ReliabilityFilter from '@/components/filters/ReliabilityFilter.vue'
import TeamFilter from '@/components/filters/TeamFilter.vue'
import TotalFilter from '@/components/filters/TotalFilter.vue'
import TransactionTypeFilter from '@/components/filters/TransactionTypeFilter.vue'
import TransferTypeFilter from '@/components/filters/TransferTypeFilter.vue'
import TypeFilter from '@/components/filters/TypeFilter.vue'
import UserFilter from '@/components/filters/UserFilter.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'

import CardStatusFilter from './filters/CardStatusFilter.vue'
import CardTypeFilter from './filters/CardTypeFilter.vue'
import ComponentDropdown from './Dropdown.vue'
import LoaderSpinner from './LoaderSpinner.vue'

dayjs.extend(customParseFormat)

export default {
  name: 'ComponentFilter',

  components: {
    TotalFilter,
    TypeFilter,
    DateFilter,
    ExpirationFilter,
    TransactionTypeFilter,
    AffectationFilter,
    IcRemove,
    IcSearch,
    IcChevron,
    CardStatusFilter,
    AmountFilter,
    ChargeBackFilter,
    ExpenseComplianceFilter,
    MinMaxFilter,
    ReliabilityFilter,
    UserFilter,
    TeamFilter,
    ComponentDropdown,
    LoaderSpinner,
    CardTypeFilter,
    TransferTypeFilter
  },

  props: {
    listFilter: {
      type: Array,
      required: true
    },

    unique: {
      type: Boolean,
      default: false
    },

    defaultVisible: {
      type: Boolean,
      default: false
    },

    cardGroup: {
      type: String,
      default: 'physical'
    },

    numberOfResults: {
      type: Number,
      default: 0
    },

    totalAmount: {
      type: Number,
      default: 0
    },

    totalCredit: {
      type: Number,
      default: 0
    },

    totalDebit: {
      type: Number,
      default: 0
    }
  },

  emits: ['filters'],

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { loading: apiLoading } = storeToRefs(apiStore)
    const { modal } = storeToRefs(appStore)
    const { operationTypes } = storeToRefs(operationStore)

    return {
      apiStore,
      cardStore,

      account,
      apiLoading,
      modal,
      operationTypes
    }
  },

  data () {
    return {
      visible: false,
      state: {
        date: dayjs(),
        unique: null
      },

      filters: {
        amount: null,
        attachment: '',
        beneficiary_id: '',
        card_uuid: '',
        category: '',
        charge_back: null,
        expense_category: '',
        expiration_date: null,
        from: null,
        is_compliant: '',
        is_sepa: null,
        min: '',
        max: '',
        proof_filter: '',
        reliability: null,
        search: '',
        status: [],
        team_uuid: null,
        to: null,
        type: null,
        user_uuid: null,
        affectation: '',
        transaction_type: null
      },

      selectedCard: null,
      selectedBeneficiary: null,
      selectedAttachment: null,
      catLabel: null,
      selectedCategory: null,
      selectedTransactionType: null,
      isFilterWaitingDebounce: false,
      showAdvancedFilter: false
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    hasOnlySearchFilter () {
      return !this.listFilter.some(filter => filter !== EFilter.Search)
    },

    listFilterLength () {
      let filterLength = 0
      this.listFilter.forEach(filter => {
        if (filter === EFilter.Search) filterLength = filterLength + 0
        else if (filter === EFilter.DateRange) filterLength = filterLength + 2
        else filterLength = filterLength + 1
      })
      return filterLength
    },

    disabledPeriods () {
      return {
        before: dayjs(this.account.opening_date, 'YYYY-MM-DD'),
        after: dayjs()
      }
    },

    hasFilters () {
      return this.filters.expiration_date !== null ||
        this.filters.to !== null ||
        this.filters.from !== null ||
        this.filters.search !== '' ||
        this.filters.type !== null ||
        this.attachmentFilterIsSet ||
        this.filters.card_uuid !== '' ||
        this.filters.category !== '' ||
        this.state.unique !== null ||
        this.filters.expense_category ||
        this.filters.beneficiary_id ||
        this.filters.is_compliant !== '' ||
        this.filters.charge_back !== null ||
        this.filters.user_uuid ||
        this.filters.team_uuid ||
        this.filters.reliability ||
        this.filters.amount ||
        this.filters.min ||
        this.filters.max ||
        this.filters.affectation ||
        this.filters.status.length ||
        this.filters.transaction_type ||
        this.filters.is_sepa !== null
    },

    attachmentTypes () {
      return [
        this.$i18n.t('form.attachment.filter.with'),
        this.$i18n.t('form.attachment.filter.without'),
        this.$i18n.t('form.attachment.filter.lost_proofs'),
        this.$i18n.t('form.attachment.filter.optional_proofs'),
        this.$i18n.t('form.attachment.filter.invalid_proofs')
      ]
    },

    attachmentFilterIsSet () {
      return typeof (this.filters.attachment) === 'number' || this.filters.proof_filter
    },

    filterProofsLabel () {
      if (this.filters.attachment === 1) {
        return this.attachmentTypes[0]
      } if (this.filters.attachment === 0) {
        return this.attachmentTypes[1]
      } if (this.filters.proof_filter === 'lost') {
        return this.attachmentTypes[2]
      } if (this.filters.proof_filter === 'optional') {
        return this.attachmentTypes[3]
      } if (this.filters.proof_filter === 'invalid') {
        return this.attachmentTypes[4]
      }
      return null
    },

    showLoader () {
      return this.isFilterWaitingDebounce
    }
  },

  watch: {
    'filters.search' (value) {
      if (value) {
        this.isFilterWaitingDebounce = true
        this.debouncedSubmit()
      } else this.onSubmit()
    },

    'filters.attachment': function () {
      if (!this.attachmentFilterIsSet) {
        this.selectedAttachment = null
      }
    },

    'filters.proof_filter': function () {
      if (!this.attachmentFilterIsSet) {
        this.selectedAttachment = null
      }
    },

    cardGroup: function () {
      this.clearField('type', 'status')
    }
  },

  created () {
    this.visible = this.defaultVisible
    this.debouncedSubmit = debounce(this.onSubmit, 1000)
  },

  mounted () {
    this.$bus.on('focus-next-field', this.focusNextField)
    this.$bus.on('get-filtered-beneficiary', this.sendFilteredBeneficiary)
    this.$bus.on('get-filtered-card', this.sendFilteredCard)
    this.$bus.on('get-filtered-category', this.sendFilteredCategory)
    this.$bus.on('toggle-filters', this.toggleFilters)
    this.$bus.on('account-switched', this.onResetFilterFields)
  },

  beforeUnmount () {
    this.debouncedSubmit.cancel()
    this.$bus.off('focus-next-field')
    this.$bus.off('get-filtered-beneficiary', this.sendFilteredBeneficiary)
    this.$bus.off('get-filtered-card', this.sendFilteredCard)
    this.$bus.off('get-filtered-category', this.sendFilteredCategory)
    this.$bus.off('toggle-filters', this.toggleFilters)
    this.$bus.off('account-switched', this.onResetFilterFields)
  },

  methods: {
    clearField (...fields) {
      fields.forEach(field => {
        this.filters[field] = field === 'status' ? [] : ''
      })

      if (fields.includes('transaction_type')) {
        this.filters.transaction_type = null
      }

      if (!fields.includes('search')) {
        this.onSubmit()
      }
    },

    onSubmit () {
      const filters = { ...this.filters }

      // TODO iterate through filters and delete keys when empty

      if (this.state.unique !== null) {
        filters.unique = this.state.unique
      }

      this.apiStore.abortAllRequests()
      this.$emit('filters', filters)
      this.$bus.emit('filters', filters)
      this.isFilterWaitingDebounce = false
    },

    onReset () {
      this.onResetFilterFields()
      this.onSubmit()
    },

    focusNextField (id) {
      if (['datepicker-from', 'datepicker-to'].includes(this.id)) {
        if (id === 'datepicker-from') {
          this.$bus.emit('focus-field', 'datepicker-to')
        } else if (id === 'datepicker-to') {
          this.$bus.emit('blur-field', 'datepicker-to')
        }
        if (!this.modal.active) {
          this.onSubmit()
        }
      }
    },

    onResetFilterFields () {
      this.state.unique = null
      this.selectedBeneficiary = null
      this.filters = {
        amount: null,
        affectation: '',
        attachment: '',
        beneficiary_id: '',
        card_uuid: '',
        category: '',
        charge_back: null,
        expense_category: '',
        expiration_date: null,
        from: null,
        is_compliant: '',
        is_sepa: null,
        min: '',
        max: '',
        proof_filter: '',
        reliability: null,
        search: '',
        status: [],
        team_uuid: null,
        transaction_type: null,
        to: null,
        type: null,
        user_uuid: null
      }
    },

    isEmpty (value) {
      if (Array.isArray(value)) {
        return !value.length
      }
      return value === '' || value === null
    },

    selectClass (value) {
      return this.isEmpty(value) ? 'select--off' : 'is-clearable'
    },

    beneficiaryFilter (beneficiary) {
      return `${beneficiary.label}`
    },

    beneficiaryListFilter (list) {
      return list.reduce((acc, cur) => {
        if (cur.beneficiaries.length) {
          acc.push({
            label: cur.label,
            items: cur.beneficiaries.filter(b => !(b.validation && b.validation.status === 'pending'))
          })
        }
        return acc
      }, [])
    },

    onBeneficiarySelected (beneficiary) {
      this.filters.beneficiary_id = beneficiary.id
      this.selectedBeneficiary = beneficiary
      this.onSubmit()
    },

    sendFilteredBeneficiary () {
      this.$bus.emit('send-filtered-beneficiary', this.selectedBeneficiary)
    },

    sendFilteredCard () {
      this.$bus.emit('send-filtered-card', this.selectedCard)
    },

    sendFilteredCategory () {
      this.$bus.emit('send-filtered-category', this.catLabel)
    },

    cardFilter (card) {
      return `•••• ${card.last_digits ? card.last_digits : '••••'} - ${card.first_name} ${card.last_name}`
    },

    onCardSelected (card) {
      this.filters.card_uuid = card.uuid
      this.selectedCard = card
      this.onSubmit()
    },

    onAttachmentSelected (attachment) {
      const attachmentFilter = {
        proof_filter: '',
        attachment: ''
      }
      if (attachment === this.attachmentTypes[0]) {
        attachmentFilter.attachment = 1
      } else if (attachment === this.attachmentTypes[1]) {
        attachmentFilter.attachment = 0
      } else if (attachment === this.attachmentTypes[2]) {
        attachmentFilter.proof_filter = 'lost'
      } else if (attachment === this.attachmentTypes[3]) {
        attachmentFilter.proof_filter = 'optional'
      } else if (attachment === this.attachmentTypes[4]) {
        attachmentFilter.proof_filter = 'invalid'
      }
      Object.assign(this.filters, attachmentFilter)
      this.selectedAttachment = attachment
      this.onSubmit()
    },

    onCatSelected (selectedCat) {
      this.filters.expense_category = selectedCat.id
      this.catLabel = selectedCat.label
      this.onSubmit()
    },

    onCategorySelected (category) {
      for (const key in this.operationTypes) {
        if (this.operationTypes[key] === category) {
          this.filters.category = key
        }
      }
      this.selectedCategory = category
      this.onSubmit()
    },

    toggleFilters () {
      const isStuck = document.getElementsByClassName('is-stuck')
      if (isStuck.length) {
        this.visible = true
        this.$bus.emit('scrollToTop')
      } else {
        this.visible = !this.visible
      }

      if (this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    }
  }
}
</script>
