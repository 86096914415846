<template>
  <section class="section section-capital-gain"
           :class="{ 'section--empty': areFundsRequested }">
    <div class="section__heading">
      <h1 class="section-title">
        {{ $t('general.capital_increase') }}
      </h1>
    </div>
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <div v-else
         class="section__content">
      <form v-if="!isCapitalGainValidated && !areFundsRequested"
            class="form form--w860 form--centered"
            @submit.prevent>
        <div class="center mb-3">
          <div class="mb-2">
            <div class="total-capital-gain">
              {{ formatAmount(totalCapitalGain, account.currency) }}
            </div>
            <span>{{ $t('capital_gain.amount_received_on') }} {{ formatDateText(new Date()) }} </span>
          </div>
          <div v-if="isWaitingClientValidation"
               class="btn-wrapper mb-2">
            <VTooltip theme="poptip"
                      :disabled="canFinishCapitalGain">
              <template #popper>
                <poptip :title="$t('popover.capital_gain.documents.title')"
                        :message="$t('popover.capital_gain.documents.message')" />
              </template>
              <div class="v-popper__button-wrapping">
                <button class="btn btn--primary btn--sm"
                        :class="{ 'pointer-none': !canFinishCapitalGain }"
                        :disabled="!canFinishCapitalGain"
                        @click="onSubmit">
                  {{ $t('button.finish_capital_gain') }}
                </button>
              </div>
            </VTooltip>
          </div>
        </div>
        <component-alert v-if="!isWaitingClientValidation && !areFundsRequested"
                         class="mb-3"
                         type="info"
                         :message="$t('alerts.pending_capital_gain')" />
        <div class="capital-gain-block bordered-box p-3">
          <div class="capital-gain-block-header">
            <ic-square depot-capital
                       class="ic ic--48" />
            <div>
              <h3 class="strong">
                {{ $t('capital_gain.funds_deposit.title') }}
              </h3>
              <span>
                {{ $t('capital_gain.funds_deposit.subtitle') }}
              </span>
              <button class="text-muted underline strong download-link"
                      @click="onExportDownload">
                {{ $t('capital_gain.funds_deposit.export') }}
              </button>
            </div>
          </div>
          <div class="bordered-box bordered-box--padding">
            <table v-if="operations.length"
                   class="table table--fixed">
              <tbody>
                <tr v-for="(item, index) in operations"
                    :key="index">
                  <td class="col--sm-6 col-nowrap">
                    <div class="user-wrapper">
                      <user-avatar class="user-icon"
                                   type="team"
                                   :size="42"
                                   :placeholder-label="item.debtor_details ? item.debtor_details.name : $t('general.not_provided')" />
                      <div class="user-details">
                        <div class="strong">
                          {{ item.debtor_details ? item.debtor_details.name : $t('general.not_provided') }}
                        </div>
                        <div class="text-muted">
                          {{ item.debtor_details ? item.debtor_details.label : item.category_label }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="col--sm-3 center">
                    <span class="text-muted">
                      {{ $t('general.received_the') }} {{ formatDateText(item.created_at) }}
                    </span>
                  </td>
                  <td class="col--sm-3 right">
                    <div class="strong">
                      {{ formatAmount(item.amount, account.currency) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else
                 class="center text-muted">
              {{ $t('placeholder.capital_gain.no_funds') }}
            </div>
          </div>
        </div>
        <div v-if="isWaitingClientValidation"
             class="capital-gain-block bordered-box p-3 mt-4">
          <div class="capital-gain-block-header">
            <ic-square documents
                       class="ic ic--48" />
            <div>
              <h3 class="strong">
                {{ $t('capital_gain.documents.title') }}
              </h3>
              <span>
                {{ $t('capital_gain.documents.subtitle') }}
              </span>
            </div>
          </div>
          <div v-if="!capitalGainGeneralAssemblyDocuments.length"
               class="bordered-box bordered-box--dashed bordered-box--padding dropzone-area"
               @click.prevent.stop="joinDocument('capital_gain_general_assembly', false)">
            <button class="btn btn--outline btn-attachment">
              <ic-attachment class="ic ic--20 ic--gray" />
            </button>
            {{ $t('capital_gain.documents.general_meeting') }}
          </div>
          <template v-else>
            <file-item-row v-for="(item, index) in capitalGainGeneralAssemblyDocuments"
                           :key="`general_meeting_${index}`"
                           class="mb-1"
                           :file="item"
                           :display-added-on="true"
                           @delete="requestDeleteDocument(item.id)" />
          </template>
          <div v-if="!capitalGainGeneralCompanyStatus.length"
               class="bordered-box bordered-box--dashed bordered-box--padding dropzone-area"
               @click.prevent.stop="joinDocument('capital_gain_company_status', false)">
            <button class="btn btn--outline btn-attachment">
              <ic-attachment class="ic ic--20 ic--gray" />
            </button>
            {{ $t('capital_gain.documents.status_project') }}
            <span v-tooltip="{ content: $t('tooltip.capital_gain_status'), theme: 'poptip' }"
                  class="ic-helper">
              <ic-info outline
                       class="ic" />
            </span>
          </div>
          <template v-else>
            <file-item-row v-for="(item, index) in capitalGainGeneralCompanyStatus"
                           :key="`status_project_${index}`"
                           class="mb-1"
                           :file="item"
                           :display-added-on="true"
                           @delete="requestDeleteDocument(item.id)" />
          </template>
          <file-item-row v-for="(item, index) in capitalGainGeneralSubscriptionForm"
                         :key="`subscription_form_${index}`"
                         class="mb-1"
                         :file="item"
                         :display-added-on="true"
                         @delete="requestDeleteDocument(item.id)" />
          <div class="bordered-box bordered-box--dashed bordered-box--padding dropzone-area"
               @click.prevent.stop="joinDocument('capital_gain_subscription_form', true)">
            <button class="btn btn--outline btn-attachment">
              <ic-attachment class="ic ic--20 ic--gray" />
            </button>
            {{ $t('capital_gain.documents.subscription_form') }}
          </div>
        </div>
      </form>
      <template v-else>
        <div class="placeholder-action-description">
          <component-placeholder :label="$t('capital_gain.complete.title')"
                                 :content="areFundsRequested ? $t('capital_gain.complete.subtitle_funds_requested') : $t('capital_gain.complete.subtitle_download_certificate')">
            <capital-deposit />
          </component-placeholder>
          <div v-if="capitalGain.capital_gain_certificate"
               class="center">
            <button class="btn btn--outline btn--sm"
                    @click="downloadFileFromUrl(capitalGain.capital_gain_certificate.url)">
              {{ $t('button.download_the_certificate') }}
            </button>
          </div>
        </div>
        <form v-if="!areFundsRequested"
              class="form form--w860 form--centered"
              @click.prevent>
          <div class="capital-gain-block bordered-box p-3 mt-4">
            <div class="capital-gain-block-header">
              <ic-square documents
                         class="ic ic--48" />
              <div>
                <h3 class="strong">
                  {{ $t('capital_gain.documents.title') }}
                </h3>
                <span>
                  {{ $t('capital_gain.documents.subtitle_funds_release') }}
                </span>
              </div>
            </div>
            <div v-if="!capitalGainReportDocuments.length"
                 class="bordered-box bordered-box--dashed bordered-box--padding dropzone-area"
                 @click.prevent.stop="joinDocument('capital_gain_report', false)">
              <button class="btn btn--outline btn-attachment">
                <ic-attachment class="ic ic--20 ic--gray" />
              </button>
              {{ $t('capital_gain.documents.minutes_of_recognition') }}
            </div>
            <template v-else>
              <file-item-row v-for="(item, index) in capitalGainReportDocuments"
                             :key="`capital_gain_report_${index}`"
                             class="mb-1"
                             :file="item"
                             :display-added-on="true"
                             @delete="requestDeleteDocument(item.id)" />
            </template>
            <div class="center mt-3 mb-1">
              <button class="btn btn--primary btn--sm m-auto"
                      :disabled="!capitalGainReportDocuments.length || isRequestFundsLoading"
                      @click="requestFunds">
                {{ $t('button.request_funds_release') }}
              </button>
            </div>
          </div>
        </form>
      </template>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'

import ComponentAlert from '@/components/Alert.vue'
import FileItemRow from '@/components/FileItemRow.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ConfirmCapitalGain from '@/components/modals/ConfirmCapitalGain.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import Poptip from '@/components/Poptip.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcSquare from '@/components/svg/icons/ic-square.vue'
import CapitalDeposit from '@/components/svg/img/capital-deposit.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcAttachment,
    IcInfo,
    IcSquare,
    CapitalDeposit,
    ComponentAlert,
    ComponentPlaceholder,
    FileItemRow,
    UserAvatar,
    LoaderSpinner,
    Poptip
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { operations } = storeToRefs(operationStore)

    return { account, accountStore, appStore, operations, operationStore }
  },

  data () {
    return {
      loading: true,
      isRequestFundsLoading: false,
      capitalGain: {}
    }
  },

  computed: {
    totalCapitalGain () {
      return this.operations.reduce((total, transfer) => total + transfer.amount, 0)
    },

    isWaitingClientValidation () {
      return this.capitalGain.status === 'waiting_for_client'
    },

    isCapitalGainValidated () {
      return this.capitalGain.status === 'signed'
    },

    areFundsRequested () {
      return this.capitalGain.status === 'request_funds'
    },

    canFinishCapitalGain () {
      return this.capitalGain.documents.filter(i => i.type !== 'capital_gain_report').every(i => i.documents.length)
    },

    capitalGainGeneralAssemblyDocuments () {
      const documents = this.capitalGain.documents.find(i => i.type === 'capital_gain_general_assembly')
      return documents.documents || []
    },

    capitalGainGeneralCompanyStatus () {
      const documents = this.capitalGain.documents.find(i => i.type === 'capital_gain_company_status')
      return documents.documents || []
    },

    capitalGainGeneralSubscriptionForm () {
      const documents = this.capitalGain.documents.find(i => i.type === 'capital_gain_subscription_form')
      return documents.documents || []
    },

    capitalGainReportDocuments () {
      const documents = this.capitalGain.documents.find(i => i.type === 'capital_gain_report')
      return documents.documents || []
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('on-upload-success', this.updateCapitalGainStatus)
    this.$bus.on('delete-success', this.updateCapitalGainStatus)
    this.$bus.on('capital-gain-signature-requested', this.updateCapitalGainStatus)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('on-upload-success', this.updateCapitalGainStatus)
    this.$bus.off('delete-success', this.updateCapitalGainStatus)
    this.$bus.off('capital-gain-signature-requested', this.updateCapitalGainStatus)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    downloadFileFromUrl,

    formatAmount,

    formatDateText,

    async initData () {
      this.loading = true
      await this.updateCapitalGainStatus()
      await this.operationStore.getOperations(1, { expand: 'debtor_details', category: 'transfer', type: 'credit' })
      this.loading = false
    },

    async updateCapitalGainStatus () {
      this.capitalGain = await this.accountStore.getCapitalGainStatus()
    },

    joinDocument (documentType, hasMultipleFiles) {
      this.appStore.showDropzoneModal(
        this.$i18n.tc('title.attach_file', hasMultipleFiles ? 2 : 1),
        {},
        { url: `/account-document/${documentType}`, maxFiles: (hasMultipleFiles ? null : 1) }
      )
    },

    requestDeleteDocument (documentId) {
      this.appStore.showDeleteModal({ label: this.$i18n.t('prompt.file.delete.title'), content: this.$i18n.t('prompt.file.delete.content'), url: `/account-document/${documentId}` })
    },

    onSubmit () {
      this.appStore.showModal(ConfirmCapitalGain)
    },

    async onExportDownload () {
      const filter = {
        format: 'xlsx',
        include_attach: false,
        accounting: false
      }
      await downloadFileFromUrl('/operations/export', filter, true)
    },

    async requestFunds () {
      this.isRequestFundsLoading = true
      await this.accountStore.capitalGainRequestFunds()
      await this.updateCapitalGainStatus()
      this.isRequestFundsLoading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.download-link
  margin-left 3px
</style>
