<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="transfers.length">
      <component-alert v-if="isAdvancedPaymentAlertVisible"
                       :message="$t('advance_payment.button.to_validate')"
                       data-cy="advance-payments.validate-alert"
                       type="info">
        <component-button class="btn btn--xs btn--info"
                          :label="$t('button.display')"
                          @click="$router.push({ name: 'advance-payments' })" />
      </component-alert>
      <component-alert v-if="isTransferBatchValidationAlertVisible"
                       :message="$tc('alerts.transfer_wait_validation', transferWaitingValidationCount)"
                       type="info">
        <component-button :label="$t('button.validate_all')"
                          wrapper-class="btn btn--xs btn--info"
                          @click="validateAll" />
      </component-alert>
      <component-table data-cy="transfers.list"
                       class="table--hover table--fixed table--row-fixed">
        <thead>
          <tr>
            <th class="col--sm-1" />
            <th class="col-date">
              {{ $t("table.date") }}
            </th>
            <th :class="`col--sm-${5 - Number(showColAction) - Number(showColDocuments)}`">
              {{ $t("general.reason") }}
            </th>
            <th :class="`col--sm-${3 - Number(showColAction)}`">
              {{ $t("table.beneficiary") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.amount") }}
            </th>
            <th v-if="showColDocuments"
                class="center col--sm-1">
              {{ $t("table.documents") }}
            </th>
            <th v-if="showColAction"
                class="col-action"
                :class="setClassColAction" />
          </tr>
        </thead>
        <tbody>
          <template v-for="(transfersList, transferStatus) in orderedTransfers"
                    :key="transferStatus">
            <tr v-if="showTransfersSort"
                class="header-table-section">
              <td :colspan="5 + Number(showColAction) + Number(showColDocuments)">
                <span class="arrow">&rsaquo;</span>{{ transferStatus }}
              </td>
            </tr>
            <tr v-for="item in transfersList"
                :id="`transfer-${item.id}`"
                :key="item.id"
                class="is-link"
                :class="{ 'is-cancelled': item.status === 'cancelled' }">
              <td @click="showTransferDetail(item)">
                <core-badge v-if="item.status"
                            v-bind="getBadgeProps(item.status)"
                            :value="item.status_label">
                  <template #default="{ value }">
                    <TooltipPoptip :value="value"
                                   placement="bottom-start"
                                   :title="$t(`popover.transfer.${item.status}.title`)"
                                   :message="$t(`popover.transfer.${item.status}${item.status === 'to_validate' ? subUserToValidateMessage(item) : ''}.message`)" />
                  </template>
                </core-badge>
              </td>
              <td class="nowrap"
                  @click="showTransferDetail(item)">
                {{ formatDate(item.execution_date) }}
              </td>
              <td class="text-overflow"
                  @click="showTransferDetail(item)">
                <span>{{ item.operation.label }}</span>
              </td>
              <td class="text-overflow"
                  @click="showTransferDetail(item)">
                <span><template v-if="item.beneficiary">{{ item.beneficiary.label }}</template></span>
              </td>
              <td class="right"
                  @click="showTransferDetail(item)">
                <div class="amount">
                  {{ formatAmount(item.amount, item.currency) }}
                </div>
                <core-badge v-if="displayFxAmount(item)"
                            :theme="ECoreBadgeTheme.Currency"
                            :size="ECoreBadgeSize.Small"
                            :fill="ECoreBadgeFill.Outline"
                            :value="formatAmount(item.fx_amount, item.fx_currency, { currencyDisplay: 'code' })" />
              </td>
              <td v-if="showColDocuments"
                  class="center">
                <template v-if="item.operation">
                  <button v-if="canReadDocument(item)"
                          v-tooltip="{ content: fileViewerTooltip(item.operation.documents), theme: 'tooltip' }"
                          class="btn-link btn-attachment"
                          :data-cy="`transfers.list.table.item-${item.id}.view-document`"
                          @click.prevent.stop="showFile(item)">
                    <ic-document class="ic ic--gray" />
                  </button>
                  <button v-else-if="canWriteDocument(item)"
                          v-tooltip="{ content: $t('tooltip.attach_files'), theme: 'tooltip' }"
                          class="btn-link btn-attachment"
                          :data-cy="`transfers.list.table.item-${item.id}.attach-document`"
                          @click.prevent.stop="appStore.showDropzoneModal($tc('title.attach_file', 2), item.operation, { url: `/operations/${item.operation.uuid}/documents`, canAnalyzeFile: true })">
                    <ic-attachment class="ic ic--gray" />
                  </button>
                </template>
              </td>
              <td v-if="showColAction"
                  class="col-action"
                  :class="setClassColAction">
                <span class="hover-item">
                  <router-link v-if="canEditTransfer(item)"
                               v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                               class="btn-link"
                               :data-cy="`transfers.list.table.item-${item.id}.modify`"
                               :to="{ name: 'transfers-edit', params: { id: item.id } }">
                    <ic-pencil class="ic ic--24 ic--gray" />
                  </router-link>
                  <button v-if="canCancelTransfer(item)"
                          v-tooltip="{ content: $t('tooltip.cancel'), theme: 'tooltip' }"
                          class="btn-link"
                          :data-cy="`transfers.list.table.item-${item.id}.cancel`"
                          @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.transfer.cancel.title'), content: $t('prompt.transfer.cancel.content'), url: `/transfers/${item.id}` })">
                    <ic-remove class="ic ic--24 ic--gray" />
                  </button>
                  <button v-if="canValidateTransfer(item)"
                          v-tooltip="{ content: $t('tooltip.validate_transfer'), theme: 'tooltip' }"
                          class="btn-link hover-item-visible"
                          @click.prevent.stop="showValidationTransferDetail(item)">
                    <ic-check-circle outline
                                     class="ic ic--24 ic--gray" />
                  </button>
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="transfersPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t('placeholder.no_transfer.title')"
                           :content="''" />
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate, groupByDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useTransferStore } from '@/stores/transfer'

import TransferDetail from '@/pages/transfers/sidepanel/TransferDetail.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Confirm from '@/components/modals/Confirm.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import ComponentTable from '@/components/Table.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'

export default {
  components: {
    TooltipPoptip,
    CoreBadge,
    IcAttachment,
    IcDocument,
    IcPencil,
    IcCheckCircle,
    IcRemove,
    ComponentButton,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    ComponentAlert,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const transferStore = useTransferStore()

    const { fileViewer } = storeToRefs(appStore)
    const { user } = storeToRefs(authStore)
    const { displaySalaryTab, account } = storeToRefs(accountStore)
    const { transfers, transfersPagination } = storeToRefs(transferStore)

    return {
      account,
      appStore,
      displaySalaryTab,
      fileViewer,
      user,
      transfers,
      transfersPagination,
      transferStore
    }
  },

  data () {
    return {
      loading: true,
      countAdvancePayments: 0
    }
  },

  computed: {
    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    transferWaitingValidationCount () {
      return this.account.counts?.transfers?.waiting_validation
    },

    isAccountOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    isAll () {
      return this.state === 'all'
    },

    isSalaries () {
      return this.state === 'salary'
    },

    isCanceled () {
      return this.state === 'history'
    },

    alertMessage () {
      return this.isAccountOwner
        ? this.$tc('alerts.transfer_wait_validation', this.transferWaitingValidationCount)
        : this.$tc('alerts.transfer_wait_validation_subuser', this.transferWaitingValidationCount)
    },

    isAdvancedPaymentAlertVisible () {
      return hasFeature(EFeature.TransferCreationMultipleAdvancedPayments) && this.isSalaries && this.countAdvancePayments > 0
    },

    isTransferBatchValidationAlertVisible () {
      return hasFeature(EFeature.TransferBatchValidation) && this.transferWaitingValidationCount > 1 && !this.isSalaries && !this.isCanceled
    },

    showColDocuments () {
      return hasFeature(EFeature.TransferDocument)
    },

    showColAction () {
      return hasFeature(EFeature.TransferAction) && this.transfers.some(transfer => this.canEditTransfer(transfer))
    },

    sortedTransfers () {
      const sorted = {
        [this.$i18n.t('label.transfers_to_validate')]: [],
        [this.$i18n.t('label.all_transfers')]: []
      }

      this.transfers.forEach(transfer => {
        const statusKey = this.canValidateTransfer(transfer)
          ? this.$i18n.t('label.transfers_to_validate')
          : this.$i18n.t('label.all_transfers')
        sorted[statusKey].push(transfer)
      })

      Object.keys(sorted).forEach(key => {
        if (sorted[key].length === 0) {
          delete sorted[key]
        }
      })

      return sorted
    },

    orderedTransfers () {
      return this.isSalaries
        ? groupByDate(this.transfers, 'payslip_period', { dateFieldFormat: 'MM-YYYY' })
        : this.sortedTransfers
    },

    showTransfersSort () {
      return this.isSalaries || (this.isAll && this.transferWaitingValidationCount)
    },

    setClassColAction () {
      if (this.transferWaitingValidationCount) {
        return 'col-action--3-items pl-0'
      } else if (this.showColAction) {
        return 'col-action--2-items pl-0'
      }
      return ''
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'transfersPagination.totalItems' () {
      this.$emit('pagination-total-items', this.transfersPagination.totalItems)
    }
  },

  created () {
    this.initData(true)
  },

  mounted () {
    this.$bus.on('transfers-cancel', this.initData)
    this.$bus.on('language-changed', this.initData)
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('operation-update-success', this.reloadData)
    this.$bus.on('download-export-success', this.appStore.closeSidePanel)
    this.$bus.on('transfer-validate-success', this.onTransferValidateSuccess)
    this.$bus.on('confirm-accept-all-transfers', this.validateAllConfirmation)
  },

  beforeUnmount () {
    this.$bus.off('transfers-cancel', this.initData)
    this.$bus.off('language-changed', this.initData)
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('operation-update-success', this.reloadData)
    this.$bus.off('download-export-success', this.appStore.closeSidePanel)
    this.$bus.off('transfer-validate-success', this.onTransferValidateSuccess)
    this.$bus.off('confirm-accept-all-transfers', this.validateAllConfirmation)
  },

  methods: {
    getBadgeProps,
    formatAmount,

    formatDate,

    async initData (showLoader = true) {
      this.loading = showLoader
      await this.transferStore.getTransfers(this.state, this.page, this.filter)
      this.loading = false

      if (this.displaySalaryTab && hasFeature(EFeature.TransferCreationMultipleAdvancedPayments) && this.isInSalaryDateRange()) {
        this.countAdvancePayments = await this.transferStore.getCountAdvancePayments()
      }
    },

    reloadData () {
      this.initData(false)
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    fileViewerTooltip (documents) {
      return this.$i18n.tc('tooltip.show_file', documents.length)
    },

    onDeleteSuccess (message) {
      if (!this.fileViewer.active) {
        showToastSuccess(message || this.$i18n.t('message.success.transfer.cancel'))
      }

      this.appStore.closeSidePanel()
      this.reloadData()
    },

    showTransferDetail (transfer) {
      this.canValidateTransfer(transfer)
        ? this.showValidationTransferDetail(transfer)
        : this.appStore.showSidePanel(TransferDetail, { id: transfer.id }, {
          wrapperClass: 'sidepanel--md',
          backgroundOverlay: true,
          canDragAndDrop: true
        })
    },

    isInSalaryDateRange () {
      return dayjs().format('DD') >= 14 && dayjs().format('DD') < 22
    },

    canValidateTransfer (transfer) {
      return hasFeature(EFeature.TransferDetailValidation, transfer)
    },

    canEditTransfer (transfer) {
      return hasFeature(EFeature.TransferEdition) && transfer.updatable
    },

    canCancelTransfer (transfer) {
      return hasFeature(EFeature.TransferCancellation) && transfer.cancellable
    },

    canReadDocument (transfer) {
      return hasFeature(EFeature.TransferDocument) && transfer.operation.documents?.length
    },

    canWriteDocument (transfer) {
      return hasFeature(EFeature.TransferDocumentUpload) && transfer.status !== 'cancelled'
    },

    onTransferValidateSuccess (multiple = false) {
      showToastSuccess(this.$t(`message.success.${multiple ? 'transfers' : 'transfer'}.validate`))
      this.initData()
    },

    displayFxAmount (transfer) {
      return transfer.fx_currency !== 'EUR' && !transfer.beneficiary.is_sepa && transfer.fx_currency !== null
    },

    subUserToValidateMessage (item) {
      return this.canValidateTransfer(item) ? '' : '_subuser'
    },

    validateAll () {
      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.tc('prompt.transfers.validate_multiple.title', this.transferWaitingValidationCount, { number: this.transferWaitingValidationCount }),
          content: this.$i18n.tc('prompt.transfers.validate_multiple.content', this.transferWaitingValidationCount, { number: this.transferWaitingValidationCount }),
          event: 'accept-all-transfers'
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    async validateAllConfirmation () {
      const success = await this.transferStore.validateAllTransfers()
      if (success) {
        this.onTransferValidateSuccess(true)
      }
    },

    showFile (item) {
      this.appStore.showSidePanel(TransferDetail, { id: item.id }, {
        wrapperClass: 'sidepanel--md',
        backgroundOverlay: true,
        canDragAndDrop: true,
        initShowFile: true
      })
    },

    showValidationTransferDetail (item) {
      hasFeature(EFeature.TransferDocument) && item.operation?.documents?.length
        ? this.showFile(item)
        : this.appStore.showSidePanel(TransferDetail, { id: item.id }, {
          wrapperClass: 'sidepanel--md',
          backgroundOverlay: true,
          canDragAndDrop: true
        })
    }
  }
}
</script>
