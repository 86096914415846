<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="mileageUsers.length">
      <component-table class="table--hover table-utilisateurs">
        <thead>
          <tr>
            <th class="col--sm-3">
              {{ $t('table.user') }}
            </th>
            <th class="col--sm-3">
              {{ $t('table.vehicle') }}
            </th>
            <th class="col--sm-3">
              {{ $t('table.mileage_per_year') }}
            </th>
            <th class="col--sm-2 center">
              {{ $t('general.vehicle_registration_document') }}
            </th>
            <th class="th-action" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in mileageUsers"
              :key="item.id"
              class="is-link"
              @click="showVehicleDetail(item)">
            <td class="col-nowrap">
              <div class="user-wrapper">
                <user-avatar class="user-icon"
                             :size="40"
                             :picture="item.picture"
                             :placeholder-label="`${item.first_name} ${item.last_name}`" />
                <div class="user-details">
                  <div class="strong">
                    {{ item.first_name }} {{ item.last_name }}
                  </div>
                  <div v-if="item.role"
                       class="text-muted">
                    {{ item.role_label }}
                  </div>
                </div>
              </div>
            </td>
            <td :colspan="item.vehicle ? 1 : 2">
              <template v-if="item.vehicle">
                {{ item.vehicle.label }}
                <ic-electrical v-if="item.vehicle.is_electrical"
                               v-tooltip="{ content: $t('tooltip.electric_vehicle'), theme: 'tooltip', html: true }"
                               class="ic ic--14 ml-1" />
              </template>
              <span v-else
                    class="d-flex align-items-center label-empty">
                <ic-error outline
                          class="ic ic--20 ic--gray mr-1" />
                <span class="text-muted strong">{{ $t('placeholder.vehicle_informations_not_provided') }}</span>
              </span>
            </td>
            <td v-if="item.vehicle">
              {{ item.vehicle.mileage_estimation_label }}
            </td>
            <td class="center">
              <button v-if="item.vehicle?.files"
                      v-tooltip="{ content: $tc('tooltip.show_file'), theme: 'tooltip' }"
                      class="btn-link btn-attachment"
                      @click.prevent.stop="showFile(item)">
                <ic-document class="ic ic--gray" />
              </button>
            </td>
            <td class="col-action">
              <span class="hover-item">
                <button v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                        class="btn-link">
                  <ic-pencil class="ic ic--24 ic--gray" />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </component-table>
      <component-pagination :pagination="mileageUsersPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t('placeholder.no_user.title')" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { useAppStore } from '@/stores/app'
import { useMileageStore } from '@/stores/mileage'

import AddVehicle from '@/pages/mileage/sidepanel/AddVehicle.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcElectrical from '@/components/svg/icons/ic-electrical.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import ComponentTable from '@/components/Table.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcDocument,
    IcPencil,
    IcError,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    UserAvatar,
    LoaderSpinner,
    IcElectrical
  },

  props: {
    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const mileageStore = useMileageStore()

    const { mileageUsers, mileageUsersPagination } = storeToRefs(mileageStore)

    return { appStore, mileageUsers, mileageUsersPagination, mileageStore }
  },

  data () {
    return {
      store,
      loading: true
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'mileageUsersPagination.totalItems' () {
      this.$emit('pagination-total-items', this.mileageUsersPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('vehicle-infos-updated', this.onVehicleInfosUpdated)
  },

  beforeUnmount () {
    this.$bus.off('vehicle-infos-updated', this.onVehicleInfosUpdated)
  },

  methods: {
    async initData () {
      this.loading = true
      await this.mileageStore.getMileageUsers(this.page, { ...this.filter })
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    showFile (item) {
      this.appStore.showFileViewer({
        content: item.vehicle.files,
        isMultiDoc: true,
        displayFullScreen: true
      })
    },

    showVehicleDetail (user) {
      this.appStore.showSidePanel(AddVehicle, { user }, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true })
    },

    async onVehicleInfosUpdated () {
      await this.initData()
      this.appStore.closeSidePanel()
    }
  }
}
</script>
