<template>
  <div>
    <input v-if="!numberType"
           ref="input"
           v-model="input"
           :type="inputType"
           step="any"
           class="form-control form-control--sm center"
           @keyup.enter="onSubmit">
    <number-input v-else
                  ref="input"
                  v-model="input"
                  input-ref="input"
                  class="form-control form-control--sm center"
                  decimal
                  name="number-input"
                  :minimum-fraction-digits="2"
                  default-zero />
    <div class="popover-footer">
      <button type="button"
              class="btn btn--block btn--gray btn--sm"
              @click="$emit('close-popover')">
        <span>{{ $t('button.cancel') }}</span>
      </button>
      <component-button :label="$t('button.validate')"
                        :disabled="!input || disabled"
                        wrapper-class="btn btn--block btn--primary btn--sm"
                        @click="onSubmit" />
    </div>
  </div>
</template>

<script>
import ComponentButton from '@/components/Button.vue'
import NumberInput from '@/components/NumberInput.vue'

export default {
  name: 'ComponentPopoverEdit',

  components: {
    ComponentButton,
    NumberInput
  },

  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },

    type: {
      type: String,
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    popoverActive: {
      type: Boolean,
      required: true
    }
  },

  emits: ['close-popover', 'input'],

  data () {
    return {
      input: '',
      oldValue: ''
    }
  },

  computed: {
    inputType () {
      switch (this.type) {
        case 'integer':
        case 'currency':
          return 'number'
        default:
          return 'text'
      }
    },

    numberType () {
      return this.inputType === 'number'
    },

    emittedInput () {
      switch (this.type) {
        case 'integer':
          return parseInt(this.input)
        case 'currency':
          return parseFloat(this.input)
        default:
          return this.input
      }
    }
  },

  watch: {
    popoverActive (value) {
      if (value) {
        this.$nextTick(() => {
          this.input = this.modelValue
          this.$refs.input.focus?.()
        })
      }
    },

    value () {
      this.input = this.modelValue
      this.oldValue = this.modelValue
    }
  },

  mounted () {
    this.input = this.modelValue
    this.oldValue = this.modelValue
  },

  methods: {
    onSubmit () {
      if (this.input && !this.disabled) {
        this.$emit('input', this.emittedInput, this.oldValue)
        this.$emit('close-popover')
      }
    }
  }
}
</script>
