<template>
  <component-placeholder :label="$t('placeholder.sca_validation_success.title')"
                         :content="getPlaceholderContent"
                         :button="$t('button.finish')"
                         button-theme="default"
                         @btn-click="$router.push({ name: 'account-sca' })">
    <image-mobile-app v-if="type === 'sms'"
                      success />
    <image-computer v-else-if="type === 'email'"
                    success />
  </component-placeholder>
</template>

<script>
import { useScaStore } from '@/stores/sca'

import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageComputer from '@/components/svg/img/computer.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'

export default {
  components: {
    ComponentPlaceholder,
    ImageComputer,
    ImageMobileApp
  },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  setup () {
    const scaStore = useScaStore()

    return { scaStore }
  },

  computed: {
    getPlaceholderContent () {
      return this.$i18n.t(`placeholder.sca_validation_success.subtitle_${this.type}`)
    }
  },

  mounted () {
    this.scaStore.toggleScaConfigured(true)
  }
}
</script>
