<template>
  <div v-if="cards.length">
    <div class="header-table-section header-table-section__noborder">
      <div class="header-table-section__group-title">
        <span class="arrow">&rsaquo;</span>{{ label }}
      </div>
    </div>
    <card-row v-for="card in cards"
              :key="card.uuid"
              :card="card" />
  </div>
</template>

<script>
import CardRow from './CardRow.vue'

export default {
  components: {
    CardRow
  },

  props: {
    label: {
      type: String,
      required: true
    },

    cards: {
      type: Array,
      required: true
    }
  }
}
</script>
