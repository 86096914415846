<template>
  <tr :data-cy="`beneficiaries.list.beneficiary-${beneficiary.id}`">
    <td>{{ beneficiary.label }}</td>
    <td>{{ beneficiary.short_tag }}</td>
    <td v-if="ibanIsValid(beneficiary.iban)">
      {{ formatIban(beneficiary.iban) }}
    </td>
    <td v-else>
      {{ beneficiary.iban }}
    </td>
    <td>{{ beneficiary.bank_data.bank }}</td>
    <td v-if="isSearchResult">
      <span>
        {{ beneficiaryListLabel }}
      </span>
    </td>
    <td>
      <core-badge v-if="isValidationPending"
                  :theme="ECoreBadgeTheme.Info"
                  :value="$t('button.to_validate')" />
      <core-badge v-else-if="!beneficiary.is_sepa"
                  :theme="ECoreBadgeTheme.Currency"
                  :size="ECoreBadgeSize.Small"
                  :value="$t('general.no_sepa')" />
    </td>
    <td>
      <span v-if="beneficiary.rib">
        <button v-tooltip="{ content: $t('tooltip.show_rib'), theme: 'tooltip' }"
                class="btn-link"
                @click.prevent="showFile(beneficiary.rib)">
          <ic-document class="ic ic--gray" />
        </button>
      </span>
    </td>
    <td v-if="hasPermission($permissions.beneficiariesWrite)"
        class="col-action">
      <span class="hover-item">
        <button v-if="hasDifferentBeneficiaryList && currentBeneficiaryList"
                v-tooltip="{ content: `${$t('tooltip.link_to')} ${currentBeneficiaryList.label}`, theme: 'tooltip' }"
                class="btn-link"
                @click.prevent="changeBeneficiaryList">
          <ic-link class="ic ic--24 ic--gray" />
        </button>
        <button v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                class="btn-link"
                :data-cy="`beneficiaries.list.beneficiary-${beneficiary.id}.edit`"
                @click.prevent="editBeneficiary">
          <ic-pencil class="ic ic--24 ic--gray" />
        </button>
        <button v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                class="btn-link"
                :data-cy="`beneficiaries.list.beneficiary-${beneficiary.id}.delete`"
                @click.prevent="appStore.showDeleteModal({ label: $t('prompt.beneficiary.delete.title'), content: $t('prompt.beneficiary.delete.content'), url: `/beneficiaries/${beneficiary.id}` })">
          <ic-remove class="ic ic--gray" />
        </button>
      </span>
    </td>
  </tr>
</template>

<script>
import { CoreBadge, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import IBAN from 'iban'

import { formatIban } from '@/helpers/utils/iban'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcLink from '@/components/svg/icons/ic-link.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    CoreBadge,
    IcDocument,
    IcPencil,
    IcRemove,
    IcLink
  },

  props: {
    beneficiary: {
      type: Object,
      required: true
    },

    currentBeneficiaryList: {
      type: Object,
      default: null
    },

    isSearchResult: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    return { appStore, beneficiaryStore }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    hasDifferentBeneficiaryList () {
      return (this.beneficiary.beneficiary_list?.id || 0) !== (this.currentBeneficiaryList?.id || 0) && this.isSearchResult
    },

    isValidationPending () {
      return this.beneficiary.validation?.status === 'pending'
    },

    beneficiaryListLabel () {
      return this.beneficiary.beneficiary_list ? this.beneficiary.beneficiary_list.label : this.$t('general.not_attached')
    }
  },

  methods: {
    formatIban,

    showFile (file) {
      this.appStore.showFileViewer({
        content: [file],
        displayFullScreen: true
      })
    },

    ibanIsValid (iban) {
      return IBAN.isValid(iban)
    },

    editBeneficiary () {
      const listId = this.beneficiary.beneficiary_list_id || this.beneficiary.beneficiary_list?.id || '0'
      this.$router.push({ name: 'beneficiaries-edit', params: { listId, id: this.beneficiary.id } })
    },

    changeBeneficiaryList () {
      this.beneficiaryStore.changeListOfBeneficiary(this.beneficiary, this.currentBeneficiaryList)
    }
  }
}
</script>
