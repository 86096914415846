<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="sidepanel__content">
    <div class="sidepanel-header">
      <h2 class="panel-title">
        {{ $t('sidepanel.service_activity.title') }}
      </h2>
    </div>
    <div class="sidepanel-body">
      <section class="section-activite-utilisateur">
        <div v-if="serviceLogs.length"
             class="timeline">
          <div class="timeline__activity">
            <div v-for="(log, index) in serviceLogs"
                 :key="index"
                 class="activity-details">
              <h4 class="activity-details__title">
                {{ log.label }}
              </h4>
              <p class="activity-details__desc">
                {{ log.text }}
              </p>
              <span class="activity-details__time">{{ formatDateText(log.created_at, 'LLL') }}</span>
            </div>
          </div>
        </div>
        <component-placeholder v-else
                               :label="$t('placeholder.no_service_activity.title')"
                               class="c c--vh" />
      </section>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatDateText } from '@/helpers/utils/date'
import { useServiceStore } from '@/stores/service'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'

export default {
  components: {
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    serviceId: {
      type: String,
      required: true
    }
  },

  setup () {
    const serviceStore = useServiceStore()

    const { serviceLogs } = storeToRefs(serviceStore)

    return { serviceLogs, serviceStore }
  },

  data () {
    return {
      loading: true
    }
  },

  async created () {
    this.loading = true
    await this.serviceStore.getServiceLogs(this.serviceId)
    this.loading = false
  },

  methods: {
    formatDateText
  }
}
</script>
