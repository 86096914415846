<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="incomingTransfers.length">
      <component-table class="table--hover table-operations table--fixed"
                       data-cy="operations.incoming-transfers.table">
        <thead>
          <tr>
            <th class="col--sm-4">
              {{ $t("general.reason") }}
            </th>
            <th class="col--sm-4">
              {{ $t("table.debtor_name") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.amount") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in incomingTransfers"
              :key="index"
              class="is-link"
              :class="{ 'text-muted': item.status === 'pending' }"
              @click="showIncomingTransferDetail(item)">
            <td><span class="text-overflow col-label">{{ item.label }}</span></td>
            <td><span class="text-overflow col-label">{{ item.debtor_name }}</span></td>
            <td class="right">
              <span class="amount">{{ formatAmount(item.amount, item.currency) }}</span>
            </td>
          </tr>
        </tbody>
      </component-table>
      <component-pagination :pagination="incomingTransfersPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t('placeholder.no_operation.title')"
                           :content="''" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'

import IncomingTransferDetail from '@/pages/operations/sidepanel/IncomingTransferDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ComponentTable from '@/components/Table.vue'

export default {
  components: {
    ComponentPagination,
    ComponentTable,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const operationStore = useOperationStore()

    const { incomingTransfers, incomingTransfersPagination } = storeToRefs(operationStore)

    return { appStore, incomingTransfers, incomingTransfersPagination, operationStore }
  },

  data () {
    return {
      store,
      loading: true
    }
  },

  watch: {
    page () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.resetData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.resetData)
  },

  methods: {
    formatAmount,

    async initData () {
      this.loading = true
      await this.operationStore.getIncomingTransfers(this.page)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    showIncomingTransferDetail (incomingTransfer) {
      this.appStore.showSidePanel(IncomingTransferDetail, { model: incomingTransfer }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    }
  }
}
</script>
