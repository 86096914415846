<template>
  <div class="alert"
       :class="[`alert--${type}`, wrapperClass]">
    <template v-if="apiError && apiError.fields.length === 1 && showApiMessages">
      <p>{{ apiError.fields[0].message }}</p>
    </template>
    <template v-else>
      <div class="alert__content">
        <div class="notification">
          <ic-info v-if="type === 'info'"
                   class="ic ic--info" />
          <ic-check-circle v-else-if="type === 'success'"
                           class="ic ic--success" />
          <ic-warning v-else-if="type === 'warning'"
                      outline
                      class="ic ic--warning" />
          <ic-error v-else-if="type === 'error'"
                    class="ic ic--error" />
          <span class="strong pre-line">
            {{ fixedMessage }}
          </span>
        </div>
        <slot />
      </div>
      <ul v-if="apiError && Array.isArray(apiError.fields) && apiError.fields.length > 1 && showApiMessages">
        <li v-for="(item, index) in apiError.fields"
            :key="index">
          {{ item.message }}
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useApiStore } from '@/stores/api'

import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'

export default {
  name: 'ComponentAlert',

  components: {
    IcInfo,
    IcCheckCircle,
    IcWarning,
    IcError
  },

  props: {
    type: {
      type: String,
      required: false,
      default: 'info'
    },

    message: {
      type: String,
      required: true
    },

    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },

    showApiMessages: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  setup () {
    const apiStore = useApiStore()
    const { error: apiError } = storeToRefs(apiStore)
    return { apiError }
  },

  computed: {
    fixedMessage () {
      return this.message.replace(/<br\s*\/?>/g, '\n')
    }
  }
}
</script>
