<template>
  <div class="mobile-table"
       data-cy="operations.table">
    <template v-for="(items, date) in groupedItems"
              :key="date">
      <div class="mobile-table__header">
        {{ date }}
      </div>
      <div v-for="item in items"
           :key="item.uuid"
           class="mobile-table__row is-link"
           :class="{ 'text-muted': isOperationPending(item) || isOperationRejected(item) }"
           :data-cy="`operations.item-${item.uuid}`"
           @click="showOperationDetail(item)">
        <div class="text-overflow">
          <div class="d-flex align-items-center">
            <core-icon v-if="isOperationPending(item)"
                       :theme="ECoreIconTheme.GrayLow"
                       class="mr-1"
                       :name="ECoreIconList.Demandes" />
            <core-icon v-else-if="isOperationRejected(item)"
                       :theme="ECoreIconTheme.Danger"
                       class="mr-1"
                       :name="ECoreIconList.Delete" />
            <span class="text-overflow col-label">
              <span>{{ item.short_label ? item.short_label : item.label }}</span>
              <span v-if="isOperationRejected(item)"
                    class="text-overflow block text-error mobile-table__row__sublabel">{{ item.reject_reason }}</span>
              <span v-else
                    class="text-overflow block mobile-table__row__sublabel">{{ item.category_label }}</span>
            </span>
          </div>
        </div>
        <div class="right">
          <span class="amount"
                :class="{ 'text-green': item.type === 'credit', 'text-error': item.type === 'debit', 'text-through': isOperationRejected(item) }">
            {{ formatAmount(item.amount, item.currency, { isNegative: item.type === 'debit' }) }}
          </span>
        </div>
        <div v-if="hasPermission(permissions.operationsDocumentsRead)"
             class="center">
          <core-badge v-if="isOperationRejected(item)"
                      :theme="ECoreBadgeTheme.Danger"
                      :fill="ECoreBadgeFill.Shaded"
                      :value="$t('general.rejected')"
                      class="mr-1" />
          <template v-else-if="(hasPermission(permissions.operationsDocumentsWrite) || (hasPermission(permissions.operationsDocumentsRead) && hasDocument(item)))">
            <core-badge v-if="item.expense_control.status_label"
                        v-bind="getBadgeProps(item.expense_control.status)"
                        :value="item.expense_control.status_label" />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  CoreBadge,
  CoreIcon, ECoreBadgeFill, ECoreBadgeTheme,
  ECoreIconList, ECoreIconTheme
} from '@common/core-ui'

import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { getBadgeProps } from '@/helpers/utils/badge'
import { groupByDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { EStatus, IOperation } from '@/types/operation.d'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

const props = defineProps({
  operations: {
    type: Array<IOperation>,
    required: true
  },

  sortingDate: {
    type: String,
    default: 'accounting_date'
  }
})

const appStore = useAppStore()

const groupedItems = computed<[[IOperation]]>(() => {
  return groupByDate(props.operations, props.sortingDate, { dateFieldFormat: 'YYYY-MM-DD', groupByFormat: 'LL' })
})

function showOperationDetail (operation: object, showFileViewer = false) {
  appStore.showSidePanel(
    OperationDetail,
    { model: operation },
    {
      wrapperClass: 'sidepanel--md',
      backgroundOverlay: true,
      canDragAndDrop: false,
      initShowFile: showFileViewer
    }
  )
}

function isOperationPending (operation: IOperation) {
  return operation.status === EStatus.Pending
}

function isOperationRejected (operation: IOperation) {
  return operation.status === EStatus.Rejected
}
function isProofUploaded (operation: IOperation) {
  return operation.documents.length
}

function isProofBill (operation: IOperation) {
  return operation.bill
}

function hasDocument (operation: IOperation) {
  return isProofUploaded(operation) || isProofBill(operation)
}
</script>

<style scoped lang="stylus">
.mobile-table
  display grid
  grid-template-rows auto
  margin-bottom 1rem

  &__header
    background-color $btn-bg-lightgray
    padding 0 2.2rem
    height 34px
    display flex
    align-items center

  &__row
    display grid
    padding 1.6rem 2.2rem
    align-items center
    grid-template-columns 50% 1fr 20%
    border-bottom 1px solid $gray-100

    &__sublabel
      color $gray-500

  .right
    padding 0 .5rem

  .amount
    font-weight 500
    font-size 1.6rem
</style>
