import { ECoreBannerTheme } from '@common/core-ui'
import dayjs from 'dayjs'
import { uniqueId } from 'lodash/util'

import { Model } from '@/models/Model'
import { EBannerShow, IBanner } from '@/types/banner.d'
import { Nullable, TDatesRange } from '@/types/default.d'

export class ModelBanner extends Model {
  readonly id: string
  readonly show_web: EBannerShow
  readonly closable: boolean
  readonly link: string
  readonly type: ECoreBannerTheme
  public message: string
  readonly dates: TDatesRange<Nullable<string>>
  readonly auto_dismiss: boolean
  readonly auto_dismiss_timeout?: number

  public is_closed = false

  private constructor (properties: Partial<IBanner>) {
    super()

    this.show_web = properties?.show_web || EBannerShow.All
    this.id = properties?.id || uniqueId()

    this.closable = properties?.closable || false
    this.type = properties?.type || ECoreBannerTheme.Info
    this.link = properties?.link || ''
    this.message = properties?.message || ''
    this.dates = properties?.dates || {} as TDatesRange<Nullable<string>>
    this.auto_dismiss = properties?.auto_dismiss || false
    this.auto_dismiss_timeout = properties.auto_dismiss_timeout ?? undefined

    return this.onConstructed()
  }

  static create (properties?: Partial<IBanner>): ModelBanner {
    return new ModelBanner(properties || {})
  }

  closeBanner (): void {
    this.is_closed = true
  }

  isAvailable (authRequired = false, isLoginPage: boolean) {
    const { from, to } = this.dates || {}
    const now = dayjs()

    if (this.is_closed) return false

    if (authRequired && this.show_web === EBannerShow.LoginOnly) return false
    if (!authRequired && !isLoginPage) return false

    if (!from && !to) { return true }
    if (from && !to) { return now.isAfter(from) }
    if (!from && to) { return now.isBefore(to) }
    return now.isBetween(from, to)
  }
}
