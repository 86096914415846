<template>
  <svg viewBox="0 0 20 20">
    <path opacity=".4"
          d="M10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Z" />
    <path d="M10 6.005a.5.5 0 0 1 .5.5l.002 2.997 3.003-.002a.5.5 0 0 1 0 1l-3.002.002.002 3.003a.5.5 0 0 1-1 0l-.002-3.002-2.997.002a.5.5 0 0 1-.001-1l2.997-.002L9.5 6.506a.5.5 0 0 1 .5-.5Z" />
  </svg>
</template>

<script>
export default {
}
</script>
