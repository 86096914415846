<template>
  <component-logo-print />
  <component-mobile v-if="showAppRedirection" />
  <template v-else-if="isSupported && areCookiesEnabled">
    <cookie-consent />
    <component-banner />
    <router-view />
    <component-modal />
    <component-side-panel />
    <component-loading v-if="loadingAccount" />
    <transition name="fade">
      <component-file-viewer v-if="fileViewer.active && fileViewer.displayFullScreen" />
    </transition>
  </template>
  <component-no-cookies v-else-if="!areCookiesEnabled" />
  <component-not-supported v-else-if="!isSupported" />
</template>

<script>
import MobileDetect from 'mobile-detect'
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useAnalyticStore } from '@/stores/analytics'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import CookieConsent from '@/components/CookieConsent.vue'
import RequestSca from '@/components/modals/RequestSca.vue'

import ComponentLoading from './components/AccountLoading.vue'
import ComponentBanner from './components/BannerComponent.vue'
import ComponentFileViewer from './components/FileViewer.vue'
import ComponentLogoPrint from './components/LogoPrint.vue'
import ComponentMobile from './components/Mobile.vue'
import ComponentModal from './components/Modal.vue'
import ComponentNoCookies from './components/NoCookies.vue'
import ComponentNotSupported from './components/NotSupported.vue'
import ComponentSidePanel from './components/SidePanel.vue'
import { EFeature, hasFeature } from './config/features'
import store from './config/store'
import { showToastSuccess } from './helpers/utils/notification'
import { switchLocale } from './locales'
import { isResponsive } from './router'

import '@modules/@common/core-ui/dist/assets/theme-qnb.css'

export default {
  components: {
    CookieConsent,
    ComponentModal,
    ComponentFileViewer,
    ComponentBanner,
    ComponentLoading,
    ComponentMobile,
    ComponentNotSupported,
    ComponentLogoPrint,
    ComponentSidePanel,
    ComponentNoCookies
  },

  setup () {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const analyticStore = useAnalyticStore()

    const { account, loadingAccount } = storeToRefs(accountStore)
    const { fileViewer, isDeviceMobile } = storeToRefs(appStore)
    const { user } = storeToRefs(authStore)
    const { gtag, cookies } = storeToRefs(analyticStore)

    return {
      account,
      analyticStore,
      appStore,
      authStore,
      cookies,
      fileViewer,
      gtag,
      isDeviceMobile,
      loadingAccount,
      user
    }
  },

  data () {
    return {
      store,
      md: new MobileDetect(navigator.userAgent),
      firebaseMonitoringIsSet: false,
      firebaseAnalytics: null
    }
  },

  computed: {
    isSupported () {
      return !(window.navigator.userAgent.indexOf('MSIE') && window.navigator.userAgent.indexOf('MSIE') !== -1)
    },

    isAccountValid () {
      return this.account?.status_group === 'valid'
    },

    showAppRedirection () {
      return this.isDeviceMobile && !isResponsive(this.$route) && !this.authStore.loading
    },

    isUserPending () {
      return ['pending', 'refused'].includes(this.account?.invitation_status) ||
          !this.account?.permissions.length
    },

    areCookiesEnabled () {
      return navigator.cookieEnabled
    }
  },

  watch: {
    '$i18n.locale': {
      handler: function () {
        switchLocale(this.$i18n.locale)
        this.analyticStore.setUserDimensions()
        showToastSuccess(this.$i18n.t('message.success.lang'))
      },

      immediate: true
    }
  },

  created () {
    this.analyticStore.restorePreferences()
    this.appStore.setDevice(!!this.md.mobile() || !!this.md.phone(), ['iOS', 'iPadOS', 'watchOS'].includes(this.md.os()))
  },

  async mounted () {
    this.analyticStore.initAnalytics()
    this.$bus.on('analytics-cookie-consent', this.initAnalytics)
    this.$bus.on('auth-success', this.onAuthSuccess)
    this.$bus.on('account-switched', this.initAnalytics)
    this.$bus.on('language-changed', this.appStore.getInit)
    window.addEventListener('resize', this.onResize)
    await this.appStore.getInit()
  },

  beforeUnmount () {
    this.$bus.off('analytics-cookie-consent', this.initAnalytics)
    this.$bus.off('auth-success', this.onAuthSuccess)
    this.$bus.off('account-switched', this.initAnalytics)
    this.$bus.off('language-changed', this.appStore.getInit)
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    async initAnalytics () {
      this.analyticStore.initAnalytics()
    },

    onAuthSuccess () {
      const sca = this.user.sca
      if (!sca.enforced && !sca.configured && this.isAccountValid && !this.isUserPending && hasFeature(EFeature.LoginConfigureScaModal)) {
        setTimeout(
          () => this.appStore.showModal(RequestSca),
          1000
        )
      }
      this.analyticStore.setUserDimensions()
    },

    onResize () {
      this.md = new MobileDetect(navigator.userAgent)
      this.appStore.setDevice(!!this.md.mobile() || !!this.md.phone(), ['iOS', 'iPadOS', 'watchOS'].includes(this.md.os()))
    }
  }
}
</script>

<style lang="stylus">
@import './assets/stylesheets/style.styl'
</style>
