<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="refunds.length">
      <component-table class="table--hover table--fixed">
        <thead>
          <tr>
            <th v-if="isDone"
                class="col--sm-1" />
            <th class="col--sm-1 center">
              {{ $t("general.created_at") }}
            </th>
            <th class="col--sm-2">
              {{ $t("general.reason") }}
            </th>
            <th class="col--sm-2">
              {{ $t("table.category") }}
            </th>
            <th class="col--sm-2">
              {{ $t("table.affectation") }}
            </th>
            <th class="col--sm-1 right">
              {{ $t("table.amount") }}
            </th>
            <th class="col--sm-1 center">
              {{ $t("table.documents") }}
            </th>
            <th v-if="isPending"
                class="col--sm-1" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in refunds"
              :key="item.id"
              class="is-link"
              @click="onClickRow(item)">
            <td v-if="isDone">
              <core-badge v-if="item.status_label"
                          v-bind="getBadgeProps(item.status)"
                          :value="item.status_label" />
            </td>
            <td class="text-muted nowrap">
              {{ formatDate(item.created_at) }}
            </td>
            <td class="text-overflow">
              <span>{{ item.label }}</span>
            </td>
            <td class="text-overflow">
              {{ categoriesLabel(item.categories) }}
            </td>
            <td class="text-overflow">
              {{ affectationsLabel(item.affectations) }}
            </td>
            <td class="right">
              <span class="amount text-green">{{ formatAmount(item.amount, item.currency) }}</span>
            </td>
            <td class="center">
              <button v-if="item.operation.documents.length"
                      v-tooltip="{ content: fileViewerTooltip(item.operation.documents), theme: 'tooltip' }"
                      class="btn-link btn-attachment"
                      @click.prevent.stop="onClickRow(item, true)">
                <ic-document class="ic ic--24 ic--gray" />
              </button>
            </td>
            <td v-if="isPending"
                class="col-action"
                @click.stop="() => {}">
              <span v-if="item.updatable"
                    class="hover-item">
                <router-link v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                             class="btn-link"
                             :to="{ name: 'refund-edit', params: { id: item.id } }">
                  <ic-pencil class="ic ic--24 ic--gray" />
                </router-link>
                <button v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                        class="btn-link"
                        @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.refund.delete.title'), content: $t('prompt.refund.delete.content'), url: `/refunds/${item.id}` })">
                  <ic-remove class="ic ic--24 ic--gray" />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </component-table>
      <component-pagination :pagination="refundsPagination" />
    </template>
    <component-placeholder v-else
                           :label="placeholderLabel"
                           :content="isUserEmployee ? $t('placeholder.no_refund.subtitle') : $t('placeholder.no_payment.subtitle')" />
  </div>
</template>

<script>
import { CoreBadge } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useRefundStore } from '@/stores/refund'

import RefundDetail from '@/pages/refund/sidepanel/RefundDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import ComponentTable from '@/components/Table.vue'

export default {
  components: {
    CoreBadge,
    IcDocument,
    IcPencil,
    IcRemove,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const refundStore = useRefundStore()

    const { fileViewer } = storeToRefs(appStore)
    const { isUserEmployee } = storeToRefs(accountStore)
    const { refunds, refundsPagination } = storeToRefs(refundStore)

    return { appStore, fileViewer, isUserEmployee, refunds, refundsPagination, refundStore }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    isPending () {
      return this.state === 'pending'
    },

    isDone () {
      return this.state === 'done'
    },

    placeholderLabel () {
      return this.isUserEmployee
        ? this.$i18n.t(`placeholder.no_refund.title${this.isDone ? '_history' : ''}`)
        : this.$i18n.t(`placeholder.no_payment.title${this.isDone ? '_history' : ''}`)
    }
  },

  watch: {
    state () {
      this.resetData()
    },

    page () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('language-changed', this.resetData)
    this.$bus.on('account-switched', this.resetData)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('language-changed', this.resetData)
    this.$bus.off('account-switched', this.resetData)
  },

  methods: {
    getBadgeProps,
    formatAmount,

    formatDate,

    async initData () {
      this.loading = true
      await this.refundStore.getRefunds(this.state, this.page)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    fileViewerTooltip (documents) {
      return this.$i18n.tc('tooltip.show_file', documents.length)
    },

    onDeleteSuccess (message) {
      this.resetData()
      if (!this.fileViewer.active) {
        showToastSuccess(message || this.$i18n.t('message.success.refund.delete'))
      }
    },

    categoriesLabel (categories) {
      if (!categories.length) { return ' - ' }

      return categories.map(category => category.label).join(', ')
    },

    affectationsLabel (affectations) {
      if (!affectations.length) { return ' - ' }

      return affectations.map(affectation => affectation.label).join(', ')
    },

    onClickRow (item, showFile = false) {
      this.appStore.showSidePanel(RefundDetail, { model: item }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, initShowFile: !!showFile })
    }
  }
}
</script>
