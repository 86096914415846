<template>
  <svg viewBox="0 0 200 160">
    <path d="m64.5 47.25h29c1.7949 0 3.25 1.4551 3.25 3.25v29c0 1.7949-1.4551 3.25-3.25 3.25h-29c-1.7949 0-3.25-1.4551-3.25-3.25v-29c0-1.7949 1.4551-3.25 3.25-3.25zm29 1.5h-29c-.9665 0-1.75.7835-1.75 1.75v29c0 .9665.7835 1.75 1.75 1.75h29c.9665 0 1.75-.7835 1.75-1.75v-29c0-.9665-.7835-1.75-1.75-1.75zm12-1.5h29c1.795 0 3.25 1.4551 3.25 3.25v29c0 1.7949-1.455 3.25-3.25 3.25h-29c-1.795 0-3.25-1.4551-3.25-3.25v-29c0-1.7949 1.455-3.25 3.25-3.25zm29 1.5h-29c-.966 0-1.75.7835-1.75 1.75v29c0 .9665.784 1.75 1.75 1.75h29c.966 0 1.75-.7835 1.75-1.75v-29c0-.9665-.784-1.75-1.75-1.75zm-41 39.5h-29c-1.7949 0-3.25 1.4551-3.25 3.25v29c0 1.795 1.4551 3.25 3.25 3.25h29c1.7949 0 3.25-1.455 3.25-3.25v-29c0-1.7949-1.4551-3.25-3.25-3.25zm-29 1.5h29c.9665 0 1.75.7835 1.75 1.75v29c0 .966-.7835 1.75-1.75 1.75h-29c-.9665 0-1.75-.784-1.75-1.75v-29c0-.9665.7835-1.75 1.75-1.75z"
          :fill="colorDark" />
    <path d="m137.745 91.3156c-.096-1.7091-1.512-3.0656-3.245-3.0656h-29l-.184.0051c-1.71.0957-3.066 1.5119-3.066 3.2449v29l.005.184c.096 1.71 1.512 3.066 3.245 3.066h23.5l.102-.007c.366-.05.648-.363.648-.743 0-.414-.336-.75-.75-.75h-23.5l-.144-.006c-.899-.073-1.606-.826-1.606-1.744v-29l.006-.1435c.073-.8994.826-1.6065 1.744-1.6065h29l.144.0058c.899.073 1.606.826 1.606 1.7442v23.5l.007.102c.05.366.363.648.743.648.414 0 .75-.336.75-.75v-23.5l-.005-.1844zm-18.709 12.9354 10.5.5c.413.02.733.371.713.785-.018.379-.315.679-.683.711l-.102.002-8.584-.409 19.657 20.136c.289.296.283.771-.013 1.061-.27.263-.686.282-.977.061l-.084-.074-19.616-20.095.402 8.034c.019.379-.247.706-.61.774l-.102.012c-.379.019-.706-.247-.774-.61l-.012-.102-.5-10c-.02-.408.288-.751.684-.784l.101-.002z"
          :fill="colorPrimary" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
