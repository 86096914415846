<template>
  <multiple-creation v-bind="groupedProps" />
</template>

<script>
import links from '@/config/links'

import MultipleCreation from '@/components/multiple-creation/MultipleCreation.vue'

export default {
  components: {
    MultipleCreation
  },

  props: {
    listId: {
      type: [Number, String],
      default: null
    }
  },

  computed: {
    groupedProps () {
      return {
        title: this.$i18n.t('beneficiaries_import.title'),
        helperContent: {
          title: this.$i18n.t('beneficiaries_import.helper.title'),
          steps: [
            'beneficiaries_import.helper.first_step',
            'beneficiaries_import.helper.second_step',
            'beneficiaries_import.helper.third_step',
            'beneficiaries_import.helper.fourth_step'
          ],

          downloadLink: links.STATIC.FILES.TEMPLATE_CREA_MULTI_BENEF
        },

        placeholderContent: {
          title: this.$i18n.t('placeholder.beneficiaries_import.title'),
          subtitle: this.$i18n.t('placeholder.beneficiaries_import.subtitle')
        },

        detailsComponent: 'beneficiaries-import-details',
        type: 'beneficiaries',
        extraData: {
          listId: parseInt(this.listId)
        }
      }
    }
  }
}
</script>
