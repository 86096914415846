<template>
  <div v-if="hasCategories">
    <template v-if="hasRestaurantCategory">
      <div class="sidepanel-details__entry">
        <div class="entry-label text-muted"
             :class="{ 'missing-field': isMissingField('guest') }">
          {{ $t('form.guests.label') }}
        </div>
        <div class="entry-value right">
          <number-input v-model="details.guest"
                        data-cy="guest"
                        name="guests"
                        :decimal="false"
                        :locale-string="false"
                        class="form-control form-control--editable right"
                        :placeholder="$t('form.guests.placeholder')"
                        :disabled="disabled"
                        @update:model-value="onDetailChanged('guest', details.guest)" />
        </div>
      </div>
      <div class="sidepanel-details__entry">
        <div class="entry-label text-muted"
             :class="{ 'missing-field': isMissingField('service') }">
          {{ $t('form.refunds.service.label') }}
        </div>
        <div class="entry-value right">
          <div class="select select--inline select--rtl"
               :class="{ 'select--disabled': disabled }">
            <component-dropdown data-cy="service"
                                label="service"
                                :dropdown-width="15"
                                :dropdown-height="21"
                                :values="servicesList"
                                :model="details.service"
                                :disabled="disabled"
                                @select="service => onDetailChanged('service', service)">
              <template #trigger>
                <div class="form-control"
                     :class="{ 'text-muted': !details.service }"
                     data-pos="top-right">
                  {{ serviceLabel }}
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
      </div>
    </template>
    <template v-if="hasHotelCategory">
      <div class="sidepanel-details__entry"
           :class="{ 'missing-field': isMissingField('nights') }">
        <div class="entry-label text-muted">
          {{ $t('form.hotels.label') }}
        </div>
        <div class="entry-value right">
          <number-input v-model="details.nights"
                        data-cy="nights"
                        name="nights"
                        :decimal="false"
                        :locale-string="false"
                        class="form-control form-control--editable right"
                        :placeholder="$t('form.hotels.placeholder')"
                        :disabled="disabled"
                        @update:model-value="onDetailChanged('nights', details.nights)" />
        </div>
      </div>
      <div class="sidepanel-details__entry">
        <div class="entry-label text-muted"
             :class="{ 'missing-field': isMissingField('half_board') }">
          {{ $t('form.dinner_included.label') }}
        </div>
        <div class="entry-value right">
          <div class="select select--inline select--rtl"
               :class="{ 'select--disabled': disabled }">
            <component-dropdown data-cy="half-board"
                                label="half-board"
                                :search="false"
                                :can-add-item="false"
                                :values="halfBoardValues"
                                :model="details.half_board_included"
                                :disabled="disabled"
                                @select="item => onDetailChanged('half_board_included', item.value)">
              <template #trigger>
                <div class="form-control">
                  {{ halfBoardLabel }}
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
      </div>
    </template>
    <template v-if="hasFuelStationCategory">
      <div class="sidepanel-details__entry"
           :class="{ 'missing-field': isMissingField('fuel') }">
        <div class="entry-label text-muted">
          {{ $t('form.fuel.label') }}
        </div>
        <div class="entry-value right">
          <div class="select select--inline select--rtl"
               :class="{ 'select--disabled': disabled }">
            <component-dropdown data-cy="fuel"
                                label="fuel"
                                async-url="/list/fuel"
                                :model="details.fuel?.value"
                                :disabled="disabled"
                                @select="fuel => onDetailChanged('fuel', fuel)"
                                @reset-value="onDetailChanged('fuel', null)">
              <template #trigger>
                <div class="form-control"
                     :class="{ 'text-muted': !details.fuel }">
                  {{ fuelLabel }}
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
      </div>
      <div class="sidepanel-details__entry">
        <div class="entry-label text-muted"
             :class="{ 'missing-field': isMissingField('liters') }">
          {{ $t('form.liters.label') }}
        </div>
        <div class="entry-value right">
          <input v-model="details.liters"
                 data-cy="liters"
                 type="number"
                 min="0"
                 step="1"
                 class="form-control form-control--editable right"
                 :placeholder="$t('form.liters.placeholder')"
                 :disabled="disabled"
                 @input="onDetailChanged('liters', details.liters)">
        </div>
      </div>
      <div class="sidepanel-details__entry">
        <div class="entry-label text-muted"
             :class="{ 'missing-field': isMissingField('mileage') }">
          {{ $t('form.mileage.label') }}
        </div>
        <div class="entry-value right">
          <input v-model="details.mileage"
                 data-cy="mileage"
                 type="number"
                 min="0"
                 step="1"
                 class="form-control form-control--editable right"
                 :placeholder="$t('form.mileage.placeholder')"
                 :disabled="disabled"
                 @input="onDetailChanged('mileage', details.mileage)">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import ComponentDropdown from '@/components/Dropdown.vue'
import NumberInput from '@/components/NumberInput.vue'

export default {
  components: {
    ComponentDropdown,
    NumberInput
  },

  props: {
    operation: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['change'],

  data () {
    return {
      details: {}
    }
  },

  computed: {
    servicesList () {
      return [
        {
          label: this.$i18n.t('form.refunds.service.noon'),
          name: 'noon'
        },
        {
          label: this.$i18n.t('form.refunds.service.evening'),
          name: 'evening'
        }
      ]
    },

    hasCategories () {
      return this.details.categories && this.details.categories.length &&
          (this.hasRestaurantCategory || this.hasHotelCategory || this.hasFuelStationCategory)
    },

    hasRestaurantCategory () {
      return this.details.categories.some(category => category.name === 'restaurant')
    },

    hasHotelCategory () {
      return this.details.categories.some(category => category.name === 'hotel-logement')
    },

    hasFuelStationCategory () {
      return this.details.categories.some(category => category.name === 'station-essence')
    },

    serviceLabel () {
      return this.details.service
        ? this.$i18n.t(`form.refunds.service.${this.details.service}`)
        : this.$i18n.t('form.common.select')
    },

    fuelLabel () {
      return this.details.fuel ? this.details.fuel.label : this.$i18n.t('form.common.select')
    },

    halfBoardLabel () {
      const value = this.halfBoardValues.find(i => i.value === this.details.half_board_included)

      return value.label || this.$i18n.t('general.fail')
    },

    halfBoardValues () {
      return [
        {
          label: this.$i18n.t('general.yes'),
          value: true
        },
        {
          label: this.$i18n.t('general.no'),
          value: false
        },
        {
          label: this.$i18n.t('general.undefined'),
          value: null
        }
      ]
    }
  },

  watch: {
    'operation.detail': {
      handler (detail) {
        this.details = cloneDeep(detail)
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    onDetailChanged (field, value) {
      this.$emit('change', field, value)
    },

    isMissingField (field) {
      return this.operation.required_fields?.some(i => i.includes(field)) ?? false
    }
  }
}
</script>
