<template>
  <section class="section section--centered">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'order-cards-choice' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ titleLabel }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <h4 class="body--extra-large center mb-4">
        {{ $t('order_card.choice.type.title') }}
      </h4>
      <div class="block-options">
        <block-options-item v-for="cardOrderType in cardOrderTypes"
                            :key="cardOrderType"
                            :title="getCardTypeLabel({ type: cardOrderType.type })"
                            :content="$t(`order_card.choice.type.${cardOrderType.type}.content`)"
                            :to="{ name: 'order-card-form', params: { role }, query: { type: cardOrderType.type } }"
                            :hide-chevron="true">
          <card-item :card="cardOrderType"
                     small-card
                     show-sim
                     :native-click="false"
                     :show-digits="false"
                     :show-cvv="false" />
        </block-options-item>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { getCardTypeLabel } from '@/helpers/utils/card'
import { useAccountStore } from '@/stores/account'

import CardItem from '@/pages/cards/components/CardItem.vue'

import BlockOptionsItem from '@/components/BlockOptionsItem.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

const accountStore = useAccountStore()
const { physicalCardTypes } = storeToRefs(accountStore)

const props = defineProps<{
  role: string;
}>()

const { t } = useI18n()

const isOwner = computed(() => props.role === 'owner')

const cardOrderTypes = computed(() => {
  return physicalCardTypes.value?.map(cardType => ({ type: cardType, subtype: 'marqeta', status: 'active' }))
})

const titleLabel = computed(() => {
  return isOwner.value
    ? t('order_card.me.title')
    : t('order_card.employee.title')
})
</script>

<style lang="stylus" scoped>
.block-options__item
  width 36rem
  padding 1.6rem
  &:deep(.option-text)
    max-width 30rem
</style>
