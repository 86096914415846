<template>
  <svg width="14"
       height="14"
       viewBox="0 0 14 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.83527 8.27658V12.9167L10.3171 6.06266L7.16473 6.06255V1.42245L3.68288 8.27647L6.83527 8.27658Z"
          fill="#979EAC" />
  </svg>
</template>

<script>
export default {}
</script>
