<template>
  <svg viewBox="0 0 24 24">
    <path d="M17.455 4 22 8.412v7.943C22 17.816 20.779 19 19.271 19H4.73C3.222 19 2 17.823 2 16.353V6.647C2 5.185 3.213 4 4.727 4zm-.91 1.764H4.727a.89.89 0 0 0-.909.883v9.706c0 .492.404.882.91.882h14.543c.504 0 .91-.394.91-.88V9.294h-3.636zm-2.727 7.942v1.765H5.636v-1.765z" />
  </svg>
</template>

<script>
export default {
}
</script>
