import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { useAuthStore } from '@/stores/auth'

export const useScaStore = defineStore('sca', {
  state: () => {
    return {
      scaAvailable: [],
      scaList: [],
      scaInitialized: {},
      scaValidationLoading: false
    }
  },

  actions: {
    async getScaAvailable () {
      try {
        const { data } = await axiosClient.get('/sca/available')
        this.scaAvailable = data
      } catch (e) {
        console.error(e)
      }
    },

    async getScaList () {
      try {
        const { data } = await axiosClient.get('/sca/tokens')
        this.scaList = data
      } catch (e) {
        console.error(e)
      }
    },

    async getScaChallenges () {
      try {
        const { data } = await axiosClient.post('/sca/challenges')
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async initScaConfiguration (type) {
      try {
        const { data } = await axiosClient.get(`/sca/tokens/${type}/enroll/init`)
        this.scaInitialized = data
      } catch (e) {
        console.error(e)
      }
    },

    async getScaStatus (tokenId) {
      try {
        const { data } = await axiosClient.get(`/sca/tokens/${tokenId}/enroll/status`)
        return data.status
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async deleteScaToken (tokenId) {
      try {
        await axiosClient.delete(`/sca/tokens/${tokenId}`)
      } catch (e) {
        console.error(e)
      }
    },

    async rejectChallenge (challengeUuid) {
      try {
        if (!this.scaValidationLoading) {
          this.scaValidationLoading = true
        }
        await axiosClient.post(`/sca/challenges/${challengeUuid}/reject`)
      } catch (e) {
        console.error(e)
      } finally {
        this.scaValidationLoading = false
      }
    },

    async triggerScaChallenge (challengeUuid, tokenId = null) {
      try {
        const { data } = await axiosClient.post(
        `/sca/challenges/${challengeUuid}/trigger`,
        tokenId ? { token_id: tokenId } : {}
        )
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getChallengeStatus (challengeUuid) {
      try {
        const { data } = await axiosClient.get(`/sca/challenges/${challengeUuid}/status`)
        return data.status
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async validateScaRequest (challengeUuid, payload = {}) {
      if (this.scaValidationLoading) return

      this.scaValidationLoading = true
      try {
        return await axiosClient.post(`/sca/challenges/${challengeUuid}/validate`, payload)
      } catch (e) {
        console.error(e)
        return null
      } finally {
        this.scaValidationLoading = false
      }
    },

    async validateScaConfiguration (tokenId, payload) {
      try {
        await axiosClient.post(`/sca/tokens/${tokenId}/enroll/activate`, payload)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    toggleScaConfigured (value) {
      useAuthStore().$patch(state => { state.user.sca.configured = value })
    }
  }
})
