import { Component, shallowRef } from 'vue'
import { ECoreBannerTheme } from '@common/core-ui'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { ModelBanner } from '@/models/Banner/ModelBanner'
import {
  AppState,
  DeleteModalOptions,
  DropzoneOptions,
  FileViewerOptions,
  IInit,
  JoinDocumentOptions,
  ModalOptions,
  RetryRequestFunction,
  SidePanelOptions,
  TAppDropzoneModalCallback
} from '@/types/app.d'
import { EDeviceFormat, EDeviceOs } from '@/types/device.d'

import Delete from '@/components/modals/Delete.vue'
import JoinDocument from '@/components/modals/JoinDocument.vue'
import RequestScaValidation from '@/components/modals/RequestScaValidation.vue'

export const useAppStore = defineStore('app', {
  state: (): AppState => {
    return {
      banners: [],
      device: {
        format: EDeviceFormat.Desktop,
        os: EDeviceOs.Android
      },
      modal: {
        active: false,
        component: {},
        props: {}
      },
      sidePanel: {
        active: false,
        component: shallowRef([]),
        props: {}
      },
      fileViewer: {
        active: false,
        allowedFilesType: [
          'application/pdf',
          'image/jpeg',
          'image/png'
        ],
        content: []
      },
      requestsToRetry: []
    }
  },

  persist: {
    paths: ['device']
  },

  getters: {
    isDeviceMobile: state => state.device.format === EDeviceFormat.Mobile
  },

  actions: {
    setDevice (isMobile: boolean, isIos: boolean) {
      this.device = {
        format: isMobile ? EDeviceFormat.Mobile : EDeviceFormat.Desktop,
        os: isIos ? EDeviceOs.Ios : EDeviceOs.Android
      }
    },

    showBanner (type: ECoreBannerTheme, message: string, closable = true, auto_dismiss = true, auto_dismiss_timeout = 3000) {
      this.banners.push(ModelBanner.create({ type, message, closable, auto_dismiss, auto_dismiss_timeout }))
    },

    closeModal (): void {
      this.modal.active = false
    },

    showModal (component: Component, props: { [propName: string]: unknown } = {}, options: ModalOptions = {}) {
      if (!component) {
        console.error('Modal missing params', 'component', component)

        return false
      }

      this.modal = {
        active: true,
        component: shallowRef(component),
        props,
        wrapperClass: options.wrapperClass || '',
        blockLeaveBackground: options.blockLeaveBackground === true,
        onBackgroundLeaveAction: options.onBackgroundLeaveAction === true
      }
    },

    showDropzoneModal (label: string, model: TAppDropzoneModalCallback = {}, options: JoinDocumentOptions & DropzoneOptions = {}) {
      if (this.isDeviceMobile) {
        options.canAnalyzeFile = false
      }

      this.showModal(
        JoinDocument,
        {
          label,
          model,
          options: {
            type: options.type || null,
            editingFileId: options.editingFileId || null,
            canAnalyzeFile: options.canAnalyzeFile === true
          },
          dropzoneOptions: {
            url: options.url || '',
            maxFilesize: options.maxFilesize || 25,
            maxFiles: options.maxFiles || null,
            acceptedFiles: options.acceptedFiles || ''
          },
          callback: (data: object = {}, index: string | number) => {
            if (index === 'simple') model.document = data
            else if (index === 'documents') model.documents = data as object[]
            else if (index !== null && model.documents && typeof index === 'number') model.documents[index] = data
          }
        },
        { wrapperClass: 'modal--md' }
      )
    },

    showDeleteModal ({
      label = 'prompt.common.delete.title',
      content = 'prompt.common.delete.content',
      cancel = 'button.no',
      submit = 'button.yes',
      url,
      callbackDelete,
      event = null,
      id = null
    }: DeleteModalOptions) {
      this.showModal(
        Delete,
        {
          label,
          content,
          cancel,
          submit,
          url,
          callbackDelete,
          event,
          id
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    showScaValidationModal (challenge: object, payload: object) {
      return new Promise((resolve, reject) => {
        this.showModal(
          RequestScaValidation,
          {
            challenge,
            payload,
            resolve,
            reject
          },
          {
            blockLeaveBackground: true,
            wrapperClass: 'modal--xs modal-explanation'
          }
        )
      })
    },

    closeSidePanel (): void {
      if (!this.sidePanel.active) return

      bus.emit('hide-sidepanel')

      setTimeout(() => {
        this.sidePanel.active = false
      }, 10)
    },

    showSidePanel (component: Component, props: { [propName: string]: unknown } = {}, options: SidePanelOptions = {}) {
      if (!component) {
        console.error('SidePanel missing params', 'component', component)

        return false
      }

      this.sidePanel = {
        active: true,
        component: shallowRef(component),
        props,
        wrapperClass: options.wrapperClass,
        wrapperStyle: options.wrapperStyle,
        backgroundOverlay: options.backgroundOverlay,
        showLoader: options.showLoader,
        canDragAndDrop: options.canDragAndDrop,
        initShowFile: options.initShowFile,
        closeButton: options.closeButton !== false
      }
    },

    closeFileViewer (): void {
      this.fileViewer.active = false
    },

    showFileViewer (options: FileViewerOptions): void {
      if (this.isDeviceMobile) {
        options.displayFullScreen = true
      }

      this.fileViewer = {
        isMultiDoc: false,
        defaultIndex: 0,
        displayFullScreen: false,
        canEdit: false,
        canAnalyze: false,
        operationId: null,
        dataModel: {},
        isLocal: false,
        documentsUrl: null,
        documentsAllUrl: null,
        ...options,
        active: true,
        allowedFilesType: [
          'application/pdf',
          'image/jpeg',
          'image/png'
        ]
      }
    },

    addRetryRequest (request: RetryRequestFunction) {
      this.requestsToRetry.push(request)
    },

    executeAllRetryRequests () {
      while (this.requestsToRetry.length > 0) {
        this.requestsToRetry.shift()?.()
      }
    },

    removeRetryRequests () {
      this.requestsToRetry = []
    },

    async getInit (): Promise<void> {
      try {
        const { data } = await axiosClient.get<IInit>('/init')

        data?.notifications.filter(banner => banner.show_web).forEach(banner => {
          const search = this.banners.find(search => search.id === banner.id)

          if (search) {
            search.message = banner.message
          } else {
            this.banners.push(ModelBanner.create(banner))
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
})
