<template>
  <div class="sidepanel__content">
    <div id="sidepanel-wrapper"
         ref="sidepanelContent"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('sidepanel.beneficiary.title') }} {{ id + 1 }}/{{ items.length }}
        </h2>
      </div>
      <div class="sidepanel-body section-card-order">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('message.warning.form_not_valid')" />
        <new-beneficiary-form ref="beneficiaryForm"
                              is-side-panel
                              is-editing-imported-beneficiary
                              :existing-beneficiary="currentItem"
                              :existing-address="currentItem.address"
                              :enable-no-sepa="hasFeature(EFeature.BeneficiaryInternational)" />
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="row row--sm-bottom">
        <div class="col col--sm left">
          <div class="btn-group">
            <button class="btn btn--outline"
                    :disabled="loading"
                    @click="validateComponent('get-previous-item')">
              <ic-arrow class="ic-arrow-prev ic--20 ic--gray" />
            </button>
            <button class="btn btn--outline"
                    :disabled="loading"
                    @click="validateComponent('get-next-item')">
              <ic-arrow next
                        class="ic-arrow-next ic--20 ic--gray" />
            </button>
          </div>
        </div>
        <div class="col col--sm right">
          <div class="form-buttons">
            <button class="btn btn--gray"
                    @click.prevent.stop="appStore.closeSidePanel">
              {{ $t('button.cancel') }}
            </button>
            <button class="btn btn--primary"
                    :disabled="loading"
                    @click="validateComponent('submit')">
              {{ $t('button.validate') }}
            </button>
          </div>
        </div>
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import modulo from 'just-modulo'
import { cloneDeep } from 'lodash'

import { EFeature, hasFeature } from '@/config/features'
import { scrollToTop } from '@/helpers/utils/dom'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import ComponentAlert from '@/components/Alert.vue'
import NewBeneficiaryForm from '@/components/forms/NewBeneficiaryForm.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow,
    ComponentAlert,
    ComponentSidepanelFooter,
    NewBeneficiaryForm
  },

  props: {
    idInitial: {
      type: Number,
      required: true
    },

    items: {
      type: Array,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    return { appStore, beneficiaryStore }
  },

  data () {
    return {
      loading: false,
      errors: {},
      id: this.idInitial,
      formItems: cloneDeep(this.items)
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    currentItem: {
      get () {
        return this.formItems[this.id]
      },

      set (value) {
        this.formItems[this.id] = value
      }
    }
  },

  methods: {
    hasFeature,

    async validateComponent (type) {
      this.loading = true

      const { result, payload } = await this.$refs.beneficiaryForm.validateForm()

      if (result.valid) {
        this.errors = {}
        this.currentItem = payload

        if (type === 'get-previous-item') {
          this.getPreviousItem()
        } else if (type === 'get-next-item') {
          this.getNextItem()
        } else if (type === 'submit') {
          await this.onSubmit()
        }
      } else {
        this.errors = result.errors
        scrollToTop(this.$refs.sidepanelContent)
      }
      this.loading = false

      if (type !== 'submit') {
        scrollToTop(this.$refs.sidepanelContent)
      }
    },

    getPreviousItem () {
      this.id = modulo(this.id - 1, this.formItems.length)
      this.$refs.beneficiaryForm.populateBeneficiary(this.currentItem)
    },

    getNextItem () {
      this.id = modulo(this.id + 1, this.formItems.length)
      this.$refs.beneficiaryForm.populateBeneficiary(this.currentItem)
    },

    async onSubmit () {
      await this.beneficiaryStore.editBeneficiariesImport(this.formItems)
      this.appStore.closeSidePanel()
    }
  }
}
</script>
