<template>
  <div v-on-list-resize
       class="dashboard__block dashboard__block--table">
    <div class="dashboard__block__heading">
      <h3 class="title">
        {{ $t('title.expenses_per_user') }}
      </h3>
      <div class="info">
        {{ $t('general.30_last_days') }}
      </div>
    </div>
    <loader-spinner v-if="loading" />
    <ul v-else-if="users.length"
        class="list-transaction">
      <li v-for="(item, i) in users.slice(0, 5)"
          :key="i"
          style="cursor: auto">
        <div class="picture">
          <user-avatar :picture="item.user?.picture"
                       :placeholder-label="`${item.user?.first_name} ${item.user?.last_name}`" />
        </div>
        <span class="name">{{ `${item.user?.first_name} ${item.user?.last_name}` }}</span>
        <span class="count">{{ $tc('general.transaction', item.operations_count) }}</span>
        <span class="amount right"
              style="padding-right: 0; min-width: 12rem">{{ formatAmount(item.operations_amount, account.currency) }}</span>
      </li>
    </ul>
    <p v-else
       class="centered-item empty-text">
      {{ $t("placeholder.no_operation.title") }}
    </p>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    UserAvatar,
    LoaderSpinner
  },

  props: {
    users: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore }
  },

  computed: {
    currentMonth () {
      return dayjs().format('MMMM YYYY')
    }
  },

  methods: {
    formatAmount
  }
}
</script>
