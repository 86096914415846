<template>
  <div class="modal__content">
    <modal-header :title="title"
                  @close="$emit('close')" />
    <div class="modal__body">
      <dropzone v-show="!hasDocuments"
                :accepted-files="acceptedFiles.join(',')"
                mode="modal"
                :max-files="1"
                @on-file-added="onDocumentAdded" />
      <div v-show="hasDocuments"
           class="profile-picture-wrapper">
        <loader-spinner v-if="croppieLoading"
                        class="c c--vh" />
        <div id="profile-picture-container"
             :style="{ opacity: croppieLoading ? 0 : 1 }" />
      </div>
    </div>
    <div class="modal__footer"
         :class="{ 'modal__footer--centered': hasDocuments }">
      <div class="modal__footer__left" />
      <div class="modal__footer__right">
        <button type="button"
                class="btn btn--gray"
                @click.prevent="$emit('close')">
          {{ $t('button.cancel') }}
        </button>
        <button v-if="hasDocuments"
                type="button"
                :disabled="loading || croppieLoading"
                class="btn btn--primary"
                @click="attachDocuments">
          {{ $t('button.validate') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Croppie from 'croppie'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores/auth'
import { useDocumentStore } from '@/stores/document'

import Dropzone from '@/components/Dropzone.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'

export default {
  components: {
    LoaderSpinner,
    Dropzone,
    ModalHeader
  },

  props: {
    updatePicture: {
      type: Function,
      default: () => ({})
    },

    pictureToUpdate: {
      type: String,
      validator: prop => ['default', 'user'].includes(prop),
      default: 'default'
    },

    title: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const authStore = useAuthStore()
    const documentStore = useDocumentStore()

    const { user } = storeToRefs(authStore)

    return { user, authStore, documentStore }
  },

  data () {
    return {
      loading: false,
      croppieLoading: false,
      profilePicture: null,
      croppie: null,
      files: [],
      acceptedFiles: ['image/jpg', 'image/jpeg', 'image/png', 'image/tif', 'image/tiff', 'image/webp']
    }
  },

  computed: {
    hasDocuments () {
      return this.files.length
    }
  },

  methods: {
    onDocumentAdded (files) {
      if (!files.length || !this.validateMimeFiles(files) || files.length !== 1) return null
      this.croppieLoading = true
      this.files = files
      this.profilePicture = URL.createObjectURL(this.files[0])
      this.croppie = new Croppie(document.getElementById('profile-picture-container'), {
        viewport: { width: 210, height: 210 },
        boundary: { width: 370, height: 290 },
        showZoomer: true
      })
      this.croppie.bind({
        url: this.profilePicture,
        zoom: 0
      }).then(() => {
        this.croppieLoading = false
      })
    },

    async attachDocuments () {
      const blob = await this.croppie.result({ type: 'blob', size: { width: 300, height: 300 } })
      if (!blob) { return }

      this.loading = true
      let success = false

      if (this.pictureToUpdate === 'user') {
        success = await this.authStore.sendPicture(blob)
      } else {
        success = await this.updatePicture(blob)
      }

      if (success) {
        this.$bus.emit('user-profile-picture-updated', { uuid: this.user.uuid, url: URL.createObjectURL(blob) })
        this.$emit('close')
      }
      this.loading = false
    },

    validateMimeFiles (files) {
      let hasError = false
      files.forEach(file => {
        if (!file.type || !this.acceptedFiles.includes(file.type)) hasError = true
      })

      return !hasError
    }
  }
}
</script>

<style>
#profile-picture-container {
  transition: opacity 0.6s ease;
}
</style>
