<template>
  <div class="multiple-card-order-details form form--w1000 form--centered">
    <component-alert :type="'success'"
                     :message="$tc('form.users_import.success', usersImport.length)"
                     :show-api-messages="false" />
    <fieldset class="form__fieldset">
      <div>
        <fieldset-title :title="$tc('form.title.users_import_details', usersImport.length)" />
        <div class="bordered-box">
          <div class="bordered-box__search">
            <div class="input-group input-group--noborder">
              <span class="input-group__addon">
                <ic-search class="ic ic--gray" />
              </span>
              <input v-model="filter"
                     class="form-control"
                     type="text"
                     :placeholder="$t('form.search.placeholder')">
            </div>
          </div>
          <div class="overflow-scrollbar order-details-list">
            <table class="table table--hover">
              <tbody>
                <tr v-for="(item, index) in filteredUsersList"
                    :key="index"
                    class="is-link">
                  <td class="col-checkbox">
                    <label class="checkbox">
                      <input v-model="item.selected"
                             type="checkbox"
                             name="checkbox">
                      <span class="checkbox__icon" />
                    </label>
                  </td>
                  <td @click="editUser(item.id)">
                    <div class="strong text-dark">
                      {{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div class="text-muted">
                      {{ item.role_label }}
                    </div>
                  </td>
                  <td class="col-nowrap center"
                      @click="editUser(item.id)">
                    <user-access :invitation="item"
                                 is-import-mode />
                  </td>
                  <td class="right button-edit"
                      @click="editUser(item.id)">
                    <button class="btn btn--outline btn-edit">
                      <ic-pencil class="ic ic--18 ic--gray" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bordered-box__summary">
            <div class="row row--sm-center">
              <div class="col col--sm">
                <strong>
                  {{ $t('users_import.total_users') }} :
                </strong>
              </div>
              <div class="col col--sm right">
                <strong>{{ usersToSubmit.length }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'users' }">
            {{ $t('button.cancel') }}
          </router-link>
          <component-button wrapper-class="btn--primary"
                            :label="$t('button.validate')"
                            :disabled="loading || !usersToSubmit.length"
                            @click="onSubmit" />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { showToastSuccess } from '@/helpers/utils/notification'
import { textContainsFilter } from '@/helpers/utils/text'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import SidepanelEditImported from '@/components/multiple-creation/sidepanel/SidepanelEditImported.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'
import UserAccess from '@/components/UserAccess.vue'

export default {
  components: {
    UserAccess,
    IcPencil,
    IcSearch,
    ComponentAlert,
    ComponentButton,
    FieldsetTitle
  },

  inheritAttrs: false,

  emits: ['toggle-step'],

  setup () {
    const appStore = useAppStore()
    const userStore = useUserStore()

    const { usersImport } = storeToRefs(userStore)

    return { appStore, usersImport, userStore }
  },

  data () {
    return {
      loading: false,
      filter: ''
    }
  },

  computed: {
    usersToSubmit () {
      return this.usersImport.filter(u => u.selected)
    },

    filteredUsersList () {
      return this.usersImport.filter(user => textContainsFilter(`${user.first_name} ${user.last_name}`, this.filter))
    }
  },

  methods: {
    editUser (id) {
      this.appStore.showSidePanel(
        SidepanelEditImported,
        {
          title: this.$i18n.t('sidepanel.user.title'),
          steps: ['user-informations', 'user-rights'],
          editItemMethod: this.userStore.editUsersImport,
          idInitial: id,
          items: this.usersImport
        },
        {
          wrapperClass: 'sidepanel--xl',
          backgroundOverlay: true
        })
    },

    toggleStep (step) {
      this.$emit('toggle-step', step)
    },

    async onSubmit () {
      this.loading = true
      const message = await this.userStore.createMultipleUsers(this.usersToSubmit)
      this.loading = false

      if (message) {
        showToastSuccess(message)
        await this.$router.push({ name: 'users' })
      }
    }
  }
}
</script>
