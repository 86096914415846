<template>
  <span class="fieldset-title">
    <span>
      <h3 class="fieldset-title__title">
        <span v-if="showNumber"
              class="number" />{{ title }}
      </h3>
      <p v-if="subtitle"
         class="fieldset-title__subtitle pre-line text-muted">
        {{ subtitle }}
      </p>
    </span>
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: ''
    },

    showNumber: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.fieldset-title
  display flex
  justify-content space-between
  align-items center
  margin-bottom 2.4rem

  &__title
    font-size 1.5rem
    color $colorShinyGray
    font-weight 500
    margin-bottom 0

    .number
      display inline-block
      width 26px
      height 26px
      line-height 2.25rem
      border 1px solid $borderColor
      border-radius 50%
      text-align center
      margin-right 1.4rem
      font-size 1.3rem
      font-weight 500

      &::before
        counter-increment countNumber
        content counter(countNumber)
</style>
