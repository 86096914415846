<template>
  <div class="modal__content">
    <div class="modal__header">
      <button type="button"
              class="modal-close btn-close"
              aria-label="Close"
              @click.prevent="$emit('close')" />
      <logo-bank-inline class="logo-icon" />
    </div>
    <iframe width="100%"
            height="100%"
            :src="source"
            class="modal__iframe" />
  </div>
</template>

<script>
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'

export default {
  name: 'ComponentIframe',

  components: {
    LogoBankInline
  },

  props: {
    source: {
      type: String,
      required: true
    }
  },

  emits: ['close']
}
</script>
