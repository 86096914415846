<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-min-max">
      <div class="filter-min-max__input-container"
           :class="inputContainerClass">
        <number-input :model-value="min"
                      class="filter-min-max__input"
                      :placeholder="$t('form.min_amount.placeholder')"
                      :default-zero="false"
                      name="min_account"
                      @update:model-value="onInput($event, 'min')" />

        <span class="filter-min-max__currency">{{ userAccountCurrencySymbol }}</span>
      </div>

      <div class="filter-min-max__input-container"
           :class="inputContainerClass">
        <number-input :model-value="max"
                      class="filter-min-max__input"
                      :placeholder="$t('form.max_amount.placeholder')"
                      :default-zero="false"
                      name="max_account"
                      @update:model-value="onInput($event, 'max')" />

        <span class="filter-min-max__currency">{{ userAccountCurrencySymbol }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'

import { getCurrencySymbol, getCurrencySymbolPosition } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import NumberInput from '@/components/NumberInput.vue'

export default {
  components: {
    NumberInput
  },

  props: {
    min: {
      type: [String, Number],
      required: true
    },

    max: {
      type: [String, Number],
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    accountCurrency () {
      return this.account.currency
    },

    userAccountCurrencySymbol () {
      return getCurrencySymbol(this.accountCurrency)
    },

    inputContainerClass () {
      const symbolPosition = getCurrencySymbolPosition(this.accountCurrency)
      return symbolPosition === 'left' ? 'filter-min-max__input-container--reverse' : ''
    }
  },

  methods: {
    onInput: debounce(function (value, syncEventName) {
      this.$emit(`update:${syncEventName}`, value)
      this.$parent.onSubmit()
    }, 400)
  }
}
</script>
