<template>
  <div class="fs-page fs-page--flex">
    <router-link class="logo-page-topleft is-link"
                 :to="{ name: 'home' }">
      <logo-bank-inline class="logo-mone-icon" />
    </router-link>
    <div class="fs-page__body">
      <router-view />
    </div>
    <component-footer />
  </div>
</template>

<script>
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'

import ComponentFooter from './components/Footer.vue'

export default {
  components: {
    LogoBankInline,
    ComponentFooter
  },

  created () {
    document.documentElement.classList.add('page-login')
  }
}
</script>
