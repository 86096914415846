<template>
  <component-tabs :data="segments" />
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'

import ComponentTabs from '@/components/Tabs.vue'

export default {
  components: {
    ComponentTabs
  },

  computed: {
    segments () {
      return [
        {
          label: this.$i18n.t('button.checking_account'),
          route: { name: 'operations' },
          visible: true
        },
        {
          label: this.$i18n.tc('button.cards'),
          route: { name: 'operations-cards' },
          visible: hasFeature(EFeature.SectionCard)
        },
        {
          label: this.$i18n.tc('button.incoming_transfers'),
          route: { name: 'incoming-transfers' },
          visible: hasFeature(EFeature.OperationIncomingTransfer)
        }
      ].filter(route => route.visible)
    }
  }
}
</script>
