<template>
  <div data-cy="cards.details.delivery.sidepanel"
       class="sidepanel__content">
    <div class="sidepanel-header">
      <h2 class="panel-title">
        {{ $t('sidepanel.track_shipment.title') }}
      </h2>
    </div>
    <div class="sidepanel-body">
      <div v-if="model.card_tracking.is_tracking_available"
           data-cy="cards.details.delivery.card-tracking"
           class="bordered-box bordered-box--padding tracking-card">
        <div class="strong">
          {{ $t('general.credit_card') }}
        </div>
        <div class="text-muted">
          {{ $t('sidepanel.track_shipment.tracked_mail', { number: model.card_tracking.tracking_number }) }}
        </div>
        <component-timeline :key="model.card_tracking.steps.length"
                            type="workflow">
          <template #body>
            <component-timeline-item v-for="(step, index) in model.card_tracking.steps"
                                     :id="index"
                                     :key="index"
                                     type="workflow"
                                     color="red"
                                     :title="formatTitle(step.triggered_at)"
                                     :subtitle="step.label"
                                     :status="step.status"
                                     icon-processing="pending"
                                     :next-is-done="nextIsDoneCard(index)"
                                     :is-last-item="index === model.card_tracking.steps.length - 1" />
          </template>
        </component-timeline>
      </div>
      <div v-if="model.pin_tracking.is_tracking_available"
           data-cy="cards.details.delivery.pin-tracking"
           class="bordered-box bordered-box--padding tracking-pin">
        <div class="strong">
          {{ $t('general.pin_code') }}
        </div>
        <div class="text-muted">
          {{ $t('sidepanel.track_shipment.tracked_mail', { number: model.pin_tracking.tracking_number }) }}
        </div>
        <component-timeline :key="model.pin_tracking.steps.length"
                            type="workflow">
          <template #body>
            <component-timeline-item v-for="(step, index) in model.pin_tracking.steps"
                                     :id="index"
                                     :key="index"
                                     type="workflow"
                                     color="red"
                                     :title="formatTitle(step.triggered_at)"
                                     :subtitle="step.label"
                                     :status="step.status"
                                     icon-processing="pending"
                                     :next-is-done="nextIsDonePin(index)"
                                     :is-last-item="index === model.pin_tracking.steps.length - 1" />
          </template>
        </component-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'

import ComponentTimeline from '@/components/timeline/Timeline.vue'
import ComponentTimelineItem from '@/components/timeline/TimelineItem.vue'

export default {
  components: {
    ComponentTimeline,
    ComponentTimelineItem
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatTitle (text) {
      return formatDateText(text)
    },

    nextIsDoneCard (index) {
      let next = index + 1
      if (this.model.card_tracking.steps[next] === undefined) next = index
      return this.model.card_tracking.steps[next].status === 'done'
    },

    nextIsDonePin (index) {
      let next = index + 1
      if (this.model.pin_tracking.steps[next] === undefined) next = index
      return this.model.pin_tracking.steps[next].status === 'done'
    }

  }
}
</script>
