<template>
  <div v-on-list-resize
       :class="isChartVisible ? 'with-chart' : 'default'">
    <div class="dashboard__block dashboard__block--table">
      <div class="dashboard__block__heading">
        <h3 class="title">
          {{ $t('title.expenses_per_categorie') }}
        </h3>
        <div class="info">
          {{ $t('general.30_last_days') }}
        </div>
      </div>
      <loader-spinner v-if="loading" />
      <ul v-else-if="categories.length"
          class="list-transaction"
          @mouseleave="onCategoryHover(null)">
        <li v-for="(category, i) in categories"
            :key="i"
            style="cursor: auto"
            @mouseover="onCategoryHover(category)">
          <div class="category-icon"
               :style="{ backgroundColor: hexToRgba(category.color, 0.1) }">
            <ic-category v-bind="{ [category.name]: true }"
                         class="ic ic-category ic--gray"
                         :style="`fill: ${category.color}`" />
          </div>
          <span class="name">{{ category.label }}</span>
          <span class="count">{{ $tc('general.transaction', category.count) }}</span>
          <span class="amount right"
                style="padding-right: 0; min-width: 12rem">{{ formatAmount(category.amount, account.currency) }}</span>
        </li>
      </ul>
      <p v-else
         class="centered-item empty-text">
        {{ $t("placeholder.no_operation.title") }}
      </p>
    </div>
    <div v-if="isChartVisible"
         class="chart-block">
      <loader-spinner v-if="loading" />
      <div v-else
           class="card-expenses__chart">
        <transition name="fade"
                    mode="out-in">
          <div v-if="activeCategory"
               :key="activeCategory.name"
               class="card-expenses__chart__inside">
            <div class="icon"
                 :style="{ backgroundColor: hexToRgba(activeCategory.color, 0.1) }">
              <ic-category v-bind="{ [activeCategory.name]: true }"
                           class="ic ic-category ic--gray"
                           :style="`fill: ${activeCategory.color}`" />
            </div>
            <div class="expense-amount">
              {{ formatAmount(activeCategory.amount, account.currency) }}
            </div>
            <div class="expense-label">
              {{ activeCategory.label }}
            </div>
          </div>
          <div v-else
               class="card-expenses__chart__inside">
            <div class="expense-amount">
              {{ formatAmount(expenses.total?.amount, account.currency) }}
            </div>
            <div class="expense-label">
              <span class="pre-line">{{ $t('general.total_expenses_on_last_days') }}</span>
            </div>
          </div>
        </transition>
        <div :style="`width:${chartSize}px; height:${chartSize}px`">
          <donut-chart ref="donut-chart"
                       :items="categories"
                       @hover="onCategoryHover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'
import { hexToRgba } from '@/helpers/utils/color'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import DonutChart from '@/components/charts/DonutChart.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcCategory from '@/components/svg/icons/ic-category.vue'

export default {
  components: {
    DonutChart,
    IcCategory,
    LoaderSpinner
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    expenses: {
      type: Object,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const accountStore = useAccountStore()

    const { account } = accountStore

    return {
      appStore,
      account
    }
  },

  data () {
    return {
      chartSize: 300,
      activeCategory: null
    }
  },

  computed: {
    categories () {
      return (this.expenses?.details || []).reduce((acc, cur) => {
        if (acc.length < 5) {
          acc.push(cur)
        } else {
          acc[acc.length - 1] = {
            ...acc[acc.length - 1],
            label: this.$t('general.other'),
            name: 'other',
            count: acc[acc.length - 1].count += cur.count,
            amount: acc[acc.length - 1].amount += cur.amount,
            color: '#CBCBCB'
          }
        }
        return acc
      }, [])
    },

    isChartVisible () {
      return hasFeature(EFeature.DashboardCategoryExpensesChartBlock)
    }
  },

  watch: {
    loading (value) {
      if (!value) { window.requestAnimationFrame(this.initDonutChart) }
    }
  },

  mounted () {
    if (!this.loading && this.categories.length) { this.initDonutChart() }
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    formatAmount,

    hexToRgba,

    onResize () {
      this.chartSize = (this.$el.getBoundingClientRect().height > 400 ? this.$el.getBoundingClientRect().height : 400) * 3 / 5
    },

    initDonutChart () {
      if (this.isChartVisible) window.requestAnimationFrame(this.$refs['donut-chart']?.initChart)
    },

    onCategoryHover (category) {
      this.activeCategory = category
    }
  }
}
</script>

<style lang="stylus" scoped>
.default
  width 50%

  .dashboard__block
    width 100%

.with-chart
  width 100%
  display flex

  .dashboard__block
    &:first-child
      width 60%

    &:nth-child(2)
      width 40%

.chart-block
  flex 1
  display flex
  justify-content center
  align-items center

  .card-expenses__chart__inside
    display flex
    flex-direction column
    align-items center

  .icon
    width 32px
    height 32px
    display flex
    align-items center
    justify-content center
    background $colorLightGray
    border-radius 8px

    .ic-category
      margin-bottom 0

  .expense-amount
    font-size 2.4rem
    font-weight 700

  .expense-label
    color $colorDarkGray

.card-expenses__chart
  margin 0
  height auto

.fade-enter-active
.fade-leave-active
  transition all 0.1s ease-out
</style>
