import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { ICampaign, ICampaignLight, IReward, IRewardLight } from '@/types/loyalty.d'

export const useLoyaltyStore = defineStore('loyalty', {
  state: () => {
    return {
      loyaltyPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },
  actions: {
    async getRewards (page: number) {
      try {
        const { data, headers } = await axiosClient.get<IRewardLight[]>('/loyalty/rewards', { params: { page } })
        this.loyaltyPagination = this.parsePagination(headers)
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getReward (uuid: string) {
      try {
        const { data } = await axiosClient.get<IReward>(`/loyalty/rewards/${uuid}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCampaigns (page: number) {
      try {
        const { data, headers } = await axiosClient.get<ICampaignLight[]>('/loyalty/campaigns', { params: { page } })
        this.loyaltyPagination = this.parsePagination(headers)
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getCampaign (uuid: string) {
      try {
        const { data } = await axiosClient.get<ICampaign>(`/loyalty/campaigns/${uuid}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
