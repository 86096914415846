<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="credits.length">
      <table data-cy="credits.list"
             class="table table--hover table--fixed">
        <thead>
          <tr>
            <th class="col--sm-1">
              {{ $t("table.date") }}
            </th>
            <th class="col--sm-4">
              {{ $t("general.reason") }}
            </th>
            <th class="col--sm-1">
              {{ $t("table.reference") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.amount") }}
            </th>
            <th v-if="hasFeature(EFeature.CreditDocument)"
                class="col--sm-1 center">
              {{ $t("table.invoice") }}
            </th>
            <th class="col--sm-1 center">
              {{ $t("table.notification") }}
            </th>
            <th class="col--sm-2" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="credit in credits"
              :key="credit.id">
            <td class="text-muted nowrap">
              {{ credit.formattedDate }}
            </td>
            <td><span class="col-label text-overflow">{{ credit.label }}</span></td>
            <td><span class="col-label text-overflow">{{ credit.reference }}</span></td>
            <td class="right text-green">
              <span class="amount">{{ credit.formattedAmount }}</span>
            </td>
            <td v-if="hasFeature(EFeature.CreditDocument)"
                class="center">
              <button v-if="credit.document"
                      v-tooltip="{ content: fileViewerTooltip(credit.document), theme: 'tooltip' }"
                      class="btn-link btn-attachment"
                      @click.prevent.stop="showFile(credit.document)">
                <ic-document class="ic ic-document ic--gray" />
              </button>
              <button v-else
                      v-tooltip="{ content: $t('title.attach_invoice'), theme: 'tooltip' }"
                      class="btn-link btn-attachment"
                      @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_invoice'), credit, { url: `/credits/${credit.id}/document`, maxFiles: 1 })">
                <ic-attachment class="ic ic--gray" />
              </button>
            </td>
            <td class="center">
              <button :key="`${credit.id}-${credit.send_notification}`"
                      v-tooltip="{ content: credit.notificationTooltip, theme: 'tooltip' }"
                      class="btn-link"
                      @click="credit.toggleNotification">
                <ic-alert :off="!credit.send_notification"
                          class="ic"
                          :class="{
                            'ic-alert ic--gray': credit.send_notification,
                            'ic-alert-off ic--off': !credit.send_notification
                          }" />
              </button>
            </td>
            <td class="col-action">
              <span class="hover-item">
                <button v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                        class="btn-link"
                        @click.prevent.stop="showCreditDetail(credit)">
                  <ic-pencil class="ic ic--24 ic--gray" />
                </button>
                <button v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                        class="btn-link"
                        @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.income.delete.title'), content: $t('prompt.income.delete.content'), url: `/credits/${credit.id}` })">
                  <ic-remove class="ic ic--gray" />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <component-pagination :pagination="creditsPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t('placeholder.no_encashment.title')"
                           :content="$t('placeholder.no_encashment.subtitle')">
      <credit-notification />
    </component-placeholder>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import store from '@/config/store'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useCreditStore } from '@/stores/credit'

import CreateCredit from '@/pages/credits/sidepanel/CreateCredit.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAlert from '@/components/svg/icons/ic-alert.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import CreditNotification from '@/components/svg/img/credit-notification.vue'

export default {
  components: {
    IcAttachment,
    IcDocument,
    IcPencil,
    IcRemove,
    IcAlert,
    CreditNotification,
    ComponentPagination,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const creditStore = useCreditStore()

    const { fileViewer } = storeToRefs(appStore)
    const { account } = storeToRefs(accountStore)
    const { user } = storeToRefs(authStore)
    const { credits, creditsPagination, creditsPendingAmount } = storeToRefs(creditStore)

    return { account, appStore, fileViewer, user, credits, creditsPagination, creditsPendingAmount, creditStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  computed: {
    EFeature () {
      return EFeature
    }
  },

  watch: {
    state () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'creditsPagination.totalItems' () {
      this.$emit('pagination-total-items', this.creditsPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('credits-save-success', this.onSaveCreditSuccess)
    this.$bus.on('credits-update-success', this.onUpdateCreditSuccess)
    this.$bus.on('delete-success', this.onDeleteSuccess)
  },

  beforeUnmount () {
    this.$bus.off('credits-save-success', this.onSaveCreditSuccess)
    this.$bus.off('credits-update-success', this.onUpdateCreditSuccess)
    this.$bus.off('delete-success', this.onDeleteSuccess)
  },

  methods: {
    hasFeature,

    async initData () {
      this.loading = true
      await this.creditStore.getCredits(this.state, this.page, this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    showCreditDetail (credit) {
      this.appStore.showSidePanel(
        CreateCredit,
        {
          model: credit
        }, {
          backgroundOverlay: true,
          wrapperClass: 'sidepanel--700'
        }
      )
    },

    showFile (file) {
      this.appStore.showFileViewer({
        content: [file],
        displayFullScreen: true
      })
    },

    onSaveCreditSuccess () {
      showToastSuccess(this.$i18n.t('message.success.income.create'))
      this.initData()
    },

    onUpdateCreditSuccess () {
      showToastSuccess(this.$i18n.t('message.success.income.edit'))
      this.initData()
    },

    fileViewerTooltip (document) {
      return (this.fileViewer.allowedFilesType.includes(document.mime_type))
        ? this.$i18n.tc('tooltip.show_file')
        : this.$i18n.t('tooltip.download_file')
    },

    onDeleteSuccess (message) {
      this.initData()

      if (!this.fileViewer.active) {
        showToastSuccess(message || this.$i18n.t('message.success.income.delete'))
      }
    }
  }
}
</script>
