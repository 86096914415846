<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'mileages' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ title }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div v-if="!isExistingMileage"
             class="section-actions">
          <a v-if="mileage.documents.length"
             class="btn-link btn-link--attachment"
             href="#"
             @click="onShowDocuments">
            <i class="btn-icon btn-icon--left">
              <ic-document class="ic ic-document ic--gray" />
            </i>
            <span class="btn__label">{{ transferDocumentsLabel }}</span>
            <button class="btn-link btn-remove-file"
                    @click.prevent.stop="removeFiles">
              <ic-remove class="ic ic--18 ic--gray" />
            </button>
          </a>
          <button v-show="!mileage.documents.length"
                  class="btn btn--default"
                  @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_proof'))">
            <i class="btn-icon btn-icon--left">
              <ic-attachment class="ic ic--white" />
            </i>
            {{ $t('button.attach_proof') }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <validation-form v-else
                       ref="form"
                       v-slot="{ errors, isSubmitting }"
                       :validation-schema="schema"
                       class="form form--w1000 form-wiretransfer form--centered"
                       @keydown.enter.prevent
                       @invalid-submit="onInvalidSubmit"
                       @submit="onSubmit">
        <component-alert v-if="Object.keys(errors).length"
                         :message="$t('form.alerts.common')"
                         type="error" />
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.credit_account.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div v-if="existingBeneficiary"
                   class="form-group col col--sm-7">
                <label class="label input__label">{{ $t('form.credit_account.label') }}</label>
                <div class="form-inline input-pencil-addon">
                  <lock-input disabled
                              :placeholder="$t('form.last_name.placeholder')"
                              :model-value="beneficiaryLabel"
                              name="beneficiary_label"
                              input-class="form-control form-control--noborder"
                              type="text" />
                  <button v-if="!isExistingMileage"
                          class="btn btn--icon btn--default"
                          type="button"
                          @click="onEditBankDetails">
                    <i class="btn-icon">
                      <ic-pencil class="ic ic--20 ic--white" />
                    </i>
                  </button>
                </div>
              </div>
              <template v-else>
                <div :class="{ error: errors.iban }"
                     class="form-group col col--sm-8">
                  <label class="label input__label">{{ $t('form.iban.label') }}</label>
                  <lock-input id="iban"
                              v-model="beneficiary.iban"
                              :disabled="isExistingMileage"
                              :placeholder="$t('form.iban.placeholder')"
                              input-class="form-control form-control--noborder input__field"
                              name="iban"
                              type="text"
                              @change="onIbanChange"
                              @keyup="onIbanKeyUp" />
                </div>
                <div :class="{ error: errors.bic }"
                     class="form-group col col--sm-4">
                  <label class="label input__label">{{ $t('general.bic_swift') }}</label>
                  <lock-input id="bic"
                              v-model="beneficiary.bic"
                              :disabled="isExistingMileage"
                              :placeholder="$t('form.beneficiary.bic_code.placeholder')"
                              input-class="form-control form-control--noborder input__field"
                              name="bic"
                              type="text" />
                </div>
              </template>
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.trip_details.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div :class="{ error: errors.amount }"
                   class="form-group col col--sm-6">
                <label class="label input__label"
                       for="amount">
                  {{ $t('form.your_vehicle.label') }}
                </label>
                <div class="input-group">
                  <div class="form-control form-control--noborder disabled">
                    <ic-electrical v-if="vehicle.is_electrical"
                                   v-tooltip="{ content: $t('tooltip.electric_vehicle'), theme: 'tooltip', html: true }"
                                   class="ic ic--14 ic--center-vertically mr-1" />
                    {{ vehicleLabel }}
                  </div>
                  <div class="input-group__addon">
                    <ic-cadenas-sm class="ic ic--16 ic--off" />
                  </div>
                </div>
              </div>
              <button class="btn btn--icon btn--default"
                      type="button"
                      @click="addVehicle">
                <i class="btn-icon">
                  <ic-pencil class="ic ic--20 ic--white" />
                </i>
              </button>
            </div>
            <div class="form__row row">
              <div :class="{ error: errors.distance }"
                   class="form-group col col--sm-6">
                <label class="label input__label"
                       for="amount">{{ $t('form.distance.label') }}</label>
                <number-input v-model="mileage.distance"
                              default-zero
                              :placeholder="$t('form.distance.placeholder')"
                              :disabled="!isVehicleSet"
                              addon="km"
                              class="form-control form-control--noborder"
                              name="distance"
                              @update:model-value="onChangeDistance" />
              </div>
              <div v-if="mileage.distance"
                   class="form-group col col--sm-6">
                <label class="label input__label">&nbsp; </label>
                <div class="form-control form-control--transparent form-control--sm pl-0">
                  <loader-spinner v-if="isAmountLoading"
                                  class="my-0 mx-4"
                                  size="20" />
                  <component-info v-else>
                    {{ estimationLabel }}
                  </component-info>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.refunds.refund_details')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row">
              <div :class="{ error: errors.amount }"
                   class="form-group col col--sm-6">
                <label class="label input__label"
                       for="amount">{{ $t('form.amount.label') }}</label>
                <div class="input-group">
                  <number-input id="amount"
                                v-model="mileage.amount"
                                decimal
                                disabled
                                :minimum-fraction-digits="2"
                                :placeholder="$t('form.amount.placeholder')"
                                :addon="getCurrencySymbol(account.currency)"
                                class="form-control form-control--noborder"
                                data-vv-as="amount"
                                name="amount" />
                  <div class="input-group__addon">
                    <ic-cadenas-sm class="ic ic--16 ic--off" />
                  </div>
                </div>
              </div>
              <div :class="{ error: errors.mileage_date }"
                   class="form-group col col--sm-6">
                <component-datepicker v-model="mileage.mileage_date"
                                      :disabled-days="disabledFrom"
                                      :format="'dd/MM/yyyy'"
                                      input-class="form-control form-control--noborder"
                                      :label="$t('form.mileage_date.label')"
                                      :language="$i18n.locale"
                                      monday-first
                                      :placeholder="$t('form.date.placeholder')"
                                      :readonly="false"
                                      wrapper-class="form-group"
                                      name="mileage_date" />
              </div>
            </div>
            <div class="form__row row">
              <div :class="{ error: errors.label }"
                   class="form-group col col--sm-6">
                <label class="label input__label"
                       for="label">
                  {{ $t('general.reason') }}</label>
                <div class="dropdown">
                  <validation-field id="label"
                                    v-model="mileage.label"
                                    :placeholder="$t('form.transfer_details.beneficiary_placeholder_short')"
                                    class="form-control form-control--noborder input__field"
                                    name="label"
                                    type="text" />
                </div>
              </div>
              <div :class="{ error: errors.comment }"
                   class="form-group col col--sm-6">
                <label class="label input__label"
                       for="comment">{{ $t('form.personal_note.label') }} ({{ $t('general.optional') }})</label>
                <validation-field id="comment"
                                  v-model="mileage.comment"
                                  :placeholder="$t('form.personal_note.placeholder')"
                                  class="form-control form-control--noborder input__field"
                                  name="comment"
                                  type="text" />
              </div>
            </div>
            <div class="form__row row">
              <div :class="{ error: errors.category }"
                   class="form-group col col--sm-6">
                <label class="label input__label">{{ $tc("form.category.label") }}</label>
                <lock-input disabled
                            :model-value="$t('title.mileages')"
                            name="category"
                            input-class="form-control form-control--noborder" />
              </div>
              <div v-if="isAffectationsInputVisible"
                   :class="{ error: errors.affectations }"
                   class="form-group col col--sm-6">
                <label class="label input__label">{{ $t("form.affectation.label") }}
                  <template v-if="!hasAffectations">({{ $t('general.optional') }})</template>
                </label>
                <validation-field v-slot="{ handleChange }"
                                  :model-value="mileage.affectations"
                                  name="affectations">
                  <component-dropdown async-url="/affectations"
                                      :can-add-item="canWriteAffectations"
                                      dropdown-width="100%"
                                      :model="mileage.affectations"
                                      multiselect
                                      search
                                      dropdown-menu-class="dropdown-menu--noborder"
                                      emiter="on-affectations-loaded"
                                      label="affectations"
                                      :disabled="!canEditAffectations"
                                      @select="handleChange"
                                      @new-item="onNewAffectationAdded">
                    <template #trigger>
                      <div class="dropdown-toggle select">
                        <div class="form-control form-control--noborder">
                          <span v-if="!mileage.affectations.length"
                                class="text-placeholder">{{ $t('form.affectation.placeholder') }}</span>
                          <span v-else>{{ affectationsLabel }}</span>
                        </div>
                      </div>
                    </template>
                  </component-dropdown>
                </validation-field>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'mileages' }">
            {{ $t('button.cancel') }}
          </router-link>
          <button :disabled="isSubmitting"
                  class="btn btn--primary"
                  type="submit">
            {{ $t('button.validate') }}
          </button>
        </div>
      </validation-form>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import IBAN from 'iban'
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatIban, formatIbanHidden, formatIbanOnKeyUp } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount, formatNumber, getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useMileageStore } from '@/stores/mileage'
import { useOperationStore } from '@/stores/operation'
import { useTransferStore } from '@/stores/transfer'

import AddVehicle from '@/pages/mileage/sidepanel/AddVehicle.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ComponentInfo from '@/components/Info.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import LockInput from '@/components/LockInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import SidepanelEditBankDetails from '@/components/sidepanels/SidepanelEditBankDetails.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcElectrical from '@/components/svg/icons/ic-electrical.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcElectrical,
    FieldsetTitle,
    ComponentInfo,
    IcRemove,
    IcAttachment,
    IcDocument,
    IcPencil,
    IcCadenasSm,
    IcArrow,
    LockInput,
    LoaderSpinner,
    NumberInput,
    ComponentAlert,
    ComponentDropdown,
    ComponentDatepicker
  },

  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const mileageStore = useMileageStore()
    const transferStore = useTransferStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { userFullName } = storeToRefs(authStore)
    const { mileages } = storeToRefs(mileageStore)
    const { iban } = storeToRefs(transferStore)

    return {
      appStore,
      mileageStore,
      transferStore,
      operationStore,

      account,
      apiError,
      authStore,
      iban,
      mileages,
      userFullName
    }
  },

  data () {
    return {
      loading: false,
      isAmountLoading: false,
      isIban: false,
      vehicle: {
        type: null,
        label: null
      },

      mileage: {
        amount: 0,
        currency: 'EUR',
        label: '',
        comment: '',
        affectations: [],
        documents: [],
        mileage_date: new Date(),
        distance: ''
      },

      beneficiary: {
        label: null,
        iban: '',
        bic: ''
      },

      disabledFrom: {
        from: new Date()
      },

      affectations: [],
      estimation: null
    }
  },

  computed: {
    isVehicleSet () {
      return !!this.vehicle.type
    },

    vehicleLabel () {
      return this.isVehicleSet ? this.vehicle.label : this.$i18n.t('form.your_vehicle.placeholder')
    },

    estimationLabel () {
      if (!this.estimation) { return null }

      return [
        `${this.$i18n.t('general.being')} ${this.mileage.distance} × ${formatNumber(this.estimation.scale)}`,
        this.estimation.bonus ? `+ ${this.estimation.bonus} =` : '=',
        `${formatAmount(this.estimation.amount, this.account.currency)}`
      ].join(' ')
    },

    existingBeneficiary () {
      return this.account.beneficiary
    },

    beneficiaryLabel () {
      return this.isExistingMileage
        ? `${this.beneficiary.label} - ${formatIbanHidden(this.beneficiary.iban)}`
        : `${this.userFullName} - ${formatIbanHidden(this.account.beneficiary.iban)}`
    },

    isExistingMileage () {
      return !!this.id
    },

    title () {
      return this.isExistingMileage ? this.$i18n.t('title.edit_mileage') : this.$i18n.t('title.new_mileage')
    },

    affectationsLabel () {
      if (!this.mileage.affectations.length) { return '' }

      return this.mileage.affectations.map(affectation => affectation.label).join(', ')
    },

    transferDocumentsLabel () {
      return this.mileage.documents.length === 1
        ? this.mileage.documents[0].filename || this.mileage.documents[0].name
        : `${this.mileage.documents.length} documents`
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.mileagesAffectationsRead)
    },

    canEditAffectations () {
      return this.canReadAffectations && this.hasPermission(this.$permissions.mileagesAffectationsWrite)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    hasAffectations () {
      return this.account.counts.affectations > 0
    },

    isAffectationsInputVisible () {
      return this.canEditAffectations || (this.canReadAffectations && this.hasAffectations)
    },

    schema () {
      return yup.object({
        amount: yup.string().required(),
        bic: this.existingBeneficiary ? null : yup.string().required(),
        comment: yup.string().nullable(),
        distance: yup.string().required().min(0.01),
        affectations: this.hasAffectations ? yup.array().min(1).required() : null,
        iban: this.existingBeneficiary ? null : yup.string().required(),
        label: yup.string().required(),
        mileage_date: yup.string().required()
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    this.initData()
  },

  async mounted () {
    this.$bus.on('on-file-attached', this.onDocumentsAttached)
    this.$bus.on('delete-local-document', this.removeFile)
    this.$bus.on('on-affectations-loaded', this.onAffectationsLoaded)
    this.$bus.on('vehicle-infos-updated', this.onVehicleInfosUpdated)
    this.$bus.on('account-switched', this.goBackToList)
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
  },

  beforeUnmount () {
    this.$bus.off('on-file-attached', this.onDocumentsAttached)
    this.$bus.off('delete-local-document', this.removeFile)
    this.$bus.off('on-affectations-loaded', this.onAffectationsLoaded)
    this.$bus.off('vehicle-infos-updated', this.onVehicleInfosUpdated)
    this.$bus.off('account-switched', this.goBackToList)
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
  },

  methods: {
    getCurrencySymbol,

    async initData () {
      this.loading = true

      if (!this.isExistingMileage) {
        const data = await this.authStore.getVehicleInfos()
        if (data) Object.assign(this.vehicle, data)
        this.loading = false
        return
      }

      const mileage = await this.mileageStore.getMileageDetail(this.id)
      if (!mileage) { return this.goBackToList() }

      Object.keys(mileage).forEach(key => (this.mileage[key] = mileage[key]))

      this.mileage.id = mileage.id
      this.mileage.affectations = [...mileage.affectations]
      this.mileage.mileage_date = mileage.mileage_date

      this.beneficiary.iban = mileage.beneficiary.iban
      this.beneficiary.bic = mileage.beneficiary.bic
      this.beneficiary.label = mileage.beneficiary.label

      Object.assign(this.vehicle, mileage.vehicle)

      await this.getDistanceAmount()
      this.loading = false
    },

    onVehicleInfosUpdated (vehicle) {
      Object.assign(this.vehicle, vehicle)

      this.appStore.closeSidePanel()
      this.onChangeDistance()
    },

    onChangeDistance () {
      this.isAmountLoading = true
      this.debounceGetDistanceAmount()
    },

    debounceGetDistanceAmount: debounce(function () {
      this.getDistanceAmount()
    }, 500),

    async getDistanceAmount () {
      this.isAmountLoading = true
      const data = await this.mileageStore.getVehicleDistanceAmount({
        distance: this.mileage.distance,
        type: this.vehicle.type,
        level: this.vehicle.level,
        is_electrical: this.vehicle.is_electrical,
        mileage_estimation: this.vehicle.mileage_estimation
      })

      if (data) {
        this.estimation = data
        this.mileage.amount = this.estimation.amount
      }
      this.isAmountLoading = false
    },

    onInvalidSubmit () {
      this.$bus.emit('scrollToTop')
    },

    async onSubmit () {
      const mileage = { ...this.mileage }
      if (!this.isExistingMileage) {
        mileage.beneficiary = this.existingBeneficiary || this.beneficiary
      }
      mileage.mileage_date = dayjs(mileage.mileage_date).format('YYYY-MM-DD')
      mileage.documents = this.mileage.documents
      if (this.isExistingMileage) {
        const success = await this.mileageStore.editMileage(mileage)
        if (success) {
          showToastSuccess(this.$t('message.success.refund.update'))
          await this.goBackToList()
        }
      } else {
        const success = await this.mileageStore.saveMileage(mileage)
        if (success) {
          showToastSuccess(this.$t('message.success.refund.save'))
          await this.goBackToList()
        }
      }
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.mileage.affectations.push(newAffectation)
        this.$bus.emit('on-new-item-added', newAffectation)
        this.account.counts.affectations += 1
      }
    },

    onDeleteAffectation (itemId) {
      this.account.counts.affectations -= 1
      const index = this.mileage.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.mileage.affectations.splice(index, 1)
      }
    },

    onAffectationsLoaded (affectations) {
      this.affectations = [...affectations]
    },

    onIbanKeyUp (e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        return
      }
      const cursorPosition = e.target.selectionStart
      const formattedIban = formatIbanOnKeyUp(this.beneficiary.iban)
      if (formattedIban) {
        this.beneficiary.iban = formattedIban
        this.$nextTick(() => {
          this.setCursorPosition(e, cursorPosition)
        })
      }
    },

    setCursorPosition (e, cursorPosition) {
      if (e.key === 'Backspace' && cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition -= 1
      } else if (!/[a-z0-9]/i.test(e.key)) {
        cursorPosition -= 1
      } else if (cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition += 1
      }
      e.target.setSelectionRange(cursorPosition, cursorPosition)
    },

    async onIbanChange () {
      if (IBAN.isValid(this.beneficiary.iban)) {
        this.beneficiary.iban = formatIban(this.beneficiary.iban)
      }

      await this.transferStore.validateIban(this.beneficiary.iban)
      if (this.iban.sepa && this.iban.bic) {
        this.beneficiary.bic = this.iban.bic
      }
    },

    onDocumentsAttached ({ files }) {
      files.forEach(file => this.mileage.documents.push(file))
      this.appStore.closeModal()
    },

    removeFiles () {
      this.mileage.documents = []
    },

    onShowDocuments () {
      this.appStore.showFileViewer({
        content: this.mileage.documents,
        isMultiDoc: true,
        canEdit: true,
        isLocal: true,
        displayFullScreen: true
      })
    },

    addVehicle () {
      this.appStore.showSidePanel(AddVehicle, {}, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true })
    },

    onEditBankDetails () {
      this.appStore.showSidePanel(SidepanelEditBankDetails, {}, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true })
    },

    goBackToList () {
      this.$router.push({ name: 'mileages' })
    }
  }
}
</script>
