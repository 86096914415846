<template>
  <svg viewBox="0 0 15 14">
    <path d="M5.517 14 0 7.04l1.353-.999 4.103 5.177L13.605 0 15 .943 5.517 14z" />
  </svg>
</template>

<script>
export default {
}
</script>
