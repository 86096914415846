<template>
  <ul :class="isSection ? 'section-tabs' : 'nav'">
    <li v-for="(item, index) in filteredData"
        :key="index"
        :class="{ nav__item: !isSection }">
      <router-link :to="item.route"
                   :data-cy="`tab-${item.route.name}`"
                   :class="{ nav__link: !isSection, active: isSection ? item.active : $route.name === item.route.name }">
        {{ item.label }}
        <dot v-if="item.dot" />
        <core-badge v-else-if="item.badgeValue"
                    :value="item.badgeValue"
                    class="ml-1"
                    :theme="isSection ? item.active : $route.name === item.route.name ? ECoreBadgeTheme.Primary : ECoreBadgeTheme.GrayLow"
                    roundness />
      </router-link>
    </li>
    <slot />
  </ul>
</template>

<script>
import { CoreBadge, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { isResponsive } from '@/router'
import { useAppStore } from '@/stores/app'

import Dot from '@/components/Dot.vue'

export default {
  name: 'ComponentSegments',

  components: {
    CoreBadge,
    Dot
  },

  props: {
    isSection: {
      type: Boolean,
      default: false
    },

    data: {
      type: Array,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const { isDeviceMobile } = storeToRefs(appStore)

    return { isDeviceMobile }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    filteredData () {
      return this.data.filter(item => {
        const matchingRoute = this.$router.resolve({ name: item.route.name })
        if (this.isDeviceMobile && !isResponsive(matchingRoute)) {
          return false
        }

        return !matchingRoute?.meta?.permissions || this.hasPermission(matchingRoute.meta.permissions)
      })
    }
  }
}
</script>
