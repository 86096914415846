<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <div v-else-if="accountingCategories.length "
         class="table-bordered-box table-bordered-box--100">
      <template v-for="(list, idxList) in accountingCategoryList"
                :key="idxList">
        <div v-if="list.items && list.items.length"
             class="bordered-box">
          <div class="bordered-box__header">
            <div class="bordered-box__header__title">
              {{ list.label }}
            </div>
          </div>
          <div class="bordered-box__body">
            <table class="table">
              <thead>
                <tr>
                  <th class="col--sm-2">
                    {{ $t('accounting.table.account_number') }}
                  </th>
                  <th class="col--sm-4">
                    {{ $t('accounting.table.label') }}
                  </th>
                  <th class="col--sm">
                    {{ $t('table.description') }}
                  </th>
                  <th class="col--sm" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idxItem) in list.items"
                    :key="`item_${idxItem}`"
                    class="is-link"
                    @click="editItem(item)">
                  <td v-if="isCategoryAssociated(item)">
                    {{ item.coa_accounting_account.account_number }}
                  </td>
                  <td v-else>
                    <core-badge :theme="ECoreBadgeTheme.Info"
                                :value="$t('general.to_define')" />
                  </td>
                  <td>{{ item.label }}</td>
                  <td>{{ item.description || ' - ' }}</td>
                  <td class="col-action">
                    <span class="hover-item">
                      <button v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                              class="btn-link">
                        <ic-pencil class="ic ic--24 ic--gray" />
                      </button>
                      <button v-if="isCategoryAssociated(item)"
                              v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                              class="btn-link"
                              @click.prevent.stop="appStore.showDeleteModal({ url: `/companies/accounting/categories/${item.id}` })">
                        <ic-remove class="ic ic--24 ic--gray" />
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
    <div v-else
         class="section section--empty">
      <component-placeholder :label="$t('placeholder.accounting_category.title')"
                             :content="$t('placeholder.accounting_category.subtitle')">
        <accounting-sheet />
      </component-placeholder>
    </div>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeTheme } from '@common/core-ui'

import store from '@/config/store'
import { useAccountingStore } from '@/stores/accounting'
import { useAppStore } from '@/stores/app'

import AccountingCategoryDetail from '@/pages/accounting/sidepanel/AccountingCategoryDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import AccountingSheet from '@/components/svg/img/accounting-sheet.vue'

export default {
  components: {
    CoreBadge,
    IcPencil,
    IcRemove,
    AccountingSheet,
    ComponentPlaceholder,
    LoaderSpinner
  },

  setup () {
    const accountingStore = useAccountingStore()
    const appStore = useAppStore()

    return { accountingStore, appStore }
  },

  data () {
    return {
      store,
      loading: true,
      accountingCategories: [],
      accountingCategoryList: {
        0: {
          label: this.$t('accounting.categories.not_associated'),
          items: []
        },

        1: {
          label: this.$t('accounting.categories.class_1'),
          items: []
        },

        2: {
          label: this.$t('accounting.categories.class_2'),
          items: []
        },

        3: {
          label: this.$t('accounting.categories.class_3'),
          items: []
        },

        4: {
          label: this.$t('accounting.categories.class_4'),
          items: []
        },

        5: {
          label: this.$t('accounting.categories.class_5'),
          items: []
        },

        6: {
          label: this.$t('accounting.categories.class_6'),
          items: []
        },

        7: {
          label: this.$t('accounting.categories.class_7'),
          items: []
        },

        8: {
          label: this.$t('accounting.categories.class_8'),
          items: []
        }
      }
    }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('accounting-category-added', this.initData)
    this.$bus.on('accounting-category-edited', this.initData)
    this.$bus.on('delete-success', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('accounting-category-added', this.initData)
    this.$bus.off('accounting-category-edited', this.initData)
    this.$bus.off('delete-success', this.initData)
  },

  methods: {
    async initData () {
      this.loading = true
      const data = await this.accountingStore.getCategories()
      this.refreshList(data)
      this.loading = false
    },

    isCategoryAssociated (category) {
      return category.coa_accounting_account && category.coa_accounting_account.account_number
    },

    editItem (item) {
      this.appStore.showSidePanel(
        AccountingCategoryDetail,
        { model: item },
        { wrapperClass: 'sidepanel--md sidepanel-accounting', backgroundOverlay: true, closeButton: false }
      )
    },

    refreshList (newList) {
      this.accountingCategories = newList
      for (const key in this.accountingCategoryList) {
        this.accountingCategoryList[key].items = []
      }

      newList.forEach(item => {
        const accountingClass = item.coa_accounting_account?.account_number
          ? item.coa_accounting_account.account_number.toString()[0]
          : 0

        if (!this.accountingCategoryList[accountingClass]) {
          this.accountingCategoryList[accountingClass] = { items: [], label: this.$i18n.t('general.other') }
        }

        this.accountingCategoryList[accountingClass].items.push(item)
      })
    }
  }
}
</script>
