<template>
  <svg viewBox="0 0 20 20">
    <path fill-rule="evenodd"
          d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-1.75a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5ZM14 10l-6 4V6l6 4Z" />
  </svg>
</template>

<script>
export default {
}
</script>
