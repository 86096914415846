<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="usersList.length">
      <component-table data-cy="users.list"
                       class="table--hover table-utilisateurs">
        <thead>
          <tr>
            <th class="col--sm-4">
              {{ $t('table.user') }}
            </th>
            <th class="col--sm-3">
              {{ $t('table.function') }}
            </th>
            <th class="col--sm-3">
              {{ $t('table.permissions') }}
            </th>
            <th class="col--sm-1" />
            <th class="col--sm-1" />
            <th class="th-action" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in usersList"
              :key="item.id"
              class="is-link"
              @click="onRowClick(item)">
            <td class="col-nowrap">
              <div class="user-wrapper">
                <user-avatar :key="item.picture ? item.picture.id : null"
                             class="user-icon"
                             :size="40"
                             :uuid="item.uuid"
                             :picture="item.picture"
                             :placeholder-label="`${item.first_name} ${item.last_name}`" />
                <div class="user-details">
                  <div class="strong">
                    {{ item.first_name }} {{ item.last_name }}
                  </div>
                  <div class="text-muted">
                    {{ item.email }}
                  </div>
                </div>
              </div>
            </td>
            <td>{{ item.role_label || '-' }}</td>
            <td class="col-nowrap">
              <user-access :invitation="item"
                           :invitation-active="invitationIsValidated(item)" />
            </td>
            <td />
            <td>
              <core-badge v-if="!invitationIsValidated(item)"
                          class="mr-1"
                          v-bind="getBadgeProps(item.status)"
                          :value=" item.status_label" />
            </td>
            <td class="col-action"
                @click.stop>
              <template v-if="!isSectionRevoked">
                <span class="hover-item">
                  <template v-if="hasWritePermission">
                    <router-link v-tooltip="{ content: $t('tooltip.edit'), theme: 'tooltip' }"
                                 :data-cy="`users.list.item-${item.id}.edit`"
                                 class="btn-link"
                                 :to="{ name: isSectionActive ? 'user-edit' : 'user-invite-edit', params: { id: item.uuid || item.id } }">
                      <ic-pencil class="ic ic--24 ic--gray" />
                    </router-link>
                    <button v-if="invitationIsToValidate(item)"
                            v-tooltip="{ content: $t('tooltip.confirm_user'), theme: 'tooltip' }"
                            class="btn-link"
                            @click.prevent.stop="onValidationClick(item)">
                      <ic-check-circle outline
                                       class="ic ic--24 ic--gray" />
                    </button>
                    <button v-if="canRefreshInvitation(item)"
                            v-tooltip="{ content: $t('tooltip.refresh_invitation'), theme: 'tooltip' }"
                            class="btn-link hover-item-visible"
                            @click.prevent.stop="onRefreshClick(item.id)">
                      <ic-mail class="ic ic--24 ic--gray" />
                    </button>
                  </template>
                  <template v-else>
                    <button v-tooltip="{ content: $t('tooltip.more_informations'), theme: 'tooltip' }"
                            class="btn-link"
                            @click="onRowClick(item)">
                      <CoreIcon :name="ECoreIconList.View"
                                :size="24"
                                :theme="ECoreIconTheme.GrayLow" />
                    </button>
                  </template>
                </span>
              </template>
            </td>
          </tr>
        </tbody>
      </component-table>
      <component-pagination :pagination="usersListPagination" />
    </template>
    <component-placeholder v-else
                           :label="$t(`placeholder.no_user.title`)"
                           :content="$t(`placeholder.no_user.subtitle`)">
      <users-management />
    </component-placeholder>
  </div>
</template>

<script>
import { CoreBadge, CoreIcon, ECoreIconList, ECoreIconTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { getBadgeProps } from '@/helpers/utils/badge'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useInvitationStore } from '@/stores/invitation'
import { useUserStore } from '@/stores/user'

import ValidateUserInvitation from '@/pages/users/modal/ValidateUserInvitation.vue'
import UserDetail from '@/pages/users/sidepanel/UserDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import UsersManagement from '@/components/svg/img/users-management.vue'
import ComponentTable from '@/components/Table.vue'
import UserAccess from '@/components/UserAccess.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    CoreBadge,
    CoreIcon,
    IcPencil,
    IcMail,
    IcCheckCircle,
    UsersManagement,
    UserAvatar,
    UserAccess,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const invitationStore = useInvitationStore()
    const userStore = useUserStore()

    const { users, usersPagination } = storeToRefs(userStore)
    const { invitations, invitationsPagination, numberOfPendingInvitations } = storeToRefs(invitationStore)

    return { appStore, invitations, invitationsPagination, numberOfPendingInvitations, invitationStore, users, usersPagination, userStore }
  },

  data () {
    return {
      store,
      loading: true,
      ECoreIconList,
      ECoreIconTheme
    }
  },

  computed: {
    usersList () {
      return this.isSectionInvitation ? this.invitations : this.users
    },

    usersListPagination () {
      return this.isSectionInvitation ? this.invitationsPagination : this.usersPagination
    },

    isSectionActive () {
      return this.state === 'active'
    },

    isSectionInvitation () {
      return this.state === 'pending'
    },

    isSectionRevoked () {
      return this.state === 'revoked'
    },

    hasUsersWritePermission () {
      return this.hasPermission(this.$permissions.usersWrite)
    },

    hasInvitationsWritePermission () {
      return this.hasPermission(this.$permissions.invitationsWrite)
    },

    hasWritePermission () {
      return this.isSectionInvitation ? this.hasInvitationsWritePermission : this.hasUsersWritePermission
    }
  },

  watch: {
    state () {
      this.resetData()
    },

    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'usersListPagination.totalItems' () {
      this.$emit('pagination-total-items', this.usersListPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('validate-user-invitation', this.initData)
    this.$bus.on('refuse-user-invitation', this.initData)
    this.$bus.on('validate-user-reactivation', this.onReactivateUser)
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('language-changed', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('validate-user-invitation', this.initData)
    this.$bus.off('refuse-user-invitation', this.initData)
    this.$bus.off('validate-user-reactivation', this.onReactivateUser)
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('language-changed', this.initData)
  },

  methods: {
    getBadgeProps,

    async initData () {
      this.loading = true
      await this.invitationStore.getNumberOfPendingInvitations()
      if (this.isSectionInvitation) {
        if (this.numberOfPendingInvitations < 1) { return this.$router.push({ name: 'users' }) }
        await this.invitationStore.getInvitations(this.page, { ...this.filter, status: 'pending,expired' })
      } else {
        await this.userStore.getUsers(this.state, this.page, this.filter)
      }
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    invitationIsValidated (invitation) {
      return invitation.status === 'validated' || this.isSectionActive
    },

    invitationIsToValidate (invitation) {
      return invitation.status === 'to_validate'
    },

    invitationIsRefused (invitation) {
      return invitation.status === 'refused'
    },

    invitationIsDeleted (invitation) {
      return invitation.status === 'deleted'
    },

    invitationIsToValidateByBank (invitation) {
      return invitation.status === 'to_validate_by_bank'
    },

    canRefreshInvitation (invitation) {
      const refreshableStatus = ['pending', 'expired']
      return refreshableStatus.includes(invitation.status)
    },

    onValidationClick (invitation) {
      this.appStore.showModal(ValidateUserInvitation, { invitation }, { wrapperClass: 'modal--xs modal--white modal-validate-user' })
    },

    async onRefreshClick (invitationId) {
      const success = await this.invitationStore.refreshInvitation(invitationId)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.invitation.refresh_invitation') || 'Success')
        await this.initData()
      }
    },

    onRowClick (invitation) {
      if ((this.invitationIsToValidate(invitation) || this.invitationIsToValidateByBank(invitation)) && this.hasInvitationsWritePermission) {
        this.onValidationClick(invitation)
      } else {
        this.appStore.showSidePanel(UserDetail, { model: invitation }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
      }
    },

    onReactivateUser () {
      this.appStore.closeSidePanel()
      showToastSuccess(this.$i18n.t('message.success.user.reactivate'))
      this.initData()
    }
  }
}
</script>
