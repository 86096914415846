<template>
  <multiple-creation :title="$t('order_card.multiple.title')"
                     :helper-content="{
                       title: $i18n.t('order_card.multiple.helper.title'),
                       steps: [
                         'order_card.multiple.helper.first_step',
                         'order_card.multiple.helper.second_step',
                         'order_card.multiple.helper.third_step',
                         'order_card.multiple.helper.fourth_step'
                       ],
                       downloadLink: links.STATIC.FILES.TEMPLATE_ORDER_CARDS
                     }"
                     :placeholder-content="{
                       title: $t('placeholder.order_multiple_cards.title'),
                       subtitle: $t('placeholder.order_multiple_cards.subtitle')
                     }"
                     details-component="order-multiple-card-details"
                     type="cards" />
</template>

<script>
import links from '@/config/links'

import MultipleCreation from '@/components/multiple-creation/MultipleCreation.vue'

export default {
  components: {
    MultipleCreation
  },

  data () {
    return {
      links
    }
  }
}
</script>
