<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="mandates.length">
      <component-table class="table--hover">
        <thead>
          <tr>
            <th class="col-nowrap">
              {{ $t("table.add_date") }}
            </th>
            <th class="col--sm-4">
              {{ $t("table.mandate_name") }}
            </th>
            <th class="col--sm-2">
              {{ $t("table.frequency") }}
            </th>
            <th class="col--sm-4">
              {{ $t("table.comment") }}
            </th>
            <th class="center">
              {{ $t("table.document") }}
            </th>
            <th class="col-action" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="mandate in mandates"
              :key="mandate.id"
              class="is-link"
              @click="showDebitDetail(mandate)">
            <td class="text-muted nowrap">
              {{ formatDate(mandate.created_at) }}
            </td>
            <td>{{ mandate.label }}</td>
            <td>{{ $t(`form.mandate.debit_type.${mandate.unique ? 'unique' : 'periodic'}`) }}</td>
            <td>{{ mandate.comment || "-" }}</td>
            <td class="center">
              <button v-if="mandate.document"
                      v-tooltip="{ content: $tc('tooltip.show_file'), theme: 'tooltip' }"
                      class="btn-link btn-attachment"
                      @click.prevent.stop="showFile(mandate.document)">
                <ic-document class="ic ic--gray" />
              </button>
            </td>
            <td class="col-action">
              <span class="hover-item">
                <button v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                        class="btn-link"
                        @click.prevent.stop="onRemove(mandate)">
                  <ic-remove class="ic ic--gray" />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </component-table>
      <component-pagination :pagination="mandatesPagination" />
    </template>
    <component-placeholder v-else
                           :label="placeholderLabel"
                           :content="placeholderContent">
      <image-debit v-if="!filter.search" />
    </component-placeholder>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useDebitStore } from '@/stores/debit'

import MandateDetail from '@/pages/debits/sidepanel/MandateDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import ImageDebit from '@/components/svg/img/debit.vue'
import ComponentTable from '@/components/Table.vue'

export default {
  components: {
    IcDocument,
    IcRemove,
    ImageDebit,
    ComponentPagination,
    ComponentPlaceholder,
    ComponentTable,
    LoaderSpinner
  },

  props: {
    page: {
      type: Number,
      required: true
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const debitStore = useDebitStore()

    const { user } = storeToRefs(authStore)
    const { mandates, mandatesPagination } = storeToRefs(debitStore)

    return { appStore, user, mandates, mandatesPagination, debitStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  computed: {
    placeholderLabel () {
      return this.filter.search
        ? this.$i18n.t('placeholder.no_mandate_found.title')
        : this.$i18n.t('placeholder.no_mandate.title')
    },

    placeholderContent () {
      return this.filter.search ? '' : this.$i18n.t('placeholder.no_mandate.subtitle')
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'mandatesPagination.totalItems' () {
      this.$emit('pagination-total-items', this.mandatesPagination.totalItems)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('mandate-save-success', this.onSaveMandateSuccess)
    this.$bus.on('delete-success', this.onDeleteMandateSuccess)
  },

  beforeUnmount () {
    this.$bus.off('mandate-save-success', this.onSaveMandateSuccess)
    this.$bus.off('delete-success', this.onDeleteMandateSuccess)
  },

  methods: {
    formatDate,

    async initData () {
      this.loading = true
      await this.debitStore.getMandates(this.page, this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    onDeleteMandateSuccess () {
      showToastSuccess(this.$i18n.t('message.success.mandate.delete'))
      this.initData()
    },

    onSaveMandateSuccess () {
      showToastSuccess(this.$i18n.t('message.success.mandate.save'))
      this.initData()
    },

    onRemove (item) {
      this.appStore.showDeleteModal({ label: this.$i18n.t('prompt.mandate.delete.title'), content: this.$i18n.t('prompt.mandate.delete.content'), url: `/debit-mandates/${item.id}` })
    },

    showFile (file) {
      this.appStore.showFileViewer({
        content: [file],
        displayFullScreen: true
      })
    },

    showDebitDetail (item) {
      this.appStore.showSidePanel(MandateDetail, { model: item }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true })
    }
  }
}
</script>
