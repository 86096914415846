<template>
  <svg viewBox="0 0 25 25">
    <path d="M25 12.5a1 1 0 0 0-25 0 1 1 0 0 0 25 0m-1.5 0a1 1 0 0 1-22 0 1 1 0 0 1 22 0Z" />
    <path v-if="error"
          d="m9.141 8.081 3.359 3.358 3.359-3.358 1.06 1.06-3.358 3.359 3.358 3.359-1.06 1.06-3.359-3.358-3.359 3.358-1.06-1.06 3.358-3.359-3.358-3.359 1.06-1.06Z" />
    <path v-else
          d="m7.362 12.742 2.25 3.651.543.883.668-.792 6.75-8-1.146-.968-6.082 7.208-1.707-2.768Z" />
  </svg>
</template>

<script>
export default {
  props: {
    error: Boolean
  }
}
</script>
