<template>
  <line-chart :title="$t(`treasury.flow.${type}.title`)"
              :primary-color="primaryColor"
              :datasets="data"
              date-format="DD MMM YYYY"
              hide-point>
    <template #header>
      <span v-if="flow"
            class="header__right--title">
        {{ isTypeIn ? flow.totalInAmountFormatted : flow.totalOutAmountFormatted }}
      </span>
    </template>
    <template #filter>
      <chart-filter v-model="formFilter"
                    custom-period-type="day"
                    :max-custom-period="12"
                    :values="getFilter"
                    :child-data-cy="`treasury.flow-${type}.filter`" />
      <loader-spinner v-if="loading"
                      size="16" />
    </template>
  </line-chart>
</template>

<script>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import config from '@/config/config'
import { useTreasuryStore } from '@/stores/treasury'
import { ETreasuryFlowGranularity, ETreasuryFlowOperationType, ETreasuryPeriodFilter } from '@/types/treasury.d'

import ChartFilter from '@/components/charts/ChartFilter.vue'
import LineChart from '@/components/charts/LineChart.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  name: 'TreasuryFlowType',
  components: { LoaderSpinner, ChartFilter, LineChart },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  setup () {
    const treasuryStore = useTreasuryStore()
    const { filters } = storeToRefs(treasuryStore)

    return { filters, treasuryStore }
  },

  data () {
    return {
      ETreasuryFlowOperationType,
      loading: false,
      formFilter: {
        from: null,
        to: null,
        periodFilter: ETreasuryPeriodFilter.LastTwelveMonths
      },

      flow: null
    }
  },

  computed: {
    getFilter () {
      return Object.values(ETreasuryPeriodFilter).map(name => {
        return { label: `treasury.filter.${name}`, name }
      })
    },

    data () {
      return [{
        label: '',
        hoverBackgroundColor: this.secondaryColor,
        data: this.flow ? this.flow.operations.map((item, index) => ({ x: item.accountingAtFormatted, y: item.amount, xTooltip: this.xTooltip(item, index, this.flow.operations.length - 1), yTooltip: item.amountFormatted })) : []
      }]
    },

    isTypeIn () {
      return this.type === ETreasuryFlowOperationType.In
    },

    isTypeOut () {
      return this.type === ETreasuryFlowOperationType.Out
    },

    primaryColor () {
      return this.isTypeIn ? config.CHART.COLOR.GREEN_300 : config.CHART.COLOR.RED_200
    },

    secondaryColor () {
      return this.isTypeIn ? config.CHART.COLOR.GREEN_400 : config.CHART.COLOR.RED_300
    }
  },

  watch: {
    formFilter: {
      handler () {
        this.initData()
      },

      deep: true
    }
  },

  mounted () {
    this.initData()
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      this.loading = true

      const params = {
        type: this.type
      }
      if (this.formFilter.periodFilter === ETreasuryPeriodFilter.Custom) {
        params.from = dayjs(this.formFilter.from).format('YYYY-MM-DD')
        params.to = dayjs(this.formFilter.to).format('YYYY-MM-DD')
      } else {
        params.period_filter = this.formFilter.periodFilter
      }

      this.flow = await this.treasuryStore.getTreasuryFlow(params)
      this.loading = false
    },

    weekTooltip (item, position, positionMax) {
      let dateStart = item.accountingAtDayjs.startOf('week')
      let dateEnd = item.accountingAtDayjs.endOf('week')

      if (dateStart.month() !== dateEnd.month()) {
        if (position === 0) {
          dateStart = dateEnd.startOf('month')
        } else if (position === positionMax) {
          dateEnd = dateStart.endOf('month')
        }

        if (dateStart.isSame(dateEnd, 'day')) {
          return this.$tc('treasury.tooltip.date.the', 0, { date: dateStart.format('DD MMMM YYYY') })
        }

        return this.$tc('treasury.tooltip.date.from_to', 0, { date_start: dateStart.format('DD'), date_end: dateEnd.format('DD MMMM YYYY') })
      }

      return this.$tc('treasury.tooltip.date.week_from_to', 0, { date_start: dateStart.format('DD MMMM'), date_end: dateEnd.format('DD MMMM YYYY') })
    },

    xTooltip (item, position, positionMax) {
      switch (this.flow.granularity) {
        case ETreasuryFlowGranularity.Week:
          return this.weekTooltip(item, position, positionMax)
        case ETreasuryPeriodFilter.Month:
          return this.$tc('treasury.tooltip.date.from_to', 0, { date_start: item.accountingAtDayjs.startOf('month').format('DD'), date_end: item.accountingAtDayjs.endOf('month').format('DD MMMM YYYY') })
        default:
          return this.$tc('treasury.tooltip.date.the', 0, { date: item.accountingAtFormat('DD MMMM YYYY') })
      }
    }
  }
}
</script>
