<template>
  <modal-dialog-prompt :title="$t('modal.request_edit_limit.title')"
                       :subtitle="$t('modal.request_edit_limit.subtitle')"
                       @close="$emit('close')">
    <template #body>
      <textarea id="message"
                v-model="reason"
                class="form-control"
                rows="3"
                :placeholder="$t('form.message.placeholder')"
                name="message" />
    </template>
    <template #footerButtons>
      <button class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <component-button :label="$t('button.confirm')"
                        :disabled="loading"
                        wrapper-class="btn--primary"
                        @click="onSubmit" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { showToastSuccess } from '@/helpers/utils/notification'
import { useCardStore } from '@/stores/card'

import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ComponentButton,
    ModalDialogPrompt
  },

  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const cardStore = useCardStore()

    return { cardStore }
  },

  data () {
    return {
      loading: false,
      reason: ''
    }
  },

  methods: {
    async onSubmit () {
      this.loading = true
      const success = await this.cardStore.requestCardLimitIncrease(this.uuid, this.reason)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.message.send'))
        this.$emit('close')
      }
      this.loading = false
    }
  }
}
</script>
