<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'transfers' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ title }}
        </h1>
        <tabs v-if="!isExistingTransfer && hasFeature(EFeature.TransferCreationMultiple)"
              is-section
              :data="sections" />
      </div>
      <div v-if="hasFeature(EFeature.TransferDocumentUpload) && transfer.type !== 'periodic'"
           class="section__heading__right">
        <div class="section-actions">
          <a v-if="transfer.documents?.length"
             href="#"
             class="btn-link btn-link--attachment"
             @click.prevent="onShowDocuments">
            <i class="btn-icon btn-icon--left">
              <ic-document class="ic ic-document ic--gray" />
            </i>
            <span class="btn__label"
                  data-cy="create-transfer.document-label">{{ transferDocumentsLabel }}</span>
            <button class="btn-link btn-remove-file"
                    @click.prevent.stop="removeFiles">
              <ic-remove class="ic ic--18 ic--gray" />
            </button>
          </a>
          <button v-show="transfer.documents?.length === 0"
                  class="btn btn--default"
                  data-cy="create-transfer.add-document-button"
                  @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_proof'), {}, { type: 'transfer', canAnalyzeFile: true })">
            <i class="btn-icon btn-icon--left">
              <ic-attachment class="ic ic--white" />
            </i>
            {{ $t('button.attach_proof') }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loadingTransfer"
           class="section__loader">
        <loader-spinner />
      </div>
      <validation-form v-else
                       :key="account.uuid"
                       ref="form"
                       v-slot="{ errors, isSubmitting, meta }"
                       class="form form--centered form--w1000 form-wiretransfer"
                       :validation-schema="schema"
                       @submit="onSubmit(false)"
                       @keydown.enter.prevent>
        <component-alert v-if="Object.keys(errors).length && formSubmitted && !isSubmitting"
                         type="error"
                         :message="$t('form.alerts.common')" />
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.debit_account.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div class="form-group col col--sm-7">
                <label class="label input__label">{{ $t('form.debit_account.label') }}</label>
                <component-dropdown label="accounts"
                                    dropdown-width="100%"
                                    search
                                    async-url="/accounts"
                                    :async-url-params="{ permissions: 'transfers.write', status_group: 'valid' }"
                                    :label-filter="getAccountLabel"
                                    :model="account"
                                    :disabled="isExistingTransfer"
                                    @select="switchAccount">
                  <template #trigger>
                    <div class="select select-account">
                      <div class="form-control form-control--noborder">
                        {{ accountLabel }}
                      </div>
                      <span class="select-account__balance text-green">{{ formatAmount(account.balance, account.currency) }}</span>
                    </div>
                  </template>
                </component-dropdown>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.credit_account.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div class="form-group col col--sm-7"
                   :class="{ error: (beneficiaryMissing && transfer.beneficiary_id === '') || errors.beneficiary, 'is-valid': imported.beneficiary }">
                <label class="label input__label">{{ $t('form.credit_account.label') }}</label>
                <validation-field name="beneficiary"
                                  :model-value="transfer.beneficiary_id">
                  <component-dropdown id="dropdown-select-beneficiary"
                                      ref="beneficiary-dropdown"
                                      label="beneficiaries"
                                      dropdown-menu-class="dropdown-menu--noborder"
                                      dropdown-width="100%"
                                      search
                                      :disabled="isExistingTransfer"
                                      async-url="/beneficiary-lists?expand=beneficiaries"
                                      :model="transfer.beneficiary_id"
                                      :label-filter="getBeneficiaryLabel"
                                      :list-filter="beneficiaryListFilter"
                                      data-cy="select-beneficiary"
                                      @select="selectBeneficiary">
                    <template #trigger>
                      <div id="select-beneficiary"
                           class="select">
                        <div class="form-control form-control--noborder">
                          <span v-if="selectedBeneficiary.id">
                            <span class="vertical-align-middle">{{ selectedBeneficiary.label }} - {{ formatIban(selectedBeneficiary.iban) }}</span>
                            <span class="pull-right">
                              <core-badge v-if="isInternational"
                                          :theme="ECoreBadgeTheme.Currency"
                                          :size="ECoreBadgeSize.Small"
                                          :value="$t('general.no_sepa')" />
                              <span v-if="inconsistency.beneficiary"
                                    class="ml-2">
                                <ic-warning v-tooltip="{ content: $t('tooltip.beneficiary_inconsistency'), theme: 'tooltip' }"
                                            outline
                                            class="ic ic--18 ic--warning" />
                              </span>
                            </span>
                          </span>
                          <span v-else
                                class="text-muted">{{ $t('form.credit_account.placeholder') }}</span>
                        </div>
                      </div>
                    </template>
                  </component-dropdown>
                </validation-field>
              </div>
              <div v-if="hasPermission($permissions.beneficiariesWrite) && !isExistingTransfer"
                   class="form-group col col--sm-5">
                <button class="btn btn--default pull-right"
                        type="button"
                        @click.prevent="showCreateBeneficiary">
                  <i class="btn-icon btn-icon--left">
                    <ic-plus class="ic ic-plus ic--white" />
                  </i>
                  {{ $t('button.new_beneficiary') }}
                </button>
              </div>
            </div>

            <div v-if="beneficiaryEmail"
                 class="d-flex">
              <label class="form-check__label checkbox"
                     for="send_notification">
                <input id="send_notification"
                       v-model="transfer.send_notification"
                       type="checkbox"
                       name="send_notification">
                <span class="checkbox__icon" />
                {{ $t('form.checkbox_notification') }} {{ beneficiaryEmail }}
              </label>
              <component-popover popover-style="margin-top: 1.2rem"
                                 popover-class="popover-actions-menu popover-actions-menu__arrow"
                                 position-arrow-x="left"
                                 position-arrow-y="top"
                                 :close-on-click="true">
                <template #trigger>
                  <span class="strong is-link"
                        style="margin-left: 0.3rem;">
                    {{ attachDocumentLabel }}
                    <ic-chevron down
                                class="ic ic--16 ic--gray" />
                  </span>
                </template>
                <div class="popover__item"
                     :class="{ selected: transfer.attach_document === true }"
                     @click="transfer.attach_document = true">
                  {{ $t('form.attachment.filter.with_attachment') }}
                </div>
                <div class="popover__item"
                     :class="{ selected: transfer.attach_document === false }"
                     @click="transfer.attach_document = false">
                  {{ $t('form.attachment.filter.without') }}
                </div>
              </component-popover>
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.transfer_details.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row">
              <div class="form-group col"
                   :class="[editRefund ? 'col--sm-6' : 'col--sm-4', { error: errors.amount, 'is-valid': imported.amount }]">
                <label class="label input__label"
                       for="amount">{{ $t('form.amount.label') }}</label>
                <div class="input-group">
                  <number-input id="amount"
                                v-model="transfer.amount"
                                :placeholder="$t('form.amount.placeholder')"
                                name="amount"
                                class="form-control form-control--noborder input__field"
                                decimal
                                :minimum-fraction-digits="2"
                                data-cy="create-transfer.amount-input"
                                :disabled="isExistingTransfer && !hasFeature(EFeature.TransferAmountEdition)"
                                @update:model-value="onInput('amount')" />
                  <span v-if="inconsistency.amount"
                        class="input-group__addon"
                        :class="{ 'pr-0': !isInternational }">
                    <ic-warning v-tooltip="{ content: $t('tooltip.amount_inconsistency'), theme: 'tooltip' }"
                                outline
                                class="ic ic--warning ic--18" />
                  </span>
                  <span v-if="!isInternational"
                        class="input-group__addon">
                    {{ getCurrencySymbol(account.currency) }}
                  </span>
                </div>
              </div>
              <div v-if="editRefund"
                   class="form-group col col--sm-6"
                   :class="{ error: errors.expense_date }">
                <component-datepicker v-model="transfer.expense_date"
                                      name="expense_date"
                                      :disabled-days="disabledFrom"
                                      :label="$t('form.expense_date.label')"
                                      :language="$i18n.locale"
                                      format="dd/MM/yyyy"
                                      wrapper-class="form-group"
                                      input-class="form-control form-control--noborder"
                                      monday-first
                                      :readonly="false"
                                      :placeholder="$t('form.date.placeholder')" />
              </div>
              <div v-if="isInternational"
                   class="form-group col col--sm-2"
                   :class="{ error: errors.currency }">
                <label class="label input__label">{{ $t('form.currency') }}</label>
                <component-dropdown label="currencies"
                                    dropdown-menu-class="dropdown-menu--noborder"
                                    :dropdown-width="24"
                                    :values="transferCurrencies"
                                    :model="selectedCurrency"
                                    :label-filter="currencyFilter"
                                    dropdown-position="right"
                                    @select="onCurrencySelected">
                  <template #trigger>
                    <div class="dropdown-toggle select">
                      <div class="form-control form-control--noborder">
                        <span>{{ transfer.currency }}</span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
              <div v-if="transfer.currency !== 'EUR' && isInternational && !amountLoading"
                   class="form-group col col--sm-3">
                <label class="label input__label">&nbsp; </label>
                <div class="form-control form-control--transparent form-control--sm pl-0">
                  <VTooltip theme="poptip">
                    <template #popper>
                      <poptip :title="`1 EUR = ${exchange_rate} ${transfer.currency}`"
                              :message="$t('poptip.exchange_rate')" />
                    </template>
                    <component-info>
                      {{ $t('tooltip.approximate_change', { amount: formatAmount(amount_change, account.currency) }) }}
                    </component-info>
                  </VTooltip>
                </div>
              </div>
            </div>
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.label, 'is-valid': imported.label }">
                <label class="label input__label"
                       for="label">{{ $t('general.reason') }}</label>
                <div class="input-group">
                  <validation-field id="label"
                                    v-model="transfer.label"
                                    type="text"
                                    class="form-control form-control--noborder input__field"
                                    :placeholder="$t('form.transfer_details.beneficiary_placeholder')"
                                    name="label"
                                    data-cy="create-transfer.label-input"
                                    :disabled="isExistingTransfer && !hasFeature(EFeature.TransferLabelEdition)"
                                    @input="onInput('label')" />
                  <span v-if="inconsistency.label"
                        class="input-group__addon">
                    <ic-warning v-tooltip="{ content: $t('tooltip.label_inconsistency'), theme: 'tooltip' }"
                                outline
                                class="ic ic--warning ic--18" />
                  </span>
                </div>
              </div>
              <div v-if="hasFeature(EFeature.TransferComment)"
                   class="form-group col col--sm-6"
                   :class="{ error: errors.comment }">
                <label class="label input__label"
                       for="comment">
                  {{ $t('form.personal_note.label') }} ({{ $t('general.optional') }})
                </label>
                <input id="comment"
                       v-model="transfer.comment"
                       type="text"
                       class="form-control form-control--noborder input__field"
                       :placeholder="$t('form.personal_note.placeholder')"
                       name="comment"
                       :disabled="isExistingTransfer && !hasFeature(EFeature.TransferCommentEdition)">
              </div>
            </div>
            <div class="form__row row">
              <div v-if="hasFeature(EFeature.TransferCategory)"
                   class="form-group col col--sm-6">
                <label class="label input__label">{{ $tc("form.category.label") }} ({{ $t('general.optional') }})</label>
                <component-dropdown label="categories"
                                    dropdown-menu-class="dropdown-menu--noborder"
                                    multiselect
                                    dropdown-width="100%"
                                    search
                                    :can-add-item="hasPermission($permissions.categoriesWrite)"
                                    async-url="/categories"
                                    :async-url-params="{ filter: 'transfer' }"
                                    has-icon
                                    :model="transfer.categories"
                                    :disabled="isExistingTransfer && !hasFeature(EFeature.TransferCategoryEdition)"
                                    @select="onCategorySelected"
                                    @new-item="onNewItemAdded">
                  <template #trigger>
                    <div class="dropdown-toggle select">
                      <div class="form-control form-control--noborder">
                        <span v-if="transfer.categories.length">{{ categoriesLabel }}</span>
                        <span v-else
                              class="text-muted">{{ $t('form.category.placeholder') }}</span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
              <div v-if="isAffectationsInputVisible"
                   class="form-group col col--sm-6"
                   :class="{ error: errors.affectations }">
                <label class="label input__label">{{ $t("form.affectation.label") }}
                  <span>({{ $t('general.optional') }})</span>
                </label>
                <component-dropdown label="affectations"
                                    dropdown-menu-class="dropdown-menu--noborder"
                                    multiselect
                                    dropdown-width="100%"
                                    search
                                    :search-placeholder="$t('form.operations.new_affectation.placeholder')"
                                    :can-add-item="canWriteAffectations"
                                    async-url="/affectations"
                                    :model="transfer.affectations"
                                    :disabled="!canEditAffectations"
                                    @select="onAffectationsSelected"
                                    @new-item="onNewAffectationAdded">
                  <template #trigger>
                    <div class="dropdown-toggle select">
                      <div class="form-control form-control--noborder">
                        <span v-if="transfer.affectations.length">{{ affectationsLabel }}</span>
                        <span v-else
                              class="text-muted">{{ $t('form.affectation.placeholder') }}</span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
            </div>
            <category-form-fields v-if="hasFeature(EFeature.TransferCategoryDetail) && transfer.type !== 'periodic'"
                                  v-model:transfer="transfer"
                                  :errors="errors" />
            <div v-if="hasFeature(EFeature.TransferCreationType) && !editPeriodic && !editRefund"
                 class="form__row row">
              <div class="form-group col"
                   :class="[(!noSepaSelected && !isExistingTransfer) ? 'col--sm-6' : 'col--sm-4']">
                <label class="label input__label">{{ $t('form.transfer_details.method_label') }}</label>
                <div>
                  <div class="btn-group btn-group--noborder btn-group-method"
                       data-toggle="buttons">
                    <label class="btn"
                           :class="{ active: transfer.type === 'direct' }">
                      <input id="option1"
                             v-model="transfer.type"
                             type="radio"
                             name="options"
                             autocomplete="off"
                             value="direct">{{ $t('general.instant') }}
                    </label>
                    <label v-if="hasFeature(EFeature.TransferCreationDelayed)"
                           class="btn"
                           :class="{ active: transfer.type === 'delayed' }">
                      <input id="option2"
                             v-model="transfer.type"
                             type="radio"
                             name="options"
                             autocomplete="off"
                             value="delayed">{{ $t('general.scheduled') }}
                    </label>
                    <label v-if="hasFeature(EFeature.TransferPeriodic) && !noSepaSelected && !isExistingTransfer"
                           class="btn"
                           :class="{ active: transfer.type === 'periodic' }"
                           data-cy="create-transfer.type.periodic">
                      <input id="option3"
                             v-model="transfer.type"
                             type="radio"
                             name="options"
                             autocomplete="off"
                             value="periodic">{{ $t('general.periodic') }}

                    </label>
                  </div>
                </div>
              </div>
              <component-datepicker v-if="transfer.type === 'delayed'"
                                    v-model="transfer.execution_date"
                                    name="execution_date"
                                    :disabled-days="disabled"
                                    :label="$t('form.transfer_details.date_label')"
                                    :language="$i18n.locale"
                                    format="dd/MM/yyyy"
                                    :wrapper-class="[{ error: errors.execution_date }, 'form-group col col--sm-2']"
                                    input-class="form-control form-control--noborder"
                                    monday-first
                                    :readonly="false"
                                    :placeholder="$t('form.date.placeholder')" />
            </div>
            <div v-if="transfer.type === 'periodic'"
                 class="form__row row">
              <div class="form-group col col--sm-4"
                   :class="{ error: errors.period }">
                <label class="label input__label"
                       for="period">{{ $t('form.transfer_details.periodic.period') }}</label>
                <div class="select">
                  <validation-field v-model="transfer.period"
                                    name="period">
                    <component-dropdown id="period"
                                        dropdown-width="100%"
                                        data-cy="create-transfer.type.periodic.select"
                                        :model="transfer.period"
                                        :values="periodsOptions"
                                        :disabled="isExistingTransfer"
                                        @select="selectPeriod">
                      <template #trigger>
                        <div class="select">
                          <div class="form-control form-control--noborder">
                            <template v-if="!transfer.period">
                              <span class="text-placeholder">
                                {{ $t('form.common.select') }}
                              </span>
                            </template>
                            <template v-else>
                              {{ periodLabel(transfer.period) }}
                            </template>
                          </div>
                        </div>
                      </template>
                    </component-dropdown>
                  </validation-field>
                </div>
              </div>
              <component-datepicker v-model="formattedStartDate"
                                    name="start_date"
                                    :disable-input="isExistingTransfer"
                                    :disabled-days="disabled"
                                    :label="$t('form.transfer_details.periodic.date_from')"
                                    :language="$i18n.locale"
                                    format="dd/MM/yyyy"
                                    :wrapper-class="[{ error: errors.start_date }, 'form-group col col--sm-2']"
                                    icon-info
                                    input-class="form-control form-control--noborder"
                                    :poptip-title="$t('poptip.date_from.title')"
                                    :poptip-content="$t('poptip.date_from.content')"
                                    monday-first
                                    :readonly="false"
                                    :placeholder="$t('form.date.placeholder')"
                                    data-cy="create-transfer.type.periodic.start-date" />

              <component-datepicker v-model="formattedEndDate"
                                    name="end_date"
                                    :disabled-days="disabled"
                                    :label="$t('form.transfer_details.periodic.date_to')"
                                    :language="$i18n.locale"
                                    format="dd/MM/yyyy"
                                    wrapper-class="form-group col col--sm-2"
                                    icon-info
                                    input-class="form-control form-control--noborder"
                                    :poptip-title="$t('poptip.date_to.title')"
                                    :poptip-content="$t('poptip.date_to.content')"
                                    monday-first
                                    :readonly="false"
                                    :placeholder="$t('form.date.placeholder')"
                                    data-cy="create-transfer.type.periodic.end-date" />
              <div v-if="hasFeature(EFeature.TransferPeriodicUpcomingTransfers) && meta.valid"
                   class="form-group col col--sm-4">
                <label class="label">&nbsp;</label>
                <div class="form-control form-control--transparent">
                  <button class="btn-link text-muted strong btn-addon"
                          type="button"
                          @click="showUpcomingTransfers">
                    {{ $t('upcoming_transfers.button.manage_deadlines') }}
                    <ic-chevron right
                                class="ic ic--16" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset v-if="isInternational && hasFeature(EFeature.TransferCreationModeFees)"
                  class="form__fieldset">
          <fieldset-title :title="$t('form.transfer_details.mode_fees.title')"
                          show-number />
          <p class="form__fieldset__subtitle pre-line text-muted">
            {{ $t('form.transfer_details.mode_fees.subtitle') }}
          </p>
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div class="form-group col col--sm-7">
                <component-dropdown :model="transfer.mode_fees"
                                    label="mode_fees"
                                    dropdown-width="100%"
                                    :label-filter="modeFeesLabel"
                                    :values="modeFeesOptions"
                                    @select="selectMode">
                  <template #trigger>
                    <div class="select select-account">
                      <div class="form-control form-control--noborder">
                        {{ modeFeesLabel(transfer.mode_fees) }}
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
            </div>
            <component-info>
              {{ modeFeesDescription(transfer.mode_fees) }}
              <a v-if="transfer.mode_fees === 'OUR'"
                 class="btn-link--gray underline"
                 target="_blank"
                 href="https://www.manager.one/fr/tarifs">{{ $t('tooltip.see_more') }}
              </a>
            </component-info>
          </div>
        </fieldset>
        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'transfers' }">
            {{ $t('button.cancel') }}
          </router-link>
          <button type="submit"
                  class="btn btn--primary"
                  :disabled="isSubmitting"
                  data-cy="create-transfer.submit">
            {{ $t('button.validate') }}
          </button>
        </div>
      </validation-form>
    </div>
  </section>
</template>

<script>
import { CoreBadge, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import dayjs from 'dayjs'
import { cloneDeep, merge } from 'lodash'
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { EFeature, featureParams, hasFeature } from '@/config/features'
import { checkCompletedInputs, clearInputs } from '@/helpers/utils'
import { formatIban } from '@/helpers/utils/iban'
import { getCurrencyName } from '@/helpers/utils/intl'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount, getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useOperationStore } from '@/stores/operation'
import { useTransferStore } from '@/stores/transfer'

import SidepanelNewBeneficiary from '@/pages/transfers/sidepanel/NewBeneficiary.vue'
import UpcomingTransfers from '@/pages/transfers/sidepanel/UpcomingTransfers.vue'

import ComponentAlert from '@/components/Alert.vue'
import CategoryFormFields from '@/components/CategoryFormFields.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ComponentInfo from '@/components/Info.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Confirm from '@/components/modals/Confirm.vue'
import Leave from '@/components/modals/Leave.vue'
import NeedsValidation from '@/components/modals/NeedsValidation.vue'
import NumberInput from '@/components/NumberInput.vue'
import ComponentPopover from '@/components/Popover.vue'
import Poptip from '@/components/Poptip.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'
import Tabs from '@/components/Tabs.vue'

import FinishAnalyzeInvoice from './modal/FinishAnalyzeInvoice.vue'

export default {
  components: {
    CoreBadge,
    IcAttachment,
    IcDocument,
    IcWarning,
    IcRemove,
    IcChevron,
    IcArrow,
    IcPlus,
    Tabs,
    ComponentInfo,
    ComponentPopover,
    ComponentDatepicker,
    ComponentAlert,
    ComponentDropdown,
    CategoryFormFields,
    NumberInput,
    Poptip,
    LoaderSpinner,
    FieldsetTitle
  },

  beforeRouteLeave (to, from, next) {
    const nextPath = this.accountStore.getUserNextPathBasedOnPermissions(from)

    if (nextPath.path === from.path && checkCompletedInputs()) {
      next(false)
      this.appStore.showModal(Leave, { location: to }, { wrapperClass: 'modal--xs' })
    } else { next() }
  },

  props: {
    id: {
      type: [Number, String],
      default: null
    },

    isPeriodic: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()
    const operationStore = useOperationStore()
    const transferStore = useTransferStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { sidePanel } = storeToRefs(appStore)
    const { operationAffectations } = storeToRefs(operationStore)
    const { permanentTransfers, transfers, amount_change, exchange_rate, currencies } = storeToRefs(transferStore)

    return {
      accountStore,
      appStore,
      authStore,
      beneficiaryStore,
      transferStore,

      account,
      apiError,
      sidePanel,
      operationAffectations,
      operationStore,
      permanentTransfers,
      transfers,
      amount_change,
      exchange_rate,
      currencies
    }
  },

  data () {
    return {
      loadingTransfer: true,
      amountLoading: false,
      isIban: false,
      newBeneficiary: null,
      modeFeesOptions: ['SHA', 'BEN', 'OUR'],
      beneficiaryMissing: false,
      initialAccount: null,
      disabled: {
        to: new Date()
      },

      disabledTo: {
        to: new Date()
      },

      disabledFrom: {
        from: new Date()
      },

      transfer: {
        send_notification: true,
        attach_document: true,
        amount: '',
        currency: this.account.currency.iso,
        deleted_deadlines: [],
        label: '',
        comment: '',
        type: 'direct',
        beneficiary_id: '',
        execution_date: null,
        start_date: null,
        end_date: null,
        documents: [],
        categories: [],
        affectations: [],
        service: 'noon',
        guest: '',
        mode_fees: null,
        nights: '',
        fuel: null,
        liters: '',
        mileage: '',
        half_board_included: false,
        period: '',
        expense_date: null
      },

      beneficiary: {
        iban: '',
        bic: '',
        label: '',
        beneficiary_list_id: ''
      },

      address: {
        street: '',
        postcode: '',
        city: '',
        country: ''
      },

      selectedBeneficiary: {},
      formSubmitted: false,
      selectedCurrency: {},
      transferCurrencies: [],
      imported: {},
      inconsistency: {},
      analyzeResult: {},
      enterTimer: null
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    EFeature () {
      return EFeature
    },

    schema () {
      return yup.object({
        amount: yup.number().min(0.01).required(),
        expense_date: this.editRefund ? yup.date().required() : null,
        label: yup.string().required(),
        execution_date: this.transfer.type === 'delayed' ? yup.date().required() : null,
        period: this.transfer.type === 'periodic' ? yup.string().required() : null,
        start_date: this.transfer.type === 'periodic' ? yup.date().required() : null,
        guests: yup.number().when([], {
          is: () => this.transfer.type !== 'periodic' && this.transfer.categories.some(category => category.name === 'restaurant'),
          then: () => yup.number().integer().min(0).required()
        }),
        nights: yup.number().when([], {
          is: () => this.transfer.type !== 'periodic' && this.transfer.categories.some(category => category.name === 'hotel-logement'),
          then: () => yup.number().integer().min(0).required()
        }),
        beneficiary: !this.isExistingTransfer ? yup.number().required() : null
      })
    },

    formattedStartDate: {
      get () {
        return this.transfer.start_date ? new Date(this.transfer.start_date) : null
      },

      set (value) {
        if (dayjs(value).format('YYYY-MM-DD') !== this.transfer.start_date) {
          this.transfer.deleted_deadlines = []
          this.transfer.start_date = dayjs(value).format('YYYY-MM-DD')
        }
      }
    },

    formattedEndDate: {
      get () {
        return this.transfer.end_date ? new Date(this.transfer.end_date) : null
      },

      set (value) {
        this.transfer.end_date = dayjs(value).format('YYYY-MM-DD')
      }
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.single'),
          route: { name: 'transfers-create' },
          active: true
        },
        {
          label: this.$i18n.t('button.multiple'),
          route: { name: 'transfers-create-multiple' },
          active: false
        }
      ]
    },

    title () {
      return this.isExistingTransfer ? this.$t('title.edit_transfer') : this.$t('title.new_transfer')
    },

    isInternational () {
      return this.selectedBeneficiary?.is_sepa === false
    },

    isExistingTransfer () {
      return !!this.id
    },

    beneficiaryEmail () {
      return this.selectedBeneficiary?.email
    },

    attachDocumentLabel () {
      return this.transfer.attach_document
        ? this.$i18n.t('form.attachment.filter.with_attachment').toLowerCase()
        : this.$i18n.t('form.attachment.filter.without').toLowerCase()
    },

    editPeriodic () {
      return this.isExistingTransfer && this.isPeriodic
    },

    getTransferPayload () {
      const transfer = cloneDeep(this.transfer)

      delete transfer.documents
      delete transfer.execution_date

      if (transfer.type === 'delayed') {
        transfer.execution_date = dayjs(this.transfer.execution_date).format('YYYY-MM-DD')
      }

      if (transfer.type === 'periodic') {
        if (this.isExistingTransfer) { transfer.uuid = this.id }
        transfer.start_date = dayjs(this.transfer.start_date).format('YYYY-MM-DD')
        transfer.end_date = this.transfer.end_date ? dayjs(this.transfer.end_date).format('YYYY-MM-DD') : null
        delete transfer.categories
      } else {
        transfer.period = null
      }

      if (this.editRefund) {
        transfer.expense_date = dayjs(transfer.expense_date).format('YYYY-MM-DD')
      } else {
        delete transfer.expense_date
      }

      if (this.transfer.documents && this.transfer.documents.length) {
        transfer.documents = this.transfer.documents
      }

      transfer.send_notification = +transfer.send_notification
      transfer.categories = cloneDeep(this.transfer.categories)
      transfer.affectations = cloneDeep(this.transfer.affectations)

      if (!this.hasRestaurantCategory) {
        delete transfer.service
        delete transfer.guest
      }

      if (!this.hasHotelCategory) {
        delete transfer.nights
        delete transfer.half_board_included
      }

      if (!this.hasFuelStationCategory) {
        delete transfer.fuel
        delete transfer.liters
        delete transfer.mileage
      } else {
        transfer.fuel = transfer.fuel?.value
      }

      if (!hasFeature(EFeature.TransferDocumentUpload)) {
        delete transfer.mode_fees
      }
      if (!hasFeature(EFeature.TransferCategory)) {
        delete transfer.categories
      }
      if (!hasFeature(EFeature.TransferComment)) {
        delete transfer.comment
      }
      if (!hasFeature(EFeature.TransferAffectation)) {
        delete transfer.affectations
      }
      if (!hasFeature(EFeature.TransferCategoryDetail)) {
        delete transfer.service
        delete transfer.guest
        delete transfer.nights
        delete transfer.half_board_included
        delete transfer.fuel
        delete transfer.liters
        delete transfer.mileage
      }
      if (!hasFeature(EFeature.TransferCreationModeFees)) {
        delete transfer.mode_fees
      }

      return transfer
    },

    noSepaSelected () {
      return this.isInternational && this.selectedBeneficiary.id
    },

    accountLabel () {
      return this.account.label ? `${this.account.company.name} - ${this.account.label}` : this.account.company.name
    },

    transferDocumentsLabel () {
      return this.transfer.documents?.length === 1
        ? this.transfer.documents[0].filename || this.transfer.documents[0].name
        : `${this.transfer.documents.length} documents`
    },

    categoriesLabel () {
      if (!this.transfer.categories.length) { return '' }

      return this.transfer.categories.map(category => category.label).join(', ')
    },

    affectationsLabel () {
      if (!this.transfer.affectations.length) { return '' }

      return this.transfer.affectations.map(affectation => affectation.label).join(', ')
    },

    canEditAffectations () {
      return hasFeature(EFeature.TransferAffectationEdition)
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.transfersAffectationsRead)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    hasAffectations () {
      return this.transfer.affectations?.length
    },

    isAffectationsInputVisible () {
      return this.canEditAffectations || (this.canReadAffectations && this.hasAffectations)
    },

    hasRestaurantCategory () {
      return this.transfer.categories.some(category => category.name === 'restaurant')
    },

    hasHotelCategory () {
      return this.transfer.categories.some(category => category.name === 'hotel-logement')
    },

    hasFuelStationCategory () {
      return this.transfer.categories.some(category => category.name === 'station-essence')
    },

    editRefund () {
      return this.isExistingTransfer && this.transfer.type === 'refund'
    },

    periodsOptions () {
      const periods = featureParams(EFeature.TransferCreationPeriodic).periods
      return periods.map(period => {
        return {
          label: this.$i18n.t(`form.transfer_details.periodic.${period}`),
          value: period
        }
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    },

    'transfer.currency': function () {
      this.onAmountChange(this.transfer)
    },

    'transfer.amount': debounce(function () {
      this.onAmountChange(this.transfer)
    }, 1000),

    isInternational: function () {
      if (this.isInternational && this.transfer.type === 'periodic') {
        this.transfer.type = 'direct'
      }
      if (this.isInternational && this.transfer.amount) {
        this.onAmountChange(this.transfer)
      }
    },

    'transfer.start_date': function () {
      this.disabledTo.to = this.transfer.start_date
    }
  },

  async created () {
    this.loadingTransfer = true
    this.initialAccount = this.account
    await this.transferStore.getCurrencies().then(() => {
      this.selectedCurrency = this.currencies.find(currency => currency.iso === this.transfer.currency)
      this.currencies.forEach(currency => {
        const item = { ...currency }
        item.name = item.label
        this.transferCurrencies.push(item)
      })
    })

    if (!this.isExistingTransfer) {
      this.loadingTransfer = false
      return
    }

    let transfer = {}
    transfer = this.isPeriodic
      ? await this.transferStore.getPermanentTransfer(this.id)
      : await this.transferStore.getTransfer(this.id)

    if (!transfer) {
      clearInputs()
      this.$router.push({ name: 'transfers' })
      return
    }

    const currentTransfer = {
      id: transfer.id,
      send_notification: transfer.send_notification,
      label: transfer.label,
      comment: this.isPeriodic ? transfer.comment : transfer.operation.comment,
      type: transfer.type,
      documents: transfer.documents,
      mode_fees: transfer.mode_fees
    }
    if (transfer.operation?.detail) {
      Object.assign(currentTransfer, {
        nights: transfer.operation.detail.nights,
        service: transfer.operation.detail.service || 'noon',
        guest: transfer.operation.detail.guest,
        fuel: transfer.operation.detail.fuel,
        liters: transfer.operation.detail.liters,
        mileage: transfer.operation.detail.mileage,
        half_board_included: transfer.operation.detail.half_board_included
      })
    }

    Object.assign(this.transfer, currentTransfer)

    const transferCategories = transfer.operation?.detail?.categories || transfer.categories
    const transferAffectations = transfer.operation?.affectations || transfer.affectations

    if (transferCategories?.length) {
      this.transfer.categories = cloneDeep(transferCategories)
    }

    if (transferAffectations?.length) {
      this.transfer.affectations = cloneDeep(transferAffectations)
    }

    if (this.isPeriodic) {
      this.transfer.period = transfer.period
      this.transfer.start_date = transfer.start_date
      this.transfer.end_date = transfer.end_date
      this.transfer.deleted_deadlines = transfer.deleted_deadlines
    }

    this.transfer.amount = transfer.fx_amount
      ? transfer.fx_amount.toString()
      : transfer.amount.toString()

    this.transfer.currency = transfer.fx_currency
      ? (transfer.fx_currency ? transfer.fx_currency.iso : transfer.fx_currency)
      : this.account.currency.iso

    if (transfer.type !== 'direct') {
      this.transfer.execution_date = transfer.execution_date
    }

    this.selectedBeneficiary = transfer.beneficiary
    this.transfer.beneficiary_id = this.selectedBeneficiary.id

    if (this.editRefund) {
      this.transfer.expense_date = transfer.operation.expense_date
    }

    this.loadingTransfer = false
  },

  mounted () {
    this.$bus.on('similar', this.onSimilarTransfers)
    this.$bus.on('confirm-similar-transfer-create', this.onSimilarTransferCreateConfirm)
    this.$bus.on('create-new-beneficiary', this.onNewBeneficiaryCreated)
    this.$bus.on('on-file-attached', this.onDocumentsAttached)
    this.$bus.on('transfer-document-analyzed', this.onDocumentAnalyzed)
    this.$bus.on('overwrite-fields-confirm', this.onOverwriteFieldsConfirm)
    this.$bus.on('add-suggested-beneficiary-confirm', this.onAddSuggestedBeneficiaryConfirm)
    this.$bus.on('delete-category', this.onDeleteCategory)
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
    this.$bus.on('update-deleted-deadlines', this.updateDeletedDeadlines)
    this.$bus.on('account-switched', this.accountSwitched)
  },

  beforeUnmount () {
    this.$bus.off('similar', this.onSimilarTransfers)
    this.$bus.off('confirm-similar-transfer-create', this.onSimilarTransferCreateConfirm)
    this.$bus.off('create-new-beneficiary', this.onNewBeneficiaryCreated)
    this.$bus.off('on-file-attached', this.onDocumentsAttached)
    this.$bus.off('transfer-document-analyzed', this.onDocumentAnalyzed)
    this.$bus.off('overwrite-fields-confirm', this.onOverwriteFieldsConfirm)
    this.$bus.off('add-suggested-beneficiary-confirm', this.onAddSuggestedBeneficiaryConfirm)
    this.$bus.off('delete-category', this.onDeleteCategory)
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
    this.$bus.off('update-deleted-deadlines', this.updateDeletedDeadlines)
    this.$bus.off('account-switched', this.accountSwitched)
  },

  methods: {
    featureParams,

    hasFeature,

    formatIban,

    getCurrencySymbol,

    formatAmount,

    modeFeesLabel (mode) {
      return this.$i18n.t(`form.transfer_details.mode_fees.${mode.toLowerCase()}.label`)
    },

    modeFeesDescription (mode) {
      return this.$i18n.t(`form.transfer_details.mode_fees.${mode.toLowerCase()}.description`, { amount: formatAmount(20.00, this.account.currency) })
    },

    switchAccount (account) {
      this.accountStore.switchAccount(account.uuid)
    },

    onSwitchedAccount () {
      if (this.isExistingTransfer) {
        this.$router.push({ name: 'transfers' })
        return
      }
      this.selectedBeneficiary = {}
    },

    accountSwitched () {
      if (this.initialAccount !== this.account) {
        this.clearBeneficiary()
      }
      this.initialAccount = this.account
    },

    clearBeneficiary () {
      this.selectBeneficiary({})
    },

    /**
       * onSubmit
       */
    async onSubmit (force = false) {
      const transfer = cloneDeep(this.getTransferPayload)
      transfer.force = force

      if (this.isExistingTransfer) {
        const success = await this.transferStore.updateTransfer(transfer)
        if (success) this.onEditSuccess()
      } else {
        const data = await this.transferStore.saveTransfer(transfer)
        if (data) this.onCreateSuccess(data)
      }
    },

    onSimilarTransferCreateConfirm () {
      this.onSubmit(true)
    },

    onCategorySelected (category) {
      this.transfer.categories = [...category]
    },

    removeFiles () {
      this.transfer.documents = []
      this.removeImportedValues()
      this.analyzeResult = {}
      this.inconsistency = {}
    },

    getAccountLabel (account) {
      return account.label ? `${account.company.name} - ${account.label}` : account.company.name
    },

    getBeneficiaryLabel (beneficiary) {
      let label = `<div class="d-flex align-items-center"><span class="dropdown-menu__item__label__left">${beneficiary.label}<br>${formatIban(beneficiary.iban)}</span>`

      if (!beneficiary.is_sepa) {
        label += `<span class="dropdown-menu__item__label__right core-badge core-badge--theme-currency-solid core-badge--size-small">${this.$i18n.t('general.no_sepa')}</span>`
      } else if (this.isValidationPending(beneficiary)) {
        label += `<span class="dropdown-menu__item__label__right core-badge core-badge--theme-info-solid core-badge--size-medium">${this.$i18n.t('button.to_validate')}</span>`
      }

      label += '</div>'
      return label
    },

    selectBeneficiary (beneficiary, removeIsValid = true) {
      if (this.isValidationPending(beneficiary)) {
        return
      }

      if (this.imported.beneficiary && removeIsValid) {
        this.imported.beneficiary = false
      }

      this.inconsistency.beneficiary = this.analyzeResult.suggested_beneficiary?.iban && this.analyzeResult.suggested_beneficiary.iban !== beneficiary.iban
      this.selectedBeneficiary = beneficiary
      this.transfer.beneficiary_id = beneficiary.id

      if (this.isInternational) {
        this.transfer.mode_fees = 'SHA'
      } else {
        this.transfer.mode_fees = null
        this.transfer.currency = this.account.currency.iso
      }
    },

    async showUpcomingTransfers () {
      const valid = await this.validateForm()
      if (valid) {
        this.appStore.showSidePanel(
          UpcomingTransfers,
          {
            amount: parseInt(this.transfer.amount),
            currency: this.selectedCurrency,
            beneficiaryLabel: this.selectedBeneficiary.label,
            initialDeletedDeadlines: this.transfer.deleted_deadlines,
            endDate: this.transfer.end_date,
            period: this.transfer.period,
            startDate: this.transfer.start_date
          },
          {
            wrapperClass: 'sidepanel--xl',
            backgroundOverlay: true,
            initShowFile: true
          }
        )
      }
    },

    async onCreateSuccess (data) {
      clearInputs()
      if (data.approval_type && !this.hasPermission(this.$permissions.expenseRequestsItemsRead)) {
        await this.authStore.refreshUser()
      }
      const routeName = data.approval_type ? 'requests' : (data.type === 'periodic' ? 'transfers-periodic' : 'transfers')
      const isTransferToValidate = data.status === 'to_validate' || data.approval_type
      this.$router.push({ name: routeName })
        .then(() => {
          if (isTransferToValidate) {
            this.appStore.showModal(
              NeedsValidation,
              { type: data.approval_type ? 'request' : 'transfer' },
              { blockLeaveBackground: true }
            )
          }
        })
      if (!isTransferToValidate) {
        showToastSuccess(this.$i18n.t('message.success.transfer.create'))
      }
    },

    onEditSuccess () {
      clearInputs()
      showToastSuccess(this.$i18n.t('message.success.transfer.edit'))
      this.$router.push({ name: this.isPeriodic ? 'transfers-periodic' : 'transfers' })
    },

    async onAmountChange (transfer) {
      if (this.isInternational) {
        this.amountLoading = true
        await this.transferStore.updateAmountChange(transfer)
        this.amountLoading = false
      }
    },

    onDocumentsAttached ({ files, isAnalyzing = false }) {
      if (!this.sidePanel.active) { // prevent from loading new beneficiary rib document
        files.forEach(file => this.transfer.documents.push(file))
      }
      if (!isAnalyzing) {
        this.appStore.closeModal()
      }
    },

    onSimilarTransfers () {
      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.t('prompt.transfer.validate.title'),
          content: this.$i18n.t('prompt.transfer.validate.content_similar'),
          confirm: this.$i18n.t('button.validate'),
          event: 'similar-transfer-create'
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    isDateTransferValid () {
      if (!['periodic', 'delayed'].includes(this.transfer.type)) { return true }

      if (this.transfer.type === 'delayed') {
        if (!this.transfer.execution_date) {
          this.$refs.form.setFieldError('execution_date', 'incorrect_date')
          return false
        }
      } else if (this.transfer.type === 'periodic') {
        if ((Date.parse(this.transfer.start_date) > Date.parse(this.transfer.end_date)) || !this.transfer.start_date) {
          this.$refs.form.setFieldError('start_date', 'incorrect_date')
          return false
        }
      }

      return true
    },

    isBeneficiaryValid () {
      if (this.transfer.type === 'periodic' && this.isInternational) {
        this.$refs.form.setFieldError('beneficiary', 'incorrect_beneficiary')
        return false
      }

      return true
    },

    isValidationPending (beneficiary) {
      return beneficiary.validation?.status === 'pending'
    },

    showCreateBeneficiary (beneficiaryData = {}) {
      this.appStore.showSidePanel(
        SidepanelNewBeneficiary,
        {
          beneficiaryInitial: merge(cloneDeep(this.beneficiary), beneficiaryData),
          enableNoSepa: true
        },
        {
          wrapperClass: 'sidepanel--xl',
          backgroundOverlay: true,
          closeButton: false
        }
      )
    },

    async onNewBeneficiaryCreated (newBeneficiary) {
      this.newBeneficiary = newBeneficiary
      this.selectBeneficiary(this.newBeneficiary)
    },

    currencyFilter (currency) {
      return `<img src="${currency.flag_url}" class="ic-flag">${currency.iso} - ${getCurrencyName(currency.iso)}`
    },

    onCurrencySelected (currency) {
      this.selectedCurrency = currency
      this.transfer.currency = currency.iso
    },

    onShowDocuments () {
      this.appStore.showFileViewer({
        content: this.transfer.documents,
        isMultiDoc: true,
        canEdit: this.hasPermission(this.$permissions.operationsDocumentsWrite),
        isLocal: true,
        displayFullScreen: true
      })
    },

    async onNewItemAdded (itemLabel) {
      const newCategory = await this.operationStore.addCategory(itemLabel)
      if (newCategory) {
        this.transfer.categories.push(newCategory)
        this.$bus.emit('on-new-item-added', newCategory)
      }
    },

    onDeleteCategory (itemId) {
      const index = this.transfer.categories.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.transfer.categories.splice(index, 1)
      }
    },

    onAffectationsSelected (affectations) {
      this.transfer.affectations = [...affectations]
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.transfer.affectations.push(newAffectation)
        this.$bus.emit('on-new-item-added', newAffectation)
      }
    },

    onDeleteAffectation (itemId) {
      const index = this.transfer.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.transfer.affectations.splice(index, 1)
      }
    },

    onDocumentAnalyzed (result) {
      this.analyzeResult = result
      const askOverwrite = (result.amount && this.transfer.amount && result.amount !== this.transfer.amount) ||
        (result.label && this.transfer.label && result.label !== this.transfer.label) ||
        (this.selectedBeneficiary.id && result.suggested_beneficiary?.iban && this.selectedBeneficiary.iban !== result.suggested_beneficiary.iban)
      if (!askOverwrite) {
        this.onOverwriteFieldsConfirm(null)
      } else {
        this.appStore.showModal(
          FinishAnalyzeInvoice,
          {
            imported: { amount: !!result.amount, reference: !!result.label, beneficiary: !!result.beneficiary, suggested_beneficiary: !!result.suggested_beneficiary.iban },
            content: 'prompt.analyze_invoice.content.overwrite',
            question: 'prompt.analyze_invoice.question.overwrite',
            validate: 'button.yes',
            cancel: 'button.no',
            event: 'overwrite-fields-confirm'
          },
          { wrapperClass: 'modal-fixed-size' }
        )
      }
    },

    onOverwriteFieldsConfirm (overwrite = null) {
      const result = this.analyzeResult
      this.imported = {}
      if (result.amount && (!this.transfer.amount || overwrite)) {
        this.imported.amount = true
        this.transfer.amount = result.amount
        this.inconsistency.amount = false
      }
      if (result.label && (!this.transfer.label || overwrite)) {
        this.imported.label = true
        this.transfer.label = result.label
        this.inconsistency.label = false
      }
      if (result.beneficiary && (!this.selectedBeneficiary.id || overwrite)) {
        this.imported.beneficiary = true
        this.selectBeneficiary(result.beneficiary, false)
        this.inconsistency.beneficiary = false
      }
      if (overwrite === false) {
        this.inconsistency.amount = !!result.amount && this.transfer.amount !== result.amount
        this.inconsistency.label = !!result.label && this.transfer.label !== result.label
        this.inconsistency.beneficiary = (!!result.beneficiary?.iban && this.selectedBeneficiary.iban !== result.beneficiary.iban) || (!result.beneficiary?.id && result.suggested_beneficiary?.iban)
      }
      if (!result.beneficiary && result.suggested_beneficiary?.iban && (!this.selectedBeneficiary.id || overwrite)) {
        this.appStore.showModal(
          FinishAnalyzeInvoice,
          {
            imported: { suggested_beneficiary: !!result.suggested_beneficiary.iban },
            title: 'prompt.analyze_invoice.title.beneficiary_not_found',
            content: 'prompt.analyze_invoice.content.beneficiary_not_found',
            question: 'prompt.analyze_invoice.question.beneficiary_not_found',
            event: 'add-suggested-beneficiary-confirm'
          },
          { wrapperClass: 'modal-fixed-size' }
        )
      } else if (overwrite === null) {
        this.appStore.showModal(FinishAnalyzeInvoice, { imported: this.imported }, { wrapperClass: 'modal-fixed-size' })
      }
    },

    onAddSuggestedBeneficiaryConfirm (confirm) {
      if (confirm) {
        this.showCreateBeneficiary(this.analyzeResult.suggested_beneficiary)
        this.inconsistency.beneficiary = false
      }
    },

    removeImportedValues () {
      const importedValues = document.getElementsByClassName('is-valid')
      for (const el of importedValues) {
        const field = el.getElementsByTagName('input')[0]?.name || null
        if (field) {
          this.transfer[field] = ''
        }
        if (this.imported.beneficiary) {
          this.selectedBeneficiary = {}
          this.transfer.beneficiary_id = ''
        }
      }
      this.imported = {}
      // this.$nextTick(() => this.errors.clear())
    },

    onInput (key) {
      if (this.imported[key]) {
        this.imported[key] = false
      }
      this.inconsistency[key] = this.analyzeResult[key] && this.analyzeResult[key] !== this.transfer[key]
    },

    beneficiaryListFilter (list) {
      return list.reduce((acc, cur) => {
        if (cur.beneficiaries.length) {
          acc.push({
            label: cur.label,
            items: cur.beneficiaries.map(beneficiary => ({
              ...beneficiary,
              isDisabled: this.isValidationPending(beneficiary)
            }))
          })
        }
        return acc
      }, [])
    },

    selectMode (mode) {
      this.transfer.mode_fees = mode
    },

    updateDeletedDeadlines (dates) {
      this.transfer.deleted_deadlines = dates
    },

    async validateForm () {
      this.beneficiaryMissing = false

      if (this.transfer.type === 'periodic') {
        delete this.transfer.documents
      }

      const { valid } = await this.$refs.form.validate()

      const isTransferValid = this.isDateTransferValid() && this.isBeneficiaryValid()

      if (valid && (this.transfer.beneficiary_id !== '') && isTransferValid) {
        return true
      } else {
        if (this.transfer.beneficiary_id === '') {
          this.beneficiaryMissing = true
        }
        this.$bus.emit('scrollToTop')
        return false
      }
    },

    selectPeriod (period) {
      this.transfer.period = period.value
    },

    periodLabel (periodValue) {
      const periodOption = this.periodsOptions.find(option => option.value === periodValue)
      return periodOption ? periodOption.label : ''
    }
  }
}
</script>
