<template>
  <div class="content-wrapper">
    <form class="form form-personal-info form--w680 form--centered"
          @submit.prevent.stop>
      <h2 class="content-title">
        {{ $t('title.my_bank_details') }}
      </h2>
      <div v-if="existingBeneficiary"
           class="row">
        <div class="col col--sm-6">
          <label class="label input__label">{{ $t('general.iban') }}</label>
          <lock-input id="new-iban"
                      type="text"
                      input-class="form-control"
                      :placeholder="$t('form.your_iban.placeholder')"
                      name="new-iban"
                      :model-value="formatIban(userIban)"
                      disabled />
        </div>
        <div class="col col--sm-6">
          <label class="label input__label">{{ $t('general.bic_swift') }}</label>
          <div class="form-inline input-pencil-addon">
            <lock-input id="new-bic"
                        type="text"
                        input-class="form-control"
                        :placeholder="$t('form.beneficiary.bic_code.placeholder')"
                        name="new-bic"
                        :model-value="userBicSwift"
                        disabled />
            <button class="btn btn--icon btn--default"
                    type="button"
                    @click="editBankDetails">
              <i class="btn-icon">
                <ic-pencil class="ic ic--20 ic--white" />
              </i>
            </button>
          </div>
        </div>
      </div>
      <field-placeholder v-else
                         border="dashed"
                         :title="$t('form.refund.add_bank_details.title')"
                         :subtitle="$t('form.refund.add_bank_details.subtitle')"
                         @click="editBankDetails" />
    </form>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatIban } from '@/helpers/utils/iban'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import FieldPlaceholder from '@/components/FieldPlaceholder.vue'
import LockInput from '@/components/LockInput.vue'
import SidepanelEditBankDetails from '@/components/sidepanels/SidepanelEditBankDetails.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'

export default {
  components: {
    FieldPlaceholder,
    IcPencil,
    LockInput
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore }
  },

  computed: {
    existingBeneficiary () {
      return this.account.beneficiary
    },

    userIban () {
      return this.account.beneficiary?.iban || null
    },

    userBicSwift () {
      return this.account.beneficiary?.bic || null
    }
  },

  methods: {
    formatIban,

    editBankDetails () {
      this.appStore.showSidePanel(SidepanelEditBankDetails, {}, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true, closeButton: false })
    }
  }
}
</script>
