<template>
  <svg v-if="locale === 'fr'"
       id="Artwork"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 113.408 35.291"
       style="enable-background:new 0 0 113.408 35.291;"
       class="locale-fr"
       xml:space="preserve">
    <g>
      <g>
        <g>
          <path class="st0"
                d="M102.791,0H9.533C9.166,0,8.804,0,8.439,0.002C8.132,0.004,7.829,0.01,7.52,0.015
                C6.854,0.031,6.181,0.072,5.516,0.191C4.847,0.309,4.225,0.509,3.616,0.818C3.017,1.125,2.47,1.523,1.997,1.997
                C1.519,2.47,1.122,3.019,0.819,3.618C0.508,4.226,0.311,4.851,0.194,5.521C0.074,6.183,0.032,6.852,0.015,7.522
                C0.006,7.829,0.005,8.136,0,8.443c0,0.362,0,0.725,0,1.092v14.948c0,0.369,0,0.73,0,1.094c0.005,0.31,0.006,0.611,0.015,0.922
                c0.017,0.67,0.059,1.34,0.179,2.002c0.117,0.67,0.314,1.298,0.625,1.904c0.303,0.596,0.7,1.144,1.178,1.614
                c0.473,0.477,1.02,0.875,1.618,1.178c0.609,0.312,1.231,0.51,1.901,0.631c0.664,0.119,1.338,0.158,2.004,0.177
                c0.309,0.007,0.613,0.011,0.919,0.011c0.366,0.002,0.728,0.002,1.095,0.002h93.258c0.359,0,0.724,0,1.084-0.002
                c0.305,0,0.617-0.004,0.922-0.011c0.67-0.018,1.342-0.058,2-0.177c0.67-0.121,1.293-0.318,1.908-0.631
                c0.597-0.304,1.144-0.701,1.617-1.178c0.476-0.47,0.873-1.018,1.181-1.614c0.307-0.606,0.506-1.234,0.619-1.904
                c0.123-0.662,0.162-1.332,0.186-2.002c0.004-0.311,0.004-0.611,0.004-0.922c0.008-0.363,0.008-0.724,0.008-1.094V9.534
                c0-0.366,0-0.729-0.008-1.092c0-0.307,0-0.614-0.004-0.921c-0.023-0.67-0.062-1.34-0.186-2.002
                c-0.113-0.67-0.312-1.295-0.619-1.903c-0.309-0.599-0.705-1.147-1.181-1.621c-0.473-0.474-1.019-0.872-1.617-1.179
                c-0.615-0.31-1.238-0.51-1.908-0.627c-0.658-0.119-1.33-0.16-2-0.177c-0.305-0.005-0.617-0.011-0.922-0.013
                C103.516,0,103.151,0,102.791,0L102.791,0z" />
        </g>
        <path d="M102.791,0.65c0.358,0,0.722,0,1.08,0.002c0.246,0.002,0.498,0.006,0.746,0.01l0.164,0.003
              c0.529,0.013,1.222,0.044,1.904,0.167c0.619,0.108,1.184,0.294,1.723,0.565c0.539,0.277,1.028,0.633,1.455,1.061
              c0.423,0.42,0.78,0.91,1.059,1.452c0.271,0.539,0.454,1.101,0.56,1.729c0.117,0.63,0.153,1.288,0.175,1.891
              c0.004,0.304,0.004,0.609,0.004,0.926c0.008,0.358,0.008,0.716,0.008,1.078v14.948c0,0.364,0,0.721-0.008,1.094
              c0,0.308,0,0.606-0.004,0.899c-0.022,0.617-0.058,1.275-0.177,1.916c-0.105,0.622-0.288,1.184-0.555,1.713
              c-0.284,0.549-0.641,1.037-1.066,1.456c-0.423,0.427-0.91,0.782-1.449,1.056c-0.531,0.27-1.097,0.456-1.729,0.571
              c-0.655,0.119-1.329,0.151-1.898,0.167c-0.3,0.007-0.607,0.011-0.911,0.011c-0.358,0.002-0.722,0.002-1.08,0.002H9.533
              c-0.365,0-0.726,0-1.095-0.002c-0.301,0-0.6-0.004-0.901-0.01c-0.572-0.016-1.245-0.048-1.906-0.167
              c-0.633-0.114-1.196-0.301-1.722-0.571c-0.539-0.273-1.027-0.629-1.457-1.063c-0.422-0.415-0.777-0.901-1.055-1.447
              c-0.268-0.523-0.453-1.086-0.564-1.724c-0.124-0.681-0.155-1.374-0.169-1.904c-0.006-0.208-0.009-0.411-0.011-0.616L0.65,25.576
              v-1.094V9.534l0-1.081l0.004-0.299C0.656,7.95,0.659,7.746,0.665,7.539c0.013-0.528,0.045-1.221,0.17-1.907
              c0.11-0.63,0.294-1.192,0.564-1.721c0.275-0.543,0.63-1.032,1.058-1.455C2.883,2.029,3.373,1.673,3.91,1.398
              C4.446,1.125,5.008,0.94,5.631,0.831C6.315,0.708,7.009,0.677,7.53,0.664l0.198-0.003c0.239-0.004,0.476-0.008,0.714-0.009
              C8.806,0.65,9.168,0.65,9.533,0.65H102.791" />
      </g>
      <g>
        <g>
          <g>
            <defs>
              <path id="SVGID_1_"
                    d="M33.265,23.054l0,0.265c0,0.127,0,0.254-0.001,0.381c-0.001,0.107-0.002,0.214-0.005,0.321
                    c-0.006,0.233-0.02,0.468-0.061,0.698c-0.042,0.234-0.111,0.452-0.219,0.664c-0.106,0.209-0.245,0.4-0.411,0.565
                    c-0.166,0.166-0.357,0.304-0.565,0.411c-0.212,0.108-0.43,0.177-0.664,0.219c-0.23,0.041-0.465,0.055-0.698,0.061
                    c-0.107,0.003-0.214,0.004-0.321,0.005c-0.127,0.001-0.254,0.001-0.381,0.001l-1.459-0.001l1.358,0.001H10.984l1.358-0.001
                    l-1.459,0.001c-0.127,0-0.254,0-0.381-0.001c-0.107-0.001-0.214-0.002-0.321-0.005c-0.233-0.006-0.468-0.02-0.698-0.061
                    c-0.234-0.042-0.451-0.111-0.664-0.219c-0.209-0.106-0.4-0.245-0.565-0.411c-0.166-0.166-0.304-0.357-0.411-0.565
                    c-0.108-0.212-0.177-0.43-0.219-0.664c-0.041-0.23-0.055-0.465-0.061-0.698C7.56,23.914,7.559,23.807,7.558,23.7
                    c-0.001-0.127-0.001-0.254-0.001-0.381l0-0.208V10.899l0,1.25l0-1.459c0-0.127,0-0.254,0.001-0.381
                    c0.001-0.107,0.002-0.214,0.005-0.321c0.006-0.233,0.02-0.468,0.061-0.698c0.042-0.234,0.111-0.452,0.219-0.664
                    c0.106-0.209,0.245-0.4,0.411-0.565C8.42,7.896,8.611,7.757,8.82,7.651c0.212-0.108,0.43-0.177,0.664-0.219
                    c0.23-0.041,0.465-0.055,0.698-0.061c0.107-0.003,0.214-0.004,0.321-0.005c0.127-0.001,0.254-0.001,0.381-0.001l1.459,0h17.496
                    l0.1,0c0.127,0,0.254,0,0.381,0.001c0.107,0.001,0.214,0.002,0.321,0.005c0.233,0.006,0.468,0.02,0.698,0.061
                    c0.234,0.042,0.451,0.111,0.664,0.219c0.209,0.106,0.4,0.245,0.565,0.411c0.166,0.166,0.304,0.357,0.411,0.565
                    c0.108,0.212,0.177,0.43,0.219,0.664c0.041,0.23,0.055,0.465,0.061,0.698c0.003,0.107,0.004,0.214,0.005,0.321
                    c0.001,0.127,0.001,0.254,0.001,0.381l0,0.265V23.054z" />
            </defs>
            <clipPath id="SVGID_00000090297036075047184360000012927945779280579721_">
              <use xlink:href="#SVGID_1_"
                   style="overflow:visible;" />
            </clipPath>
            <g style="clip-path:url(#SVGID_00000090297036075047184360000012927945779280579721_);">
              <rect x="8.351"
                    y="8.168"
                    class="st2"
                    width="24.1"
                    height="16.781" />
              <rect x="8.629"
                    y="8.436"
                    class="st3"
                    width="23.565"
                    height="9.997" />
              <g>

                <image style="overflow:visible;opacity:0.17;"
                       width="109"
                       height="53"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAApVJREFUeNrsnEtP20AURv2YAEkf
0FIBaiuxqbro//9BrdQFoBBVVaCQh9076jdkQiEegW2yOEf6ZOLEWfjkzrWRfLMMAABaIO/5OHga
dVsy8mgbB7qVdz9Pkhg+U1hKxel1gchOBVaWpWWh7fIhmXmCwFziBpY9y1DbXcmkIrsT6OXdWq6V
W+2rYpF5gkAnaa8tB5b32vrXOxJMn2y//wWBvy2XlrHll2SuiXQNX1hKoJd2YvmkHFn29V6JwE5E
LiTMC/xp+R4VjN8/b5KYq98NVHFe4BfLV8up5djyVksqEruVONbqt6v3Qm8MlVi7hCp8Z/ksgd8k
8dAykmR6Ync98UYCh9of+uMfyyxc6GyqxFIHe4kfJe9Uf7/RL4Or0+6oVCihAr24ieVcS+xUS2ru
Gi5ohuqHR1pCDyVwGF2ZQnfVGHqgd/BB5/+/NtbUE3fUE/d18Ci6tSg4z50SCiTcHYyUPbWxu1Ww
aJDoJDLcFw5YQnsXWUQuXFSBdw6KxBv98qGDoVeZj/67s3jOwbAd0NeQCEgEJAISkQhIBCQCEpEI
SAQkAhKRCEgEJAISkQhIBCQCEpEISAQkAhKRCEgEJEI6LvFzSUNxoHWSzrtL+JIqWx+Ks9B7PCHV
j8RwzpeRizpVYhDonwv3AwCusn/PiYfjkNifxKnO/418rIl0DQJnOtg/8H8mcf6RY8ae9CfRV+C1
zv9EPmZZwjCi8AvwExv8HJUf2n+RrQYQQT8sVYETeRjLy2KTxPpeGV9ov/8SP4SB2TX9X9jM5eJS
PqaRxPoxEfH0DD/u5FW2Gj7EUvoyS+pcy+pVthpGVG2SGIt0UZic8XIiq+hKdW1AX+qoTAYvbPE9
Y6oU5G2XTAAAaJ+/AgwAuTyv8S7NBjkAAAAASUVORK5CYII="
                       transform="matrix(0.24 0 0 0.24 7.2978 9.0541)" />
                <g>
                  <path class="st4"
                        d="M32.193,12.688c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                        c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.261-0.359
                        c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                        c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.066,0H11.664l-0.925,0c-0.081,0-0.161,0-0.242,0
                        c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                        c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.261,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                        c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                        h23.565v-7.641L32.193,12.688z" />
                </g>
              </g>
              <g>

                <image style="overflow:visible;opacity:0.16;"
                       width="109"
                       height="52"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAo1JREFUeNrsnFtvEzEQhb27DqQp
UFqgSNyE6APi//8hkPoAVRshlJY2l13G9Lg4oc0a2t3m4TvSUSTv2g/+MjNOJI9zCCGEEAoqepqD
bqfmroAU1xh1D2/V/wUxAqtkr88SkJ0DrM0L81yfi+tgFpkAA7iheSQ/1BgguwUY4F2Yz+QLjdUp
yCID4MC8ZX5qfi4/SUBSJ7upfxHgD/OJ+dj8XTCXQPqWBStF4K75jfm9+a1AjjQfgN2AnAtYAHho
/iweTuOzNoiFUmWIwkfml+YD8yfzB/MLIPYGMUTgnjKfS2pjjMTGZ0RhSKOvBfGjonFPz6iJ3dbE
c+31lsZjffxpnsaDzrpIrDQ5LPLK/E4w982PFaUl+92Z6uQQ6QRubP6mFDtRSi18y4k0QNxR+txX
bdzWwhVR2Hk0xhoYD5XPkkPl1f631cQHqok7ir6RxiqisHPFAFn9eTdMsuDvd8oWiF7QhqI/oA72
DrJMWPgkAq8YlP/wT81fk1GvMG/8y7O8zWS0GaKuAREBEQERARGICIgIiAiIQERAREBEQAQiAiIC
IgIiEBEQERAREIGIgIiAiIAIRAREBESUL5/5XlZTHHTnytp3n7FI7Zab4sz1jBtS/UCMe75IWDS5
ECPAcC88NAA4dZf3xOM8IPYHcaL9PxePJZC+BeBUk8OF/68CF64cc1+/P4ghAs+0/2PxmLqMZkTx
GxA6NoQ+Kl80fuQur35X7G9vWigCx+JwLC7zdRCblTA+0nhYJDRhGDhuDfd9sJmJxYl4TBKIzU0g
0u4ZoQ3KttLogFR6byl1prR66v40I6rXQUxB+sR0zrg/kHVyUl1q0JfbKpPGCxv8mzEXCvA2CyZC
aOP0S4ABAPhzr8F+uSRRAAAAAElFTkSuQmCC"
                       transform="matrix(0.24 0 0 0.24 7.2978 11.2141)" />
                <g>
                  <path class="st5"
                        d="M32.193,14.83c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                        c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.261-0.359
                        c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                        c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.664l-0.925,0c-0.081,0-0.161,0-0.242,0
                        c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                        c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.261,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                        c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                        h23.565v-7.641L32.193,14.83z" />
                </g>
              </g>
              <g>

                <image style="overflow:visible;opacity:0.17;"
                       width="109"
                       height="52"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAo1JREFUeNrsnFtvEzEQhb27DqQp
UFqgSNyE6APi//8hkPoAVRshlJY2l13G9Lg4oc0a2t3m4TvSUSTv2g/+MjNOJI9zCCGEEAoqepqD
bqfmroAU1xh1D2/V/wUxAqtkr88SkJ0DrM0L81yfi+tgFpkAA7iheSQ/1BgguwUY4F2Yz+QLjdUp
yCID4MC8ZX5qfi4/SUBSJ7upfxHgD/OJ+dj8XTCXQPqWBStF4K75jfm9+a1AjjQfgN2AnAtYAHho
/iweTuOzNoiFUmWIwkfml+YD8yfzB/MLIPYGMUTgnjKfS2pjjMTGZ0RhSKOvBfGjonFPz6iJ3dbE
c+31lsZjffxpnsaDzrpIrDQ5LPLK/E4w982PFaUl+92Z6uQQ6QRubP6mFDtRSi18y4k0QNxR+txX
bdzWwhVR2Hk0xhoYD5XPkkPl1f631cQHqok7ir6RxiqisHPFAFn9eTdMsuDvd8oWiF7QhqI/oA72
DrJMWPgkAq8YlP/wT81fk1GvMG/8y7O8zWS0GaKuAREBEQERARGICIgIiAiIQERAREBEQAQiAiIC
IgIiEBEQERAREIGIgIiAiIAIRAREBESUL5/5XlZTHHTnytp3n7FI7Zab4sz1jBtS/UCMe75IWDS5
ECPAcC88NAA4dZf3xOM8IPYHcaL9PxePJZC+BeBUk8OF/68CF64cc1+/P4ghAs+0/2PxmLqMZkTx
GxA6NoQ+Kl80fuQur35X7G9vWigCx+JwLC7zdRCblTA+0nhYJDRhGDhuDfd9sJmJxYl4TBKIzU0g
0u4ZoQ3KttLogFR6byl1prR66v40I6rXQUxB+sR0zrg/kHVyUl1q0JfbKpPGCxv8mzEXCvA2CyZC
aOP0S4ABAPhzr8F+uSRRAAAAAElFTkSuQmCC"
                       transform="matrix(0.24 0 0 0.24 7.2978 13.3741)" />
                <g>
                  <path class="st6"
                        d="M32.193,16.972c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                        c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.261-0.359
                        c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                        c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.664l-0.925,0c-0.081,0-0.161,0-0.242,0
                        c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                        c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.261,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                        c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                        h23.565v-7.641L32.193,16.972z" />
                </g>
              </g>
              <path class="st7"
                    d="M7.201,7.008v11.068h1.428v-6.605l0-0.925c0-0.081,0-0.161,0-0.242c0-0.068,0.001-0.136,0.003-0.204
                    c0.004-0.148,0.013-0.297,0.039-0.443c0.027-0.148,0.07-0.286,0.139-0.421c0.067-0.132,0.155-0.254,0.261-0.359
                    c0.105-0.105,0.226-0.193,0.359-0.261C9.564,8.549,9.702,8.505,9.85,8.479c0.146-0.026,0.295-0.035,0.443-0.039
                    c0.068-0.002,0.136-0.003,0.204-0.003c0.081,0,0.161,0,0.242,0l0.925,0h18.349l0.071,0c0.081,0,0.161,0,0.242,0
                    c0.068,0,0.136,0.001,0.204,0.003c0.148,0.004,0.297,0.013,0.443,0.039c0.148,0.027,0.286,0.07,0.421,0.139
                    c0.132,0.067,0.254,0.155,0.359,0.261c0.105,0.105,0.193,0.226,0.261,0.359c0.069,0.135,0.112,0.273,0.139,0.421
                    c0.026,0.146,0.035,0.295,0.039,0.443c0.002,0.068,0.003,0.136,0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925v6.605h1.428V7.008
                    H7.201z" />
              <g>

                <image style="overflow:visible;opacity:0.14;"
                       width="121"
                       height="52"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAA2CAYAAADqH5aKAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABIpJREFUeNrsnYlS20AMhtcHSSCB
cJYrtNNQaKfv/0KdzrSUcrYORy53d/hV1K0vIO3ajjSjccYQs6tP0spKvCglMnfivfDnriSukA1L
ZyMv47ytZZkIV1cO4KXYqYw2iotAN+d8rSFTvwSTMoOfQsdaJ9Dpf4RPNjD2CKBknzLZaMx0atsm
TAHe0Lqota11SesCJug59l4ziXutd1pvcRwB/r8GT9EcwB4t2Mgcm7Cl59hGE9jjRusANhra4JOg
h5jMptZXWje0djBRF5OiwU4wgUjrldZLHCPAT/TqGQKn7NeCPVa1ruHYQaAEjtZ5CooR7HGu9VTr
GWwySoPuMegdAD/S+kbrOiYbOPZiA/da63etX6DfAP8Gk5s1eAK+gKxnIG9r3YNuae0y+7iKdLLP
hdZPOHeHzDjGuOIikW6Af9C6iwm7mhRBNxP4CS/+nGDswYzBc+BtOH9P61utfa0HCI5lpHjX9jGO
/xXnLhEcoT2mrDWdJrmjdR/RHzqcFKWvW0TXGsZkG3tW4G3gZpl7jez3HtD3MI5Fh8ufYvVOhNen
GHMjqcAMcybbwps78OZQuS1UphhLG4ZuWmMi5yDwzy3u+FK3BOc3wI+1fgT4HhyBCl3fsW3GeN0G
t9QxhTle7rPbktAxdJocjSOwIjxmt3F0Kzd5AfQAxlsF4CMAP4YDbMDAroFzxya7ZN5ChgU9vixN
iLSxkKcPse6PWGETPxE8ObzJIitY3vpI6UcAvlki4OqpjaJQVU/stZbS/hCFTITjkEV60fU9aR03
xdoh9KBkEf4sqSL0JDhU2Q9Q3Q9YUyKyCrs4I0romh3A7SHK+3i9XnXgVYaeFZURKnwqbALmBBP1
d0/aY7VLA4UZVerv6hThdYCetv7eM+ALqPIvAJ5+xnv2dqdtHbeoh+hR9HHdFfydSgOvA3S70l4D
UI9lgFV07S6R+vl6rxjwFRRou4jyQwDfx3Vdd9wEugVdsXvqTcBpomNnOmYn6qEPbSL+moH3kNI7
eN8O1u4eYG8D+JJy/4GKQE9J8wSep/wtwD7H8QoRP8R7myzKd+AkvOPXUuX5eFmgZxR2PlvPu4hY
U+D9YCmeOnbUcu5iKeiyrlaDpXSvLoaqE3Q71fMCbZk1bu6sNZ2WAvp8vKkevz/gW9cV6CUH71u3
Yi31Z4t2av2e3dr16ga7ztBt+AHuySnlJ92n25C9Gtul1tBtB+B9+iwnqb3MC/S5hJsmvhIR6CIC
XUSgiwh0EYEuItBFBLqIQBcR6CICXUSgiwh0EYEuItBFBLqIQBcR6PMoeV+Xyt2ITqQU8iROYcZF
6GvC5ivDtBGdEU9sXEroxIh/xTsuCp2AmydAzIMB5mnPiP2+QC8v9Ai8aFPFRPBhCvAh3mye/ToB
aJdbionkQ6ctxU7U46PZQ1Vgm1DyGPNQv3nKkzahM1tUudw8UCRf7M0Dz9Tj5gyp0GMrTZzivLmI
y21CRYoXcvY2oRGD/hu8DbCsGwKLFE/xuRsCV2nrb5Fi4HO3/q7aJv8iM7hnr+q/8xApBl9E5EF+
CTAA7F5RGov2NqQAAAAASUVORK5CYII="
                       transform="matrix(0.24 0 0 0.24 5.8578 15.5341)" />
                <g>
                  <path class="st8"
                        d="M26.985,17.005c-0.153,0-0.307,0-0.46,0.001c-0.129,0.001-0.258,0.002-0.387,0.006
                        c-0.281,0.008-0.565,0.024-0.843,0.074c-0.282,0.051-0.545,0.134-0.801,0.264c-0.033,0.017-0.738,0.337-1.372,1.323
                        c-0.481,0.749-1.417,1.543-2.728,1.543c-1.31,0-2.246-0.794-2.728-1.543c-0.667-1.039-1.428-1.351-1.373-1.323
                        c-0.256-0.131-0.519-0.214-0.801-0.264c-0.278-0.05-0.562-0.067-0.843-0.074c-0.129-0.003-0.258-0.005-0.387-0.006
                        c-0.153-0.001-0.307-0.001-0.46-0.001l-6.6,0v9.997h26.421v-9.997L26.985,17.005z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <path class="st9"
                d="M38.605,21.686V21.68c0-2.558,1.452-4.155,3.736-4.155c1.776,0,3.111,1.095,3.362,2.687l0.011,0.051h-1.24
                l-0.033-0.112c-0.268-0.943-1.017-1.519-2.1-1.519c-1.497,0-2.457,1.184-2.457,3.049v0.006c0,1.865,0.966,3.061,2.457,3.061
                c1.072,0,1.815-0.559,2.117-1.581l0.017-0.05h1.24l-0.005,0.056c-0.251,1.603-1.586,2.681-3.368,2.681
                C40.058,25.852,38.605,24.244,38.605,21.686z" />
          <path class="st9"
                d="M46.718,24.049v-0.012c0-1.021,0.793-1.647,2.184-1.73l1.598-0.096v-0.44c0-0.648-0.419-1.039-1.167-1.039
                c-0.698,0-1.128,0.329-1.229,0.799l-0.011,0.05h-1.139l0.005-0.062c0.084-1.011,0.961-1.787,2.407-1.787
                c1.435,0,2.346,0.76,2.346,1.938v4.049h-1.212v-0.928h-0.022c-0.341,0.632-1.022,1.033-1.798,1.033
                C47.505,25.824,46.718,25.104,46.718,24.049z M49.008,24.858c0.849,0,1.491-0.575,1.491-1.34V23.06l-1.436,0.089
                c-0.726,0.045-1.128,0.363-1.128,0.86v0.011C47.936,24.534,48.36,24.858,49.008,24.858z" />
          <path class="st9"
                d="M53.136,19.843h1.206v1.005h0.028c0.189-0.687,0.681-1.117,1.351-1.117c0.173,0,0.324,0.028,0.419,0.045
                v1.122c-0.095-0.039-0.307-0.066-0.542-0.066c-0.776,0-1.257,0.508-1.257,1.385v3.502h-1.206V19.843z" />
          <path class="st9"
                d="M57.743,24.172v-3.368h-0.844v-0.96h0.844v-1.531h1.233v1.531h1.101v0.96h-1.101v3.295
                c0,0.598,0.269,0.776,0.776,0.776c0.129,0,0.234-0.011,0.324-0.022v0.933c-0.14,0.022-0.368,0.051-0.614,0.051
                C58.3,25.836,57.743,25.344,57.743,24.172z" />
          <path class="st9"
                d="M60.924,22.803v-0.006c0-1.838,1.061-3.066,2.708-3.066c1.648,0,2.659,1.185,2.659,2.949v0.407H62.13
                c0.022,1.111,0.631,1.76,1.592,1.76c0.715,0,1.189-0.369,1.34-0.811l0.018-0.044h1.145l-0.011,0.062
                c-0.19,0.933-1.095,1.781-2.52,1.781C61.968,25.836,60.924,24.657,60.924,22.803z M62.147,22.233h2.949
                c-0.101-1.017-0.67-1.514-1.458-1.514C62.857,20.719,62.253,21.251,62.147,22.233z" />
          <path class="st9"
                d="M67.23,24.037l-0.005-0.05h1.194l0.012,0.056c0.111,0.542,0.514,0.86,1.257,0.86
                c0.726,0,1.161-0.302,1.161-0.776v-0.006c0-0.38-0.212-0.598-0.843-0.748l-0.961-0.218c-1.117-0.257-1.67-0.793-1.67-1.647
                v-0.006c0-1.05,0.916-1.771,2.268-1.771c1.391,0,2.229,0.776,2.29,1.776l0.005,0.066h-1.134l-0.005-0.039
                c-0.073-0.514-0.475-0.871-1.156-0.871c-0.648,0-1.056,0.308-1.056,0.776v0.006c0,0.363,0.24,0.608,0.871,0.76l0.955,0.218
                c1.201,0.279,1.658,0.754,1.658,1.597v0.006c0,1.072-1.005,1.81-2.39,1.81C68.213,25.836,67.353,25.109,67.23,24.037z" />
          <path class="st9"
                d="M78.314,17.659h1.352l2.927,8.059H81.28l-0.737-2.179h-3.105l-0.742,2.179h-1.312L78.314,17.659z
                 M77.773,22.535h2.429l-1.2-3.541h-0.022L77.773,22.535z" />
          <path class="st9"
                d="M83.594,19.843H84.8v1.011h0.028c0.352-0.698,1-1.123,1.837-1.123c1.497,0,2.458,1.173,2.458,3.05v0.005
                c0,1.871-0.967,3.05-2.44,3.05c-0.832,0-1.508-0.425-1.854-1.105H84.8v2.942h-1.206V19.843z M87.895,22.786v-0.005
                c0-1.251-0.598-2.017-1.542-2.017c-0.916,0-1.558,0.793-1.558,2.017v0.005c0,1.218,0.647,2.011,1.558,2.011
                C87.302,24.797,87.895,24.026,87.895,22.786z" />
          <path class="st9"
                d="M90.314,19.843h1.206v1.011h0.028c0.352-0.698,1-1.123,1.837-1.123c1.497,0,2.458,1.173,2.458,3.05v0.005
                c0,1.871-0.967,3.05-2.44,3.05c-0.832,0-1.508-0.425-1.854-1.105H91.52v2.942h-1.206V19.843z M94.615,22.786v-0.005
                c0-1.251-0.598-2.017-1.542-2.017c-0.916,0-1.558,0.793-1.558,2.017v0.005c0,1.218,0.647,2.011,1.558,2.011
                C94.022,24.797,94.615,24.026,94.615,22.786z" />
          <path class="st9"
                d="M97.079,17.659h1.207v8.059h-1.207V17.659z" />
          <path class="st9"
                d="M99.517,22.803v-0.006c0-1.838,1.061-3.066,2.708-3.066c1.648,0,2.659,1.185,2.659,2.949v0.407h-4.161
                c0.022,1.111,0.631,1.76,1.592,1.76c0.715,0,1.189-0.369,1.34-0.811l0.018-0.044h1.145l-0.011,0.062
                c-0.19,0.933-1.095,1.781-2.52,1.781C100.561,25.836,99.517,24.657,99.517,22.803z M100.74,22.233h2.949
                c-0.101-1.017-0.67-1.514-1.458-1.514C101.45,20.719,100.846,21.251,100.74,22.233z" />
        </g>
        <g>
          <g>
            <path class="st9"
                  d="M40.617,8.161h0.747l2.232,6.055h-0.793l-0.608-1.733h-2.409l-0.608,1.733h-0.793L40.617,8.161z
                  M40.008,11.841h1.964l-0.949-2.702h-0.067L40.008,11.841z" />
            <path class="st9"
                  d="M43.781,15.769v-0.646h0.109c0.491,0,0.676-0.172,0.676-0.671V9.693h0.73v4.746
                  c0,0.932-0.44,1.331-1.389,1.331H43.781z M44.427,8.316c0-0.277,0.227-0.503,0.504-0.503c0.277,0,0.503,0.227,0.503,0.503
                  S45.208,8.82,44.931,8.82C44.654,8.82,44.427,8.593,44.427,8.316z" />
            <path class="st9"
                  d="M46.436,11.959V11.95c0-1.452,0.797-2.337,2.085-2.337c1.289,0,2.085,0.885,2.085,2.337v0.009
                  c0,1.447-0.797,2.337-2.085,2.337C47.233,14.296,46.436,13.406,46.436,11.959z M49.86,11.959V11.95
                  c0-1.07-0.483-1.691-1.339-1.691s-1.338,0.621-1.338,1.691v0.009c0,1.065,0.482,1.691,1.338,1.691S49.86,13.024,49.86,11.959z" />
            <path class="st9"
                  d="M51.686,12.621V9.693h0.73v2.752c0,0.814,0.293,1.205,1.015,1.205c0.797,0,1.221-0.487,1.221-1.28V9.693
                  h0.73v4.523h-0.73V13.54h-0.067c-0.223,0.483-0.68,0.755-1.343,0.755C52.215,14.296,51.686,13.692,51.686,12.621z" />
            <path class="st9"
                  d="M57.017,13.041v-2.745h-0.713V9.693h0.713V8.522h0.755v1.171h0.99v0.604h-0.99v2.56
                  c0,0.533,0.185,0.747,0.654,0.747c0.13,0,0.202-0.004,0.336-0.017v0.621c-0.143,0.025-0.281,0.042-0.424,0.042
                  C57.407,14.25,57.017,13.905,57.017,13.041z" />
            <path class="st9"
                  d="M59.553,11.971v-0.004c0-1.397,0.797-2.354,2.026-2.354s1.977,0.915,1.977,2.266v0.286H60.3
                  c0.021,0.936,0.537,1.481,1.343,1.481c0.612,0,0.99-0.29,1.111-0.562l0.018-0.038h0.729l-0.008,0.034
                  c-0.155,0.613-0.802,1.217-1.868,1.217C60.333,14.296,59.553,13.389,59.553,11.971z M60.313,11.581h2.5
                  c-0.075-0.894-0.57-1.317-1.237-1.317C60.904,10.263,60.392,10.72,60.313,11.581z" />
            <path class="st9"
                  d="M64.677,9.693h0.73v0.671h0.067c0.172-0.474,0.596-0.751,1.208-0.751c0.139,0,0.294,0.017,0.365,0.029
                  v0.709c-0.151-0.025-0.289-0.042-0.449-0.042c-0.696,0-1.191,0.441-1.191,1.104v2.803h-0.73V9.693z" />
            <path class="st9"
                  d="M70.079,12.953v-0.008c0-0.806,0.596-1.271,1.649-1.334l1.296-0.08v-0.411c0-0.558-0.34-0.86-1.016-0.86
                  c-0.541,0-0.897,0.202-1.015,0.554l-0.005,0.013h-0.729l0.004-0.025c0.117-0.709,0.806-1.188,1.771-1.188
                  c1.104,0,1.721,0.566,1.721,1.506v3.097h-0.73v-0.667h-0.066c-0.286,0.482-0.76,0.747-1.368,0.747
                  C70.729,14.296,70.079,13.776,70.079,12.953z M70.788,7.636h0.864l0.743,1.427h-0.638L70.788,7.636z M71.74,13.662
                  c0.743,0,1.284-0.487,1.284-1.133v-0.411l-1.208,0.076c-0.688,0.042-0.99,0.285-0.99,0.734v0.008
                  C70.826,13.394,71.215,13.662,71.74,13.662z" />
            <path class="st9"
                  d="M77.526,7.897h0.73v6.319h-0.73V7.897z" />
            <path class="st9"
                  d="M80.008,8.161h0.835l-0.658,2.388h-0.541L80.008,8.161z" />
            <path class="st9"
                  d="M81.459,12.953v-0.008c0-0.806,0.596-1.271,1.649-1.334l1.296-0.08v-0.411c0-0.558-0.34-0.86-1.016-0.86
                  c-0.541,0-0.897,0.202-1.015,0.554l-0.005,0.013H81.64l0.004-0.025c0.117-0.709,0.806-1.188,1.771-1.188
                  c1.104,0,1.721,0.566,1.721,1.506v3.097h-0.73v-0.667h-0.066c-0.286,0.482-0.76,0.747-1.368,0.747
                  C82.11,14.296,81.459,13.776,81.459,12.953z M83.121,13.662c0.743,0,1.284-0.487,1.284-1.133v-0.411l-1.208,0.076
                  c-0.688,0.042-0.99,0.285-0.99,0.734v0.008C82.207,13.394,82.596,13.662,83.121,13.662z" />
            <path class="st9"
                  d="M86.458,9.693h0.73v0.722h0.067c0.276-0.503,0.78-0.801,1.41-0.801c1.146,0,1.896,0.927,1.896,2.337v0.009
                  c0,1.418-0.747,2.337-1.896,2.337c-0.621,0-1.163-0.311-1.41-0.793h-0.067v2.224h-0.73V9.693z M89.815,11.959V11.95
                  c0-1.053-0.491-1.691-1.317-1.691c-0.823,0-1.326,0.646-1.326,1.691v0.009c0,1.044,0.503,1.691,1.326,1.691
                  C89.324,13.65,89.815,13.012,89.815,11.959z" />
            <path class="st9"
                  d="M91.691,9.693h0.73v0.722h0.067c0.276-0.503,0.78-0.801,1.41-0.801c1.146,0,1.896,0.927,1.896,2.337v0.009
                  c0,1.418-0.747,2.337-1.896,2.337c-0.621,0-1.163-0.311-1.41-0.793h-0.067v2.224h-0.73V9.693z M95.048,11.959V11.95
                  c0-1.053-0.491-1.691-1.317-1.691c-0.823,0-1.326,0.646-1.326,1.691v0.009c0,1.044,0.503,1.691,1.326,1.691
                  C94.557,13.65,95.048,13.012,95.048,11.959z" />
          </g>
        </g>
      </g>
    </g>
  </svg>

  <svg v-else-if="locale === 'de'"
       id="Artwork"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       class="locale-de"
       viewBox="0 0 110.278 34.499"
       style="enable-background:new 0 0 110.278 34.499;"
       xml:space="preserve">
    <g>
      <g>
        <g>
          <path class="st0"
                d="M100.211,0H9.533C9.166,0,8.804,0,8.439,0.002C8.132,0.004,7.829,0.01,7.52,0.015
                C6.854,0.031,6.181,0.072,5.516,0.191C4.847,0.309,4.225,0.509,3.616,0.818C3.017,1.125,2.47,1.523,1.997,1.997
                C1.519,2.47,1.122,3.019,0.819,3.618C0.508,4.226,0.311,4.851,0.194,5.521C0.074,6.183,0.032,6.852,0.015,7.522
                C0.006,7.829,0.005,8.136,0,8.443c0,0.362,0,0.725,0,1.092v14.948c0,0.369,0,0.73,0,1.094c0.005,0.31,0.006,0.611,0.015,0.922
                c0.017,0.67,0.059,1.34,0.179,2.002c0.117,0.67,0.314,1.298,0.625,1.904c0.303,0.596,0.7,1.144,1.178,1.614
                c0.473,0.477,1.02,0.875,1.618,1.178c0.609,0.312,1.231,0.51,1.901,0.631c0.664,0.119,1.338,0.158,2.004,0.177
                c0.309,0.007,0.613,0.011,0.919,0.011c0.366,0.002,0.728,0.002,1.095,0.002h90.678c0.359,0,0.724,0,1.084-0.002
                c0.305,0,0.617-0.004,0.922-0.011c0.67-0.018,1.342-0.058,2-0.177c0.67-0.121,1.293-0.318,1.908-0.631
                c0.597-0.304,1.144-0.701,1.617-1.178c0.476-0.47,0.873-1.018,1.181-1.614c0.307-0.606,0.506-1.234,0.619-1.904
                c0.123-0.662,0.162-1.332,0.186-2.002c0.004-0.311,0.004-0.611,0.004-0.922c0.008-0.363,0.008-0.724,0.008-1.094V9.534
                c0-0.366,0-0.729-0.008-1.092c0-0.307,0-0.614-0.004-0.921c-0.023-0.67-0.062-1.34-0.186-2.002
                c-0.113-0.67-0.312-1.295-0.619-1.903c-0.309-0.599-0.705-1.147-1.181-1.621c-0.473-0.474-1.019-0.872-1.617-1.179
                c-0.615-0.31-1.238-0.51-1.908-0.627c-0.658-0.119-1.33-0.16-2-0.177c-0.305-0.005-0.617-0.011-0.922-0.013
                C100.936,0,100.571,0,100.211,0L100.211,0z" />
        </g>
        <path d="M100.211,0.65c0.358,0,0.722,0,1.08,0.002c0.246,0.002,0.498,0.006,0.746,0.01l0.164,0.003
                c0.529,0.013,1.222,0.044,1.904,0.167c0.619,0.108,1.184,0.294,1.723,0.565c0.539,0.277,1.028,0.633,1.455,1.061
                c0.423,0.42,0.78,0.91,1.059,1.452c0.271,0.539,0.454,1.101,0.56,1.729c0.117,0.63,0.153,1.288,0.175,1.891
                c0.004,0.304,0.004,0.609,0.004,0.926c0.008,0.358,0.008,0.716,0.008,1.078v14.948c0,0.364,0,0.721-0.008,1.094
                c0,0.308,0,0.606-0.004,0.899c-0.022,0.617-0.058,1.275-0.177,1.916c-0.105,0.622-0.288,1.184-0.555,1.713
                c-0.284,0.549-0.641,1.037-1.066,1.456c-0.423,0.427-0.91,0.782-1.449,1.056c-0.531,0.27-1.097,0.456-1.729,0.571
                c-0.655,0.119-1.329,0.151-1.898,0.167c-0.3,0.007-0.607,0.011-0.911,0.011c-0.358,0.002-0.722,0.002-1.08,0.002H9.533
                c-0.365,0-0.726,0-1.095-0.002c-0.301,0-0.6-0.004-0.901-0.01c-0.572-0.016-1.245-0.048-1.906-0.167
                c-0.633-0.114-1.196-0.301-1.722-0.571c-0.539-0.273-1.027-0.629-1.457-1.063c-0.422-0.415-0.777-0.901-1.055-1.447
                c-0.268-0.523-0.453-1.086-0.564-1.724c-0.124-0.681-0.155-1.374-0.169-1.904c-0.006-0.208-0.009-0.411-0.011-0.616L0.65,25.576
                v-1.094V9.534l0-1.081l0.004-0.299C0.656,7.95,0.659,7.746,0.665,7.539c0.013-0.528,0.045-1.221,0.17-1.907
                c0.11-0.63,0.294-1.192,0.564-1.721c0.275-0.543,0.63-1.032,1.058-1.455C2.883,2.029,3.373,1.673,3.91,1.398
                C4.446,1.125,5.008,0.94,5.631,0.831C6.315,0.708,7.009,0.677,7.53,0.664l0.198-0.003c0.239-0.004,0.476-0.008,0.714-0.009
                C8.806,0.65,9.168,0.65,9.533,0.65H100.211" />
      </g>
      <g>
        <g>
          <g>
            <defs>
              <path id="SVGID_1_"
                    d="M33.265,23.054l0,0.265c0,0.127,0,0.254-0.001,0.381c-0.001,0.107-0.002,0.214-0.005,0.321
                      c-0.006,0.233-0.02,0.468-0.061,0.698c-0.042,0.234-0.111,0.452-0.219,0.664c-0.106,0.209-0.245,0.4-0.411,0.565
                      c-0.166,0.166-0.357,0.304-0.565,0.411c-0.212,0.108-0.43,0.177-0.664,0.219c-0.23,0.041-0.465,0.055-0.698,0.061
                      c-0.107,0.003-0.214,0.004-0.321,0.005c-0.127,0.001-0.254,0.001-0.381,0.001l-1.459-0.001l1.358,0.001H10.984l1.358-0.001
                      l-1.459,0.001c-0.127,0-0.254,0-0.381-0.001c-0.107-0.001-0.214-0.002-0.321-0.005c-0.233-0.006-0.468-0.02-0.698-0.061
                      c-0.234-0.042-0.451-0.111-0.664-0.219c-0.209-0.106-0.4-0.245-0.565-0.411c-0.166-0.166-0.304-0.357-0.411-0.565
                      c-0.108-0.212-0.177-0.43-0.219-0.664c-0.041-0.23-0.055-0.465-0.061-0.698C7.56,23.914,7.559,23.807,7.558,23.7
                      c-0.001-0.127-0.001-0.254-0.001-0.381l0-0.208V10.899l0,1.25l0-1.459c0-0.127,0-0.254,0.001-0.381
                      c0.001-0.107,0.002-0.214,0.005-0.321c0.006-0.233,0.02-0.468,0.061-0.698c0.042-0.234,0.111-0.452,0.219-0.664
                      c0.106-0.209,0.245-0.4,0.411-0.565C8.42,7.896,8.611,7.757,8.82,7.651c0.212-0.108,0.43-0.177,0.664-0.219
                      c0.23-0.041,0.465-0.055,0.698-0.061c0.107-0.003,0.214-0.004,0.321-0.005c0.127-0.001,0.254-0.001,0.381-0.001l1.459,0h17.496
                      l0.1,0c0.127,0,0.254,0,0.381,0.001c0.107,0.001,0.214,0.002,0.321,0.005c0.233,0.006,0.468,0.02,0.698,0.061
                      c0.234,0.042,0.451,0.111,0.664,0.219c0.209,0.106,0.4,0.245,0.565,0.411c0.166,0.166,0.304,0.357,0.411,0.565
                      c0.108,0.212,0.177,0.43,0.219,0.664c0.041,0.23,0.055,0.465,0.061,0.698c0.003,0.107,0.004,0.214,0.005,0.321
                      c0.001,0.127,0.001,0.254,0.001,0.381l0,0.265V23.054z" />
            </defs>
            <clipPath id="SVGID_00000016077057154139084880000008981350053158418615_">
              <use xlink:href="#SVGID_1_"
                   style="overflow:visible;" />
            </clipPath>
            <g style="clip-path:url(#SVGID_00000016077057154139084880000008981350053158418615_);">
              <rect x="8.351"
                    y="8.168"
                    class="st2"
                    width="24.1"
                    height="16.781" />
              <rect x="8.629"
                    y="8.436"
                    class="st3"
                    width="23.565"
                    height="9.997" />
              <g>

                <image style="overflow:visible;opacity:0.17;"
                       width="109"
                       height="52"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA2CAYAAADwI3YEAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAsJJREFUeNrs3G9P01AUBvDb0rK5
6QBF1PgP30j8/l9IfAMiTKNjwraunBOf6461vS3SdZI8T/JkpFv2oj9u2zXpcY5hmM0nuuVno8Lf
EXdha8lNXeHvOyN6rFi6ZRqjhGwHcIlmpssmmFEDPAVLpKm0h/al29jOFdnOClxIZ9Ir6TU6x/Ys
hBkFAGMgKdpQ+lA6Qh9JB4Dc+odDM7MCdEBSwKn0h/Q7OpFeAnRhVmYtogVUqF3pU+kBXp9I9wDZ
AyIB7waZAUoBv0ovpF+kZ3j9BuBSyKQC0QMq2CvpofSN9IV0H7BDrESeF9s5H86w6hTsXHoi/ST9
aI52Uxxig4j+HNgD1Evpe+mR9J30ObYP8BmeE9s9J14DSiGfSXewUPLQxU4V4gAr7hCAH6SvpY/x
XmquUAnY3mrMsNJ2cLrq432P+xMrNqtCjCoOpW/xeoCLm9TgEbD9Fbltrvxz4I2ln3GunAA68pCh
w+kIkMVzIK9G13fjJTcLZIj9vg+HUdWFZFJxZZpiKQ/QPj7Lw+f6IZ35dVA0SMsM4gY/8hPendkI
ZmwwE7MC/zKIa+7W8B7p5jFrHWLup/sfIhKRISJDRIaIRGSIyBCRISIRGSIyRGSISESGiAwRGSIS
kSEiQ0SGiERkiMgQkSEiERkiMkRkmiepeT8vKbP+3Gq/J4EvsROOFqiGj7l1g+j3eWYs8qaIHlCf
C9fpRjqWY2I+T8TuEP0woit4lEImFYB+poo+8H8KOH3kmIOHukPUFTjF/h/DY+YaDCPy/wE6akMH
4hxju0426rvV0AVm/cmwAsdwOIfLIoSYF5bxGbbrl/jRJ3z0u9sLmzksLuAxMYi/IYsg/oF/HXXy
wP0aw2GHDxGw+0PqHIfVS7caRrQMIVrIxP05PYOAm4FcmivV0gF9oVGZnJ5xT34z1uEQ7//CLM2N
AAMApf7L8dXkt64AAAAASUVORK5CYII="
                       transform="matrix(0.24 0 0 0.24 7.3445 9.1483)" />
                <g>
                  <path class="st4"
                        d="M32.193,12.688c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                          c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.261-0.359
                          c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                          c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.066,0H11.664l-0.925,0c-0.081,0-0.161,0-0.242,0
                          c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                          c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.261,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                          c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                          h23.565v-7.641L32.193,12.688z" />
                </g>
              </g>
              <g>

                <image style="overflow:visible;opacity:0.16;"
                       width="109"
                       height="53"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA2CAYAAADwI3YEAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAArhJREFUeNrs3G1P01AYxvGuO2MD
RIcTNCQa3xj9/t/ImBhEQI0b6rqt3ideB0+WPpG0HUv+d3KlsLG+6I/z0CW9k4Ta+xr09BnqYZV3
ATKIjnGo9vHiNAIdNHg/ZKg4S6oA2S7gRllZ1sqmDtPVAKaCG1nGlkPLRD87RmTrI9Dj/bH8tvzS
MRNmKWQZQBhpI8E9sTy1nFqe6fcDAbNOtrP+eailZW75bvmm41yYK43KvMlIDCNwLKznlnPLK8tL
y5lAJ0IEsB3ItbB+WL5aPitfBHpXBulKAEcC9GhvLG91vLC8sJxEIxHE9hD9VPrTcmX5pFlvLJNE
kFkdYiKYiabO15YPlvdCPNeJJ4JmTWx3Tcy0Fp7p+h/LKN/a6MS718KRONQ6OBPiO8WPwqnec+xO
O9ud+hnwKLrOG43QOwEvhVmIGEaV0wlOtQ5e6DjTyQHsFtJFt3Nr4d1qbbzRRifT9c+r1sSwqZkq
J4IdAdhphYF0IKCp9iAzbSbHRfuQtATR6USH0fo3BLA3yGAw0ex3FDmkdYhJwTc04O0OMmC6aAQO
im7qq4Y135M+jum10iHlOu1/gQgiBSIFIgUiiBSIFIgUiCBSIFIgUiCCSIFIgUiBCCIFIgUiBSKI
FIgUiBSIIFIgUiBSzcvVvJ8nxV2OqG7rQdfdVZwkdDfyjxyvFF885tYPYrjm68gib4oYAP1z4b6v
yiL595x4+HsQ+0Oc6/qHzlKNmhEFwKU+7B/4vxScf+SYvjX9IYamC5dyWMilthlR+A/wrTauLR/1
um+OEzpIUf1U6DB1K4druayqEPOtYXyl1/1JfCcNmg/1v7HJZHEjj3mEeA+5DRIe+A+dM441jY6Y
Snc2pWaaVhfJ/2ZEmyrEGNJFoXvG7iA30U61sEFfGQzdM/bonrFJ52Hq8WAW1l8BBgBg68ERmyys
9gAAAABJRU5ErkJggg=="
                       transform="matrix(0.24 0 0 0.24 7.3445 11.0683)" />
                <g>
                  <path class="st5"
                        d="M32.193,14.83c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                          c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.261-0.359
                          c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                          c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.664l-0.925,0c-0.081,0-0.161,0-0.242,0
                          c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                          c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.261,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                          c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                          h23.565v-7.641L32.193,14.83z" />
                </g>
              </g>
              <g>

                <image style="overflow:visible;opacity:0.17;"
                       width="109"
                       height="53"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAArxJREFUeNrsnG1PE0EURrftLC0g
WqygIdH4xej//0eGhCACamxRu23XO/EZnDT7RrK7heSc5MlCS/fDHO7MbJO5SQIAu2fQ02fgYeRd
CBlE1zjQvrw4jYQOGrwfMlKcZaggsl2BG2VlWSubOpmuRuBQ4lLL2LJvmehnR0W2XoFe3h/Lb8sv
XTPJLBVZJiBUWipxzyzPLceWF/p9T4JZJ9tZ/7yopWVu+W75putcMleqyrxJJYYKHEvWS8up5Y3l
teVEQieSiMB2RK4l64flq+VC+SKhd2UiXYnAVAK9tHeW97qeWV5ZjqJKRGJ7Ev1U+tNyZTnXrDeW
k0QiszqJicRMNHW+tXyyfJTEU914ItGsie2uiZnWwhON/6Ec5VsbnXj3WliJI62DM0n8oPgqnOo9
x+60s92pnwEPonHeqELvJHgpmYUSQ1U53eBY6+CZrjPdHIHdinTR49xa8m61Nt5oo5Np/POqNTFs
aqbKkcSmCOyUUEh7EjTVHmSmzeS4aB8yLJHodKP9aP0bIbA3kcHBRLPfQeRhWCcxKfiGBnm7Exlk
uqgCB0UP9VVlzfekj2N6rfQwZJyePkhEIiARkAhIRCIgEZAISEQiIBGQCEhEIiARkAhIRCIgEZAI
SEQiIBGQCEhEIiARkAjNcTXv50lxlyPolgeNu6u4Sehu5I8crxQPx9z6kRjGfB25yJtKDAL9uXDf
V2WR/DsnHv4eif1JnGv8Q2epRs2IgsClPuwP/F9KnD9yTN+a/iSGpguX8rCQl9pmROE/wLfauLZ8
1uu+OU7oIAX9EDpM3crDtbysqiTmW2V8pdf9TXwnDZoP9b+xyeTiRj7mkcR7kdtCwoH/0DnjUNNo
ylS6syk107S6SP43I9pUSYxFuih0z9idyE20Uy1s0Fcmhu4ZT+iZsUnnYXg8MgEAoDv+CjAAh/7B
ERXb5OgAAAAASUVORK5CYII="
                       transform="matrix(0.24 0 0 0.24 7.3445 13.2283)" />
                <g>
                  <path class="st6"
                        d="M32.193,16.972c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                          c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.261-0.359
                          c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                          c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.664l-0.925,0c-0.081,0-0.161,0-0.242,0
                          c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                          c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.261,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                          c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                          h23.565v-7.641L32.193,16.972z" />
                </g>
              </g>
              <path class="st7"
                    d="M7.201,7.008v11.068h1.428v-6.605l0-0.925c0-0.081,0-0.161,0-0.242c0-0.068,0.001-0.136,0.003-0.204
                      c0.004-0.148,0.013-0.297,0.039-0.443c0.027-0.148,0.07-0.286,0.139-0.421c0.067-0.132,0.155-0.254,0.261-0.359
                      c0.105-0.105,0.226-0.193,0.359-0.261C9.564,8.549,9.702,8.505,9.85,8.479c0.146-0.026,0.295-0.035,0.443-0.039
                      c0.068-0.002,0.136-0.003,0.204-0.003c0.081,0,0.161,0,0.242,0l0.925,0h18.349l0.071,0c0.081,0,0.161,0,0.242,0
                      c0.068,0,0.136,0.001,0.204,0.003c0.148,0.004,0.297,0.013,0.443,0.039c0.148,0.027,0.286,0.07,0.421,0.139
                      c0.132,0.067,0.254,0.155,0.359,0.261c0.105,0.105,0.193,0.226,0.261,0.359c0.069,0.135,0.112,0.273,0.139,0.421
                      c0.026,0.146,0.035,0.295,0.039,0.443c0.002,0.068,0.003,0.136,0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925v6.605h1.428V7.008
                      H7.201z" />
              <g>

                <image style="overflow:visible;opacity:0.14;"
                       width="121"
                       height="53"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAA6CAYAAACd3VbxAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABL1JREFUeNrsnX1T00AQxi8hSUOb
Si2CUAEVVBz9/h/IP1QEFNRSXto03s08O92eSW1qyUu7O/NMQiw1t7/bvdwmOZQSExMTE1tBcxb8
t6ItqbkvK+UvJ+OYrTJPPmGNSCrUARzLX6pi/sr0lZPys6vlMbklNcSc8BiKmeh4WRnAYX6i7Qbk
VsBfI6Zxmo+cFOCB1qZWS6up5aNBTgk91pz4g9ad1j32hzgeFwyfYBNgH0ERQA1svRIiPoE/jG8G
Wjdat/DXX+A9q1EegD/T2tXa1orQwKIaQicY46RNI35r/dLqo0EDdIQhPveY4B0rAzasoGhiP8J+
gE6hCvRXAl8Y/3zXOte6BPBhFnSHQY8A/K3WS62uVsgaUmTPvQfwa60faBBtr9HIu1mpbInRHQDq
FoLBqIOfn2AboVMUnRlj+MH45hOOUXYc4VySeSLdAH+vtY/GFtkQGp8eENk/0SDTe8+0Pmt90foG
+AP06GWCJ+A+On0boI0/Xmj1tHYAvg1RpLsF+yqGD77i2JXWBRtq1Kz0TmN6CxG+hwZGWV9Q0JhO
Kf4KoHcRWSHrjDdLBM+BmyB4Cl8car2CegiOCJ8JSxrTyU997J+DX2bn8zKu3ql301jVLhg6j/YY
gLtwMqXVtFS6LPCU0kP8vwdaJ1pvtI4RCDtI6w12UbdR0kXcCPstnLM/K9t4GQ122VSET9+Knorw
+WaIiGpi28g4p/8FT+1voLPvAfhHXOccsggP2VStrLpGwljONW305khxZRZqHNYw3gn5vDjNCQQ+
71U9z3QtwD0E7HdarxnwwDqHMosyuRh5qh7mzNEJ+XAwttJesgBwSuvHgH0A4E+s8dJRNTOvZudr
g7Fhj9jcPckxnbO/dxsRfgIdpUR47WDXFXoaIF5+fGBVKAfTudt/gE8DfoSUfopI32MXbW6dgdcV
ehooDn3Eag6BNY+P2YUhL7z4uEDcRho3wD9ge4DZQlj3CK87dBt8pCY3ZPj8uoViDoGnTkERv4Ho
bWIM7yGyTwH8CMebqpyaukCfMZ8OkH4pwqmC1kH0XqCqN2DjvIPfi/C554DML9y66Dj+KqT1VYHO
wTes/Tag7aNKdYWq3g0iXiEbUMeg6mMPHaCjJncZVwb4qkC3wbssvW8B4DUine7S3eF3Nlm1r2tV
+0JV7vMEAj3HGB+o6btiHVzBc1GkU6mZ1FSTMmYZJVWBviB4xSKU7hqO2Bx+yIo2PjqHr6br51Wo
sgn0BcC7DLyvph+/GltTNv7o08rCXmXoNnyeohNraz/UuBbmrUk71w7sLHPFBQJdTKCLCXQxgS4m
0MUEuphAFxPoYgJdTKCLCXQxgS4m0MUEuphAFxPoYgJdoIuth816Rm6u1QfFSrfcnLyML+ErNdIz
48bkwcJqQidGMWOXzAudgJsXAsyrP+YVoD77rECvLnT+ytbMNXe8FOC0dptZt+0MoIteR04sH3Ra
R+4M3OglzVTwXkqPMe96mUX6aOVB88Zn0StGiuUze8XISzVZgSMTemKliXMcN19S9NqwYotdyNlr
w/YZ9CnwVV0FWmyxFD/XKtBVXu9dLD/43Ou982NV+csOYo8wZ6/L33ARyw9fTExsre2PAAMAAktc
gtNM82QAAAAASUVORK5CYII="
                       transform="matrix(0.24 0 0 0.24 5.9045 15.3883)" />
                <g>
                  <path class="st8"
                        d="M26.985,17.005c-0.153,0-0.307,0-0.46,0.001c-0.129,0.001-0.258,0.002-0.387,0.006
                        c-0.281,0.008-0.565,0.024-0.843,0.074c-0.282,0.051-0.545,0.134-0.801,0.264c-0.033,0.017-0.738,0.337-1.372,1.323
                        c-0.481,0.749-1.417,1.543-2.728,1.543c-1.31,0-2.246-0.794-2.728-1.543c-0.667-1.039-1.428-1.351-1.373-1.323
                        c-0.256-0.131-0.519-0.214-0.801-0.264c-0.278-0.05-0.562-0.067-0.843-0.074c-0.129-0.003-0.258-0.005-0.387-0.006
                        c-0.153-0.001-0.307-0.001-0.46-0.001l-6.6,0v9.997h26.421v-9.997L26.985,17.005z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <path class="st9"
                d="M41.23,17.66h1.352l2.926,8.058h-1.312l-0.737-2.178h-3.105l-0.743,2.178h-1.312L41.23,17.66z
                   M40.689,22.535h2.429l-1.201-3.541h-0.022L40.689,22.535z" />
          <path class="st9"
                d="M46.282,19.843h1.206v1.011h0.028c0.352-0.698,1-1.122,1.837-1.122c1.497,0,2.458,1.173,2.458,3.049v0.006
                  c0,1.871-0.966,3.049-2.44,3.049c-0.832,0-1.508-0.424-1.854-1.105h-0.028v2.943h-1.206V19.843z M50.582,22.786V22.78
                  c0-1.251-0.598-2.016-1.542-2.016c-0.916,0-1.558,0.793-1.558,2.016v0.006c0,1.218,0.648,2.011,1.558,2.011
                  C49.99,24.797,50.582,24.025,50.582,22.786z" />
          <path class="st9"
                d="M53.002,19.843h1.206v1.011h0.028c0.352-0.698,1-1.122,1.837-1.122c1.497,0,2.458,1.173,2.458,3.049v0.006
                  c0,1.871-0.967,3.049-2.44,3.049c-0.833,0-1.508-0.424-1.854-1.105h-0.028v2.943h-1.206V19.843z M57.303,22.786V22.78
                  c0-1.251-0.598-2.016-1.542-2.016c-0.916,0-1.558,0.793-1.558,2.016v0.006c0,1.218,0.648,2.011,1.558,2.011
                  C56.71,24.797,57.303,24.025,57.303,22.786z" />
          <path class="st9"
                d="M59.595,17.66h1.206v8.058h-1.206V17.66z" />
          <path class="st9"
                d="M61.976,22.803v-0.006c0-1.837,1.061-3.065,2.708-3.065s2.659,1.184,2.659,2.948v0.408h-4.161
                  c0.022,1.111,0.631,1.759,1.592,1.759c0.715,0,1.189-0.368,1.34-0.81l0.018-0.045h1.145l-0.011,0.062
                  c-0.19,0.933-1.095,1.781-2.52,1.781C63.019,25.835,61.976,24.657,61.976,22.803z M63.198,22.233h2.949
                  c-0.101-1.017-0.67-1.514-1.458-1.514C63.908,20.72,63.305,21.25,63.198,22.233z" />
          <path class="st9"
                d="M71.177,17.66h1.302l1.502,6.316h0.022l1.703-6.316h1.185l1.703,6.316h0.027l1.503-6.316h1.301l-2.178,8.058
                    h-1.201l-1.73-6.076h-0.028l-1.737,6.076h-1.2L71.177,17.66z" />
          <path class="st9"
                d="M81.769,24.048v-0.011c0-1.022,0.793-1.647,2.184-1.731l1.598-0.095v-0.441c0-0.647-0.419-1.039-1.168-1.039
                  c-0.698,0-1.128,0.33-1.229,0.799l-0.011,0.051h-1.14l0.006-0.062c0.084-1.011,0.96-1.787,2.407-1.787
                  c1.435,0,2.346,0.759,2.346,1.938v4.049h-1.212v-0.927h-0.022c-0.341,0.631-1.022,1.033-1.799,1.033
                  C82.557,25.824,81.769,25.103,81.769,24.048z M84.06,24.858c0.849,0,1.491-0.575,1.491-1.341v-0.458l-1.436,0.09
                  c-0.727,0.044-1.128,0.362-1.128,0.859v0.012C82.987,24.534,83.411,24.858,84.06,24.858z" />
          <path class="st9"
                d="M88.083,17.66h1.207v8.058h-1.207V17.66z" />
          <path class="st9"
                d="M90.988,17.66h1.207v8.058h-1.207V17.66z" />
          <path class="st9"
                d="M93.255,22.803v-0.006c0-1.837,1.062-3.065,2.709-3.065s2.658,1.184,2.658,2.948v0.408h-4.16
                  c0.021,1.111,0.631,1.759,1.591,1.759c0.715,0,1.19-0.368,1.341-0.81l0.017-0.045h1.145l-0.011,0.062
                  c-0.189,0.933-1.095,1.781-2.519,1.781C94.3,25.835,93.255,24.657,93.255,22.803z M94.478,22.233h2.948
                  c-0.101-1.017-0.67-1.514-1.457-1.514C95.187,20.72,94.584,21.25,94.478,22.233z" />
          <path class="st9"
                d="M100.153,24.171v-3.367H99.31v-0.961h0.844v-1.53h1.234v1.53h1.1v0.961h-1.1v3.295
                  c0,0.598,0.268,0.776,0.776,0.776c0.128,0,0.234-0.012,0.323-0.022v0.933c-0.14,0.022-0.368,0.05-0.614,0.05
                  C100.712,25.835,100.153,25.344,100.153,24.171z" />
        </g>
        <g>
          <g>
            <path class="st9"
                  d="M38.867,8.161h0.755v2.619h3.323V8.161h0.755v6.055h-0.755v-2.757h-3.323v2.757h-0.755V8.161z" />
            <path class="st9"
                  d="M45.023,8.316c0-0.277,0.227-0.503,0.503-0.503s0.503,0.227,0.503,0.503S45.803,8.82,45.526,8.82
                    S45.023,8.593,45.023,8.316z M45.157,9.693h0.73v4.523h-0.73V9.693z" />
            <path class="st9"
                  d="M47.253,9.693h0.73v0.68h0.067c0.227-0.479,0.65-0.759,1.317-0.759c1.016,0,1.582,0.6,1.582,1.674v2.929
                  h-0.73v-2.753c0-0.814-0.336-1.204-1.058-1.204s-1.179,0.487-1.179,1.28v2.677h-0.73V9.693z" />
            <path class="st9"
                  d="M52.151,13.687l2.61-3.323v-0.067h-2.606V9.693h3.466v0.542l-2.597,3.311v0.067h2.61v0.604h-3.483V13.687z" />
            <path class="st9"
                  d="M56.836,12.621V9.693h0.73v2.752c0,0.814,0.293,1.205,1.015,1.205c0.798,0,1.222-0.487,1.222-1.28V9.693
                  h0.729v4.523h-0.729V13.54h-0.067c-0.223,0.483-0.68,0.755-1.343,0.755C57.364,14.296,56.836,13.692,56.836,12.621z" />
            <path class="st9"
                  d="M62.309,10.297h-0.751V9.693h0.751V9.189c0-0.902,0.453-1.334,1.313-1.334c0.176,0,0.335,0.013,0.486,0.042
                  V8.48c-0.088-0.017-0.21-0.021-0.34-0.021c-0.521,0-0.73,0.256-0.73,0.751v0.482h1.028v0.604h-1.028v3.919h-0.729V10.297z" />
            <path class="st9"
                  d="M65.063,12.621V9.693h0.73v2.752c0,0.814,0.293,1.205,1.015,1.205c0.798,0,1.222-0.487,1.222-1.28V9.693
                  h0.729v4.523H68.03V13.54h-0.067c-0.223,0.483-0.68,0.755-1.343,0.755C65.592,14.296,65.063,13.692,65.063,12.621z
                   M65.517,8.321c0-0.252,0.21-0.462,0.466-0.462c0.252,0,0.462,0.21,0.462,0.462c0,0.256-0.21,0.466-0.462,0.466
                  C65.727,8.787,65.517,8.577,65.517,8.321z M67.371,8.321c0-0.252,0.21-0.462,0.462-0.462c0.256,0,0.466,0.21,0.466,0.462
                  c0,0.256-0.21,0.466-0.466,0.466C67.581,8.787,67.371,8.577,67.371,8.321z" />
            <path class="st9"
                  d="M70.07,14.577l0.009-0.004h0.755l0.004,0.004c0.08,0.344,0.483,0.587,1.134,0.587
                  c0.81,0,1.292-0.382,1.292-1.049v-0.915h-0.067c-0.285,0.5-0.797,0.781-1.418,0.781c-1.171,0-1.896-0.907-1.896-2.174v-0.008
                  c0-1.267,0.729-2.186,1.913-2.186c0.638,0,1.133,0.314,1.41,0.827h0.05V9.693h0.73v4.46c0,1.011-0.78,1.658-2.014,1.658
                  C70.88,15.811,70.184,15.32,70.07,14.577z M73.28,11.808v-0.008c0-0.898-0.508-1.54-1.343-1.54c-0.839,0-1.309,0.642-1.309,1.54
                  v0.008c0,0.898,0.47,1.527,1.309,1.527C72.777,13.335,73.28,12.705,73.28,11.808z" />
            <path class="st9"
                  d="M75.106,11.971v-0.004c0-1.397,0.798-2.354,2.027-2.354s1.977,0.915,1.977,2.266v0.286h-3.257
                  c0.021,0.936,0.537,1.481,1.343,1.481c0.612,0,0.99-0.29,1.112-0.562l0.017-0.038h0.73l-0.009,0.034
                  c-0.155,0.613-0.802,1.217-1.867,1.217C75.888,14.296,75.106,13.39,75.106,11.971z M75.866,11.581h2.501
                  c-0.075-0.894-0.57-1.317-1.238-1.317C76.458,10.264,75.946,10.721,75.866,11.581z" />
            <path class="st9"
                  d="M80.231,9.693h0.729v0.68h0.067c0.227-0.479,0.65-0.759,1.317-0.759c1.016,0,1.582,0.6,1.582,1.674v2.929
                  h-0.729v-2.753c0-0.814-0.336-1.204-1.058-1.204s-1.18,0.487-1.18,1.28v2.677h-0.729V9.693z" />
            <path class="st9"
                  d="M87.536,13.687l2.61-3.323v-0.067H87.54V9.693h3.466v0.542l-2.597,3.311v0.067h2.609v0.604h-3.482V13.687z" />
            <path class="st9"
                  d="M92.22,12.621V9.693h0.73v2.752c0,0.814,0.294,1.205,1.016,1.205c0.797,0,1.221-0.487,1.221-1.28V9.693
                  h0.73v4.523h-0.73V13.54H95.12c-0.223,0.483-0.681,0.755-1.343,0.755C92.749,14.296,92.22,13.692,92.22,12.621z" />
          </g>
        </g>
      </g>
    </g>
  </svg>

  <svg v-else
       id="Artwork"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       class="locale-en"
       viewBox="0 0 110.739 35.016"
       style="enable-background:new 0 0 110.739 35.016;"
       xml:space="preserve">
    <g>
      <g>
        <path class="st0"
              d="M100.211,0H9.533C9.166,0,8.804,0,8.439,0.002C8.132,0.004,7.829,0.01,7.52,0.015
                  C6.854,0.031,6.181,0.072,5.516,0.191C4.847,0.309,4.225,0.509,3.616,0.818C3.017,1.125,2.47,1.523,1.997,1.997
                  C1.519,2.47,1.122,3.019,0.819,3.618C0.508,4.226,0.311,4.851,0.194,5.521C0.074,6.183,0.032,6.852,0.015,7.522
                  C0.006,7.829,0.005,8.136,0,8.443c0,0.362,0,0.725,0,1.092v14.948c0,0.369,0,0.73,0,1.094c0.005,0.31,0.006,0.611,0.015,0.922
                  c0.017,0.67,0.059,1.34,0.179,2.002c0.117,0.67,0.314,1.298,0.625,1.904c0.303,0.596,0.7,1.144,1.178,1.614
                  c0.473,0.477,1.02,0.875,1.618,1.178c0.609,0.312,1.231,0.51,1.901,0.631c0.664,0.119,1.338,0.158,2.004,0.177
                  c0.309,0.007,0.613,0.011,0.919,0.011c0.366,0.002,0.728,0.002,1.095,0.002h90.678c0.359,0,0.724,0,1.084-0.002
                  c0.305,0,0.617-0.004,0.922-0.011c0.67-0.018,1.342-0.058,2-0.177c0.67-0.121,1.293-0.318,1.908-0.631
                  c0.597-0.304,1.144-0.701,1.617-1.178c0.476-0.47,0.873-1.018,1.181-1.614c0.307-0.606,0.506-1.234,0.619-1.904
                  c0.123-0.662,0.162-1.332,0.186-2.002c0.004-0.311,0.004-0.611,0.004-0.922c0.008-0.363,0.008-0.724,0.008-1.094V9.534
                  c0-0.366,0-0.729-0.008-1.092c0-0.307,0-0.614-0.004-0.921c-0.023-0.67-0.062-1.34-0.186-2.002
                  c-0.113-0.67-0.312-1.295-0.619-1.903c-0.309-0.599-0.705-1.147-1.181-1.621c-0.473-0.474-1.019-0.872-1.617-1.179
                  c-0.615-0.31-1.238-0.51-1.908-0.627c-0.658-0.119-1.33-0.16-2-0.177c-0.305-0.005-0.617-0.011-0.922-0.013
                  C100.936,0,100.571,0,100.211,0L100.211,0z" />
      </g>
      <path d="M100.211,0.65c0.358,0,0.722,0,1.08,0.002c0.246,0.002,0.498,0.006,0.746,0.01l0.164,0.003
            c0.529,0.013,1.222,0.044,1.904,0.167c0.619,0.108,1.184,0.294,1.723,0.565c0.539,0.277,1.028,0.633,1.455,1.061
            c0.423,0.42,0.78,0.91,1.059,1.452c0.271,0.539,0.454,1.101,0.56,1.729c0.117,0.63,0.153,1.288,0.175,1.891
            c0.004,0.304,0.004,0.609,0.004,0.926c0.008,0.358,0.008,0.716,0.008,1.078v14.948c0,0.364,0,0.721-0.008,1.094
            c0,0.308,0,0.606-0.004,0.899c-0.022,0.617-0.058,1.275-0.177,1.916c-0.105,0.622-0.288,1.184-0.555,1.713
            c-0.284,0.549-0.641,1.037-1.066,1.456c-0.423,0.427-0.91,0.782-1.449,1.056c-0.531,0.27-1.097,0.456-1.729,0.571
            c-0.655,0.119-1.329,0.151-1.898,0.167c-0.3,0.007-0.607,0.011-0.911,0.011c-0.358,0.002-0.722,0.002-1.08,0.002H9.533
            c-0.365,0-0.726,0-1.095-0.002c-0.301,0-0.6-0.004-0.901-0.01c-0.572-0.016-1.245-0.048-1.906-0.167
            c-0.633-0.114-1.196-0.301-1.722-0.571c-0.539-0.273-1.027-0.629-1.457-1.063c-0.422-0.415-0.777-0.901-1.055-1.447
            c-0.268-0.523-0.453-1.086-0.564-1.724c-0.124-0.681-0.155-1.374-0.169-1.904c-0.006-0.208-0.009-0.411-0.011-0.616L0.65,25.576
            v-1.094V9.534l0-1.081l0.004-0.299C0.656,7.95,0.659,7.746,0.665,7.539c0.013-0.528,0.045-1.221,0.17-1.907
            c0.11-0.63,0.294-1.192,0.564-1.721c0.275-0.543,0.63-1.032,1.058-1.455C2.883,2.029,3.373,1.673,3.91,1.398
            C4.446,1.125,5.008,0.94,5.631,0.831C6.315,0.708,7.009,0.678,7.53,0.664l0.198-0.003c0.239-0.004,0.476-0.008,0.714-0.009
            C8.806,0.65,9.168,0.65,9.533,0.65H100.211" />
      <g>
        <defs>
          <path id="SVGID_1_"
                d="M33.265,23.054l0,0.265c0,0.127,0,0.254-0.001,0.381c-0.001,0.107-0.002,0.214-0.005,0.321
                    c-0.006,0.233-0.02,0.468-0.061,0.698c-0.042,0.234-0.111,0.452-0.219,0.664c-0.106,0.209-0.245,0.4-0.411,0.565
                    c-0.166,0.166-0.357,0.304-0.565,0.411c-0.212,0.108-0.43,0.177-0.664,0.219c-0.23,0.041-0.465,0.055-0.698,0.061
                    c-0.107,0.003-0.214,0.004-0.321,0.005c-0.127,0.001-0.254,0.001-0.381,0.001l-1.459-0.001l1.358,0.001H10.985l1.358-0.001
                    l-1.459,0.001c-0.127,0-0.254,0-0.381-0.001c-0.107-0.001-0.214-0.002-0.321-0.005c-0.233-0.006-0.468-0.02-0.698-0.061
                    c-0.234-0.042-0.451-0.111-0.664-0.219c-0.209-0.106-0.4-0.245-0.565-0.411c-0.166-0.166-0.304-0.357-0.411-0.565
                    c-0.108-0.212-0.177-0.43-0.219-0.664c-0.041-0.23-0.055-0.465-0.061-0.698C7.561,23.914,7.56,23.807,7.56,23.7
                    c-0.001-0.127-0.001-0.254-0.001-0.381l0-0.208V10.899l0,1.25l0-1.458c0-0.127,0-0.254,0.001-0.381
                    c0.001-0.107,0.002-0.214,0.005-0.321c0.006-0.233,0.02-0.468,0.061-0.698c0.042-0.234,0.111-0.452,0.219-0.664
                    c0.106-0.209,0.245-0.4,0.411-0.565c0.166-0.166,0.357-0.304,0.565-0.411c0.212-0.108,0.43-0.177,0.664-0.219
                    c0.23-0.041,0.465-0.055,0.698-0.061c0.107-0.003,0.214-0.004,0.321-0.005c0.127-0.001,0.254-0.001,0.381-0.001l1.458,0h17.496
                    l0.1,0c0.127,0,0.254,0,0.381,0.001c0.107,0.001,0.214,0.002,0.321,0.005c0.233,0.006,0.468,0.02,0.698,0.061
                    c0.234,0.042,0.451,0.111,0.664,0.219c0.209,0.106,0.4,0.245,0.565,0.411c0.166,0.166,0.304,0.357,0.411,0.565
                    c0.108,0.212,0.177,0.43,0.219,0.664c0.041,0.23,0.055,0.465,0.061,0.698c0.003,0.107,0.004,0.214,0.005,0.321
                    c0.001,0.127,0.001,0.254,0.001,0.381l0,0.265V23.054z" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_"
               style="overflow:visible;" />
        </clipPath>
        <rect x="8.352"
              y="8.169"
              class="st1"
              width="24.099"
              height="16.78" />
        <rect x="8.63"
              y="8.436"
              class="st2"
              width="23.564"
              height="9.997" />
        <g class="st3">

          <image style="overflow:visible;opacity:0.17;"
                 width="109"
                 height="52"
                 xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAArlJREFUeNrsnG1vEkEUhXeXBSpU
FCW+6zdN/P8/qGk00WhFY6CWsqD3JmfS6bpLoWGXNnlOckJ32NkPPNw7MzT3JglCCKE9KN3zfWh/
+rsPiGn0Ghs1D6/sW0H08czckXNdZ4BsHODavDIXel3dBDOvGAuwuuYj8wO5r/uJyGYBOryF+Vy+
0Fhtis1rItCBHZvH5qfmx7ruKzJZJ5tZ/wLA3+Yz+ZdgFttATKMIdGDPzW/lFwJ6JIgAbAZkIWAO
75P5RO95hP6pS6vlSOwI1FjwPprfm18pGoHYDsRv5pHGF/IyWh9rIWa6Hpgn5ncC+MH80vzQ3GNN
bBTiSmvgSDzm5p9KqXOB/C8a89IxIqRSh/ja/EZROFEU5gBsVGttIjOlz+/mz+YvArnQPbUQO4q0
Y21mngneSA/u6eGo2Wh0FkMtXxOxGEWbyuWmdBrWQ5/wRB5FEcgZsR2FYAogx1rK4v1IGkdjVorE
vib45EdaH7sAbE1picVAPIYCW7mpzCrS6VATB5smokZBhk1mTzC7m35oyUrfgFwT+noAafTwEdkp
pdGkDuLOE1FrIG/850N224no7ogjAxAREBEQERCBiICIgIiACEQERAREBEQgIiAiICIgAhEBEQER
ARGICIgIiAiIQET3RXnN+M4NcdDetPNnn1c8YJ1cb4gTeqhQl9EexPC5ryIeWzUjCgC9nNiL/73Q
fxbdB8R2Ic7E4EJMakHmJYCXmjg1fxU4Lzal0LRdiB6B52IwFZPLOpB5ib53bPBGOKca934qoVYc
tafQCmUqFmdiU2wDcSZwiR7gnTS6CbWKh9jYLMXjh5jMIojXQMbtMP244SXe3u5kmFw1XSCVHi6l
LpVW54rEypSalv4OBf/B1OsfFuQ62qkWdWtiWnFNufc9OzNualqL7hZMhBBCzeqfAAMARUSzZ7uC
WKkAAAAASUVORK5CYII="
                 transform="matrix(0.24 0 0 0.24 7.4439 9.1078)" />
          <g>
            <path class="st4"
                  d="M32.194,12.688c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                    c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.26-0.359
                    c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                    c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.066,0H11.665l-0.925,0c-0.081,0-0.161,0-0.242,0
                    c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                    c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.26,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                    c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                    h23.564v-7.64L32.194,12.688z" />
          </g>
        </g>
        <g class="st3">

          <image style="overflow:visible;opacity:0.16;"
                 width="109"
                 height="52"
                 xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAqFJREFUeNrsnNtu00AURW3HdkJK
UxAX8YAqUN/4//9BCCQQlIBQUtrGSZkj7ZGmxpNLZU9baS1pK7Xr8UNWzxkn1UyWAQBAD+Q9XQP9
c9OHxLwjkEZeOwdL9MJGSqnXApFJBG5c1i6NXte7ZJYRgXZ+4jJVxjqHyOEFmrwrlwvlUueiLbZL
Yilxz1xeKrNAJPPkcPOfF/jH5Vz5LZnNPhJ9C51I4FuXdy6nEjnV9QgcTmQjYSbvs8tH/c4q9G+s
rXZJNFmvXN67fHA5c3mNxKQSv6v7ZapMyyqYHzsl5prvKpcjVd6pBJ7peCLJSBxO4lpz4Ew+li6/
1FKXEvlfNZaR+fC5qu+NqtLaa60bw3BY23yi99na5w+XLy5fJfJK19xsq8RaEk8kbqbjWlVKFQ5f
jbm6oX+wfBE8WI5UjdF2OpKspxo0019FFbRRJA6P9+BFWlc8bk1neViNRWvwWBJPgoElApMRftEy
Vhc8ltA69kxSdFTiVCKn2wbCoCILFU8tmdW2Yio6vqmp9hkIySpytGs6K+46EJKJ3PkPiOKuA+Hh
wOc+JAISAYmARCQCEgGJgEQkAhIBiYBEJAISAYmARCQCEgGJgEQkAhIBiYBEJMJjoYycP3hDHOiN
g9/7suMGm+z2hjh+DxXWZaST6N/3deBjr82IvEBbTmyL/22h/yK4DolpJS7k4FJOoiLLlsBrDZy7
fJM4W2zKQtO0Eq0CL+RgLifXMZFly77t2GAb4XzSedtPxa8Vh3T4rVDmcnEuN80+EhcSl+kGtuzb
75pBJaZ9sFnJx085WQQSb4nMg1e/BYrtmHGkNlrRSu+1pa7UVpeqxM6Wmrd+9gv+fdhV8X5FboIn
1SY2J+Ydxyz3fmSfGWOSkPfwZAIAwLD8E2AAOBuy81Gw3f4AAAAASUVORK5CYII="
                 transform="matrix(0.24 0 0 0.24 7.4439 11.2678)" />
          <g>
            <path class="st5"
                  d="M32.194,14.83c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                    c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.26-0.359
                    c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                    c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.665l-0.925,0c-0.081,0-0.161,0-0.242,0
                    c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                    c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.26,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                    c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                    h23.564v-7.64L32.194,14.83z" />
          </g>
        </g>
        <g class="st3">

          <image style="overflow:visible;opacity:0.17;"
                 width="109"
                 height="52"
                 xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAvBJREFUeNrsnFtv00AQhX1Ngkkv
EYW+ICQET/z//4NASEBbUi5JyMVOmJHOiMF4mzRJnVQ6RzrazdbrB3+and1KO1FEURRFUZQqvscz
caBP7aYV3NTfGaKBSlybwokbp3YDuIQreOnGV5vAjO+AZ8BycSbuwF20GSNyLxFYiufiGdo5xhYO
7J0wswBAHe+JC/FTtNbvo98B5IggtwIYAZBCm4hH4jH6E9efAmpwiW2CmAHSufhC/Fw8EJ+JT9H2
EZEpAe4EskIEKsAf4p9ob8XX4hvxd8AsN4FoS2gPAF+KX4tfiV9gTCGeuEhkXtw9H1ok/gJEhXYl
/ih+j2f1ud+hZbUJYoHoU4DvxG/FlwD4BJCZE/efE6cApSC/IlgiROrM5cggRNt95sh7F4hABfgG
vwu3yUkJcK8gK7eZmSBdxciLt4jOMf7+XzRmgXw4wBJ6CYDnyIGJg0eA+wNpu/8l2hhRqXnxk/gz
QM7c0SMYiR1APHM5sABALp8P+w+XFRhEWA1tY/kMHGwjuai/IKnlww5C+dTlwLwWgdTDwfQcDOQA
+bEXSmOJ66eg3Uck2kRG4GFAdrEKngBoJ3Ska4rEouFAT4Dtgkxcnuy6DWW8DqJtbPJNJlKtRWS6
7jSQbDuRag3k2tNAsu1E6niU8BMQIkWIFCFShEiIFCFShEgRIiFShEgRIkWIhEgRIkWIFCESIkWI
FCFShEiIFCFShEgRIiFSj0RZYHzVYKod3fvbZw0v8BWOyuhvDRXey2gPon33yvHYqBiRAdTrxFrJ
QS/6j9xzhNguRCtONAWTIMisBnCOiUPxF4DTy6a8aNouRI3ACRgMwWQeApnV6GvFBq1i9AHjWhTH
7opT7alCBA7B4gZsyk0gjgAuwgv02nce8a7iITY2C/D4BiYjB/EfkL5+qZVA0YoZVpQv51J60CXV
ihONEYmNS2pc69uFfzNrtx0W5NLtVMtQTowbfvO69yM7M4YgEd7xwaQo6qj1R4ABAM5x0/sxgGqp
AAAAAElFTkSuQmCC"
                 transform="matrix(0.24 0 0 0.24 7.4439 13.4278)" />
          <g>
            <path class="st6"
                  d="M32.194,16.972c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
                    c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.26-0.359
                    c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
                    c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.665l-0.925,0c-0.081,0-0.161,0-0.242,0
                    c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
                    c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.26,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
                    c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
                    h23.564v-7.64L32.194,16.972z" />
          </g>
        </g>
        <path class="st7"
              d="M7.202,7.008v11.068H8.63v-6.605l0-0.925c0-0.081,0-0.161,0-0.242c0-0.068,0.001-0.136,0.003-0.204
                c0.004-0.148,0.013-0.297,0.039-0.443c0.027-0.148,0.07-0.286,0.139-0.421c0.067-0.132,0.155-0.254,0.26-0.359
                C9.177,8.773,9.298,8.685,9.43,8.618c0.135-0.069,0.273-0.112,0.421-0.139c0.146-0.026,0.295-0.035,0.443-0.039
                c0.068-0.002,0.136-0.003,0.204-0.003c0.081,0,0.161,0,0.242,0l0.925,0h18.348l0.071,0c0.081,0,0.161,0,0.242,0
                c0.068,0,0.136,0.001,0.204,0.003c0.148,0.004,0.297,0.013,0.443,0.039c0.148,0.027,0.286,0.07,0.421,0.139
                c0.132,0.067,0.254,0.155,0.359,0.261c0.105,0.105,0.193,0.226,0.26,0.359c0.069,0.135,0.112,0.273,0.139,0.421
                c0.026,0.146,0.035,0.295,0.039,0.443c0.002,0.068,0.003,0.136,0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925v6.605h1.428V7.008
                H7.202z" />
        <g class="st3">

          <image style="overflow:visible;opacity:0.14;"
                 width="121"
                 height="53"
                 xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAA6CAYAAACd3VbxAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABMZJREFUeNrsnQtz0kAUhTfJ8iog
2LdI7VhH6/j//5DjjLa1D22hBQrEXT13uOwECBSTEO6ZObNIKWbvt3v3EdgqJRKJRCKRKA/y1vSa
JBTmLK6pxc1bcOGu07roMOJxlhqC58RMpRwzHqvQjZs3pxK+sWb2U6iEvdAxPILpuTCiQaQF2mdl
APspxYzHbchMMYu8ILr4onHFuGq8Y1xAZbyEW6q94IFxH+WQNQD+eJwQfKo/B6wRnwLiVkKpU+jx
IeLxbPxo3DV+Quz+gtczKqUBfN/40HjPuIZKJVEJAjfCxdLF27LHGsET3EMlR/8ZPNU9QCzKiBN5
B52khsdFvDapcZ46io1Fx/jG+Mr4GsCfF0GvAfhH41PjXVQySLjF9lGBe5SPAP1g/Mv4DmUH8KdS
2ZqB05BXRnyaiIstG8Z1lK/w81LC2ZE6io3DrfFXPNdDHG1svDg93QL/bPwGrTdIsNWOWU8n4GQL
+qfxd/gSzz2iRa8TPAEvIAYW8pFxCz4E/Doz9fQkx3XqKDYGP/DcHeJEQ43SC8b0KipzbPwWrVcn
nKqGgEhpfYCefo/U9Q29i2ehdYJ3gduhrm383vjM+ATQm/h5JcUxneLVweMrMJxqfDpGRctsnKon
CJ2DHzkm8AfGr1kq9dnvdgH+JWO8xzIfAX9n/Mn4HNBbuIYdBlqzjJj0JG6Ix1WwK7jZRsdo4T6b
oeqEoVNFdMQSroaKVdjYqZzl3Zgt81aFHiB4TfRwC/wLyjYagru6SWtvI2RcZy4b9RKtPa3KeE6F
AtYQNFs28dl+n83mexGbOsuk9RImZsfo2ecAfgrg1YjelOamzEJOWm2OvAUVi5r0DSI2dJYdx6uA
a8fuD/CJs4z1Vba2Xudqk6AvAkPLuy6Wc138e8TWrnHAu++7izR+BrfxXHUTgW869Hk9kvf0sTOx
G89I994SPXxjgecB+qyxt89SO80DbiJSfhix01Z0lmZ8pn6M/6O0qcDzAj1qls334gNAqmCXqst2
p3ij0HgdpfSWM3Fr473LKeyyCfQ5kzxaT5MoA9CW6SV2qB6wzqd1fAGNoo419xHW42fYhOFLszRu
ogj0mDtnivX+BnbNLtS/mw+32NHr4XVlpG3bw/fZDmQLDaDJ1uL+JgPPG/SoiV3AxvoDwL5B+Rtj
vALQBqDvsbKJLFFW6X2mQKAvCd5nqbuBXsvv2PGeXlOTu2P0GYJyxE6bEujZH+P57dC6muzY9Z0x
vaQmN0oKanorU+UFeJ6hc0j8/kER8N1P2/BPwQQsW+QK9jZAd+HTnj2l/Kh1uspTGt9m6G4DcG/g
qLz2aIE+OwNsnXwlEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLo26g4
H5eaefKgKHOKxUoveAN+WiOdPGjlSXwzC504jRi/MA50Ak6nOtlvenbY7wj0bEPvgBkdqjgFXs8B
PsAv2u99XQB0kufIiVaDTufIXajJV7MHHLye01rsV3ntNzzp1EF7JlmSJ0aKVpN7YuQ1WA7jQO8A
tMIbJHk2rOhlEzn3bNgOgx5m+RRo0ctS/MxToLN+3rtodfBLnffOwWfhLzuI1rxm36S/4SJaHb5o
2/VHgAEAKq1w/7wLXkMAAAAASUVORK5CYII="
                 transform="matrix(0.24 0 0 0.24 6.0039 15.3478)" />
          <g>
            <path class="st8"
                  d="M26.985,17.005c-0.153,0-0.307,0-0.46,0.001c-0.129,0.001-0.258,0.002-0.387,0.006
                    c-0.281,0.008-0.565,0.024-0.843,0.074c-0.282,0.051-0.545,0.134-0.801,0.264c-0.033,0.017-0.738,0.337-1.372,1.323
                    c-0.481,0.749-1.417,1.543-2.727,1.543s-2.246-0.794-2.727-1.543c-0.667-1.039-1.428-1.351-1.373-1.323
                    c-0.256-0.131-0.519-0.213-0.801-0.264c-0.278-0.05-0.562-0.067-0.843-0.074c-0.129-0.003-0.258-0.005-0.387-0.006
                    c-0.153-0.001-0.307-0.001-0.46-0.001l-6.6,0v9.996h26.42v-9.996L26.985,17.005z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <path class="st9"
                d="M41.23,17.661h1.351l2.926,8.057h-1.312l-0.737-2.177h-3.105l-0.743,2.177h-1.312L41.23,17.661z
                   M40.688,22.536h2.429l-1.2-3.54h-0.022L40.688,22.536z" />
          <path class="st9"
                d="M46.28,19.844h1.206v1.011h0.028c0.352-0.698,1-1.122,1.837-1.122c1.497,0,2.457,1.173,2.457,3.048v0.006
                  c0,1.871-0.966,3.048-2.44,3.048c-0.832,0-1.508-0.424-1.854-1.105h-0.028v2.943H46.28V19.844z M50.58,22.787v-0.006
                  c0-1.251-0.598-2.015-1.541-2.015c-0.916,0-1.558,0.793-1.558,2.015v0.006c0,1.218,0.648,2.01,1.558,2.01
                  C49.987,24.797,50.58,24.026,50.58,22.787z" />
          <path class="st9"
                d="M52.999,19.844h1.206v1.011h0.028c0.352-0.698,0.999-1.122,1.837-1.122c1.497,0,2.457,1.173,2.457,3.048
                  v0.006c0,1.871-0.966,3.048-2.44,3.048c-0.832,0-1.508-0.424-1.854-1.105h-0.028v2.943h-1.206V19.844z M57.298,22.787v-0.006
                  c0-1.251-0.598-2.015-1.541-2.015c-0.916,0-1.558,0.793-1.558,2.015v0.006c0,1.218,0.648,2.01,1.558,2.01
                  C56.706,24.797,57.298,24.026,57.298,22.787z" />
          <path class="st9"
                d="M59.59,17.661h1.207v8.057H59.59V17.661z" />
          <path class="st9"
                d="M61.97,22.803v-0.006c0-1.837,1.061-3.065,2.709-3.065s2.658,1.183,2.658,2.948v0.408h-4.159
                  c0.022,1.111,0.631,1.758,1.591,1.758c0.715,0,1.19-0.368,1.341-0.809l0.017-0.045h1.144l-0.011,0.062
                  c-0.189,0.932-1.095,1.781-2.518,1.781C63.015,25.835,61.97,24.657,61.97,22.803z M63.194,22.234h2.948
                  c-0.101-1.016-0.67-1.513-1.457-1.513C63.902,20.721,63.299,21.251,63.194,22.234z" />
          <path class="st9"
                d="M71.171,17.661h1.301l1.503,6.315h0.022l1.703-6.315h1.183l1.703,6.315h0.028l1.502-6.315h1.302
                  l-2.178,8.057h-1.2l-1.731-6.075H76.28l-1.737,6.075h-1.201L71.171,17.661z" />
          <path class="st9"
                d="M81.761,24.048v-0.011c0-1.022,0.794-1.647,2.184-1.731l1.596-0.095V21.77c0-0.647-0.419-1.039-1.167-1.039
                  c-0.698,0-1.128,0.33-1.228,0.799l-0.012,0.051h-1.138l0.005-0.062c0.084-1.011,0.961-1.787,2.407-1.787
                  c1.435,0,2.345,0.76,2.345,1.937v4.048h-1.212v-0.927h-0.022c-0.341,0.631-1.021,1.033-1.798,1.033
                  C82.549,25.824,81.761,25.104,81.761,24.048z M84.05,24.859c0.848,0,1.491-0.575,1.491-1.341V23.06l-1.435,0.09
                  c-0.725,0.044-1.128,0.362-1.128,0.859v0.012C82.978,24.534,83.403,24.859,84.05,24.859z" />
          <path class="st9"
                d="M88.074,17.661h1.206v8.057h-1.206V17.661z" />
          <path class="st9"
                d="M90.979,17.661h1.206v8.057h-1.206V17.661z" />
          <path class="st9"
                d="M93.245,22.803v-0.006c0-1.837,1.06-3.065,2.708-3.065c1.648,0,2.659,1.183,2.659,2.948v0.408h-4.16
                  c0.022,1.111,0.631,1.758,1.592,1.758c0.715,0,1.189-0.368,1.34-0.809l0.018-0.045h1.144l-0.011,0.062
                  c-0.19,0.932-1.095,1.781-2.519,1.781C94.289,25.835,93.245,24.657,93.245,22.803z M94.468,22.234h2.949
                  c-0.101-1.016-0.67-1.513-1.458-1.513C95.177,20.721,94.574,21.251,94.468,22.234z" />
          <path class="st9"
                d="M100.142,24.171v-3.367h-0.844v-0.961h0.844v-1.53h1.233v1.53h1.1v0.961h-1.1v3.294
                  c0,0.598,0.269,0.776,0.776,0.776c0.129,0,0.234-0.012,0.324-0.022v0.932c-0.14,0.022-0.368,0.05-0.614,0.05
                  C100.7,25.835,100.142,25.344,100.142,24.171z" />
        </g>
        <g>
          <g>
            <path class="st9"
                  d="M42.193,12.483h-2.408l-0.608,1.733h-0.793l2.232-6.054h0.747l2.232,6.054h-0.793L42.193,12.483z
                  M40.008,11.841h1.964l-0.949-2.702h-0.067L40.008,11.841z" />
            <path class="st9"
                  d="M44.074,11.954c0-1.418,0.751-2.341,1.901-2.341c0.629,0,1.162,0.298,1.418,0.793h0.063V7.897h0.722v6.318
                      h-0.688v-0.722h-0.067c-0.285,0.503-0.822,0.801-1.448,0.801C44.817,14.295,44.074,13.376,44.074,11.954z M44.821,11.954
                      c0,1.057,0.495,1.691,1.322,1.691c0.822,0,1.33-0.646,1.33-1.691c0-1.036-0.512-1.691-1.33-1.691
                      C45.32,10.263,44.821,10.901,44.821,11.954z" />
            <path class="st9"
                  d="M49.342,11.954c0-1.418,0.751-2.341,1.901-2.341c0.629,0,1.162,0.298,1.418,0.793h0.063V7.897h0.722v6.318
                    h-0.688v-0.722H52.69c-0.285,0.503-0.822,0.801-1.448,0.801C50.084,14.295,49.342,13.376,49.342,11.954z M50.088,11.954
                    c0,1.057,0.495,1.691,1.322,1.691c0.822,0,1.33-0.646,1.33-1.691c0-1.036-0.512-1.691-1.33-1.691
                    C50.587,10.263,50.088,10.901,50.088,11.954z" />
            <path class="st9"
                  d="M58.274,8.522v1.171h1.008v0.604h-1.008v2.56c0,0.533,0.202,0.759,0.672,0.759
                    c0.13,0,0.201-0.004,0.336-0.017v0.608c-0.144,0.025-0.281,0.042-0.424,0.042c-0.931,0-1.305-0.344-1.305-1.208v-2.744h-0.729
                    V9.693h0.729V8.522H58.274z" />
            <path class="st9"
                  d="M60.072,11.954c0-1.456,0.798-2.341,2.086-2.341s2.085,0.885,2.085,2.341c0,1.451-0.797,2.341-2.085,2.341
                    S60.072,13.405,60.072,11.954z M63.496,11.954c0-1.074-0.483-1.691-1.339-1.691c-0.856,0-1.339,0.617-1.339,1.691
                    c0,1.07,0.482,1.691,1.339,1.691C63.013,13.645,63.496,13.024,63.496,11.954z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="stylus">

.locale
  &-fr
    .st0
    fill #A6A6A6

    .st1
      clip-path url(#SVGID_00000099635824966500508100000003095773382220559261_)

    .st2
      fill #DEDBCE

    .st3
      fill-rule evenodd
      clip-rule evenodd
      fill #40A5D9

    .st4
      fill-rule evenodd
      clip-rule evenodd
      fill #FFB003

    .st5
      fill-rule evenodd
      clip-rule evenodd
      fill #40C740

    .st6
      fill-rule evenodd
      clip-rule evenodd
      fill #F26D5F

    .st7
      fill-rule evenodd
      clip-rule evenodd
      fill #D9D6CC

    .st8
      fill-rule evenodd
      clip-rule evenodd
      fill #DEDBCE

    .st9
      fill #FFFFFF

  &-de
    .st0
    fill #A6A6A6

    .st1
      clip-path url(#SVGID_00000036241015773612366540000011328654630077282433_)

    .st2
      fill: #DEDBCE

    .st3
      fill-rule:evenodd
      clip-rule evenodd
      fill #40A5D9

    .st4
      fill-rule evenodd
      clip-rule evenodd
      fill #FFB003

    .st5
      fill-rule evenodd
      clip-rule evenodd
      fill #40C740

    .st6
      fill-rule:evenodd
      clip-rule evenodd
      fill #F26D5F

    .st7
      fill-rule evenodd
      clip-rule evenodd
      fill #D9D6CC

    .st8
      fill-rule evenodd
      clip-rule evenodd
      fill #DEDBCE

    .st9
      fill: #FFFFFF

  &-en
    .st0
    fill #A6A6A6

    .st1
      clip-path url(#SVGID_2_)
      fill #DEDBCE

    .st2
      clip-path url(#SVGID_2_)
      fill-rule evenodd
      clip-rule evenodd
      fill #40A5D9

    .st3
      clip-path url(#SVGID_2_)

    .st4
      fill-rule evenodd
      clip-rule evenodd
      fill #FFB003

    .st5
      fill-rule evenodd
      clip-rule evenodd
      fill #40C740

    .st6
      fill-rule evenodd
      clip-rule evenodd
      fill #F26D5F

    .st7
      clip-path url(#SVGID_2_)
      fill-rule evenodd
      clip-rule evenodd
      fill #D9D6CC

    .st8
      fill-rule evenodd
      clip-rule evenodd
      fill #DEDBCE

    .st9
      fill #FFFFFF
</style>
