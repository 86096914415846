<template>
  <modal-dialog-prompt data-cy="validate-toggle-transfer.container"
                       :title="title"
                       :subtitle="content"
                       @close="$emit('close')">
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              data-cy="validate-toggle-transfer.cancel"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <button type="button"
              class="btn btn--primary"
              :disabled="loading"
              data-cy="validate-toggle-transfer.confirm"
              @click="onValidate">
        {{ $t('button.validate') }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: { ModalDialogPrompt },
  props: {
    transfer: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  data () {
    return {
      loading: false
    }
  },

  computed: {
    title () {
      return this.transfer.enabled
        ? this.$i18n.t('prompt.permanent_transfer.suspend.title')
        : this.$i18n.t('prompt.permanent_transfer.resume.title')
    },

    content () {
      return this.transfer.enabled
        ? this.$i18n.t('prompt.permanent_transfer.suspend.content')
        : this.$i18n.t('prompt.permanent_transfer.resume.content')
    }
  },

  methods: {
    async onValidate () {
      this.loading = true
      this.$emit('close')
      await this.$bus.emit('validate-toggle', this.transfer)
      this.loading = false
    }
  }
}
</script>
