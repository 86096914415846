<template>
  <div class="chart-filter">
    <component-dropdown ref="dropdown"
                        dropdown-width="220px"
                        dropdown-position="right"
                        :dropdown-height="24"
                        :values="values"
                        :model="selected.name"
                        :data-cy="childDataCy"
                        :can-reset-value="false"
                        :label-filter="item => $t(item.label)"
                        @select="onDropdownSelect">
      <template #trigger>
        <div class="select">
          <div class="form-control form-control--sm">
            {{ selected?.label ? $t(selected.label) : '' }}
          </div>
        </div>
      </template>
    </component-dropdown>
    <month-period-picker v-if="customPeriodType === 'month'"
                         ref="custom-period-picker"
                         :period="modelValue"
                         :max-period="maxCustomPeriod"
                         :disabled-months="disabledDates"
                         :data-cy="childDataCy"
                         @cancel="onCustomPeriodCancel"
                         @period-select="onCustomPeriodSelect" />
    <day-period-picker v-else-if="customPeriodType === 'day'"
                       ref="custom-period-picker"
                       :period="modelValue"
                       :max-month-period="maxCustomPeriod"
                       :disabled-days="disabledDates"
                       :data-cy="childDataCy"
                       @cancel="onCustomPeriodCancel"
                       @period-select="onCustomPeriodSelect" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { ETreasuryPeriodFilter } from '@/types/treasury.d'

import DayPeriodPicker from '@/components/DayPeriodPicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import MonthPeriodPicker from '@/components/MonthPeriodPicker.vue'

export default {
  components: {
    ComponentDropdown,
    DayPeriodPicker,
    MonthPeriodPicker
  },

  props: {
    childDataCy: {
      type: String,
      default: ''
    },

    customPeriodType: {
      type: [String, null],
      default: null
    },

    maxCustomPeriod: {
      type: Number,
      default: null
    },

    modelValue: {
      type: Object,
      required: true
    },

    values: {
      type: Array,
      required: true
    }
  },

  emits: ['update:model-value'],

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  data () {
    return {
      selected: {}
    }
  },

  computed: {
    disabledDates () {
      if (this.customPeriodType === 'month') {
        return {
          after: dayjs().subtract(1, 'month').format('YYYY-MM'),
          before: dayjs(this.account.opening_date, 'YYYY-MM-DD').format('YYYY-MM')
        }
      } else if (this.customPeriodType === 'day') {
        return {
          after: dayjs().subtract(1, 'day'),
          before: this.account.opening_date
        }
      }
      return null
    }
  },

  watch: {
    modelValue () {
      if (!this.modelValue) {
        this.selected = {}
      }
    }
  },

  mounted () {
    this.selected = this.values.find(item => item.name === this.modelValue.periodFilter)
  },

  methods: {
    onCustomPeriodCancel () {
      this.$refs.dropdown.toggleDropdown()
    },

    onCustomPeriodSelect (value) {
      this.selected = this.values.find(item => item.name === ETreasuryPeriodFilter.Custom)
      this.$emit('update:model-value', {
        from: value.from,
        periodFilter: ETreasuryPeriodFilter.Custom,
        to: value.to
      })
    },

    onDropdownSelect (value) {
      if (value.name === ETreasuryPeriodFilter.Custom) {
        this.$refs['custom-period-picker'].togglePicker(true)
      } else {
        this.selected = value
        this.$emit('update:model-value', {
          from: null,
          periodFilter: value.name,
          to: null
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.chart-filter
  position relative
</style>
