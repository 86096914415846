<template>
  <line-chart :title="$t('treasury.evolution.title')"
              hide-point
              :datasets="data">
    <template #filter>
      <chart-filter v-model="formFilter"
                    custom-period-type="month"
                    :max-custom-period="24"
                    :values="getFilter"
                    child-data-cy="treasury.evolution.filter" />
      <loader-spinner v-if="loading"
                      size="16" />
    </template>
  </line-chart>
</template>

<script>
import dayjs from 'dayjs'

import { useTreasuryStore } from '@/stores/treasury'
import { ETreasuryPeriodFilter } from '@/types/treasury.d'

import ChartFilter from '@/components/charts/ChartFilter.vue'
import LineChart from '@/components/charts/LineChart.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  name: 'TreasuryEvolution',
  components: { LoaderSpinner, ChartFilter, LineChart },

  setup () {
    const treasuryStore = useTreasuryStore()

    return { treasuryStore }
  },

  data () {
    return {
      loading: false,
      formFilter: {
        from: null,
        to: null,
        periodFilter: ETreasuryPeriodFilter.LastTwelveMonths
      },

      evolution: null
    }
  },

  computed: {
    getFilter () {
      return [ETreasuryPeriodFilter.LastTwelveMonths, ETreasuryPeriodFilter.Year, ETreasuryPeriodFilter.Custom].map(name => {
        return { label: `treasury.filter.${name}`, name }
      })
    },

    data () {
      return [{
        label: '',
        data: this.evolution ? this.evolution.map(item => ({ x: item.balanceDateFormatted, y: item.balance, xTooltip: this.$tc('treasury.tooltip.date.at', 0, { date: item.balanceDateTooltipFormatted }), yTooltip: item.balanceFormatted })) : []
      }]
    }
  },

  watch: {
    formFilter: {
      handler () {
        this.initData()
      },

      deep: true
    }
  },

  mounted () {
    this.initData()

    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      this.loading = true

      const params = {}
      if (this.formFilter.periodFilter === ETreasuryPeriodFilter.Custom) {
        params.from = dayjs(this.formFilter.from).format('YYYY-MM')
        params.to = dayjs(this.formFilter.to).format('YYYY-MM')
      } else {
        params.period_filter = this.formFilter.periodFilter
      }

      this.evolution = await this.treasuryStore.getTreasuryEvolution(params)
      this.loading = false
    }
  }
}
</script>
