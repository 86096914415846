<template>
  <section class="section section--empty">
    <div class="section__content">
      <div class="placeholder">
        <div v-if="account"
             class="placeholder__inner">
          <figure class="placeholder__img">
            <component :is="getIcon"
                       height="160px"
                       width="200px" />
          </figure>
          <h4>{{ getTitle }}</h4>
          <p class="pre-line">
            {{ getMessage }}
          </p>
          <template v-if="isAccountClosing && numberOfStatements">
            <component-button wrapper-class="btn btn--outline"
                              :disabled="loading"
                              :label="$t('button.download_all_statements')"
                              @click="downloadStatements" />
          </template>
          <template v-else-if="isInvitationRefused">
            <component-button :label="$t('button.attach_id')"
                              icon
                              wrapper-class="btn btn--outline"
                              @click="appStore.showDropzoneModal($tc('title.attach_file'), {}, { url: '/user/identity-card', maxFiles: 3 })">
              <ic-attachment class="ic ic--gray" />
            </component-button>
          </template>
        </div>
        <div v-else
             class="placeholder__inner">
          <figure class="placeholder__img">
            <restricted-access width="160px"
                               height="128px" />
          </figure>
          <h4>{{ $t("placeholder.signup.title") }}</h4>
          <p>{{ $t("placeholder.signup.subtitle") }}</p>
          <a :href="links.SIGNUP"
             class="btn btn--secondary">{{ $t("button.open_account") }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import links from '@/config/links'
import store from '@/config/store'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useStatementStore } from '@/stores/statement'

import ComponentButton from '@/components/Button.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import DocsWarning from '@/components/svg/img/docs-warning.vue'
import Guarantee from '@/components/svg/img/guarantee.vue'
import RestrictedAccess from '@/components/svg/img/restricted-access.vue'

export default {
  components: {
    IcAttachment,
    RestrictedAccess,
    ComponentButton
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const statementStore = useStatementStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore, statementStore }
  },

  data () {
    return {
      store,
      loading: false,
      numberOfStatements: null,
      links
    }
  },

  computed: {
    isInvitationRefused () {
      return this.account.invitation_status === 'refused'
    },

    isAccountClosing () {
      return this.account.status === 'closing'
    },

    getRestrictedAccessInfo () {
      if (this.isInvitationRefused) {
        return {
          title: this.$i18n.t('placeholder.restricted_access.invitation_refused.title'),
          message: this.$i18n.t('placeholder.restricted_access.invitation_refused.message'),
          icon: DocsWarning
        }
      }

      let info
      if (store.translations.placeholder.restricted_access[this.account.status_group] !== undefined) {
        info = {
          title: this.$t(`placeholder.restricted_access.${this.account.status_group}.title`),
          message: this.$t(`placeholder.restricted_access.${this.account.status_group}.message`)
        }
      } else if (this.account.status === 'sequestre') {
        info = {
          title: this.$t('placeholder.restricted_access.sequestre.title'),
          message: this.$t('placeholder.restricted_access.sequestre.message')
        }
      } else if (this.account.status_group === 'valid' && this.account.status === 'valid' && this.account.invitation_status === 'to_validate') {
        info = {
          title: this.$t('placeholder.restricted_access.user_pending.title'),
          message: this.$t('placeholder.restricted_access.user_pending.message')
        }
      } else if (this.account.status_group === 'valid' && this.account.status === 'valid' && this.account.invitation_status === 'to_validate_by_bank') {
        info = {
          title: this.$t('placeholder.restricted_access.request_pending_validation.title'),
          message: this.$t('placeholder.restricted_access.request_pending_validation.message'),
          icon: Guarantee
        }
      } else {
        info = {
          title: this.$t('placeholder.restricted_access.default.title'),
          message: this.$t('placeholder.restricted_access.default.message')
        }
      }

      return {
        title: info.title,
        message: info.message,
        icon: RestrictedAccess
      }
    },

    getTitle () {
      return this.getRestrictedAccessInfo.title
    },

    getMessage () {
      return this.getRestrictedAccessInfo.message
    },

    getIcon () {
      return this.getRestrictedAccessInfo.icon
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('on-upload-success', this.onUploadSuccess)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('on-upload-success', this.onUploadSuccess)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      if (this.isAccountClosing) {
        this.loading = true
        await this.assignNumberOfStatements()
        this.loading = false
      }
    },

    onUploadSuccess (response) {
      if (this.account.type === 'capital_gain') return

      if (this.isInvitationRefused) {
        this.account.invitation_status = 'to_validate'
      }

      if (response.url?.includes('status')) {
        this.account.company.status_url = response.url
      } else if (response.url?.includes('proof_of_address')) {
        this.account.company.address_proof_url = response.url
      }
    },

    async assignNumberOfStatements () {
      this.loading = true
      this.numberOfStatements = await this.statementStore.getNumberOfStatements()
      this.loading = false
    },

    async downloadStatements () {
      this.loading = true
      const message = await this.statementStore.downloadAllStatements()
      this.loading = false
      if (message) {
        showToastSuccess(message)
      }
    }
  }
}

</script>
