<template>
  <div class="modal__content">
    <modal-header :title="$t('title.contact_form')"
                  close-button
                  @close="$emit('close')" />
    <validation-form v-slot="{ errors, isSubmitting }"
                     :validation-schema="schema"
                     class="form"
                     @submit="onSubmit">
      <div class="modal__body">
        <p class="modal-subtitle pre-line mb-4">
          {{ $t('modal.contact.text') }}
        </p>
        <div v-if="isContact"
             class="form__row row">
          <div class="form-group col col--sm"
               :class="{ error: errors.email }">
            <label class="label input__label"
                   for="subject">{{ $t("form.email.label") }}</label>
            <validation-field id="email"
                              name="email"
                              type="text"
                              class="form-control"
                              :placeholder="$t('form.email.placeholder')" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm"
               :class="{ error: errors.subject }">
            <label class="label input__label"
                   for="subject">{{ $t("form.subject.label") }}</label>
            <validation-field id="subject"
                              type="text"
                              class="form-control"
                              :placeholder="$t('form.subject.placeholder')"
                              name="subject" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm"
               :class="{ error: errors.message }">
            <label class="label input__label"
                   for="message">{{ $t("form.message.label") }}</label>
            <validation-field v-slot="{ field }"
                              name="message">
              <textarea id="message"
                        v-bind="field"
                        class="form-control textarea-scrollbar"
                        rows="5"
                        :placeholder="$t('form.message.placeholder')" />
            </validation-field>
          </div>
        </div>
      </div>
      <div class="modal__footer">
        <div class="modal__footer__left" />
        <div class="modal__footer__right">
          <button type="button"
                  class="btn btn--gray"
                  @click.prevent="$emit('close')">
            {{ $t("button.cancel") }}
          </button>
          <button type="submit"
                  class="btn btn--primary"
                  :disabled="isSubmitting">
            {{ $t("button.send") }}
          </button>
        </div>
      </div>
    </validation-form>
  </div>
</template>

<script>
import * as yup from 'yup'

import { showToastSuccess } from '@/helpers/utils/notification'
import { ModelMessage } from '@/models/Message/ModelMessage'
import { useMessageStore } from '@/stores/message'
import { useUserStore } from '@/stores/user'

import ModalHeader from '@/components/modal-template/ModalHeader.vue'

export default {
  name: 'ComponentContact',

  components: {
    ModalHeader
  },

  props: {
    isContact: {
      type: Boolean,
      default: false
    }
  },

  emits: ['close'],

  setup () {
    const userStore = useUserStore()
    const messageStore = useMessageStore()
    const message = ModelMessage.create()

    return { userStore, message, messageStore }
  },

  computed: {
    schema () {
      return yup.object({
        email: this.isContact ? yup.string().required().email() : yup.string().nullable(),
        subject: yup.string().required(),
        message: yup.string().required()
      })
    }
  },

  methods: {

    async onSubmit (values) {
      this.message.setFields({ subject: values.subject, content: values.message })

      const success = this.isContact
        ? await this.userStore.contact(values.email, values.subject, values.message)
        : await this.messageStore.sendMessage(this.message)

      if (success) {
        this.$emit('close')

        if (success?.message) showToastSuccess(success.message)
        else showToastSuccess(this.$i18n.t('message.success.message.send'))
      }
    }
  }
}
</script>
