<template>
  <svg viewBox="0 0 92 92">
    <path d="M14.668 18.505h62.664a2.994 2.994 0 0 1 2.3.89 3.01 3.01 0 0 1 .863 2.287l-.001 39.604a3.028 3.028 0 0 1-.862 2.318 2.977 2.977 0 0 1-2.272.892H53.644l.782 5.237c.514 2.328 2.295 4.28 4.167 4.28v1H32.989v-1c1.872 0 3.653-1.952 4.161-4.247l.788-5.27h-23.27a2.994 2.994 0 0 1-2.3-.892 3.006 3.006 0 0 1-.863-2.286l.001-39.604a3.028 3.028 0 0 1 .862-2.318 2.994 2.994 0 0 1 2.3-.891Zm62.692.999H14.613a1.994 1.994 0 0 0-1.532.593 2.05 2.05 0 0 0-.576 1.585V54h66.99V21.65a2.013 2.013 0 0 0-2.136-2.146ZM79.495 55h-66.99v6.35a2.013 2.013 0 0 0 2.136 2.146h62.746a1.994 1.994 0 0 0 1.532-.593 2.05 2.05 0 0 0 .576-1.585V55ZM53.443 69.914l-.809-5.414H38.947l-.814 5.448c-.356 1.612-1.237 3.08-2.394 4.016l-.063.048h20.232l-.066-.05c-1.1-.893-1.95-2.27-2.341-3.804l-.058-.244Z"
          :fill="colorDark" />
    <path d="M35.899 30.947v-1h-15.13v1H35.9Zm-3.492 4.672v-1H20.77v1h11.638Zm9.31 3.672v1H20.77v-1h20.949ZM35.9 44.964v-1H20.77v1H35.9Z"
          :fill="colorGrey" />
    <path d="M63.096 45.54a19.01 19.01 0 0 1-2.658 1.375 18.676 18.676 0 0 1-3.273-1.776l-.148-.101a1.169 1.169 0 0 0-.122-.075 1.686 1.686 0 0 1-.132-.104 2.716 2.716 0 0 0-.104-.08 16.872 16.872 0 0 1-1.643-1.389 17.83 17.83 0 0 1-.496-.504 8.676 8.676 0 0 1-.226-.25l-.057-.064-.056-.064a11.423 11.423 0 0 1-.867-1.168 10.61 10.61 0 0 1-.408-.69l-.079-.147-.032-.064-.04-.078a12.284 12.284 0 0 1-.266-.635 7.432 7.432 0 0 1-.335-1.234 24.609 24.609 0 0 1-.06-.518c-.022-.3-.025-.558-.018-1.147.002-.212.002-.307.002-.426v-5.918c0-.131.002-.237.006-.48l.004-.269.802-.254.573-.181 1.038-.33a3130.958 3130.958 0 0 1 5.086-1.606l.857-.27 1.29.406.084.027a4098.67 4098.67 0 0 1 4.055 1.281 5201.786 5201.786 0 0 1 2.924.924v7.576c0 .15-.016.495-.049 1.026l-.01.078c-.13.598-.25 1.05-.343 1.304-.03.081-.055.146-.114.295a6.89 6.89 0 0 1-.086.194l-.044.096-.013.028a12.002 12.002 0 0 1-.237.449 10.945 10.945 0 0 1-1.204 1.728l-.047.053c-.03.035-.045.052-.064.077l-.018.018-.045.05-.041.046-.122.133c-.323.346-.668.68-1.034 1.002-.275.242-1.15.925-1.412 1.118l-.213.145a17.29 17.29 0 0 1-.605.393Zm1.317-11.57-5.595 4.968-2.098-2.191a.46.46 0 0 0-.65-.015.457.457 0 0 0-.015.648l2.405 2.511a.46.46 0 0 0 .638.026l5.927-5.262a.457.457 0 0 0 .037-.647.46.46 0 0 0-.649-.038Z"
          :fill="colorPrimary" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
