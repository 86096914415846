<template>
  <modal-dialog-prompt :title="$t('prompt.leave.title')"
                       :subtitle="$t('prompt.leave.content')"
                       @close="$emit('close')">
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.no') }}
      </button>
      <button type="button"
              class="btn btn--primary"
              @click="leave">
        {{ $t('button.yes') }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { clearInputs } from '@/helpers/utils'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  name: 'ComponentLeave',

  components: {
    ModalDialogPrompt
  },

  props: {
    method: {
      type: Function,
      default: null
    },

    location: {
      type: Object,
      default: null
    }
  },

  emits: ['close'],

  methods: {
    leave () {
      clearInputs()
      this.$emit('close')

      if (this.location) {
        this.$router.push(this.location)
      } else if (this.method) {
        this.method()
      }
    }
  }
}
</script>
