<template>
  <div class="placeholder-action-description">
    <component-placeholder :label="$t('placeholder.sca_app.ask_user.title')"
                           :content="$t('placeholder.sca_app.ask_user.subtitle')">
      <image-mobile-app sca />
    </component-placeholder>
    <div class="center">
      <router-link :to="{ name: 'account-sca-configure' }"
                   class="btn btn--default placeholder-btn">
        {{ $t('button.ok_understood') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageMobileApp from '@/components/svg/img/mobile-app.vue'

export default {
  components: {
    ComponentPlaceholder,
    ImageMobileApp
  }
}
</script>
