<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.mileages') }}
        </h1>
        <tabs v-if="isOwner"
              is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="numberOfResults"
                       @filters="filters" />
    <router-view :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { useMileageStore } from '@/stores/mileage'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    ComponentFilters,
    Tabs,
    ButtonFilters
  },

  setup () {
    const mileageStore = useMileageStore()

    return { mileageStore }
  },

  data () {
    return {
      numberOfResults: null,
      filter: {
        search: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.business_trips'),
          route: { name: 'mileages' },
          active: false
        },
        {
          label: this.$i18n.t('general.users'),
          route: { name: 'mileage-users' },
          active: true
        }
      ]
    },

    isOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('language-changed', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('language-changed', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        search: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search
      }
    }
  }
}
</script>
