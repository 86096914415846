<template>
  <div class="fileviewer"
       data-cy="file-viewer">
    <div style="display: flex; flex: 1; max-height: 100vh">
      <div class="fileviewer__container">
        <template v-if="document">
          <div v-if="fileViewer.dataModel.documents_status === 'invalid'"
               class="fileviewer__banner fileviewer__banner--danger">
            <ic-error class="ic ic--error ic--16" />
            <span>
              {{ $t('message.warning.invalid_proof') }}
            </span>
          </div>
          <div class="fileviewer-topbar">
            <button type="button"
                    class="fileviewer-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click.prevent="close">
              <ic-close class="ic ic--18 ic--gray" />
            </button>
            <div class="fileinfo">
              <div class="filename">
                <h2 class="filename__label">
                  {{ filename }}
                </h2>
                <popover v-if="canEditDocument && fileViewer.operationId"
                         ref="popover"
                         trigger="click"
                         popover-class="popover--center popover-rename"
                         position-arrow-x="center">
                  <template #trigger="slotProps">
                    <button v-tooltip="{ content: slotProps.popoverActive ? false : $t('tooltip.rename'), theme: 'tooltip' }"
                            class="btn-link">
                      <ic-pencil class="ic ic--18 ic--gray" />
                    </button>
                  </template>
                  <template #default="slotProps">
                    <popover-rename :name="filename"
                                    :url="`${fileViewer.documentsUrl}/${document.id}`"
                                    :popover-active="slotProps.popoverActive"
                                    :data-key="'filename'"
                                    @renamed="onFileRenamed"
                                    @close-popover="closePopover" />
                  </template>
                </popover>
              </div>
              <span v-if="document.updated_at"
                    class="filedate">{{ $t('general.added_on') }} {{ formatDateText(document.updated_at, 'LLL') }} <template v-if="document.user"> {{ $t('general.by') }} {{ document.user.first_name }} {{ document.user.last_name }}</template></span>
            </div>
            <div v-if="!fileNotFound"
                 class="file-actions">
              <button v-if="canEditDocument"
                      v-tooltip="{ content: $t('tooltip.replace_file'), theme: 'tooltip' }"
                      type="button"
                      class="file-actions__btn"
                      @click="editFile">
                <ic-attachment class="ic" />
              </button>
              <button v-if="canEditDocument || fileViewer.isLocal"
                      v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                      type="button"
                      class="file-actions__btn"
                      @click="deleteFile">
                <ic-trash class="ic" />
              </button>
              <button v-if="isReadable && !isLoading && !isDeviceMobile"
                      v-tooltip="{ content: $t('tooltip.print'), theme: 'tooltip' }"
                      type="button"
                      class="file-actions__btn"
                      @click="printFile">
                <ic-print class="ic" />
              </button>
              <button v-if="hasMultipleDocuments && isReadable && !isLoading && !fileViewer.isLocal"
                      class="file-actions__btn">
                <popover trigger="hover"
                         popover-class="popover-actions-menu popover--pull-right">
                  <template #trigger>
                    <button type="button"
                            class="file-actions__btn">
                      <ic-download class="ic" />
                    </button>
                  </template>
                  <div class="popover__item"
                       @click="downloadFile">
                    {{ $t('button.download_file') }}
                  </div>
                  <div class="popover__item"
                       @click="downloadAllFiles">
                    {{ $t('button.download_all') }}
                  </div>
                </popover>
              </button>
              <button v-else-if="isReadable && !isLoading && !fileViewer.isLocal"
                      v-tooltip="{ content: $t('tooltip.download'), theme: 'tooltip' }"
                      type="button"
                      class="file-actions__btn"
                      @click="downloadFile">
                <ic-download class="ic" />
              </button>
            </div>
          </div>
          <div class="fileviewer-content">
            <button v-if="fileViewer.isMultiDoc && canEditDocument"
                    v-tooltip="{ content: $t('tooltip.add_files'), theme: 'tooltip' }"
                    class="btn-circle btn-circle--lg btn-add-document btn--midgray"
                    @click="addFiles">
              <ic-add-document class="ic ic--white" />
            </button>
            <div v-if="isLoading || !isReadable || fileNotFound"
                 class="placeholder">
              <div class="placeholder__inner">
                <loader-spinner v-if="isLoading"
                                size="38" />
                <template v-if="fileNotFound">
                  <h4>{{ $t('placeholder.file_not_found.title') }}</h4>
                  <p>{{ $t('placeholder.file_not_found.subtitle') }}</p>
                </template>
                <template v-else-if="!isReadable">
                  <h4>{{ $t('placeholder.file_not_recognized.title') }}</h4>
                  <p>{{ $t('placeholder.file_not_recognized.subtitle') }}</p>
                  <button class="btn btn--default"
                          @click="downloadFile">
                    {{ $t('button.download') }}
                  </button>
                </template>
              </div>
            </div>
            <div v-show="isImage && isReadable && !isLoading"
                 class="fileviewer-preview-container overflow-scrollbar">
              <img ref="imageView"
                   :src="url"
                   :width="imageWidth.current">
            </div>
            <div v-show="!isImage && isReadable && !isLoading"
                 id="viewerContainer"
                 ref="viewerContainer"
                 class="overflow-scrollbar">
              <div id="viewer"
                   class="pdfViewer" />
            </div>
            <div v-show="(isReadable || hasMultipleDocuments) && !isLoading"
                 id="toolbar"
                 class="fileviewer-toolbar fileviewer-toolbar--is-hidden">
              <div class="fileviewer-toolbar-content">
                <template v-if="hasMultipleDocuments">
                  <div class="toolbar-btn-item"
                       @click.prevent="getPreviousDocument">
                    <ic-chevron left
                                zoom
                                class="ic ic--16 ic--white" />
                  </div>
                  <div style="color: white">
                    {{ index + 1 }}&nbsp;/&nbsp;{{ fileViewer.content.length }}
                  </div>
                  <div class="toolbar-btn-item"
                       @click.prevent="getNextDocument">
                    <ic-chevron right
                                zoom
                                class="ic ic--16 ic--white" />
                  </div>
                </template>
                <div id="zoom-out"
                     class="zoom-out toolbar-btn-item"
                     :class="{ disabled: scale <= zoomValues[0] }"
                     @click.prevent="zoom(zoomValues.findLast(v => v < scale))">
                  <ic-zoom out
                           class="ic ic--20 ic--white" />
                </div>
                <div id="zoom-in"
                     class="zoom-in toolbar-btn-item"
                     :class="{ disabled: scale >= zoomValues[zoomValues.length - 1] }"
                     @click.prevent="zoom(zoomValues.find(v => v > scale))">
                  <ic-zoom class="ic ic--20 ic--white" />
                </div>
                <div v-if="!isImage"
                     id="zoom-mode"
                     class="zoom-mode toolbar-btn-item"
                     @click.prevent="setFitMode(fitButton)">
                  <ic-fit-page v-bind="{ [fitButton]: true }"
                               class="ic ic--20 ic--white" />
                </div>
                <div id="rotate-90"
                     class="rotate-90 toolbar-btn-item"
                     @click.prevent="rotate">
                  <ic-rotate90 class="ic ic--20 ic--white" />
                </div>
                <template v-if="!isImage && pageCount > 1">
                  <div id="prev-page"
                       class="page-up toolbar-btn-item"
                       :class="{ disabled: page === 1 }"
                       @click.prevent="turnPdfPage(page - 1)" />
                  <div id="page-number"
                       class="file-paging">
                    Page {{ page }} / {{ pageCount }}
                  </div>
                  <div id="next-page"
                       class="page-down toolbar-btn-item"
                       :class="{ disabled: page === pageCount }"
                       @click.prevent="turnPdfPage(page + 1)" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <component-placeholder v-else
                               :model="fileViewer.dataModel"
                               @close="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { toRaw } from 'vue'
import EXIF from 'exif-js'
import modulo from 'just-modulo'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf.min'
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.min?worker'
import { EventBus, PDFViewer } from 'pdfjs-dist/legacy/web/pdf_viewer'
import { storeToRefs } from 'pinia'
import printJS from 'print-js-updated'

import axiosClient from '@/api'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDateText } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcAddDocument from '@/components/svg/icons/ic-add-document.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcClose from '@/components/svg/icons/ic-close.vue'
import IcDownload from '@/components/svg/icons/ic-download.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcFitPage from '@/components/svg/icons/ic-fit-page.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcPrint from '@/components/svg/icons/ic-print.vue'
import IcRotate90 from '@/components/svg/icons/ic-rotate-90.vue'
import IcTrash from '@/components/svg/icons/ic-trash.vue'
import IcZoom from '@/components/svg/icons/ic-zoom.vue'

import ComponentPlaceholder from './fileviewer/Placeholder.vue'
import PopoverRename from './popovers/Rename.vue'
import Popover from './Popover.vue'

if (typeof window !== 'undefined' && 'Worker' in window) {
  GlobalWorkerOptions.workerPort = pdfjsWorker()
}

export default {
  components: {
    IcRotate90,
    LoaderSpinner,
    IcAttachment,
    IcClose,
    IcDownload,
    IcPencil,
    IcError,
    IcPrint,
    IcFitPage,
    IcTrash,
    IcAddDocument,
    IcChevron,
    IcZoom,
    ComponentPlaceholder,
    Popover,
    PopoverRename
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()

    const { isDeviceMobile, fileViewer } = storeToRefs(appStore)

    return { appStore, isDeviceMobile, fileViewer }
  },

  data () {
    return {
      container: HTMLDivElement,
      isReadable: true,
      isLoading: true,
      pdfViewer: null,
      scale: 1,
      page: 1,
      pageCount: 1,
      url: false,
      blob: false,
      type: false,
      isImage: false,
      index: this.fileViewer.defaultIndex,
      imageWidth: {
        current: 0,
        initial: 0
      },

      mouseOnToolbar: false,
      editMode: false,
      fileNotFound: false,
      zoomValues: [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5],
      fitWidthScale: 1,
      fitHeightScale: 1,
      fitMode: 'width',
      fitted: false,
      disableHeightFit: false,
      delayedRender: false,
      resizeTimeout: null
    }
  },

  computed: {
    canEditDocument () {
      return this.fileViewer.canEdit && !this.fileViewer.isLocal
    },

    hasMultipleDocuments () {
      return this.fileViewer.content.length > 1
    },

    document () {
      return this.fileViewer.content[this.index]
    },

    filename () {
      return this.document ? (this.document.filename || this.document.name) : ''
    },

    isBlob () {
      return this.document instanceof Blob
    },

    fitButton () {
      return (!this.disableHeightFit && this.fitMode === 'width') ? 'height' : 'width'
    }
  },

  mounted () {
    this.refreshFileViewer()

    document.addEventListener('keyup', this.handleKeyup)
    window.addEventListener('resize', this.onResize)
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('on-upload-success', this.onUploadSuccess)
    this.$bus.on('on-file-attached', this.onDocumentsAttached)
    this.$bus.on('edit-file', this.editFile)

    this.handleMouseMove()
  },

  beforeUnmount () {
    document.removeEventListener('keyup', this.handleKeyup)
    window.removeEventListener('resize', this.onResize)
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('on-upload-success', this.onUploadSuccess)
    this.$bus.off('on-file-attached', this.onDocumentsAttached)
    this.$bus.off('edit-file', this.editFile)
  },

  methods: {
    formatDateText,

    async getFile () {
      if (this.isBlob) {
        this.blob = this.document
      } else {
        try {
          const { data } = await axiosClient.get(this.document.url, { responseType: 'blob' })
          this.blob = data
        } catch (e) {
          console.error(e)
          this.isLoading = false
          this.fileNotFound = e.response.status === 404
          return
        }
      }

      const fileURL = URL.createObjectURL(this.blob)
      if (this.fileViewer.allowedFilesType.includes(this.blob.type)) {
        this.blob.type === 'application/pdf'
          ? this.initPdfViewer(fileURL)
          : this.resetImageOrientation(this.blob, blob => { this.initImageViewer(blob) })

        this.type = this.blob.type
        this.isReadable = true
      } else {
        this.url = fileURL
        this.isLoading = false
        this.isReadable = false
      }
    },

    async initViewer () {
      this.resetView()

      await this.getFile()

      const container = document.querySelector('.fileviewer-content')
      container.addEventListener('mousemove', this.handleMouseMove)
      const toolbar = document.querySelector('#toolbar')
      if (toolbar) {
        toolbar.addEventListener('mouseenter', this.handleMouseEnter)
        toolbar.addEventListener('mouseleave', this.handleMouseLeave)
      }
      this.handleMouseMove()
    },

    initPdfViewer (fileURL) {
      this.isImage = false
      this.url = fileURL
      this.isLoading = false

      this.container = this.$refs.viewerContainer

      this.pdfViewer = new PDFViewer({ container: this.container, eventBus: this.initEventBus() })

      document.querySelector('#toolbar').addEventListener('mouseenter', this.handleMouseEnter)
      document.querySelector('#toolbar').addEventListener('mouseleave', this.handleMouseLeave)

      getDocument(fileURL).promise.then(this.loadDocument)
    },

    initImageViewer (fileURL) {
      this.isImage = true
      this.url = fileURL

      const image = new Image()
      image.src = this.url
      image.onload = () => {
        this.imageWidth.initial = image.width

        const maxWidth = Math.round(window.innerWidth * 0.50)
        const maxHeight = Math.round(window.innerHeight * 0.80)

        if (this.imageWidth.initial > maxWidth && image.width > image.height) {
          this.imageWidth.initial = maxWidth
        }
        if (image.height > maxHeight && image.width <= image.height) {
          this.imageWidth.initial = this.imageWidth.initial * (maxHeight / image.height)
        }
        this.isLoading = false
        this.zoom(1)
      }

      this.container = document.querySelector('.fileviewer-preview-container')
      this.container.addEventListener('mousemove', this.handleMouseMove)
      document.querySelector('#toolbar').addEventListener('mouseenter', this.handleMouseEnter)
      document.querySelector('#toolbar').addEventListener('mouseleave', this.handleMouseLeave)
      this.handleMouseMove()
    },

    viewerInit () {
      toRaw(this.pdfViewer).currentScaleValue = this.scale
    },

    loadDocument (pdfDocument) {
      toRaw(this.pdfViewer).setDocument(pdfDocument)
      this.pageCount = pdfDocument.numPages
    },

    resetView () {
      this.scale = 1
      this.url = ''
      this.isLoading = true
      this.isReadable = true
      this.imageWidth.initial = 0
      this.imageWidth.current = 0
      this.fileNotFound = false
    },

    zoom (scale, fit = false) {
      if (!scale) return

      this.fitted = fit
      if (!fit) {
        if (scale < this.zoomValues[0]) {
          scale = this.zoomValues[0]
        }

        if (scale > this.zoomValues[this.zoomValues.length - 1]) {
          scale = this.zoomValues[this.zoomValues.length - 1]
        }
      }

      this.scale = scale

      if (this.isImage) {
        this.imageWidth.current = Math.round(this.imageWidth.initial * this.scale)
      } else {
        if (this.pdfViewer?._pages.some(p => p.renderingState === 1)) {
          this.delayedRender = true
          return
        }
        toRaw(this.pdfViewer).currentScaleValue = this.scale
      }
    },

    setFitMode (mode) {
      this.fitMode = mode
      this.zoom(mode === 'width' ? this.fitWidthScale : this.fitHeightScale, true)
    },

    turnPdfPage (page) {
      this.page = Math.max(1, Math.min(page, this.pageCount))

      toRaw(this.pdfViewer).currentPageNumber = this.page
    },

    downloadFile () {
      downloadFileFromUrl(this.document.url)
    },

    downloadAllFiles () {
      if (this.fileViewer.isMultiDoc) {
        const url = this.fileViewer.documentsAllUrl ? this.fileViewer.documentsAllUrl : `${this.fileViewer.documentsUrl}/all`

        downloadFileFromUrl(url)
      }
    },

    printFile () {
      const type = this.isImage ? 'image' : 'pdf'

      printJS({ printable: this.url, type })
    },

    editFile () {
      this.editMode = this.fileViewer.isMultiDoc
      const fileId = this.document.id
      this.appStore.showDropzoneModal(
        this.$i18n.t('title.replace_file'),
        this.fileViewer.dataModel,
        {
          url: this.document.url,
          canAnalyzeFile: this.fileViewer.canAnalyze,
          editingFileId: this.fileViewer.isMultiDoc ? fileId : null,
          maxFiles: 1
        }
      )
    },

    addFiles () {
      this.isBlob
        ? this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file', 2), {}, { canAnalyzeFile: this.fileViewer.canAnalyze })
        : this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file', 2), this.fileViewer.dataModel, { url: this.fileViewer.documentsUrl, canAnalyzeFile: this.fileViewer.canAnalyze })
    },

    async onUploadSuccess (response) {
      if (!this.fileViewer.active) return

      this.resetView()

      if (this.fileViewer.isMultiDoc) {
        if (this.editMode) {
          Object.assign(this.fileViewer.content[this.index], response)
          this.editMode = false
        } else {
          this.fileViewer.content = [...response.documents]
          this.index = this.fileViewer.content.length - 1
        }
        await this.initViewer()
      } else {
        await this.getFile()
      }
    },

    onDocumentsAttached () {
      if (this.isBlob && this.fileViewer.isMultiDoc) {
        this.index = this.fileViewer.content.length - 1
        this.initViewer()
      }
    },

    onDocumentLoaded () {
      const containerRect = this.container.getBoundingClientRect()
      const elementRect = this.container.firstElementChild.firstElementChild.getBoundingClientRect()
      this.fitWidthScale = (containerRect.width - 8) / elementRect.width
      this.fitHeightScale = (containerRect.height - 32) / elementRect.height
      this.disableHeightFit = elementRect.width > elementRect.height
      if (this.fitWidthScale < 1) {
        this.setFitMode('width', true)
      } else {
        this.zoom(1)
      }
    },

    onDocumentRendered () {
      if (this.delayedRender) {
        this.delayedRender = false
        this.zoom(this.scale)
      }
    },

    deleteFile () {
      if (this.isBlob) {
        this.$bus.emit('delete-local-file')
        this.onDeleteSuccess()
      } else {
        this.appStore.showDeleteModal({ label: this.$i18n.t('prompt.file.delete.title'), content: this.$i18n.t('prompt.file.delete.content'), url: this.document.url || this.document })
      }
    },

    onDeleteSuccess (message = '') {
      if (this.fileViewer.active) {
        if (this.fileViewer.isMultiDoc) {
          this.fileViewer.content.splice(this.index, 1)
          if (this.fileViewer.content.length > 0) {
            this.getPreviousDocument()
          } else if (this.fileViewer.type !== 'expense') { // TODO change condition. Maybe add another parameter when calling fileviewer method.
            this.close()
          }
        } else if (this.fileViewer.type !== 'expense') {
          this.close()
        }
        if (!this.isBlob) {
          showToastSuccess(message || this.$i18n.t('message.success.file.delete'))
        }
      }
    },

    resetImageOrientation (image, callback) {
      const img = new Image()

      img.onload = function () {
        EXIF.getData(img, function () {
          const width = img.width
          const height = img.height
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const srcOrientation = EXIF.getTag(this, 'Orientation')

          if ([5, 6, 7, 8].indexOf(srcOrientation) > -1) {
            canvas.width = height
            canvas.height = width
          } else {
            canvas.width = width
            canvas.height = height
          }

          switch (srcOrientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0); break
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height); break
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height); break
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0); break
            case 6:
              ctx.transform(0, 1, -1, 0, height, 0); break
            case 7:
              ctx.transform(0, -1, -1, 0, height, width); break
            case 8:
              ctx.transform(0, -1, 1, 0, 0, width); break
            default:
              ctx.transform(1, 0, 0, 1, 0, 0)
          }

          ctx.drawImage(img, 0, 0)
          canvas.toBlob(function (blob) {
            return callback(URL.createObjectURL(blob))
          }, 'image/jpeg', 0.75)
        })
      }

      img.src = URL.createObjectURL(image)
    },

    handleKeyup (e) {
      if (this.$refs?.popover?.popoverActive) {
        return
      }
      switch (e.which) {
        case 27:
          this.close()
          break
        case 37:
          if (this.hasMultipleDocuments) {
            this.getPreviousDocument()
          }
          break
        case 39:
          if (this.hasMultipleDocuments) {
            this.getNextDocument()
          }
          break
      }
    },

    handleMouseMove () {
      if (document.querySelector('.fileviewer-toolbar')) {
        document.querySelector('.fileviewer-toolbar').classList.remove('fileviewer-toolbar--is-hidden')
        if (this.enterTimer) clearTimeout(this.enterTimer)
        this.enterTimer = setTimeout(() => {
          if (!this.mouseOnToolbar && document.querySelector('.fileviewer-toolbar')) {
            document.querySelector('.fileviewer-toolbar').classList.add('fileviewer-toolbar--is-hidden')
          }
        }, 2000)
      }
    },

    handleMouseEnter () {
      this.mouseOnToolbar = true
    },

    handleMouseLeave () {
      this.mouseOnToolbar = false
    },

    close () {
      this.appStore.closeFileViewer()
      this.$emit('close')
    },

    initEventBus () {
      const eventBus = new EventBus()

      eventBus.on('pagesloaded', this.onDocumentLoaded)
      eventBus.on('pagerendered', this.onDocumentRendered)
      eventBus.on('mousemove', this.handleMouseMove)
      eventBus.on('pagesinit', this.viewerInit)
      eventBus.on('pagechanging', this.pageChange)
      return eventBus
    },

    getPreviousDocument () {
      this.index = modulo(this.index - 1, this.fileViewer.content.length)
      this.initViewer()
    },

    getNextDocument () {
      this.index = modulo(this.index + 1, this.fileViewer.content.length)
      this.initViewer()
    },

    onFileRenamed (value) {
      this.document.name = value.name
      this.document.filename = value.filename
    },

    closePopover () {
      this.$refs.popover.closePopover()
    },

    refreshFileViewer () {
      if (this.document) {
        this.initViewer()
      }
    },

    onResize () {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout)
      }

      this.resizeTimeout = setTimeout(() => {
        const containerRect = this.container.getBoundingClientRect()
        const elementRect = this.container.firstElementChild.firstElementChild.getBoundingClientRect()
        const elementOriginalWidth = elementRect.width / this.pdfViewer.currentScaleValue
        const elementOriginalHeight = elementRect.height / this.pdfViewer.currentScaleValue
        this.fitWidthScale = (containerRect.width - 8) / elementOriginalWidth
        this.fitHeightScale = (containerRect.height - 32) / elementOriginalHeight
        if (this.fitted) {
          this.zoom(this.fitMode === 'width' ? this.fitWidthScale : this.fitHeightScale, true)
        }
        this.resizeTimeout = null
      }, 500)
    },

    pageChange (event) {
      this.page = event.pageNumber
    },

    rotate () {
      const page = (this.pdfViewer) ? this.pdfViewer.container.querySelector(`div[data-page-number='${this.page}']`) : this.container

      page.style.transform = `rotate(${this.getRotateDegres(page)}deg)`
    },

    getRotateDegres (page) {
      switch (page.style.transform.replace(/\D/g, '')) {
        case '90':
          return -180
        case '180':
          return -270
        case '270':
          return 0
        default:
          return -90
      }
    }
  }
}
</script>
