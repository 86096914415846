import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import utc from 'dayjs/plugin/utc'
import { upperFirst } from 'lodash/string'
import { range } from 'lodash/util'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)

export const getDateDifference = (startDate, endDate, unit = 'month') => {
  return dayjs(endDate).diff(dayjs(startDate), unit)
}

export const groupByDate = (data, dateField, options = {}) => {
  options = {
    dateFieldFormat: 'unix',
    groupByFormat: 'MMMM YYYY',
    ...options
  }

  return data.reduce((r, a) => {
    const groupedBy = upperFirst((options.dateFieldFormat === 'unix' ? dayjs.unix(a[dateField]) : dayjs(a[dateField], options.dateFieldFormat)).format(options.groupByFormat))
    r[groupedBy] = r[groupedBy] || []
    r[groupedBy].push(a)
    return r
  }, {})
}

export const relativeTimeDifference = value => {
  if (!value) { return }

  const given = dayjs.unix(value)

  return {
    hours: given.diff(dayjs(), 'h', true),
    text: given.fromNow()
  }
}

export const getHoursOfDay = () => {
  const arr = []

  range(0, 24).forEach(hour => {
    const fullHour = parseInt(hour).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

    arr.push(`${fullHour}:00`)
    arr.push(`${fullHour}:30`)
  })

  return arr
}

export const formatDateText = (value, format = 'LL') => {
  if (!value) { return }

  if (Number.isInteger(value)) {
    return dayjs.unix(value).format(format)
  }

  if (value instanceof dayjs) {
    return value.format(format)
  }

  return dayjs.tz(value, 'Europe/Paris').format(format)
}

export const formatDate = value => {
  return formatDateText(value, 'L')
}

export const formatTime = value => {
  return formatDateText(value, 'LT')
}
