<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="status"
                          dropdown-width="100%"
                          :dropdown-height="24"
                          :can-add-item="false"
                          :values="values"
                          :model="selectedCardStatus.value"
                          @select="onSelectCardStatus">
        <template #trigger>
          <div class="select"
               :class="modelValue.length ? 'is-clearable' : 'select--off'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue.length">
                {{ selectedCardStatus.label }}
              </template>
              <template v-else>
                {{ $t('table.status') }}
              </template>
            </div>
            <button v-if="modelValue.length"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$emit('clear-field')">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    ComponentDropdown,
    IcRemove
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    group: {
      type: String,
      required: true
    }
  },

  emits: [
    'clear-field',
    'update:modelValue'
  ],

  data () {
    return {
      selectedCardStatus: {}
    }
  },

  computed: {
    values () {
      if (this.group === 'virtual') {
        return [{
          label: this.$i18n.t('form.card_status.filter.activated'),
          value: ['active']
        },
        {
          label: this.$i18n.t('form.card_status.filter.locked'),
          value: ['locked', 'locked_partner', 'locked_proof', 'locked_service']
        }]
      }
      return [
        {
          label: this.$i18n.t('form.card_status.filter.activated'),
          value: ['active']
        },
        {
          label: this.$i18n.t('form.card_status.filter.locked'),
          value: ['locked', 'locked_partner', 'locked_proof', 'locked_service']
        },
        {
          label: this.$i18n.t('general.pending'),
          value: ['pending']
        },
        {
          label: this.$i18n.t('form.card_status.filter.shipped'),
          value: ['shipped']
        },
        {
          label: this.$i18n.t('form.card_status.filter.ordered'),
          value: ['ordered']
        }
      ]
    }
  },

  watch: {
    modelValue () {
      if (!this.modelValue.length) {
        this.selectedCardStatus = {}
      }
    }
  },

  methods: {
    onSelectCardStatus (cardStatus) {
      this.selectedCardStatus = cardStatus
      this.$emit('update:modelValue', cardStatus.value)
    }
  }
}
</script>
