const enum permissions {
  accountBalanceRead = 'account.balance.read',
  accountBeneficiaryRibWrite = 'account.beneficiary.rib.write',
  accountCapitalGainCreate = 'account.capital_gain.create',
  accountOverview = 'account.overview',
  accountRibRead = 'account.rib.read',
  accountRulesRead = 'account.rules.read',
  accountRulesWrite = 'account.rules.write',
  accountTermAccountCreate = 'account.term_account.create',
  accountWithdrawal = 'account.withdrawal',
  accountingRead = 'accounting.read',
  accountingWrite = 'accounting.write',
  accountingExpensesRead = 'accounting.expenses.read',
  accountingExpensesWrite = 'accounting.expenses.write',
  accountOwner = 'account.owner',
  accountOwnerMain = 'account.owner.main',
  accountSequestreAccountCreate = 'account.sequestre_account.create',
  accountSubAccountCreate = 'account.sub_account.create',
  affectationsRead = 'affectations.read',
  affectationsWrite = 'affectations.write',
  beneficiariesRead = 'beneficiaries.read',
  beneficiariesWrite = 'beneficiaries.write',
  beneficiaryListsRead = 'beneficiary_lists.read',
  beneficiaryListsWrite = 'beneficiary_lists.write',
  cardsActivate = 'cards.activate',
  cardsBlock = 'cards.block',
  cardsCancelAutomaticDeletion = 'cards.cancel-automatic-deletion',
  cardsDigitsRead = 'cards.digits.read',
  cardsLock = 'cards.lock',
  cardsPhysicalCreate = 'cards.physical.create',
  cardsPhysicalRead = 'cards.physical.read',
  cardsPhysicalWrite = 'cards.physical.write',
  cardsCreateMultiple = 'cards.create.multiple',
  cardsPhysicalSmartCardRead = 'cards.physical.smart_card.read',
  cardsPhysicalSmartCardWrite = 'cards.physical.smart_card.write',
  cardsPinRead = 'cards.pin.read',
  cardsWebWallet = 'cards.web_wallet',
  cardsPinWrite = 'cards.pin.write',
  cardsVirtualCreate = 'cards.virtual.create',
  cardsVirtualRead = 'cards.virtual.read',
  cardsVirtualRequest = 'cards.virtual.request',
  cardsVirtualValidate = 'cards.virtual.validate',
  cardsVirtualWrite = 'cards.virtual.write',
  cardsRulesRead = 'cards.rules.read',
  cardsRulesWrite = 'cards.rules.write',
  categoriesRead = 'categories.read',
  categoriesWrite = 'categories.write',
  checkRemittanceSlipRead = 'check_remittance_slip.read',
  creditsRead = 'credits.read',
  creditsWrite = 'credits.write',
  dashboardRead = 'dashboard.read',
  dashboardCardsHolderRead = 'dashboard.cards_holder.read',
  debitsRead = 'debits.read',
  debitsWrite = 'debits.write',
  expenseRequestsAffectationsRead = 'expense_requests.affectations.read',
  expenseRequestsAffectationsWrite = 'expense_requests.affectations.write',
  expenseRequestsItemsApprove = 'expense_requests.items.approve',
  expenseRequestsItemsDelete = 'expense_requests.items.delete',
  expenseRequestsItemsRead = 'expense_requests.items.read',
  expenseRequestsItemsWrite = 'expense_requests.items.write',
  invitationsRead = 'invitations.read',
  invitationsWrite = 'invitations.write',
  loyaltyCampaignsClaim = 'loyalty.campaigns.claim',
  loyaltyRead = 'loyalty.read',
  loyaltyRewardsClaim = 'loyalty.rewards.claim',
  messagesRead = 'messages.read',
  mileagesAffectationsRead = 'mileages.affectations.read',
  mileagesAffectationsWrite = 'mileages.affectations.write',
  mileagesRead = 'mileages.read',
  mileagesWrite = 'mileages.write',
  operationsAffectationsRead = 'operations.affectations.read',
  operationsAffectationsWrite = 'operations.affectations.write',
  operationsChargeBackRead = 'operations.charge_back.read',
  operationsChargeBackWrite = 'operations.charge_back.write',
  operationsExport = 'operations.export',
  operationsRead = 'operations.read',
  operationsWrite = 'operations.write',
  operationsCardsRead = 'operations.cards.read',
  operationsDocumentsRead = 'operations.documents.read',
  operationsDocumentsWrite = 'operations.documents.write',
  operationsIncomingTransfersRead = 'operations.incoming_transfers.read',
  operationsVatRead = 'operations.vat.read',
  refundsAffectationsRead = 'refunds.affectations.read',
  refundsAffectationsWrite = 'refunds.affectations.write',
  refundsRead = 'refunds.read',
  refundsWrite = 'refunds.write',
  statementsAnnualFeesRead = 'statements.annual_fees.read',
  statementsCardsRead = 'statements.cards.read',
  statementsDispatchRead = 'statements.dispatch.read',
  statementsRead = 'statements.read',
  statementsWrite = 'statements.write',
  teamsRead = 'teams.read',
  teamsWrite = 'teams.write',
  transfersAdvancePaymentRead = 'transfers.advanced_payment.read',
  transfersAdvancePaymentWrite = 'transfers.advanced_payment.write',
  transfersAffectationsRead = 'transfers.affectations.read',
  transfersAffectationsWrite = 'transfers.affectations.write',
  transfersApprovalFlowRead = 'transfers.approval_flow.read',
  transfersApprovalFlowWrite = 'transfers.approval_flow.write',
  transfersBatchValidate = 'transfers.batch.validate',
  transfersRead = 'transfers.read',
  transfersValidate = 'transfers.validate',
  transfersWrite = 'transfers.write',
  treasuryRead = 'treasury.read',
  usersAffectationsRead = 'users.affectations.read',
  usersAffectationsWrite = 'users.affectations.write',
  usersLightRead = 'users.light.read',
  // TODO change with users.read in #1720
  usersRead = 'account.owner',
  usersWrite = 'users.write',
  cardsRequestIncrease = 'cards.request.increase',
  cardsSettingsRead = 'cards.settings.read',
  cardsSettingsWrite = 'cards.settings.write'
}

export default permissions
