<template>
  <div class="bordered-box card-expenses"
       style="position: relative">
    <loader-spinner v-if="loading" />
    <div v-show="!loading"
         class="card-expenses__container overflow-scrollbar">
      <div class="card-expenses__chart">
        <div class="card-expenses__chart__inside">
          <template v-if="expensesPeriod.length">
            <ic-category v-bind="{ [activeExpense.name]: true }"
                         class="ic ic-category" />
            <div class="expense-amount">
              {{ formatAmount(activeExpense.amount, account.currency) }}
            </div>
            <div class="expense-label">
              {{ activeExpense.label }}
            </div>
          </template>
          <template v-else>
            <div class="expense-amount">
              {{ formatAmount(0, account.currency) }}
            </div>
            <div class="expense-label">
              {{ $t('cards.expenses.label.no_expense') }}
            </div>
          </template>
        </div>
        <donut-chart ref="donut-chart"
                     :items="expensesPeriod"
                     :size="220"
                     @hover="changeExpenseInChart" />
      </div>
      <div class="card-expenses__legend">
        <div class="card-expenses__legend__items-container"
             :class="{ empty: !expensesPeriod.length }">
          <template v-if="expensesPeriod.length">
            <div v-for="(item, index) in expensesPeriod"
                 :key="index"
                 class="card-expenses__legend__item"
                 @mouseover="changeExpenseInChart(item)">
              <div class="dot-container">
                <div class="dot"
                     :style="getStyle(item)" />
                <ic-category v-bind="{ [item.name]: true }"
                             :class="`ic-category-${item.name}`"
                             class="ic ic-category" />
              </div>
              <div class="card-expenses__legend__item__label">
                {{ item.label }}
              </div>
              <div class="card-expenses__legend__item__expenses">
                {{ item.count }} {{ item.count > 1 ? 'transactions' : 'transaction' }}
              </div>
              <div class="card-expenses__legend__item__amount">
                {{ formatAmount(item.amount, account.currency) }}
              </div>
            </div>
          </template>
          <component-placeholder v-else
                                 :icon-height="128"
                                 :icon-width="160"
                                 :label="placeholderLabel">
            <image-card grey />
          </component-placeholder>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArcElement, Chart, DoughnutController, Tooltip } from 'chart.js'
import { storeToRefs } from 'pinia'

import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useCardStore } from '@/stores/card'

import DonutChart from '@/components/charts/DonutChart.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcCategory from '@/components/svg/icons/ic-category.vue'
import ImageCard from '@/components/svg/img/card.vue'

Chart.register(DoughnutController, ArcElement, Tooltip)

export default {
  components: {
    DonutChart,
    IcCategory,
    ComponentPlaceholder,
    LoaderSpinner,
    ImageCard
  },

  props: {
    cardUuid: {
      type: String,
      required: true
    },

    period: {
      type: String,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)

    return { account, cardStore }
  },

  data () {
    return {
      expenses: [],
      activeExpense: {},
      loading: true,
      chart: null
    }
  },

  computed: {
    expensesPeriod () {
      return this.expenses[this.period]?.details || []
    },

    placeholderLabel () {
      return (this.period) ? this.$t(`cards.expenses.label.${this.period}`) : ''
    }
  },

  watch: {
    expensesPeriod () {
      this.activeExpense = this.expensesPeriod[0]

      this.$nextTick(() => {
        this.expensesPeriod.forEach(item => { document.querySelector(`.ic-category-${item.name}`)?.setAttribute('style', `fill: ${item.color}`) })
      })
    }
  },

  async mounted () {
    this.loading = true

    const data = await this.cardStore.getExpenses(this.cardUuid)
    if (!data) { return }

    this.expenses = data.details
    this.activeExpense = this.expensesPeriod[0]
    this.$refs['donut-chart']?.initChart()
    this.loading = false
  },

  beforeUnmount () {
    this.chart?.destroy()
  },

  methods: {
    formatAmount,

    changeExpenseInChart (item) {
      if (!item) { return }
      this.activeExpense = item
    },

    getStyle (item) {
      return `background-color: ${item.color}`
    }
  }
}
</script>

<style lang="stylus">
  .card-expenses
    min-height 31rem
    margin-bottom 2rem
    display flex
    align-items center

    &__container
      display flex
      padding 3.9rem 8rem
      flex 1
      overflow-x auto
    &__chart
      position absolute
      margin-right 12rem
      height 22rem
      &__inside
        position absolute
        left 50%
        top 50%
        transform translateX(-50%) translateY(-50%)
        text-align center
        .ic-category
          margin-bottom 1rem
        .expense-amount
          font-size 1.7rem
          font-weight 500
        .expense-label
          white-space nowrap
          font-size 1.3rem
          color #7a818c //colorShinyGray
    &__legend
      margin-left 32rem
      width 100%
      display flex
      justify-content space-between
      .dot-container
        position relative
        margin-right 1.8rem
      .dot
        opacity 0.1
        justify-content center
        width 3.5rem
        height 3.5rem
        border-radius 50%
        z-index 1
      .ic-category
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        z-index 2

      &__items-container
        height 230px
        display flex
        width 100%
        flex-direction column

        &.empty
          align-items center
          justify-content center

      .placeholder__img
        margin-top -2rem

      &__item
        width 100%
        height 5rem
        border-radius 4px
        display flex
        align-items center
        padding 0.8rem 1.5rem
        &:hover
          background-color #F6F6F8
        &:last-child
          margin-bottom 2rem

        &__label
          flex 1
          font-weight 500
        &__expenses
          flex 0.7
          font-weight 400
        &__amount
          flex 0.7
          text-align right
          font-weight 500

</style>
