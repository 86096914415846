<template>
  <div>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('form.title.account_role')"
                      show-number />
      <div class="form__fieldset__group ">
        <div class="form__row row">
          <div class="form-group col col--sm">
            <dropdown-account :account="account"
                              :disabled="!isCreatingUser"
                              :params="{
                                access: 'owner',
                                status_group: 'valid'
                              }" />
          </div>
          <div class="form-group col col--sm"
               :class="{ error: errors.roles }">
            <validation-field :model-value="userForm.role"
                              name="roles">
              <component-dropdown label="role"
                                  dropdown-width="100%"
                                  :disabled="!hasWritePermission"
                                  :values="userRoles"
                                  :model="userForm.role"
                                  @select="selectRole">
                <template #trigger>
                  <div class="select">
                    <div class="form-control form-control--noborder">
                      <template v-if="!userForm.role">
                        <span class="text-placeholder">
                          {{ $t('form.roles.placeholder.default') }}
                        </span>
                      </template>
                      <template v-else>
                        {{ roleLabel(userForm.role) }}
                      </template>
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
          <div v-if="isRoleOther"
               class="form-group col col--sm-4"
               :class="{ error: errors.role }">
            <validation-field id="role"
                              v-model="userForm.role_label"
                              type="text"
                              class="form-control form-control--noborder input__field"
                              name="role"
                              :disabled="!hasWritePermission"
                              :placeholder="$t('form.roles.placeholder.other')" />
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <fieldset-title :title="$t('form.title.general_informations')"
                      show-number />
      <div class="form__fieldset__group ">
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.first_name }">
            <label class="label input__label">{{ $t('form.first_name.label') }}</label>
            <component-lock-input id="first_name"
                                  v-model="userForm.first_name"
                                  type="text"
                                  input-class="form-control form-control--noborder input__field"
                                  name="first_name"
                                  :placeholder="$t('form.first_name.placeholder')"
                                  :disabled="isEditingUser || !hasWritePermission" />
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.last_name }">
            <label class="label input__label">{{ $t('form.last_name.label') }}</label>
            <component-lock-input id="last_name"
                                  v-model="userForm.last_name"
                                  type="text"
                                  input-class="form-control form-control--noborder input__field"
                                  name="last_name"
                                  :placeholder="$t('form.last_name.placeholder')"
                                  :disabled="isEditingUser || !hasWritePermission" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.email }">
            <label class="label input__label">{{ $t('form.email.label') }}</label>
            <component-lock-input id="email"
                                  v-model="userForm.email"
                                  type="text"
                                  input-class="form-control form-control--noborder input__field"
                                  name="email"
                                  :placeholder="$t('form.email.placeholder')"
                                  :disabled="isEditingUser || !hasWritePermission" />
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.phone }">
            <label class="label input__label">{{ $t('form.mobile_phone.label') }}</label>
            <component-phone-input id="phone"
                                   v-model="userForm.phone"
                                   wrapper-class="input-group--noborder"
                                   name="phone"
                                   :disabled="isEditingUser || !hasWritePermission">
              <template #addon>
                <div v-if="isEditingUser"
                     class="input-group__addon">
                  <ic-cadenas-sm class="ic ic--16 ic--off" />
                </div>
              </template>
            </component-phone-input>
          </div>
        </div>
        <user-language v-if="!isActiveUser && hasWritePermission && hasMultipleLanguages"
                       :model="userForm.language"
                       @update-model="updateUserModel" />
      </div>
    </fieldset>
    <user-team v-if="!isActiveUser && hasWritePermission"
               :errors="errors"
               :model="userTeam"
               @update-model="updateUserModel" />
    <fieldset class="form__fieldset">
      <fieldset-title :title="`${$t('form.title.professional_informations')} (${$t('general.optional')})`"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div v-if="hasBankDetails"
               class="form-group col col--sm">
            <label class="label input__label">{{ $t('form.beneficiary.social_security_number.label') }}</label>
            <VTooltip theme="poptip"
                      placement="bottom-start">
              <template #popper>
                <component-poptip :title="$t('title.important')"
                                  :message="$t('form.beneficiary.social_security_number.message')" />
              </template>
              <input v-model="userForm.beneficiary.details.social_security_number"
                     :disabled="!hasWritePermission"
                     autocomplete="off"
                     type="text"
                     class="form-control form-control--noborder input__field"
                     :placeholder="$t('form.beneficiary.social_security_number.placeholder')">
            </VTooltip>
          </div>
          <div v-else
               class="form-group col col--sm">
            <label class="label input__label">{{ $t('form.company.label') }}</label>
            <input id="company"
                   v-model="userForm.company"
                   type="text"
                   class="form-control form-control--noborder input__field"
                   name="company"
                   :disabled="!hasWritePermission"
                   :placeholder="$t('form.company.placeholder')">
          </div>
          <div class="form-group col col--sm">
            <label class="label input__label"
                   for="employee_number">{{ $t('form.registration_number.label') }}</label>
            <div class="input-group">
              <input id="employee_number"
                     v-model="userForm.employee_number"
                     type="text"
                     :disabled="!hasWritePermission"
                     class="form-control form-control--noborder"
                     :placeholder="$t('form.registration_number.placeholder')"
                     name="employee_number">
            </div>
          </div>
          <div v-if="canReadAffectations && !isImportingUser"
               class="form-group col col--sm"
               :class="{ error: errors.affectations }">
            <label class="label input__label">
              {{ $t("form.affectation.label") }}
              <span v-tooltip="{
                      content: account.settings.map_access_affectations_to_operations ? $t('tooltip.affectation_mapped') : $t('tooltip.affectation_not_mapped'),
                      theme: 'tooltip'
                    }"
                    class="ic-helper small vertical-align-bottom">
                <ic-info outline
                         class="ic" />
              </span>
            </label>
            <component-dropdown ref="affectationsDropdown"
                                label="affectations"
                                dropdown-menu-class="dropdown-menu--noborder"
                                multiselect
                                dropdown-width="100%"
                                search
                                :search-placeholder="$t('form.operations.new_affectation.placeholder')"
                                :can-add-item="canWriteAffectations"
                                :disabled="!canEditAffectations || !hasWritePermission"
                                async-url="/affectations"
                                :model="userForm.affectations"
                                @select="onAffectationsSelected"
                                @new-item="onNewAffectationAdded">
              <template #trigger>
                <div class="dropdown-toggle select">
                  <div class="form-control form-control--noborder">
                    <span v-if="!userForm.affectations.length"
                          class="text-placeholder">{{ $t('form.common.select') }}</span>
                    <span v-else>{{ affectationsLabel }}</span>
                  </div>
                </div>
              </template>
            </component-dropdown>
          </div>
        </div>
        <component-employee-details v-if="userForm.role === 'employee'"
                                    v-model:user="userForm.beneficiary"
                                    :disabled="!hasWritePermission" />
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <fieldset-title :title="bankDetailsLabel"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row">
          <div class="form-group col col--sm-8"
               :class="{ error: errors.iban }">
            <label class="label input__label">{{ $t('form.iban.label') }}</label>
            <component-lock-input v-model="userForm.beneficiary.iban"
                                  type="text"
                                  name="iban"
                                  input-class="form-control form-control--noborder input__field"
                                  :placeholder="$t('form.iban.placeholder')"
                                  :disabled="isEditingUser && isBeneficiary || !hasWritePermission"
                                  @keyup="onIbanKeyUp"
                                  @change="onIbanChange" />
          </div>
          <div class="form-group col col--sm-4"
               :class="{ error: errors.bic }">
            <label class="label input__label">
              {{ $t('general.bic_swift') }}
            </label>
            <component-lock-input id="bic"
                                  v-model="userForm.beneficiary.bic"
                                  type="text"
                                  input-class="form-control form-control--noborder input__field"
                                  name="bic"
                                  :placeholder="$t('form.beneficiary.bic_code.placeholder')"
                                  :disabled="isEditingUser && isBeneficiary || !hasWritePermission" />
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import IBAN from 'iban'
import { cloneDeep } from 'lodash'
import isEqual from 'lodash/isEqual'
import { storeToRefs } from 'pinia'

import config from '@/config/config'
import store from '@/config/store'
import { formatIban, formatIbanOnKeyUp } from '@/helpers/utils/iban'
import { getLanguageName } from '@/helpers/utils/intl'
import { useAccountStore } from '@/stores/account'
import { useOperationStore } from '@/stores/operation'
import { useTransferStore } from '@/stores/transfer'
import { useUserStore } from '@/stores/user'

import UserLanguage from '@/pages/users/components/form/UserLanguage.vue'
import UserTeam from '@/pages/users/components/form/UserTeam.vue'

import ComponentDropdown from '@/components/Dropdown.vue'
import DropdownAccount from '@/components/DropdownAccount.vue'
import ComponentEmployeeDetails from '@/components/EmployeeDetails.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ComponentLockInput from '@/components/LockInput.vue'
import ComponentPhoneInput from '@/components/PhoneInput.vue'
import ComponentPoptip from '@/components/Poptip.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'

export default {
  components: {
    DropdownAccount,
    ComponentDropdown,
    ComponentEmployeeDetails,
    ComponentLockInput,
    ComponentPhoneInput,
    ComponentPoptip,
    IcCadenasSm,
    IcInfo,
    UserTeam,
    UserLanguage,
    FieldsetTitle
  },

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    mode: {
      type: String,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  },

  emits: ['update:user'],

  setup () {
    const accountStore = useAccountStore()
    const userStore = useUserStore()
    const operationStore = useOperationStore()
    const transferStore = useTransferStore()

    const { account, accounts } = storeToRefs(accountStore)
    const { iban } = storeToRefs(transferStore)

    return {
      accountStore,
      operationStore,
      transferStore,
      userStore,

      account,
      accounts,
      iban
    }
  },

  data () {
    return {
      store,
      isValidIban: false,
      userForm: {},
      roles: []
    }
  },

  computed: {
    userTeam () {
      return {
        team: {
          id: this.userForm?.team_id,
          name: this.userForm?.team_name
        },

        teamRole: this.userForm?.team_role
      }
    },

    affectationsLabel () {
      if (!this.userForm.affectations.length) { return '' }

      return this.userForm.affectations.map(affectation => affectation.label).join(', ')
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.usersAffectationsRead)
    },

    canEditAffectations () {
      return this.canReadAffectations && this.hasPermission(this.$permissions.usersAffectationsWrite)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    bankDetailsLabel () {
      if (this.userForm.beneficiary.details.advance_payment) {
        return this.$i18n.t('general.bank_details')
      }
      return `${this.$i18n.t('general.bank_details')} (${this.$i18n.t('general.optional')})`
    },

    hasBankDetails () {
      return ['salarie', 'associe', 'employee', 'associate'].includes(this.userForm.role)
    },

    isAccountSequestre () {
      return this.account.status === 'sequestre'
    },

    isBeneficiary () {
      return this.userForm.beneficiary && this.userForm.beneficiary.id
    },

    isCreatingUser () {
      return this.mode === 'create'
    },

    isEditingUser () {
      return this.mode === 'edit-user'
    },

    isImportingUser () {
      return this.mode === 'import'
    },

    isActiveUser () {
      return this.userForm.status === 'active'
    },

    isRoleOther () {
      return this.userForm.role === 'other'
    },

    hasWritePermission () {
      return this.isEditingUser
        ? this.hasPermission(this.$permissions.usersWrite)
        : this.hasPermission(this.$permissions.invitationsWrite)
    },

    userRoles () {
      return this.roles
        .map(role => ({
          label: role.role_label,
          value: role.role
        }))
        .concat({
          value: 'other',
          label: this.$i18n.t('general.other')
        })
    },

    hasMultipleLanguages () {
      return config.TRANSLATIONS.AVAILABLE_LANGUAGES.length > 1
    }
  },

  watch: {
    'userForm.role': {
      handler (newValue, oldValue) {
        if (oldValue !== undefined && newValue !== oldValue) {
          if (newValue === 'accountant' || newValue === 'associate') {
            this.userForm.access.accounting = true
            this.userForm.access.treasury = true
          }
          if (newValue === 'accountant') {
            this.userForm.access.operations = true
            this.userForm.access.statements = true
          }
        }
        if (this.hasBankDetails) {
          this.userForm.company = this.account.company.name
        }
      }
    },

    userForm: {
      handler (userForm) {
        this.$emit('update:user', userForm)
      },

      deep: true
    },

    user: {
      handler (user) {
        if (!isEqual(this.userForm, user)) {
          this.userForm = cloneDeep(user)
        }
      },

      deep: true
    }
  },

  async created () {
    this.userForm = cloneDeep(this.user)
    await this.getRoles()
  },

  async mounted () {
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
    this.$bus.on('language-changed', this.getRoles)
  },

  beforeUnmount () {
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
    this.$bus.off('language-changed', this.getRoles)
  },

  methods: {
    getLanguageName,

    onAffectationsSelected (affectations) {
      this.userForm.affectations = [...affectations]
    },

    onDeleteAffectation (itemId) {
      const index = this.userForm.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.userForm.affectations.splice(index, 1)
      }
    },

    onIbanKeyUp (e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        return
      }
      const cursorPosition = e.target.selectionStart
      const formattedIban = formatIbanOnKeyUp(this.userForm.beneficiary.iban)
      if (formattedIban) {
        this.userForm.beneficiary.iban = formattedIban
        this.$nextTick(() => {
          this.setCursorPosition(e, cursorPosition)
        })
      }
    },

    setCursorPosition (e, cursorPosition) {
      if (e.key === 'Backspace' && cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition -= 1
      } else if (!/[a-z0-9]/i.test(e.key)) {
        cursorPosition -= 1
      } else if (cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition += 1
      }
      e.target.setSelectionRange(cursorPosition, cursorPosition)
    },

    async onIbanChange () {
      if (this.userForm.beneficiary.iban.length > 0) {
        if (IBAN.isValid(this.userForm.beneficiary.iban)) {
          this.userForm.beneficiary.iban = formatIban(this.userForm.beneficiary.iban)
        }

        await this.transferStore.validateIban(this.userForm.beneficiary.iban)

        if (!this.iban.sepa) {
          this.$refs.form.setFieldError('iban', this.$i18n.t('form.alerts.no_sepa'))
          this.isValidIban = false
        } else {
          this.isValidIban = true
          if (this.iban.bic) {
            this.userForm.beneficiary.bic = this.iban.bic
          }
        }
      } else {
        this.isValidIban = false
      }
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.userForm.affectations.push(newAffectation)
        this.$refs.affectationsDropdown.onNewItemAdded(newAffectation)
      }
    },

    async getRoles () {
      this.roles = await this.userStore.getRoles()
    },

    roleLabel (value) {
      const role = this.userRoles.find(role => role.value === value)
      return role ? role.label : ''
    },

    selectRole (role) {
      this.userForm.role = role.value
      if (!this.isRoleOther) {
        this.userForm.role_label = role.label
      }
    },

    updateUserModel (object) {
      this.userForm = { ...this.userForm, ...object }
    }
  }
}
</script>
