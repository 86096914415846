<template>
  <form class="form-inline">
    <div v-if="isCard && !cardUuid"
         class="form-group">
      <div class="select">
        <component-dropdown :key="isVirtualCard ? 'virtual' : 'physical'"
                            label="card"
                            :dropdown-width="32"
                            search
                            has-default
                            async-url="/cards"
                            :async-url-params="{
                              except_status: ['pending'],
                              grp: isVirtualCard ? 'virtual' : 'physical',
                              scenario: 'light'
                            }"
                            :model="selectedCard?.uuid"
                            :label-filter="cardFilter"
                            @select="onCardSelected">
          <template #trigger>
            <div class="form-control">
              <loader-spinner v-if="showLoader"
                              size="15" />
              <span v-else
                    class="d-flex align-items-center">
                <badge-visa :type="selectedCard.type" />
                •••• {{ selectedCard.last_digits ? selectedCard.last_digits : '••••' }} - {{ selectedCard.first_name }} {{ selectedCard.last_name }}
              </span>
            </div>
          </template>
          <template #prefix="{ status, type }">
            <badge-visa :type="type"
                        :disabled="['deleted', 'blocked', 'cancelled'].includes(status)" />
          </template>
        </component-dropdown>
      </div>
    </div>
    <div v-if="!isAnnualFees && years.length"
         class="form-group">
      <div class="select">
        <component-dropdown :model="selectedYear"
                            :values="years"
                            label="year"
                            dropdown-width="100%"
                            @select="updateParams">
          <template #trigger>
            <div class="form-control">
              <loader-spinner v-if="showLoader"
                              size="15" />
              <span v-else>{{ selectedYear }}</span>
            </div>
          </template>
        </component-dropdown>
      </div>
    </div>
    <template v-if="hasStatements">
      <button class="btn btn--default"
              @click.prevent="downloadStatements">
        {{ downloadButtonLabel }}
      </button>
    </template>
  </form>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { useStatementStore } from '@/stores/statement'

import BadgeVisa from '@/components/BadgeVisa.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  components: {
    BadgeVisa,
    ComponentDropdown,
    LoaderSpinner
  },

  props: {
    isCard: {
      type: Boolean,
      default: false
    },

    isAnnualFees: {
      type: Boolean,
      default: false
    },

    cardUuid: {
      type: String,
      default: null
    },

    isVirtualCard: {
      type: Boolean,
      required: true
    },

    selectedStatements: {
      type: Array,
      required: true
    }
  },

  emits: ['selected-params', 'update-loading'],

  setup () {
    const statementStore = useStatementStore()

    const { statements } = storeToRefs(statementStore)

    return { statements, statementStore }
  },

  data () {
    return {
      years: [],
      selectedYear: null,
      selectedCard: null,
      showLoader: true
    }
  },

  computed: {
    downloadButtonLabel () {
      return this.hasSelectedStatements
        ? `${this.$i18n.t('button.download')} (${this.selectedStatements.length})`
        : this.$i18n.t('button.download_all')
    },

    hasSelectedStatements () {
      return this.selectedStatements.length > 0
    },

    hasStatements () {
      return this.statements.length
    }
  },

  watch: {
    '$route.name' () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$emit('update-loading', true)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    initData () {
      this.onCardSelected(this.isCard && this.cardUuid ? { uuid: this.cardUuid } : null)
    },

    async onCardSelected (card) {
      if (this.isAnnualFees) return

      this.showLoader = true
      this.$emit('update-loading', true)

      this.selectedCard = card || null

      if (!this.isCard) {
        this.years = await this.statementStore.getStatementsYears()
      } else if (this.selectedCard) {
        this.years = await this.statementStore.getStatementsYears({ type: 'card', card_uuid: this.selectedCard.uuid })
      }

      this.selectedYear = this.years[0] || null
      this.$emit('update-loading', false)
      if (this.selectedCard || this.selectedYear) this.showLoader = false

      this.updateParams(this.selectedYear)
    },

    updateParams (year) {
      this.selectedYear = year
      this.$emit('selected-params', { card_uuid: this.selectedCard?.uuid, year: this.selectedYear })
    },

    cardFilter (card) {
      return `<span><badge-visa :type="${card.type}" />•••• ${card.last_digits ? card.last_digits : '••••'} - ${card.first_name} ${card.last_name}</span>`
    },

    downloadStatements () {
      const statements = this.hasSelectedStatements ? this.selectedStatements : this.statements

      if (statements.length === 0) { return }

      const ids = statements.map(statement => statement.id).join(',')
      const url = `/statements/${ids}/download`

      const downloadParams = {}
      if (this.isCard) {
        downloadParams.type = 'card'
      } else if (this.isAnnualFees) {
        downloadParams.type = 'annual_fees'
      }

      downloadFileFromUrl(url, downloadParams)
    }
  }
}
</script>
