<template>
  <svg viewBox="0 0 16 16">
    <path d="M6.6 2.5a.1.1 0 0 0-.1.1v6.8a.1.1 0 0 0 .1.1h6.8a.1.1 0 0 0 .1-.1V2.6a.1.1 0 0 0-.1-.1zM11 13c0 1.197-.68 1.931-1.832 1.995L9 15H3c-1.254 0-2-.746-2-2V7c0-1.254.746-2 2-2h1v1.5H3c-.425 0-.5.075-.5.5v6c0 .425.075.5.5.5h6c.425 0 .5-.075.5-.5v-1H11zm2.4-12A1.6 1.6 0 0 1 15 2.6v6.8a1.6 1.6 0 0 1-1.6 1.6H6.6A1.6 1.6 0 0 1 5 9.4V2.6A1.6 1.6 0 0 1 6.6 1z" />
  </svg>
</template>

<script>
export default {
}
</script>
