import { Buffer } from 'buffer'
import CryptoJS from 'crypto-js'
import forge from 'node-forge'

export function getRandomBytes (bytes = 16): string {
  return forge.util.bytesToHex(forge.random.getBytesSync(bytes))
}

// Encrypt text with Public Key
export function encryptWithPublicKey (valueHex: string, publicKeyPem: string, label: string|null = null): string {
  const publicKey: forge.pki.rsa.PublicKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----\n${publicKeyPem}\n-----END PUBLIC KEY-----`)
  const bytes: forge.Bytes = forge.util.hexToBytes(valueHex)
  const encrypted: string = publicKey.encrypt(bytes, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: forge.md.sha256.create(),
    label
  })
  return forge.util.bytesToHex(encrypted)
}

// Get PIN Block from PIN
function getPinBlock (pin: string, randomHex = ''): string {
  return `4${pin.length}${pin}${new Array(14 - pin.length).fill('a').join('')}${randomHex || getRandomBytes(8)}`
}

// Extract PIN from PIN Block
function getPin (pinBlock: string): string|null {
  const reg = /^4([4-9]|1[0-2])([0-9]{4,12})(a{2,10})/
  const match: RegExpMatchArray|null = pinBlock.match(reg)
  if (!match) return null
  const length = parseInt(match[1])
  const pin = match[2]
  const filler = match[3]
  if (filler.length !== (14 - length) || pin.length !== length) return null
  return pin
}

// ConvertedIdData Block
function getConvertedIdDataBlock (convertedId: string): string {
  return `${convertedId.length - 12}${convertedId}${new Array(31 - convertedId.length).fill(0).join('')}`
}

// AES Encrypt/Decrypt
function aesEncrypt (valueHex: string, keyHex: string): string {
  const value: CryptoJS.lib.WordArray = CryptoJS.enc.Hex.parse(valueHex)
  const key: CryptoJS.lib.WordArray = CryptoJS.enc.Hex.parse(keyHex)
  const iv: CryptoJS.lib.WordArray = CryptoJS.enc.Hex.parse('00000000000000000000000000000000')
  return CryptoJS.AES.encrypt(value, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.NoPadding }).ciphertext.toString()
}

function aesDecrypt (valueHex: string, keyHex: string): string {
  const params: CryptoJS.lib.CipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Hex.parse(valueHex) })
  const key: CryptoJS.lib.WordArray = CryptoJS.enc.Hex.parse(keyHex)
  const iv: CryptoJS.lib.WordArray = CryptoJS.enc.Hex.parse('00000000000000000000000000000000')
  return CryptoJS.AES.decrypt(params, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.NoPadding }).toString()
}

// GCM Decrypt
export function gcmDecrypt (valueHex: string, keyHex: string, ivHex: string, authTagHex: string): string {
  const valueBuffer: forge.util.ByteStringBuffer = forge.util.createBuffer(forge.util.hexToBytes(valueHex))
  const key: forge.Bytes = forge.util.hexToBytes(keyHex)
  const iv: forge.Bytes = forge.util.hexToBytes(ivHex)
  const tagBuffer: forge.util.ByteStringBuffer = forge.util.createBuffer(forge.util.hexToBytes(authTagHex))
  const decipher: forge.cipher.BlockCipher = forge.cipher.createDecipher('AES-GCM', key)
  decipher.start({ iv, tagLength: 128, tag: tagBuffer })
  decipher.update(valueBuffer)
  decipher.finish()
  return decipher.output.toString()
}

// XOR
function xor (hex1: string, hex2: string): string {
  const buffer1: Buffer = Buffer.from(hex1, 'hex')
  const buffer2: Buffer = Buffer.from(hex2, 'hex')
  const bufferResult: Uint8Array = buffer1.map((b, i) => b ^ buffer2[i])
  return Buffer.from(bufferResult).toString('hex')
}

// Encrypt PIN
export function encryptPin (pin: string, convertedId: string, key: string, randomHex = ''): string {
  const pinBlock: string = getPinBlock(pin, randomHex)
  const encryptPinBlock: string = aesEncrypt(pinBlock, key)
  const convertedIdBlock: string = getConvertedIdDataBlock(convertedId)
  const xorRes: string = xor(encryptPinBlock, convertedIdBlock)
  return aesEncrypt(xorRes, key)
}

// Decrypt PIN
export function decryptPin (encryptedPin: string, convertedId: string, key: string): string|null {
  const unAes: string = aesDecrypt(encryptedPin, key)
  const convertedIdBlock: string = getConvertedIdDataBlock(convertedId)
  const xorRes: string = xor(unAes, convertedIdBlock)
  const unAes2: string = aesDecrypt(xorRes, key)
  return getPin(unAes2)
}
