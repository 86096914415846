<template>
  <div class="form-group input-verification-code">
    <template v-for="(_, key) in numberOfDigits"
              :key="key">
      <validation-field ref="input"
                        v-model="digits[key]"
                        type="text"
                        inputmode="numeric"
                        autocomplete="off"
                        class="form-control"
                        maxlength="1"
                        :name="`digits${key}`"
                        :disabled="disabled"
                        :data-cy="`verification-code-${key}`"
                        @compositionupdate="e => onAutoComplete(e, key)"
                        @click="selectItem"
                        @keypress="checkValidKey"
                        @keyup="e => onAutoComplete(e, key)"
                        @keydown="onKeyDown"
                        @paste.prevent=" e => onPaste(e)"
                        @focus="onInputFocus" />
      <span v-if="hasSeparator && key === numberOfDigits / 2 - 1"
            :key="`span-${key}`"
            class="separator">-</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },

    numberOfDigits: {
      type: Number,
      default: 6
    },

    numbers: {
      type: Boolean,
      required: false,
      default: true
    },

    hasSeparator: {
      type: Boolean,
      required: false,
      default: true
    },

    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['complete', 'update:modelValue'],

  data () {
    return {
      digits: []
    }
  },

  computed: {
    isCodeComplete () {
      return this.digits.filter(i => i !== '').length === this.numberOfDigits
    }
  },

  created () {
    this.digits = this.value ? this.value.split('') : Array(this.numberOfDigits).map((_, i) => this.value[i] || '')
  },

  methods: {
    checkValidKey (e) {
      if (this.numbers && isNaN(e.key)) {
        e.stopPropagation()
        e.preventDefault()
      }
    },

    onAutoComplete (e, index) {
      e.preventDefault()
      const currentDigit = e.target.value
      const nextDigit = e.target.nextElementSibling && e.target.nextElementSibling.tagName === 'SPAN' ? e.target.nextElementSibling.nextElementSibling : e.target.nextElementSibling
      const previousDigit = e.target.previousElementSibling && e.target.previousElementSibling.tagName === 'SPAN' ? e.target.previousElementSibling.previousElementSibling : e.target.previousElementSibling

      if (currentDigit && e.code !== 'Space' && e.code !== 'Backspace') {
        if (nextDigit) {
          if (nextDigit.value === '') nextDigit.focus()
        } else {
          e.target.blur()
        }
      } else if (e.code === 'Backspace' && previousDigit && currentDigit === '') {
        this.digits[index - 1] = ''
        previousDigit.value = ''
        previousDigit.focus()
      } else if (e.code === 'Backspace' && currentDigit !== '') {
        this.digits[index] = ''
        e.target.value = ''
      } else if (e.code === 'Space') {
        this.digits[index] = ''
        e.target.value = ''
      }
      if (this.isCodeComplete) {
        this.$emit('complete', this.digits.join(''))
      }
      this.$emit('update:modelValue', this.digits.join(''))
    },

    onInputFocus (e) {
      e.target.selectionStart = 1
    },

    onKeyDown (e) {
      if (e.code === 'Backspace') {
        e.preventDefault()
      }
    },

    splitDigits (digits) {
      this.digits = digits.split('')
    },

    onPaste (e) {
      let contentPasted = e.clipboardData.getData('text')

      if (this.numbers) {
        contentPasted = contentPasted.replace(/\D/g, '')
      }

      if (contentPasted.length >= this.numberOfDigits) {
        const reduceContentPasted = contentPasted.substring(0, this.numberOfDigits)
        this.splitDigits(reduceContentPasted)
      } else {
        this.splitDigits(contentPasted)
        const inputs = this.$refs.input
        inputs[contentPasted.length].select()
      }

      if (this.isCodeComplete) {
        this.$emit('complete', this.digits.join(''))
      }
      this.$emit('update:modelValue', this.digits.join(''))
    },

    selectItem (e) {
      e.target.select()
    }
  }
}
</script>
