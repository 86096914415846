<template>
  <svg viewBox="0 0 24 24">
    <path d="M15.279 5.314a7 7 0 0 1 .366 9.504l4.072 4.072a.5.5 0 0 1 0 .707l-.707.707a.5.5 0 0 1-.707 0l-4.166-4.165A7 7 0 1 1 15.28 5.314zm-1.414 1.414A5 5 0 1 0 6.794 13.8a5 5 0 0 0 7.07-7.07z" />
  </svg>
</template>

<script>
export default {
}
</script>
