<template>
  <svg viewBox="0 0 24 24">
    <path d="M17.133 1.5C18.164 1.5 19 2.318 19 3.326v17.348c0 1.008-.836 1.826-1.867 1.826H6.867C5.836 22.5 5 21.682 5 20.674V3.326C5 2.318 5.836 1.5 6.867 1.5zm-5.149 17.53a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm5.15-15.704H6.866v14.609h10.266z" />
  </svg>
</template>

<script>
export default {
}
</script>
