'use strict'

import { ECoreBannerTheme } from '@common/core-ui'
import dayjs from 'dayjs'
import { decodeJwt } from 'jose'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import links from '@/config/links'
import store from '@/config/store'
import { db } from '@/helpers/db'
import bus from '@/helpers/eventBus'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n, { globalTranslation } from '@/locales'
import { useAccountStore } from '@/stores/account'
import { useAccountingStore } from '@/stores/accounting'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useCardStore } from '@/stores/card'
import { useCreditStore } from '@/stores/credit'
import { useDebitStore } from '@/stores/debit'
import { useExpenseStore } from '@/stores/expense'
import { useInvitationStore } from '@/stores/invitation'
import { useMessageStore } from '@/stores/message'
import { useMileageStore } from '@/stores/mileage'
import { useOperationStore } from '@/stores/operation'
import { useRefundStore } from '@/stores/refund'
import { useScaStore } from '@/stores/sca'
import { useServiceStore } from '@/stores/service'
import { useStatementStore } from '@/stores/statement'
import { useTeamStore } from '@/stores/team'
import { useTransferStore } from '@/stores/transfer'
import { useUserStore } from '@/stores/user'

import Inactivity from '@/components/modals/Inactivity.vue'

import { useDocumentStore } from './document'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      user: {
        loggedIn: false,
        token: null,
        decodedToken: null,
        uuid: null,
        gender: null,
        first_name: '',
        last_name: '',
        sca: {
          enforced: false,
          configured: false
        },
        email: '',
        phone: '',
        language: '',
        birth_date: null,
        picture: {},
        numberOfAccounts: 0,
        preferences: {
          cards: {
            list_display_type: 'grid'
          }
        }
      },
      inactivityTimeout: null,
      redirectAfterLogin: null,
      isTokenLoading: false,
      loading: null
    }
  },

  persist: {
    paths: ['user'],
    afterRestore: ctx => {
      ctx.store.loading = ctx.store.refreshUser
    }
  },

  share: {
    omit: ['redirectAfterLogin', 'isTokenLoading', 'loading'],
    enable: true,
    initialize: true
  },

  getters: {
    userFullName: state => `${state.user.first_name} ${state.user.last_name}`.trim(),
    numberOfAccounts: state => state.user.numberOfAccounts,
    isCardListDisplay: state => state.user.preferences.cards.list_display_type === 'list' && !useAppStore().isDeviceMobile,
    isTokenExpired: state => (state.user.decodedToken?.exp || 0) <= (Math.floor(new Date().getTime() / 1000) + store.data.deltaTime),
    isScaSectionEnforced: state => state.user.sca.enforced && !state.user.sca.configured
  },

  actions: {
    patchUser (data, loadDefaultAccount) {
      this.$patch(state => {
        state.user.loggedIn = true
        state.user.token = data.token
        state.user.decodedToken = decodeJwt(data.token)
        state.user.uuid = data.uuid
        state.user.gender = data.gender
        state.user.first_name = data.first_name
        state.user.last_name = data.last_name
        state.user.language = data.language
        state.user.sca = {
          enforced: data.sca.enforced,
          configured: data.sca.configured
        }
        state.user.email = data.email
        state.user.phone = data.phone
        state.user.birth_date = data.birth_date
        state.user.picture = data.picture
        state.user.numberOfAccounts = data.accounts.count
        state.user.defaultAccount = data.accounts.default
        state.user.preferences = data.preferences
      })

      if (data.language) i18n.global.locale = data.language

      if (loadDefaultAccount) {
        useAccountStore().setAccount(data.accounts.default)

        this.setInactivityTimeout(this.user.decodedToken)
      }
    },

    async auth (username, password) {
      try {
        const { data } = await axiosClient.get('/user/auth', { auth: { username, password } })
        if (!data.accounts.count) {
          useAppStore().showBanner(ECoreBannerTheme.Danger, store.translations.message.warning.unknown)
          return false
        }

        this.resetStores()

        this.patchUser(data, true)

        await this.router.push(this.redirectAfterLogin || { name: 'dashboard' })
        this.redirectAfterLogin = null

        bus.emit('auth-success')

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async reAuth (username, password) {
      try {
        const { data } = await axiosClient.get('/user/auth', { auth: { username, password } })
        this.user.loggedIn = true
        this.setUserToken(data.token)
        this.setInactivityTimeout(this.user.decodedToken)

        return { success: true, error: null }
      } catch (e) {
        console.error(e)
        return { success: false, error: e }
      }
    },

    async acceptInvitation (username, password, invitation_token) {
      try {
        const { data } = await axiosClient.get('/user/auth', { auth: { username, password }, params: { invitation_token } })

        this.patchUser(data, true)

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async saveUser (user) {
      const payload = new FormData()

      if (user.files) {
        user.files.forEach(file => {
          payload.append('files[]', file)
        })
      }
      delete user.files

      payload.append('jsonBody', JSON.stringify(user))

      try {
        const { data } = await axiosClient.post('/user', payload, { params: { invitation_token: user.token } })

        this.patchUser(data, true)

        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async updatePassword (current_password, password, password_repeat) {
      try {
        const { data } = await axiosClient.put('/user/auth', { current_password, password, password_repeat })
        showToastSuccess(data.message || 'Success')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async passwordReset (token, password, password_repeat) {
      try {
        const { data } = await axiosClient.put(
          '/password-recovery/password',
          { password, password_repeat },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        this.router.push('/login')
        showToastSuccess(data.message || 'Success')
        return { error: null }
      } catch (e) {
        console.error(e)
        return { error: e.response }
      }
    },

    async passwordRecovery (email) {
      try {
        const { data } = await axiosClient.post('/password-recovery', { email, callback: `${links.CLIENT}/reset-password` })
        this.router.push('/login')
        showToastSuccess(data.message || 'Success')
      } catch (e) {
        console.error(e)
      }
    },

    async updatePhoneNumber (phone) {
      try {
        const { data } = await axiosClient.put('/user/phone', { phone })
        this.user.phone = data.phone
        showToastSuccess(globalTranslation('message.success.phone.update') || 'Success')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async refreshUser () {
      if (this.isTokenExpired) {
        return
      }

      try {
        const { data } = await axiosClient.get('/user')

        this.patchUser(data, true)
      } catch (e) {
        console.error(e)
      }

      this.loading = null
    },

    async sendPicture (picture) {
      const payload = new FormData()

      payload.append('files', picture, 'avatar.png')

      try {
        const { data } = await axiosClient.post('/users/documents/picture', payload)
        this.user.picture = data
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getVehicleInfos () {
      try {
        const { data } = await axiosClient.get('/user/vehicle')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async updateVehicleInfos (body) {
      const payload = new FormData()

      body.files.filter(file => file instanceof File).forEach(file => payload.append('files[]', file))
      delete body.files

      payload.append('jsonBody', JSON.stringify(body))

      try {
        const { data } = await axiosClient.post('/user/vehicle', payload)
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async updateDisplaySettings (item, value) {
      try {
        await axiosClient.put('/accounts/display-sections', { [item]: value })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getAccountNotifications () {
      try {
        const { data } = await axiosClient.get('/notifications/account')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getUserNotifications () {
      try {
        const { data } = await axiosClient.get('/notifications/user')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async updateAccountNotificationsPreferences (payload) {
      try {
        await axiosClient.put('/notifications/account', payload)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateUserNotificationsPreferences (payload) {
      try {
        await axiosClient.put('/notifications/user', payload)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateUserLanguagePreferences (language) {
      try {
        await axiosClient.put('/user/language', { language })
        i18n.global.locale = language
        bus.emit('language-changed')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateUserPreferences (preferences) {
      try {
        const { data } = await axiosClient.put('/user/preferences', preferences)
        this.user.preferences = data
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async logout () {
      try {
        await axiosClient.delete('/user/auth', { doNotShowBanner: true })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async refreshToken () {
      if (this.isTokenLoading) {
        return
      }

      try {
        this.isTokenLoading = true
        store.api.hideProgressBar = true
        const { headers } = await axiosClient.get('/session/refresh')
        this.setUserToken(headers['x-authorization-token'])
        return true
      } catch (e) {
        console.error(e)
        return false
      } finally {
        this.isTokenLoading = false
        store.api.hideProgressBar = false
      }
    },

    setUserToken (token) {
      this.user.token = token
      this.user.decodedToken = token ? decodeJwt(token) : null
    },

    replaceToken (newToken) {
      if (!this.user.token) { return }

      const decodedNewToken = decodeJwt(newToken)
      if (decodedNewToken.exp > this.user.decodedToken?.exp) {
        this.setUserToken(newToken)
        const modal = useAppStore().modal
        if (!modal.active || modal.component.name !== 'request-sca-validation') {
          this.setInactivityTimeout(this.user.decodedToken)
        }
      }
    },

    resetStores (clearAuth = true) {
      if (clearAuth) {
        const redirectAfterLogin = this.redirectAfterLogin

        this.$reset()

        this.redirectAfterLogin = redirectAfterLogin

        useAccountStore().$reset()
      }

      db.pictures.clear()
      useAccountingStore().$reset()
      useBeneficiaryStore().$reset()
      useCardStore().$reset()
      useCreditStore().$reset()
      useDebitStore().$reset()
      useDocumentStore().$reset()
      useExpenseStore().$reset()
      useInvitationStore().$reset()
      useMessageStore().$reset()
      useMileageStore().$reset()
      useOperationStore().$reset()
      useRefundStore().$reset()
      useScaStore().$reset()
      useServiceStore().$reset()
      useStatementStore().$reset()
      useTeamStore().$reset()
      useTransferStore().$reset()
      useUserStore().$reset()
    },

    clearInactivityTimeout () {
      clearTimeout(this.inactivityTimeout)
      this.inactivityTimeout = null
    },

    setInactivityTimeout (decodedNewToken) {
      if (this.inactivityTimeout) { clearTimeout(this.inactivityTimeout) }

      const time = (decodedNewToken.exp - dayjs().unix() - 30 - store.data.deltaTime) * 1000
      this.inactivityTimeout = setTimeout(() => {
        if (this.router.requireAuth()) {
          useAppStore().showModal(
            Inactivity,
            { tokenExpireAt: decodedNewToken.exp },
            { onBackgroundLeaveAction: true, wrapperClass: 'modal--xs' }
          )
        }
      }, time)
    }
  }
})
