<template>
  <router-link v-if="!loading"
               class="dashboard__block"
               :class="`dashboard__block--${color}`"
               :to="{ name: 'transfers' }">
    <h2 class="number-label">
      {{ dashboard.pending_transfers_count }}
    </h2>
    <span class="sub-label">
      {{ $tc('label.processing_transfer', dashboard.pending_transfers_count) }}
    </span>
  </router-link>
  <dashboard-loading v-else
                     :color="color" />
</template>

<script>
import DashboardLoading from '@/pages/dashboard/components/DashboardLoading.vue'

export default {
  components: {
    DashboardLoading
  },

  props: {
    color: {
      type: String,
      required: false,
      default: 'white'
    },

    dashboard: {
      type: Object,
      required: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
