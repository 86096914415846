<template>
  <section class="section section-statements">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.statements") }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions"
             @click.prevent.stop="onCreateDispatch(null, roles)">
          <button class="btn btn--primary">
            {{ $t("button.new_dispatch") }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <template v-else-if="dispatch.length">
        <table class="table table--hover">
          <thead>
            <tr>
              <th class="col--sm-3">
                {{ $t("table.fullname") }}
              </th>
              <th class="col--sm-3">
                {{ $t("general.email") }}
              </th>
              <th class="col--sm-2">
                {{ $t("table.role") }}
              </th>
              <th class="col--sm-2">
                {{ $t("table.add_date") }}
              </th>
              <th class="col--sm-1 center">
                {{ $t("table.attachment") }}
              </th>
              <th class="col-action" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dispatch"
                :key="index">
              <td>{{ `${item.first_name} ${item.last_name}` }}</td>
              <td>{{ item.mail }}</td>
              <td>{{ getRoleLabel(item.role) }}</td>
              <td>{{ formatDate(item.created_at) }}</td>
              <td class="center">
                <toggle-switch v-model="item.include_attach"
                               :toggle-class="'switch-btn--sm'"
                               @update:model-value="statementStore.editStatementDispatch(item)" />
              </td>
              <td class="col-action">
                <span class="hover-item">
                  <button v-tooltip="{ content: $t('button.edit'), theme: 'tooltip' }"
                          class="btn-link"
                          @click="onCreateDispatch(item, roles)">
                    <ic-pencil class="ic ic--24 ic--gray" />
                  </button>
                  <button v-tooltip="{ content: $t('button.delete'), theme: 'tooltip' }"
                          class="btn-link"
                          @click.prevent.stop="appStore.showDeleteModal({ label: $t('prompt.statement_dispatch.title'), content: $t('prompt.statement_dispatch.content'), url: `/statement-dispatch/${item.id}` })">
                    <ic-remove class="ic ic--24 ic--gray" />
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <component-pagination :pagination="dispatchPagination" />
      </template>
      <component-placeholder v-else
                             :label="$t('placeholder.no_statement_dispatch.title')"
                             content="">
        <statement-email />
      </component-placeholder>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useStatementStore } from '@/stores/statement'
import { useUserStore } from '@/stores/user'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import StatementEmail from '@/components/svg/img/statement-email.vue'
import Tabs from '@/components/Tabs.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

import CreateStatementDispatch from './modal/CreateStatementDispatch.vue'

export default {
  components: {
    IcPencil,
    IcRemove,
    StatementEmail,
    ComponentPlaceholder,
    ComponentPagination,
    ToggleSwitch,
    Tabs,
    LoaderSpinner
  },

  props: {
    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const statementStore = useStatementStore()
    const userStore = useUserStore()

    const { account } = storeToRefs(accountStore)
    const { dispatch, dispatchPagination } = storeToRefs(statementStore)

    return { account, appStore, dispatch, dispatchPagination, statementStore, userStore }
  },

  data () {
    return {
      loading: true,
      roles: []
    }
  },

  computed: {
    sections () {
      return [
        {
          label: this.$i18n.t('button.my_statements'),
          route: { name: 'statements' },
          active: false
        },
        {
          label: this.$i18n.t('button.statement_dispatch'),
          route: { name: 'statements-dispatch' },
          active: true
        }
      ]
    }
  },

  watch: {
    page () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  async mounted () {
    await this.getRoles()

    this.$bus.on('dispatch-save-success', this.onSaveDispatchSuccess)
    this.$bus.on('dispatch-update-success', this.onUpdateDispatchSuccess)
    this.$bus.on('delete-success', this.onDeleteDispatchSuccess)
    this.$bus.on('account-switched', this.resetData)
    this.$bus.on('language-changed', this.getRoles)
  },

  beforeUnmount () {
    this.$bus.off('dispatch-save-success', this.onSaveDispatchSuccess)
    this.$bus.off('dispatch-update-success', this.onUpdateDispatchSuccess)
    this.$bus.off('delete-success', this.onDeleteDispatchSuccess)
    this.$bus.off('account-switched', this.resetData)
    this.$bus.off('language-changed', this.getRoles)
  },

  methods: {
    formatDate,

    async initData (showLoader = true) {
      this.appStore.closeModal()
      this.loading = showLoader
      await this.statementStore.getStatementsDispatch(this.page)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    onDeleteDispatchSuccess () {
      showToastSuccess(this.$i18n.t('message.success.statement_dispatch.delete'))
      this.initData(false)
    },

    onUpdateDispatchSuccess () {
      showToastSuccess(this.$i18n.t('message.success.statement_dispatch.update'))
      this.initData(false)
    },

    onSaveDispatchSuccess () {
      showToastSuccess(this.$i18n.t('message.success.statement_dispatch.save'))
      this.initData(false)
    },

    onCreateDispatch (model = null, roles) {
      this.appStore.showModal(CreateStatementDispatch, { model, roles }, { wrapperClass: 'modal--md' })
    },

    async getRoles () {
      this.roles = await this.userStore.getRoles()
    },

    getRoleLabel (role) {
      const rolesTranslations = this.roles.reduce((acc, role) => {
        acc[role.role] = role.role_label
        return acc
      }, {})

      return rolesTranslations[role] || role
    }
  }
}
</script>
