<template>
  <svg viewBox="0 0 200 160">
    <path d="m100.25 51h-27v1.5h27zm10 6h-37v1.5h37zm15.5 7.9997h-52c-.4142 0-.75.3361-.75.7503v19.75h1.5v-1h11.5v7.5h-3v1.5h3v7.5h-3v1.5h42.77c.4142 0 .75-.3358.75-.75v-36c0-.2024-.0817-.3963-.2267-.5375-.145-.1412-.341-.2179-.5433-.2127zm-39.75 18.0003h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm13 27h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm13 27h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm13 27h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5zm0-9h-11.5v-7.5h11.5z"
          :fill="colorGrey" />
    <path d="m66.5 36v1.5h66.5v-1.5zm-14.5 63.5h25.5v-7.5h-25.5zm1.5-6h22.5v4.5h-22.5z"
          :fill="colorPrimary" />
    <path d="m70.75 129.5h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm-.75-4h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm57.25-87.5h-58c-3.1734.0055-5.7445 2.5767-5.75 5.75v53.75h1.5v-53.75c0-2.3472 1.9028-4.25 4.25-4.25h58c2.3472 0 4.25 1.9028 4.25 4.25v88.25h-1.25c-3.7256.0055-6.7445 3.0244-6.75 6.75v1.25h-42v1.5h42.77c.1988 0 .3895-.0793.53-.22l8-8c.1407-.1405.22-.3312.22-.53v-89c-.0026-1.5276-.612-2.9917-1.6941-4.07-1.0821-1.0783-2.5482-1.6826-4.0759-1.68zm-2.25 100.94v-.19c.0055-2.8972 2.3528-5.2445 5.25-5.25h.19zm-55.75-17.44h6c.4142 0 .75-.3358.75-.75v-6c-.0154-.4077-.3423-.7346-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm-18.75 6h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm-.75 26h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm8.25 6h6c.4098-.0105.7395-.3402.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm-9.75-4h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm8.25 6h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5zm18.25-26.5h-32c-.4142 0-.75.3358-.75.75v46c0 .4142.3358.75.75.75h32c.4142 0 .75-.3358.75-.75v-1.18-44.07-.75c0-.4142-.3358-.75-.75-.75zm-.75 46h-30.5v-44.5h30.5zm-18.25-23.5h6c.4142 0 .75-.3358.75-.75v-6c0-.4142-.3358-.75-.75-.75h-6c-.4142 0-.75.3358-.75.75v6c0 .4142.3358.75.75.75zm.75-6h4.5v4.5h-4.5z"
          :fill="colorDark" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
