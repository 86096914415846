<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'refunds' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ title }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div v-if="!isExistingRefund"
             class="section-actions">
          <a v-if="refund.documents.length"
             href="#"
             class="btn-link btn-link--attachment"
             @click="onShowDocuments">
            <i class="btn-icon btn-icon--left">
              <ic-document class="ic ic-document ic--gray" />
            </i>
            <span class="btn__label">{{ transferDocumentsLabel }}</span>
            <button class="btn-link btn-remove-file"
                    @click.prevent.stop="removeFiles">
              <ic-remove class="ic ic--18 ic--gray" />
            </button>
          </a>
          <button v-show="!refund.documents.length"
                  class="btn btn--default"
                  @click.prevent.stop="appStore.showDropzoneModal($t('title.attach_proof'), {}, { canAnalyzeFile: true, type: 'refund' })">
            <i class="btn-icon btn-icon--left">
              <ic-attachment class="ic ic--white" />
            </i>
            {{ $t('button.attach_proof') }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <validation-form ref="form"
                       v-slot="{ errors, isSubmitting }"
                       :validation-schema="schema"
                       class="form form--w1000 form-wiretransfer form--centered"
                       @submit="onSubmit"
                       @keydown.enter.prevent>
        <component-alert v-if="Object.keys(errors).length && formSubmitted"
                         type="error"
                         :message="$t('form.alerts.common')" />
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.credit_account.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div v-if="existingBeneficiary"
                 class="form__row row row--sm-bottom">
              <div class="form-group col col--sm-7">
                <label class="label input__label">{{ $t('form.credit_account.label') }}</label>
                <div class="form-inline input-pencil-addon">
                  <div class="form-control form-control--noborder disabled">
                    {{ beneficiaryLabel }}
                  </div>
                  <button v-if="!isExistingRefund"
                          class="btn btn--icon btn--default"
                          type="button"
                          @click="onEditBankDetails">
                    <i class="btn-icon">
                      <ic-pencil class="ic ic--20 ic--white" />
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <field-placeholder v-else
                               :title="$t('form.refund.add_bank_details.title')"
                               :subtitle="$t('form.refund.add_bank_details.subtitle')"
                               @click="showCreateBeneficiary" />
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="isUserEmployee ? $t('form.refunds.refund_details') : $t('form.refunds.payment_details')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.amount, 'is-valid': imported.amount }">
                <label class="label input__label"
                       for="amount">{{ $t('form.amount.label') }}</label>
                <div class="input-group">
                  <number-input id="amount"
                                v-model="refund.amount"
                                name="amount"
                                :placeholder="$t('form.amount.placeholder')"
                                class="form-control form-control--noborder input__field"
                                decimal
                                :minimum-fraction-digits="2"
                                @update:model-value="removeValidClass('amount')" />
                  <span class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
                </div>
              </div>
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.expense_date, 'is-valid': imported.expense_date }">
                <component-datepicker v-model="refund.expense_date"
                                      name="expense_date"
                                      :disabled-days="disabledFrom"
                                      :label="$t('form.expense_date.label')"
                                      :language="$i18n.locale"
                                      format="dd/MM/yyyy"
                                      wrapper-class="form-group"
                                      input-class="form-control form-control--noborder"
                                      monday-first
                                      :readonly="false"
                                      :placeholder="$t('form.date.placeholder')"
                                      :disable-input="isExistingRefund"
                                      @selected="removeValidClass('expense_date')" />
              </div>
            </div>
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.label, 'is-valid': imported.label }">
                <label class="label input__label"
                       for="label">
                  {{ $t('general.reason') }}</label>
                <div class="dropdown">
                  <validation-field id="label"
                                    v-model="refund.label"
                                    type="text"
                                    class="form-control form-control--noborder input__field"
                                    :placeholder="$t('form.transfer_details.beneficiary_placeholder_short')"
                                    name="label"
                                    @input="removeValidClass('label')" />
                </div>
              </div>
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.comment }">
                <label class="label input__label"
                       for="comment">{{ $t('form.personal_note.label') }} ({{ $t('general.optional') }})</label>
                <validation-field id="comment"
                                  v-model="refund.comment"
                                  type="text"
                                  class="form-control form-control--noborder input__field"
                                  :placeholder="$t('form.personal_note.placeholder')"
                                  name="comment" />
              </div>
            </div>
            <div class="form__row row">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.category }">
                <label class="label input__label">{{ $tc("form.category.label") }}</label>
                <component-dropdown label="categories"
                                    dropdown-menu-class="dropdown-menu--noborder"
                                    multiselect
                                    dropdown-width="100%"
                                    search
                                    :can-add-item="hasPermission($permissions.categoriesWrite)"
                                    async-url="/categories"
                                    :async-url-params="{ filter: 'refund' }"
                                    has-icon
                                    :model="refund.categories"
                                    @select="onCategorySelected"
                                    @new-item="onNewCategoryAdded">
                  <template #trigger>
                    <div class="dropdown-toggle select">
                      <div class="form-control form-control--noborder">
                        <span v-if="!refund.categories.length"
                              class="text-muted">{{ $t('form.category.placeholder') }}</span>
                        <span v-else>{{ categoriesLabel }}</span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
              <div v-if="isAffectationsInputVisible"
                   class="form-group col col--sm-6"
                   :class="{ error: errors.affectations }">
                <label class="label input__label">{{ $t("form.affectation.label") }}
                  <template v-if="affectations.length < 2">({{ $t('general.optional') }})</template>
                </label>
                <validation-field name="affectations"
                                  :model-value="refund.affectations">
                  <component-dropdown label="affectations"
                                      dropdown-menu-class="dropdown-menu--noborder"
                                      multiselect
                                      dropdown-width="100%"
                                      search
                                      :can-add-item="canWriteAffectations"
                                      async-url="/affectations"
                                      get-data-on-mounted
                                      emiter="on-affectations-loaded"
                                      :model="refund.affectations"
                                      :disabled="!canEditAffectations"
                                      @select="onAffectationsSelected"
                                      @new-item="onNewAffectationAdded">
                    <template #trigger>
                      <div class="dropdown-toggle select">
                        <div class="form-control form-control--noborder">
                          <span v-if="!refund.affectations.length"
                                class="text-muted">{{ $t('form.affectation.placeholder') }}</span>
                          <span v-else>{{ affectationsLabel }}</span>
                        </div>
                      </div>
                    </template>
                  </component-dropdown>
                </validation-field>
              </div>
            </div>
            <category-form-fields v-model:transfer="refund" />
          </div>
        </fieldset>
        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'refunds' }">
            {{ $t('button.cancel') }}
          </router-link>
          <button type="submit"
                  class="btn btn--primary"
                  :disabled="isSubmitting">
            {{ $t('button.validate') }}
          </button>
        </div>
      </validation-form>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatIbanHidden } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useOperationStore } from '@/stores/operation'
import { useRefundStore } from '@/stores/refund'
import { useTransferStore } from '@/stores/transfer'

import FinishAnalyzeInvoice from '@/pages/transfers/modal/FinishAnalyzeInvoice.vue'

import ComponentAlert from '@/components/Alert.vue'
import CategoryFormFields from '@/components/CategoryFormFields.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FieldPlaceholder from '@/components/FieldPlaceholder.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import NeedsValidation from '@/components/modals/NeedsValidation.vue'
import NumberInput from '@/components/NumberInput.vue'
import SidepanelEditBankDetails from '@/components/sidepanels/SidepanelEditBankDetails.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcAttachment,
    IcDocument,
    IcPencil,
    IcRemove,
    IcArrow,
    ComponentAlert,
    ComponentDropdown,
    ComponentDatepicker,
    CategoryFormFields,
    FieldPlaceholder,
    NumberInput,
    FieldsetTitle
  },

  props: {
    id: {
      type: [Number, null],
      default: null
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const operationStore = useOperationStore()
    const refundStore = useRefundStore()
    const transferStore = useTransferStore()

    const { account, isUserEmployee } = storeToRefs(accountStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { userFullName } = storeToRefs(authStore)
    const { operationCategoriesCard } = storeToRefs(operationStore)
    const { refunds } = storeToRefs(refundStore)

    return {
      appStore,
      operationStore,
      refundStore,
      transferStore,

      account,
      apiError,
      isUserEmployee,
      userFullName,
      operationCategoriesCard,
      refunds
    }
  },

  data () {
    return {
      isIban: false,
      loading: false,
      refund: {
        amount: '',
        currency: 'EUR',
        label: '',
        comment: '',
        categories: [],
        affectations: [],
        documents: [],
        service: 'noon',
        guest: '',
        nights: '',
        fuel: {},
        liters: '',
        mileage: '',
        half_board_included: false,
        expense_date: new Date()
      },

      beneficiary: {
        iban: '',
        bic: ''
      },

      disabledFrom: {
        from: new Date()
      },

      imported: {},

      formSubmitted: false,
      affectations: []
    }
  },

  computed: {
    schema () {
      return yup.object({
        amount: yup.number().min(0.01).required(),
        expense_date: yup.date().required(),
        label: yup.string().required(),
        comment: yup.string().nullable(),
        affectations: this.affectations.length ? yup.array().min(1).required() : null
      })
    },

    existingBeneficiary () {
      return this.account.beneficiary
    },

    beneficiaryLabel () {
      return `${this.userFullName} - ${formatIbanHidden(this.existingBeneficiary.iban)}`
    },

    isExistingRefund () {
      return this.id !== null
    },

    title () {
      return this.isExistingRefund
        ? (this.isUserEmployee ? this.$t('title.edit_refund') : this.$t('title.edit_payment'))
        : (this.isUserEmployee ? this.$t('title.new_refund') : this.$t('title.new_payment'))
    },

    categoriesLabel () {
      if (!this.refund.categories.length) { return ' - ' }

      return this.refund.categories.map(category => category.label).join(', ')
    },

    affectationsLabel () {
      if (!this.refund.affectations.length) { return ' - ' }

      return this.refund.affectations.map(affectation => affectation.label).join(', ')
    },

    transferDocumentsLabel () {
      return this.refund.documents.length === 1
        ? this.refund.documents[0].filename || this.refund.documents[0].name
        : `${this.refund.documents.length} documents`
    },

    hasRestaurantCategory () {
      return this.refund.categories.some(category => category.name === 'restaurant')
    },

    hasHotelCategory () {
      return this.refund.categories.some(category => category.name === 'hotel-logement')
    },

    hasFuelStationCategory () {
      return this.refund.categories.some(category => category.name === 'station-essence')
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.refundsAffectationsRead)
    },

    canEditAffectations () {
      return this.canReadAffectations && this.hasPermission(this.$permissions.refundsAffectationsWrite)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    hasAffectations () {
      return this.refund.affectations?.length
    },

    isAffectationsInputVisible () {
      return this.canEditAffectations || (this.canReadAffectations && this.hasAffectations)
    },

    getRefundPayload () {
      const refund = cloneDeep(this.refund)
      refund.expense_date = dayjs(refund.expense_date).format('YYYY-MM-DD')
      if (!this.hasRestaurantCategory) {
        delete refund.service
        delete refund.guest
      }

      if (!this.hasHotelCategory) {
        delete refund.nights
        delete refund.half_board_included
      }

      if (!this.hasFuelStationCategory) {
        delete refund.fuel
        delete refund.liters
        delete refund.mileage
      } else {
        refund.fuel = refund.fuel?.value
      }

      refund.beneficiary = this.existingBeneficiary || this.beneficiary

      refund.documents = this.refund.documents

      return refund
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  async created () {
    this.loading = true
    if (!this.operationCategoriesCard.length) {
      await this.operationStore.getOperationCategories()
    }

    if (!this.isExistingRefund) {
      this.loading = false
      return
    }

    const refund = this.refunds.find(refund => refund.id === parseInt(this.id))
    if (!refund) {
      return this.goBackToList()
    }

    for (const key in this.refund) {
      if (Object.prototype.hasOwnProperty.call(this.refund, key)) {
        this.refund[key] = refund[key]
      }
    }

    this.refund.id = refund.id
    this.refund.categories = [...refund.categories]
    this.refund.affectations = [...refund.affectations]
    this.refund.guest = refund.operation.detail.guest
    this.refund.expense_date = refund.operation.expense_date
    this.refund.nights = refund.operation.detail.nights
    this.refund.service = refund.operation.detail.service
    this.refund.fuel = refund.operation.detail.fuel
    this.refund.liters = refund.operation.detail.liters
    this.refund.mileage = refund.operation.detail.mileage
    this.refund.half_board_included = refund.operation.detail.half_board_included
    this.refund.comment = refund.operation.comment

    if (!this.existingBeneficiary) {
      this.beneficiary.iban = refund.beneficiary.iban
      this.beneficiary.bic = refund.beneficiary.bic
    }

    this.loading = false
  },

  mounted () {
    this.$bus.on('on-file-attached', this.onDocumentsAttached)
    this.$bus.on('delete-local-document', this.removeFile)
    this.$bus.on('on-affectations-loaded', this.onAffectationsLoaded)
    this.$bus.on('account-switched', this.goBackToList)
    this.$bus.on('refund-document-analyzed', this.onDocumentAnalyzed)
    this.$bus.on('delete-category', this.onDeleteCategory)
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
  },

  beforeUnmount () {
    this.$bus.off('on-file-attached', this.onDocumentsAttached)
    this.$bus.off('delete-local-document', this.removeFile)
    this.$bus.off('on-affectations-loaded', this.onAffectationsLoaded)
    this.$bus.off('account-switched', this.goBackToList)
    this.$bus.off('refund-document-analyzed', this.onDocumentAnalyzed)
    this.$bus.off('delete-category', this.onDeleteCategory)
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
  },

  methods: {
    getCurrencySymbol,

    onEditBankDetails () {
      this.appStore.showSidePanel(SidepanelEditBankDetails, {}, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true })
    },

    async onNewCategoryAdded (itemLabel) {
      const newCategory = await this.operationStore.addCategory(itemLabel)
      if (newCategory) {
        this.refund.categories.push(newCategory)
        this.$bus.emit('on-new-item-added', newCategory)
      }
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.refund.affectations = [...this.refund.affectations, newAffectation]
        this.$bus.emit('on-new-item-added', newAffectation)
      }
    },

    onDeleteCategory (itemId) {
      const index = this.refund.categories.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.refund.categories.splice(index, 1)
      }
    },

    onDeleteAffectation (itemId) {
      const index = this.refund.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.refund.affectations.splice(index, 1)
      }
    },

    async onSubmit () {
      this.loading = true
      this.formSubmitted = true

      if (this.isExistingRefund) {
        const success = await this.refundStore.editRefund(this.getRefundPayload)
        this.loading = false
        if (success) {
          showToastSuccess(this.$t('message.success.refund.update'))
          await this.goBackToList()
        }
      } else {
        const data = await this.refundStore.saveRefund(this.getRefundPayload)
        this.loading = false
        if (data) {
          if (data.status === 'to_validate') {
            this.appStore.showModal(
              NeedsValidation,
              { type: 'refund' },
              { blockLeaveBackground: true }
            )
          } else {
            showToastSuccess(this.$t('message.success.refund.save'))
          }
          await this.goBackToList()
        }
      }
    },

    onCategorySelected (category) {
      this.refund.categories = [...category]
    },

    onAffectationsSelected (affectations) {
      this.refund.affectations = [...affectations]
    },

    onAffectationsLoaded (affectations) {
      this.affectations = [...affectations]
    },

    onDocumentAnalyzed (result) {
      this.imported = {}
      Object.entries(result).forEach(([key, value]) => {
        if (value && Object.prototype.hasOwnProperty.call(this.refund, key)) {
          this.refund[key] = value
          this.imported[key] = true
        }
      })
      this.appStore.showModal(FinishAnalyzeInvoice, { imported: this.imported }, { wrapperClass: 'modal-fixed-size' })
      this.showAnalyzeButton = false
    },

    onDocumentsAttached ({ files }) {
      files.forEach(file => this.refund.documents.push(file))
      this.appStore.closeModal()
    },

    removeFiles () {
      this.refund.documents = []
      Object.entries(this.imported).forEach(([key, value]) => {
        if (value) {
          this.refund[key] = ''
        }
      })
      this.imported = {}
    },

    removeValidClass (key) {
      if (this.imported[key]) {
        this.imported[key] = false
      }
    },

    onShowDocuments () {
      this.appStore.showFileViewer({
        content: this.refund.documents,
        isMultiDoc: true,
        canEdit: true,
        isLocal: true,
        displayFullScreen: true
      })
    },

    showCreateBeneficiary () {
      this.appStore.showSidePanel(SidepanelEditBankDetails, {}, { wrapperClass: 'sidepanel--lg', backgroundOverlay: true, closeButton: false })
    },

    goBackToList () {
      this.$router.push({ name: 'refunds' })
    }
  }
}
</script>
