export const showCardPinCode = async (
  token: string,
  cardPinDomId: string,
  cardPinCounter: number,
  cardPinToggleDomId: string,
  hidePinTimeoutDomId: string,
  onRevealSuccess: () => void,
  onHideSuccess: () => void,
  onSuccess: () => void,
  onFailure: () => void
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await marqeta.bootstrap({
    clientAccessToken: token,
    integrationType: 'custom',
    containerId: 'virtual-card-container',
    component: {
      pinReveal: {
        cardPin: {
          domId: cardPinDomId,
          styles: {
            span: {
              color: 'black',
              'font-family': 'Sans-serif',
              'font-size': '30px',
              'letter-spacing': '8.86px',
              background: 'transparent'
            }
          }
        },
        toggleCardPin: {
          domId: cardPinToggleDomId,
          mode: 'transparent',
          onRevealSuccess,
          onHideSuccess
        },
        hidePinTimeout: {
          domId: hidePinTimeoutDomId,
          hideTimeout: cardPinCounter,
          styles: {
            position: 'static'
          }
        }
      }
    },
    callbackEvents: { onSuccess, onFailure }
  })
}

export const showSecretInformations = (
  token: string,
  cardPanDomId: string,
  cardPanCopyDomId: string,
  cardExpDomId: string,
  cardCvvDomId: string,
  onCardPanCopySuccess: () => void,
  onSuccess: () => void,
  onFailure: () => void
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  marqeta.bootstrap({
    clientAccessToken: token,
    integrationType: 'custom',
    containerId: 'virtual-card-container',
    component: {
      showPan: {
        cardPan: {
          domId: cardPanDomId,
          format: true,
          styles: {
            span: {
              'font-size': '18.7px',
              color: 'white',
              'font-family': 'monospace',
              'letter-spacing': '2px',
              'white-space': 'nowrap'
            }
          }
        },
        copyCardPan: {
          domId: cardPanCopyDomId,
          mode: 'transparent',
          onCopySuccess: onCardPanCopySuccess
        },
        cardExp: {
          domId: cardExpDomId,
          format: true,
          styles: {
            span: {
              'font-size': '14px',
              'letter-spacing': '-0.5px',
              color: 'white',
              'font-family': 'monospace'
            }
          }
        },
        cardCvv: {
          domId: cardCvvDomId,
          styles: {
            span: {
              'font-size': '14px',
              'letter-spacing': '-0.5px',
              color: 'white',
              'font-family': 'monospace'
            }
          }
        }
      }
    },
    callbackEvents: {
      onSuccess,
      onFailure
    }
  })
}
