<template>
  <div class="col col--sm-6">
    <component-datepicker-v2 :from-value="formattedDates.from"
                             :to-value="formattedDates.to"
                             clear-button
                             :disabled="disabledPeriods"
                             input-class="form-control form-control--noborder"
                             :lang="$i18n.locale"
                             field-class="form-group filter-item filter-date"
                             :from-placeholder="$t('form.date.placeholder')"
                             :from-pre-addon="$t('form.from.label')"
                             :to-placeholder="$t('form.date.placeholder')"
                             :to-pre-addon="$t('form.to.label')"
                             size-class="sm"
                             @update:from-value="date => updateValue('from', date)"
                             @update:to-value="date => updateValue('to', date)" />
  </div>
</template>

<script>
import dayjs from 'dayjs'

import ComponentDatepickerV2 from '@/components/DatepickerV2.vue'

export default {
  name: 'DateFilter',
  components: { ComponentDatepickerV2 },
  props: {
    fromValue: {
      type: [String, null],
      required: true
    },

    toValue: {
      type: [String, null],
      required: true
    },

    disabledPeriods: {
      type: Object,
      required: true
    }
  },

  emits: ['update:to-value', 'update:from-value', 'update'],

  computed: {
    formattedDates () {
      return {
        from: this.fromValue ? dayjs(this.fromValue).toDate() : null,
        to: this.toValue ? dayjs(this.toValue).toDate() : null
      }
    }
  },

  methods: {
    updateValue (field, date) {
      const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null
      if (field === 'from') {
        this.$emit('update:from-value', formattedDate)
      } else if (field === 'to') {
        this.$emit('update:to-value', formattedDate)
      }
      this.$emit('update')
    }
  }
}
</script>
