<template>
  <div v-on-list-resize
       class="dashboard__block dashboard__block--table">
    <div class="dashboard__block__heading">
      <h3 class="title">
        {{ blockTitle }}
      </h3>
      <router-link v-if="isOperationLinkVisible"
                   class="view-more"
                   :to="{ name: 'operations' }">
        {{ $t("button.see_more") }}
      </router-link>
    </div>
    <loader-spinner v-if="loading" />
    <ul v-else-if="operations?.length > 0"
        class="list-transaction">
      <li v-for="item in operations"
          :key="item.id"
          @click="showTransferDetail(item)">
        <span class="date">{{ formatDate(item.accounting_date) }}</span>
        <span class="name">{{ item.short_label ? item.short_label : item.label }}</span>
        <span class="amount right"
              :class="{ 'amount--credit': item.type === 'credit', 'amount--debit': item.type === 'debit' }">
          {{ formatAmount(item.amount, item.currency) }}
        </span>
        <span v-if="isDocumentColVisible(item)"
              class="attachment">
          <button v-tooltip="{ content: fileTooltip(item), theme: 'tooltip' }"
                  class="btn-link btn-attachment"
                  @click.prevent.stop="documentCheckMethod(item)">
            <ic-attachment-sm v-bind="{ [iconType(item)]: true }"
                              class="ic ic--16 ic--gray" />
          </button>
        </span>
      </li>
    </ul>
    <p v-else
       class="empty-text">
      {{ $t("placeholder.no_operation.title") }}
    </p>
  </div>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'
import { formatDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcAttachmentSm from '@/components/svg/icons/ic-attachment-sm.vue'

export default {
  components: {
    LoaderSpinner,
    IcAttachmentSm
  },

  props: {
    isRevenue: {
      type: [Boolean, null],
      default: null
    },

    operations: {
      type: [Array],
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  computed: {
    blockTitle () {
      if (this.isRevenue === true) { return this.$i18n.t('title.recent_incomes') }
      if (this.isRevenue === false) { return this.$i18n.t('title.recent_expenses') }
      return this.$i18n.t('title.recent_operations')
    },

    isOperationLinkVisible () {
      return hasFeature(EFeature.SectionOperation)
    }
  },

  methods: {
    formatAmount,

    formatDate,

    isDocumentColVisible (item) {
      return this.hasPermission(this.$permissions.operationsDocumentsWrite) || (this.hasPermission(this.$permissions.operationsDocumentsRead) && this.hasDocument(item))
    },

    showTransferDetail (operation) {
      this.appStore.showSidePanel(OperationDetail, { model: operation }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, canDragAndDrop: !this.isProofBill(operation) })
    },

    fileTooltip (operation) {
      if (this.isProofLost(operation)) {
        return this.$i18n.t('tooltip.lost_proof')
      }
      if (this.isProofInvalid(operation)) {
        return this.$i18n.t('tooltip.invalid_proof')
      }
      if (this.isProofOptional(operation)) {
        return this.$i18n.t('button.optional_proof')
      }
      if (this.isProofUploaded(operation)) {
        return this.$i18n.tc('tooltip.show_file', operation.documents.length)
      }
      if (this.isProofBill(operation)) {
        return this.$i18n.tc('tooltip.show_file')
      }
      return this.$i18n.t('tooltip.attach_files')
    },

    documentCheckMethod (operation) {
      if (this.isProofUploaded(operation) || this.isProofBill(operation)) {
        this.appStore.showSidePanel(OperationDetail, { model: operation }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, initShowFile: true })
      } else if (this.isProofLost(operation)) {
        this.showTransferDetail(operation)
      } else {
        this.appStore.showDropzoneModal(
          this.$i18n.tc('title.attach_file', 2),
          operation,
          {
            url: `/operations/${operation.uuid}/documents`,
            canAnalyzeFile: true
          })
      }
    },

    isProofUploaded (operation) {
      return operation.documents.length
    },

    isProofBill (operation) {
      return operation.bill
    },

    hasDocument (operation) {
      return this.isProofUploaded(operation) || this.isProofBill(operation)
    },

    isProofInvalid (operation) {
      return operation.documents_status === 'invalid'
    },

    isProofLost (operation) {
      return operation.documents_status === 'lost'
    },

    isProofOptional (operation) {
      return operation.documents_status === 'optional'
    },

    iconType (operation) {
      if (this.isProofLost(operation)) {
        return 'danger'
      }
      if (this.isProofInvalid(operation)) {
        return 'invalid'
      }
      if (this.isProofOptional(operation)) {
        return 'optional'
      }
      if (this.isProofUploaded(operation) || this.isProofBill(operation)) {
        return 'document'
      }
      return 'attachment'
    }
  }
}
</script>
