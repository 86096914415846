<template>
  <div class="multiple-card-order-details form form--w1000 form--centered">
    <core-alert :theme="ECoreAlertTheme.Success"
                class="my-1"
                :icon-name="ECoreIconList.CheckCircleFilled"
                :text="$tc('form.multiple_cards.success', cardOrders.length)" />
    <fieldset class="form__fieldset">
      <div>
        <fieldset-title :title="$t('form.title.order_details')"
                        :subtitle="$t('form.subtitle.order_details')" />
        <div class="bordered-box">
          <div class="bordered-box__search">
            <div class="input-group input-group--noborder">
              <span class="input-group__addon">
                <ic-search class="ic ic--gray" />
              </span>
              <input v-model="filter"
                     class="form-control"
                     type="text"
                     :placeholder="$t('form.search.placeholder')">
            </div>
          </div>
          <div class="overflow-scrollbar order-details-list">
            <table class="table table--hover">
              <tbody>
                <tr v-for="(item, index) in filteredCardOrders"
                    :key="index"
                    class="is-link">
                  <td class="col-checkbox">
                    <label class="checkbox">
                      <input v-model="item.selected"
                             type="checkbox"
                             name="checkbox">
                      <span class="checkbox__icon" />
                    </label>
                  </td>
                  <td @click="editOrder(item.id)">
                    {{ `${item.card.first_name} ${item.card.last_name}` }}<br>
                    <span v-if="item.card.address"
                          class="text-muted">{{ addressLabel(item.card.address) }}</span>
                  </td>
                  <td class="text-muted"
                      @click="editOrder(item.id)">
                    {{ item.card.invitation.email }}
                  </td>
                  <td @click="editOrder(item.id)">
                    <span v-tooltip="{ content: getCardTypeLabel(item.card), theme: 'tooltip' }"
                          class="p-1">
                      <badge-visa :type="item.card.type" />
                    </span>
                  </td>
                  <td v-if="hasBankValidationRequest"
                      style="width: 20px;">
                    <span v-if="item.card.status === 'to_validate_by_bank'"
                          v-tooltip="{
                            content: $t('order_card.multiple.warning.to_validate_by_bank'),
                            theme: 'tooltip-md'
                          }">
                      <core-icon :name="ECoreIconList.Clock"
                                 :size="20"
                                 :theme="ECoreIconTheme.Info" />
                    </span>
                  </td>
                  <td class="right button-edit"
                      @click="editOrder(item.id)">
                    <button class="btn btn--outline btn-edit">
                      <ic-pencil class="ic ic--18 ic--gray" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="selectedCardOrders.length"
               class="bordered-box__summary">
            <div class="row row--sm-center">
              <div class="col col--sm">
                <strong>
                  {{ $tc('order_card.multiple.total_cards', selectedCardOrders.length) }}
                </strong>
              </div>
              <div class="col col--sm right">
                <strong>{{ formatAmount(totalCardPrice, account.currency) }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div v-if="useCompanyName"
             class="bordered-box validate-order">
          <div class="bordered-box__item">
            <label class="checkbox">
              <input v-model="acceptTerms"
                     type="checkbox"
                     name="checkbox">
              <span class="checkbox__icon" />
              <span>{{ $t('order_card.multiple.certify_address') }}</span>
            </label>
          </div>
        </div>
        <core-alert v-if="hasBankValidationRequest"
                    class="mt-2"
                    :text="$t('order_card.multiple.alert.bank_validation_request')"
                    :icon-name="ECoreIconList.InfoFilled" />
        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'cards-physical' }">
            {{ $t('button.cancel') }}
          </router-link>
          <component-button wrapper-class="btn--primary"
                            :label="$t('button.order')"
                            :disabled="(useCompanyName && !acceptTerms) || loading || !selectedCardOrders.length"
                            @click="onSubmit" />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { CoreAlert, CoreIcon, ECoreAlertTheme, ECoreIconList, ECoreIconTheme } from '@common/core-ui'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import { getCardTypeLabel, isPhysicalCard } from '@/helpers/utils/card'
import { getCountryName } from '@/helpers/utils/intl'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { textContainsFilter } from '@/helpers/utils/text'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import EditOrder from '@/pages/cards/sidepanel/EditOrder.vue'

import BadgeVisa from '@/components/BadgeVisa.vue'
import ComponentButton from '@/components/Button.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import IcSearch from '@/components/svg/icons/ic-search.vue'

export default {
  components: {
    CoreAlert,
    CoreIcon,
    IcPencil,
    IcSearch,
    BadgeVisa,
    ComponentButton,
    FieldsetTitle
  },

  emits: ['toggle-step'],

  setup () {
    const appStore = useAppStore()
    const accountStore = useAccountStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)
    const { cardOrders } = storeToRefs(cardStore)

    return { account, appStore, cardOrders, cardStore }
  },

  data () {
    return {
      filter: '',
      acceptTerms: false,
      loading: false
    }
  },

  computed: {
    ECoreAlertTheme () {
      return ECoreAlertTheme
    },

    ECoreIconTheme () {
      return ECoreIconTheme
    },

    ECoreIconList () {
      return ECoreIconList
    },

    hasBankValidationRequest () {
      return this.cardOrders.find(item => item.card.status === 'to_validate_by_bank')
    },

    useCompanyName () {
      return this.selectedCardOrders.some(item => item.card.address?.add_company_name)
    },

    filteredCardOrders () {
      return this.cardOrders.filter(item => textContainsFilter(`${item.card.first_name} ${item.card.last_name}`, this.filter))
    },

    selectedCardOrders () {
      return this.cardOrders.filter(item => item.selected)
    },

    totalCardPrice () {
      return this.selectedCardOrders.reduce((total, order) => total + (order.billing ? order.billing_amount : 0), 0)
    }
  },

  methods: {
    formatAmount,
    getCardTypeLabel,

    editOrder (id) {
      this.appStore.showSidePanel(
        EditOrder,
        { cardOrders: cloneDeep(this.cardOrders), cardOrderId: id },
        { wrapperClass: 'sidepanel--xxl', backgroundOverlay: true }
      )
    },

    toggleStep (step) {
      this.$emit('toggle-step', step)
    },

    addressLabel (address) {
      return `${address.street}, ${address.zip} ${address.city}, ${getCountryName(address.country)}`
    },

    formatCard (card) {
      const newCard = {
        first_name: card.first_name,
        last_name: card.last_name,
        company: card.invitation.company,
        type: card.type,
        phone: card.phone,
        label: card.label,
        metadata: {
          reason: card.reason
        },

        months_before_expiration: card.months_before_expiration,
        settings: { ...card.settings }
      }

      if (isPhysicalCard(card)) {
        newCard.address = card.address
        newCard.bulk_address = card.bulk_address
      }

      if (this.isRecurringCard) {
        for (const period in newCard.settings.payment) {
          if (period === card.periodicity) {
            newCard.settings.payment[period].enabled = true
            newCard.settings.payment[period].limit = newCard.settings.initial_amount
          } else {
            newCard.settings.payment[period].enabled = false
            newCard.settings.payment[period].limit = null
          }
        }
        delete newCard.settings.initial_amount
      }

      return newCard
    },

    async onSubmit () {
      this.loading = true

      const cardOrders = cloneDeep(this.selectedCardOrders)
      cardOrders.forEach(cardOrder => {
        cardOrder.invitation = cardOrder.card.invitation
        cardOrder.card = this.formatCard(cardOrder.card)
      })

      const message = await this.cardStore.orderMultipleCards(cardOrders)
      this.loading = false

      if (message) {
        showToastSuccess(message)
        await this.$router.push({ name: 'cards-physical' })
      }
    }
  }
}
</script>
