<template>
  <div class="section__content">
    <div v-if="loading || loadingFilter"
         class="section__loader">
      <loader-spinner />
    </div>
    <table v-else-if="statements.length"
           data-cy="statements.list"
           class="table table--hover">
      <thead>
        <tr>
          <th width="30">
            <label class="form-check__label checkbox">
              <input v-model="areAllSelected"
                     type="checkbox">
              <span class="checkbox__icon" />
            </label>
          </th>
          <th class="col--sm-4">
            {{ $t("table.period") }}
          </th>
          <template v-if="isCard">
            <th class="col--sm-4">
              {{ $t("table.end_date") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t('table.month_consumption') }}
            </th>
            <th class="col--sm-2 right" />
          </template>
          <template v-else>
            <th class="col--sm-3">
              {{ $t("table.end_date") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.date_balance") }}
            </th>
            <th class="col--sm-3 right" />
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in statements"
            :key="item.id"
            class="is-link"
            @click.prevent="toggleSelected(item)">
          <td>
            <label class="form-check__label checkbox">
              <input v-model="item.selected"
                     type="checkbox"
                     name="checkbox">
              <span class="checkbox__icon mr-0" />
            </label>
          </td>
          <td>{{ $t(`month.${item.month}`) }} {{ item.year }}</td>
          <td>{{ formatDate(item.closed_at) }}</td>
          <td class="right">
            <span class="amount"
                  :class="[item.eom_balance >= 0 ? 'text-green' : 'text-red']">
              {{ formatAmount(item.eom_balance, account.currency) }}
            </span>
          </td>
          <td class="right">
            <button v-if="item.document"
                    v-tooltip="{ content: $t('tooltip.download_pdf'), theme: 'tooltip' }"
                    class="btn-link"
                    @click.prevent.stop="downloadFileFromUrl(item.document.url)">
              <ic-extension pdf
                            class="ic ic--20 ic--gray" />
            </button>
            <template v-if="!isCard">
              <button v-if="item.csv"
                      v-tooltip="{ content: $t('tooltip.download_csv'), theme: 'tooltip' }"
                      class="btn-link"
                      @click.prevent.stop="downloadFileFromUrl(item.csv.url)">
                <ic-extension csv
                              class="ic ic--20 ic--gray" />
              </button>
              <button v-if="item.document"
                      v-tooltip="{ content: $t('tooltip.download_with_proofs'), theme: 'tooltip' }"
                      class="btn-link"
                      @click.prevent.stop="requestDownloadWithProofs(item)">
                <ic-download class="ic ic--24 ic--gray" />
              </button>
              <button v-tooltip="{ content: $t('tooltip.send_email'), theme: 'tooltip' }"
                      class="btn-link"
                      @click.prevent.stop="onSendEmail(item)">
                <ic-mail class="ic ic--24 ic--gray" />
              </button>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <component-placeholder v-else
                           :label="$t('placeholder.no_statement.title')"
                           :content="$t('placeholder.no_statement.subtitle')" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useStatementStore } from '@/stores/statement'

import ConfirmStatementDownload from '@/pages/statements/modal/ConfirmStatementDownload.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import SendEmail from '@/components/modals/SendEmail.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcDownload from '@/components/svg/icons/ic-download.vue'
import IcExtension from '@/components/svg/icons/ic-extension.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'

export default {
  components: {
    IcDownload,
    IcMail,
    IcExtension,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    isCard: {
      type: Boolean,
      default: false
    },

    loadingFilter: {
      type: Boolean,
      default: false
    },

    params: {
      type: Object,
      default: () => ({
        card_uuid: null,
        year: null
      })
    }
  },

  emits: ['selected-statements'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const statementStore = useStatementStore()

    const { account } = storeToRefs(accountStore)
    const { user } = storeToRefs(authStore)
    const { statements } = storeToRefs(statementStore)

    return { account, appStore, user, statements, statementStore }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    selectedStatements () {
      return this.statements.filter(statement => statement.selected)
    },

    areAllSelected: {
      get () { return this.selectedStatements.length === this.statements.length },
      set (value) { this.selectAll(value) }
    }
  },

  watch: {
    selectedStatements () {
      this.$emit('selected-statements', this.selectedStatements)
    },

    params () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    downloadFileFromUrl,

    formatAmount,

    formatDate,

    async initData () {
      this.loading = true
      this.statements = []
      if (this.params?.year) {
        this.isCard
          ? await this.statementStore.getStatements({ type: 'card', card_uuid: this.params.card_uuid, year: this.params.year })
          : await this.statementStore.getStatements({ year: this.params.year })
      }
      this.loading = false
    },

    async requestDownloadWithProofs (item) {
      const success = await this.statementStore.sendByEmail(item.id, this.user.email, 'all')
      if (success) {
        this.appStore.showModal(
          ConfirmStatementDownload,
          {},
          { wrapperClass: 'modal--xs modal-statement-confirm-download' }
        )
      }
    },

    toggleSelected (item) {
      item.selected = !item.selected
    },

    selectAll (value) {
      this.statements.forEach(statement => { statement.selected = value })
    },

    onSendEmail (item) {
      this.appStore.showModal(SendEmail, { callback: email => this.statementStore.sendByEmail(item.id, email) }, { wrapperClass: 'modal--xs' })
    }
  }
}
</script>
