<template>
  <section class="section section-accounting">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.accounting') }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div v-if="isExpenseFeatureEnabled"
           class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <form class="ml-1">
            <div class="form-group">
              <component-dropdown label="order_by"
                                  :dropdown-width="18"
                                  :values="orderValues"
                                  :model="filter.order_by"
                                  @select="onChangeOrderFilter">
                <template #trigger>
                  <div class="select">
                    <div class="form-control">
                      {{ orderValues.find(i => i.id === filter.order_by).label }}
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </form>
          <button class="btn btn--default"
                  @click="showExportPanel">
            <i class="btn-icon btn-icon--left">
              <ic-export class="ic ic--20 ic--white" />
            </i>
            <span class="btn__label">{{ $t("button.export") }}</span>
          </button>
          <button v-if="selectedExpenses.length"
                  class="btn btn--primary"
                  @click="requestAcceptAllExpenses">
            <div class="d-flex align-items-center">
              <span class="btn__label flex-1">{{ $t('button.validate') }} ({{ selectedExpenses.length }})</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange,
                         EFilter.Attachment,
                         EFilter.User,
                         EFilter.Team,
                         EFilter.Reliability,
                         EFilter.Amount,
                         EFilter.Affectation,
                         EFilter.ExpenseCategory,
                         EFilter.ChargeBack
                       ]"
                       :number-of-results="numberOfResults"
                       :total-amount="expensesTotalAmount"
                       @filters="filters" />
    <template v-if="isExpenseFeatureEnabled">
      <tabs :data="segments" />
      <router-view :filter="filter"
                   @selected-expenses="onSelectedExpenses"
                   @pagination-total-items="onPaginationTotalItems"
                   @pending-expenses-count="onPendingExpensesCount" />
    </template>
    <expenses-inactive v-else />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useExpenseStore } from '@/stores/expense'
import { EFilter } from '@/types/filter.d'

import OperationsExport from '@/pages/operations/sidepanel/OperationsExport.vue'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ComponentFilters from '@/components/Filter.vue'
import Confirm from '@/components/modals/Confirm.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import Tabs from '@/components/Tabs.vue'

import ExpensesInactive from './components/ExpensesInactive.vue'

export default {
  name: 'PageExpenses',

  components: {
    IcExport,
    ButtonFilters,
    ComponentFilters,
    Tabs,
    ExpensesInactive,
    ComponentDropdown
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const expenseStore = useExpenseStore()

    const { account } = storeToRefs(accountStore)
    const { expensesTotalAmount } = storeToRefs(expenseStore)

    return { account, appStore, expenseStore, expensesTotalAmount }
  },

  data () {
    return {
      numberOfPendingExpenses: null,
      selectedExpenses: [],
      numberOfResults: 0,
      filter: {
        order_by: 'date',
        search: null,
        from: null,
        to: null,
        attachment: null,
        expense_category: null,
        user_uuid: null,
        team_uuid: null,
        reliability: null,
        amount: null,
        charge_back: null,
        affectations: null,
        proof_filter: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      return [
        {
          label: this.$i18n.t('title.expense_control'),
          route: { name: 'expenses' },
          active: true
        },
        {
          label: this.$i18n.t('title.accounting_charts'),
          route: { name: 'accounting' },
          active: false
        }
      ]
    },

    segments () {
      return [
        {
          label: this.$i18n.t('general.pending'),
          route: { name: 'expenses' },
          badgeValue: this.numberOfPendingExpenses > 99 ? '99+' : this.numberOfPendingExpenses
        },
        {
          label: this.$i18n.tc('general.treated', 2),
          route: { name: 'expenses-done' }
        }
      ]
    },

    isExpenseFeatureEnabled () {
      return this.account.settings.expense_control
    },

    orderValues () {
      return [
        {
          label: this.$i18n.t('form.order_by.date'),
          id: 'date'
        },
        {
          label: this.$i18n.t('form.order_by.user'),
          id: 'users'
        },
        {
          label: this.$i18n.t('form.order_by.reliability'),
          id: 'reliability'
        },
        {
          label: this.$i18n.t('form.order_by.amount'),
          id: 'amount'
        }
      ]
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('download-export-success', this.appStore.closeSidePanel)
    this.$bus.on('account-switched', this.resetData)
    this.$bus.on('language-changed', this.resetData)
  },

  beforeUnmount () {
    this.$bus.off('download-export-success', this.appStore.closeSidePanel)
    this.$bus.off('account-switched', this.resetData)
    this.$bus.off('language-changed', this.resetData)
  },

  methods: {
    initData () {
      if (!this.isExpenseFeatureEnabled) return

      this.numberOfPendingExpenses = null
      this.selectedExpenses = []
      this.numberOfResults = 0
    },

    resetData () {
      this.initData()
      this.setDefaultFilter()
    },

    setDefaultFilter () {
      this.filter = {
        order_by: 'date',
        search: null,
        from: null,
        to: null,
        attachment: null,
        expense_category: null,
        user_uuid: null,
        team_uuid: null,
        reliability: null,
        amount: null,
        charge_back: null,
        affectations: null,
        proof_filter: null
      }
    },

    onSelectedExpenses (expenses) {
      this.selectedExpenses = expenses
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    requestAcceptAllExpenses () {
      const number = this.selectedExpenses.length
      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.tc('prompt.expenses.validate_multiple.title', number, { number }),
          content: this.$i18n.tc('prompt.expenses.validate_multiple.content', number, { number }),
          event: 'accept-all-expenses',
          preventClose: true
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    onPendingExpensesCount (pendingExpensesCount) {
      this.numberOfPendingExpenses = pendingExpensesCount
    },

    onChangeOrderFilter (item) {
      this.filter.order_by = item.id
    },

    filters (filters) {
      this.filter = {
        order_by: this.filter.order_by,
        search: filters.search,
        from: filters.from,
        to: filters.to,
        attachment: filters.attachment,
        expense_category: filters.expense_category,
        user_uuid: filters.user_uuid,
        team_uuid: filters.team_uuid,
        reliability: filters.reliability,
        charge_back: filters.charge_back,
        amount: filters.amount,
        affectations: filters.affectation,
        proof_filter: filters.proof_filter
      }
    },

    showExportPanel () {
      const ignoreFilters = ['status', 'order_by', 'page']

      this.appStore.showSidePanel(
        OperationsExport,
        {
          type: 'expenses',
          filter: this.filter,
          isFiltered: Object.keys(this.filter).some(i => this.filter[i] && !ignoreFilters.includes(i)),
          persistingFilters: { status: this.filter.status }
        },
        { wrapperClass: 'sidepanel-export sidepanel--md', backgroundOverlay: true }
      )
    }
  }

}
</script>
