<template>
  <div class="modal__content">
    <modal-header :title="label"
                  close-button
                  @close="$emit('close')" />
    <div class="modal__body">
      <dropzone :url="dropzoneOptions.url"
                :accepted-files="dropzoneOptions.acceptedFiles"
                :max-filesize="dropzoneOptions.maxFilesize"
                :max-files="dropzoneOptions.maxFiles"
                mode="modal"
                @has-error="value => dropzoneHasError = !!value"
                @on-file-added="onDocumentUploaded" />
    </div>
    <div class="modal__footer">
      <div class="modal__footer__left">
        <template v-if="canAnalyzeFile">
          <toggle-switch v-model="analyzeFile"
                         :toggle-class="'switch-btn--sm'" />
          <span class="switch-btn-label">{{ $t('button.analyze_document') }}</span>
          <span v-tooltip="{ content: poptipMessage, theme: 'poptip' }"
                class="ic-helper">
            <ic-info outline
                     class="ic ic-info-outline ic--gray" />
          </span>
        </template>
      </div>
      <div class="modal__footer__right">
        <button v-if="!documentSent"
                type="button"
                class="btn btn--gray"
                @click.prevent="$emit('close')">
          {{ $t('button.cancel') }}
        </button>
        <component-button v-if="isSubmitButtonVisible"
                          :label="$t('button.validate')"
                          data-cy="join-document.button"
                          wrapper-class="btn--primary"
                          @click="attachDocuments" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'
import { useDocumentStore } from '@/stores/document'
import { useOperationStore } from '@/stores/operation'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import ComponentButton from '@/components/Button.vue'
import Dropzone from '@/components/Dropzone.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

import DocumentAnalyze from './DocumentAnalyze.vue'

export default {
  components: {
    ModalHeader,
    IcInfo,
    ComponentButton,
    Dropzone,
    ToggleSwitch
  },

  props: {
    label: {
      type: String,
      required: true
    },

    model: {
      type: Object,
      required: true
    },

    options: {
      type: Object,
      required: true
    },

    dropzoneOptions: {
      type: Object,
      required: true
    },

    callback: {
      type: Function,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const documentStore = useDocumentStore()
    const operationStore = useOperationStore()

    return {
      appStore,
      documentStore,
      operationStore
    }
  },

  data () {
    return {
      documentSent: false,
      analyzeFile: false,
      dropzoneHasError: false,
      files: []
    }
  },

  computed: {
    isSubmitButtonVisible () {
      return this.files.length && !this.dropzoneHasError
    },

    canAnalyzeFile () {
      return this.options.canAnalyzeFile && this.files.length < 2
    },

    poptipMessage () {
      if (this.options.type) {
        return this.$i18n.t(`poptip.analyze_${this.options.type}_file`)
      }

      return this.$i18n.t('prompt.analyze.title')
    }
  },

  mounted () {
    this.$bus.on('on-upload-success', this.onUploadSuccess)
  },

  beforeUnmount () {
    this.$bus.off('on-upload-success', this.onUploadSuccess)
  },

  methods: {
    onUploadSuccess (response) {
      if (Object.prototype.hasOwnProperty.call(this.model, 'documents')) {
        if (this.options.editingFileId) {
          const documentToEdit = this.model.documents.find(document => document.id === this.options.editingFileId)
          const index = this.model.documents.indexOf(documentToEdit)
          this.callback(response, index)
        } else {
          this.callback(response.documents, 'documents')
        }
      } else {
        this.callback(response, 'simple')
      }

      this.documentSent = true

      if (this.analyzeFile) {
        this.handleOperationAnalyzeDocument()
      }

      this.$bus.emit('on-file-attached', { files: this.files, isAnalyzing: this.analyzeFile })
      this.$emit('close')
      this.documentStore.setDropzoneQueue(false)
    },

    handleOperationAnalyzeDocument () {
      this.appStore.showSidePanel(
        OperationDetail,
        { model: this.model },
        { wrapperClass: 'sidepanel--md', backgroundOverlay: true, initShowFile: true }
      )
      const operationToAnalyze = {
        operationUuid: this.model.uuid,
        documentId: this.model.documents[this.model.documents.length - 1].id
      }
      this.operationStore.analyzeOperationDocument(operationToAnalyze)
    },

    onDocumentUploaded (files) {
      this.files = files
    },

    async attachDocuments () {
      if (this.dropzoneOptions.url) {
        this.documentStore.setDropzoneQueue(true)
        return
      }

      this.$bus.emit('on-file-attached', { files: this.files, isAnalyzing: this.analyzeFile })

      if (this.analyzeFile) {
        this.appStore.showModal(
          DocumentAnalyze,
          {
            title: this.$i18n.t('prompt.transfer.document_analyze.title'),
            content: this.$i18n.t('prompt.transfer.document_analyze.content'),
            type: this.options.type,
            fileToAnalyze: this.files[0]
          },
          { wrapperClass: 'modal-document-analyze' }
        )
      }
    }
  }
}
</script>
