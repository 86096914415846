<template>
  <div class="datepicker"
       :class="wrapperClass"
       :data-cy="`datepicker.${dataCy}`">
    <label v-if="label"
           class="label">{{ label }}</label>
    <validation-field :model-value="modelValue"
                      :name="name">
      <div class="input-group"
           :class="{ 'is-clearable': clearButton && modelValue }">
        <label v-if="preAddon"
               class="input-group__addon input-group__addon--gray input-group__addon--noborder"
               :class="sizeClass ? `input-group__addon--${sizeClass}` : ''"
               :for="id">{{ preAddon }}</label>
        <input :id="id"
               ref="date"
               type="text"
               :class="[inputClass, sizeClass ? `form-control--${sizeClass}` : '']"
               :disabled="disableInput"
               :value="formattedValue"
               :placeholder="placeholder"
               :clear-button="clearButton"
               :readonly="readonly"
               @focus="showCalendar()"
               @keyup="onKeyUp"
               @keydown="onKeyDown">
        <label v-if="iconCalendar || iconInfo"
               class="input-group__addon"
               :for="id"
               :class="sizeClass ? `input-group__addon--${sizeClass}` : ''">
          <VTooltip theme="poptip"
                    :disabled="!poptipContent">
            <template #popper>
              <poptip :title="poptipTitle"
                      :message="poptipContent" />
            </template>
            <ic-calendar v-if="iconCalendar"
                         class="ic ic--18 ic--addon" />
            <ic-info v-else-if="iconInfo"
                     outline
                     class="ic ic--addon ic--16" />
          </VTooltip>
        </label>
        <button v-if="clearButton && modelValue"
                type="button"
                class="form-control-clear-btn"
                @click="clearDate()">
          <ic-remove class="ic ic--16 ic--gray" />
        </button>
      </div>
    </validation-field>
    <!-- Day View -->
    <div v-show="showDayView"
         class="calendar"
         :style="calendarStyle"
         :data-cy="`datepicker.${dataCy}.day`">
      <header>
        <span class="prev"
              :class="{ disabled: previousMonthDisabled(currDate) }"
              @click="previousMonth">&lt;</span>
        <span class="up"
              @click="showMonthCalendar">{{ currMonthName }} {{ currYear }}</span>
        <span class="next"
              :class="{ disabled: nextMonthDisabled(currDate) }"
              @click="nextMonth">&gt;</span>
      </header>
      <span v-for="d in daysOfWeek"
            :key="d"
            class="cell day-header">{{ d }}</span>
      <span v-for="d in blankDays"
            :key="d"
            class="cell day blank" />
      <span v-for="(day, i) in days"
            :key="day.timestamp"
            class="cell day"
            track-by="timestamp"
            :class="{ selected: day.isSelected, disabled: day.isDisabled, highlighted: day.isHighlighted }"
            :data-cy="`datepicker.${dataCy}.day.item-${i}`"
            @click="selectDate(day)">{{ day.date }}</span>
    </div>

    <!-- Month View -->
    <div v-show="showMonthView"
         class="calendar"
         :style="calendarStyleSecondary"
         :data-cy="`datepicker.${dataCy}.month`">
      <header>
        <span class="prev"
              :class="{ disabled: previousYearDisabled(currDate) }"
              @click="previousYear">&lt;</span>
        <span class="up"
              @click="showYearCalendar">{{ getYear() }}</span>
        <span class="next"
              :class="{ disabled: nextYearDisabled(currDate) }"
              @click="nextYear">&gt;</span>
      </header>
      <span v-for="(month, i) in months"
            :key="month.timestamp"
            class="cell month"
            track-by="timestamp"
            :class="{ selected: month.isSelected, disabled: month.isDisabled }"
            :data-cy="`datepicker.${dataCy}.month.item-${i}`"
            @click.stop="selectMonth(month)">{{ month.month }}</span>
    </div>

    <!-- Year View -->
    <div v-show="showYearView"
         class="calendar"
         :style="calendarStyleSecondary">
      <header>
        <span class="prev"
              :class="{ disabled: previousDecadeDisabled(currDate) }"
              @click="previousDecade">&lt;</span>
        <span>{{ getDecade() }}</span>
        <span class="next"
              :class="{ disabled: nextMonthDisabled(currDate) }"
              @click="nextDecade">&gt;</span>
      </header>
      <span v-for="year in years"
            :key="year.timestamp"
            class="cell year"
            track-by="timestamp"
            :class="{ selected: year.isSelected, disabled: year.isDisabled }"
            @click.stop="selectYear(year)">{{ year.year }}</span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import Poptip from '@/components/Poptip.vue'
import IcCalendar from '@/components/svg/icons/ic-calendar.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

import DateLanguages from './utils/DateLanguages'
import DateUtils from './utils/DateUtils'

dayjs.extend(customParseFormat)

export default {
  name: 'ComponentDatepicker',

  components: {
    IcRemove,
    IcInfo,
    IcCalendar,
    Poptip
  },

  props: {
    dataCy: {
      type: String,
      default: 'default'
    },

    modelValue: {
      type: [Date, String],
      default: null
    },

    name: {
      type: String,
      default: null
    },

    disableInput: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    format: {
      type: String,
      default: 'dd MMM yyyy'
    },

    language: {
      type: String,
      default: 'en'
    },

    disabledDays: {
      type: Object,
      default: null
    },

    highlighted: {
      type: Object,
      default: null
    },

    placeholder: {
      type: String,
      default: 'JJ/MM/AAAA'
    },

    inline: {
      type: Boolean,
      default: false
    },

    inputClass: {
      type: String,
      default: null
    },

    wrapperClass: {
      type: [Object, String],
      default: null
    },

    iconCalendar: Boolean,
    iconInfo: Boolean,
    sizeClass: {
      type: String,
      default: null
    },

    poptipTitle: {
      type: String,
      default: null
    },

    poptipContent: {
      type: String,
      default: null
    },

    mondayFirst: {
      type: Boolean,
      default: false
    },

    clearButton: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: true
    },

    monthPicker: {
      type: Boolean,
      default: false
    },

    preAddon: {
      type: String,
      default: null
    }

  },

  emits: [
    'changed-decade',
    'changed-month',
    'changed-year',
    'cleared',
    'opened',
    'selected',
    'update:modelValue'
  ],

  data () {
    return {
      currDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
      selectedDate: null,
      showDayView: false,
      showMonthView: false,
      showYearView: false,
      calendarHeight: 0,
      intervalTimer: null
    }
  },

  computed: {
    formattedValue () {
      return this.selectedDate
        ? DateUtils.formatDate(new Date(this.selectedDate), this.format, this.translation)
        : null
    },

    translation () {
      return DateLanguages.translations[this.language]
    },

    currMonthName () {
      const d = new Date(this.currDate)

      return DateUtils.getMonthName(d.getMonth(), this.translation.months.original)
    },

    currYear () {
      const d = new Date(this.currDate)

      return d.getFullYear()
    },

    blankDays () {
      const d = new Date(this.currDate)
      const dObj = new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes())
      if (this.mondayFirst) {
        return dObj.getDay() > 0 ? dObj.getDay() - 1 : 6
      }

      return dObj.getDay()
    },

    daysOfWeek () {
      if (this.mondayFirst) {
        const tempDays = this.translation.days.slice()
        tempDays.push(tempDays.shift())
        return tempDays
      }
      return this.translation.days
    },

    days () {
      const d = new Date(this.currDate)
      const days = []
      // set up a new date object to the beginning of the current 'page'
      const dObj = new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes())
      const daysInMonth = DateUtils.daysInMonth(dObj.getFullYear(), dObj.getMonth())
      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          date: dObj.getDate(),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedDate(dObj),
          isDisabled: this.isDisabledDate(dObj),
          isHighlighted: this.isHighlightedDate(dObj)
        })
        dObj.setDate(dObj.getDate() + 1)
      }
      return days
    },

    months () {
      const d = new Date(this.currDate)
      const months = []
      // set up a new date object to the beginning of the current 'page'
      const dObj = new Date(d.getFullYear(), 0, 1, 0, 0)
      for (let i = 0; i < 12; i++) {
        months.push({
          month: DateUtils.getMonthName(i, this.translation.months.original),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedMonth(dObj),
          isDisabled: this.isDisabledMonth(dObj)
        })
        dObj.setMonth(dObj.getMonth() + 1)
      }
      return months
    },

    years () {
      const d = new Date(this.currDate)
      const years = []
      // set up a new date object to the beginning of the current 'page'
      const dObj = new Date(Math.floor(d.getFullYear() / 10) * 10, d.getMonth(), d.getDate(), d.getHours(), d.getMinutes())
      for (let i = 0; i < 10; i++) {
        years.push({
          year: dObj.getFullYear(),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedYear(dObj),
          isDisabled: this.isDisabledYear(dObj)
        })
        dObj.setFullYear(dObj.getFullYear() + 1)
      }
      return years
    },

    calendarStyle () {
      let elSize = {
        top: 0,
        height: 0
      }
      if (this.$el) {
        elSize = this.$el.getBoundingClientRect()
      }
      const heightNeeded = elSize.top + elSize.height + this.calendarHeight || 0
      let styles = {}
      // if the calendar doesn't fit on the window without scrolling position it above the input
      if (heightNeeded > window.innerHeight) {
        styles = {
          bottom: `${elSize.height}px`
        }
      }
      if (this.isInline()) {
        styles.position = 'static'
      }

      return styles
    },

    calendarStyleSecondary () {
      return (this.isInline()) ? { position: 'static' } : {}
    }
  },

  watch: {
    modelValue (value) {
      this.setValue(value)
    },

    disabledDays () {
      this.findEnabledDate()
    }
  },

  mounted () {
    this.findEnabledDate()
    if (this.modelValue) {
      this.setValue(this.modelValue)
    }
    if (this.isInline()) {
      this.showDayCalendar()
    }

    this.$nextTick(() => {
      const calendarElement = this.$el.querySelector('.calendar')
      if (!calendarElement) return
      this.calendarHeight = calendarElement.getBoundingClientRect().height
    })

    this.$bus.on('focus-field', this.focusField)
    this.$bus.on('blur-field', this.blurField)

    this.intervalTimer = setInterval(this.toggleDate, 10)

    document.addEventListener('click', this.onDocumentClick, false)
  },

  beforeUnmount () {
    this.$bus.off('focus-field')
    this.$bus.off('blur-field')

    clearInterval(this.intervalTimer)
    document.removeEventListener('click', this.onDocumentClick)
  },

  methods: {
    close () {
      this.showDayView = this.showMonthView = this.showYearView = false
    },

    isOpen () {
      return this.showDayView || this.showMonthView || this.showYearView
    },

    isInline () {
      return this.inline === true
    },

    showCalendar () {
      if (this.isInline()) {
        return false
      }
      if (this.isOpen()) {
        return this.close()
      }
      if (this.monthPicker) {
        this.showMonthCalendar()
      } else {
        this.showDayCalendar()
      }
    },

    onKeyUp (key) {
      if (this.$refs.date) {
        if (key !== undefined && key.code !== 'Backspace') {
          const str = this.autoCompleteDate(this.$refs.date.value).slice(0, 10)

          if (str !== this.$refs.date.value) {
            this.$refs.date.value = str
          }
        }
        const format = this.monthPicker ? 'MM/YYYY' : 'DD/MM/YYYY'
        const date = dayjs(this.$refs.date.value, format)

        if (!isNaN(date.valueOf()) && (this.$refs.date.value.length === 10 || (this.monthPicker && this.$refs.date.value.length === 7))) {
          this.setDate(date.valueOf())
        }
      }
    },

    autoCompleteDate (str) {
      const looksLike_MM_slash_DD = /^(\d\d\/)?\d\d$/
      const looksLike_MM_slash_D_slash = /^(\d\d\/)?(\d\/)$/
      const looksLike_MM = /^(\d\d)$/

      if (!this.monthPicker) {
        if (looksLike_MM_slash_DD.test(str)) { str += '/' } else if (looksLike_MM_slash_D_slash.test(str)) { str = str.replace(looksLike_MM_slash_D_slash, '$10$2') }
      } else {
        if (looksLike_MM.test(str)) { str += '/' }
      }

      return str
    },

    onKeyDown (event) {
      if (event.key === 'Enter') {
        this.close()
        this.$bus.emit('focus-next-field', this.id)
      } else if (event.key === 'Tab') {
        this.close()
      }
    },

    focusField (id) {
      if (id === this.id && this.$refs.date) {
        this.$refs.date.focus()
        this.showDayView = !this.monthPicker
      }
    },

    blurField (id) {
      if (id === this.id && this.$refs.date) {
        this.$refs.date.blur()
      }
    },

    showDayCalendar () {
      this.close()
      this.showDayView = true
      this.$emit('opened')
    },

    showMonthCalendar () {
      this.close()
      this.showMonthView = true
    },

    showYearCalendar () {
      this.close()
      this.showYearView = true
    },

    setDate (timestamp) {
      this.selectedDate = new Date(timestamp)
      this.currDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1).getTime()
      this.$emit('selected', new Date(timestamp))
      this.$emit('update:modelValue', new Date(timestamp))
      this.close()
      this.$bus.emit('focus-next-field', this.id)
    },

    clearDate () {
      this.selectedDate = null
      this.$emit('selected', this.selectedDate)
      this.$emit('update:modelValue', this.selectedDate)
      this.$emit('cleared')
    },

    selectDate (day) {
      if (day.isDisabled) {
        return false
      }
      this.setDate(day.timestamp)
      if (this.isInline()) {
        return this.showDayCalendar()
      }
      this.close()
    },

    selectMonth (month) {
      if (month.isDisabled) {
        return false
      }
      this.currDate = month.timestamp
      if (!this.monthPicker) {
        this.showDayCalendar()
      } else {
        this.setDate(month.timestamp)
      }
    },

    selectYear (year) {
      if (year.isDisabled) {
        return false
      }
      this.currDate = year.timestamp
      this.showMonthCalendar()
    },

    getMonth () {
      const d = new Date(this.currDate)
      return d.getMonth()
    },

    getYear () {
      const d = new Date(this.currDate)
      return d.getFullYear()
    },

    getDecade () {
      const d = new Date(this.currDate)
      const sD = Math.floor(d.getFullYear() / 10) * 10
      return `${sD}'s`
    },

    previousMonth () {
      if (this.previousMonthDisabled()) {
        return false
      }
      const d = new Date(this.currDate)
      d.setMonth(d.getMonth() - 1)
      this.currDate = d.getTime()
      this.$emit('changed-month', d)
    },

    previousMonthDisabled () {
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || typeof this.disabledDays.to === 'undefined' || !this.disabledDays.to) {
        return false
      }
      const d = new Date(this.currDate)

      return this.disabledDays.to.getMonth() >= d.getMonth() &&
        this.disabledDays.to.getFullYear() >= d.getFullYear()
    },

    nextMonth () {
      if (this.nextMonthDisabled()) {
        return false
      }
      const d = new Date(this.currDate)
      const daysInMonth = DateUtils.daysInMonth(d.getFullYear(), d.getMonth())
      d.setDate(d.getDate() + daysInMonth)
      this.currDate = d.getTime()
      this.$emit('changed-month', d)
    },

    nextMonthDisabled () {
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || typeof this.disabledDays.from === 'undefined' || !this.disabledDays.from) {
        return false
      }
      const d = new Date(this.currDate)

      return this.disabledDays.from.getMonth() <= d.getMonth() &&
        this.disabledDays.from.getFullYear() <= d.getFullYear()
    },

    previousYear () {
      if (this.previousYearDisabled()) {
        return false
      }
      const d = new Date(this.currDate)
      d.setYear(d.getFullYear() - 1)
      this.currDate = d.getTime()
      this.$emit('changed-year')
    },

    previousYearDisabled () {
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || typeof this.disabledDays.to === 'undefined' || !this.disabledDays.to) {
        return false
      }
      const d = new Date(this.currDate)

      return this.disabledDays.to.getFullYear() >= d.getFullYear()
    },

    nextYear () {
      if (this.nextYearDisabled()) {
        return false
      }
      const d = new Date(this.currDate)
      d.setYear(d.getFullYear() + 1)
      this.currDate = d.getTime()
      this.$emit('changed-year')
    },

    nextYearDisabled () {
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || typeof this.disabledDays.from === 'undefined' || !this.disabledDays.from) {
        return false
      }
      const d = new Date(this.currDate)

      return this.disabledDays.from.getFullYear() <= d.getFullYear()
    },

    previousDecade () {
      if (this.previousDecadeDisabled()) {
        return false
      }
      const d = new Date(this.currDate)
      d.setYear(d.getFullYear() - 10)
      this.currDate = d.getTime()
      this.$emit('changed-decade')
    },

    previousDecadeDisabled () {
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || typeof this.disabledDays.to === 'undefined' || !this.disabledDays.to) {
        return false
      }
      const d = new Date(this.currDate)

      return Math.floor(this.disabledDays.to.getFullYear() / 10) * 10 >= Math.floor(d.getFullYear() / 10) * 10
    },

    nextDecade () {
      if (this.nextDecadeDisabled()) {
        return false
      }
      const d = new Date(this.currDate)
      d.setYear(d.getFullYear() + 10)
      this.currDate = d.getTime()
      this.$emit('changed-decade')
    },

    nextDecadeDisabled () {
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || typeof this.disabledDays.from === 'undefined' || !this.disabledDays.from) {
        return false
      }
      const d = new Date(this.currDate)

      return Math.ceil(this.disabledDays.from.getFullYear() / 10) * 10 <= Math.ceil(d.getFullYear() / 10) * 10
    },

    isSelectedDate (dObj) {
      return this.selectedDate && this.selectedDate.toDateString() === dObj.toDateString()
    },

    isDisabledDate (date) {
      let disabledDays = false

      if (this.disabledDays === null || typeof this.disabledDays === 'undefined') {
        return false
      }

      if (typeof this.disabledDays.dates !== 'undefined') {
        this.disabledDays.dates.forEach(d => {
          if (date.toDateString() === d.toDateString()) {
            disabledDays = true
            return true
          }
        })
      }
      if (typeof this.disabledDays.to !== 'undefined' && this.disabledDays.to && date < this.disabledDays.to) {
        disabledDays = true
      }
      if (typeof this.disabledDays.from !== 'undefined' && this.disabledDays.from && date > this.disabledDays.from) {
        disabledDays = true
      }
      if (typeof this.disabledDays.days !== 'undefined' && this.disabledDays.days.indexOf(date.getDay()) !== -1) {
        disabledDays = true
      }
      return disabledDays
    },

    isHighlightedDate (date) {
      if (this.isDisabledDate(date)) {
        return false
      }

      let highlighted = false

      if (this.highlighted === null || typeof this.highlighted === 'undefined') {
        return false
      }

      if (typeof this.highlighted.dates !== 'undefined') {
        this.highlighted.dates.forEach(d => {
          if (date.toDateString() === d.toDateString()) {
            highlighted = true
            return true
          }
        })
      }

      if (this.isDefined(this.highlighted.from) && this.isDefined(this.highlighted.to)) {
        highlighted = date >= this.highlighted.from && date <= this.highlighted.to
      }

      if (typeof this.highlighted.days !== 'undefined' && this.highlighted.days.indexOf(date.getDay()) !== -1) {
        highlighted = true
      }
      return highlighted
    },

    isDefined (prop) {
      return typeof prop !== 'undefined' && prop
    },

    isSelectedMonth (date) {
      return (this.selectedDate &&
          this.selectedDate.getFullYear() === date.getFullYear() &&
          this.selectedDate.getMonth() === date.getMonth())
    },

    isDisabledMonth (date) {
      let disabledDays = false

      if (this.disabledDays === null || typeof this.disabledDays === 'undefined') {
        return false
      }

      if (typeof this.disabledDays.to !== 'undefined' && this.disabledDays.to) {
        if (
          (date.getMonth() < this.disabledDays.to.getMonth() && date.getFullYear() <= this.disabledDays.to.getFullYear()) ||
            date.getFullYear() < this.disabledDays.to.getFullYear()
        ) {
          disabledDays = true
        }
      }
      if (typeof this.disabledDays.from !== 'undefined' && this.disabledDays.from) {
        if (
          this.disabledDays.from &&
          ((date.getMonth() > this.disabledDays.from.getMonth() && date.getFullYear() >= this.disabledDays.from.getFullYear()) ||
          date.getFullYear() > this.disabledDays.from.getFullYear())
        ) {
          disabledDays = true
        }
      }
      return disabledDays
    },

    isSelectedYear (date) {
      return this.selectedDate && this.selectedDate.getFullYear() === date.getFullYear()
    },

    isDisabledYear (date) {
      let disabledDays = false
      if (this.disabledDays === null || typeof this.disabledDays === 'undefined' || !this.disabledDays) {
        return false
      }

      if (typeof this.disabledDays.to !== 'undefined' && this.disabledDays.to) {
        if (date.getFullYear() < this.disabledDays.to.getFullYear()) {
          disabledDays = true
        }
      }
      if (typeof this.disabledDays.from !== 'undefined' && this.disabledDays.from) {
        if (date.getFullYear() > this.disabledDays.from.getFullYear()) {
          disabledDays = true
        }
      }

      return disabledDays
    },

    setValue (date) {
      if (typeof date === 'string') {
        const parsed = new Date(date)
        date = isNaN(parsed.valueOf()) ? null : parsed
      }
      if (!date) {
        this.findEnabledDate()
        this.selectedDate = null
        this.$emit('update:modelValue', this.selectedDate)
        return
      }
      this.selectedDate = date
      this.currDate = new Date(date.getFullYear(), date.getMonth(), 1).getTime()
      this.$emit('update:modelValue', this.selectedDate)
    },

    onDocumentClick (e) {
      if (this.$el && !this.$el.contains(e.target)) {
        if (this.isInline()) {
          return this.showDayCalendar()
        }

        if (this.$refs.date !== document.activeElement) {
          this.close()
        }
      }
    },

    findEnabledDate () {
      const from = this.disabledDays?.from
      const to = this.disabledDays?.to
      const unit = this.showMonthView || this.showYearView ? 'month' : 'day'
      if (from && to &&
        (dayjs(to).isAfter(from) || dayjs(this.currDate).isBetween(to, from))) {
        return
      }
      if (from && dayjs(this.currDate).isAfter(from)) {
        this.currDate = dayjs(from).subtract(1, unit).valueOf()
      }
      if (to && dayjs(this.currDate).isBefore(to)) {
        this.currDate = dayjs(to).add(1, unit).valueOf()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.is-clearable
  &:hover
    input + .input-group__addon
      display none
</style>
