<template>
  <section class="section section--empty">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'transfers' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.new_transfer') }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
    </div>
    <div class="section__content">
      <div class="block-options">
        <div v-if="hasFeature(EFeature.TransferCreationMultipleSepa)"
             class="block-options__item"
             @click.prevent.stop="onAttachFiles('sepa')">
          <file-sheet class="icon" />
          <div class="option-text">
            <h4>{{ $t('placeholder.multiple_transfers.sepa.title') }}</h4>
            <p class="pre-line">
              {{ $t('placeholder.multiple_transfers.sepa.subtitle') }}
            </p>
          </div>
        </div>
        <div v-if="hasFeature(EFeature.TransferCreationMultiplePayslips)"
             class="block-options__item"
             @click.prevent.stop="onAttachFiles('payslips')">
          <file-payslips class="icon" />
          <div class="option-text">
            <h4>{{ $t('placeholder.multiple_transfers.payslips.title') }}</h4>
            <p class="pre-line">
              {{ $t('placeholder.multiple_transfers.payslips.subtitle') }}
            </p>
          </div>
        </div>
        <router-link v-if="hasFeature(EFeature.TransferCreationMultipleAdvancedPayments)"
                     :to="{ name: 'advance-payments' }"
                     data-cy="advance-payments.block"
                     class="block-options__item">
          <image-wallet class="icon" />
          <div class="option-text">
            <h4>{{ $t('advance_payment.title') }}</h4>
            <p>{{ $t('advance_payment.placeholder.subtitle') }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'
import { useAppStore } from '@/stores/app'

import ImportDocuments from '@/components/modals/ImportDocuments.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import FilePayslips from '@/components/svg/img/file-payslips.vue'
import FileSheet from '@/components/svg/img/file-sheet.vue'
import ImageWallet from '@/components/svg/img/wallet.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    IcArrow,
    ImageWallet,
    FileSheet,
    FilePayslips,
    Tabs
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.single'),
          route: { name: 'transfers-create' },
          active: false
        },
        {
          label: this.$i18n.t('button.multiple'),
          route: { name: 'transfers-create-multiple' },
          active: true
        }
      ]
    }
  },

  mounted () {
    if (!hasFeature(EFeature.TransferCreationMultiple)) {
      this.$router.push({ name: 'transfers-create' })
    }
  },

  methods: {
    hasFeature,

    onAttachFiles (type) {
      this.appStore.showModal(ImportDocuments, { type }, { wrapperClass: 'modal--md' })
    }
  }
}
</script>
