<template>
  <div class="chart bordered-box mb-2">
    <div class="chart__header">
      <div class="header__left">
        <h3 class="header__left--title">
          {{ title }}
        </h3>
        <slot name="filter" />
      </div>

      <div class="header__right">
        <slot name="header" />
      </div>
    </div>

    <slot name="resume" />

    <div class="chart__body">
      <canvas :id="`chart-${uuid}`"
              height="400" />
      <transition name="fade">
        <components-chart-nodata v-if="noData && !firstInit" />
      </transition>
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { Chart, registerables } from 'chart.js'
import dayjs from 'dayjs'
import { uniqueId } from 'lodash/util'

import config from '@/config/config'
import { chartFormatNumber } from '@/helpers/utils/number'
import { capitalizeFirstLetter } from '@/helpers/utils/text'

import ComponentsChartNodata from '@/components/charts/ChartNodata.vue'
import hoverSegment from '@/components/charts/utils/plugin-hover-segment'
import pluginTooltip from '@/components/charts/utils/plugin-tooltip'

import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'

Chart.register(...registerables, hoverSegment)

export default {
  name: 'BarChar',
  components: { ComponentsChartNodata },
  props: {
    datasets: {
      required: true,
      type: Array
    },

    dateFormat: {
      type: String,
      default: 'MMM YYY'
    },

    title: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      chart: null,
      defaultOptions: {
        hover: {
          intersect: false,
          mode: 'nearest'
        },

        interaction: {
          axis: 'x',
          intersect: false,
          mode: 'nearest'
        },

        maintainAspectRatio: false,
        responsive: true
      },

      firstInit: true,
      uuid: uniqueId()
    }
  },

  computed: {
    chartX () {
      return {
        align: 'inner',
        border: {
          color: this.colors.border
        },

        font: {
          size: 11,
          weight: 500
        },

        grid: {
          display: true,
          drawOnChartArea: false,
          offset: false,
          tickColor: this.colors.border
        },

        hideOtherPoints: true,
        showEndX: true,
        showStartX: true,
        ticks: {
          align: 'center',

          callback: value => {
            return capitalizeFirstLetter(dayjs(this.datasetsFormatted[0]?.data[value]?.x).format(this.dateFormat))
          },

          maxRotation: 0,
          precision: 0,
          source: 'data'
        }
      }
    },

    chartY () {
      return {
        beginAtZero: true,
        border: {
          display: false
        },

        bounds: 'ticks',
        grid: {
          color: this.colors.border
        },

        ticks: {
          align: 'inner',
          autoSkip: false,
          callback: value => {
            if (this.noData) return chartFormatNumber(value * 100000)
            return chartFormatNumber(value)
          },

          font: {
            size: 11,
            weight: 400
          },

          maxRotation: 0,
          maxTicksLimit: 6,
          padding: 10,
          source: 'data'
        },

        type: 'linear'
      }
    },

    colors () {
      return {
        border: config.SVG.COLOR.BORDER_COLOR
      }
    },

    datasetsFormatted () {
      return this.datasets.map(data => {
        return {
          ...{
            barThickness: 18,
            borderColor: 'rgba(0,0,0,0)',
            borderRadius: 6,
            borderWidth: 3,
            defaultBackgroundHoverColor: [...data.hoverBackgroundColor],
            defaultColor: [...data.backgroundColor],
            fill: true,
            grouped: true
          },
          ...data
        }
      })
    },

    noData () {
      return this.datasets.every(item => item.data.length === 0)
    }
  },

  watch: {
    datasets: {
      handler () {
        this.chart.data = { datasets: this.datasetsFormatted }
        this.chart.update()

        this.firstInit = false
      },

      deep: true
    }
  },

  mounted () {
    this.chart = markRaw(new Chart(`chart-${this.uuid}`, {
      data: { datasets: this.datasetsFormatted },

      options: {
        ...this.defaultOptions,

        onHover: event => {
          const element = this.chart.getElementsAtEventForMode(event, 'index', { intersect: false })
          const barIndex = element?.length ? element[0].index : false

          this.chart.data.datasets.forEach((data, dataIndex) => {
            this.chart.data.datasets[dataIndex].data.forEach((set, index) => {
              if (barIndex === index) {
                this.chart.data.datasets[dataIndex].backgroundColor[index] = this.chart.data.datasets[dataIndex].defaultBackgroundHoverColor[index]
                this.chart.data.datasets[dataIndex].hoverBackgroundColor[index] = this.chart.data.datasets[dataIndex].defaultBackgroundHoverColor[index]
              } else {
                this.chart.data.datasets[dataIndex].backgroundColor[index] = this.chart.data.datasets[dataIndex].defaultColor[index]
                this.chart.data.datasets[dataIndex].hoverBackgroundColor[index] = this.chart.data.datasets[dataIndex].defaultColor[index]
              }
            })
          })

          this.chart.update()
        },

        plugins: {
          hoverSegment: true,
          legend: { display: false },
          tooltip: { enabled: false, external: pluginTooltip, intersect: false, mode: 'index' }
        },

        scales: {
          x: this.chartX,
          y: this.chartY
        }
      },

      type: 'bar'
    }))
  }
}
</script>
