<template>
  <div class="dashboard__block dashboard__block--row">
    <dashboard-pending-transfers v-if="isTransferVisible"
                                 :dashboard="dashboard"
                                 color="mid"
                                 :loading="loading" />
    <dashboard-inbox v-else-if="isInboxVisible"
                     color="mid"
                     :dashboard="dashboard"
                     :loading="loading" />
    <div>
      <dashboard-account :small="isInboxVisible && isTransferVisible"
                         :color="!isInboxVisible && !isTransferVisible ? 'mid' : 'light'"
                         :loading="loading" />
      <dashboard-inbox v-if="isInboxVisible && isTransferVisible"
                       small
                       :dashboard="dashboard"
                       :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { EFeature, hasFeature } from '@/config/features'

import DashboardAccount from '@/pages/dashboard/components/DashboardAccount.vue'
import DashboardInbox from '@/pages/dashboard/components/DashboardInbox.vue'
import DashboardPendingTransfers from '@/pages/dashboard/components/DashboardPendingTransfers.vue'

defineProps<{
  dashboard: object,
  loading?: boolean
}>()

const isInboxVisible = computed(() => {
  return hasFeature(EFeature.Inbox)
})

const isTransferVisible = computed(() => {
  return hasFeature(EFeature.Transfer)
})
</script>
