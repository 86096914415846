<template>
  <modal-dialog-placeholder data-cy="account.create.sucess-modal"
                            @close="close">
    <component-placeholder :button="validationButton"
                           button-theme="default"
                           :content="validationContent"
                           :label="validationLabel"
                           @btn-click="onClickButton">
      <image-success />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script>
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { EAccountType } from '@/types/account.d'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageSuccess from '@/components/svg/img/success.vue'

export default {
  components: {
    ComponentPlaceholder,
    ImageSuccess,
    ModalDialogPlaceholder
  },

  props: {
    newAccount: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    return {
      accountStore,
      appStore
    }
  },

  computed: {
    accountLabel () {
      return `"${this.newAccount.company.name} - ${this.newAccount.label}"`
    },

    validationButton () {
      return this.newAccount.status === ('valid' && this.newAccount.type !== EAccountType.Term)
        ? this.$i18n.t('button.go_to_account')
        : this.$i18n.t('button.ok_understood')
    },

    validationContent () {
      if (this.newAccount.type === EAccountType.Term) {
        return this.$i18n.t('create_term_account.success')
      }

      if (this.newAccount.status !== 'valid') {
        return this.$i18n.t('modal.account_validation.pending.content')
      }

      if (this.newAccount.type === EAccountType.CapitalGain) {
        return this.$i18n.t('create_capital_gain_account.success', { label: this.accountLabel })
      }

      if (this.newAccount.type === EAccountType.Sequestre) {
        return this.$i18n.t('create_checks_account.success')
      }

      return this.$i18n.t('create_additional_account.success', { label: this.accountLabel })
    },

    validationLabel () {
      if (this.newAccount.type === EAccountType.Term) {
        return this.$i18n.t('modal.term_account_validation.success.label')
      }

      if (this.newAccount.status !== 'valid') {
        return this.$i18n.t('modal.account_validation.pending.label')
      }

      return this.$i18n.t('modal.account_validation.success.label')
    }
  },

  methods: {
    async close () {
      await this.$router.push({ name: 'account-company' })
      this.appStore.closeModal()
    },

    onClickButton () {
      if (this.newAccount.type === EAccountType.Term) {
        return this.switchToNewAccount()
      }

      if (this.newAccount && this.newAccount.status === 'valid') {
        return this.switchToNewAccount()
      }

      return this.close()
    },

    async switchToNewAccount () {
      await this.accountStore.switchAccount(this.newAccount.uuid)

      if (this.newAccount.type !== EAccountType.CapitalGain) {
        await this.$router.push({ name: 'dashboard' })
        this.appStore.closeModal()
      }
    }
  }
}
</script>
