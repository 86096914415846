<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.inbox") }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <button class="btn btn--primary"
                  @click.prevent.stop="onNewMessage">
            {{ $t("button.new_message") }}
          </button>
          <button v-if="selectedMessages.length > 0"
                  class="btn btn--default"
                  @click.prevent.stop="deleteSelected()">
            {{ $t("button.delete") }} ({{ selectedMessages.length }})
          </button>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange
                       ]"
                       :number-of-results="messagesPagination.totalItems"
                       @filters="filters" />
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <template v-else-if="messages.length">
        <table class="table table--hover">
          <thead>
            <tr>
              <th width="80">
                <label class="form-check__label checkbox">
                  <input v-model="allSelected"
                         type="checkbox">
                  <span class="checkbox__icon" />
                </label>
              </th>
              <th class="th-date">
                {{ $t("table.date") }}
              </th>
              <th>{{ $t("table.message_subject") }}</th>
              <th>{{ $t("table.message_from") }}</th>
              <th class="center" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in messages"
                :key="message.id"
                class="is-link"
                :class="{ strong: !message.is_read }">
              <td>
                <label class="form-check__label checkbox">
                  <input v-model="message.selected"
                         type="checkbox"
                         name="checkbox">
                  <span class="checkbox__icon mr-0" />
                </label>
              </td>
              <td class="text-muted nowrap"
                  @click="onReadMessage(message)">
                {{ message.createdAtFormatted() }}
              </td>
              <td :class="{ 'text-muted': message.is_read }"
                  @click="onReadMessage(message)">
                {{ message.subject }}
              </td>
              <td :class="{ 'text-muted': message.is_read }"
                  @click="onReadMessage(message)">
                {{ message.from }}
              </td>
              <td class="col-action">
                <span class="hover-item">
                  <button v-tooltip="{ content: $t('tooltip.delete'), theme: 'tooltip' }"
                          class="btn-link"
                          @click.prevent.stop="deleteSelected(message.id)">
                    <ic-remove class="ic ic--gray" />
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <component-pagination :pagination="messagesPagination" />
      </template>
      <component-placeholder v-else
                             :label="$t('placeholder.inbox_empty.title')"
                             :content="$t('placeholder.inbox_empty.subtitle')" />
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatDateText } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useMessageStore } from '@/stores/message'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import InboxMessage from '@/components/modals/Contact.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

import MessageDetail from './modal/MessageDetail.vue'
export default {
  name: 'PageInbox',

  components: {
    IcRemove,
    ButtonFilters,
    ComponentFilters,
    ComponentPagination,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const messageStore = useMessageStore()

    const { messages, messagesPagination } = storeToRefs(messageStore)

    return { appStore, messages, messagesPagination, messageStore }
  },

  data () {
    return {
      loading: true,
      filter: {
        search: null,
        from: null,
        to: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    allSelected: {
      get () { return this.selectedMessages.length === this.messages.length },
      set (value) { this.selectAll(value) }
    },

    selectedMessages () {
      return this.messages.filter(message => message.selected)
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('account-switched', this.filters)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('account-switched', this.filters)
  },

  methods: {
    formatDateText,

    async initData (showLoader = true) {
      this.loading = showLoader
      await this.messageStore.getMessages(this.filter)
      this.loading = false
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    filters (filters = {}) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to
      }
    },

    selectAll (value) {
      this.messages.forEach(message => { message.selected = value })
    },

    deleteSelected (messageId = null) {
      if (!messageId && !this.selectedMessages.length) { return }

      const ids = messageId ? [messageId] : this.selectedMessages.map(message => message.id)

      this.appStore.showDeleteModal({
        label: this.$i18n.tc('prompt.message.delete.title', ids.length),
        content: this.$i18n.tc('prompt.message.delete.content', ids.length),
        url: `/messages/${ids.join(',')}`
      })
    },

    onNewMessage () {
      this.appStore.showModal(InboxMessage, {}, { wrapperClass: 'modal--md' })
    },

    onReadMessage (message) {
      this.appStore.showModal(MessageDetail, { id: message.id }, { wrapperClass: 'modal--md' })
    },

    onDeleteSuccess () {
      showToastSuccess(this.$i18n.t('message.success.message.delete'))
      this.initData()
    }
  }
}

</script>
