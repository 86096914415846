<template>
  <div class="login-block-wrapper">
    <div class="login-block">
      <h2 class="login-title">
        {{ $t('title.logout_inactivity.h1') }}
      </h2>
      <div class="login-subtitle">
        {{ $t('title.logout_inactivity.subtitle') }}
      </div>
      <div class="login-form">
        <router-link class="btn btn--primary btn--block"
                     :to="{ name: 'login' }">
          {{ $t('title.logout_inactivity.cta') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
