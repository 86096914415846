<template>
  <section class="section"
           :class="{ 'section--empty': !loading && !scaList.length }">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link v-if="!hasToConfigureSca"
                       class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.sca') }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <router-link v-if="scaList.length"
                       :to="{ name: 'account-sca-configure' }"
                       class="btn btn--primary">
            {{ $t('button.add') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <div v-else-if="scaList.length">
        <component-alert v-if="scaList.length < 2"
                         :type="'info'"
                         :message="$t('message.info.sca.add_another_sca')" />
        <div class="text-muted">
          {{ $t('sca.activated_methods.title') }}
        </div>
        <table class="table table--bordered-row">
          <tbody>
            <tr v-for="(item, index) in scaList"
                :key="index"
                class="bordered-row">
              <td class="col--sm-1 center">
                <ic-phone v-if="item.type === 'app'"
                          class="ic ic--20" />
                <ic-textmessage v-else-if="item.type === 'sms'"
                                class="ic ic--20" />
                <ic-mail v-if="item.type === 'email'"
                         class="ic ic--20" />
              </td>
              <td class="col--sm-4"
                  style="padding-left: 0;">
                <div class="strong">
                  {{ getTypeLabel(item) }}
                </div>
                <div class="text-muted">
                  <template v-if="item.activated_at">
                    {{ $t('sca.activated_methods.added_on') }} {{ formatDateText(item.activated_at, 'LLL') }}
                  </template>
                  <template v-else>
                    {{ $t('sca.activated_methods.not_configured') }}
                  </template>
                </div>
              </td>
              <td class="col--sm text-muted">
                <template v-if="item.activated_at">
                  {{ $t('sca.activated_methods.last_use') }} :
                  <template v-if="item.last_used_at">
                    {{ formatDateText(item.last_used_at, 'LLL') }}
                  </template>
                  <template v-else>
                    {{ formatDateText(item.activated_at, 'LLL') }}
                  </template>
                </template>
              </td>
              <td class="col--sm right">
                <button v-if="item.deletable"
                        class="btn btn--outline-red btn--sm"
                        @click="requestDelete(item.id)">
                  {{ $t('button.delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else
           class="placeholder-action-description">
        <component-placeholder :label="$t('placeholder.sca.title')"
                               :content="$t('placeholder.sca.subtitle')">
          <computer-phone-security />
        </component-placeholder>
        <div class="center">
          <router-link :to="{ name: 'account-sca-configure' }"
                       class="btn btn--primary placeholder-btn">
            {{ $t('button.configure') }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { formatDateText } from '@/helpers/utils/date'
import { useAuthStore } from '@/stores/auth'
import { useScaStore } from '@/stores/sca'

import ComponentAlert from '@/components/Alert.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcPhone from '@/components/svg/icons/ic-phone.vue'
import IcTextmessage from '@/components/svg/icons/ic-textmessage.vue'
import ComputerPhoneSecurity from '@/components/svg/img/computer-phone-security.vue'

export default {
  components: {
    IcMail,
    IcTextmessage,
    IcPhone,
    IcArrow,
    ComputerPhoneSecurity,
    ComponentAlert,
    ComponentPlaceholder,
    LoaderSpinner
  },

  setup () {
    const authStore = useAuthStore()
    const scaStore = useScaStore()

    const { user } = storeToRefs(authStore)
    const { scaList } = storeToRefs(scaStore)

    return { user, scaList, authStore, scaStore }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    hasToConfigureSca () {
      return this.authStore.isScaSectionEnforced
    }
  },

  async created () {
    this.loading = true
    await this.scaStore.getScaList()
    if (this.scaList.length !== 0 && this.authStore.isScaSectionEnforced) {
      await this.authStore.refreshUser()
    }
    this.loading = false
  },

  methods: {
    formatDateText,

    async requestDelete (itemUuid) {
      await this.scaStore.deleteScaToken(itemUuid)
      await this.scaStore.getScaList()
    },

    getTypeLabel (item) {
      if (item.type === 'app') {
        let label = item.device_name ? this.$i18n.t('sca.type.app_device', { deviceName: item.device_name }) : this.$i18n.t(`sca.type.${item.type}`)
        if (item.device_os) {
          label += ` (${item.device_os})`
        }
        return label
      }

      return item.type ? this.$i18n.t(`sca.type.${item.type}`) : ''
    }
  }

}
</script>
