<template>
  <div class="login-block-wrapper">
    <loader-spinner size="24"
                    class="c c--vh spinner--loading" />
    <div class="login-block">
      <template v-if="validToken">
        <h2 class="login-title">
          {{ $t("title.new_password") }}
        </h2>
        <div class="login-subtitle">
          {{ $t("form.password.helper") }}
        </div>
        <validation-form v-slot="{ errors, isSubmitting }"
                         class="login-form"
                         autocomplete="off"
                         :validation-schema="schema"
                         @submit="onSubmit"
                         @invalid-submit="onInvalidSubmit">
          <div class="login-form__input">
            <input-password v-model="password"
                            name="password"
                            :class="{ error: errors.password }"
                            :placeholder="$t('form.new_password.label')"
                            password-visibility-toggle />
          </div>
          <div class="login-form__input">
            <input-password id="password_repeat"
                            v-model="password_repeat"
                            autocomplete="off"
                            name="password_repeat"
                            :password-meter="false"
                            password-visibility-toggle
                            :class="{ error: errors.password_repeat }"
                            :placeholder="$t('form.confirm_password.label')" />
          </div>
          <button class="btn btn--primary btn--block login-form__submit"
                  :disabled="isSubmitting"
                  type="submit">
            {{ $t("button.validate") }}
          </button>
        </validation-form>
      </template>
      <template v-else>
        <h2 class="login-title">
          {{ $t('message.warning.problem') }}
        </h2>
        <div class="login-subtitle">
          {{ $t('message.warning.invalid_link') }}
        </div>
        <router-link :to="{ name: 'home' }"
                     class="btn btn--primary btn--block login-form__submit">
          {{ $t("button.back_to_home") }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { ECoreBannerTheme } from '@common/core-ui'
import { zxcvbn } from '@zxcvbn-ts/core'
import { decodeJwt } from 'jose'
import * as yup from 'yup'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import InputPassword from '@/components/InputPassword.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  components: {
    LoaderSpinner,
    InputPassword
  },

  props: {
    token: {
      type: String,
      default: null
    }
  },

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()

    return { appStore, authStore }
  },

  data () {
    return {
      password: '',
      password_repeat: '',
      validToken: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        password: yup.string().matches(/\d/).min(8)
          .test(value => zxcvbn(value).score >= 1)
          .required(),
        password_repeat: yup.string()
          .oneOf([yup.ref('password'), null], 'Passwords must match').required()
      })
    }
  },

  created () {
    if (!this.token) { return }

    const decodedToken = decodeJwt(this.token)
    if (decodedToken.exp < Math.floor(new Date().getTime() / 1000)) {
      return
    }

    this.validToken = true
  },

  methods: {
    async onSubmit () {
      const { error } = await this.authStore.passwordReset(this.token, this.password, this.password_repeat)
      if (error?.status === 401) {
        this.validToken = false
      }
    },

    onInvalidSubmit () {
      this.appStore.showBanner(ECoreBannerTheme.Danger, this.$i18n.t('message.warning.missing_fields'))
    }
  }
}
</script>

<style lang="stylus" scoped>
.section-login
  .login-title
    margin-bottom 2.5rem

  .login-form
    margin-bottom 0
</style>
