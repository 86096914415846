<template>
  <div class="placeholder-action-description">
    <component-placeholder :label="$t('placeholder.sca.title')"
                           :content="$t('placeholder.sca_choice.subtitle')">
      <computer-phone-security />
    </component-placeholder>
    <div class="center w-full">
      <loader-spinner v-if="loading" />
      <div v-else
           class="bordered-box bordered-box--grouped">
        <div v-for="(item, index) in scaList"
             :key="index"
             class="bordered-box__item is-link"
             @click="onClickSca(item)">
          <div class="item-left">
            <ic-phone v-if="item.type === 'app'"
                      class="ic ic--20 ic--gray" />
            <ic-textmessage v-else-if="item.type === 'sms'"
                            class="ic ic--20 ic--gray" />
            <ic-mail v-if="item.type === 'email'"
                     class="ic ic--20 ic--gray" />
            <div class="item-label">
              <div class="strong">
                {{ getTypeLabel(item) }}
              </div>
              <div v-if="item.device_os"
                   class="left text-muted label--sm">
                {{ item.device_os }}
              </div>
            </div>
          </div>
          <div class="item-right">
            <ic-chevron right
                        class="ic ic--12" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useScaStore } from '@/stores/sca'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcChevron from '@/components/svg/icons/ic-chevron.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcPhone from '@/components/svg/icons/ic-phone.vue'
import IcTextmessage from '@/components/svg/icons/ic-textmessage.vue'
import ComputerPhoneSecurity from '@/components/svg/img/computer-phone-security.vue'

export default {
  components: {
    IcMail,
    IcTextmessage,
    IcPhone,
    IcChevron,
    ComputerPhoneSecurity,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    challenge: {
      type: Object,
      required: true
    }
  },

  emits: ['action'],

  setup () {
    const scaStore = useScaStore()

    const { scaList } = storeToRefs(scaStore)

    return { scaList, scaStore }
  },

  data () {
    return {
      loading: true
    }
  },

  async created () {
    this.loading = true
    await this.scaStore.getScaList()
    this.loading = false
  },

  methods: {
    getTypeLabel (item) {
      if (item.type === 'app' && item.device_name) {
        return this.$i18n.t('sca.type.app_device', { deviceName: item.device_name })
      } else {
        return item.type ? this.$i18n.t(`sca.type.${item.type}`) : ''
      }
    },

    async onClickSca (item) {
      this.loading = true
      const data = await this.scaStore.triggerScaChallenge(this.challenge.id, item.id)
      if (data) {
        this.$emit('action', 'send-invitation', data)
      }
      this.loading = false
    }
  }
}
</script>
