<template>
  <div class="fs-page__content page-document-check">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="!errorCode">
      <component-alert v-if="!responseBody.up_to_date"
                       type="warning"
                       :message="$t('document_check.not_up_to_date')" />
      <component :is="componentName"
                 :type="responseBody.type"
                 :data="responseBody.data" />
    </template>
    <div v-else
         class="document-check document-check--error">
      <div class="document-check__header">
        <ic-remove class="ic ic--white ic--26" />
        <h1>{{ isInvalid ? $t('document_check.title.invalid') : $t('document_check.title.error') }}</h1>
      </div>
      <div class="document-check__content">
        <div class="error-message">
          <template v-if="isInvalid">
            <h3>{{ $t('document_check.message.important') }}</h3>
            <p>{{ $t('document_check.message.invalid') }}</p>
          </template>
          <p v-else>
            {{ $t('document_check.message.error') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useDocumentStore } from '@/stores/document'

import AccountCertificate from '@/pages/documents/components/AccountCertificate.vue'
import CapitalCreditorCertificate from '@/pages/documents/components/CapitalCreditorCertificate.vue'
import CapitalDepositCertificate from '@/pages/documents/components/CapitalDepositCertificate.vue'
import CapitalGainCertificate from '@/pages/documents/components/CapitalGainCertificate.vue'
import ClosedAccountCertificate from '@/pages/documents/components/ClosedAccountCertificate.vue'
import Rib from '@/pages/documents/components/Rib.vue'
import TermAccountContract from '@/pages/documents/components/TermAccountContract.vue'
import TransferProof from '@/pages/documents/components/TransferProof.vue'

import ComponentAlert from '@/components/Alert.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  name: 'PageDocumentCheck',

  components: {
    IcRemove,
    LoaderSpinner,
    ComponentAlert,
    AccountCertificate,
    CapitalDepositCertificate,
    CapitalGainCertificate,
    ClosedAccountCertificate,
    CapitalCreditorCertificate,
    Rib,
    TermAccountContract,
    TransferProof
  },

  async beforeRouteLeave (to, from, next) {
    if (this.authStore.user.token && to.matched.some(record => record.meta.requiresAuth)) {
      await this.authStore.refreshUser()
    }
    next()
  },

  props: {
    uuid: {
      type: String,
      required: true
    },

    signature: {
      type: String,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const documentStore = useDocumentStore()

    const { banner } = storeToRefs(appStore)

    return { banner, authStore, documentStore }
  },

  data () {
    return {
      errorCode: null,
      loading: true,
      responseBody: {
        type: '',
        data: {}
      }
    }
  },

  computed: {
    componentName () {
      switch (this.responseBody.type) {
        case 'temp_rib_new_company':
        case 'temp_rib_existing_company':
        case 'rib':
          return 'rib'
        case 'account_certificate':
          return 'account-certificate'
        case 'capital_deposit_certificate':
          return 'capital-deposit-certificate'
        case 'capital_gain_certificate':
          return 'capital-gain-certificate'
        case 'closed_account_certificate':
          return 'closed-account-certificate'
        case 'capital_creditor_certificate':
          return 'capital-creditor-certificate'
        case 'term_account_contract':
          return 'term-account-contract'
        case 'transfer_proof':
          return 'transfer-proof'
        default:
          return null
      }
    },

    isInvalid () {
      return [401, 403, 404].includes(this.errorCode)
    }
  },

  created () {
    this.checkDocument()
  },

  methods: {
    async checkDocument () {
      this.loading = true

      const { success, data } = await this.documentStore.getDocumentCheck(this.uuid, this.signature)

      success ? this.responseBody = data : this.errorCode = data

      this.loading = false
    }
  }
}
</script>
