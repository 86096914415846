<template>
  <div v-if="modelValue.length"
       :data-cy="`editable-workflow-${dataCy}`"
       class="workflow overflow-scrollbar"
       :class="{
         'workflow--split': isSimpleValidation,
         'workflow--chain': isChainValidation
       }">
    <sortable-list v-model="sortedMembers"
                   item-class="workflow-item"
                   handle-class="handle">
      <template #list="{ items }">
        <div v-for="(item, index) in items"
             :key="item.uuid"
             class="workflow-item">
          <span v-if="isChainValidation"
                class="workflow-item__number">{{ index + 1 }}</span>
          <div class="workflow-item__content">
            <user-avatar :picture="item.picture"
                         :size="40"
                         :placeholder-label="`${item.first_name} ${item.last_name}`" />
            <div class="item-left ml-2">
              <div class="strong">
                {{ item.name }}
              </div>
              <div v-if="item.role"
                   class="text-muted">
                {{ item.role_label }}
              </div>
            </div>
            <div v-if="!readOnly"
                 class="item-right">
              <span v-if="isChainValidation"
                    class="handle mr-2">
                <ic-listview class="ic ic--gray ic--24" />
              </span>
              <button :data-cy="`editable-workflow-${dataCy}.item-${index}.remove-button`"
                      type="button"
                      @click="removeMember(item)">
                <ic-remove class="ic ic--gray ic--18" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </sortable-list>
  </div>
</template>

<script>
import { ECoreBannerTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import SortableList from '@/components/SortableList.vue'
import IcListview from '@/components/svg/icons/ic-listview.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcRemove,
    IcListview,
    SortableList,
    UserAvatar
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    dataCy: {
      type: String,
      default: 'default'
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:model-value'],

  setup () {
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const { user } = storeToRefs(authStore)

    return {
      appStore,
      user
    }
  },

  computed: {
    isSimpleValidation () {
      return this.type === 'simple' || this.modelValue.length === 1
    },

    isChainValidation () {
      return this.type === 'ordered' && this.modelValue.length > 1
    },

    isOwnerMain () {
      return this.hasPermission(this.$permissions.accountOwnerMain)
    },

    sortedMembers: {
      get () {
        return this.modelValue
      },

      set (value) {
        this.$emit('update:model-value', value)
      }
    }
  },

  methods: {
    canRemoveMember (member) {
      return this.isOwnerMain || member.uuid !== this.user.uuid
    },

    removeMember (member) {
      if (!this.canRemoveMember(member)) {
        return this.appStore.showBanner(ECoreBannerTheme.Danger, this.$t('message.warning.workflow_approvers.no_remove'))
      }

      this.sortedMembers = this.modelValue.filter(el => el.uuid !== member.uuid)
    }
  }
}
</script>

<style lang="stylus" scoped>
.workflow
  border 1px solid $borderBoxColor
  margin-bottom 2rem
  background white
  border-radius $border-radius
  overflow auto
  padding 1.2rem

  &-item
    position relative
    display flex
    align-items center
    min-height 6.6rem
    padding 1.7rem 1.4rem

    .handle
      cursor grab
      padding 1rem

    &.draggable-source--is-dragging
      &, .handle
        cursor grabbing !important
      > *
        opacity 0

    &.draggable-mirror
      background-color rgba($colorLightGray, 0.8)

    &__content
      display flex
      align-items center
      width 100%

    .item-left
      line-height 1.4

    .item-right
      display flex
      margin-left auto

    &__number
      display block
      position relative
      background-color lighten($colorShinyGray, 50%)
      color $colorWhite
      margin-right 3.2rem
      font-size 1.4rem
      font-weight 500
      flex-shrink 0
      height 2.8rem
      width @height
      line-height @height
      text-align center
      border-radius 50%
      box-shadow 0 0 0 4px $colorWhite

    &:not(:last-child):after
      width calc(100% - 10rem)

  &--chain
    position relative

    &:before
      content ''
      position absolute
      border-left 1px solid $colorLightGray
      left 3.9rem
      top 4.5rem
      bottom 4.5rem

  &--split
    border none
    padding 0

    & ^[0]-item
      border 1px solid $borderBoxColor
      margin-bottom 1rem
      padding 1.7rem 2rem

      &:after
        content initial
</style>
