<template>
  <svg viewBox="0 0 20 20">
    <circle fill="#7A818C"
            cx="10"
            cy="10"
            r="10" />
    <path d="M9.15 3v7.5l.437.243 4.5 2.5.826-1.486L10.85 9.5V3Z"
          fill="#FFF" />
  </svg>
</template>

<script>
export default {
}
</script>
