<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <table v-else-if="beneficiaries.length"
           class="table table--hover">
      <thead>
        <tr>
          <th>{{ $t("table.beneficiary_name") }}</th>
          <th>{{ $t("table.code") }}</th>
          <th>{{ $t("table.account_number") }}</th>
          <th>{{ $t("table.bank_name") }}</th>
          <th>{{ $t("table.list") }}</th>
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <BeneficiaryTableRow v-for="(beneficiary, i) in beneficiaries"
                             :key="i"
                             :beneficiary="beneficiary"
                             is-search-result />
      </tbody>
    </table>
    <component-placeholder v-else
                           :label="$t('placeholder.no_beneficiary.found')" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useBeneficiaryStore } from '@/stores/beneficiary'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'

import BeneficiaryTableRow from './BeneficiaryTableRow.vue'

export default {
  components: {
    BeneficiaryTableRow,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const beneficiaryStore = useBeneficiaryStore()

    const { beneficiaries } = storeToRefs(beneficiaryStore)

    return { beneficiaries, beneficiaryStore }
  },

  data () {
    return {
      store,
      loading: false
    }
  },

  watch: {
    filter () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
  },

  methods: {
    async initData () {
      this.loading = true
      await this.beneficiaryStore.getBeneficiaries({ ...this.filter, expand: 'beneficiary_list' })
      this.loading = false
    },

    onDeleteSuccess (message) {
      this.$emit('close')
      showToastSuccess(message || this.$i18n.t('message.success.beneficiary.delete'))
      this.initData()
    }
  }
}
</script>
