<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'teams' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ title }}
        </h1>
      </div>
      <div v-if="isExistingTeam"
           class="section__heading__right">
        <div class="section-actions">
          <button v-if="hasPermission($permissions.teamsWrite)"
                  class="btn btn--outline-red"
                  @click.prevent="onDeleteTeam">
            {{ $t('button.delete_team') }}
          </button>
        </div>
      </div>
    </div>
    <div class="section__content">
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <validation-form v-else
                       v-slot="{ errors, isSubmitting }"
                       class="form form--centered form--w780"
                       :validation-schema="schema"
                       @keydown.enter.prevent
                       @invalid-submit="onInvalidSubmit"
                       @submit="onSubmit">
        <fieldset v-if="hasTeamsWritePermission"
                  class="form__fieldset">
          <group-title :title="$t('team.create.name.title')"
                       :subtitle="$t('team.create.name.subtitle')" />
          <div class="form__fieldset__group">
            <validation-field v-model="team.name"
                              class="form-control form-control--noborder"
                              :class="{ error: errors.name }"
                              name="name"
                              type="text"
                              :placeholder="$t('team.create.name.placeholder')" />
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <group-title :title="$t('team.create.configuration.title')"
                       :subtitle="hasTeamsWritePermission ? $t('team.create.configuration.subtitle') : null" />
          <div class="form__fieldset__group">
            <team-selector :members="team.managers"
                           :hide-members="getHideMembers('manager')"
                           :read-only="!hasTeamsWritePermission"
                           type="managers" />
            <team-selector :members="team.approvers"
                           :read-only="!hasTeamsWritePermission"
                           :hide-members="getHideMembers('approver')"
                           type="approvers" />
            <team-selector :members="team.members"
                           :read-only="!hasTeamsWritePermission"
                           :hide-members="getHideMembers('member')"
                           type="members" />
          </div>
        </fieldset>
        <div v-if="hasTeamsWritePermission"
             class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'teams' }">
            {{ $t('button.cancel') }}
          </router-link>
          <component-button type="submit"
                            class="btn btn--primary"
                            :disabled="isSubmitting"
                            :label="$t('button.validate')" />
        </div>
      </validation-form>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useTeamStore } from '@/stores/team'

import TeamSelector from '@/pages/teams/components/TeamSelector.vue'

import ComponentButton from '@/components/Button.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow,
    ComponentButton,
    TeamSelector,
    GroupTitle,
    LoaderSpinner
  },

  props: {
    id: {
      type: String,
      default: null
    }
  },

  setup () {
    const appStore = useAppStore()
    const teamStore = useTeamStore()

    return { appStore, teamStore }
  },

  data () {
    return {
      loading: false,
      schema: {
        name: yup.string().required()
      },

      team: {
        name: null,
        approvers: [],
        managers: [],
        members: []
      }
    }
  },

  computed: {
    isExistingTeam () {
      return !!this.id
    },

    title () {
      if (!this.hasTeamsWritePermission) {
        return this.team.name
      }
      return this.isExistingTeam ? this.$i18n.t('title.edit_team') : this.$i18n.t('title.create_team')
    },

    getUUIDMembers () {
      const users = []

      this.team.managers.forEach(user => users.push({ uuid: user.uuid, teamRole: 'manager' }))
      this.team.approvers.forEach(user => users.push({ uuid: user.uuid, teamRole: 'approver' }))
      this.team.members.forEach(user => users.push({ uuid: user.uuid, teamRole: 'member' }))

      return users
    },

    hasTeamsWritePermission () {
      return this.hasPermission(this.$permissions.teamsWrite)
    }
  },

  async created () {
    if (!this.isExistingTeam) { return }

    this.loading = true
    const data = await this.teamStore.getTeamDetail(this.id)
    this.loading = false

    data
      ? Object.assign(this.team, data)
      : await this.$router.push({ name: 'teams' })
  },

  mounted () {
    this.$bus.on('choose-members', this.onChooseMembers)
    this.$bus.on('delete-success', this.onDeleteSuccess)
  },

  beforeUnmount () {
    this.$bus.off('choose-members', this.onChooseMembers)
    this.$bus.off('delete-success', this.onDeleteSuccess)
  },

  methods: {
    onInvalidSubmit () {
      this.$bus.emit('scrollToTop')
    },

    async onSubmit () {
      const success = this.isExistingTeam
        ? await this.teamStore.editTeam(this.team)
        : await this.teamStore.createTeam(this.team)

      if (success) {
        await this.$router.push({ name: 'teams' })
      }
    },

    getHideMembers (role) {
      return this.getUUIDMembers.filter(user => user.teamRole !== role)
    },

    onChooseMembers ({ type, members }) {
      this.team[type] = members
    },

    onDeleteTeam () {
      this.appStore.showDeleteModal({ label: this.$i18n.t('prompt.team.delete.title'), content: this.$i18n.t('prompt.team.delete.content'), url: `/teams/${this.team.id}` })
    },

    onDeleteSuccess () {
      showToastSuccess(this.$i18n.t('message.success.team.delete'))
      this.$router.push({ name: 'teams' })
    }
  }
}
</script>
