<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="operationTypes"
                          dropdown-width="100%"
                          :dropdown-height="40"
                          :search="false"
                          :can-add-item="false"
                          :values="operationTypes"
                          :model="selectedOperationType ? operationTypes[selectedOperationType] : null"
                          @select="onTypeSelected">
        <template #trigger>
          <div class="select"
               :class="modelValue === null ? 'select--off' : 'is-clearable'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue">
                {{ operationTypes[selectedOperationType] }}
              </template>
              <template v-else>
                {{ $t('button.all_types') }}
              </template>
            </div>
            <button v-if="modelValue"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="reset">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import { findKey } from 'lodash'

import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    ComponentDropdown,
    IcRemove
  },

  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: null
    }
  },

  emits: [
    'clear-field',
    'update:modelValue'
  ],

  data () {
    return {
      selectedOperationType: null
    }
  },

  computed: {
    operationTypes () {
      return {
        credit: this.$i18n.t('general.credit'),
        debit: this.$i18n.t('general.debit')
      }
    }
  },

  watch: {
    modelValue (newValue) {
      newValue === null ? this.selectedOperationType = null : this.selectedOperationType = newValue
    }
  },

  methods: {
    onTypeSelected (typeLabel) {
      this.selectedOperationType = findKey(this.operationTypes, item => item === typeLabel)
      this.$emit('update:modelValue', this.selectedOperationType)
    },

    reset () {
      this.selectedOperationType = null
      this.$emit('update:modelValue', null)
    }
  }
}
</script>
