<template>
  <div class="sidepanel__content"
       :data-cy="`cards.settings.categories.limits.${category.name}`">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('cards_settings.authorized_categories.limit.title') }}
        </h2>
      </div>
      <div class="sidepanel-body">
        <group-title :title="$t('cards_settings.authorized_categories.limit.subtitle', { category: category.label }) "
                     :subtitle="$t('cards_settings.authorized_categories.limit.text')" />
        <fieldset-block>
          <limit-item :limit="unit.limit"
                      :label="$t('limit.unit')"
                      :enabled="unit.enabled"
                      @update-enabled="value => updateLimit({ value: { ...unit, enabled: value }, period: 'unit' })"
                      @update-limit="value => updateLimit({ value: { ...unit, limit: parseInt(value) }, period: 'unit' })" />
        </fieldset-block>
        <fieldset-block class="mt-2">
          <limits-per-period :value="{ daily, weekly, monthly, annually }"
                             @update-limit-settings="updateLimit" />
        </fieldset-block>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="form-buttons">
        <button class="btn btn--gray"
                type="button"
                @click="appStore.closeSidePanel">
          {{ $t('button.cancel') }}
        </button>
        <button class="btn btn--primary"
                type="button"
                data-cy="cards.settings.categories.limits.validate"
                @click="onSubmit">
          {{ $t('button.validate') }}
        </button>
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { isNumber } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'

import FieldsetBlock from '@/components/FieldsetBlock.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LimitItem from '@/components/limits/LimitItem.vue'
import LimitsPerPeriod from '@/components/limits/LimitsPerPeriod.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    LimitsPerPeriod,
    LimitItem,
    FieldsetBlock,
    GroupTitle,
    ComponentSidepanelFooter
  },

  props: {
    category: {
      type: Object,
      required: true
    },

    onUpdated: {
      type: Function,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    return { appStore }
  },

  data () {
    return {
      limits: cloneDeep(this.category.limit)
    }
  },

  computed: {
    unit: {
      get () {
        return this.getLimit('unit')
      },

      set (newValue) {
        return this.setLimit('unit', newValue)
      }
    },

    daily: {
      get () {
        return this.getLimit('daily')
      },

      set (newValue) {
        return this.setLimit('daily', newValue)
      }
    },

    weekly: {
      get () {
        return this.getLimit('weekly')
      },

      set (newValue) {
        return this.setLimit('weekly', newValue)
      }
    },

    monthly: {
      get () {
        return this.getLimit('monthly')
      },

      set (newValue) {
        return this.setLimit('monthly', newValue)
      }
    },

    annually: {
      get () {
        return this.getLimit('annually')
      },

      set (newValue) {
        this.setLimit('annually', newValue)
      }
    }
  },

  methods: {
    getLimit (limitType) {
      const limitValue = this.limits[`payment_${limitType}_limit`]
      return {
        enabled: isNumber(limitValue),
        limit: isNumber(limitValue) ? limitValue : 0
      }
    },

    setLimit (limitType, newValue) {
      this.limits[`payment_${limitType}_limit`] = newValue.enabled ? newValue.limit : null
    },

    updateLimit (limit) {
      this[limit.period] = limit.value
    },

    onSubmit () {
      this.onUpdated({
        name: this.category.name,
        enabled: this.category.enabled,
        limit: this.limits
      })
      this.appStore.closeSidePanel()
    }
  }

}
</script>
