<template>
  <svg viewBox="0 0 24 24">
    <path d="M13.083 5a6.04 6.04 0 0 0-.046 1.672l.046.327H2.572L9.5 12.704l4.367-3.596a6.03 6.03 0 0 0 1.308 1.515l-5.04 4.149a1 1 0 0 1-1.166.075l-.105-.075L2 9.119V18l15 .002v-6.343A5.99 5.99 0 0 0 19 12v6.002a2.004 2.004 0 0 1-1.85 1.992L17 20H2a2 2 0 0 1-1.994-1.85L0 18.003V6.999a2.004 2.004 0 0 1 1.85-1.993L2 5zM19 1a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm.007 6.785a.713.713 0 0 0-.518.211.68.68 0 0 0 .005 1c.067.06.145.11.233.146a.724.724 0 0 0 .28.055c.202 0 .374-.07.518-.21s.215-.31.215-.505a.646.646 0 0 0-.22-.495.736.736 0 0 0-.513-.202zm.55-5.133h-1.1v4.455h1.1z" />
  </svg>
</template>

<script>
export default {
}
</script>
