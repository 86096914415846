<template>
  <div class="modal__content">
    <modal-header :close-button="false" />
    <div class="modal__body">
      <div class="multiple-transfer-modal-content center">
        <lottie name="document-sablier"
                class="icon"
                style="height: 110px; margin-left: auto; margin-right: auto; margin-bottom: 2rem;"
                :start-frames="[0, 10]"
                :loop-frames="[10, 61]" />
        <h4>{{ title }}</h4>
        <p>{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useApiStore } from '@/stores/api'

import Lottie from '@/components/Lottie.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'

export default {
  name: 'ComponentWait',

  components: {
    Lottie,
    ModalHeader
  },

  props: {
    title: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const apiStore = useApiStore()
    const { loading: apiLoading } = storeToRefs(apiStore)
    return { apiLoading }
  },

  watch: {
    apiLoading () {
      if (!this.apiLoading) {
        this.$emit('close')
      }
    }
  }
}
</script>
