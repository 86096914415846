<template>
  <validation-form v-slot="{ errors, isSubmitting }"
                   :validation-schema="schema"
                   class="sidepanel__content"
                   @invalid-submit="scrollTopHandler"
                   @submit="onSubmit"
                   @key.enter.prevent>
    <div id="sidepanel-wrapper"
         ref="scrollWrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('order_card.step.delivery_address') }}
        </h2>
      </div>
      <div class="sidepanel-body section-card-order">
        <component-alert v-if="Object.keys(errors).length && formSubmitted"
                         type="error"
                         :message="$t('message.warning.form_not_valid')" />
        <delivery-address v-model:address="address"
                          :errors="errors"
                          :card="model"
                          :invitation="{ company: account.company.name }"
                          is-imported-user
                          :form-submitted="false" />
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="row row--sm-bottom">
        <div class="col col--sm right">
          <div class="form-buttons">
            <button class="btn btn--gray"
                    @click.prevent.stop="appStore.closeSidePanel">
              {{ $t('button.cancel') }}
            </button>
            <button class="btn btn--primary"
                    :disabled="isSubmitting"
                    data-cy="edit-shipping-address.submit">
              {{ $t('button.validate') }}
            </button>
          </div>
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { scrollToTop } from '@/helpers/utils/dom'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import DeliveryAddress from '@/pages/cards/order/physical/components/DeliveryAddress.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    ComponentAlert,
    ComponentSidepanelFooter,
    DeliveryAddress
  },

  props: {
    model: {
      type: Object,
      required: true
    },

    updateAddress: {
      type: Function,
      default: () => ({})
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore, cardStore }
  },

  data () {
    return {
      formSubmitted: false,
      address: cloneDeep(this.model.address)
    }
  },

  computed: {
    schema () {
      return yup.object({
        add_company_name: yup.boolean(),
        street: yup.string().required(),
        postcode: yup.string().required(),
        city: yup.string().required(),
        country: yup.string().required()
      })
    }
  },

  methods: {
    async onSubmit () {
      this.formSubmitted = true
      const success = await this.cardStore.updateAddress(this.model.uuid, this.address)
      if (success) {
        showToastSuccess(this.$t('message.success.edit_shipping_address'))
        this.appStore.closeSidePanel()
        this.updateAddress(this.address)
      } else {
        this.scrollTopHandler()
      }
    },

    scrollTopHandler () {
      scrollToTop(this.$refs.scrollWrapper)
    }
  }
}
</script>
