<template>
  <div class="section-creditcard-settings"
       data-cy="cards.settings">
    <template v-if="hasFeature(EFeature.CardSettings, card)">
      <div class="mt-4 mb-4 center">
        <segmented-control v-model="switchButton"
                           :buttons="menuButtons" />
      </div>
      <div class="contain">
        <div v-if="showAsideMenu"
             class="aside-menu">
          <ul class="items">
            <template v-for="item in steps"
                      :key="item.id">
              <li v-if="item.menu === switchButton"
                  :class="{ 'item--selected': item.active }"
                  class="item"
                  :data-cy="`cards.settings.menu.${item.id}`">
                <a class="item__label"
                   href="#"
                   @click.prevent.stop="scrollToHandler(item.id)">{{ $t(item.name) }}</a>
              </li>
            </template>
          </ul>
        </div>
        <div class="section-creditcard-settings__wrapper">
          <div class="section-creditcard-settings__container block-centered">
            <div class="creditcard-settings"
                 :class="{ 'creditcard-settings--with-menu': showAsideMenu }">
              <template v-if="isTabGeneral">
                <security :disabled="isDisabled"
                          :settings="settings"
                          :is-virtual-card="isVirtualCard"
                          @update-card-settings="updateCardSettings" />
                <template v-if="isVirtualCard">
                  <periodicity-virtual v-if="isRecurringCard"
                                       :disabled="isDisabled"
                                       :settings="settings"
                                       @update-card-settings="updateCardSettings" />
                  <card-limits v-if="!isRecurringCard"
                               :disabled="isDisabled"
                               :is-physical-card="false"
                               :display-global-limit="!isCardClassic"
                               :settings="settings"
                               @update-card-settings="updateCardSettings"
                               @update-limit-settings="updateLimitSettings" />
                  <control-rule :settings="settings"
                                :card-uuid="card.uuid"
                                :card="card"
                                :disabled="isControlRuleDisabled"
                                @update-card-settings="updateCardSettings" />
                  <alerts :disabled="isDisabled"
                          :settings="settings"
                          :type="scopedCard.type"
                          @update-card-settings="updateCardSettings" />
                </template>
                <template v-else>
                  <card-limits :disabled="isDisabled"
                               is-physical-card
                               :settings="settings"
                               @update-card-settings="updateCardSettings" />
                  <control-rule :settings="settings"
                                :card-uuid="card.uuid"
                                :card="card"
                                :disabled="isControlRuleDisabled"
                                @update-card-settings="updateCardSettings" />
                  <alerts :disabled="isDisabled"
                          :settings="settings"
                          :type="scopedCard.type"
                          @update-card-settings="updateCardSettings" />
                  <smart-card v-if="hasFeature(EFeature.SmartCard, card)"
                              :disabled="isDisabled || !hasFeature(EFeature.SmartCardEdition, card)"
                              :card="scopedCard"
                              :smart-card-settings="settings.nfc_tag"
                              :does-user-exist="scopedCard.user !== null"
                              @update-smart-card-settings="updateSmartCardSettings"
                              @update-smart-card-settings-from-backend="updateSmartCardSettingsFromBackend" />
                </template>
              </template>
              <template v-else>
                <categories v-if="hasAuthorizationCategories"
                            :disabled="isDisabled"
                            :settings="settings"
                            @update-card-settings="updateCardSettings" />
                <authorized-days :disabled="isDisabled"
                                 :settings="settings"
                                 @update-card-settings="updateCardSettings" />
                <div class="blocks-row">
                  <include-list :disabled="isDisabled"
                                :value="settings.blocked_keywords"
                                type="blocked_keywords"
                                @update-card-settings="updateCardSettings" />

                  <include-list :disabled="isDisabled"
                                :value="settings.authorization_merchants"
                                type="authorization_merchants"
                                @update-card-settings="updateCardSettings" />
                </div>
                <div class="blocks-row">
                  <auto-block-spent :disabled="isDisabled"
                                    :settings="settings"
                                    @update-card-settings="updateCardSettings" />
                  <limit-transaction :disabled="isDisabled"
                                     :settings="settings"
                                     @update-card-settings="updateCardSettings" />
                </div>
                <div class="blocks-row">
                  <countries-list-select v-if="hasSettingField('blocked_countries')"
                                         id="countries"
                                         :disabled="isDisabled"
                                         :title="$t('cards_settings.blocked_countries.title')"
                                         :placeholder-icon="CountryBlocked()"
                                         :placeholder-text="$t('cards_settings.blocked_countries.placeholder')"
                                         :countries="settings.blocked_countries"
                                         @update-countries="updateCountries('blocked_countries', $event)" />
                  <countries-list-select v-if="!isOneTimeCard"
                                         :disabled="isDisabled"
                                         :title="$t('cards_settings.check_countries.title')"
                                         :placeholder-icon="CountryCheckin()"
                                         :placeholder-text="$t('cards_settings.check_countries.placeholder')"
                                         :countries="settings.check_countries"
                                         @update-countries="updateCountries('check_countries', $event)" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-else-if="hasFeature(EFeature.SmartCard, card)">
      <div class="contain">
        <div class="section-creditcard-settings__container block-centered">
          <div class="creditcard-settings">
            <smart-card :disabled="!hasFeature(EFeature.CardSettingsEdition, card) && !hasFeature(EFeature.SmartCardEdition, card)"
                        :card="scopedCard"
                        :smart-card-settings="settings.nfc_tag"
                        :does-user-exist="scopedCard.user !== null"
                        @update-smart-card-settings="updateSmartCardSettings"
                        @update-smart-card-settings-from-backend="updateSmartCardSettingsFromBackend" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { EFeature, hasFeature } from '@/config/features'
import store from '@/config/store'
import { isClassicCard, isOneTimeVirtualCard, isRecurringVirtualCard, isVirtualCard } from '@/helpers/utils/card'
import { scrollElementToTop } from '@/helpers/utils/dom'
import { useCardStore } from '@/stores/card'

import AuthorizedDays from '@/pages/cards/components/settings/AuthorizedDays.vue'
import CardLimits from '@/pages/cards/components/settings/CardLimits.vue'
import Security from '@/pages/cards/components/settings/Security.vue'
import SmartCard from '@/pages/cards/details/components/settings/SmartCard.vue'

import SegmentedControl from '@/components/SegmentedControl.vue'
import CountryBlocked from '@/components/svg/img/country-blocked.vue'
import CountryCheckin from '@/components/svg/img/country-checkin.vue'

import Alerts from './components/settings/Alerts.vue'
import AutoBlockSpent from './components/settings/AutoBlockSpent.vue'
import Categories from './components/settings/Categories.vue'
import ControlRule from './components/settings/ControlRule.vue'
import CountriesListSelect from './components/settings/CountriesListSelect.vue'
import IncludeList from './components/settings/IncludeList.vue'
import LimitTransaction from './components/settings/LimitTransaction.vue'
import PeriodicityVirtual from './components/settings/PeriodicityVirtual.vue'

export default {
  name: 'CardSettings',

  components: {
    SmartCard,
    SegmentedControl,
    ControlRule,
    PeriodicityVirtual,
    CountriesListSelect,
    AutoBlockSpent,
    LimitTransaction,
    IncludeList,
    AuthorizedDays,
    Alerts,
    Categories,
    CardLimits,
    Security
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    showAsideMenu: {
      type: Boolean,
      default: true
    }
  },

  emits: ['card-update'],

  setup () {
    const cardStore = useCardStore()

    return { cardStore }
  },

  data () {
    return {
      loading: false,
      scopedCard: cloneDeep(this.card),
      store,
      steps: [
        {
          name: 'cards_settings.menu.security',
          id: 'security',
          active: true,
          menu: 'general'
        },
        {
          name: 'cards_settings.menu.limit',
          id: 'limit',
          active: false,
          menu: 'general'
        },
        {
          name: 'cards_settings.menu.control_rule',
          id: 'control-rule',
          active: false,
          menu: 'general',
          visible: hasFeature(EFeature.CardControlRule)
        },
        {
          name: 'cards_settings.menu.alerts',
          id: 'alerts',
          active: false,
          menu: 'general'
        },
        {
          name: 'cards_settings.menu.smart_card',
          id: 'smart-card',
          active: false,
          menu: 'general'
        },
        {
          name: 'cards_settings.menu.mcc',
          id: 'mcc',
          active: false,
          menu: 'advanced'
        },
        {
          name: 'cards_settings.menu.dayblock',
          id: 'dayblock',
          active: false,
          menu: 'advanced'
        },
        {
          name: 'cards_settings.menu.authorisations',
          id: 'authorisations',
          active: false,
          menu: 'advanced'
        },
        {
          name: 'cards_settings.menu.countries_management',
          id: 'countries',
          active: false,
          menu: 'advanced'
        }
      ].filter(item => item.visible !== false),

      listeners: [],
      switchButton: 'general'
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    settings () {
      return this.scopedCard.settings
    },

    isVirtualCard () {
      return isVirtualCard(this.scopedCard)
    },

    isOneTimeCard () {
      return isOneTimeVirtualCard(this.scopedCard)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.scopedCard)
    },

    isCardClassic () {
      return isClassicCard(this.scopedCard)
    },

    isTabGeneral () {
      return this.switchButton === 'general'
    },

    isControlRuleDisabled () {
      return !hasFeature(EFeature.CardControlRuleEdition) || this.isDisabled
    },

    isDisabled () {
      return this.loading || !hasFeature(EFeature.CardSettingsEdition, this.card)
    },

    hasAuthorizationCategories () {
      return !!this.settings.authorization_categories?.length
    },

    menuButtons () {
      return [
        {
          label: this.$i18n.t('button.general'),
          value: 'general'
        },
        {
          label: this.$i18n.t('button.advanced'),
          value: 'advanced'
        }
      ]
    },

    mainContentElement () {
      return document.getElementById('content')
    }
  },

  watch: {
    switchButton (val) {
      this.toggleState(val)
      this.$nextTick(() => { this.initSteps() })
    },

    card (val) {
      this.scopedCard = cloneDeep(val)
    }
  },

  mounted () {
    this.initSteps()
  },

  beforeUnmount () {
    this.listeners.forEach(listener => {
      this.mainContentElement.removeEventListener('scroll', listener)
    })
  },

  methods: {
    hasFeature,

    CountryCheckin () {
      return CountryCheckin
    },

    CountryBlocked () {
      return CountryBlocked
    },

    async updateCountries (key, countries) {
      this.loading = true
      const success = await this.cardStore.updateSettings(this.scopedCard.uuid, { [key]: countries })
      this.loading = false
      if (success) this.settings[key] = countries
    },

    async updateLimitSettings (type, period) {
      this.loading = true
      const settings = {
        [type]: {
          [period]: this.scopedCard.settings[type][period]
        }
      }
      await this.cardStore.updateSettings(this.scopedCard.uuid, settings)
      this.loading = false
    },

    async updateCardSettings (key) {
      this.loading = true
      let settings = key

      if (typeof key !== 'object') {
        settings = {
          [key]: this.scopedCard.settings[key]
        }
      }

      if (key.authorization_categories) this.updateCardAuthorizationCategories(key.authorization_categories)
      else {
        this.scopedCard.settings = { ...this.scopedCard.settings, ...settings }
      }

      await this.cardStore.updateSettings(this.scopedCard.uuid, settings)
      this.loading = false
      this.$emit('card-update', this.scopedCard)
      this.$bus.emit('card-updated')
    },

    async updateCardAuthorizationCategories (newAuthorizationCategories) {
      const authorizationCategories = cloneDeep(this.scopedCard.settings.authorization_categories)

      newAuthorizationCategories.forEach(item => {
        const searchIndex = authorizationCategories.findIndex(search => search.name === item.name)
        if (searchIndex !== -1) {
          authorizationCategories[searchIndex] = {
            ...authorizationCategories[searchIndex],
            ...item
          }
        }
      })

      this.scopedCard.settings.authorization_categories = authorizationCategories
    },

    async updateSmartCardSettings ({ key, value }) {
      this.loading = true

      this.scopedCard.settings.nfc_tag[key] = value
      await this.cardStore.updateSmartCardSettings(this.scopedCard.uuid, this.scopedCard.settings.nfc_tag)

      this.loading = false
      this.$emit('card-update', this.scopedCard)
    },

    updateSmartCardSettingsFromBackend (smartCardSettings) {
      this.scopedCard.settings.nfc_tag = smartCardSettings
      this.$emit('card-update', this.scopedCard)
    },

    scrollToHandler (item) {
      scrollElementToTop(this.mainContentElement, document.getElementById(item), -100)
    },

    isInViewport (elem) {
      if (!elem) {
        return false
      }

      const scrollY = window.pageYOffset
      const sectionHeight = elem.offsetHeight
      const headerOffset = 250
      const sectionTop = (elem.getBoundingClientRect().top + window.pageYOffset) - headerOffset

      return scrollY > sectionTop &&
        scrollY <= sectionTop + sectionHeight
    },

    initSteps () {
      this.listeners.forEach(listener => {
        this.mainContentElement.removeEventListener('scroll', listener)
      })

      if (!hasFeature(EFeature.SmartCard, this.card)) {
        const index = this.steps.findIndex(step => step.id === 'smart-card')
        if (index !== -1) {
          this.steps.splice(index, 1)
        }
      }

      if (!this.hasAuthorizationCategories) {
        const index = this.steps.findIndex(step => step.id === 'mcc')
        if (index !== -1) {
          this.steps.splice(index, 1)
        }
      }

      if (this.isOneTimeCard) {
        ['dayblock', 'control-rule', 'countries'].forEach(id => {
          const index = this.steps.findIndex(step => id === step.id)
          if (index !== -1) {
            this.steps.splice(index, 1)
          }
        })
      }

      // Adds a listener for each step and determines which one is active and so highlighted
      this.steps.forEach(step => {
        const stepElement = document.getElementById(step.id)

        if (stepElement) {
          const listener = () => {
            if (!this.isInViewport(stepElement)) return

            // The lowest/last matching item will always prevail so the forEach method perfectly does the job
            step.active = true
            this.removeActiveState(step.id)
          }
          this.listeners.push(listener)
          this.mainContentElement.addEventListener('scroll', listener)
        }
      })
    },

    toggleState (val) {
      this.switchButton = val
      this.steps.forEach(step => { step.active = false })

      this.isTabGeneral
        ? this.steps.filter(step => step.menu === 'general')[0].active = true
        : this.steps.filter(step => step.menu === 'advanced')[0].active = true
    },

    removeActiveState (activeStepId) {
      // let's "reset" the active (nav highlighting) property on every item but the current one
      const otherSteps = this.steps.filter(step => step.id !== activeStepId)
      otherSteps.forEach(step => { step.active = false })
    },

    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    }
  }
}
</script>
