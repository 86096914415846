<template>
  <modal-dialog-prompt :title="$t('modal.reactivate_user.title')"
                       @close="$emit('close')">
    <template #body>
      <p>
        {{ $t('modal.reactivate_user.subtitle_one', { firstName: user.first_name, lastName: user.last_name }) }}
        <i18n-t v-if="accessList.length"
                tag="span"
                keypath="modal.reactivate_user.subtitle_two"
                scope="global">
          <template #accessList>
            <b>{{ accessList }}</b>
          </template>
        </i18n-t>
      </p>
    </template>

    <template #footerButtons>
      <button class="btn btn--gray"
              type="button"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <button :disabled="loading"
              class="btn btn--primary"
              type="button"
              @click="onValidate">
        {{ $t('button.validate') }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { EFeature, hasFeature } from '@/config/features'
import { useUserStore } from '@/stores/user'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: { ModalDialogPrompt },
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const userStore = useUserStore()

    return { userStore }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    userAccessRights () {
      return [
        {
          access: this.user.access.operations,
          tooltipMessage: this.$i18n.t('menu.operations')
        },
        {
          access: this.user.access.transfers,
          tooltipMessage: this.$i18n.t('menu.transfers')
        },
        {
          access: this.user.access.debits,
          tooltipMessage: this.$i18n.t('menu.debits')
        },
        {
          access: this.user.access.credits,
          tooltipMessage: this.$i18n.t('menu.credits')
        },
        {
          access: this.user.access.statements,
          tooltipMessage: this.$i18n.t('menu.statements')
        },
        {
          access: hasFeature(EFeature.SectionCard) && this.user.access.cards,
          tooltipMessage: this.$i18n.t('menu.cards')
        },
        {
          access: !this.user.access.cards && this.user.card_settings && this.user.card_settings.virtual.request,
          tooltipMessage: this.$i18n.t('generate_ecard.title_request')
        },
        {
          access: this.user.access.refunds,
          tooltipMessage: this.isEmployee(this.user) ? this.$i18n.t('menu.refunds') : this.$i18n.t('menu.payments')
        },
        {
          access: this.user.access.mileages,
          tooltipMessage: this.$i18n.t('menu.mileages')
        },
        {
          access: this.user.access.card_holder,
          tooltipMessage: this.$i18n.t('tooltip.card_holder')
        },
        {
          access: this.user.access.accounting,
          tooltipMessage: this.$i18n.t('menu.accounting')
        },
        {
          access: this.user.access.owner,
          tooltipMessage: this.$i18n.t('menu.users')
        }
      ].filter(userAccess => userAccess.access)
    },

    accessList () {
      return this.userAccessRights.map(x => x.tooltipMessage).join(', ')
    }
  },

  methods: {
    async onValidate () {
      this.loading = true
      const success = await this.userStore.reactivateUser(this.user.uuid)
      if (success) {
        this.$bus.emit('validate-user-reactivation')
        this.$emit('close')
      }
      this.loading = false
    },

    isEmployee (invitation) {
      return ['salarie', 'associe', 'employee', 'associate'].includes(invitation.role)
    }
  }
}
</script>
