import { Location, Route, RouteRecordNormalized, RouteRecordRaw } from 'vue-router'
import { cloneDeep, sortBy } from 'lodash'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { EFeature, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { isCardTypeDefined } from '@/helpers/utils/card'
import { formatApiFilters } from '@/helpers/utils/filter'
import { showToastSuccess } from '@/helpers/utils/notification'
import { globalTranslation } from '@/locales'
import { ITermAccountRate, ModelTermAccountRate } from '@/models/Account/ModelTermAccountRate'
import { isResponsive } from '@/router'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import {
  Account,
  EAccountType,
  isCapitalGainAccount,
  isMainAccount,
  isSubAccount,
  isTermAccount,
  TermAccount,
  TSubAccountPayload
} from '@/types/account.d'
import { ECompanyTaxType, TCompany } from '@/types/company.d'
import { Pagination } from '@/types/pagination.d'

interface State {
  account: Account | null
  loadingAccount: boolean
  accountValidation: object
  accounts: Array<Account>
  accountsPagination: Pagination
  refreshAccountsList: boolean,
  companiesList: Array<TCompany>
  companiesListPagination: Pagination
}

export const useAccountStore = defineStore('account', {
  state: (): State => {
    return {
      account: null,
      loadingAccount: false,
      accountValidation: {},
      accounts: [],
      accountsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      refreshAccountsList: true,
      companiesList: [],
      companiesListPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  getters: {
    operationFilters: state => state.account ? formatApiFilters(state.account.filters.operations) : [],
    getAccountLabel: state => state.account?.label ? `${state.account.company.name} - ${state.account.label}` : state.account?.company.name,
    displaySalaryTab: state => !!state.account?.counts?.transfers?.salaries,
    isUserEmployee: state => ['salarie', 'associe', 'employee', 'associate'].includes(state.account?.access.role || ''),
    displayMenuPreference: function () {
      if (this.isRestrictedAccount) { return false }
      return this.displaySections ? Object.keys(this.displaySections).length > 0 : false
    },
    displaySections: function (state) {
      const routes = this.router.options.routes
      return Object.entries(state.account?.display_sections || {}).reduce((acc, [key, value]) => {
        if (routes.find(r => r.meta?.menu?.name === key)) { acc[key] = value }
        return acc
      }, {} as { [key: string]: boolean })
    },
    isClassicAccount: state => state?.account ? isSubAccount(state.account) || isMainAccount(state.account) : false,
    isRestrictedAccount: state => state?.account ? (!['valid', 'closing'].includes(state.account.status_group) || ['to_validate', 'refused', 'to_validate_by_bank'].includes(state.account.invitation_status)) : false,
    isPendingAccount: state => state?.account ? (state.account.status_group === 'pending' && state.account.has_account_validation) : false,
    isDeferredAccount: state => state?.account ? state.account.is_deferred : false,
    cardPinMailer: state => (!state.account || state.account.card_pin_mailer === undefined) ? hasFeature(EFeature.CardPinMailer) : state.account.card_pin_mailer,
    physicalCardTypes: state => state?.account?.card_types?.physical.length
      ? state.account.card_types.physical.filter(type => isCardTypeDefined({ type }))
      : [],
    virtualCardTypes: state => state?.account?.card_types?.virtual.length
      ? state.account.card_types.virtual.filter(type => isCardTypeDefined({ type }))
      : []
  },

  actions: {
    async getAccountValidation (): Promise<void> {
      let accountValidation = {}

      try {
        const { data } = await axiosClient.get<object>('/account-validation')
        accountValidation = data
      } catch (error) {
        console.error(error)
      }

      this.accountValidation = accountValidation
    },

    async getAccounts (params: {search?: string} & object = {}): Promise<boolean> {
      try {
        const { data, headers } = await axiosClient.get<Array<Account>>('/accounts', { params })
        this.accountsPagination = this.parsePagination(headers)

        this.refreshAccountsList = !!params.search
        if (this.accountsPagination.current > 1) {
          this.refreshAccountsList = true
          this.accounts = Array.prototype.concat.apply(this.accounts, data)
        } else {
          this.accounts = data
        }
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getSubAccounts (page: number, company_id: number): Promise<{pagination: Pagination, data: Array<object>} | null> {
      const params = { company_id, page }
      try {
        const { data, headers } = await axiosClient.get<Array<Account>>('/accounts', { params })
        return {
          pagination: this.parsePagination(headers),
          data
        }
      } catch (error) {
        console.error(error)
        return { pagination: { current: 1, count: 1, perPage: 50, totalItems: 0 }, data: [] }
      }
    },

    async getCompaniesList (page: number): Promise<void> {
      let companiesList: Array<TCompany> = []
      try {
        const { data, headers } = await axiosClient.get<Array<TCompany>>('/companies', { params: { page } })
        this.companiesListPagination = this.parsePagination(headers)

        companiesList = data
      } catch (error) {
        console.error(error)
      }

      this.companiesList = companiesList
    },

    async getCompany (id: number): Promise<TCompany | null> {
      const company = this.companiesList?.find(o => o.id === id)
      if (company) return company

      try {
        const { data } = await axiosClient.get<TCompany>(`/companies/${id}`)
        this.companiesList.push(data)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCompanyRepresentatives (companyId: number): Promise<object | null> {
      try {
        const { data } = await axiosClient.get<Array<object>>('/representatives', { params: { companyId } })
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getTermAccountRates () : Promise<Array<ModelTermAccountRate> | []> {
      let rates:Array<ModelTermAccountRate>

      try {
        const { data } = await axiosClient.get<ITermAccountRate[]>('/term-account-rates')
        rates = data.map(item => ModelTermAccountRate.create(item))
      } catch (error) {
        console.error(error)
        rates = []
      }

      return rates
    },

    async closeAccount (account_uuid: string): Promise<Account | null> {
      try {
        const { data } = await axiosClient.put<Account>('/accounts/close', { account_uuid })
        return data
      } catch (error) {
        console.error(error)

        return null
      }
    },

    async createNewSubAccount (sub_account: TSubAccountPayload): Promise<Account | null> {
      try {
        const { data } = await axiosClient.post<Account>('/accounts', sub_account)
        bus.emit('on-display-new-account', data)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async createTermAccount (accountUUid: string, amount: number, termAccountRateUuid: string): Promise<Account | null> {
      try {
        const { data } = await axiosClient.post<Account>('/accounts', {
          amount,
          source_account_uuid: accountUUid,
          term_account_rate_uuid: termAccountRateUuid,
          type: EAccountType.Term
        })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCapitalGainStatus (): Promise<object | null> {
      try {
        const { data } = await axiosClient.get<object>('/accounts/capital-gain')
        return data
      } catch (error) {
        console.error(error)

        return null
      }
    },

    async capitalGainRequestSignature (): Promise<boolean> {
      try {
        await axiosClient.patch('/accounts/capital-gain/request-signature')
        return true
      } catch (error) {
        console.error(error)

        return false
      }
    },

    async capitalGainRequestFunds (): Promise<void> {
      try {
        await axiosClient.patch('/accounts/capital-gain/request-funds')
      } catch (error) {
        console.error(error)
      }
    },

    async getAccountDetails (uuid: string): Promise<Account | null> {
      try {
        const { data } = await axiosClient.get<Account>(`/accounts/${uuid}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async requestEarlyWithdraw (): Promise<TermAccount | null> {
      try {
        const { data } = await axiosClient.put<TermAccount>('/accounts/withdrawal')
        if (data) {
          this.setAccount(data)
        }
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async switchAccount (accountUuid: string) {
      if (accountUuid === this.account?.uuid) {
        return
      }

      this.loadingAccount = true
      try {
        const data = await this.getAccountDetails(accountUuid)
        if (data) {
          this.setAccount(data)
        }
      } catch (e) {
        console.error(e)
      }
      this.loadingAccount = false

      const route = this.getUserNextPathBasedOnPermissions(this.router.currentRoute.value, true)
      route.name !== this.router.currentRoute.value.name
        ? await this.router.push({ name: route.name || '' })
        : bus.emit('account-switched')
    },

    setAccount (account: Account): void {
      useAuthStore().resetStores(false)

      this.account = account
    },

    async getAccountControlOverrides (uuid: string): Promise<object | null> {
      try {
        const { data } = await axiosClient.get<object>(`/accounts/${uuid}/control/overrides`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getAccountControlRule (uuid: string): Promise<object | null> {
      try {
        const { data } = await axiosClient.get<object>(`/accounts/${uuid}/control`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async createAccountControlRule (uuid: string, feature: string): Promise<object | null> {
      try {
        const { data } = await axiosClient.post<object>(`/accounts/${uuid}/control`, { feature })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateAccountControlRule (uuid: string, control: object, override: boolean): Promise<boolean> {
      try {
        await axiosClient.put(`/accounts/${uuid}/control`, { ...control, override })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    getUserNextPathBasedOnPermissions (toRoute: Route, onAccountSwitch = false): Route | Location {
      const responsiveOnly = useAppStore().isDeviceMobile

      if (!this.account) {
        return { name: 'login' }
      }

      if (isTermAccount(this.account) && this.account.status === 'validating') {
        return { name: 'term-account-opening' }
      }

      if (!toRoute.matched.some((route: Route) => route.path === '/account' && !onAccountSwitch)) {
        if (this.isPendingAccount) {
          return { name: 'follow-opening' }
        }

        if (isCapitalGainAccount(this.account)) {
          return { name: 'capital-gain' }
        }

        if (this.isRestrictedAccount && !isTermAccount(this.account)) {
          return { name: 'restricted-access' }
        }
      }

      // Take last matched route with permission
      const toPermission = toRoute.matched.map(route => route.meta.permissions).filter(Boolean).pop()
      const userPermissions = [
        ...(this.account.permissions || []),
        ...(this.account.company.permissions || [])
      ]

      // User has permission to access section
      const hasSectionAccess = !['follow-opening', 'capital-gain', 'restricted-access', 'term-account-opening'].includes(toRoute.name || '')
      const hasRequiredPermission = !toPermission || hasPermission(toPermission, this.account) || hasPermission(toPermission, this.account.company)

      const isToRouteAccessible = responsiveOnly ? isResponsive(toRoute) : true

      if (hasRequiredPermission && hasSectionAccess && isToRouteAccessible) {
        return toRoute
      }

      const getRouteNames = (routeArray: RouteRecordRaw[]) => routeArray.reduce((acc, cur) => {
        acc.push(cur.name)
        if (cur.children) acc = acc.concat(getRouteNames(cur.children))
        return acc.filter(e => e)
      }, [])

      const priorityPath = toRoute.path.match(/\/([a-zA-Z-]+)/)[0]
      const routes = cloneDeep(this.router.options.routes)
      routes.unshift(...routes.splice(routes.findIndex(route => route.path.match(priorityPath)), 1))
      const routeNames = getRouteNames(routes)

      // Getting all the first level routes having a permission
      let accessRoutes = this.router.getRoutes()

      if (responsiveOnly) {
        accessRoutes = accessRoutes.filter((route: RouteRecordNormalized) => route.meta.isResponsive)
      }

      accessRoutes = sortBy(accessRoutes.filter((route: RouteRecordNormalized) => route.meta?.permissions), item => routeNames.concat(undefined).indexOf(item.name))
      // Finding the first route to be accessible with user permissions
      const firstAccessRoute = accessRoutes.find(route => {
        if (route.meta.parentPermissions?.length && !route.meta.parentPermissions.every(permissions => permissions.some((i: string) => userPermissions.indexOf(i) >= 0))) {
          return false
        }
        return route.meta.permissions.some((i: string) => userPermissions.indexOf(i) >= 0)
      })

      return firstAccessRoute || { name: 'account' }
    },

    async sendRibByEmail (email: string) {
      try {
        await axiosClient.post('/accounts/rib/send', { email })
        showToastSuccess(globalTranslation('message.success.share.rib', { email }))
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    updateExpenseRequestsWaitingValidationCount (count: number) {
      if (this.account) this.account.counts.expense_requests.waiting_validation = count
    },

    async updateAccountSettings (params: {default_card_country_holidays?: string} & object = {}) {
      try {
        const { data } = await axiosClient.put('/accounts/settings', params)
        if (this.account) this.account.settings = data
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateCompanyTaxType (tax_type: ECompanyTaxType) {
      try {
        if (this.account && this.account.company.tax_type !== tax_type) {
          const { data } = await axiosClient.put<TCompany>(`/companies/${this.account?.company.id}/tax-type`, { tax_type })
          this.account.company = data
        }
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    hasAnyCompanyPermission (company: TCompany) {
      const companyPermissions = [
        permissions.accountSubAccountCreate,
        permissions.accountCapitalGainCreate,
        permissions.accountSequestreAccountCreate,
        permissions.accountTermAccountCreate
      ]

      return companyPermissions.some(permission => hasPermission(permission, company))
    },

    async getCheckRemittanceSlipFile () {
      try {
        const { data } = await axiosClient.post('/accounts/check-remittance-slip', null, {
          responseType: 'blob'
        })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    }
  }
})
