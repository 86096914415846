<template>
  <section class="section section-accounting">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.accounting') }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div v-if="isInitialized && isAccountingCategories"
           class="section__heading__right">
        <div class="section-actions">
          <component-popover trigger="hover"
                             popover-class="popover-actions-menu popover--pull-right">
            <template #trigger>
              <button class="btn btn--icon btn--white">
                <i class="btn-icon">
                  <ic-settings class="ic ic--gray" />
                </i>
              </button>
            </template>
            <div class="popover__item"
                 @click="downloadAccountingAccount">
              <div class="popover__item__icon">
                <ic-export class="ic ic--gray" />
              </div>
              <div>{{ $t('button.export_chart_of_accounts') }}</div>
            </div>
            <div class="popover__item"
                 @click="onClickReset">
              <div class="popover__item__icon">
                <ic-reset class="ic ic--gray" />
              </div>
              <div>{{ $t('button.reset_chart_of_accounts') }}</div>
            </div>
          </component-popover>
          <button class="btn btn--primary"
                  @click="newItem">
            {{ $t('button.type_of_expenditure') }}
          </button>
        </div>
      </div>
    </div>
    <template v-if="isInitialized">
      <tabs :data="segments" />
      <router-view />
    </template>
    <accounting-profile v-else />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { useAccountStore } from '@/stores/account'
import { useAccountingStore } from '@/stores/accounting'
import { useAppStore } from '@/stores/app'

import AccountingCategoryDetail from '@/pages/accounting/sidepanel/AccountingCategoryDetail.vue'

import Confirm from '@/components/modals/Confirm.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import IcReset from '@/components/svg/icons/ic-reset.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'
import Tabs from '@/components/Tabs.vue'

import AccountingProfile from './components/AccountingProfile.vue'

export default {
  name: 'PageAccounting',

  components: {
    IcReset,
    IcSettings,
    IcExport,
    AccountingProfile,
    ComponentPopover,
    Tabs
  },

  setup () {
    const accountStore = useAccountStore()
    const accountingStore = useAccountingStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, accountingStore, appStore }
  },

  computed: {
    sections () {
      return [
        {
          label: this.$i18n.t('title.expense_control'),
          route: { name: 'expenses' },
          active: false
        },
        {
          label: this.$i18n.t('title.accounting_charts'),
          route: { name: 'accounting' },
          active: true
        }
      ]
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.your_expenses'),
          route: { name: 'accounting' }
        },
        {
          label: this.$i18n.t('button.settings'),
          route: { name: 'accounting-accounts' }
        }
      ]
    },

    isInitialized () {
      return this.account.company.company_profile_id !== null
    },

    isAccountingCategories () {
      return this.$route.name === 'accounting'
    }
  },

  mounted () {
    this.$bus.on('confirm-reset-accounting-account', this.resetAccountingAccount)
  },

  beforeUnmount () {
    this.$bus.off('confirm-reset-accounting-account', this.resetAccountingAccount)
  },

  methods: {
    newItem () {
      this.appStore.showSidePanel(
        AccountingCategoryDetail,
        { model: {} },
        { wrapperClass: 'sidepanel--md sidepanel-accounting', backgroundOverlay: true, closeButton: false }
      )
    },

    downloadAccountingAccount () {
      downloadFileFromUrl('/companies/accounting/categories/export')
    },

    async resetAccountingAccount () {
      const success = await this.accountingStore.delete()
      if (success) {
        this.account.company.company_profile_id = null
      }
    },

    onClickReset () {
      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.t('prompt.accounting.reset.title'),
          content: this.$i18n.t('prompt.accounting.reset.content'),
          event: 'reset-accounting-account'
        },
        { wrapperClass: 'modal--xs' }
      )
    }
  }
}
</script>
