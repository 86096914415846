<template>
  <div class="bordered-box"
       :class="classSelectors">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: ''
    }
  },

  computed: {
    classSelectors () {
      return this.theme ? `bordered-box--${this.theme}` : ''
    }
  }
}
</script>
