<template>
  <svg viewBox="0 0 24 24">
    <path d="M21.75 15v4a2.75 2.75 0 0 1-2.582 2.745L19 21.75h-4v-1.5h4a1.25 1.25 0 0 0 1.244-1.122L20.25 19v-4zm-18 0v4c0 .647.492 1.18 1.122 1.244L5 20.25h4v1.5H5a2.75 2.75 0 0 1-2.745-2.582L2.25 19v-4zm9.45-.75a.3.3 0 0 1 .3.3v.9a.3.3 0 0 1-.3.3H7.3a.3.3 0 0 1-.3-.3v-.9a.3.3 0 0 1 .3-.3zm3.5-3a.3.3 0 0 1 .3.3v.9a.3.3 0 0 1-.3.3H7.3a.3.3 0 0 1-.3-.3v-.9a.3.3 0 0 1 .3-.3zm-2-3a.3.3 0 0 1 .3.3v.9a.3.3 0 0 1-.3.3H7.3a.3.3 0 0 1-.3-.3v-.9a.3.3 0 0 1 .3-.3zm-5.7-6v1.5H5a1.25 1.25 0 0 0-1.244 1.122L3.75 5v4h-1.5V5a2.75 2.75 0 0 1 2.582-2.745L5 2.25zm10 0a2.75 2.75 0 0 1 2.745 2.582L21.75 5v4h-1.5V5a1.25 1.25 0 0 0-1.122-1.244L19 3.75h-4v-1.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
