<template>
  <div class="section-creditcard-details">
    <template v-if="warnings.length">
      <component-alert v-for="(warning, index) in warnings"
                       :key="index"
                       :type="warning.type"
                       :message="warning.message">
        <div class="alert__actions">
          <component-button v-for="(btn, btnIdx) in warning.buttons"
                            :key="`card-btn-alert-${btnIdx}`"
                            :class="`btn btn--xs ${btn.class}`"
                            :label="btn.label"
                            :show-loader="false"
                            :data-cy="btn.dataCy"
                            @click="btn.action" />
        </div>
      </component-alert>
    </template>
    <div class="card-summary bordered-box">
      <div class="card-summary__left">
        <div class="card-summary__left__wrapper">
          <card-item :show-card-label="false"
                     :card="card"
                     :native-click="false"
                     :clickable="isCardActive"
                     @click.prevent.stop="showCardInformations" />
        </div>
        <div class="card-balance-buttons">
          <template v-if="hasCardLockFeature">
            <component-button v-if="isLockedPartner"
                              v-tooltip="{ content: $t('poptip.unlock_card'), theme: 'poptip' }"
                              :label="toggleButtonLockLabel"
                              :wrapper-class="toggleButtonLockClass"
                              icon
                              disabled
                              @click="handleLockButton">
              <ic-cadenas-outline :unlock="isLocked"
                                  class="ic"
                                  :class="[isLocked ? 'ic--white' : 'ic--gray']" />
            </component-button>
            <component-button v-else
                              :label="toggleButtonLockLabel"
                              :wrapper-class="toggleButtonLockClass"
                              icon
                              :disabled="isToggleButtonLockDisabled"
                              data-cy="cards.details.lock"
                              :show-loader="false"
                              @click="handleLockButton">
              <ic-cadenas-outline :unlock="isLocked"
                                  class="ic"
                                  :class="[isLocked ? 'ic--white' : 'ic--gray']" />
            </component-button>
          </template>
          <component-button v-if="isCardActive && canSeeCardDigits"
                            data-cy="cards.details.virtual-card-secret-information.trigger"
                            icon
                            wrapper-class="btn--outline"
                            :label="$t('button.display_informations')"
                            :show-loader="false"
                            @click="showCardInformations">
            <ic-view class="ic ic--gray" />
          </component-button>
        </div>
      </div>
      <div class="card-summary__right">
        <card-track-shipment v-if="isCardToActivate"
                             :card="card" />
        <template v-else>
          <component-dropdown v-if="isCardClassic"
                              :can-add-item="false"
                              :dropdown-height="40"
                              :dropdown-position="'center'"
                              :search="false"
                              :values="limitPeriods"
                              label="expenses"
                              @select="onLimitPeriodSelected">
            <template #trigger>
              <div class="dropdown">
                <button class="dropdown-toggle">
                  <span class="dropdown-toggle-value">{{ $t(`cards_settings.expenses.${activePeriod}`) }}</span>
                </button>
              </div>
            </template>
          </component-dropdown>
          <span v-else-if="isRecurringCard"
                class="dropdown-toggle-value">{{ $t(`cards_settings.expenses.${activePeriod}`) }}</span>
          <span v-else
                class="dropdown-toggle-value">{{ $t('cards_settings.expenses.general') }}</span>
          <div class="card-summary__right__wrapper">
            <amount-consumed :amount="paymentConsumed"
                             :display-limit="displayLimit"
                             :enabled="isPaymentEnabled"
                             :limit="paymentLimit">
              {{ $t('general.payment') }}
            </amount-consumed>
            <amount-consumed v-if="isWithdrawalVisible"
                             :amount="withdrawalConsumed"
                             :display-limit="displayLimit"
                             :enabled="isWithdrawalEnabled"
                             :limit="withdrawalLimit"
                             data-cy="cards.details.withdrawal">
              {{ $t('general.withdrawal') }}
            </amount-consumed>
          </div>
          <div class="card-balance-buttons card-balance-buttons--limit">
            <button v-if="hasCardRequestIncreaseFeature || (isPaymentEnabled || isWithdrawalEnabled)"
                    class="btn btn--outline"
                    @click="handleLimitButton">
              <i class="btn-icon btn-icon--left">
                <ic-meter class="ic ic--20 ic--gray" />
              </i>
              {{ hasCardRequestIncreaseFeature ? $t('button.request_limit_change') : $t('button.set_limits') }}
            </button>
            <button v-if="hasWriteCardFeature && isCardCapped"
                    v-tooltip="{ content: $t('tooltip.reset_available_amount'), theme: 'tooltip' }"
                    class="btn btn--icon btn--outline"
                    @click="onClickResetLimit">
              <i class="btn-icon">
                <ic-reset class="ic ic--gray" />
              </i>
            </button>
          </div>
        </template>
      </div>
    </div>
    <card-web-wallet v-if="hasFeature(EFeature.CardWebWallet, card)"
                     data-cy="card.wallet"
                     :card-uuid="card.uuid" />
    <card-expenses v-if="card.display_expenses"
                   :card-uuid="card.uuid"
                   :period="activePeriod" />
    <card-detail-actions :card="card" />
    <div v-if="showRecentActivity"
         class="recent-activity-block">
      <h3 class="recent-activity-block__title">
        {{ $t('card.general.recent_activity') }}
      </h3>
      <div class="bordered-box bordered-box--padding">
        <table class="table">
          <tbody>
            <tr v-for="(item, index) in recentActivity"
                :key="index"
                :class="{ 'text-muted': isOperationRejected(item) }">
              <td class="text-muted col--sm-1 col-nowrap">
                {{ formatDate(item.created_at) }}
              </td>
              <td class="col--sm">
                <core-badge v-if="isOperationRejected(item)"
                            :theme="ECoreBadgeTheme.Danger"
                            :fill="ECoreBadgeFill.Shaded"
                            :value="$t('general.rejected')"
                            class="mr-1">
                  <template #default="{ value }">
                    <TooltipPoptip :value="value"
                                   :title="$t('popover.operation.rejected.title')"
                                   :message="`${$t('popover.operation.rejected.message')}<br>${item.reject_reason}`" />
                  </template>
                </core-badge>
                {{ item.label }}
              </td>
              <td class="col--sm-2 right col-nowrap">
                <span :class="{ 'text-through': isOperationRejected(item) }"
                      class="amount">
                  {{ formatAmount(item.amount, item.currenccy, { trailingZeroDisplay: 'stripIfInteger', isNegative: item.type === 'debit' }) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import {
  isCappedVirtualCard,
  isClassicCard,
  isClassicVirtualCard,
  isRecurringVirtualCard,
  isVirtualCard
} from '@/helpers/utils/card'
import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ECardProcessor } from '@/types/card.d'

import AmountConsumed from '@/pages/cards/components/AmountConsumed.vue'
import CardItem from '@/pages/cards/components/CardItem.vue'
import CardWebWallet from '@/pages/cards/details/components/CardWebWallet.vue'
import CardSecretInformations from '@/pages/cards/modal/CardSecretInformations.vue'
import LockedCardProofRequired from '@/pages/cards/modal/LockedCardProofRequired.vue'
import RequestEditLimit from '@/pages/cards/modal/RequestEditLimit.vue'
import EditShippingAddress from '@/pages/cards/sidepanel/EditShippingAddress.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import Confirm from '@/components/modals/Confirm.vue'
import IcCadenasOutline from '@/components/svg/icons/ic-cadenas-outline.vue'
import IcMeter from '@/components/svg/icons/ic-meter.vue'
import IcReset from '@/components/svg/icons/ic-reset.vue'
import IcView from '@/components/svg/icons/ic-view.vue'
import TooltipPoptip from '@/components/TooltipPoptip.vue'

import CardDetailActions from './components/CardDetailActions.vue'
import CardExpenses from './components/CardExpenses.vue'
import CardTrackShipment from './components/CardTrackShipment.vue'

export default {
  name: 'CardGeneral',

  components: {
    CardWebWallet,
    AmountConsumed,
    TooltipPoptip,
    CoreBadge,
    IcCadenasOutline,
    IcReset,
    IcMeter,
    IcView,
    CardItem,
    CardDetailActions,
    ComponentAlert,
    ComponentButton,
    ComponentDropdown,
    CardExpenses,
    CardTrackShipment
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  emits: ['card-update', 'cancel-deletion-success', 'cancel-renewal-success'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore, cardStore }
  },

  data () {
    const activePeriod = (
      this.card.settings.payment &&
      Object.entries(this.card.settings.payment)
        .filter(item => item[1].enabled)
        .sort((a, b) => a[1].limit - b[1].limit)[0]?.[0]
    ) || 'monthly'
    return {
      activePeriod,
      recentActivity: []
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    deletedSoon () {
      return this.card.automatic_deletion && this.card.automatic_deletion_soon
    },

    showRecentActivity () {
      return hasFeature(EFeature.OperationCard) && this.recentActivity.length
    },

    warnings () {
      const warnings = []

      if (this.renewalSoon) {
        const buttons = this.hasWriteCardFeature
          ? [
              /* Adresse de livraison fixe, TODO: permission édition de l'adresse de livraison
              {
                label: this.$t('button.card.edit_address'),
                action: this.editShippingAddress,
                class: 'btn--info',
                dataCy: 'cards.details.edit-delivery-address'
              },
              */
              {
                label: this.$t('prompt.cards.cancel_automatic_renewal.title'),
                action: this.cancelRenewal,
                class: 'btn--outline-info',
                dataCy: 'cards.details.cancel-renewal'
              }
            ]
          : []

        warnings.push({
          type: 'info',
          message: this.$tc('message.warning.card.renewal_soon', this.card.expire_in),
          buttons
        })
      } else if (this.expireSoon) {
        warnings.push({
          type: 'info',
          message: this.$tc('message.warning.card.expire_soon', this.card.expire_in),
          buttons: []
        })
      }

      if (this.deletedSoon) {
        const buttons = this.hasCardCancelAutomaticDeletionFeature
          ? [
              {
                label: this.$t('button.automatic_deletion.cancel'),
                action: this.cancelDeletion,
                class: 'btn--info'
              }
            ]
          : []

        warnings.push({
          type: 'info',
          message: this.$tc('message.warning.card.deleted_soon', this.card.automatic_deletion_in),
          buttons
        })
      }

      if (this.isLockedPartner) {
        warnings.push({
          type: 'warning',
          message: this.$t('message.warning.card.locked_partner'),
          buttons: []
        })
      } else if (this.isToggleButtonLockDisabled) {
        warnings.push({
          type: 'warning',
          message: this.$t('message.warning.card.locked_service'),
          buttons: []
        })
      } else if (this.cardHasProofsToJustify) {
        warnings.push({
          type: 'warning',
          message: this.$t('alerts.card_blocked.proof_required.warning'),
          buttons: [
            {
              label: this.$t('button.display'),
              action: () => this.$router.push({ name: 'operations-to-justify', params: { uuid: this.card.uuid } }),
              class: 'btn--warning'
            }
          ]
        })
      } else if (this.isLockedProof) {
        warnings.push({
          type: 'error',
          message: this.$t('alerts.card_blocked.proof_required.error'),
          buttons: [
            {
              label: this.$t('button.display'),
              action: () => this.$router.push({ name: 'operations-to-justify', params: { uuid: this.card.uuid } }),
              class: 'btn--error'
            }
          ]
        })
      }

      return warnings
    },

    renewalSoon () {
      return this.card.settings.automatic_renewal && this.card.expire_soon
    },

    expireSoon () {
      return this.card.expire_soon
    },

    isCardToActivate () {
      return !this.isActive
    },

    isActive () {
      return this.card.status === 'active' || this.isLocked
    },

    isLocked () {
      return this.card.status === 'locked' || this.isLockedProof || this.isLockedService || this.isLockedPartner
    },

    cardHasProofsToJustify () {
      return this.card.settings.proof_required && this.card.proof_required_before
    },

    isLockedProof () {
      return this.card.status === 'locked_proof'
    },

    isLockedPartner () {
      return this.card.status === 'locked_partner'
    },

    isLockedService () {
      return this.card.status === 'locked_service'
    },

    isToggleButtonLockDisabled () {
      return this.isLockedService && !this.hasWriteCardFeature
    },

    isVirtualCardClassic () {
      return isClassicVirtualCard(this.card)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.card)
    },

    isCardCapped () {
      return isCappedVirtualCard(this.card)
    },

    toggleButtonLockClass () {
      return this.isLocked ? 'btn--primary' : 'btn--outline'
    },

    toggleButtonLockLabel () {
      return this.isLocked
        ? this.$i18n.t('button.unblock')
        : this.$i18n.t('button.temporary_block')
    },

    paymentConsumed () {
      return this.isCardClassic || this.isRecurringCard
        ? this.card.settings.payment[this.activePeriod].consumed
        : this.card.total_consumed
    },

    withdrawalConsumed () {
      return !this.isVirtualCard
        ? this.card.settings.withdrawal[this.activePeriod].consumed
        : false
    },

    isPaymentEnabled () {
      return this.isCardClassic
        ? this.card.settings.payment?.[this.activePeriod]?.enabled
        : true
    },

    isWithdrawalEnabled () {
      return this.isVirtualCard
        ? false
        : this.card.settings.withdrawal?.[this.activePeriod]?.enabled
    },

    isWithdrawalCategoryEnabled () {
      return this.card.settings.authorization_categories.find(category => category.name === 'retrait')?.enabled
    },

    paymentLimit () {
      return this.isCardClassic || this.isRecurringCard
        ? this.card.settings.payment[this.activePeriod].limit
        : this.card.settings.initial_amount
    },

    withdrawalLimit () {
      return !this.isVirtualCard
        ? this.card.settings.withdrawal[this.activePeriod].limit
        : false
    },

    displayLimit () {
      return this.isCardClassic
        ? this.hasWriteCardFeature || this.card.settings.display_limit
        : true
    },

    isWithdrawalVisible () {
      if (this.withdrawalConsumed) return true

      if (!this.isWithdrawalCategoryEnabled) return false

      return this.isWithdrawalEnabled || hasFeature(EFeature.CardDetailWithdrawalAlwaysVisible)
    },

    isCardClassic () {
      return isClassicCard(this.card)
    },

    limitPeriods () {
      const periods = []
      for (const key in this.card.settings.payment) {
        if (Object.prototype.hasOwnProperty.call(this.card.settings.payment, key)) {
          periods.push({
            periodType: key,
            label: this.$i18n.t(`cards_settings.expenses.${key}`)
          })
        }
      }
      return periods
    },

    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    canSeeCardDigits () {
      return this.hasPermission(permissions.cardsDigitsRead, this.card)
    },

    isCardActive () {
      return this.card.active
    },

    hasWriteCardFeature () {
      return this.isVirtualCard ? hasFeature(EFeature.CardVirtualEdition) : hasFeature(EFeature.CardPhysicalEdition)
    },

    hasCardCancelAutomaticDeletionFeature () {
      return hasFeature(EFeature.CardCancelAutomaticDeletion, this.card)
    },

    hasCardRequestIncreaseFeature () {
      return hasFeature(EFeature.CardRequestIncrease, this.card)
    },

    hasCardLockFeature () {
      return hasFeature(EFeature.CardLock, this.card)
    }
  },

  async created () {
    if (!this.isVirtualCard) { return }

    if (!this.isOneTimeCard && hasFeature(EFeature.OperationCard)) {
      this.recentActivity = await this.cardStore.getCardLastExpenses(this.card.uuid)
    }

    if (this.isRecurringCard) {
      for (const period in this.card.settings.payment) {
        if (this.card.settings.payment[period].enabled) {
          this.activePeriod = period
        }
      }
    }
  },

  mounted () {
    this.$bus.on('confirm-reset-consumed-amount', this.onResetConsumedAmount)
  },

  beforeUnmount () {
    this.$bus.off('confirm-reset-consumed-amount', this.onResetConsumedAmount)
  },

  methods: {
    hasFeature,
    formatAmount,

    formatDate,

    async cancelDeletion () {
      const success = await this.cardStore.cancelAutomaticDeletion(this.card.uuid)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.cancel_automatic_deletion'))
        this.$emit('cancel-deletion-success')
      }
    },

    async cancelRenewal () {
      const success = await this.cardStore.cancelAutomaticRenewal(this.card.uuid)
      if (success) {
        showToastSuccess(this.$i18n.t('message.success.cancel_automatic_renewal'))
        this.$emit('cancel-renewal-success')
      }
    },

    editShippingAddress () {
      this.appStore.showSidePanel(
        EditShippingAddress,
        { model: this.card, updateAddress: this.updateAddress },
        { wrapperClass: 'sidepanel--xl', backgroundOverlay: true }
      )
    },

    async onToggleLockCard () {
      const card = await this.cardStore.toggleLockCard(this.card)
      if (!card) { return }

      if (card.status === 'locked') {
        showToastSuccess(this.$i18n.t('message.success.card.lock'))
      } else if (card.status === 'active') {
        showToastSuccess(this.$i18n.t('message.success.card.unlock'))
      }

      this.$emit('card-update', card)
    },

    handleLockButton () {
      this.isLockedProof ? this.showUnlockCardProof() : this.onToggleLockCard()
    },

    showUnlockCardProof () {
      this.appStore.showModal(LockedCardProofRequired, { uuid: this.card.uuid }, { wrapperClass: 'modal--xs' })
    },

    handleLimitButton () {
      this.hasCardRequestIncreaseFeature
        ? this.requestEditLimit()
        : this.$router.push({ name: 'cards-detail-settings' })
    },

    requestEditLimit () {
      this.appStore.showModal(RequestEditLimit, { uuid: this.card.uuid }, { wrapperClass: 'modal--xs' })
    },

    onLimitPeriodSelected (period) {
      this.activePeriod = period.periodType
    },

    async showCardInformations () {
      if (!this.isCardActive || !this.canSeeCardDigits) return

      let secretInformations = null
      let token = null
      if (this.card.subtype === ECardProcessor.WORLDLINE) {
        secretInformations = await this.cardStore.getWorldlineCardSecretInformations(this.card.uuid)
      } else if (this.card.subtype === ECardProcessor.MARQETA) {
        token = await this.cardStore.getClientAccessToken(this.card.uuid, 'digits')
      }
      if (token || secretInformations) {
        this.appStore.showModal(
          CardSecretInformations,
          { card: this.card, token, secretInformations },
          { wrapperClass: 'modal--xs modal-card-secret-informations' }
        )
      }
    },

    onClickResetLimit () {
      this.appStore.showModal(
        Confirm,
        {
          title: this.$i18n.t('prompt.ecards.reset_available_amount.title'),
          content: this.$i18n.t('prompt.ecards.reset_available_amount.content'),
          event: 'reset-consumed-amount'
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    async onResetConsumedAmount () {
      const card = await this.cardStore.resetConsumedAmount(this.card.uuid)
      if (!card) { return }

      showToastSuccess(this.$t('message.success.ecard.reset_available_amount'))

      this.$emit('card-update', card)
    },

    isOperationRejected (operation) {
      return operation.status === 'rejected'
    },

    updateAddress (address) {
      this.$emit('card-update', { ...this.card, ...{ address } })
    }
  }
}
</script>

<style scoped lang="stylus">
.card-summary__right__wrapper
  flex-direction row
  margin-top 4rem
  padding 0 5rem
</style>
