<template>
  <div class="fs-page c-wrapper center">
    <div class="logo-page-topleft">
      <logo-bank-inline class="logo-mone-icon" />
    </div>
    <div class="fs-page__content c-rel notsupported-msg">
      <h1 class="page-title">
        {{ $t("pages.notsupported.title") }}
      </h1>
      <h2 class="page-subtitle">
        {{ $t("pages.notsupported.subtitle") }}
      </h2>
      <p class="page-msg">
        {{ $t("pages.notsupported.content") }}
      </p>
      <ul class="browser-list">
        <li>
          <a href="https://www.google.com/chrome/browser/desktop/"
             target="_blank">Chrome 45 +</a>
        </li>
        <li>
          <a href="https://www.mozilla.org/firefox/new/"
             target="_blank">Firefox 36 +</a>
        </li>
        <li>
          <a href="https://www.apple.com/safari/"
             target="_blank">Safari 9 +</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'

export default {
  components: {
    LogoBankInline
  }
}
</script>
