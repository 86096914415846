<template>
  <svg viewBox="0 0 48 71">
    <g fill="#7a818c"
       opacity=".7">
      <path d="M44.645.826H3.355C2.118.82.837 2.028.828 3.302V7.43h46.344V3.302c-.009-1.274-1.29-2.48-2.527-2.476z"
            fill-opacity=".25" />
      <path d="M1.241 7.843v58.724a2.802 2.802 0 0 0 2.814 2.782h36.29v-.62a5.771 5.771 0 0 1 1.55-4.147 5.8 5.8 0 0 1 4.036-1.838h.828V7.843zm0-1.238H46.76V4.02a2.802 2.802 0 0 0-2.814-2.783H4.055A2.802 2.802 0 0 0 1.241 4.02zM43.945 0A4.042 4.042 0 0 1 48 4.02v59.764c0 .16-.062.314-.174.43l-6 6.192a.621.621 0 0 1-.447.19H4.055A4.042 4.042 0 0 1 0 66.574V4.02A4.042 4.042 0 0 1 4.055 0zm-2.359 68.862 4.759-4.88h-.414a4.62 4.62 0 0 0-4.345 4.748zM31.074 31.047c3.106 0 5.63 2.559 5.63 5.701 0 3.145-2.524 5.705-5.63 5.705h-1.107a.636.636 0 0 1-.634-.639c0-.352.282-.638.634-.638h1.107c2.403 0 4.36-1.986 4.36-4.428 0-2.44-1.957-4.423-4.36-4.423-.232 0-.426.013-.595.04a.63.63 0 0 1-.677-.367c-1.03-2.288-3.295-3.765-5.77-3.765-3.497 0-6.344 2.888-6.344 6.44 0 .117.003.233.01.354a.64.64 0 0 1-.282.571.629.629 0 0 1-.64.037 2.862 2.862 0 0 0-1.322-.325c-1.591 0-2.888 1.316-2.888 2.935 0 .783.3 1.519.847 2.073.545.553 1.27.857 2.041.857h2.58c.35 0 .634.287.634.639a.636.636 0 0 1-.635.639h-2.579a4.106 4.106 0 0 1-2.942-1.236 4.209 4.209 0 0 1-1.215-2.972c0-2.322 1.863-4.212 4.157-4.212.334 0 .662.04.982.119.265-4.018 3.575-7.197 7.596-7.197 2.815 0 5.409 1.59 6.724 4.1.102-.005.208-.008.318-.008zM24.43 35.73l3.056 2.816a.641.641 0 0 1 .044.898.63.63 0 0 1-.9.044l-1.996-1.838v9.884c0 .352-.283.639-.634.639s-.634-.287-.634-.639V37.65l-1.996 1.838a.631.631 0 0 1-.9-.044.641.641 0 0 1 .043-.898l3.057-2.816c.06-.045.064-.05.085-.06a.412.412 0 0 1 .082-.05l.042-.017a.44.44 0 0 1 .09-.022c.042-.01.048-.012.079-.015a.3.3 0 0 1 .114.002.19.19 0 0 1 .047.008.605.605 0 0 1 .15.043.395.395 0 0 1 .083.05c.024.012.03.017.088.061z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ImageUpload'
}
</script>
