import { createI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import config from '@/config/config'
import store from '@/config/store'

type TTranslationMessages = Record<string, string>

const importLanguages = import.meta.glob('./**/*.yml', { eager: true }) as Record<string, { default: TTranslationMessages }>
const localStorageKeyLang = 'mone-client-locales'

export const translatedLanguages = config.TRANSLATIONS.AVAILABLE_LANGUAGES
export type TLanguage = typeof translatedLanguages[number]

const languages: Record<string, TTranslationMessages> = {}

for (const path in importLanguages) {
  const match = path.match(/\.\/([^/]+)\/[^/]+\.yml/)
  if (match) {
    const lang = match[1]
    if (translatedLanguages.includes(lang)) {
      languages[lang] = (importLanguages[path]).default
    }
  }
}

// initialization
const langArray = (navigator.languages?.[0] || navigator.language).split('-')
let defaultLocale = langArray[0]
if (navigator.cookieEnabled) {
  const storedLanguage = window.localStorage.getItem(localStorageKeyLang)

  if (storedLanguage) {
    defaultLocale = storedLanguage
  }
}

export const localeFallback = (locale: string): string => {
  locale = translatedLanguages.includes(locale) ? locale : translatedLanguages[0]
  return locale
}

export const switchLocale = (locale: string) => {
  locale = localeFallback(locale)

  store.translations = languages[locale]
  dayjs.locale(locale)

  window.localStorage.setItem(localStorageKeyLang, locale)
  document.documentElement.lang = locale
}

switchLocale(defaultLocale)

export const i18n = createI18n({
  allowComposition: true,
  globalInjection: true,
  locale: localeFallback(defaultLocale),
  messages: languages,
  pluralizationRules: {
    fr: function (choice, choicesLength) {
      if (choicesLength === 2) {
        return choice > 1 ? 1 : 0
      }
      return choice ? Math.min(choice, 2) : 0
    },
    de: function (choice, choicesLength) {
      if (choicesLength === 2) {
        return choice > 1 ? 1 : 0
      }
      return choice ? Math.min(choice, 2) : 0
    }
  },
  silentTranslationWarn: true,
  missing: (locale, key, vm) => {
    let route = ''
    if (vm && typeof vm.appContext?.app?.config?.globalProperties?.$route?.path !== 'undefined') {
      route = ` - route: ${vm.appContext.app.config.globalProperties.$route.path}`
    }

    console.error(`missing translation: ${key} - locale: ${locale}${route}`)
  }
})

export default i18n
export const globalTranslation = i18n.global.t
