<template>
  <AppleWallet :data-cy="`card.wallet-${ECardWalletProvider.APPLE}`"
               :locale="i18n.global.locale"
               @click="enroll" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive } from 'vue'

import i18n from '@/locales'
import { useCardStore } from '@/stores/card'
import {
  ECardWalletEmitType, ECardWalletErrorCode, ECardWalletProvider,
  TCardWalletApplePopupMessage,
  TCardWalletAppleResolver
} from '@/types/card.d'
import { Nullable } from '@/types/default.d'

import AppleWallet from '@/components/svg/img/apple-wallet.vue'

export interface IComputed {
  formSubmitted: boolean
}

interface IAppleProvisioningConfig {
  id: number
  resultDelivered: boolean
  popup?: Nullable<Window>
  isPopupReady: boolean
}

enum EAppleEventMessages {
  READY = 'ready',
  JWT_GENERATION_SUCCESSFUL = 'jwtGenerationSuccessful',
  DELIVER_RESULT = 'deliverResult'
}

const cardStore = useCardStore()
const APPLE_DOMAIN = import.meta.env.VITE_WEB_WALLET_APPLE_DOMAIN
const APPLE_PARTNER_ID = import.meta.env.VITE_WEB_WALLET_APPLE_PARTNER_ID

const config: IAppleProvisioningConfig = {
  id: 0,
  resultDelivered: false,
  isPopupReady: false
}

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'message', code: string | number): void,
  (e: 'handleBlur'): void
}>()

const props = defineProps({
  cardUuid: {
    type: String,
    required: true
  }
})

const computed: IComputed = reactive({
  formSubmitted: false
})

onMounted(() => {
  window.addEventListener('message', onMessage)
})

onUnmounted(() => {
  window.removeEventListener('message', onMessage)
})

const onMessage = async (event: MessageEvent) => {
  const payload = event as TCardWalletApplePopupMessage

  if (APPLE_DOMAIN !== payload.origin) {
    return console.warn(`invalid domain ${payload.origin}`)
  }

  if (EAppleEventMessages.READY === payload.data.message) {
    config.isPopupReady = true
    payload.source.postMessage({
      message: EAppleEventMessages.JWT_GENERATION_SUCCESSFUL,
      timestamp: config.id,
      jwt: await getJWT()
    }, APPLE_DOMAIN)
  } else if (EAppleEventMessages.DELIVER_RESULT === payload.data.message) {
    resultResolver(payload.data.result)
  }
}

const enroll = async () => {
  if (computed.formSubmitted) {
    console.warn('invalid formSubmitted')
    return
  }

  launchPopup()
  config.id++
  config.resultDelivered = false
  computed.formSubmitted = true
}

const getJWT = async () => {
  try {
    // SCA is not applicable because calls are made outside the console window
    const response = await cardStore.initWallet(props.cardUuid, { provider: ECardWalletProvider.APPLE })

    if (response) {
      return response
    }
  } catch (e) {
    console.error(e)
  }

  computed.formSubmitted = false
}

const createPopup = (): Window | null => {
  const width = 600
  const height = 660
  const left = window.outerWidth / 2 + window.screenLeft - width / 2
  const top = window.outerHeight / 2 + window.screenTop - height / 2

  return window.open(
      `${APPLE_DOMAIN}/navweb/static/index.html?partnerId=${APPLE_PARTNER_ID}&cardType=PAYMENT&id=${config.id}`,
      'Add to Wallet',
      `scrollbars,width=${width},height=${height},top=${top},left=${left}`
  )
}

const launchPopup = () => {
  config.popup = createPopup()

  if (!config.popup) {
    emitMessage(ECardWalletErrorCode.POPUP_BLOCKED)
  } else {
    emit(ECardWalletEmitType.HANDLE_BLUR)

    const checkPopupStatus = (): void => {
      if (config.popup && !config.popup.closed) {
        setTimeout(checkPopupStatus, 1e3)

        return
      }

      if (!config.resultDelivered) {
        return resultResolver({
          statusMessage: 'User closed window before provisioning was completed',
          status: ECardWalletErrorCode.ABORT_BY_USER
        })
      }
    }
    checkPopupStatus()
  }
}

const resultResolver = (result: TCardWalletAppleResolver) => {
  if (!config.isPopupReady) {
    console.warn('Result received before ready event, ignoring.')
    return
  }
  if (config.resultDelivered) return

  const status = parseInt(result.status)

  if (result.status === ECardWalletErrorCode.ABORT_BY_USER && config.popup && !config.popup.closed) return

  emitMessage(status ?? 0)

  if (status >= 300) {
    console.error(result.statusMessage)
  }
}

const emitMessage = (code: ECardWalletErrorCode) => {
  computed.formSubmitted = false
  config.resultDelivered = true
  emit(ECardWalletEmitType.MESSAGE, code)
}
</script>

<style scoped lang="stylus">
.apple-widget--hidden-btn
  visibility 0
  height 0
  width 0
</style>
