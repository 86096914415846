<template>
  <svg viewBox="0 0 24 24">
    <path v-if="unlock"
          d="M12 1.505c2.872 0 5.09 2.285 5.09 5.09h-1.608c0-1.928-1.509-3.483-3.482-3.483S8.518 4.667 8.518 6.595l-.001 2.618H6.91V6.594c0-2.732 2.106-4.971 4.87-5.084Z" />
    <path v-else
          d="M12 3c2.872 0 5.09 2.285 5.09 5.09l-.001 1.124h-1.607V8.09c0-1.863-1.41-3.378-3.286-3.477-2.169-.006-3.678 1.549-3.678 3.476v1.125H6.911V8.089c-.001-2.731 2.105-4.97 4.869-5.083Z" />
    <path d="M6.65 9.214h10.701c1.187 0 2.149.965 2.149 2.135v7.517A2.141 2.141 0 0 1 17.35 21H6.65a2.147 2.147 0 0 1-2.15-2.134v-7.517c0-1.18.961-2.135 2.15-2.135zm10.7 1.607H6.65a.534.534 0 0 0-.543.528v7.517a.54.54 0 0 0 .542.527h10.702a.534.534 0 0 0 .542-.527v-7.517a.54.54 0 0 0-.542-.528z" />
  </svg>
</template>

<script>
export default {
  props: {
    unlock: Boolean
  }
}
</script>
