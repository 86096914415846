import { ECoreCardIconTheme } from '@common/core-ui'

import { globalTranslation } from '@/locales'
import { ECappedVirtualCard, EClassicVirtualCard, EOneTimeVirtualCard, EPhysicalCard, ERecurringVirtualCard, TCardType } from '@/types/card.d'

export type TCard = {
  type: TCardType
}

export function isPhysicalCard (card: TCard): boolean {
  return (Object.values(EPhysicalCard) as string[]).includes(card.type)
}

export function isClassicVirtualCard (card: TCard): boolean {
  return (Object.values(EClassicVirtualCard) as string[]).includes(card.type)
}
export function isOneTimeVirtualCard (card: TCard): boolean {
  return (Object.values(EOneTimeVirtualCard) as string[]).includes(card.type)
}
export function isRecurringVirtualCard (card: TCard): boolean {
  return (Object.values(ERecurringVirtualCard) as string[]).includes(card.type)
}
export function isCappedVirtualCard (card: TCard): boolean {
  return (Object.values(ECappedVirtualCard) as string[]).includes(card.type)
}

export function isClassicCard (card: TCard): boolean {
  return isPhysicalCard(card) || isClassicVirtualCard(card)
}

export function isVirtualCard (card: TCard): boolean {
  return isClassicVirtualCard(card) || isOneTimeVirtualCard(card) || isRecurringVirtualCard(card) || isCappedVirtualCard(card)
}

export function isCardTypeDefined (card: TCard): boolean {
  return isPhysicalCard(card) || isVirtualCard(card)
}

export function getCardBadgeLabel (card: TCard): string {
  if (card.type === EOneTimeVirtualCard.ONE_TIME_VIRTUAL_CARD_CONSUMER) return globalTranslation('general.unique_consumer')
  if (isCappedVirtualCard(card)) return globalTranslation('general.capped')
  if (isOneTimeVirtualCard(card)) return globalTranslation('general.unique')
  if (isRecurringVirtualCard(card)) return globalTranslation('general.periodic_f')
  if (isClassicVirtualCard(card)) return globalTranslation('general.classic')
  return ''
}

export function getCardTypeLabel (card: TCard): string {
  return globalTranslation(`general.card.${card.type}`)
}

export function getBadgeVisaTheme (card: TCard): ECoreCardIconTheme {
  if (card.type === EPhysicalCard.BIOMETRIC_CARD) return ECoreCardIconTheme.Biometric
  if (card.type as string === 'gold') return ECoreCardIconTheme.Gold
  if (isPhysicalCard(card)) return ECoreCardIconTheme.Physical
  return ECoreCardIconTheme.Virtual
}

export function getImageCardIconName (card: TCard): string {
  if (isCappedVirtualCard(card)) return 'capped-virtual-card'
  if (isOneTimeVirtualCard(card)) return 'one-time-virtual-card'
  if (isRecurringVirtualCard(card)) return 'recurring-virtual-card'
  if (isClassicVirtualCard(card)) return 'virtual-card'
  return ''
}
