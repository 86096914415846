<template>
  <svg viewBox="0 0 24 24">
    <path d="M18 3a2 2 0 0 1 1.995 1.85L20 5v8.083a6.04 6.04 0 0 0-2 0V5H6v14h7c0 .702.12 1.375.342 2H6a2 2 0 0 1-1.995-1.85L4 19V5a2 2 0 0 1 1.85-1.995L6 3zm-2.5 5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm3.75 7.5a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm0 2a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5zM12.5 11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
