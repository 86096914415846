<template>
  <table :class="`data-table data-table--${theme}`">
    <thead>
      <template v-for="groupHeader in groupHeaders"
                :key="groupHeader.value">
        <tr v-if="groupHeader.length"
            :key="groupHeader.value">
          <th v-for="header in groupHeader"
              :key="header.value"
              data-cy="data-table.column"
              :class="`align-${header.align}`"
              :rowspan="header.rowspan"
              :colspan="header.colspan">
            {{ header.label }}
          </th>
        </tr>
      </template>
    </thead>
    <tbody>
      <tr v-for="item in data"
          :key="item"
          data-cy="data-table.row">
        <td v-for="header in headers"
            :key="header.value"
            :class="`align-${header.align}`">
          {{ item[header.value] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    headers: {
      type: Array,
      required: true
    },

    data: {
      type: Array,
      required: true
    },

    theme: {
      type: String,
      default: ''
    }
  },

  computed: {
    groupHeaders () {
      return this.headers.reduce(([groupHeaders, headers], { align, groupHeader, label }) => {
        if (groupHeader) {
          const index = groupHeaders.findIndex(e => e.value === groupHeader.value)

          if (index > -1) {
            groupHeaders[index].colspan++
          } else {
            groupHeaders.push({
              align: groupHeader.align,
              label: groupHeader.label,
              value: groupHeader.value,
              colspan: 1,
              rowspan: 1
            })
          }

          headers.push({
            align,
            label,
            value: groupHeader.value,
            colspan: 1,
            rowspan: 1
          })
        } else {
          groupHeaders.push({
            align,
            label,
            rowspan: 2,
            colspan: 1
          })
        }

        return [groupHeaders, headers]
      }, [[], []])
    }
  }
})
</script>

<style lang="stylus" scoped>
@import './../../assets/stylesheets/partials/utils.styl'

.data-table
  width 100%
  border-style hidden
  box-shadow 0 0 0 1px $borderColor
  border-radius $border-radius

  td:first-child,
  th:first-child
    padding-left 2rem

  td:last-child,
  th:last-child
    padding-right 2rem

  td
    padding 2rem 1rem
    max-width 14rem

  th
    padding 1rem
    @extend .strong

  th,
  td
    text-align center

    &.align-left
      @extend .left

    &.align-right
      @extend .right

  th
    background-color $colorBG

  tbody tr,
  thead tr:last-child
    border-bottom 1px solid $borderColor

.data-table--banded-rows
  tr:nth-child(2n)
    background-color $colorBG
</style>
