import { Analytics } from '@firebase/analytics'
import { FirebaseApp } from '@firebase/app'
import { AppCheck } from '@firebase/app-check'
import { FirebasePerformance } from '@firebase/performance'
import { getAnalytics, setUserId } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getPerformance } from 'firebase/performance'
import { defineStore } from 'pinia'

type FirebaseState = {
  app: FirebaseApp | null
  appCheck: AppCheck | null
  analytics: Analytics | null
  performance: FirebasePerformance | null
}

export const useFirebaseStore = defineStore('firebase', {
  state: (): FirebaseState => {
    return {
      app: null,
      appCheck: null,
      analytics: null,
      performance: null
    }
  },

  actions: {
    initialize (): void {
      if (this.app || import.meta.env.VITE_FIREBASE_ACTIVE !== 'true') {
        return
      }

      self.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env.VITE_APP_CHECK_DEBUG_TOKEN

      this.app = initializeApp({
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_FIREBASE_APP_ID
        // measurementId: import.meta.env.VITE_GTAG_ID
      })
      this.appCheck = initializeAppCheck(this.app, {
        provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_RECAPTCHA_V3_SITE_KEY),
        isTokenAutoRefreshEnabled: true
      })
      this.performance = getPerformance(this.app)
    },

    initializeAnalytics (): void {
      if (!this.app || this.analytics) {
        return
      }

      this.analytics = getAnalytics(this.app)
    },

    setAnalyticsUserId (userId: string): void {
      if (this.analytics) {
        setUserId(this.analytics, userId)
      }
    },

    async getAppCheckToken (): Promise<string | null> {
      if (!this.appCheck) {
        return null
      }

      try {
        return await getToken(this.appCheck).then(resolve => {
          return resolve.token
        })
      } catch (e) {
        console.error(e)
        return null
      }
    }
  }
})
