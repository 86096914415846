<template>
  <core-banner-transition>
    <core-banner v-if="banner"
                 :key="banner.id"
                 data-cy="banner"
                 :text="banner.message"
                 :link="banner.link"
                 :closable="banner.closable"
                 :theme="banner.type"
                 @close="() => { banner && banner.closeBanner() }" />
  </core-banner-transition>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { CoreBanner, CoreBannerTransition } from '@common/core-ui'

import { pageRequiresAuth } from '@/router/guard'
import { useAppStore } from '@/stores/app'

const appStore = useAppStore()
const route = useRoute()

const banner = computed(() => {
  const priorityNotification = appStore.banners.find(banner => !banner.is_closed && banner.auto_dismiss)

  if (priorityNotification) return priorityNotification

  const notifications = appStore.banners.filter(banner => banner.isAvailable(pageRequiresAuth(route), route?.name === 'login'))

  const nonClosable = notifications.find(banner => !banner.closable)

  return nonClosable || notifications[0] || null
})

watch(banner, newBanner => {
  if (newBanner?.auto_dismiss) setTimeout(() => { newBanner.closeBanner() }, newBanner.auto_dismiss_timeout ?? 3000)
})
</script>
