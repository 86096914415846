import { hexToRgb } from '@/helpers/utils/color'

export default function generateGradient (id, primaryColor) {
  primaryColor = hexToRgb(primaryColor)
  const ctx = document.getElementById(`chart-${id}`).getContext('2d')
  const gradient = ctx.createLinearGradient(0, 25, 0, ctx?.canvas?.clientHeight)
  gradient.addColorStop(0, `rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, 0.15)`)
  gradient.addColorStop(0.8, `rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, 0)`)

  return gradient
}
