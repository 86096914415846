<template>
  <div class="sidepanel-body">
    <loader-spinner v-if="loading"
                    class="c c--vh" />
    <template v-else>
      <component-timeline v-for="(logs, day) in invitationLogsGroupByDay"
                          :key="day"
                          type="block"
                          :title="day">
        <template #body>
          <component-timeline-item v-for="(activity, index) in formatLogs(logs)"
                                   :id="index"
                                   :key="index"
                                   :title="activity.label"
                                   type="block"
                                   :subtitle="activity.desc"
                                   :top-right-label="activity.time" />
        </template>
      </component-timeline>
      <next-page-button v-if="hasNextPage"
                        :next-page-loading="isNextPageLoading"
                        @btn-click="getNextPageLogs" />
      <component-placeholder v-if="!hasResult"
                             class="c c--vh"
                             :label="$t('placeholder.no_user_activity.title')" />
    </template>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatTime } from '@/helpers/utils/date'
import { useInvitationStore } from '@/stores/invitation'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import NextPageButton from '@/components/NextPageButton.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ComponentTimeline from '@/components/timeline/Timeline.vue'
import ComponentTimelineItem from '@/components/timeline/TimelineItem.vue'

export default {

  components: {
    NextPageButton,
    ComponentPlaceholder,
    ComponentTimeline,
    LoaderSpinner,
    ComponentTimelineItem
  },

  props: {
    userUuid: {
      type: String,
      required: true
    }
  },

  setup () {
    const invitationStore = useInvitationStore()

    const { invitationLogsGroupByDay, invitationLogsPagination } = storeToRefs(invitationStore)

    return { invitationLogsGroupByDay, invitationLogsPagination, invitationStore }
  },

  data: () => ({
    store,
    loading: true,
    isNextPageLoading: false
  }),

  computed: {
    hasResult () {
      return Object.keys(this.invitationLogsGroupByDay).length > 0
    },

    hasNextPage () {
      return this.invitationLogsPagination.current < this.invitationLogsPagination.count
    }
  },

  async created () {
    await this.invitationStore.getUserLogs(1, this.userUuid)
    this.loading = false
  },

  methods: {
    formatLogs (logs) {
      return logs.map(log => {
        return {
          label: log.label,
          desc: log.text,
          time: formatTime(log.created_at)
        }
      })
    },

    async getNextPageLogs () {
      this.isNextPageLoading = true
      await this.invitationStore.getUserLogs(this.invitationLogsPagination.current + 1, this.userUuid)
      this.isNextPageLoading = false
    }
  }
}
</script>
