<template>
  <svg class="ic-check-circle"
       viewBox="0 0 24 24">
    <path v-if="outline"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924zm3.743 4.955 1.106 1.014-6.03 6.577-3.553-3.554 1.06-1.06 2.446 2.445z" />
    <path v-else
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm3.87 6.156-5.006 5.39-2.802-2.452-1.128 1.29 4.054 3.548 6.138-6.61z" />
  </svg>
</template>

<script>
export default {
  props: {
    outline: Boolean
  }
}
</script>
