<template>
  <modal-dialog-prompt :title="isPhysical ? $t('modal.card.lock_cards.title_physical') : $t('modal.card.lock_cards.title_virtual')"
                       :subtitle="$t('modal.card.lock_cards.content')"
                       @close="$emit('close')">
    <template #body>
      <div class="bordered-box">
        <label class="bordered-box__item radio"
               for="true">
          <input id="true"
                 v-model="owner"
                 type="radio"
                 name="owner"
                 :value="true">
          <span class="radio__icon" />
          <span class="item-label">{{ $t('modal.card.lock_cards.choice.all') }}</span>
        </label>
        <label class="bordered-box__item radio"
               for="false">
          <input id="false"
                 v-model="owner"
                 type="radio"
                 name="owner"
                 :value="false">
          <span class="radio__icon" />
          <span class="item-label">{{ $t('modal.card.lock_cards.choice.card_holders') }}</span>
        </label>
      </div>
    </template>

    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <component-button type="button"
                        class="btn btn--primary"
                        :label="$t('button.lock')"
                        :disabled="loading"
                        @click="onSubmit" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { useCardStore } from '@/stores/card'

import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ComponentButton,
    ModalDialogPrompt
  },

  props: {
    group: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const cardStore = useCardStore()

    return {
      cardStore
    }
  },

  data () {
    return {
      loading: false,
      formSubmitted: false,
      owner: true
    }
  },

  computed: {
    isPhysical () {
      return this.group === 'physical'
    }
  },

  methods: {
    async onSubmit () {
      this.loading = true
      const success = await this.cardStore.lockAllCards(this.owner, this.group)
      if (success) { this.$emit('close') }
      this.loading = false
    }
  }
}
</script>
