<template>
  <div class="section__content-block--centered section__content--w780">
    <component-placeholder icon
                           :content="$t('create_term_account.placeholder.content')"
                           :label="$t('create_term_account.placeholder.title')">
      <saving-account />
    </component-placeholder>
    <div class="block-summaries">
      <core-block-summary v-for="(blockSummary, index) in blockSummaries"
                          :key="index"
                          :items="[blockSummary]"
                          :size="ECoreBlockSummarySize.Medium" />
    </div>
    <data-table class="mt-3 rate-table"
                data-cy="account.create.term.placeholder.data-table"
                :data="tableData"
                :headers="headers"
                theme="banded-rows" />
    <core-button class="my-4"
                 data-cy="account.create.term.placeholder.button"
                 :text="$t('button.continue')"
                 @click="$router.push({ name: 'create-term-account-form' })" />
    <p class="text-muted text--sm">
      <sup>(1)</sup> {{ $t('create_term_account.placeholder.info') }}
      <button class="link underline"
              type="button"
              @click="showPenalties">
        {{ $t('create_term_account.button.show_penalties') }}
      </button>
    </p>
  </div>
</template>

<script>
import {
  CoreBlockSummary,
  CoreButton,
  ECoreBlockSummarySize
} from '@common/core-ui'
import { storeToRefs } from 'pinia'

import {
  formatCompactAmount,
  formatPercentage,
  isNumber
} from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import TermAccountPenalties from '@/pages/account/create-sub-account/modal/TermAccountPenalties.vue'

import ComponentPlaceholder from '@/components/Placeholder.vue'
import SavingAccount from '@/components/svg/img/saving-account.vue'
import DataTable from '@/components/table/DataTable.vue'

export default {
  components: {
    ComponentPlaceholder,
    CoreBlockSummary,
    CoreButton,
    DataTable,
    SavingAccount
  },

  props: {
    termAccountRates: {
      type: Array,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return {
      account,
      accountStore,
      appStore
    }
  },

  data () {
    return {
      blockSummaries: [
        {
          subtitle: this.$t('create_term_account.no_fee.subtitle'),
          title: this.$t('create_term_account.no_fee.title')
        },
        {
          subtitle: this.$t('create_term_account.no_limit.subtitle'),
          title: this.$t('create_term_account.no_limit.title')
        },
        {
          subtitle: this.$t('create_term_account.no_risk.subtitle'),
          title: this.$t('create_term_account.no_risk.title')
        }
      ],

      durations: []
    }
  },

  computed: {
    ECoreBlockSummarySize () {
      return ECoreBlockSummarySize
    },

    headers () {
      let headers = [
        {
          align: 'left',
          label: this.$i18n.t('table.duration'),
          value: 'minimum_deposit'
        }
      ]

      const newFields = this.durations.map(duration => {
        return {
          label: this.$i18n.t('general.months', { n: duration }),
          value: duration
        }
      })

      headers = headers.concat(newFields)

      return headers
    },

    tableData () {
      const data = []

      const length = this.termAccountRates.length

      this.termAccountRates.forEach((termAccountRate, index) => {
        const item = {}
        item.minimum_deposit = this.$i18n.tc(
          'create_term_account.rate.minimum_deposit',
          length === 1 ? 2 : index + 1,
          { amount: formatCompactAmount(this.termAccountRates[index === 0 && length > 1 ? index + 1 : index].minimum_deposit) }
        )

        this.headers.forEach(header => {
          if (isNumber(header.value)) {
            const rate = termAccountRate.rates.find(rate => rate.duration === header.value)
            item[header.value] = rate ? formatPercentage(rate.client_rate) : '-'
          }
        })

        data.push(item)
      })

      return data
    }
  },

  async mounted () {
    const durationsSet = new Set(
      this.termAccountRates.flatMap(termAccountRate =>
        termAccountRate.getDurations()
      )
    )

    this.durations = Array.from(durationsSet)
    this.durations.sort((a, b) => a - b)
  },

  methods: {
    showPenalties () {
      return this.appStore.showModal(TermAccountPenalties)
    }
  }
}
</script>

<style lang="stylus" scoped>
.block-summaries
  display flex
  gap 2rem
  width 100%
  margin-top 3rem

  .core-block-summary
    width 100%

:deep(.data-table.rate-table)
  td:first-child
    max-width 26rem
    width 26rem
</style>
