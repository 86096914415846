<template>
  <validation-form ref="form"
                   :key="id"
                   v-slot="{ errors, isSubmitting }"
                   as="form"
                   class="sidepanel__content"
                   :validation-schema="schema[activeStep]"
                   @keydown.enter.prevent
                   @invalid-submit="onInvalidSubmit"
                   @submit="validateComponent('submit')">
    <div id="sidepanel-wrapper"
         ref="scrollWrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ title }} {{ id + 1 }}/{{ items.length }}
        </h2>
      </div>
      <div class="sidepanel-body section-card-order">
        <ul v-if="hasMultipleSteps"
            class="nav">
          <li v-for="(step, i) in steps"
              :key="i"
              class="nav__item">
            <button class="nav__link"
                    type="button"
                    :class="{ active: activeStep === i }"
                    :disabled="apiLoading"
                    @click="validateComponent('toggle-step', i)">
              {{ stepsNames[step] }}
            </button>
          </li>
        </ul>
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('message.warning.form_not_valid')" />
        <div class="form form--w1000 form--centered">
          <component :is="`component-${currentStep}`"
                     :key="id"
                     v-model:user="formItems[id]"
                     :errors="errors"
                     mode="import" />
        </div>
      </div>
    </div>
    <component-sidepanel-footer :key="activeStep">
      <div class="row row--sm-bottom">
        <div class="col col--sm left">
          <div class="btn-group">
            <button class="btn btn--outline"
                    type="button"
                    :disabled="isSubmitting"
                    @click="validateComponent('get-previous-item')">
              <ic-arrow class="ic-arrow-prev ic--20 ic--gray" />
            </button>
            <button class="btn btn--outline"
                    type="button"
                    :disabled="isSubmitting"
                    @click="validateComponent('get-next-item')">
              <ic-arrow next
                        class="ic-arrow-next ic--20 ic--gray" />
            </button>
          </div>
        </div>
        <div class="col col--sm right">
          <div class="form-buttons">
            <button class="btn btn--gray"
                    type="button"
                    @click="appStore.closeSidePanel">
              {{ $t('button.cancel') }}
            </button>
            <button class="btn btn--primary"
                    :disabled="isSubmitting">
              {{ $t('button.validate') }}
            </button>
          </div>
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import modulo from 'just-modulo'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import store from '@/config/store'
import { scrollToTop } from '@/helpers/utils/dom'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'

import ComponentUserInformations from '@/pages/users/components/form/UserInformations.vue'
import ComponentUserRights from '@/pages/users/components/form/UserRights.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    ComponentAlert,
    ComponentSidepanelFooter,
    ComponentUserInformations,
    ComponentUserRights,
    IcArrow
  },

  props: {
    title: {
      type: String,
      required: true
    },

    steps: {
      type: Array,
      required: true
    },

    editItemMethod: {
      type: Function,
      required: true
    },

    idInitial: {
      type: Number,
      required: true
    },

    items: {
      type: Array,
      required: true
    }
  },

  setup () {
    const apiStore = useApiStore()
    const appStore = useAppStore()

    const { loading: apiLoading } = storeToRefs(apiStore)

    return {
      appStore,
      apiLoading
    }
  },

  data () {
    return {
      store,
      id: this.idInitial,
      activeStep: 0,
      formItems: {},
      schema: [
        yup.object({
          advance_payment: yup.boolean(),
          first_name: yup.string().required().matches(/^([^0-9]*)$/),
          last_name: yup.string().required().matches(/^([^0-9]*)$/),
          email: yup.string().required().email(),
          phone: yup.string().validPhoneNumber().nullable(),
          roles: yup.string().required(),
          role: yup.string().when('roles', {
            is: 'other',
            then: () => yup.string().required()
          }),
          iban: yup.string().when('advance_payment', {
            is: true,
            then: () => yup.string().required()
          }),
          bic: yup.string().nullable().when('iban', {
            is: iban => !!iban,
            then: () => yup.string().required()
          })
        }),
        {}
      ],

      stepsNames: {
        'user-informations': this.$i18n.t('form.users_import.step.user_informations'),
        'user-rights': this.$i18n.t('form.users_import.step.user_rights')
      }
    }
  },

  computed: {
    hasMultipleSteps () {
      return this.steps.length >= 2
    },

    currentStep () {
      return this.steps[this.activeStep]
    }
  },

  created () {
    this.formItems = cloneDeep(this.items)
  },

  methods: {
    async validateComponent (type, step = null) {
      const { valid } = await this.$refs.form.validate()

      if (!valid) {
        return this.onInvalidSubmit()
      }

      if (type === 'toggle-step') {
        this.toggleStep(step)
      } else if (type === 'get-previous-item') {
        this.getPreviousItem()
      } else if (type === 'get-next-item') {
        this.getNextItem()
      } else if (type === 'submit') {
        this.onSubmit()
      }

      if (type !== 'submit') {
        this.onInvalidSubmit()
      }
    },

    toggleStep (step) {
      this.activeStep = step
    },

    getPreviousItem () {
      this.id = modulo(this.id - 1, this.formItems.length)
      this.activeStep = 0
    },

    getNextItem () {
      this.id = modulo(this.id + 1, this.formItems.length)
      this.activeStep = 0
    },

    onInvalidSubmit () {
      scrollToTop(this.$refs.scrollWrapper)
    },

    onSubmit () {
      this.editItemMethod(this.formItems)
      this.appStore.closeSidePanel()
    }
  }
}
</script>
