<template>
  <validation-field :name="name"
                    as="span"
                    :class="[{ checked, disabled }, toggleClass]"
                    class="switch-btn"
                    :model-value="modelValue"
                    @click="checked = !checked" />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },

    name: {
      type: String,
      default: 'toggle-switch'
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false
    },

    toggleClass: {
      type: String,
      default: null
    }
  },

  emits: ['update:modelValue'],

  computed: {
    checked: {
      get () {
        return this.modelValue
      },

      set (newValue) {
        if (this.disabled) {
          return
        }

        this.$emit('update:modelValue', newValue)
      }
    }
  }
}
</script>
