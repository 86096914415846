<template>
  <section class="section section-debits">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.debits") }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <button class="btn btn--primary"
                  @click="addMandate">
            {{ $t('button.new_mandate') }}
          </button>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="numberOfResults"
                       @filters="filters" />
    <router-view :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { useAppStore } from '@/stores/app'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import Tabs from '@/components/Tabs.vue'

import CreateMandate from './modal/CreateMandate.vue'

export default {
  name: 'PageMandates',

  components: {
    ButtonFilters,
    ComponentFilters,
    Tabs
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      numberOfResults: null,
      filter: {
        search: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.overview'),
          route: { name: 'debits' },
          active: false
        },
        {
          label: this.$i18n.t('button.mandates'),
          route: { name: 'mandates' },
          active: true
        }
      ]
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        search: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search
      }
    },

    addMandate () {
      this.appStore.showModal(CreateMandate, {}, { wrapperClass: 'modal--md' })
    }
  }
}

</script>
