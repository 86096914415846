<template>
  <section class="section section-card-order"
           :class="{ 'section--centered': activeStep !== 3 }">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <a href="#"
             class="btn-link btn-back"
             @click.prevent="() => $router.back()">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </a>
          {{ title }}
        </h1>
      </div>

      <div class="section__heading__right">
        <div class="section-actions">
          <div v-if="activeStep === 3 && filename"
               class="btn-link btn-link--attachment">
            <i class="btn-icon btn-icon--left">
              <ic-document class="ic ic-document ic--gray" />
            </i>
            <span class="btn__label">{{ filename }}</span>
            <button class="btn-link btn-remove-file"
                    @click.prevent.stop="removeFile">
              <ic-remove class="ic ic--18 ic--gray" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="section__content">
      <component :is="currentStep"
                 :helper-content="helperContent"
                 :placeholder-content="placeholderContent"
                 :details-component="detailsComponent"
                 @toggle-step="toggleStep"
                 @on-attach-file="onAttachFile" />
    </div>
  </section>
</template>

<script>
import { useAppStore } from '@/stores/app'

import ImportDocuments from '@/components/modals/ImportDocuments.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

import BeneficiariesImportDetails from './components/BeneficiariesImportDetails.vue'
import OrderMultipleCardDetails from './components/OrderMultipleCardDetails.vue'
import UserImportDetails from './components/UsersImportDetails.vue'
import ComponentHelper from './steps/Helper.vue'
import ComponentPlaceholder from './steps/Placeholder.vue'

export default {
  components: {
    IcDocument,
    IcRemove,
    IcArrow,
    ComponentHelper,
    ComponentPlaceholder,
    UserImportDetails,
    OrderMultipleCardDetails,
    BeneficiariesImportDetails
  },

  props: {
    title: {
      type: String,
      required: true
    },

    helperContent: {
      type: Object,
      required: true
    },

    placeholderContent: {
      type: Object,
      required: true
    },

    detailsComponent: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    extraData: {
      type: Object,
      default: () => ({})
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      steps: [
        'component-helper',
        'component-placeholder'
      ],

      activeStep: 1,
      filename: ''
    }
  },

  computed: {
    currentStep () {
      return this.activeStep === 3 ? this.detailsComponent : this.steps[this.activeStep - 1]
    }
  },

  mounted () {
    this.$bus.on('on-validate-file-import', this.onImportValidated)
  },

  beforeUnmount () {
    this.$bus.off('on-validate-file-import', this.onImportValidated)
  },

  methods: {
    toggleStep (step) {
      this.activeStep = step
    },

    onAttachFile () {
      this.appStore.showModal(ImportDocuments, { type: this.type, extraData: this.extraData }, { wrapperClass: 'modal--md' })
    },

    onImportValidated (files) {
      if (files.length) {
        this.filename = files[0].upload.filename
      }
      this.activeStep = 3
    },

    removeFile () {
      this.filename = ''
      this.activeStep = 2
    }
  }
}
</script>
