<template>
  <div>
    <loader-spinner v-if="loading"
                    class="c c--vh" />
    <div v-show="!loading"
         class="content-wrapper">
      <list-notifications v-if="isBankNotificationVisible"
                          is-bank-notification
                          @loaded="() => { loadingBank = false }" />
      <list-notifications class="mt-6"
                          @loaded="() => { loadingUser = false }" />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'

import LoaderSpinner from '@/components/LoaderSpinner.vue'

import ListNotifications from './ListNotifications.vue'

export default {
  components: {
    LoaderSpinner,
    ListNotifications
  },

  setup () {
    const accountStore = useAccountStore()

    const { isPendingAccount, isRestrictedAccount } = storeToRefs(accountStore)

    return { isPendingAccount, isRestrictedAccount }
  },

  data () {
    return {
      loadingBank: true,
      loadingUser: true
    }
  },

  computed: {
    loading () {
      return (this.loadingBank && this.isBankNotificationVisible) || this.loadingUser
    },

    // TODO: à retirer ? https://git.manager.one/mone/backlog/-/issues/1055
    isBankNotificationVisible () {
      return !this.isPendingAccount && !this.isRestrictedAccount
    }
  }
}
</script>
