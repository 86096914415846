<template>
  <section class="section section--empty">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account-sca-configure' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.sca') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <app-enrollment-instructions v-if="isApp" />
      <component :is="activeStep"
                 v-else
                 :type="type"
                 @action="goToScreen" />
    </div>
  </section>
</template>

<script>
import SendInvitation from '@/components/modals/sca/SendInvitation.vue'
import StartValidation from '@/components/modals/sca/StartValidation.vue'
import ValidationFailed from '@/components/modals/sca/ValidationFailed.vue'
import ValidationSuccess from '@/components/modals/sca/ValidationSuccess.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

import AppEnrollmentInstructions from './AppEnrollmentInstructions.vue'

export default {
  components: {
    IcArrow,
    AppEnrollmentInstructions,
    StartValidation,
    SendInvitation,
    ValidationFailed,
    ValidationSuccess
  },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      activeStep: 'start-validation'
    }
  },

  computed: {
    isApp () {
      return this.type === 'app'
    }
  },

  created () {
    if (!this.$config.features.account.sca.methods.includes(this.type)) {
      this.$router.push({ name: 'account-sca-configure' })
    }
  },

  methods: {
    goToScreen (screen, challenge = null) {
      if (challenge) {
        this.modal.props.challenge = challenge
      }
      this.activeStep = screen
    }
  }
}
</script>
