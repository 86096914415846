export const backLine = {
  id: 'backLine',
  beforeDraw: (chart, args, options) => {
    if (options.enable && chart.tooltip?._active?.length) {
      const x = chart.tooltip._active[0].element.x
      const yAxis = chart.scales.y
      const ctx = chart.ctx
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, yAxis.top)
      ctx.lineTo(x, yAxis.bottom)
      ctx.lineWidth = 1
      ctx.setLineDash([3, 4])
      ctx.strokeStyle = '#B4B9C3'
      ctx.stroke()
      ctx.restore()
    }
  },
  defaults: {
    enable: false
  }
}
