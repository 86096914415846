<template>
  <div class="section__content">
    <div class="placeholder-action-description section__content-block--centered">
      <component-placeholder :label="$t('placeholder.expense_control.title')"
                             :content="$t('placeholder.expense_control.subtitle')">
        <image-notepad-check />
      </component-placeholder>
      <div class="center">
        <component-button wrapper-class="btn btn--primary placeholder-btn"
                          :label="$t('button.enable_feature')"
                          :disabled="loading"
                          @click="onEnableExpenseFeature" />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useExpenseStore } from '@/stores/expense'

import ComponentButton from '@/components/Button.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageNotepadCheck from '@/components/svg/img/notepad-check.vue'

export default {
  components: {
    ImageNotepadCheck,
    ComponentButton,
    ComponentPlaceholder
  },

  setup () {
    const accountStore = useAccountStore()
    const expenseStore = useExpenseStore()

    const { account } = storeToRefs(accountStore)

    return { account, expenseStore }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    async onEnableExpenseFeature () {
      this.loading = true
      const data = await this.expenseStore.enableExpenseFeature()
      if (data?.message) {
        showToastSuccess(data.message)
        this.account.settings.expense_control = true
      }
      this.loading = false
    }
  }
}
</script>
