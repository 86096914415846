<template>
  <label class="tile-radio form__fieldset__block"
         :for="$attrs.id"
         @click="updateInputValue">
    <div class="tile-radio__container">
      <validation-field :name="name"
                        :model-value="modelValue">
        <input :checked="isChecked"
               type="radio">
      </validation-field>
      <span class="radio-custom">
        <ic-check class="ic ic--10" />
      </span>
    </div>
    <div>
      <h4 class="text-dark strong">{{ label }}</h4>
      <span class="text-muted">{{ content }}</span>
    </div>
  </label>
</template>

<script>
import IcCheck from '@/components/svg/icons/ic-check.vue'

export default {
  components: {
    IcCheck
  },

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    },

    modelValue: {
      type: String,
      required: true
    },

    name: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      required: true
    }
  },

  emits: ['update:modelValue'],

  computed: {
    isChecked () {
      return this.modelValue === this.value
    }
  },

  methods: {
    updateInputValue () {
      if (this.modelValue !== this.value) {
        this.$emit('update:modelValue', this.value)
      }
    }
  }
}
</script>
