import { localize } from '@vee-validate/i18n'
import { getCountryCodeForRegionCode, parsePhoneNumber } from 'awesome-phonenumber'
import { configure } from 'vee-validate'
import { addMethod, string } from 'yup'

import { checkPasswordValid, checkUrlValid } from '@/helpers/utils/text'
import dictionary from '@/locales/validator/dictionary'

declare module 'yup' {
  interface StringSchema {
    phoneNumberRequired(): StringSchema
    password(): StringSchema
    validUrl(): StringSchema
    validEmail(): StringSchema
    validPhoneNumber(): StringSchema
  }
}

addMethod(string, 'phoneNumberRequired', () => {
  return string().test('test-phone-number', 'the phone number is not valid', value => {
    if (!value) return false
    const phone = parsePhoneNumber(value)
    return phone.valid
  })
})

addMethod(string, 'password', () => {
  return string().test('test-password', 'the password is not valid', value => {
    return checkPasswordValid(value)
  })
})

addMethod(string, 'validUrl', () => {
  return string().test('test-valid-url', 'the url is not valid', value => {
    if (!value) {
      return true
    }
    return !!checkUrlValid(value)
  })
})

addMethod(string, 'validEmail', () => {
  return string().test('test-valid-email', 'the email is not valid', value => {
    if (!value) {
      return true
    }
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !!value.match(emailRegExp)
  })
})

addMethod(string, 'validPhoneNumber', () => {
  return string().test('test-valid-phone-number', 'the phone number is not valid', value => {
    if (!value) return true
    const phone = parsePhoneNumber(value)
    if (!phone.valid && phone?.regionCode && phone?.number?.input === `+${getCountryCodeForRegionCode(phone.regionCode)}`) return true
    return phone.valid
  })
})

configure({
  generateMessage: localize({ dictionary })
})
