<template>
  <svg viewBox="0 0 16 16">
    <path v-if="circle"
          d="M.3 8A7.7 7.7 0 0115.7 8 7.7 7.7 0 01.3 8ZM15.2 8A7.2 7.2 0 00.8 8 7.2 7.2 0 0015.2 8Z"
          fill-opacity=".3" />
    <path d="m8 11 4-4.051L11.063 6 8 9.103 4.936 6 4 6.949 8 11Z"
          :transform="getTransform" />
  </svg>
</template>

<script>
export default {
  props: {
    up: Boolean,
    down: Boolean,
    left: Boolean,
    right: Boolean,
    zoom: Boolean,
    shrink: Boolean,
    circle: Boolean
  },

  computed: {
    getTransform () {
      const transform = []

      if (this.left) {
        transform.push('rotate(90 8 8)')
      } else if (this.up) {
        transform.push('rotate(180 8 8)')
      } else if (this.right) {
        transform.push('rotate(-90 8 8)')
      }

      if (this.zoom) {
        transform.push('scale(1.7)')
        transform.push('translate(-3.3 -3.3)')
      } else if (this.shrink) {
        transform.push('scale(0.6)')
        transform.push('translate(5 5.5)')
      }

      return transform
    }
  }
}
</script>
