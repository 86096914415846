<template>
  <div class="form__fieldset__group">
    <div class="form-group">
      <core-radio-tile-group data-cy="account.create.term.duration-form"
                             :model-value="duration"
                             :options="durations"
                             @update:model-value="updateDuration" />
      <div />
    </div>
  </div>
</template>

<script>
import { CoreRadioTileGroup } from '@common/core-ui'

import { formatPercentage } from '@/helpers/utils/number'

export default {
  components: {
    CoreRadioTileGroup
  },

  props: {
    amount: {
      type: Number,
      required: true
    },

    duration: {
      type: Number,
      required: true
    },

    termAccountRate: {
      type: Object,
      required: true
    }
  },

  emits: ['update:duration'],

  computed: {
    durations () {
      return this.termAccountRate.rates.map(rate => {
        return {
          subtitle: this.$i18n.t('create_term_account.steps.duration.remuneration', { n: formatPercentage(rate.client_rate) }),
          title: this.$i18n.t('general.months', { n: rate.duration }),
          value: rate.duration
        }
      })
    }
  },

  created () {
    this.updateDuration(this.termAccountRate.rates[0].duration)
  },

  methods: {
    updateDuration (value) {
      this.$emit('update:duration', value)
    }
  }
}
</script>
