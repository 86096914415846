<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else-if="team"
       class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header sidepanel-header--centered">
        <user-avatar :size="72"
                     type="team"
                     :placeholder-label="team.name" />
        <div class="header-title">
          {{ team.name }}
        </div>
        <div class="header-subtitle">
          {{ $tc('sidepanel.team.members_count', team.members_count) }}
        </div>
      </div>
      <hr class="divider divider--fw">
      <div class="sidepanel-body">
        <div class="sidepanel-details">
          <h3 class="head-title">
            {{ $t('sidepanel.team.details.title') }}
          </h3>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('general.creation_date') }}
            </div>
            <div class="entry-value right">
              {{ formatDateText(team.created_at) }}
            </div>
          </div>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('table.managers') }}
            </div>
            <div class="entry-value right">
              {{ team.managers.length ? team.managers.map(i => i.name).join(', ') : $t('general.none_m') }}
            </div>
          </div>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('table.approvers') }}
            </div>
            <div class="entry-value right">
              {{ team.approvers.length ? team.approvers.map(i => i.name).join(', ') : $t('general.none_m') }}
            </div>
          </div>
          <hr class="divider divider--fw">
          <h3 class="head-title">
            {{ $t('sidepanel.team.title.members') }}
          </h3>
          <div v-for="(member, index) in team.members"
               :key="index"
               class="d-flex mt-2 align-items-center">
            <user-avatar :size="28"
                         :picture="member.picture"
                         :uuid="member?.uuid"
                         :placeholder-label="member.name"
                         class="mr-2" />
            <div>
              {{ member.name }}
              <span v-if="member.role"> - {{ member.role_label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <template v-if="hasPermission($permissions.teamsWrite)">
        <div class="btn-grouped">
          <button class="btn btn--sm btn--outline-red"
                  @click="onDeleteTeam">
            {{ $t("button.delete") }}
          </button>
          <button class="btn btn--sm btn--default"
                  @click="editTeam">
            {{ $t('button.edit') }}
          </button>
        </div>
      </template>
      <template v-else>
        <button class="btn btn--block btn--filled"
                @click="editTeam">
          {{ $t('button.more_informations') }}
        </button>
      </template>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useTeamStore } from '@/stores/team'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    ComponentSidepanelFooter,
    LoaderSpinner,
    UserAvatar
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const teamStore = useTeamStore()

    return { appStore, teamStore }
  },

  data () {
    return {
      loading: true,
      team: null
    }
  },

  async created () {
    this.loading = true
    this.team = await this.teamStore.getTeamDetail(this.id)
    this.loading = false
  },

  methods: {
    formatDateText,

    editTeam () {
      this.appStore.closeSidePanel()
      this.$router.push({ name: 'team-edit', params: { id: this.team.id } })
    },

    onDeleteTeam () {
      this.appStore.showDeleteModal({ label: this.$i18n.t('prompt.team.delete.title'), content: this.$i18n.t('prompt.team.delete.content'), url: `/teams/${this.team.id}` })
    }
  }
}
</script>
