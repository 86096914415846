<template>
  <svg viewBox="0 0 200 160">
    <path d="M124.945 38h-49.89c-1.496-.005-3.044 1.456-3.055 3v5h56v-5c-.011-1.544-1.559-3.005-3.055-3Z"
          fill="#E4E6E8" />
    <path fill-rule="evenodd"
          d="M75.9 37h48.2a4.89 4.89 0 0 1 4.9 4.87v72.39c0 .194-.075.38-.21.52l-7.25 7.5a.748.748 0 0 1-.54.23H75.9a4.89 4.89 0 0 1-4.9-4.87V41.87A4.89 4.89 0 0 1 75.9 37Zm51.6 8v-3.13a3.39 3.39 0 0 0-3.4-3.37H75.9a3.39 3.39 0 0 0-3.4 3.37V45h55Zm-55 1.5h55V113h-1a7 7 0 0 0-6.75 7.25v.75H75.9a3.39 3.39 0 0 1-3.4-3.37V46.5Zm48.75 73.91v-.16a5.59 5.59 0 0 1 5.25-5.75h.5l-5.75 5.91Zm-12.702-45.803c3.752 0 6.802 3.098 6.802 6.905 0 3.809-3.05 6.91-6.802 6.91h-1.338a.77.77 0 0 1-.766-.774.77.77 0 0 1 .766-.773h1.338c2.903 0 5.269-2.405 5.269-5.363 0-2.955-2.366-5.358-5.269-5.358-.28 0-.515.017-.719.05a.762.762 0 0 1-.819-.446c-1.244-2.77-3.981-4.56-6.972-4.56-4.225 0-7.665 3.498-7.665 7.8 0 .143.004.283.013.43a.775.775 0 0 1-.342.69.758.758 0 0 1-.773.045 3.452 3.452 0 0 0-1.597-.393c-1.923 0-3.49 1.594-3.49 3.555 0 .949.363 1.84 1.023 2.51a3.434 3.434 0 0 0 2.467 1.04h3.116a.77.77 0 0 1 .767.773.77.77 0 0 1-.767.773h-3.116a4.955 4.955 0 0 1-3.555-1.496 5.104 5.104 0 0 1-1.469-3.6c0-2.812 2.252-5.102 5.024-5.102.403 0 .8.049 1.186.144.32-4.867 4.32-8.717 9.179-8.717 3.402 0 6.535 1.927 8.124 4.966.123-.006.251-.01.385-.01Zm-4.336 9.082-3.693-3.41a.569.569 0 0 0-.106-.075.493.493 0 0 0-.099-.06l-.038-.015a.724.724 0 0 0-.144-.037.248.248 0 0 0-.049-.008.501.501 0 0 0-.083-.01c-.046.005-.065.006-.063.007-.037.003-.045.005-.094.017a.532.532 0 0 0-.11.027l-.051.02c-.07.04-.071.04-.098.062a.517.517 0 0 0-.103.072l-3.694 3.41a.778.778 0 0 0-.052 1.089.76.76 0 0 0 1.087.053l2.411-2.227v11.972a.77.77 0 0 0 .767.774.77.77 0 0 0 .767-.774V82.604l2.411 2.226a.76.76 0 0 0 1.087-.053.779.779 0 0 0-.053-1.088Zm-4.123-3.603.013.003s-.003-.002-.013-.004Z"
          fill="#7A818C" />
  </svg>
</template>

<script>
export default {
}
</script>
