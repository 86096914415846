<template>
  <div class="team-selector">
    <div class="team-selector__title">
      <div class="d-flex align-items-center">
        <span>{{ $t(`table.${type}`) }}</span>
        <VTooltip v-if="type !== 'members'"
                  class="ml-1"
                  theme="poptip">
          <template #popper>
            <poptip :title="poptipTitle"
                    :message="poptipContent" />
          </template>
          <ic-info outline
                   class="ic ic--16 ic--gray" />
        </VTooltip>
      </div>
      <button v-if="members.length && !readOnly"
              type="button"
              class="btn-link text-muted d-flex align-items-center"
              @click="chooseMembers">
        {{ $t("button.edit") }}
        <ic-pencil class="ic ic--18 ic--gray" />
      </button>
    </div>
    <field-placeholder v-if="!members.length"
                       :read-only="readOnly"
                       :title="$t(`team.create.configuration.${type}.title`)"
                       :subtitle="$t(`team.create.configuration.${type}.subtitle`)"
                       @click="chooseMembers" />
    <div v-else
         class="team-selector__content">
      <div v-for="member in members"
           :key="member.uuid"
           class="team-selector__item">
        <user-avatar :picture="member.picture"
                     :uuid="member?.uuid"
                     :placeholder-label="`${member.first_name} ${member.last_name}`" />
        <div>
          {{ member.name }}
        </div>
        <span v-if="member.role"
              class="text-muted">&nbsp;- {{ member.role_label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

import SidepanelChooseMembers from '@/pages/teams/sidepanel/ChooseMembers.vue'

import FieldPlaceholder from '@/components/FieldPlaceholder.vue'
import Poptip from '@/components/Poptip.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcPencil,
    IcInfo,
    FieldPlaceholder,
    UserAvatar,
    Poptip
  },

  props: {
    members: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    hideMembers: {
      type: Array,
      default: () => ([])
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  computed: {
    poptipTitle () {
      return this.$i18n.t(`poptip.${this.type}.title`)
    },

    poptipContent () {
      return this.$i18n.t(`poptip.${this.type}.content`)
    }
  },

  methods: {
    chooseMembers () {
      this.appStore.showSidePanel(
        SidepanelChooseMembers,
        { members: this.members, type: this.type, hideMembers: this.hideMembers },
        { wrapperClass: 'sidepanel--lg', backgroundOverlay: true, closeButton: false }
      )
    }
  }
}
</script>

<style lang="stylus">
.team-selector
  &__title
    font-weight 500
    font-size 1.6rem
    margin-bottom 2rem
    display flex
    align-items center
    justify-content space-between

    .btn-link
      font-size 1.4rem

  &:not(:last-child)
    margin-bottom 3.8rem

  &__content
    background-color white
    border-radius 3px
    .content-title
      font-weight 500
      font-size 1.5rem
      margin-bottom 1.8rem
      display flex
      align-items center
      .ic
        margin-right 1rem

    &--empty
      padding 4rem 2rem
      display flex
      align-items center
      flex-direction column
      justify-content center
      cursor pointer

  &__item
    display flex
    align-items center
    padding 2rem 3rem
    &:not(:last-child)
      border-bottom 1px solid $colorBG
    .user-avatar
      margin-right 2rem
</style>
