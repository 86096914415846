<template>
  <div class="amount-consumed">
    <div class="amount-consumed__title"
         data-cy="cards.details.limits.title">
      <slot />
    </div>
    <div class="amount-consumed__values">
      <span class="value">
        <span class="value__int"
              data-cy="cards.details.limits.amount">{{ formattedValue[0] }}</span>
        <span v-if="formattedValue[1]"
              class="value__dec">{{ formattedValue[1] }}</span>
      </span>
      <span v-if="enabled && displayLimit"
            class="limit"
            data-cy="cards.details.limits.limit">/ {{ formatAmount(limit, account.currency, { trailingZeroDisplay: 'stripIfInteger' }) }}</span>
    </div>
    <template v-if="displayLimit">
      <line-gauge v-if="enabled"
                  size="medium"
                  :percentage="percentage" />
      <div v-else
           class="amount-consumed__placeholder"
           data-cy="cards.details.limits.placeholder">
        {{ $t('card.limit.no_limit') }}
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import { formatAmount, formatPartAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import LineGauge from './LineGauge.vue'

const accountStore = useAccountStore()
const { account } = storeToRefs(accountStore)

const props = defineProps({
  amount: {
    type: Number,
    default: 0
  },

  displayLimit: {
    type: Boolean,
    default: false
  },

  enabled: {
    type: Boolean,
    required: true
  },

  limit: {
    type: Number,
    default: 0
  }
})

const percentage = computed(() => {
  return props.limit !== 0 ? props.amount / props.limit * 100 : 0
})

const formattedValue = computed(() => {
  return formatPartAmount(props.amount, null, { trailingZeroDisplay: 'stripIfInteger' })
})
</script>

<style lang="stylus">
.amount-consumed
  position relative
  padding 0 3rem
  flex 1
  min-width 15rem
  max-width 26rem
  display flex
  flex-direction column
  align-items center
  &:not(:first-child)::before
    content ''
    background #EEF1F3
    position absolute
    height 9rem
    width 1px
    left 0
    top 50%
    transform translateY(-50%)

  &__title
    font-size 1.4rem
    margin-bottom 1.6rem
    color $gray-500

  &__values
    font-weight 500
    margin-bottom 1.5rem
    white-space nowrap

    .value
      display inline-block
      font-size 2.8rem
      color $gray-700
      &__dec
        font-size 0.6em
    .limit
      display inline-block
      font-size 1.6rem
      color $gray-500
      margin-left 1rem

  &__placeholder
    color $gray-400
</style>
