<template>
  <transition name="fade">
    <div v-if="visible"
         class="cookie-consent">
      <template v-if="step === 1">
        <div class="cookie-consent__body">
          <h3>{{ $t('cookies.consent.title') }}</h3>
          <i18n-t tag="p"
                  keypath="cookies.consent.description"
                  scope="global">
            <template #cookiesPolicyLink>
              <button class="btn-link btn-link--primary"
                      @click="openCookiesPolicy">
                {{ $t('button.cookies_policy') }}
              </button>
            </template>
          </i18n-t>
        </div>
        <div class="cookie-consent__footer">
          <div>
            <button class="btn btn--outline btn--sm"
                    @click="rejectAll">
              {{ $t('button.reject_all') }}
            </button>
          </div>
          <div>
            <button class="btn btn--outline-primary btn--sm"
                    @click="step = 2">
              {{ $t('button.customize') }}
            </button>
            <button class="btn btn--primary btn--sm"
                    @click="acceptAll">
              {{ $t('button.accept_all') }}
            </button>
          </div>
        </div>
      </template>
      <template v-if="step === 2">
        <div class="cookie-consent__body">
          <h3>{{ $t('cookies.preferences.title') }}</h3>
          <div class="cookie-item">
            <div class="cookie-item-toggle">
              <toggle-switch :model-value="preferences.functional"
                             disabled
                             toggle-class="switch-btn--sm" />
              <span class="cookie-item-toggle__label">{{ $t('cookies.preferences.functional.name') }}</span>
            </div>
            <p class="cookie-item__desc">
              {{ $t('cookies.preferences.functional.description') }}
            </p>
          </div>
          <div class="cookie-item">
            <div class="cookie-item-toggle">
              <toggle-switch v-model="preferences.analytics"
                             toggle-class="switch-btn--sm" />
              <span class="cookie-item-toggle__label">{{ $t('cookies.preferences.analytics.name') }}</span>
            </div>
            <p class="cookie-item__desc">
              {{ $t('cookies.preferences.analytics.description') }}
            </p>
          </div>
        </div>
        <div class="cookie-consent__footer">
          <div />
          <div>
            <button class="btn btn--outline btn--sm"
                    @click="step = 1">
              {{ $t('button.back') }}
            </button>
            <button class="btn btn--primary btn--sm"
                    @click="save">
              {{ $t('button.save') }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import { storeToRefs } from 'pinia'

import links from '@/config/links'
import { useAnalyticStore } from '@/stores/analytics'
import { ECookieStatus } from '@/types/analytics.d'

import ToggleSwitch from '@/components/ToggleSwitch.vue'

import Iframe from './modals/Iframe.vue'

export default {
  name: 'CookieConsent',

  components: {
    ToggleSwitch
  },

  setup () {
    const analyticStore = useAnalyticStore()

    const { cookies } = storeToRefs(analyticStore)

    return { analyticStore, cookies }
  },

  data: () => {
    return {
      visible: false,
      step: 1,
      preferences: {
        functional: true,
        analytics: false
      }
    }
  },

  computed: {
    noCookieRoute () {
      return this.$route.matched.some(record => record.meta.noCookie === true)
    }
  },

  mounted () {
    this.initCookieConsent()
  },

  methods: {
    initCookieConsent () {
      if (this.noCookieRoute) {
        return
      }

      if (this.cookies.analytics === ECookieStatus.UNDEFINED) {
        this.visible = true
      }
    },

    rejectAll () {
      this.chooseCookies({ analytics: ECookieStatus.NOT_CONSENTED, functional: ECookieStatus.CONSENTED })
    },

    acceptAll () {
      this.chooseCookies({ analytics: ECookieStatus.CONSENTED, functional: ECookieStatus.CONSENTED })
    },

    save () {
      this.chooseCookies(this.preferences)
    },

    openCookiesPolicy () {
      if (this.$config.isCookiePolicyLinkType()) {
        return window.open(`${links.COMMERCIAL.BASE}${this.$t('message.info.cookie.link')}`)
      }

      if (this.$config.isCookiePolicyIframeType()) {
        return this.appStore.showModal(
          Iframe,
          { source: links.COMMERCIAL.COOKIES },
          { wrapperClass: 'modal--iframe modal--fullscreen modal--white' }
        )
      }
    },

    chooseCookies (preferences) {
      this.analyticStore.setCookiePreferences(preferences)
      this.$bus.emit('analytics-cookie-consent')
      this.visible = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.cookie-consent
  position fixed
  z-index 999
  left 0
  bottom 0
  width 100%
  opacity 1
  transition all 0.3s ease
  background-color #fff
  padding 2.8rem
  box-shadow 0 12px 50px rgba(41, 45, 53, 0.1)
  +min-screen($md-screen)
    left 3.5rem
    bottom 3.5rem
    width 40rem
    border-radius 1rem

  &__body
    margin-bottom 2.8rem

    h3
      font-weight 500
      font-size 1.7rem
      margin-bottom 2rem

    p
      font-size 1.3rem
      line-height 2
      margin-bottom 0

    .cookie-item
      border 1px solid $colorLightGray
      padding 2rem

      + .cookie-item
        margin-top 1.6rem

      &-toggle
        &__label
          font-weight 500
          margin-left 1.5rem
          vertical-align middle

      &__desc
        margin-bottom 0
        font-size 1.2rem
        line-height 1.7
        color $colorShinyGray

    .cookie-item-toggle + .cookie-item__desc
      margin-top 1.6rem

  &__footer
    display flex
    justify-content space-between

    .btn
      font-size 1.2rem
      border-radius 3px
      padding 0.95rem 2rem
      line-height 1.5
      min-width 4rem
      text-transform none
      letter-spacing normal

      +max-screen($sm-screen)
        padding-left 10px
        padding-right @padding-left

      + .btn
        margin-left 8px

.cookie-consent.fade
  &-enter-from, &-leave-to
    opacity 0
</style>
