<template>
  <svg viewBox="0 0 200 160">
    <g fill="#7A818C">
      <path d="m112.06 63 1.5 0 0-20-1.5 0 0 20zm18.43-6.75-.49.75-.45.75-2.92 4.86 1.29.77 3.38-5.64.45-.75.45-.75 4.8-7.85-1.29-.77-5.22 8.63zm-33.91.75-.45-.75-5.13-8.64-1.29.77 4.72 7.86.45.75.45.75 3.38 5.64 1.29-.77-3-4.86-.42-.75z" />
      <path fill-opacity=".2"
            d="m138.81 57.75-5.43 0-3.92 6.54c-.4591.7663-1.2921 1.2295-2.1853 1.2151-.8932-.0144-1.7109-.5043-2.145-1.285-.4341-.7807-.4187-1.7338.0403-2.5001l2.38-4-12.24 0 0 5.28c0 1.3807-1.1193 2.5-2.5 2.5-1.3807 0-2.5-1.1193-2.5-2.5l0-5.25-11.24 0 2.38 4c.4591.7663.4745 1.7194.0403 2.5001-.4341.7807-1.2518 1.2706-2.145 1.285-.8932.0144-1.7263-.4488-2.1853-1.2151l-3.92-6.54-3 0c-2.7218.0031-5.3427-1.0303-7.33-2.89l-1.74-1.62c-1.7105-1.6017-3.9667-2.492-6.31-2.49l-18.05 0c-2.3355-.0001-4.2335 1.8845-4.25 4.22l0 64.79 7.55-46c.601-3.7551 3.8471-6.5133 7.65-6.5l75.3 0 0-5.29c0-2.3472-1.9028-4.25-4.25-4.25z" />
      <path fill-opacity=".33"
            d="m141.2456 124-84.4912 0c-.4166 0-.7544.3358-.7544.75 0 .4142.3378.75.7544.75l84.4912 0c.4166 0 .7544-.3358.7544-.75 0-.4142-.3378-.75-.7544-.75z" />
      <path d="m110.25 58 0-1.5-12.13 0 .88 1.5 11.25 0zm18.13-1.5-13.13 0 0 1.5 12.23 0 .9-1.5zm23.86 13c-1.0963-1.2764-2.6974-2.0075-4.38-2l-3.36 0 0-5.25c-.0055-3.1734-2.5766-5.7445-5.75-5.75l-4.53 0-.45.75-.45.75 5.43 0c2.3472 0 4.25 1.9028 4.25 4.25l0 5.25-75.3 0c-3.8218-.0381-7.0962 2.726-7.7 6.5l-7.5 46 0-64.75c0-2.3472 1.9028-4.25 4.25-4.25l18.06 0c2.3433-.002 4.5995.8883 6.31 2.49l1.74 1.62c1.9873 1.8597 4.6082 2.8931 7.33 2.89l3 0-.45-.75-.45-.75-2.1 0c-2.3447-.0007-4.6011-.8946-6.31-2.5l-1.74-1.62c-1.9873-1.8597-4.6082-2.8931-7.33-2.89l-18.06 0c-3.1773.0055-5.75 2.5827-5.75 5.76l0 74c.0016.0963.022.1915.06.28.0149.0277.0316.0544.05.08.0272.0538.0608.1042.1.15l.09.07c.0431.0352.0901.0654.14.09l.12 0 .13 0 92.06 0c.3678-.0002.6812-.267.74-.63l9-55.19c.2635-1.6308-.1883-3.2961-1.24-4.57l-.01-.03zm-.19 4.41-8.94 54.56-90.48 0 8.9-54.26c.5004-3.015 3.1138-5.2218 6.17-5.21l76.05 0c.1598-.0678.3402-.0678.5 0l3.61 0c1.2471.0011 2.4308.5498 3.2375 1.5009.8067.9511 1.1549 2.2085.9525 3.4391l0-.03zm-94.3-18.41c-.4142 0-.75.3358-.75.75 0 .4142.3358.75.75.75l19 0c.4142 0 .75-.3358.75-.75 0-.4142-.3358-.75-.75-.75l-19 0z" />
    </g>
  </svg>
</template>

<script>
export default {}
</script>
