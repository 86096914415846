<template>
  <div class="sidepanel__content">
    <transition name="fade-delay">
      <file-viewer v-if="displayFileViewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <div class="transaction-summary">
          <h2 class="transaction-amount">
            {{ formatAmount(model.amount, model.currency) }}
          </h2>
          <p class="transaction-name">
            {{ model.label }}
          </p>
          <core-badge v-if="model.sequence_type === 'FRST'"
                      :theme="ECoreBadgeTheme.Warning"
                      :fill="ECoreBadgeFill.Shaded"
                      :size="ECoreBadgeSize.Large"
                      :value="$t('sidepanel.debit.badge.first_debit')"
                      class="transaction-badge" />
          <template v-if="model.operation_id !== null">
            <button class="btn btn--default btn-attachment btn-attachment--float"
                    @click="showFile">
              <component :is="documentIcon"
                         class="ic ic--white"
                         :class="documentIcon" />
            </button>
          </template>
        </div>
      </div>
      <div class="sidepanel-body">
        <div class="sidepanel-details">
          <h3 class="head-title">
            {{ $t('sidepanel.debit.title') }}
          </h3>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('general.status') }}</span>
            <span class="entry-value right">{{ model.status_label }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('table.frequency') }}</span>
            <span class="entry-value right">{{ model.unique ? $t('general.unique') : $t('general.periodic') }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.debit_type") }}</span>
            <span class="entry-value right">SEPA {{ model.transaction_type }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.debit_date") }}</span>
            <span class="entry-value right">{{ formatDateText(model.collection_date, 'll') }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('table.creditor') }}</span>
            <span class="entry-value right">{{ model.creditor_name }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('table.reference') }}</span>
            <span class="entry-value right">{{ model.mandate_unique_reference }}</span>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer v-if="canAddMandate || model.mandate">
      <template v-if="canAddMandate">
        <div class="sidepanel-footer__text">
          <ic-info class="ic ic--gray" />
          <p class="text--center">
            {{ $t('sidepanel.debit.add_to_mandate_info') }}
          </p>
        </div>
        <button class="btn btn--sm btn--block btn--default"
                @click="addMandate">
          {{ $t('button.save_mandate') }}
        </button>
      </template>
      <button v-else-if="model.mandate"
              class="btn btn--sm btn--block btn--outline"
              @click="showMandate">
        {{ $t('button.see_mandate') }}
      </button>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { formatDateText } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import CreateMandate from '@/pages/debits/modal/CreateMandate.vue'

import FileViewer from '@/components/FileViewer.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'

export default {
  components: {
    CoreBadge,
    ComponentSidepanelFooter,
    FileViewer,
    IcInfo
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore }
  },

  data () {
    return {
      displayFileViewer: false
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    hasDocument () {
      return this.model.documents?.length
    },

    documentIcon () {
      return this.hasDocument ? IcDocument : IcAttachment
    },

    canAddMandate () {
      return !this.model.is_handled && this.model.transaction_type === 'B2B'
    }
  },

  mounted () {
    this.$bus.on('mandate-save-success', this.updateDebit)
    this.$bus.on('hide-sidepanel', this.closeFileViewer)
  },

  beforeUnmount () {
    this.$bus.off('mandate-save-success', this.updateDebit)
    this.$bus.off('hide-sidepanel', this.closeFileViewer)
  },

  methods: {
    formatAmount,

    formatDateText,

    closeFileViewer () {
      this.displayFileViewer = false
    },

    showFile () {
      if (!this.hasDocument) {
        return this.appStore.showDropzoneModal(
          this.$i18n.t('title.attach_invoice'),
          this.model,
          {
            url: `/operations/${this.model.operation_id}/documents`
          })
      }

      this.appStore.showFileViewer({
        content: this.model.documents,
        isMultiDoc: true,
        canEdit: true,
        operationId: this.model.operation_id,
        documentsUrl: `/operations/${this.model.operation_id}/documents`
      })
      this.displayFileViewer = true
    },

    showMandate () {
      this.appStore.showFileViewer({
        content: [this.model.mandate],
        displayFullScreen: true
      })
    },

    addMandate () {
      this.appStore.showModal(CreateMandate, {
        model: {
          label: this.model.label,
          ics: this.model.creditor_sepa_id,
          rum: this.model.mandate_unique_reference,
          unique: this.model.unique
        }
      },
      { wrapperClass: 'modal--md' }
      )
    },

    updateDebit () {
      showToastSuccess(this.$i18n.t('message.success.mandate.save'))
      this.$bus.emit('debit-handled', this.model.id)
    }
  }
}
</script>
