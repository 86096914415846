<template>
  <div ref="periodPicker"
       class="period-picker"
       :class="{ 'is-open': open }"
       :data-cy="`month-period-picker.${dataCy}`">
    <div class="period-picker__header">
      <div class="period-picker__title">
        {{ $t('custom_period_picker.title') }}
      </div>
      <div class="period-picker__subtitle">
        {{ contentSubtitle }}
      </div>
    </div>
    <div class="period-picker__body">
      <div class="period">
        <label for="period-from"
               class="period__label">
          {{ $t('form.start_period.label') }}
        </label>
        <div class="period-from__value">
          <component-datepicker ref="from"
                                v-model="from"
                                :data-cy="`month-period-picker.${dataCy}.from`"
                                name="from"
                                input-class="form-control"
                                month-picker
                                :language="$i18n.locale"
                                :placeholder="$t('form.common.select')"
                                format="MMMM yyyy"
                                :disabled-days="fromDisabled"
                                @selected="onFromSelect" />
        </div>
      </div>

      <div class="period">
        <label for="period-to"
               class="period__label">
          {{ $t('form.end_period.label') }}
        </label>
        <div class="period-to__value">
          <component-datepicker ref="to"
                                v-model="to"
                                :data-cy="`month-period-picker.${dataCy}.to`"
                                wrapper-class="datepicker--right"
                                name="to"
                                input-class="form-control"
                                month-picker
                                :language="$i18n.locale"
                                :placeholder="$t('form.common.select')"
                                :disabled-days="toDisabled"
                                format="MMMM yyyy" />
        </div>
      </div>
    </div>

    <div class="period-picker__footer">
      <button class="btn btn--gray"
              type="button"
              @click="cancel">
        {{ $t('button.cancel') }}
      </button>
      <button class="btn btn--default"
              type="button"
              :disabled="isApplyButtonDisabled"
              :data-cy="`month-period-picker.${dataCy}.submit`"
              @click="periodSelect">
        {{ $t('button.apply') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

import ComponentDatepicker from '@/components/Datepicker.vue'

dayjs.extend(minMax)

export default {
  components: {
    ComponentDatepicker
  },

  props: {
    dataCy: {
      type: String,
      default: 'default'
    },

    disabledMonths: {
      type: [Object, null],
      default: null
    },

    maxPeriod: {
      type: Number,
      default: null
    },

    period: {
      type: Object,
      required: true
    }
  },

  emits: ['period-select', 'cancel'],

  setup () {
    const periodPicker = ref()
    const open = ref(false)

    onClickOutside(periodPicker, () => {
      open.value = false
    })

    return { open, periodPicker }
  },

  data () {
    return {
      from: null,
      to: null
    }
  },

  computed: {
    fromDisabled () {
      return {
        from: this.disabledMonths?.after ? dayjs(this.disabledMonths.after, 'YYYY-MM').toDate() : null,
        to: this.disabledMonths?.before ? dayjs(this.disabledMonths.before, 'YYYY-MM').toDate() : null
      }
    },

    isApplyButtonDisabled () {
      return !this.from || !this.to
    },

    toDisabled () {
      if (this.from) {
        const limits = []
        if (this.maxPeriod) limits.push(dayjs(this.from).add(this.maxPeriod - 1, 'month'))
        if (this.disabledMonths?.after) limits.push(dayjs(this.disabledMonths.after, 'YYYY-MM'))
        return {
          from: limits.length ? dayjs.min(limits).toDate() : null,
          to: dayjs(this.from).toDate()
        }
      }
      return this.fromDisabled
    },

    contentSubtitle () {
      return this.maxPeriod ? this.$t('custom_period_picker.content.max', { x: this.maxPeriod }) : this.$t('custom_period_picker.content.default')
    }
  },

  watch: {
    open (value) {
      if (value) {
        this.to = this.period.to
        this.from = this.period.from
      }
    }
  },

  methods: {

    cancel () {
      this.togglePicker(false)
      this.$emit('cancel')
    },

    onFromSelect (value) {
      const diff = dayjs(this.to).diff(value, 'month')
      if (this.to && (diff < 0 || (this.maxPeriod !== null && diff > this.maxPeriod - 1))) {
        this.to = null
      }
      this.$refs.to.showCalendar()
    },

    periodSelect () {
      if (this.isApplyButtonDisabled) return
      this.togglePicker(false)
      this.$emit('period-select', { from: this.from, to: this.to })
    },

    togglePicker (value = null) {
      if (value === null) {
        this.open = !this.open
      } else {
        this.open = value
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.period-picker
  position absolute
  z-index 20
  top 100%
  left 0
  width 44rem
  background-color $colorWhite
  margin-top 0.5rem
  transition all 0.5s $easeOutCirc
  opacity 0
  visibility hidden
  transform scale3d(0.9, 0.9, 1)
  transform-origin top center
  user-select none
  border-radius $border-radius
  border 1px solid $borderColor
  box-shadow 0 7px 10px rgba($colorDarkGray, 0.05)

  &.is-open
    opacity 1
    visibility visible
    transform scale3d(1, 1, 1)
    user-select auto

  &__header
    padding 1.5rem

  &__body
    padding 0.5rem 1.5rem 1.5rem
    display flex

  &__footer
    padding 1.5rem
    border-top 1px solid $gray-100
    display flex
    justify-content flex-end

    > .btn
      margin-left 1rem

  &__title
    font-size 1.5rem
    font-weight 500
    margin-bottom 0.4rem

  &__subtitle
    color $colorShinyGray

  &__content
    font-size 1.4rem

.period-picker__body
  .period
    flex 1

    &:first-child
      margin-right 1.5rem

    &__label
      display block
      color $colorShinyGray
      margin-bottom 0.8rem
</style>
