import i18n from '@/locales'
import { useAccountStore } from '@/stores/account'

export const getCurrencyNumberFormat = (currency, options = {}) => {
  return new Intl.NumberFormat(
    i18n.global.locale,
    { style: 'currency', currency: currency.iso, currencyDisplay: 'narrowSymbol', ...options }
  )
}

export const getCurrencySymbolPosition = currency => {
  const parts = getCurrencyNumberFormat(currency).formatToParts(0)

  const index = parts.findIndex(p => p.type === 'currency')

  return index === 0 ? 'left' : 'right'
}

export const getCurrencySymbol = currency => {
  const parts = getCurrencyNumberFormat(currency).formatToParts(0)

  return parts.find(p => p.type === 'currency')?.value || ''
}

export const formatAmount = (amount, currency = null, options = {}) => {
  if (!currency) currency = useAccountStore().account?.currency
  if (options.isNegative) amount = amount * -1
  delete options.isNegative
  return getCurrencyNumberFormat(currency, options).format(amount)
}

export const formatPartAmount = (amount, currency = null, options = {}) => {
  if (!currency) currency = useAccountStore().account?.currency
  return getCurrencyNumberFormat(currency, options).formatToParts(amount)
    .reduce((acc, cur) => {
      if (cur.type === 'decimal') { acc.push('') }
      acc[acc.length - 1] += cur.value
      return acc
    }, [''])
}

export const formatCompactAmount = (amount, currency = null) => {
  currency = currency || useAccountStore().account?.currency

  if (i18n.global.locale === 'fr') {
    const format = getCurrencyNumberFormat(currency, { notation: 'compact' })
    const parts = format.formatToParts(amount)

    const index = parts.findLastIndex(el => el.type === 'literal')
    parts.splice(index, 1)

    return parts.map(el => el.value).join('')
  } else {
    return formatAmount(amount, currency, { notation: 'compact' })
  }
}

export const formatPercentage = value => {
  return new Intl.NumberFormat(i18n.global.locale, { style: 'percent', minimumFractionDigits: 2 }).format(value / 100)
}

export const formatNumber = value => {
  return new Intl.NumberFormat(i18n.global.locale).format(value || 0)
}

export const formatDistance = value => {
  return new Intl.NumberFormat(i18n.global.locale, { style: 'unit', unit: 'kilometer' }).format(value)
}

// Convert bytes into megabytes
export const formatFileSize = size => {
  // Math.ceil() to round up 0,0001MB => return 0,01MB (and not 0MB)
  return new Intl.NumberFormat(i18n.global.locale, { style: 'unit', unit: 'megabyte' }).format(Math.ceil(size / 1e4) / 100)
}

export const isNumber = value => {
  return !isNaN(parseFloat(value))
}

export function chartFormatNumber (num) {
  const precision = 1
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 }
  ]

  const found = map.find(x => Math.abs(num) >= x.threshold)
  if (found) {
    return (num / found.threshold).toFixed(precision).replace('.0', '') + found.suffix
  }

  return num
}
