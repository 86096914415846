<template>
  <svg viewBox="0 0 13 25">
    <path d="M0 .67.742 0l11.307 12.525L.737 24.676l-.732-.682 10.688-11.48z" />
  </svg>
</template>

<script>
export default {
}
</script>
