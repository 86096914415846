import { formatAmount } from '@/helpers/utils/number'
import { Model } from '@/models/Model'
import { ModelTreasuryFlowOperation } from '@/models/Treasury/ModelTreasuryFlowOperation'
import { ETreasuryFlowGranularity, ITreasuryFlow, ITreasuryFlowOperation } from '@/types/treasury.d'
export class ModelTreasuryFlow extends Model {
  readonly total_in_amount?: number
  readonly total_out_amount?: number
  readonly total_result_amount?: number
  readonly operations?: Array<ITreasuryFlowOperation>
  readonly granularity?: ETreasuryFlowGranularity

  private constructor (properties: Partial<ITreasuryFlow>) {
    super()

    this.total_in_amount = properties.total_in_amount || 0
    this.total_out_amount = properties.total_out_amount || 0
    this.total_result_amount = properties.total_result_amount || 0
    this.granularity = properties.granularity || ETreasuryFlowGranularity.Year
    this.operations = (properties.operations?.length) ? properties.operations.map(operation => ModelTreasuryFlowOperation.create(operation)) : []

    return this.onConstructed()
  }

  static create (properties: Partial<ITreasuryFlow>): ModelTreasuryFlow {
    return new ModelTreasuryFlow(properties || {})
  }

  get totalInAmountFormatted (): string {
    return formatAmount(this.total_in_amount)
  }

  get totalOutAmountFormatted (): string {
    return formatAmount(this.total_out_amount)
  }

  get totalResultAmountFormatted (): string {
    return formatAmount(this.total_result_amount)
  }

  get dateGranularity (): string {
    if (this.granularity === ETreasuryFlowGranularity.Day) return 'DD'
    else if (this.granularity === ETreasuryFlowGranularity.Week) return 'ddd'
    else if (this.granularity === ETreasuryFlowGranularity.Month) return 'MMM YY'
    else return 'YYYY'
  }
}
