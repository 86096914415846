<template>
  <svg viewBox="0 0 200 160">
    <path d="M149.94 91.55c-1.45-2.19-6.14-2.88-9.39-1.49a9.66 9.66 0 0 0-11.1-2.21L127.52 89a8.31 8.31 0 0 0-10-1.58l-8 4.41a.75.75 0 0 0 .72 1.31l8-4.41a6.88 6.88 0 0 1 7.94 1.06L120 93.35a.749.749 0 0 0 .74 1.3l7-4 2.46-1.48a8.2 8.2 0 0 1 9 1.63L126 98.35a.749.749 0 0 0 .74 1.3l14-8c2.71-1.46 7-.81 8 .74l.06.08c.463.514.679 1.204.59 1.89a1.85 1.85 0 0 1-.92 1.3l-35.36 21.28a19.172 19.172 0 0 1-16.54 1.44L81.9 112.9a12.709 12.709 0 0 0-10.8.87l-2.34 1.33-9-18 5.95-3.4a36.14 36.14 0 0 1 33.33-1l12.15 5.57h7.16a5.69 5.69 0 0 1 5.75 5.75.75.75 0 0 1-.75.75h-15.5a18.607 18.607 0 0 1-7.26-1.46l-9-3a.75.75 0 0 0-.47 1.42l8.94 3a19.996 19.996 0 0 0 7.79 1.56h15.5a2.25 2.25 0 0 0 2.25-2.25 7.167 7.167 0 0 0-7.25-7.25h-6.85l-11.85-5.43a37.6 37.6 0 0 0-34.69 1l-5.9 3.37-1.51-3a3.75 3.75 0 0 0-4.83-1.77L35 98.48a.75.75 0 0 0 .59 1.38l17.73-7.56a2.25 2.25 0 0 1 2.9 1.06l15.35 30.71a2 2 0 0 1 .15 1.53 2.178 2.178 0 0 1-1.19 1.21L52 135.32a.75.75 0 0 0 .31 1.43.738.738 0 0 0 .31-.07l18.5-8.5a3.67 3.67 0 0 0 2-2.08 3.45 3.45 0 0 0-.22-2.7l-3.48-7 2.41-1.38a11.21 11.21 0 0 1 9.53-.77L96 119.79a20.667 20.667 0 0 0 17.83-1.56L149.21 97a3.35 3.35 0 0 0 1.63-2.34 3.862 3.862 0 0 0-.9-3.11Z"
          :fill="colorDark" />
    <path d="M100.33 23.25a.75.75 0 0 0-.75.75v9a.75.75 0 0 0 1.5 0v-9a.75.75 0 0 0-.75-.75Zm-17.85 5.33a.75.75 0 1 0-1.3.75l4.5 7.79a.75.75 0 1 0 1.3-.75l-4.5-7.79Zm-6 17.77-7.79-4.5a.75.75 0 1 0-.75 1.3l7.79 4.5a.75.75 0 1 0 .75-1.3Zm-4.13 13.9h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5Zm46.87-31.94a.75.75 0 0 0-1 .27l-4.5 7.79a.75.75 0 0 0 1.3.75l4.5-7.79a.752.752 0 0 0-.31-1.02h.01Zm18.12 31.94h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5ZM133 42.13a.75.75 0 0 0-1-.27l-7.79 4.5a.75.75 0 0 0 .75 1.3l7.79-4.5a.75.75 0 0 0 .25-1.03Z"
          :fill="colorGrey" />
    <path d="M91.7 61.3h-3.9v-1.5h2.6c1.4-7.5 7.6-12.9 14.9-12.9 1.9 0 3.8.4 5.6 1.1l-.6 1.4c-1.6-.7-3.3-1-5-1-6.8 0-12.5 5.2-13.5 12.3l-.1.6Zm17.1 0H96.2l.3-.9c1.1-4.2 4.8-7.1 8.9-7.1 1.3 0 2.6.3 3.9.9l-.7 1.3c-1-.5-2.1-.8-3.2-.8-3.1 0-5.9 2-7.1 5h10.5v1.6ZM66.12 122a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM96.4 65.7c1.1 4.2 4.8 7.1 8.9 7.1 1.4 0 2.8-.3 4.1-1l-.7-1.3c-1.1.5-2.2.8-3.4.8-3.1 0-5.9-2-7.1-5h10.6v-1.5H96.1l.3.9Zm-6 .6c1.4 7.5 7.6 12.9 14.9 12.9 1.8 0 3.7-.4 5.5-1.2l-.6-1.4c-1.6.7-3.3 1-4.9 1-6.7 0-12.4-5.1-13.5-12.2l-.1-.6h-3.9v1.5h2.6Z"
          :fill="colorPrimary" />
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  props: {
    colorGrey: {
      type: String,
      default: config.SVG.COLOR.GREY
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
