<template>
  <svg viewBox="0 0 24 24">
    <path d="M13.16 19.413a5.34 5.34 0 0 1-7.599 0 5.442 5.442 0 0 1 0-7.63l7.554-7.595a4.016 4.016 0 0 1 5.703 0A4.045 4.045 0 0 1 20 7.05a4.045 4.045 0 0 1-1.182 2.862l-6.93 6.964a2.654 2.654 0 0 1-1.9.803 2.655 2.655 0 0 1-1.9-.803c-.503-.506-.785-1.194-.785-1.912s.282-1.405.785-1.912l4.395-4.409a.442.442 0 0 1 .632 0l.48.477a.452.452 0 0 1 0 .639L9.2 14.167c-.372.451-.34 1.115.071 1.53s1.069.441 1.513.063l6.93-6.956c.462-.463.721-1.093.721-1.75s-.259-1.287-.72-1.75a2.454 2.454 0 0 0-3.488 0l-7.571 7.594a3.855 3.855 0 0 0 0 5.399 3.813 3.813 0 0 0 5.382 0l5.027-5.048a.45.45 0 0 1 .64 0l.472.477a.434.434 0 0 1 0 .63z" />
  </svg>
</template>

<script>
export default {
}
</script>
