import { ECoreBannerTheme } from '@common/core-ui'
import contentDisposition from 'content-disposition'
import { saveAs } from 'file-saver'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { showToastError, showToastSuccess } from '@/helpers/utils/notification'
import { globalTranslation } from '@/locales'
import { useAppStore } from '@/stores/app'

export const clearInputs = () => {
  const inputs = document.getElementsByTagName('input')
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = ''
  }
}

export const checkCompletedInputs = () => {
  const inputs = document.getElementsByTagName('input')
  let completedInputs = 0
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].value && inputs[i].type !== 'radio') {
      completedInputs += 1
    }
  }
  return completedInputs > 0
}

export const getFlatErrorsArray = errors => errors.reduce((acc, cur) => cur.errors && cur.field ? acc.concat(getFlatErrorsArray(cur.errors)) : acc.concat(cur), [])

export const downloadFileFromUrl = async (url, params = {}, successIfNoContent = false) => {
  if (!url) {
    useAppStore().showBanner(ECoreBannerTheme.Danger, 'Adresse du fichier inconnue', false)
    return
  }

  try {
    const { data, headers, status } = await axiosClient.get(url, { responseType: 'blob', params })
    if (headers['content-disposition']) {
      const filename = contentDisposition.parse(headers['content-disposition']).parameters.filename
      saveAs(data, typeof filename === 'string' ? filename : 'file.pdf')
    } else if (status === 200) {
      const { message } = JSON.parse(await data.text())
      showToastSuccess(message)
    } else if (status === 204) {
      if (successIfNoContent) showToastSuccess(globalTranslation('message.success.operation.export'))
      else showToastError(globalTranslation('alerts.download.empty_response'))
    }
    bus.emit('download-export-success')
  } catch (e) {
    console.error(e)
  }
}

export const downloadFileFromBlob = (blob, filename = 'file.pdf') => {
  saveAs(blob, filename)
}
