<template>
  <svg viewBox="0 0 16 16">
    <path v-if="unlock"
          d="M8.01 1.185c2.034 0 3.683 1.619 3.683 3.602l-.001-.008h-1.636v-.203c0-1.103-.918-2.002-2.045-2.002-1.127 0-2.046.897-2.046 2.002v2.207H4.352a3.546 3.546 0 0 1-.023-.402V4.787c0-1.99 1.653-3.602 3.682-3.602Z" />
    <path v-else
          d="M8.001 1.98c2.034 0 3.682 1.62 3.682 3.603v.796c0 .136-.007.27-.022.402h-1.615V5.583c0-1.102-.918-2.002-2.045-2.002-1.127-.001-2.045.898-2.045 2.002v1.198H4.342l-.022-.402v-.796c0-1.99 1.652-3.602 3.681-3.602Z" />
    <path d="M4.301 6.781h7.402a1 1 0 0 1 .798.798v5.604a1 1 0 0 1-.798.798H4.3a.805.805 0 0 1-.799-.798V7.579a1 1 0 0 1 .8-.798z" />
  </svg>
</template>

<script>
export default {
  props: {
    unlock: Boolean
  }
}
</script>
