<template>
  <validation-form v-slot="{ errors, isSubmitting }"
                   @submit="onSubmit">
    <modal-dialog-prompt :title="$t('modal.reject_virtual_card.label')"
                         @close="$emit('close')">
      <template #body>
        <div class="form">
          <div class="form__row row">
            <div :class="{ error: errors.reason }"
                 class="form-group col col--sm">
              <label class="label input__label"
                     for="reason">
                {{ $t('form.reject_reason.label') }}
              </label>
              <textarea id="reason"
                        v-model="reason"
                        data-cy="reject-card-request.reason-textarea"
                        :placeholder="$t('form.reject_reason.placeholder')"
                        class="form-control"
                        name="reason"
                        rows="5" />
            </div>
          </div>
        </div>
      </template>

      <template #footerButtons>
        <button class="btn btn--gray"
                type="button"
                @click="$emit('close')">
          {{ $t('button.cancel') }}
        </button>
        <component-button data-cy="reject-card-request.submit-button"
                          :disabled="isSubmitting"
                          :label="$t('button.confirm')"
                          type="submit"
                          wrapper-class="btn btn--primary"
                          @click="onSubmit" />
      </template>
    </modal-dialog-prompt>
  </validation-form>
</template>

<script>
import * as yup from 'yup'

import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ComponentButton,
    ModalDialogPrompt
  },

  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()

    return { appStore, cardStore }
  },

  data () {
    return {
      reason: '',
      schema: yup.object({
        reason: yup.string().required()
      })
    }
  },

  methods: {
    async onSubmit () {
      const success = await this.cardStore.rejectCardRequest(this.uuid, this.reason)
      if (success) {
        this.$emit('close')
        this.appStore.closeSidePanel()
        this.$bus.emit('refresh-cards-list')
      }
    }
  }

}
</script>
