<template>
  <svg viewBox="0 0 24 24">
    <path d="M18 11a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm-.007 8.187a.978.978 0 0 0-.69.28.963.963 0 0 0-.28.67c0 .133.026.257.08.37.053.113.123.213.21.3a.898.898 0 0 0 .305.2 1 1 0 0 0 .375.07c.133 0 .26-.025.38-.075s.225-.117.315-.2.16-.183.21-.3a.943.943 0 0 0 0-.74c-.05-.117-.12-.217-.21-.3s-.195-.15-.315-.2-.247-.075-.38-.075zM17 3a2 2 0 0 1 1.995 1.85L19 5l.001 4.062a8.079 8.079 0 0 0-2.001 0V5H5v14h5.252c.183.71.46 1.382.819 2.001L5 21a2 2 0 0 1-1.995-1.85L3 19V5a2 2 0 0 1 1.85-1.995L5 3zm1.833 9.897h-1.68v4.71h1.68zM11.5 11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm3-3a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
