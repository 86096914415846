<template>
  <div class="campaign-block"
       :class="{ 'campaign-block--disabled': !canClaimCampaign }">
    <user-avatar :picture="campaign.partner.logo"
                 bordered
                 class="campaign-block__logo"
                 :size="60"
                 :uuid="campaign.uuid"
                 :rounded="false" />
    <div class="campaign-block__partner">
      {{ campaign.partner.label }}
    </div>
    <div class="campaign-block__label">
      {{ campaign.label }}
    </div>
    <core-button class="campaign-block__button"
                 :appearance="ECoreButtonAppearance.Outlined"
                 :size="ECoreButtonSize.Small"
                 :theme="canClaimCampaign ? ECoreButtonTheme.Primary : ECoreButtonTheme.GrayLow"
                 :text="t('loyalty.button.claim')"
                 @click="showCampaignDetail" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  ECoreButtonAppearance,
  ECoreButtonSize, ECoreButtonTheme
} from '@common/core-ui'

import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { useAppStore } from '@/stores/app'
import { ICampaignLight } from '@/types/loyalty.d'

import CampaignDetail from '@/pages/loyalty/sidepanel/CampaignDetail.vue'

import UserAvatar from '@/components/UserAvatar.vue'

const { t } = useI18n()
const appStore = useAppStore()

const props = defineProps<{
  campaign: ICampaignLight
}>()

const canClaimCampaign = computed(() => {
  return hasPermission(permissions.loyaltyCampaignsClaim, props.campaign)
})

function showCampaignDetail () {
  appStore.showSidePanel(
    CampaignDetail,
    { uuid: props.campaign.uuid },
    {
      wrapperClass: 'sidepanel--500',
      backgroundOverlay: true
    }
  )
}
</script>

<style lang="stylus" scoped>
.campaign-block
  box-shadow 0 0 0 1px $borderBoxColor inset
  border-radius .6rem
  display flex
  padding 3.2rem 2.8rem
  flex-direction column
  align-items center

  &__partner
    margin-top 2.8rem
    text-transform uppercase
    color $colorShinyGray

  &__label
    text-align center
    margin-top 1.2rem
    font-size 1.4rem
    font-weight 700
    color $colorDarkGray

  &__button
    margin-top 3rem

  &--disabled
    .campaign-block
      &__label
        color $disabledColor
</style>
