<template>
  <button class="btn btn--white"
          data-cy="filters.button"
          :class="{ 'btn--icon': !showText }"
          @click="$bus.emit('toggle-filters')">
    <i class="btn-icon"
       :class="{ 'btn-icon--left': showText }">
      <ic-search class="ic ic--20 ic--gray" />
    </i>
    <span v-if="showText">
      {{ $t("button.filter") }}
    </span>
  </button>
</template>

<script>
import IcSearch from '@/components/svg/icons/ic-search.vue'

export default {
  components: {
    IcSearch
  },

  props: {
    showText: {
      type: Boolean,
      default: false
    }
  }
}
</script>
