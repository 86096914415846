<template>
  <section data-cy="operations.list"
           class="section section-operations">
    <div class="section__heading">
      <div class="section__heading__left align-items-center">
        <h1 class="section-title">
          {{ $t("title.requests") }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters show-text />
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="numberOfResults"
                       is-section-operations
                       @filters="filters" />
    <tabs :data="segments" />
    <router-view :key="$route.name"
                 :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'
import { useExpenseRequestStore } from '@/stores/expense-request'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    ButtonFilters,
    ComponentFilters,
    Tabs
  },

  setup () {
    const accountStore = useAccountStore()
    const expenseRequestsStore = useExpenseRequestStore()

    const { account } = storeToRefs(accountStore)
    const { counts } = storeToRefs(expenseRequestsStore)

    return { account, counts, expenseRequestsStore }
  },

  data: function () {
    return {
      filter: {},
      numberOfResults: 0
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    segments () {
      return [
        {
          label: this.$i18n.t('general.pending'),
          route: { name: 'requests' },
          badgeValue: this.counts.toApprove
        },
        {
          label: this.$i18n.t('button.history'),
          route: { name: 'requests-done' }
        }
      ]
    }
  },

  watch: {
    '$route.name' () {
      this.getExpenseRequestsCounts()
    }
  },

  mounted () {
    this.getExpenseRequestsCounts()
    this.$bus.on('account-switched', this.getExpenseRequestsCounts)
    this.$bus.on('expense-request-approve-success', this.getExpenseRequestsCounts)
    this.$bus.on('expense-request-reject', this.getExpenseRequestsCounts)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.getExpenseRequestsCounts)
    this.$bus.off('expense-request-approve-success', this.getExpenseRequestsCounts)
    this.$bus.off('expense-request-reject', this.getExpenseRequestsCounts)
  },

  methods: {
    getExpenseRequestsCounts () {
      this.expenseRequestsStore.getExpenseRequestsCount()
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = filters
    }
  }
}
</script>
