<template>
  <div>
    <div v-if="isVirtualCard">
      <fieldset class="form__fieldset">
        <div class="group-title">
          <h3 class="title">
            {{ $t('form.title.general_settings') }}
          </h3>
        </div>
        <div class="form__fieldset__group">
          <div class="form__row row row--sm-bottom">
            <div v-if="!isVirtualCardClassic"
                 class="form-group col col--sm-6"
                 :class="{ error: errors.initial_amount }">
              <label class="label input__label">
                {{ isRecurringCard ? $t('form.amount.label') : $t('form.virtual_cards.cap.label') }}
              </label>
              <div class="input-group">
                <number-input v-model="settings.initial_amount"
                              class="form-control form-control--noborder"
                              name="initial_amount"
                              data-vv-as="initial_amount"
                              :decimal="!isRecurringCard"
                              default-zero
                              :minimum-fraction-digits="2"
                              :placeholder="$t('form.amount.placeholder')"
                              data-cy="cards.create.form.initial-amount" />
                <span v-if="typeof settings.initial_amount === 'number'"
                      class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
              </div>
            </div>
            <div v-if="isRecurringCard"
                 class="form-group col col--sm-6">
              <label class="label input__label">
                {{ $t("general.periodicity") }}
              </label>
              <component-dropdown :dropdown-height="21"
                                  dropdown-width="100%"
                                  :model="card.periodicity"
                                  :values="cardPeriods"
                                  label="periodicity"
                                  @select="onSelectPeriodicity">
                <template #trigger>
                  <div class="select">
                    <div class="form-control form-control--noborder">
                      {{ $t(`generate_ecard.choice.recurring.type.${card.periodicity}`) }}
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </div>
            <validation-field name="expiration"
                              :model-value="card.months_before_expiration">
              <div class="form-group col col--sm-6"
                   :class="{ error: errors.expiration }">
                <label class="label input__label">{{ $t('form.validity_time.label') }}</label>
                <component-lock-input v-if="cardExpirationItems.length === 1"
                                      id="dropdown-input-expiration"
                                      type="text"
                                      input-class="form-control form-control--noborder input__field"
                                      :placeholder="availableExpirationFilter(card.months_before_expiration)"
                                      disabled />
                <component-dropdown v-else
                                    id="dropdown-select-expiration"
                                    label="expiration"
                                    dropdown-width="100%"
                                    :values="cardExpirationItems.map(elem => elem.value)"
                                    :label-filter="availableExpirationFilter"
                                    :model="card.months_before_expiration"
                                    data-cy="cards.create.form.expiration"
                                    @select="onSelectExpiration">
                  <template #trigger>
                    <div class="select">
                      <div class="form-control form-control--noborder">
                        <span v-if="availableExpirationFilter(card.months_before_expiration)">
                          {{ availableExpirationFilter(card.months_before_expiration) }}
                        </span>
                        <span v-else
                              class="text-muted">
                          {{ $t('form.common.select') }}
                        </span>
                      </div>
                    </div>
                  </template>
                </component-dropdown>
              </div>
            </validation-field>
            <div class="form-group col col--sm">
              <label class="label input__label">
                {{ $t('form.virtual_cards.label.label') }} ({{ $t('general.optional') }})
              </label>
              <validation-field :model-value="card.label"
                                class="form-control form-control--noborder"
                                type="text"
                                name="label"
                                :placeholder="$t('form.virtual_cards.label.placeholder')"
                                maxlength="24"
                                @input="value => updateField('label', value.target.value)" />
            </div>
            <div class="form-group col col--sm-6">
              <label class="label input__label">
                {{ $t('form.virtual_cards.reason.label') }} ({{ $t('general.optional') }})
              </label>
              <validation-field :model-value="card.reason"
                                name="reason"
                                class="form-control form-control--noborder"
                                type="text"
                                :placeholder="$t('form.virtual_cards.reason.placeholder')"
                                maxlength="24"
                                @input="value => updateField('reason', value.target.value)" />
            </div>
          </div>
        </div>
      </fieldset>
      <security :settings="settings"
                :is-virtual-card="isVirtualCard"
                @update-card-settings="updateCardSettings" />
      <proof-delay v-if="!isOneTimeCard"
                   :settings="settings"
                   @update-card-settings="updateCardSettings" />
      <card-limits v-if="!isRecurringCard && !isOneTimeCard"
                   :is-physical-card="false"
                   :settings="settings"
                   @update-card-settings="updateCardSettings" />
      <authorized-days :settings="settings"
                       @update-card-settings="updateCardSettings" />
    </div>
    <div v-else>
      <security :settings="settings"
                :is-virtual-card="isVirtualCard"
                @update-card-settings="updateCardSettings" />
      <proof-delay :settings="settings"
                   @update-card-settings="updateCardSettings" />
      <card-limits :settings="settings"
                   is-physical-card
                   @update-card-settings="updateCardSettings" />
      <authorized-days :settings="settings"
                       @update-card-settings="updateCardSettings" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import { isClassicVirtualCard, isOneTimeVirtualCard, isRecurringVirtualCard, isVirtualCard } from '@/helpers/utils/card'
import { formatDateText } from '@/helpers/utils/date'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useCardStore } from '@/stores/card'

import AuthorizedDays from '@/pages/cards/components/settings/AuthorizedDays.vue'
import CardLimits from '@/pages/cards/components/settings/CardLimits.vue'
import ProofDelay from '@/pages/cards/components/settings/ProofDelay.vue'
import Security from '@/pages/cards/components/settings/Security.vue'

import ComponentDropdown from '@/components/Dropdown.vue'
import ComponentLockInput from '@/components/LockInput.vue'
import NumberInput from '@/components/NumberInput.vue'

dayjs.extend(customParseFormat)

export default {
  components: {
    ComponentLockInput,
    ComponentDropdown,
    ProofDelay,
    AuthorizedDays,
    NumberInput,
    CardLimits,
    Security
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings', 'update-card-field'],

  setup () {
    const accountStore = useAccountStore()
    const cardStore = useCardStore()

    const { account } = storeToRefs(accountStore)

    return { account, cardStore }
  },

  data () {
    return {
      cardExpirationItems: []
    }
  },

  computed: {
    settings: {
      get () {
        return this.card.settings
      },

      set (value) {
        this.$emit('update-card-settings', value)
      }
    },

    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    isVirtualCardClassic () {
      return isClassicVirtualCard(this.card)
    },

    isOneTimeCard () {
      return isOneTimeVirtualCard(this.card)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.card)
    },

    cardPeriods () {
      return [
        {
          id: 'daily',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.daily')
        },
        {
          id: 'weekly',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.weekly')
        },
        {
          id: 'monthly',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.monthly')
        },
        {
          id: 'annually',
          label: this.$i18n.t('generate_ecard.choice.recurring.type.annually')
        }
      ]
    }
  },

  async created () {
    if (!this.isVirtualCard) return
    this.cardExpirationItems = await this.cardStore.getAvailableExpirationDates(this.card.type)

    if (this.cardExpirationItems?.length === 1) {
      this.onSelectExpiration(this.cardExpirationItems[0].value)
    }
  },

  methods: {
    getCurrencySymbol,

    onSelectExpiration (value) {
      this.$emit('update-card-field', { months_before_expiration: value, expiration_date: dayjs().add(value, 'months').format('MM/YY') })
    },

    availableExpirationFilter (value) {
      if (!value) {
        return
      }

      if (!this.cardExpirationItems.length) {
        return
      }

      const expirationDate = this.cardExpirationItems.find(el => el.value === parseInt(value)).expiration_date

      const label = value % 12 !== 0
        ? this.$i18n.t('general.months', { n: value })
        : `${value / 12} ${this.$i18n.tc('general.years', value / 12).toLowerCase()}`

      return `${label} (${this.$i18n.t('general.end_of').toLowerCase()} ${formatDateText(expirationDate, 'MMMM YYYY')})`
    },

    onSelectPeriodicity (value) {
      this.updateField('periodicity', value?.id)
    },

    updateField (key, value) {
      this.$emit('update-card-field', { [key]: value })
    },

    updateCardAuthorizationCategories (newAuthorizationCategories) {
      const authorizationCategories = cloneDeep(this.settings.authorization_categories)

      newAuthorizationCategories.forEach(item => {
        const searchIndex = authorizationCategories.findIndex(search => search.name === item.name)
        if (searchIndex !== -1) authorizationCategories[searchIndex] = item
      })

      return authorizationCategories
    },

    updateCardSettings (data) {
      const settings = { ...this.settings, ...data }

      if (data.authorization_categories) settings.authorization_categories = this.updateCardAuthorizationCategories(data.authorization_categories)

      this.settings = settings
    }
  }
}
</script>
