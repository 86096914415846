<template>
  <div id="smart-card"
       data-cy="cards.settings.section.smart-card"
       class="form__fieldset">
    <group-title :title="$t('cards_settings.smart_card.title')">
      <i18n-t tag="p"
              class="subtitle"
              keypath="cards_settings.smart_card.description"
              scope="global">
        <template #link>
          <a :href="smartCardSupportUrl"
             target="_blank">{{ $t('tooltip.see_more') }}</a>
        </template>
      </i18n-t>
    </group-title>

    <div class="form__fieldset__group">
      <div class="bordered-box form__fieldset__block">
        <div class="item-left">
          <toggle-switch :model-value="smartCardSettings.smart_card"
                         data-cy="cards.settings.smart-card.toggle"
                         :disabled="disabled"
                         toggle-class="switch-btn--sm"
                         @update:model-value="updateSettings($event, 'smart_card')" />
          <span class="switch-btn-label"
                :class="{ 'text-muted': !smartCardSettings.smart_card }">{{ $t('cards_settings.smart_card.toggle') }}</span>
        </div>

        <div v-if="smartCardSettings.smart_card"
             class="item-right">
          <component-dropdown :model="smartCardSettings.smart_card_option"
                              :disabled="disabled"
                              dropdown-width="100%"
                              :values="smartCardOptions"
                              data-cy="smart-card-option"
                              label="smart-card-option"
                              @select="updateSettings($event.value, 'smart_card_option')">
            <template #trigger>
              <div class="dropdown-toggle select"
                   style="width: 23rem;">
                <div class="form-control form-control--sm">
                  <span>{{ dropdownLabel }}</span>
                </div>
              </div>
            </template>
          </component-dropdown>
        </div>
      </div>

      <template v-if="smartCardSettings.smart_card && smartCardSettings.smart_card_option && !isOptionOperation">
        <div v-if="doesUserExist || isOptionLink || isOptionFile"
             class="bordered-box form__fieldset__block mt-2"
             data-cy="cards.settings.smart-card.edit-block.container">
          <div class="item-left">
            <span data-cy="cards.settings.smart-card.edit-block.label">{{ $t(`cards_settings.smart_card.${smartCardSettings.smart_card_option}.title`) }}</span>
            <span v-tooltip="{ content: $t(`cards_settings.smart_card.${smartCardSettings.smart_card_option}.tooltip`), theme: 'poptip' }"
                  class="ic-helper ml-1">
              <ic-info outline
                       class="ic ic--gray" />
            </span>
          </div>

          <button class="item-right btn-link btn-link--bold"
                  data-cy="cards.settings.smart-card.edit-block.trigger"
                  :disabled="disabled"
                  @click="editSmartCard">
            <span>{{ editLabel }}</span>
            <span v-tooltip="{ content: isOptionLink && smartCardSettings.smart_card_link ? $t('cards_settings.smart_card.link.tooltip_edit') : false, theme: 'tooltip' }">
              <ic-attachment-sm v-if="isOptionFile"
                                class="ic ic--16 ic--gray ml-1" />
              <ic-pencil v-else
                         class="ic ic--20 ic--gray ml-1" />
            </span>
          </button>
        </div>

        <component-alert v-else
                         :message="$t('cards_settings.smart_card.warning')"
                         :show-api-messages="false"
                         data-cy="cards.settings.smart-card.warning-block.container"
                         type="warning" />
      </template>
    </div>
  </div>
</template>

<script>
import links from '@/config/links'
import { useAppStore } from '@/stores/app'

import EditSmartCard from '@/pages/cards/details/components/settings/sidepanel/EditSmartCard.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import IcAttachmentSm from '@/components/svg/icons/ic-attachment-sm.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcAttachmentSm,
    IcPencil,
    IcInfo,
    ComponentAlert,
    ComponentDropdown,
    GroupTitle,
    ToggleSwitch
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    doesUserExist: {
      type: Boolean,
      required: true
    },

    smartCardSettings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-smart-card-settings', 'update-smart-card-settings-from-backend'],

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  computed: {
    editLabel () {
      if (this.isOptionLink && this.smartCardSettings.smart_card_link) {
        return this.smartCardSettings.smart_card_link
      }

      if (this.isOptionFile && this.smartCardSettings.smart_card_file) {
        return this.smartCardSettings.smart_card_file.filename
      }

      return this.$t(`cards_settings.smart_card.${this.smartCardSettings.smart_card_option}.edit`)
    },

    smartCardOptions () {
      return [
        {
          value: 'vcard',
          label: this.$i18n.t('cards_settings.smart_card.choice.vcard')
        },
        {
          value: 'link',
          label: this.$i18n.t('cards_settings.smart_card.choice.link')
        },
        {
          value: 'file',
          label: this.$i18n.t('cards_settings.smart_card.choice.file')
        },
        {
          value: 'operation',
          label: this.$i18n.t('cards_settings.smart_card.choice.operation')
        }
      ]
    },

    dropdownLabel () {
      return this.smartCardOptions.find(option => option.value === this.smartCardSettings.smart_card_option)?.label || this.$i18n.t('form.common.select')
    },

    isOptionFile () {
      return this.smartCardSettings.smart_card_option === 'file'
    },

    isOptionLink () {
      return this.smartCardSettings.smart_card_option === 'link'
    },

    isOptionOperation () {
      return this.smartCardSettings.smart_card_option === 'operation'
    },

    smartCardSupportUrl () {
      return links.SUPPORT.SMART_CARD
    }
  },

  methods: {
    updateSettings (value, key) {
      this.$emit('update-smart-card-settings', { key, value })
    },

    editSmartCard () {
      if (this.disabled) return

      this.appStore.showSidePanel(
        EditSmartCard,
        {
          cardId: this.card.uuid,
          smartCardSettings: this.smartCardSettings,
          onUpdated: newSettings => this.$emit('update-smart-card-settings-from-backend', newSettings)
        },
        { wrapperClass: 'sidepanel--lg', backgroundOverlay: true }
      )
    }
  }
}
</script>
