<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.mileages') }}
        </h1>
        <tabs v-if="isOwner"
              is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <button v-if="isOwner"
                  class="btn btn--default"
                  @click="showExportPanel">
            <i class="btn-icon btn-icon--left">
              <ic-export class="ic ic--20 ic--white" />
            </i>
            {{ $t("button.export") }}
          </button>
          <router-link class="btn btn--primary"
                       :to="{ name: 'mileage-create' }">
            {{ $t('title.new_mileage') }}
          </router-link>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange,
                         isOwner ? EFilter.Beneficiary : null
                       ]"
                       :number-of-results="numberOfResults"
                       :total-amount="mileagesTotalAmount"
                       @filters="filters" />
    <tabs :data="segments" />
    <router-view :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'
import { useMileageStore } from '@/stores/mileage'
import { EFilter } from '@/types/filter.d'

import OperationsExport from '@/pages/operations/sidepanel/OperationsExport.vue'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    IcExport,
    ComponentFilters,
    ButtonFilters,
    Tabs
  },

  setup () {
    const appStore = useAppStore()
    const mileageStore = useMileageStore()

    const { mileagesTotalAmount } = storeToRefs(mileageStore)

    return { appStore, mileageStore, mileagesTotalAmount }
  },

  data () {
    return {
      numberOfResults: null,
      filter: {
        search: null,
        from: null,
        to: null,
        beneficiary_id: null,
        type: null
      },

      types: {}
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.business_trips'),
          route: { name: 'mileages' },
          active: true
        },
        {
          label: this.$i18n.t('general.users'),
          route: { name: 'mileage-users' },
          active: false
        }
      ]
    },

    segments () {
      return [
        {
          label: this.$i18n.t('general.processing'),
          route: { name: 'mileages' }
        },
        {
          label: this.$i18n.tc('button.history'),
          route: { name: 'mileages-done' }
        }
      ]
    },

    isOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('language-changed', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('language-changed', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        search: null,
        from: null,
        to: null,
        beneficiary_id: null,
        type: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to,
        beneficiary_id: filters.beneficiary_id,
        type: filters.type
      }
    },

    showExportPanel () {
      const persistingFilters = this.$route.name === 'mileages'
        ? { status: 'pending' }
        : { except_status: 'pending' }

      this.appStore.showSidePanel(
        OperationsExport,
        {
          type: 'mileages',
          filter: this.filter,
          isFiltered: Object.keys(this.filter).some(i => this.filter[i]),
          persistingFilters
        },
        { wrapperClass: 'sidepanel-export sidepanel--md', backgroundOverlay: true }
      )
    }
  }
}
</script>
