import { Nullable } from '@/types/default.d'

export enum ECompanyTaxType {
  CorporationTax = 'corporation_tax',
  FlatTax = 'flat_tax'
}

export type TCompany = {
  id: number
  name: string
  permissions?: Array<string>
  tax_type: Nullable<ECompanyTaxType>
} & object
