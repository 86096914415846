<template>
  <svg viewBox="0 0 32 32">
    <path d="M23.151 9.387c3.321 0 6.02 2.742 6.02 6.112s-2.699 6.115-6.02 6.115h-1.184c-.376 0-.678-.307-.678-.685s.302-.684.678-.684h1.184c2.57 0 4.663-2.128 4.663-4.746 0-2.616-2.093-4.743-4.663-4.742a4.02 4.02 0 0 0-.636.043.674.674 0 0 1-.725-.394c-1.1-2.452-3.524-4.036-6.17-4.036-3.74 0-6.785 3.096-6.785 6.903 0 .127.004.25.011.38a.686.686 0 0 1-.302.613.67.67 0 0 1-.684.039 3.055 3.055 0 0 0-1.414-.348c-1.702 0-3.089 1.41-3.089 3.146 0 .84.322 1.628.906 2.222a3.04 3.04 0 0 0 2.183.92h2.758c.376 0 .679.307.679.684a.682.682 0 0 1-.679.685H6.446A4.386 4.386 0 0 1 3.3 20.29 4.517 4.517 0 0 1 2 17.103c0-2.489 1.993-4.515 4.446-4.515.357 0 .708.043 1.05.127C7.78 8.408 11.32 5 15.62 5c3.01 0 5.784 1.705 7.19 4.395.11-.005.223-.008.341-.008zm-7.106 5.02 3.269 3.018a.689.689 0 0 1 .046.963.674.674 0 0 1-.962.047l-2.134-1.97v10.596a.682.682 0 0 1-.678.685.682.682 0 0 1-.679-.685V16.465l-2.134 1.97a.674.674 0 0 1-.962-.046.689.689 0 0 1 .046-.964l3.269-3.018c.063-.049.068-.053.091-.064a.44.44 0 0 1 .087-.054c.032-.013.034-.015.046-.018a.47.47 0 0 1 .096-.024c.044-.01.051-.012.084-.015l.056-.006a.36.36 0 0 1 .09.013s-.004-.002-.025-.005c.022.001.03.003.051.008.055.01.087.017.128.033a.421.421 0 0 1 .12.067c.027.012.034.018.095.065z" />
  </svg>
</template>

<script>
export default {
}
</script>
