<template>
  <svg viewBox="0 0 30 30">
    <defs>
      <filter id="ic-file/filter/a"
              x="0"
              y="0"
              width="30"
              height="30"
              color-interpolation-filters="sRGB">
        <feOffset dy="1" />
        <feColorMatrix values="0 0 0 0 0.937051 0 0 0 0 0.941441 0 0 0 0 0.943266 0 0 0 1 0"
                       result="b" />
        <feBlend in="SourceGraphic"
                 in2="b" />
      </filter>
    </defs>
    <g filter="url(#ic-file/filter/a)">
      <rect width="30"
            height="29"
            rx="2"
            fill="#F7F9FA" />
    </g>
    <path v-if="image"
          d="m18 12.5 5.5 8H7l4-6 2.373 4.17zm-5-4a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
          fill="#7A818C" />
    <path v-else-if="archive"
          d="M15 .746h-2v1h2v1h-2v1h2v-1h2v-1h-2v-1Zm0 3h2v1h-2v-1Zm0 2h-2v-1h2v1Zm0 1v-1h2v1h-2Zm0 1h-2v-1h2v1Zm0 1v-1h2v1h-2Zm0 1h-2v-1h2v1Zm0 1v-1h2v1h-2Zm0 1h-2v-1h2v1Zm0 1v-1h2v1h-2Zm0 1h-2v-1h2v1Zm2 1v-1h-2v1h1v2h-2v-2h-1v6h4v-6Z"
          fill="#7A818C" />
    <path v-else-if="video"
          fill="#7A818C"
          fill-rule="evenodd"
          d="M15 8a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm2.511 7.338a.304.304 0 0 0 .01-.515l-3.658-2.391a.304.304 0 0 0-.47.255v4.605c0 .238.259.384.461.26l3.657-2.214Z" />
    <path v-else-if="pdf"
          fill="#E23F3F"
          fill-rule="evenodd"
          d="M21.111 15.683c.013.005 1.333.535 1.387 1.362.011.156-.015.547-.475.8-.511.281-1.267.357-2.118.226-1.01-.154-2.047-.576-3.01-1.223-.267.072-.53.147-.787.22a69.148 69.148 0 0 1-.873.246l-.336.09c-.938.25-1.906.51-2.814.855-.361.805-.729 1.503-1.122 2.128-.195.311-1.225 1.863-2.295 1.863a1.13 1.13 0 0 1-.854-.399 1.211 1.211 0 0 1-.312-.886c.034-.71.625-1.39 1.116-1.835.75-.68 1.648-1.234 2.742-1.691.388-.907.733-1.86 1.067-2.784l.005-.015c.27-.747.55-1.519.85-2.263-.834-1.847-.978-3.395-.429-4.605.308-.678.687-1.022 1.126-1.022.641 0 1.113.741 1.173 1.844.074 1.376-.334 2.605-.787 3.747.185.371.398.75.635 1.131a8.381 8.381 0 0 0 2.084 2.26c.933-.23 1.66-.333 2.334-.333.63 0 1.183.093 1.693.284Zm-7.306-4.692c.243-.747.409-1.527.365-2.337-.022-.4-.126-.688-.204-.816-.051.059-.13.172-.226.383-.374.825-.235 1.846.065 2.77Zm2.075 5.068-.045.013-.073.021-.004.001c-.263.076-.52.15-.766.215l-.334.09-.01.002c-.67.18-1.354.362-2.029.579.255-.648.491-1.302.723-1.942l.01-.028.012-.034c.17-.47.343-.949.522-1.422.094.162.19.325.292.488.46.74 1.04 1.425 1.702 2.017Zm5.482.951c-.325.102-.803.113-1.315.035a6.031 6.031 0 0 1-1.661-.523c.982-.15 1.745-.104 2.396.14.155.057.409.212.58.349ZM10.66 18.92c-.169.317-.34.614-.516.895-.425.675-1.12 1.398-1.476 1.398-.035 0-.078-.005-.14-.075a.145.145 0 0 1-.044-.119c.012-.248.324-.69.775-1.1a7.62 7.62 0 0 1 1.4-1Z" />
    <path v-else-if="doc"
          fill="#3E70C8"
          d="M8 8h14v1H8V8Zm0 3h14v1H8v-1Zm14 3H8v1h14v-1ZM8 17h14v1H8v-1Zm7 3H8v1h7v-1Z" />
    <path v-else-if="xls"
          fill="#21934F"
          fill-rule="evenodd"
          d="M8 8h14v13H8V8Zm1 12h3v-3H9v3Zm4 0h8v-3h-8v3Zm8-7v3h-8v-3h8Zm-9 0v3H9v-3h3Zm-3-1h3V9H9v3Zm4 0h8V9h-8v3Z" />
    <path v-else-if="ppt"
          fill="#DE5D3E"
          fill-rule="evenodd"
          d="M21.21 10.127h-4.413v-4.41a4.41 4.41 0 0 1 4.412 4.41ZM15.033 7.48a4.41 4.41 0 1 0 4.411 4.41h-4.411V7.48ZM8.002 20H22v-1H8l.002 1Zm0 2.5H22v-1H8l.002 1Z" />
    <path v-else
          fill="#7A818C"
          d="M20 8H8v1h12V8Zm2 4H8v1h14v-1ZM8 16h12v1H8v-1Zm14 4H8v1h14v-1Z" />
  </svg>
</template>

<script>
export default {
  props: {
    image: Boolean,
    archive: Boolean,
    video: Boolean,
    doc: Boolean,
    pdf: Boolean,
    ppt: Boolean,
    xls: Boolean
  }
}
</script>
