<template>
  <modal-dialog-prompt :title="$t('modal.reject_request.title')"
                       :subtitle="$t('modal.reject_request.subtitle')"
                       data-cy="expense-requests.reject.modal.content"
                       @close="$emit('close')">
    <template #body>
      <textarea id="rejectReason"
                v-model="reason"
                class="form-control multiple-rows-note"
                rows="3"
                :placeholder="$t('modal.non_compliant_expense.textarea.placeholder')" />
    </template>

    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              :disabled="loading"
              data-cy="expense-requests.reject.modal.cancel"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <component-button type="submit"
                        class="btn btn--primary"
                        :disabled="loading"
                        :label="$t('button.send')"
                        data-cy="expense-requests.reject.modal.save"
                        @click="onSubmit" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useExpenseRequestStore } from '@/stores/expense-request'

import ComponentButton from '@/components/Button.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ModalDialogPrompt,
    ComponentButton
  },

  props: {
    uuid: {
      type: String,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const expenseRequestStore = useExpenseRequestStore()

    return {
      appStore,
      expenseRequestStore
    }
  },

  data () {
    return {
      loading: false,
      reason: ''
    }
  },

  methods: {
    async onSubmit () {
      this.loading = true
      const success = await this.expenseRequestStore.rejectExpenseRequest(this.uuid, this.reason)
      this.loading = false

      if (success) {
        showToastSuccess(`${this.$i18n.tc('message.success.reject_request', 0, { first_name: this.user?.first_name, last_name: this.user?.last_name })}`)
        this.$bus.emit('expense-request-reject')
        this.appStore.closeSidePanel()
        this.$emit('close')
      }
    }
  }
}
</script>
