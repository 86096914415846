<template>
  <div class="modal__content center modal-edit-pin">
    <modal-header :title="$t('prompt.cards.pin.show.title')"
                  align-title="center"
                  @close="$emit('close')" />
    <div class="modal__body">
      <loader-spinner v-if="loading"
                      class="c c--vh" />
      <div class="card-pin"
           :class="{ 'is-hidden': loading }">
        <div class="card-pin__value">
          <template v-if="pin">
            <span v-if="!isRevealed"
                  class="hidden-value">••••</span>
            <template v-else>
              <span v-for="(digit, i) in pin"
                    :key="i"
                    class="digit">{{ digit }}</span>
            </template>
          </template>

          <span id="mq-card-pin"
                :class="{ 'mq-hidden-value': !isRevealed }" />
        </div>
        <div class="card-pin__timeout">
          <span class="timeout-text"
                :class="{ 'is-hidden': !isRevealed }">
            {{ $tc('prompt.cards.pin.show.auto_hide', count) }}
          </span>
        </div>
        <span id="mq-pin-timeout"
              class="hide" />
        <button v-if="hasPinEditionFeature"
                class="btn btn--outline mr-1"
                @click="editPinCode">
          {{ $t('button.card_edit_pin') }}
        </button>
        <button id="mq-toggle-card-pin"
                class="btn btn--midgray btn--iframe"
                :disabled="isRevealed"
                @click="onRevealClick">
          {{ $t('button.card_reveal_pin') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ECoreBannerTheme } from '@common/core-ui'

import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import { showCardPinCode } from '@/helpers/marqeta'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import EditPinCode from '@/pages/cards/modal/EditPinCode.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'

import UndefinedPinCode from './UndefinedPinCode.vue'

export default {
  components: {
    LoaderSpinner,
    ModalHeader
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    token: {
      type: String,
      required: false,
      default: null
    },

    pin: {
      type: String,
      required: false,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()

    return {
      appStore,
      cardStore
    }
  },

  data () {
    return {
      loading: true,
      isRevealed: false,
      intervalId: null,
      count: 5
    }
  },

  computed: {
    hasPinEditionFeature () {
      return hasFeature(EFeature.CardPinEdition, this.card)
    }
  },

  async created () {
    if (this.pin) {
      this.loading = false
      return
    }

    if (!this.token) this.$emit('close')

    await this.$loadScript(links.MARQETA.WIDGET)
    await showCardPinCode(
      this.token,
      'mq-card-pin',
      this.count,
      'mq-toggle-card-pin',
      'mq-pin-timeout',
      () => { this.startTimer() },
      () => { this.stopTimer() },
      () => { this.loading = false },
      e => {
        if (e === 'Error: Internal MarqetaJS:\nUnable to fetch pin information') {
          this.appStore.showModal(UndefinedPinCode, { card: this.card }, { wrapperClass: 'modal--xs' })
        } else {
          this.appStore.showBanner(ECoreBannerTheme.Danger, this.$i18n.t('message.warning.unknown'))
          this.$emit('close')
        }
      }
    )
  },

  unmounted () {
    if (this.token) {
      this.$unloadScript(links.MARQETA.WIDGET)
      this.observer && this.observer.disconnect()
    }
  },

  methods: {
    editPinCode () {
      this.appStore.showModal(EditPinCode, { card: this.card }, { wrapperClass: 'modal--xs' })
    },

    startTimer () {
      this.intervalId = setInterval(() => {
        if (this.count > 0) {
          this.count--
        } else {
          this.stopTimer()
        }
      }, 1000)

      this.isRevealed = true
    },

    stopTimer () {
      this.isRevealed = false

      clearInterval(this.intervalId)
      this.count = 5
    },

    onRevealClick () {
      if (!this.pin) return
      this.startTimer()
    }
  }
}
</script>

<style lang="stylus">
.card-pin
  text-align center

  &__value
    border 1px solid $borderBoxColor
    border-radius 6px
    padding 2.8rem 3rem
    width 15.4rem
    height 9rem
    margin 7rem auto 0

    > span.mq-hidden-value
      margin-left 10px

    > span.digit
      display inline-block
      font-size 2.8rem
      font-weight 500
      width 2.2rem
      line-height 1.2

    > span.hidden-value
      font-family $fontOCR
      font-size 2.6rem
      letter-spacing 6px

  &__timeout
    padding 1.6rem

    .timeout-text
      color $colorShinyGray
      font-size 1.3rem
      height 2rem
      line-height 2rem
      margin-bottom 0

      &__value
        position relative
        display inline-block
        white-space pre-line
        height 2rem
        line-height 2rem
        vertical-align top
        overflow hidden

        span
          display inline-block
          width 8px

          &:empty:before
            content '5'

  .btn
    margin-top 2rem
    margin-bottom 2rem
    iframe
      pointer-events none

    &--iframe:not(:disabled)
      position relative
      iframe
        pointer-events auto
</style>
