<template>
  <div>
    <slot name="list"
          :items="modelValue" />
  </div>
</template>

<script>
import { Plugins, Sortable } from '@shopify/draggable'
import { cloneDeep } from 'lodash'

export default {
  props: {
    modelValue: {
      type: Array,
      required: true
    },

    itemClass: {
      type: String,
      required: true
    },

    handleClass: {
      type: String,
      required: true
    }
  },

  emits: ['update:model-value'],

  mounted () {
    new Sortable(this.$el, {
      draggable: `.${this.itemClass}`,
      handle: `.${this.handleClass}`,
      mirror: {
        constrainDimensions: true
      },
      sortAnimation: {
        duration: 200,
        easingFunction: 'ease'
      },
      plugins: [Plugins.SortAnimation]
    }).on('sortable:stop', ({ oldIndex, newIndex }) => {
      const items = cloneDeep(this.modelValue)
      items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])
      this.$emit('update:model-value', items)
    })
  }
}
</script>
