<template>
  <modal-dialog-prompt :title="$t('account_info.default_language.title')"
                       :subtitle="$t('account_info.default_language.subtitle')"
                       @close="$emit('close')">
    <template #body>
      <language-dropdown v-model="lang" />
    </template>
    <template #footerButtons>
      <button type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <component-button type="button"
                        class="btn btn--primary"
                        :label="$t('button.confirm')"
                        :disabled="loading"
                        @click="confirm" />
    </template>
  </modal-dialog-prompt>
</template>

<script>
import { useAuthStore } from '@/stores/auth'

import ComponentButton from '@/components/Button.vue'
import LanguageDropdown from '@/components/LanguageDropdown.vue'
import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: {
    ComponentButton,
    LanguageDropdown,
    ModalDialogPrompt
  },

  emits: ['close'],

  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },

  data () {
    return {
      loading: false,
      lang: this.$i18n.locale
    }
  },

  methods: {
    async confirm () {
      this.loading = true
      const success = await this.authStore.updateUserLanguagePreferences(this.lang)
      this.loading = false

      if (success) {
        this.$bus.emit('scrollToTop')
      }
      this.$emit('close')
    }
  }
}
</script>
