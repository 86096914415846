<template>
  <div class="modal__content center">
    <modal-header @close="cancelAnalyze" />
    <div class="modal__body">
      <lottie name="document-scan-loader"
              class="icon"
              style="height: 160px; width: 200px; margin-left: auto; margin-right: auto;"
              :start-frames="[0, 20]"
              :loop-frames="[20, 148]"
              :end-frames="[148, 186]"
              @animation-ended="onAnimationEnded" />
      <h4 class="modal-content-title">
        {{ title }}
      </h4>
      <p class="pre-line">
        {{ content }}
      </p>
    </div>
    <div class="modal__footer">
      <button type="button"
              class="btn btn--gray"
              @click="cancelAnalyze">
        {{ $t('button.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { ModelCredit } from '@/models/Credit/ModelCredit'
import { useApiStore } from '@/stores/api'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useCreditStore } from '@/stores/credit'
import { useDocumentStore } from '@/stores/document'
import { useTransferStore } from '@/stores/transfer'

import Lottie from '@/components/Lottie.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'

export default {
  components: {
    Lottie,
    ModalHeader
  },

  props: {
    title: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    fileToAnalyze: {
      type: [ModelCredit, File],
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const apiStore = useApiStore()
    const beneficiaryStore = useBeneficiaryStore()
    const creditStore = useCreditStore()
    const documentStore = useDocumentStore()
    const transferStore = useTransferStore()

    const { error: apiError } = storeToRefs(apiStore)

    return {
      beneficiaryStore,
      creditStore,
      documentStore,
      transferStore,

      apiError
    }
  },

  data () {
    return {
      uploaded: false,
      initialTimeout: false,
      animationEnded: false,
      abortController: null
    }
  },

  computed: {
    isModalToBeClosed () {
      return (this.uploaded && this.initialTimeout && this.animationEnded) || this.apiError
    }
  },

  watch: {
    isModalToBeClosed: function () {
      if (this.isModalToBeClosed) {
        this.$emit('close')
      }
    }
  },

  created () {
    setTimeout(() => { this.initialTimeout = true }, 3500)

    this.initData()
  },

  async mounted () {
    this.$bus.on('animation-ended', this.onAnimationEnded)
  },

  beforeUnmount () {
    this.$bus.off('animation-ended', this.onAnimationEnded)
  },

  methods: {
    async initData () {
      this.abortController = new AbortController()
      let data

      switch (this.type) {
        case 'beneficiary':
          data = await this.beneficiaryStore.getRibInfos(this.fileToAnalyze, this.abortController.signal)
          if (data) {
            this.$bus.emit('beneficiary-rib-upload-success', data[0])
          }
          break
        case 'credit' :
          data = await this.creditStore.analyzeCreditDocument(this.fileToAnalyze, this.abortController.signal)
          if (data) {
            this.$bus.emit('credit-document-analyzed', data)
          }
          break
        case 'refund' :
          data = await this.documentStore.analyzeDocument(this.fileToAnalyze, this.abortController.signal)
          if (data) {
            this.$bus.emit('refund-document-analyzed', data)
          }
          break
        case 'transfer':
          data = await this.transferStore.analyzeTransferDocument(this.fileToAnalyze, this.abortController.signal)
          if (data) {
            this.$bus.emit('transfer-document-analyzed', data)
          }
          break
      }

      this.onDocumentAnalyzed()
    },

    onDocumentAnalyzed () {
      this.$bus.emit('display-end-animation')
      this.uploaded = true
    },

    onAnimationEnded () {
      this.animationEnded = true
    },

    cancelAnalyze () {
      this.abortController.abort()
      this.$emit('close')
    }
  }
}
</script>
