import { defineStore } from 'pinia'

import axiosClient from '@/api'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      usersImport: [],
      users: [],
      usersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      sponsors: []
    }
  },

  actions: {
    async getUsers (state, page, params = {}) {
      let users = []

      if (state === 'active') {
        params.status = 'active'
      } else if (state === 'revoked') {
        params.status = 'deleted'
      }

      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/users', { params })
        this.usersPagination = this.parsePagination(headers)
        users = data
      } catch (error) {
        console.error(error)
      }

      this.users = users
    },

    async getUserDetail (userUuid) {
      try {
        const { data } = await axiosClient.get(`/users/${userUuid}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateUser (uuid, payload) {
      try {
        const { data } = await axiosClient.put(`/users/${uuid}`, payload)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async reactivateUser (userUuid) {
      try {
        await axiosClient.put(`/users/${userUuid}/reactivate`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    updateUserVehicle: async function (uuid, vehicle) {
      const payload = new FormData()

      vehicle.files.filter(file => file instanceof File).forEach(file => { payload.append('files[]', file) })
      delete vehicle.files

      payload.append('jsonBody', JSON.stringify(vehicle))

      try {
        await axiosClient.post(`/users/${uuid}/vehicle`, payload)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async getUsersImport (file) {
      this.usersImport = []

      const payload = new FormData()

      payload.append('files', file)

      try {
        const { data } = await axiosClient.post('/user/import', payload)
        const users = data
        users.forEach((value, index) => {
          value.selected = true
          value.id = index
          value.beneficiary = value.beneficiary
            ? value.beneficiary
            : {
                iban: '',
                details: {
                  social_security_number: '',
                  contract_type: null,
                  contract_start_date: null,
                  contract_end_date: null,
                  advance_payment: false
                }
              }
          value.affectations = value.affectations ? value.affectations : []
          value.card_settings = value.card_settings
            ? value.card_settings
            : {
                virtual: {
                  request: false
                }
              }
          value.access.cards = value.access.cards || false
          value.access.transfers = value.access.transfers || false
        })
        this.usersImport = users
      } catch (error) {
        console.error(error)
      }
    },

    editUsersImport (data) {
      this.usersImport = data
    },

    async createMultipleUsers (payload) {
      try {
        const { data } = await axiosClient.post('/access/invite', payload)
        return data.message
      } catch (error) {
        console.error(error)
        return ''
      }
    },

    async getSponsors () {
      try {
        const { data } = await axiosClient.get('/user/sponsor')
        this.sponsors = data
      } catch (e) {
        console.error(e)
      }
    },

    async sendSponsorRequest (email) {
      try {
        await axiosClient.post('/user/sponsor', { email })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async contact (email, subject, message) {
      try {
        await axiosClient.post('/contact', { email, subject, message })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async getRoles () {
      try {
        const { data } = await axiosClient.get('/list/roles')
        return data.filter(item => item.role !== 'other')
      } catch (error) {
        console.error(error)
        return []
      }
    }
  }
})
