<template>
  <section class="section"
           data-cy="loyalty.page">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('menu.loyalty') }}
        </h1>
      </div>
    </div>
    <core-tabs :items="tabsItem"
               :is-section="false" />
    <router-view />
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreTabs } from '@common/core-ui'

const { t } = useI18n()

const tabsItem = computed(() => {
  return [
    {
      label: t('loyalty.rewards.title'),
      routeName: 'loyalty-rewards'
    },
    {
      label: t('loyalty.campaigns.title'),
      routeName: 'loyalty-campaigns'
    }
  ]
})

</script>
