<template>
  <div class="modal__content">
    <modal-header :title="$t('title.new_mandate')"
                  @close="$emit('close')" />
    <validation-form ref="form"
                     v-slot="{ errors }"
                     :validation-schema="schema"
                     class="form"
                     @submit="onSubmit">
      <div class="modal__body">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('form.alerts.common')" />
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.label }">
            <label class="label input__label"
                   for="label">{{ $t('form.mandate.name.label') }}</label>
            <validation-field id="label"
                              v-model="mandate.label"
                              type="text"
                              :placeholder="$t('form.mandate.name.placeholder')"
                              class="form-control"
                              name="label" />
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.comment }">
            <label class="label input__label"
                   for="comment">{{ `${$t('form.mandate.comment.label')} (${$t('general.optional')})` }}</label>
            <validation-field id="comment"
                              v-model="mandate.comment"
                              type="text"
                              :placeholder="$t('form.mandate.comment.placeholder')"
                              class="form-control"
                              name="comment" />
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.ics }">
            <label class="label input__label"
                   for="ics">{{ $t('form.mandate.ics.label') }}</label>
            <div class="input-group">
              <validation-field id="ics"
                                v-model="mandate.ics"
                                v-nospace
                                type="text"
                                :placeholder="$t('form.mandate.ics.placeholder')"
                                class="form-control"
                                name="ics"
                                autocomplete="no" />
              <span class="input-group__addon">
                <a v-tooltip="{ content: $t('tooltip.see_more'), theme: 'tooltip' }"
                   target="_blank"
                   :href="links.STATIC.FILES.MANDAT_ICS"
                   tabIndex="-1">
                  <ic-help class="ic ic--addon ic--16 ic--gray" />
                </a>
              </span>
            </div>
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.rum }">
            <label class="label input__label"
                   for="rum">{{ $t('form.mandate.rum.label') }}</label>
            <div class="input-group">
              <validation-field id="rum"
                                v-model="mandate.rum"
                                v-nospace
                                type="text"
                                :placeholder="$t('form.mandate.rum.placeholder')"
                                class="form-control"
                                name="rum"
                                autocomplete="no" />
              <span class="input-group__addon">
                <a v-tooltip="{ content: $t('tooltip.see_more'), theme: 'tooltip' }"
                   target="_blank"
                   :href="links.STATIC.FILES.MANDAT_RUM"
                   tabIndex="-1">
                  <ic-help class="ic ic--addon ic--16 ic--gray" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-6"
               :class="{ error: errors.debit_type }">
            <label class="label input__label"
                   for="document">{{ $t('form.mandate.debit_type.label') }}</label>
            <validation-field v-slot="{ field }"
                              :model-value="mandate.unique"
                              name="debit_type">
              <component-dropdown label="debit_type"
                                  dropdown-width="100%"
                                  :values="debitTypes"
                                  :model="field"
                                  @select="value => mandate.unique = value.value">
                <template #trigger>
                  <div class="dropdown-toggle select">
                    <div class="form-control">
                      <div v-if="mandate.unique === null"
                           class="text-placeholder">
                        {{ $t('form.common.select') }}
                      </div>
                      <div v-else>
                        {{ getDebitTypeLabel }}
                      </div>
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
          <div class="form-group col col--sm-6"
               :class="{ error: errors.attachment }">
            <label class="label input__label"
                   for="document">{{ $t('form.mandate.document') }}</label>
            <validation-field name="attachment"
                              :model-value="mandate.document">
              <div class="file-input">
                <div v-if="mandate.document"
                     class="file-input__attachment form-control">
                  <span v-if="mandate.document.name"
                        class="file-input__placeholder">{{ mandate.document.name }}</span>
                  <span v-else-if="mandate.document.filename"
                        class="file-input__placeholder">{{ mandate.document.filename }}</span>
                  <button class="file-input-icon"
                          type="button"
                          @click.prevent.stop="removeFile">
                    <ic-remove class="ic ic--16 ic--gray" />
                  </button>
                </div>
                <div v-else
                     class="file-input__control form-control">
                  <input id="document"
                         ref="attachment"
                         type="file"
                         name="attachment"
                         @change="onFileAttached">
                  <span class="file-input__placeholder">{{ $t('form.file_input.attach_file') }}</span>
                  <i class="file-input-icon">
                    <ic-attachment class="ic ic--20 ic--gray" />
                  </i>
                </div>
              </div>
            </validation-field>
          </div>
        </div>
      </div>
      <div class="modal__footer">
        <div class="modal__footer__left" />
        <div class="modal__footer__right">
          <button type="button"
                  class="btn btn--gray"
                  @click="$emit('close')">
            {{ $t("button.cancel") }}
          </button>
          <component-button type="submit"
                            class="btn btn--primary"
                            :label="$t('button.send')" />
        </div>
      </div>
    </validation-form>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import links from '@/config/links'
import { useApiStore } from '@/stores/api'
import { useDebitStore } from '@/stores/debit'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcHelp from '@/components/svg/icons/ic-help.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcAttachment,
    IcHelp,
    IcRemove,
    ComponentAlert,
    ComponentButton,
    ComponentDropdown,
    ModalHeader
  },

  directives: {
    nospace: {
      update: function (el, binding, vnode) {
        if (!el.value.match(/\s/)) return

        const model = vnode.data.directives.find(el => el.name === 'model')
        if (!model) { return }

        let vmodel = vnode.context
        if (model.expression.match(/\./)) {
          const splittedExp = model.expression.split('.')
          splittedExp.forEach((el, index) => {
            if (index < splittedExp.length - 1) {
              vmodel = vmodel[el]
            }
          })
          vmodel[splittedExp[splittedExp.length - 1]] = el.value.replace(/\s/g, '')
        } else {
          vmodel[model.expression] = el.value.replace(/\s/g, '')
        }
      }
    }
  },

  props: {
    model: {
      type: Object,
      default: null
    }
  },

  emits: ['close'],

  setup () {
    const apiStore = useApiStore()
    const debitStore = useDebitStore()

    const { error: apiError } = storeToRefs(apiStore)

    return { debitStore, apiError }
  },

  data () {
    return {
      mandate: {
        label: '',
        comment: '',
        ics: '',
        rum: '',
        document: null,
        unique: null
      },

      debitTypes: [
        {
          value: true,
          label: this.$t('form.mandate.debit_type.unique')
        },
        {
          value: false,
          label: this.$t('form.mandate.debit_type.periodic')
        }
      ],

      schema: yup.object({
        attachment: yup.mixed().required(),
        comment: yup.string(),
        debit_type: yup.string().required(),
        ics: yup.string().required(),
        label: yup.string().required(),
        rum: yup.string().required()
      }),

      links
    }
  },

  computed: {
    getDebitTypeLabel () {
      return this.debitTypes.find(type => type.value === this.mandate.unique)?.label
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    if (!this.model) { return }

    this.mandate.label = this.model.label
    this.mandate.ics = this.model.ics
    this.mandate.rum = this.model.rum
    this.mandate.unique = this.model.unique
  },

  methods: {
    onFileAttached (e) {
      this.mandate.document = e.target.files[0]
    },

    removeFile () {
      this.mandate.document = false
    },

    async onSubmit () {
      const mandate = { ...this.mandate }
      const success = await this.debitStore.postMandate(mandate)
      if (success) { this.$emit('close') }
    }
  }
}

</script>
