<template>
  <div class="input-group">
    <validation-field v-slot="{ field, meta: fieldMeta }"
                      :value="modelValue"
                      type="text"
                      :name="name">
      <input v-bind="{ ...$attrs, ...field }"
             class="form-control"
             @input="onInput">
      <button v-tooltip="{ content: fieldMeta.valid ? $t('tooltip.open_link') : '', theme: 'tooltip' }"
              :disabled="!fieldMeta.valid"
              type="button"
              class="input-group__addon clickable"
              @click="openUrl">
        <slot name="icon">
          <ic-link-to class="ic ic--addon ic--16 ic--gray" />
        </slot>
      </button>
    </validation-field>
  </div>
</template>

<script>
import IcLinkTo from '@/components/svg/icons/ic-link-to.vue'

export default {
  name: 'InputLink',
  components: {
    IcLinkTo
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  methods: {
    onInput (event) {
      this.$emit('update:modelValue', event.target.value)
    },

    openUrl () {
      let link = this.modelValue

      if (!link) { return }

      if (!['http://', 'https://'].some(protocol => link.startsWith(protocol))) { link = `http://${link}` }

      window.open(link)
    }
  }
}
</script>
