<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm6.32 5.095L7.096 18.321A8 8 0 0 0 18.32 7.096zM12 4a8 8 0 0 0-6.32 12.905L16.904 5.679A7.965 7.965 0 0 0 12 4z" />
  </svg>
</template>

<script>
export default {
}
</script>
