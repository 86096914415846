<template>
  <li :class="[isAccountToClose ? 'disabled' : null]">
    <div class="item account-item"
         @click="showCompanyAccountDetails">
      <div class="account-item__infos">
        <h4 class="account-item__name">
          {{ accountName }}
        </h4>
        <div v-if="accountData.rib"
             class="account-item__number">
          {{ accountData.rib.number }}
        </div>
      </div>
      <div class="account-item__infos">
        <div v-if="displayAccountBalance"
             class="account-item__balance"
             :class="[accountData.balance < 0 ? 'text-red' : 'text-green']">
          {{ formatAmount(accountData.balance, accountData.currency) }}
        </div>
      </div>
      <core-badge v-if="showAccountStatusBadge"
                  :theme="isAccountToValidate ? ECoreBadgeTheme.Info : ECoreBadgeTheme.Danger"
                  :fill="isAccountToValidate ? ECoreBadgeFill.Solid : ECoreBadgeFill.Shaded"
                  :value="accountStatusLabel" />
    </div>
  </li>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'

import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'

import AccountDetails from '@/pages/account/companies/sidepanel/AccountDetails.vue'

export default {
  components: { CoreBadge },
  props: {
    accountData: {
      type: Object,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    displayAccountBalance () {
      return this.accountData.status === 'valid' && Object.prototype.hasOwnProperty.call(this.accountData, 'balance') &&
          this.accountData.balance !== null && this.hasPermission([this.$permissions.accountBalanceRead], this.accountData)
    },

    isAccountToClose () {
      return this.accountData.status === 'closing'
    },

    isAccountToValidate () {
      return this.accountData.status === 'validating'
    },

    accountName () {
      return this.accountData.label || this.accountData.type_label || this.$i18n.t('general.additional_account')
    },

    showAccountStatusBadge () {
      return this.isAccountToClose || this.isAccountToValidate
    },

    accountStatusLabel () {
      if (this.isAccountToValidate) {
        return this.$i18n.t('placeholder.restricted_access.user_pending.badge')
      }
      if (this.isAccountToClose) {
        return this.$i18n.t('general.closed')
      }
      return null
    }
  },

  methods: {
    formatAmount,

    showCompanyAccountDetails () {
      this.appStore.showSidePanel(
        AccountDetails,
        { uuid: this.accountData.uuid, displayBalance: this.displayAccountBalance },
        { wrapperClass: 'sidepanel--md', backgroundOverlay: true, canDragAndDrop: false }
      )
    }
  }
}
</script>
