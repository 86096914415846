import dayjs, { Dayjs } from 'dayjs'

import { formatAmount } from '@/helpers/utils/number'
import { Model } from '@/models/Model'
import { ETreasuryFlowOperationType, ITreasuryFlowOperation } from '@/types/treasury.d'

export class ModelTreasuryFlowOperation extends Model {
  accounting_at: number
  amount: number
  type: ETreasuryFlowOperationType
  private constructor (properties: Partial<ITreasuryFlowOperation>) {
    super()

    this.accounting_at = properties.accounting_at || 0
    this.amount = properties.amount || 0
    this.type = properties.type || ETreasuryFlowOperationType.In

    return this.onConstructed()
  }

  static create (properties: ITreasuryFlowOperation): ModelTreasuryFlowOperation {
    return new ModelTreasuryFlowOperation(properties || {})
  }

  get amountFormatted ():string {
    return formatAmount(this.amount, null)
  }

  get accountingAtDayjs ():Dayjs {
    return dayjs.unix(this.accounting_at)
  }

  get accountingAtFormatted ():string {
    return this.accountingAtDayjs.format('YYYY-MM-DD')
  }

  accountingAtFormat (format: string): string {
    return dayjs.unix(this.accounting_at).format(format)
  }

  get positiveAmount (): number {
    return Math.abs(this.amount)
  }
}
