<template>
  <aside id="sidebar"
         class="sidebar">
    <div class="sidebar__scroll">
      <div class="sidebar__header">
        <button class="btn-link sidebar-btn-menu"
                @click="onToggleSidebar">
          <ic-menu class="ic ic--24 ic--gray" />
        </button>
        <span class="sidebar__logo">
          <router-link :to="{ name: 'dashboard' }">
            <logo-bank-inline white
                              class="logo-mone-icon" />
          </router-link>
        </span>
      </div>
      <div v-if="hasSidebarAccess"
           class="sidebar__body">
        <div class="sidebar__content">
          <div v-if="account"
               class="sidebar-bankaccount">
            <div v-if="numberOfAccounts > 1"
                 class="select-bankaccount"
                 @click="showAccountSidepanel">
              <button data-cy="sidebar.account-selector"
                      class="company-account company-account--btn">
                <div v-if="account.company"
                     class="company-label text-overflow">
                  {{ account.company.name }}
                </div>
                <div class="account-name">
                  <span v-if="account.label"
                        class="account-label text-overflow">{{ account.label }}</span>
                  <span v-if="isAccountClosing(account)"
                        class="access-invited">
                    ({{ $t('menu.detail.closing_account') }})
                  </span>
                  <span v-else-if="isAccountClosed(account)"
                        class="access-invited">
                    ({{ $t('menu.detail.closed_account') }})
                  </span>
                  <span v-else-if="account.status === 'sequestre'"
                        class="access-invited">
                    ({{ $t('menu.detail.sequestre') }})
                  </span>
                  <span v-else-if="isClassicAccount && !hasPermission($permissions.accountOwner, account)"
                        class="access-invited">
                    ({{ $t('menu.detail.shared_access') }})
                  </span>
                </div>
              </button>
            </div>
            <div v-else
                 class="select-bankaccount">
              <div class="company-account">
                <div class="company-label text-overflow">
                  {{ account.company.name }}
                </div>
                <div v-if="account.label"
                     class="account-label text-overflow">
                  {{ account.label }}
                </div>
              </div>
            </div>
            <h3 v-if="accountHasBalance"
                class="account-balance"
                :class="[account.balance > 0 ? 'text-green' : 'text-red']">
              {{ formatAmount(account.balance, account.currency) }}
            </h3>
          </div>
          <nav v-if="showMenu"
               class="sidebar-nav">
            <ul class="sidebar-nav__links">
              <template v-for="(item, index) in menu"
                        :key="index">
                <li v-if="hasPermission(item.permissions) && displayItem(item.menu.name)"
                    class="sidebar-nav__link"
                    data-cy="sidebar.nav-link">
                  <router-link :to="{ path: item.path }"
                               class="sidebar-nav__link--item"
                               :class="{ 'router-link-active': isActive(item.path) }">
                    <ic-section v-bind="{ [item.menu.icon]: true }"
                                class="ic" />
                    <span class="sidebar-nav__link__label">
                      {{ getMenuItemName(item.menu.name) }}
                    </span>
                    <core-badge roundness
                                :theme="ECoreBadgeTheme.Primary"
                                :value="getMenuBadgeCount(item.menu.name)" />
                  </router-link>
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
      <div v-else
           class="sidebar__body">
        <div class="sidebar__content">
          <menu-skeleton class="sidebar-bankaccount"
                         width="14rem" />
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar-logout">
        <button type="button"
                class="sidebar-btn-logout"
                @click.prevent="logout">
          <loader-spinner v-if="loadingLogout"
                          class="loader-spinner--no-marge"
                          color="white"
                          size="20" />
          <ic-logout v-if="!loadingLogout"
                     class="ic ic-logout" />
          <span v-if="!loadingLogout"
                class="logout-label">{{ $t('menu.logout') }}</span>
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import { CoreBadge, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { EAccountType } from '@/types/account.d'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcLogout from '@/components/svg/icons/ic-logout.vue'
import IcMenu from '@/components/svg/icons/ic-menu.vue'
import IcSection from '@/components/svg/icons/ic-section.vue'
import LogoBankInline from '@/components/svg/img/logo-bank-inline.vue'
import MenuSkeleton from '@/components/svg/img/menu-skeleton.vue'

export default {
  name: 'ComponentSidebar',

  components: {
    CoreBadge,
    LoaderSpinner,
    IcMenu,
    IcLogout,
    IcSection,
    MenuSkeleton,
    LogoBankInline

  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { account, isClassicAccount, isUserEmployee } = storeToRefs(accountStore)
    const { isDeviceMobile } = storeToRefs(appStore)
    const { user, numberOfAccounts } = storeToRefs(authStore)

    return {
      account,
      authStore,
      isClassicAccount,
      isDeviceMobile,
      isUserEmployee,
      numberOfAccounts,
      user
    }
  },

  data () {
    return {
      store,
      loadingLogout: false
    }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    menu () {
      let routes = this.$router.options.routes

      if (this.isDeviceMobile) {
        routes = routes.filter(route => route.meta?.isResponsive)
      }

      routes = routes.filter(route => route.meta?.menu)

      return routes.map(route => ({ path: route.path, ...route.meta }))
    },

    accountHasBalance () {
      return (typeof this.account.balance === 'number') &&
          this.hasPermission([this.$permissions.accountBalanceRead])
    },

    showMenu () {
      return (this.account.status_group === 'valid' || this.account.status_group === 'closing') && this.account.type !== EAccountType.CapitalGain
    },

    hasSidebarAccess () {
      const sca = this.user.sca
      return sca && (!sca.enforced || sca.configured)
    }
  },

  mounted () {
    window.addEventListener('resize', this.resizeSidebar)
    this.$bus.on('account-switched', this.closeSidebar)
    this.$bus.on('sub-account-created', this.authStore.refreshUser)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.resizeSidebar)
    this.$bus.off('account-switched', this.closeSidebar)
    this.$bus.off('sub-account-created', this.authStore.refreshUser)
  },

  methods: {
    closeSidebar () {
      document.getElementsByClassName('sidebar')[0].classList.remove('is-open')
    },

    formatAmount,

    isActive (path) {
      return this.$router?.currentRoute?.value?.matched?.some?.(r => r.path === path) // Bug (?) class .router-link-active
    },

    isAccountClosing (account) {
      return (account.status_group === 'closing')
    },

    isAccountClosed (account) {
      return (account.status_group === 'closed')
    },

    onToggleSidebar () {
      document.getElementsByClassName('sidebar')[0].classList.toggle('is-open')
    },

    displayItem (itemName) {
      return Object.prototype.hasOwnProperty.call(this.account.display_sections || {}, itemName)
        ? this.account.display_sections[itemName]
        : true
    },

    hasBalance (accountItem) {
      return accountItem.balance !== null && Object.prototype.hasOwnProperty.call(accountItem, 'balance')
    },

    resizeSidebar () {
      if (window.innerWidth >= 1200) {
        document.getElementsByClassName('sidebar')[0].classList.remove('is-open')
      }
    },

    showAccountSidepanel () {
      this.$bus.emit('show-account-picker')
    },

    async logout () {
      this.loadingLogout = true
      await this.authStore.logout()
      this.$router.push({ name: 'logout' }).catch(() => null)
      this.loadingLogout = false
    },

    getMenuItemName (itemName) {
      if (itemName === 'refunds' && !this.isUserEmployee) {
        itemName = 'payments'
      }
      return this.$i18n.t(`menu.${itemName}`)
    },

    getMenuBadgeCount (itemName) {
      if (itemName === 'transfers') {
        return this.account.counts?.transfers?.waiting_validation
      } else if (itemName === 'users') {
        return this.account.invitation_waiting_validation_count
      } else if (itemName === 'cards') {
        return this.account.counts.virtual_cards.waiting_validation + this.account.counts.physical_cards.waiting_validation
      } else if (itemName === 'requests') {
        return this.account.counts.expense_requests.waiting_validation
      }

      return 0
    }
  }
}
</script>

<style scoped lang="stylus">
.sidebar-nav
  margin-left 1.2rem
  margin-right @margin-left

  &__links
    margin 0
    padding 0
    list-style none

  &__link
    transition background 0.3s ease

    &__label
      margin-left 1.1rem
      vertical-align middle
      flex-grow 1

    &--item
      font-size $fontSizeNav
      font-weight 500
      color alpha(white, 60%)
      line-height 32px
      padding 0 1rem
      display flex
      align-items center
      height 40px
      width 100%
      border-radius $border-radius
      margin-top 0.15rem
      margin-bottom @margin-top

      &.router-link-active
        font-weight 600
        background-color alpha(white, 0.1)

      &:hover
      &.router-link-active
        color white

        .ic
          fill white

      .ic
        width 24px
        height @width
        fill alpha(white, 50%)
        transition fill 0.4s ease

.sidebar-logout
  > .sidebar-btn-logout
    font-size 1.4rem
    font-weight 500
    color white
    line-height 32px
    display block
    width 100%
    padding 14px 0

    .loader-spinner
      min-height 32px

    .logout-label
      color alpha(white, 50%)
      margin-left 0.7rem
      transition opacity 0.3s ease
      text-align left
      display inline-block
      margin-right 1rem
      vertical-align middle

    .ic-logout
      fill alpha(white, 50%)
      square(22px)

    &:hover
      .logout-label
        color white
        transition color 0.4s ease

      .ic-logout
        fill white
        transition fill 0.4s ease

.sidebar
  &__logo
    +max-screen($xl-screen)
      margin-left 3rem

.sidebar-btn-menu
  position absolute
  margin-left 2rem
  +min-screen($xl-screen)
    visibility hidden
</style>
