<template>
  <svg viewBox="0 0 200 160">
    <path d="m154 42c2.623353 0 4.75 2.1266474 4.75 4.75v68c0 2.623353-2.126647 4.75-4.75 4.75h-41.154l.938912 7.742734c.491611 4.055789 3.872479 7.127021 7.930155 7.253229l.258933.004037h1.026v1.5h-45v-1.5h1.0250345c4.0854753 0 7.5412153-2.986736 8.1547717-6.999742l.035282-.257524.9379118-7.742734h-42.153c-2.5504817 0-4.6314697-2.010141-4.7451121-4.532572l-.0048879-.217428v-68c0-2.6233526 2.1266474-4.75 4.75-4.75zm-42.665 77.5h-21.671l-.9598112 7.923231c-.3496563 2.884665-1.9360442 5.347895-4.1892202 6.884659l-.2959686.19211h32.561l-.041901-.025197c-2.314702-1.475679-3.976459-3.907167-4.405677-6.780881l-.036611-.270691zm45.915-12.25h-114.5v7.5c0 1.733031 1.3564548 3.149246 3.0655761 3.244855l.1844239.005145h108c1.794925 0 3.25-1.455075 3.25-3.25zm-3.25-63.75h-108c-1.7949254 0-3.25 1.4550746-3.25 3.25v59h114.5v-59c0-1.7330315-1.356455-3.1492459-3.065576-3.2448552z"
          :fill="colorDark" />
    <g :fill="colorPrimary">
      <path v-if="success"
            d="m100 58.75c-9.5269119 0-17.25 7.7230881-17.25 17.25s7.7230881 17.25 17.25 17.25c9.526912 0 17.25-7.7230881 17.25-17.25-.011021-9.5223431-7.727657-17.2389788-17.25-17.25zm0 29c-3.9148229-.0051047-7.552187-2.0220854-9.63-5.34l1.26-.82c1.7965476 2.8957372 4.9622328 4.6571588 8.37 4.6571588 3.407767 0 6.573452-1.7614216 8.37-4.6571588l1.26.82c-2.077813 3.3179146-5.715177 5.3348953-9.63 5.34zm0 7c-10.3553391 0-18.75-8.3946609-18.75-18.75s8.3946609-18.75 18.75-18.75c10.355339 0 18.75 8.3946609 18.75 18.75-.011023 10.3507697-8.39923 18.7389768-18.75 18.75z" />
      <path v-else-if="error"
            d="m99.6913365 58 1.7868255.0023759c2.959195.2394281 5.819042 1.1789235 8.343592 2.7409752l.20999.1299301.023262.0606517.114117.0713402c5.81095 3.7494964 9.086065 10.3975585 8.517222 17.2889323l-.035565.3830875c-.738017 7.1549974-5.505861 13.2567413-12.270853 15.7039313-6.7649522 2.4471757-14.3338644.8082231-19.4861871-4.2243127-.1322999-.132276-.2681989-.2752645-.4302432-.4510681l-.5721257-.6277539c-.2283874-.2550059-.4179755-.4801328-.5892767-.7028605-1.6624474-2.1060291-2.8533628-4.5443252-3.491335-7.1463701-1.3888516-5.5413318-.1664446-11.413433 3.3183669-15.9405507 3.4847842-4.527082 8.8491327-7.2119024 14.5622103-7.2883082zm1.7263405 1.4999329h-1.716311l-.3834458.0096909c-5.1067882.1850446-9.8751812 2.633984-13.0001643 6.6936486-3.2050834 4.1637229-4.3293585 9.5644263-3.0510739 14.6646512.5877681 2.3972434 1.6833786 4.640411 3.2185308 6.5852732.1893351.2460913.4060236.4980094.6906846.8084928l.4977941.5431196.2743834.2851905c4.7343817 4.6242458 11.6978151 6.1320896 17.9215961 3.8806787 6.223742-2.2513964 10.61007-7.8648867 11.289025-14.4472894.678954-6.5823893-2.469199-12.9727336-8.102364-16.4466827l-.210707-.1299422-.021619-.0528312-.204474-.1226278c-2.080292-1.2082089-4.388019-1.9671766-6.732521-2.2262399zm-1.4302592 23.2053917c.1941582.0022871.3869242.033124.5721032.0915201v-.02288c.990327.2997968 1.595364 1.297763 1.40308 2.3142828-.192285 1.0165198-1.120044 1.7246477-2.1514748 1.6421481-1.0314304-.0824996-1.8347902-.9290922-1.8629907-1.9632476-.0282006-1.0341554.7278284-1.9232559 1.7532305-2.0618234zm-.8550088-17.4463895.1112741.0003664h1.6018899c.216041.0249922.41747.1216607.572104.2745604.200462.131436.320973.3552028.320382.5948808l-.320382 8.0080106-.205957 5.296727c-.036023.3526411-.302743.6380274-.652199.6978409-.079807.0095893-.160476.0095893-.240283 0h-.617872c-.4195529-.0025471-.7721103-.315875-.8238292-.732161l-.5263353-13.3504976c.0117302-.2237207.1128707-.4333484.2806969-.5817834.1678263-.148435.3882641-.2232292.6117847-.2075777z" />
      <path v-else-if="code"
            d="m126 63.5c2.481994 0 4.5 1.9784377 4.5 4.4264706v17.6470588c0 2.4480329-2.018006 4.4264706-4.5 4.4264706h-52c-2.4819935 0-4.5-1.9784377-4.5-4.4264706v-17.6470588c0-2.4480329 2.0180065-4.4264706 4.5-4.4264706zm0 1.5h-52c-1.6601421 0-3 1.3135862-3 2.9264706v17.6470588c0 1.6128844 1.3398579 2.9264706 3 2.9264706h52c1.660142 0 3-1.3135862 3-2.9264706v-17.6470588c0-1.6128844-1.339858-2.9264706-3-2.9264706zm-42.485 8-.0567925 2.9473684 2.5556604-1.5438596.6815094 1.1789473-2.5840566 1.431579 2.5840566 1.3754386-.6815094 1.1789474-2.5556604-1.5157895.0567925 2.9473684h-1.3630189l.0567925-2.9192982-2.5272642 1.4877193-.6815094-1.1789474 2.5840566-1.3754386-2.5840566-1.431579.6815094-1.1789473 2.5272642 1.5157894-.0567925-2.9192982zm11.7682075 0-.0567924 2.9473684 2.5556604-1.5438596.6815094 1.1789473-2.5840566 1.431579 2.5840566 1.3754386-.6815094 1.1789474-2.5556604-1.5157895.0567924 2.9473684h-1.3630188l.0567924-2.9192982-2.5272641 1.4877193-.6815094-1.1789474 2.5840566-1.3754386-2.5840566-1.431579.6815094-1.1789473 2.5272641 1.5157894-.0567924-2.9192982zm11.7682075 0-.056792 2.9473684 2.55566-1.5438596.681509 1.1789473-2.584056 1.431579 2.584056 1.3754386-.681509 1.1789474-2.55566-1.5157895.056792 2.9473684h-1.363019l.056793-2.9192982-2.527264 1.4877193-.68151-1.1789474 2.584057-1.3754386-2.584057-1.431579.68151-1.1789473 2.527264 1.5157894-.056793-2.9192982zm11.768208 0-.056793 2.9473684 2.555661-1.5438596.681509 1.1789473-2.584057 1.431579 2.584057 1.3754386-.681509 1.1789474-2.555661-1.5157895.056793 2.9473684h-1.363019l.056792-2.9192982-2.527264 1.4877193-.681509-1.1789474 2.584056-1.3754386-2.584056-1.431579.681509-1.1789473 2.527264 1.5157894-.056792-2.9192982z" />
      <path v-else-if="problem"
            d="m100 58.75c-9.5269119 0-17.25 7.7230881-17.25 17.25s7.7230881 17.25 17.25 17.25c9.526912 0 17.25-7.7230881 17.25-17.25-.011021-9.5223431-7.727657-17.2389788-17.25-17.25zm0 22.84c3.914823.0051047 7.552187 2.0220854 9.63 5.34l-1.26.82c-1.796548-2.8957372-4.962233-4.6571588-8.37-4.6571588-3.4077672 0-6.5734524 1.7614216-8.37 4.6571588l-1.26-.82c2.077813-3.3179146 5.7151771-5.3348953 9.63-5.34zm0 13.16c-10.3553391 0-18.75-8.3946609-18.75-18.75s8.3946609-18.75 18.75-18.75c10.355339 0 18.75 8.3946609 18.75 18.75-.011023 10.3507697-8.39923 18.7389768-18.75 18.75z" />
    </g>
  </svg>
</template>

<script>
import config from '@/config/config'

export default {
  name: 'ImageComputer',

  props: {
    code: {
      type: Boolean,
      default: false
    },

    success: {
      type: Boolean,
      default: false
    },

    problem: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    },

    colorPrimary: {
      type: String,
      default: config.SVG.COLOR.PRIMARY
    },

    colorDark: {
      type: String,
      default: config.SVG.COLOR.DARK
    }
  }
}
</script>
