<template>
  <div class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="users"
                          :dropdown-width="'100%'"
                          :dropdown-height="24"
                          :search="false"
                          :can-add-item="false"
                          :values="values"
                          :label-filter="amountFilter"
                          :model="selectedAmount.id"
                          @select="onSelectAmount">
        <template #trigger>
          <div class="select"
               :class="modelValue ? 'is-clearable' : 'select--off'">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="modelValue">
                {{ amountFilter(selectedAmount) }}
              </template>
              <template v-else>
                {{ $t('form.amount.placeholder') }}
              </template>
            </div>
            <button v-if="modelValue"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$emit('clear-field', 'amount')">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },

  emits: ['update:modelValue', 'clear-field'],

  data () {
    return {
      selectedAmount: {}
    }
  },

  computed: {
    values () {
      return [
        {
          label: this.$i18n.t('form.amount.filter.under_100'),
          value: 'under_100',
          id: 1
        },
        {
          label: this.$i18n.t('form.amount.filter.between_100_and_500'),
          value: 'between_100_and_500',
          id: 2
        },
        {
          label: this.$i18n.t('form.amount.filter.over_500'),
          value: 'over_500',
          id: 3
        }
      ]
    }
  },

  watch: {
    modelValue () {
      if (!this.modelValue) {
        this.selectedAmount = {}
      }
    }
  },

  methods: {
    amountFilter (amount) {
      return amount.label
    },

    onSelectAmount (amount) {
      this.selectedAmount = amount
      this.$emit('update:modelValue', amount.value)
      this.$parent.onSubmit()
    }
  }
}
</script>
