import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import LoadScript from 'vue-plugin-load-script'
import Toast, { POSITION, useToast } from 'vue-toastification'
import { ToastOptionsAndRequiredContent } from 'vue-toastification/src/types'
import FloatingVue from 'floating-vue'
import { Field, Form } from 'vee-validate'

import { onListResize } from '@/helpers/directives'

import './validator'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/nl'

import config from './config/config'
import permissions from './config/permissions'
import bus from './helpers/eventBus'
import hasPermission from './helpers/permissions'
import App from './App.vue'
import i18n from './locales'
import router from './router'
import pinia from './stores'

import 'floating-vue/dist/style.css'

const app = createApp({
  ...App
})

// plugins
app
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(LoadScript)
  .use(Toast, {
    transition: 'toast-animation',
    icon: false,
    position: POSITION.TOP_RIGHT,
    closeOnClick: false,
    draggable: false,
    timeout: 5000,
    toastClassName: 'toast-centered',
    filterBeforeCreate (toast: ToastOptionsAndRequiredContent, toasts: ToastOptionsAndRequiredContent[]) {
      const lastToast = toasts.at(-1)
      if (lastToast?.id && JSON.stringify(lastToast.content) === JSON.stringify(toast.content)) {
        useToast().dismiss(lastToast.id)
      }
      return toast
    }
  })

  .use(FloatingVue, {
    distance: 10,
    triggers: ['hover', 'focus'],
    themes: {
      tooltip: {
        placement: 'bottom'
      },
      'tooltip-sm': {
        $extend: 'tooltip',
        placement: 'bottom'
      },
      'tooltip-md': {
        $extend: 'tooltip',
        placement: 'bottom'
      },
      'tooltip-lg': {
        $extend: 'tooltip',
        placement: 'bottom'
      },
      poptip: {
        placement: 'bottom'
      }
    }
  })

  .use(VueGtag, {
    enabled: !!import.meta.env.VITE_GTAG_ID,
    config: {
      id: import.meta.env.VITE_GTAG_ID
    }
  }, router)

// components
app
  .component('ValidationForm', Form)
  .component('ValidationField', Field)

// directives
app.directive('on-list-resize', onListResize)

// global properties
app.config.globalProperties.$config = config
app.config.globalProperties.$permissions = permissions
app.config.globalProperties.hasPermission = hasPermission
app.config.globalProperties.$bus = bus

app.mount('#app')
