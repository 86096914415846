<template>
  <div class="section__content">
    <core-steps class="mb-2"
                :active-step="activeStep"
                :steps="steps.map(step => $t(`create_term_account.steps.${step.value}.label`))" />
    <validation-form v-slot="{ errors, meta }"
                     ref="form"
                     as="form"
                     class="form form--w780 form--centered"
                     :validation-schema="steps.map(step => step.schema)[activeStep]"
                     @keydown.enter.prevent>
      <fieldset class="form__fieldset">
        <group-title :title="steps.map(step => $t(`create_term_account.steps.${step.value}.title`))[activeStep]"
                     :subtitle="steps.map(step => $t(`create_term_account.steps.${step.value}.subtitle`))[activeStep]" />
        <keep-alive>
          <component :is="steps.map(step => step.component)[activeStep]"
                     v-model:account="account"
                     v-model:amount="amount"
                     v-model:duration="duration"
                     v-model:tax-type="taxType"
                     :use-initial-account="useInitialAccount"
                     :is-amount-too-low="isAmountTooLow"
                     :term-account-rate="selectedTermAccountRate"
                     :errors="errors"
                     @update:account="setTaxType" />
        </keep-alive>
      </fieldset>
      <form-button-group :cancel-button="{
                           theme: ECoreButtonTheme.GrayLight,
                           text: $t('button.previous')
                         }"
                         data-cy="account.create.term.form.buttons"
                         :disabled="!meta.valid || isAmountTooLow"
                         :loading="loading"
                         :validate-button="{
                           theme: ECoreButtonTheme.Primary,
                           text: isLastStep ? $t('button.subscribe') : $t('button.next')
                         }"
                         @cancel-button-click="getPreviousStep"
                         @validate-button-click="getNextStep" />
    </validation-form>
  </div>
</template>

<script>
import { CoreSteps, ECoreButtonTheme } from '@common/core-ui'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { EAccountType } from '@/types/account.d'
import { ECompanyTaxType } from '@/types/company.d'

import CreateSubAccountValidation from '@/pages/account/create-sub-account/modal/CreateSubAccountValidation.vue'

import ComponentAlert from '@/components/Alert.vue'
import FormButtonGroup from '@/components/FormButtonGroup.vue'
import GroupTitle from '@/components/GroupTitle.vue'

import AmountForm from './create-term-form-steps/AmountForm.vue'
import DurationForm from './create-term-form-steps/DurationForm.vue'
import Summary from './create-term-form-steps/FormSummary.vue'
import TaxTypeForm from './create-term-form-steps/TaxTypeForm.vue'

export default {
  components: {
    AmountForm,
    ComponentAlert,
    CoreSteps,
    FormButtonGroup,
    GroupTitle
  },

  props: {
    termAccountRates: {
      type: Array,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()

    const { error: apiError } = storeToRefs(apiStore)
    const { account: loggedAccount } = storeToRefs(accountStore)

    return {
      accountStore,
      apiError,
      appStore,
      loggedAccount
    }
  },

  data () {
    return {
      account: null,
      activeStep: 0,
      amount: 10000,
      duration: 0,
      loading: false,
      taxType: ECompanyTaxType.CorporationTax,
      useInitialAccount: false
    }
  },

  computed: {
    ECoreButtonTheme () {
      return ECoreButtonTheme
    },

    isAmountTooLow () {
      return this.account.balance < 10000
    },

    isFirstStep () {
      return this.activeStep === 0
    },

    isLastStep () {
      return this.activeStep === (this.steps.length - 1)
    },

    isTaxTypeStep () {
      return this.activeStep === this.steps.findIndex(item => item.value === 'tax_type')
    },

    selectedRate () {
      return this.selectedTermAccountRate.rates.find(rate => rate.duration === this.duration)
    },

    selectedTermAccountRate () {
      let rate = this.termAccountRates[0]

      this.termAccountRates.forEach((termAccountRate, index) => {
        if (this.amount >= termAccountRate.minimum_deposit) {
          rate = this.termAccountRates[index]
        }
      })

      return rate
    },

    steps () {
      return [
        {
          component: AmountForm,
          schema: yup.object({
            amount: yup.number().min(10000).max(this.account.balance).required()
          }),

          value: 'amount'
        },
        {
          component: DurationForm,
          value: 'duration'
        },
        {
          component: TaxTypeForm,
          value: 'tax_type'
        },
        {
          component: Summary,
          value: 'summary'
        }
      ]
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    this.account = cloneDeep(this.loggedAccount)
    if (this.account.type === EAccountType.Main || this.account.type === EAccountType.Sub) {
      this.useInitialAccount = true
    }
  },

  mounted () {
    this.$bus.on('account-switched', this.resetForm)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.resetForm)
  },

  methods: {
    async getNextStep () {
      const result = await this.$refs.form.validate()

      if (!result) {
        return this.onInvalidSubmit()
      }

      if (this.isTaxTypeStep) {
        return this.updateTaxType()
      }

      if (this.isLastStep) {
        return this.onSubmit()
      }

      return this.activeStep++
    },

    getPreviousStep () {
      if (this.isFirstStep) {
        return this.$router.push({ name: 'create-term-account' })
      }

      return this.activeStep--
    },

    onInvalidSubmit () {
      this.$bus.emit('scrollToTop')
    },

    async onSubmit () {
      this.loading = true
      const account = await this.accountStore.createTermAccount(this.account.uuid, this.amount, this.selectedRate.uuid)
      this.loading = false

      if (account) {
        this.appStore.showModal(
          CreateSubAccountValidation,
          { newAccount: account },
          { blockLeaveBackground: true }
        )
      }
    },

    resetForm () {
      this.$router.push({ name: 'create-term-account' })
    },

    setTaxType () {
      if (this.account.company.tax_type) {
        this.taxType = this.account.company.tax_type
      }
    },

    async updateTaxType () {
      this.loading = true
      const success = await this.accountStore.updateCompanyTaxType(this.taxType)
      this.loading = false

      if (!success) {
        return this.onInvalidSubmit()
      }

      return this.activeStep++
    }
  }
}
</script>
