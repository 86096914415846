<template>
  <svg viewBox="0 0 21 15">
    <path fill="#F5F6F9"
          d="M17.933.273H2.613A2.113 2.113 0 0 0 .5 2.385v9.898c0 1.167.946 2.113 2.113 2.113h15.32a2.113 2.113 0 0 0 2.113-2.113V2.385A2.113 2.113 0 0 0 17.933.273Z" />
    <path fill="#676766"
          d="M17.933.273H2.613A2.113 2.113 0 0 0 .5 2.385v9.898a2.11 2.11 0 0 0 2.113 2.113h15.32a2.114 2.114 0 0 0 2.113-2.113V2.385A2.114 2.114 0 0 0 17.933.273Zm1.937 2.112v2.633H14.02l-.025.038c-.025.037-.595.89-.595 2.263 0 1.203.445 2.012.564 2.212-.727 1.242-.665 2.426-.414 3.304-.3.05-1.398.213-3.316.213s-2.99-.163-3.285-.213c.251-.878.314-2.062-.413-3.304.119-.2.564-1.009.564-2.212 0-1.204-.445-2.013-.564-2.213.72-1.235.67-2.401.42-3.279.307-.056 1.379-.213 3.278-.213 1.9 0 2.984.157 3.304.213-.057.2-.1.414-.132.64l.175.025a4.6 4.6 0 0 1 .81-2.037h3.54c1.073 0 1.938.87 1.938 1.937v-.007Zm-5.755 7.059c-.106-.176-.539-.966-.539-2.125 0-1.16.433-1.95.54-2.125h5.754v4.25h-5.755Zm-7.221 3.56c.276.05 1.373.22 3.341.22 1.969 0 3.09-.17 3.366-.22a4.59 4.59 0 0 0 .602 1.223H6.305a5.14 5.14 0 0 0 .602-1.223h-.013Zm-.514-3.56H.676v-4.25h5.716c.107.175.54.965.54 2.125 0 1.16-.433 1.95-.54 2.125H6.38Zm7.215-7.786c-.3-.05-1.41-.22-3.354-.22-1.943 0-3.04.17-3.328.22A4.807 4.807 0 0 0 6.33.448h7.848a4.696 4.696 0 0 0-.583 1.21ZM2.613.448h3.504c.22.301 1.586 2.332.27 4.57H.675V2.385c0-1.072.865-1.937 1.937-1.937ZM.676 12.283v-2.67h5.71c1.41 2.4-.263 4.564-.3 4.607H2.612a1.938 1.938 0 0 1-1.937-1.937Zm17.257 1.937h-3.51c-.038-.05-1.712-2.206-.301-4.607h5.748v2.67a1.938 1.938 0 0 1-1.937 1.937Z" />
  </svg>
</template>

<script>
export default {
  name: 'ImageSim'
}
</script>
