<template>
  <div v-if="loading"
       class="section__loader">
    <loader-spinner />
  </div>
  <div v-else
       class="content-wrapper">
    <div class="form form-personal-info form--w680 form--centered">
      <h2 v-if="!user && displayFooter"
          class="content-title">
        {{ $t('title.my_vehicle_details') }}
      </h2>
      <validation-form v-if="isVehicleSet"
                       ref="form"
                       v-slot="{ errors }"
                       :validation-schema="schema"
                       @keydown.enter.prevent
                       @submit="onSubmit">
        <div class="group-title">
          <h3 class="title">
            {{ $t('subtitle.my_vehicle_details.general_informations') }}
          </h3>
        </div>
        <div class="form__row row">
          <div class="form-group col col--sm-6">
            <label class="label input__label">
              {{ $t('form.vehicle_motorization_type.label') }}
            </label>
            <component-dropdown dropdown-width="100%"
                                label="level"
                                :model="vehicle.is_electrical"
                                :values="motorizations"
                                @select="onMotorizationSelected">
              <template #trigger>
                <div class="select form-control">
                  <span v-if="selectedMotorizaton">
                    {{ selectedMotorizaton.label }}
                  </span>
                  <span v-else
                        class="text-muted">
                    {{ $t('form.common.select') }}
                  </span>
                </div>
              </template>
            </component-dropdown>
          </div>
          <div class="form-group col col--sm-6">
            <label class="label input__label">
              {{ $t('form.vehicle_type.label') }}
            </label>
            <validation-field :model-value="vehicle.type"
                              name="type">
              <component-dropdown dropdown-width="100%"
                                  :values="types"
                                  :model="vehicle.type"
                                  @select="onTypeSelected">
                <template #trigger>
                  <div class="select form-control">
                    <span v-if="selectedType">
                      {{ selectedType.label }}
                    </span>
                    <span v-else
                          class="text-muted">
                      {{ $t('form.common.select') }}
                    </span>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
        </div>
        <div class="form__row row">
          <div v-if="isVehicleAuto || isVehicleMoto"
               :class="{ error: errors.level }"
               class="form-group col col--sm-6">
            <label class="label input__label">
              {{ $t('form.vehicle_power.label') }}
            </label>
            <validation-field :model-value="vehicle.level"
                              name="level">
              <component-dropdown :key="vehicle.type"
                                  dropdown-width="100%"
                                  async-url="/user/vehicle/levels"
                                  :async-url-params="{ type: vehicle.type }"
                                  :model="vehicle.level"
                                  label="level"
                                  @select="onLevelSelected">
                <template #trigger>
                  <div class="select form-control">
                    <span v-if="selectedLevel">
                      {{ selectedLevel.label }}
                    </span>
                    <span v-else
                          class="text-muted">
                      {{ $t('form.common.select') }}
                    </span>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>

          <div :class="{ error: errors.mileage_estimation }"
               class="form-group col col--sm-6">
            <label class="label input__label">
              {{ $t('form.mileage_estimation.label') }}
            </label>
            <validation-field :model-value="vehicle.mileage_estimation"
                              name="mileage_estimation">
              <component-dropdown :key="vehicle.type"
                                  async-url="/user/vehicle/mileages"
                                  :async-url-params="{ type: vehicle.type }"
                                  dropdown-width="100%"
                                  label="mileage_estimation"
                                  :model="vehicle.mileage_estimation"
                                  @select="onMileageSelected">
                <template #trigger>
                  <div class="select form-control">
                    <span v-if="selectedMileage">
                      {{ selectedMileage.label }}
                    </span>
                    <span v-else
                          class="text-muted">
                      {{ $t('form.common.select') }}
                    </span>
                  </div>
                </template>
              </component-dropdown>
            </validation-field>
          </div>
          <div class="group-title mt-3 col col-sm-12">
            <h4 class="title">
              {{ $t('subtitle.my_vehicle_details.registration_document') }}
            </h4>
          </div>
          <div :class="{ error: errors.date_registration }"
               class="form-group col col--sm-6 pt-0">
            <component-datepicker id="date_registration"
                                  v-model="vehicle.date_registration"
                                  name="date_registration"
                                  :disabled-days="disabledFrom"
                                  format="dd/MM/yyyy"
                                  icon-calendar
                                  input-class="form-control"
                                  :label="$t('form.vehicle_date_registration.label')"
                                  :language="$i18n.locale"
                                  monday-first
                                  :placeholder="$t('form.date.placeholder')"
                                  :readonly="false"
                                  wrapper-class="form-group" />
          </div>
        </div>
        <div v-if="!isVehicleBicycle"
             class="form__row row">
          <div :class="{ error: errors.vehicle_registration }"
               class="form-group col col--sm-12">
            <label class="label input__label">
              {{ user ? $t('form.vehicle_registration.label') : $t('form.your_vehicle_registration.label') }} ({{ $t('general.mandatory') }})
            </label>
            <validation-field :model-value="vehicle.files"
                              name="vehicle_registration">
              <dropzone v-model="vehicle.files"
                        mode="inline"
                        :max-files="1"
                        accepted-files="image/jpg,image/jpeg,image/png,image/tif,image/tiff,application/pdf"
                        name="vehicle_registration"
                        @on-file-added="onFileAdded" />
            </validation-field>
          </div>
        </div>
        <div v-if="displayFooter"
             class="form-buttons"
             :class="[showDeleteVehicle ? 'form-buttons--flex' : 'form-buttons--single']">
          <component-button v-if="showDeleteVehicle"
                            :label="$t('button.delete_vehicle')"
                            wrapper-class="btn--outline-red"
                            @click="onDelete" />
          <component-button :label="$t('button.save')"
                            wrapper-class="btn--primary"
                            @click="onSubmit" />
        </div>
      </validation-form>
      <field-placeholder v-else
                         border="dashed"
                         :title="$t('form.vehicle.title')"
                         :subtitle="$t('form.vehicle.subtitle')"
                         @click="showForm" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import * as yup from 'yup'

import { showToastSuccess } from '@/helpers/utils/notification'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'

import ComponentButton from '@/components/Button.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import Dropzone from '@/components/Dropzone.vue'
import FieldPlaceholder from '@/components/FieldPlaceholder.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  name: 'ComponentInfoVehicle',

  components: {
    FieldPlaceholder,
    ComponentDatepicker,
    Dropzone,
    ComponentButton,
    ComponentDropdown,
    LoaderSpinner
  },

  props: {
    user: {
      type: [Object, null],
      required: false,
      default: null
    },

    displayFooter: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const userStore = useUserStore()

    return { appStore, authStore, userStore }
  },

  data () {
    return {
      vehicle: {
        type: 'auto',
        level: null,
        is_electrical: false,
        date_registration: null,
        mileage_estimation: null,
        files: []
      },

      isVehicleSet: false,
      showDeleteVehicle: false,

      disabledFrom: {
        from: new Date()
      },

      selectedType: null,
      selectedMotorizaton: null,
      selectedLevel: null,
      selectedMileage: null,
      loading: true,

      schema: yup.object({
        date_registration: yup.string().required(),
        mileage_estimation: yup.string().required(),
        type: yup.string().required(),
        level: yup.string().when('type', {
          is: type => ['auto', 'motorcycle'].includes(type),
          then: schema => schema.required()
        }),
        vehicle_registration: yup.array().required().min(1)
      })
    }
  },

  computed: {
    types () {
      return [
        {
          value: 'auto',
          label: this.$i18n.t('form.vehicle_type.auto')
        },
        {
          value: 'motorcycle',
          label: this.$i18n.t('form.vehicle_type.motorcycle')
        },
        {
          value: 'light_motorcycle',
          label: this.$i18n.t('form.vehicle_type.light_motorcycle')
        }
      ]
    },

    motorizations () {
      return [
        {
          value: false,
          label: this.$i18n.t('form.vehicle_motorization_type.thermal')
        },
        {
          value: true,
          label: this.$i18n.t('form.vehicle_motorization_type.electric')
        }
      ]
    },

    isVehicleAuto () {
      return this.vehicle.type === 'auto'
    },

    isVehicleMoto () {
      return this.vehicle.type === 'motorcycle'
    },

    isVehicleBicycle () {
      return this.vehicle.type === 'bicycle'
    }
  },

  async created () {
    this.loading = true
    if (this.user) {
      Object.assign(this.vehicle, this.user.vehicle || {})
      this.selectedType = this.types.find(item => item.value === this.vehicle.type)
      this.selectedMotorizaton = this.motorizations.find(item => item.value === this.vehicle.is_electrical)
      this.onLevelSelected(this.vehicle.level ? { label: this.vehicle.level_label, value: this.vehicle.level } : null)
      this.onMileageSelected(this.vehicle.mileage_estimation ? { label: this.vehicle.mileage_estimation_label, value: this.vehicle.mileage_estimation } : null)
    } else {
      const data = await this.authStore.getVehicleInfos()
      if (data) {
        Object.assign(this.vehicle, data)
        this.doesVehicleExist()
        this.onLevelSelected(data.level ? { label: data.level_label, value: data.level } : null)
        this.onMileageSelected(data.mileage_estimation ? { label: data.mileage_estimation_label, value: data.mileage_estimation } : null)
        if (!this.vehicle.type) {
          this.vehicle.type = 'auto'
        }
        this.selectedType = this.types.find(item => item.value === this.vehicle.type)
        this.selectedMotorizaton = this.motorizations.find(item => item.value === this.vehicle.is_electrical)
      }
    }

    if (this.vehicle.files.length) {
      this.isVehicleSet = true
    }

    this.loading = false
  },

  mounted () {
    this.$bus.on('delete-success', this.onDeleteSuccess)
    this.$bus.on('submit-add-vehicle-form', this.onSubmit)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.onDeleteSuccess)
    this.$bus.off('submit-add-vehicle-form', this.onSubmit)
  },

  methods: {
    resetVehicleInfos () {
      this.vehicle.level = null
      this.vehicle.date_registration = null
      this.vehicle.mileage_estimation = null
      this.vehicle.files = []
      this.selectedLevel = null
      this.selectedMileage = null
    },

    onFileAdded (files) {
      this.vehicle.files = [...files]
    },

    async onSubmit () {
      const { valid } = await this.$refs.form.validate()

      if (!valid) {
        return
      }

      const vehicle = { ...this.vehicle }
      vehicle.date_registration = dayjs(vehicle.date_registration).format('YYYY-MM-DD')

      if (this.user) {
        const success = await this.userStore.updateUserVehicle(this.user.uuid, vehicle)
        if (success) {
          this.$bus.emit('vehicle-infos-updated')
          this.doesVehicleExist()
        }
      } else {
        const data = await this.authStore.updateVehicleInfos(vehicle)
        if (data) {
          this.$bus.emit('vehicle-infos-updated', data)
          this.doesVehicleExist()
          if (this.displayFooter) {
            showToastSuccess(this.$i18n.t('message.success.vehicle.update'))
          }
        }
      }
    },

    async onDelete () {
      this.appStore.showDeleteModal({ label: this.$i18n.t('prompt.vehicle.delete.title'), content: this.$i18n.t('prompt.vehicle.delete.content'), url: '/user/vehicle' })
    },

    onDeleteSuccess () {
      showToastSuccess(this.$i18n.t('message.success.vehicle.delete'))
      this.isVehicleSet = false
      this.resetVehicleInfos()
      this.showDeleteVehicle = false
    },

    onTypeSelected (type) {
      this.selectedType = type
      this.vehicle.type = type?.value || null
      this.resetVehicleInfos()
    },

    onLevelSelected (level) {
      this.selectedLevel = level
      this.vehicle.level = level?.value || null
    },

    onMileageSelected (mileage) {
      this.selectedMileage = mileage
      this.vehicle.mileage_estimation = mileage?.value || null
    },

    onMotorizationSelected (motorization) {
      this.selectedMotorizaton = motorization
      this.vehicle.is_electrical = motorization?.value || false
    },

    showForm () {
      this.isVehicleSet = true
    },

    doesVehicleExist () {
      this.showDeleteVehicle = this.vehicle.date_registration !== null &&
          this.vehicle.files.length > 0 &&
          this.vehicle.mileage_estimation !== null &&
          (!this.isVehicleBicycle || this.vehicle.level !== null)
    }
  }
}
</script>
