<template>
  <validation-form v-slot="{ errors, isSubmitting }"
                   ref="form"
                   class="sidepanel__content"
                   :validation-schema="schema"
                   @submit="onSubmit">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h1 class="modal-title">
          {{ $t('sidepanel.bank_details_editing.title') }}
        </h1>
      </div>
      <div class="sidepanel-body">
        <div class="form__row row">
          <div class="form-group col col--sm-12">
            <label class="label input__label">
              {{ $t('button.analyze_rib') }}
            </label>
            <dropzone v-model="ribDocument"
                      :max-files="1"
                      :max-filesize="100"
                      mode="inline"
                      name="rib_document"
                      accepted-files="image/jpg,image/jpeg,image/png,image/tif,image/tiff,application/pdf"
                      @on-file-added="onDocumentAttached">
              <button v-tooltip="{ content: $t('button.analyze_document'), theme: 'tooltip' }"
                      type="button"
                      class="btn-link"
                      @click.prevent.stop="onDocumentAttached([rib])">
                <ic-analyze class="ic ic--20 ic--gray" />
              </button>
            </dropzone>
          </div>
        </div>
        <div class="form__row row row--sm-bottom">
          <div id="ibanInput"
               class="form-group col"
               :class="{ error: errors.iban }">
            <label class="label input__label">{{ $t('general.iban') }}</label>
            <validation-field id="iban"
                              v-model="userIban"
                              class="form-control"
                              type="text"
                              :placeholder="$t('form.your_iban.placeholder')"
                              name="iban"
                              autocomplete="off"
                              @keyup="onIbanKeyUp"
                              @focus="onIbanFocus"
                              @blur="onIbanBlur" />
          </div>
        </div>
        <div class="form__row row row--sm-bottom">
          <div id="bicInput"
               class="form-group col"
               :class="{ error: errors.bic }">
            <label class="label input__label">{{ $t('general.bic_swift') }}</label>
            <validation-field id="bic"
                              v-model="userBicSwift"
                              class="form-control"
                              type="text"
                              :placeholder="$t('form.beneficiary.bic_code.placeholder')"
                              autocomplete="off"
                              name="bic"
                              @keyup="onBicKeyUp" />
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="buttons-wrapper buttons-wrapper--pull-right">
        <div class="btn-grouped">
          <button type="button"
                  class="btn btn--gray"
                  @click="onCloseSidePanel">
            {{ $t('button.cancel') }}
          </button>
          <component-button type="submit"
                            :disabled="isSubmitting || !formHasChanged"
                            class="btn btn--primary"
                            :label="$t('button.edit')" />
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import IBAN from 'iban'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatIban, formatIbanOnKeyUp } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useTransferStore } from '@/stores/transfer'

import ComponentButton from '@/components/Button.vue'
import Dropzone from '@/components/Dropzone.vue'
import DocumentAnalyze from '@/components/modals/DocumentAnalyze.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcAnalyze from '@/components/svg/icons/ic-analyze.vue'

export default {
  components: {
    IcAnalyze,
    Dropzone,
    ComponentButton,
    ComponentSidepanelFooter
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const beneficiaryStore = useBeneficiaryStore()
    const transferStore = useTransferStore()

    const { account } = storeToRefs(accountStore)
    const { modal } = storeToRefs(appStore)
    const { iban } = storeToRefs(transferStore)

    return { account, modal, appStore, beneficiaryStore, iban, transferStore }
  },

  data () {
    return {
      loading: false,
      userIban: '',
      userBicSwift: '',
      rib: null
    }
  },

  computed: {
    schema () {
      return yup.object({
        iban: yup.string().required(),
        bic: yup.string().required()
      })
    },

    formHasChanged () {
      if (this.userIban && this.userIban !== '' && this.account.beneficiary) {
        return (this.userIban.replace(/ /g, '') !== this.account.beneficiary.iban) ||
          (this.userBicSwift.replace(/ /g, '') !== this.account.beneficiary.bic)
      }
      return true
    },

    ribDocument: {
      get () {
        return this.rib ? [this.rib] : []
      },

      set (value) {
        this.rib = value[0] || null
      }
    }
  },

  watch: {
    'account.beneficiary.iban': function () {
      this.userIban = formatIban(this.account.beneficiary?.iban || null)
    },

    'account.beneficiary.bic': function () {
      this.userBicSwift = this.account.beneficiary?.bic || null
    },

    'iban.bic': function () {
      this.userBicSwift = this.iban.bic
    }
  },

  created () {
    if (this.account.beneficiary) {
      this.userIban = formatIban(this.account.beneficiary.iban)
      this.userBicSwift = this.account.beneficiary.bic
    }
  },

  mounted () {
    this.$bus.on('beneficiary-rib-upload-success', this.onRibUploadSuccess)
  },

  beforeUnmount () {
    this.$bus.off('beneficiary-rib-upload-success', this.onRibUploadSuccess)
  },

  methods: {
    onCloseSidePanel () {
      this.appStore.closeSidePanel()
    },

    onDocumentAttached (files) {
      if (!files[0] || files.length !== 1) { return }

      this.rib = files[0]
      if (this.rib.status === 'error') return

      this.appStore.showModal(
        DocumentAnalyze,
        {
          title: this.$i18n.t('prompt.beneficiary.rib.title'),
          content: this.$i18n.t('prompt.beneficiary.rib.content'),
          type: 'beneficiary',
          fileToAnalyze: files[0]
        },
        { wrapperClass: 'modal-document-analyze' }
      )
    },

    onRibUploadSuccess (data) {
      if (!data || !this.modal.active) return

      if (data.iban) {
        this.userIban = data.iban
        document.getElementById('ibanInput').classList.add('is-valid')
      }

      if (data.bic) {
        this.userBicSwift = data.bic
        document.getElementById('bicInput').classList.add('is-valid')
      }
    },

    async onSubmit () {
      const data = await this.beneficiaryStore.editBeneficiaryRib({ iban: this.userIban, rib: this.userBicSwift })

      if (data) { // TODO assign computed setter
        this.account.beneficiary = data
        showToastSuccess(this.$i18n.t('message.success.bank_details.edit'))
        this.onCloseSidePanel()
      }
    },

    onBicKeyUp () {
      document.getElementById('bicInput').classList.remove('is-valid')
    },

    onIbanKeyUp (e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        return
      }
      const cursorPosition = e.target.selectionStart
      document.getElementById('ibanInput').classList.remove('is-valid')
      const formattedIban = formatIbanOnKeyUp(this.userIban)
      if (formattedIban) {
        this.userIban = formattedIban
        this.$nextTick(() => {
          this.setCursorPosition(e, cursorPosition)
        })
      }
    },

    setCursorPosition (e, cursorPosition) {
      if (e.key === 'Backspace' && cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition -= 1
      } else if (!/[a-z0-9]/i.test(e.key)) {
        cursorPosition -= 1
      } else if (cursorPosition > 0 && cursorPosition % 5 === 0) {
        cursorPosition += 1
      }
      e.target.setSelectionRange(cursorPosition, cursorPosition)
    },

    onIbanFocus (e) {
      e.target.oldValue = e.target.value
    },

    async onIbanBlur (e) {
      if (e.target.oldValue === e.target.value) {
        return
      }

      if (IBAN.isValid(e.target.value)) {
        this.userIban = formatIban(e.target.value)
      }

      const response = await this.transferStore.validateIban(e.target.value)
      if (typeof response?.sepa === 'boolean' && response.sepa) {
        this.userBicSwift = response.bic
      } else {
        this.setError()
      }
    },

    setError () {
      this.$refs.form.setFieldError('iban', 'error')
      this.$refs.form.setFieldError('bic', 'error')
    }
  }
}
</script>
