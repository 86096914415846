<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else-if="expenseRequests.length">
      <component-alert v-if="state === 'pending' && counts.toApprove > 1"
                       type="info"
                       :message="$tc('alerts.expense_requests_to_approve', counts.toApprove)">
        <div class="alert__actions">
          <button type="button"
                  class="btn btn--xs btn--info"
                  @click="approveAll">
            {{ $t('button.approve_all') }}
          </button>
        </div>
      </component-alert>
      <component-table class="table--hover table-operations table--fixed"
                       data-cy="expense-requests.list">
        <thead>
          <tr>
            <th style="width: 8rem" />
            <th class="col-date">
              {{ $t("table.date") }}
            </th>
            <th :class="showColAction ? 'col--sm-3' : 'col--sm-4'">
              {{ $t("table.label") }}
            </th>
            <th :class="showColAction ? 'col--sm-2' : 'col--sm-3'">
              {{ $t("table.beneficiary") }}
            </th>
            <th class="col--sm-2 right">
              {{ $t("table.amount") }}
            </th>
            <th class="center col--sm-1">
              {{ $t("table.documents") }}
            </th>
            <th v-if="showColAction"
                class="col-action"
                :class="setClassColAction" />
          </tr>
        </thead>
        <tbody>
          <template v-for="(requestList, listLabel) in sortedRequests">
            <tr v-if="showRequestSort && requestList.length"
                :key="listLabel"
                class="header-table-section">
              <td :colspan="showColAction ? 7 : 6">
                <span class="arrow">&rsaquo;</span>{{ listLabel }}
              </td>
            </tr>
            <tr v-for="item in requestList"
                :id="`expense-request-${item.id}`"
                :key="item.id"
                :data-cy="`expense-requests.list.table.item-${item.id}.tr`"
                class="is-link"
                :class="{ 'is-cancelled': item.status === 'cancelled' }"
                @click="showDetail(item)">
              <td>
                <core-badge v-bind="statusBadge(item)" />
              </td>
              <td class="text-muted">
                {{ formatDate(item.settlement_date) }}
              </td>
              <td class="text-overflow">
                <span>{{ item.label }}</span>
              </td>
              <td class="text-overflow">
                <span><template v-if="item.beneficiary">{{ item.beneficiary.label }}</template></span>
              </td>
              <td class="right">
                <div class="amount">
                  {{ formatAmount(item.amount, item.currency) }}
                </div>
                <core-badge v-if="item.fx_currency && item.fx_currency.iso !== item.currency.iso"
                            :theme="ECoreBadgeTheme.Currency"
                            :size="ECoreBadgeSize.Small"
                            :value="formatAmount(item.fx_amount, item.fx_currency, { currencyDisplay: 'code' })" />
              </td>
              <td class="center">
                <template v-if="item.status === 'pending' || item.documents?.length">
                  <button v-if="item.documents && item.documents?.length"
                          v-tooltip="{ content: $tc('tooltip.show_file', item.documents?.length), theme: 'tooltip' }"
                          class="btn-link btn-attachment"
                          :data-cy="`expense-requests.list.table.item-${item.id}.view-document`"
                          @click.prevent.stop="showFile(item)">
                    <ic-document class="ic ic--gray" />
                  </button>
                  <button v-else
                          v-tooltip="{ content: $t('tooltip.attach_files'), theme: 'tooltip' }"
                          class="btn-link btn-attachment"
                          :data-cy="`expense-requests.list.table.item-${item.id}.attach-document`"
                          @click.prevent.stop="showFile(item)">
                    <ic-attachment class="ic ic--gray" />
                  </button>
                </template>
              </td>
              <td v-if="showColAction"
                  class="col-action"
                  :class="setClassColAction">
                <span v-if="canApproveRequest(item)"
                      class="hover-item">
                  <button v-tooltip="{ content: $t('button.reject'), theme: 'tooltip' }"
                          class="btn-link"
                          :data-cy="`expense-requests.list.table.item-${item.id}.reject`"
                          @click.prevent.stop="rejectRequest(item)">
                    <ic-remove class="ic ic--24 ic--gray" />
                  </button>
                  <button v-tooltip="{ content: $t('button.approve'), theme: 'tooltip' }"
                          class="btn-link hover-item-visible"
                          :data-cy="`expense-requests.list.table.item-${item.id}.approve`"
                          @click.prevent.stop="approveRequest(item.uuid)">
                    <ic-check-circle outline
                                     class="ic ic--24 ic--gray" />
                  </button>
                </span>
                <span v-else-if="canCancelRequest(item)">
                  <button v-tooltip="{ content: $t('button.cancel'), theme: 'tooltip' }"
                          class="btn-link"
                          :data-cy="`expense-requests.list.table.item-${item.id}.cancel`"
                          @click.prevent.stop="cancelRequest(item.uuid)">
                    <ic-remove class="ic ic--24 ic--gray" />
                  </button>
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </component-table>
      <component-pagination :pagination="expenseRequestsPagination" />
    </template>
    <component-placeholder v-else
                           data-cy="expense-requests.list.placeholder"
                           :label="$t('placeholder.no_request.title')"
                           content="" />
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeSize, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate } from '@/helpers/utils/date'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useExpenseRequestStore } from '@/stores/expense-request'

import RejectRequest from '@/pages/expense_requests/modal/RejectRequest.vue'
import ExpenseRequestDetail from '@/pages/expense_requests/sidepanel/ExpenseRequestDetail.vue'

import ComponentAlert from '@/components/Alert.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
import ComponentTable from '@/components/Table.vue'

export default {
  components: {
    CoreBadge,
    ComponentAlert,
    IcRemove,
    IcCheckCircle,
    IcAttachment,
    IcDocument,
    ComponentTable,
    ComponentPagination,
    ComponentPlaceholder,
    LoaderSpinner
  },

  props: {
    state: {
      type: String,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    uuid: {
      type: String,
      default: ''
    },

    filter: {
      type: Object,
      required: true
    }
  },

  emits: ['pagination-total-items'],

  setup () {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const expenseRequestsStore = useExpenseRequestStore()

    const { expenseRequests, expenseRequestsPagination, counts } = storeToRefs(expenseRequestsStore)
    const { user } = storeToRefs(authStore)

    return {
      appStore,

      counts,
      expenseRequests,
      expenseRequestsPagination,
      expenseRequestsStore,
      user
    }
  },

  data: function () {
    return {
      loading: false
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    isPending () {
      return this.state === 'pending'
    },

    setClassColAction () {
      if (this.showColAction) {
        return 'col-action--2-items pl-0'
      }
      return ''
    },

    showColAction () {
      return this.expenseRequests.some(item => this.canApproveRequest(item) || this.canCancelRequest(item))
    },

    showRequestSort () {
      return this.isPending && this.counts.toApprove
    },

    sortedRequests () {
      const toApproveRequests = []
      const processingRequests = []

      this.expenseRequests.forEach(request => {
        if (this.canApproveRequest(request) && this.isApprover(request)) {
          toApproveRequests.push(request)
        } else {
          processingRequests.push(request)
        }
      })

      return {
        [this.$i18n.t('table.requests.to_approve')]: toApproveRequests,
        [this.$i18n.t('table.requests.all')]: processingRequests
      }
    }
  },

  watch: {
    page () {
      this.initData()
    },

    filter () {
      this.resetData()
    },

    'expenseRequestsPagination.totalItems' () {
      this.$emit('pagination-total-items', this.expenseRequestsPagination.totalItems)
    }
  },

  created () {
    this.initData()
    if (this.uuid) this.showDetail({ uuid: this.uuid })
  },

  mounted () {
    this.$bus.on('account-switched', this.resetData)
    this.$bus.on('expense-request-approve-success', this.reloadData)
    this.$bus.on('expense-request-reject', this.reloadData)
    this.$bus.on('expense-request-update-success', this.reloadData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.resetData)
    this.$bus.off('expense-request-approve-success', this.reloadData)
    this.$bus.off('expense-request-reject', this.reloadData)
    this.$bus.off('expense-request-update-success', this.reloadData)
  },

  methods: {
    formatAmount,

    formatDate,

    isApprover (request) {
      return request.approvers.find(approver => { return approver.user.uuid === this.user.uuid })
    },

    resetData () {
      this.page > 1
        ? this.$router.push({ name: this.$router.currentRoute.name })
        : this.initData()
    },

    async initData (showLoader = true) {
      this.loading = showLoader
      const filter = { ...this.filter }
      await this.expenseRequestsStore.getExpenseRequests(this.state, this.page, filter)
      this.loading = false
    },

    reloadData () {
      this.initData(false)
    },

    showFile (request) {
      if (request.documents.length) {
        this.showDetail(request, true)
      } else {
        this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file', 2), request, { url: `/requests/${request.uuid}/documents`, hasMultipleFile: true })
      }
    },

    statusBadge (request) {
      if (request.status === 'pending') {
        if (this.canApproveRequest(request) && this.isApprover(request)) {
          return {
            value: this.$i18n.t('general.to_approve'),
            theme: this.ECoreBadgeTheme.Info
          }
        } else {
          return {
            value: this.$i18n.t('general.pending'),
            theme: this.ECoreBadgeTheme.Info,
            fill: this.ECoreBadgeFill.Shaded
          }
        }
      } else {
        return {
          value: request.status_label,
          ...getBadgeProps(request.status)
        }
      }
    },

    showDetail (request, initShowFile = false) {
      this.appStore.showSidePanel(ExpenseRequestDetail, { uuid: request.uuid }, { wrapperClass: 'sidepanel--md', backgroundOverlay: true, canDragAndDrop: true, initShowFile })
    },

    canApproveRequest (request) {
      return request.status === 'pending' && this.hasPermission(this.$permissions.expenseRequestsItemsApprove, request)
    },

    canCancelRequest (request) {
      return request.status === 'pending' && this.hasPermission(this.$permissions.expenseRequestsItemsDelete, request)
    },

    async approveRequest (uuid) {
      const success = await this.expenseRequestsStore.approveExpenseRequest(uuid)
      if (success) {
        this.$bus.emit('expense-request-approve-success')
        showToastSuccess(`${this.$i18n.tc('message.success.approve_request', 0)}`)
      }
    },

    async rejectRequest (item) {
      this.appStore.showModal(
        RejectRequest,
        {
          uuid: item.uuid,
          user: item.user
        },
        {
          wrapperClass: 'modal--xs'
        })
    },

    async cancelRequest (uuid) {
      const success = await this.expenseRequestsStore.cancelExpenseRequest(uuid)
      if (success) {
        this.$bus.emit('expense-request-reject')
        showToastSuccess(`${this.$i18n.t('message.success.cancel_request')}`)
      }
    },

    async approveAll () {
      const success = await this.expenseRequestsStore.approveAllExpenseRequest()
      if (success) {
        this.$bus.emit('expense-request-approve-success')
        showToastSuccess(`${this.$i18n.tc('message.success.approve_request', 2)}`)
      }
    }
  }
}
</script>
