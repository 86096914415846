import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import Reconnect from '@/components/modals/Reconnect.vue'

function pageRequiresNotAuth (to) {
  return to.matched.some(record => record.meta.requiresAuth === false) &&
    !to.matched.some(record => record.meta.requiresAuth === true)
}

function pageRequiresNone (to) {
  return to.matched.every(record => !Object.prototype.hasOwnProperty.call(record.meta, 'requiresAuth'))
}

export function pageRequiresAuth (to) {
  return !pageRequiresNotAuth(to) && !pageRequiresNone(to)
}

async function Guard (to, from, next) {
  const appStore = useAppStore()
  const apiStore = useApiStore()
  appStore.closeModal()
  appStore.closeSidePanel()
  appStore.closeFileViewer()

  document.getElementsByClassName('sidebar')[0]?.classList.remove('is-open')

  apiStore.abortAllRequests()

  if (!to.matched.length) {
    next('')
    return
  }

  if (pageRequiresNone(to)) {
    next()
    return
  }

  const authStore = useAuthStore()

  if (authStore.loading) {
    await authStore.loading()
  }

  if (pageRequiresNotAuth(to)) {
    authStore.user.token ? next({ name: 'login' }) : next()

    return
  }

  // check if token is expired or null
  if (authStore.isTokenExpired) {
    if (!authStore.user.token || !from.name) {
      authStore.redirectAfterLogin = to
      next({ name: 'login' })

      return
    }

    next(false)

    appStore.showModal(Reconnect, { location: to }, { wrapperClass: 'modal--sm', onBackgroundLeaveAction: true })

    return
  }

  // Route with no account required TODO use a significative meta
  if (to.meta.scaView === true) {
    next()

    return
  }

  const accountStore = useAccountStore()

  if (authStore.isScaSectionEnforced && accountStore.account.permissions.length) {
    next({ name: 'account-sca' })

    return
  }

  const nextAllowedLocation = accountStore.getUserNextPathBasedOnPermissions(to)
  to.name !== nextAllowedLocation.name
    ? next(nextAllowedLocation)
    : next()
}

export default Guard
