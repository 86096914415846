import dayjs, { Dayjs } from 'dayjs'

import { formatAmount } from '@/helpers/utils/number'
import { Model } from '@/models/Model'
import { ITreasuryEvolution } from '@/types/treasury.d'
export class ModelTreasuryEvolution extends Model {
  readonly balance: number
  readonly balance_date: Dayjs
  private constructor (properties: Partial<ITreasuryEvolution>) {
    super()

    this.balance = properties.balance || 0
    this.balance_date = dayjs.tz(properties?.balance_date, 'Europe/Paris')

    return this.onConstructed()
  }

  static create (properties: Partial<ITreasuryEvolution>): ModelTreasuryEvolution {
    return new ModelTreasuryEvolution(properties || {})
  }

  get balanceDateFormatted () {
    return this.balance_date.format('YYYY-MM-DD')
  }

  get balanceDateTooltipFormatted () {
    return this.balance_date.endOf('month').format('DD MMMM YYYY')
  }

  get balanceFormatted () {
    return formatAmount(this.balance)
  }
}
