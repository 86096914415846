<template>
  <svg viewBox="0 0 20 20">
    <circle cx="10"
            cy="10"
            fill="#42d2a4"
            r="10" />
    <path d="m4.97 10.697 2.833 2.833.572.573.527-.615 5.667-6.611-1.138-.976-5.14 5.996-2.261-2.26z"
          fill="#f2fdf9" />
  </svg>
</template>

<script>
export default {
}
</script>
