<template>
  <div ref="sidepanelContent"
       class="sidepanel__content">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper sidepanel-wrapper--flex overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t(`sidepanel.team.title.${type}`) }}
        </h2>
      </div>
      <div class="sidepanel-body"
           :class="{ 'sidepanel-body--flex': loading || showPlaceholder }">
        <loader-spinner v-if="loading"
                        class="c c--vh"
                        size="22" />

        <component-placeholder v-else-if="showPlaceholder"
                               :label="$t('placeholder.team.no_user.title')"
                               :content="$t('placeholder.team.no_user.subtitle', { type: memberType })">
          <users-management grey />
        </component-placeholder>
        <dropdown-members-select v-show="!loading && !showPlaceholder"
                                 v-model:selected-members="selectedMembers"
                                 :type="type"
                                 :hide-members="hideMembers" />
      </div>
    </div>

    <component-sidepanel-footer>
      <div class="buttons-wrapper buttons-wrapper--pull-right">
        <div class="btn-grouped">
          <button type="button"
                  class="btn btn--gray"
                  @click="appStore.closeSidePanel">
            {{ $t('button.cancel') }}
          </button>
          <component-button :label="$t('button.validate')"
                            :disabled="loading || showPlaceholder"
                            wrapper-class="btn btn--primary"
                            @click="onSubmit" />
        </div>
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

import DropdownMembersSelect from '@/pages/teams/components/DropdownMembersSelect.vue'

import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import UsersManagement from '@/components/svg/img/users-management.vue'

export default {
  components: {
    ComponentSidepanelFooter,
    DropdownMembersSelect,
    LoaderSpinner,
    ComponentPlaceholder,
    UsersManagement,
    ComponentButton
  },

  props: {
    type: {
      type: String,
      required: true
    },

    members: {
      type: Array,
      required: true
    },

    hideMembers: {
      type: Array,
      default: () => ([])
    }
  },

  setup () {
    const appStore = useAppStore()

    return { appStore }
  },

  data () {
    return {
      selectedMembers: [],
      loading: true,
      usersLoaded: {
        users: [],
        bySearch: false
      }
    }
  },

  computed: {
    noActiveUsers () {
      return this.usersLoaded.users.length === 0
    },

    memberType () {
      return this.$t(`table.${this.type}`).toLowerCase()
    },

    showPlaceholder () {
      return this.noActiveUsers && !this.usersLoaded.bySearch
    }
  },

  mounted () {
    this.$bus.on('members-loaded', this.membersLoaded)
  },

  unmounted () {
    this.$bus.off('members-loaded', this.membersLoaded)
  },

  async created () {
    this.selectedMembers = [...this.members]
    this.membersType = this.$t(`sidepanel.team.title.${this.type}`).toLowerCase()
  },

  methods: {
    membersLoaded (data) {
      this.usersLoaded = data
      this.loading = false
    },

    onSubmit () {
      this.$bus.emit('choose-members', { type: this.type, members: this.selectedMembers })
      this.appStore.closeSidePanel()
    }
  }
}
</script>
