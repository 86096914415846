<template>
  <form class="form form-newuser">
    <div class="form-actions">
      <router-link type="button"
                   class="btn btn--block btn--secondary"
                   :to="{ name: 'invitation-create-account', query: { token } }">
        {{ $t('button.create_user_account') }}
      </router-link>
      <router-link type="button"
                   class="btn btn--block btn--primary"
                   :to="{ name: 'invitation-existing-account', query: { token } }">
        {{ $t('button.existing_user_account') }}
      </router-link>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      required: true
    }
  }
}
</script>
