<template>
  <div class="modal__content">
    <modal-header close-button
                  :title="title"
                  @close="$emit('close')" />
    <div class="modal__body">
      <p v-if="subtitle"
         class="pre-line mb-2">
        {{ subtitle }}
      </p>
      <slot name="body" />
    </div>
    <div class="modal__footer">
      <div class="modal__footer__left" />
      <div class="modal__footer__right">
        <slot name="footerButtons" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from './ModalHeader.vue'

export default {
  components: {
    ModalHeader
  },

  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: ''
    }
  },

  emits: ['close']
}
</script>

<style lang="stylus" scoped>
.modal__content
  background-color white
  border-radius $modal-border-radius
  padding 4.4rem

.modal__footer
  margin-top 4rem
  justify-content space-between
  display flex
  flex-shrink 0

  &:deep(.btn),
  &:deep(.core-button)
    min-width 12rem

  &__left
  &__right
    display flex
    align-items center

  &__left
    justify-content flex-start

    .ic-info-outline
      margin-left 0.7rem

    &:deep(.btn),
    &:deep(.core-button)
      margin-right 1rem

  &__right
    justify-content flex-end

    &:deep(.btn),
    &:deep(.core-button)
      margin-left 1rem

.modal__body
  .modal-close
    position absolute
    top 3rem
    right 3rem
    width 2rem
    height @width
    font-size 2rem
    transition opacity 0.3s ease
    line-height 0
    opacity 0.7

    &:hover
      opacity 1
</style>
