<template>
  <svg viewBox="0 0 24 24">
    <path d="M16.616 10.238a.816.816 0 0 1 1.142-.01.789.789 0 0 1 .01 1.127l-3.904 3.92a2 2 0 1 1-1.131-1.136zM12 4c6.078 0 11 5.03 11 11.23a.77.77 0 0 1-.767.77.769.769 0 0 1-.768-.77c0-5.354-4.24-9.69-9.465-9.69-5.224 0-9.465 4.336-9.465 9.69a.769.769 0 1 1-1.535 0C1 9.03 5.922 4 12 4z" />
  </svg>
</template>

<script>
export default {
}
</script>
