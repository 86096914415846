<template>
  <modal-dialog-placeholder @close="emit('close')">
    <component-placeholder :button="hasPinEditionFeature ? $t('placeholder.undefined_pin_code.button') : null"
                           :content="hasPinEditionFeature ? $t('placeholder.undefined_pin_code.content') : null"
                           :icon-width="160"
                           :icon-height="120"
                           :label="$t('placeholder.undefined_pin_code.label')"
                           @btn-click="definePin"
                           @close="emit('close')">
      <image-card warning />
    </component-placeholder>
  </modal-dialog-placeholder>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { EFeature, hasFeature } from '@/config/features'
import { useAppStore } from '@/stores/app'

import ModalDialogPlaceholder from '@/components/modal-template/ModalDialogPlaceholder.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageCard from '@/components/svg/img/card.vue'

import EditPinCode from './EditPinCode.vue'

const props = defineProps<{
  card: object
}>()

const emit = defineEmits<{
  close: []
}>()

const hasPinEditionFeature = computed(() => {
  return hasFeature(EFeature.CardPinEdition, props.card)
})

const appStore = useAppStore()

function definePin () {
  appStore.showModal(EditPinCode, { card: props.card }, { wrapperClass: 'modal--xs' })
}
</script>

<style lang="stylus" scoped>
.placeholder-action-description
  margin-bottom auto
  margin-top 0
</style>
