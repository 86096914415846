import { Model } from '@/models/Model'
interface IRate {
  uuid: string
  duration: number
  client_rate: number
}

export interface ITermAccountRate {
  minimum_deposit: number
  rates: IRate[]
}

export class ModelTermAccountRate extends Model {
  readonly minimum_deposit: number
  readonly rates: IRate[]

  private constructor (properties: ITermAccountRate) {
    super()

    this.minimum_deposit = properties.minimum_deposit
    this.rates = properties.rates

    return this.onConstructed()
  }

  static create (properties: ITermAccountRate): ModelTermAccountRate {
    return new ModelTermAccountRate(properties)
  }

  getDurations () {
    return this.rates.map(rate => rate.duration)
  }

  getRateByDuration (duration: number) {
    return this.rates.find(rate => rate.duration === duration)?.client_rate
  }
}
