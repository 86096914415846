<template>
  <svg viewBox="0 0 24 24">
    <defs>
      <path id="ic-arrow/path/a"
            d="m13 19.806 7.778-7.778L13 4.25l-1.414 1.414L16.922 11H3.5v2h13.478l-5.392 5.392L13 19.806Z" />
    </defs>
    <use v-if="next"
         href="#ic-arrow/path/a" />
    <use v-else
         href="#ic-arrow/path/a"
         transform="rotate(180, 12, 12)" />
  </svg>
</template>

<script>
export default {
  props: {
    next: Boolean
  }
}
</script>
