<template>
  <svg width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.375 2.5C1.375 1.87868 1.87868 1.375 2.5 1.375H5.625V2.625H2.625V13.375H13.375V10.375H14.625V13.5C14.625 14.1213 14.1213 14.625 13.5 14.625H2.5C1.87868 14.625 1.375 14.1213 1.375 13.5V2.5ZM8.375 1.375H13.5C14.1213 1.375 14.625 1.87868 14.625 2.5V7.625H13.375V3.50896L8.442 8.44196L7.55812 7.55807L12.4912 2.625H8.375V1.375Z"
          fill="#979EAC" />
  </svg>
</template>

<script>
export default {
}
</script>
