<template>
  <svg viewBox="0 0 16 16">
    <path d="M8.5 2a.5.5 0 0 1 .5.5V7h4.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H9v4.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V9H2.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H7V2.5a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
