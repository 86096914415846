<template>
  <div class="modal__content">
    <modal-header align-title="center"
                  close-button
                  size="small"
                  :title="$t('create_term_account.penalties.title')"
                  @close="$emit('close')" />
    <div class="modal__body">
      <data-table :data="dataTableData"
                  :headers="dataTableHeaders" />
    </div>
  </div>
</template>

<script>
import { formatPercentage } from '@/helpers/utils/number'

import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import DataTable from '@/components/table/DataTable.vue'

export default {
  components: {
    DataTable,
    ModalHeader
  },

  emits: ['close'],

  computed: {
    dataTableData () {
      return [{
        6: formatPercentage(-0.5),
        9: formatPercentage(-0.75),
        12: formatPercentage(-1),
        18: formatPercentage(-1),
        24: formatPercentage(-1),
        time: this.$i18n.t('create_term_account.penalties.table.row.1.time')
      }, {
        6: formatPercentage(-0.25),
        9: formatPercentage(-0.5),
        12: formatPercentage(-0.75),
        18: formatPercentage(-0.75),
        24: formatPercentage(-1),
        time: this.$i18n.t('create_term_account.penalties.table.row.2.time')
      }, {
        6: '-',
        9: formatPercentage(-0.25),
        12: formatPercentage(-0.5),
        18: formatPercentage(-0.5),
        24: formatPercentage(-0.75),
        time: this.$i18n.t('create_term_account.penalties.table.row.3.time')
      }, {
        6: '-',
        9: '-',
        12: formatPercentage(-0.25),
        18: formatPercentage(-0.50),
        24: formatPercentage(-0.75),
        time: this.$i18n.t('create_term_account.penalties.table.row.4.time')
      }, {
        6: '-',
        9: '-',
        12: '-',
        18: formatPercentage(-0.25),
        24: formatPercentage(-0.50),
        time: this.$i18n.t('create_term_account.penalties.table.row.5.time')
      },
      {
        6: '-',
        9: '-',
        12: '-',
        18: '-',
        24: formatPercentage(-0.25),
        time: this.$i18n.t('create_term_account.penalties.table.row.6.time')
      }]
    },

    dataTableHeaders () {
      const headers = [{
        label: this.$i18n.t('create_term_account.penalties.table.time'),
        value: 'time'
      }]

      const values = [6, 9, 12, 18, 24]
      values.forEach(value => {
        headers.push({
          groupHeader: {
            label: this.$i18n.t('create_term_account.penalties.table.maturity'),
            value: 'maturity'
          },
          label: this.$i18n.t('general.months', { n: value }),
          value
        })
      })

      return headers
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal__content
  background-color white
  border-radius $modal-border-radius
  padding 4.4rem
  min-width 80rem

:deep(.data-table)
  td:first-child,
  tr:first-child th:first-child
      border 1px solid $borderColor
      max-width 18rem
      width 18rem
</style>
