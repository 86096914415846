<template>
  <component-placeholder icon
                         :button="$t('button.continue')"
                         :label="$t('create_additional_account.placeholder.title')"
                         :content="$t('create_additional_account.placeholder.content')"
                         :content-info="$t('create_additional_account.placeholder.content_info')"
                         @btn-click="$router.push({ name: 'create-additional-account-form' })">
    <multiple-bank-account />
  </component-placeholder>
</template>

<script>
import ComponentPlaceholder from '@/components/Placeholder.vue'
import MultipleBankAccount from '@/components/svg/img/multiple-bank-account.vue'

export default {
  components: {
    ComponentPlaceholder,
    MultipleBankAccount
  },

  emits: ['next']
}
</script>
