<template>
  <section class="section section-credits">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.credits") }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <button class="btn btn--primary"
                  type="button"
                  @click="createCredit">
            {{ $t("button.create_credit") }}
          </button>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange
                       ]"
                       :number-of-results="numberOfResults"
                       :total-amount="creditsTotalAmount"
                       @filters="filters" />
    <core-block-summary v-if="creditsPendingAmount !== null"
                        :items="creditsSummary"
                        class="mb-3" />
    <tabs :data="segments" />
    <router-view :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { CoreBlockSummary } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCreditStore } from '@/stores/credit'
import { EFilter } from '@/types/filter.d'

import CreateCredit from '@/pages/credits/sidepanel/CreateCredit.vue'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'PageCredits',

  components: {
    CoreBlockSummary,
    ButtonFilters,
    ComponentFilters,
    Tabs
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const creditStore = useCreditStore()

    const { account } = storeToRefs(accountStore)
    const { creditsPendingAmount, creditsTotalAmount } = storeToRefs(creditStore)

    return { account, appStore, creditsPendingAmount, creditsTotalAmount }
  },

  data () {
    return {
      store,
      numberOfResults: null,
      filter: {
        search: null,
        from: null,
        to: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.pending'),
          route: { name: 'credits' }
        },
        {
          label: this.$i18n.tc('button.received'),
          route: { name: 'credits-done' }
        }
      ]
    },

    creditsSummary () {
      return [
        {
          title: formatAmount(this.creditsPendingAmount, this.account.currency),
          subtitle: this.$t('label.incoming_transfers')
        },
        {
          title: formatAmount(this.creditsPendingAmount + this.account.balance, this.account.currency),
          subtitle: this.$t('label.balance_estimated')
        }
      ]
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    formatAmount,

    initData () {
      this.numberOfResults = null
      this.filter = {
        search: null,
        from: null,
        to: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to
      }
    },

    createCredit () {
      this.appStore.showSidePanel(CreateCredit, { model: {} }, { wrapperClass: 'sidepanel--700', backgroundOverlay: true })
    }
  }
}
</script>
