<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 4c4.05 0 7.717 2.667 11 8-3.283 5.333-6.95 8-11 8s-7.717-2.667-11-8c3.283-5.333 6.95-8 11-8zm0 1.5c-3.19 0-6.2 2.015-9.036 6.22l-.187.28.187.28c2.76 4.092 5.683 6.11 8.778 6.216L12 18.5c3.19 0 6.2-2.015 9.036-6.22l.186-.28-.186-.28c-2.76-4.092-5.683-6.11-8.778-6.216zM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 1.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z" />
  </svg>
</template>

<script>
export default {
}
</script>
