<template>
  <validation-form v-slot="{ errors, isSubmitting, meta }"
                   class="sidepanel__content"
                   :validation-schema="schema"
                   @submit="onSubmit"
                   @keydown.enter.prevent>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h1 class="modal-title">
          {{ $t('sidepanel.phone_number_editing.title') }}
        </h1>
      </div>
      <div class="sidepanel-body">
        <div class="form__row row row--sm-bottom">
          <div class="form-group col">
            <strong>{{ $t('title.important') }} :</strong>
            {{ $t('message.phone_number_utility.subtitle') }}
          </div>
          <div class="form-group col"
               :class="{ error: errors.phone_number && formSubmitted }">
            <label class="label input__label">{{ $t("form.phone.label") }}</label>
            <phone-input v-model="userPhone"
                         name="phone_number" />
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div class="buttons-wrapper buttons-wrapper--pull-right">
        <div class="btn-grouped">
          <button type="button"
                  class="btn btn--gray"
                  @click="onCloseSidePanel">
            {{ $t('button.cancel') }}
          </button>
          <component-button type="submit"
                            :disabled="!meta.dirty || isSubmitting"
                            class="btn btn--primary"
                            :label="$t('button.edit')"
                            @click="onSubmit" />
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import ComponentButton from '@/components/Button.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    PhoneInput,
    ComponentButton,
    ComponentSidepanelFooter
  },

  setup () {
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const { user } = storeToRefs(authStore)

    return { user, authStore, appStore }
  },

  data () {
    return {
      userPhone: this.user.phone,
      formSubmitted: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        phone_number: yup.string().validPhoneNumber().nullable()
      })
    }
  },

  methods: {
    onCloseSidePanel () {
      this.appStore.closeSidePanel()
    },

    async onSubmit () {
      this.formSubmitted = true
      const success = await this.authStore.updatePhoneNumber(this.userPhone)
      if (success) this.onCloseSidePanel()
    }
  }
}
</script>
