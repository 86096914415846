<template>
  <div class="fs-page__content page-wire-check">
    <loader-spinner v-if="loading" />
    <div v-else
         class="block"
         :class="[error ? 'block--error' : 'block--success']">
      <div class="block__header">
        <ic-status class="status-icon ic--white"
                   :error="error" />
        {{ $t(getTitle) }}
      </div>
      <div class="block__content">
        <div v-if="error"
             class="response-msg">
          <h3>{{ $t('wirecheck.details.title_error') }}</h3>
          <p>{{ $t('wirecheck.details.content_error') }}</p>
        </div>
        <div v-else>
          <h4>{{ $t('wirecheck.details.title_success') }}</h4>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('general.reason') }}
            </div>
            <div class="response-row__data">
              {{ transfer.label }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('wirecheck.details.status') }}
            </div>
            <div class="response-row__data">
              {{ transfer.status_label }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('wirecheck.details.execution_date') }}
            </div>
            <div class="response-row__data">
              {{ formatDateText(transfer.execution_date) }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('wirecheck.details.amount') }}
            </div>
            <div class="response-row__data">
              {{ formatAmount(transfer.amount, transfer.currency) }}
            </div>
          </div>
          <div class="response-row">
            <div class="response-row__name">
              {{ $t('wirecheck.details.account') }}
            </div>
            <div class="response-row__data">
              {{ formatIban(transfer.beneficiary.iban) }}
            </div>
          </div>
        </div>
      </div>
      <div class="block__bottom">
        <a href="#"
           class="btn"
           @click.prevent="resetVerificationKey">
          {{ $t('wirecheck.details.code') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { formatAmount } from '@/helpers/utils/number'
import { useTransferStore } from '@/stores/transfer'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcStatus from '@/components/svg/icons/ic-status.vue'

export default {
  name: 'WirecheckResult',

  components: {
    IcStatus,
    LoaderSpinner
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup () {
    const transferStore = useTransferStore()

    return { transferStore }
  },

  data () {
    return {
      error: false,
      loading: true,
      transfer: null
    }
  },

  computed: {
    getTitle () {
      return this.error ? 'wirecheck.error.title' : 'wirecheck.success.title'
    }
  },

  created () {
    this.getData(this.id)
  },

  methods: {
    formatIban,

    formatAmount,

    formatDateText,

    async getData (key) {
      this.loading = true
      const data = await this.transferStore.wirecheck(key)
      if (data) {
        this.transfer = data
      } else {
        this.error = true
      }
      this.loading = false
    },

    resetVerificationKey () {
      this.$router.push({ name: 'wirecheck' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.page-wire-check
  max-width 48rem
  width 100%

  +min-screen($md-screen)
    padding 5rem 2rem

  +ie11()
    +min-screen($sm-screen)
      padding-left 4rem
      padding-right @padding-left
      position absolute
      width 100%
      top 50%
      left 50%
      transform translate(-50%, -50%)

.status-icon
  margin-right 2rem
  width 25px
  height 25px

.block
  max-width 48rem
  width 100%
  border-radius 2px
  border 1px solid #eef1f3
  box-shadow 0 4px 10px rgba(41,45,53,0.03)

  +ie11()
    +min-screen($sm-screen)
      position absolute
      width 100%
      top 50%
      left 50%
      transform translate(-50%, -50%)

  &__header
    padding 3rem 4rem
    color $colorWhite
    background-color $colorShinyGray
    font-size 2.6rem
    border-top-left-radius 2px
    border-top-right-radius 2px
    display flex
    align-items center
    line-height 1.2

  &--success .block__header
    background-color $colorSuccess

  &--error .block__header
    background-color $colorRed

  &__content
    padding 4rem
    h4
      text-transform uppercase
      font-size 1.2rem
      margin-bottom 3rem
      font-weight 500
    .response-row
      display flex
      margin-left -1rem
      margin-right -1rem
      flex-direction column
      +min-screen($sm-screen)
        flex-direction row
      &:not(:last-child)
        margin-top 1.2rem
        margin-bottom 1.2rem
      &__name
        flex 1
        padding-left 1rem
        padding-right 1rem
        color $colorShinyGray
      &__data
        flex 2
        padding-left 1rem
        padding-right 1rem
    .response-msg
      margin 2rem 0
      h3
        font-size 2.4rem
        color $colorDarkGray
        margin-bottom 1.8rem
      p
        font-size 1.6rem
        color #7a818c
        margin-bottom 0

  &__bottom
    padding 0 2rem 2rem
    a
      background #fff
      border-color #fff
      color rgba(122,129,140,0.5)
      display block
      width 100%
      padding 1.3rem 2rem
      line-height 1.7
      text-transform uppercase
      font-weight bold
      font-size 1.3rem
      text-align center
      border-radius 4px
      transition background 0.25s, color 0.25s, border-color 0.25s
      white-space nowrap
      text-overflow ellipsis
      vertical-align middle
      user-select none
      overflow hidden
      cursor pointer
      text-decoration none
</style>
