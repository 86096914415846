<template>
  <div class="modal__content">
    <modal-header :title="$tc('title.attach_file', type === 'payslips' ? 2 : 1)"
                  @close="$emit('close')" />
    <div class="modal__body">
      <component-alert v-if="isSepa"
                       type="info"
                       :show-api-messages="false"
                       :message="$t('alerts.sepa_limit')">
        <div class="alert__actions">
          <a type="button"
             class="btn btn--xs btn--info"
             :href="links.SUPPORT.IMPORT_SEPA_LIMIT"
             target="_blank">
            {{ $t('tooltip.see_more') }}
          </a>
        </div>
      </component-alert>
      <dropzone :max-files="(type !== 'payslips') ? 1 : null"
                :max-filesize="100"
                :class="{ 'dropzone--small-marge': isSepa }"
                mode="modal"
                @on-file-added="onFileAdded" />
    </div>
    <div class="modal__footer__right">
      <button v-if="files.length"
              type="button"
              class="btn btn--primary"
              @click="onSubmit">
        {{ $t("button.send") }}
      </button>
      <button v-else
              type="button"
              class="btn btn--gray"
              @click="$emit('close')">
        {{ $t("button.cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
import links from '@/config/links'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useCardStore } from '@/stores/card'
import { useTransferStore } from '@/stores/transfer'
import { useUserStore } from '@/stores/user'

import ComponentAlert from '@/components/Alert.vue'
import Dropzone from '@/components/Dropzone.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'

import ReceiveImportedDocuments from './ReceiveImportedDocuments.vue'

export default {
  components: {
    ComponentAlert,
    Dropzone,
    ModalHeader
  },

  props: {
    type: {
      type: String,
      required: true
    },

    extraData: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['close'],

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()
    const cardStore = useCardStore()
    const transferStore = useTransferStore()
    const userStore = useUserStore()

    return { appStore, beneficiaryStore, cardStore, transferStore, userStore }
  },

  data () {
    return {
      files: [],
      links
    }
  },

  computed: {
    isSepa () {
      return this.type === 'sepa'
    }
  },

  methods: {
    onFileAdded (files) {
      this.files = files
    },

    onSubmit () {
      if (this.type === 'beneficiaries') {
        this.beneficiaryStore.getBeneficiariesImport(this.files[0], this.extraData.listId)
      } else if (this.type === 'cards') {
        this.cardStore.getCardOrders(this.files[0])
      } else if (this.type === 'users') {
        this.userStore.getUsersImport(this.files[0])
      } else {
        this.transferStore.getMultipleTransfers(this.files, this.type === 'sepa')
      }

      this.appStore.showModal(
        ReceiveImportedDocuments,
        { type: this.type, files: this.files },
        { wrapperClass: 'modal--sm modal--white modal-fixed-size' }
      )
    }
  }
}

</script>
