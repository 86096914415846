<template>
  <canvas id="donut-chart"
          ref="donut-chart"
          :height="size"
          :width="size" />
</template>

<script>
import { markRaw } from 'vue'
import { Chart } from 'chart.js'

export default {
  props: {
    animation: {
      type: Boolean,
      default: true
    },

    cutout: {
      type: Number,
      default: 85
    },

    items: {
      type: Array,
      required: true
    },

    size: {
      type: Number,
      default: null
    }
  },

  emits: ['hover'],

  data () {
    return {
      chart: null
    }
  },

  computed: {
    datasetsFormatted () {
      return [{
        backgroundColor: this.items.length ? this.items.map(e => e.color) : '#EEF1F3',
        borderWidth: 0,
        data: this.items.length ? this.items.map(e => e.amount) : '1',
        hoverBackgroundColor: this.items.length ? this.items.map(e => this.saturateColor(e.color, 8)) : '#EEF1F3'
      }]
    }
  },

  watch: {
    items () {
      this.chart.data = { datasets: this.datasetsFormatted }
      this.chart.update()
    }
  },

  beforeUnmount () {
    this.chart?.destroy()
  },

  methods: {
    initChart () {
      this.chart = markRaw(new Chart('donut-chart', {
        data: {
          datasets: this.datasetsFormatted
        },
        options: {
          cutout: `${this.cutout}%`,
          plugins: {
            tooltip: {
              enabled: false,
              external: ({ tooltip }) => {
                if (!tooltip.opacity) {
                  this.$emit('hover', null)
                } else if (tooltip.dataPoints && tooltip.dataPoints[0] && this.items.length) {
                  this.$emit('hover', this.items[tooltip.dataPoints[0].dataIndex])
                }
              }
            }
          },
          responsive: !this.size
        },
        type: 'doughnut'
      }))
    },

    saturateColor (color, percent) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return `#${((1 << 24) + (r << 16) + (Math.min(255, Math.round(g * (1 + percent / 100))) << 8) + b).toString(16).slice(1)}`
    }
  }
}
</script>
