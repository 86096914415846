<template>
  <svg viewBox="0 0 24 24">
    <path v-if="csv"
          d="M21 3.5a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-11a3 3 0 0 1 3-3zM5.384 7.952c-.432 0-.819.075-1.16.216-.353.14-.638.334-.876.571a2.525 2.525 0 0 0-.547.852 2.832 2.832 0 0 0-.193 1.024v2.674c0 .356.068.7.193 1.024a2.473 2.473 0 0 0 1.411 1.423c.342.151.729.216 1.172.216s.831-.065 1.184-.216c.352-.14.648-.323.887-.571.24-.248.421-.528.546-.852a2.81 2.81 0 0 0 .194-1.024v-.151H6.534v.15c0 .163-.023.303-.069.443-.057.14-.125.27-.227.377s-.216.194-.364.259a1.195 1.195 0 0 1-.49.097 1.12 1.12 0 0 1-.489-.097.944.944 0 0 1-.353-.259 1.119 1.119 0 0 1-.205-.377 1.39 1.39 0 0 1-.068-.442v-2.674c0-.151.023-.302.068-.442a.976.976 0 0 1 .217-.377.944.944 0 0 1 .352-.26c.137-.064.296-.096.478-.096.171 0 .33.032.478.097.137.064.25.15.353.27.091.118.16.247.216.387.046.151.068.302.068.464v.065h1.662v-.054c0-.367-.057-.712-.182-1.046a2.513 2.513 0 0 0-.546-.873 2.545 2.545 0 0 0-.877-.582 3.134 3.134 0 0 0-1.172-.216zm6.434 0c-.364 0-.706.054-1.013.162-.307.118-.58.27-.796.463-.217.205-.387.442-.513.712s-.193.571-.193.905c0 .26.034.496.102.712.057.216.171.41.33.593.148.183.353.345.604.507.25.162.557.302.921.442l.66.248c.114.043.228.086.353.14.125.054.227.13.33.205.102.075.182.172.25.27a.766.766 0 0 1 .091.366c0 .27-.102.474-.318.593-.217.13-.467.194-.751.194-.319 0-.58-.097-.785-.291-.205-.194-.308-.453-.308-.776v-.13H9.121v.14c0 .42.08.798.239 1.11.148.324.353.594.614.799.262.215.558.377.888.485.33.097.66.15 1.013.15.33 0 .66-.053.99-.15.33-.097.614-.248.876-.442s.466-.442.626-.733a2.11 2.11 0 0 0 .239-1.025c0-.592-.171-1.088-.524-1.466-.353-.377-.853-.68-1.49-.905l-.638-.238a2.464 2.464 0 0 1-.705-.355.613.613 0 0 1-.285-.54c0-.215.091-.377.273-.496a1.064 1.064 0 0 1 .9-.107.858.858 0 0 1 .307.172.86.86 0 0 1 .227.291c.057.119.091.27.091.442v.14h1.627v-.247c0-.335-.068-.637-.193-.928a2.564 2.564 0 0 0-.546-.744 2.821 2.821 0 0 0-.82-.496 2.891 2.891 0 0 0-1.012-.172zm4.954.172H15.03l2.47 7.655h1.615l2.492-7.655h-1.764L18.32 13.31z" />
    <path v-else-if="pdf"
          d="M21 3.5a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-11a3 3 0 0 1 3-3zM6.37 8H4v8h1.656v-2.952h.715c.408 0 .782-.056 1.111-.17a2.5 2.5 0 0 0 .862-.495c.238-.214.42-.484.556-.8.125-.304.193-.665.193-1.06s-.068-.754-.193-1.07a2.318 2.318 0 0 0-.556-.788 2.5 2.5 0 0 0-.862-.496A3.434 3.434 0 0 0 6.371 8zm6.147 0H9.965v8h2.552c.442 0 .85-.079 1.202-.225a2.45 2.45 0 0 0 .885-.598 2.59 2.59 0 0 0 .556-.867c.125-.327.193-.676.193-1.048v-2.501c0-.372-.068-.722-.193-1.06a2.755 2.755 0 0 0-.556-.878 2.474 2.474 0 0 0-.885-.609A3.13 3.13 0 0 0 12.517 8zM21 8h-4.469v8h1.656v-3.324h2.064v-1.555h-2.064V9.544H21zm-8.483 1.544c.374 0 .67.124.873.36.205.237.307.519.307.845v2.524c0 .316-.102.586-.295.823-.204.236-.5.36-.885.36h-.907V9.544zm-6.146 0c.34 0 .6.079.77.248.17.169.262.416.262.732 0 .304-.091.54-.261.71-.17.169-.431.259-.771.259h-.715v-1.95z" />
  </svg>
</template>

<script>
export default {
  props: {
    csv: Boolean,
    pdf: Boolean
  }
}
</script>
