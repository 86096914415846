<template>
  <svg viewBox="0 0 24 24">
    <path d="M12 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm-7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm14 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
  </svg>
</template>

<script>
export default {
}
</script>
