<template>
  <div class="reward-block"
       :class="{ 'reward-block--disabled': isLocked }">
    <div class="reward-block__background">
      <image-api :url="reward.picture.url"
                 class="reward-block__background__picture" />
      <span v-if="isLocked"
            class="reward-block__background__overlay">
        <core-icon :name="ECoreIconList.Cadenas"
                   :size="20"
                   :theme="ECoreIconTheme.White" />
        {{ t('general.locked') }}
      </span>
    </div>
    <user-avatar :picture="reward.partner.logo"
                 elevated
                 class="reward-block__logo"
                 :size="60"
                 :uuid="reward.uuid"
                 :rounded="false" />
    <div class="reward-block__content">
      <div class="reward-block__content__label">
        {{ reward.label }}
      </div>
      <div class="reward-block__content__footer">
        <points-title :points="reward.points"
                      :size="EPointsTitleSize.Medium" />
        <core-button :appearance="ECoreButtonAppearance.Outlined"
                     :size="ECoreButtonSize.Small"
                     :theme="(canClaimReward && !isLocked) ? ECoreButtonTheme.Primary : ECoreButtonTheme.GrayLow"
                     :text="t('loyalty.button.claim')"
                     @click="showRewardDetail" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreIcon,
  ECoreButtonAppearance,
  ECoreButtonSize, ECoreButtonTheme,
  ECoreIconList,
  ECoreIconTheme
} from '@common/core-ui'

import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { useAppStore } from '@/stores/app'
import { IRewardLight } from '@/types/loyalty.d'

import { EPointsTitleSize } from '@/pages/loyalty/components/PointsTitle.d'
import PointsTitle from '@/pages/loyalty/components/PointsTitle.vue'
import RewardDetail from '@/pages/loyalty/sidepanel/RewardDetail.vue'

import ImageApi from '@/components/ImageApi.vue'
import UserAvatar from '@/components/UserAvatar.vue'

const { t } = useI18n()
const appStore = useAppStore()

const props = defineProps<{
  reward: IRewardLight
}>()

const canClaimReward = computed(() => {
  return hasPermission(permissions.loyaltyRewardsClaim, props.reward)
})

const isLocked = computed(() => {
  return !props.reward?.has_required_points
})

function showRewardDetail () {
  appStore.showSidePanel(
    RewardDetail,
    { uuid: props.reward.uuid },
    {
      wrapperClass: 'sidepanel--500',
      backgroundOverlay: true
    }
  )
}
</script>

<style lang="stylus" scoped>
.reward-block
  box-shadow 0 0 0 1px $borderBoxColor inset
  border-radius .6rem

  &__logo
    position absolute
    margin-top -4.4rem
    margin-left 2.6rem

  &__background
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content center
    align-items center

    &__picture
      flex 1
      height 16.4rem
      border-radius .6rem .6rem 0 0
      overflow hidden

      position relative
      padding-bottom 50%
      &:deep(.image)
        position absolute
        top 0

    &__overlay
      position absolute
      display flex
      gap 1rem
      align-items center
      z-index 10
      color $colorWhite
      font-weight 700
      text-transform uppercase

  &__content
    padding 3.4rem 2.4rem 3rem 2.4rem

    &__label
      font-size 1.4rem
      font-weight 700
      color $colorDarkGray

    &__footer
      padding-top 2.4rem
      display flex
      justify-content space-between
      align-items center

  &--disabled
    :deep(.points)
      color $disabledColor
    .reward-block
      &__background__picture
        filter brightness(60%)
      &__content
        &__label
          color $disabledColor

</style>
