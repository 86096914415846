<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <table v-else-if="statements.length"
           class="table table--hover">
      <thead>
        <tr>
          <th width="30">
            <label class="form-check__label checkbox">
              <input v-model="areAllSelected"
                     type="checkbox">
              <span class="checkbox__icon" />
            </label>
          </th>
          <th class="col--sm">
            {{ $t("table.period") }}
          </th>
          <th class="col--sm-3 right" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in statements"
            :key="item.id"
            class="is-link"
            @click.prevent="toggleSelected(item)">
          <td>
            <label class="form-check__label checkbox">
              <input v-model="item.selected"
                     type="checkbox"
                     name="checkbox">
              <span class="checkbox__icon" />
            </label>
          </td>
          <td>{{ item.year }}</td>
          <td class="right">
            <button v-if="item.document"
                    v-tooltip="{ content: $t('tooltip.download_pdf'), theme: 'tooltip' }"
                    class="btn-link"
                    @click.prevent.stop="downloadFileFromUrl(item.document.url, { type: 'annual_fees' })">
              <ic-extension pdf
                            class="ic ic--20 ic--gray" />
            </button>
            <button v-tooltip="{ content: $t('tooltip.send_email'), theme: 'tooltip' }"
                    class="btn-link"
                    @click.prevent.stop="onSendEmail(item)">
              <ic-mail class="ic ic--24 ic--gray" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <component-placeholder v-else
                           :label="$t('placeholder.no_statement.title')"
                           :content="$t('placeholder.no_statement.subtitle')" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { useAppStore } from '@/stores/app'
import { useStatementStore } from '@/stores/statement'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import SendEmail from '@/components/modals/SendEmail.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import IcExtension from '@/components/svg/icons/ic-extension.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'

export default {
  components: {
    IcMail,
    IcExtension,
    ComponentPlaceholder,
    LoaderSpinner
  },

  emits: ['selected-statements'],

  setup () {
    const statementStore = useStatementStore()
    const appStore = useAppStore()

    const { statements } = storeToRefs(statementStore)

    return { statements, statementStore, appStore }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    selectedStatements () {
      return this.statements.filter(statement => statement.selected)
    },

    areAllSelected: {
      set (value) {
        this.selectAll(value)
      },

      get () {
        return this.selectedStatements.length === this.statements.length
      }
    }
  },

  watch: {
    selectedStatements () {
      this.$emit('selected-statements', this.selectedStatements)
    },

    params () {
      this.initData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    downloadFileFromUrl,

    async initData () {
      this.loading = true
      await this.statementStore.getStatements({ type: 'annual_fees' })
      this.loading = false
    },

    toggleSelected (item) {
      item.selected = !item.selected
    },

    selectAll (value) {
      this.statements.forEach(statement => { statement.selected = value })
    },

    onSendEmail (item) {
      this.appStore.showModal(SendEmail, { callback: email => this.statementStore.sendByEmail(item.id, email, 'annual') }, { wrapperClass: 'modal--xs' })
    }
  }
}
</script>
