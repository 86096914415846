<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="sidepanel__content">
    <transition name="fade-delay">
      <file-viewer v-if="displayFileViewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <div class="sidepanel-header">
      <div class="transaction-summary transaction-summary--no-border">
        <h2 class="transaction-amount">
          {{ formatAmount(model.amount, model.currency) }}
        </h2>
        <p class="transaction-name">
          {{ model.label }}
        </p>
        <core-badge v-if="model.status_label"
                    v-bind="getBadgeProps(model.status)"
                    :value="model.status_label"
                    :size="ECoreBadgeSize.Large"
                    class="transaction-badge" />
        <div v-if="showHeader"
             class="document-actions-wrapper">
          <button type="button"
                  class="btn btn-proofs"
                  :class="proofButton.btnClass"
                  @click="showFile">
            <span class="btn-icon btn-icon--left">
              <component :is="proofButton.icon"
                         class="ic ic--24 ic--gray" />
            </span>
            <span class="btn__label">{{ proofLabel }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="sidepanel-body">
      <div class="sidepanel-details">
        <h3 class="head-title">
          {{ $t('form.mileages.mileage_details') }}
        </h3>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('form.mileages.declared_by') }}</span>
          <span class="entry-value right">{{ declaredUser }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("general.created_at") }}</span>
          <span class="entry-value right">{{ formatDateText(model.created_at, 'lll') }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('form.mileage_date.label') }}</span>
          <span class="entry-value right">{{ formatDateText(model.mileage_date) }}</span>
        </div>
        <div v-if="model.processing_at"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('form.refunds.refund_date') }}</span>
          <span class="entry-value right">{{ formatDateText(model.processing_at) }}</span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('table.vehicle') }}</span>
          <span class="entry-value right">
            <ic-electrical v-if="model.vehicle.is_electrical"
                           v-tooltip="{ content: $t('tooltip.electric_vehicle'), theme: 'tooltip', html: true }"
                           class="ic ic--14 ic--center-vertically mr-1" />
            {{ model.vehicle.label }}
          </span>
        </div>
        <div class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t('form.distance.label') }}</span>
          <span class="entry-value right">{{ formatDistance(model.distance) }}</span>
        </div>
        <sidepanel-affectations v-if="canReadAffectations"
                                :can-edit-affectations="false"
                                :can-write-affectations="false"
                                :affectations="model.affectations" />
        <div v-if="model.comment"
             class="sidepanel-details__entry">
          <span class="entry-label text-muted">{{ $t("form.personal_note.label") }}</span>
          <span class="entry-value right">{{ model.comment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeSize } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount, formatDistance } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useMileageStore } from '@/stores/mileage'

import FileViewer from '@/components/FileViewer.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import SidepanelAffectations from '@/components/SidepanelAffectations.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcElectrical from '@/components/svg/icons/ic-electrical.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    CoreBadge,
    FileViewer,
    LoaderSpinner,
    UserAvatar,
    IcElectrical,
    SidepanelAffectations
  },

  props: {
    model: {
      type: Object,
      required: true
    },

    showHeader: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const mileageStore = useMileageStore()

    const { sidePanel } = storeToRefs(appStore)

    return { sidePanel, appStore, mileageStore }
  },

  data () {
    return {
      loading: true,
      displayFileViewer: false
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    hasDocument () {
      return this.model.documents.length
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.mileagesAffectationsRead)
    },

    declaredUser () {
      return `${this.model.user.first_name} ${this.model.user.last_name}`.trim()
    },

    proofButton () {
      return this.hasDocument ? { icon: IcDocument, btnClass: 'btn--outline btn-document' } : { icon: IcAttachment, btnClass: 'btn--outline btn-attachment' }
    },

    proofLabel () {
      return this.hasDocument ? this.$i18n.tc('button.show_proofs', this.hasDocument) : this.$i18n.t('button.attach_proof')
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.closeFileViewer)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.closeFileViewer)
  },

  methods: {
    getBadgeProps,
    formatAmount,

    formatDistance,

    formatDateText,

    async initData () {
      this.loading = true
      const data = await this.mileageStore.getMileageDetail(this.model.id)
      Object.assign(this.model, data)
      this.loading = false

      this.$nextTick(() => {
        if (this.sidePanel.initShowFile) this.showFile()
      })
    },

    closeFileViewer () {
      this.displayFileViewer = false
    },

    showFile () {
      if (!this.hasDocument) {
        return this.appStore.showDropzoneModal(
          this.$i18n.tc('title.attach_file', 2),
          this.model,
          {
            url: `/mileages/${this.model.id}/documents`
          })
      }

      this.appStore.showFileViewer({
        content: this.model.documents,
        isMultiDoc: true,
        canEdit: this.model.updatable,
        operationId: this.model.id,
        documentsUrl: `/mileages/${this.model.id}/documents`,
        dataModel: this.model
      })

      this.displayFileViewer = true
    }
  }
}
</script>
