<template>
  <span v-if="blobUrl || loading"
        class="avatar-pic user-avatar"
        :class="{
          'avatar-pic--rounded user-avatar--rounded': rounded,
          'avatar-pic--bordered': bordered,
          'avatar-pic--elevated': elevated
        }"
        :style="{ height: `${size}px`, width: `${size}px` }">
    <transition name="fade">
      <img v-show="!loading"
           :src="blobUrl"
           :alt="placeholderLabel"
           class="avatar-img"
           :class="{ 'avatar-img--rounded': rounded }"
           @load="pictureLoaded">
    </transition>
    <loader-spinner v-if="loading"
                    :size="loaderSize"
                    class="c c--vh" />
  </span>
  <span v-else-if="placeholderLabel"
        :style="{ ...placeholderAvatarStyle, height: `${size}px`, width: `${size}px`, fontSize: `${size * 0.45}px` }"
        class="avatar-placeholder user-avatar"
        :class="{ 'user-avatar--rounded': rounded }">
    {{ letters }}
  </span>
  <span v-else
        :style="{ height: `${size}px`, width: `${size}px` }"
        class="avatar-default-pic user-avatar"
        :class="{ 'user-avatar--rounded': rounded }" />
</template>

<script>
import axiosClient from '@/api'
import store from '@/config/store'
import { db } from '@/helpers/db'
import { replaceAccents } from '@/helpers/utils/text'

import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  components: {
    LoaderSpinner
  },

  props: {
    picture: {
      default: () => ({}),
      validator: value => {
        return typeof value === 'object' || value === null
      }
    },

    bordered: {
      type: Boolean,
      default: false
    },

    elevated: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: true
    },

    size: {
      type: Number,
      default: 32
    },

    type: {
      type: String,
      default: 'color'
    },

    uuid: {
      type: String,
      default: ''
    },

    placeholderLabel: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      blobUrl: null,
      loading: true
    }
  },

  computed: {
    loaderSize () {
      return this.size === 110 ? '32' : '20'
    },

    placeholderAvatarStyle () {
      return this.type === 'team'
        ? {
            'background-color': this.stringToHslColor(this.placeholderLabel, 50, 92),
            color: this.stringToHslColor(this.placeholderLabel, 40, 40)
          }
        : {
            'background-color': this.stringToHslColor(this.placeholderLabel, 60, 70),
            color: 'white'
          }
    },

    letters () {
      return this.placeholderLabel.split(' ').map(i => i[0]).slice(0, 2).join('').toUpperCase()
    }
  },

  watch: {
    blobUrl () {
      this.loading = true
    }
  },

  mounted () {
    this.$bus.on('user-profile-picture-updated', this.onPictureUpdated)
  },

  beforeUnmount () {
    this.$bus.off('user-profile-picture-updated', this.onPictureUpdated)
  },

  created () {
    this.getPicture()
  },

  methods: {
    async getPicture () {
      if (!this.picture?.url) return (this.loading = false)

      const picture = await db.pictures.get(this.picture.url)
      if (picture && picture.updated_at === this.picture.updated_at) {
        this.blobUrl = URL.createObjectURL(picture.data)
        return
      }

      try {
        store.api.hideProgressBar = true
        const { data } = await axiosClient.get(this.picture.url, { responseType: 'blob' })
        this.blobUrl = URL.createObjectURL(data)

        await db.pictures.put({
          url: this.picture.url,
          updated_at: this.picture.updated_at,
          data
        })
      } catch (e) {
        console.error(e)
        this.loading = false
      } finally {
        store.api.hideProgressBar = false
      }
    },

    pictureLoaded () {
      this.loading = false
    },

    stringToHslColor (str, s, l) {
      str = replaceAccents(str)
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash += str.charCodeAt(i)
      }
      const h = hash % 360
      return `hsl(${h}, ${s}%, ${l}%)`
    },

    onPictureUpdated (event) {
      if (this.uuid === event.uuid) {
        this.loading = true
        this.blobUrl = event.url
      }
    }
  }
}
</script>
