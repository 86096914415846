<template>
  <section v-if="loading"
           class="section section--empty">
    <loader-spinner size="32" />
  </section>
  <section v-else
           class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="goBackToListQuery">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('order_card.step.card_settings') }}
        </h1>
      </div>
    </div>

    <card-settings :card="card"
                   @card-update="onCardUpdate" />
  </section>
</template>

<script>
import { isVirtualCard } from '@/helpers/utils/card'
import { useCardStore } from '@/stores/card'

import CardSettings from '@/pages/cards/details/Settings.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    LoaderSpinner,
    CardSettings,
    IcArrow
  },

  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  setup () {
    const cardStore = useCardStore()

    return { cardStore }
  },

  data () {
    return {
      loading: true,
      card: null
    }
  },

  computed: {
    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    goBackToListQuery () {
      return this.isVirtualCard
        ? { name: 'cards-virtual-to-validate', query: { cardRequestUuid: this.uuid } }
        : { name: 'cards-physical-to-validate', query: { cardRequestUuid: this.uuid } }
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.goBackToList)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.goBackToList)
  },

  methods: {
    async initData () {
      this.loading = true

      await this.refreshCard(this.uuid)
      if (!this.card) { return this.goBackToList() }

      this.loading = false
    },

    onCardUpdate (card) {
      this.card = card
    },

    async refreshCard () {
      const card = await this.cardStore.getCard(this.uuid)
      if (card) { this.card = card }
    },

    goBackToList () {
      this.isVirtualCard
        ? this.$router.push({ name: 'cards-virtual-to-validate' })
        : this.$router.push({ name: 'cards-physical-to-validate' })
    }
  }
}
</script>
