<template>
  <div class="placeholder-action-description">
    <div class="placeholder">
      <div class="placeholder__inner">
        <figure v-if="icon"
                class="placeholder__img">
          <div :style="styleSize">
            <slot>
              <empty-folder />
            </slot>
          </div>
        </figure>
        <h4 v-if="label"
            class="pre-line">
          {{ label }}
        </h4>
        <!-- eslint-disable vue/no-v-html -->
        <p v-if="content"
           class="pre-line"
           v-html="content" />
        <!-- eslint-enable -->
        <p v-if="contentInfo"
           class="pre-line strong">
          {{ contentInfo }}
        </p>
      </div>
    </div>
    <div v-if="button">
      <button :class="`btn btn--${buttonTheme} placeholder-btn`"
              :disabled="disabled"
              @click="$emit('btn-click')">
        {{ button }}
      </button>
      <p v-if="cancelButton"
         class="later"
         :disabled="disabled"
         @click="$emit('cancel-btn-click')">
        {{ cancelButton }}
      </p>
    </div>
  </div>
</template>

<script>
import EmptyFolder from '@/components/svg/img/empty-folder.vue'

export default {
  name: 'ComponentPlaceholder',

  components: {
    EmptyFolder
  },

  props: {
    label: {
      type: String,
      default: null
    },

    button: {
      type: String,
      default: null
    },

    buttonTheme: {
      type: String,
      default: 'primary'
    },

    cancelButton: {
      type: String,
      default: null
    },

    content: {
      type: String,
      default: null
    },

    contentInfo: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Boolean,
      default: true
    },

    iconWidth: {
      type: Number,
      default: 200
    },

    iconHeight: {
      type: Number,
      default: 160
    }
  },

  emits: [
    'btn-click',
    'cancel-btn-click'
  ],

  computed: {
    styleSize () {
      return `width: ${this.iconWidth}px; height: ${this.iconHeight}px`
    }
  }
}
</script>
