<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="sidepanel__content sidepanel--account-details">
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <div class="transaction-summary">
          <p class="account-name">
            {{ companyAccountDetails.company.name }}
          </p>
          <p class="account-label text-overflow">
            {{ companyAccountName }}
          </p>
          <p v-if="displayBalance"
             class="account-amount"
             :class="[companyAccountDetails.balance < 0 ? 'text-red' : 'text-green']">
            {{ formatAmount(companyAccountDetails.balance, companyAccountDetails.currency) }}
          </p>
          <core-badge v-if="showAccountStatusBadge"
                      class="block-centered"
                      :theme="isAccountToValidate ? ECoreBadgeTheme.Info : ECoreBadgeTheme.Danger"
                      :fill="isAccountToValidate ? ECoreBadgeFill.Solid : ECoreBadgeFill.Shaded"
                      :value="accountStatusLabel" />
        </div>
      </div>
      <div v-if="companyAccountDetails"
           class="sidepanel-body">
        <div class="sidepanel-details">
          <h3 class="head-title">
            {{ $t('sidepanel.account.title') }}
          </h3>
          <div v-if="companyAccountDetails.opening_date"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('general.creation_date') }}</span>
            <span class="entry-value right">{{ formatDateText(companyAccountDetails.opening_date) }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('sidepanel.account.type') }}</span>
            <span class="entry-value right">{{ companyAccountDetails.type_label }}</span>
          </div>
          <div v-if="companyAccountDetails.rib"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('sidepanel.account.account_number') }}</span>
            <span class="entry-value right">{{ companyAccountDetails.rib.number }}</span>
          </div>
          <div v-if="companyAccountDetails.rib"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('general.iban') }}</span>
            <span class="entry-value right">{{ formatIban(companyAccountDetails.rib.iban) }}</span>
          </div>
          <div v-if="companyAccountDetails.rib"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t('label.account_details.bic') }}</span>
            <span class="entry-value right">{{ companyAccountDetails.rib.bic }}</span>
          </div>
        </div>
      </div>
    </div>
    <component-sidepanel-footer v-if="showCloseAccountButton">
      <component-button v-if="showCloseAccountButton"
                        :label="$t('button.close_the_account')"
                        wrapper-class="btn--block btn--outline"
                        @click="onClickClose" />
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'

import { EFeature, hasFeature } from '@/config/features'
import store from '@/config/store'
import { formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import CloseAccount from '@/pages/account/companies/modals/CloseAccount.vue'

import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'

export default {
  components: {
    CoreBadge,
    ComponentButton,
    ComponentSidepanelFooter,
    LoaderSpinner
  },

  props: {
    uuid: {
      type: String,
      required: true
    },

    displayBalance: {
      type: Boolean,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    return { accountStore, appStore }
  },

  data () {
    return {
      store,
      companyAccountDetails: null,
      loading: true
    }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    companyAccountName () {
      return this.companyAccountDetails.label || this.companyAccountDetails.type_label || this.$i18n.t('general.additional_account')
    },

    isAccountToClose () {
      return this.companyAccountDetails.status === 'closing'
    },

    isAccountToValidate () {
      return this.companyAccountDetails.status === 'validating'
    },

    showCloseAccountButton () {
      return !this.isAccountToClose && hasFeature(EFeature.AccountCompanyAccountClosing, this.companyAccountDetails)
    },

    showAccountStatusBadge () {
      return this.isAccountToClose || this.isAccountToValidate
    },

    accountStatusLabel () {
      if (this.isAccountToValidate) {
        return this.$i18n.t('placeholder.restricted_access.user_pending.badge')
      }
      if (this.isAccountToClose) {
        return this.$i18n.t('general.closed')
      }
      return null
    }
  },

  async created () {
    this.loading = true
    this.companyAccountDetails = await this.accountStore.getAccountDetails(this.uuid)
    this.loading = false
  },

  methods: {
    formatIban,

    formatAmount,

    formatDateText,

    onClickClose () {
      this.appStore.showModal(CloseAccount, { account: this.companyAccountDetails }, { wrapperClass: 'modal--xs' })
    }
  }
}
</script>
