<template>
  <section class="section"
           data-cy="account.create.additional-account.page">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'create-sub-account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('create_additional_account.title') }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <router-view />
    </div>
  </section>
</template>

<script>
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow
  }
}
</script>
