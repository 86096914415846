<template>
  <section class="section">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'account' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.settings') }}
        </h1>
      </div>
    </div>
    <tabs :data="segments" />
    <div class="section__content">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { useAccountStore } from '@/stores/account'

import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  components: {
    IcArrow,
    Tabs
  },

  setup () {
    const accountStore = useAccountStore()
    const { account, displayMenuPreference } = storeToRefs(accountStore)
    return { account, displayMenuPreference }
  },

  computed: {
    segments () {
      return [
        {
          id: 'preferences_notifications',
          route: { name: 'account-settings-notifications' },
          label: this.$i18n.t('button.notifications'),
          visible: true
        },
        {
          id: 'preferences_main_menu',
          route: { name: 'account-settings-preferences' },
          label: this.$i18n.t('button.main_menu'),
          visible: this.displayMenuPreference
        },
        {
          id: 'preferences_control_rule',
          route: { name: 'account-settings-control-rule' },
          label: this.$i18n.t('button.control_rule'),
          visible: hasFeature(EFeature.AccountCardControlRule)
        }
      ].filter(route => route.visible)
    }
  }
}
</script>
