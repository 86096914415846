<template>
  <validation-form ref="form"
                   v-slot="{ errors, isSubmitting }"
                   class="form form-newuser"
                   autocomplete="off"
                   :validation-schema="schema"
                   @submit="onSubmit">
    <div class="form__row row">
      <div class="form-group col col--sm"
           :class="{ error: errors.email }">
        <validation-field v-model="user.email"
                          type="text"
                          class="form-control"
                          :placeholder="$t('form.email.placeholder')"
                          name="email" />
      </div>
    </div>
    <div class="form__row row">
      <div class="form-group col col--sm"
           :class="{ error: errors.password }">
        <input-password v-model="user.password"
                        :placeholder="$t('form.password.placeholder')"
                        name="password"
                        :password-meter="false"
                        password-visibility-toggle />
      </div>
    </div>
    <div class="form-actions">
      <button type="submit"
              class="btn btn--block btn--primary"
              :disabled="isSubmitting">
        {{ $t('button.login') }}
      </button>
    </div>
    <div class="center">
      <router-link type="button"
                   class="btn-link text-muted"
                   :to="{ name: 'invitation-create-account', query: { token } }">
        {{ $t('button.create_user_account') }}
      </router-link>
    </div>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { useApiStore } from '@/stores/api'
import { useAuthStore } from '@/stores/auth'

import InputPassword from '@/components/InputPassword.vue'

export default {
  components: {
    InputPassword
  },

  props: {
    token: {
      type: String,
      required: true
    },

    decodedToken: {
      type: Object,
      required: true
    }
  },

  emits: ['success'],

  setup () {
    const apiStore = useApiStore()
    const authStore = useAuthStore()

    const { error: apiError } = storeToRefs(apiStore)

    return { authStore, apiError }
  },

  data () {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },

  computed: {
    schema () {
      return yup.object({
        email: yup.string().validEmail().required(),
        password: yup.string().required()
      })
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.form.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    this.user.email = this.decodedToken.ema
  },

  methods: {
    async onSubmit () {
      const success = await this.authStore.acceptInvitation(this.user.email, this.user.password, this.token)
      if (success) {
        this.$emit('success')
      }
    }
  }
}
</script>
