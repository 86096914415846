import { zxcvbn } from '@zxcvbn-ts/core'

export const checkPasswordValid = password => {
  return zxcvbn(password).score >= 1 && /\d/.test(password) && password.length >= 8
}

export const checkUrlValid = url => {
  return url.match('^(?:http(s)?:\\/\\/)?[\\w\u00C0-\u017F.-]+(?:\\.[\\w\u00C0-\u017F\\.-]+)+[\\w\u00C0-\u017F\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.%]+$')
}

export const replaceAccents = data => {
  return data
    .toLowerCase()
    .replace(/\n/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const textContainsFilter = (text, filter) => replaceAccents(text).includes(replaceAccents(filter))

export const formatCardDigits = (digits, showPan = false) => {
  const string = (digits || '').replace(/\*/g, '•').padEnd(16, '•')

  if (showPan) {
    return [string.substring(0, 4), string.substring(4, 8), string.substring(8, 12), string.substring(12, 16)]
  }
  return [string.substring(0, 4), '••••', '••••', string.substring(12, 16)]
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
