<template>
  <component :is="isButton ? 'button' : 'div'"
             :class="`info info--${size} ${danger ? 'info--danger' : ''}`"
             :type="isButton ? 'button' : ''"
             @click="$emit('click')">
    <ic-info outline
             class="info__ic" />
    <span class="info__description">
      <slot />
    </span>
  </component>
</template>

<script>
import IcInfo from '@/components/svg/icons/ic-info.vue'
export default {
  name: 'ComponentInfo',

  components: {
    IcInfo
  },

  props: {
    danger: {
      type: Boolean,
      default: false
    },

    isButton: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'md',
      validator: value => ['sm', 'md'].includes(value)
    }
  },

  emits: ['click']
}

</script>

<style scoped lang="stylus">
@import './../assets/stylesheets/components/icons.styl'

.info
  display flex
  align-items center
  text-wrap initial

  &__ic
    margin-right .5rem
    @extend .ic
    @extend .ic--gray
    @extend .ic--16

  &__description
    font-size 0.9em
    line-height 18px
    color $colorShinyGray

  &--sm
    font-weight 400

    .info__ic
      @extend .ic--14

  &--md
    font-weight 500

  &--danger
   .info__description
      color $colorError
   .info__ic
      @extend .ic--error
</style>
