<template>
  <section class="section section-beneficiary-lists">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'transfers' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $tc("title.beneficiaries_list", beneficiaryLists.length) }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <component-popover trigger="hover"
                             popover-class="popover-actions-menu popover--pull-right">
            <template #trigger>
              <button class="btn btn--icon btn--white">
                <i class="btn-icon">
                  <ic-settings class="ic ic--gray" />
                </i>
              </button>
            </template>
            <div class="popover__item"
                 @click="$router.push({ name: 'beneficiaries-import' })">
              <div class="popover__item__icon">
                <ic-import class="ic ic--16 ic--gray" />
              </div>
              <div style="white-space: nowrap">
                {{ $t('button.import_beneficiaries') }}
              </div>
            </div>
            <div class="popover__item"
                 @click="exportAllBeneficiaries">
              <div class="popover__item__icon">
                <ic-export class="ic ic--gray" />
              </div>
              <div>{{ $t('button.export_all_beneficiaries') }}</div>
            </div>
          </component-popover>
          <button v-if="hasPermission($permissions.beneficiaryListsWrite)"
                  class="btn btn--secondary"
                  type="button"
                  data-cy="beneficiaries.lists.create"
                  @click="onCreateList">
            {{ $t("button.create_list") }}
          </button>
          <router-link v-if="hasPermission($permissions.beneficiariesWrite)"
                       class="btn btn--primary"
                       :to="{ name: 'beneficiaries-create' }">
            {{ $t("button.new_beneficiary") }}
          </router-link>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="beneficiariesPagination.totalItems"
                       @filters="filters" />
    <beneficiaries-table v-if="showSearchResult"
                         :filter="filter" />
    <beneficiary-lists-table v-else />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { useAppStore } from '@/stores/app'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import IcImport from '@/components/svg/icons/ic-import.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'

import BeneficiariesTable from './components/BeneficiariesTable.vue'
import BeneficiaryListsTable from './components/BeneficiaryListsTable.vue'
import CreateBeneficiaryList from './modal/CreateBeneficiaryList.vue'

export default {
  components: {
    IcSettings,
    IcImport,
    IcExport,
    IcArrow,
    ButtonFilters,
    ComponentFilters,
    ComponentPopover,
    BeneficiaryListsTable,
    BeneficiariesTable
  },

  setup () {
    const appStore = useAppStore()
    const beneficiaryStore = useBeneficiaryStore()

    const { beneficiaryLists, beneficiariesPagination } = storeToRefs(beneficiaryStore)

    return { appStore, beneficiaryLists, beneficiaryStore, beneficiariesPagination }
  },

  data () {
    return {
      filter: {
        search: null
      },

      showSearchResult: false
    }
  },

  computed: {
    EFilter () {
      return EFilter
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('filters', this.filters)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('filters', this.filters)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    async initData () {
      this.filters({ search: null })
    },

    filters (filters) {
      this.appStore.closeModal()

      this.filter = {
        search: filters.search
      }

      this.showSearchResult = !!filters.search
    },

    exportAllBeneficiaries () {
      downloadFileFromUrl('/beneficiaries/export')
    },

    onCreateList () {
      this.appStore.showModal(CreateBeneficiaryList, {}, { wrapperClass: 'modal--md' })
    }
  }
}
</script>
