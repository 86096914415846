<template>
  <div ref="datePicker"
       class="datepicker-v2"
       :class="wrapperClass">
    <validation-field v-slot="{ errors }"
                      :model-value="fromValue"
                      :name="fromName">
      <div class="field"
           :class="[fieldClass, { error: errors.length && !isActive }]">
        <label v-if="fromLabel"
               class="label">{{ fromLabel }}</label>
        <div class="input-group"
             :class="{ 'is-clearable': clearButton && fromValue }">
          <label v-if="fromPreAddon"
                 class="input-group__addon input-group__addon--gray input-group__addon--noborder"
                 :class="sizeClass ? `input-group__addon--${sizeClass}` : ''"
                 :for="fromId">{{ fromPreAddon }}</label>
          <input :id="fromId"
                 ref="from-input"
                 v-model="formattedFromValue"
                 type="text"
                 :class="[inputClass, sizeClass ? `form-control--${sizeClass}` : '']"
                 :placeholder="fromPlaceholder"
                 :disabled="disableInput"
                 autocomplete="off"
                 @focus="onFocus('from')"
                 @keyup="autoCompleteDate">
          <label v-if="iconCalendar"
                 :for="fromId"
                 class="input-group__addon"
                 :class="sizeClass ? `input-group__addon--${sizeClass}` : ''">
            <VTooltip theme="poptip"
                      :disabled="!fromPoptipContent">
              <template #popper>
                <poptip :title="fromPoptipTitle"
                        :message="fromPoptipContent" />
              </template>
              <ic-calendar class="ic ic--18 ic--addon" />
            </VTooltip>
          </label>
          <button v-if="clearButton && fromValue"
                  type="button"
                  class="form-control-clear-btn"
                  @click="clearDate('from')">
            <ic-remove class="ic ic--16 ic--gray" />
          </button>
        </div>
      </div>
    </validation-field>
    <validation-field v-slot="{ errors }"
                      :model-value="toValue"
                      :name="toName">
      <div class="field"
           :class="[fieldClass, { error: errors.length && !isActive }]">
        <label v-if="toLabel"
               class="label">{{ toLabel }}</label>
        <div class="input-group"
             :class="{ 'is-clearable': clearButton && toValue }">
          <label v-if="toPreAddon"
                 class="input-group__addon input-group__addon--gray input-group__addon--noborder"
                 :class="sizeClass ? `input-group__addon--${sizeClass}` : ''"
                 :for="toId">{{ toPreAddon }}</label>
          <input :id="toId"
                 ref="to-input"
                 v-model="formattedToValue"
                 type="text"
                 :class="[inputClass, sizeClass ? `form-control--${sizeClass}` : '']"
                 :placeholder="toPlaceholder"
                 :disabled="disableInput"
                 autocomplete="off"
                 @focus="onFocus('to')"
                 @keyup="autoCompleteDate">
          <label v-if="iconCalendar"
                 :for="toId"
                 class="input-group__addon"
                 :class="sizeClass ? `input-group__addon--${sizeClass}` : ''">
            <VTooltip theme="poptip"
                      :disabled="!toPoptipContent">
              <template #popper>
                <poptip :title="toPoptipTitle"
                        :message="toPoptipContent" />
              </template>
              <ic-calendar class="ic ic--18 ic--addon" />
            </VTooltip>
          </label>
          <button v-if="clearButton && toValue"
                  type="button"
                  class="form-control-clear-btn"
                  @click="clearDate('to')">
            <ic-remove class="ic ic--16 ic--gray" />
          </button>
        </div>
      </div>
    </validation-field>
    <div v-if="isActive"
         class="calendar">
      <div v-if="showPeriods"
           class="periods">
        <header>
          <span class="prev"
                :class="{ disabled: isPrevMonthListDisabled }"
                @click="prevMonthsList">&lt;</span>
          <span class="current-month">{{ $t('table.period') }}</span>
          <span class="next"
                :class="{ disabled: !monthsListIndex }"
                @click="nextMonthsList">&gt;</span>
        </header>
        <ul class="periods-list">
          <li v-for="month in monthsList"
              :key="month.dayObj.format('MMMM YYYY')"
              :class="{ selected: month.isSelected, disabled: month.isDisabled }"
              @click="selectMonth(month)">
            {{ month.dayObj.format('MMMM YYYY') }}
          </li>
        </ul>
      </div>
      <div class="basic">
        <header>
          <span class="prev"
                :class="{ disabled: isPrevMonthDisabled }"
                @click="prevMonth">&lt;</span>
          <span class="current-month">{{ headerMonth }}</span>
          <span class="next"
                :class="{ disabled: isNextMonthDisabled }"
                @click="nextMonth">&gt;</span>
        </header>
        <span v-for="d in daysOfWeek"
              :key="d"
              class="cell day-header">{{ d }}</span>
        <span v-for="d in blankDays"
              :key="d"
              class="cell day blank" />
        <span v-for="day in days"
              :key="day.dayObj.valueOf()"
              class="cell day"
              :class="{ selected: day.isSelected, between: day.isBetween, disabled: day.isDisabled }"
              @mouseover="onMouseOver(day)"
              @mouseout="onMouseOut"
              @click.stop="selectDate(day)">{{ day.dayObj.date() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'

import Poptip from '@/components/Poptip.vue'
import IcCalendar from '@/components/svg/icons/ic-calendar.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)

export default {
  components: {
    IcCalendar,
    IcRemove,
    Poptip
  },

  props: {
    clearButton: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Object,
      default: null
    },

    disableInput: {
      type: Boolean,
      default: false
    },

    fieldClass: {
      type: String,
      default: null
    },

    fromId: {
      type: String,
      default: null
    },

    fromLabel: {
      type: String,
      default: null
    },

    fromName: {
      type: String,
      default: 'from-input'
    },

    fromPlaceholder: {
      type: String,
      default: 'JJ/MM/AAAA'
    },

    fromPoptipContent: {
      type: String,
      default: null
    },

    fromPoptipTitle: {
      type: String,
      default: null
    },

    fromPreAddon: {
      type: String,
      default: null
    },

    fromValue: {
      type: Date,
      default: null
    },

    iconCalendar: Boolean,
    inputClass: {
      type: String,
      default: ''
    },

    lang: {
      type: String,
      default: 'en'
    },

    required: {
      type: Boolean,
      default: false
    },

    sizeClass: {
      type: String,
      default: null
    },

    showPeriods: {
      type: Boolean,
      default: true
    },

    toId: {
      type: String,
      default: null
    },

    toLabel: {
      type: String,
      default: null
    },

    toName: {
      type: String,
      default: 'to-input'
    },

    toPlaceholder: {
      type: String,
      default: 'JJ/MM/AAAA'
    },

    toPoptipContent: {
      type: String,
      default: null
    },

    toPoptipTitle: {
      type: String,
      default: null
    },

    toPreAddon: {
      type: String,
      default: null
    },

    toValue: {
      type: Date,
      default: null
    },

    wrapperClass: {
      type: [Object, String],
      default: null
    }
  },

  emits: ['update:to-value', 'update:from-value', 'update'],

  setup () {
    const datePicker = ref()
    const isActive = ref(false)
    const focus = ref(null)

    function close () {
      isActive.value = false
      focus.value = null
    }

    onClickOutside(datePicker, () => {
      close()
    })

    return {
      datePicker,
      focus,
      isActive,

      close,
      onClickOutside
    }
  },

  data () {
    return {
      currDate: null,
      hoverDayObj: null,
      monthsListIndex: 0,
      tempInput: ''
    }
  },

  computed: {
    monthsList () {
      const monthsList = []
      let d = dayjs().subtract(6 * this.monthsListIndex, 'month')
      while (monthsList.length < 6) {
        monthsList.push({
          dayObj: d,
          isSelected: this.isSelectedMonth(d),
          isDisabled: this.isDisabledDate(d.startOf('month')) && this.isDisabledDate(d.endOf('month'))
        })
        d = d.subtract(1, 'month')
      }
      return monthsList
    },

    headerMonth () {
      return this.currDate.format('MMMM YYYY')
    },

    isPrevMonthListDisabled () {
      return this.disabled?.before && this.monthsList[this.monthsList.length - 1].dayObj.isSameOrBefore(this.disabled.before)
    },

    isNextMonthListDisabled () {
      return this.disabled?.after && !this.monthsListIndex
    },

    isPrevMonthDisabled () {
      return this.disabled?.before && this.currDate.subtract(1, 'month').endOf('month').isBefore(this.disabled.before, 'day')
    },

    isNextMonthDisabled () {
      return this.disabled?.after && this.currDate.add(1, 'month').startOf('month').isAfter(this.disabled.after, 'day')
    },

    daysOfWeek () {
      return dayjs.weekdaysShort(true)
    },

    blankDays () {
      const d = this.currDate.startOf('month')

      return d.day() > 0 ? d.day() - 1 : 6
    },

    days () {
      let dayObj = this.currDate.startOf('month')
      const days = []
      const daysInMonth = dayObj.daysInMonth()
      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          dayObj,
          isSelected: this.isSelectedDate(dayObj),
          isBetween: this.isBetweenDate(dayObj),
          isDisabled: this.isDisabledDate(dayObj)
        })
        dayObj = dayObj.add(1, 'day')
      }

      return days
    },

    formattedFromValue: {
      get () {
        if (this.focus === 'from' && !this.hoverDayObj) {
          return this.tempInput
        }
        return this.hoverDayObj && (this.focus === 'from' || this.hoverDayObj.isBefore(this.fromValue))
          ? this.hoverDayObj.format('DD/MM/YYYY')
          : (this.fromValue ? dayjs(this.fromValue).format('DD/MM/YYYY') : '')
      },

      set (input) {
        const date = dayjs(input, 'DD/MM/YYYY', true)
        if (date.isValid() && input.length === 10 && !this.isDisabledDate(date)) {
          if (this.toValue && date.isAfter(this.toValue, 'day')) {
            this.$emit('update:to-value', null)
            this.$emit('update')
          }
          this.setFromValue(date)
        } else {
          this.tempInput = input
        }
      }
    },

    formattedToValue: {
      get () {
        if (this.focus === 'to' && !this.hoverDayObj) {
          return this.tempInput
        }
        if (this.hoverDayObj) {
          if (this.focus === 'to' && (!this.fromValue || this.hoverDayObj.isAfter(this.fromValue, 'day'))) {
            return this.hoverDayObj.format('DD/MM/YYYY')
          } else if (this.focus === 'from' && this.hoverDayObj.isAfter(this.toValue, 'day')) {
            return null
          }
        }
        return this.toValue ? dayjs(this.toValue).format('DD/MM/YYYY') : ''
      },

      set (input) {
        const date = dayjs(input, 'DD/MM/YYYY', true)
        if (date.isValid() && input.length === 10 && !this.isDisabledDate(date)) {
          if (this.fromValue && date.isBefore(this.fromValue, 'day')) {
            this.$emit('update:from-value', null)
            this.$emit('update')
          }
          this.setToValue(date)
        } else {
          this.tempInput = input
        }
      }
    }
  },

  methods: {
    onFocus (type) {
      if (type === 'from') {
        this.tempInput = this.formattedFromValue
      } else if (type === 'to') {
        this.tempInput = this.formattedToValue
      }
      if (!this.fromValue && !this.toValue) {
        this.currDate = dayjs()
      } else if ((this.fromValue && type === 'from') || (!this.toValue && type === 'to')) {
        this.currDate = dayjs(this.fromValue)
      } else {
        this.currDate = dayjs(this.toValue)
      }
      this.hoverDayObj = null
      this.focus = type
      if (!this.isActive) {
        this.setInitialMonthsList()
        this.isActive = true
      }
    },

    setInitialMonthsList () {
      let index = 0
      if (this.fromValue && this.toValue) {
        const startOfMonth = this.disabled?.before && dayjs(this.fromValue).isSame(this.disabled.before, 'month') ? dayjs(this.disabled.before) : dayjs(this.fromValue).startOf('month')
        const endOfMonth = dayjs(this.toValue).endOf('month')
        if (startOfMonth.isSame(endOfMonth, 'month') && startOfMonth.isSame(this.fromValue, 'day') && endOfMonth.isSame(this.toValue, 'day')) {
          index = this.monthsListIndex = Math.floor(dayjs().diff(startOfMonth, 'month') / 6)
        }
      }
      this.monthsListIndex = index
    },

    selectDate (day) {
      if (day.isDisabled) { return }

      if (this.focus === 'from') {
        if (day.dayObj.isAfter(this.toValue)) {
          this.$emit('update:to-value', null)
          this.$emit('update')
        }
        this.setFromValue(day.dayObj)
      } else if (day.dayObj.isBefore(this.fromValue)) {
        this.setFromValue(day.dayObj)
      } else {
        this.setToValue(day.dayObj)
      }
    },

    setFromValue (day) {
      this.$emit('update:from-value', day.toDate())
      this.$emit('update')
      if (this.isActive && this.focus === 'from') this.$nextTick(() => { this.$refs['to-input'].focus() })
    },

    setToValue (day) {
      this.$emit('update:to-value', day.toDate())
      this.$emit('update')
      this.$refs['to-input'].blur()
      this.close()
    },

    onMouseOver (day) {
      if (!day.isDisabled) {
        this.hoverDayObj = day.dayObj
      }
    },

    onMouseOut () {
      this.hoverDayObj = null
    },

    prevMonth () {
      if (!this.isPrevMonthDisabled) {
        this.currDate = this.currDate.subtract(1, 'month')
      }
    },

    nextMonth () {
      if (!this.isNextMonthDisabled) {
        this.currDate = this.currDate.add(1, 'month')
      }
    },

    selectMonth (month) {
      if (month.isDisabled) {
        return
      }
      let fromValue = month.dayObj.startOf('month')
      if (this.disabled?.before && fromValue.isBefore(this.disabled.before, 'day')) {
        fromValue = dayjs(this.disabled.before)
      }
      let toValue = month.dayObj.endOf('month')
      if (this.disabled?.after && toValue.isAfter(this.disabled.after, 'day')) {
        toValue = dayjs(this.disabled.after)
      }

      this.$emit('update:from-value', fromValue.toDate())
      this.$emit('update:to-value', toValue.toDate())
      this.$emit('update')
      this.close()
    },

    prevMonthsList () {
      if (!this.isPrevMonthListDisabled) {
        this.monthsListIndex++
      }
    },

    nextMonthsList () {
      if (!this.isNextMonthListDisabled) {
        this.monthsListIndex--
      }
    },

    autoCompleteDate (e) {
      if (e?.code === 'Backspace') { return false }

      let str = e.target.value
      const looksLike_MM_slash_DD = /^(\d\d\/)?\d\d$/
      const looksLike_MM_slash_D_slash = /^(\d\d\/)?(\d\/)$/
      if (looksLike_MM_slash_DD.test(str)) {
        str += '/'
      } else if (looksLike_MM_slash_D_slash.test(str)) {
        str = str.replace(looksLike_MM_slash_D_slash, '$10$2')
        str += '/'
      } else {
        return
      }
      e.target.value = str
      const event = new Event('input')
      e.target.dispatchEvent(event)
    },

    isSelectedDate (dayObj) {
      let isSelected = false
      if (this.hoverDayObj) {
        if (this.focus === 'from' && this.hoverDayObj.isAfter(this.toValue, 'day')) {
          return false
        } else if (this.focus === 'from' || this.hoverDayObj.isBefore(this.fromValue, 'day')) {
          isSelected = dayObj.isSame(this.toValue, 'day')
        } else {
          isSelected = dayObj.isSame(this.fromValue, 'day')
        }
      } else {
        isSelected = dayObj.isSame(this.fromValue, 'day') || dayObj.isSame(this.toValue, 'day')
      }
      return isSelected
    },

    isBetweenDate (dayObj) {
      if (this.hoverDayObj) {
        return this.focus === 'from' || this.hoverDayObj.isBefore(this.fromValue, 'day')
          ? dayObj.isBetween(this.hoverDayObj, this.toValue)
          : dayObj.isBetween(this.fromValue, this.hoverDayObj)
      }

      return !this.isSelectedDate(dayObj) && dayObj.isBetween(this.fromValue, this.toValue)
    },

    isDisabledDate (dayObj) {
      let disabled = false

      if (!this.disabled) { return disabled }
      if (this.disabled?.before && dayObj.isBefore(this.disabled.before, 'day')) { disabled = true }
      if (this.disabled?.after && dayObj.isAfter(this.disabled.after, 'day')) { disabled = true }

      return disabled
    },

    isSelectedMonth (dayObj) {
      const startOfMonth = this.disabled?.before && dayObj.isSame(this.disabled.before, 'month') ? dayjs(this.disabled.before) : dayObj.startOf('month')
      const endOfMonth = this.disabled?.after && dayObj.isSame(this.disabled.after, 'month') ? dayjs(this.disabled.after) : dayObj.endOf('month')
      return startOfMonth.isSame(this.fromValue, 'day') && endOfMonth.isSame(this.toValue, 'day')
    },

    clearDate (type) {
      this.$emit(`update:${type}-value`, null)
      this.$nextTick(() => {
        this.$emit('update')
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.datepicker-v2
  position relative
  display flex

  .field
    + .field
      margin-left 2rem

    + .field--nomargin
      margin-left 0

    .input-group
      .form-control
        width 100%

      &__addon:not(:last-child)
        border-right none

      &.is-clearable
        &:hover
          input + .input-group__addon
            display none

  .calendar
    display flex
    flex-direction row
    top 100%
    width auto
    padding 0

    > div
      padding 1rem 1.5rem 1.5rem

      &:first-child
        border-right 1px solid #E4E6E8

    header
      font-weight 500

      .prev, .next
        &:after, &.disabled:after
          height 6px
          width 6px
          border none
          border-left 1.5px solid $colorMidGray
          border-bottom 1.5px solid $colorMidGray

        &.disabled:after
          opacity 0.5

      .prev:after
        transform translate(50%, -50%) rotate(45deg)

      .next:after
        transform translate(-150%, -50%) rotate(225deg)

    .periods
      width 18.4rem
      display flex
      flex-direction column

      header
        padding-bottom 0

      &-list
        flex-grow 1
        padding 0
        margin 1rem 0 0
        align-items stretch
        list-style none
        text-align center

        li
          padding 0.7rem 0
          font-size 1.4rem
          user-select none
          border-radius $border-radius

          &.selected
            background $colorShinyGray
            color white
            font-weight 500

          &:not(.disabled)
            cursor pointer
            &:not(.selected):hover
              background $colorLightGray

    .basic
      width 29rem

    .cell.day:not(.blank):not(.disabled)
      &.between
        background-color $colorLightGray
        border-radius 0

      &:not(.disabled):hover, &.selected
        border-color $colorShinyGray
        background $colorShinyGray
        color white
        font-weight 500
</style>
