<template>
  <div v-if="showFilter"
       class="col col--sm-3">
    <div class="filter-item filter-select">
      <component-dropdown label="affectation"
                          dropdown-width="100%"
                          :dropdown-height="40"
                          async-url="/affectations"
                          :search="false"
                          :can-add-item="false"
                          :model="selectedAffectation"
                          @select="onAffectationSelected">
        <template #trigger>
          <div class="select"
               :class="$parent.selectClass(modelValue)">
            <div class="form-control form-control--sm form-control--noborder">
              <template v-if="expenseAffectationIsSet">
                {{ selectedAffectation }}
              </template>
              <template v-else>
                {{ $t('form.affectation.filter.placeholder') }}
              </template>
            </div>
            <button v-if="expenseAffectationIsSet"
                    class="form-control-clear-btn"
                    type="button"
                    @click.prevent.stop="$parent.clearField('affectation')">
              <ic-remove class="ic ic--16 ic--gray" />
            </button>
          </div>
        </template>
      </component-dropdown>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import { useAccountStore } from '@/stores/account'

import ComponentDropdown from '@/components/Dropdown.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'
export default {
  components: {
    IcRemove,
    ComponentDropdown
  },

  props: {
    modelValue: {
      type: [Number, String],
      required: true
    }
  },

  emits: ['update:modelValue'],

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  data () {
    return {
      selectedAffectation: null,
      values: null
    }
  },

  computed: {
    expenseAffectationIsSet () {
      return this.modelValue !== ''
    },

    showFilter () {
      return this.account.counts?.affectations
    }
  },

  async mounted () {
    this.$bus.on('account-switched', this.onSwitchedAccount)
    this.$bus.on('get-filtered-affectations', this.sendFilteredAffectation)
  },

  beforeUnmount () {
    this.$bus.off('account-switched')
    this.$bus.off('get-filtered-affectations', this.sendFilteredAffectation)
  },

  methods: {
    onSwitchedAccount () {
      this.$emit('update:modelValue', '')
      this.$parent.onSubmit()
    },

    onAffectationSelected (complianceType) {
      this.selectedAffectation = complianceType.label
      this.$emit('update:modelValue', complianceType.id)
    },

    sendFilteredAffectation () {
      this.$bus.emit('send-filtered-affectations', this.selectedAffectation)
    }
  }
}
</script>
