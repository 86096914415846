<template>
  <core-loader v-if="loading"
               class="c c--vh" />
  <div v-else-if="campaign"
       class="sidepanel__content campaign-detail"
       :class="{ 'campaign-detail--disabled': !canClaimCampaign }">
    <div class="campaign-detail__background">
      <image-api :url="campaign.picture.url"
                 class="campaign-detail__background__picture" />
      <span v-if="!canClaimCampaign"
            class="campaign-detail__background__overlay">
        <core-icon :name="ECoreIconList.Cadenas"
                   :size="20"
                   :theme="ECoreIconTheme.White" />
        {{ t('general.locked') }}
      </span>
    </div>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <user-avatar :picture="campaign.partner.logo"
                   elevated
                   class="campaign-detail__logo"
                   :size="60"
                   :uuid="campaign.uuid"
                   :rounded="false" />
      <div class="sidepanel-body campaign-detail__body">
        <core-alert v-if="showAlert"
                    :icon-name="ECoreIconList.WarningFilled"
                    :theme="ECoreAlertTheme.Primary"
                    :text="alertText" />
        <div class="campaign-detail__body__partner">
          {{ campaign.partner.label }}
        </div>
        <div class="campaign-detail__body__label">
          {{ campaign.label }}
        </div>
        <div v-if="campaign.end_date"
             class="campaign-detail__body__date">
          {{ t('loyalty.campaign.end_date', { date: formatDateText(campaign.end_date) }) }}
        </div>
        <div class="campaign-detail__body__description">
          {{ campaign.description }}
        </div>
        <div v-if="campaign.gcu_url"
             class="campaign-detail__body__link">
          <a target="_blank"
             :href="campaign.gcu_url">
            {{ t('loyalty.gcu.see') }}
          </a>
        </div>
      </div>
    </div>
    <component-sidepanel-footer v-if="isCustomerActionVisible">
      <core-button :text="campaign.customer_action.text"
                   block
                   @click="onCustomerAction" />
    </component-sidepanel-footer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreAlert,
  CoreButton,
  CoreIcon,
  CoreLoader,
  ECoreAlertTheme,
  ECoreIconList,
  ECoreIconTheme
} from '@common/core-ui'

import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { getCardTypeLabel } from '@/helpers/utils/card'
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useLoyaltyStore } from '@/stores/loyalty'
import { ICampaign } from '@/types/loyalty.d'

import ContactAdvisor from '@/pages/loyalty/modal/ContactAdvisor.vue'

import ImageApi from '@/components/ImageApi.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import UserAvatar from '@/components/UserAvatar.vue'

const { t } = useI18n()
const appStore = useAppStore()
const loyaltyStore = useLoyaltyStore()

const loading = ref(true)
const campaign = ref<ICampaign | null>(null)

const props = defineProps<{
  uuid: string
}>()

onMounted(() => {
  initData()
})

const canClaimCampaign = computed(() => {
  return hasPermission(permissions.loyaltyCampaignsClaim, campaign.value)
})

const isCustomerActionVisible = computed(() => {
  return canClaimCampaign.value && campaign.value?.customer_action?.type
})

const showAlert = computed(() => {
  if (!campaign.value) {
    return false
  }

  if (!campaign.value?.card_types.length) {
    return false
  }

  return !campaign.value.has_card_type
})

const alertText = computed(() => {
  if (!showAlert.value) {
    return ''
  }

  const cardLabels = campaign.value?.card_types.map(type => getCardTypeLabel({ type }))
  const cardLabelsString = cardLabels?.join(', ')
  return t('loyalty.campaign.card.alert', { string: cardLabelsString })
})

async function initData () {
  loading.value = true
  campaign.value = await loyaltyStore.getCampaign(props.uuid)
  loading.value = false
}

async function onCustomerAction () {
  if (campaign.value?.customer_action.type === 'url') { redirectToUrl() }
  if (campaign.value?.customer_action.type === 'phone') { showPhoneModal() }
}

function redirectToUrl () {
  if (campaign.value?.customer_action.type === 'url') {
    window.open(campaign.value?.customer_action.url, '_blank')
  }
}

function showPhoneModal () {
  if (campaign.value?.customer_action.type === 'phone') {
    appStore.showModal(
      ContactAdvisor,
      { phone: campaign.value?.customer_action.phone },
      { wrapperClass: 'modal--xs' }
    )
  }
}
</script>

<style lang="stylus" scoped>
.campaign-detail
  &__background
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content center
    align-items center

    &__picture
      flex 1
      height 27rem
      overflow hidden

    &__overlay
      position absolute
      display flex
      gap 1rem
      align-items center
      z-index 10
      color $colorWhite
      font-weight 700
      text-transform uppercase

  &__logo
    position absolute
    margin-top -4.4rem
    margin-left 4rem

  &__body
    &__partner
      margin-top 2.8rem
      font-size 1.3rem
      text-transform uppercase
      color $colorShinyGray

    &__label
      margin-top 1.4rem
      font-size 2.2rem
      font-weight 700
      color $colorDarkGray

    &__date
      color $colorShinyGray

    &__description
      margin-top 1.8rem

    &__link
      margin-top 1.8rem

  &--disabled
    .campaign-detail
      &__background__picture
        filter brightness(60%)
</style>
