<template>
  <loader-spinner v-if="loading"
                  class="c c--vh" />
  <div v-else
       class="sidepanel__content">
    <transition name="sidepanel-double-slide">
      <div v-if="showLogs"
           class="sidepanel-double overflow-scrollbar">
        <div class="sidepanel-header">
          <button type="button"
                  class="sidepanel-double__close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="hideLogsPanel">
            <ic-close class="ic ic--18" />
          </button>
          <h2 class="panel-title">
            {{ $t('sidepanel.user_activity.title') }}
          </h2>
        </div>
        <user-activity :user-uuid="user.uuid" />
      </div>
    </transition>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header sidepanel-header--centered">
        <user-avatar :size="72"
                     :picture="user.picture"
                     :placeholder-label="user.name" />
        <div class="header-title">
          {{ user.name }}
        </div>
        <div class="header-subtitle">
          {{ user.role_label }}
          <span v-if="user.role_label && user.teams && user.teams.length"> - </span>
          <span v-if="user.teams && user.teams.length"
                v-tooltip="{ content: tooltipTeamsLabel, theme: 'tooltip', html: true }">{{ teamsLabel }}</span>
        </div>
        <core-badge v-if="user.status"
                    class="mt-2"
                    v-bind="getBadgeProps(user.status)"
                    :size="ECoreBadgeSize.Large"
                    :value="user.status_label" />
      </div>
      <hr class="divider divider--fw">
      <div class="sidepanel-body">
        <div class="sidepanel-details">
          <h3 class="head-title">
            {{ $t('sidepanel.user.title') }}
          </h3>
          <div v-if="user.created_at"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('general.creation_date') }}
            </div>
            <div class="entry-value right">
              {{ formatDateText(user.created_at) }}
            </div>
          </div>
          <div class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t('form.email.label') }}
            </div>
            <div class="entry-value right">
              {{ user.email }}
            </div>
          </div>
          <div v-if="user.phone"
               class="sidepanel-details__entry">
            <div class="entry-label text-muted">
              {{ $t("form.phone.label") }}
            </div>
            <div class="entry-value right">
              {{ formatPhoneNumber(user.phone) }}
            </div>
          </div>
          <template v-if="user.vehicle">
            <hr class="divider divider--fw">
            <h3 class="head-title">
              {{ $t('sidepanel.vehicle.title') }}
            </h3>
            <div class="sidepanel-details__entry">
              <div class="entry-label text-muted">
                {{ $t('sidepanel.vehicle.vehicle') }}
              </div>
              <span class="entry-value right">
                <ic-electrical v-if="model.vehicle.is_electrical"
                               v-tooltip="{ content: $t('tooltip.electric_vehicle'), theme: 'tooltip', html: true }"
                               class="ic ic--14 ic--center-vertically mr-1" />
                {{ model.vehicle.label }}
              </span>
            </div>
            <div class="sidepanel-details__entry">
              <div class="entry-label text-muted">
                {{ $t('sidepanel.vehicle.mileage_per_year') }}
              </div>
              <div class="entry-value right">
                {{ user.vehicle.mileage_estimation_label }}
              </div>
            </div>
            <div v-if="user.vehicle.date_registration"
                 class="sidepanel-details__entry">
              <div class="entry-label text-muted">
                {{ $t('general.vehicle_registration_document') }}
              </div>
              <div class="spacer right">
                {{ formatDateText(user.vehicle.date_registration, 'll') }}
                <template v-if="user.vehicle.files && user.vehicle.files.length">
                  -&nbsp;<button class="btn-link strong"
                                 @click="showRegistrationDocument">
                    {{ $tc('button.show_cards', 1) }}
                  </button>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <component-sidepanel-footer>
      <div v-if="user.status === 'deleted' && hasWritePermission"
           class="btn-grouped">
        <button class="btn btn--sm btn--outline"
                @click="reactivateAccessUser">
          {{ $t('sidepanel.user.reactivate') }}
        </button>
      </div>
      <div v-else
           class="btn-grouped">
        <button v-if="!isInvitation && hasWritePermission"
                class="btn btn--sm btn--outline"
                @click="showLogs = !showLogs">
          {{ $t('button.show_activity') }}
        </button>
        <button class="btn btn--sm btn--default"
                @click="viewMoreInformations">
          {{ hasWritePermission ? $t('button.edit') : $t('button.more_informations') }}
        </button>
      </div>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeSize } from '@common/core-ui'
import { parsePhoneNumber } from 'awesome-phonenumber'

import store from '@/config/store'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useInvitationStore } from '@/stores/invitation'
import { useUserStore } from '@/stores/user'

import ReactivateUser from '@/pages/users/modal/ReactivateUser.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcClose from '@/components/svg/icons/ic-close.vue'
import IcElectrical from '@/components/svg/icons/ic-electrical.vue'
import UserAvatar from '@/components/UserAvatar.vue'

import UserActivity from './UserActivity.vue'

export default {
  components: {
    CoreBadge,
    ComponentSidepanelFooter,
    IcClose,
    LoaderSpinner,
    UserActivity,
    UserAvatar,
    IcElectrical
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const invitationStore = useInvitationStore()
    const userStore = useUserStore()

    return {
      appStore,
      invitationStore,
      userStore
    }
  },

  data () {
    return {
      store,
      loading: true,
      user: {},
      showLogs: false
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    teamsLabel () {
      return this.user.teams.length < 2
        ? this.user.teams[0].name
        : this.$i18n.tc('form.operations.attributions.multiple', this.user.teams.length - 1, { name: this.user.teams[0].name, number: this.user.teams.length - 1 })
    },

    tooltipTeamsLabel () {
      return this.user.teams.length >= 2 ? this.user.teams.map(t => `${t.name}<br>`).join('') : false
    },

    hasUsersWritePermission () {
      return this.hasPermission(this.$permissions.usersWrite)
    },

    hasInvitationsWritePermission () {
      return this.hasPermission(this.$permissions.invitationsWrite)
    },

    hasWritePermission () {
      return this.isInvitation ? this.hasInvitationsWritePermission : this.hasUsersWritePermission
    },

    isInvitation () {
      return ['pending', 'to_validate', 'expired', 'refused'].includes(this.model.status)
    }
  },

  async created () {
    this.loading = true
    if (this.isInvitation) {
      this.user = this.model
    } else {
      this.user = await this.userStore.getUserDetail(this.model.uuid)
    }
    this.loading = false
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.hideLogsPanel)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.hideLogsPanel)
  },

  methods: {
    getBadgeProps,
    formatDateText,

    editUser () {
      this.appStore.closeSidePanel()
      this.$router.push({ name: 'user-edit', params: { id: this.model.uuid } })
    },

    editInvitation () {
      this.appStore.closeSidePanel()
      this.$router.push({ name: 'user-invite-edit', params: { id: this.model.id } })
    },

    viewMoreInformations () {
      this.isInvitation ? this.editInvitation() : this.editUser()
    },

    async reactivateAccessUser () {
      this.appStore.showModal(ReactivateUser, { user: this.model }, { wrapperClass: 'modal--xs' })
    },

    hideLogsPanel () {
      this.showLogs = false
    },

    showRegistrationDocument () {
      this.appStore.showFileViewer({
        content: this.user.vehicle.files,
        isMultiDoc: true,
        displayFullScreen: true
      })
    },

    formatPhoneNumber (value) {
      const phone = parsePhoneNumber(value)

      return phone.number.international
    }
  }
}
</script>
