<template>
  <div class="sidepanel-footer"
       :class="{ 'sidepanel-footer--overflow': footerOverflow }">
    <slot />
  </div>
</template>

<script>

export default {
  data () {
    return {
      footerOverflow: false
    }
  },

  mounted () {
    this.displayOverflow()
    document.getElementById('sidepanel-wrapper').addEventListener('scroll', this.displayOverflow)
    window.addEventListener('resize', this.displayOverflow)
  },

  beforeUnmount () {
    document.getElementById('sidepanel-wrapper').removeEventListener('scroll', this.displayOverflow)
    window.removeEventListener('resize', this.displayOverflow)
  },

  methods: {
    displayOverflow () {
      const wrapper = document.getElementById('sidepanel-wrapper')
      this.footerOverflow = wrapper ? wrapper.scrollHeight - wrapper.scrollTop - 10 >= wrapper.clientHeight : false
    }
  }
}
</script>

<style lang="stylus">
.sidepanel
  .sidepanel-footer
    padding $sidepanel-padding-md
    transition all 0.3s ease

    &--overflow
      z-index $zindex-sticky
      box-shadow 0 0 8px 0 alpha($colorShinyGray, 0.25)

    .btn--block:not(:first-child)
      margin-top 1.2rem

    &--bordered
      border-top 1px solid alpha($borderColor, 0.5)

    &__text
      text-align center
      margin-bottom 3rem

      .ic
        margin-bottom 1.3rem
        width 16px
        height @width
        opacity 0.5

    .form-buttons
      margin-top 0

    .buttons-wrapper
      display flex

      &--pull-right
        justify-content flex-end

        .btn-grouped
          flex 0.7

.sidepanel--lg
  .sidepanel-footer
    padding $sidepanel-padding
</style>
