<template>
  <component-dropdown async-url="/accounts"
                      :async-url-params="{
                        ...params
                      }"
                      :has-default="hasDefault"
                      :disabled="onlyOneItem || disabled"
                      dropdown-width="100%"
                      get-data-on-mounted
                      label="accounts"
                      :label-filter="getAccountLabel"
                      :model="props.account?.uuid"
                      @select="selectAccount"
                      @request-loaded="onRequestLoaded">
    <template #trigger>
      <div class="select select-account">
        <div class="form-control form-control--noborder">
          {{ getAccountLabel(props.account) }}
        </div>
        <span v-if="showAccountBalance"
              class="select-account__balance text-green">{{ formatAmount(props.account.balance, props.account.currency) }}</span>
      </div>
    </template>
  </component-dropdown>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { Account } from '@/types/account.d'
import { Pagination } from '@/types/pagination.d'

import ComponentDropdown from '@/components/Dropdown.vue'

const props = withDefaults(defineProps<{
  account: Account,
  disabled?:boolean,
  hasDefault?: boolean,
  params?: object | null,
  showAccountBalance?: boolean,
  switchAccountOnSelect?: boolean
}>(), {
  disabled: false,
  hasDefault: false,
  params: null,
  showAccountBalance: false,
  switchAccountOnSelect: true
})

const emit = defineEmits([
  'select'
])

const accountStore = useAccountStore()

const onlyOneItem = ref(false)

function getAccountLabel (account: Account) {
  return account.label ? `${account.company.name} - ${account.label}` : account.company.name
}

function selectAccount (account: Account) {
  emit('select', account)
  if (props.switchAccountOnSelect) {
    accountStore.switchAccount(account.uuid)
  }
}

function onRequestLoaded (request: {pagination: Pagination, data: Array<Account>}) {
  if (request.pagination.totalItems < 2) {
    onlyOneItem.value = true
  }
}
</script>
