<template>
  <section class="section section-operations">
    <div class="section__heading">
      <div class="section__heading__left align-items-center">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'cards-detail', params: { uuid } }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ $t('title.operations_to_justify') }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
        </div>
      </div>
    </div>
    <component-filters :list-filter="[
                         EFilter.Search,
                         EFilter.DateRange,
                         EFilter.ExpenseCategory
                       ]"
                       :number-of-results="operationsPagination.totalItems"
                       @filters="filters" />
    <div class="section__content">
      <div class="summary-balance" />
      <component-alert v-if="cardHasProofsToJustify"
                       type="warning"
                       :message="$t('alerts.card_blocked.proof_required.warning')" />
      <component-alert v-if="isLockedProof && hasResult"
                       type="error"
                       :message="$t('alerts.card_blocked.proof_required.error')" />
      <div v-if="loading"
           class="section__loader">
        <loader-spinner />
      </div>
      <template v-else-if="hasResult">
        <table class="table table--hover table-operations">
          <thead>
            <tr>
              <th class="col--sm-1">
                {{ $t("table.date") }}
              </th>
              <th class="col--sm-3">
                {{ $t("table.label") }}
              </th>
              <th class="col--sm">
                {{ $t("table.category") }}
              </th>
              <th class="col--sm-2 right">
                {{ $t("table.amount") }}
              </th>
              <th class="center">
                {{ $t("table.documents") }}
              </th>
              <th class="col--sm-1 center">
                {{ $t('table.delay') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(items, date) in groupedOperations"
                      :key="date">
              <tr class="header-table-section">
                <td colspan="6">
                  <span class="arrow">&rsaquo;</span>{{ date }}
                </td>
              </tr>
              <tr v-for="item in items"
                  :key="item.id"
                  class="is-link"
                  @click="showOperationDetail(item)">
                <td class="text-muted nowrap">
                  {{ formatDate(item.executed_at) }}
                </td>
                <td class="text-overflow">
                  <core-badge v-if="isOperationPending(item)"
                              :fill="ECoreBadgeFill.Shaded"
                              :theme="ECoreBadgeTheme.GrayLow"
                              :value="$t('general.to_come')" />
                  <span class="text-overflow col-label">{{ item.label }}</span>
                </td>
                <td>
                  <span v-tooltip.bottom-start="{ content: tooltipCategoriesLabel(item), theme: 'tooltip', html: true }"
                        class="text-overflow">{{ categoriesLabel(item) }}</span>
                </td>
                <td class="right">
                  <span class="amount text-red">
                    {{ formatAmount(item.amount, item.currency) }}
                  </span>
                </td>
                <td class="center">
                  <template v-if="!isOperationRejected(item)">
                    <button v-tooltip="{ content: fileTooltip(item), theme: 'tooltip' }"
                            class="btn-link btn-attachment"
                            @click.prevent.stop="documentCheckMethod(item)">
                      <component :is="proofIcon(item)"
                                 class="ic ic--24 ic--gray"
                                 :class="proofIcon(item)" />
                    </button>
                  </template>
                </td>
                <td class="center">
                  <VTooltip theme="poptip"
                            placement="bottom-start">
                    <template #popper>
                      <poptip :title="operationDelayPoptip(item).title"
                              :message="operationDelayPoptip(item).message" />
                    </template>
                    <component :is="operationDelayPoptip(item).icon"
                               v-bind="operationDelayPoptip(item).iconProps"
                               class="ic ic--20"
                               :class="operationDelayPoptip(item).iconClass" />
                  </VTooltip>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <component-pagination :pagination="operationsPagination" />
      </template>
      <component-placeholder v-else
                             :label="$t('placeholder.no_operation_to_justify.title')"
                             :content="''" />
    </div>
  </section>
</template>

<script>
import { CoreBadge, ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { formatDate, groupByDate, relativeTimeDifference } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { useOperationStore } from '@/stores/operation'
import { EFilter } from '@/types/filter.d'

import OperationDetail from '@/pages/operations/sidepanel/OperationDetail.vue'

import ComponentAlert from '@/components/Alert.vue'
import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import Poptip from '@/components/Poptip.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcClock from '@/components/svg/icons/ic-clock.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcDocumentInvalid from '@/components/svg/icons/ic-document-invalid.vue'
import IcDocumentOptional from '@/components/svg/icons/ic-document-optional.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'

export default {
  components: {
    CoreBadge,
    IcArrow,
    ButtonFilters,
    ComponentFilters,
    ComponentPlaceholder,
    ComponentAlert,
    ComponentPagination,
    LoaderSpinner,
    Poptip
  },

  props: {
    page: {
      type: Number,
      required: true
    },

    uuid: {
      type: String,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()
    const operationStore = useOperationStore()

    const { account } = storeToRefs(accountStore)
    const { sidePanel } = storeToRefs(appStore)
    const { operations, operationsPagination, operationTypes } = storeToRefs(operationStore)

    return { account, sidePanel, appStore, cardStore, operations, operationsPagination, operationTypes, operationStore }
  },

  data () {
    return {
      currentCard: {},
      filter: {
        search: null,
        from: null,
        to: null,
        expense_category: null
      },

      loading: false
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    ECoreBadgeFill () {
      return ECoreBadgeFill
    },

    groupedOperations () {
      return groupByDate(this.operations, 'executed_at')
    },

    cardHasProofsToJustify () {
      return this.currentCard.settings?.proof_required && this.currentCard.proof_required_before
    },

    isLockedProof () {
      return this.currentCard.status === 'locked_proof'
    },

    hasResult () {
      return this.operations.length
    }
  },

  watch: {
    page () {
      this.loadData()
    },

    filter () {
      this.loadData()
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('delete-success', this.refreshOperationList)
    this.$bus.on('on-upload-success', this.refreshOperationList)
    this.$bus.on('operation-update-success', this.refreshOperationList)
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('delete-success', this.refreshOperationList)
    this.$bus.off('on-upload-success', this.refreshOperationList)
    this.$bus.off('operation-update-success', this.refreshOperationList)
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    formatAmount,

    formatDate,

    initData () {
      this.filter = {
        search: null,
        from: null,
        to: null,
        expense_category: null
      }

      this.loadData()

      this.operationStore.getOperationCategories()
      this.operationStore.getOperationAffectations()
      this.operationStore.getOperationTypes()
    },

    async loadData (hideOperationList = true) {
      this.loading = hideOperationList

      this.filter.card = true
      this.filter.filter = 'proof_required'
      this.filter.is_compliant = true
      this.filter.card_uuid = this.uuid

      this.currentCard = await this.cardStore.getCard(this.uuid)
      await this.operationStore.getOperations(this.page, this.filter)
      this.loading = false
    },

    filters (filters) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to,
        expense_category: filters.expense_category
      }

      this.loadData()
    },

    refreshOperationList () {
      this.loadData(false)
    },

    fileTooltip (operation) {
      if (this.isProofLost(operation)) {
        return this.$i18n.t('tooltip.lost_proof')
      }
      if (this.isProofInvalid(operation)) {
        return this.$i18n.t('tooltip.invalid_proof')
      }
      if (this.isProofOptional(operation)) {
        return this.$i18n.t('button.optional_proof')
      }
      if (this.isProofUploaded(operation)) {
        return this.$i18n.tc('tooltip.show_file', operation.documents.length)
      }
      if (this.isProofBill(operation)) {
        return this.$i18n.tc('tooltip.show_file')
      }
      return this.$i18n.t('tooltip.attach_files')
    },

    isOperationPending (operation) {
      return operation.status === 'pending'
    },

    isOperationRejected (operation) {
      return operation.status === 'rejected'
    },

    isProofUploaded (operation) {
      return operation.documents.length
    },

    isProofBill (operation) {
      return operation.bill
    },

    isProofInvalid (operation) {
      return operation.documents_status === 'invalid'
    },

    isProofLost (operation) {
      return operation.documents_status === 'lost'
    },

    isProofOptional (operation) {
      return operation.documents_status === 'optional'
    },

    tooltipCategoriesLabel (operation) {
      return operation.detail.categories.length >= 3
        ? operation.detail.categories.map(c => `${c.label}<br>`).join('')
        : false
    },

    categoriesLabel (operation) {
      if (!operation.detail.categories.length) { return '-' }

      return operation.detail.categories.length < 3
        ? operation.detail.categories.map(category => category.label).join(', ')
        : `${operation.detail.categories.length} ${this.$i18n.t('general.categories').toLowerCase()}`
    },

    operationDelayPoptip (operation) {
      const timeDiff = relativeTimeDifference(operation.proof_required_before)

      if (timeDiff.hours < 0) {
        return {
          title: this.$i18n.t('remaining_formatter.overdue'),
          message: this.$i18n.t('poptip.late_proof.content'),
          icon: IcError,
          iconProps: {},
          iconClass: 'ic--error'
        }
      }

      const title = `${this.$i18n.t('button.required_proof')} - ${timeDiff.text}`
      return timeDiff.hours < 24
        ? {
            title,
            message: this.$i18n.t('poptip.required_proof.content'),
            iconName: IcWarning,
            iconProps: { outline: true },
            iconClass: 'ic--warning'
          }
        : {
            title,
            message: this.$i18n.t('poptip.late_proof.content'),
            icon: IcClock,
            iconProps: {},
            iconClass: 'ic--gray'
          }
    },

    proofIcon (operation) {
      if (this.isProofLost(operation)) {
        return IcWarning
      }
      if (this.isProofInvalid(operation)) {
        return IcDocumentInvalid
      }
      if (this.isProofOptional(operation)) {
        return IcDocumentOptional
      }
      if (this.isProofUploaded(operation) || this.isProofBill(operation)) {
        return IcDocument
      }
      return IcAttachment
    },

    documentCheckMethod (operation) {
      if (this.isProofLost(operation)) {
        return this.showOperationDetail(operation)
      }

      if (this.isProofUploaded(operation) || this.isProofBill(operation)) {
        return this.showOperationDetail(operation, true)
      }

      return this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file', 2), operation, { url: `/operations/${operation.uuid}/documents`, canAnalyzeFile: true })
    },

    showOperationDetail (operation, showFileViewer = false) {
      this.appStore.showSidePanel(
        OperationDetail,
        { model: operation },
        { wrapperClass: 'sidepanel--md', backgroundOverlay: true, canDragAndDrop: !this.isOperationRejected(operation) && !this.isProofBill(operation), initShowFile: showFileViewer }
      )
    }
  }
}
</script>
