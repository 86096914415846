<template>
  <div class="document-check document-check--success">
    <div class="document-check__header">
      <ic-check-circle outline
                       class="ic ic--white ic--26" />
      <h1>{{ getTitle }}</h1>
    </div>
    <div class="document-check__content">
      <div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.owner') }}
          </div>
          <div class="response-row__data">
            {{ data.owner }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.address') }}
          </div>
          <div class="response-row__data">
            {{ data.address }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.name') }}
          </div>
          <div class="response-row__data">
            {{ data.account_label }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.iban') }}
          </div>
          <div class="response-row__data">
            {{ data.iban }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.bic') }}
          </div>
          <div class="response-row__data">
            {{ data.bic }}
          </div>
        </div>
        <div class="response-row">
          <div class="response-row__name">
            {{ $t('document_check.details.bank_name') }}
          </div>
          <div class="response-row__data">
            {{ data.bank_name }}
          </div>
        </div>
      </div>
      <div v-if="getDocumentDescription"
           class="document-check__description">
        {{ getDocumentDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import IcCheckCircle from '@/components/svg/icons/ic-check-circle.vue'

export default {
  name: 'ComponentRib',

  components: {
    IcCheckCircle
  },

  props: {
    type: {
      type: String,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    getTitle () {
      return this.type === 'rib' ? this.$i18n.t('document_check.title.rib') : this.$i18n.t('document_check.title.temp_rib')
    },

    getDocumentDescription () {
      if (this.type === 'temp_rib_new_company') {
        return this.$i18n.t('document_check.description.temp_rib_new_company', {
          company_name: this.data.owner
        })
      }
      if (this.type === 'temp_rib_existing_company') {
        return this.$i18n.t('document_check.description.temp_rib_existing_company', {
          company_name: this.data.owner
        })
      }
      return null
    }
  }
}
</script>
