<template>
  <fieldset class="form__fieldset">
    <div class="bordered-box bordered-box--padding virtual-card-summary">
      <div class="card-item card-item--default-virtual"
           :class="`card-item--${card.type}`">
        <div class="card-item__content">
          <div class="card-title">
            <div class="badge-block">
              <core-badge :theme="cardBadgeTheme(card.type)"
                          :value="badgeLabel" />
            </div>
          </div>
          <div v-if="!isCardClassic"
               class="card-amount">
            {{ formatAmount(card.settings.initial_amount, account.currency) }}
          </div>
          <card-digits />
          <div class="card-bottom-details">
            <span class="card-details holder">
              <strong>{{ cardName }}</strong>
            </span>
            <span class="card-details date">
              <small>{{ $t('label.card.expiration_end') }}</small>
              <strong>{{ card.expiration_date }}</strong>
            </span>
          </div>
        </div>
      </div>
      <div class="card-informations">
        <transaction-user :pictures="[card.picture]"
                          :user-names="[cardName]"
                          :text="$t('label.card.holder')" />
        <div class="card__entry">
          <div class="entry-label text-muted">
            {{ $t('table.type') }}
          </div>
          <div class="entry-value right">
            {{ badgeLabel }}
          </div>
        </div>
        <div v-if="!isCardClassic"
             class="card__entry">
          <div class="entry-label text-muted">
            {{ isRecurringCard ? $t('form.amount.label') : $t('form.virtual_cards.cap.label') }}
          </div>
          <div class="entry-value right">
            {{ formatAmount(card.settings.initial_amount, account.currency) }}
          </div>
        </div>
        <div v-if="isRecurringCard"
             class="card__entry">
          <div class="entry-label text-muted">
            {{ $t("general.periodicity") }}
          </div>
          <div class="entry-value right"
               style="text-transform: capitalize">
            {{ $t(`generate_ecard.choice.recurring.type.${card.periodicity}`) }}
          </div>
        </div>
        <div v-if="!isOneTimeCard"
             class="card__entry">
          <div class="entry-label text-muted">
            {{ $t('label.card.expiration_end_long') }}
          </div>
          <div class="entry-value right"
               style="text-transform: capitalize">
            {{ formattedExpirationDateFull }}
          </div>
        </div>
        <div v-if="card.label"
             class="card__entry">
          <div class="entry-label text-muted">
            {{ $t('form.virtual_cards.label.label') }}
          </div>
          <div class="entry-value right">
            {{ card.label }}
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { CoreBadge } from '@common/core-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { storeToRefs } from 'pinia'

import { cardBadgeTheme } from '@/helpers/utils/badge'
import { getCardBadgeLabel, isClassicVirtualCard, isOneTimeVirtualCard, isRecurringVirtualCard } from '@/helpers/utils/card'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import CardDigits from '@/components/CardDigits.vue'
import TransactionUser from '@/components/TransactionUser.vue'

dayjs.extend(customParseFormat)

export default {
  components: {
    CoreBadge,
    CardDigits,
    TransactionUser
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()

    const { account } = storeToRefs(accountStore)

    return { account }
  },

  computed: {
    isCardClassic () {
      return isClassicVirtualCard(this.card)
    },

    isOneTimeCard () {
      return isOneTimeVirtualCard(this.card)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.card)
    },

    badgeLabel () {
      return getCardBadgeLabel(this.card)
    },

    formattedExpirationDateFull () {
      return dayjs(this.card.expiration_date, 'MM/YY').format('MMMM YYYY')
    },

    cardName () {
      return `${this.card.first_name} ${this.card.last_name}`.trim()
    }
  },

  methods: {
    cardBadgeTheme,
    formatAmount
  }
}
</script>
