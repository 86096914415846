<template>
  <div class="section__content">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else>
      <component-alert v-if="isPermissionGranted"
                       type="info"
                       :message="$t('alerts.sub_accounts')">
        <component-button class="btn btn--xs btn--info"
                          :label="$t('tooltip.see_more')"
                          :show-loader="false"
                          @click="createAccount" />
      </component-alert>
      <ul class="company-list">
        <li v-if="isPermissionGranted">
          <div class="item add-new"
               @click="createAccount">
            <div class="add-new-item">
              <p class="add-new-item__label">
                {{ $t('button.create_bank_account') }}
              </p>
              <div class="add-new-item__icon">
                <ic-plus class="ic--gray ic--24" />
              </div>
            </div>
            <span class="decorations">
              <hr class="decorative-hr decorative-hr_1">
              <hr class="decorative-hr decorative-hr_2">
            </span>
          </div>
        </li>
        <account-item v-for="companyAccount in accounts"
                      :key="companyAccount.id"
                      :account-data="companyAccount" />
      </ul>
      <component-pagination :pagination="pagination" />
    </template>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'

import ComponentAlert from '@/components/Alert.vue'
import ComponentButton from '@/components/Button.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentPagination from '@/components/Pagination.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'

import AccountItem from './AccountItem.vue'

export default {
  components: {
    IcPlus,
    AccountItem,
    ComponentPagination,
    ComponentAlert,
    ComponentButton,
    LoaderSpinner
  },

  props: {
    company: {
      type: Object,
      required: true
    },

    page: {
      type: Number,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()

    const { account } = storeToRefs(accountStore)

    return {
      account,
      accountStore,
      appStore
    }
  },

  data () {
    return {
      store,
      accounts: [],
      pagination: { current: 1, count: 1, perPage: 50, totalItems: 0 },
      loading: true
    }
  },

  computed: {
    isPermissionGranted () {
      return this.accountStore.hasAnyCompanyPermission(this.company)
    }
  },

  watch: {
    page () {
      this.initData()
    }
  },

  async created () {
    await this.initData()
  },

  mounted () {
    this.$bus.on('on-display-new-account', this.onDisplayNewAccount)
    this.$bus.on('on-account-closed', this.onAccountClosed)
  },

  beforeUnmount () {
    this.$bus.off('on-display-new-account', this.onDisplayNewAccount)
    this.$bus.off('on-account-closed', this.onAccountClosed)
  },

  methods: {
    async initData () {
      this.loading = true
      const { pagination, data } = await this.accountStore.getSubAccounts(this.page, this.company.id)
      this.pagination = pagination
      this.accounts = data
      this.loading = false
    },

    async createAccount () {
      if (this.company.id !== this.account.company.id) {
        await this.accountStore.switchAccount(this.accounts[0].uuid)
      }

      return this.$router.push({ name: 'create-sub-account' })
    },

    onDisplayNewAccount (newAccount) {
      this.accounts.push(newAccount)
    },

    onAccountClosed (account) {
      this.accounts = this.accounts.map(a => a.uuid === account.uuid ? account : a)
    }
  }
}
</script>
