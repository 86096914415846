import DateLanguages from './DateLanguages'

export default {
  getDayNameAbbr (date, days) {
    if (typeof date !== 'object') {
      throw TypeError('Invalid Type')
    }
    return days[date.getDay()]
  },

  getMonthName (month, months) {
    if (!months) {
      throw Error('missing 2nd parameter Months array')
    }
    if (typeof month === 'object') {
      return months[month.getMonth()]
    }
    if (typeof month === 'number') {
      return months[month]
    }
    throw TypeError('Invalid type')
  },

  getMonthNameAbbr (month, monthsAbbr) {
    if (!monthsAbbr) {
      throw Error('missing 2nd paramter Months array')
    }
    if (typeof month === 'object') {
      return monthsAbbr[month.getMonth()]
    }
    if (typeof month === 'number') {
      return monthsAbbr[month]
    }
    throw TypeError('Invalid type')
  },

  daysInMonth (year, month) {
    return new Date(Date.UTC(year, month + 1, 0)).getUTCDate()
  },

  getNthSuffix (day) {
    switch (day) {
      case 1:
      case 21:
      case 31:
        return 'st'
      case 2:
      case 22:
        return 'nd'
      case 3:
      case 23:
        return 'rd'
      default:
        return 'th'
    }
  },

  formatDate (date, format, translation) {
    translation = (!translation) ? DateLanguages.translations.en : translation
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return format
      .replace(/D(?!e)/, this.getDayNameAbbr(date, translation.days))
      .replace(/dd/, (`0${day}`).slice(-2))
      .replace(/d/, day)
      .replace(/yyyy/, year)
      .replace(/yy/, String(year).slice(2))
      .replace(/MMMM/, this.getMonthName(date.getMonth(), translation.months.original))
      .replace(/MMM/, this.getMonthNameAbbr(date.getMonth(), translation.months.abbr))
      .replace(/MM/, (`0${month}`).slice(-2))
      .replace(/M(?!a)/, month)
      .replace(/su/, this.getNthSuffix(date.getDate()))
  }

}
