<template>
  <modal-dialog-prompt :title="$t('title.legal_informations')"
                       @close="$emit('close')">
    <template #body>
      <i18n-t tag="p"
              keypath="prompt.cards.legal_informations.cgu"
              scope="global">
        <template #link>
          <a class="terms-link"
             :href="links.STATIC.VISA.CGU"
             target="_blank">{{ $t('prompt.cards.legal_informations.cgu_link') }}</a>
        </template>
      </i18n-t>
      <p>{{ $t('prompt.cards.legal_informations.also') }}</p>
      <i18n-t tag="p"
              keypath="prompt.cards.legal_informations.assistance"
              scope="global">
        <template #link>
          <a class="terms-link"
             :href="links.STATIC.VISA.ASSISTANCE"
             target="_blank">{{ $t('prompt.cards.legal_informations.assistance_link') }}</a>
        </template>
      </i18n-t>
      <i18n-t tag="p"
              keypath="prompt.cards.legal_informations.insurance"
              scope="global">
        <template #link>
          <a class="terms-link"
             :href="links.STATIC.VISA.INSURANCE"
             target="_blank">{{ $t('prompt.cards.legal_informations.insurance_link') }}</a>
        </template>
      </i18n-t>
    </template>

    <template #footerButtons>
      <button class="btn btn--gray"
              type="button"
              @click="$emit('close')">
        {{ $t('button.cancel') }}
      </button>
      <button class="btn btn--primary"
              data-cy="cards.details.activate.accept"
              @click="$emit('accept')">
        {{ $t('button.accept') }}
      </button>
    </template>
  </modal-dialog-prompt>
</template>

<script>
import links from '@/config/links'

import ModalDialogPrompt from '@/components/modal-template/ModalDialogPrompt.vue'

export default {
  components: { ModalDialogPrompt },

  emits: ['accept', 'close'],

  data () {
    return {
      links
    }
  }
}
</script>

<style lang="stylus" scoped>
.terms-link
  color $colorDarkGray
  font-weight 500
  text-decoration underline
</style>
