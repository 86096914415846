import { createRouter, createWebHistory } from 'vue-router'

import guard from './guard'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})

router.beforeEach(guard)

router.requireAuth = () => {
  return router.currentRoute.value?.matched?.some(record => record.meta.requiresAuth === true)
}

export function isResponsive (route) {
  return route.matched.some(record => record.meta.isResponsive === true) && !route.matched.some(record => record.meta.isResponsive === false)
}

export default router
