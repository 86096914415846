import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'

export const useDebitStore = defineStore('debit', {
  state: () => {
    return {
      debits: [],
      debitsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      debitsTotalAmount: 0,
      mandates: [],
      mandatesPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  actions: {
    async getDebits (state, page, params = {}) {
      if (state === 'to_come') {
        params.status = 'new'
      } else if (state === 'done') {
        params.status = 'done'
      } else if (state === 'rejected') {
        params.status = 'rejected'
      }

      params.page = page
      params.mode = 'collection'

      try {
        const { data, headers } = await axiosClient.get('/debits', { params })
        const collection = this.parseCollection(data, headers)

        this.debits = collection.items
        this.debitsTotalAmount = collection.totalAmount
        this.debitsPagination = collection.pagination
      } catch (error) {
        console.error(error)
        this.debits = []
        this.debitsTotalAmount = 0
      }
    },

    async getMandates (page, params = {}) {
      let mandates = []

      params.page = page
      try {
        const { data, headers } = await axiosClient.get('/debit-mandates', { params })
        this.mandatesPagination = this.parsePagination(headers)

        mandates = data
      } catch (error) {
        console.error(error)
      }

      this.mandates = mandates
    },

    async postMandate (mandate) {
      const payload = new FormData()

      if (mandate.document instanceof File) {
        payload.append('document', mandate.document)
      }
      delete mandate.document

      payload.append('jsonBody', JSON.stringify(mandate))

      try {
        await axiosClient.post('/debit-mandates', payload)
        bus.emit('mandate-save-success')
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
})
