import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { showToastSuccess } from '@/helpers/utils/notification'
import { globalTranslation } from '@/locales'
import { ModelCredit } from '@/models/Credit/ModelCredit'
import { ECreditState, ICredit } from '@/types/credit.d'
import { Pagination } from '@/types/pagination.d'

type Params = {
  state?: ECreditState
  page?: number
  mode?: string
}

interface State {
  credits: ModelCredit[]
  creditsPagination: Pagination
  creditsPendingAmount: number|null
  creditsTotalAmount: number
}

interface IResponse {
  data: ICredit[]
  _meta: {
    totalAmount: number
  }
}

export const useCreditStore = defineStore('credit', {
  state: (): State => {
    return {
      credits: [],
      creditsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      creditsPendingAmount: null,
      creditsTotalAmount: 0
    }
  },

  actions: {
    async getCredits (state: ECreditState, page: number, params: Params = {}) {
      params.state = state
      params.page = page
      params.mode = 'collection'

      try {
        const { data: { data: credits, _meta: { totalAmount } }, headers } = await axiosClient.get<IResponse>('/credits', { params })
        this.creditsPagination = this.parsePagination(headers)

        if (headers['x-mone-credits-pending-amount']) {
          this.creditsPendingAmount = parseFloat(headers['x-mone-credits-pending-amount'])
        }

        this.credits = credits.map((credit: ICredit) => ModelCredit.create(credit))
        this.creditsTotalAmount = totalAmount
      } catch (error) {
        console.error(error)
        this.credits = []
        this.creditsTotalAmount = 0
      }
    },

    async getCredit (id: number) {
      try {
        const { data } = await axiosClient.get<ICredit>(`/credits/${id}`)

        return ModelCredit.create(data)
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async saveCredit (credit: ModelCredit) {
      try {
        const { data } = await axiosClient.post('/credits', credit.toApi({ exceptsFields: ['send_notification'] }))
        bus.emit('credits-save-success', data)
      } catch (error) {
        console.error(error)
      }
    },

    async updateCredit (credit: ModelCredit) {
      try {
        const { data } = await axiosClient.put(`/credits/${credit.id}`, credit.toApi({ onlyDirty: true }))
        bus.emit('credits-update-success', data)
      } catch (error) {
        console.error(error)
      }
    },

    async creditSendNotification (credit: ModelCredit) {
      try {
        await axiosClient.put(`/credits/${credit.id}`, credit.toApi({ fields: ['send_notification'] }))

        showToastSuccess(globalTranslation(`message.success.notification.${credit.send_notification ? 'on' : 'off'}`))
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async analyzeCreditDocument (credit: ModelCredit, abortSignal: AbortSignal): Promise<ICredit | null> {
      if (!credit?.document?.file) return null

      const payload: FormData = new FormData()

      payload.append('files', credit.document.file)

      try {
        const { data } = await axiosClient.post('/credits/document/analyze', payload, { signal: abortSignal })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
