<template>
  <svg viewBox="0 0 16 16">
    <path d="M14 13.75v1.5H2v-1.5zM8.75 1a.5.5 0 0 1 .5.5v4.499L13 6l-5 6-5-6 3.75-.001V1.5a.5.5 0 0 1 .5-.5z" />
  </svg>
</template>

<script>
export default {
}
</script>
