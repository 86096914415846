<template>
  <fieldset class="form__fieldset">
    <fieldset-title :title="$t('form.title.team')"
                    show-number />
    <div class="form__fieldset__group ">
      <div class="form__row row">
        <div class="form-group col col--sm">
          <label class="label input__label">
            {{ $t("form.team.label") }}
          </label>
          <component-dropdown ref="teamDropdown"
                              label="team"
                              dropdown-menu-class="dropdown-menu--noborder"
                              dropdown-width="100%"
                              async-url="/teams"
                              :label-filter="value => value.name"
                              search
                              :model="team?.id"
                              @select="onTeamSelected">
            <template #trigger>
              <div class="dropdown-toggle select">
                <div class="form-control form-control--noborder">
                  <span v-if="!team?.id"
                        class="text-placeholder">{{ $t('form.team.placeholder') }}</span>
                  <span v-else>{{ team?.name }}</span>
                </div>
              </div>
            </template>
          </component-dropdown>
        </div>
        <div class="form-group col col--sm"
             :class="{ error: errors.team_role }">
          <label class="label input__label">
            {{ $t("form.team_role.label") }}
          </label>
          <validation-field name="team_role"
                            :model-value="teamRole">
            <component-dropdown ref="teamRoleDropdown"
                                label="teamRole"
                                dropdown-menu-class="dropdown-menu--noborder"
                                :dropdown-width="'100%'"
                                :values="teamRoles"
                                :model="teamRole"
                                @select="onTeamRoleSelected">
              <template #trigger>
                <div class="dropdown-toggle select">
                  <div class="form-control form-control--noborder">
                    <span v-if="!teamRole"
                          class="text-placeholder">{{ $t('form.team_role.placeholder') }}</span>
                    <span v-else>{{ getTeamRole(teamRole) }}</span>
                  </div>
                </div>
              </template>
            </component-dropdown>
          </validation-field>
        </div>
      </div>
      <transition name="show-toggle">
        <div v-if="teamRole === 'manager'"
             class="form__row row">
          <div class="col">
            <component-info size="sm">
              {{ $t('form.team_role.manager.help') }}
            </component-info>
          </div>
        </div>
      </transition>
    </div>
  </fieldset>
</template>

<script>
import ComponentDropdown from '@/components/Dropdown.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ComponentInfo from '@/components/Info.vue'

export default {
  components: {
    ComponentDropdown, ComponentInfo, FieldsetTitle
  },

  props: {
    model: {
      type: Object,
      default: () => ({})
    },

    errors: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['update-model'],

  data () {
    return {
      team: {
        id: null,
        name: null
      },

      teamRole: null
    }
  },

  computed: {
    teamRoles () {
      return [
        {
          value: 'approver',
          label: this.$i18n.t('form.team_role.approver.label')
        },
        {
          value: 'manager',
          label: this.$i18n.t('form.team_role.manager.label')
        },
        {
          value: 'member',
          label: this.$i18n.t('form.team_role.member.label')
        }
      ]
    }
  },

  created () {
    if (this.model) {
      this.team = this.model.team
      this.teamRole = this.model.teamRole
    }
  },

  methods: {
    getTeamRole (teamRole) {
      return this.teamRoles.find(role => role.value === teamRole)?.label
    },

    onTeamSelected (value) {
      this.team = (this.team?.id === value.id) ? null : value
      this.$emit('update-model', { team_id: this.team ? this.team.id : null, team_name: this.team ? this.team.name : null })
    },

    onTeamRoleSelected (teamRole) {
      this.teamRole = (this.teamRole === teamRole.value) ? null : teamRole.value
      this.$emit('update-model', { team_role: this.teamRole })
    }
  }
}
</script>

<style lang="stylus" scoped>
.show-toggle-enter-to
.show-toggle-leave-from
  height 3.8rem
</style>
