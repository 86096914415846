import { useAccountStore } from '@/stores/account'

type item = null | {
  permissions?: Array<string>
} & object

export default function hasPermission (permissions: string | Array<string>, item?:item) {
  const permissionItem = item || useAccountStore().account
  if (!permissionItem?.permissions) return false

  permissions = Array.isArray(permissions) ? permissions : [permissions]

  return permissionItem.permissions.some(i => permissions.includes(i))
}
